import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'antd/lib/form/Form';
import { Button, Spin, Drawer } from 'antd';
import FormComponent from './NewFormComponent';
import useStore from '../../../../store';
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [saving, setSaving] = useState(false);
  const [submitSaving, setSubmitSaving] = useState(false);
  const [savePreviewSaving, setsavePreviewSaving] = useState(false);

  const [tip, setTip] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const { SavedReportStore, AUTH } = useStore();
  const [formDataList, setFormDataList] = useState([]);
  const [saveFilterList, setsaveFilterList] = useState([]);
  const defaultFormDataList = [
    'report_type',
    'name',
    'code',
    'description',
    'global_toggle',
  ];

  const handleSubmit = (data, isPreview = false) => {
    data.form_list = formDataList;
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.check_unique_code = true;
    setSaving(true);
    if (isPreview) {
      setsavePreviewSaving(true);
    } else {
      setSubmitSaving(true);
    }
    setTip('Saving');
    SavedReportStore.AddData(data)
      .then((res) => {
        vsmNotify.success({
          message: vsmTransactionReport.save,
        });

        if (res.report) {
          SavedReportStore.created_by_id = res.report.created_by;
          SavedReportStore.previewReportValues = {
            ...JSON.parse(res.report.generated_on_options),
            id: res.report.id,
            name: res.report.name,
            code: res.report.code,
            description: res.report.description,
          };
          if (SavedReportStore.previewReportValues?.global_toggle !== undefined) {
            SavedReportStore.previewReportValues.global_toggle =
              !SavedReportStore.previewReportValues.global_toggle;
          } else {
            SavedReportStore.previewReportValues.global_toggle = false;
          }
        }

        if (isPreview) {
          openPreviewReport();
        } else {
          props.close();
        }
        if (SavedReportStore.my_records === 'my') {
          SavedReportStore.getList({}, AUTH.user.id);
        } else {
          SavedReportStore.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        setSubmitSaving(false);
        setsavePreviewSaving(false);
        setTip(null);
      });
  };

  const openPreviewReport = () => {
    let data;
    SavedReportStore.setPreviewReportValues({
      ...SavedReportStore.previewReportValues,
      ...form.getFieldsValue(),
      form_list: formDataList,
    });
    data = SavedReportStore.formatFormData(
      SavedReportStore.previewReportValues,
      true,
      true
    );
    data.action = 'preview-data';
    data.check_unique_code = true;

    setSaving(true);
    setTip('Loading');
    SavedReportStore.PreviewData(data)
      .then((data) => {
        props.openPreviewDataModal(props.report_id);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        setTip(null);
      });
  };

  const savePreviewReport = (data = form.getFieldsValue()) => {
    data.form_list = formDataList;
    data = SavedReportStore.formatFormData({
      ...SavedReportStore.previewReportValues,
      ...data,
    });
    data.action = 'save_report';
    handleSubmit(data, true);
  };

  useEffect(() => {
    SavedReportStore.previewReportValues = null;
  }, [SavedReportStore]);

  const close = () => {
    props.close();
    SavedReportStore.previewReportValues = null;
    setSaving(true);
    setTip('Loading');
  };

  return (
    <>
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Add')} ${t('Reports.Report')}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right' key='1'>
            {
              <Button
                key='1'
                className='mr-10'
                htmlType='button'
                shape='round'
                disabled={isDisabled}
                onClick={() => {
                  openPreviewReport();
                }}
              >
                {t('Reports.Preview')} {t('Reports.Data')}
              </Button>
            }
            {AUTH.checkPermission(21, 'add') && (
              <Button
                key='2'
                form='addformReport'
                className='mr-10'
                htmlType='submit'
                shape='round'
                type='primary'
                disabled={isDisabled}
                loading={submitSaving}
              >
                {t('Common_Data.Save')}
              </Button>
            )}
            {AUTH.checkPermission(21, 'add') && (
              <Button
                key='3'
                className='mr-10'
                shape='round'
                disabled={isDisabled}
                onClick={() => {
                  savePreviewReport();
                }}
                loading={savePreviewSaving}
              >
                {t('Common_Data.Save')} & {t('Reports.Preview')}
              </Button>
            )}
            <Button shape='round' onClick={close} key='4'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        <Spin size='large' spinning={saving} tip={tip}>
          <FormComponent
            defaultFormDataList={defaultFormDataList}
            form={form}
            formDataList={formDataList}
            setFormDataList={setFormDataList}
            handleSubmit={handleSubmit}
            initialValues={{
              ...SavedReportStore.initialValues,
              sort_by: 'modified_at',
            }}
            setDisabled={setDisabled}
            saveFilterList={saveFilterList}
            setsaveFilterList={setsaveFilterList}
            id='addformReport'
          />
        </Spin>
      </Drawer>
    </>
  );
});

export default AddComponent;
