import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { Link } from 'react-router-dom';


const MasterTicketRenderer = observer((props) => {
  const { AUTH } = useStore();

  return props.value && (
    <>
      <div className='action-column StatusRenderer text-left'>
        {props?.data?.type.toLowerCase() === 'ticket'
          ?
          <>
            {props.value} <Link to={`/ticket/${props.value}`} target={'_blank'}>
              <img alt='' src={AUTH.GetThemedImage('Tickets')} style={{ width: '14px', marginLeft: "5px" }} />
            </Link>
          </>
          : <>
            {props.value} <Link to={`/dealdesk/${props.value}`} target={'_blank'}>
              <img alt='' src={AUTH.GetThemedImage('Deal_icn')} style={{ width: '14px', marginLeft: "5px" }} />
            </Link>
          </>
        }
      </div>
    </>
  );
});
export default MasterTicketRenderer;
