import React from 'react';
import { Button, Modal, Col, Row, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
// import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';

const SaveModal = observer((props) => {
  const { t } = useTranslation();
  const { saveType, id } = props;

  const getTitleBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    } else if (saveType === 'schedule') {
      return `${t('Common_Data.Schedule')}`;
    } else if (saveType === 'shortfall') {
      return `${t('Common_Data.Shortfall')}`;
    }
  }
  return (
    <Modal
      centered
      title={`${t('Common_Data.Save')} ${getTitleBasedOnSaveType()}`}
      visible={props.visible}
      onCancel={() => props.close(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <div className='d-flex justify-space-between'>
          <span>
            <Checkbox
              key={'1'}
              checked={props.isTemplate}
              onChange={
                (e) => {
                  props.setIsTemplate(e.target.checked)
                }
              }
            >
              {t('DealDesk.Save_Template')}
            </Checkbox>
          </span>
          <span>
            <Button
              key={'2'}
              form={id}
              loading={props.loading}
              htmlType='submit'
              type='primary'
              shape='round'
              size='medium'
            >
              {t('Common_Data.Save')}
            </Button>
            <Button key='3' htmlType='button' shape='round' size='medium'
              onClick={() => props.close(false)}
            >
              {t('Common_Data.Cancel')}
            </Button>
          </span>
        </div>
      ]}
    >
      <Row align='middle'>
        <Col span={4} className='DeleteIcon'>
          <WarningFilled />
        </Col>
        <Col className='DeleteText'>
          {t('DealDesk.Are_You_Sure_To_Save')}{' '}
          {getTitleBasedOnSaveType()}?
        </Col>
      </Row>
    </Modal>
  )
})

export default SaveModal