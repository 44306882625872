import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { convertTextToID } from '../../utils/GlobalFunction';
import { reset, clear } from '../../utils/ResetClearButton';

export default class BlockEditAssignStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  dropdown_leads_users_list = null;
  dropdown_global_org = null;
  dropdown_people_list = null;
  allChildStagesList = null;
  classification_role_list = null;
  dropdown_leadstatus_list = null;

  extraFilter = null;
  search_payload = {};

  DEFAULT_GRID_FILTER_ID = null;
  selectedGridFilter = localStorage.getItem('savedFilters')
    ? JSON.parse(localStorage.getItem('savedFilters'))?.BLOCKEDITASSIGNSTORE
      ? JSON.parse(localStorage.getItem('savedFilters'))?.BLOCKEDITASSIGNSTORE
      : null
    : null;

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('block_editassign_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
    let param = this.getFilter(params);
    let user_name_fullname = localStorage.getItem('user_name_fullname');
    let filter = {
      'lead_owner_name.full_name': { values: [user_name_fullname], filterType: 'set' },
      ...param?.request?.filter,
    };

    params.api.setFilterModel(filter);
    params.api.setSortModel(param?.request?.sort);
    var datasource = this.createDatasource(GridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.PromotionStore) {
        params = { request: temp.PromotionStore };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PromotionStore: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          PromotionStore: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  getList = (payload = {}) => {
    let api_url_path = `/leadstransactions/list/management`;

    return Axios.post(api_url_path, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
          // item.email_name = 'a'; // item.email && item.email[0] && item.email[0].contact_value;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'block_editassign_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('block_editassign_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        var filter_data = params.request.filterModel;
        if (filter_data['lead_owner_name.full_name']) {
          filter_data['lead_owner_name.full_name'].values = convertTextToID(
            filter_data['lead_owner_name.full_name'],
            this.dropdown_leads_users_list,
            'name',
            'id'
          );
        }
        if (filter_data['classification.name']) {
          filter_data['classification.name'].values = convertTextToID(
            filter_data['classification.name'],
            this.classification_role_list,
            'name',
            'id'
          );
        }
        if (filter_data['lead_holder_name.full_name']) {
          filter_data['lead_holder_name.full_name'].values = convertTextToID(
            filter_data['lead_holder_name.full_name'],
            this.dropdown_leads_users_list,
            'name',
            'id'
          );
        }
        if (filter_data['people.full_name']) {
          filter_data['people.full_name'].values = convertTextToID(
            filter_data['people.full_name'],
            this.dropdown_people_list,
            'people',
            'id'
          );
        }
        if (filter_data['organisation_name.organisation_name']) {
          filter_data['organisation_name.organisation_name'].values = convertTextToID(
            filter_data['organisation_name.organisation_name'],
            this.dropdown_global_org,
            'organisation_name',
            'id'
          );
        }
        if (filter_data['lead_status_id.status_name']) {
          filter_data['lead_status_id.status_name'].values = convertTextToID(
            filter_data['lead_status_id.status_name'],
            this.allChildStagesList,
            'status_name',
            'id'
          );
        }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        this.setFilter(params.api);
        let merged = { ...payload, ...this.search_payload, ...this.extraFilter };

        this.getList(merged).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('block_editassign_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
              this.agGrid.columnApi.applyColumnState({
                state: JSON.parse(columnConfig),
              });
            }
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  //set Deal Status Update API
  assignDeal = (lead_holder_id, formdata) => {
    return Axios.post(
      `management/block-edit-assign/lead-assign-holder/${lead_holder_id}`,
      formdata
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  //set Deal Status Update API
  deleteDeal = (payload) => {
    return Axios.post(`management/block-edit-assign/delete`, payload)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  getReassignUserList = () => {
    return Axios.get(`leads-status/user-list`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getLeadsUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`).then(({ data }) => {
      this.dropdown_leads_users_list = data.data;

      return data;
    });
  };

  // call api to get all parent organisations list
  getMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      this.dropdown_global_org = data.data;
      return data;
    });
  };

  getPeopleList = () => {
    return Axios.get(`global/people/dropdown/list`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => { });
      }
      this.dropdown_people_list = data.data;

      return data;
    });
  };
  // USED FOR PROMOTION AND UNUSED LEADS FILTER DROPDOWN
  getAllChildStatusList = () => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      let childArr = [];
      if (data.data && data.data.length > 0) {
        data.data.forEach((x) => {
          if (x.child_leads) {
            x.child_leads.forEach((y) => {
              if (y) {
                childArr.push(y);
              }
            });
          }
        });
      }
      this.allChildStagesList = childArr;

      return childArr;
    });
  };

  getClassificationList = () => {
    return Axios.post(`industry/list`).then(({ data }) => {
      this.classification_role_list = data.data;
      return data;
    });
  };

  getLeadStatusList = (type = {}) => {
    return Axios.post(`leads-status/status/list`, { lead_record_type: type }).then(
      ({ data }) => {
        this.dropdown_leadstatus_list = data.data;

        return data;
      }
    );
  };

  // reset all the server side filters :: DEFAULT - R
  // handleReset = () => {
  //   localStorage.removeItem('block_editassign_grid');

  //   this.extraFilter = null;
  //   this.selectedGridFilter = null;

  //   let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
  //   localStorage.setItem(
  //     'savedFilters',
  //     JSON.stringify({
  //       ...tempsavedFilters,
  //       BLOCKEDITASSIGNSTORE: this.DEFAULT_GRID_FILTER_ID,
  //     })
  //   );

  //   let user_name_fullname = localStorage.getItem('user_name_fullname');
  //   let filter = {
  //     'lead_owner_name.full_name': { values: [user_name_fullname], filterType: 'set' },
  //   };
  //   this.agGrid.api.setFilterModel(filter);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  handleReset = () => {
    this.extraFilter = null;
    reset(this.agGrid, "block_editassign_grid", this.setSelectedGridFilter);
  }

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  applyFilterDefault = () => {
    this.extraFilter = null;
    clear(this.agGrid)
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;

    let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
    localStorage.setItem(
      'savedFilters',
      JSON.stringify({
        ...tempsavedFilters,
        BLOCKEDITASSIGNSTORE: data,
      })
    );
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          BLOCKEDITASSIGNSTORE: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  /** DEAFULT - R */
  // applyFilterDefault = () => {
  //   this.selectedGridFilter = null;
  //   this.extraFilter = null;
  //   if (this.agGrid) {
  //     // localStorage.removeItem('block_editassign_grid');
  //     this.agGrid.api.setFilterModel(null);
  //     this.agGrid.api.setSortModel(null);
  //     // this.agGrid.columnApi.resetColumnState();
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };
}

decorate(BlockEditAssignStore, {
  list_data: observable,
  per_page: observable,
  current_page: observable,
  total: observable,
  agGrid: observable,

  dropdown_leads_users_list: observable,
  dropdown_global_org: observable,
  dropdown_people_list: observable,
  allChildStagesList: observable,
  classification_role_list: observable,
  dropdown_leadstatus_list: observable,
  extraFilter: observable,
  search_payload: observable,

  selectedGridFilter: observable,
  DEFAULT_GRID_FILTER_ID: observable,

  setPageSize: action,
  setupGrid: action,
  getFilter: action,
  setFilter: action,
  getList: action,
  onGridChanged: action,
  createDatasource: action,
  assignDeal: action,
  deleteDeal: action,
  getReassignUserList: action,

  getLeadsUsersList: action,
  getMappedOrg: action,
  getPeopleList: action,
  getAllChildStatusList: action,
  getClassificationList: action,
  getLeadStatusList: action,
  handleReset: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  applyFilterDefault: action,
});
