import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Spin, Select } from 'antd';

import debounce from 'lodash/debounce';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import useStore from '../../../store';
import InputComponent from '../../../component/InputComponent';
//import ApplyGridFilter from '../../../component/ApplyGridFilter';
import ApplyGridFilterNew from '../../../component/ApplyGridFilterNew';
import EnterFullscreen from '../../../component/EnterFullscreen';
import RecordPerPage from '../../../config/RecordPerPage';
import { filterFiltersByPageType } from '../../../utils/GlobalFunction';
import ResetGridOptions from '../../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';

const TopSelection = observer(() => {
  const { t } = useTranslation();
  const [fetchLeadstatusList, setFetchLeadstatusList] = useState(true);
  const [fetchClassification, setFetchClassification] = useState(true);
  const [fetchOrgList, setFetchOrgList] = useState(true);

  const {
    AUTH: { allPageFilters, allPageFiltersLoaded },
    BLOCKEDITASSIGNSTORE,
    BLOCKEDITASSIGNSTORE: {
      getLeadStatusList,
      dropdown_leadstatus_list,
      classification_role_list,
      getClassificationList,
      selectedGridFilter,
      per_page,
      setPageSize,
    },
    LEADSTATUS: { recordTypeList, getRecordType },
    ORGANISATION: { dropdown_global_org, getMappedOrg },
  } = useStore();

  const [form] = Form.useForm();
  const { Option, OptGroup } = Select;

  const handleSubmit = (data) => {
    let dateRangeArr = {};
    if (data.deadline_date) {
      dateRangeArr = {
        from_date: moment(data.deadline_date[0]).format('YYYY-MM-DD'),
        to_date: moment(data.deadline_date[1]).format('YYYY-MM-DD'),
      };
      data.deadline_date = dateRangeArr;
    }

    delete data['RecordPerPage'];

    BLOCKEDITASSIGNSTORE.extraFilter = data;
    if (BLOCKEDITASSIGNSTORE.agGrid) {
      BLOCKEDITASSIGNSTORE.setupGrid(BLOCKEDITASSIGNSTORE.agGrid);
    }
  };

  const handleDealStatus = (e) => {
    form.resetFields(['lead_status_id']);
    getLeadStatusList(e).then(({ data }) => {
      setFetchLeadstatusList(false);
    });
  };
  const onChange = debounce(() => {
    form.submit();
  }, 500);

  useEffect(() => { }, [allPageFilters, allPageFiltersLoaded]);

  const pageFilterType = 'blockeditassign';

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        className='innerForm'
        onFinish={handleSubmit}
        id='blockEditAssignTopfilter'
      >
        <div className='topSelection'>
          <InputComponent
            name='lead_record_type'
            label=''
            placeholder={`${t('Block_Edit_Assign.SOLD_Type')}`}
            type='select'
            onChange={(e) => {
              handleDealStatus(e);
              onChange();
            }}
            allowClear
            showArrow
            showSearch
            options={{
              values: recordTypeList,
              value_key: 'record_type_name',
              text_key: 'record_type_name',
            }}
            onFocus={() =>
              fetchLeadstatusList &&
              getRecordType().then(() => setFetchLeadstatusList(false))
            }
            notFoundContent={
              fetchLeadstatusList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
          />

          <Form.Item>
            <Form.Item name='lead_status_id' noStyle>
              <Select
                className='w100'
                placeholder={`${t('Common_Data.SOLD_Stage')}`}
                onChange={() => {
                  onChange();
                }}
                mode='multiple'
                allowClear
                showArrow
                showSearch
                onFocus={() =>
                  fetchLeadstatusList &&
                  getLeadStatusList(form.getFieldValue('lead_record_type')).then(() =>
                    setFetchLeadstatusList(false)
                  )
                }
                notFoundContent={
                  fetchLeadstatusList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              >
                {dropdown_leadstatus_list && dropdown_leadstatus_list.length
                  ? dropdown_leadstatus_list.map((parent_status) => (
                    <OptGroup key={parent_status.id} label={parent_status.status_name}>
                      {parent_status.child_leads
                        .filter((item) => item.status_name !== 'Unused')
                        .map((child_status) => (
                          <Option value={child_status.id} key={child_status.id}>
                            {child_status.status_name}
                          </Option>
                        ))}
                    </OptGroup>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Form.Item>

          <InputComponent
            name='classification_id'
            placeholder={`${t('Block_Edit_Assign.Classification')}`}
            type='select'
            showArrow
            allowClear
            onChange={onChange}
            options={{
              values: classification_role_list,
              value_key: 'id',
              text_key: 'name',
            }}
            onFocus={() =>
              fetchClassification &&
              getClassificationList().then(() => setFetchClassification(false))
            }
            notFoundContent={
              fetchClassification ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
          />

          <InputComponent
            name='organisation_name'
            placeholder={`${t('Block_Edit_Assign.Organisation')}`}
            type='select'
            allowClear
            onChange={onChange}
            onFocus={() =>
              fetchOrgList && getMappedOrg().then(() => setFetchOrgList(false))
            }
            notFoundContent={fetchOrgList ? <Spin size='small' /> : null}
            options={{
              values: dropdown_global_org,
              value_key: 'id',
              text_key: 'organisation_name',
            }}
          />

          <div className='dateRangeSelection'>
            <InputComponent onChange={onChange} name='deadline_date'
              placeholder={[`${t('DealDesk.Start_Date')}`, `${t('DealDesk.End_Date')}`]}
              type='date_range' />
          </div>
          {/* <ApplyGridFilter type='blockeditassign' selectionValue={selectedGridFilter} /> */}
          <ApplyGridFilterNew
            type={pageFilterType}
            selectionValue={selectedGridFilter}
            filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
          />

          {/* <div className='smallBtn'>
            <Button
              key='1'
              title={`${t('Block_Edit_Assign.Reset')}`}
              shape='round'
              size='medium'
              onClick={() => resetAllFilter()}
            >
              <FontAwesomeIcon icon={faUndo} />
            </Button>
          </div> */}

          <ResetGridOptions key={'1'} pageType={pageFilterType} form={form} />

          <div className='smallBtn'>
            <EnterFullscreen key='2' />
          </div>
          <RecordPerPage
            key='3'
            initialValue={per_page + 'per page'}
            onChange={setPageSize}
            className='RecordPerPage'
          />
        </div>
      </Form>
    </>
  );
});

export default TopSelection;
