import { Menu } from 'antd';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Popover, Modal, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import useStore from '../store';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { default as EditProfileComponent } from '../page/users/component/EditProfileComponent';
import { default as ResetPasswordComponent } from '../page/users/component/ResetPasswordComponent';
//import GetThemedImage from '../utils/GetThemedImage';
import { vsmNotify } from '../config/messages';
import { useTranslation } from 'react-i18next';
// import moment from 'moment';

const TopleftComponent = observer((props) => {
  let themecolor = localStorage.getItem('app_color_theme');
  let themefont = localStorage.getItem('app_font_size');

  if (themefont && themefont !== 'undefined') {
    if (themefont && themefont === 'small') {
      document.documentElement.style.setProperty('--fontSize', '56%');
    } else if (themefont && themefont === 'large') {
      document.documentElement.style.setProperty('--fontSize', '70%');
    } else {
      document.documentElement.style.setProperty('--fontSize', '62.5%');
    }
  } else {
    themefont = 'medium';
    document.documentElement.style.setProperty('--fontSize', '62.5%');
  }

  if (themecolor && themecolor !== 'undefined') {
    if (themecolor && themecolor === 'blue') {
      document.documentElement.style.setProperty('--primary', '#96a6bf');
      document.documentElement.style.setProperty('--primary_rgb', '150,166,191');
      document.documentElement.style.setProperty('--dark', '#96a6bf');
      document.documentElement.style.setProperty('--lite', '#dee5ef');
      document.documentElement.style.setProperty('--textLite', '#777777');
      document.documentElement.style.setProperty('--textDark', '#28324b');
      document.documentElement.style.setProperty('--inputBorder', '#dee7fa');
      document.documentElement.style.setProperty('--seleteBG', '#e9effb');
      document.documentElement.style.setProperty('--primaryComment', '#c6d5eb');
      document.documentElement.style.setProperty('--primaryConversation', '#d2e2fb');
    } else if (themecolor && themecolor === 'pink') {
      document.documentElement.style.setProperty('--primary', '#bb9baf');
      document.documentElement.style.setProperty('--primary_rgb', '187,155,175');
      document.documentElement.style.setProperty('--dark', '#A87D98');
      document.documentElement.style.setProperty('--lite', '#ffdff3');
      document.documentElement.style.setProperty('--textLite', '#777777');
      document.documentElement.style.setProperty('--textDark', '#5f2d48');
      document.documentElement.style.setProperty('--inputBorder', '#efd8e6');
      document.documentElement.style.setProperty('--seleteBG', '#faebf4');
      document.documentElement.style.setProperty('--primaryComment', '#E0C3D5');
      document.documentElement.style.setProperty('--primaryConversation', '#efcce2');
    } else if (themecolor && themecolor === 'green') {
      document.documentElement.style.setProperty('--primary', '#A0C793');
      document.documentElement.style.setProperty('--primary_rgb', '160,199,147');
      document.documentElement.style.setProperty('--dark', '#4D803B');
      document.documentElement.style.setProperty('--lite', '#dbf7d2');
      document.documentElement.style.setProperty('--textLite', '#777777');
      document.documentElement.style.setProperty('--textDark', '#475B40');
      document.documentElement.style.setProperty('--inputBorder', '#D1E5CA');
      document.documentElement.style.setProperty('--seleteBG', '#F4FFF0');
      document.documentElement.style.setProperty('--primaryComment', '#b9e1ad');
      document.documentElement.style.setProperty('--primaryConversation', '#CFE7C7');
    } else if (themecolor && themecolor === 'yellow') {
      document.documentElement.style.setProperty('--primary', '#DDB95C');
      document.documentElement.style.setProperty('--primary_rgb', '221,185,92');
      document.documentElement.style.setProperty('--dark', '#DDB95C');
      document.documentElement.style.setProperty('--lite', '#f5eec7');
      document.documentElement.style.setProperty('--textLite', '#777777');
      document.documentElement.style.setProperty('--textDark', '#8B7506');
      document.documentElement.style.setProperty('--inputBorder', '#EDEEA7');
      document.documentElement.style.setProperty('--seleteBG', '#FFFDEF');
      document.documentElement.style.setProperty('--primaryComment', '#EEDE8A');
      document.documentElement.style.setProperty('--primaryConversation', '#f7ecab');
    } else {
      document.documentElement.style.setProperty('--primary', '#a5a5a5');
      document.documentElement.style.setProperty('--primary_rgb', '165,165,165');
      document.documentElement.style.setProperty('--dark', '#b4b4b4');
      document.documentElement.style.setProperty('--lite', '#e5e5e5');
      document.documentElement.style.setProperty('--textLite', '#777777');
      document.documentElement.style.setProperty('--textDark', '#484848');
      document.documentElement.style.setProperty('--inputBorder', '#dddddd');
      document.documentElement.style.setProperty('--seleteBG', '#f8f8f8');
      document.documentElement.style.setProperty('--primaryComment', '#cecece');
      document.documentElement.style.setProperty('--primaryConversation', '#dddddd');
    }
  } else {
    themecolor = 'gray';
  }
  const { t } = useTranslation();
  const { AUTH, USERS, USERGROUP, TimeTrackerStore } = useStore();
  const history = useHistory();
  const [logoutconfirm, setLogoutconfirm] = useState(false);
  const [dateTime, setDateTime] = useState(new Date());
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [openRestPassModal, setOpenRestPassModal] = useState(false);
  // const [userList, setUserList] = useState();
  // const [mainMenuText, setMainMenuText] = useState('Dashboard');
  // const [mainMenuIcon, setMainMenuIcon] = useState('Dashboard_icn');
  // const [mainMenuLink, setMainMenuLink] = useState('dashboard');
  const [ManagementMenuText, setManagementMenuText] = useState(t('Common_Data.Block_Edit_Assign'));
  const location = useLocation();
  const handleClickChangeTheme = (color) => {
    AUTH.updateUIsettings(color, themefont).then(() => {
      props.forceUpdate();
    });
  };
  const handleClickChangeFont = (size) => {
    AUTH.updateUIsettings(themecolor, size);
  };

  //Open reset pass modal
  const resetPassModal = () => {
    USERS.setResetPassword(AUTH.user);
    setOpenRestPassModal(true);
  };
  const closeResetPassModal = () => {
    USERS.setResetPassword(null);
    setOpenRestPassModal(false);
  };

  // Open form for edit profile User and set values to form
  const openProfileEditModal = () => {
    TimeTrackerStore.setStop(true);
    USERS.setEditProfileValues(AUTH.user.id);
    setEditProfileModal(true);
  };

  // Open form for edit User
  const closeProfileEditModal = () => {
    TimeTrackerStore.setStop(false);
    setEditProfileModal(false)
  };

  const menu = () => {
    return (
      <Menu>
        <Menu.Item
          key='1'
        // onClick={() => {
        //   setMainMenuText('Dashboard');
        //   setMainMenuIcon('Dashboard_icn');
        //   setMainMenuLink('dashboard');
        // }}
        >
          <Link to='/dashboard' title={t('DashBoard.Overview')}>
            <img
              className='menuicon'
              alt='Dashboard'
              src={AUTH.GetThemedImage('Dashboard_icn')}
            />
            {t('DashBoard.Overview')}
          </Link>
        </Menu.Item>
        <Menu.Item
          key='2'
          // onClick={() => {
          //   setMainMenuText('Promotion');
          //   setMainMenuIcon('Promotion');
          //   setMainMenuLink('promotion');
          // }}
          disabled={!AUTH.checkPermission(2, 'list')}
        >
          <Link to='/promotion' title={t('Reports.Promotion')} >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Promotion')} />
            {t('Reports.Promotion')}
          </Link>
        </Menu.Item>
        <Menu.Item
          key='3'
          // onClick={() => {
          //   setMainMenuText('Productivity');
          //   setMainMenuIcon('productivity');
          //   setMainMenuLink('productivity');
          // }}
          title={t('Productivity.Productivity')}
          disabled={!AUTH.checkPermission(3, 'list')}
        >
          <Link to='/productivity'>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('productivity')} />
            {t('Productivity.Productivity')}
          </Link>
        </Menu.Item>
        <Menu.Item
          key='4'
          // onClick={() => {
          //   setMainMenuText('Management');
          //   setMainMenuIcon('Management');
          //   setMainMenuLink('management/block-edit-assign');
          // }}
          title={t('PeopleManagement.Management')}
          disabled={
            AUTH.checkPermission(5, 'list') || AUTH.checkPermission(6, 'list')
              ? false
              : true
          }
        >
          <Link
            to='/management/block-edit-assign'
            onClick={() => setManagementMenuText(t('Common_Data.Block_Edit_Assign'))}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Management')} />
            {t('PeopleManagement.Management')}
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  // call logout function
  const Logout = () => {
    localStorage.setItem('doProxyLogIn', false);
    setLogoutconfirm(false);
    AUTH.doLogout();
    history.replace('/');
  };
  // call logout function
  const cancelLogout = () => {
    setLogoutconfirm(false);
  };

  // call confirm logout function
  const confirmLogout = () => {
    setLogoutconfirm(true);
  };

  useEffect(() => {
    // USERGROUP.getList().then((data) => {
    //   setUserList(data.data);
    // });
    const id = setInterval(() => setDateTime(new Date()), 1000);
    return () => {
      clearInterval(id);
    };
  }, [USERGROUP]);

  const countrymenu = (
    <ul className='popover-menu'>
      {AUTH.global_country_list.map((country, index) => (
        <li
          key={index}
          className={country.id === AUTH.timezone ? 'active' : ''}
          onClick={() => {
            AUTH.updateCountryTimezone(country.id).then((data) => {
              vsmNotify.success({
                message: data.message,
              });
            });
          }}
        >
          {country.country_name}
        </li>
      ))}
    </ul>
  );

  const profilecontent = (
    <ul className='popover-menu'>
      <li className='cursor' onClick={() => openProfileEditModal()} title={t('Common_Data.My_Profile')}>
        <img className='menuicon' alt='' src={AUTH.GetThemedImage('Profile')} /> {t('Common_Data.My_Profile')}
      </li>
      <li className='theme cursor'>
        {t('Common_Data.Theme')}:
        <span
          title={'Blue'}
          className={themecolor === 'blue' ? 'blue active' : 'blue'}
          onClick={() => handleClickChangeTheme('blue')}
        ></span>
        <span
          title={'Pink'}
          className={themecolor === 'pink' ? 'pink active' : 'pink'}
          onClick={() => handleClickChangeTheme('pink')}
        ></span>
        <span
          title={'Grey'}
          className={themecolor === 'gray' ? 'gray active' : 'gray'}
          onClick={() => handleClickChangeTheme('gray')}
        ></span>
        <span
          title={'Yellow'}
          className={themecolor === 'yellow' ? 'yellow active' : 'yellow'}
          onClick={() => handleClickChangeTheme('yellow')}
        ></span>
        <span
          title={'Green'}
          className={themecolor === 'green' ? 'green active' : 'green'}
          onClick={() => handleClickChangeTheme('green')}
        ></span>
      </li>
      <li className='size cursor'>
        {t('Common_Data.Font_Size')}:
        <span
          title={'Small'}
          className={themefont === 'small' ? 'small size active' : 'small size'}
          onClick={() => handleClickChangeFont('small')}
        >
          A
        </span>
        <span
          title={'Medium'}
          className={themefont === 'medium' ? 'medium size active' : 'medium size'}
          onClick={() => handleClickChangeFont('medium')}
        >
          A
        </span>
        <span
          title={'Large'}
          className={themefont === 'large' ? 'large size active' : 'large size'}
          onClick={() => handleClickChangeFont('large')}
        >
          A
        </span>
      </li>
      <li className='cursor' onClick={confirmLogout} title={t('Common_Data.Logout')}>
        <img className='menuicon' alt='' src={AUTH.GetThemedImage('Logout')} />
        {localStorage.getItem('previous_token') ? t('Common_Data.Back_To_ADMIN') : t('Common_Data.Logout')}
      </li>
    </ul>
  );
  const managementMenu = (
    <ul className='popover-menu'>
      <li>
        <Link
          title={t('Common_Data.Block_Edit_Assign')}
          to='/management/block-edit-assign'
          onClick={() => {
            if (AUTH.checkPermission(5, 'list')) {
              setManagementMenuText(t('Common_Data.Block_Edit_Assign'));
            }
          }}
        >
          {t('Common_Data.Block_Edit_Assign')}
        </Link>
      </li>
      <li>
        <Link
          title={t('Common_Data.All_Departments')}
          to='/management/all-departments'
          onClick={() => {
            if (AUTH.checkPermission(6, 'list')) {
              setManagementMenuText(t('Common_Data.All_Departments'));
            }
          }}
        >
          {t('Common_Data.All_Departments')}
        </Link>
        {/* <ul className='bullet'>
          {userList &&
            userList.map((item, index) => (
              <li key={index}>
                <Link
                  to='/management/all-departments'
                  onClick={() => setManagementMenuText('All Departments')}
                >
                  {item.group_name}
                </Link>
              </li>
            ))}
        </ul> */}
      </li>
    </ul>
  );
  let mainMenuIcon = 'Dashboard_icn';
  let mainMenuLink = 'dashboard';
  let mainMenuText = t('DashBoard.Overview');
  if (/promotion/.test(location.pathname)) {
    mainMenuIcon = 'Promotion';
    mainMenuLink = 'promotion';
    mainMenuText = t('Reports.Promotion');
  } else if (/productivity/.test(location.pathname)) {
    mainMenuIcon = 'productivity';
    mainMenuLink = 'productivity';
    mainMenuText = t('Productivity.Productivity');
  } else if (/(\/management\/(.*))/.test(location.pathname)) {
    mainMenuIcon = 'Management';
    mainMenuLink = 'management/block-edit-assign';
    mainMenuText = t('PeopleManagement.Management');
  } else if (/(\/dealdesk\/(.*))/.test(location.pathname)) {
    mainMenuText = 'Deal Desk';
    mainMenuIcon = 'Deal_icn';
    mainMenuLink = '';
  }

  let fullusername = `${AUTH.user.first_name} ${AUTH.user.last_name}`;

  let timeWithSecond = AUTH.global_timeformat === "hh:mm a" ? `${AUTH.global_dateformat + " hh:mm:ss a"}` : `${AUTH.global_dateformat + " HH:mm:ss"}`
  return (
    <>
      {props.collapsed ? (
        <Button>
          <img className='menuicon' alt='' src={AUTH.GetThemedImage(mainMenuIcon)} />
        </Button>
      ) : (
        <Button>
          {mainMenuLink ? (
            <Link to={`/${mainMenuLink}`} title={mainMenuText}>
              <img className='menuicon' alt='' src={AUTH.GetThemedImage(mainMenuIcon)} />
              {mainMenuText}
            </Link>
          ) : (
            <>
              <img className='menuicon' alt='' src={AUTH.GetThemedImage(mainMenuIcon)} />
              {mainMenuText}
            </>
          )}

          <Dropdown
            overlayClassName='dropDownMenuTop'
            overlay={menu}
            trigger='hover'
            placement='bottomRight'
          >
            <DownOutlined />
          </Dropdown>
        </Button>
      )}
      {props.collapsed ? (
        <Button>
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('Date_Time_icn')} />
        </Button>
      ) : (
        <Button>
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('Date_Time_icn')} />
          {/* {`${dateTime.toLocaleDateString('fr-CA')} ${dateTime.toLocaleTimeString()}`} */}
          {`${AUTH.getLocalTime(dateTime, timeWithSecond)}`}
          <Popover
            overlayClassName='timeMenu'
            placement='bottomRight'
            content={countrymenu}
            trigger='hover'
          >
            <DownOutlined />
          </Popover>
        </Button>
      )}
      {/(\/management\/(.*))/.test(location.pathname) ? (
        <>
          {props.collapsed ? (
            <Button>
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('blockedit')} />
            </Button>
          ) : (
            <Button title={ManagementMenuText}>
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('blockedit')} />
              {ManagementMenuText}
              <Popover
                overlayClassName='profileMenu'
                placement='bottomRight'
                content={managementMenu}
                trigger='hover'
              >
                <DownOutlined />
              </Popover>
            </Button>
          )}
        </>
      ) : (
        <Popover
          overlayClassName='profileMenu'
          content={profilecontent}
          trigger='hover'
          placement='bottomRight'
        //visible={profilevisible}
        >
          {props.collapsed ? (
            <Button>
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Profile')} />
            </Button>
          ) : (
            <Button>
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Profile')} />
              <Tooltip title={fullusername}>
                {fullusername.length > 25 ? fullusername.substring(0, 25) : fullusername}
              </Tooltip>
              <DownOutlined />
            </Button>
          )}
        </Popover>
      )}
      <Modal
        title={t('Common_Data.Logout_Confirmation')}
        visible={logoutconfirm}
        onOk={Logout}
        onCancel={cancelLogout}
        okText='Yes'
        cancelText='Cancel'
        footer={[
          <Button
            key='1'
            //disabled={disabled}
            //form='editform'
            //loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='medium'
            onClick={() => {
              Logout();
            }}
          >
            {t('Common_Data.Yes')}
          </Button>,
          <Button
            key='2'
            onClick={() => {
              cancelLogout();
            }}
            shape='round'
            size='medium'
          >
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
      >
        <p>{t('Common_Data.Logout_Text')}?</p>
      </Modal>
      <EditProfileComponent
        visible={editProfileModal}
        close={closeProfileEditModal}
        resetPassModal={resetPassModal}
        myProfile={true}
        fromSideBar
      />
      <ResetPasswordComponent visible={openRestPassModal} close={closeResetPassModal} />
    </>
  );
});

export default TopleftComponent;
