import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';

import ParentOrgRowElements from './ParentOrgRowElements';
import NaceCategoryElement from './NaceCategoryElement';
import PersonElements from './PersonElements';
import OrganisationElements from './OrganisationElements';
import TimesTrackElement from './TimesTrackElement';
import useStore from '../../../../store';
import debounce from 'lodash/debounce';
import { Col, Spin } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const OrgformElements = observer(({ handleChange, formDataList, form }) => {
  const [searchOrgKey, setSearchOrgKey] = useState();
  const [fetchClassification, setFetchClassification] = useState(true);
  const { t } = useTranslation();
  const {
    ORGANISATION,
    REPORTSTORE: { getClassificationList, classification_role_list },
  } = useStore();

  const handleOrgSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchOrgKey(val);
      ORGANISATION.getAllROrgNameListForReport({ search_for: val, mapping_type: 1 });
    };

    return debounce(loadOptions, 500);
  }, [ORGANISATION]);

  return (
    <>
      {formDataList.includes('organisation_name') && (
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.Organisation')}/s`}
            name='organisation_name'
            placeholder={`${t('PeopleManagement.All')}`}
            type='select'
            mode='multiple'
            showSearch
            allowClear
            showArrow
            onChange={handleChange}
            onSearch={handleOrgSearch}
            notFoundContent={
              ORGANISATION.fetchingReportName ? (
                <Spin size='small' />
              ) : searchOrgKey ? (
                `${t('Common_Data.No_Record_Found')}.`
              ) : null
            }
            options={{
              values: ORGANISATION.dropdown_ROrg_name_report,
              value_key: 'id',
              text_key: 'entity_name',
            }}
            tooltip={`${t('Reports.Organisation_Tooltip')}.`}
          />
        </Col>
      )}

      {formDataList.includes('classification_id') && (
        <Col span={12}>
          <InputComponent
            name='classification_id'
            placeholder={`${t('PeopleManagement.All')}`}
            label={
              <span
                className='converbillLink'
                onClick={() => {
                  window.open(
                    'https://www.convertibill.com/credebtclassifications-popup.html',
                    '_blank',
                    'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                  );
                }}
              >
                {t('Credebt_Classification.Credebt')}®{t('Credebt_Classification.Classification')}
              </span>
            }
            type='select'
            mode='multiple'
            allowClear
            showArrow
            showSearch
            onChange={handleChange}
            options={{
              values: classification_role_list,
              value_key: 'id',
              text_key: 'name',
            }}
            onFocus={() =>
              fetchClassification &&
              getClassificationList().then(() => setFetchClassification(false))
            }
            notFoundContent={
              fetchClassification ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            tooltip={`${t('Common_Data.Classification_Group_Tooltip')}.`}
            rules={vsmTransactionReport.validation.classification_id}
          />
        </Col>
      )}

      <ParentOrgRowElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <NaceCategoryElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <OrganisationElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <PersonElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <TimesTrackElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
    </>
  );
});

export default OrgformElements;
