import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const PageNotFound = (props) => {
  const {t} = useTranslation()
  const { SearchPage } = props;
  let history = useHistory();
  return (
    <Result
      status='404'
      title='404'
      subTitle={
        SearchPage
          ? `${t('Login.No_Result_Found')}.`
          : `${t('Login.Page_Not_Exist')}.`
      }
      extra={
        <Button
          type='primary'
          onClick={() => {
            history.goBack();
          }}
        >
          {t('Common_Data.GO')} {t('Common_Data.Back')}
        </Button>
      }
    />
  );
};

export default PageNotFound;
