import React, { useCallback, useEffect } from 'react';
import ScheduleHeader from './ScheduleHeader';
import ScheduleContent from './ScheduleContent';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { arrayMoveImmutable } from 'array-move';
import {
  calculateHeight,
  isElementOutViewport,
} from '../../../../../utils/GlobalFunction';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const ScheduleComponent = ({
  scheduleName,
  form,
  openViewDrawer,
  SelectedDate,
  DateType,
  SetDate,
  INITIAL_HEIGHT,
  SetDateType,
  scheduleList,
  setscheduleList,
  handleFormChange,
  forceUpdate,
  autoSaveData = () => {},
  saveObj,
  is_checked,
  setis_checked,
  isFromLibrary = false,
}) => {
  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }
  const ADD = require(`../../../../../assets/images/${themecolor}/add.svg`).default;
  //------------------------------------------------ Scroll To New Node ---------------------------------------------------------//

  const scrollToNode = debounce((id) => {
    localStorage.removeItem('last_focusedElementSchedule');
    let newElementID = 0;

    if (id) {
      let schedule_content = document.getElementById(id);
      let test = schedule_content.querySelector('.fields_draggable_container');
      if (test && test.lastElementChild.id) {
        newElementID = test.lastElementChild.id;
      }
    } else {
      let test = document.getElementById('field_draggable_container_schedule');

      if (test) {
        newElementID = test.lastElementChild?.id;
      }
    }
    if (newElementID && document.getElementById(newElementID)) {
      if (document.getElementById(newElementID).classList) {
        document.getElementById(newElementID).classList.add('blink-BGanimation');
        if (newElementID) {
          document.querySelector('#' + newElementID + ' .data textarea').focus();
          let splitArr = newElementID.split('schedule_innerContent_');
          if (splitArr) {
            let focusedID = splitArr
              ? newElementID.split('schedule_innerContent_')[1]
              : '';
            if (focusedID) {
              localStorage.setItem('last_focusedElementSchedule', focusedID);
            }
          }
        }
      }

      if (!isElementOutViewport(document.getElementById(newElementID))) {
        document.getElementById(newElementID).scrollIntoView();
      }
      setTimeout(() => {
        if (
          document.getElementById(newElementID) &&
          document.getElementById(newElementID).classList
        ) {
          document.getElementById(newElementID).classList.remove('blink-BGanimation');
        }
      }, 1000);
    }
  }, 500);

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------ Add Sibling Item  ----------------------------------------------------------//

  const addNodeByIndex = () => {
    let lasFocused = localStorage.getItem('last_focusedElementSchedule');
    let item_key = lasFocused;
    if (item_key) {
      let finalItemKey;
      let splitArr = item_key.split('-');

      if (splitArr.length === 1) {
        finalItemKey = 0;
      } else {
        if (splitArr.length > 0) {
          splitArr.splice(splitArr.length - 1, 1);
        }
        if (splitArr.length === 1) {
          finalItemKey = Number(splitArr[0]);
        } else {
          finalItemKey = splitArr.join('-');
        }
      }
      let scrollID = finalItemKey === 0 ? 0 : `schedule_innerContent_${finalItemKey}`;

      AddNode(finalItemKey);
      scrollToNode(scrollID);
      autoSaveData();
    }
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------- Add New Node  -------------------------------------------------------------//

  const AddNode = (ItemKey) => {
    let finalData = scheduleList;
    let position = 1;
    let detail = '';
    let item_key = null;
    let day = '';
    let height = '18px';

    const add = (NodeArray, ItemKey) => {
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          if (!x.children) {
            x.children = [];
          } else {
            if (x.children.length > 0) {
              position = parseInt(x.children[x.children.length - 1].position) + 1;
            } else {
              position = 1;
            }
          }

          item_key = [x.item_key, x.children.length].join('-');
          let start_dateFromParent = x.start_date;
          x.day = 0;
          day = 0;

          detail = '';
          x.children.push({
            item_key,
            position,
            detail,
            height,
            day,
            start_date: start_dateFromParent,
          });
        } else {
          if (x.children && x.children.length > 0) {
            add(x.children, ItemKey);
          }
        }
      });
    };
    if (ItemKey) {
      add(finalData, ItemKey);
    } else {
      if (finalData.length > 0) {
        position = parseInt(finalData[finalData.length - 1].position) + 1;
        item_key = finalData.length + 1;
        // setItemKey(prevval => prevval + 1);
        day = 0;
      } else {
        position = 1;
        item_key = 1;
        day = 0;
      }
      detail = '';
      finalData.push({ item_key, position, detail, height, day });
    }

    //-----------------------START: TO REAGRANGE POSITION-----------------//
    let startingPosition = 0;
    finalData &&
      finalData.forEach((obj, index) => {
        obj.item_key = index + 1;
        if (index === 0) {
          startingPosition = obj.position;
        } else {
          obj.position = startingPosition + index;
        }
      });
    //-----------------------END: TO REAGRANGE POSITION-----------------//

    setscheduleList(finalData);
    GenerateDate(SelectedDate, is_checked, finalData);
    handleFormChange();
    forceUpdate();
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------- Remove Node(Also update item key based on Index)  -------------------------------------------//

  const RemoveNode = (ItemKey) => {
    let finalData = scheduleList;

    let startPosition = 0;

    const remove = (NodeArray, ItemKey, IsChild) => {
      NodeArray.forEach((x, index) => {
        if (index === 0 && !IsChild) {
          startPosition = x.position;
        }
        if (x.item_key === ItemKey) {
          if (IsChild) {
            NodeArray.splice(index, 1);
          } else {
            NodeArray.splice(index, 1);
          }
        } else {
          if (x.children && x.children.length > 0) {
            remove(x.children, ItemKey, true);
          }
        }
      });
    };
    remove(finalData, ItemKey);

    //-----------------------START: TO REAGRANGE POSITION-----------------//

    finalData &&
      finalData.forEach((obj, index) => {
        obj.item_key = index + 1;
        if (index === 0) {
          obj.position = startPosition;
        } else {
          obj.position = startPosition + index;
        }
        obj.height = calculateHeight(
          `schedule_innerContent_textarea_${obj.item_key}`,
          INITIAL_HEIGHT,
          obj.detail,
          true
        );

        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((obj1, index1) => {
            obj1.item_key = [obj.item_key, index1].join('-');
            obj1.position = index1 + 1;

            obj1.height = calculateHeight(
              `schedule_innerContent_textarea_${obj1.item_key}`,
              INITIAL_HEIGHT,
              obj1.detail,
              true
            );
            if (obj1.children && obj1.children.length > 0) {
              obj1.children.forEach((obj2, index2) => {
                obj2.item_key = [obj1.item_key, index2].join('-');
                obj2.position = index2 + 1;

                obj2.height = calculateHeight(
                  `schedule_innerContent_textarea_${obj2.item_key}`,
                  INITIAL_HEIGHT,
                  obj2.detail,
                  true
                );
              });
            } else if (obj1.day === 0 && obj1.children?.length === 0) {
              obj1.day = 0;
            }
          });
        } else if (obj.day === 0 && obj.children?.length === 0) {
          obj.day = 0;
        }
      });
    //-----------------------END: TO REAGRANGE POSITION-----------------//

    setscheduleList(finalData);
    GenerateDate(SelectedDate, is_checked, finalData);
    handleFormChange();

    forceUpdate();
    if (saveObj) {
      let newObj = saveObj;
      newObj.description = finalData;
      autoSaveData(newObj);
    } else {
      autoSaveData();
    }
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //----------------------------------------- Change Node(Update value based on key)  -------------------------------------------//

  const ChangeNode = (ItemKey, Value, key) => {
    let finalData = scheduleList;

    const change = (NodeArray, ItemKey, childLoop = false) => {
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          x[key] = Value;
        } else {
          if (x.children && x.children.length > 0) {
            change(x.children, ItemKey, true);
          }
        }
      });
    };
    change(finalData, ItemKey);

    setscheduleList(finalData);
  };

  const resetPosition = (value) => {
    value = Number(value);
    let finalData = scheduleList;

    finalData.forEach((obj, index) => {
      if (index === 0) {
        obj.position = value;
      } else {
        obj.position = value + index;
      }
    });

    setscheduleList(finalData);
    forceUpdate();
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------ Exchange Node(Drag and Drop based on NewIndex,OldIndex)  -------------------------------------//

  const ExchangeNode = (ItemKey, { oldIndex, newIndex }) => {
    let finalData = scheduleList;

    const loop = (NodeArray, ItemKey, Parent) => {
      let initialArr = NodeArray;
      let starting_position = 1;
      if (initialArr) {
        starting_position = parseInt(initialArr[0].position);
      }
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          if (Parent) {
            Parent.children = arrayMoveImmutable(NodeArray, oldIndex, newIndex);
            Parent.children.forEach((child, child_index) => {
              let sequenceItemKey = child.item_key.split('-');
              if (sequenceItemKey.length === 2) {
                child.item_key = sequenceItemKey[0] + '-' + child_index;
              }
              if (sequenceItemKey.length === 3) {
                child.item_key =
                  sequenceItemKey[0] + '-' + sequenceItemKey[1] + '-' + child_index;
              }
              if (child_index === 0) {
                if (starting_position) {
                  child.position = starting_position;
                }
              } else {
                child.position = parseInt(Parent.children[child_index - 1].position) + 1;
              }
            });
          } else {
            finalData = arrayMoveImmutable(NodeArray, oldIndex, newIndex);
            finalData.forEach((element, index) => {
              element.item_key = index + 1;
              if (index === 0) {
                if (starting_position) {
                  element.position = starting_position;
                }
              } else {
                element.position = parseInt(finalData[index - 1].position) + 1;
              }

              element.children &&
                element.children.length > 0 &&
                element.children.forEach((child, child_index) => {
                  let sequenceItemKey = child.item_key.split('-');
                  if (sequenceItemKey.length === 2) {
                    child.item_key = element.item_key + '-' + child_index;
                  }
                  if (sequenceItemKey.length === 3) {
                    child.item_key =
                      element.item_key + '-' + sequenceItemKey[1] + '-' + child_index;
                  }
                  // child.position = child_index + 1;

                  child.children &&
                    child.children.length > 0 &&
                    child.children.forEach((x, i) => {
                      x.item_key = element.item_key + '-' + child_index + '-' + i;
                      // x.position = i + 1;
                    });
                });
            });
          }
        } else {
          if (x.children && x.children.length > 0) {
            loop(x.children, ItemKey, x);
          }
        }
      });
    };
    loop(finalData, ItemKey);
    setscheduleList(finalData);
    GenerateDate(SelectedDate, is_checked, finalData);
    forceUpdate();

    if (saveObj) {
      let newObj = saveObj;
      newObj.description = finalData;
      newObj.date = SelectedDate;
      newObj.is_checked = is_checked;
      autoSaveData(newObj);
    } else {
      autoSaveData();
    }
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------- Generate Date  ------------------------------------------------------------//

  const deadlineDate = useCallback(
    (date, task_list) => {
      let decrease_rate = 0;
      let totalDays = 0;
      task_list.map((obj, index) => {
        totalDays += obj.day;
        if (obj.children?.length > 0) {
          obj.children.map((obj1, index1) => {
            totalDays += obj1.day;
            if (obj1.children?.length > 0) {
              obj1.children.map((obj2, index2) => {
                totalDays += obj2.day;
                return null;
              });
            }
            return null;
          });
        }
        return null;
      });
      const decreaseDate = (NodeArray) => {
        if (NodeArray && NodeArray.length > 0) {
          for (let i = NodeArray.length - 1; i >= 0; i--) {
            let count = 0;
            let temp_date = new Date(date);
            if (totalDays > 0) {
              temp_date = moment(temp_date).add(1, 'days');
            }
            NodeArray[i].start_date = moment(temp_date);
            decrease_rate -= parseInt(NodeArray[i].day);
            while (count > decrease_rate) {
              temp_date = moment(temp_date).add(-1, 'days');
              if (temp_date.format('ddd') === 'Sun') {
                temp_date = moment(temp_date).add(-2, 'days');
              }
              if (temp_date.format('ddd') === 'Sat') {
                temp_date = moment(temp_date).add(-1, 'days');
              }
              count--;
            }
            NodeArray[i].start_date = moment(new Date(temp_date));
            if (moment(new Date(date)) < NodeArray[i].start_date) {
              NodeArray[i].start_date = moment(new Date(date));
            }
            if (NodeArray[i].children && NodeArray[i].children.length > 0) {
              decreaseDate(NodeArray[i].children);
            }
          }
        }
      };
      decreaseDate(task_list);
      setscheduleList(task_list);
    },
    [setscheduleList]
  );

  const GenerateDate = useCallback(
    (date, type, newData = null) => {
      let task_list;
      if (newData) {
        task_list = newData;
      } else {
        task_list = scheduleList;
      }
      let increase_rate = 0;
      if (type) {
        deadlineDate(date, task_list);
      } else {
        if (task_list && task_list.length > 0) {
          for (let i = 0; i < task_list.length; i++) {
            let count = 0;
            let temp_date = new Date(date);

            if (task_list[i].children && task_list[i].children.length) {
              for (let child = 0; child < task_list[i].children.length; child++) {
                if (
                  task_list[i].children[child].children &&
                  task_list[i].children[child].children.length
                ) {
                  for (
                    let innerChild = 0;
                    innerChild < task_list[i].children[child].children.length;
                    innerChild++
                  ) {
                    task_list[i].children[child].children[innerChild].start_date =
                      moment(temp_date);
                    while (count < increase_rate) {
                      temp_date = moment(temp_date).add(1, 'days');

                      if (temp_date.format('ddd') === 'Sun') {
                        temp_date = moment(temp_date).add(1, 'days');
                      }
                      if (temp_date.format('ddd') === 'Sat') {
                        temp_date = moment(temp_date).add(2, 'days');
                      }
                      count++;
                    }
                    task_list[i].children[child].children[innerChild].start_date = moment(
                      new Date(temp_date)
                    );
                    increase_rate += parseInt(
                      task_list[i].children[child].children[innerChild].day
                    );
                  }
                }
                task_list[i].children[child].start_date = moment(temp_date);
                while (count < increase_rate) {
                  temp_date = moment(temp_date).add(1, 'days');

                  if (temp_date.format('ddd') === 'Sun') {
                    temp_date = moment(temp_date).add(1, 'days');
                  }
                  if (temp_date.format('ddd') === 'Sat') {
                    temp_date = moment(temp_date).add(2, 'days');
                  }
                  count++;
                }
                task_list[i].children[child].start_date = moment(new Date(temp_date));
                increase_rate += parseInt(task_list[i].children[child].day);
              }
            }
            task_list[i].start_date = moment(temp_date);
            while (count < increase_rate) {
              temp_date = moment(temp_date).add(1, 'days');
              if (temp_date.format('ddd') === 'Sun') {
                temp_date = moment(temp_date).add(1, 'days');
              }
              if (temp_date.format('ddd') === 'Sat') {
                temp_date = moment(temp_date).add(2, 'days');
              }
              count++;
            }
            task_list[i].start_date = moment(new Date(temp_date));

            increase_rate += parseInt(task_list[i].day);
          }

          setscheduleList(task_list);
        }
      }
      forceUpdate();
    },

    [scheduleList, setscheduleList, deadlineDate, forceUpdate]
  );

  //-----------------------------------------------------------------------------------------------------------------------------//

  //-------------------------------------------- UseEffect (Generate Date)  -----------------------------------------------------//

  useEffect(() => {
    GenerateDate(SelectedDate, is_checked);
  }, [GenerateDate, SelectedDate, is_checked]);

  const {t} =useTranslation();
  //-----------------------------------------------------------------------------------------------------------------------------//

  return (
    <div>
      {/* Schedule Header(Deadline/View Drawer) */}

      <ScheduleHeader
        AddNode={AddNode}
        scrollToNode={scrollToNode}
        is_checked={is_checked}
        setis_checked={setis_checked}
        DateType={DateType}
        saveObj={saveObj}
        autoSaveData={autoSaveData}
        SetDateType={SetDateType}
        SelectedDate={SelectedDate}
        SetDate={SetDate}
        handleFormChange={handleFormChange}
        openViewDrawer={openViewDrawer}
        scheduleList={scheduleList}
      />

      {/* ------------------------------------ */}

      <div id='schedule_list' className='content'>
        <table width={'100%'} className='box-RightScheduleList'>
          <tr>
            <th width={'4%'}>&nbsp;</th>
            <th width={'10%'} className='numTD' align='center'>
              #
            </th>
            <th width={'6%'} className='dayTD' align='center'>
             {t('DealDesk.Days')}
            </th>
            <th style={{ width: 'calc(80% - 145px)' }} className='dataTD data'>
              {t('Common_Data.Description')}
            </th>
            <th width={'90px'} className='date dateTD' align='center'>
              {t('DealDesk.Start_Date')}
            </th>
            <th width={'55px'} className='actionTD'>
              &nbsp;
            </th>
          </tr>
        </table>
        <ScheduleContent
          AddNode={AddNode}
          scrollToNode={scrollToNode}
          scheduleName={scheduleName}
          DateType={DateType}
          is_checked={is_checked}
          SelectedDate={SelectedDate}
          INITIAL_HEIGHT={INITIAL_HEIGHT}
          form={form}
          resetPosition={resetPosition}
          RemoveNode={RemoveNode}
          ChangeNode={ChangeNode}
          editable={true}
          saveType={'schedule'}
          isFromLibrary={isFromLibrary}
          autoSaveData={autoSaveData}
          setscheduleList={setscheduleList}
          scheduleList={scheduleList}
          GenerateDate={GenerateDate}
          handleFormChange={handleFormChange}
          ExchangeNode={ExchangeNode}
        />
      </div>

      {/* Add Sibling Item(Only Visible when scroll appears) */}

      {document.getElementById('schedule_list')?.scrollHeight >
      document.getElementById('schedule_list')?.offsetHeight ? (
        <Tooltip title={`${t('DealDesk.Sibling_Item')}`}>
          <Button
            className={`float-button-addNode ${isFromLibrary ? 'libraryBtn' : ''}`}
            key='2'
            shape='round'
            size='medium'
            onClick={() => addNodeByIndex()}
            icon={<img className='menuicon' alt='' src={ADD} />}
          />
        </Tooltip>
      ) : null}

      {/* -------------------------------------------------- */}
    </div>
  );
};

export default ScheduleComponent;
