import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer } from 'antd';
import useStore from '../../../../store';
import PreviewDetailTableElement from '../elements/PreviewDetailTableElement';
import { useTranslation } from 'react-i18next';

const PreviewDetailComponent = observer((props) => {
  const { t } = useTranslation();
  const { SavedReportStore } = useStore();
  useEffect(() => {
    var payload = SavedReportStore.formatFormData({
      ...SavedReportStore.previewReportValues,
      ...props.payload,
      action: 'child_data'
    });
    SavedReportStore.PreviewDetailPayload = { ...payload, ...props.payload };
  }, [SavedReportStore, props.payload]);
  return (
    <Drawer
      onClose={props.close}
      title={props.title}
      destroyOnClose={true}
      visible={props.visible}
      onCancel={props.close}
      width={'1050px'}
    >
      <div className='d-flex justify-content-end mb-20' key='1'>
        <Button
          key='1'
          type={SavedReportStore.ZeroRecord ? 'default' : 'primary'}
          onClick={() => {
            SavedReportStore.ZeroRecord = !SavedReportStore.ZeroRecord;
            SavedReportStore.setupPreviewDetailGrid(SavedReportStore.agPreviewDetailGrid);
          }}
        >
          {t('Common_Data.View')} {t('Reports.Non_Zero_Posting')}
        </Button>
      </div>
      <PreviewDetailTableElement
        PreviewDetailPinnedRow={SavedReportStore.PreviewDetailPinnedRow}
        onGridReady={SavedReportStore.setupPreviewDetailGrid}
      />
    </Drawer>
  );
});

export default PreviewDetailComponent;
