import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Checkbox } from 'antd';
import FormComponent from './FormComponent';
import { vsmDealDesk, vsmNotify } from '../../../../../config/messages';
import useStore from '../../../../../store';
import moment from 'moment';
import {
  calculateHeight,
  trimExtraCharacters,
} from '../../../../../utils/GlobalFunction';
import debounce from 'lodash/debounce';
import ViewListing from './ViewListing';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [viewDrawer, setViewDrawer] = useState(false); //# USED TO OPEN COPY FROM LIBRARY DRAWER

  const [form] = Form.useForm();
  const INITIAL_HEIGHT = '18px';
  //-----------  For Deadline date Switch is on or not --------------//
  const [is_checked, setis_checked] = useState(false);
  const [is_template, setIsTemplate] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  //-----------------------------------------------------------------//

  const {
    AUTH,
    SSSLEADLIBRARYSTORE: { fetchLibraryByType, addSssData },
    DEALDESKSCHEDULELIBRARY: {
      editValues,
      SelectedDate,
      SetDate,
      setScheduleData,
      ScheduleData,
      SetDateType,
      setEditValues,
      calculateHeightOnEdit,
      setCopiedScheduleData,
    },
  } = useStore();
  const [isDisabled, setDisabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const close = () => {
    setEditValues(null);
    form.resetFields();
    setDisabled(false);
    setis_checked(false);
    setCopiedScheduleData(null);
    setScheduleData([]);
    props.close();
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.is_library = true;
    data.type = 2;
    data.description = ScheduleData;
    data.date = moment(SelectedDate).format('YYYY-MM-DD');
    data.is_start_date = is_checked;
    data.leads_transactions_id = null;
    data.is_template = is_template;
    //  To make new id/clone it only when it is changed to template 
    if (!editValues?.is_template && is_template) {
      data.id = null;
    } else {
      data.id = editValues?.id;
    }
    let leadId = editValues?.leads_transactions_id ? editValues?.leads_transactions_id : 0;

    addSssData(leadId, data)
      .then((data) => {
        fetchLibraryByType("schedule")
        close();
        vsmNotify.success({
          message: vsmDealDesk.schedule.saveSuccess,
        });
        setIsSaveModal(false);
      })
      .catch((e) => {
        if (e.errors) {
          let schedule_description_error = 0;
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (e === 'description') {
                  schedule_description_error = 1;
                }
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });

          if (schedule_description_error) {
            vsmNotify.error({
              message: `${t('DealDesk.Schedule_Description')}!`,
            });
          } else {
            form.setFields(e.errors);
          }
        }
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (props.visible && editValues) {
      // Character Length Limit from Setting
      editValues.description &&
        editValues.description.forEach((element) => {
          delete element.start_date;
        });
      editValues.description &&
        editValues.description.forEach((element) => {
          delete element.start_date;
          if (element.children && element.children.length > 0) {
            element.children.forEach((obj1, index) => {
              delete obj1.start_date;
              if (obj1.children && obj1.children.length > 0) {
                obj1.children.forEach((obj2, index) => {
                  delete obj2.start_date;
                });
              }
            });
          }
        });

      let lengthLimit = 170;
      if (AUTH?.user?.bonus_figures) {
        if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
          lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
        }
      }

      //--------------------------------------- Calculate height based on content ------------------------------//

      let schedule_data = editValues.description || [];
      calculateHeightOnEdit(editValues.description).then(
        debounce(() => {
          if (schedule_data?.length > 0) {
            setDisabled(false);
          }
          schedule_data &&
            schedule_data.forEach((obj, index) => {
              obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

              obj.height = calculateHeight(
                `schedule_innerContent_textarea_${obj.item_key}`,
                INITIAL_HEIGHT,
                obj.detail,
                true
              );

              if (obj.children && obj.children.length > 0) {
                obj.children.forEach((obj1, index) => {
                  obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                  obj1.height = calculateHeight(
                    `schedule_innerContent_textarea_${obj1.item_key}`,
                    INITIAL_HEIGHT,
                    obj1.detail,
                    true
                  );
                  if (obj1.children && obj1.children.length > 0) {
                    obj1.children.forEach((obj2, index) => {
                      obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                      obj2.height = calculateHeight(
                        `schedule_innerContent_textarea_${obj2.item_key}`,
                        INITIAL_HEIGHT,
                        obj2.detail,
                        true
                      );
                    });
                  }
                });
              }
            });
          form.setFieldsValue({
            title: editValues?.title,
            classification_id: editValues?.classification_id,
            description_text: editValues?.description_text,
          });
        }, 200)
      );
      setScheduleData(schedule_data);
      //--------------------------------------------------------------------------------------------------------//
      setIsTemplate(editValues?.is_template);
      setis_checked(editValues?.is_start_date);
      SetDate(new Date(editValues?.date));
      SetDateType(false);
    }
  }, [
    editValues,
    setScheduleData,
    props.visible,
    calculateHeightOnEdit,
    AUTH?.user,
    form,
    SetDate,
    SetDateType,
    setIsTemplate
  ]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        if (ScheduleData?.length > 0) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  //-----------START: LIBRARY COPY---------------//
  const openViewDrawer = (data) => {
    setViewDrawer(true);
  };
  const closeViewDrawer = () => setViewDrawer(false);
  //-----------END: LIBRARY COPY---------------//

  return editValues ? (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('DealDesk.Edit_Schedule_Title')} - #${editValues?.id} - ${editValues?.title}`}
      destroyOnClose={true}
      footer={[
        <>
          <div className='DealLibrary-ScheduleFormFooter'>
            <div className='mr-10'>
              <Checkbox
                checked={is_template}
                onChange={(e) => setIsTemplate(e.target.checked)}
              // disabled={editValues?.is_template}
              >
                {t('DealDesk.Save_Template')}
              </Checkbox>
              {/* <label className='mr-10'>Save as Template</label> */}
            </div>
            <div>
              <Button
                key='1'
                // form='editform'
                className='mr-10'
                // loading={saving}
                // htmlType='submit'
                type='primary'
                disabled={isDisabled}
                shape='round'
                size='medium'
                onClick={() => setIsSaveModal(true)}
              >
                {t('Common_Data.Update')}
              </Button>
              <Button shape='round' size='medium' onClick={close} key='2'>
                {t('Common_Data.Cancel')}
              </Button>
            </div>
          </div>
        </>,
      ]}
    >
      <FormComponent
        form={form}
        forceUpdate={forceUpdate}
        setScheduleData={setScheduleData}
        ScheduleData={ScheduleData}
        onChange={handleChange}
        setis_checked={setis_checked}
        INITIAL_HEIGHT={INITIAL_HEIGHT}
        is_checked={is_checked}
        handleSubmit={handleSubmit}
        id='editform'
        openViewDrawer={openViewDrawer}
        isSaveModal={isSaveModal}
        setIsSaveModal={setIsSaveModal}
        loading={saving}
        isTemplate={is_template}
        setIsTemplate={setIsTemplate}
      />

      {viewDrawer && <ViewListing visible={viewDrawer} close={closeViewDrawer} />}
    </Drawer>
  ) : null;
});

export default EditComponent;
