import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { ActionRenderer } from './GridActions';
import { vsmCommon } from '../../../config/messages';
import LocalGridConfig from '../../../config/LocalGridConfig';

import moment from 'moment';
import { Tooltip } from 'antd';
import { CurrencyFormat, DateComparator } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
import { GridLoader } from '../../../utils/GridLoader';
// import { contains } from 'jquery';

const MasterTicketSearchListing = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    TicketsStore,
    SEARCHSTORE,
    SEARCHSTORE: { setResultCount, searchCount },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();
  const {
    openDeleteDrawer,
    openRestoreModal,
    openEditDrawer,
    openCloneDrawer,
    openViewDrawer,
    TagTicketModule,
    WasteManagement,
    SearchPage,
    AllView,
    // onCellKeyDown,
    showCheckbox = false,
    selectionChanged,
  } = props;

  useEffect(() => {
    let childTicketList = localStorage.getItem('get_child_tickets');
    let masterTicketList = localStorage.getItem('get_master_ticket')
    if (WasteManagement) {
      TicketsStore.waste = true;
      TicketsStore.search_payload = {};
      TicketsStore.setIsSavedFiltersLoadedTicket(true);
    } else if (SearchPage) {
      TicketsStore.waste = false;
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.master_ticket_count;
      TicketsStore.setIsSavedFiltersLoadedTicket(true);
      TicketsStore.isSearchPage = true;
      // let temp = JSON.parse(localStorage.getItem('params'));
      // localStorage.setItem('params',JSON.stringify({...temp,MasterTicketSearchStore: {},}));
      // alert("hi")
    } else {
      TicketsStore.waste = false;
      TicketsStore.search_payload = {};
    }
    if (childTicketList) {
      TicketsStore.setChildTicketList(childTicketList);
      TicketsStore.setSelectedGridFilter(null);
      localStorage.removeItem('ticket_grid');
      let temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem('params', JSON.stringify({ ...temp, TicketsStore: {}, }));
    }
    if (masterTicketList) {
      TicketsStore.setMasterTicketNumber(masterTicketList);
      TicketsStore.setSelectedGridFilter(null);
      let temp = JSON.parse(localStorage.getItem('params'));
      localStorage.removeItem('ticket_grid');
      localStorage.setItem('params', JSON.stringify({ ...temp, TicketsStore: {}, }));
    }
  }, [TicketsStore, SEARCHSTORE, SearchPage, WasteManagement, TicketsStore.total]);

  useEffect(() => {
    setResultCount(searchCount?.master_ticket_count);
    TicketsStore.getMasterListSearch(TicketsStore.search_payload);
  }, [TicketsStore, setResultCount, searchCount?.master_ticket_count])

  // function getContextMenuItems(params) {
  //   var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
  //   if (params.column.colId === 'actions') {
  //     result = [
  //       {
  //         // custom item
  //         name: `${t('Common_Data.Open_Link_New_Tab')}`,
  //         action: function () {
  //           window.open(`/dealdesk/${params.node.data.leads_transactions_id}`, '_blank');
  //         },
  //       },
  //       {
  //         // custom item
  //         name: `${t('Common_Data.Open_Link_New_Window')}`,
  //         action: function () {
  //           window.open(
  //             `/dealdesk/${params.node.data.leads_transactions_id}`,
  //             "New Window'",
  //             'location=yes,scrollbars=yes,status=yes'
  //           );
  //         },
  //       }, // built in copy item
  //       ...result,
  //     ];
  //   }

  //   return result;
  // }

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        checkboxSelection: showCheckbox,
        valueGetter: function (params) {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        tooltipValueGetter: (params) => {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        // width: 60,
        minWidth: 37,
        width: 37,
        resizable: true,
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,
        field: 'updated_at',
        cellClass: 'cellClass',
        cellRendererFramework: (params) => {
          return (
            <Tooltip
              title={AUTH.getLocalTimeDealDesk(moment(params?.data?.updated_at), 'YYYY-MM-DD HH:mm a')}
            >
              {AUTH.getLocalTimeDealDesk(moment(params?.data?.updated_at), 'YYYY-MM-DD HH:mm a')}
            </Tooltip>
          );
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'reset'],
          comparator: DateComparator,
        },
        //onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,
        field: 'owner',
        tooltipField: 'owner',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            TicketsStore.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
        //onCellDoubleClicked: (event) => openViewDrawer(event?.data)
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`,
        field: 'ticket_number',
        tooltipField: 'ticket_number',
        //cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
        //onCellDoubleClicked: (event) => openViewDrawer(event?.data),

        // lockPosition: true,
        // cellClass: 'locked-col',
        // width: 60,
        // suppressNavigable: true,
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,
        field: 'ticketholder',
        tooltipField: 'ticketholder',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            TicketsStore.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
        //onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Common_Data.Subject')}`,
        headerTooltip: `${t('Common_Data.Subject')}`,
        field: 'ticket_subject',
        tooltipField: 'ticket_subject',
        cellClass: 'cellClass',
        // minWidth: 300,
        filter: 'agTextColumnFilter',
        //onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        field: 'organisation.organisation_name',
        tooltipField: 'organisation.organisation_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            TicketsStore.filter_organisation_list &&
            TicketsStore.filter_organisation_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
        //onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      // sss
      // {
      //   headerName: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`,
      //   headerTooltip: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`,
      //   field: 'master_ticket_number',
      //   cellClass: 'cellClass',
      //   filter: 'agTextColumnFilter',
      //   onCellDoubleClicked: (params) => {
      //     params?.data?.master_ticket && openViewDrawer(params?.data?.master_ticket);
      //   },
      //   tooltipValueGetter: (params) => {
      //     return params?.data?.master_ticket?.master_ticket_number;
      //   },
      //   cellRendererFramework: (params) => {
      //     return params?.data?.master_ticket ? (
      //       <span className='cursor'>
      //         {params?.data?.master_ticket?.master_ticket_number}
      //       </span>
      //     ) : (
      //       ''
      //     );
      //   },
      // },
      // {
      //   headerName: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Subject')}`,
      //   headerTooltip: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Subject')}`,
      //   field: 'master_ticket.ticket_subject',
      //   tooltipField: 'master_ticket.ticket_subject',
      //   cellClass: 'cellClass',
      //   filter: 'agSetColumnFilter',
      //   // minWidth: 300,
      //   valueFormatter: (params) => {
      //     return (
      //       params.value &&
      //       TicketsStore.filter_dropdown_master_ticket &&
      //       TicketsStore.filter_dropdown_master_ticket[params.value]
      //     );
      //   },
      //   filterParams: {
      //     defaultToNothingSelected: true,
      //     buttons: ['apply', 'reset'],
      //     values: (params) => {
      //       TicketsStore.filterMasterTicketList().then((data) => {
      //         params.success([null, ...data.map((x, indexID) => indexID)]);
      //       });
      //     },
      //     valueFormatter: (params) => {
      //       return (
      //         params.value &&
      //         TicketsStore.filter_dropdown_master_ticket &&
      //         TicketsStore.filter_dropdown_master_ticket[params.value]
      //       );
      //     },
      //   },
      //   onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      // },
      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'ticket_description',
        tooltipField: 'ticket_description',
        cellClass: 'cellClass',
        // minWidth: 300,
        filter: 'agTextColumnFilter',
        // onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        headerTooltip: `${t('Common_Data.Status')}`,
        field: 'ticket_status.status_name',
        tooltipField: 'ticket_status.status_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            TicketsStore.dropdown_ticketstatus,
            params,
            'id',
            'status_name'
          ),
        filterParams: {
          defaultToNothingSelected: false,
          buttons: ['apply', 'reset'],
          // values: (params) => {
          //   TicketsStore.getTicketstatusList().then((data) => {
          //     params.success([...TicketsStore.dropdown_ticketstatus.map((x) => x.id)]);
          //   });
          // },
          // valueFormatter: (params) =>
          //   formatterSetColumnFilter(
          //     TicketsStore.dropdown_ticketstatus,
          //     params,
          //     'id',
          //     'status_name'
          //   ),
        },
        // onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Common_Data.Time')}`,
        headerTooltip: `${t('Common_Data.Time')}`,
        field: 'investment_hours',
        filter: false,
        sortable: true,
        tooltipField: 'investment_hours',
        cellClass: 'cellClass text-right',
      },
      {
        // headerName: `${t('Tickets.Age')}(${t('Tickets.Day')}(s))`,
        headerName: `${t('Tickets.Age')}`,
        headerTooltip: `${t('Tickets.Age')}`,
        field: 'age',
        cellRendererFramework: (params) => {
          return <> {CurrencyFormat({ value: params.data.age })}</>;
        },
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'age',
        cellClass: 'cellClass text-center',
      },
      // {
      //   headerName: `${t('Common_Data.Idle')}(${t('Tickets.Day')}(s))`,
      //   headerTooltip: `${t('Common_Data.Idle')}`,
      //   field: 'idle',
      //   cellRendererFramework: (params) => {
      //     return <> {CurrencyFormat({ value: params.data.idle })}</>;
      //   },
      //   filter: 'agNumberColumnFilter',
      //   sortable: true,
      //   tooltipField: 'idle',
      //   cellClass: 'cellClass text-center',
      // },
      // {
      //   headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
      //   headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,
      //   field: 'deadline_date',
      //   // tooltipField: 'deadline_date',
      //   cellClass: 'cellClass',
      //   filter: 'agDateColumnFilter',
      //   cellRendererFramework: (params) => {
      //     return (
      //       <Tooltip
      //         title={
      //           params?.data?.deadline_date === ' ' ||
      //             params?.data?.deadline_date === '' ||
      //             params?.data?.deadline_date === null
      //             ? ''
      //             : AUTH.getLocalTime(params?.data?.deadline_date, 0, 1)
      //         }
      //       >
      //         {params?.data?.deadline_date === ' ' ||
      //           params?.data?.deadline_date === '' ||
      //           params?.data?.deadline_date === null
      //           ? ''
      //           : AUTH.getLocalTime(params?.data?.deadline_date, 0, 1)}
      //       </Tooltip>
      //     );
      //   },
      //   filterParams: {
      //     buttons: ['reset'],
      //     suppressAndOrCondition: true,
      //   },
      //   onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      // },
      // {
      //   headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
      //   headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
      //   field: 'created_by_name',
      //   tooltipField: 'created_by_name',
      //   cellClass: 'cellClass',
      //   filter: 'agSetColumnFilter',
      //   valueFormatter: (params) =>
      //     formatterSetColumnFilter(
      //       TicketsStore.dropdown_leads_users_list,
      //       params,
      //       'id',
      //       'name'
      //     ),
      //   filterParams: {
      //     defaultToNothingSelected: true,
      //     buttons: ['apply', 'reset'],
      //     values: (params) => {
      //       TicketsStore.getLeadsUsersList().then((data) => {
      //         params.success([...data.data.map((x) => x.id)]);
      //       });
      //     },
      //     valueFormatter: (params) =>
      //       formatterSetColumnFilter(
      //         TicketsStore.dropdown_leads_users_list,
      //         params,
      //         'id',
      //         'name'
      //       ),
      //   },
      //   onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      // },
      // {
      //   headerName: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
      //   headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
      //   field: 'created_at',
      //   cellClass: 'cellClass',
      //   filter: 'agDateColumnFilter',
      //   cellRendererFramework: (params) => {
      //     return (
      //       <Tooltip
      //         title={AUTH.getLocalTime(
      //           params?.data?.created_at,
      //           AUTH.global_fulldate_format
      //         )}
      //       >
      //         {AUTH.getLocalTime(params?.data?.created_at, AUTH.global_fulldate_format)}
      //       </Tooltip>
      //     );
      //   },
      //   filterParams: {
      //     buttons: ['reset'],
      //     suppressAndOrCondition: true,
      //   },
      //   onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      // },
      // 
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: 250,
        minWidth: 250,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('search_master_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  GridLoader(TicketsStore);

  return (
    <>
      <div
        className={`ag-theme-alpine grid_wrapper TicketsGrid ${SearchPage ? "paginationAtTop" : ""}`}
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : TagTicketModule
              ? '70vh'
              : SearchPage && !AllView
                ? 'calc(100vh - 13rem)'
                : SearchPage && AllView
                  ? '150px'
                  : 'calc(100vh - 11rem)',
          minHeight: props.AllView ? '100px' : '250px',
        }}
      >
        <AgGridReact
          rowData={TicketsStore.master_list_data}
          modules={AllModules}
          columnDefs={columns}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            // floatingFilter: !SearchPage,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openDeleteDrawer,
            openRestoreModal,
            openEditDrawer,
            openCloneDrawer,
            openViewDrawer,
            TagTicketModule,
            WasteManagement,
          }}
          onGridReady={TicketsStore.setupGridSearchMaster}
          onColumnResized={TicketsStore.onGridChangedSearchMaster}
          onColumnMoved={TicketsStore.onGridChangedSearchMaster}
          onColumnPinned={TicketsStore.onGridChangedSearchMaster}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: SearchPage ? true : !TagTicketModule,
            animateRows: true,
            // getContextMenuItems: getContextMenuItems,
          }}
          rowDragManaged={true}
          // onCellKeyDown={onCellKeyDown}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          // onFilterChanged={(e) => {           
          //   if (
          //     e.hasOwnProperty('afterFloatingFilter') &&
          //     TicketsStore.selectedGridFilter
          //   ) {
          //     TicketsStore.setSelectedGridFilter(null);
          //   }
          // }}
          onSortChanged={(params) => {
            if (localStorage.getItem('params')) {
              var temp = JSON.parse(localStorage.getItem('params'));
              localStorage.setItem('params', JSON.stringify({ ...temp, MasterTicketSearchStore: { sort: params.api.getSortModel() }, }));
            } else {
              localStorage.setItem('params',
                JSON.stringify({ MasterTicketSearchStore: { sort: params.api.getSortModel() }, })
              );
            }
          }}
          onSelectionChanged={selectionChanged}
        />
      </div>
    </>
  );
});
export default MasterTicketSearchListing;
