import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class TickerTapBroadcastStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  agGridRelOrg = null;
  deleteValues = null;
  viewValues = null;
  editValues = null;
  tickerValues = null;
  pTickerValues = null;
  dropdown_pplRole_list = null;
  dropdown_RPpl_list = null;
  fetching = false;

  usergroup_list = null;

  initialValues = {
    phone: [null],
    mobile: [null],
    email: [null],
    adddress: [],
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };
  //set edit values
  setEditValues = (formdata) => {
    return Axios.get(`ticker/read/${formdata.id}`)
      .then(({ data }) => {
        this.editValues = data.data;
        return data;
      })
      .catch(({ response: { data } }) => {
        this.editValues = null;
        return Promise.reject(data);
      });
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  setupGrid = (params) => {
    this.agGrid = params
    let columnConfig = localStorage.getItem('ticker_tap_broadcast_grid')
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
    }
  }

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem('ticker_tap_broadcast_grid', JSON.stringify(params.columnApi.getColumnState()))
  }

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  }

  // reset function
  handleReset = () => {
    this.agGrid.api.setFilterModel(null)
    this.agGrid.api.setSortModel(null)
    this.agGrid.api.onFilterChanged(null)
  }

  getList = (payload = {}) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel()
      var sort = this.agGrid.columnApi.getColumnState()
    }

    return Axios.post(`ticker/list`, payload).then(({ data }) => {

      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.api.setFilterModel(filter)
        this.agGrid.columnApi.applyColumnState({ state: sort })
      }
      let columnConfig = localStorage.getItem('ticker_tap_broadcast_grid')
      if (this.agGrid && this.agGrid.columnApi && columnConfig) {
        this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
      }
      return data;
    });
  };

  getRecordDetail = (formdata, setVariable = true) => {
    return Axios.get(`ticker/read/${formdata.tickerid}`)
      .then(({ data }) => {
        if (setVariable) {
          this.tickerValues = data.data;
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        this.tickerValues = null;
        return Promise.reject(data);
      });
  };

  setTickerValues = (data) => {
    this.tickerValues = data;
  };

  // set related organisation values
  setRTickerValues = (data) => {
    this.rTickerValues = data;
  };

  AddData = (data) => {
    return Axios.post(`ticker/add`, data)
      .then(({ data }) => {
        this.getList();

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditData = (data) => {
    return Axios.post(`ticker/edit/${data.id}`, data)
      .then(({ data }) => {
        this.getList();

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteData = (ticker_id) => {
    return Axios.get(`ticker/delete/${ticker_id}`)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['logo_url', 'date_of_birth'].includes(key)) {
        data[key] && formData.append(key, data[key]);
      }
    });
    return formData;
  };

  getUsergroupList = () => {
    return Axios.get(`usergroup/list`).then(({ data }) => {
      var listdata = data.data;
      //this.usergroup_list = [{ id: 0, group_name: 'All' }, ...listdata];
      this.usergroup_list = listdata;

      return this.usergroup_list;
    });
  };

  updateTickerSetting = (data) => {
    return Axios.post(`ticker/brodcast`, data)
      .then(({ data }) => {
        this.getList();

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  fetchTickerSetting = () => {
    return Axios.get(`ticker/brodcast`).then(({ data }) => {
      return data.data && data.data;
    });
  };
}

decorate(TickerTapBroadcastStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  agGridRelOrg: observable,
  deleteValues: observable,
  tickerValues: observable,
  editValues: observable,
  rTickerValues: observable,
  initialValues: observable,
  dropdown_pplRole_list: observable,
  viewValues: observable,
  usergroup_list: observable,
  setPageSize: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  getRecordDetail: action,
  setTickerValues: action,
  setRTickerValues: action,
  AddData: action,
  EditData: action,
  DeleteData: action,
  JsonToFormData: action,
  getUsergroupList: action,
  updateTickerSetting: action,
  fetchTickerSetting: action,
  setEditValues: action,
  onFilterChanged: action,
  onGridChanged: action,
  handleReset: action
});
