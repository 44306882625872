import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PageHeader, Button, Space, Form, Typography, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

//import TopSelection from './component/TopSelection';
import ListComponent from '../Opportunities/ListComponent';
import EditComponent from './../component/EditComponentNew';
import DeleteComponent from './../component/DeleteComponent';
import CloneComponent from './../component/CloneComponentNew';
import ViewComponent from './../component/ViewComponent';
// import LeadImportComponent from './../component/LeadImportComponent';
import OpportunityImportComponent from './../component/OpportunityImportComponent';
import RecordPerPage from '../../../config/RecordPerPage';
import useStore from '../../../store';
import EnterFullscreen from '../../../component/EnterFullscreen';
import Unauthorized from '../../Unauthorized';
import ApplyGridFilter from '../../../component/ApplyGridFilter';
import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';

const LeadListingOpportunity = observer((props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const { SearchPage, AllView, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;

  const { LEADSSTORE, AUTH, LEADOPPORTUNITY, SETTINGS } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [kanbanRoute, setKanbanRoute] = useState(null);
  // const [importLeadModal, setImportLeadModal] = useState(false);
  const [importOpportunitiesModal, setImportOpportunitiesModal] = useState(false);

  const [editOpen, setEditOpen] = useState(false);

  const [form] = Form.useForm();
  //Edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    LEADSSTORE.setEditValues(data).then(() => {
      setEditDrawer(true);
      setEditOpen(isEditOpen);
    });
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setEditOpen(false);
  };

  //open delet drawer
  const openDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //Clone drawer
  const openCloneDrawer = (data) => {
    LEADSSTORE.setCloneValues(data).then(() => setCloneDrawer(true));
  };
  const closeCloneDrawer = () => setCloneDrawer(false);

  //View drawer
  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(12, 'view-details')) {
      LEADSSTORE.setViewValues(data);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => setViewDrawer(false);
  const openKanbanView = () => history.push(kanbanRoute);

  //Open Drawer to import data
  // const importLeadData = () => setImportLeadModal(true);
  // const closeImportLeadModal = () => {
  //   setImportLeadModal(false);
  // };
  const importOpportunitiesData = () => setImportOpportunitiesModal(true);
  const closeImportOpportunitiesModal = () => {
    setImportOpportunitiesModal(false);
  };

  useEffect(() => {
    if (location.pathname !== LEADSSTORE.current_list_path) {
      LEADSSTORE.current_list_path = location.pathname;
    }
    setKanbanRoute(location.pathname + '/kanban');
  }, [LEADSSTORE, LEADSSTORE.current_list_path, location]);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(12, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }
          if (AUTH.checkPermission(12, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteDrawer(e.data);
            }
          }
        }
      }
    }
  };

  return (
    <>
      {!AUTH.checkPermission(12, 'list') ? (
        <Unauthorized />
      ) :
        // SearchPage ? (
        //   <>
        //     <ListComponent
        //       openViewDrawer={openViewDrawer}
        //       openEditDrawer={openEditDrawer}
        //       openDeleteDrawer={openDeleteDrawer}
        //       openCloneDrawer={openCloneDrawer}
        //       SearchPage={SearchPage}
        //       AllView={AllView}
        //       onCellKeyDown={onCellKeyDown}
        //     />
        //   </>
        // ) : 
        (
          <PageHeader
            title={
              !SearchPage ? `${t('LeadListing.Opportunities')}`
                : (<>
                  <div className='d-flex justify-content-start align-items-center notes'>
                    <Title level={3}>
                      {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                    </Title>
                    <div className='searchView ml-10 '>
                      <span className='sep'>|</span>
                      {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                      {t('Common_Data.Records_found')}
                      <span className='sep'>|</span>
                      <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                        {t('Common_Data.View_all')} {sectorSelector}
                      </span>
                    </div>
                  </div>
                </>)
            }
            className={SearchPage ? 'SearchResult-PageHeader ' + SETTINGS.fullscreen_class : 'page-title ' + SETTINGS.fullscreen_class}
            extra={[
              <Form form={form} className='innerForm headerButton'>
                <Space size={5}>
                  {!SearchPage ? <ApplyGridFilter
                    type='opportunity'
                    selectionValue={LEADOPPORTUNITY.selectedGridFilter}
                  /> : null}
                  {!SearchPage ? AUTH.checkPermission(12, 'change-view') && (
                    <Button
                      className='gridbtn'
                      key='1'
                      shape='round'
                      size='medium'
                      onClick={openKanbanView}
                      icon={
                        <img
                          className='menuicon'
                          alt=''
                          src={AUTH.GetThemedImage('Kanban_View')}
                        />
                      }
                    >
                      {t('LeadListing.Kanban_View')}
                    </Button>
                  ):null}
                  {!SearchPage ? AUTH.checkPermission(12, 'import') && (
                    <Button
                      className='gridbtn'
                      key='2'
                      shape='round'
                      size='medium'
                      onClick={importOpportunitiesData}
                    >
                      {t('LeadListing.Import')}
                    </Button>
                  ):null}
                  {/* {(location.pathname === '/leads' ||
                  location.pathname === '/leads/unused') &&
                  AUTH.checkPermission(12, 'import') && (
                    <Button
                      className='gridbtn'
                      key='3'
                      shape='round'
                      size='medium'
                      onClick={importLeadData}
                    >
                      Import
                    </Button>
                  )} */}
                  {!SearchPage ? AUTH.checkPermission(12, 'export') && (
                    <Button
                      className='gridbtn'
                      key='4'
                      shape='round'
                      size='medium'
                      loading={LEADSSTORE.exportLoadingIcn}
                      onClick={() => LEADSSTORE.exportLeadData('opportunities')}
                    >
                      {t('LeadListing.Export')}
                    </Button>
                  ):null}
                  <Button
                    key='5'
                    title={`${t('Common_Data.Reset')}`}
                    shape='round'
                    size='medium'
                    onClick={LEADOPPORTUNITY.handleReset}
                  >
                    <FontAwesomeIcon icon={faUndo} />
                  </Button>
                  <EnterFullscreen key='6' />
                  <RecordPerPage
                    key='7'
                    initialValue={LEADOPPORTUNITY.per_page + ' per page'}
                    onChange={LEADOPPORTUNITY.setPageSize}
                  />
                </Space>
              </Form>,
            ]}
          >
            <ListComponent
              openViewDrawer={openViewDrawer}
              openEditDrawer={openEditDrawer}
              openDeleteDrawer={openDeleteDrawer}
              openCloneDrawer={openCloneDrawer}
              SearchPage={SearchPage}
              onCellKeyDown={onCellKeyDown}
              AllView={AllView}
            />
            <TrackTimeComponent pageName={SearchPage ? 'search' :'leadlisting'} />
          </PageHeader>
        )}
      <ViewComponent
        visible={viewDrawer}
        openEditDrawer={openEditDrawer}
        close={closeViewDrawer}
      />
      <EditComponent
        visible={editDrawer}
        editOpen={editOpen}
        close={closeEditDrawer}
        SearchPage={SearchPage}
      />
      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        lineText={`${t('Common_Data.Remove_Lead')}?`}
        SearchPage={SearchPage}
      />
      <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
      {/* <LeadImportComponent visible={importLeadModal} close={closeImportLeadModal} /> */}
      <OpportunityImportComponent
        visible={importOpportunitiesModal}
        close={closeImportOpportunitiesModal}
      />
    </>
  );
});

export default LeadListingOpportunity;
