import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Checkbox } from 'antd';
import InputComponent from '../../../../../component/InputComponent';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';
import { vsmLeadsList } from '../../../../../config/messages';

const AddLibaryTitle = observer(
  ({
    visible,
    close,
    libraryTitle,
    setLibraryTitle,
    classificationId,
    setClassificationId,
    parentForm,
    handleChange,
    handleSubmit,
    libraryDescriptionInfo,
    setLibraryDescriptionInfo,
    saveType,
    disabled,
    setIsOverwriteTemplate,
    isOverwriteTemplate,
  }) => {
    const [disabledTitleBtn, setDisabledTitleBtn] = useState(true);
    const { t } = useTranslation();
    const {
      DEALDESKSTORE: {
        editActiveStructureData,
        editActiveScheduleData,
        editActiveScreenData,
        editActiveScrutiniseData,
        deal_data,
      },
      LEADSSTORE,
      DEALDESKNOTESOUTBOUND
    } = useStore();

    useEffect(() => {
      if (saveType === 'screen' && editActiveScreenData) {
        parentForm.setFieldsValue({
          title: editActiveScreenData?.is_library
            ? editActiveScreenData?.title
            : deal_data?.lead_information?.opportunity_name,
          classification_id: editActiveScreenData?.classification_id
            ? editActiveScreenData?.classification_id
            : deal_data?.lead_information?.classification_id,
          description_text: editActiveScreenData?.description_text,
        });
        setLibraryTitle(editActiveScreenData?.title);
        setClassificationId(editActiveScreenData?.classification_id)
        setLibraryDescriptionInfo(editActiveScreenData?.description_text);
        if (editActiveScreenData?.title && editActiveScreenData?.classification_id && editActiveScreenData?.description_text) {
          setDisabledTitleBtn(false);
        }
      }
      if (saveType === 'structure' && editActiveStructureData) {
        parentForm.setFieldsValue({
          title: editActiveStructureData?.is_library
            ? editActiveStructureData?.title
            : deal_data?.lead_information?.opportunity_name,
          classification_id: editActiveStructureData?.classification_id
            ? editActiveStructureData?.classification_id
            : deal_data?.lead_information?.classification_id,
          description_text: editActiveStructureData?.description_text,
        });
        setLibraryTitle(editActiveStructureData?.title);
        setClassificationId(editActiveStructureData?.classification_id)
        setLibraryDescriptionInfo(editActiveStructureData?.description_text);
        if (editActiveStructureData?.title && editActiveScreenData?.classification_id && editActiveStructureData?.description_text) {
          setDisabledTitleBtn(false);
        }
      }
      if (saveType === 'schedule' && editActiveScheduleData) {
        parentForm.setFieldsValue({
          title: editActiveScheduleData?.is_library
            ? editActiveScheduleData?.title
            : deal_data?.lead_information?.opportunity_name,
          classification_id: editActiveScheduleData?.classification_id
            ? editActiveScheduleData?.classification_id
            : deal_data?.lead_information?.classification_id,
          description_text: editActiveScheduleData?.description_text,
        });
        setLibraryTitle(editActiveScheduleData?.title);
        setClassificationId(editActiveScheduleData?.classification_id)
        setLibraryDescriptionInfo(editActiveScheduleData?.description_text);
        if (editActiveScheduleData?.title && editActiveScreenData?.classification_id && editActiveScheduleData?.description_text) {
          setDisabledTitleBtn(false);
        }
      }
      if (saveType === 'scrutinise' && editActiveScrutiniseData) {
        parentForm.setFieldsValue({
          title: editActiveScrutiniseData?.is_library
            ? editActiveScrutiniseData?.title
            : deal_data?.lead_information?.opportunity_name,
          classification_id: editActiveScrutiniseData?.classification_id
            ? editActiveScrutiniseData?.classification_id
            : deal_data?.lead_information?.classification_id,
          description_text: editActiveScrutiniseData?.description_text,
        });
        setLibraryTitle(editActiveScrutiniseData?.title);
        setClassificationId(editActiveScrutiniseData?.classification_id)
        setLibraryDescriptionInfo(editActiveScrutiniseData?.description_text);
        if (editActiveScrutiniseData?.title && editActiveScreenData?.classification_id && editActiveScrutiniseData?.description_text) {
          setDisabledTitleBtn(false);
        }
      }
    }, [
      visible,
      parentForm,
      saveType,
      setLibraryTitle,
      setClassificationId,
      setLibraryDescriptionInfo,
      editActiveScreenData,
      editActiveScheduleData,
      editActiveStructureData,
      editActiveScrutiniseData,
      deal_data,
      setDisabledTitleBtn,
    ]);

    /** To change the credebt classification when over-write-library is un-ticked  */
    useEffect(() => {
      if (!isOverwriteTemplate) {
        parentForm.setFieldsValue({
          classification_id: deal_data?.lead_information?.classification_id,
        })
      }
    }, [isOverwriteTemplate, deal_data, parentForm])

    const onClose = () => {
      close();
      setIsOverwriteTemplate(true);
    };
    const onFinish = () => {
      let data = parentForm.getFieldsValue();
      setLibraryTitle(data.title);
      setClassificationId(data.classification_id)
      setLibraryDescriptionInfo(data.description_text);
      setDisabledTitleBtn(true);
      handleSubmit();
    };

    const handleChangeTitleForm = () => {
      parentForm
        .validateFields()
        .then((data) => {
          setDisabledTitleBtn(false);
        })
        .catch((e) => {
          if (e.errorFields && e.errorFields.length > 0) {
            setDisabledTitleBtn(true);
          } else {
            setDisabledTitleBtn(false);
          }
        });
    };

    return (
      <Modal
        centered
        title={`${t('DealDesk.Library_Name')}`}
        visible={visible}
        destroyOnClose
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Checkbox
            value={isOverwriteTemplate}
            defaultChecked={isOverwriteTemplate}
            onChange={(e) => {
              handleChangeTitleForm();
              setIsOverwriteTemplate(e.target.checked);
            }}
          >
            {t('DealDesk.Overwrite_On_Library')}?
          </Checkbox>,
          <Button
            key='3'
            onClick={() => onFinish()}
            shape='round'
            type='primary'
            //disabled={(parentForm?.getFieldValue("title") && parentForm?.getFieldValue("description_text")) ? false : true}
            disabled={disabledTitleBtn}
          >
            {t('Common_Data.Save')}
          </Button>,
          <Button key='4' shape='round' onClick={() => onClose()}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={500}
      >
        <div className='innerForm'>
          <Row gutter={0}>
            {/** Library Name Input Box */}
            <Col xs={{ span: 24 }}>
              <InputComponent
                type={'labelOnly'}
                tooltip={`${t('DealDesk.Library_Name')}`}
                label={`${t('DealDesk.Library_Name')}`}
                className='m-0'
              />
              <InputComponent
                name={'title'}
                //label={'Library Name'}
                required={visible}
                onChange={handleChangeTitleForm}
                placeholder={`${t('DealDesk.Library_Name')}`}
                autoComplete='off'
                defaultValue={libraryTitle ? libraryTitle : ''}
                maxLength={100}
                rules={
                  visible
                    ? [
                      {
                        required: true,
                        message: `${t('DealDesk.Library_Title_Not_Blank')}.`,
                      },
                      {
                        max: 100,
                        message: `${t('DealDesk.Contain_Maximum')} 100 ${t('DealDesk.Characters')}.`,
                      },
                    ]
                    : [{ required: false, message: '' }]
                }
              />
            </Col>
            {/** Credebt® Classification DropDown */}
            <Col xs={{ span: 24 }}>
              <InputComponent
                type={'labelOnly'}
                label={<span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>}
                tooltip={`${t('LeadListing.Classification_Group_Tooltip')}.`}
                className='m-0'
              />
              <InputComponent
                name='classification_id'
                placeholder={`${t('Common_Data.Select_Credebt_Classification')}`}
                defaultValue={classificationId ? classificationId : ''}
                required={visible}
                type='select'
                allowClear
                showArrow
                showSearch
                onChange={handleChangeTitleForm}
                options={{
                  values:
                  DEALDESKNOTESOUTBOUND.classificationList ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.classifications && [
                        LEADSSTORE.editValues.classifications,
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.classifications && [
                        LEADSSTORE.cloneValues.classifications,
                      ]),
                  value_key: 'id',
                  text_key: 'name',
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
                notFoundContent={'No Record Found.'}
                rules={visible ? vsmLeadsList.validation.classification_id : [{ required: false, message: '' }]}
              />
            </Col>
            {/** Description Input Box */}
            <Col xs={{ span: 24 }}>
              <InputComponent
                type={'labelOnly'}
                label={`${t('Common_Data.Description')}`}
                tooltip={`${t('Common_Data.Description')}`}
                className='m-0'
              />
              <InputComponent
                type={'textarea'}
                name={'description_text'}
                // label={'Description'}
                required={visible}
                placeholder={`${t('Common_Data.Description')}`}
                onChange={handleChangeTitleForm}
                autoComplete='off'
                defaultValue={libraryDescriptionInfo ? libraryDescriptionInfo : ''}
                maxLength={500}
                rules={
                  visible
                    ? [
                      {
                        required: true,
                        message: `${t('DealDesk.Not_Blank')}`,
                      },
                      {
                        max: 500,
                        message: `${t('DealDesk.Description_Contain_Maximum')} 500 ${t('DealDesk.Characters')}.`,
                      },
                    ]
                    : [{ required: false, message: '' }]
                }
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
);

export default AddLibaryTitle;
