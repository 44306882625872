import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';

const TurnoverElement = ({ form, formDataList, handleChange }) => {
  const { t } = useTranslation();

  const handleTurnoverChange = (e, type) => {
    if (type === 'min') {
      if (e < 0) {
        form.setFieldsValue({
          turnover_min: 0
        });
      }
      else if (e > form.getFieldValue('turnover_max')) {
        form.setFieldsValue({
          turnover_min: form.getFieldValue('turnover_max')
        });
      }
    }
    else if (type === 'max') {
      if (e < form.getFieldValue('turnover_min')) {
        form.setFieldsValue({
          turnover_max: form.getFieldValue('turnover_min')
        });
      }
      else if (e > 999999999999999) {
        form.setFieldsValue({
          turnover_max: 999999999999999
        });
      }
    }
  }
  return (
    <>
      {formDataList.includes('turnover_min') && formDataList.includes('turnover_max') && (
        <Col span={8}>
          <Row gutter={12}>
            <Col span={12}>
              <InputComponent
                label={t('Reports.Turnover_Title_Min')}
                name='turnover_min'
                placeholder={t('Reports.Min')}
                onChange={(e) => {
                  handleChange();
                  handleTurnoverChange(e, 'min')
                }}
                tooltip={t('Reports.Turnover_Title_Min')}
                rules={vsmTransactionReport.validation.turnover_min}
                autoComplete='off'
                type={'number'}
                min={0}
                max={
                  999999999999999
                }
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label={t('Reports.Max')}
                name='turnover_max'
                placeholder={t('Reports.Max')}
                onChange={(e) => {
                  handleChange();
                  handleTurnoverChange(e, 'max')
                }}
                tooltip={t('Reports.Turnover_Title_Max')}
                rules={vsmTransactionReport.validation.turnover_max}
                autoComplete='off'
                type={'number'}
                min={0
                }
                max={999999999999999}
              />
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

export default TurnoverElement;
