import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Col, Button, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddressSubElement from './SubElement/AddressSubElement';
import useStore from '../../../../store';

const AddressElement = observer(({ onChange, form }) => {
  const [SelectTypes, SetSelectedTypes] = useState();
  const { AUTH } = useStore();

  return (
    <Form.List name='address'>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => {
              let rejected_types = [];
              SelectTypes &&
                SelectTypes.map((x) => {
                  if (x && x.address_type === 'Primary') {
                    rejected_types.push('Primary');
                  }
                  return null;
                });
              return (
                <div key={field.key} className='innerForm'>
                  <Row gutter={15}>
                    <Col span={24}>
                      <img
                        src={AUTH.GetThemedImage('remove')}
                        onClick={async () => {
                          await remove(field.name);
                          onChange();
                        }}
                        alt='-'
                        className='removeAddressSection'
                      />
                      <AddressSubElement
                        onChange={onChange}
                        field={field}
                        SetSelectedTypes={SetSelectedTypes}
                        rejected_types={rejected_types}
                        form={form}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
            <div className='text-center'>
              <Button
                type='primary'
                onClick={async () => {
                  await add();
                  onChange();
                }}
                icon={<PlusOutlined />}
              >
                Add Address
              </Button>
            </div>
          </>
        );
      }}
    </Form.List>
  );
});

export default AddressElement;
