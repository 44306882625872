import React from 'react';
//import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

export const ActionRenderer = (props) => {
  const {t} = useTranslation();
  const { gotoEditScreen, openDeleteModal, gotoViewScreen } =
    props.agGridReact.props.frameworkComponents;

  const { AUTH } = useStore();

  return (
    <div className='action-column'>
      {AUTH.checkPermission(23, 'view-details') && (
        <Button
          title={`${t('DealBuilder.View_Algorithm')}`}
          type='text'
          onClick={() => {
            gotoViewScreen(props.data.id);
          }}
        >
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
        </Button>
      )}
      {props.data && !props.data.status ? (
        <>
          {AUTH.checkPermission(23, 'edit') && (
            <Button
              title={`${t('DealBuilder.Edit_Algorithm')}`}
              type='text'
              onClick={() => {
                gotoEditScreen(props.data.id);
              }}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
            </Button>
          )}
          {AUTH.checkPermission(23, 'delete') && (
            <Button
              title={`${t('DealBuilder.Delete_Algorithm')}`}
              type='text'
              onClick={() => {
                openDeleteModal(props.data);
              }}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
            </Button>
          )}
        </>
      ) : (
        <>
          {AUTH.checkPermission(23, 'edit') && (
            <Tooltip
              placement='topRight'
              color={'red'}
              title={`${t('DealBuilder.Published_Algorithm')}`}
            >
              <Button type='text' disabled>
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
              </Button>
            </Tooltip>
          )}
          {AUTH.checkPermission(23, 'delete') && (
            <Tooltip
              placement='topRight'
              color={'red'}
              title={`${t('DealBuilder.Delete_Published_Algorithm')}`}              
            >
              <Button type='text' disabled>
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};
