import React from 'react'
import { Switch } from 'antd'
import debounce from 'lodash/debounce'

const SwitchComponent = (props) => {
	return (
		<div>
			<Switch
				disabled={props.values && props.values.last_parse_result === 'Fail'}
				defaultChecked={props.values && props.values.status}
				onChange={debounce((val) => props.onSwitchChange(val, props.values), 300)}
			/>
		</div>
	)
}

export default SwitchComponent
