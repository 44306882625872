import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class SequencerStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  applyLeftmenuFilter = null;

  dropdown_seq_users_list = null;
  dropdown_seq_status_list = null;
  dropdown_seq_type_list = null;

  viewValues = null;
  viewLoader = null;

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.SEQUENCERSTORE) {
        params = { request: temp.SEQUENCERSTORE };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          SEQUENCERSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          SEQUENCERSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('sequencer_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // reset all the server side filters
  handleReset = () => {
    this.applyLeftmenuFilter = null;
    localStorage.removeItem('sequencer_grid');
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.setSortModel(null);
    this.agGrid.api.onFilterChanged(null);
    this.agGrid.columnApi.resetColumnState();
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'sequencer_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
    params.api.redrawRows();
  };

  // call api to get records
  getList = (payload = {}) => {
    let filter;
    filter = this.applyLeftmenuFilter;
    if (this.agGrid) {
      this.agGrid.api.showNoRowsOverlay();
    }

    return Axios.post(`sequencer/active-list`, payload).then(({ data }) => {
      if (data?.data?.list && data?.data?.list.length > 0) {
        data?.data?.list.forEach((x, i) => {
          let seconds = 0;
          if (x.time_spent) {
            let a = x.time_spent.split(':'); // split it at the colons
            if (a.length > 1) {
              seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            }
          }
          x.time_spent_seconds = seconds;
        });
      }

      this.list_data = data?.data?.list;
      this.total = data?.data?.entries;
      this.current_page = data?.data?.current_page;

      if (this.agGrid && this.agGrid.columnApi) {
        let columnConfig = localStorage.getItem('sequencer_grid');
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
          if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
          }
        }

        if (this.agGrid) {
          this.agGrid.api.hideOverlay();
        }

        if (this.applyLeftmenuFilter) {
          this.agGrid.api.setFilterModel(filter);
          this.applyLeftmenuFilter = null;
        }
      }
      return data;
    });
  };

  getUsersList = () => {
    return Axios.get(`sequencer/lov-users`).then(({ data }) => {
      this.dropdown_seq_users_list = data.data;
      return data && data.data;
    });
  };
  getStatusList = () => {
    return Axios.get(`sequencer/lov-status`).then(({ data }) => {
      this.dropdown_seq_status_list = data.data;
      return data && data.data;
    });
  };
  getSequenceTypeList = () => {
    return Axios.get(`sequencer/lov-sequence-type`).then(({ data }) => {
      this.dropdown_seq_type_list = data.data;
      return data && data.data;
    });
  };
  readSequencerDetails = (id) => {
    this.viewLoader = true;
    return Axios.get(`sequencer/details/${id}`).then(({ data }) => {
      data?.data &&
        data.data.length > 0 &&
        data.data.forEach((x, i) => {
          if (x.details?.data?.step_name) {
            if (x.details?.data?.step_name?.step_time) {
              let seconds = 0;
              let a = x.details?.data?.step_name?.step_time.split(':'); // split it at the colons
              if (a.length > 1) {
                seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
              }
              x.details.data.step_name.step_time_seconds = seconds;
            } else {
              x.details.data.step_name.step_time_seconds = 0;
            }
          }
        });

      this.viewValues = data.data;
      this.viewLoader = false;
      return data && data.data;
    });
  };
  setViewValues = (data) => {
    this.viewValues = data;
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    this.setFilter(params.api);
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  downloadFile = (apiUrl) => {
    let payload = {
      token: 'DMFILETOKEN2022',
    };
    var formData = this.JsonToFormData(payload);
    return Axios.post(apiUrl, formData).then(({ data }) => {
      return data;
    });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (![].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ''
        );
      }
    });
    return formData;
  };
}

decorate(SequencerStore, {
  list_data: observable,
  per_page: observable,
  current_page: observable,
  total: observable,
  agGrid: observable,
  dropdown_seq_users_list: observable,
  dropdown_seq_status_list: observable,
  dropdown_seq_type_list: observable,
  viewValues: observable,
  viewLoader: observable,
  applyLeftmenuFilter: observable,

  setupGrid: action,
  getList: action,
  onGridChanged: action,
  handleReset: action,
  getUsersList: action,
  getStatusList: action,
  getSequenceTypeList: action,
  setViewValues: action,
  readSequencerDetails: action,
  onFilterChanged: action,
  downloadFile: action,
});
