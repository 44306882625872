import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, PageHeader } from 'antd';
import useStore from '../../store';
import ListComponent from './component/ListComponent';
import { useHistory } from 'react-router-dom';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import InformationComponent from './component/InformationComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../Unauthorized';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import { useTranslation } from 'react-i18next';

const TickerTapBroadcast = observer(() => {
  let history = useHistory();
  const { t } = useTranslation()
  const { TICKERTAPBROADCAST, AUTH, SETTINGS } = useStore();
  const [addDrawer, setAddDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);

  //open add drawer
  const openAddDrawer = () => {
    setAddDrawer(true);
  };

  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  const openEditDrawer = (data) => {
    TICKERTAPBROADCAST.setEditValues(data);
    setEditDrawer(true);
  };

  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);

  //open delet drawer
  const openDeleteDrawer = (data) => {
    setDeleteDrawer(true);
    TICKERTAPBROADCAST.setDeleteValues(data);
  };

  //close delet drawer
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const openViewDrawer = (data) => {
    history.push(`ticker/add/${data.id}`);
  };

  //confirmation alert before deleting existing user & edit
  const onCellKeyDown = (e) => {
    if (AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (result.key === 'Enter' && AUTH.checkPermission(29, 'edit')) {
            openEditDrawer(e.data);
          }
          if (result.key === 'Delete' && AUTH.checkPermission(29, 'delete')) {
            openDeleteDrawer(e.data);
          }
        }
      }
    }
  };

  return (
    <>
      <section className='main-content' style={{ height: 'auto' }}>
        <InformationComponent />
      </section>

      <section className='main-content mt-20' style={{ height: 'calc(100vh - 390px)' }}>
        {!AUTH.checkPermission(29, 'list') ? (
          <Unauthorized />
        ) : (
          <PageHeader
            title={`${t('TickerTapBroadcast.Ticker')} ${t('TickerTapBroadcast.Information')}`}
            className={'page-title ' + SETTINGS.fullscreen_class}
            extra={[
              AUTH.checkPermission(29, 'add') && (
                <Button key='1' shape='round' onClick={openAddDrawer}>
                  {t('Common_Data.Add')} {t('TickerTapBroadcast.Ticker')} {t('TickerTapBroadcast.Information')}
                </Button>
              ),
              <Button
                key='2'
                shape='round'
                size='medium'
                title={`${t('Common_Data.Reset')}`}
                onClick={TICKERTAPBROADCAST.handleReset}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>,
              <EnterFullscreen key='3' />
            ]}
          >
            <ListComponent
              openDeleteDrawer={openDeleteDrawer}
              openEditDrawer={openEditDrawer}
              openViewDrawer={openViewDrawer}
              onCellKeyDown={onCellKeyDown}
            />
            <AddComponent visible={addDrawer} close={closeAddDrawer} />
            <EditComponent visible={editDrawer} close={closeEditDrawer} />
            <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
          </PageHeader>
        )}
      </section>
      {/* <TrackTimeComponent pageName={'ticker_tap_info'} /> */}
      <TimeTracker />
    </>
  );
});

export default TickerTapBroadcast;
