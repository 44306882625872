import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';
const AutosaveNotifiy = observer((props) => {
  const { t } = useTranslation();
  const {
    SSSLEADLIBRARYSTORE: {
      notifyStructure,
      notifyScreen,
      notifyScrutinise,
      setNotifyScreen,
      setNotifyStructure,
      setNotifyScrutinise,
    },
  } = useStore();

  useEffect(() => {
    if (notifyStructure && props.saveType === 'structure') {
      setTimeout(() => {
        setNotifyStructure(false);
      }, 4000);
    }
    if (notifyScreen && props.saveType === 'screen') {
      setTimeout(() => {
        setNotifyScreen(false);
      }, 4000);
    }
    if (notifyScrutinise && props.saveType === 'scrutinise') {
      setTimeout(() => {
        setNotifyScrutinise(false);
      }, 4000);
    }
  }, [
    props.saveType,
    notifyStructure,
    notifyScreen,
    notifyScrutinise,
    setNotifyStructure,
    setNotifyScreen,
    setNotifyScrutinise,
  ]);

  return (
    <>
      {(notifyScreen && props.saveType === 'screen') ||
        (notifyStructure && props.saveType === 'structure') ||
        (notifyScrutinise && props.saveType === 'scrutinise') ? (
        <span className={'autoSaveNoteNotify'}>{t('DealDesk.Draft_Saved')}</span>
      ) : null}
    </>
  );
});

export default AutosaveNotifiy;
