import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PageHeader, Button, Space, Form, Tooltip, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';

//import TopSelection from './component/TopSelection';
import ListComponent from '../ActiveLeads/ListComponent';
import EditComponent from '../component/EditComponentNew';
import DeleteComponent from '../component/DeleteComponent';
import CloneComponent from '../component/CloneComponentNew';
import ViewComponent from '../component/ViewComponent';
import LeadImportComponent from '../component/LeadImportComponent';
import OpportunityImportComponent from '../component/OpportunityImportComponent';
import RecordPerPage from '../../../config/RecordPerPage';
import useStore from '../../../store';
import EnterFullscreen from '../../../component/EnterFullscreen';
import Unauthorized from '../../Unauthorized';
//import ApplyGridFilter from '../../../component/ApplyGridFilter';
import MergeLead from '../component/MergeLead';
import { vsmNotify } from '../../../config/messages';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { filterFiltersByPageType } from '../../../utils/GlobalFunction';
import ApplyGridFilterNew from '../../../component/ApplyGridFilterNew';
import ResetGridOptions from '../../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const LeadListingActive = observer((props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const { SearchPage, AllView, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;

  const {
    LEADSSTORE,
    AUTH,
    LEADACTIVE,
    SETTINGS,
    REMINDERSSTORE,
    AUTH: { allPageFilters, allPageFiltersLoaded },
  } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [kanbanRoute, setKanbanRoute] = useState(null);
  const [importLeadModal, setImportLeadModal] = useState(false);
  const [importOpportunitiesModal, setImportOpportunitiesModal] = useState(false);

  //-------------------- Merge Sold States ---------------------------------//

  const [mergeSOLDVisible, setmergeSOLDVisible] = useState(false);
  const [showCheckbox, setshowCheckbox] = useState(false);
  const [selectedLeadsMerge, setSelectedLeadsMerge] = useState(null);
  const [selectedRowsCount, setSelectedRowsCount] = useState(false);

  //------------------------------------------------------------------------//
  const [editOpen, setEditOpen] = useState(false);
  const [form] = Form.useForm();

  //Edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    LEADSSTORE.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setEditOpen(false);
  };

  //open delet drawer
  const openDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  /** Call on Clone button to open clone drawer */
  const openCloneDrawer = (data) => {
    LEADSSTORE.setCloneValues(data);
    setCloneDrawer(true);
  };
  const closeCloneDrawer = () => setCloneDrawer(false);

  //View drawer
  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(11, 'view-details')) {
      LEADSSTORE.setViewValues(data);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => setViewDrawer(false);
  const openKanbanView = () => history.push(kanbanRoute);

  //Open Drawer to import data
  const importLeadData = () => setImportLeadModal(true);
  const closeImportLeadModal = () => {
    setImportLeadModal(false);
  };
  const importOpportunitiesData = () => setImportOpportunitiesModal(true);
  const closeImportOpportunitiesModal = () => {
    setImportOpportunitiesModal(false);
  };

  useEffect(() => {
    REMINDERSSTORE.dropdown_user_list = null;
    if (location.pathname !== LEADSSTORE.current_list_path) {
      LEADSSTORE.current_list_path = location.pathname;
    }
    setKanbanRoute(location.pathname + '/kanban');
  }, [
    LEADSSTORE,
    LEADSSTORE.current_list_path,
    location,
    REMINDERSSTORE,
    allPageFiltersLoaded,
    allPageFilters,
  ]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('selected_dealdesk');
    };
  }, []);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(11, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }
          if (AUTH.checkPermission(11, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteDrawer(e.data);
            }
          }
        }
      }
    }
  };

  //START:-----------SOLD Merge Functions---------------------------//

  const toggleMergeLead = () => {
    //setshowCheckbox(!showCheckbox);
    if (showCheckbox) {
      openSOLDMergeModal();
    } else {
      setshowCheckbox(true);
    }
  };

  const openSOLDMergeModal = () => {
    let selectedRow = LEADACTIVE.agGrid.api.getSelectedRows();
    if (selectedRow.length >= 2) {
      setmergeSOLDVisible(true);
      setSelectedLeadsMerge(selectedRow);
    } else {
      vsmNotify.error({
        message: `${t('LeadListing.Please_Select_Records')} 2 ${t('LeadListing.Records')}.`,
      });
    }
  };
  const closeSOLDMergeModal = () => {
    setshowCheckbox(false);
    setmergeSOLDVisible(false);
    setSelectedLeadsMerge(null);
  };

  const selectionChanged = () => {
    let selectedRow = LEADACTIVE.agGrid.api.getSelectedRows();
    setSelectedRowsCount(selectedRow.length > 0 ? selectedRow.length : 0);
  };

  const onClearSelectionClick = () => {
    if (LEADACTIVE.agGrid) {
      LEADACTIVE.agGrid.api.deselectAll();
    }
    setshowCheckbox(false);
  };
  //END:-----------SOLD Merge Functions---------------------------//

  const pageFilterType = 'activeleads';

  return (
    <>
      {!AUTH.checkPermission(11, 'list') ? (
        <Unauthorized />
      ) :
        // SearchPage ? (
        //   <>
        //     <ListComponent
        //       openViewDrawer={openViewDrawer}
        //       openEditDrawer={openEditDrawer}
        //       openDeleteDrawer={openDeleteDrawer}
        //       openCloneDrawer={openCloneDrawer}
        //       SearchPage={SearchPage}
        //       onCellKeyDown={onCellKeyDown}
        //     />
        //   </>
        // ) : 
        (
          <PageHeader
            title={!SearchPage ? `${t('LeadListing.Leads')}`
              : (<>
                <div className='d-flex justify-content-start align-items-center notes'>
                  <Title level={3}>
                    {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                  </Title>
                  <div className='searchView ml-10 '>
                    <span className='sep'>|</span>
                    {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                    {t('Common_Data.Records_found')}
                    <span className='sep'>|</span>
                    <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                      {t('Common_Data.View_all')} {sectorSelector}
                    </span>
                  </div>
                </div>
              </>)
            }
            className={SearchPage ? 'SearchResult-PageHeader ' : '' + SETTINGS.fullscreen_class}
            extra={[
              <Form form={form} className='innerForm headerButton'>
                <Space size={5}>
                  {selectedRowsCount || showCheckbox ? (
                    <Tooltip title={`${t('LeadListing.Click_Clear')}`}>
                      <Button
                        key='1'
                        shape='round'
                        size='medium'
                        onClick={onClearSelectionClick}
                      >
                        <img
                          src={AUTH.GetThemedImage('Refresh_NoSpace')}
                          alt=''
                          className='icon'
                        />
                      </Button>
                    </Tooltip>
                  ) : null}

                  <Tooltip title={showCheckbox ? `${t('LeadListing.Click_Merge')}` : `${t('LeadListing.Start_Merging')}`}>
                    <Button key='2' shape='round' size='medium' onClick={toggleMergeLead}>
                      <img
                        src={AUTH.GetThemedImage('Hyperlink_icn')}
                        alt=''
                        className='icon'
                      />
                    </Button>
                  </Tooltip>

                  {!SearchPage ? <ApplyGridFilterNew
                    type={pageFilterType}
                    selectionValue={LEADACTIVE.selectedGridFilter}
                    filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
                  /> : null}
                  {!SearchPage ? location.pathname !== '/leads/unused' &&
                    AUTH.checkPermission(11, 'change-view') && (
                      <Button
                        className='gridbtn'
                        key='3'
                        shape='round'
                        size='medium'
                        onClick={openKanbanView}
                        icon={
                          <img
                            className='menuicon'
                            alt=''
                            src={AUTH.GetThemedImage('Kanban_View')}
                          />
                        }
                      >
                        {t('LeadListing.Kanban_View')}
                      </Button>
                    ) : null}
                  {!SearchPage ? location.pathname === '/leads/opportunities' &&
                    AUTH.checkPermission(11, 'import') && (
                      <Button
                        className='gridbtn'
                        key='4'
                        shape='round'
                        size='medium'
                        onClick={importOpportunitiesData}
                      >
                        {t('LeadListing.Import')}
                      </Button>
                    ) : null}
                  {!SearchPage ? AUTH.checkPermission(11, 'import') && (
                    <Button
                      className='gridbtn'
                      key='5'
                      shape='round'
                      size='medium'
                      onClick={importLeadData}
                    >
                      {t('LeadListing.Import')}
                    </Button>
                  ) : null}
                  {!SearchPage ? AUTH.checkPermission(11, 'export') && (
                    <Button
                      className='gridbtn'
                      key='6'
                      shape='round'
                      size='medium'
                      loading={LEADSSTORE.exportLoadingIcn}
                      onClick={() => LEADSSTORE.exportLeadData('active')}
                    >
                      {t('LeadListing.Export')}
                    </Button>
                  ) : null}

                  <ResetGridOptions key={'7'} pageType={pageFilterType} />

                  <EnterFullscreen key='8' />
                  <RecordPerPage
                    key='9'
                    initialValue={LEADACTIVE.per_page + ' per page'}
                    onChange={LEADACTIVE.setPageSize}
                  />
                </Space >
              </Form >,
            ]}
          >
            <ListComponent
              openViewDrawer={openViewDrawer}
              openEditDrawer={openEditDrawer}
              openDeleteDrawer={openDeleteDrawer}
              openCloneDrawer={openCloneDrawer}
              showCheckbox={showCheckbox}
              SearchPage={SearchPage}
              onCellKeyDown={onCellKeyDown}
              selectionChanged={selectionChanged}
              AllView={AllView}
            />
            <TimeTracker
              page={"leadlisting"}
              stop={viewDrawer || editDrawer || cloneDrawer}
            />
            {/* <TrackTimeComponent pageName={SearchPage ? 'search' : 'leadlisting'} /> */}
          </PageHeader >
        )}
      <ViewComponent
        visible={viewDrawer}
        openEditDrawer={openEditDrawer}
        close={closeViewDrawer}
        editDrawer={editDrawer}
      />
      <EditComponent visible={editDrawer} editOpen={editOpen} close={closeEditDrawer} />
      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        lineText={`${t('Common_Data.Remove_Lead')}?`}
      />
      <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
      <LeadImportComponent visible={importLeadModal} close={closeImportLeadModal} />
      <OpportunityImportComponent
        visible={importOpportunitiesModal}
        close={closeImportOpportunitiesModal}
      />
      <MergeLead
        visible={mergeSOLDVisible}
        close={closeSOLDMergeModal}
        selectedLeadsMerge={selectedLeadsMerge}
      />
    </>
  );
});

export default LeadListingActive;