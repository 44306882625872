import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class KeyNotesStore {
  key_notes_data = null;
  key_documents_data = null;
  notes_inbound_data = null;

  //set Deal Status Update API
  fetchKeyNotes = (lead_id = 0) => {
    let keyNotesAPIURL = `deal-desk/key-note-list`;
    if (lead_id) {
      keyNotesAPIURL = `deal-desk/key-note-list/${lead_id}`;
    }
    return Axios.get(keyNotesAPIURL)
      .then(({ data }) => {
        this.key_notes_data = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  //get Inbound Email List
  fetchInboundmail = (id) => {
    return Axios.get(`deal-desk/inbound-email-list/${id}`)
      .then(({ data }) => {
        this.notes_inbound_data = data && data.data;
        /*[
          {
            organisation: 'Advantage Tankers LLC',
            user: 'Mark Runiewicz',
            date: '2021-02-15 | 09:36 am',
            content:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised dummy text ever since the 1500s, when an unknown printer took a galley.",
          },
        ];*/
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  //Read Email Inbound
  readEmailInbound = (formdata) => {
    return Axios.get(`deal-desk/inbound-email-read/1`)
      .then(({ data }) => {
        if (data && data.data && data.data.length > 0) {
          this.notes_inbound_view = data && data.data[0];
        }
        /*{
          organisation: 'Advantage Tankers LLC',
          user: 'Mark Runiewicz',
          date: '2021-02-15 | 09:36 am',
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised dummy text ever since the 1500s, when an unknown printer took a galley.",
        };*/
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  keyNoteSave = (payload) => {
    return Axios.post(`deal-desk/key-note-save`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  saveNoteKnowledge = (payload) => {
    return Axios.post(`deal-desk/knowledge-note-save`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
}
decorate(KeyNotesStore, {
  key_notes_data: observable,
  key_documents_data: observable,
  notes_inbound_data: observable,
  notes_inbound_view: observable,
  fetchKeyNotes: action,
  fetchInboundmail: action,
  readEmailInbound: action,
  keyNoteSave: action,
  saveNoteKnowledge: action,
});
