import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import useStore from '../../../../store';
import InputComponent from '../../../../component/InputComponent';
import { Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';

const PersonElements = observer(({ handleChange, form, formDataList }) => {
  const [searchPplKey, setSearchPplKey] = useState();
  const [fetchPeopleRole, setFetchPeopleRole] = useState(true);
  const { t } = useTranslation();
  //----------GLOBAL STATE DECLARATION---------------//
  const { PEOPLE } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  const handlePplSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchPplKey(val);
      PEOPLE.getAllPeople({
        search_for: val,
        mapping_type: 2,
        people_type: form.getFieldValue('people_type'),
      });
    };

    return debounce(loadOptions, 500);
  }, [PEOPLE, form]);

  return (
    <>
      {formDataList.includes('people_type') && (
        <Col span={12}>
          <InputComponent
            allowClear
            showSearch
            showArrow
            type='select'
            label={`${t('Reports.People_Type')}`}
            name='people_type'
            placeholder={`${t('PeopleManagement.All')}`}
            onChange={handleChange}
            tooltip={`${t('Reports.People_Type_Tooltip')}.`}
            mode='multiple'
            onFocus={() =>
              fetchPeopleRole &&
              PEOPLE.getPeopleRoles().then(() => setFetchPeopleRole(false))
            }
            notFoundContent={fetchPeopleRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values: PEOPLE.dropdown_pplRole_list,
              value_key: 'id',
              text_key: 'role_name',
            }}
          />
        </Col>
      )}
      {formDataList.includes('people') && (
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.People')}/s`}
            name='people'
            placeholder={`${t('PeopleManagement.All')}`}
            type='select'
            mode='multiple'
            showSearch
            allowClear
            showArrow
            onSearch={handlePplSearch}
            onChange={handleChange}
            notFoundContent={
              PEOPLE.fetching ? (
                <Spin size='small' />
              ) : searchPplKey ? (
                `${t('Common_Data.No_Record_Found')}.`
              ) : null
            }
            options={{
              values: PEOPLE.drowpdown_people_list,
              value_key: 'id',
              text_key: 'entity_name',
            }}
            tooltip={`${t('Reports.People_Name_Tooltip')}.`}
          />
        </Col>
      )}
    </>
  );
});

export default PersonElements;
