import { createContext, useContext } from 'react';
import AuthStore from './AuthStore';
import TableExampleStore from './page/TableExampleStore';
import UserGroupStore from './UserGroup/UserGroupStore';
import ContractsStore from './Contracts/ContractsStore';
import CredebtClassificationStrore from './page/CredebtClassificationStrore';
import LeadStatusStore from './page/LeadStatusStore';
import UsersStore from './Users/UsersStore';
import LeadsStore from './page/LeadsStore';
import TicketsStore from './page/TicketsStore';
import SettingStore from './Settings/SettingStore';
import TagsStore from './page/TagsStore';
import PeopleStore from './People/PeopleStore';
import OrganisationStore from './Organisation/OrganisationStore';
import PeopleRoleMappingStore from './People/RoleMappingStore';
import OrgRoleMappingStore from './Organisation/RoleMappingStore';
import OrgStatusStore from './Organisation/OrgStatusStore';
import OrgBankAccountStore from './Organisation/OrgBankAccountStore';
import OrgRoleStore from './Organisation/Administration/OrgRoleStore';
import PEOPLEROLESTORE from './People/Administration/RoleStore';
import KnowledgeStore from './Knowledge/KnowledgeStore';
import TickerTapBroadcastStore from './TickerTapBroadcast/TickerTapBroadcastStore';
import AuditLogStore from './AuditLog/AuditLogStore';
import DealDeskStore from './DealDesk/DealDeskStore';
import DealdeskNotesOutboundStore from './DealDesk/Notes/NotesOutboundStore';
import NotesSequenceStore from './DealDesk/DealDeskLeft/Sequence/NotesSequenceStore';
import KeyNotesStore from './DealDesk/KeyNotes/KeyNotesStore';
import PromotionStore from './Promotion/PromotionStore';
import BlockEditAssignStore from './BlockEditAssign/BlockEditAssignStore';
import ProductivityStore from './Productivity/ProductivityStore';
import ManagementAllDepartmentsStore from './ManagementAllDepartments/ManagementAllDepartmentsStore';
import AlgorithmsStore from './DealBuilder/AlgorithmsStore';
import TransactionFieldStore from './DealBuilder/TransactionFieldStore';
import StructureStore from './DealDesk/DealDeskLeft/Structure/StructureStore';
import StructureLibraryStore from './DealDesk/Structure/StructureLibraryStore';
import DealStore from './DealDesk/Deal/DealStore';
import ScheduleLibraryStore from './DealDesk/Schedule/ScheduleLibraryStore';
// import ScheduleStore from './DealDesk/DealDeskLeft/Schedule/ScheduleStore';
import SavedReportStore from './Reports/SavedReportStore';
import ConnectionsStore from './DealDesk/ConnectionsStore';
import WasteStore from './Waste/WasteStore';
import TagsticketStore from './DealDesk/TagsticketStore';
import RemindersStore from './Reminders/RemindersStore';
import HeaderRemindersStore from './Reminders/HeaderRemindersStore';
import SpanNoteStore from './SpanNote/SpanNoteStore';
import SearchStore from './Search/SearchStore';
import LeadsOpportunityStore from './page/LeadsOpportunityStore';
import LeadsDealsStore from './page/LeadsDealsStore';
import LeadsActiveStore from './page/LeadsActiveStore';
import LeadsSearchStore from './page/LeadsSearchStore';
//import LeadsUnusedStore from './page/LeadsUnusedStore';
import Dashboardstore from './Dashboard/Dashboardstore';
import AllLeadsStore from './page/AllLeadsStore';
import HistoryStore from './History/HistoryStore';
import HistoryStoreWaste from './History/HistoryStoreWasteManagement';
import MergeLeadsStore from './page/MergeLeadsStore';
import SssLeadLibraryStore from './Common/SssLeadLibraryStore';
import SavedFilterStore from './page/SavedFilterStore';
import SequencerStore from './SequencerStore/SequencerStore';
import ReportStore from './Reports/ReportStore';
import LibrarySearchStore from './Search/LibrarySearchStore';
import ScoreLibraryStore from './DealDesk/Score/ScoreLibraryStore';
import KeyNotesSearchStore from './Search/KeyNotesSearchStore'
import TimeTrackerStore from './TimeTracker/TimeTrackerStore';

const AppContext = createContext({
  AUTH: new AuthStore(),
  TABLEEXAMPLE: new TableExampleStore(),
  USERGROUP: new UserGroupStore(),
  CONTRACTS: new ContractsStore(),
  CREDEBTCLASSIFICATION: new CredebtClassificationStrore(),
  LEADSTATUS: new LeadStatusStore(),
  USERS: new UsersStore(),
  PEOPLE: new PeopleStore(),
  ORGANISATION: new OrganisationStore(),
  PEOPLEMAPPINGSTORE: new PeopleRoleMappingStore(),
  ORGMAPPINGSTORE: new OrgRoleMappingStore(),
  ORGSTATUSSTORE: new OrgStatusStore(),
  ORGBANKACCOUNTSTORE: new OrgBankAccountStore(),
  PEOPLEROLESTORE: new PEOPLEROLESTORE(),
  ORGROLESTORE: new OrgRoleStore(),
  KNOWLEDGE: new KnowledgeStore(),
  TICKERTAPBROADCAST: new TickerTapBroadcastStore(),
  AUDITLOG: new AuditLogStore(),
  LEADSSTORE: new LeadsStore(),
  TicketsStore: new TicketsStore(),
  SETTINGS: new SettingStore(),
  TagsStore: new TagsStore(),
  DEALDESKSTORE: new DealDeskStore(),
  DEALDESKNOTESOUTBOUND: new DealdeskNotesOutboundStore(),
  DEALDESKNOTESEQUENCE: new NotesSequenceStore(),
  DEALDESKKEYNOTES: new KeyNotesStore(),
  DEALDESKSTRUCTURE: new StructureStore(),
  DEALDESKSTRUCTURELIBRARY: new StructureLibraryStore(),
  DEAL: new DealStore(),
  PROMOTIONSTORE: new PromotionStore(),
  BLOCKEDITASSIGNSTORE: new BlockEditAssignStore(),
  PRODUCTIVITYSTORE: new ProductivityStore(),
  MANAGEMENTALLDEPARTMENTSSTORE: new ManagementAllDepartmentsStore(),
  AlgorithmsStore: new AlgorithmsStore(),
  TransactionFieldStore: new TransactionFieldStore(),
  DEALDESKSCHEDULELIBRARY: new ScheduleLibraryStore(),
  // DEALDESKSCHEDULE: new ScheduleStore(),
  SavedReportStore: new SavedReportStore(),
  CONNECTIONS: new ConnectionsStore(),
  WASTESTORE: new WasteStore(),
  RIGHTSECTTION_TAGSTICKETS: new TagsticketStore(),
  REMINDERSSTORE: new RemindersStore(),
  HEADERREMINDERSSTORE: new HeaderRemindersStore(),
  SPANNOTESTORE: new SpanNoteStore(),
  SEARCHSTORE: new SearchStore(),
  LEADOPPORTUNITY: new LeadsOpportunityStore(),
  LEADDEALS: new LeadsDealsStore(),
  LEADACTIVE: new LeadsActiveStore(),
  LEADSEARCH: new LeadsSearchStore(),
  //LEADUNUSED: new LeadsUnusedStore(),
  DASHBOARD: new Dashboardstore(),
  ALLLEADS: new AllLeadsStore(),
  HISTORY: new HistoryStore(),
  HISTORYWASTE: new HistoryStoreWaste(),
  MERGELEADSTORE: new MergeLeadsStore(),
  SSSLEADLIBRARYSTORE: new SssLeadLibraryStore(),
  SAVEDFILTERSTORE: new SavedFilterStore(),
  SEQUENCERSTORE: new SequencerStore(),
  REPORTSTORE: new ReportStore(),
  LIBRARYSEARCHSTORE: new LibrarySearchStore(),
  KeyNotesSearchStore: new KeyNotesSearchStore(),
  SCORELIBRARYSTORE: new ScoreLibraryStore(),
  TimeTrackerStore: new TimeTrackerStore(),
});
const useStore = () => useContext(AppContext);

export default useStore;
