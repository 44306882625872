import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { vsmNotify, vsmWaste } from '../../../config/messages';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const RestoreComponent = observer((props) => {
  const [form] = Form.useForm();
  const { AUTH, WASTESTORE } = useStore();
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation()
  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  // Make function call to restore existing record
  const handleSubmit = () => {
    setSaving(true);
    props.store.RestoreData(props.currentValues.id)
      .then(() => {
        if (props.getWasteCount) { props.getWasteCount() }
        AUTH.fetchLeftmenuCount();
        close();
        vsmNotify.success({
          message: vsmWaste.restore,
        });
        if (WASTESTORE.activeTab === "1") {
          window.open(`/people/${props.currentValues.leads_transactions_id}`, '_blank');
        }
        if (WASTESTORE.activeTab === "2") {
          window.open(`/organisation/${props.currentValues.leads_transactions_id}`, '_blank');
        }
        if (WASTESTORE.activeTab === "8") {
          window.open(`/ticket/${props.currentValues.leads_transactions_id}`, '_blank');
        }
        if (WASTESTORE.activeTab === "3" || WASTESTORE.activeTab === "5") {
          window.open(`/dealdesk/${props.currentValues.id}`, '_blank');
        }
        if (WASTESTORE.activeTab === "6") {
          window.open(`/dealdesk/${props.currentValues.leads_transactions_id}#n${props.currentValues.id}`, '_blank');
        }
        if (WASTESTORE.activeTab === "7") {
          window.open(`/dealdesk/${props.currentValues.leads_transactions_id}#n${props.currentValues.leads_notes_id}`, '_blank');
        }
      })
      .catch((e) => {
        if (e.errors) { form.setFields(e.errors) }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return props.currentValues ? (
    <Modal
      centered
      title={props.title}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form={props.id}
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
         {t('Common_Data.Restore')}
        </Button>,
        <Button key='2' htmlType='button' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id={props.id} onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('Waste.Are_You_Sure_To_Restore')} {props.lineText}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});
export default RestoreComponent;
