import React, { useState } from 'react';
import {
  Button,
  Popover,
  //Space,
  Tooltip,
} from 'antd';
// import DateTimeConfirmComponent from './DateTimeConfirmComponent';
// import HourglassConfirmComponent from './HourglassConfirmComponent';
import ReassignConfirmComponent from '../../Tickets/component/ReassignConfirmComponent';
import useStore from '../../../store';
import { useHistory } from 'react-router-dom';
// import ConvertticketComponent from './ConvertticketComponent';
import { useTranslation } from 'react-i18next';
import NoteRenderer from './NoteRenderer';
// import DatePickerCostume from '../../../component/DatePickerCostume';

export const ActionRenderer = (props) => {
  let history = useHistory();
  const { t } = useTranslation()
  //const [count, setCount] = useState(0);
  // const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  // const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  // const [convertTicketModal, setConvertTicketModal] = useState(false);

  const [reAssignUser, setReAssignUser] = useState(null);
  const [reAssingTempData, setReAssingTempData] = useState(null);
  // const [convertTicketTempData, setConvertTicketTempData] = useState(null);
  // const [date, setDate] = useState();
  // const [reminderConfirm, setReminderConfirm] = useState();
  // const [hourglassConfirm, setHourglassConfirm] = useState();
  // const [extrafooter, setExtrafooter] = useState(null);

  const {
    openViewDrawer,
    openDeleteDrawer,
    // openRestoreModal,
    openEditDrawer,
    // openCloneDrawer,
    TagTicketModule,
    // WasteManagement,
    toggleDealTicket,
  } = props.agGridReact.props.frameworkComponents;

  const {
    AUTH,
    DEALDESKSTORE: { deal_data },
    TicketsStore: { getUsersList },
    // REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();

  let AdminIsLogging = AUTH?.user?.id === 1;
  let OwnerIsLogging = AUTH?.user?.id === props?.data?.ticket_owner?.id;
  // let HolderIsLogging = AUTH?.user?.id === props?.data?.ticket_holder?.id;

  // const handleCount = () => {
  //   setCount(count + 1);
  // };

  //Confirm Modal
  // const openConfirmDateTimeModal = (dateformat, data) => {
  //   if (dateformat) {
  //     setDate(dateformat);
  //     setReminderConfirm(data);
  //     setOpenDateTimeModal(true);
  //   }
  // };
  // const closeDateTimeModal = () => {
  //   setDate(null);
  //   setReminderConfirm(null);
  //   setOpenDateTimeModal(false);
  // };
  // const openConfirmHourglassModal = (data) => {
  //   setOpenHourglassModal(true);
  //   setHourglassConfirm(data);
  // };
  // const closeHourglassModal = () => setOpenHourglassModal(false);
  const openConfirmReassignModal = (id, username, ticketData) => {
    let tempReAssign = {
      userid: id,
      username: username,
      ticketno: ticketData.ticketno,
      ticketid: ticketData.id,
    };
    setReAssingTempData(tempReAssign);
    setOpenReassignModal(true);
  };
  const closeReassignModal = () => setOpenReassignModal(false);

  /*START:  convert Ticket To Deal */
  // const openConvertTicketToDeal = (data) => {
  //   setConvertTicketTempData(data);
  //   setConvertTicketModal(true);
  // };
  // const closeConvertModal = () => setConvertTicketModal(false);
  /*END:  convert Ticket To Deal */

  const userList = (
    <div className='ticketsPopoverIcons username'>
      <ul>
        {reAssignUser &&
          reAssignUser.map(function (item) {
            return (
              <li
                key={item.id}
                onClick={() => openConfirmReassignModal(item.id, item.name, props.data)}
              >
                {item.name}
              </li>
            );
          })}
      </ul>
    </div>
  );



  const openChildTickets = (data) => {
    localStorage.removeItem("ticket_grid");
    let temp = JSON.parse(localStorage.getItem('params'));
    localStorage.setItem(
      'params',
      JSON.stringify({
        ...temp,
        TicketsStore: {},
      })
    );
    localStorage.setItem('get_child_tickets', data?.id)
    window.open(`/tickets`, '_blank');
  };
  const openMasterTicket = (data) => {
    localStorage.removeItem("ticket_grid");
    let temp = JSON.parse(localStorage.getItem('params'));
    localStorage.setItem(
      'params',
      JSON.stringify({
        ...temp,
        TicketsStore: {},
      })
    );
    localStorage.setItem('get_master_ticket', data?.master_ticket.master_ticket_number)
    window.open(`/tickets`, '_blank');
  }

  return (
    <div className='action-column'>
      <>
        {!TagTicketModule && AUTH.checkPermission(15, 'ticket-desk') && (
          <Button type='text' title={`${t('Common_Data.Ticket')} ${t('Common_Data.Desk')}`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('Tickets')}
              onClick={() => {
                if (props.data?.ticket_number) {
                  history.push(`/ticket/${props.data.ticket_number}`);
                } else {
                  history.push(`/ticket/${props.data.leads_transactions_id}`);
                }
              }}
            />
          </Button>
        )}
        {/* )} */}
        {/* {AUTH.checkPermission(15, 'view-details') && (
            <Button
              type='text'
              title={`${t('Common_Data.View')}`}
              onClick={() => {
                openViewDrawer(props.data);
              }}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
            </Button>
          )} */}
        {TagTicketModule && (
          <Button
            title={
              props?.data?.leads_transactions_id
                ? `${t('Common_Data.Remove')} ${t('Tickets.This')} ${t('Common_Data.Ticket')} ${t('Tickets.From')} ${t('Common_Data.Lead')} #${deal_data?.lead_information?.id}`
                : `${t('Common_Data.Add')} ${t('Tickets.This')} ${t('Common_Data.Ticket')} ${t('Common_Data.To')} ${t('Common_Data.Lead')} #${deal_data?.lead_information?.id}`
            }
            type='text'
            onClick={() => {
              toggleDealTicket(
                props.data.id,
                props?.data?.leads_transactions_id ? 'remove' : 'add'
              );
            }}
          >
            <img
              alt=''
              className='menuicon circle'
              src={AUTH.GetThemedImage(
                props?.data?.leads_transactions_id ? 'remove' : 'add'
              )}
            />
          </Button>
        )}
        {!TagTicketModule ? (
          <>
            {/* Edit Icon */}
            {
              (AdminIsLogging || OwnerIsLogging)
                ? AUTH.checkPermission(15, 'edit') && (
                  <Button
                    type='text'
                    title={`${t('Common_Data.Edit')}`}
                    onClick={() => {
                      openEditDrawer(props.data);
                    }}
                  >
                    <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
                  </Button>)
                : <Button type='text' disabled={true}>
                  <Tooltip
                    placement='topRight'
                    color={'red'}
                    title={`${t('Tickets.Edit_Disable')}.`}
                  >
                    <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
                  </Tooltip>
                </Button>
            }

            {/* View Icon */}
            {AUTH.checkPermission(15, 'view-details') && (
              <Button
                type='text'
                title={`${t('Common_Data.View')}`}
                onClick={() => {openViewDrawer(props.data);}}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
              </Button>)
            }

            {/* Note */}
            <NoteRenderer data={props.data} />

            {/* {props.data?.child_ticket_count ? (
                <Tooltip
                  placement='topRight'
                  color={'red'}
                  title={`${t('Tickets.Master_Ticket')}.`}
                >
                  <Button
                    type='text'
                    disabled={props.data?.child_ticket_count}
                    title={`${t('Tickets.Convert')} ${t('Common_Data.To')} ${t('Common_Data.Sold')}`}
                    onClick={() => openConvertTicketToDeal(props.data)}
                  >
                    <img
                      className='menuicon'
                      title={`${t('Tickets.Convert')} ${t('Common_Data.To')} ${t('Common_Data.Sold')}`}
                      alt={`${t('Tickets.Convert')} ${t('Common_Data.To')} ${t('Common_Data.Sold')}`}
                      src={AUTH.GetThemedImage('TicketToDeal')}
                    />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  type='text'
                  disabled={props.data?.child_ticket_count}
                  title={`${t('Tickets.Convert')} ${t('Common_Data.To')} ${t('Common_Data.Sold')}`}
                  onClick={() => openConvertTicketToDeal(props.data)}
                >
                  <img
                    className='menuicon'
                    title={`${t('Tickets.Convert')} ${t('Common_Data.To')} ${t('Common_Data.Sold')}`}
                    alt={`${t('Tickets.Convert')} ${t('Common_Data.To')} ${t('Common_Data.Sold')}`}
                    src={AUTH.GetThemedImage('TicketToDeal')}
                  />
                </Button>
              )} */}

            {/* {AUTH.checkPermission(15, 'clone') && (
                <Button
                  type='text'
                  title={`${t('Common_Data.Clone')}`}
                  onClick={() => {
                    openCloneDrawer(props.data);
                  }}
                >
                  <img
                    className='menuicon'
                    alt=''
                    src={AUTH.GetThemedImage('Clone_Record')}
                  />
                </Button>
              )} */}
            {/* <Popover
                content={userList}
                placement='bottomRight'
                trigger='hover'
                overlayClassName='profileMenu'
              >
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Reassign')}
                  onMouseEnter={() => {
                    if (!reAssignUser) {
                      getUsersList().then((data) => {
                        setReAssignUser(data.data);
                      });
                    }
                  }}
                />
              </Popover> */}
            {(OwnerIsLogging || AdminIsLogging)
              ? <Popover
                content={userList}
                placement='bottomRight'
                trigger='hover'
                overlayClassName='profileMenu'
              >
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Reassign')}
                  style={{ cursor: 'pointer', margin: 5 }}
                  onMouseEnter={() => {
                    if (!reAssignUser) {
                      getUsersList().then((data) => {
                        setReAssignUser(data.data);
                      });
                    }
                  }}
                />
              </Popover>
              : <Tooltip
                placement='topRight'
                color={'red'}
                title={`${t('Tickets.Reassign_Disable')}.`}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Reassign')}
                  style={{ cursor: 'not-allowed', margin: 5 }}
                />
              </Tooltip>
            }

            {/* {AUTH.checkPermission(15, 'reset-reminder-date-time') && (
                //props?.data?.reset_count !== null &&
                <span
                  style={{ position: 'relative', overflow: 'hidden' }}
                  onMouseEnter={() => {
                    if (props.data?.reset_count !== null) {
                      getReminderDatepickerExtraFooter(
                        props.data,
                        AUTH.global_fulldate_format,
                        {
                          type: 'tickets',
                        }
                      ).then((data) => {
                        setExtrafooter(data);
                      });
                    }
                  }}
                >
                  <Tooltip
                    title={
                      props.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`
                    }
                  >
                    <DatePickerCostume
                      name='Calendar'
                      title={`${t('Common_Data.Calendar')}`}
                      showTime
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 5,
                        opacity: 0,
                      }}
                      disabledDate={true}
                      // disabledHours={() => disabledHours()}
                      // minuteStep={15}
                      renderExtraFooter={() => extrafooter}
                      onOk={(e) => openConfirmDateTimeModal(e, props.data)}
                      hideDisabledOptions
                      showNow={false}
                    />
                  </Tooltip>
                  <img
                    style={{ margin: 5 }}
                    className='menuicon'
                    alt={`${t('Common_Data.Calendar')}`}
                    src={AUTH.GetThemedImage('Calendar')}
                    title={`${t('Common_Data.Calendar')}`}
                  //onClick={handleCount}
                  />
                </span>
              )} */}

            {/* {AUTH.checkPermission(15, 'reset-reminder-time') && (
                //props?.data?.reset_count !== null &&
                <div>
                  <img
                    style={{ margin: 5 }}
                    className='menuicon'
                    title={`${t('Common_Data.Hour_Glass')}`}
                    alt={`${t('Common_Data.Hour_Glass')}`}
                    src={AUTH.GetThemedImage('Reminders_icn')}
                    onClick={() => openConfirmHourglassModal(props.data)}
                  />
                  <span>{props.data?.reset_count ? props.data?.reset_count : 0}</span>
                </div>
              )} */}

            {/* {(AUTH.checkPermission(15, 'reset-reminder-date-time') ||
                AUTH.checkPermission(15, 'reset-reminder-time')) &&
                props?.data?.reset_count !== null && (
                  <Popover
                    content={content}
                    placement='bottomRight'
                    trigger='hover'
                    overlayClassName='profileMenu'
                  >
                    <Button type='text'>
                      <img
                        className='menuicon'
                        alt=''
                        src={AUTH.GetThemedImage('DropdownBox')}
                      />
                    </Button>
                  </Popover>
                )} */}




            {/* <DateTimeConfirmComponent
                visible={openDateTimeModal}
                close={closeDateTimeModal}
                date={date}
                reminderConfirm={reminderConfirm}
              /> */}
            {/* <HourglassConfirmComponent
                visible={openHourglassModal}
                close={closeHourglassModal}
                hourglassConfirm={hourglassConfirm}
              /> */}
            <ReassignConfirmComponent
              visible={openReassignModal}
              close={closeReassignModal}
              reAssingTempData={reAssingTempData}
            />
            {/* <ConvertticketComponent
                visible={convertTicketModal}
                close={closeConvertModal}
                convertTicketTempData={convertTicketTempData}
              /> */}
            {/* {AUTH.checkPermission(15, 'delete') && (
                <Button
                  type='text'
                  title={`${t('Common_Data.Delete')}`}
                  onClick={() => {
                    openDeleteDrawer(props.data);
                  }}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              )} */}

            {AUTH.checkPermission(15, 'delete') && (
              (OwnerIsLogging || AdminIsLogging)
                ? <Button
                  type='text'
                  title={`${t('Common_Data.Delete')}`}
                  onClick={() => {
                    openDeleteDrawer(props.data);
                  }}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                </Button>
                : <Tooltip
                  placement='topRight'
                  color={'red'}
                  title={`${t('Tickets.Delete_Disable')}.`}
                >
                  <Button
                    type='text'
                    // title={`${t('Common_Data.Delete')}`}
                    disabled={true}
                    onClick={() => {
                      openDeleteDrawer(props.data);
                    }}
                  >
                    <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                  </Button>
                </Tooltip>
            )}
            {props.data?.child_ticket_count > 0 ? (
              <div>
                <img
                  style={{ margin: 5 }}
                  className='menuicon'
                  title={`${t('Tickets.Linked_Tickets')} ${t('Common_Data.Tickets')}`}
                  alt={`${t('Tickets.Linked_Tickets')} ${t('Common_Data.Tickets')}`}
                  src={AUTH.GetThemedImage('childticket')}
                  onClick={() => openChildTickets(props.data)}
                />
              </div>
            ) : null}
            {(props.data?.child_ticket_count === 0 && props.data?.master_ticket?.master_ticket_number != null) ? (
              <div>
                <img
                  style={{ margin: 5, transform: "rotate(180deg)" }}
                  className='menuicon'
                  title={`${t('Common_Data.Master')} ${t('Common_Data.Ticket')}`}
                  alt={`${t('Common_Data.Master')} ${t('Common_Data.Ticket')}`}
                  src={AUTH.GetThemedImage('childticket')}
                  onClick={() => openMasterTicket(props.data)}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </>
    </div>
  );
};
