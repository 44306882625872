import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmOrganisations, vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {
    AUTH,
    SEARCHSTORE,
    ORGANISATION,
    ORGANISATION: { DeleteData, deleteValues },
  } = useStore();
  const [saving, setSaving] = useState();

  // Make function call to delete existing record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = deleteValues.id;
    DeleteData(data)
      .then(() => {
        props.close();
        if (props.getWasteCount) {
          props.getWasteCount();
        }

        if (!props?.SearchPage) {
          if (ORGANISATION.agGrid) {
            ORGANISATION.setupGrid(ORGANISATION.agGrid);
          }
        }
        else {
          ORGANISATION.agGrid.api.applyTransaction({ remove: [ORGANISATION.deleteValues] });
          SEARCHSTORE.updateSearchCount({ organisation_count: SEARCHSTORE.searchCount.organisation_count - 1 });
        }

        // if (props?.SearchPage) {
        //   SEARCHSTORE.setSearchCount(
        //     { search_for: SEARCHSTORE.searchTerm },
        //     'organisation'
        //   );
        // }
        vsmNotify.success({
          message: vsmOrganisations.delete,
        });
        form.resetFields();
        AUTH.fetchLeftmenuCount();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  // set the form values to delete
  useEffect(() => {
    if (deleteValues) {
      form.setFieldsValue({
        organisation_name: deleteValues.organisation_name,
      });
    }
  }, [deleteValues, form]);

  return deleteValues ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${t('Common_Data.Organisation')} - #${deleteValues?.id} - ${deleteValues?.organisation_name}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteorgform'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteorgform' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {props.lineText}
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
