import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import MergeLeadListing from './MergeLeadListing';
import { vsmNotify } from '../../../config/messages';
import { useLocation } from 'react-router-dom';
import ConfirmMergeComponent from './ConfirmMergeComponent';
import { useTranslation } from 'react-i18next';

const MergeLead = observer((props) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [confirmMerge, setconfirmMerge] = useState(false);

  const { selectedLeadsMerge, visible } = props;

  const {
    LEADDEALS,
    LEADACTIVE,
    LEADSSTORE,
    LEADSSTORE: { masterLeadToMerge, setMasterLeadToMerge, handleMergeLead },
  } = useStore();
  const location = useLocation();

  useEffect(() => { }, [masterLeadToMerge]);

  const openConfirmMergeModal = () => {
    setconfirmMerge(true);
  };

  const handleSubmit = () => {
    setSaving(true);
    let selectedLeadsArray = [];

    selectedLeadsMerge &&
      selectedLeadsMerge.forEach((element) => {
        selectedLeadsArray.push(element.id);
      });
    let payload = {
      selectedLeads: selectedLeadsArray,
      mergeLead: masterLeadToMerge,
    };

    handleMergeLead(payload)
      .then((data) => {
        if (location.pathname === '/leads/deals') {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
          LEADDEALS.agGrid.api.deselectAll();
        }
        if (location.pathname === '/leads/active') {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
          LEADACTIVE.agGrid.api.deselectAll();
        }
        if (location.pathname === '/search/Leads') {
          LEADSSTORE.getListSearch(LEADSSTORE.search_payload);
          LEADSSTORE.agGrid.api.deselectAll();
        }
        if(location.pathname === '/search/Deals') {
          LEADDEALS.getListSearch(LEADDEALS.search_payload);
          LEADDEALS.agGrid.api.deselectAll();
        }

        vsmNotify.success({
          message: `${t('LeadListing.SOLD_Merged')}!`,
        });
        close();
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
        }
      })
      .finally(() => setSaving(false));
  };

  const closeConfirmMerge = () => {
    setconfirmMerge(false);
    setSaving(false);
  };

  const close = () => {
    setMasterLeadToMerge(null);
    setSaving(false);
    setconfirmMerge(false);

    props.close();
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Merge')} ${t('Common_Data.Sold')}`}
      visible={visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      width={1200}
      destroyOnClose
      footer={[
        <Button
          key='1'
          shape='round'
          size='medium'
          disabled={masterLeadToMerge ? false : true}
          // loading={saving}
          onClick={() => openConfirmMergeModal()}
        >
          {t('Common_Data.Merge')} {t('Common_Data.Sold')}
        </Button>,
      ]}
    >
      <h5>
        {t('LeadListing.SOLD_Merged_Selection')}.
      </h5>
      <MergeLeadListing selectedLeadsMerge={selectedLeadsMerge} />
      <ConfirmMergeComponent
        visible={confirmMerge}
        close={closeConfirmMerge}
        handleSubmit={handleSubmit}
        saving={saving}
        setSaving={setSaving}
      />
    </Modal>
  );
});
export default MergeLead;
