import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { vsmCommon } from '../../../config/messages';
// import GridConfig from '../../../config/GridConfig';

import {
  Spin,
  Button,
  //DatePicker, Tooltip
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

//import DateTimeConfirmComponent from '../elements/DateTimeConfirmComponent';
// import HourglassConfirmComponent from '../elements/HourglassConfirmComponent';
import { useTranslation } from 'react-i18next';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { GridLoader } from '../../../utils/GridLoader';
import { CurrencyFormat, DateComparator } from '../../../utils/GlobalFunction';
import NoteRenderer from './NoteRenderer';

const ReminderSearchListing = observer((props) => {
  const { t } = useTranslation()
  let history = useHistory();

  const {
    AUTH,
    REMINDERSSTORE,
    SEARCHSTORE,
    SEARCHSTORE: { setResultCount, searchCount },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    //REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();

  useEffect(() => {
    if (props.SearchPage) {
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.reminder_count;
      REMINDERSSTORE.setIsSavedFiltersLoadedRm(true);
    } else {
      REMINDERSSTORE.search_payload = {};
      REMINDERSSTORE.setIsSavedFiltersLoadedRm(true);
    }
  }, [props.SearchPage, REMINDERSSTORE, SEARCHSTORE, REMINDERSSTORE.total]);

  useEffect(() => {
    setResultCount(searchCount?.reminder_count);
    REMINDERSSTORE.getListSearch(REMINDERSSTORE.search_payload)
  }, [REMINDERSSTORE, setResultCount, searchCount?.reminder_count])

  const ActionRenderer = (params) => {
    return (
      <>
        <div className='action-column'>
          {/* {params.data?.leads_transactions_id > 0 && ( */}

          {props?.AllSOLDReminder ? null : (
            <Button
              type='text'
              title={`${t('Block_Edit_Assign.Deal_Desk')}`}
              disabled={
                params?.data?.reminder_type === 'private'
                  ? true
                  : params.data?.leads_transactions_id
                    ? false
                    : true
              }
            >
              <img
                className='menuicon'
                alt=''
                src={AUTH.GetThemedImage('Deal_icn')}
                onClick={() => {
                  if (params.data.leads_transactions_id) {
                    let routePath = `/dealdesk/${params.data.leads_transactions_id}`;
                    if (
                      params.data.reminder_type === 'notes' &&
                      params.data?.reminder_for
                    ) {
                      routePath = `/dealdesk/${params.data.leads_transactions_id}/#n${params.data?.reminder_for}`;
                    }
                    if (
                      params.data.reminder_type === 'tags' &&
                      params.data?.leads_notes_id
                    ) {
                      routePath = `/dealdesk/${params.data.leads_transactions_id}/#n${params.data?.leads_notes_id}`;
                    }

                    history.push(routePath);
                  }
                }}
              />
            </Button>
          )}


          {AUTH.checkPermission(46, 'edit') &&
            (props?.AllSOLDReminder ? (
              params.data?.receiver_by && params.data.receiver_by?.id === AUTH.user.id ? (
                <Button
                  type='text'
                  title={`${t('Common_Data.Edit')}`}
                  disabled={params.data.status === 'Closed' ? true : false}
                  onClick={() => props.openEditReminder(params.data)}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
                </Button>
              ) : (
                <Button type='text' title={`${t('Common_Data.Edit')}`} disabled={true}>
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
                </Button>
              )
            ) : (
              <Button
                type='text'
                title={`${t('Common_Data.Edit')}`}
                disabled={params.data.status === 'Closed' ? true : false}
                onClick={() => props.openEditReminder(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
              </Button>
            ))}


          {/* Note */}
          <NoteRenderer data={params.data} />

          {AUTH.checkPermission(46, 'delete') &&
            (props?.AllSOLDReminder ? (
              params.data?.receiver_by && params.data.receiver_by?.id === AUTH.user.id ? (
                <Button
                  type='text'
                  title={`${t('Common_Data.Delete')}`}
                  onClick={() => props.openDeleteReminder(params.data)}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              ) : (
                <Button type='text' title={`${t('Common_Data.Delete')}`} disabled={true}>
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              )
            ) : (
              <Button
                type='text'
                title={`${t('Common_Data.Delete')}`}
                onClick={() => props.openDeleteReminder(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            ))}


        </div>
      </>
    );
  };

  // const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
  //     let fieldValue = null;
  //     if (dropdownList && dropdownList.length > 0) {
  //         const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
  //         let ownerIndex = dropdownList.findIndex(searchItem);
  //         if (ownerIndex >= 0) {
  //             fieldValue = dropdownList[ownerIndex][returnfieldName];
  //         }
  //     }
  //     return fieldValue;
  // };

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params?.column?.colId === 'actions') {
      let leads_transactions_id = params.node?.data?.leads_transactions_id;
      let reminder_for = params.node?.data?.reminder_for;
      let leads_notes_id = params.node?.data?.leads_notes_id;
      let routePath = null;

      if (leads_transactions_id) {
        routePath = `/dealdesk/${leads_transactions_id}`;
        if (params?.node.data.reminder_type === 'notes' && reminder_for) {
          routePath = `/dealdesk/${leads_transactions_id}/#n${reminder_for}`;
        }
        if (params?.node?.data.reminder_type === 'tags' && leads_notes_id) {
          routePath = `/dealdesk/${leads_transactions_id}/#n${leads_notes_id}`;
        }
      } else {
        routePath = '/reminders';
      }

      result = [
        {
          // custom item
          name: 'Open link in new tab',
          action: function () {
            window.open(routePath, '_blank');
          },
        },
        {
          // custom item
          name: 'Open link in new window',
          action: function () {
            window.open(
              routePath,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  const gridOptions = {
    columnDefs: [
      // {
      //   headerName: 'Record Type',
      //   field: 'event_record_type',
      //   cellClass: 'cellClass',
      // },
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        // valueFormatter: (params) =>
        //     formatterSetColumnFilter(
        //         REMINDERSSTORE.dropdown_user_list,
        //         params,
        //         'id',
        //         'name'
        //     ),
        filterParams: { defaultToNothingSelected: true, buttons: ['apply', 'reset'], },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        cellClass: 'cellClass',
        filter: 'agNumberColumnFilter',
        width: 90,
        sortable: true,
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,
        field: 'fullname',
        tooltipField: 'fullname',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.fullname
          // ? params?.data?.receiver_by?.first_name +
          // ' ' +
          // params?.data?.receiver_by?.last_name
          // : '';
        },
        filter: 'agSetColumnFilter',

        // valueFormatter: (params) =>
        //     formatterSetColumnFilter(
        //         REMINDERSSTORE.dropdown_user_list,
        //         params,
        //         'id',
        //         'name'
        //     ),
        filterParams: { defaultToNothingSelected: true, buttons: ['apply', 'reset'], },
      },
      //   {
      //     headerName: `${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`,
      //     headerTooltip: `${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`,
      //     field: 'reminder_type',
      //     tooltipValueGetter: (params) =>
      //       params?.data?.reminder_type &&
      //       REMINDERSSTORE.remindersTypeValue[params?.data?.reminder_type],
      //     valueGetter: (params) =>
      //       params?.data?.reminder_type &&
      //       REMINDERSSTORE.remindersTypeValue[params?.data?.reminder_type],
      //     cellClass: 'cellClass',
      //     filter: 'agSetColumnFilter',
      //     filterParams: {
      //       defaultToNothingSelected: true,
      //       buttons: ['apply', 'reset'],
      //       values: (params) => {
      //         params.success([
      //           ...REMINDERSSTORE.remindersTypeValueForFilter.map((x) => x.name),
      //         ]);
      //       },
      //     },
      //   },

      {
        headerName: `${t('Common_Data.Subject')}`,
        field: 'subject',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reminders.For')}`,
        field: 'reminder_for_name',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Priority')}`,
        field: 'priority_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: { defaultToNothingSelected: true, buttons: ['apply', 'reset'], },
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        field: 'description',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },

      //   {
      //     headerName: `${t('Common_Data.Status')}`,
      //     headerTooltip: `${t('Common_Data.Status')}`,
      //     field: 'status',
      //     tooltipField: 'status',
      //     cellClass: 'cellClass',
      //     filter: 'agSetColumnFilter',
      //     filterParams: {
      //       defaultToNothingSelected: true,
      //       buttons: ['apply', 'reset'],
      //       values: (params) => {
      //         params.success([...REMINDERSSTORE.statuslistForFilter.map((x) => x.text)]);
      //       },
      //     },
      //   },

      {
        headerName: `${t('Common_Data.DeadLine')}`,
        field: 'due_date_time',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.due_date_time
            ? moment(params?.data?.due_date_time).format(AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'reset'],
          comparator: DateComparator,
        },
      },
      // Modification Needed for Idle
      {
        headerName: `${t('LeadListing.Idle')}`,
        headerTooltip: `${t('LeadListing.Idle')}`,
        cellRendererFramework: (params) => {
          return <> {CurrencyFormat({ value: params.data.idle })}</>;
        },
        field: 'idle',
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'idle',
        cellClass: 'cellClass text-center',
      },

      //   {
      //     headerName: `${t('Common_Data.Created')} ${t('Common_Data.At')}`,
      //     field: 'created_at',
      //     cellClass: 'cellClass',
      //     cellRenderer: (params) => {
      //       return params?.data?.created_at
      //         ? moment(params?.data?.created_at).format(AUTH.global_fulldate_format)
      //         : '';
      //     },
      //     filter: 'agDateColumnFilter',
      //     filterParams: {
      //       buttons: ['reset'],
      //       suppressAndOrCondition: true,
      //     },
      //   },

      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        width: 150,
        minWidth: 150,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('search_reminders_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['reminder_type'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }
    return item;
  });

  GridLoader(REMINDERSSTORE)

  return (
    <>
      <div
        className={`ag-theme-alpine grid_wrapper ${props.SearchPage ? "paginationAtTop" : ""}`}
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : props.SearchPage && !props.AllView
              ? 'calc(100vh - 13rem)'
              : props.SearchPage && props.AllView
                ? '150px'
                : 'calc(100vh - 13rem)',
          minHeight: props.AllView ? '100px' : '250px',
        }}
      >
        {REMINDERSSTORE.list_data ? (
          <AgGridReact
            rowData={REMINDERSSTORE.list_data}
            modules={AllModules}
            columnDefs={withTooltipCols}
            defaultColDef={{
              ...LocalGridConfig.defaultColDef,
              width: 120,
              minWidth: 70,
              flex: 1,
              // floatingFilter: !SearchPage,
              sortable: true,

            }}
            columnTypes={LocalGridConfig.columnTypes}
            overlayNoRowsTemplate={vsmCommon.noRecord}
            frameworkComponents={{ ActionRenderer }}
            onGridReady={REMINDERSSTORE.setupGridSearch}
            onColumnResized={REMINDERSSTORE.onGridChangedSearch}
            onColumnMoved={REMINDERSSTORE.onGridChangedSearch}
            onColumnPinned={REMINDERSSTORE.onGridChangedSearch}
            onSortChanged={(params) => {
              if (localStorage.getItem('params')) {
                var temp = JSON.parse(localStorage.getItem('params'));
                localStorage.setItem('params', JSON.stringify({ ...temp, ReminderSearchStore: { sort: params.api.getSortModel() }, }));
              } else {
                localStorage.setItem('params',
                  JSON.stringify({ ReminderSearchStore: { sort: params.api.getSortModel() }, })
                );
              }
            }}

            gridOptions={{
              ...LocalGridConfig.options,
              rowHeight: 30,
              pagination: true,
              paginationPageSize: 100,
              getContextMenuItems: getContextMenuItems,
              // onRowDoubleClicked: (event) => props.openViewDrawer(event?.data),
            }}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
          // onCellKeyDown={props.onCellKeyDown}
          // onFilterChanged={(e) => {
          //   if (
          //     e.hasOwnProperty('afterFloatingFilter') &&
          //     REMINDERSSTORE.selectedGridFilter
          //   ) {
          //     REMINDERSSTORE.setSelectedGridFilter(null);
          //   }
          // }}
          />
        ) : (
          <div className='spinCenter' style={{ paddingTop: '20rem' }}>
            <Spin tip={`${t('Common_Data.Load')}...`} />
          </div>
        )}
      </div>
    </>
  );
});
export default ReminderSearchListing;
