import React from 'react';
import { observer } from 'mobx-react';
import { Drawer /* Button */ } from 'antd';
import { useTranslation } from 'react-i18next';
import ScoreContent from './ScoreContent';

const ScoreLibraryView = observer((props) => {
  const { t } = useTranslation();
  const {
    ScoreData,
    // selectedLibraryItemNode,
    // setSelectedLibraryItemNode,
    // isCheckBoxSelection,
    // tempcopySchedule,
  } = props;

  const closeScheduleLibraryDrawer = () => props.closeScheduleLibraryDrawer();

  return (
    <>
      <Drawer
        title={`${t('DealDesk.View_Score')} - #${ScoreData?.id} - ${ScoreData?.title}`}
        visible={props.visibleScheduleLibraryDrawer}
        onClose={closeScheduleLibraryDrawer}
        placement='right'
        width={'1050px'}
        destroyOnClose={true}
        footer={[
          <div className='text-right'>
            {/* {selectedLibraryItemNode && selectedLibraryItemNode.length > 0 && (
              <Button
                key='1'
                className='mr-10'
                type='primary'
                shape='round'
                onClick={() => tempcopySchedule(ScoreData, true)}
              >
                {t('DealDesk.Copy')}
              </Button>
            )} */}
          </div>,
        ]}
      >
        <div className='structureRight noStyle'>
          <table width={'100%'} className='box-RightScheduleList'>
            <tr>
              <th width={'4%'}>&nbsp;</th>
              <th width={'6%'} className='dayTD' align='center'>
                Focus
              </th>
              <th width={'90px'} className='date dateTD' align='center'>
                Feature
              </th>
              <th style={{ width: 'calc(80% - 235px)' }} className='dataTD data'>
                Explanation
              </th>
              <th width={'25px'} className='dataTD data'>
                Min
              </th>
              <th width={'25px'} className='dataTD data'>
                Max
              </th>
              <th width={'15px'} className='dataTD data'>
                +/-
              </th>
              <th width={'15px'} className='dataTD data'>
                Weight
              </th>
              <th width={'55px'} className='actionTD'>
                &nbsp;
              </th>
            </tr>
          </table>
          <div className='list fullHeight'>
            <ScoreContent
              Score={ScoreData?.score_json}
              editable={false}
              onlyForViewWithNostyle={true}
              // isCheckBoxSelection={isCheckBoxSelection}
              // selectedLibraryItemNode={selectedLibraryItemNode}
              // setSelectedLibraryItemNode={setSelectedLibraryItemNode}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
});
export default ScoreLibraryView;
