import React from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button } from 'antd';
import StructureContent from './StructureContent';
import { useTranslation } from 'react-i18next';

const StructureLibraryView = observer((props) => {
  const { t } = useTranslation()
  const {
    saveType = 'structure',
    selectedLibraryItemNode,
    setSelectedLibraryItemNode,
    isCheckBoxSelection,
    tempcopyStructure,
  } = props;
  const closeScheduleLibraryDrawer = () => props.closeScheduleLibraryDrawer();

  const getTitleBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    } else if (saveType === 'shortfall') {
      return `${t('Common_Data.Shortfall')}`;
    }
  }

  return (
    <>
      <Drawer
        title={`${t('DealDesk.View')} ${getTitleBasedOnSaveType()} - #${props?.structure?.id
          } - ${props?.structure?.title}`}
        visible={props.visibleScheduleLibraryDrawer}
        onClose={closeScheduleLibraryDrawer}
        placement='right'
        width={'1050px'}
        destroyOnClose={true}
        footer={[
          <div className='text-right'>
            {selectedLibraryItemNode && selectedLibraryItemNode.length > 0 && (
              <Button
                key='1'
                className='mr-10'
                type='primary'
                shape='round'
                onClick={() => tempcopyStructure(props?.structure, true)}
              >
                {t('DealDesk.Copy')}
              </Button>
            )}
          </div>,
        ]}
      >
        <div className='structureRight noStyle'>
          <div className='list fullHeight'>
            <StructureContent
              Structure={props?.structure?.description}
              editable={false}
              onlyForViewWithNostyle={true}
              isCheckBoxSelection={isCheckBoxSelection}
              selectedLibraryItemNode={selectedLibraryItemNode}
              setSelectedLibraryItemNode={setSelectedLibraryItemNode}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
});
export default StructureLibraryView;
