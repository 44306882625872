import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from './store';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './utils/AppRouter';
import Login from './page/Login';
import LayoutComponent from './component/LayoutComponent';
import { getTokenInit } from './firebase';
import IdleTracker from './component/IdleTracker';
import { getMessaging, onMessage } from 'firebase/messaging';
import { Notify } from './utils/Notify';

import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey("CompanyName=Credebt Exchange,LicensedGroup=Credebt Exchange,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-022455,ExpiryDate=19_November_2022_[v2]_MTY2ODgxNjAwMDAwMA==1029c0d148e5c58ed13aca41f507708e");

const App = observer(() => {

  const {
    AUTH,
    AUTH: { setnotificationData },
    REMINDERSSTORE: { setViewValues },
  } = useStore();
  const { user } = AUTH;


  useEffect(() => {
    AUTH.setAxiosInterceptors();
  }, [AUTH]);

  useEffect(() => {
    var tempVar = JSON.parse(localStorage.getItem('doProxyLogIn'));
    let checkHttps = window.location.protocol === 'https:' ? true : false;

    if ((checkHttps === true || window.location.hostname === 'localhost') && user && (tempVar === false || tempVar === null)) {
      getTokenInit();
      const messaging = getMessaging();


      onMessage(messaging, (payload) => {
        Notify.custome(payload);
        let objtest = payload.data['gcm.notification.data'];
        let isReminderNotification = (JSON.parse(objtest).is_reminder_notification === true || JSON.parse(objtest).is_reminder_notification === 'true')
        // let objtest = payload?.data?.data;
        // let isReminderNotification = (payload?.data?.is_reminder_notification === true || payload?.data?.is_reminder_notification === 'true')
        //
        //if (getfocus === true) {
        if (objtest && isReminderNotification) {
          let dataJson = JSON.parse(objtest);
          setViewValues(dataJson).then(() => {
            setnotificationData(dataJson);
          });
        }
        //}
      });
    }
  }, [//getfocus,
    user, setnotificationData, setViewValues]);


  /**Remove grids and params on version update */
  if (JSON.parse(localStorage.getItem("version")) < process.env.React_APP_version.split(".").pop()) {
    localStorage.removeItem("params")
    for (let key in localStorage) {
      if (key.includes("grid")) {
        localStorage.removeItem(key)
      }
    }
    localStorage.setItem("version", process.env.React_APP_version.split(".").pop());
  }

  return (
    <BrowserRouter basename='/'>
      {AUTH.open_login ? (
        <Login />
      ) : (
        <LayoutComponent>
          {' '}
          <AppRouter />
          <IdleTracker />
        </LayoutComponent>
      )}
    </BrowserRouter>
  );
});

export default App;
