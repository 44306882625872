import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';
import { vsmNotify } from '../../config/messages';

export default class LibrarySearchStore {
  agGrid = null;
  loader = null;
  listData = null;
  search_payload = {};
  deleteValue = null;
  editValue = null;

  per_page = LocalGridConfig.options.paginationPageSize;

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  setDeleteValue = (data) => {
    this.deleteValue = data;
  }
  setEditValue = (data) => {
    this.editValue = data;
  }

  setupGrid = (data) => {
    this.agGrid = data;
    let columnConfig = localStorage.getItem('library_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };
  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_Keynote_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };
  onGridChangedSearch = (params) => {
    localStorage.setItem('search_Keynote_grid', JSON.stringify(params.columnApi.getColumnState()));
  };

  getList = (payload) => {
    if (this.loader) {
      return false;
    } else {
      this.loader = true;
    }

    let merged = { ...payload, ...this.search_payload };

    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.KeyNotesSearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post('deal-desk/key-notes-search/list', merged)
      .then(({ data }) => {
        this.loader = false;
        this.listData = data?.data ? data?.data : [];

        this.agGrid.api.setSortModel(sort ? sort : temp?.KeyNotesSearchStore?.sort);

        if (this.listData?.length === 0) {
          this.agGrid.api.showNoRowsOverlay();
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        this.loader = false;
        return Promise.reject(data);
      });
  };

  setSearch_payload = (data) => {
    this.search_payload = data;
  };

  deleteKeyNote = (payload = {}) => {
    return Axios.post(`deal-desk/key-notes/delete/${this.deleteValue.id}`, payload)
      .then(({ data }) => {
        vsmNotify.success({
          message: data.message,
        });
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditKeyNote = (data) => {
    return Axios.post(`deal-desk/key-notes/edit/${this.editValue.id}`, data)
      .then(({ data }) => {
        vsmNotify.success({
          message: data.message
        });
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
}

decorate(LibrarySearchStore, {
  agGrid: observable,
  loader: observable,
  search_payload: observable,
  listData: observable,
  deleteValue: observable,
  per_page: observable,

  setupGrid: action,
  getList: action,
  setSearch_payload: action,
  deleteKeyNote: action,
  EditKeyNote: action,
  setEditValue: action,
  setPageSize: action,
  setupGridSearch: action,
  onGridChangedSearch: action,
});
