import React, { useMemo, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Divider, Drawer, Form, Row, Spin } from 'antd';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';

import InputComponent from '../../../component/InputComponent';
import useStore from '../../../store';
import debounce from 'lodash/debounce';
import ConnectionCard from './ConnectionCard';

import { default as AddPeopleComponent } from '../../PeopleManagement/People/component/AddComponentNew';
import { default as EditPeopleComponent } from '../../PeopleManagement/People/component/EditComponentNew';
import { default as AddOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/AddComponentNew';
import { default as EditOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/EditComponentNew';
import { default as ViewOrganisation } from '../../OrganisationManagement/AllOrganisations/component/ViewComponent';
import { default as ViewPeopleComponent } from '../../PeopleManagement/People/component/ViewComponent';

import { globalStatus } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const ConnectionComponent = observer(
  ({
    visible,
    close,
    connectionList,
    setConnectionList,
    mainform,
    isFromDealDesk,
    updateSequence,
    primaryConnectionList,
    setPrimaryConnectionList,
    parentFormSelected,
    setOtherConnectionList,
    otherConnectionList,
  }) => {
    const [saving, setSaving] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [disableOrgBtn, setDisableOrgBtn] = useState(true); // USED TO DISABLE ORG ADD BUTTON
    const [disablePeopleBtn, setDisablePeopleBtn] = useState(true); // USED TO DISABLE PEOPLE ADD BUTTON

    const [searchOrgKey, setSearchOrgKey] = useState();
    const [searchPplKey, setSearchPplKey] = useState();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    //ADD-EDIT PEOPLE STATE//
    const [addModal, setAddModal] = useState(false);
    const [editPeopleDrawer, setEditPeopleDrawer] = useState(false);
    const [showPeopleEditBtn, setShowPeopleEditBtn] = useState(false);
    //ADD-EDIT ORGANISATION STATE//
    const [addOrgModal, setAddOrgModal] = useState(false);
    const [editOrgModal, setEditOrgModal] = useState(false);
    const [transactionId, setTransactionId] = useState(null); //Used only for ORG edit.
    const [showOrgEditBtn, setShowOrgEditBtn] = useState(false);
    const [viewOrgDrawer, setViewOrgDrawer] = useState(false);
    const [viewPeopleDrawer, setViewPeopleDrawer] = useState(false);
    const { t } = useTranslation();
    const {
      ORGANISATION,
      PEOPLE,
      AUTH,
      DEALDESKSTORE: { deal_data, getDealDetail },
    } = useStore();

    const [form] = Form.useForm();

    //--------------------START:PEOPLE ADD - EDIT FUNCTIONS--------------------//
    const setPeopleDropDown = (id) => {
      PEOPLE.getAllPeopleSOLDConnections({
        selected_id: [id],
        mapping_type: 2,
        is_connection: true,
      });
      form.setFieldsValue({ full_name: id });
      if (
        mainform?.getFieldValue('full_name') &&
        mainform?.getFieldValue('full_name') === id
      ) {
        PEOPLE.getAllPeople({
          selected_id: [id],
          mapping_type: 2,
        });
      }
      handleChange();
    };
    const openAddModal = () => {
      setAddModal(true);
    };
    const closeAddModal = () => setAddModal(false);

    const openEditPeopleDrawer = () => {
      var payload = { people_id: form.getFieldValue('full_name') };
      PEOPLE.editPeopleLoading = true;
      PEOPLE.getRecordDetail(payload)
        .then((data) => {
          setEditPeopleDrawer(true);
          PEOPLE.editPeopleLoading = false;
        })
        .catch((data) => {
          PEOPLE.editPeopleLoading = false;
        });
    };
    const closeEditPeopleDrawer = () => {
      PEOPLE.getAllPeopleSOLDConnections({
        selected_id: [form.getFieldValue('full_name')],
        mapping_type: 2,
        is_connection: true,
      });
      setEditPeopleDrawer(false);
      handleChange();
    };

    //--------------------END:PEOPLE ADD - EDIT FUNCTIONS--------------------//

    //--------------------START:ORGANISATION ADD - EDIT FUNCTIONS--------------------//
    const setId = (id) => setTransactionId(id);
    const openEditOrgDrawer = () => {
      var payload = { organisation_id: form.getFieldValue('organisation_name') };
      ORGANISATION.setOrganisationValues(null);
      ORGANISATION.getRecordDetail(payload).then((data) => {
        setId(payload.organisation_id);
        ORGANISATION.setEditValues(data);
        setEditOrgModal(true);
      });
    };

    // Close form of edit Organisation
    const closeEditOrgDrawer = () => {
      form.setFieldsValue({ organisation_name: form.getFieldValue('organisation_name') });

      ORGANISATION.getAllROrg({
        selected_id: [form.getFieldValue('organisation_name')],
        mapping_type: 1,
        is_connection: true,
      });

      // ORGANISATION.organisationValues = null;
      // ORGANISATION.setEditValues(null);
      setEditOrgModal(false);
      handleChange();
    };

    // Open form for add new Organisation
    const openAddOrgModal = () => {
      setAddOrgModal(true);
    };
    // Close form for close new Organisation
    const closeAddOrgModal = () => setAddOrgModal(false);

    const setOrgDropDown = (id) => {
      ORGANISATION.getAllROrgSOLDConnections({
        selected_id: [id],
        mapping_type: 1,
        is_connection: true,
      });
      form.setFieldsValue({ organisation_name: id });
      handleChange();
    };

    //--------------------END:ORGANISATION ADD - EDIT FUNCTIONS--------------------//

    const onFinish = () => {
      setSaving(true);
      if (isFromDealDesk) {
        let selectedItems = [];

        connectionList &&
          connectionList.length > 0 &&
          connectionList.forEach((element) => {
            if (element?.role_name) {
              if (element?.role_name !== 'Primary') {
                let newConnectionRolename = '';

                if (element?.connection_role_name && element?.connection_role_name?.length) {
                  element.connection_role_name.map((role_obj, index) => {
                    if (index === 0) {
                      newConnectionRolename = role_obj;
                    }
                    else {
                      newConnectionRolename += `#${role_obj}`;
                    }
                    return null
                  })
                }
                selectedItems.push({
                  id: element.id,
                  type: element?.type,
                  connection_role_name: newConnectionRolename,
                });
              }
            } else {

              selectedItems.push({
                id: element.id,
                type: element?.type,
              });
            }
          });

        let primaryArr = [];
        if (primaryConnectionList.length > 0) {
          let orgData;
          let peopleData;
          primaryConnectionList.map((obj) => {
            let newConnectionRolename = '';

            if (obj?.connection_role_name && obj?.connection_role_name?.length) {
              obj.connection_role_name.map((role_obj, index) => {
                if (index === 0) {
                  newConnectionRolename = role_obj;
                }
                else {
                  newConnectionRolename += `#${role_obj}`;
                }
                return null
              })
            }
            if (obj.type === 1) {
              orgData = newConnectionRolename;
              if (orgData) {
                primaryArr.push({ [`${obj.id}_org`]: orgData })
              }
            }
            if (obj.type === 2) {
              peopleData = newConnectionRolename;
              if (peopleData) {
                primaryArr.push({ [`${obj.id}_people`]: peopleData })
              }
            }
            return obj;
          })
        }

        if (!disabled) {
          updateSequence({ connections: selectedItems, primary_connection: primaryArr }, deal_data?.lead_information?.id)
            .then((data) => {
              getDealDetail(deal_data?.lead_information?.id);
            })
            .finally(() => {
              form.resetFields();
              ORGANISATION.dropdown_ROrg_connection_list = null;
              PEOPLE.dropdown_people_connection_list = null;
              setDisabled(true);
            });
        }
      } else {
        let selectedItems = [];
        connectionList &&
          connectionList.length > 0 &&
          connectionList.forEach((element) => selectedItems.push(element.id));

        mainform.setFieldsValue({
          connections: selectedItems,
        });
      }
      // ORGANISATION.dropdown_ROrg_connection_list = null;
      // PEOPLE.dropdown_people_connection_list = null;
      // close();
      onClose(true);
      setSaving(false);
    };

    //----------------CALLED ON PEOPLE SEARCH ON TYPE--------------------------//
    const handleSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchPplKey(val);
        PEOPLE.getAllPeopleSOLDConnections({
          search_for: val,
          mapping_type: 2,
          is_connection: true,
        });
      };

      return debounce(loadOptions, 500);
    }, [PEOPLE]);

    //----------------CALLED ON ORGANISATION SEARCH ON TYPE--------------------------//
    const handleOrgSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchOrgKey(val);
        ORGANISATION.getAllROrgSOLDConnections({
          search_for: val,
          mapping_type: 1,
          is_connection: true,
        });
      };

      return debounce(loadOptions, 500);
    }, [ORGANISATION]);

    const scrollToNode = debounce((id) => {
      document.getElementById(id).scrollIntoView();
    }, 100);

    const handleChange = debounce(() => {
      if (form.getFieldValue('organisation_name')) {
        setDisableOrgBtn(false);
        setShowOrgEditBtn(true);
      } else {
        setDisableOrgBtn(true);
        setShowOrgEditBtn(false);
      }

      if (form.getFieldValue('full_name')) {
        setDisablePeopleBtn(false);
        setShowPeopleEditBtn(true);
      } else {
        setDisablePeopleBtn(true);
        setShowPeopleEditBtn(false);
      }
      setDisabled(false);
    }, 200);

    const addItem = (type) => {
      if (type === 1 && form.getFieldValue('organisation_name')) {
        let orgDetails =
          ORGANISATION.dropdown_ROrg_connection_list &&
          ORGANISATION.dropdown_ROrg_connection_list.length > 0 &&
          ORGANISATION.dropdown_ROrg_connection_list.find(
            (element) => element.id === form.getFieldValue('organisation_name')
          );

        if (orgDetails) {
          let checkConnection =
            connectionList &&
            connectionList.length > 0 &&
            connectionList.find((el) => el.type === 1 && el.id === orgDetails.id);

          if (!checkConnection) {
            setConnectionList([
              ...connectionList,
              {
                type: type,
                id: form.getFieldValue('organisation_name'),
                name: orgDetails?.entity_name,
                connection_role_name: [],
                email:
                  orgDetails?.email &&
                    Array.isArray(orgDetails?.email) &&
                    orgDetails.email.length > 0
                    ? orgDetails?.email[0].contact_value
                    : 'N/A',
                phone:
                  orgDetails?.phone &&
                    Array.isArray(orgDetails?.phone) &&
                    orgDetails.phone.length > 0
                    ? orgDetails?.phone[0].contact_value
                    : 'N/A',
              },
            ]);
          }
          scrollToNode(`connection_div_${orgDetails.id}`);
          form.setFieldsValue({ organisation_name: null });
        }
      }

      if (type === 2 && form.getFieldValue('full_name')) {
        let peopleDetails =
          PEOPLE.dropdown_people_connection_list &&
          PEOPLE.dropdown_people_connection_list.length > 0 &&
          PEOPLE.dropdown_people_connection_list.find(
            (element) => element.id === form.getFieldValue('full_name')
          );
        if (peopleDetails) {
          let checkConnection =
            connectionList &&
            connectionList.length > 0 &&
            connectionList.find((el) => el.type === 2 && el.id === peopleDetails.id);
          if (!checkConnection) {
            setConnectionList([
              ...connectionList,
              {
                type: type,
                id: form.getFieldValue('full_name'),
                name: peopleDetails?.entity_name,
                connection_role_name: [],
                email:
                  peopleDetails?.email &&
                    Array.isArray(peopleDetails?.email) &&
                    peopleDetails.email.length > 0
                    ? peopleDetails?.email[0].contact_value
                    : 'N/A',
                phone:
                  peopleDetails?.phone &&
                    Array.isArray(peopleDetails?.phone) &&
                    peopleDetails.phone.length > 0
                    ? peopleDetails?.phone[0].contact_value
                    : 'N/A',
              },
            ]);
          }
          scrollToNode(`connection_div_${peopleDetails.id}`);
          form.setFieldsValue({ full_name: null });
        }
      }
      handleChange();
      forceUpdate();
    };

    const removeItem = (item) => {
      if (connectionList && connectionList.length > 0) {
        let filteredConnection = connectionList.filter((e) => e.id !== item.id);
        setConnectionList(filteredConnection);
      }
      setDisabled(false);
      forceUpdate();
    };

    const onClose = (reload = false) => {
      form.resetFields();
      ORGANISATION.dropdown_ROrg_connection_list = null;
      PEOPLE.dropdown_people_connection_list = null;
      setSaving(false);
      setDisabled(true);
      close(reload);
    };

    //----------------------START:ORGANISATION VIEW FUNCTIONS----------------------------//
    const handleViewOrgDetailCall = debounce((data) => {
      ORGANISATION.viewPeopleLoading = true;
      ORGANISATION.getViewOrgDetail(data?.id)
        .then((data) => {
          if (data?.cash_transactions && data?.cash_transactions.length) {
            data?.cash_transactions.forEach((item) => {
              item.status_name = globalStatus(
                'cash_transaction_status',
                'key',
                item.status
              );
            });
          }
          ORGANISATION.viewOrgValues = data;
          PEOPLE.list_related_ORG = data?.related_organisations;
          PEOPLE.list_related_Ppl = data?.related_peoples;
          PEOPLE.list_trade_transaction = data?.trade_transactions;
          PEOPLE.list_cash_transaction = data?.cash_transactions;
          PEOPLE.list_banks = data?.banks;
          ORGANISATION.viewPeopleLoading = false;
        })
        .catch((ORGANISATION.viewPeopleLoading = false));
    }, 500);

    const openViewOrgDrawer = (data) => {
      if (AUTH.checkPermission(16, 'view-details')) {
        ORGANISATION.viewPeopleLoading = true;
        setViewOrgDrawer(true);
        handleViewOrgDetailCall(data);
      }
    };

    const closeViewOrgDrawer = () => {
      // ORGANISATION.viewOrgValues = null;
      setViewOrgDrawer(false);
    };
    //----------------------END:ORGANISATION VIEW FUNCTIONS----------------------------//

    //----------------------START:PEOPLE VIEW FUNCTIONS----------------------------//
    const handleViewPeopleDetailCall = debounce((data) => {
      PEOPLE.viewPeopleLoading = true;
      PEOPLE.setViewValues(data);
      PEOPLE.getViewPplDetail(data?.id)
        .then((data) => {
          PEOPLE.viewPeopleLoading = false;
        })
        .catch((data) => {
          PEOPLE.viewPeopleLoading = false;
        });
    }, 300);

    const openPeopleViewDrawer = (data) => {
      if (AUTH.checkPermission(18, 'view-details')) {
        PEOPLE.viewPeopleLoading = true;
        setViewPeopleDrawer(true);
        handleViewPeopleDetailCall(data);
      }
    };
    const closePeopleViewDrawer = () => {
      // PEOPLE.setViewValues([]);
      // PEOPLE.peopleValues = null;
      setViewPeopleDrawer(false);
    };
    //----------------------END:PEOPLE VIEW FUNCTIONS----------------------------//

    let rejected_org = [];
    let rejected_people = [];
    connectionList &&
      connectionList.forEach((x) => {
        if (x && x.type === 1) {
          rejected_org.push(x.id);
        }
        if (x && x.type === 2) {
          rejected_people.push(x.id);
        }
      });

    primaryConnectionList &&
      primaryConnectionList.forEach((x) => {
        if (x && x.type === 1) {
          rejected_org.push(x.id);
        }
        if (x && x.type === 2) {
          rejected_people.push(x.id);
        }
      });

    parentFormSelected &&
      parentFormSelected?.forEach((x) => {
        if (x && x.type === 1) {
          rejected_org.push(x.id);
        }
        if (x && x.type === 2) {
          rejected_people.push(x.id);
        }
      });
    //----------------------- Connection Role Function--------------------------------------//
    const handleConnnectionChange = (value, id, primaryData) => {
      if (primaryData.isPrimary) {
        let newList = primaryConnectionList;
        let connectionIndex = newList.findIndex(x => x.id === id);
        if (connectionIndex !== -1) {
          let connectionRoleArr = newList[connectionIndex]["connection_role_name"] || [];
          if (connectionRoleArr.includes(value)) {
            let removeIndex = connectionRoleArr.findIndex(x => x === value);
            if (removeIndex !== -1) {
              connectionRoleArr.splice(removeIndex, 1);
            }
          }
          else {
            connectionRoleArr.push(value)
          }
          newList[connectionIndex]["connection_role_name"] = connectionRoleArr;
          setPrimaryConnectionList(newList);
        }
      }
      else {
        let newList = connectionList;
        let connectionIndex = newList.findIndex(x => x.id === id);
        if (connectionIndex !== -1) {
          let connectionRoleArr = newList[connectionIndex]["connection_role_name"] || [];
          if (connectionRoleArr.includes(value)) {
            let removeIndex = connectionRoleArr.findIndex(x => x === value);
            if (removeIndex !== -1) {
              connectionRoleArr.splice(removeIndex, 1);
            }
          }
          else {
            connectionRoleArr.push(value)
          }
          newList[connectionIndex]["connection_role_name"] = connectionRoleArr;
          setConnectionList(newList);
        }
      }
      setDisabled(false);
      forceUpdate();
    }


    /**Connections Drawer Component */
    return (
      <>
        <Drawer
          title={`${t('LeadListing.Connections')}`}
          placement='right'
          width={'1100px'}
          onClose={() => onClose()}
          visible={visible}
          zIndex={
            editOrgModal ||
              addOrgModal ||
              addModal ||
              editPeopleDrawer ||
              viewOrgDrawer ||
              viewPeopleDrawer
              ? 1003
              : 1004
          }
          destroyOnClose={true}
          footer={[
            <div className='text-right' key='1'>
              <Button
                key='1'
                form='connectionForm'
                className='mr-10'
                loading={saving}
                htmlType='submit'
                type='primary'
                shape='round'
                size='medium'
                disabled={disabled}
              >
                {t('Common_Data.Save')}
              </Button>
              {isFromDealDesk && (
                <Button key='2' shape='round' onClick={() => onClose()}>
                  {t('Common_Data.Cancel')}
                </Button>
              )}
            </div>,
          ]}
        >
          {/** Top Organisation and People dropdown and edit/ add buttons   */}
          <Form
            form={form}
            layout='vertical'
            id={'connectionForm'}
            onFinish={onFinish}
            className='innerForm'
          >
            <Row gutter={15}>
              <Col span={6}>
                <InputComponent
                  label={`${t('Contracts.Organisation_Name')}`}
                  type='labelOnly'
                  tooltip={`${t('LeadListing.Add_Multiple_Organisation')}`}
                />

                <div className='suffixIcon'>
                  <InputComponent
                    name='organisation_name'
                    placeholder={`${t('Contracts.Organisation_Name_Select')}`}
                    allowClear
                    type='select'
                    onSearch={handleOrgSearch}
                    notFoundContent={
                      ORGANISATION.fetchingConnection ? (
                        <Spin size='small' />
                      ) : searchOrgKey ? (
                        `${t('Common_Data.No_Record_Found')}.`
                      ) : null
                    }
                    options={{
                      values: ORGANISATION.dropdown_ROrg_connection_list,
                      value_key: 'id',
                      text_key: 'entity_name',
                      rejected_keys: rejected_org,
                    }}
                    onChange={handleChange}
                  />
                  {AUTH.checkPermission(16, 'add') ? (
                    <PlusCircleOutlined
                      className='extraFromIcon'
                      onClick={openAddOrgModal}
                    />
                  ) : null}

                  {AUTH.checkPermission(16, 'edit') ? (
                    form.getFieldValue('organisation_name') || showOrgEditBtn ? (
                      <EditOutlined
                        className='extraFromIcon'
                        onClick={() => openEditOrgDrawer()}
                      />
                    ) : null
                  ) : null}
                </div>
              </Col>

              <Col span={6}>
                <Button
                  shape='round'
                  size='medium'
                  onClick={() => addItem(1)}
                  style={{ marginTop: '15px' }}
                  disabled={disableOrgBtn}
                >
                  {t('Common_Data.Add')}
                </Button>
              </Col>

              <Col span={6}>
                <InputComponent
                  label={`${t('LeadListing.Person_Name')}`}
                  type='labelOnly'
                  tooltip={`${t('LeadListing.Add_Multiple_People')}`}
                />
                <div className='suffixIcon'>
                  <InputComponent
                    name='full_name'
                    placeholder={`${t('LeadListing.Select_Person_Name')}`}
                    required
                    type='select'
                    showSearch={true}
                    onSearch={handleSearch}
                    allowClear
                    notFoundContent={
                      PEOPLE.fetchingConnection ? (
                        <Spin size='small' />
                      ) : searchPplKey ? (
                        `${t('Common_Data.No_Record_Found')}.`
                      ) : null
                    }
                    options={{
                      values: PEOPLE.dropdown_people_connection_list,
                      value_key: 'id',
                      text_key: 'entity_name',
                      rejected_keys: rejected_people,
                    }}
                    onChange={handleChange}
                  />

                  <PlusCircleOutlined className='extraFromIcon' onClick={openAddModal} />
                  {AUTH.checkPermission(18, 'edit') ? (
                    form.getFieldValue('full_name') || showPeopleEditBtn ? (
                      <EditOutlined
                        className='extraFromIcon'
                        onClick={openEditPeopleDrawer}
                      />
                    ) : null
                  ) : null}
                </div>
              </Col>

              <Col span={6}>
                <Button
                  shape='round'
                  size='medium'
                  onClick={() => addItem(2)}
                  style={{ marginTop: '15px' }}
                  disabled={disablePeopleBtn}
                >
                  {t('Common_Data.Add')}
                </Button>
              </Col>
            </Row>
          </Form>

          <div className='connectionDataBox'>
            {/** List of Primary Connection boxes */}
            {primaryConnectionList && primaryConnectionList.length > 0 && (
              <div className='selectedConnectionBox'>
                <h5>{t('LeadListing.Primary_Connections')}</h5>
                <ConnectionCard
                  items={primaryConnectionList}
                  setItems={setPrimaryConnectionList}
                  removeItem={removeItem}
                  handleConnnectionChange={handleConnnectionChange}
                  showRoleDropdown={true}
                  setDisabled={setDisabled}
                  isPrimaryConnection={true}
                  openViewOrgDrawer={openViewOrgDrawer}
                  openPeopleViewDrawer={openPeopleViewDrawer}
                />
                {((connectionList && connectionList.length > 0) ||
                  (otherConnectionList && otherConnectionList.length > 0)) && (
                    <Divider className='mt-10 mb-20' />
                  )}
              </div>
            )}

            {/** List of Related Connection boxes */}
            {connectionList && connectionList.length > 0 && (
              <div className='selectedConnectionBox'>
                {primaryConnectionList && primaryConnectionList.length > 0 && (
                  <h5>{t('LeadListing.Related_Connections')}</h5>
                )}

                <ConnectionCard
                  items={connectionList}
                  setItems={setConnectionList}
                  removeItem={removeItem}
                  handleConnnectionChange={handleConnnectionChange}
                  setDisabled={setDisabled}
                  showRoleDropdown={true}
                  isPrimaryConnection={false}
                  openViewOrgDrawer={openViewOrgDrawer}
                  openPeopleViewDrawer={openPeopleViewDrawer}
                />
                {otherConnectionList && otherConnectionList.length > 0 && (
                  <Divider className='mt-10 mb-20' />
                )}
              </div>
            )}

            {/** List of Other Connection boxes */}
            {otherConnectionList && otherConnectionList.length > 0 && (
              <div className='selectedConnectionBox'>
                <h5>{t('LeadListing.Other')} {t('LeadListing.Connections')} </h5>

                <ConnectionCard
                  items={otherConnectionList}
                  setItems={setOtherConnectionList}
                  removeItem={removeItem}
                  showRoleDropdown={false}
                  setDisabled={setDisabled}
                  isPrimaryConnection={true}
                  openViewOrgDrawer={openViewOrgDrawer}
                  openPeopleViewDrawer={openPeopleViewDrawer}
                />
              </div>
            )}
          </div>
          {/** Add, Edit and View components for people and organisation */}
          <AddPeopleComponent
            visible={addModal}
            isLead={true}
            isChildDrawer={true}
            close={closeAddModal}
            setPeopleDropDown={setPeopleDropDown}
          />
          <EditPeopleComponent
            visible={editPeopleDrawer}
            close={closeEditPeopleDrawer}
            isChildDrawer={true}
            setPeopleDropDown={setPeopleDropDown}
          />
          <AddOrgComponent
            visible={addOrgModal}
            isLead={true}
            isChildDrawer={true}
            close={closeAddOrgModal}
            setOrgDropDown={setOrgDropDown}
          />
          <EditOrgComponent
            transactionId={transactionId}
            setId={setId}
            isChildDrawer={true}
            visible={editOrgModal}
            close={closeEditOrgDrawer}
            setOrgDropDown={setOrgDropDown}
          />
          <ViewOrganisation
            visible={viewOrgDrawer}
            isChildDrawer={true}
            close={closeViewOrgDrawer}
          />
          <ViewPeopleComponent
            visible={viewPeopleDrawer}
            isChildDrawer={true}
            close={closePeopleViewDrawer}
          />
        </Drawer>
        <TimeTracker
          page={"connections"}
          stop={!visible || addModal || editPeopleDrawer || addOrgModal || editOrgModal || viewOrgDrawer || viewPeopleDrawer}
        />
      </>
    );
  }
);

export default ConnectionComponent;
