import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import { Button, Tooltip, Image, Popover, Space } from 'antd';
import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import GridConfig from '../../../../config/GridConfig';
import DateTimeConfirmElements from '../elements/DateTimeConfirmElements';
import HourglassConfirmElements from '../elements/HourglassConfirmElements';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../../component/DatePickerCostume';

const ListComponent = observer((props) => {
  const { t } = useTranslation();
  //const [count, setCount] = useState(0);
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();
  const [hourglassConfirm, setHourglassConfirm] = useState();

  const {
    openEditModal,
    openDeleteModal,
    openRestoreModal,
    openRoleListing,
    openBankAccounts,
    openViewDrawer,
    openCloneDrawer,
    WasteManagement,
    SearchPage,
    onCellKeyDown,
    showCheckbox = false,
    selectionChanged,
  } = props;

  const {
    AUTH,
    ORGANISATION,
    SEARCHSTORE,
    ORGANISATION: { setupGrid, onGridChanged },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();

  useEffect(() => {
    if (WasteManagement) {
      ORGANISATION.waste = true;
      ORGANISATION.search_payload = {};
    } else {
      ORGANISATION.waste = false;
      ORGANISATION.search_payload = {};
    }
  }, [ORGANISATION, SearchPage, SEARCHSTORE, WasteManagement, ORGANISATION.total]);

  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(data);
      setOpenDateTimeModal(true);
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  const openConfirmHourglassModal = (data) => {
    setOpenHourglassModal(true);
    //setCount(count + 1);
    setHourglassConfirm(data);
  };
  const closeHourglassModal = () => setOpenHourglassModal(false);

  const ActionRenderer = (props) => {
    const {
      ORGANISATION: { getLeadTransactionID },
    } = useStore();
    let history = useHistory();

    const [extrafooter, setExtrafooter] = useState(null);

    // const disabledDate = (current) => {
    //   // Can not select days before today and today
    //   return current && current <= moment().subtract(1, 'days').endOf('day');
    // };

    // const disabledHours = (e) => {
    //   let hours = [19, 20, 21, 22, 23];
    //   for (let i = 0; i < 7; i++) {
    //     hours.push(i);
    //   }
    //   return hours;
    // };

    const content = (
      <div className='ticketsPopoverIcons'>
        <Space size={15}>
          {/* {props?.data?.reset_count !== null && ( */}
          <span
            className='Calendar cursor'
            onMouseEnter={() => {
              if (props.data?.reset_count !== null) {
                getReminderDatepickerExtraFooter(
                  props.data,
                  AUTH.global_fulldate_format,
                  {
                    type: 'organisations',
                  }
                ).then((data) => {
                  setExtrafooter(data);
                });
              }
            }}
          >
            <Tooltip
              title={props.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`}
            >
              <DatePickerCostume
                name='Calendar'
                title={`${t('Common_Data.Calendar')}`}
                className='cursor'
                showTime
                disabledDate={true}
                // disabledHours={() => disabledHours()}
                // minuteStep={15}
                // hideDisabledOptions
                showNow={false}
                onOk={(e) => openConfirmDateTimeModal(e, props.data)}
                renderExtraFooter={() => extrafooter}
              />
            </Tooltip>
            <img
              className='menuicon'
              title={`${t('Common_Data.Calendar')}`}
              alt=''
              src={AUTH.GetThemedImage('Calendar')}
            />
          </span>
          {/* )} */}
          {/* {props?.data?.reset_count !== null && ( */}
          <>
            <img
              className='menuicon'
              title={`${t('OrganisationManagement.Hourglass')}`}
              alt=''
              src={AUTH.GetThemedImage('Reminders_icn')}
              onClick={() => openConfirmHourglassModal(props.data)}
            />
            <span>{props.data?.reset_count ? props.data?.reset_count : 0}</span>
          </>
          {/* )} */}
        </Space>
      </div>
    );

    return (
      <div className='action-column'>
        {WasteManagement === true ? (
          <>
            {AUTH.checkPermission(34, 'view-details') && (
              <Button
                type='text'
                title={`${t('Common_Data.View')}`}
                onClick={() => {
                  openViewDrawer(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('View')} />
              </Button>
            )}
            {AUTH.checkPermission(34, 'delete') && (
              <Button
                type='text'
                title={`${t('Common_Data.Delete')}`}
                onClick={() => {
                  openDeleteModal(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            )}
            {AUTH.checkPermission(34, 'restore') && (
              <Button
                type='text'
                title={`${t('Common_Data.Restore')}`}
                onClick={() => {
                  openRestoreModal(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Restore')} />
              </Button>
            )}
          </>
        ) : (
          <>
            {/* {props.data.leads_transactions_id > 0 && ( */}
            <Button
              type='text'
              title={props.data.leads_transactions_id > 0 ? `${t('OrganisationManagement.History')}` : `${t('OrganisationManagement.Start')}`}
              onClick={() => {
                if (props.data.leads_transactions_id > 0) {
                  history.push(`/organisation/${props.data?.leads_transactions_id}`);
                } else {
                  getLeadTransactionID(props.data.id).then((data) => {
                    history.push(`/organisation/${data.leads_transactions_id}`);
                  });
                }
              }}
            >
              <img alt='' className='menuicon' src={AUTH.GetThemedImage('Deal_icn')} />
            </Button>
            {/* )} */}
            {AUTH.checkPermission(16, 'edit') ? (
              // &&
              // AUTH?.user?.user_role &&
              // props?.data &&
              // AUTH?.user?.user_role?.user_role_id === 1
              <Button
                title={`${t('Common_Data.Edit')}`}
                type='text'
                onClick={() => {
                  openEditModal(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Edit')} />
              </Button>
            ) : null}

            {AUTH.checkPermission(16, 'delete') &&
              props?.data?.id !== 1 &&
              (AUTH?.user?.user_role?.user_role_id === 1 ||
                AUTH?.user?.id === props?.data?.created_by) ? (
              props?.data?.transactions !== null ? (
                <Tooltip
                  placement='topRight'
                  color={'red'}
                  title={
                    `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Transactions')}.`
                  }
                >
                  <Button type='text' disabled>
                    <img
                      alt=''
                      className='menuicon disabled'
                      src={AUTH.GetThemedImage('Waste')}
                    />
                  </Button>
                </Tooltip>
              ) : props?.data?.associated_ledgers === null ? (
                <Button
                  title={`${t('Common_Data.Delete')}`}
                  type='text'
                  onClick={() => {
                    openDeleteModal(props.data);
                  }}
                >
                  <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              ) : props.data?.organisation_role_mapping_count > 0 ||
                props.data?.related_organisation_role_mapping_count > 0 ? (
                <Tooltip
                  placement='topRight'
                  color={'red'}
                  title={
                    `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Role')}.`
                  }
                >
                  <Button type='text' disabled>
                    <img
                      alt=''
                      className='menuicon disabled'
                      src={AUTH.GetThemedImage('Waste')}
                    />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  placement='topRight'
                  color={'red'}
                  title={
                    `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Ledger')}.`
                  }
                >
                  <Button type='text' disabled>
                    <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
                  </Button>
                </Tooltip>
              )
            ) : (
              <>
                <Tooltip
                  placement='topRight'
                  color={'red'}
                  title={
                    `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Role')}.`
                  }
                >
                  <Button type='text' disabled>
                    <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
                  </Button>
                </Tooltip>
              </>
            )}

            {AUTH.checkPermission(16, 'view-details') && (
              <Button
                type='text'
                title={`${t('Common_Data.View')}`}
                onClick={() => {
                  openViewDrawer(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('View')} />
              </Button>
            )}

            {
              <Button type='text' title={`${t('Common_Data.View')} ${t('LeadListing.Leads')}`}>
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('List_view')}
                  // onClick={() => {
                  //   localStorage.setItem(
                  //     'selected_leads',
                  //     JSON.stringify({
                  //       current: 'org',
                  //       people: null,
                  //       org: props.data.id,
                  //     })
                  //   );
                  onClick={() => {
                    localStorage.setItem(
                      'allLeadsByOrg',
                      JSON.stringify({"organisation_name.organisation_name":{"values":[props.data.id],"filterType":"set"}})
                    );
                    window.open(`/leads/all`, '_blank');
                  }}
                />
              </Button>
            }

            {AUTH.checkPermission(16, 'clone') && (
              <Button
                title={`${t('Common_Data.Clone')}`}
                type='text'
                onClick={() => {
                  openCloneDrawer(props.data);
                }}
              >
                <img
                  alt=''
                  className='menuicon'
                  src={AUTH.GetThemedImage('Clone_Record')}
                />
              </Button>
            )}
            {/* {AUTH.checkPermission(16, 'role-mapping-list') && (
              <Button
                title={'Role Mapping'}
                type='text'
                onClick={() => {
                  openRoleListing(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Resign')} />
              </Button>
            )} */}
            {/* {props?.data?.reset_count !== null && ( */}
            <Popover
              content={content}
              placement='bottomRight'
              trigger='hover'
              overlayClassName='profileMenu'
              arrowPointAtCenter
            >
              <Button type='text' title={`${t('Common_Data.Clone')}`}>
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('DropdownBox')}
                />
              </Button>
            </Popover>
            {/* )} */}
          </>
        )}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        checkboxSelection: showCheckbox,
        valueGetter: function (params) {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        tooltipValueGetter: (params) => {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },

        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`,
        field: 'organisation_name',
        tooltipField: 'organisation_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        headerTooltip: `${t('Common_Data.Credebt_Classification')}`,
        field: 'global_industries.name',
        tooltipField: 'global_industries.name',
        filter: 'agSetColumnFilter',

        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            ORGANISATION.getIndustries().then((data) => {
              params.success([...data?.data?.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('OrganisationManagement.Trade')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('OrganisationManagement.Trade')} ${t('Common_Data.Name')}`,
        field: 'trade_name',
        tooltipField: 'trade_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('OrganisationManagement.Role')} (${t('OrganisationManagement.Associated')} ${t('Common_Data.Organisation')})`,
        headerTooltip: `${t('OrganisationManagement.Role')} (${t('OrganisationManagement.Associated')} ${t('Common_Data.Organisation')})`,
        field: 'organisation_role_mapping',
        tooltipValueGetter: (params) =>
          params.data &&
            params.data?.organisation_role_mapping &&
            params.data?.organisation_role_mapping.length > 0
            ? params.data?.organisation_role_mapping.map((item, i) => {
              return item.role_name + '(' + item.organisation_name + ')';
            })
            : '',
        valueGetter: (params) =>
          params.data &&
            params.data?.organisation_role_mapping &&
            params.data?.organisation_role_mapping.length > 0
            ? params.data?.organisation_role_mapping.map((item, i) => {
              return item.role_name + '(' + item.organisation_name + ')';
            })
            : '',
        filter: false,
        sortable: false,
        wrapText: false,
      },
      {
        headerName: `${t('Common_Data.Connected_Peoples')}`,
        headerTooltip: `${t('Common_Data.Connected_Peoples')}`,
        field: 'connected_peoples',
        tooltipField: 'connected_peoples'
      },
      {
        headerName: `${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`,
        field: 'registration_number',
        tooltipField: 'registration_number',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Country')}`,
        headerTooltip: `${t('Common_Data.Country')}`,
        field: 'global_countries.country_name',
        tooltipField: 'global_countries.country_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            ORGANISATION.getCountryFilter().then((data) => {
              params.success([...data?.data?.map((x) => x.country_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        headerTooltip: `${t('Common_Data.Status')}`,
        field: 'status.status_name',
        tooltipField: 'status.status_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            ORGANISATION.getStatusFilter().then((data) => {
              params.success([...data?.data?.map((x) => x.status_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('OrganisationManagement.Logo')}`,
        headerTooltip: `${t('OrganisationManagement.Logo')}`,
        field: 'logo_url',
        cellRendererFramework: function (data) {
          return data?.data && data?.data?.logo_url ? (
            <div className='text-center'>
              <Image
                style={{ height: '46px', width: 'auto' }}
                src={process.env.React_APP_API_URL + data?.data?.logo_url}
                alt=''
              />
            </div>
          ) : (
            ''
          );
        },

        filter: false,
        sortable: false,
      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        headerTooltip: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        // tooltipField: 'updated_at',
        filter: 'agDateColumnFilter',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={AUTH.getLocalTime(params?.data?.updated_at,AUTH.global_fulldate_format)}>
              {AUTH.getLocalTime(params?.data?.updated_at,AUTH.global_fulldate_format)}
            </Tooltip>
          );
        },
        // cellRenderer: (params) => {
        //   return AUTH.getLocalTime(params?.data?.updated_at);
        // },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `#${t('Common_Data.ID')}`,
        field: 'id',
        tooltipField: 'id',
        filter: 'agNumberColumnFilter',
        width: 120,
      },
      {
        headerName: `${t('OrganisationManagement.Exchange_Ref')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('OrganisationManagement.Exchange_Ref')} ${t('Common_Data.ID')}`,
        field: 'crm_id',
        tooltipField: 'crm_id',
        filter: 'agTextColumnFilter',
        width: 80,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: WasteManagement === true ? 140 : SearchPage === true ? 240 : 240,
        minWidth: WasteManagement === true ? 140 : SearchPage === true ? 240 : 240,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  // Columns size change, move, pin
  let columns = [];
  let columnConfig = localStorage.getItem('org_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data?.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/organisation/${params.node.data?.leads_transactions_id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/organisation/${params.node.data?.leads_transactions_id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  return (
    <div className={`ag-theme-alpine grid_wrapper ${SearchPage ? "paginationAtTop" : ""}`}
      style={{
        height:
          WasteManagement === true
            ? 'calc(100vh - 11rem)'
            : SearchPage
              ? 'calc(100vh - 13rem)'
              : list_fullscreen
                ? fullscreen_heigthwidth
                : props.AllView
                  ? '150px'
                  : 'calc(100vh - 15rem)',
        minHeight: props.AllView ? '100px' : '250px',
      }}
    >
      <AgGridReact
        modules={AllModules}
        columnDefs={gridOptions.columnDefs}
        defaultColDef={{
          ...GridConfig.defaultColDef,
          width: 120,
          minWidth: 70,
          flex: 1,
          // floatingFilter: !SearchPage,
        }}
        columnTypes={GridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openEditModal,
          openDeleteModal,
          openRoleListing,
          openBankAccounts,
          openViewDrawer,
          WasteManagement,
        }}
        onGridReady={setupGrid}
        //gridOptions={GridConfig.options}
        gridOptions={{
          ...GridConfig.options,
          pagination: true,
          rowHeight: 30,
          onRowDoubleClicked: (event) => openViewDrawer(event?.data),
          getContextMenuItems: getContextMenuItems,
        }}
        suppressRowClickSelection={true}
        rowSelection='multiple'
        onColumnResized={onGridChanged}
        onColumnMoved={onGridChanged}
        onColumnPinned={onGridChanged}
        onCellKeyDown={onCellKeyDown}
        onFilterChanged={(e) => {
          if (
            e.hasOwnProperty('afterFloatingFilter') &&
            ORGANISATION.selectedGridFilter
          ) {
            ORGANISATION.setSelectedGridFilter(null);
          }
        }}
        onSelectionChanged={selectionChanged}
      />
      <DateTimeConfirmElements
        visible={openDateTimeModal}
        close={closeDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <HourglassConfirmElements
        visible={openHourglassModal}
        close={closeHourglassModal}
        hourglassConfirm={hourglassConfirm}
      />
    </div>
  );
});

export default ListComponent;
