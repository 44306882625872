import { Layout } from 'antd';
import React from 'react';
import TopNotificationModule from '../rightsideModule/TopNotificationModule';
import SnapNoteModule from '../rightsideModule/SnapNoteModule';
import ConnectionsModule from '../rightsideModule/ConnectionsModule';
import TagsTicketsModule from '../rightsideModule/TagsTicketsModule';
import FoldersFilesModule from '../rightsideModule/FoldersFilesModule';
import useStore from '../store';
import { observer } from 'mobx-react';

import ReminderNotify from '../page/Reminders/component/ReminderNotify';

const RightsideComponent = observer((props) => {
  const {
    AUTH,
    DEALDESKSTORE: { deal_data },
  } = useStore();

  return (
    AUTH.app_color_theme && (
      <Layout.Sider className='right__panel'>
        <TopNotificationModule />
        {(AUTH.checkPermission(48, 'snap-note-organisation') ||
          AUTH.checkPermission(48, 'snap-note-deals') ||
          AUTH.checkPermission(48, 'snap-note-tickets') ||
          AUTH.checkPermission(48, 'snap-note-notes') ||
          AUTH.checkPermission(48, 'snap-note-people') ||
          AUTH.checkPermission(48, 'unread-snap-notes')) && <SnapNoteModule />}
        {deal_data && (
          <>
            <ConnectionsModule />
            {AUTH.checkPermission(50, 'manage') && <TagsTicketsModule />}
            {(AUTH.checkPermission(51, 'sort') || AUTH.checkPermission(51, 'upload')) && (
              <FoldersFilesModule />
            )}
          </>
        )}

        <ReminderNotify />
        
      </Layout.Sider>
    )
  );
});
export default RightsideComponent;
