import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import FormComponent from './FormComponentNew';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import useStore from '../../../store';
import { vsmNotify, vsmLeadsList } from '../../../config/messages';
import { default as AddPeopleComponent } from '../../PeopleManagement/People/component/AddComponentNew';
import { default as AddOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/AddComponentNew';
import { default as EditPeopleComponent } from '../../PeopleManagement/People/component/EditComponentNew';
import { default as EditOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/EditComponentNew';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const AddLeadComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    LEADSSTORE,
    PEOPLE,
    ORGANISATION,
    //ORGANISATION: { getMappedOrg },
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    DEALDESKSTORE,
    DEALDESKNOTESOUTBOUND,
    DEALDESKNOTESEQUENCE,
  } = useStore();

  const location = useLocation();
  let history = useHistory();

  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addOrgModal, setAddOrgModal] = useState(false);
  const [editPeopleDrawer, setEditPeopleDrawer] = useState(false);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null); //Used only for ORG edit.

  const [failedReason, setFailedReason] = useState(null); //Used only for ORG edit.
  const [connectionList, setConnectionList] = useState([]);
  const [primaryConnectionList, setPrimaryConnectionList] = useState([]);
  const [getSoldStatus, setSoldStatus] = useState({});

  const close = () => {
    props.close();
    form.resetFields();
    setConnectionList([]);
    setPrimaryConnectionList([]);
    setDisabled(true);
    setSaving(false);
  };

  const handleSubmit = (data) => {
    setSaving(true);

    //--------------------- Formatting Finance/Turn Over values ---------------------------------//

    let finance_value = data.finance_value;
    if (finance_value && (finance_value?.includes('k') || finance_value?.includes('K'))) {
      let splitArr = finance_value?.includes('K') ? finance_value.split('K') : finance_value.split('k');
      finance_value = Number(splitArr[0]) * 1000;
    }
    else if (finance_value && (finance_value?.includes('m') || finance_value?.includes('M'))) {
      let splitArr = finance_value?.includes('M') ? finance_value.split('M') : finance_value.split('m');
      finance_value = Number(splitArr[0]) * 1000000;
    }
    else if (finance_value && (finance_value?.includes('b') || finance_value?.includes('B'))) {
      let splitArr = finance_value?.includes('B') ? finance_value.split('B') : finance_value.split('b');
      finance_value = Number(splitArr[0]) * 1000000000;
    }
    else if (finance_value && (finance_value?.includes('t') || finance_value?.includes('T'))) {
      let splitArr = finance_value?.includes('T') ? finance_value.split('T') : finance_value.split('t');
      finance_value = Number(splitArr[0]) * 1000000000000;
    }


    let turn_over = data.turn_over;
    if (turn_over && (turn_over?.includes('k') || turn_over?.includes('K'))) {
      let splitArr = turn_over?.includes('K') ? turn_over.split('K') : turn_over.split('k');
      turn_over = Number(splitArr[0]) * 1000;
    }
    else if (turn_over && (turn_over?.includes('m') || turn_over?.includes('M'))) {
      let splitArr = turn_over?.includes('M') ? turn_over.split('M') : turn_over.split('m');
      turn_over = Number(splitArr[0]) * 1000000;
    }
    else if (turn_over && (turn_over?.includes('b') || turn_over?.includes('B'))) {
      let splitArr = turn_over?.includes('B') ? turn_over.split('B') : turn_over.split('b');
      turn_over = Number(splitArr[0]) * 1000000000;
    }
    else if (turn_over && (turn_over?.includes('t') || turn_over?.includes('T'))) {
      let splitArr = turn_over?.includes('T') ? turn_over.split('T') : turn_over.split('t');
      turn_over = Number(splitArr[0]) * 1000000000000;
    }

    data.finance_value = finance_value;
    data.turn_over = turn_over;

    //-------------------------------------------------------------------------------------------//
    data.phone = data.phone.filter((x) => x && x);
    data.email = data.email.filter((x) => x && x);
    data.deadline_date =
      data.deadline_date && moment(data.deadline_date).format('YYYY-MM-DD');

    if (failedReason) {
      data.failed_reason = failedReason;
    } else {
      if (data.lead_status_id === AUTH.SOLD_STATUS_FAILED) {
        if (
          AUTH.user &&
          AUTH.user?.failed_reasons &&
          AUTH.user.failed_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.failed_reasons[0]?.reason;
        }
      } else if (data.lead_status_id === AUTH.SOLD_STATUS_REJECTED) {
        if (
          AUTH.user &&
          AUTH.user?.rejected_reasons &&
          AUTH.user.rejected_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.rejected_reasons[0]?.reason;
        }
      } else if (data.lead_status_id === AUTH.SOLD_STATUS_FUTURE) {
        if (
          AUTH.user &&
          AUTH.user?.future_reasons &&
          AUTH.user.future_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.future_reasons[0]?.reason;
        }
      }
    }
    if (connectionList.length > 0) {
      connectionList.map((obj) => {
        let newConnectionRolename = '';
        if (obj?.connection_role_name && obj?.connection_role_name?.length) {
          obj.connection_role_name.map((role_obj, index) => {
            if (index === 0) {
              newConnectionRolename = role_obj;
            }
            else {
              newConnectionRolename += `#${role_obj}`;
            }
            return null
          })
        }
        obj.connection_role_name = newConnectionRolename;
        return null
      })
    }
    let primaryArr = [];
    if (primaryConnectionList.length > 0) {
      let orgData;
      let peopleData;
      primaryConnectionList.map((obj) => {
        let newConnectionRolename = '';

        if (obj?.connection_role_name && obj?.connection_role_name?.length) {
          obj.connection_role_name.map((role_obj, index) => {
            if (index === 0) {
              newConnectionRolename = role_obj;
            }
            else {
              newConnectionRolename += `#${role_obj}`;
            }
            return null
          })
        }
        if (obj.type === 1) {
          orgData = newConnectionRolename;
          if (orgData) {
            primaryArr.push({ [`${data.organisation_name}_org`]: orgData })
          }
        }
        if (obj.type === 2) {
          peopleData = newConnectionRolename;
          if (peopleData) {
            primaryArr.push({ [`${data.full_name}_people`]: peopleData })
          }
        }
        return obj;
      })
    }
    data.primary_connection = primaryArr
    data.connections = connectionList;


    data.future_reason = null;
    data.failed_reason = null;
    data.failed_summary = null;
    data.rejected_reason = null;
    data.summary = null;

    if (getSoldStatus) {
      data.future_reason = getSoldStatus.future_reason ? getSoldStatus.future_reason : null;
      data.failed_reason = getSoldStatus.failed_reason ? getSoldStatus.failed_reason : null;
      data.failed_summary = getSoldStatus.failed_summary ? getSoldStatus.failed_summary : null;
      data.rejected_reason = getSoldStatus.rejected_reason ? getSoldStatus.rejected_reason : null;
      data.summary = getSoldStatus.summary ? getSoldStatus.summary : null;
    }

    LEADSSTORE.addLeadData(data)
      .then((data) => {
        if (data?.id) {
          history.push(`/dealdesk/${data.id}`);
        }
        close();

        if (location.pathname === '/leads/deals') {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
        }
        if (location.pathname === '/leads/opportunities') {
          LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
        }
        if (location.pathname === '/leads/active') {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
        }

        // Add note on Status changes to failed
        if (
          (DEALDESKSTORE.rejectionSummaryData?.summary) &&
          (DEALDESKSTORE?.rejectionSummaryData?.type === "failed")
        ) {
          let modifiedRejectionSummaryData = `<rejectionSummary>Failed Summary<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`

          let payload = {
            "notes_flag": 2,
            "notes_description": modifiedRejectionSummaryData,
          }
          DEALDESKNOTESOUTBOUND.saveOutboundNote(data?.id, payload).then((res) => {
            if (res && res?.data) {
              DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            }
          })
        }

        // Add note on Status changes to rejected
        if (
          (DEALDESKSTORE.rejectionSummaryData?.summary) &&
          (DEALDESKSTORE?.rejectionSummaryData?.type === "rejected")
        ) {
          let modifiedRejectionSummaryData = `<rejectionSummary>Rejection Summary<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`

          let payload = {
            "notes_flag": 2,
            "notes_description": modifiedRejectionSummaryData,
          }
          DEALDESKNOTESOUTBOUND.saveOutboundNote(data?.id, payload).then((res) => {
            if (res && res?.data) {
              DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            }
          })
        }


        vsmNotify.success({
          message: vsmLeadsList.add,
        });
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (props.visible) {
      LEADSSTORE.getLeadStatusList();
      DEALDESKSTORE.setRejectionSummaryData(null)
    }
  }, [LEADSSTORE, props.visible,DEALDESKSTORE]);

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // Open form for add new People
  const openAddModal = () => {
    setAddModal(true);
  };

  const setPeopleDropDown = (id, classification_id = null) => {
    PEOPLE.getAllPeople({ selected_id: [id], mapping_type: 2 });
    form.setFieldsValue({ full_name: id });

    if (!form.getFieldValue('classification_id')) {
      if (!LEADSSTORE.classification_role_list) {
        LEADSSTORE.getClassificationList();
      }
      if (classification_id) {
        form.setFieldsValue({ classification_id: classification_id });
      }
    }

    handleChange();
  };
  const setOrgDropDown = (id, classification_id = null) => {
    //getMappedOrg();
    if (!form.getFieldValue('classification_id')) {
      if (!LEADSSTORE.classification_role_list) {
        LEADSSTORE.getClassificationList();
      }
      if (classification_id) {
        form.setFieldsValue({ classification_id: classification_id });
      }
    }

    ORGANISATION.getAllROrg({
      selected_id: [id],
      mapping_type: 1,
    });
    form.setFieldsValue({ organisation_name: id });

    handleChange();
  };

  // Close form for add new People
  const closeAddModal = () => setAddModal(false);

  // Open form for add new Organisation
  const openAddOrgModal = () => {
    setAddOrgModal(true);
  };
  // Close form for close new Organisation
  const closeAddOrgModal = () => setAddOrgModal(false);

  // Open form for edit People
  const openEditPeopleDrawer = () => {
    var payload = { people_id: form.getFieldValue('full_name') };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setEditPeopleDrawer(true);
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  };
  // Close form for edit People
  const closeEditPeopleDrawer = () => {
    PEOPLE.getAllPeople({
      selected_id: [form.getFieldValue('full_name')],
      mapping_type: 2,
    });

    setEditPeopleDrawer(false);
    handleChange();
  };

  // Open form for edit existing Organisation and set values to form
  // set id for edit / view  Organisation drawer
  const setId = (id) => setTransactionId(id);
  const openEditOrgDrawer = () => {
    var payload = { organisation_id: form.getFieldValue('organisation_name') };
    ORGANISATION.setOrganisationValues(null);
    ORGANISATION.getRecordDetail(payload).then((data) => {
      setId(payload.organisation_id);
      ORGANISATION.setEditValues(data);
      setEditOrgModal(true);
    });
  };

  // Close form of edit Organisation
  const closeEditOrgDrawer = () => {
    //getMappedOrg();
    form.setFieldsValue({ organisation_name: form.getFieldValue('organisation_name') });

    ORGANISATION.getAllROrg({
      selected_id: [form.getFieldValue('organisation_name')],
      mapping_type: 1,
    });

    ORGANISATION.organisationValues = null;
    ORGANISATION.setEditValues(null);
    setEditOrgModal(false);
    handleChange();
  };

  return (
    <>
      <Drawer
        key='1'
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1100px'}
        title={`${t('Common_Data.Add')} ${t('Common_Data.Sold')}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right' key='1'>
            <Button
              key='1'
              form='addleadform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              shape='round'
              disabled={isDisabled}
            >
              {t('Common_Data.Save')}
            </Button>
            <Button shape='round' onClick={close} key='2'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          formid='addleadform'
          openAddModal={openAddModal}
          visible={props.visible}
          id={props.parent_id}
          openAddOrgModal={openAddOrgModal}
          openEditPeopleDrawer={openEditPeopleDrawer}
          openEditOrgDrawer={openEditOrgDrawer}
          setFailedReason={setFailedReason}
          setConnectionList={setConnectionList}
          setPrimaryConnectionList={setPrimaryConnectionList}
          connectionList={connectionList}
          primaryConnectionList={primaryConnectionList}
          setSoldStatus={setSoldStatus}
          AddNewLead={true}
        />
        <AddPeopleComponent
          visible={addModal}
          isLead={true}
          close={closeAddModal}
          setPeopleDropDown={setPeopleDropDown}
        />
        <AddOrgComponent
          visible={addOrgModal}
          isLead={true}
          close={closeAddOrgModal}
          setOrgDropDown={setOrgDropDown}
        />
        <EditPeopleComponent
          visible={editPeopleDrawer}
          close={closeEditPeopleDrawer}
          setPeopleDropDown={setPeopleDropDown}
        />
        <EditOrgComponent
          transactionId={transactionId}
          setId={setId}
          visible={editOrgModal}
          close={closeEditOrgDrawer}
          setOrgDropDown={setOrgDropDown}
        />
        {props.visible && <TimeTracker
          page={"leadlisting"}
          stop={!props.visible}
          fromSideBar={props?.fromSideBar}
        />}
      </Drawer>
    </>
  );
});

export default AddLeadComponent;
