import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import moment from 'moment';
import LocalGridConfig from '../../config/LocalGridConfig';
import { vsmNotify } from '../../config/messages';
import {
  capitalize,
  dynamicFieldsListReport,
  getNewReportFormField,
  reportDynamicFieldsListColumnConfig,
  defaultFieldsListReport,
  getReportOnFromSavedFilterPageType,
  formatDataBasedOnColumn,
  secondsToHmsWithZeros,
  numberWithCommas,
} from '../../utils/GlobalFunction';

import { reset } from '../../utils/ResetClearButton';

export default class ReportStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  previewReportTotal = 0;
  agGrid = null;
  created_by_id = null;

  initialValues = {
    //date_filter_on: 'created',
  };
  initialSummaryValues = {
    //report_for: 'all',
    //date_filter_on: 'created',
    seperate_sum_users: true,
    count_historical_moments: false,
    count_for_holders_only: false,
    period_x_value: 1,
  };
  previewReportValues = null;
  selectedReportFieldsKeys = null;
  columnsForSortList = null;

  //------------REPORT GRID --------------------//
  deleteValues = null;
  //------------REPORT GRID --------------------//

  //------------FAV TOGGLE--------------------//
  globalStatusTempData = null;
  //------------FAV TOGGLE--------------------//

  //------------PREVIEW REPORT VARIABLES--------------------//
  reportType = '';
  reportColumnSettings = null;
  preview_report_criteria = null;
  previewFilterForExport = null;
  preview_report_grid = null;
  performance_total_investment_hours = null;
  performance_total_sum_finance_value = null;
  performance_total_sum_turn_over_value = null;
  performance_total_sum_lead_turn_over_value = null;
  performance_total_sum_sold_turn_over_value = null;

  performance_total_sum_active_leads_finance_value = null;
  performance_total_sum_active_leads_turn_over = null;
  performance_total_sum_active_trade_finance_value = null;
  performance_total_sum_active_trade_turn_over = null;
  performance_total_sum_active_treasury_finance_value = null;
  performance_total_sum_active_treasury_turn_over = null;
  performance_total_sum_deals_finance_value = null;
  performance_total_sum_deals_turn_over = null;
  performance_total_sum_leads_finance_value = null;
  performance_total_sum_leads_turn_over = null;
  performance_total_sum_sold_finance_value = null;
  performance_total_sum_sold_finance_value = null;
  performance_total_sum_sold_turn_over = null;
  performance_total_sum_trade_finance_value = null;
  performance_total_sum_trade_turn_over = null;
  performance_total_sum_treasury_finance_value = null;
  performance_total_sum_treasury_turn_over = null;

  auditExportData = null;
  auditExportFormatedData = null;

  total_sum_finance = null;

  //------------PREVIEW REPORT VARIABLES--------------------//

  //------------DROPDOWN VALUES--------------------//
  org_contacts_list_data = null;
  contacts_list_data = null;
  lead_search_dropdown_data = null;
  ticket_list_data = null;
  lead_list_data = null;
  classification_role_list = null;
  financial_year_list = null;
  product_list = null;
  dropdown_userGroupList = null;
  report_dropdown_users_list = null;
  fetchingLead = false;
  fetchingTicketSearch = false;
  targetKeys = null;
  mockData = [];
  selectedKeys = [];
  report_on_report = null;
  lead_type_list = [];
  lead_status_list = [];

  //------------DROPDOWN VALUES--------------------//

  investmentHoursDetailDataByuser = null; //USED FOR INVESTMENT HOUR REPORT DRILL DOWN
  drilldownLoader = null; //USED FOR INVESTMENT HOUR REPORT DRILL DOWN
  payload_drilldown_generated_on = null;
  investment_hour_grid_settings = null;

  recordTypeList = [];

  searchLeadKey = null;
  searchTicketKey = null;

  originalReadArr = null;

  isPreviewFromEditMode = false;

  /**Ticket Priority */
  ticketPriorityDataByUser = null;

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  setTargetKeys = (data) => {
    this.targetKeys = data;
  };

  setMockData = (data) => {
    this.mockData = data;
  };
  setSelectedKeys = (data) => {
    this.selectedKeys = data;
  };

  getList = (payload = {}) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }
    this.list_data = null;
    this.reportGridLoader = true;
    return Axios.post(`report/list`, payload).then(({ data }) => {
      this.reportGridLoader = false;
      if (data.data) {
        var temp = [];
        data.data.forEach((item) => {
          item.last_generated_by = item.user
            ? item.user.first_name + ' ' + item.user.last_name
            : 'N/A';

          temp.push(item);
        });
        this.list_data = temp;
        this.total = temp.length;
      }
      if (this.agGrid && this.agGrid.columnApi) {
        let columnConfig = localStorage.getItem('report_grid');
        this.agGrid.columnApi.applyColumnState({
          state: columnConfig ? JSON.parse(columnConfig) : sort,
        });
        this.agGrid.api.setFilterModel(filter);
      }
      return data;
    });
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid) {
      if (this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
        this.agGrid.api.showNoRowsOverlay();
      }
      if (this.agGrid.api.rowModel.rowsToDisplay.length >= 0) {
        this.agGrid.api.hideOverlay();
      }
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'report_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  //Handle Reset :: DEFAULT - R
  // handleReset = () => {
  //   localStorage.removeItem('report_grid');
  //   this.agGrid.api.setFilterModel(null);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.columnApi.autoSizeColumns();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  // reset all the server side filters
  // Reset : Will reset all filters, sorting, saved columns settings.
  handleReset = () => {
    reset(this.agGrid, "report_grid", this.setSelectedGridFilter);
  }


  //Handle Inner Reset
  handleInnserReset = () => {
    this.preview_report_grid.api.setFilterModel(null);
    this.preview_report_grid.api.setSortModel(null);
    this.preview_report_grid.columnApi.resetColumnState();
    this.preview_report_grid.columnApi.autoSizeColumns();
    this.preview_report_grid.api.onFilterChanged(null);
    this.preview_report_grid.api.paginationGoToPage(0);
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.preview_report_grid.api.paginationSetPageSize(parseInt(page));
  };

  setDeleteValues = (data) => {
    this.deleteValues = data;
  };
  // Call delete api
  DeleteData = (formdata) => {
    return Axios.get(`report/delete/${formdata.id}`)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setGlobalStatusTempData = (data) => {
    this.globalStatusTempData = data;
  };

  saveToMenu = (formdata, is_favorite) => {
    return Axios.post(`report/favorite/status/${formdata}`, { is_favorite: is_favorite })
      .then(({ data }) => {
        vsmNotify.success({
          message: data.message,
        });
        this.getList();
        return data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  editFilter = (id, payload, userid) => {
    return Axios.post(`report/edit/is-global/${id}`, payload)
      .then(({ data }) => {
        if (this.my_records === 'all') {
          this.getList({}, 'all');
        } else {
          this.getList({}, userid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setCreated_by_id = (data) => {
    this.created_by_id = data;
  };

  setReportColumnSettings = (data) => {
    this.reportColumnSettings = data;
  };

  setPreview_report_criteria = (data) => {
    this.preview_report_criteria = data;
  };
  setPreviewReportValues = (data) => {
    this.previewReportValues = data;
  };

  AddData = (formdata) => {
    return Axios.post(`/v2/report/create`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  formatFormData = (data, forRequest = true, isPreview = false) => {
    var temp;
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          ![
            'form_list',
            'id',
            'action',
            'name',
            'code',
            'description',
            'json_load_reminder_for',
            'json_load_investment_hour',
            'json_load_relationship',
            'global_toggle',
            'column_settings',
            'json_related_items_data',
            'grid_columns',
            'targetKeys',
          ].includes(key)
        ) {
          if (!data.form_list.includes(key)) {
            delete data[key];
          }
        }
      });

      if (forRequest) {
        if (data.form_list) {
          data.form_list = data.form_list.filter((v, i, a) => a.indexOf(v) === i);
        }
        data.targetKeys = this.targetKeys;

        this.previewReportValues = data;
        data = getNewReportFormField(data, forRequest);

        //---------------START:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//
        if (data.report_on && data.report_on === 'reminders') {
          let jsonLoadReminderFor = {};
          if (data?.reminder_type?.length > 0) {
            if (data.reminder_type.includes('tickets') && data.reminder_ticket_id) {
              temp = [];
              this.ticket_list_data &&
                this.ticket_list_data.length > 0 &&
                this.ticket_list_data.map((item, index) => {
                  if (data.reminder_ticket_id.includes(item.id)) {
                    temp.push({
                      ...item,
                    });
                  }
                  return null;
                });
              jsonLoadReminderFor.reminder_ticket_id = temp;
            } else {
              if (data.reminder_ticket_id) {
                delete data.reminder_ticket_id;
              }
            }
            if (data.reminder_type.includes('leads') && data.reminder_leads_id) {
              temp = [];
              this.lead_search_dropdown_data &&
                this.lead_search_dropdown_data.length > 0 &&
                this.lead_search_dropdown_data.map((item, index) => {
                  if (data.reminder_leads_id.includes(item.id)) {
                    temp.push({
                      ...item,
                    });
                  }
                  return null;
                });
              jsonLoadReminderFor.reminder_leads_id = temp;
            } else {
              if (data.reminder_leads_id) {
                delete data.reminder_leads_id;
              }
            }
          } else {
            data.reminder_ticket_id = [];
            data.reminder_leads_id = [];
          }
          data.jsonLoadReminderFor = jsonLoadReminderFor;
        }

        if (data.report_type && data.report_type === 'time_spent') {
          let jsonLoadInvestmentHour = {};
          if (data.lead_id) {
            temp = [];
            this.lead_list_data &&
              this.lead_list_data.length > 0 &&
              this.lead_list_data.map((item, index) => {
                if (data.lead_id.includes(item.id)) {
                  temp.push({
                    ...item,
                  });
                }
                return null;
              });
            if (data?.json_load_investment_hour) {
              if (data.json_load_investment_hour?.lead_id) {
                data.json_load_investment_hour?.lead_id?.map((obj) => {
                  let tempIndex = temp.findIndex((x) => x.id === obj.id);
                  if (data.lead_id.includes(obj.id) && tempIndex === -1) {
                    temp.push({
                      ...obj,
                    });
                  }
                  return null;
                });
              }
            }
            jsonLoadInvestmentHour.lead_id = temp;
          }
          if (data.ticket_id) {
            temp = [];
            this.ticket_list_data &&
              this.ticket_list_data.length > 0 &&
              this.ticket_list_data.map((item, index) => {
                if (data.ticket_id.includes(item.id)) {
                  temp.push({
                    ...item,
                  });
                }
                return null;
              });
            if (data?.json_load_investment_hour) {
              if (data.json_load_investment_hour?.ticket_id) {
                data.json_load_investment_hour?.ticket_id?.map((obj) => {
                  let tempIndex = temp.findIndex((x) => x.id === obj.id);
                  if (data.ticket_id.includes(obj.id) && tempIndex === -1) {
                    temp.push({
                      ...obj,
                    });
                  }
                  return null;
                });
              }
            }
            jsonLoadInvestmentHour.ticket_id = temp;
          }
          data.jsonLoadInvestmentHour = jsonLoadInvestmentHour;
          if (data?.report_for) {
            this.payload_drilldown_generated_on = data;
          }
        }

        if (data.report_type && data.report_type === 'relationship') {
          let jsonLoadRelationship = {};
          if (data.lead_id) {
            temp = [];
            this.lead_list_data &&
              this.lead_list_data.length > 0 &&
              this.lead_list_data.map((item, index) => {
                if (data.lead_id === item.id) {
                  temp.push({
                    ...item,
                  });
                }
                return null;
              });
            jsonLoadRelationship.lead_id = temp;
          }
          data.jsonLoadRelationship = jsonLoadRelationship;
        }

        //---------------END:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//
        // --- Payload Formation for Comparison Mode first row -----//
        if (data?.enable_comparison_mode && data?.report_for_comparison_mode?.length) {
          Object.keys(data.report_for_comparison_mode[0]).map((key) => {
            data[key] = data.report_for_comparison_mode[0][key];
            return null;
          });
        }
      } else {
        data = getNewReportFormField(data);

        //---------------START:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//
        if (data.json_load_reminder_for) {
          if (data.json_load_reminder_for?.reminder_leads_id) {
            this.lead_search_dropdown_data =
              data.json_load_reminder_for?.reminder_leads_id;
          }
          if (data.json_load_reminder_for?.reminder_ticket_id) {
            this.ticket_list_data = data.json_load_reminder_for?.reminder_ticket_id;
          }
        }
        //---------------END:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//
        //---------------START:INVESTMENT HOUR SOLD ID LOAD DROPDOWN DATA-------------//
        if (data.json_load_investment_hour) {
          if (data.json_load_investment_hour?.lead_id) {
            this.lead_list_data = data.json_load_investment_hour?.lead_id;
          }
          if (data.json_load_investment_hour?.ticket_id) {
            this.ticket_list_data = data.json_load_investment_hour?.ticket_id;
          }
        }
        //---------------END:INVESTMENT HOUR SOLD ID LOAD DROPDOWN DATA-------------//

        if (data.json_load_relationship) {
          if (data.json_load_relationship?.lead_id) {
            this.lead_list_data = data.json_load_relationship?.lead_id;
          }
        }
      }
    }
    return data;
  };

  setSelectedReportFieldsKeys = (data) => {
    if (
      data &&
      data.length <= 0 &&
      localStorage.getItem('tempSelectedReportFieldsKeys')
    ) {
      localStorage.removeItem('tempSelectedReportFieldsKeys');
    }

    this.selectedReportFieldsKeys = data;
    let columnsList = data || [];
    if (columnsList) {
      columnsList = columnsList.map((obj) => {
        let newObj = {};
        newObj.col_id = obj;
        newObj.title = reportDynamicFieldsListColumnConfig[obj]
          ? reportDynamicFieldsListColumnConfig[obj]['title']
          : capitalize(obj);
        return newObj;
      });
    }
    this.columnsForSortList = columnsList;
  };

  setColumnForSortList = (data) => {
    this.columnsForSortList = data;
  };

  searchOrgContacts = (payload) => {
    this.org_contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetchingOrg = true;
      return Axios.post(`snapnote/contacts-organisations-search`, payload)
        .then(({ data }) => {
          this.org_contacts_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingOrg = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchPersonContacts = (payload) => {
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetchingPeople = true;
      return Axios.post(`snapnote/contacts-people-search`, payload)
        .then(({ data }) => {
          this.contacts_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingPeople = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchLeadSOLDName = (payload) => {
    this.lead_search_dropdown_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetchingLead = true;
      return Axios.post(`snapnote/lead-search`, payload)
        .then(({ data }) => {
          this.lead_search_dropdown_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingLead = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchTicket = (payload) => {
    this.ticket_list_data = null;

    if (payload?.search_text?.length >= 3) {
      this.fetchingTicketSearch = true;
      return Axios.post(`snapnote/ticket-search`, payload)
        .then(({ data }) => {
          this.ticket_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingTicketSearch = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  // snapnote/lead-people-org/read
  getEditLeads = (payload) => {
    this.lead_list_data = null;
    return Axios.post(`snapnote/lead-people-org/read`, payload)
      .then(({ data }) => {
        if (data.data) {
          data.data.map((obj) => {
            obj.search_name = obj.id + '-' + obj.org_name + '-' + obj.person;
            return null;
          });
        }
        this.lead_list_data = data.data;
        return data;
      })
      .catch(({ response: { data } }) => {
        return data;
      })
      .finally(() => { });
  };

  searchLead = (payload) => {
    if (payload?.search_text?.length >= 1) {
      this.lead_list_data = null;
      this.fetching = true;
      return Axios.post(`snapnote/lead-people-org-search`, payload)
        .then(({ data }) => {
          this.lead_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  getClassificationList = () => {
    return Axios.post(`industry/list`).then(({ data }) => {
      this.classification_role_list = data.data;
      return data;
    });
  };
  getFinancialYearList = () => {
    return Axios.get(`report/financial/year`).then(({ data }) => {
      this.financial_year_list = data.data;

      return data;
    });
  };
  getProductList = () => {
    return Axios.post(`products-names`).then(({ data }) => {
      this.product_list = data.data;

      return data;
    });
  };
  ReadReport = (id, isClone = false) => {
    return Axios.get(`/report/read/${id}`)
      .then(({ data }) => {
        if (data.data) {
          if (data?.data?.global_toggle !== undefined) {
            data.data.global_toggle = !data.data.global_toggle;
          } else {
            data.data.global_toggle = false;
          }
          this.created_by_id = data.data.created_by;
          if (isClone) {
            let clonePreivewReportValues = {
              ...JSON.parse(data.data.generated_on_options),
              id: null,
              name: 'clone-' + data.data.name,
              description: data.data.description,
              grid_columns: data.data?.grid_columns
                ? data.data?.grid_columns?.split(',')
                : [],
            };
            delete clonePreivewReportValues.id;
            this.previewReportValues = clonePreivewReportValues;
          } else {
            this.previewReportValues = {
              ...JSON.parse(data.data.generated_on_options),
              id: data.data.id,
              name: data.data.name,
              code: data.data.code,
              description: data.data.description,
              grid_columns: data.data?.grid_columns
                ? data.data?.grid_columns?.split(',')
                : [],
            };
          }

          /**-----------------START: On Load Transfer Field Setup----------------------- */
          let selectData = data.data?.grid_columns
            ? data.data?.grid_columns?.split(',')
            : [];
          let updatedKeys = [];
          let allFieldKeysArr = [];
          let reportType = data.data.report_on;

          /**-----LOAD SAVED FILTER---- */
          if (reportType === 'saved_filter') {
            reportType = 'sold';
            if (
              this.previewReportValues?.filter_type &&
              getReportOnFromSavedFilterPageType[this.previewReportValues?.filter_type]
            ) {
              reportType =
                getReportOnFromSavedFilterPageType[this.previewReportValues?.filter_type];
            }
          }
          /**-----LOAD SAVED FILTER---- */

          let dynamicArray = dynamicFieldsListReport?.[reportType]
            ? dynamicFieldsListReport?.[reportType]
            : [];
          let defaultArray = defaultFieldsListReport?.[reportType]
            ? defaultFieldsListReport?.[reportType]
            : [];

          if (this.previewReportValues.waste) {
            if (!defaultArray.includes('deleted_at')) {
              defaultArray = [...defaultArray, 'deleted_at'];
            }
            if (!dynamicArray.includes('deleted_at')) {
              dynamicArray = [...dynamicArray, 'deleted_at'];
            }
          }

          dynamicArray &&
            dynamicArray.length > 0 &&
            dynamicArray.map((item, i) => {
              let elementDetails = reportDynamicFieldsListColumnConfig?.[item];
              allFieldKeysArr.push({
                key: i.toString(),
                title: `${elementDetails && elementDetails?.title
                  ? elementDetails?.title
                  : capitalize(item)
                  }`,
                description: item,
                disabled: defaultArray.includes(item) ? true : false,
              });
              if (selectData.includes(item)) {
                updatedKeys.push(i.toString());
              }
              return null;
            });
          this.setTargetKeys(updatedKeys);
          this.setMockData(allFieldKeysArr);
          if (selectData) {
            this.previewReportValues.grid_columns = selectData;
            this.setSelectedReportFieldsKeys(selectData);
            if (
              selectData &&
              selectData.length > 0 &&
              !localStorage.getItem('tempSelectedReportFieldsKeys')
            ) {
              localStorage.setItem(
                'tempSelectedReportFieldsKeys',
                JSON.stringify(selectData)
              );
            }
          }
          if (this.previewReportValues?.waste && !selectData.includes('deleted_at')) {
            this.previewReportValues.grid_columns = [...selectData, 'deleted_at'];
            this.setMockDataToggleDeleteColumn(true);
          }
          /**-----------------START: On Load Transfer Field Setup----------------------- */

          if (this.previewReportValues?.global_toggle !== undefined) {
            this.previewReportValues.global_toggle =
              !this.previewReportValues.global_toggle;
          } else {
            this.previewReportValues.global_toggle = false;
          }

          //--------------------GRID COLUMN LOAD---------------------//
          if (data.data.column_settings && !isClone) {
            this.setReportColumnSettings(data.data.column_settings);
          }
          //--------------------GRID COLUMN LOAD---------------------//
        }
        return data.data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };
  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`/v2/report/edit/${formdata.id}`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  PreviewData = (formdata) => {
    if (this.preview_report_grid) {
      var filter = this.preview_report_grid.api.getFilterModel();
      var sort = this.preview_report_grid.columnApi.getColumnState();
    }
    this.performance_total_investment_hours = '';
    this.performance_total_sum_finance_value = '';
    this.performance_total_sum_turn_over_value = '';
    this.performance_total_sum_lead_turn_over_value = '';
    this.performance_total_sum_sold_turn_over_value = '';

    this.performance_total_sum_active_deals_finance_value = '';
    this.performance_total_sum_active_deals_turn_over = '';
    this.performance_total_sum_active_leads_finance_value = '';
    this.performance_total_sum_active_leads_turn_over = '';
    this.performance_total_sum_active_trade_finance_value = '';
    this.performance_total_sum_active_trade_turn_over = '';
    this.performance_total_sum_active_treasury_finance_value = '';
    this.performance_total_sum_active_treasury_turn_over = '';
    this.performance_total_sum_deals_finance_value = '';
    this.performance_total_sum_deals_turn_over = '';
    this.performance_total_sum_leads_finance_value = '';
    this.performance_total_sum_leads_turn_over = '';
    this.performance_total_sum_sold_finance_value = '';
    this.performance_total_sum_sold_finance_value = '';
    this.performance_total_sum_sold_turn_over = '';
    this.performance_total_sum_trade_finance_value = '';
    this.performance_total_sum_trade_turn_over = '';
    this.performance_total_sum_treasury_finance_value = '';
    this.performance_total_sum_treasury_turn_over = '';
    this.total_sum_finance = '';

    return Axios.post(`/v2/report/preview/data`, formdata)
      .then(({ data }) => {
        this.preview_report_criteria = data?.preview_report_criteria;
        this.reportType = formdata.report_type;

        /*---SET GRID COLUMNS ON PREVIEW UPDATE--- */
        if (formdata.grid_columns) {
          if (this.previewReportValues) {
            this.previewReportValues.grid_columns = formdata.grid_columns;
          }
          this.setSelectedReportFieldsKeys(formdata.grid_columns);
        }
        /*---SET GRID COLUMNS ON PREVIEW--- */

        /* -----------------START: USED TO REFRESH GRID AND UPDATE NEW SELECTED FIELDS IN GRID---------------  */
        if (this.preview_report_grid && this.preview_report_grid.columnApi) {
          this.preview_report_grid.api.setFilterModel(filter);
          this.preview_report_grid.columnApi.applyColumnState({ state: sort });
        }
        /* -----------------START: USED TO REFRESH GRID AND UPDATE NEW SELECTED FIELDS IN GRID---------------  */
        let total_timespent = 0;
        let total_timespentFin = 0;
        let total_timespentTurnover = 0;
        let total_leadsTurnover = 0;
        let total_soldTurnover = 0;
        let total_active_deals_finance_value = 0;
        let total_active_deals_turn_over = 0;
        let total_active_leads_finance_value = 0;
        let total_active_leads_turn_over = 0;
        let total_active_trade_finance_value = 0;
        let total_active_trade_turn_over = 0;
        let total_active_treasury_finance_value = 0;
        let total_active_treasury_turn_over = 0;
        let total_deals_finance_value = 0;
        let total_deals_turn_over = 0;
        let total_leads_finance_value = 0;
        let total_leads_turn_over = 0;
        let total_sold_finance_value = 0;
        let total_sold_turn_over = 0;
        let total_trade_finance_value = 0;
        let total_trade_turn_over = 0;
        let total_treasury_finance_value = 0;
        let total_treasury_turn_over = 0;


        let totalArr = {};
        let totalArrFin = {};
        let totalArrTurn = {};
        let totalArrTurnN = {};
        let totalArrTurnS = {};

        let totalActiveLeadsFv = {};
        let totalActiveLeadsTo = {};
        let totalActiveTradeFv = {};
        let totalActiveTradeTo = {};
        let totalActiveTreasuryFv = {};
        let totalActiveTreasuryTo = {};
        let totalActiveDealsFv = {};
        let totalActiveDealsTo = {};
        let totalDealsFv = {};
        let totalDealsTo = {};
        let totalLeadsFv = {};
        let totalLeadsTo = {};
        let totalSoldFv = {};
        let totalSoldTo = {};
        let totalTradeFv = {};
        let totalTradeTo = {};
        let totalTreasuryFv = {};
        let totalTreasuryTo = {};


        // for count list report
        let countListWholeData = data?.data?.data?.length > 0 && data?.data?.data
        // console.log("yes data countListWholeData", typeof countListWholeData, countListWholeData);
        if ((this.reportType === 'count_list') && (countListWholeData?.length > 0)) {

          countListWholeData.forEach((x) => {
            if (this.previewReportValues.report_type === 'count_list') {
              // console.log("yes data", typeof x, x);
              if (x.hasOwnProperty('finance')) {
                x.finance = parseFloat(x.finance);
              }
            }
          })
          // total of whole column
          this.total_sum_finance = countListWholeData.reduce((prev, next) => prev + next.finance, 0);
        }


        if (data.data && data?.data?.length > 0) {

          data.data.forEach((x, idx) => {


            if (this.previewReportValues.report_type === 'records' || this.previewReportValues.report_type === 'count_list') {
              if (x.hasOwnProperty('financial_value')) {
                x.financial_value = parseFloat(x.financial_value);
              }
              if (x.hasOwnProperty('finance')) {
                x.finance = parseFloat(x.finance);
              }
              if (x.hasOwnProperty('turn_over')) {
                x.turn_over = parseFloat(x.turn_over);
              }
            }
            // -------- Time Spent Formation Starts -------------- //

            if (x.hasOwnProperty('Time Spent')) {
              if (typeof x['Time Spent'] === 'number') {
                total_timespent += Number(x['Time Spent']);
                x['time_spent_original'] = Number(x['Time Spent']);
                if (x['Time Spent']) {
                  x['Time Spent'] = secondsToHmsWithZeros(x['Time Spent']);
                } else {
                  x['Time Spent'] = '0';
                }
              } else if (typeof x['Time Spent'] === 'string' && x['Time Spent'] !== '') {
                let convertedCount = x['Time Spent'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString = tempString + secondsToHmsWithZeros(splitArr[j]);
                    } else {
                      tempString =
                        tempString + ' | ' + secondsToHmsWithZeros(splitArr[j]);
                    }
                    if (totalArr[j] === undefined) {
                      totalArr[j] = Number(splitArr[j]);
                    } else {
                      totalArr[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['Time Spent'] = tempString;
              }
            }

            let convertedString = '';
            Object.keys(totalArr).map((key, index) => {
              if (index === 0) {
                convertedString = convertedString + secondsToHmsWithZeros(totalArr[key]);
              } else {
                convertedString =
                  convertedString + ' | ' + secondsToHmsWithZeros(totalArr[key]);
              }
              return null;
            });
            if (convertedString && idx === data.data?.length - 1) {
              this.performance_total_investment_hours = convertedString;
            }
            if (total_timespent && idx === data.data?.length - 1) {
              this.performance_total_investment_hours =
                secondsToHmsWithZeros(total_timespent);
            }
            // -------- Time Spent Formation Ends -------------- //

            if (['summary', 'kpi'].includes(this.previewReportValues.report_type)) {
              if (x.hasOwnProperty('financial_value', 'finance')) {

                //x['finance_original'] = Number(x['financial_value']);

                if (typeof x['finance'] === 'number') {
                  total_timespentFin += Number(x['finance']);
                  if (x['finance']) {
                    x['finance'] = formatDataBasedOnColumn(
                      'finance',
                      x['finance']
                    );
                  } else {
                    x['finance'] = '0';
                  }
                } else if (
                  typeof x['finance'] === 'string' &&
                  x['finance'] !== ''
                ) {

                  let convertedCount = x['finance'];
                  let tempString = '';
                  let splitArr = convertedCount.split('|');
                  if (splitArr.length > 0) {
                    for (let j = 0; j < splitArr.length; j++) {
                      if (j === 0) {
                        tempString =
                          tempString +
                          formatDataBasedOnColumn('finance', splitArr[j]);
                      } else {
                        tempString =
                          tempString +
                          ' | ' +
                          formatDataBasedOnColumn('finance', splitArr[j]);
                      }
                      if (totalArrFin[j] === undefined) {
                        totalArrFin[j] = Number(splitArr[j]);
                      } else {
                        totalArrFin[j] += Number(splitArr[j]);
                      }
                    }
                  }
                  x['finance'] = tempString;
                }
              }

              if (x.hasOwnProperty('turn_over')) {
                //x['turn_over_original'] = Number(x['turn_over']);
                if (typeof x['turn_over'] === 'number') {
                  total_timespentTurnover += Number(x['turn_over']);
                  if (x['turn_over']) {
                    x['turn_over'] = formatDataBasedOnColumn('turn_over', x['turn_over']);
                  } else {
                    x['turn_over'] = '0';
                  }
                } else if (typeof x['turn_over'] === 'string' && x['turn_over'] !== '') {
                  let convertedCount = x['turn_over'];
                  let tempString = '';
                  let splitArr = convertedCount.split('|');
                  if (splitArr.length > 0) {
                    for (let j = 0; j < splitArr.length; j++) {
                      if (j === 0) {
                        tempString =
                          tempString + formatDataBasedOnColumn('turn_over', splitArr[j]);
                      } else {
                        tempString =
                          tempString +
                          ' | ' +
                          formatDataBasedOnColumn('turn_over', splitArr[j]);
                      }
                      if (totalArrTurn[j] === undefined) {
                        totalArrTurn[j] = Number(splitArr[j]);
                      } else {
                        totalArrTurn[j] += Number(splitArr[j]);
                      }
                    }
                  }
                  x['turn_over'] = tempString;
                }
              }

              if (x.hasOwnProperty('leads_turn_over')) {
                //x['leads_turn_over_original'] = Number(x['leads_turn_over']);
                if (typeof x['leads_turn_over'] === 'number') {
                  total_leadsTurnover += Number(x['leads_turn_over']);
                  if (x['leads_turn_over']) {
                    x['leads_turn_over'] = formatDataBasedOnColumn(
                      'leads_turn_over',
                      x['leads_turn_over']
                    );
                  } else {
                    x['leads_turn_over'] = '0';
                  }
                } else if (
                  typeof x['leads_turn_over'] === 'string' &&
                  x['leads_turn_over'] !== ''
                ) {
                  let convertedCount = x['leads_turn_over'];
                  let tempString = '';
                  let splitArr = convertedCount.split('|');
                  if (splitArr.length > 0) {
                    for (let j = 0; j < splitArr.length; j++) {
                      if (j === 0) {
                        tempString =
                          tempString +
                          formatDataBasedOnColumn('leads_turn_over', splitArr[j]);
                      } else {
                        tempString =
                          tempString +
                          ' | ' +
                          formatDataBasedOnColumn('leads_turn_over', splitArr[j]);
                      }
                      if (totalArrTurnN[j] === undefined) {
                        totalArrTurnN[j] = Number(splitArr[j]);
                      } else {
                        totalArrTurnN[j] += Number(splitArr[j]);
                      }
                    }
                  }
                  x['leads_turn_over'] = tempString;
                }
              }

              if (x.hasOwnProperty('sold_turn_over')) {
                if (typeof x['sold_turn_over'] === 'number') {
                  total_soldTurnover += Number(x['sold_turn_over']);
                  if (x['sold_turn_over']) {
                    x['sold_turn_over'] = formatDataBasedOnColumn(
                      'sold_turn_over',
                      x['sold_turn_over']
                    );
                  } else {
                    x['sold_turn_over'] = '0';
                  }
                } else if (
                  typeof x['sold_turn_over'] === 'string' &&
                  x['sold_turn_over'] !== ''
                ) {
                  let convertedCount = x['sold_turn_over'];
                  let tempString = '';
                  let splitArr = convertedCount.split('|');
                  if (splitArr.length > 0) {
                    for (let j = 0; j < splitArr.length; j++) {
                      if (j === 0) {
                        tempString =
                          tempString +
                          formatDataBasedOnColumn('sold_turn_over', splitArr[j]);
                      } else {
                        tempString =
                          tempString +
                          ' | ' +
                          formatDataBasedOnColumn('sold_turn_over', splitArr[j]);
                      }
                      if (totalArrTurnS[j] === undefined) {
                        totalArrTurnS[j] = Number(splitArr[j]);
                      } else {
                        totalArrTurnS[j] += Number(splitArr[j]);
                      }
                    }
                  }
                  x['sold_turn_over'] = tempString;
                }
              }

              if (['summary'].includes(this.previewReportValues.report_type)) {
                if (typeof x['sold_created_total'] === 'number') {
                  x['sold_created_total'] = numberWithCommas(x['sold_created_total'])
                }
                if (typeof x['sold_assigned_total'] === 'number') {
                  x['sold_assigned_total'] = numberWithCommas(x['sold_assigned_total'])
                }
                if (typeof x['inbound_leads'] === 'number') {
                  x['inbound_leads'] = numberWithCommas(x['inbound_leads'])
                }
                if (typeof x['outbound_leads'] === 'number') {
                  x['outbound_leads'] = numberWithCommas(x['outbound_leads'])
                }
                if (typeof x['structure_count'] === 'number') {
                  x['structure_count'] = numberWithCommas(x['structure_count'])
                }
                if (typeof x['schedule_count'] === 'number') {
                  x['schedule_count'] = numberWithCommas(x['schedule_count'])
                }
                if (typeof x['screen_count'] === 'number') {
                  x['screen_count'] = numberWithCommas(x['screen_count'])
                }
                if (typeof x['scrutinise_count'] === 'number') {
                  x['scrutinise_count'] = numberWithCommas(x['scrutinise_count'])
                }
                if (typeof x['shortfall_count'] === 'number') {
                  x['shortfall_count'] = numberWithCommas(x['shortfall_count'])
                }
                if (typeof x['sss_sum'] === 'number') {
                  x['sss_sum'] = numberWithCommas(x['sss_sum'])
                }
                if (typeof x['calls'] === 'number') {
                  x['calls'] = numberWithCommas(x['calls'])
                }
                if (typeof x['emails'] === 'number') {
                  x['emails'] = numberWithCommas(x['emails'])
                }
                if (typeof x['Summary (History)'] === 'number') {
                  x['Summary (History)'] = numberWithCommas(x['Summary (History)'])
                }
                if (typeof x['sum_modified'] === 'number') {
                  x['sum_modified'] = numberWithCommas(x['sum_modified'])
                }
                if (typeof x['sold_sum_total'] === 'number') {
                  x['sold_sum_total'] = numberWithCommas(x['sold_sum_total'])
                }
                if (typeof x['results'] === 'number') {
                  x['results'] = numberWithCommas(x['results'])
                }
                if (typeof x['Lead'] === 'number') {
                  x['Lead'] = numberWithCommas(x['Lead'])
                }
                if (typeof x['Deal'] === 'number') {
                  x['Deal'] = numberWithCommas(x['Deal'])
                }
                if (typeof x['Trade'] === 'number') {
                  x['Trade'] = numberWithCommas(x['Trade'])
                }
                if (typeof x['Treasury'] === 'number') {
                  x['Treasury'] = numberWithCommas(x['Treasury'])
                }
                if (typeof x['Accepted - Deal'] === 'number') {
                  x['Accepted - Deal'] = numberWithCommas(x['Accepted - Deal'])
                }
                if (typeof x['Bonus - Deal'] === 'number') {
                  x['Bonus - Deal'] = numberWithCommas(x['Bonus - Deal'])
                }
                if (typeof x['Team Bonus - Deal'] === 'number') {
                  x['Team Bonus - Deal'] = numberWithCommas(x['Team Bonus - Deal'])
                }
              }

              let convertedStringFin = '';
              Object.keys(totalArrFin).map((key, index) => {
                if (index === 0) {
                  convertedStringFin =
                    convertedStringFin +
                    formatDataBasedOnColumn('finance', totalArrFin[key]);
                } else {
                  convertedStringFin =
                    convertedStringFin +
                    ' | ' +
                    formatDataBasedOnColumn('finance', totalArrFin[key]);
                }
                return null;
              });

              let convertedStringTurn = '';
              Object.keys(totalArrTurn).map((key, index) => {
                if (index === 0) {
                  convertedStringTurn =
                    convertedStringTurn + totalArrTurn[key]
                  // formatDataBasedOnColumn('turn_over', totalArrTurn[key]);
                } else {
                  convertedStringTurn =
                    convertedStringTurn + totalArrTurn[key]
                  // ' | ' +
                  // formatDataBasedOnColumn('turn_over', totalArrTurn[key]);
                }
                return null;
              });

              let convertedStringLeadsTurn = '';
              Object.keys(totalArrTurnN).map((key, index) => {
                if (index === 0) {
                  convertedStringLeadsTurn =
                    convertedStringLeadsTurn +
                    formatDataBasedOnColumn('leads_turn_over', totalArrTurnN[key]);
                } else {
                  convertedStringLeadsTurn =
                    convertedStringLeadsTurn +
                    ' | ' +
                    formatDataBasedOnColumn('leads_turn_over', totalArrTurnN[key]);
                }
                return null;
              });

              let convertedStringSoldTurn = '';
              Object.keys(totalArrTurnS).map((key, index) => {
                if (index === 0) {
                  convertedStringSoldTurn =
                    convertedStringSoldTurn +
                    formatDataBasedOnColumn('sold_turn_over', totalArrTurnS[key]);
                } else {
                  convertedStringSoldTurn =
                    convertedStringSoldTurn +
                    ' | ' +
                    formatDataBasedOnColumn('sold_turn_over', totalArrTurnS[key]);
                }
                return null;
              });

              //
              if (convertedStringFin && idx === data.data?.length - 1) {
                this.performance_total_sum_finance_value = convertedStringFin;
              }
              if (convertedStringTurn && idx === data.data?.length - 1) {
                this.performance_total_sum_turn_over_value = convertedStringTurn;
              }
              if (convertedStringLeadsTurn && idx === data.data?.length - 1) {
                this.performance_total_sum_lead_turn_over_value =
                  convertedStringLeadsTurn;
              }
              if (convertedStringSoldTurn && idx === data.data?.length - 1) {
                this.performance_total_sum_sold_turn_over_value = convertedStringSoldTurn;
              }

              if (total_timespentFin && idx === data.data?.length - 1) {
                this.performance_total_sum_finance_value = formatDataBasedOnColumn(
                  'finance',
                  total_timespentFin
                );
              }
              if (total_timespentTurnover && idx === data.data?.length - 1) {
                this.performance_total_sum_turn_over_value = total_timespentTurnover
                // this.performance_total_sum_turn_over_value = formatDataBasedOnColumn(
                //   'turn_over',
                //   total_timespentTurnover
                // );
              }
              if (total_leadsTurnover && idx === data.data?.length - 1) {
                this.performance_total_sum_lead_turn_over_value = formatDataBasedOnColumn(
                  'leads_turn_over',
                  total_leadsTurnover
                );
              }

              if (total_soldTurnover && idx === data.data?.length - 1) {
                this.performance_total_sum_sold_turn_over_value = formatDataBasedOnColumn(
                  'sold_turn_over',
                  total_soldTurnover
                );
              }
            }

            //active_deals_finance_value
            if (x.hasOwnProperty('active_deals_finance_value')) {
              // x['active_deals_finance_value_original'] = Number(
              //   x['active_deals_finance_value']
              // );
              if (typeof x['active_deals_finance_value'] === 'number') {
                total_active_deals_finance_value += Number(
                  x['active_deals_finance_value']
                );
                if (x['active_deals_finance_value']) {
                  x['active_deals_finance_value'] = formatDataBasedOnColumn(
                    'active_deals_finance_value',
                    x['active_deals_finance_value']
                  );
                } else {
                  x['active_deals_finance_value'] = '0';
                }
              } else if (
                typeof x['active_deals_finance_value'] === 'string' &&
                x['active_deals_finance_value'] !== ''
              ) {
                let convertedCount = x['active_deals_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn(
                          'active_deals_finance_value',
                          splitArr[j]
                        );
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn(
                          'active_deals_finance_value',
                          splitArr[j]
                        );
                    }
                    if (totalActiveDealsFv[j] === undefined) {
                      totalActiveDealsFv[j] = Number(splitArr[j]);
                    } else {
                      totalActiveDealsFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_deals_finance_value'] = tempString;
              }
            }
            let convertedStringActiveDealsFv = '';
            Object.keys(totalActiveDealsFv).map((key, index) => {
              if (index === 0) {
                convertedStringActiveDealsFv =
                  convertedStringActiveDealsFv +
                  formatDataBasedOnColumn(
                    'active_deals_finance_value',
                    totalActiveDealsFv[key]
                  );
              } else {
                convertedStringActiveDealsFv =
                  convertedStringActiveDealsFv +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_deals_finance_value',
                    totalActiveDealsFv[key]
                  );
              }
              return null;
            });
            if (convertedStringActiveDealsFv && idx === data.data?.length - 1) {
              this.performance_total_sum_active_deals_finance_value =
                convertedStringActiveDealsFv;
            }
            if (total_active_deals_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_active_deals_finance_value =
                formatDataBasedOnColumn(
                  'active_deals_finance_value',
                  total_active_deals_finance_value
                );
            }

            //active_deals_turn_over
            if (x.hasOwnProperty('active_deals_turn_over')) {
              //x['active_deals_turn_over_original'] = Number(x['active_deals_turn_over']);
              if (typeof x['active_deals_turn_over'] === 'number') {
                total_active_deals_turn_over += Number(x['active_deals_turn_over']);
                if (x['active_deals_turn_over']) {
                  x['active_deals_turn_over'] = formatDataBasedOnColumn(
                    'active_deals_turn_over',
                    x['active_deals_turn_over']
                  );
                } else {
                  x['active_deals_turn_over'] = '0';
                }
              } else if (
                typeof x['active_deals_turn_over'] === 'string' &&
                x['active_deals_turn_over'] !== ''
              ) {
                let convertedCount = x['active_deals_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('active_deals_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('active_deals_turn_over', splitArr[j]);
                    }
                    if (totalActiveDealsTo[j] === undefined) {
                      totalActiveDealsTo[j] = Number(splitArr[j]);
                    } else {
                      totalActiveDealsTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_deals_turn_over'] = tempString;
              }
            }

            let convertedStringAdto = '';
            Object.keys(totalActiveDealsTo).map((key, index) => {
              if (index === 0) {
                convertedStringAdto =
                  convertedStringAdto +
                  formatDataBasedOnColumn(
                    'active_deals_turn_over',
                    totalActiveDealsTo[key]
                  );
              } else {
                convertedStringAdto =
                  convertedStringAdto +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_deals_turn_over',
                    totalActiveDealsTo[key]
                  );
              }
              return null;
            });

            if (convertedStringAdto && idx === data.data?.length - 1) {
              this.performance_total_sum_active_deals_turn_over = convertedStringAdto;
            }
            if (total_active_deals_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_active_deals_turn_over = formatDataBasedOnColumn(
                'active_deals_turn_over',
                total_active_deals_turn_over
              );
            }

            //active_leads_finance_value
            if (x.hasOwnProperty('active_leads_finance_value')) {
              if (typeof x['active_leads_finance_value'] === 'number') {
                total_active_leads_finance_value += Number(
                  x['active_leads_finance_value']
                );
                if (x['active_leads_finance_value']) {
                  x['active_leads_finance_value'] = formatDataBasedOnColumn(
                    'active_leads_finance_value',
                    x['active_leads_finance_value']
                  );
                } else {
                  x['active_leads_finance_value'] = '0';
                }
              } else if (
                typeof x['active_leads_finance_value'] === 'string' &&
                x['active_leads_finance_value'] !== ''
              ) {
                let convertedCount = x['active_leads_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn(
                          'active_leads_finance_value',
                          splitArr[j]
                        );
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn(
                          'active_leads_finance_value',
                          splitArr[j]
                        );
                    }
                    if (totalActiveLeadsFv[j] === undefined) {
                      totalActiveLeadsFv[j] = Number(splitArr[j]);
                    } else {
                      totalActiveLeadsFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_leads_finance_value'] = tempString;
              }
            }

            let convertedStringAlfv = '';
            Object.keys(totalActiveLeadsFv).map((key, index) => {
              if (index === 0) {
                convertedStringAlfv =
                  convertedStringAlfv +
                  formatDataBasedOnColumn(
                    'active_leads_finance_value',
                    totalActiveLeadsFv[key]
                  );
              } else {
                convertedStringAlfv =
                  convertedStringAlfv +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_leads_finance_value',
                    totalActiveLeadsFv[key]
                  );
              }
              return null;
            });

            if (convertedStringAlfv && idx === data.data?.length - 1) {
              this.performance_total_sum_active_leads_finance_value = convertedStringAlfv;
            }
            if (total_active_leads_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_active_leads_finance_value =
                formatDataBasedOnColumn(
                  'active_leads_finance_value',
                  total_active_leads_finance_value
                );
            }

            //active_leads_turn_over
            if (x.hasOwnProperty('active_leads_turn_over')) {
              if (typeof x['active_leads_turn_over'] === 'number') {
                total_active_leads_turn_over += Number(x['active_leads_turn_over']);
                if (x['active_leads_turn_over']) {
                  x['active_leads_turn_over'] = formatDataBasedOnColumn(
                    'active_leads_turn_over',
                    x['active_leads_turn_over']
                  );
                } else {
                  x['active_leads_turn_over'] = '0';
                }
              } else if (
                typeof x['active_leads_turn_over'] === 'string' &&
                x['active_leads_turn_over'] !== ''
              ) {
                let convertedCount = x['active_leads_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('active_leads_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('active_leads_turn_over', splitArr[j]);
                    }
                    if (totalActiveLeadsTo[j] === undefined) {
                      totalActiveLeadsTo[j] = Number(splitArr[j]);
                    } else {
                      totalActiveLeadsTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_leads_turn_over'] = tempString;
              }
            }
            let convertedStringAlto = '';
            Object.keys(totalActiveLeadsTo).map((key, index) => {
              if (index === 0) {
                convertedStringAlto =
                  convertedStringAlto +
                  formatDataBasedOnColumn(
                    'active_leads_turn_over',
                    totalActiveLeadsTo[key]
                  );
              } else {
                convertedStringAlto =
                  convertedStringAlto +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_leads_turn_over',
                    totalActiveLeadsTo[key]
                  );
              }
              return null;
            });
            if (convertedStringAlto && idx === data.data?.length - 1) {
              this.performance_total_sum_active_leads_turn_over = convertedStringAlto;
            }
            if (total_active_leads_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_active_leads_turn_over = formatDataBasedOnColumn(
                'active_leads_turn_over',
                total_active_leads_turn_over
              );
            }
            //active_trade_finance_value
            if (x.hasOwnProperty('active_trade_finance_value')) {
              if (typeof x['active_trade_finance_value'] === 'number') {
                total_active_trade_finance_value += Number(
                  x['active_trade_finance_value']
                );
                if (x['active_trade_finance_value']) {
                  x['active_trade_finance_value'] = formatDataBasedOnColumn(
                    'active_trade_finance_value',
                    x['active_trade_finance_value']
                  );
                } else {
                  x['active_trade_finance_value'] = '0';
                }
              } else if (
                typeof x['active_trade_finance_value'] === 'string' &&
                x['active_trade_finance_value'] !== ''
              ) {
                let convertedCount = x['active_trade_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn(
                          'active_trade_finance_value',
                          splitArr[j]
                        );
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn(
                          'active_trade_finance_value',
                          splitArr[j]
                        );
                    }
                    if (totalActiveTradeFv[j] === undefined) {
                      totalActiveTradeFv[j] = Number(splitArr[j]);
                    } else {
                      totalActiveTradeFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_trade_finance_value'] = tempString;
              }
            }
            let convertedStringAtfv = '';
            Object.keys(totalActiveTradeFv).map((key, index) => {
              if (index === 0) {
                convertedStringAtfv =
                  convertedStringAtfv +
                  formatDataBasedOnColumn(
                    'active_trade_finance_value',
                    totalActiveTradeFv[key]
                  );
              } else {
                convertedStringAtfv =
                  convertedStringAtfv +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_trade_finance_value',
                    totalActiveTradeFv[key]
                  );
              }
              return null;
            });
            if (convertedStringAtfv && idx === data.data?.length - 1) {
              this.performance_total_sum_active_trade_finance_value = convertedStringAtfv;
            }
            if (total_active_trade_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_active_trade_finance_value =
                formatDataBasedOnColumn(
                  'active_trade_finance_value',
                  total_active_trade_finance_value
                );
            }
            //active_trade_turn_over
            if (x.hasOwnProperty('active_trade_turn_over')) {
              if (typeof x['active_trade_turn_over'] === 'number') {
                total_active_trade_turn_over += Number(x['active_trade_turn_over']);
                if (x['active_trade_turn_over']) {
                  x['active_trade_turn_over'] = formatDataBasedOnColumn(
                    'active_trade_turn_over',
                    x['active_trade_turn_over']
                  );
                } else {
                  x['active_trade_turn_over'] = '0';
                }
              } else if (
                typeof x['active_trade_turn_over'] === 'string' &&
                x['active_trade_turn_over'] !== ''
              ) {
                let convertedCount = x['active_trade_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('active_trade_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('active_trade_turn_over', splitArr[j]);
                    }
                    if (totalActiveTradeTo[j] === undefined) {
                      totalActiveTradeTo[j] = Number(splitArr[j]);
                    } else {
                      totalActiveTradeTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_trade_turn_over'] = tempString;
              }
            }
            let convertedStringAtTo = '';
            Object.keys(totalActiveTradeTo).map((key, index) => {
              if (index === 0) {
                convertedStringAtTo =
                  convertedStringAtTo +
                  formatDataBasedOnColumn(
                    'active_trade_turn_over',
                    totalActiveTradeTo[key]
                  );
              } else {
                convertedStringAtTo =
                  convertedStringAtTo +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_trade_turn_over',
                    totalActiveTradeTo[key]
                  );
              }
              return null;
            });
            if (convertedStringAtTo && idx === data.data?.length - 1) {
              this.performance_total_sum_active_trade_turn_over = convertedStringAtTo;
            }
            if (total_active_trade_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_active_trade_turn_over = formatDataBasedOnColumn(
                'active_trade_turn_over',
                total_active_trade_turn_over
              );
            }
            //active_treasury_finance_value
            if (x.hasOwnProperty('active_treasury_finance_value')) {
              if (typeof x['active_treasury_finance_value'] === 'number') {
                total_active_treasury_finance_value += Number(
                  x['active_treasury_finance_value']
                );
                if (x['active_treasury_finance_value']) {
                  x['active_treasury_finance_value'] = formatDataBasedOnColumn(
                    'active_treasury_finance_value',
                    x['active_treasury_finance_value']
                  );
                } else {
                  x['active_treasury_finance_value'] = '0';
                }
              } else if (
                typeof x['active_treasury_finance_value'] === 'string' &&
                x['active_treasury_finance_value'] !== ''
              ) {
                let convertedCount = x['active_treasury_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn(
                          'active_treasury_finance_value',
                          splitArr[j]
                        );
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn(
                          'active_treasury_finance_value',
                          splitArr[j]
                        );
                    }
                    if (totalActiveTreasuryFv[j] === undefined) {
                      totalActiveTreasuryFv[j] = Number(splitArr[j]);
                    } else {
                      totalActiveTreasuryFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_treasury_finance_value'] = tempString;
              }
            }
            let convertedStringATreasuryFv = '';
            Object.keys(totalActiveTreasuryFv).map((key, index) => {
              if (index === 0) {
                convertedStringATreasuryFv =
                  convertedStringATreasuryFv +
                  formatDataBasedOnColumn(
                    'active_treasury_finance_value',
                    totalActiveTreasuryFv[key]
                  );
              } else {
                convertedStringATreasuryFv =
                  convertedStringATreasuryFv +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_treasury_finance_value',
                    totalActiveTreasuryFv[key]
                  );
              }
              return null;
            });
            if (convertedStringATreasuryFv && idx === data.data?.length - 1) {
              this.performance_total_sum_active_treasury_finance_value =
                convertedStringATreasuryFv;
            }
            if (total_active_treasury_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_active_treasury_finance_value =
                formatDataBasedOnColumn(
                  'active_treasury_finance_value',
                  total_active_treasury_finance_value
                );
            }

            //active_treasury_turn_over
            if (x.hasOwnProperty('active_treasury_turn_over')) {
              if (typeof x['active_treasury_turn_over'] === 'number') {
                total_active_treasury_turn_over += Number(x['active_treasury_turn_over']);
                if (x['active_treasury_turn_over']) {
                  x['active_treasury_turn_over'] = formatDataBasedOnColumn(
                    'active_treasury_turn_over',
                    x['active_treasury_turn_over']
                  );
                } else {
                  x['active_treasury_turn_over'] = '0';
                }
              } else if (
                typeof x['active_treasury_turn_over'] === 'string' &&
                x['active_treasury_turn_over'] !== ''
              ) {
                let convertedCount = x['active_treasury_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('active_treasury_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('active_treasury_turn_over', splitArr[j]);
                    }
                    if (totalActiveTreasuryTo[j] === undefined) {
                      totalActiveTreasuryTo[j] = Number(splitArr[j]);
                    } else {
                      totalActiveTreasuryTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['active_treasury_turn_over'] = tempString;
              }
            }
            let convertedStringATreasuryTo = '';
            Object.keys(totalActiveTreasuryTo).map((key, index) => {
              if (index === 0) {
                convertedStringATreasuryTo =
                  convertedStringATreasuryTo +
                  formatDataBasedOnColumn(
                    'active_treasury_turn_over',
                    totalActiveTreasuryTo[key]
                  );
              } else {
                convertedStringATreasuryTo =
                  convertedStringATreasuryTo +
                  ' | ' +
                  formatDataBasedOnColumn(
                    'active_treasury_turn_over',
                    totalActiveTreasuryTo[key]
                  );
              }
              return null;
            });
            if (convertedStringATreasuryTo && idx === data.data?.length - 1) {
              this.performance_total_sum_active_treasury_turn_over =
                convertedStringATreasuryTo;
            }
            if (total_active_treasury_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_active_treasury_turn_over =
                formatDataBasedOnColumn(
                  'active_treasury_turn_over',
                  total_active_treasury_turn_over
                );
            }

            //deals_finance_value
            if (x.hasOwnProperty('deals_finance_value')) {
              if (typeof x['deals_finance_value'] === 'number') {
                total_deals_finance_value += Number(x['deals_finance_value']);
                if (x['deals_finance_value']) {
                  x['deals_finance_value'] = formatDataBasedOnColumn(
                    'deals_finance_value',
                    x['deals_finance_value']
                  );
                } else {
                  x['deals_finance_value'] = '0';
                }
              } else if (
                typeof x['deals_finance_value'] === 'string' &&
                x['deals_finance_value'] !== ''
              ) {
                let convertedCount = x['deals_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('deals_finance_value', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('deals_finance_value', splitArr[j]);
                    }
                    if (totalDealsFv[j] === undefined) {
                      totalDealsFv[j] = Number(splitArr[j]);
                    } else {
                      totalDealsFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['deals_finance_value'] = tempString;
              }
            }
            let convertedStringADealsFv = '';
            Object.keys(totalDealsFv).map((key, index) => {
              if (index === 0) {
                convertedStringADealsFv =
                  convertedStringADealsFv +
                  formatDataBasedOnColumn('deals_finance_value', totalDealsFv[key]);
              } else {
                convertedStringADealsFv =
                  convertedStringADealsFv +
                  ' | ' +
                  formatDataBasedOnColumn('deals_finance_value', totalDealsFv[key]);
              }
              return null;
            });
            if (convertedStringADealsFv && idx === data.data?.length - 1) {
              this.performance_total_sum_deals_finance_value = convertedStringADealsFv;
            }
            if (total_deals_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_deals_finance_value = formatDataBasedOnColumn(
                'deals_finance_value',
                total_deals_finance_value
              );
            }

            //deals_turn_over
            if (x.hasOwnProperty('deals_turn_over')) {
              if (typeof x['deals_turn_over'] === 'number') {
                total_deals_turn_over += Number(x['deals_turn_over']);
                if (x['deals_turn_over']) {
                  x['deals_turn_over'] = formatDataBasedOnColumn(
                    'deals_turn_over',
                    x['deals_turn_over']
                  );
                } else {
                  x['deals_turn_over'] = '0';
                }
              } else if (
                typeof x['deals_turn_over'] === 'string' &&
                x['deals_turn_over'] !== ''
              ) {
                let convertedCount = x['deals_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('deals_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('deals_turn_over', splitArr[j]);
                    }
                    if (totalDealsTo[j] === undefined) {
                      totalDealsTo[j] = Number(splitArr[j]);
                    } else {
                      totalDealsTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['deals_turn_over'] = tempString;
              }
            }
            let convertedStringADealsTo = '';
            Object.keys(totalDealsTo).map((key, index) => {
              if (index === 0) {
                convertedStringADealsTo =
                  convertedStringADealsTo +
                  formatDataBasedOnColumn('deals_turn_over', totalDealsTo[key]);
              } else {
                convertedStringADealsTo =
                  convertedStringADealsTo +
                  ' | ' +
                  formatDataBasedOnColumn('deals_turn_over', totalDealsTo[key]);
              }
              return null;
            });
            if (convertedStringADealsTo && idx === data.data?.length - 1) {
              this.performance_total_sum_deals_turn_over = convertedStringADealsTo;
            }
            if (total_deals_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_deals_turn_over = formatDataBasedOnColumn(
                'deals_turn_over',
                total_deals_turn_over
              );
            }

            //leads_finance_value
            if (x.hasOwnProperty('leads_finance_value')) {
              if (typeof x['leads_finance_value'] === 'number') {
                total_leads_finance_value += Number(x['leads_finance_value']);
                if (x['leads_finance_value']) {
                  x['leads_finance_value'] = formatDataBasedOnColumn(
                    'leads_finance_value',
                    x['leads_finance_value']
                  );
                } else {
                  x['leads_finance_value'] = '0';
                }
              } else if (
                typeof x['leads_finance_value'] === 'string' &&
                x['leads_finance_value'] !== ''
              ) {
                let convertedCount = x['leads_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('leads_finance_value', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('leads_finance_value', splitArr[j]);
                    }
                    if (totalLeadsFv[j] === undefined) {
                      totalLeadsFv[j] = Number(splitArr[j]);
                    } else {
                      totalLeadsFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['leads_finance_value'] = tempString;
              }
            }
            let convertedStringALeadsFv = '';
            Object.keys(totalLeadsFv).map((key, index) => {
              if (index === 0) {
                convertedStringALeadsFv =
                  convertedStringALeadsFv +
                  formatDataBasedOnColumn('leads_finance_value', totalLeadsFv[key]);
              } else {
                convertedStringALeadsFv =
                  convertedStringALeadsFv +
                  ' | ' +
                  formatDataBasedOnColumn('leads_finance_value', totalLeadsFv[key]);
              }
              return null;
            });
            if (convertedStringALeadsFv && idx === data.data?.length - 1) {
              this.performance_total_sum_leads_finance_value = convertedStringALeadsFv;
            }
            if (total_leads_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_leads_finance_value = formatDataBasedOnColumn(
                'leads_finance_value',
                total_leads_finance_value
              );
            }

            //leads_turn_over
            if (x.hasOwnProperty('leads_turn_over')) {
              if (typeof x['leads_turn_over'] === 'number') {
                total_leads_turn_over += Number(x['leads_turn_over']);
                if (x['leads_turn_over']) {
                  x['leads_turn_over'] = formatDataBasedOnColumn(
                    'leads_turn_over',
                    x['leads_turn_over']
                  );
                } else {
                  x['leads_turn_over'] = '0';
                }
              } else if (
                typeof x['leads_turn_over'] === 'string' &&
                x['leads_turn_over'] !== ''
              ) {
                let convertedCount = x['leads_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('leads_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('leads_turn_over', splitArr[j]);
                    }
                    if (totalLeadsTo[j] === undefined) {
                      totalLeadsTo[j] = Number(splitArr[j]);
                    } else {
                      totalLeadsTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['leads_turn_over'] = tempString;
              }
            }
            let convertedStringALeadsTo = '';
            Object.keys(totalLeadsTo).map((key, index) => {
              if (index === 0) {
                convertedStringALeadsTo =
                  convertedStringALeadsTo +
                  formatDataBasedOnColumn('leads_turn_over', totalLeadsTo[key]);
              } else {
                convertedStringALeadsTo =
                  convertedStringALeadsTo +
                  ' | ' +
                  formatDataBasedOnColumn('leads_turn_over', totalLeadsTo[key]);
              }
              return null;
            });
            if (convertedStringALeadsTo && idx === data.data?.length - 1) {
              this.performance_total_sum_leads_turn_over = convertedStringALeadsTo;
            }
            if (total_leads_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_leads_turn_over = formatDataBasedOnColumn(
                'leads_turn_over',
                total_leads_turn_over
              );
            }

            //sold_finance_value
            if (x.hasOwnProperty('sold_finance_value')) {
              if (typeof x['sold_finance_value'] === 'number') {
                total_sold_finance_value += Number(x['sold_finance_value']);
                if (x['sold_finance_value']) {
                  x['sold_finance_value'] = formatDataBasedOnColumn(
                    'sold_finance_value',
                    x['sold_finance_value']
                  );
                } else {
                  x['sold_finance_value'] = '0';
                }
              } else if (
                typeof x['sold_finance_value'] === 'string' &&
                x['sold_finance_value'] !== ''
              ) {
                let convertedCount = x['sold_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('sold_finance_value', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('sold_finance_value', splitArr[j]);
                    }
                    if (totalSoldFv[j] === undefined) {
                      totalSoldFv[j] = Number(splitArr[j]);
                    } else {
                      totalSoldFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['sold_finance_value'] = tempString;
              }
            }
            let convertedStringSoldFv = '';
            Object.keys(totalSoldFv).map((key, index) => {
              if (index === 0) {
                convertedStringSoldFv =
                  convertedStringSoldFv +
                  formatDataBasedOnColumn('sold_finance_value', totalSoldFv[key]);
              } else {
                convertedStringSoldFv =
                  convertedStringSoldFv +
                  ' | ' +
                  formatDataBasedOnColumn('sold_finance_value', totalSoldFv[key]);
              }
              return null;
            });
            if (convertedStringSoldFv && idx === data.data?.length - 1) {
              this.performance_total_sum_sold_finance_value = convertedStringSoldFv;
            }
            if (total_sold_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_sold_finance_value = formatDataBasedOnColumn(
                'sold_finance_value',
                total_sold_finance_value
              );
            }

            //sold_turn_over
            if (x.hasOwnProperty('sold_turn_over')) {
              if (typeof x['sold_turn_over'] === 'number') {
                total_sold_turn_over += Number(x['sold_turn_over']);
                if (x['sold_turn_over']) {
                  x['sold_turn_over'] = formatDataBasedOnColumn(
                    'sold_turn_over',
                    x['sold_turn_over']
                  );
                } else {
                  x['sold_turn_over'] = '0';
                }
              } else if (
                typeof x['sold_turn_over'] === 'string' &&
                x['sold_turn_over'] !== ''
              ) {
                let convertedCount = x['sold_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('sold_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('sold_turn_over', splitArr[j]);
                    }
                    if (totalSoldTo[j] === undefined) {
                      totalSoldTo[j] = Number(splitArr[j]);
                    } else {
                      totalSoldTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['sold_turn_over'] = tempString;
              }
            }
            let convertedStringSoldTo = '';
            Object.keys(totalSoldTo).map((key, index) => {
              if (index === 0) {
                convertedStringSoldTo =
                  convertedStringSoldTo +
                  formatDataBasedOnColumn('sold_turn_over', totalSoldTo[key]);
              } else {
                convertedStringSoldTo =
                  convertedStringSoldTo +
                  ' | ' +
                  formatDataBasedOnColumn('sold_turn_over', totalSoldTo[key]);
              }
              return null;
            });
            if (convertedStringSoldTo && idx === data.data?.length - 1) {
              this.performance_total_sum_sold_turn_over = convertedStringSoldTo;
            }
            if (total_sold_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_sold_turn_over = formatDataBasedOnColumn(
                'sold_turn_over',
                total_sold_turn_over
              );
            }

            //trade_finance_value
            if (x.hasOwnProperty('trade_finance_value')) {
              if (typeof x['trade_finance_value'] === 'number') {
                total_trade_finance_value += Number(x['trade_finance_value']);
                if (x['trade_finance_value']) {
                  x['trade_finance_value'] = formatDataBasedOnColumn(
                    'trade_finance_value',
                    x['trade_finance_value']
                  );
                } else {
                  x['trade_finance_value'] = '0';
                }
              } else if (
                typeof x['trade_finance_value'] === 'string' &&
                x['trade_finance_value'] !== ''
              ) {
                let convertedCount = x['trade_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('trade_finance_value', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('trade_finance_value', splitArr[j]);
                    }
                    if (totalTradeFv[j] === undefined) {
                      totalTradeFv[j] = Number(splitArr[j]);
                    } else {
                      totalTradeFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['trade_finance_value'] = tempString;
              }
            }
            let convertedStringTradeFv = '';
            Object.keys(totalTradeFv).map((key, index) => {
              if (index === 0) {
                convertedStringTradeFv =
                  convertedStringTradeFv +
                  formatDataBasedOnColumn('trade_finance_value', totalTradeFv[key]);
              } else {
                convertedStringTradeFv =
                  convertedStringTradeFv +
                  ' | ' +
                  formatDataBasedOnColumn('trade_finance_value', totalTradeFv[key]);
              }
              return null;
            });
            if (convertedStringTradeFv && idx === data.data?.length - 1) {
              this.performance_total_sum_trade_finance_value = convertedStringTradeFv;
            }
            if (total_trade_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_trade_finance_value = formatDataBasedOnColumn(
                'trade_finance_value',
                total_trade_finance_value
              );
            }

            //trade_turn_over
            if (x.hasOwnProperty('trade_turn_over')) {
              if (typeof x['trade_turn_over'] === 'number') {
                total_trade_turn_over += Number(x['trade_turn_over']);
                if (x['trade_turn_over']) {
                  x['trade_turn_over'] = formatDataBasedOnColumn(
                    'trade_turn_over',
                    x['trade_turn_over']
                  );
                } else {
                  x['trade_turn_over'] = '0';
                }
              } else if (
                typeof x['trade_turn_over'] === 'string' &&
                x['trade_turn_over'] !== ''
              ) {
                let convertedCount = x['trade_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('trade_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('trade_turn_over', splitArr[j]);
                    }
                    if (totalTradeTo[j] === undefined) {
                      totalTradeTo[j] = Number(splitArr[j]);
                    } else {
                      totalTradeTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['trade_turn_over'] = tempString;
              }
            }
            let convertedStringTradeTo = '';
            Object.keys(totalTradeTo).map((key, index) => {
              if (index === 0) {
                convertedStringTradeTo =
                  convertedStringTradeTo +
                  formatDataBasedOnColumn('trade_turn_over', totalTradeTo[key]);
              } else {
                convertedStringTradeTo =
                  convertedStringTradeTo +
                  ' | ' +
                  formatDataBasedOnColumn('trade_turn_over', totalTradeTo[key]);
              }
              return null;
            });
            if (convertedStringTradeTo && idx === data.data?.length - 1) {
              this.performance_total_sum_trade_turn_over = convertedStringTradeTo;
            }
            if (total_trade_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_trade_turn_over = formatDataBasedOnColumn(
                'trade_turn_over',
                total_trade_turn_over
              );
            }

            //treasury_finance_value
            if (x.hasOwnProperty('treasury_finance_value')) {
              if (typeof x['treasury_finance_value'] === 'number') {
                total_treasury_finance_value += Number(x['treasury_finance_value']);
                if (x['treasury_finance_value']) {
                  x['treasury_finance_value'] = formatDataBasedOnColumn(
                    'treasury_finance_value',
                    x['treasury_finance_value']
                  );
                } else {
                  x['treasury_finance_value'] = '0';
                }
              } else if (
                typeof x['treasury_finance_value'] === 'string' &&
                x['treasury_finance_value'] !== ''
              ) {
                let convertedCount = x['treasury_finance_value'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('treasury_finance_value', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('treasury_finance_value', splitArr[j]);
                    }
                    if (totalTreasuryFv[j] === undefined) {
                      totalTreasuryFv[j] = Number(splitArr[j]);
                    } else {
                      totalTreasuryFv[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['treasury_finance_value'] = tempString;
              }
            }
            let convertedStringTreasuryFv = '';
            Object.keys(totalTreasuryFv).map((key, index) => {
              if (index === 0) {
                convertedStringTreasuryFv =
                  convertedStringTreasuryFv +
                  formatDataBasedOnColumn('treasury_finance_value', totalTreasuryFv[key]);
              } else {
                convertedStringTreasuryFv =
                  convertedStringTreasuryFv +
                  ' | ' +
                  formatDataBasedOnColumn('treasury_finance_value', totalTreasuryFv[key]);
              }
              return null;
            });
            if (convertedStringTreasuryFv && idx === data.data?.length - 1) {
              this.performance_total_sum_treasury_finance_value =
                convertedStringTreasuryFv;
            }
            if (total_treasury_finance_value && idx === data.data?.length - 1) {
              this.performance_total_sum_treasury_finance_value = formatDataBasedOnColumn(
                'treasury_finance_value',
                total_treasury_finance_value
              );
            }

            //treasury_turn_over
            if (x.hasOwnProperty('treasury_turn_over')) {
              if (typeof x['treasury_turn_over'] === 'number') {
                total_treasury_turn_over += Number(x['treasury_turn_over']);
                if (x['treasury_turn_over']) {
                  x['treasury_turn_over'] = formatDataBasedOnColumn(
                    'treasury_turn_over',
                    x['treasury_turn_over']
                  );
                } else {
                  x['treasury_turn_over'] = '0';
                }
              } else if (
                typeof x['treasury_turn_over'] === 'string' &&
                x['treasury_turn_over'] !== ''
              ) {
                let convertedCount = x['treasury_turn_over'];
                let tempString = '';
                let splitArr = convertedCount.split('|');
                if (splitArr.length > 0) {
                  for (let j = 0; j < splitArr.length; j++) {
                    if (j === 0) {
                      tempString =
                        tempString +
                        formatDataBasedOnColumn('treasury_turn_over', splitArr[j]);
                    } else {
                      tempString =
                        tempString +
                        ' | ' +
                        formatDataBasedOnColumn('treasury_turn_over', splitArr[j]);
                    }
                    if (totalTreasuryTo[j] === undefined) {
                      totalTreasuryTo[j] = Number(splitArr[j]);
                    } else {
                      totalTreasuryTo[j] += Number(splitArr[j]);
                    }
                  }
                }
                x['treasury_turn_over'] = tempString;
              }
            }

            let convertedStringTreasuryTo = '';
            Object.keys(totalTreasuryTo).map((key, index) => {
              if (index === 0) {
                convertedStringTreasuryTo =
                  convertedStringTreasuryTo +
                  formatDataBasedOnColumn('treasury_turn_over', totalTreasuryTo[key]);
              } else {
                convertedStringTreasuryTo =
                  convertedStringTreasuryTo +
                  ' | ' +
                  formatDataBasedOnColumn('treasury_turn_over', totalTreasuryTo[key]);
              }
              return null;
            });
            if (convertedStringTreasuryTo && idx === data.data?.length - 1) {
              this.performance_total_sum_treasury_turn_over = convertedStringTreasuryTo;
            }
            if (total_treasury_turn_over && idx === data.data?.length - 1) {
              this.performance_total_sum_treasury_turn_over = formatDataBasedOnColumn(
                'treasury_turn_over',
                total_treasury_turn_over
              );
            }
          });
        }


        this.previewReportTotal = data.data ? data.data.total : 0;

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setPreviewFilterForExport = (data) => {
    this.previewFilterForExport = data;
  };

  setupReportGrid = (params) => {
    this.preview_report_grid = params;

    let columnConfig = this.reportColumnSettings;
    if (this.preview_report_grid && this.preview_report_grid.columnApi && columnConfig) {
      this.preview_report_grid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };
  onChildnGridChanged = (params) => {
    params.api.redrawRows();
    this.agGrid = params;
    // let columnConfig = localStorage.getItem('search_ticket_grid');
    // if (this.agGrid && this.agGrid.columnApi && columnConfig) {
    //   this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    // }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  // export data as a csv
  exportAudit = async () => {
    var account_numbers = [];
    var group_on = [];
    if (this.auditExportFormatedData && this.auditExportData) {
      Object.keys(this.auditExportData).forEach((AccountNumber) => {
        if (
          !['group_range', 'group_on', 'account_number'].includes(AccountNumber) &&
          !account_numbers.includes(AccountNumber)
        ) {
          account_numbers.push(AccountNumber);
        }
      });
      this.auditExportFormatedData.forEach((accountData) => {
        if (!group_on.includes(accountData.group_on)) {
          group_on.push(accountData.group_on);
        }
      });
      var csv_array = [];
      var csv_headerGroup = [''];
      account_numbers &&
        account_numbers.forEach((x, index) => {
          csv_headerGroup.push(x);
          csv_headerGroup.push(x);
          csv_headerGroup.push(x);
        });
      csv_array.push(csv_headerGroup);
      group_on &&
        group_on.forEach((x, index) => {
          var csv_row = [];
          if (index === 0) {
            csv_row = ['Group On'];
            account_numbers &&
              account_numbers.forEach((x) => {
                csv_row.push('Reciept');
                csv_row.push('Payment');
                csv_row.push('External');
              });
            csv_array.push(csv_row);
          }
          csv_row = [x];
          account_numbers &&
            account_numbers.forEach((account_no) => {
              var g_x = x;
              if ((x.match(/-/g) || []).length === 1) {
                g_x = x.replace('-', '_');
              }

              if (g_x && parseInt(g_x[0]) === 0) {
                g_x = g_x.slice(1);
              }
              if (
                this.auditExportData[account_no] &&
                this.auditExportData[account_no][g_x]
              ) {
                csv_row.push(`"${this.auditExportData[account_no][g_x].receipts.main +
                  '\n' +
                  this.auditExportData[account_no][g_x].receipts.errors
                  }
						"`);
                csv_row.push(`"${this.auditExportData[account_no][g_x].payments.main +
                  '\n' +
                  this.auditExportData[account_no][g_x].payments.errors
                  }
						"`);
                csv_row.push(`"${this.auditExportData[account_no][g_x].externals
                  ? this.auditExportData[account_no][g_x].externals.main +
                  '\n' +
                  this.auditExportData[account_no][g_x].externals.errors
                  : '0\n0'
                  }
						"`);
              } else {
                csv_row.push(`"0\n0"`);
                csv_row.push(`"0\n0"`);
                csv_row.push(`"0\n0"`);
              }
            });
          csv_array.push(csv_row);
        });
    }
    let csvContent =
      'data:text/csv;charset=utf-8,' + csv_array.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);

    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `auditlog-export-${moment().format('YYYY-MM-DD hh:mm:s')}.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click();
    document.body.removeChild(link);
  };

  saveGridColumnState = (id, payload = null) => {
    if (payload) {
      return Axios.post(`report/column-setting/save/${id}`, payload)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response: { data } }) => {
          var errors = [];
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
          data.errors = errors;
          return Promise.reject(data);
        });
    }
  };

  /** This will be effect when clear action in preview report */
  handleResetGrid = () => {
    if (this.preview_report_grid) {
      if (this.reportColumnSettings) {
        ///this.setReportColumnSettings(null);
      }
      this.preview_report_grid.columnApi.resetColumnState();
      this.preview_report_grid.api.setFilterModel(null);
      this.preview_report_grid.api.setSortModel(null);

      //   this.preview_report_grid.api.setFilterModel(null);
      // this.preview_report_grid.api.setSortModel(null);
      // this.preview_report_grid.columnApi.resetColumnState();
      // this.preview_report_grid.columnApi.autoSizeColumns();
      // this.preview_report_grid.api.onFilterChanged(null);
    }
  };

  //set Deal Status Update API
  getReportUsersList = (payload) => {
    return Axios.post(`user/dropdown/list`, payload).then(({ data }) => {
      this.report_dropdown_users_list = data.data;
      return data;
    });
  };
  getReportUserGroupList = (payload) => {
    return Axios.post(`deal-desk/user/user-group/list`, payload).then(({ data }) => {
      this.dropdown_userGroupList = data.data;
      return data;
    });
  };
  setPreview_report_grid = (data) => {
    this.preview_report_grid = data;
  };

  exportPreviewReportByAgGrid = (name) => {
    if (this.preview_report_grid) {
      let params = {
        fontSize: 10,
        rowHeight: 16,
        fileName: `${name}.csv`,
      };

      this.preview_report_grid.api.exportDataAsCsv(params);
    }
  };

  setReport_on_report = (data) => {
    this.report_on_report = data;
  };

  setMockDataToggleDeleteColumn = (includeDeleteOn = false) => {
    if (includeDeleteOn) {
      let lastIndex = this.mockData.length - 1;
      let newLastIndex = lastIndex + 1;
      let elementDetails = reportDynamicFieldsListColumnConfig?.['deleted_at'];
      this.mockData.push({
        key: newLastIndex.toString(),
        title: `${elementDetails && elementDetails?.title
          ? elementDetails?.title
          : capitalize('deleted_at')
          }`,
        description: 'deleted_at',
        disabled: true,
      });
      this.setTargetKeys([...this.targetKeys, newLastIndex.toString()]);
      this.setSelectedReportFieldsKeys([...this.selectedReportFieldsKeys, 'deleted_at']);
    } else {
      const deleteIndexFind = (x) => x.description === 'deleted_at';
      let deletedAtIndex = this.mockData.findIndex(deleteIndexFind);

      if (deletedAtIndex >= 0) {
        let newMockData = this.mockData.filter(
          (item) => item.description !== 'deleted_at'
        );
        this.setMockData(newMockData);
        let newTargetKeys = this.targetKeys.filter(
          (item) => item !== deletedAtIndex.toString()
        );
        this.setTargetKeys(newTargetKeys);
        let newSelectedKeys = this.selectedReportFieldsKeys.filter(
          (x) => x !== 'deleted_at'
        );
        this.setSelectedReportFieldsKeys(newSelectedKeys);
      }
    }
  };

  exportDataFromServer = (payload) => {
    return Axios.post(`/v2/report/preview/data/export`, payload)
      .then(({ data }) => {
        if (data.data && data.data.url) {
          window.open(data.data.url, '_blank');
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //--------------------START:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//
  setupInvestmentGroup = (params) => {
    this.agGridGroupInvestment = params;
  };
  setupInvestmentDrillDownDateWise = (params) => {
    this.agGridGroupInvestment = params;
    let defaultsort = [
      {
        colId: 'datetime',
        sort: 'desc',
      },
    ];
    this.agGridGroupInvestment.api.setSortModel(defaultsort);
  };
  setupTimespentTypeOthers = (params) => {
    this.agGRidTimespentTypeOthers = params;
    let defaultsort = [
      {
        colId: 'datetime',
        sort: 'desc',
      },
    ];
    this.agGRidTimespentTypeOthers.api.setSortModel(defaultsort);
  };
  getUserInvestmentHourDetail = (payload) => {
    this.drilldownLoader = true;
    return Axios.post(`/v2/report/investment-hours/data`, payload)
      .then(({ data }) => {
        this.drilldownLoader = false;
        this.investmentHoursDetailDataByuser = data?.data;
        return data && data?.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  //--------------------END:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//

  onInvestmentHourGridChanged = (params) => {
    //this.investment_hour_grid_settings
  };

  // Setup grid and set column size to autosize
  setupAuditGrid = (params, index) => {
    this.AuditGrid = params;
    let columnConfig = this.reportColumnSettings;
    if (this.AuditGrid && this.AuditGrid.columnApi && columnConfig) {
      this.AuditGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };

  handleResetInvestmentGrid = () => {
    if (this.AuditGrid) {
      if (this.reportColumnSettings) {
        let NewcolumnDef = this.AuditGrid.columnApi.getAllColumns();
        let allColumnIds = [];
        NewcolumnDef.forEach(function (column) {
          allColumnIds.push(column.colId.replace('_1', ''));
        });
        this.AuditGrid.api.setColumnDefs(allColumnIds);
      }
      this.setReportColumnSettings(null);
      this.AuditGrid.columnApi.resetColumnState();
    }
  };

  setinvestment_hour_grid_settings = (data) => {
    this.investment_hour_grid_settings = data;
  };

  drilldownRedrawGrid = (params) => {
    params.api.redrawRows();
  };

  exportInvestmentHourReport = (name) => {
    if (this.AuditGrid) {
      let params = {
        fontSize: 10,
        rowHeight: 16,
        fileName: `${name}.csv`,
      };

      this.AuditGrid.api.exportDataAsCsv(params);
    }
  };

  getRecordType = (type = {}) => {
    return Axios.get('leads-record-type/list', type).then(({ data }) => {
      let recordTypeList_temp = data.data;
      recordTypeList_temp &&
        recordTypeList_temp.length > 0 &&
        recordTypeList_temp.sort(function (a, b) {
          const nameA = a.record_type_name;
          const nameB = b.record_type_name;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      this.recordTypeList = recordTypeList_temp;
      return data;
    });
  };

  //Get Lead Type List
  getLeadTypeList = (form = null, withoutsort = false) => {
    Axios.get('leads-status/status/list').then(({ data }) => {
      let lead_type_list_temp = data.data;
      // if (!withoutsort) {
      //   lead_type_list_temp &&
      //     lead_type_list_temp.length > 0 &&
      //     lead_type_list_temp.sort(function (a, b) {
      //       const nameA = a.status_name;
      //       const nameB = b.status_name;
      //       if (nameA < nameB) {
      //         return -1;
      //       }
      //       if (nameA > nameB) {
      //         return 1;
      //       }
      //       return 0;
      //     });
      // }
      this.lead_type_list = lead_type_list_temp;

      this.getStatusList([], form?.record_type, withoutsort).then(() => { });

      // if (form?.report_type && (form?.report_type === 'summary' || form?.report_type === 'count_list')) {
      //   this.getStatusList([], [], withoutsort).then(() => { }); // Due to independent dropdown
      // } else {
      //   this.getStatusList([], form?.record_type, withoutsort).then(() => { });
      // }
    });
  };
  // Get Status List by Lead Type
  getStatusList = (lead_list = [], record_type_list = [], withoutsort = false) => {
    let status_list = [];
    let tempList = this.lead_type_list;

    if (typeof lead_list === 'number') {
      lead_list = [lead_list];
    }
    if (typeof record_type_list === 'number') {
      record_type_list = [record_type_list];
    }
    if (lead_list.length > 0) {
      lead_list?.map((obj) => {
        let statusIndex =
          tempList && tempList.length > 0 && tempList.findIndex((x) => x.id === obj);
        if (statusIndex !== -1) {
          let child_list = tempList[statusIndex]['child_leads'];
          status_list = [...status_list, ...child_list];
        }
        return null;
      });
    } else {
      tempList.map((obj) => {
        if (obj.child_leads) {
          status_list = [...status_list, ...obj.child_leads];
        }
        return null;
      });
    }
    let final_status_list = [];

    if (record_type_list.length > 0) {
      record_type_list.map((obj) => {
        let newRecordList =
          status_list &&
          status_list.length > 0 &&
          status_list.filter((x) => x.record_type_id.includes(obj));
        final_status_list = [...final_status_list, ...newRecordList];
        return null;
      });
    } else {
      final_status_list = status_list;
    }
    if (final_status_list && final_status_list.length > 0) {
      final_status_list = final_status_list.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      // if (!withoutsort) {
      //   final_status_list.sort(function (a, b) {
      //     const nameA = a.status_name;
      //     const nameB = b.status_name;
      //     if (nameA < nameB) {
      //       return -1;
      //     }
      //     if (nameA > nameB) {
      //       return 1;
      //     }
      //     // names must be equal
      //     return 0;
      //   });
      // }
    }

    this.lead_status_list = final_status_list;
    return Promise.resolve();
  };
  setSearchLeadKey = (data) => {
    this.searchLeadKey = data;
  };
  setSearchTicketKey = (data) => {
    this.searchTicketKey = data;
  };

  setOriginalReadArr = (data) => {
    this.originalReadArr = data;
  };

  setIsPreviewFromEditMode = (data) => {
    this.isPreviewFromEditMode = data;
  };

  getTicketPriorityDataByUser = (payload) => {
    this.drilldownLoader = true;
    return Axios.post(`/v2/report/preview/data`, payload)
      .then(({ data }) => {
        this.drilldownLoader = false;
        this.ticketPriorityDataByUser = data?.data;
        return data && data?.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  }
}

decorate(ReportStore, {
  list_data: observable,
  per_page: observable,
  current_page: observable,
  total: observable,
  previewReportTotal: observable,
  agGrid: observable,

  reportGridLoader: observable,
  deleteValues: observable,
  globalStatusTempData: observable,

  //-------PREVIEWREPORT VARIABLES---//
  reportColumnSettings: observable,
  preview_report_criteria: observable,
  previewReportValues: observable,
  previewFilterForExport: observable,
  preview_report_grid: observable,
  //-------PREVIEWREPORT VARIABLES---//

  //-------FORM OBSERVERABLE---//
  initialValues: observable,
  initialSummaryValues: observable,
  selectedReportFieldsKeys: observable,
  //tempSelectedReportFieldsKeys: observable,
  columnsForSortList: observable,
  //-------FORM OBSERVERABLE---//

  //--------------------START:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//
  investmentHoursDetailDataByuser: observable,
  drilldownLoader: observable,
  //--------------------END:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//

  //-------OTHERS - DROPDOWN VARIABLES------//
  reportType: observable,
  created_by_id: observable,
  org_contacts_list_data: observable,
  contacts_list_data: observable,
  lead_search_dropdown_data: observable,
  ticket_list_data: observable,
  lead_list_data: observable,
  classification_role_list: observable,
  financial_year_list: observable,
  product_list: observable,
  dropdown_userGroupList: observable,
  report_dropdown_users_list: observable,
  performance_total_investment_hours: observable,
  performance_total_sum_finance_value: observable,
  total_sum_finance: observable,
  fetchingLead: observable,
  fetchingTicketSearch: observable,
  targetKeys: observable,
  mockData: observable,
  selectedKeys: observable,
  report_on_report: observable,
  auditExportData: observable,
  auditExportFormatedData: observable,
  AuditGrid: observable,
  payload_drilldown_generated_on: observable,
  investment_hour_grid_settings: observable,
  recordTypeList: observable,
  lead_type_list: observable,
  lead_status_list: observable,
  searchLeadKey: observable,
  searchTicketKey: observable,
  originalReadArr: observable,
  isPreviewFromEditMode: observable,

  //-------OTHERS------//
  ticketPriorityDataByUser: observable,

  //-------SETUP GRID ACTIONS---//
  setPageSize: action,
  setupGrid: action,
  getList: action,
  onFilterChanged: action,
  onGridChanged: action,
  handleReset: action,
  handleInnserReset: action,
  //-------SETUP GRID ACTIONS---//

  //-------PREVIEWREPORT ACTIONS---//
  setReportColumnSettings: action,
  setPreviewFilterForExport: action,
  saveGridColumnState: action,
  exportPreviewReportByAgGrid: action,
  exportDataFromServer: action,
  //-------PREVIEWREPORT ACTIONS---//

  //-------OTHERS ACTIONS ----//
  setCreated_by_id: action,
  searchOrgContacts: action,
  searchPersonContacts: action,
  searchLeadSOLDName: action,
  searchTicket: action,
  getEditLeads: action,
  getClassificationList: action,
  getFinancialYearList: action,
  getProductList: action,
  getReportUsersList: action,
  getReportUserGroupList: action,
  //-------OTHERS ACTIONS---//

  //-------REPORT GRID ACTIONS---//
  setDeleteValues: action,
  DeleteData: action,
  editFilter: action,
  AddData: action,
  EditData: action,
  PreviewData: action,
  //-------REPORT GRID ACTIONS---//

  //---------REPORT PREVIEW GRID ACTION ----//
  setupReportGrid: action,
  onChildnGridChanged: action,
  handleResetGrid: action,
  setPreview_report_grid: action,
  //---------REPORT PREVIEW GRID ACTION ----//

  //------------FAV TOGGLE ACTIONS--------------------//
  setGlobalStatusTempData: action,
  saveToMenu: action,
  //------------FAV TOGGLE ACTIONS--------------------//

  setPreviewReportValues: action,
  formatFormData: action,
  setSelectedReportFieldsKeys: action,
  setColumnForSortList: action,
  searchLead: action,
  setTargetKeys: action,
  setMockData: action,
  setSelectedKeys: action,
  setReport_on_report: action,
  onInvestmentHourGridChanged: action,
  setupAuditGrid: action,
  exportInvestmentHourReport: action,
  setinvestment_hour_grid_settings: action,
  handleResetInvestmentGrid: action,
  setupInvestmentDrillDownDateWise: action,
  getRecordType: action,
  getLeadTypeList: action,
  getStatusList: action,
  setSearchLeadKey: action,
  setSearchTicketKey: action,
  setOriginalReadArr: action,
  setIsPreviewFromEditMode: action,
  getTicketPriorityDataByUser: action,
});
