import { React } from 'react';
import { observer } from 'mobx-react';

// import { useTranslation } from 'react-i18next';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import LocalGridConfig from '../../../../config/LocalGridConfig';
import useStore from '../../../../store';
import PreviewDataTableElement from '../elements/PreviewDataTableElement';
import { vsmCommon } from '../../../../config/messages';
import GetGridColumnOptions from '../GetGridColumnOptions';
import {
  capitalize,
  formatDataBasedOnColumn,
  kpiReportType,
  listReportType,
  numberWithCommas,
  relationShipsReportType,
  replaceUnderScoreWithSpace,
  // secondsToHmsWithZeros,
  summaryType,
  countsListType,
  timeSpentReportType,
} from '../../../../utils/GlobalFunction';
import PreviewInvestmentHourReportElement from '../elements/PreviewInvestmentHourReportElement';

const PreviewDataGridComponent = observer((props) => {
  // const { t } = useTranslation();
  const { REPORTSTORE } = useStore();

  const report_on = props.form.getFieldValue('report_on')?.replace(/-/g, '_');

  let report_type =
    props.form.getFieldValue('report_type') ||
    REPORTSTORE.previewReportValues?.report_type;

  let gridOptions = {
    sold: GetGridColumnOptions(report_on, report_type), //is value of report_on field
    tags: GetGridColumnOptions(report_on, report_type), //is value of report_on field
    tickets: GetGridColumnOptions(report_on, report_type), //is value of report_on field
    reminders: GetGridColumnOptions(report_on, report_type), //is value of report_on field
    people: GetGridColumnOptions(report_on, report_type), //is value of report_on field
    organisation: GetGridColumnOptions(report_on, report_type), //is value of report_on field
    saved_filter: GetGridColumnOptions(report_on, report_type), //is value of report_on field
  };

  let PinnedData = [];
  let columns = [];

  // let rowData = props.rowData

  // if (props.form.getFieldValue('report_type') === "summary") {
  //   const rowListRecord = rowData ? rowData : null;

  //   if (rowListRecord) {
  //     let cloneRowData = [...rowListRecord];
  //     const rowLastIndexRecord = cloneRowData.splice(-1);

  //     // historyTotal = rowLastIndexRecord;
  //     // rowData = cloneRowData.splice(0, cloneRowData.length);
  //   }
  // }

  if (summaryType.includes(report_type) || countsListType.includes(report_type) || kpiReportType.includes(report_type)) {
    let columnDefs = [];

    let list_total = {};
    let convertedString = '';
    let rowData = props.rowData;

    if (summaryType.includes(report_type)) {
      const getData = (rowData !== null) && rowData;
      let keys = [];
      (typeof getData === 'object') && getData.forEach((item) =>
        Object.keys(item).forEach((val) => !keys.includes(val) && keys.push(val))
      )
      const output = (typeof getData === 'object') && getData.map((item) => {
        let newitem = {};
        [...keys].forEach((val) => newitem = { ...newitem, [val]: item[val] ?? '' })
        return newitem
      }
      );
      rowData = output
    }
    rowData &&
      rowData.length > 0 &&
      rowData.map((item, index) => {
        if (index === 0) {
          Object.keys(item).forEach((x) => {
            if (['#', 'sr'].includes(x)) {
              list_total[x] = 'Total';
            } else if (['id', 'days'].includes(x)) {
              list_total[x] = '';
            } else if (x === 'user' || x === 'Lead Status' || x === 'ticket_number') {
              list_total[x] = 'Total';
            } else if (x === 'Time Spent') {
              list_total[x] = REPORTSTORE.performance_total_investment_hours;
            } else if (x === 'finance') {
              // list_total[x] = REPORTSTORE.performance_total_sum_finance_value;
              if (countsListType.includes(report_type)) {
                list_total[x] = REPORTSTORE.total_sum_finance;
              } else {
                list_total[x] = REPORTSTORE.performance_total_sum_finance_value;
              }
              // list_total[x] = "8000"
            } else if (x === 'financial_value') {
              list_total[x] = REPORTSTORE.performance_total_sum_finance_value;
            } else if (x === 'turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_turn_over_value;
            } else if (x === 'leads_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_lead_turn_over_value;
            } else if (x === 'sold_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_sold_turn_over_value;
            } else if (x === 'active_deals_finance_value') {
              list_total[x] =
                REPORTSTORE.performance_total_sum_active_deals_finance_value;
            } else if (x === 'active_deals_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_active_deals_turn_over;
            } else if (x === 'active_leads_finance_value') {
              list_total[x] =
                REPORTSTORE.performance_total_sum_active_leads_finance_value;
            } else if (x === 'active_leads_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_active_leads_turn_over;
            } else if (x === 'active_trade_finance_value') {
              list_total[x] =
                REPORTSTORE.performance_total_sum_active_trade_finance_value;
            } else if (x === 'active_trade_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_active_trade_turn_over;
            } else if (x === 'active_treasury_finance_value') {
              list_total[x] =
                REPORTSTORE.performance_total_sum_active_treasury_finance_value;
            } else if (x === 'active_treasury_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_active_treasury_turn_over;
            } else if (x === 'deals_finance_value') {
              list_total[x] = REPORTSTORE.performance_total_sum_deals_finance_value;
            } else if (x === 'deals_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_deals_turn_over;
            } else if (x === 'leads_finance_value') {
              list_total[x] = REPORTSTORE.performance_total_sum_leads_finance_value;
            } else if (x === 'leads_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_leads_turn_over;
            } else if (x === 'sold_finance_value') {
              list_total[x] = REPORTSTORE.performance_total_sum_sold_finance_value;
            } else if (x === 'sold_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_sold_turn_over;
            } else if (x === 'trade_finance_value') {
              list_total[x] = REPORTSTORE.performance_total_sum_trade_finance_value;
            } else if (x === 'trade_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_trade_turn_over;
            } else if (x === 'treasury_finance_value') {
              list_total[x] = REPORTSTORE.performance_total_sum_treasury_finance_value;
            } else if (x === 'treasury_turn_over') {
              list_total[x] = REPORTSTORE.performance_total_sum_treasury_turn_over;
            } else {
              let total = 0;
              let totalArr = {};
              props.rowData.map((obj, idx) => {
                let convertedCount = obj[x];


                // if ([' - Lead'].includes(x)) {
                //   convertedCount = Number(convertedCount)
                //   console.log("yes convertedCount", x, typeof convertedCount, convertedCount);
                // }

                if (typeof convertedCount === 'string') {
                  if (convertedCount.includes('|')) {
                    let splitArr = convertedCount.split('|');
                    if (splitArr.length > 0) {
                      for (let j = 0; j < splitArr.length; j++) {
                        if (typeof splitArr[j] === 'string') {
                          splitArr[j] = Number(splitArr[j].replace(/,/g, ''));
                        }
                        if (totalArr[j] === undefined) {
                          totalArr[j] = Number(splitArr[j]);
                        } else {
                          totalArr[j] += Number(splitArr[j]);
                        }
                      }
                    }
                  } else {
                    convertedCount = convertedCount.replace(/,/g, '');
                  }
                }


                Object.keys(totalArr).map((key, index) => {
                  if (index === 0) {
                    convertedString = convertedString + numberWithCommas(totalArr[key]);
                  } else {
                    convertedString =
                      convertedString + ' | ' + numberWithCommas(totalArr[key]);
                  }
                  return null;
                });

                if (typeof convertedCount === 'string') {
                  if (convertedCount?.includes('|')) {
                    total = convertedString;
                  } else {
                    total += Number(convertedCount);
                  }
                } else {
                  total += Number(convertedCount);
                }
                return null;
              });
              if (typeof total === 'number' && !isNaN(total)) {
                list_total[x] = numberWithCommas(total);
              } else if (typeof total === 'string') {
                list_total[x] = total;
              }
            }
          });
        }


        Object.keys(item).forEach((x) => {
          if (!['user', 'id', 'time_spent_original', 'finance_original', 'finance', 'modified_date'].includes(x)) {
            if (item[x] !== '') {
              item[x] = item[x] ? item[x] : 0;
              item[x] = numberWithCommas(item[x]);
            }
          }
        });

        columnDefs.length <= 0 &&
          Object.keys(item).forEach((x) => {
            if (['finance', 'financial_value',].includes(x)) {
              columnDefs.push({
                headerName: capitalize(replaceUnderScoreWithSpace(x)),
                headerTooltip: capitalize(replaceUnderScoreWithSpace(x)),
                cellStyle: { 'text-align': 'right' },
                field: x,
                cellRenderer: (params) => {
                  let total = params?.value ? params?.value : 0
                  return formatDataBasedOnColumn(x, total)
                },
              });

            }
            else if (['last_summary'].includes(x)) {
              columnDefs.push({
                headerName: capitalize(replaceUnderScoreWithSpace(x)),
                headerTooltip: capitalize(replaceUnderScoreWithSpace(x)),
                cellStyle: { 'text-align': 'left' },
                field: x,
                cellRenderer: (params) => {
                  let updateData = params?.value ? JSON.stringify(params?.value) : '';
                  return updateData ? updateData : '';
                },
                comparator: (valueA, valueB, nodeA, nodeB) => {
                  if (valueA) { valueA = JSON.stringify(valueA); }
                  if (valueB) { valueB = JSON.stringify(valueB); }
                  return valueA > valueB ? 1 : -1;
                }
              });
            }

            else if (!['time_spent_original', 'finance_original'].includes(x)) {
              columnDefs.push({
                headerName: capitalize(replaceUnderScoreWithSpace(x)),
                headerTooltip: capitalize(replaceUnderScoreWithSpace(x)),
                cellStyle: {
                  'text-align': ['user', 'Lead Status'].includes(x)
                    ? 'left'
                    : [
                      'Time Spent',
                      'financial_value',
                      'turn_over',
                      'leads_turn_over',
                      'sold_turn_over',
                      'active_deals_finance_value',
                      'active_deals_turn_over',
                      'active_leads_finance_value',
                      'active_leads_turn_over',
                      'active_trade_finance_value',
                      'active_trade_turn_over',
                      'active_treasury_finance_value',
                      'active_treasury_turn_over',
                      'deals_finance_value',
                      'deals_turn_over',
                      'leads_finance_value',
                      'leads_turn_over',
                      'sold_finance_value',
                      'sold_turn_over',
                      'trade_finance_value',
                      'trade_turn_over',
                      'treasury_finance_value',
                      'treasury_turn_over',
                    ].includes(x)
                      ? 'right'
                      : ['lead_status'].includes(x)
                        ? 'left'
                        : 'center',
                },
                field: x,
                comparator: (valueA, valueB, nodeA, nodeB) => {
                  if (['user', 'Lead Status', 'lead_status'].includes(x)) {
                    if (valueA === valueB) return 0;
                    return valueA > valueB ? 1 : -1;
                  }
                  // else if (
                  //   (['Last Summary'].includes(x)) ||
                  //   (x.includes('Last Summary')) ||
                  //   (['last_summary'].includes(x)) ||
                  //   (x.includes('last_summary'))
                  // ) {
                  //   console.log("yes last_summary", typeof valueA, valueA);
                  //   if (valueA) { valueA = String(valueA); }
                  //   if (valueB) { valueB = String(valueB); }
                  //   return valueA > valueB ? 1 : -1;
                  // }
                  else if (['#'].includes(x)) {
                    if (valueA) { valueA = Number(valueA); }
                    if (valueB) { valueB = Number(valueB); }
                    return valueA > valueB ? 1 : -1;
                  }
                  else if (
                    (x.includes('- Lead')) ||
                    (x.includes('- Deal')) ||
                    (x.includes('- Trade')) ||
                    (x.includes('- Treasury'))
                  ) {
                    if (valueA) { valueA = Number(valueA); }
                    if (valueB) { valueB = Number(valueB); }
                    return valueA > valueB ? 1 : -1;
                  }
                  else if (['Time Spent'].includes(x)) {
                    if (nodeA.data.time_spent_original) {
                      nodeA.data.time_spent_original = Number(
                        nodeA.data.time_spent_original
                      );
                    }
                    if (nodeB.data.time_spent_original) {
                      nodeB.data.time_spent_original = Number(
                        nodeB.data.time_spent_original
                      );
                    }
                    return nodeA.data.time_spent_original > nodeB.data.time_spent_original
                      ? 1
                      : -1;
                  }
                  else if (['financial_value'].includes(x)) {
                    if (nodeA.data.finance_original) {
                      nodeA.data.finance_original = Number(nodeA.data.finance_original);
                    }
                    if (nodeB.data.finance_original) {
                      nodeB.data.finance_original = Number(nodeB.data.finance_original);
                    }
                    return nodeA.data.finance_original > nodeB.data.finance_original
                      ? 1
                      : -1;
                  }
                  else if (['leads_turn_over'].includes(x)) {
                    if (nodeA.data.leads_turn_over_original) {
                      nodeA.data.leads_turn_over_original = Number(
                        nodeA.data.leads_turn_over_original
                      );
                    }
                    if (nodeB.data.leads_turn_over_original) {
                      nodeB.data.leads_turn_over_original = Number(
                        nodeB.data.leads_turn_over_original
                      );
                    }
                    return nodeA.data.leads_turn_over_original >
                      nodeB.data.leads_turn_over_original
                      ? 1
                      : -1;
                  }
                  else if ((typeof valueA === 'string') || (typeof valueB === 'string')) {
                    if (typeof valueA === 'string') {
                      valueA = valueA.replace(/,/g, '');
                    }
                    if (typeof valueB === 'string') {
                      valueB = valueB.replace(/,/g, '');
                    }
                    // return valueA - valueB;
                    if (valueA === valueB) return 0;
                    return valueA > valueB ? 1 : -1;
                  }
                  else {
                    if (valueA === valueB) return 0;
                    return valueA > valueB ? 1 : -1;
                  }
                },
                cellRenderer: (params) => {
                  let data = params.value
                  if (summaryType.includes(report_type)) {
                    data = params.value ?? 0
                  }
                  if (['lead_status'].includes(x)) {

                    if (params?.data?.is_downgraded === true) {
                      data = params.value + ' -'
                    } else {
                      data = params.value
                    }
                  }
                  return data;
                },
              });
            }

          });

        return item;

      });

    // for summary report finance start
    let summaryWholeData = rowData && rowData?.length > 0 && rowData
    list_total.finance = summaryWholeData && summaryWholeData.reduce((previousValue, currentValue) =>
      parseFloat(previousValue) + parseFloat(currentValue.finance), 0
    );
    // for summary report finance end

    PinnedData.push(list_total);

    let columnConfig = REPORTSTORE.reportColumnSettings;
    if (columnConfig && !REPORTSTORE.isPreviewFromEditMode) {
      let data = JSON.parse(columnConfig);
      let cols = columnDefs.find((x) => !x.field);
      if (cols) {
        columns.push(cols);
      }
      data &&
        data.forEach((element) => {
          cols = columnDefs.find((x) => x.field === element.colId);
          if (cols) {
            if (element?.width) {
              cols.width = element.width;
            }
            columns.push(cols);
          }
        });
    } else {
      columns = columnDefs;
    }
  }



  const getColumnsBasedOnReportType = () => {
    if (props.form.getFieldValue("report_type") === "summary") {
      columns = columns.filter((n) => n.field !== "id");
    } //removed id column when ticket-priority selected
    if (summaryType.includes(report_type) || countsListType.includes(report_type) || kpiReportType.includes(report_type)) {
      return columns;
    } else if (
      listReportType.includes(report_type) ||
      relationShipsReportType.includes(report_type)
    ) {
      let listCols = [];
      let columnConfig = REPORTSTORE.reportColumnSettings;
      if (columnConfig && !REPORTSTORE.isPreviewFromEditMode) {
        let data = JSON.parse(columnConfig);
        let cols = gridOptions[report_on]?.find((x) => !x.field);
        if (cols) {
          listCols.push(cols);
        }
        data &&
          data.forEach((element) => {
            cols = gridOptions[report_on]?.find((x) => x.field === element.colId);
            if (cols) {
              if (element?.width) {
                cols.width = element.width;
              }
              listCols.push(cols);
            }
          });
      } else {
        listCols = gridOptions[report_on];
      }
      let pin_columns = {};
      props.rowData &&
        props.rowData.map((item, index) => {
          if (index === 0) {
            Object.keys(item).forEach((x) => {
              let total = 0;
              let timeSpentTotal = 0;
              let financial_valTotal = 0;
              let turn_over_valTotal = 0;
              let leads_finance_value_valTotal = 0;
              let leads_turn_over_valTotal = 0;
              let active_deals_finance_value_valTotal = 0;
              let active_deals_turn_over_valTotal = 0;
              let active_leads_finance_value_valTotal = 0;
              let active_leads_turn_over_valTotal = 0;
              let active_trade_finance_value_valTotal = 0;
              let active_trade_turn_over_valTotal = 0;
              let active_treasury_finance_value_valTotal = 0;
              let active_treasury_turn_over_valTotal = 0;
              let deals_finance_value_valTotal = 0;
              let deals_turn_over_valTotal = 0;
              let sold_finance_value_valTotal = 0;
              let sold_turn_over_valTotal = 0;
              let trade_finance_value_valTotal = 0;
              let trade_turn_over_valTotal = 0;
              let treasury_finance_value_valTotal = 0;
              let treasury_turn_over_valTotal = 0;
              props.rowData.map((obj, idx) => {

                if (
                  [
                    'total_turn_over',
                    'total_comments',
                    'total_communication',
                    'total_conversation',
                    'total_conversation_words',
                    'total_inbound_notes',
                    'total_outbound_notes',
                    'total_words',
                    'total_notes',
                    'deals',
                    // 'meetings',
                    'meetings_held',
                    'qualify_held',
                    'traded',
                    'chat'
                  ].includes(x)
                ) {
                  if (obj[x]) {
                    total += Number(obj[x]);
                  }
                } else if (['investment_hours'].includes(x)) {
                  if (obj[x]) {
                    timeSpentTotal += Number(obj[x]);
                  }
                } else if (['financial_value'].includes(x)) {
                  financial_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                  obj[x] = Number(obj[x])
                }
                // else if (['turn_over'].includes(x)) {
                //   leads_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                // }
                else if (['leads_turn_over'].includes(x)) {
                  leads_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                }
                else if (['turn_over'].includes(x)) {
                  turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                }
                // else if (['turn_over'].includes(x)) {
                //   turn_over_valTotal += !isNaN(obj[x]) ? obj[x] : 0;
                //   // turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                // }
                // else if (['turn_over_original'].includes(x)) {
                //   turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                // }
                else if (['active_deals_finance_value_original'].includes(x)) {
                  active_deals_finance_value_valTotal += !isNaN(obj[x])
                    ? Number(obj[x])
                    : 0;
                } else if (['active_deals_turn_over_original'].includes(x)) {
                  active_deals_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['active_leads_finance_value_original'].includes(x)) {
                  active_leads_finance_value_valTotal += !isNaN(obj[x])
                    ? Number(obj[x])
                    : 0;
                } else if (['active_leads_turn_over'].includes(x)) {
                  active_leads_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['active_trade_finance_value_original'].includes(x)) {
                  active_trade_finance_value_valTotal += !isNaN(obj[x])
                    ? Number(obj[x])
                    : 0;
                } else if (['active_trade_turn_over_original'].includes(x)) {
                  active_trade_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['active_treasury_finance_value_original'].includes(x)) {
                  active_treasury_finance_value_valTotal += !isNaN(obj[x])
                    ? Number(obj[x])
                    : 0;
                } else if (['active_treasury_turn_over_original'].includes(x)) {
                  active_treasury_turn_over_valTotal += !isNaN(obj[x])
                    ? Number(obj[x])
                    : 0;
                } else if (['deals_finance_value_original'].includes(x)) {
                  deals_finance_value_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['deals_turn_over_original'].includes(x)) {
                  deals_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['leads_finance_value_original'].includes(x)) {
                  leads_finance_value_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['leads_turn_over'].includes(x)) {
                  leads_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['sold_finance_value_original'].includes(x)) {
                  sold_finance_value_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['sold_turn_over_original'].includes(x)) {
                  sold_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['trade_finance_value_original'].includes(x)) {
                  trade_finance_value_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['trade_turn_over_original'].includes(x)) {
                  trade_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['treasury_finance_value_original'].includes(x)) {
                  treasury_finance_value_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                } else if (['treasury_turn_over_original'].includes(x)) {
                  treasury_turn_over_valTotal += !isNaN(obj[x]) ? Number(obj[x]) : 0;
                }
                return null;
              });

              if (
                [
                  'total_turn_over',
                  'total_comments',
                  'total_communication',
                  'total_conversation',
                  'total_conversation_words',
                  'total_inbound_notes',
                  'total_outbound_notes',
                  'total_words',
                  'total_notes',
                  'deals',
                  // 'meetings',
                  'meetings_held',
                  'qualify_held',
                  'traded',
                  'chat'
                ].includes(x)
              ) {
                pin_columns[x] = numberWithCommas(total);
              }
              else if (['investment_hours'].includes(x)) {
                pin_columns["investment_hours"] = timeSpentTotal;
              }
              else if (['financial_value'].includes(x)) {
                pin_columns['financial_value'] = !isNaN(financial_valTotal)
                  ? formatDataBasedOnColumn('financial_value', financial_valTotal)
                  : 0;
              } else if (['leads_turn_over'].includes(x)) {
                pin_columns['leads_turn_over'] = !isNaN(leads_turn_over_valTotal)
                  ? formatDataBasedOnColumn('leads_turn_over', leads_turn_over_valTotal)
                  : 0;
              }

              else if (['turn_over'].includes(x)) {
                pin_columns['turn_over'] = turn_over_valTotal
                // pin_columns['turn_over'] = formatDataBasedOnColumn(
                //   'turn_over',
                //   turn_over_valTotal
                // );
              }


              // else if (['turn_over_original'].includes(x)) {
              //   pin_columns['turn_over'] = formatDataBasedOnColumn(
              //     'turn_over',
              //     turn_over_valTotal
              //   );
              // }
              else if (['active_deals_finance_value_original'].includes(x)) {
                pin_columns['active_deals_finance_value'] = formatDataBasedOnColumn(
                  'active_deals_finance_value',
                  active_deals_finance_value_valTotal
                );
              } else if (['active_deals_turn_over_original'].includes(x)) {
                pin_columns['active_deals_turn_over'] = formatDataBasedOnColumn(
                  'active_deals_turn_over',
                  active_deals_turn_over_valTotal
                );
              } else if (['active_leads_finance_value_original'].includes(x)) {
                pin_columns['active_leads_finance_value'] = formatDataBasedOnColumn(
                  'active_leads_finance_value',
                  active_leads_finance_value_valTotal
                );
              } else if (['active_leads_turn_over_original'].includes(x)) {
                pin_columns['active_leads_turn_over'] = formatDataBasedOnColumn(
                  'active_leads_turn_over',
                  active_leads_turn_over_valTotal
                );
              } else if (['active_trade_finance_value_original'].includes(x)) {
                pin_columns['active_trade_finance_value'] = formatDataBasedOnColumn(
                  'active_trade_finance_value',
                  active_trade_finance_value_valTotal
                );
              } else if (['active_trade_turn_over_original'].includes(x)) {
                pin_columns['active_trade_turn_over'] = formatDataBasedOnColumn(
                  'active_trade_turn_over',
                  active_trade_turn_over_valTotal
                );
              } else if (['active_treasury_finance_value_original'].includes(x)) {
                pin_columns['active_treasury_finance_value'] = formatDataBasedOnColumn(
                  'active_treasury_finance_value',
                  active_treasury_finance_value_valTotal
                );
              } else if (['active_treasury_turn_over_original'].includes(x)) {
                pin_columns['active_treasury_turn_over'] = formatDataBasedOnColumn(
                  'active_treasury_turn_over',
                  active_treasury_turn_over_valTotal
                );
              } else if (['deals_finance_value_original'].includes(x)) {
                pin_columns['deals_finance_value'] = formatDataBasedOnColumn(
                  'deals_finance_value',
                  deals_finance_value_valTotal
                );
              } else if (['deals_turn_over_original'].includes(x)) {
                pin_columns['deals_turn_over'] = formatDataBasedOnColumn(
                  'deals_turn_over',
                  deals_turn_over_valTotal
                );
              } else if (['leads_finance_value_original'].includes(x)) {
                pin_columns['leads_finance_value'] = formatDataBasedOnColumn(
                  'leads_finance_value',
                  leads_finance_value_valTotal
                );
              } else if (['leads_turn_over'].includes(x)) {
                pin_columns['leads_turn_over'] = formatDataBasedOnColumn(
                  'leads_turn_over',
                  leads_turn_over_valTotal
                );
              } else if (['sold_finance_value_original'].includes(x)) {
                pin_columns['sold_finance_value'] = formatDataBasedOnColumn(
                  'sold_finance_value',
                  sold_finance_value_valTotal
                );
              } else if (['sold_turn_over_original'].includes(x)) {
                pin_columns['sold_turn_over'] = formatDataBasedOnColumn(
                  'sold_turn_over',
                  sold_turn_over_valTotal
                );
              } else if (['trade_finance_value_original'].includes(x)) {
                pin_columns['trade_finance_value'] = formatDataBasedOnColumn(
                  'trade_finance_value',
                  trade_finance_value_valTotal
                );
              } else if (['trade_turn_over_original'].includes(x)) {
                pin_columns['trade_turn_over'] = formatDataBasedOnColumn(
                  'trade_turn_over',
                  trade_turn_over_valTotal
                );
              } else if (['treasury_finance_value_original'].includes(x)) {
                pin_columns['treasury_finance_value'] = formatDataBasedOnColumn(
                  'treasury_finance_value',
                  treasury_finance_value_valTotal
                );
              } else if (['treasury_turn_over_original'].includes(x)) {
                pin_columns['treasury_turn_over'] = formatDataBasedOnColumn(
                  'treasury_turn_over',
                  treasury_turn_over_valTotal
                );
              }
            });
          }
          return null;
        });
      if (Object.keys(pin_columns).length > 0) {
        PinnedData.push(pin_columns);
      }
      return listCols;
    }
  };

  return props.rowData ? (
    <>
      <div className='p-reletive'>
        {timeSpentReportType.includes(report_type) ? (
          <PreviewInvestmentHourReportElement
            rowData={props.rowData}
            form={props.form}
            editFilter={props.editFilter}
          />
        ) : (
          <PreviewDataTableElement
            rowData={props.rowData}
            modules={AllModules}
            pinnedBottomRowData={
              props.rowData && props.rowData.length > 0 ? PinnedData : []
            }
            // pinnedTopRowData={
            //   historyTotal
            // }

            report_on={report_on}
            report_type={report_type}
            gridOptions={getColumnsBasedOnReportType()}
            defaultColDef={{ ...LocalGridConfig.defaultColDef }}
            columnTypes={LocalGridConfig.columnTypes}
            overlayNoRowsTemplate={vsmCommon.noRecord}
            form={props.form}
            onGridReady={REPORTSTORE.setupReportGrid}
            onColumnResized={REPORTSTORE.onChildnGridChanged}
            onColumnMoved={REPORTSTORE.onChildnGridChanged}
            onColumnPinned={REPORTSTORE.onChildnGridChanged}
            onSortChanged={REPORTSTORE.onChildnGridChanged}
          />
        )}
        {/* {props.rowData && props.rowData.length === 1000 && (
          <>
            {AUTH.user?.user_role?.user_role_id === 1 ? (
              <div className='redText noteAtTop text-right'>
                {t('Reports.Maximum_Records_Text_Admin')}
              </div>
            ) : (
              <div className='redText noteAtTop text-right'>
                {t('Reports.Maximum_Records_Text_Normal')}
              </div>
            )}
          </>
        )} */}
      </div>
    </>
  ) : null;
});
export default PreviewDataGridComponent;
