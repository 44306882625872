import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const MainOption = (props) => {
  const {t} = useTranslation();
  const handleChange = (e) => {
    props.formula.component = e.target.value;
    switch (e.target.value) {
      case 'ELSE IF':
      case 'IF':
        props.formula.child = [
          {
            block: 'If-Condition',
            component: 'Formula',
            operand1: { key: 0, component: 'Custom' },
            operand2: { key: 0, component: 'Custom' },
            operator: { key: 0, value: '=' }
          },
          {
            block: 'If-True',
            component: 'Value',
            value: { component: 'Custom', key: 0 }
          },
          {
            block: 'If-False',
            component: 'Value',
            value: { component: 'Custom', key: 0 }
          }
        ];
        break;

      case 'Value':
        props.formula.value = { key: 0, component: 'Custom' };
        break;

      default:
        props.formula.child = [];
    }

    props.setFormula(props.main);
  };

  return (
    <Radio.Group
      defaultValue={props.formula.component}
      buttonStyle='solid'
      onChange={handleChange}
      className='MainOption'
    >
      {props.formula.block === 'If-False' ? (
        <Radio.Button value='ELSE IF'>{t('DealBuilder.ELSE_IF')}</Radio.Button>
      ) : (
        <Radio.Button value='IF'>{t('DealBuilder.IF')}</Radio.Button>
      )}
      <Radio.Button value='Minimum'>{t('DealBuilder.Minimum')}</Radio.Button>
      <Radio.Button value='Maximum'>{t('DealBuilder.Maximum')}</Radio.Button>
      <Radio.Button value='Value'>{t('DealBuilder.Value')}</Radio.Button>
    </Radio.Group>
  );
};

export default MainOption;
