import React from 'react';
import { Col, Radio, Row, Tooltip } from 'antd';
import ArithmeticComponent from './ArithmeticComponent';
import TransactionComponent from './TransactionComponent';
import CustomComponent from './CustomComponent';
import {
  FontSizeOutlined,
  AppstoreOutlined,
  CalculatorOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const FormulaComponent = (props) => {
  const {t} = useTranslation();
  const handleClick = (e) => {
    props.formula.component = e.target.value;
    props.formula.value = null;
    props.setFormula(props.main);
  };

  const handleChange = (e) => {
    props.formula.value = e;
    props.setFormula(props.main);
  };
  return (
    <Row style={{ flexFlow: 'nowrap' }}>
      <Col flex='150px'>
        <Radio.Group
          value={props.formula ? props.formula.component : ''}
          buttonStyle='solid'
          onChange={handleClick}
          className='FormulaComponent'
        >
          <Tooltip title={`${t('DealBuilder.Arithmetic_Formula')}`}>
            <Radio.Button value='Arithmetic Formula'>
              <CalculatorOutlined />
            </Radio.Button>
          </Tooltip>
          <Tooltip title={`${t('DealBuilder.Transaction_Fields')}`}>
            <Radio.Button value='Transaction Fields'>
              <AppstoreOutlined />
            </Radio.Button>
          </Tooltip>
          <Tooltip title={`${t('DealBuilder.Custom')}`}>
            <Radio.Button value='Custom'>
              <FontSizeOutlined />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </Col>
      <Col style={{ width: '100%' }}>
        {props.formula && props.formula.component === 'Arithmetic Formula' && (
          <ArithmeticComponent {...props} handleChange={handleChange} />
        )}
        {props.formula && props.formula.component === 'Transaction Fields' && (
          <TransactionComponent {...props} handleChange={handleChange} />
        )}
        {props.formula && props.formula.component === 'Custom' && (
          <CustomComponent {...props} handleChange={handleChange} />
        )}
      </Col>
    </Row>
  );
};

export default FormulaComponent;
