import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../store';

const EnterFullscreen = observer(() => {
  const {
    SETTINGS: { list_fullscreen, toggleListFullscreen },
  } = useStore();
  const { t } = useTranslation();

  if (list_fullscreen) {
    return (
      <Button
        shape='round'
        size='medium'
        title={t('Common_Data.Exit_Fullscreen')}
        onClick={toggleListFullscreen}
      >
        <FontAwesomeIcon icon={faCompress} />
      </Button>
    );
  } else {
    return (
      <Button
        shape='round'
        size='medium'
        title={t('Common_Data.Enter_Fullscreen')}
        onClick={toggleListFullscreen}
      >
        <FontAwesomeIcon icon={faExpand} />
      </Button>
    );
  }
});

export default EnterFullscreen;
