import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Tooltip } from 'antd';
import GridConfig from '../../../config/GridConfig';
// import { useHistory } from 'react-router';
import { vsmCommon } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
// import { DateComparator } from '../../../utils/GlobalFunction';
// import moment from 'moment';
// import NoteRenderer from './NoteRenderer';
import RestoreComponent from '../../Waste/component/RestoreComponent';
import DeleteComponent from './DeleteComponentWaste';
// import DeleteComponent from '../../Waste/component/DeleteComponent';

const ListComponentWasteManagement = observer((props) => {
  const { t } = useTranslation();
  const { AUTH, HISTORYWASTE, WASTESTORE } = useStore();
  const { WasteManagement, } = props;
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restoreValues, setRestoreValues] = useState(false);

  useEffect(() => {
    if (WasteManagement) {
      HISTORYWASTE.isWastePage = true;
      HISTORYWASTE.search_payload = {};
    }
  }, [
    HISTORYWASTE,
    WasteManagement,
  ]);

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  // Open & Close Restore Model function
  const openRestoreModal = (data) => {   
    setRestoreValues(data);
    setRestoreModal(true);
  };

  const closeRestoreModal = () => setRestoreModal(false);

  //open delet drawer
  const openDeleteDrawer = (data) => {
    HISTORYWASTE.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        {AUTH.checkPermission(38, 'delete') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => openDeleteDrawer(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        )}
        {AUTH.checkPermission(38, 'restore') && (
          <Button
            type='text'
            title={`${t('Common_Data.Restore')}`}
            onClick={() => openRestoreModal(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Restore')} />
          </Button>
        )}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `#${t('Common_Data.ID')}`,
        field: 'id',
        tooltipField: 'id',
        filter: 'agNumberColumnFilter',
        width: 120,
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        cellClass: 'cellClass',
        field: 'organisation_name',
        tooltipField: 'organisation_name',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            HISTORYWASTE.filter_org_list &&
            HISTORYWASTE.filter_org_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            HISTORYWASTE.filterGetMappedOrg().then((data) => {
              params.success([null, ...data.map((x, indexID) => indexID)]);
            });
          },
          valueFormatter: (params) => {
            return (
              params.value &&
              HISTORYWASTE.filter_org_list &&
              HISTORYWASTE.filter_org_list[params.value]
            );
          },
        },
      },
      {
        headerName: `${t('LeadListing.Notes')} ${t('Common_Data.Description')}`,
        headerTooltip: `${t('LeadListing.Notes')} ${t('Common_Data.Description')}`,
        field: 'notes_description',
        tooltipValueGetter: (params) => {
          // return (
          //   params.data?.notes_description &&
          //   params.data?.notes_description.replace(/<(.|\n)*?>/g, '')
          // );
          return params.value && params.value
        },
        cellRenderer: (params) => {
          // return (
          //   params.data.notes_description &&
          //   params.data.notes_description.replace(/<(.|\n)*?>/g, '')
          // );
          return params.value && params.value
        },
        filter: 'agTextColumnFilter',
      },

      {
        headerName: `${t('LeadListing.Notes')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('LeadListing.Notes')} ${t('Common_Data.Date')}`,
        field: 'notes_date',
        // tooltipField: 'notes_date',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={AUTH.getLocalTime(params.data.notes_date, AUTH.global_fulldate_format)}>
              {AUTH.getLocalTime(params.data.notes_date, AUTH.global_fulldate_format)}
            </Tooltip>
          )
        },
        // cellRenderer: (params) => {
        //   return AUTH.getLocalTime(params.data.notes_date);
        // },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        field: 'created_by_name',
        tooltipField: 'created_by_name',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => formatterSetColumnFilter(HISTORYWASTE.dropdown_leads_users_list, params, 'id', 'name'),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            HISTORYWASTE.getLeadsUsersList().then((data) => { params.success([...data.data.map((x) => x.id)]); });
          },
          valueFormatter: (params) => formatterSetColumnFilter(HISTORYWASTE.dropdown_leads_users_list, params, 'id', 'name'),
        },
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        maxWidth: 100,
        filter: false,
        sortable: false,
        pinned: 'right',
        lockPosition: true,
        suppressNavigable: true,
      },
    ],
  };
  let columns = [];
  let columnConfig = localStorage.getItem('history_grid_waste');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data.forEach((element) => {
      cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
      if (cols) {
        columns.push(cols);
      }
    });
  } else {
    columns = gridOptions.columnDefs;
  }

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('History.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/dealdesk/${params.node.data.leads_transactions_id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('History.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/dealdesk/${params.node.data.leads_transactions_id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  return (
    <div
      className={`ag-theme-alpine grid_wrapper`}
      style={{
        height: 'calc(100vh - 13rem)',
        minHeight: '250px',
      }}
    >
      <AgGridReact
        rowData={HISTORYWASTE.list_data}
        modules={AllModules}
        columnDefs={columns}
        defaultColDef={{
          ...GridConfig.defaultColDef,
          width: 120,
          minWidth: 70,
          flex: 1,
          // floatingFilter: !SearchPage,
          editable: false,
        }}
        columnTypes={GridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openDeleteDrawer,
          openRestoreModal,
        }}
        onGridReady={HISTORYWASTE.setupGrid}
        gridOptions={{
          ...GridConfig.options,
          pagination: true,
          rowHeight: 30,
          getContextMenuItems: getContextMenuItems,
        }}
        onColumnResized={HISTORYWASTE.onGridChanged}
        onColumnMoved={HISTORYWASTE.onGridChanged}
        onColumnPinned={HISTORYWASTE.onGridChanged}
        onFilterChanged={HISTORYWASTE.onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
      />

      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        SearchPage={false}
        getWasteCount={WASTESTORE.getWasteCount}
      />

      {/* <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        getWasteCount={WASTESTORE.getWasteCount}
      /> */}
      <RestoreComponent
        visible={restoreModal}
        close={closeRestoreModal}
        getWasteCount={WASTESTORE.getWasteCount}
        store={HISTORYWASTE}
        id={'restorenoteform'}
        currentValues={restoreValues}
        lineText={`${t('Common_Data.History')}`}
        title={`${t('Common_Data.Restore')} ${t('Common_Data.History')} - #${restoreValues && restoreValues.id}`}
      />
    </div>
  );
});

export default ListComponentWasteManagement;
