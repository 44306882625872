import React from 'react';
import { observer } from 'mobx-react';
import TopSelection from './component/TopSelection';
import ListComponent from './component/ListComponent';
import useStore from '../../store';
import Unauthorized from '../Unauthorized';
// import TrackTimeComponent from '../../component/TrackTimeComponent';

const Productivity = observer(() => {
  const { SETTINGS, AUTH } = useStore();

  return (
    <>
      {!AUTH.checkPermission(3, 'list') ? (
        <Unauthorized />
      ) : (
        <div className={SETTINGS.fullscreen_class}>
          <TopSelection />
          <ListComponent />
          {/* <TrackTimeComponent pageName={'productivity'} /> */}
        </div>
      )}
    </>
  );
});

export default Productivity;
