import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import KeyNotes from './KeyNotes';
import KeyDocuments from './KeyDocuments';
import useStore from '../../../../store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const Key = observer(() => {
  const {t}= useTranslation();
  const [currentTab, setCurrentTab] = useState('1');
  const { TabPane } = Tabs;

  const {
    DEALDESKSTORE: { deal_data },
    DEALDESKKEYNOTES: { key_notes_data, fetchKeyNotes },
  } = useStore();

  const switchKeyTab = (value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (currentTab === '1') {
      fetchKeyNotes(deal_data?.lead_information?.id);
    }
    if (currentTab === '2') {
    }
  }, [deal_data, currentTab, fetchKeyNotes]);

  return (
    <>
      <Tabs
        defaultActiveKey={currentTab}
        centered
        className='Dealdesk_SubTab keyTab'
        onChange={switchKeyTab}
      >
        <TabPane tab={`${t('DealDesk.Key_Notes')}`} key='1'>
          <KeyNotes key_notes_data={key_notes_data} />
        </TabPane>
        <TabPane tab={`${t('DealDesk.Key_Documents')}`} key='2'>
          <KeyDocuments />
        </TabPane>
      </Tabs>
    </>
  );
});
export default Key;
