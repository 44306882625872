import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Spin } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { vsmLeadstatus } from '../../../config/messages';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(({ handleSubmit, onChange, form, id }) => {
  const { t } = useTranslation();
  const { LEADSTATUS } = useStore();
  const [fetchParentstatus, setFetchParentstatus] = useState(true);
  const [fetchRecordType, setFetchRecordType] = useState(true);

  const onFinish = (data) => {
    handleSubmit(data);
  };
  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      id={id}
      onFinish={onFinish}
      className='innerForm'
    >
      <Row gutter={15}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <InputComponent
            name='parent_status_id'
            label={`${t('Common_Data.Sold')} ${t('Common_Data.Stage')}:`}
            required
            placeholder={`${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`}
            type='select'
            tooltip={`${t('LeadStatus.Parent_Tooltip')}.`}
            options={{
              values:
                LEADSTATUS.parent_leadstatus_list ||
                (LEADSTATUS.editValues &&
                  LEADSTATUS.editValues.parent_leads && [
                    {
                      id: LEADSTATUS.editValues.parent_leads.id,
                      status_name: LEADSTATUS.editValues.parent_leads.status_name,
                    },
                  ]),
              value_key: 'id',
              text_key: 'status_name',
            }}
            onChange={onChange}
            onFocus={() =>
              fetchParentstatus &&
              LEADSTATUS.getParentLeadStatus().then(() => setFetchParentstatus(false))
            }
            notFoundContent={
              fetchParentstatus ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            rules={vsmLeadstatus.validation.parent_status_id}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <InputComponent
            name='status_name'
            label={`${t('Common_Data.Sold')} ${t('Common_Data.Status')}:`}
            required
            placeholder={`${t('Common_Data.Sold')} ${t('Common_Data.Status')}`}
            type='text'
            tooltip={`${t('LeadStatus.Status_Active_Inactive')}.`}
            onChange={onChange}
            rules={vsmLeadstatus.validation.status_name}
          />
        </Col>

      </Row>

      <Row gutter={24}>
        <Col span={24} className='autoHeight'>
          <InputComponent
            className='autoHeight'
            label={`${t('Block_Edit_Assign.SOLD_Type')}`}
            type='select'
            mode='multiple'
            name='record_type'
            onChange={onChange}
            placeholder={`${t('LeadStatus.SOLD_Type_Placeholder')}`}
            options={{
              values: LEADSTATUS.recordTypeList,
              value_key: 'record_type_name',
              text_key: 'record_type_name',
            }}
            tooltip={`${t('LeadStatus.Sold_Tooltip')}.`}
            onFocus={() =>
              fetchRecordType &&
              LEADSTATUS.getRecordType().then(() => setFetchRecordType(false))
            }
            notFoundContent={fetchRecordType ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
          />
        </Col>
      </Row>
    </Form>
  );
});

export default FormComponent;
