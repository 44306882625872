import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { Checkbox, Spin } from 'antd';
//import ViewComponent from './component/ViewComponent';
import EditViewComponent from '../../../page/Tags/component/EditViewComponent';
import { useTranslation } from 'react-i18next';

const TagNoteCompoenent = observer((props) => {
  const [viewDrawer, setViewDrawer] = useState(false);
  const [viewRowIndex, setViewRowIndex] = useState(null);
  const [prevLoader, setPrevLoader] = useState(false);
  const [nextLoader, setNextLoader] = useState(false);
  const [loadingEditView, setLoadingEditView] = useState(false);
  const { t } = useTranslation()
  const {
    AUTH,
    //HEADERREMINDERSSTORE: { list_data },
    SPANNOTESTORE: {
      is_tag_loadMore,
      tagNoteList,
      getList,
      readCommonReminder,
      tagLoading,
    },
    DEALDESKSTORE: { setStopInvestmentHourCounter },
    TagsStore,
    TimeTrackerStore,
  } = useStore();

  //View drawer
  const openViewDrawer = (data, index) => {
    if (AUTH.checkPermission(14, 'view-details')) {
      TimeTrackerStore.setStop(true);
      setLoadingEditView(true);
      TagsStore.viewValues = data;
      TagsStore.setViewValues(data).then((res) => {
        if (TagsStore.viewValues.tag_status_id === 1) {
          //If Open Then Convert To Read
          TagsStore.viewValues.tag_status_id = 2;
        }
        TagsStore.viewValues.leads_notes_description = res?.leads_notes_description;
        setLoadingEditView(false);
      });

      setViewRowIndex(index);
      if (tagNoteList.length > 0) {
        if (tagNoteList.length === index + 1) {
          setNextLoader(true);
        }
        if (tagNoteList.length > index + 1) {
          setNextLoader(false);
        }
      }
      setStopInvestmentHourCounter(true);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => {
    TimeTrackerStore.setStop(false);
    // TagsStore.viewValues = null;
    setViewDrawer(false);
    setPrevLoader(false);
    setNextLoader(false);
    setStopInvestmentHourCounter(false);
    setLoadingEditView(false);
  };

  const previousNextReminder = (type, rowIndex, isAfterMarkComplete = false) => {
    if (type === 1 && rowIndex) {
      //Previous
      setLoadingEditView(true);

      setPrevLoader(true);
      setViewRowIndex(rowIndex - 1);
      if (tagNoteList[rowIndex - 1]) {
        TagsStore.viewValues = tagNoteList[rowIndex - 1];
        setNextLoader(false);
        setPrevLoader(false);
      }

      TagsStore.setViewValues(tagNoteList[rowIndex - 1]).then((res) => {
        if (TagsStore.viewValues.tag_status_id === 1) {
          //If Open Then Convert To Read
          TagsStore.viewValues.tag_status_id = 2;
        }
        TagsStore.viewValues.leads_notes_description = res?.leads_notes_description;
        setLoadingEditView(false);
      });
    }
    if (type === 2) {
      //Next
      if (rowIndex || rowIndex === 0) {
        if (tagNoteList && rowIndex + 1 < tagNoteList.length) {
          setLoadingEditView(true);
          setNextLoader(true);
          setViewRowIndex(rowIndex + 1);

          if (tagNoteList[rowIndex + 1]) {
            TagsStore.viewValues = tagNoteList[rowIndex + 1];

            if (rowIndex + 2 === tagNoteList.length) {
              setPrevLoader(false);
              setNextLoader(true);
            } else if (rowIndex + 2 <= tagNoteList.length) {
              setPrevLoader(false);
              setNextLoader(false);
            }
          }
          TagsStore.setViewValues(tagNoteList[rowIndex + 1]).then((res) => {
            if (TagsStore.viewValues.tag_status_id === 1) {
              //If Open Then Convert To Read
              TagsStore.viewValues.tag_status_id = 2;
            }
            TagsStore.viewValues.leads_notes_description = res?.leads_notes_description;
            setLoadingEditView(false);
          });

          if (rowIndex === 0 && isAfterMarkComplete) {
            setPrevLoader(true);
          }
        }
      }
    }
  };

  return (
    <>
      <Checkbox.Group style={{ width: '100%' }}>
        <ul className='popover-menu nocheckbox fixedHeight'>
          {tagNoteList && tagNoteList?.length > 0 ? (
            tagNoteList.map((item, index) => (
              <li key={index}>
                <Checkbox
                  value={item.id}
                  onChange={(e) =>
                    readCommonReminder({ id: [e.target.value], type: item.type }, 5).then(
                      () => {
                        if (TagsStore.agGrid) {
                          TagsStore.setupGrid(TagsStore.agGrid);
                        }
                      }
                    )
                  }
                ></Checkbox>
                <div className='ant-checkbox-wrapper'>
                  <span
                    onClick={() => {
                      openViewDrawer(item, index);
                    }}
                  >
                    <strong>
                      {item.tag_subject && item.tag_subject.length > 35
                        ? item.tag_subject.substring(0, 35) + '...'
                        : item.tag_subject}
                    </strong>
                    <div
                      className='resopnsiveImg'
                      dangerouslySetInnerHTML={{
                        __html: item.tag_content
                          ? item.tag_content.replace(/<\/?a[^>]*>/g, '').length > 35
                            ? item.tag_content
                              .replace(/<\/?a[^>]*>/g, '')
                              .substring(0, 35) + '...'
                            : item.tag_content.replace(/<\/?a[^>]*>/g, '')
                          : '',
                      }}
                    ></div>

                    <i>
                      {item.tag_created_by_name && item.tag_created_by_name.length > 14
                        ? item.tag_created_by_name.substring(0, 14) + '...'
                        : item.tag_created_by_name}{' '}
                      - {AUTH.getLocalTime(item?.created_at)}
                    </i>

                    {/* <strong>{item?.tag_created_by_name}</strong>
                    <i>{item.created_at}</i>
                    <div
                      className='resopnsiveImg'
                      dangerouslySetInnerHTML={{
                        __html:
                          item.tag_content && item.tag_content.length > 35
                            ? item.tag_content.substring(0, 35) + '...'
                            : item.tag_content,
                      }}
                    ></div> */}
                  </span>
                </div>
              </li>
            ))
          ) : (
            <li className='text-center'>{t('Common_Data.No')} {t('Common_Data.Reminders')}</li>
          )}
          {is_tag_loadMore && (
            <Spin spinning={tagLoading}>
              <li className='loadMore'>
                <span onClick={() => getList(5)}>
                  {tagLoading ? `${t('Common_Data.Load')}` : `${t('TopNotificationModule.Load_More')}`}
                </span>
              </li>
            </Spin>
          )}
        </ul>
      </Checkbox.Group>
      <EditViewComponent
        visible={viewDrawer}
        close={closeViewDrawer}
        viewRowIndex={viewRowIndex}
        prevLoader={prevLoader}
        nextLoader={nextLoader}
        TotalRecords={tagNoteList ? tagNoteList?.length : 0}
        previousNextReminder={previousNextReminder}
        isFromTopNotification={true}
        loadingEditView={loadingEditView}
        fromSideBar
      />
    </>
  );
});
export default TagNoteCompoenent;
