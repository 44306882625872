import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { observer } from 'mobx-react';
import { Button, Tooltip } from 'antd';
import { vsmCommon } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const ActionRenderer = observer((props) => {
  const {t} = useTranslation()
  return (
    <div className='action-column'>
      <Tooltip placement='topRight' title={`${t('TableExample.Tooltip_Message_Delete')}`}>
        <Button type='text' size='large' style={{ padding: 8 }}>
          <EditOutlined />
        </Button>
      </Tooltip>
      <Tooltip placement='topRight' title={`${t('TableExample.Tooltip_Message_Edit')}`}>
        <Button type='text' size='large' style={{ padding: 8 }}>
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </div>
  );
});

const ListComponent = observer((props) => {
  const { TABLEEXAMPLE } = useStore();
  const {t} = useTranslation()
  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      { headerName: `${t('Common_Data.Name')}`, field: 'name' },
      { headerName: `${t('Common_Data.Description')}`, field: 'desc' },
      {
        headerName: `${t('Common_Data.Count')}`,
        field: 'count',
        filter: 'agNumberColumnFilter'
      },
      { headerName:`# ${t('Common_Data.ID')}`, field: 'id', filter: 'agNumberColumnFilter' },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        type: 'actionColumn',
        filter: false,
        sortable: false
      }
    ]
  };
  return (
    <div className='ag-theme-alpine grid_wrapper'>
      <AgGridReact
        rowData={TABLEEXAMPLE.list_data}
        modules={AllModules}
        columnDefs={gridOptions.columnDefs}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{ ActionRenderer }}
        onGridReady={TABLEEXAMPLE.setupGrid}
        gridOptions={LocalGridConfig.options}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
      />
    </div>
  );
});

export default ListComponent;
