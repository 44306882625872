import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form, Row, Col, Checkbox } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmSavedFilter } from '../../../config/messages';
import debounce from 'lodash/debounce';
import InputComponent from '../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const {
    SAVEDFILTERSTORE: { editFilter, editValues },
  } = useStore();

  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [checkboxSelection, setCheckboxSelection] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setCheckboxSelection(false);
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);

    data.global_toggle = checkboxSelection;

    editFilter(editValues.id, data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmSavedFilter.update,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (editValues && props.visible) {
      form.setFieldsValue({
        filter: editValues?.filter,
      });
      setCheckboxSelection(editValues?.global_toggle);
    }
  }, [editValues, props.visible, form, setCheckboxSelection]);

  return (
    editValues && (
      <Modal
        title={`${t('Common_Data.Edit')} ${t('Common_Data.Page')} ${t('Common_Data.Filter')} - ${editValues?.filter}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='editSavedFilterForm'
            shape='round'
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
            loading={saving}
          >
            {t('Common_Data.Update')}
          </Button>,
          <Button key='2' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={500}
      >
        <Form
          form={form}
          id={'editSavedFilterForm'}
          labelCol={{ span: 24 }}
          className='innerForm'
          onFinish={handleSubmit}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <InputComponent
                name='filter'
                label={`${t('Common_Data.Filter')} ${t('Common_Data.Name')}`}
                required
                placeholder={`${t('Common_Data.Filter')} ${t('Common_Data.Name')}`}
                type='input'
                tooltip={`${t('Common_Data.Filter')} ${t('Common_Data.Name')}.`}
                onChange={handleChange}
                autoComplete='off'
                rules={[
                  { required: true, message: `${t('Save_Filter.Group_Name_Not_Blank')}.` },
                  {
                    pattern: /^[A-Za-z0-9-. ]*$/,
                    message:
                      `${t('Save_Filter.Special_Characters')}.`,
                  },
                ]}
              />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <Checkbox
                checked={checkboxSelection}
                onChange={(e) => {
                  setCheckboxSelection(e.target.checked);
                  handleChange();
                }}
              >
                {t('Save_Filter.Mark_Global')}
              </Checkbox>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  );
});

export default EditComponent;
