import React, { useState } from 'react';
import { Form, Button, Modal, Input, Select, Col, Row } from 'antd';
import { vsmNotify, vsmTransactionFields } from '../../../../config/messages';
import useStore from '../../../../store';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import { convertError } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const {
    AUTH,
    TransactionFieldStore: { AddData, dropdown_type_list, dropdown_algorithm_list },
    DEAL: { getCurrencyList },
  } = useStore();
  const [fieldType, setFieldType] = useState();
  const [currencyList, setCurrencyList] = useState();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [fieldIsCSV, setFieldIsCSV] = useState(false);
  const [fieldIsDate, setFieldIsDate] = useState(false);

  // Handle submit and call function to save new record
  const handleSubmit = (data) => {
    setSaving(true);
    data.default_value = data?.rpg_values?.map((x) => x.default_value);
    data.rpa_group = data?.rpg_values?.map((x) => x.rpa_group);
    delete data?.rpg_values;
    AddData(data)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: vsmTransactionFields.add,
        });
        setDisabled(true);
        form.resetFields();
        form.setFieldsValue({
          field_type: 'Number',
        });
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.includes('default_value.')) {
              x.name = 'rpg_values.' + x.name.split('.')[1] + '.default_value';
            }
          });
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => setSaving(false));
  };

  // Check for valid form values then accordingly made save button disable / enable
  const handleChange = async () => {
    if (
      form.getFieldValue().field_type === 'CSV Column' ||
      form.getFieldValue().field_type === 'Date'
    ) {
      setFieldIsCSV(true);
      setFieldIsDate(true);
    } else {
      await setFieldIsCSV(false);
      await setFieldIsCSV(false);
    }
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  // Reset form and close add form
  const close = () => {
    form.resetFields();
    setFieldIsCSV(false);
    setFieldIsCSV(false);
    setDisabled(true);
    props.close();
  };

  return (
    <Modal
      centered
      title={`${t('DealBuilder.Add_Deal_Maker')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ maxWidth: '600px' }}
      width='100%'
      footer={[
        <Button
          key='1'
          disabled={disabled}
          form='addform'
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          size='medium'
        >
          {t('Common_Data.Save')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            close();
          }}
          shape='round'
          size='medium'
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id='addform'
        onFinish={handleSubmit}
        layout='vertical'
        onChange={handleChange}
        className='innerForm'
      >
        <Form.Item
          name='field_name'
          rules={vsmTransactionFields.validation.field_name}
          label={`${t('DealBuilder.Field_Name')}`}
          required
          tooltip={`${t('DealBuilder.Assigned_Transcation')}`}
        >
          <Input id='field_name' placeholder={`${t('DealBuilder.Field_Name')}`} maxLength={100} />
        </Form.Item>

        <Form.Item
          name='field_type'
          rules={vsmTransactionFields.validation.field_type}
          label={`${t('DealBuilder.Field_Type')}`}
          required
          extra={[
            <div key={1} className='text-right mr-25'>
              <label style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {t('DealBuilder.Not_Able_To_Edit')}
              </label>
            </div>,
          ]}
          tooltip={`${t('DealBuilder.Field_Type_Tooltip')}`}
        >
          <Select
            placeholder={`${t('DealBuilder.Field_Type')}`}
            onChange={(value) => {
              if (
                [
                  'Flag',
                  'Currency',
                  'Toggle (Months/Days)',
                  'Toggle (Convertibill®/Trade Credebt®)',
                ].includes(fieldType) ||
                [
                  'Flag',
                  'Currency',
                  'Toggle (Months/Days)',
                  'Toggle (Convertibill®/Trade Credebt®)',
                ].includes(value)
              ) {
                form.resetFields(['rpg_values']);
              }
              if (value === 'Currency' && !currencyList) {
                getCurrencyList().then((data) => {
                  setCurrencyList(data);
                });
              }
              setFieldType(value);
              handleChange();
            }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp='children'
            allowClear
          >
            {dropdown_type_list &&
              dropdown_type_list.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {!fieldIsCSV || !fieldIsDate ? (
          <Form.Item
            name='algorithm_id'
            rules={vsmTransactionFields.validation.algorithm_id}
            label={`${t('DealBuilder.Algorithm')}`}
            tooltip={`${t('DealBuilder.Algorithm_Transcation_Tooltip')}`}
          >
            <Select
              showSearch
              placeholder={`${t('DealBuilder.Algorithm')}`}
              allowClear
              optionFilterProp='children'
              onChange={handleChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dropdown_algorithm_list &&
                dropdown_algorithm_list.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.algorithm_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        ) : null}

        <Form.List name='rpg_values' initialValue={[null]}>
          {(fields, { add, remove }) => {
            return (
              fields &&
              fields.map((field, index) => {
                return (
                  <Row gutter={24} style={{ flexWrap: 'nowrap' }} key={index}>
                    <Col span={10}>
                      <InputComponent
                        name={[field.name, 'rpa_group']}
                        label={`${t('DealBuilder.RPA_Group')}`}
                        placeholder={`${t('DealBuilder.RPA_Group')}`}
                        type='select'
                        onChange={handleChange}
                        options={{
                          values: [
                            { value: 'a-ETR', text: `a-${t('DealBuilder.ETR')}` },
                            { value: 'b-ETR', text: `b-${t('DealBuilder.ETR')}` },
                            { value: 'c-ETR', text: `c-${t('DealBuilder.ETR')}` },
                            { value: 'd-ETR', text: `d-${t('DealBuilder.ETR')}` },
                            { value: 'f-ETR', text: `f-${t('DealBuilder.ETR')}` },
                          ],
                          rejected_keys:
                            form.getFieldValue('rpg_values') &&
                            form.getFieldValue('rpg_values').map((x) => x && x.rpa_group),
                          accepted_keys: form.getFieldValue('rpg_values') &&
                            form.getFieldValue('rpg_values')[field.name] && [
                              form.getFieldValue('rpg_values')[field.name].rpa_group,
                            ],
                        }}
                        required
                        rules={vsmTransactionFields.validation.rpa_group}
                        tooltip={`${t('DealBuilder.RPA_Tooltip')}`}
                      />
                    </Col>
                    <Col flex={1}>
                      {fieldType === 'Currency' ? (
                        <InputComponent
                          placeholder={`${t('DealBuilder.Default_Value')}`}
                          name={[field.name, 'default_value']}
                          label={`${t('DealBuilder.Default_Value')}`}
                          tooltip={`${t('DealBuilder.Default_Value_Tooltip')}`}
                          type='select'
                          options={{
                            values: currencyList,
                            value_key: 'currency_code',
                            text_key: {
                              key: ['currency_name', ' (', 'currency_code', ')'],
                            },
                          }}
                          rules={[
                            {
                              required: true,
                              message: `${t('DealBuilder.Default_Value_Not_Empty')}.`,
                            },
                          ]}
                        />
                      ) : [
                        'Flag',
                        'Toggle (Months/Days)',
                        'Toggle (Convertibill®/Trade Credebt®)',
                      ].includes(fieldType) ? (
                        <InputComponent
                          id='default_value'
                          name={[field.name, 'default_value']}
                          rules={[
                            {
                              required: true,
                              message: `${t('DealBuilder.Default_Value_Not_Empty')}.`,
                            },
                          ]}
                          type='radio_button'
                          options={{
                            values:
                              fieldType === 'Toggle (Convertibill®/Trade Credebt®)'
                                ? [
                                  { value: 'Convertibill®', text: `${t('DealBuilder.Convertibill')}®` },
                                  { value: 'Trade Credebt®', text: `${t('DealBuilder.Trade_Credebt')}®` },
                                ]
                                : fieldType === 'Toggle (Months/Days)'
                                  ? [
                                    { value: 'Months', text: `${t('Common_Data.Months')}` },
                                    { value: 'Days', text: `${t('Common_Data.Days')}` },
                                  ]
                                  : fieldType === 'Flag'
                                    ? [
                                      { value: '0', text: `${t('Common_Data.No')}` },
                                      { value: '1', text: `${t('Common_Data.Yes')}` },
                                    ]
                                    : null,
                          }}
                          label={`${t('DealBuilder.Default_Value')}`}
                          tooltip={`${t('DealBuilder.Default_Value_Tooltip')}`}
                        />
                      ) : (
                        <InputComponent
                          id='default_value'
                          placeholder={`${t('DealBuilder.Default_Value')}`}
                          maxLength={100}
                          name={[field.name, 'default_value']}
                          rules={vsmTransactionFields.validation.default_value}
                          label={`${t('DealBuilder.Default_Value')}`}
                          extra={[
                            <div key={1}>
                              <span>
                                <b>{t('Common_Data.Note')}:</b> {t('DealBuilder.Changing_Algorithm')}.
                              </span>
                            </div>,
                          ]}
                          tooltip={`${t('DealBuilder.Default_Value_Tooltip')}`}
                        />
                      )}
                    </Col>
                    <Col className='d-flex align-items-center'>
                      {fields.length - 1 === index && index < 4 && (
                        <img
                          src={AUTH.GetThemedImage('add')}
                          alt={`${t('Common_Data.Add')}`}
                          className='icon circle cursorPointer mr-5'
                          onClick={async () => {
                            await add();
                            handleChange();
                          }}
                        />
                      )}

                      {fields.length > 1 && (
                        <img
                          src={AUTH.GetThemedImage('remove')}
                          alt={`${t('Common_Data.Remove')}`}
                          className='icon circle cursorPointer'
                          onClick={async () => {
                            await remove(field.name);
                            handleChange();
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })
            );
          }}
        </Form.List>

        <InputComponent
          required
          type='textarea'
          label={`${t('DealBuilder.Description')}`}
          name='description'
          placeholder={`${t('DealBuilder.Description')}`}
          onChange={handleChange}
          rowSize={3}
          rules={vsmTransactionFields.validation.description}
          maxLength={1000}
          tooltip={`${t('DealBuilder.Description_Tooltip')}`}
        />
      </Form>
    </Modal>
  );
});

export default AddComponent;
