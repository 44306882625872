import React from 'react';
import { Row, Col, Button, Form } from 'antd';
import { observer } from 'mobx-react';
import FormComponent from '../components/FormComponent';
import { useTranslation } from 'react-i18next';

const Sum = observer(({ changeTab }) => {
  const {t} = useTranslation();
  const [form] = Form.useForm()

  const handleSubmit = () => {
    changeTab('4');
  }

  return (
    <Form form={form} id='DealSum' className='innerForm DealDeskDeal' layout='vertical' onFinish={handleSubmit}>
      <FormComponent disabled={true} changeTab={changeTab} form={form} is_issued={false} is_sum={true} />

      <Row gutter={0} className='mt-10'>
        <Col span={24} className='text-right border-top pt-15'>
          <Button shape='round' size='small' htmlType="submit">
           {t('Common_Data.Next')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default Sum;
