import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Checkbox } from 'antd';
import FormComponent from './FormComponent';
import { vsmDealDesk, vsmNotify } from '../../../../../config/messages';
import useStore from '../../../../../store';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import StructureLibrary from './StructureLibrary';
import {
  calculateHeight,
  trimExtraCharacters,
} from '../../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [is_template, setIsTemplate] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const INITIAL_HEIGHT = '18px';
  const [form] = Form.useForm();
  const { saveType = 'structure', viewFromLeads } = props;
  const {
    SSSLEADLIBRARYSTORE: { addSssData, fetchLibraryByType },
    AUTH,
    DEALDESKSTRUCTURELIBRARY: {
      Structure,
      calculateHeightOnEdit,
      editValues,
      setEditValues,
      SetStructure,
      SetScrunitise,
      Scrunitise,
      Screen,
      SetScreen,
      Shortfall,
      SetShortfall,
      //saveStructureData,
      setCopiedStructureData,
      setSavestructureform,
      //fetchStructureLibrary,
    },
    LEADSSTORE,
  } = useStore();
  const [isDisabled, setDisabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const close = () => {
    setEditValues(null);
    form.resetFields();
    setDisabled(false);
    SetStructure([]);
    SetScreen([]);
    SetShortfall([]);
    SetScrunitise([]);
    setCopiedStructureData(null);
    setSavestructureform(null);
    props.close();
  };

  const checkSaveType = () => {
    if (saveType === 'screen') {
      return Screen;
    }
    if (saveType === 'structure') {
      return Structure;
    }
    if (saveType === 'scrutinise') {
      return Scrunitise;
    }
    if (saveType === 'shortfall') {
      return Shortfall;
    }
  }

  const getTitleBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    } else if (saveType === 'shortfall') {
      return `${t('Common_Data.Shortfall')}`;
    }
  }

  const getSaveIDBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return 3;
    }
    else if (saveType === 'structure') {
      return 1;
    }
    else if (saveType === 'scrutinise') {
      return 4;
    }
    else if (saveType === 'shortfall') {
      return 5;
    }
  }

  const handleSubmit = (data) => {
    setSaving(true);

    if (!checkSaveType()) {
      vsmNotify.error({
        message: `${getTitleBasedOnSaveType()} ${t('DealDesk.Required')}!`,
      });
      return false;
    }
    let datanew = {
      ...data,
      description: checkSaveType(),
      date: moment(new Date()).format('YYYY-MM-DD'),
    };
    datanew.type = getSaveIDBasedOnSaveType();
    datanew.is_library = 1;
    datanew.autosave = 0;
    datanew.is_template = is_template;
    //    datanew.leads_transactions_id = saveType === 'shortfall' ? editValues?.leads_transactions_id : null;
    datanew.leads_transactions_id = editValues?.leads_transactions_id;
    datanew.id = editValues?.id;
    datanew.shortfall_type = editValues?.shortfall_type ? editValues?.shortfall_type : 'other';

    //  To make new id/clone it only when it is changed to template 
    if (!editValues?.is_template && is_template) {
      datanew.id = null;
    } else {
      datanew.id = editValues?.id;
    }

    let leadId = editValues?.leads_transactions_id ? editValues?.leads_transactions_id : 0;

    addSssData(leadId, datanew)
      .then((data) => {
        fetchLibraryByType(saveType)
        //fetchStructureLibrary({ type: saveType });
        close();
        viewFromLeads && LEADSSTORE.setViewValues(viewFromLeads)
        vsmNotify.success({
          message:
            saveType === 'screen'
              ? vsmDealDesk.screen.updateSuccess
              : saveType === 'structure'
                ? vsmDealDesk.structure.updateSuccess
                : saveType === 'shortfall'
                  ? vsmDealDesk.shortfall.updateSuccess
                  : vsmDealDesk.scrutinise.updateSuccess,
        });
        setIsSaveModal(false);
      })
      .catch((e) => {
        if (e.errors) {
          let structure_description_error = 0;

          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (e === 'description') {
                  structure_description_error = 1;
                }
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });

          if (structure_description_error) {
            vsmNotify.error({
              message: `${getTitleBasedOnSaveType()} ${t('Common_Data.Description')} ${t('DealDesk.Required')}!`,
            });
          } else {
            form.setFields(e.errors);
          }
        }
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (props.visible && editValues) {
      let structure = cloneDeep(editValues?.description);
      form.setFieldsValue({
        title: editValues?.title,
        classification_id: editValues?.classification_id,
        description_text: editValues?.description_text,
      });

      if (structure) {
        let lengthLimit = 170;
        if (AUTH?.user?.bonus_figures) {
          if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
            lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
          }
        }

        calculateHeightOnEdit(structure).then(
          debounce(() => {
            structure &&
              structure.forEach((obj, index) => {
                obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

                obj.height = calculateHeight(
                  `${saveType}_innerContent_textarea_${obj.item_key}`,
                  INITIAL_HEIGHT,
                  obj.detail,
                  true
                );

                if (obj.children && obj.children.length > 0) {
                  obj.children.forEach((obj1, index) => {
                    obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                    obj1.height = calculateHeight(
                      `${saveType}_innerContent_textarea_${obj1.item_key}`,
                      INITIAL_HEIGHT,
                      obj1.detail,
                      true
                    );
                    if (obj1.children && obj1.children.length > 0) {
                      obj1.children.forEach((obj2, index) => {
                        obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                        obj2.height = calculateHeight(
                          `${saveType}_innerContent_textarea_${obj2.item_key}`,
                          INITIAL_HEIGHT,
                          obj2.detail,
                          true
                        );
                      });
                    }
                  });
                }
              });
          }, 200)
        );
      }
      if (saveType && saveType === 'screen') {
        SetScreen(structure ? structure : []);
      } else if (saveType === 'structure') {
        SetStructure(structure ? structure : []);
      } else if (saveType === 'scrutinise') {
        SetScrunitise(structure ? structure : []);
      } else if (saveType === 'shortfall') {
        SetShortfall(structure ? structure : []);
      }
      setIsTemplate(editValues?.is_template);

      //----------If created from Dealdesk ---------------//
      // if (!editValues?.is_template) {
      //   setDisabled(true);
      // }
    }
  }, [
    editValues,
    AUTH?.user?.bonus_figures,
    calculateHeightOnEdit,
    props.visible,
    saveType,
    form,
    SetScreen,
    SetShortfall,
    SetScrunitise,
    SetStructure,
    setIsTemplate,
  ]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        if (
          (checkSaveType().length > 0) &&
          form.getFieldValue('title') !== ''
        ) {
          // if (!editValues?.is_template && !is_template) {
          //   setDisabled(true);
          // } else {
          //   setDisabled(false);
          // }
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  //-----------START: LIBRARY COPY---------------//
  const openViewDrawer = (data) => {
    setViewDrawer(true);
  };
  const closeViewDrawer = () => setViewDrawer(false);
  //-----------END: LIBRARY COPY---------------//

  return editValues ? (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={viewDrawer ? '1200px' : '1050px'}
      title={`${t('Common_Data.Edit')} ${getTitleBasedOnSaveType()} - #${editValues?.id
        } - ${editValues?.title} `}
      destroyOnClose={true}
      footer={[
        <div className='DealLibrary-ScheduleFormFooter'>
          <div className='mr-10'>
            <Checkbox
              checked={is_template}
              onChange={(e) => {
                // if (!editValues?.is_template) {
                //   if (e.target.checked) {
                //     setDisabled(false);
                //   } else {
                //     setDisabled(true);
                //   }
                // }
                setIsTemplate(e.target.checked);
              }}
            // disabled={editValues?.is_template}
            >
              {t('DealDesk.Save_Template')}
            </Checkbox>
            {/* <label className='mr-10'>Save as Template</label> */}
          </div>
          <div>
            <Button
              key='1'
              // form={`editfrom${saveType}`}
              className='mr-10'
              // loading={saving}
              // htmlType='submit'
              type='primary'
              disabled={isDisabled}
              shape='round'
              size='medium'
              onClick={() => setIsSaveModal(true)}
            >
              {t('Common_Data.Save')}
            </Button>
            <Button shape='round' className='mr-10' size='medium' onClick={close} key='2'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>
        </div>,
      ]}
    >
      <FormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        id={`editfrom${saveType}`}
        saveType={saveType}
        openViewDrawer={openViewDrawer}
        isSaveModal={isSaveModal}
        setIsSaveModal={setIsSaveModal}
        loading={saving}
        isTemplate={is_template}
        setIsTemplate={setIsTemplate}
      />
      {viewDrawer && (
        <StructureLibrary
          visible={viewDrawer}
          close={closeViewDrawer}
          saveType={saveType}
        />
      )}
    </Drawer>
  ) : null;
});

export default EditComponent;
