import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import KanbanView from './component/KanbanView';
import TopSelection from './component/TopSelection';
import { Button, Spin } from 'antd';
import useStore from '../../store';
import { observer } from 'mobx-react';

import { vsmNotify, vsmLeadsList } from '../../config/messages';
import { useTranslation } from 'react-i18next';

const LeadKanbanView = observer(() => {
  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }
  const List_view = require(`../../assets/images/${themecolor}/List_view.svg`).default;
  const {t} = useTranslation();
  const {
    SETTINGS,
    LEADSSTORE,
    LEADSSTORE: {
      kanban_status_list,
      kanban_list_data,
      loadkanban,
      getKanbanStatusList,
      updateLeadStatus,
      setLoadkanban,
    },
  } = useStore();
  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    LEADSSTORE.current_list_path = location.pathname;

    if (location.pathname === '/leads/deals/kanban') {
      getKanbanStatusList('Deal');
    }
    if (location.pathname === '/leads/opportunities/kanban') {
      getKanbanStatusList('Opportunity');
    }
    if (location.pathname === '/leads/active/kanban') {
      getKanbanStatusList('Lead');
    }

    return function cleanup() {
      setLoadkanban(true);
    };
  }, [LEADSSTORE, location.pathname, getKanbanStatusList, setLoadkanban]);

  const onDragStop = (params) => {
    let payload = {
      lead_status_id: parseInt(params.newstatus),
    };
    updateLeadStatus(parseInt(params.id), payload)
      .then(() => {
        vsmNotify.success({
          message: vsmLeadsList.successStatusUpdate,
        });
      })
      .catch((e) => { })
      .finally(() => { });
  };

  return (
    <div className={SETTINGS.fullscreen_class}>
      <div className='KanbanView'>
        <TopSelection />
        <div style={{ width: '500px', minWidth: '100%' }}>
          {kanban_status_list || kanban_list_data ? (
            <KanbanView
              onDragStop={onDragStop}
              fieldKey={'LeadStatusID'}
              directives={kanban_status_list}
              datasource={kanban_list_data}
              loadkanban={loadkanban}
              setLoadkanban={setLoadkanban}
            />
          ) : (
            <Spin size='large' tip={`${t('Common_Data.Load')}`}></Spin>
          )}
        </div>
        <Button
          className='KanbanViewBtn'
          key='2'
          shape='round'
          size='medium'
          onClick={history.goBack}
          icon={<img className='menuicon' alt='' src={List_view} />}
        />
      </div>
    </div>
  );
});

export default LeadKanbanView;
