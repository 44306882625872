import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { ActionRenderer } from './GridComponent';
import { vsmCommon, vsmNotify } from '../../../config/messages';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { useTranslation } from 'react-i18next';

const ListClientSideComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    TicketsStore,
    DEALDESKSTORE: { deal_data },
    RIGHTSECTTION_TAGSTICKETS: { updateTicketSequence, fetchTagsTicketData },
  } = useStore();
  const {
    openDeleteDrawer,
    openEditDrawer,
    openCloneDrawer,
    openViewDrawer,
    TagTicketModule,
    WasteManagement,
    toggleDealTicket,
  } = props;

  useEffect(() => {
    TicketsStore.getList({
      leads_transactions_id: deal_data?.lead_information?.id,
      organisation_id: deal_data?.lead_information?.sold_organisation?.id,
    });
  }, [TicketsStore, deal_data]);

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Creator')}`,
        field: 'owner',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Created')}`,
        field: 'created_at',
        cellClass: 'cellClass',

        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params.data.created_at, AUTH.global_dateformat);
        },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')}`,
        field: 'master_ticket.ticket_subject',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Subject')}`,
        field: 'ticket_subject',
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`,
        field: 'ticket_number',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation.organisation_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        field: 'ticket_description',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },

      {
        headerName: `${t('Tickets.Held')}`,
        field: 'ticketholder',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TicketsStore.getUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params.data.deadline_date, AUTH.global_dateformat);
        },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        field: 'ticket_status.status_name',
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        field: 'updated_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params.data.updated_at, AUTH.global_dateformat);
        },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `# ${t('Common_Data.ID')}`,
        field: 'id',
        cellClass: 'cellClass',

        width: 120,
        filter: 'agTextColumnFilter',
        sortable: true,
        rowDrag: TagTicketModule,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        //,
        width: !TagTicketModule ? (WasteManagement === true ? 110 : 200) : 110,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: TagTicketModule ? '70vh' : 'calc(100vh - 11rem)',
        }}
      >
        <AgGridReact
          rowData={TicketsStore.list_data}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: true,
            width: 120,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openDeleteDrawer,
            openEditDrawer,
            openCloneDrawer,
            openViewDrawer,
            TagTicketModule,
            WasteManagement,
            toggleDealTicket,
          }}
          onGridReady={TicketsStore.setupGrid}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: !TagTicketModule,
            animateRows: true,
            onRowDragEnd: (event) => {
              let payload = {
                ticket_sequences: [
                  {
                    ticket_id: event.node.data.id,
                    sequence_no: event.overIndex === 0 ? 1 : event.overIndex,
                  },
                ],
              };
              updateTicketSequence(deal_data?.lead_information?.id, payload)
                .then(() => {
                  vsmNotify.success({
                    message: `${t('Common_Data.Ticket')} ${t('Tickets.Sequence_Updated')}.`,
                  });
                  fetchTagsTicketData(deal_data?.lead_information?.id);
                })
                .catch((e) => { })
                .finally(() => { });
            },
            onRowDoubleClicked: (event) => openViewDrawer(event?.data),
          }}
          rowDragManaged={true}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
        />
      </div>
    </>
  );
});
export default ListClientSideComponent;
