import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmLeadstatus } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { LEADSTATUS } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const close = () => {
    props.close();
    LEADSTATUS.editValues = null;
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    let tempValue = data.record_type ? data.record_type.join(',') : null;
    data.record_type = tempValue && tempValue.toString();

    data.id = LEADSTATUS.editValues.id;
    LEADSTATUS.EditData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmLeadstatus.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (LEADSTATUS.editValues) {
      form.setFieldsValue({
        status_name: LEADSTATUS.editValues.status_name,
        parent_status_id: LEADSTATUS.editValues.parent_status_id,
        record_type: LEADSTATUS.editValues.record_type
          ? LEADSTATUS.editValues.record_type
          : [],
      });
    }
  }, [LEADSTATUS.editValues, form]);

  return (
    LEADSTATUS.editValues && (
      <Modal
        //centered
        title={`${t('Common_Data.Edit_SOLD_Stage')} - #${LEADSTATUS.editValues?.id}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='editform'
            shape='round'
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
            loading={saving}
          >
            {t('Common_Data.Update')}
          </Button>,
          <Button key='2' form='addform' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={500}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
        />
      </Modal>
    )
  );
});

export default EditComponent;
