import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
// import LocalGridConfig from '../../config/LocalGridConfig';
import {
  ageDifferenceInDays, defaultMyOpenTickets,
  // defaultMyOpenTickets,
  //  convertTextToID
} from '../../utils/GlobalFunction';
import { clear, reset } from '../../utils/ResetClearButton';

export default class TicketsStore {
  list_data = [];
  master_list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  // per_page_master = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  dropdown_master_ticket = null;
  dropdown_user_list = null;
  dropdown_ticketstatus = null;

  filter_dropdown_master_ticket = null;
  filter_organisation_list = null;
  gridLoading = null;
  isMergePeople = false;

  dropdown_ticket_type = [
    { value: 5, text: 'Complaints' },
    { value: 3, text: 'Customer' },
    { value: 2, text: 'Productivity' },
    { value: 1, text: 'Risk' },
    { value: 6, text: 'Routine' },
    { value: 4, text: 'Sales' },
  ];
  // dropdown_priority = [
  //   { value: 5, text: 'Agenda' },
  //   { value: 1, text: 'Critical' },
  //   { value: 4, text: 'Routine' },
  //   { value: 3, text: 'Timed' },
  //   { value: 2, text: 'Urgent' },
  // ];
  // dropdown_priority = [
  //   { value: 1, text: 'Critical' },
  //   { value: 2, text: 'Urgent' },
  //   { value: 3, text: 'Important' },
  //   { value: 4, text: 'Timed' },
  //   { value: 5, text: 'Routine' },
  //   { value: 2, text: 'Agenda' },
  //   { value: 1, text: 'Hold' },
  // ]
  dropdown_priority = null
  search_payload = {};
  isSearchPage = false;

  deleteValues = null;
  viewValues = null;
  editValues = null;
  cloneValues = null;
  waste = false;
  exportLoading = false;
  dropdown_leads_users_list = null;
  reminderdatepicker_extrafooter = null;
  is_data_modified = false;
  priority_name = null;
  tickettype_name = null;
  filter_org_list = [];
  apicalled = false; // Flag for API called or not

  // change page size, default page size is GridConfig.options.paginationPageSize

  // selectedGridFilter = localStorage.getItem('savedFilters')
  //   ? JSON.parse(localStorage.getItem('savedFilters'))?.TicketsStore
  //     ? JSON.parse(localStorage.getItem('savedFilters'))?.TicketsStore
  //     : null
  //   : 99998; //Used For Saved Grid Filters To Apply - default my open tickets
  DEFAULT_GRID_FILTER_ID = 99998;
  selectedGridFilter = this.DEFAULT_GRID_FILTER_ID; //Used For Saved Grid Filters To Apply - default my open tickets  

  masterTicketToMerge = null;
  isSavedFiltersLoadedTicket = false;
  isChildList = null; // to check is this child list or not
  isMasterTicketNumber = null; // to check is this master ticket or not


  setChildTicketList = (data) => {
    this.isChildList = data;
  };

  setMasterTicketNumber = (data) => {
    this.isMasterTicketNumber = data;
  }

  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // setPageSizeMaster = (page = LocalGridConfig.options.paginationPageSize) => {
  //   this.per_page = page;
  //   this.agGrid.api.paginationSetPageSize(parseInt(page));
  // };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setisDataModified = (data = false) => {
    this.is_data_modified = data;
  };

  setpriority = (data) => {
    this.priority_name = data;
  };

  setTicketType = (data) => {
    this.tickettype_name = data;
  };

  //set delete values
  setEditValues = (data) => {
    if (data === null) {
      this.editValues = null
    } else {
      return Axios.post(`leadstickets/read/${data.id}`, { waste: this.waste })
        .then(({ data }) => {
          if (data.data) {
            data.data.age = data.data.created_at
              ? ageDifferenceInDays(new Date(data.data.created_at))
              : 1;
            data.data.idle = data.data.updated_at
              ? ageDifferenceInDays(new Date(data.data.updated_at))
              : 1;
          }
          this.editValues = data && data.data;
          return true;
        })
        .catch(({ response: { data } }) => {
          return Promise.reject(data);
        });
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('ticket_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    let param = this.getFilter(params);
    params.api.setFilterModel(param?.request?.filter);
    params.api.setSortModel(param?.request?.sort);
    var datasource = this.createDatasource(GridConfig.options);


    if (this.isSavedFiltersLoadedTicket) {
      api.setServerSideDatasource(datasource);
    } else {
      //--------------TO HOLD GRID SETUP - TILL SAVED FILTER LOADED-------------//      
      if (localStorage.getItem('isSavedFiltersFetching')) {
        setTimeout(() => {
          api.setServerSideDatasource(datasource);
        }, 2000);
      }
      //--------------TO HOLD GRID SETUP - TILL SAVED FILTER LOADED-------------//
    }
  };

  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_ticket_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  setupGridSearchMaster = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_master_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem('ticket_grid', JSON.stringify(params.columnApi.getColumnState()));
  };

  onGridChangedSearch = (params) => {
    localStorage.setItem('search_ticket_grid', JSON.stringify(params.columnApi.getColumnState()));
  };
  onGridChangedSearchMaster = (params) => {
    localStorage.setItem('search_master_grid', JSON.stringify(params.columnApi.getColumnState()));
  };

  // reset all the server side filters
  // Reset : Will reset all filters, sorting, saved columns settings.
  // handleReset = () => {
  //   localStorage.removeItem('ticket_grid');
  //   this.agGrid.api.setFilterModel({});
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.api.onFilterChanged(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
  // };
  handleReset = () => {
    reset(this.agGrid, 'ticket_grid', this.setSelectedGridFilter, this.DEFAULT_GRID_FILTER_ID, defaultMyOpenTickets)
  }

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  // saveParams = JSON.parse(localStorage.getItem('params'))
  // onLoadFliter = this.saveParams?.TicketsStore?.filter;
  // applyFilterDefault = () => {
  //   this.agGrid.api.setSortModel(null);
  //   if (this.onLoadFliter) {
  //     this.agGrid.api.setFilterModel(this.onLoadFliter);
  //   }
  // };
  applyFilterDefault = () => {
    clear(this.agGrid, defaultMyOpenTickets)
  };


  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.TicketsStore) {
        params = { request: temp.TicketsStore };
      }
    }
    return params;
  };
  setIsSearchPage = (val) => {
    this.isSearchPage = val
  }
  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem('params', JSON.stringify({ ...temp, TicketsStore: { filter: param.getFilterModel(), sort: param.getSortModel() }, }));
    } else {
      localStorage.setItem('params',
        JSON.stringify({ TicketsStore: { filter: param.getFilterModel(), sort: param.getSortModel() }, })
      );
    }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('ticket_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        let masterTicketNumber = localStorage.getItem("get_master_ticket");
        let filterMasterTicketNumber = null;
        if (masterTicketNumber) {

          // reset(this.agGrid, 'ticket_grid', this.setSelectedGridFilter, this.DEFAULT_GRID_FILTER_ID)
          filterMasterTicketNumber = {
            ticket_number: {
              filterType: "text",
              type: 'contains',
              filter: masterTicketNumber,
            },
          }
        }

        var payload = {
          waste: this.waste,
          filter_data: filterMasterTicketNumber || (this.search_payload?.hasOwnProperty('search')
            ? {}
            : params.request.filterModel),
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        if (this.selectedGridFilter && this.selectedGridFilter === 99997) {
          //Recently Updated Grid
          payload.recently_viewed = true;
        }
        // if (
        //   !payload.filter_data['ticket_status.status_name'] &&
        //   this.search_payload?.hasOwnProperty('search') === false
        // ) {
        //   let status = [null, 5, 2, 9, 8, 12, 14, 11, 10, 13, 4, 1, 7, 3];
        //   payload.filter_data['ticket_status.status_name'] = {
        //     values: status,
        //     filterType: 'set',
        //   };
        // }


        // let merged = { ...payload, ...this.search_payload };
        let merged = { ...payload };


        if (this.isChildList) {
          let get_child_tickets = this.isChildList;
          merged = { ...merged, filter_data: {}, get_child_tickets }
        }

        this.setFilter(params.api);
        if (this.isSavedFiltersLoadedTicket) {
          this.getList(merged).then((data) => {
            if (data.total === 0) {
              this.agGrid.api.showNoRowsOverlay();
            } else {
              this.agGrid.api.hideOverlay();
            }
            params.successCallback(data.data, data.total);
            var allColumnIds = [];
            let columnConfig = localStorage.getItem('ticket_grid');
            if (this.agGrid && this.agGrid.columnApi && columnConfig) {
              if (
                JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig
              ) {
                this.agGrid.columnApi.applyColumnState({
                  state: JSON.parse(columnConfig),
                });
              }
            } else {
              if (this.agGrid && this.agGrid.columnApi && data.total) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                  if (!['actions'].includes(column.colId)) {
                    allColumnIds.push(column.colId);
                  }
                });
                this.agGrid.columnApi.autoSizeColumns(allColumnIds);
              }
            }
            let fetch_child_ticket = JSON.parse(
              localStorage.getItem('fetch_child_ticket')
            );
            if (fetch_child_ticket) {
              localStorage.removeItem('fetch_child_ticket');
            }
            let fetch_master_ticket = localStorage.getItem('get_master_ticket');
            if (fetch_master_ticket) {
              localStorage.removeItem("get_master_ticket");
            }
          });
        }
      },
    };
  };

  getList = (payload = {}) => {
    return Axios.post(`leadstickets/list`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.owner = null;
          item.ticketholder = null;
          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at
            ? ageDifferenceInDays(new Date(item.updated_at))
            : 1;
          // item.updated_at = moment(item.updated_at).format("YYYY-MM-DD")
          if (item.ticket_owner) {
            item.owner =
              (item.ticket_owner.first_name && item.ticket_owner.first_name) +
              ' ' +
              (item.ticket_owner.first_name && item.ticket_owner.last_name);
          }

          if (item.ticket_holder) {
            item.ticketholder =
              (item.ticket_holder.first_name && item.ticket_holder.first_name) +
              ' ' +
              (item.ticket_holder.first_name && item.ticket_holder.last_name);
          }
          item.master_ticket_number = item?.master_ticket?.master_ticket_number;
        });
      }
      if (this.isChildList) {
        localStorage.removeItem('get_child_tickets')
        // this.isChildList = null
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  getListSearch = (payload = {}) => {
    this.gridLoading = true;
    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.TicketSearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post(`leadstickets/list`, payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.owner = null;
          item.ticketholder = null;
          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at ? ageDifferenceInDays(new Date(item.updated_at)) : 1;
          // item.updated_at = moment(item.updated_at).format("YYYY-MM-DD")
          if (item.ticket_owner) {
            item.owner =
              (item.ticket_owner.first_name && item.ticket_owner.first_name) +
              ' ' +
              (item.ticket_owner.first_name && item.ticket_owner.last_name);
          }

          if (item.ticket_holder) {
            item.ticketholder =
              (item.ticket_holder.first_name && item.ticket_holder.first_name) +
              ' ' +
              (item.ticket_holder.first_name && item.ticket_holder.last_name);
          }
          item.master_ticket_number = item?.master_ticket?.master_ticket_number;
        });
      }
      if (this.isChildList) {
        localStorage.removeItem('get_child_tickets')
        // this.isChildList = null
      }

      this.list_data = data.data;
      if (this.list_data?.length === 0) {
        this.agGrid.api.showNoRowsOverlay();
      }
      this.total = data.total;
      this.current_page = data.current_page;
      // let filter = null;

      this.agGrid.api.setSortModel(sort ? sort : temp?.TicketSearchStore?.sort);
      // if (this.agGrid) {
      //   filter = {
      //     "ticket_status.status_name": {
      //       filterType: "set", values: [
      //         // null,
      //         'Completed',
      //         'Escalated',
      //         'In Progress',
      //         'IT Active',
      //         'IT Assigned',
      //         'IT Feedback',
      //         'IT In QA',
      //         'IT Paused',
      //         'IT Planning',
      //         'IT QA Ready',
      //         'On Hold',
      //         'Open',
      //         'Project System',
      //         'Waiting for Response',
      //       ],
      //     }
      //   }
      //   this.agGrid.api.setFilterModel(filter);
      //   // this.agGrid.api.setSortModel(sort);
      // }
      return data;
    });
  };

  getMasterListSearch = (payload = {}) => {
    this.gridLoading = true;
    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.MasterTicketSearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post(`leadstickets/seacrh-master-list`, payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.owner = null;
          item.ticketholder = null;
          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at ? ageDifferenceInDays(new Date(item.updated_at)) : 1;
          // item.updated_at = moment(item.updated_at).format("YYYY-MM-DD")
          if (item.ticket_owner) {
            item.owner =
              (item.ticket_owner.first_name && item.ticket_owner.first_name) +
              ' ' +
              (item.ticket_owner.first_name && item.ticket_owner.last_name);
          }

          if (item.ticket_holder) {
            item.ticketholder =
              (item.ticket_holder.first_name && item.ticket_holder.first_name) +
              ' ' +
              (item.ticket_holder.first_name && item.ticket_holder.last_name);
          }
          item.master_ticket_number = item?.master_ticket?.master_ticket_number;
        });
      }
      if (this.isChildList) {
        localStorage.removeItem('get_child_tickets')
        // this.isChildList = null
      }
      this.master_list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      this.agGrid.api.setSortModel(sort ? sort : temp?.MasterTicketSearchStore?.sort);

      if (this.agGrid) {
        this.agGrid.api.setFilterModel({
          "ticket_status.status_name": {
            filterType: "set", values: [
              // null,
              'Completed',
              'Escalated',
              'In Progress',
              'IT Active',
              'IT Assigned',
              'IT Feedback',
              'IT In QA',
              'IT Paused',
              'IT Planning',
              'IT QA Ready',
              'On Hold',
              'Open',
              'Project System',
              'Waiting for Response',
            ],
          }
        });
        // this.agGrid.api.setSortModel(sort);
      }
      // if (this.agGrid) {
      // 	this.agGrid.api.setFilterModel(filter);
      // 	this.agGrid.api.setSortModel(sort);
      // }
      if (this.master_list_data?.length === 0) {
        this.agGrid.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  getMasterTicketList = (payload = {}) => {
    return Axios.get(`leadstickets/drop-down-list`).then(({ data }) => {
      this.dropdown_master_ticket = data.data;

      return data.data;
    });
  };

  // Filter function for no record found message
  onSearchFilterChanged = (params) => {
    this.agGrid = params;
    params && this.setFilter(params.api);
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  filterMasterTicketList = (payload = {}) => {
    return Axios.get(`leadstickets/drop-down-list`).then(({ data }) => {
      let newData = [];
      data.data &&
        data.data.length > 0 &&
        data.data.forEach((x) => {
          newData[x.id] = x.ticket_subject;
        });
      this.filter_dropdown_master_ticket = newData;

      return newData;
    });
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_user_list = data.data;
      return data;
    });
  };

  getLeadsUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`).then(({ data }) => {
      this.dropdown_leads_users_list = data.data;

      return data;
    });
  };

  getTicketstatusList = (payload = {}) => {
    return Axios.get(`leadstickets/ticket/status/get`).then(({ data }) => {
      this.dropdown_ticketstatus = data && data.data;
      return data && data.data;
    });
  };

  addTicket = (formdata) => {
    return Axios.post(`leadstickets/add`, formdata)
      .then(({ data }) => {
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setViewValues = (data) => {
    this.apicalled = true; // Flag for API called or not

    return Axios.post(`leadstickets/read/${data.id}`, { waste: this.waste })
      .then(({ data }) => {
        if (data.data) {
          data.data.age = data.data.created_at
            ? ageDifferenceInDays(new Date(data.data.created_at))
            : 1;
          data.data.idle = data.data.updated_at
            ? ageDifferenceInDays(new Date(data.data.updated_at))
            : 1;
        }
        this.viewValues = data.data;
        this.apicalled = false; // Flag for API called or not

        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  deleteTicketData = (formdata) => {
    return Axios.post(`leadstickets/delete/${formdata.id}`, { waste: this.waste })
      .then(({ data }) => {
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  editTicket = (id, formdata) => {
    return Axios.post(`leadstickets/edit/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //set delete values
  setClonevalues = (data) => {
    return Axios.post(`leadstickets/read/${data.id}`, { waste: this.waste })
      .then(({ data }) => {
        if (data && data.data) {
          data.data.ticket_number =
            data.data?.master_ticket_prefix + data.data?.master_ticket_number;
        }
        this.cloneValues = data && data.data;
        return true;
      })
      .catch(({ response: { data } }) => {
        this.cloneValues = null;
        return Promise.reject(data);
      });
  };
  assignTicket = (formdata) => {
    return Axios.post(`leadstickets/update-tickets-tags-users`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  RestoreData = (ticket_id) => {
    return Axios.get(`leads-tickets/restore/${ticket_id}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // call api to get all parent organisations list
  getMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      this.filter_org_list = data.data;
      return data;
    });
  };

  // call api to get all parent organisations list
  filterGetMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      let newData = [];
      data.data &&
        data.data.length > 0 &&
        data.data.forEach((x) => {
          newData[x.id] = x.organisation_name;
        });
      this.filter_organisation_list = newData;

      return newData;
    });
  };

  // export organisation data as a csv
  exportData = () => {
    this.exportLoading = true;
    return Axios.get(`ticket/export`)
      .then(({ data }) => {
        if (data.data && data.data.csv_link) {
          window.location.href = data.data.csv_link;
        }
        this.exportLoading = false;
        return data;
      })
      .catch(() => {
        this.exportLoading = false;
        return Promise.reject([]);
      });
  };

  convertTicket = (data) => {
    return Axios.post(`leads-tickets/convert-ticket-to-lead/${data.id}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;
    // var tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
    // localStorage.setItem(
    //   'savedFilters',
    //   JSON.stringify({
    //     ...tempsavedFilters,
    //     TicketsStore: data,
    //   })
    // );
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterValue, columnStates = null) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));

      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          TicketsStore: { filter: filterValue },
        })
      );

      // if (columnStates) {
      //   this.agGrid.columnApi.setColumnState(JSON.parse(columnStates));
      // } else {
      //   this.agGrid.columnApi.resetColumnState();
      // }
      this.agGrid.api.setFilterModel(filterValue);
    }
  };

  //Used On Waste Tab Change
  resetGridFilterForWaste = () => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));

      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          TicketsStore: {
            filter: {},
          },
        })
      );

      this.agGrid.api.setFilterModel({});
    }
  };

  resetSelectedSavedGridFilter = () => {
    this.selectedGridFilter = this.DEFAULT_GRID_FILTER_ID;
  };

  setMasterTicketToMerge = (data) => {
    this.masterTicketToMerge = data;
  };

  handleMergeTicket = (data) => {
    return Axios.post(`leadstickets/merge-tickets`, data)
      .then(({ data }) => {
        this.isMergePeople = true;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };


  setIsSavedFiltersLoadedTicket = (data) => {
    this.isSavedFiltersLoadedTicket = data;
  };


  getDropdownPriority = () => {
    return Axios.get(`ticket-priority`).then(({ data }) => {
      this.dropdown_priority = data.data;
      return this.dropdown_priority;
    });
  }

  applyResetDeadline = (id, payload) => {
    return Axios.post(`leadstickets/reset-deadline/${id}`, payload).then(({ data }) => {
      if (this.agGrid) {
        this.setupGrid(this.agGrid);
        this.setupGridSearch(this.agGrid);
      }
      return data;
    });
  }
  applyResetHoursRequired = (id, payload) => {
    return Axios.post(`leadstickets/reset-hours-required/${id}`, payload).then(({ data }) => {
      if (this.agGrid) {
        this.setupGrid(this.agGrid);
        this.setupGridSearch(this.agGrid);
      }
      return data;
    });
  }


}

decorate(TicketsStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  // per_page_master: observable,
  agGrid: observable,
  dropdown_master_ticket: observable,
  dropdown_user_list: observable,
  dropdown_ticketstatus: observable,
  viewValues: observable,
  deleteValues: observable,
  editValues: observable,
  cloneValues: observable,
  waste: observable,
  filter_org_list: observable,
  dropdown_ticket_type: observable,
  dropdown_priority: observable,
  search_payload: observable,
  exportLoading: observable,
  dropdown_leads_users_list: observable,
  reminderdatepicker_extrafooter: observable,
  selectedGridFilter: observable,
  DEFAULT_GRID_FILTER_ID: observable,
  masterTicketToMerge: observable,
  is_data_modified: observable,
  priority_name: observable,
  tickettype_name: observable,
  apicalled: observable,
  isSavedFiltersLoadedTicket: observable,
  isSearchPage: observable,

  filter_dropdown_master_ticket: observable,
  filter_organisation_list: observable,
  isChildList: observable,
  saveParams: observable,
  onLoadFliter: observable,
  masterTicketNumber: observable,
  gridLoading: observable,
  master_list_data: observable,
  isMergePeople: observable,

  setPageSize: action,
  // setPageSizeMaster: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  getMasterTicketList: action,
  getUsersList: action,
  getTicketstatusList: action,
  addTicket: action,
  setViewValues: action,
  setEditValues: action,
  setClonevalues: action,
  deleteTicketData: action,
  editTicket: action,
  RestoreData: action,
  getMappedOrg: action,
  exportData: action,
  onGridChanged: action,
  convertTicket: action,
  getLeadsUsersList: action,

  handleReset: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  resetGridFilterForWaste: action,
  resetSelectedSavedGridFilter: action,
  setMasterTicketToMerge: action,
  setisDataModified: action,
  setTicketType: action,
  setpriority: action,
  handleMergeTicket: action,
  filterMasterTicketList: action,
  filterGetMappedOrg: action,
  applyFilterDefault: action,
  setIsSavedFiltersLoadedTicket: action,
  setChildTicketList: action,
  setMasterTicketNumber: action,
  getListSearch: action,
  setupGridSearch: action,
  onGridChangedSearch: action,
  setupGridSearchMaster: action,
  onGridChangedSearchMaster: action,
  getDropdownPriority: action,
  applyResetDeadline: action,
  getMasterListSearch: action,
  onSearchFilterChanged: action,
  setIsSearchPage: action,
  applyResetHoursRequired: action,
});
