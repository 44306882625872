import React from 'react';
import { Button, Tooltip } from 'antd';
import useStore from '../../../store';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import NoteRenderer from './NoteRenderer';
// import DatePickerCostume from '../../../component/DatePickerCostume';
export const ActionRenderer = (props) => {
  const { t } = useTranslation()
  let history = useHistory();
  const {
    openEditDrawer,
    // openCloneDrawer,
    openDeleteModal,
    // openRestoreModal,
    //openRoleListing,
    // openViewDrawer,
    // WasteManagement,
    // openConfirmDateTimeModal,
    // openConfirmHourglassModal,
    //count,
  } = props.agGridReact.props.frameworkComponents;
  const {
    AUTH,
    AUTH: { checkPermission },
    // REMINDERSSTORE: { getReminderDatepickerExtraFooter },
    PEOPLE: { getLeadTransactionID },
  } = useStore();

  // const [extrafooter, setExtrafooter] = useState(null);

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // };

  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  // const content = (
  //   <div className='ticketsPopoverIcons'>
  //     <Space size={15}>
  //       {/* {props?.data?.reset_count !== null && ( */}
  //       <span
  //         className='Calendar cursor'
  //         onMouseEnter={() => {
  //           if (props.data?.reset_count !== null) {
  //             getReminderDatepickerExtraFooter(props.data, AUTH.global_fulldate_format, {
  //               type: 'contacts',
  //             }).then((data) => {
  //               setExtrafooter(data);
  //             });
  //           }
  //         }}
  //       >
  //         <Tooltip
  //           title={props.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`}
  //         >
  //           <DatePickerCostume
  //             name='Calendar'
  //             title={`${t('Common_Data.Calendar')}`}
  //             className='cursor'
  //             showTime
  //             disabledDate={true}
  //             // disabledHours={() => disabledHours()}
  //             // minuteStep={15}
  //             // hideDisabledOptions
  //             showNow={false}
  //             onOk={(e) => openConfirmDateTimeModal(e, props.data)}
  //             renderExtraFooter={() => extrafooter}
  //           />
  //         </Tooltip>
  //         <img
  //           className='menuicon'
  //           title={`${t('Common_Data.Calendar')}`}
  //           alt=''
  //           src={AUTH.GetThemedImage('Calendar')}
  //         />
  //       </span>
  //       {/* )} */}
  //       {/* {props?.data?.reset_count !== null && ( */}
  //       <>
  //         <img
  //           className='menuicon'
  //           title={`${t('OrganisationManagement.Hourglass')}`}
  //           alt=''
  //           src={AUTH.GetThemedImage('Reminders_icn')}
  //           onClick={() => openConfirmHourglassModal(props.data)}
  //         />
  //         <span>{props.data?.reset_count ? props.data?.reset_count : 0}</span>
  //       </>
  //       {/* )} */}
  //     </Space>
  //   </div>
  // );
  
  return (
    <div className='action-column'>
      <>
        {/* {props.data.leads_transactions_id > 0 && ( */}
        <Button
          type='text'
          title={props.data.leads_transactions_id > 0 ? `${t('PeopleManagement.History')}` : `${t('Common_Data.Start')}`}
          onClick={() => {
            if (props.data.leads_transactions_id > 0) {
              history.push(`/people/${props.data?.leads_transactions_id}`);
            } else {
              getLeadTransactionID(props.data.id).then((data) => {
                history.push(`/people/${data.leads_transactions_id}`);
              });
            }
          }}
        >
          <img alt='' className='menuicon' src={AUTH.GetThemedImage('Deal_icn')} />
        </Button>
        {/* )} */}

        {checkPermission(18, 'edit') && (
          <Button
            type='text'
            title={`${t('Common_Data.Edit')}`}
            onClick={() => {
              openEditDrawer(props.data);
            }}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Edit')} />
          </Button>
        )}

        {/* Note */}
        <NoteRenderer data={props.data} />

        {!checkPermission(18, 'delete') ? null : props.data?.match_transactions_count >
          0 ||
          props.data?.matched_related_transactions_count > 0 ||
          props.data?.people_roles_count > 0 ||
          props.data?.people_related_roles_count > 0 ? (
          <Tooltip
            placement='topRight'
            color={'red'}
            title={
              `${t('PeopleManagement.Transcation_Tooltip')}`
            }
          >
            <Button type='text' disabled>
              <img
                alt=''
                className='menuicon disabled'
                src={AUTH.GetThemedImage('Waste')}
              />
            </Button>
          </Tooltip>
        ) : (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => {
              openDeleteModal(props.data);
            }}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        )}

        {/* {checkPermission(18, 'view-details') && (
          <Button
            type='text'
            title={`${t('Common_Data.View')}`}
            onClick={() => {
              openViewDrawer(props.data);
            }}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('View')} />
          </Button>
        )}

        {
          <Button type='text' title={`${t('Common_Data.View')} ${t('LeadListing.Leads')}`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('List_view')}
              onClick={() => {
                localStorage.setItem(
                  'allLeadsByPeople',
                  JSON.stringify({ "people.full_name": { "values": [props.data.id], "filterType": "set" } })
                );
                window.open(`/leads/all`, '_blank');
              }}
            />
          </Button>
        }

        {checkPermission(18, 'clone') && (
          <Button
            type='text'
            title={`${t('Common_Data.Clone')}`}
            onClick={() => {
              openCloneDrawer(props.data);
            }}
          >
            <img
              alt=''
              className='menuicon'
              src={AUTH.GetThemedImage('Clone_Record')}
            />
          </Button>
        )} */}
        {/* {checkPermission(18, 'role-mapping-list') && (
            <Tooltip title={'Role Mapping'}>
              <Button
                type='text'
                onClick={() => {
                  openRoleListing(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Resign')} />
              </Button>
            </Tooltip>
          )} */}

        {/* {props?.data?.reset_count !== null && ( */}
        {/* <Popover
          content={content}
          placement='bottomRight'
          trigger='hover'
          overlayClassName='profileMenu'
          arrowPointAtCenter
        >
          <Button type='text' title={`${t('Common_Data.Clone')}`}>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('DropdownBox')} />
          </Button>
        </Popover> */}
        {/* )} */}
      </>
    </div>
  );
};
