import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Col, Form, Row, Spin } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store';
import debounce from 'lodash/debounce';
import { vsmTransactionReport } from '../../../../config/messages';

const FormRelationshipElements = observer(({ handleChange, formDataList, form }) => {
  const [searchPplKey, setSearchPplKey] = useState();
  const [searchOrgKey, setSearchOrgKey] = useState();
  const [fetchTicket, setFetchTicket] = useState(true);

  const { t } = useTranslation();

  const {
    PEOPLE,
    ORGANISATION,
    TicketsStore: { dropdown_master_ticket, getMasterTicketList },
    REPORTSTORE,
    REPORTSTORE: { searchLead, searchLeadKey, setSearchLeadKey, },
  } = useStore();

  const handlePplSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchPplKey(val);
      PEOPLE.getAllPeopleNameDropdown({ search_for: val, mapping_type: 2 });
    };

    return debounce(loadOptions, 500);
  }, [PEOPLE]);

  const handleOrgSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchOrgKey(val);
      ORGANISATION.getAllROrgNameListForReport({ search_for: val, mapping_type: 1 });
    };

    return debounce(loadOptions, 500);
  }, [ORGANISATION]);


  const handleSearchLead = useMemo(() => {
    const loadOptions = (val) => {
      if (val && val?.length >= 3) {
        setSearchLeadKey(val);
        searchLead({ search_text: val });
      }
    };

    return debounce(loadOptions, 500);
  }, [searchLead, setSearchLeadKey]);

  return (
    <>
      {form.getFieldValue('report_on') &&
        form.getFieldValue('report_on') === 'sold' &&
        formDataList.includes(`lead_id`) && (
          <Col span={12}>
            <InputComponent
              label={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
              name='lead_id'
              placeholder={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
              tooltip={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
              type='select'
              showSearch
              required
              allowClear
              showArrow
              rules={vsmTransactionReport.validation.sold_type}
              onChange={handleChange}
              onSearch={handleSearchLead}
              notFoundContent={
                REPORTSTORE.fetching ? (
                  <Spin size='small' />
                ) : searchLeadKey ? (
                  `${t('Common_Data.No_Record_Found')}.`
                ) : null
              }
              options={{
                values: REPORTSTORE.lead_list_data,
                value_key: 'id',
                text_key: 'search_name',
              }}
            />
          </Col>
        )}
      {form.getFieldValue('report_on') &&
        form.getFieldValue('report_on') === 'tickets' &&
        formDataList.includes(`ticket_number`) && (
          <Col span={12}>
            <InputComponent
              name='ticket_number'
              label={`${t('DealDesk.Ticket_Number')}`}
              type='select'
              rules={vsmTransactionReport.validation.sold_type}
              showSearch={true}
              required
              placeholder={`${t('DealDesk.Ticket_Number')}`}
              tooltip={`${t('DealDesk.Ticket_Number')}`}
              onChange={handleChange}
              allowClear
              showArrow
              onFocus={() =>
                fetchTicket && getMasterTicketList().then(() => setFetchTicket(false))
              }
              notFoundContent={
                fetchTicket ? (
                  <Spin size='small' />
                ) : (
                  `${t('Common_Data.No_Record_Found')}.`
                )
              }
              options={{
                values: dropdown_master_ticket,
                value_key: 'id',
                text_key: 'ticket_subject',
              }}
            />
          </Col>
        )}
      {form.getFieldValue('report_on') &&
        form.getFieldValue('report_on') === 'organisation' &&
        formDataList.includes('organisation_name') && (
          <Col span={12}>
            <InputComponent
              label={`${t('Common_Data.Organisation')}/s`}
              name='organisation_name'
              placeholder={`${t('PeopleManagement.All')}`}
              type='select'
              showSearch
              required
              rules={vsmTransactionReport.validation.sold_type}
              allowClear
              showArrow
              onChange={handleChange}
              onSearch={handleOrgSearch}
              notFoundContent={
                ORGANISATION.fetchingReportName ? (
                  <Spin size='small' />
                ) : searchOrgKey ? (
                  `${t('Common_Data.No_Record_Found')}.`
                ) : null
              }
              options={{
                values: ORGANISATION.dropdown_ROrg_name_report,
                value_key: 'id',
                text_key: 'entity_name',
              }}
              tooltip={`${t('Reports.Organisation_Tooltip')}.`}
            />
          </Col>
        )}
      {form.getFieldValue('report_on') &&
        form.getFieldValue('report_on') === 'people' &&
        formDataList.includes('people_name') && (
          <Col span={12}>
            <InputComponent
              label={`${t('Reports.People_Name')}`}
              name='people_name'
              placeholder={`${t('PeopleManagement.All')}`}
              type='select'
              showSearch
              required
              rules={vsmTransactionReport.validation.sold_type}
              allowClear
              showArrow
              onSearch={handlePplSearch}
              onChange={handleChange}
              notFoundContent={
                PEOPLE.fetchingNameDropdown ? (
                  <Spin size='small' />
                ) : searchPplKey ? (
                  `${t('Common_Data.No_Record_Found')}.`
                ) : null
              }
              options={{
                values: PEOPLE.drowpdown_people_name_list,
                value_key: 'id',
                text_key: 'entity_name',
              }}
              tooltip={`${t('Reports.People_Name_Tooltip')}.`}
            />
          </Col>
        )}

      {formDataList.includes('related_to_rows') && (
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Form.Item
                tooltip={t('Reports.Select_Rows_to_Display')}
                label={t('Reports.Select_Rows_to_Display')}
                name='related_to_rows'
                rules={vsmTransactionReport.validation.include_related_item_types}
              >
                <Checkbox.Group
                  name='related_to_rows'
                  options={[
                    `${t('Reports.Related_SOLD')}`,
                    `${t('Reports.Related_Tickets')}`,
                    `${t('Reports.Related_Organisations')}`,
                    `${t('Reports.Related_People')}`,
                  ]}
                  onChange={(e) => handleChange()}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
});

export default FormRelationshipElements;
