import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';

import InputComponent from '../../../component/InputComponent';
import useStore from '../../../store';
import { Form, Spin } from 'antd';
import moment from 'moment';
import debounce from 'lodash/debounce';
import EnterFullscreen from '../../../component/EnterFullscreen';
import ApplyGridFilterNew from '../../../component/ApplyGridFilterNew';
import { filterFiltersByPageType } from '../../../utils/GlobalFunction';
import ResetGridOptions from '../../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';
// import { DownOutlined } from '@ant-design/icons';

const TopSelection = observer(() => {
  const { t } = useTranslation();
  const [fetchOrgList, setFetchOrgList] = useState(true);
  const [fetchTicketstatus, setFetchTicketstatus] = useState(true);
  const [fetchUsersList, setFetchUsersList] = useState(true);
  const [dropdownPriority, setDropdownPriority] = useState(true);
  // const [expand, setexpand] = useState(false)
  const {
    MANAGEMENTALLDEPARTMENTSSTORE,
    ORGANISATION,
    TicketsStore,
    // , AUTH
    AUTH: { allPageFilters, allPageFiltersLoaded },
  } = useStore();
  const [form] = Form.useForm();

  const handleSubmit = (data) => {
    delete data['RecordPerPage'];
    let dateRangeArr = {};
    if (data.deadline_date) {
      dateRangeArr = {
        from_date: moment(data.deadline_date[0]).format('YYYY-MM-DD'),
        to_date: moment(data.deadline_date[1]).format('YYYY-MM-DD'),
      };
      data.deadline_date = dateRangeArr;
    }
    MANAGEMENTALLDEPARTMENTSSTORE.extraFilter = data;
    if (MANAGEMENTALLDEPARTMENTSSTORE.agGrid) {
      MANAGEMENTALLDEPARTMENTSSTORE.getList();
    }
  };

  const onChange = debounce(() => {
    form.submit();
  }, 200);

  useEffect(() => { }, [allPageFilters, allPageFiltersLoaded]);

  const pageFilterType = 'alldepartments';

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        className='innerForm'
        onFinish={handleSubmit}
        id='TopSelection'
      >
        <div className='topSelection'>
          <InputComponent
            name='organisation_id'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Common_Data.Organisation')}`}
            onChange={onChange}
            onFocus={() =>
              fetchOrgList &&
              ORGANISATION.getMappedOrg().then(() => setFetchOrgList(false))
            }
            options={{
              values: ORGANISATION.dropdown_global_org,
              value_key: 'id',
              text_key: 'organisation_name',
            }}
            notFoundContent={fetchOrgList ? <Spin size='small' /> : null}
          />
          <InputComponent
            name='ticket_status'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Common_Data.Status')}`}
            onChange={onChange}
            onFocus={() =>
              fetchTicketstatus &&
              TicketsStore.getTicketstatusList().then(() => setFetchTicketstatus(false))
            }
            options={{
              values: TicketsStore.dropdown_ticketstatus,
              value_key: 'id',
              text_key: 'status_name',
            }}
            notFoundContent={fetchTicketstatus ? <Spin size='small' /> : null}
          />
          <InputComponent
            name='priority'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Common_Data.Priority')}`}
            onChange={onChange}
            onFocus={() => {
              dropdownPriority && TicketsStore.getDropdownPriority().then(() => setDropdownPriority(true))                
            }}
            options={{
              values: TicketsStore.dropdown_priority,
              value_key: 'id',
              text_key: 'priority'
            }}
            notFoundContent={dropdownPriority ? <Spin size='small' /> : null}
          />
          <InputComponent
            name='holder'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Common_Data.User')}`}
            onChange={onChange}
            onFocus={() =>
              fetchUsersList &&
              TicketsStore.getUsersList().then(() => setFetchUsersList(false))
            }
            options={{
              values: TicketsStore.dropdown_user_list,
              value_key: 'id',
              text_key: 'name',
            }}
            notFoundContent={fetchUsersList ? <Spin size='small' /> : null}
          />
          <div className='dateRangeSelection'>
            <InputComponent
              allowClear
              onChange={onChange}
              name='deadline_date'
              placeholder={[`${t('DealDesk.Start_Date')}`, `${t('DealDesk.End_Date')}`]}
              type='date_range'
            />
          </div>

          <ApplyGridFilterNew
            type={pageFilterType}
            selectionValue={MANAGEMENTALLDEPARTMENTSSTORE.selectedGridFilter}
            filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
          />
          <div className='smallBtn height-35'>
            <EnterFullscreen key='1' />
          </div>

          <ResetGridOptions key={'2'} pageType={pageFilterType} form={form} />
        </div >
      </Form >
    </>
  );
});
export default TopSelection;
