import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import DisplayMsgList from '../component/DisplayMsgList';
import Footer from '../component/footer';
import useStore from '../../../../store';
import { Spin } from 'antd';

const Sequence = observer((props) => {
  const {
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: { sequence_notes_display_data, sequencenotes_list, dataValue, noteLoader },
  } = useStore();

  const sequenceList = true;

  let dealid = deal_data.lead_information && deal_data.lead_information.id;
  useEffect(() => { }, []);

  const { openExpandModalSeq, openModalSeq, close, isSpecific = false } = props;
  return (
    <div className='displayBoxWithFooter'>
      <Spin
        size='large'
        spinning={noteLoader}
        style={{
          textAlign: 'center',
          padding: '50px 30px',
        }}
      >
        <DisplayMsgList
          msgBoxArray={isSpecific ? sequence_notes_display_data : sequencenotes_list}
          type='sequence'
          dealid={dealid}
        />
      </Spin>
      <Footer
        msgBoxArray={{ get: isSpecific ? sequence_notes_display_data : sequencenotes_list, set: dataValue }}
        openExpandModalSeq={openExpandModalSeq}
        openModalSeq={openModalSeq}
        close={close}
        sequenceList={sequenceList}
      />
    </div>
  );
});

export default Sequence;
