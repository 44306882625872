import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmContracts } from '../../../config/messages';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    AUTH,
    CONTRACTS,
    CONTRACTS: { editValues, JsonToFormData, EditData },
  } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [imgchanged, setImgchanged] = useState(false);

  const close = () => {
    props.close();
    CONTRACTS.editValues = null;

    updateFileList([]);
    setImgchanged(false);
    setSaving(false);
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.id = editValues.id;

    data.contract_start_date =
      data.contract_start_date && data.contract_start_date.format('Y/MM/D');
    data.contract_end_date =
      data.contract_end_date && data.contract_end_date.format('Y/MM/D');
    data.company_signed_date =
      data.company_signed_date && data.company_signed_date.format('Y/MM/D');
    data.customer_signed_date =
      data.customer_signed_date && data.customer_signed_date.format('Y/MM/D');

    var formData = JsonToFormData(data);

    if (fileList.length > 0 && fileList[0] && imgchanged) {
      formData.append(
        'contract_file_path',
        fileList[0].hasOwnProperty('originFileObj')
          ? fileList[0].originFileObj
          : fileList[0]
      );
    } else if (fileList.length === 0) {
      formData.append('contract_file_path', 'deleted');
    }

    EditData(data, formData)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmContracts.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (editValues) {
      updateFileList(
        editValues.contract_file_path
          ? [
            {
              uid: '-1',
              name: editValues.contract_file_path.substring(
                editValues.contract_file_path.lastIndexOf('/') + 1
              ),
              status: 'done',
              url: editValues.contract_file_path,
            },
          ]
          : []
      );

      form.setFieldsValue({
        contract_owner: editValues.contract_owner,
        contract_number: editValues.contract_number?.toString(),
        contract_start_date: editValues.contract_start_date
          ? moment(editValues.contract_start_date)
          : null,
        contract_end_date: editValues.contract_end_date
          ? moment(editValues.contract_end_date)
          : null,
        contract_months: editValues.contract_months,
        global_organisation_id: parseInt(editValues.global_organisation_id),
        contract_status: editValues.contract_status,
        owner_expiration_notice: editValues.owner_expiration_notice
          ? parseInt(editValues.owner_expiration_notice)
          : null,
        price_book_description: editValues.price_book_description,
        special_terms: editValues.special_terms,
        description: editValues.description,
        contract_file_path: editValues.contract_file_path,
        customer_signed_by: editValues.customer_signed_by,
        company_signed_by: editValues.company_signed_by,
        customer_signed_title: editValues.customer_signed_title,
        customer_signed_date: editValues.customer_signed_date
          ? moment(editValues.customer_signed_date)
          : null,
        company_signed_date: editValues.company_signed_date
          ? moment(editValues.company_signed_date)
          : null,
        currency_id: parseInt(editValues.currency_id),
      });
    }
  }, [AUTH, CONTRACTS, editValues, updateFileList, form]);

  return (
    editValues && (
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Contracts.Edit_Contract')} - #${editValues?.id}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right'>
            <Button
              key='1'
              form='editform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              shape='round'
              size='medium'
              type='primary'
              disabled={isDisabled}
            >
              {t('Common_Data.Update')}
            </Button>
            <Button onClick={close} shape='round' size='medium' key='2'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
          updateFileList={updateFileList}
          fileList={fileList}
          setImgchanged={setImgchanged}
        />
      </Drawer>
    )
  );
});

export default EditComponent;
