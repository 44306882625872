import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Result, Space } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import useStore from '../../../store';
// import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
// import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import AddComponent from './component/AddComponent';
//import AddBankComponent from './component/AddBankComponent';
import { default as PeopleRolemappingEditComponent } from '../../PeopleManagement/RoleMapping/component/EditComponent';
//import { default as AddBankAccountComponent } from '../BankAccounts/component/AddComponent';
import ContentLoader from '../../../component/ContentLoader';
import Unauthorized from '../../Unauthorized';
import { useTranslation } from 'react-i18next';

const RoleMapping = observer((props) => {
  const {t} = useTranslation()
  let history = useHistory();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editPplModal, setEditPplModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [mapBankModal, setMapBankModal] = useState(false);
  //const [addBankAccountModal, setAddBankAccountModal] = useState(false);
  const [loading, setLoading] = useState('pending');
  const [mappingId, setMappingId] = useState(null);
  const {
    ORGANISATION,
    ORGMAPPINGSTORE,
    PEOPLEMAPPINGSTORE,
    PEOPLE,
    ORGMAPPINGSTORE: {
      getList,
      //setEditValues,
      setDeleteValues,
    },
    AUTH,
  } = useStore();
  const { id } = useParams();

  // set id on edit drawer
  const setId = (id) => setMappingId(id);

  // Open form for add new Organisation
  const openAddModal = () => {
    setAddModal(true);
  };

  // Close form for close new Organisation
  const closeAddModal = () => setAddModal(false);

  // Open form for edit existing Organisation and set values to form
  const openEditModal = (data) => {
    setId(data.id);
    if (data.entity_type === 'People') {
      var payload = { people_id: data.entity_id };
      PEOPLE.drowpdown_people_list = null;
      PEOPLE.peopleValues = null;
      PEOPLE.getRecordDetail(payload).then((data) => {
        setEditPplModal(true);
      });
    } else {
      setEditModal(true);
    }
  };

  // Open to add Bank Accounts
  // const openMapBankAccount = (data) => {
  //   OrgBankAccountStore.getAllBanksList(
  //     ORGANISATION.organisationValues.id
  //   );
  //   setEditValues(data);
  //   setMapBankModal(true);
  // };

  //   // Open add Bank Accounts
  //   const openAddBankAccount = (data) => {
  //     setAddBankAccountModal(true);
  //   };

  //   // Close Bank Account Modal
  //   const closeBankModal = () => setMapBankModal(false);

  // Close Add Bank Account Modal
  // const closeAddBankModal = () => setAddBankAccountModal(false);

  // Close form of edit Organisation
  const closeEditModal = () => setEditModal(false);

  // Close form of edit people
  const closeEditPplModal = () => {
    PEOPLEMAPPINGSTORE.setEditValues(null);
    setEditPplModal(false);
  };

  // Open confirmation alert before deleting existing Organisation
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);

  useEffect(() => {
    if (loading === 'pending') {
      ORGMAPPINGSTORE.list_data = null;
      var payload = { organisation_id: id };
      ORGANISATION.getRecordDetail(payload)
        .then((data) => {
          setLoading(200);
        })
        .catch((data) => {
          if (data.status === 404) {
            setLoading(404);
          }
          if (data.status === 403) {
            setLoading(403);
          }
        });
      getList(payload);
    }
  }, [getList, ORGMAPPINGSTORE, ORGANISATION, id, loading]);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();

        if (result) {
          if (AUTH.checkPermission(16, 'role-mapping-edit')) {
            if (result.key === 'Enter') {
              openEditModal(e.data);
            }
          }
          if (AUTH.checkPermission(16, 'role-mapping-delete')) {
            if (result.key === 'Delete') {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };

  function pageTitle() {
    return (
      <>
        {t('OrganisationManagement.Role')} {t('Common_Data.Mapping')}
        <Button
          key='1'
          shape='round'
          size='small'
          type='primary'
          onClick={history.goBack}
          className='goBackBtn'
        >
          {t('Common_Data.GO')} {t('Common_Data.Back')}
        </Button>
      </>
    );
  }

  if (loading === 'pending') {
    return <ContentLoader />;
  }
  if (loading === 200) {
    return (
      <>
        {!AUTH.checkPermission(16, 'role-mapping-list') ? (
          <Unauthorized />
        ) : (
          <PageHeader
            title={pageTitle()}
            extra={[
              <Space>
                {AUTH.checkPermission(16, 'role-mapping-add') && (
                  <Button key='2' onClick={openAddModal} shape='round' size='mediumn'>
                    {t('OrganisationManagement.Map')} {t('OrganisationManagement.New')} {t('OrganisationManagement.Role')}
                  </Button>
                )}
              </Space>,
            ]}
          >
            <ListComponent
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              onCellKeyDown={onCellKeyDown}
            />
            <AddComponent
              visible={addModal}
              close={closeAddModal}
              parent_id={id}
              waste={props.waste}
            />
            <PeopleRolemappingEditComponent
              visible={editPplModal}
              close={closeEditPplModal}
              parent_id={id}
              mappingId={mappingId}
              setId={setId}
            />
            <EditComponent
              visible={editModal}
              close={closeEditModal}
              parent_id={id}
              mappingId={mappingId}
              setId={setId}
            />
            <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
            {/* {mapBankModal && (
             <AddBankComponent visible={mapBankModal} close={closeBankModal} />
           )}
           <AddBankAccountComponent
             visible={addBankAccountModal}
             close={closeAddBankModal}
           /> */}
          </PageHeader>
        )}
      </>
    );
  } else {
    if (loading === 404) {
      return (
        <Result
          status='404'
          title='404'
          subTitle={`${t('Common_Data.Not_Exist')}.`}
          extra={
            <Button
              type='primary'
              onClick={() => {
                history.goBack();
              }}
            >
               {t('Common_Data.GO')} {t('Common_Data.Back')}
            </Button>
          }
        />
      );
    }
    if (loading === 403) {
      return (
        <Result
          status='403'
          title='403'
          subTitle={`${t('Common_Data.Not_Authorized')}.`}
          extra={
            <Button
              type='primary'
              onClick={() => {
                history.goBack();
              }}
            >
               {t('Common_Data.GO')} {t('Common_Data.Back')}
            </Button>
          }
        />
      );
    }
  }
});

export default RoleMapping;
