import React, { useState } from 'react';
import { Form, Button, Drawer } from 'antd';
import { observer } from 'mobx-react';
import { vsmOrganisations, vsmNotify } from '../../../../config/messages';
import useStore from '../../../../store';
import FormComponent from './FormComponentNew';
import { useHistory } from 'react-router-dom';

import { default as AddPeopleComponent } from '../../../PeopleManagement/People/component/AddComponentNew';
import { default as EditPeopleComponent } from '../../../PeopleManagement/People/component/EditComponentNew';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../../component/TimeTracker';

const AddComponent = observer((props) => {
  const { t } = useTranslation();
  const { isLead = false, isChildDrawer = false, isFromPeople = false } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const {
    AUTH,
    ORGANISATION: { JsonToFormData, AddData, getOrgCurrentLedgerMap, getRecordDetail },
    PEOPLE,
    TimeTrackerStore,
  } = useStore();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [isChecked, setChecked] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [addPeopleModal, setAddPeopleModal] = useState(false);
  const [editPeopleDrawer, setEditPeopleDrawer] = useState(false);
  // const [leadTransactionID, setLeadTransactionID] = useState(null);

  // Handle submit and call function to save new record
  const handleSubmit = (data) => {
    if (data.phone && data.phone.length > 0) {
      data.phone = data.phone.filter((x) => x && x.contact_for && x.contact_value);
      data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null;
    }
    if (data.email && data.email.length > 0) {
      data.email = data.email.filter((x) => x && x.contact_for && x.contact_value);
      data.email = data.email.length > 0 ? JSON.stringify(data.email) : null;
    }
    if (data.addresses && data.addresses.length > 0) {
      data.add_address = true;
      data.addresses = data.addresses.filter((x) => x && x && x.address_type);
      data.addresses =
        data.add_address && data.addresses.length > 0
          ? JSON.stringify(data.addresses)
          : null;
      if (data.addresses && data.addresses.length > 0) {
        data.add_address = true;
      } else {
        data.add_address = false;
      }
    }

    setSaving(true);
    delete data['nace_category_id'];
    var formData = JsonToFormData(data);
    if (fileList.length > 0 && fileList[0]) {
      formData.append(
        'logo_url',
        fileList[0].hasOwnProperty('originFileObj')
          ? fileList[0].originFileObj
          : fileList[0]
      );
    }
    let formPayloadObject = data;
    AddData(formData)
      .then((data) => {
        TimeTrackerStore.setLeadTransactionId(data.leads_transactions_id)
        if (props.currentTab && props.currentTab.para === 'maporg') {
          getOrgCurrentLedgerMap({
            search_for: data.organisation_name,
            related_entity_type: 1,
          }).then((data) => {
            props.currentTab.form.setFieldsValue({
              entity_id: data.last_inserted_id,
            });
            getRecordDetail({ organisation_id: data.last_inserted_id });
          });
        }
        let redirectToDesk = true;
        if (props.setOrgDropDown) {
          redirectToDesk = false;
          props.setOrgDropDown(data.id, formPayloadObject.industry_id);
        }
        close('add');
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmOrganisations.add,
        });
        if (!isLead && redirectToDesk) {
          history.push(`/organisation/${data.leads_transactions_id}`);
        }
        // if (data.leads_transactions_id) {
        //   setLeadTransactionID(data.leads_transactions_id)
        // }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        let checkbox = form.getFieldValue('add_address');
        let address = form.getFieldValue('addresses');
        address = address && address.filter((x) => x && x);
        if (checkbox && (!address || (address && address.length === 0))) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  // reset form and close add form
  const close = (action = null) => {
    props.close(action);
    form.resetFields();
    setDisabled(true);
    setChecked(false);
  };

  // check whether address checkbox is checked or not
  const addAddress = async (e) => {
    if (!e.target.checked) {
      form.resetFields([
        'postal_code',
        'address_1',
        'address_2',
        'address_3',
        'city',
        'state_county',
      ]);
    }
    form.setFieldsValue({ addresses: e.target.checked ? [null] : null });
    await setChecked(e.target.checked);
    handleChange();
  };

  // Open form for add new People
  const openAddModal = () => {
    setAddPeopleModal(true);
  };

  const setPeopleDropDown = (id) => {
    PEOPLE.getAllPeople({ selected_id: [id], mapping_type: 2 });
    form.setFieldsValue({ full_name: id });
    handleChange();
  };

  // Close form for add new People
  const closeAddModal = () => setAddPeopleModal(false);

  // Open form for edit People
  const openEditPeopleDrawer = () => {
    var payload = { people_id: form.getFieldValue('full_name') };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setEditPeopleDrawer(true);
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  };
  // Close form for edit People
  const closeEditPeopleDrawer = () => {
    PEOPLE.getAllPeople({
      selected_id: [form.getFieldValue('full_name')],
      mapping_type: 2,
    });

    setEditPeopleDrawer(false);
    handleChange();
  };

  return (
    <Drawer
      key='5'
      title={`${t('Common_Data.Add')} ${t('Common_Data.Organisation')}`}
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      zIndex={isChildDrawer ? 1005 : 1000}
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          <Button
            key='1'
            disabled={disabled}
            form='addform'
            loading={saving}
            htmlType='submit'
            type='primary'
            className='mr-10'
            shape='round'
            size='medium'
          >
            {t('Common_Data.Save')}
          </Button>
          <Button key='2' shape='round' size='medium' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <FormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        id='addform'
        isFromPeople={isFromPeople}
        updateFileList={updateFileList}
        fileList={fileList}
        addAddress={addAddress}
        isChecked={isChecked}
        openAddModal={openAddModal}
        openEditPeopleDrawer={openEditPeopleDrawer}
      />

      <AddPeopleComponent
        visible={addPeopleModal}
        close={closeAddModal}
        isFromOrg={true}
        isChildDrawer={true}
        setPeopleDropDown={setPeopleDropDown}
      />
      <EditPeopleComponent
        visible={editPeopleDrawer}
        close={closeEditPeopleDrawer}
        setPeopleDropDown={setPeopleDropDown}
      />
      {props.visible && <TimeTracker
        page={"organisation"}
        stop={!props.visible || addPeopleModal || editPeopleDrawer}
        fromSideBar={props?.fromSideBar}
      />}
    </Drawer>
  );
});
export default AddComponent;
