import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import useStore from '../../../store';
import { vsmNotify, vsmLeadsList } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const ReassignConfirmComponent = observer((props) => {
  const {t} = useTranslation();
  const {
    LEADSSTORE: { assignDeal },
    LEADSSTORE,
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
  } = useStore();
  const [saving, setSaving] = useState(false);

  const location = useLocation();
  const [form] = Form.useForm();
  const close = () => {
    props.close();
    form.resetFields();
  };

  // Make function call to reassign deal
  const handleSubmit = () => {
    setSaving(true);
    let payload = {
      lead_holder_id: props.reAssingTempData.userid,
    };
    assignDeal(props.reAssingTempData.leadid, payload)
      .then(() => {
        close();
        if (location.pathname === '/leads/deals') {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
        }
        if (location.pathname === '/leads/opportunities') {
          LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
        }
        if (location.pathname === '/leads/active') {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
        }
        if (location.pathname === '/search/Leads') {
          LEADSSTORE.getListSearch(LEADSSTORE.search_payload)
        }
        if(location.pathname === '/search/Deals') {
          LEADDEALS.getListSearch(LEADDEALS.search_payload)
        }
        vsmNotify.success({
          message: vsmLeadsList.successAssignLead,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
        setSaving(false);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return props.reAssingTempData ? (
    <Modal
      centered
      title={`${t('LeadListing.Reassign')} ${t('Common_Data.To')} ${props.reAssingTempData.username}`}
      visible={props.visible}
      onCancel={close}
      onOk={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteformReassignConfirm'
          htmlType='submit'
          type='primary'
          shape='round'
          onClick={close}
          loading={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformReassignConfirm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('LeadListing.Reassign_Lead')} {props.reAssingTempData.leadid}{' '}
              {t('Common_Data.To')} {props.reAssingTempData.username}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default ReassignConfirmComponent;
