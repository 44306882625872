import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import useStore from '../../../../store';
import { PageHeader, Button, Form, Space } from 'antd';
import ListComponent from './component/ListComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import AddComponent from './component/AddComponent';
//import RecordPerPage from '../../../../config/RecordPerPage';
import Unauthorized from '../../../Unauthorized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../../../component/EnterFullscreen';
import { useTranslation } from 'react-i18next';
import RecordPerPage from '../../../../config/RecordPerPage';

const Roles = observer(() => {
  const { t } = useTranslation();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [form] = Form.useForm();
  let history = useHistory();
  const {
    AUTH,
    ORGROLESTORE,
    ORGROLESTORE: { setEditValues, setDeleteValues, handleReset },
    SETTINGS,
  } = useStore();

  // Open form for add new Role
  const openAddModal = () => setAddModal(true);

  // Close form for close new Role
  const closeAddModal = () => setAddModal(false);

  // Open form for edit existing Role and set values to form
  const openEditModal = (data) => {
    setEditValues(data);
    setEditModal(true);
  };

  // Close form of edit Role
  const closeEditModal = () => setEditModal(false);

  // Open confirmation alert before deleting existing Role
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);
  function pageTitle() {
    return (
      <>
        {t('OrganisationManagement.Organisations_Roles')}
        <Button
          key='1'
          shape='round'
          size='small'
          type='primary'
          onClick={history.goBack}
          className='goBackBtn'
        >
          {t('Common_Data.GO')} {t('Common_Data.Back')}
        </Button>
      </>
    );
  }

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(17, 'edit')) {
            if (result.key === 'Enter') {
              openEditModal(e.data);
            }
          }
          if (AUTH.checkPermission(17, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };
  return (
    <>
      {!AUTH.checkPermission(17, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={pageTitle()}
          className={SETTINGS.fullscreen_class}
          extra={[
            <Form form={form} className='innerForm headerButton'>
              <Space align='center' size={5}>
                {AUTH.checkPermission(17, 'add') && (
                  <Button key='3' onClick={openAddModal} shape='round' size='medium'>
                    {t('OrganisationManagement.New')} {t('OrganisationManagement.Role')}
                  </Button>
                )}
                <Button
                  key='1'
                  title={`${t('Common_Data.Reset')}`}
                  shape='round'
                  size='medium'
                  onClick={handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <EnterFullscreen key='2' />
                <RecordPerPage
                  key='4'
                  initialValue={ORGROLESTORE.per_page + ' per page'}
                  onChange={ORGROLESTORE.setPageSize}
                />

              </Space>
            </Form>,
          ]}
        >
          <ListComponent
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
            onCellKeyDown={onCellKeyDown}
          />
          <AddComponent visible={addModal} close={closeAddModal} />
          <EditComponent visible={editModal} close={closeEditModal} />
          <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
        </PageHeader>
      )}
    </>
  );
});

export default Roles;
