import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
// import { useWindowDimensions } from "../../../../../config/WindowHeightWidth";
import useStore from "../../../../../store";
import { globalStatus } from "../../../../../utils/GlobalFunction";
// import ButtonsComponent from "../ButtonsComponent";
import debounce from 'lodash/debounce';
import DropdownComponent from "./DropdownComponent";
import RightSideTicketDetails from "./RightSideTicketDetails";
import TicketDetailsComponent from "./TicketDetailsComponent";
import ButtonsComponent from "./ButtonsComponent";

import { default as ViewTicketComponent } from '../../../../Tickets/component/ViewComponent';
import { default as ViewEditTicketComponent } from '../../../../Tickets/component/EditComponentNew';
import { default as ViewOrganisationComponent } from '../../../../OrganisationManagement/AllOrganisations/component/ViewComponent';
import { default as EditOrganizationComponent } from '../../../../OrganisationManagement/AllOrganisations/component/EditComponentNew';
import { default as ViewPeopleComponent } from '../../../../PeopleManagement/People/component/ViewComponent';

const TicketDeskHeaderComponent = observer(() => {

  // const { width } = useWindowDimensions();
  const { TicketsStore,
    PEOPLE, ORGANISATION,
    AUTH,
    DEALDESKSTORE: {
      deal_data,
      getDealDetail,
    }, } = useStore()
  const [viewTicketDrawer, setViewTicketDrawer] = useState(false);
  const [viewOrganisationDrawer, setViewOrganisationDrawer] = useState(false);
  const [viewPeopleDrawer, setViewPeopleDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [isMasterTicket, setisMasterTicket] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);

  // States added for resolving zindex issue
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  // ----------------------------------------//

  const setId = (id) => setTransactionId(id);

  const openTicketViewDrawer = (isMaster = false) => {
    setisMasterTicket(isMaster);
    // TicketsStore.setViewValues(deal_data?.lead_information?.ticket_data);
    setViewTicketDrawer(true);
  };
  const closeTicketViewDrawer = () => {
    setViewTicketDrawer(false);
    setisMasterTicket(false);
  };

  const openEditDrawer = (data, isViewOpen = false) => {
    setViewOpen(isViewOpen);
    TicketsStore.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setViewOpen(false);
  }

  // View Organisation Drawer
  const handleViewORGANISATIONDetailCall = debounce((data) => {
    ORGANISATION.viewPeopleLoading = true;
    ORGANISATION.getViewOrgDetail(data?.id)
      .then((data) => {
        if (data?.cash_transactions && data?.cash_transactions.length) {
          data?.cash_transactions.forEach((item) => {
            item.status_name = globalStatus(
              'cash_transaction_status',
              'key',
              item.status
            );
          });
        }
        ORGANISATION.viewOrgValues = data;
        PEOPLE.list_related_ORG = data?.related_organisations;
        PEOPLE.list_related_Ppl = data?.related_peoples;
        PEOPLE.list_trade_transaction = data?.trade_transactions;
        PEOPLE.list_cash_transaction = data?.cash_transactions;
        PEOPLE.list_banks = data?.banks;
        ORGANISATION.viewPeopleLoading = false;
      })
      .catch((ORGANISATION.viewPeopleLoading = false));
  }, 500);

  const openOrganisationViewDrawer = () => {
    if (deal_data?.lead_information?.sold_organisation?.id) {
      ORGANISATION.viewOrgValues = deal_data?.lead_information?.organisation_name;
      setViewOrganisationDrawer(true);
      handleViewORGANISATIONDetailCall({
        id: deal_data?.lead_information?.sold_organisation?.id,
      });
    }
  };



  const closeOrganisationViewDrawer = () => {
    setViewOrganisationDrawer(false);
  };

  // Open form for edit existing Organisation and set values to form
  const openEditModal = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    ORGANISATION.setOrganisationValues(null);
    setId(data?.id);
    ORGANISATION.setEditValues(data);
    setEditModal(true);
  };

  // Close form of edit Organisation
  const closeEditModal = () => {
    ORGANISATION.organisationValues = null;
    ORGANISATION.setEditValues(null);
    setEditModal(false);
    setEditOpen(false);
    getDealDetail(deal_data?.lead_information?.id);
  };

  // View People Drawer
  const handleViewPeopleDetailCall = debounce((data) => {
    PEOPLE.viewPeopleLoading = true;
    PEOPLE.setViewValues(data);
    PEOPLE.getViewPplDetail(data?.id)
      .then((data) => {
        PEOPLE.viewPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.viewPeopleLoading = false;
      });
  }, 300);

  const openPeopleViewDrawer = () => {
    if (deal_data?.lead_information?.people?.id) {
      if (AUTH.checkPermission(18, 'view-details')) {
        PEOPLE.viewPeopleLoading = true;
        setViewPeopleDrawer(true);
        handleViewPeopleDetailCall({ id: deal_data?.lead_information?.people?.id });
      }
    }
  };

  const closePeopleViewDrawer = () => {
    PEOPLE.setViewValues([]);
    PEOPLE.peopleValues = null;
    setViewPeopleDrawer(false);
    getDealDetail(deal_data?.lead_information?.id);
  };
  return (
    <>
      <Row gutter={5} className='HeaderComponent'>

        <Col span={4}>
          <DropdownComponent />
        </Col>

        {/* <Col span={width && width > 1900 ? 15 : 15}> */}
        <Col span={15}>
          <TicketDetailsComponent
            openPeopleViewDrawer={openPeopleViewDrawer}
            openOrganisationViewDrawer={openOrganisationViewDrawer}
            openTicketViewDrawer={openTicketViewDrawer}
            openTicketEditDrawer={openEditDrawer}

          />
        </Col>

        {/* <Col span={width && width > 1900 ? 5 : 5}> */}
        <Col span={5}>
          <RightSideTicketDetails
            openTicketViewDrawer={openTicketViewDrawer}
            openTicketEditDrawer={openEditDrawer}
          />
        </Col>
        <Col span={24}>
          <ButtonsComponent
            openTicketViewDrawer={openTicketViewDrawer}
            setisMasterTicket={setisMasterTicket}
            setViewTicketDrawer={setViewTicketDrawer}
          />
        </Col>
      </Row>
      <ViewOrganisationComponent
        ticketDesk={true}
        id={deal_data?.lead_information?.sold_organisation?.id}
        openEditModal={openEditModal}
        visible={viewOrganisationDrawer}
        close={closeOrganisationViewDrawer}
        handleViewDetailCall={handleViewORGANISATIONDetailCall}
      />

      <EditOrganizationComponent
        transactionId={transactionId}
        setId={setId}
        editOpen={editOpen}
        visible={editModal}
        close={closeEditModal}
      />
      <ViewTicketComponent
        ticketDesk={true}
        id={isMasterTicket ? deal_data?.lead_information?.ticket_data?.master_ticket : deal_data?.lead_information?.ticket_data}
        visible={viewTicketDrawer}
        close={closeTicketViewDrawer}
        openEditDrawer={openEditDrawer}
      />

      <ViewEditTicketComponent
        viewOpen={viewOpen}
        visible={editDrawer}
        close={closeEditDrawer}
      />

      <ViewPeopleComponent
        ticketDesk={true}
        id={deal_data?.lead_information?.people?.id}
        visible={viewPeopleDrawer}
        close={closePeopleViewDrawer}
        handleViewDetailCall={handleViewPeopleDetailCall}
      />
    </>
  )
})
export default TicketDeskHeaderComponent;