import React, { useEffect, useState } from 'react';
import KanbanView from './component/KanbanView';
import TopSelection from './component/TopSelection';
import { Spin } from 'antd';
import useStore from '../../../store';
import { observer } from 'mobx-react';
import AddComponent from '../component/AddComponent';
//import TrackTimeComponent from '../../../component/TrackTimeComponent';
import TimeTracker from '../../../component/TimeTracker';
import { useTranslation } from 'react-i18next';
import { Notify } from '../../../utils/Notify';
const LeadKanbanView = observer(() => {
  const { t } = useTranslation();

  const [addDrawer, setAddDrawer] = useState(false);

  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }
  const [updateList, setupdateList] = useState(null);

  const {
    SETTINGS,
    LEADSTATUS,
    LEADSTATUS: {
      getKanbanLeadStatus,
      getList,
      listLoading,
      setListLoading,
      kanbanLead_status_list,
      list_data,
      extraFilter,
      cardDataUpdate
    }
  } = useStore();

  useEffect(() => {
    if (!kanbanLead_status_list) {
      getKanbanLeadStatus()
    }
    getList();

  }, [getKanbanLeadStatus, kanbanLead_status_list, getList]);

  useEffect(() => {
    if (extraFilter !== null) {
      if (kanbanLead_status_list && list_data) {
        let sequenceObj = {};
        kanbanLead_status_list.map((obj) => {
          let filterList = list_data.filter(x => Number(x.id) === Number(obj.id));
          if (filterList && filterList[0]) {
            let sequenceList = filterList[0].child_leads.map(x => { return x.id });

            sequenceObj[obj.id] = sequenceList;
          }
          else {
            sequenceObj[obj.id] = []
          }
          return null
        })
        setupdateList(sequenceObj);
      }
    }
  }, [extraFilter, list_data, kanbanLead_status_list])

  useEffect(() => {
    return () => {
      LEADSTATUS.extraFilter = null
    }
  }, [LEADSTATUS])

  const onDragStop = (params, event) => {
    if (params.data && params.dropIndex !== undefined) {
      let newUpdatedList = updateList;
      let parent_statusID = Number(params.data.parent_status_id);
      let id = params.data.id
      let currentExists = cardDataUpdate.findIndex(x => x.parent_status_id === parent_statusID && x.status_name === params.data.status_name)
      let currentParentID = cardDataUpdate.filter(x => x.id === id);

      if (currentExists !== -1) {
        if (currentParentID[0]["parent_status_id"] === Number(parent_statusID)) {
          currentExists = -1;
        }
      }
      if (currentExists === -1) {

        let sequenceArr = updateList[parent_statusID];

        let previousIndex = sequenceArr.findIndex(x => x === id);
        let newIndex = params.dropIndex
        let existingID = sequenceArr[newIndex]
        if (existingID !== id) {
          if (newIndex !== -1 && previousIndex !== -1) {
            //------------------  DRAG TOP TO BOTTOM ------------------------------//
            if (newIndex > previousIndex) {
              // sequenceArr[previousIndex] = existingID;
              // sequenceArr[newIndex] = id;
              let a1 = [];
              let a2 = [];
              let a3 = [];
              let a4 = [];
              updateList[parent_statusID].forEach((e, i) => {
                if (i < newIndex && id !== e) {
                  a1.push(e);
                }
                if (existingID === e) {
                  a2.push(e);
                }
                if (id === e) {
                  a3.push(e);
                }
                if (i > newIndex) {
                  if (!a2.includes(e) && !a3.includes(e)) {
                    a4.push(e);
                  }
                }
              });
              sequenceArr = [...a1, ...a2, ...a3, ...a4];
            }
            //-----------------   DRAG BOTTOM TO TOP ------------------------------//
            else if (newIndex < previousIndex) {
              // sequenceArr[previousIndex] = existingID;
              let a1 = [];
              let a2 = [];
              let a3 = [];
              let a4 = [];
              updateList[parent_statusID].forEach((e, i) => {
                if (i < newIndex) {
                  a1.push(e);
                }
                if (existingID === e) {
                  a3.push(e);
                }
                if (id === e) {
                  a2.push(e);
                }
                if (i > newIndex) {
                  if (!a2.includes(e) && !a3.includes(e)) {
                    a4.push(e);
                  }
                }
              });
              sequenceArr = [...a1, ...a2, ...a3, ...a4];
            }
          }
        }
        newUpdatedList[parent_statusID] = sequenceArr;
        setupdateList(newUpdatedList)


        let newArr = [];
        Object.values(newUpdatedList).map((item) => {
          newArr = [...newArr, ...item];
          return item;
        });

        let status_change;
        if (currentParentID[0]) {
          if (currentParentID[0]["parent_status_id"] !== Number(parent_statusID)) {
            status_change = [
              { "status_id": id, "new_parent_id": parent_statusID }
            ]
          }
        }
        let payload = {
          lead_record_type: extraFilter,
          sequence: newArr.length > 1 ? newArr.join(",") : newArr.length > 0 ? String(newArr[0]) : String(id), // all columns array
          // sequence: sequenceArr.length > 1 ? sequenceArr.join(",") : sequenceArr.length > 0 ? String(sequenceArr[0]) : String(id),
        };
        if (status_change) {
          payload.status_change = status_change;
        }
        LEADSTATUS.saveReorder(payload)
          .then((res) => {
            Notify.success({
              message: res.message
            })
          })
          .catch((e) => { })
          .finally(() => { });
      }
      else {
        event.cancel = true
      }
    }

  };

  //open add drawer
  const openAddDrawer = () => {
    setAddDrawer(true);
  };

  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  return (
    <>
      <div className={SETTINGS.fullscreen_class}>
        <div className='KanbanView'>
          <TopSelection
            openAddDrawer={openAddDrawer}
          />
          <div className='mt-10' style={{ width: '500px', minWidth: '100%' }}>
            {kanbanLead_status_list && list_data ? (
              <KanbanView
                onDragStop={onDragStop}
                allowDragAndDrop={extraFilter ? true : false}
                fieldKey={'parent_status_id'}
                directives={kanbanLead_status_list}
                datasource={list_data}
                loadkanban={listLoading}
                setLoadkanban={setListLoading}
                cardDataUpdate={cardDataUpdate}
              />
            ) : (
              <Spin size='large' tip={`${t('Common_Data.Load')}`}></Spin>
            )}
          </div>

          <AddComponent visible={addDrawer} close={closeAddDrawer} />
        </div>
        {/* <TrackTimeComponent pageName={'sold_status'} /> */}
        <TimeTracker />
      </div>
    </>
  );
});

export default LeadKanbanView;
