import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { ActionRenderer, StatusRenderer, TypeRenderer } from './GridComponent';
import { vsmCommon } from '../../../config/messages';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { DateComparator } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
import LocalGridConfig from '../../../config/LocalGridConfig';
import MasterTicketRenderer from '../component/MasterTicketRenderer';
import PriorityRender from '../element/PriorityRender';



const ListComponent = observer((props) => {
  const { openEditDrawer, openViewDrawer, expandGroup, expand } = props;
  const { t } = useTranslation();
  const {
    TicketsStore,
    MANAGEMENTALLDEPARTMENTSSTORE,
    PRODUCTIVITYSTORE,
    MANAGEMENTALLDEPARTMENTSSTORE: { onGridChanged, onFilterChanged },
    AUTH,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();
  //const [gridApi, setGridApi] = useState(null);
  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }

  const onCellKeyDown = (e) => {
    if (AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (result.key === 'Enter') {
            openEditDrawer(e.data);
            //openEditDrawer(e.data);
          }
          if (result.key === 'Delete') {
            //bulkDelete(e.data);
          }
        }
      }
    }
  };

  useEffect(() => {
    MANAGEMENTALLDEPARTMENTSSTORE.getList();
    TicketsStore.getTicketstatusList();
  }, [MANAGEMENTALLDEPARTMENTSSTORE, TicketsStore]);

  // const LocalGridConfig = {
  //   options: {
  //     animateRows: true,
  //     pagination: false,
  //     paginationPageSize: 100,
  //     rowHeight: 30,

  //     onRowDragEnd: (event) => { },
  //   },
  //   defaultColDef: {
  //     resizable: true,
  //     sortable: true,
  //     filter: 'agTextColumnFilter',
  //     filterParams: {
  //       suppressAndOrCondition: true,
  //     },
  //     floatingFilter: true,
  //     flex: 1,
  //     minWidth: 80,
  //   },
  //   columnTypes: {
  //     actionColumn: {
  //       cellRenderer: 'ActionRenderer',
  //     },
  //   },
  // };

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: `${t('Knowledge.Group_Name')}`,
        field: 'group_name',
        rowGroup: true,
        hide: true,
        rowDrag: false,
      },
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        filter: false,
        cellClass: 'cellClass',
        headerClass: ' p-0 pl-10 m-0',
        pinned: 'left',
        headerComponentFramework: () => {
          return (
            <div className='smallBtn p-0 m-0'>
              <Button
                key='2'
                title={`${expand === true ? `${t('ManageAllDepartments.Expand')}` : `${t('ManageAllDepartments.Collapse')}`}`}
                shape='round'
                size='small'
                className='collapseIcon-management p-0 m-0'
                onClick={() => {
                  expandGroup();
                }}
              >
                {expand === true ? <UpOutlined /> : <DownOutlined />}
              </Button>
            </div>
          );
        },
        minWidth: 45,
        width: 45,
        // onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Common_Data.Priority')}`,
        headerTooltip: `${t('Common_Data.Priority')}`,
        field: 'priority',
        tooltipField: 'priority',
        cellClass: 'cellClass priorityRender',
        filter: 'agSetColumnFilter',
        sortable: false,
        lockPosition: true,
        cellRenderer: 'PriorityRender',
        // valueFormatter: (params) => {
        //   formatterSetColumnFilter(
        //     TicketsStore.dropdown_priority,
        //     params,
        //     'id',
        //     'priority'
        //   )
        // },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TicketsStore.getDropdownPriority().then((data) => {
              params.success([...TicketsStore.dropdown_priority.map((x) => x.id)]);
              // params?.success([...data?.data?.map((x) => x.id)]);
              // alert("hi")
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              TicketsStore.dropdown_priority,
              params,
              'id',
              'priority'
            ),
        },
        // onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Common_Data.ID')}`,
        field: 'tag_ticket_no',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
        sortable: true,
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'holder',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.holder;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PRODUCTIVITYSTORE.getUsersList().then((data) => {
              params.success([null, ...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('ManageAllDepartments.Master_Ticket_Number')}`,
        // onCellClicked: (params) => {
        //   params?.data?.master_ticket && openViewDrawer(params?.data?.master_ticket);
        // },
        field: 'master_ticket.master_ticket_number',
        cellClass: 'cellClass',
        cellRenderer: 'MasterTicketRenderer',
      },
      {
        headerName: `${t('ManageAllDepartments.Master_Ticket_Subject')}`,
        field: 'master_ticket.ticket_subject',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation.organisation_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('ManageAllDepartments.Subject')}`,
        field: 'subject',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        field: 'description',
        cellRenderer: (params) => {
          var a = document.createElement('div');
          a.innerHTML = params.data.description;
          return a;
        },
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        field: 'assigned_to.lead_ticket_status.status_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TicketsStore.getTicketstatusList().then((data) => {
              params.success([null, ...data.map((x) => x.status_name)]);
            });
          },
        },
        cellClass: 'cellClass StatusCellPromotion text-left',
        cellRenderer: 'StatusRenderer',
      },
      
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTime(params.data.deadline_date,AUTH.global_fulldate_format)
            : '';
        },
        floatingFilter: true,
      },

      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        field: 'created_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.created_at ? AUTH.getLocalTime(params.data.created_at,AUTH.global_fulldate_format) : '';
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        field: 'updated_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.updated_at ? AUTH.getLocalTime(params.data.updated_at,AUTH.global_fulldate_format) : '';
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        cellRenderer: 'ActionRenderer',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        width: 170,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('alldepartment_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 37rem)',
      }}
    >
      <AgGridReact
        rowData={MANAGEMENTALLDEPARTMENTSSTORE.list_data}
        modules={AllModules}
        columnDefs={columns}
        // rowClassRules={
        //   {
        //     "row-other": function (params) { return params?.node?.data?.priority !== null },
        //     "row-critical": function (params) { return params?.node?.data?.priority === "Critical" },
        //     "row-urgent": function (params) { return params?.node?.data?.priority === "Urgent" },
        //     "row-important": function (params) { return params?.node?.data?.priority === "Important" },
        //     "row-timed": function (params) { return params?.node?.data?.priority === "Timed" },
        //   }
        // }
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          floatingFilter: true,
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          MasterTicketRenderer,
          StatusRenderer,
          TypeRenderer,
          openEditDrawer,
          openViewDrawer,
          expand,
          PriorityRender,
        }}
        onGridReady={MANAGEMENTALLDEPARTMENTSSTORE.setupGrid}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: false,
          animateRows: true,
          rowHeight: 30,
        }}
        onCellKeyDown={onCellKeyDown}
        onCellDoubleClicked={(e) => {
          openViewDrawer(e.data);
        }}
        groupUseEntireRow={true}
        rowDragManaged={true}
        onColumnResized={onGridChanged}
        onColumnMoved={onGridChanged}
        onColumnPinned={onGridChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        groupDefaultExpanded={0}
      />
    </div>
  );
});
export default ListComponent;
