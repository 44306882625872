import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Checkbox, Form, Row, Col, Radio, Space } from 'antd';
import TinyComponent from '../../../component/TinyComponent';
import useStore from '../../../store';
import Notification from '../elements/Notification';
import DropDownSelection from '../elements/DropDownSelection';
import {
  vsmLeadsList,
  vsmNotify,
  vsmOrganisations,
  vsmSnapNote,
  vsmTags,
  vsmTicket,
} from '../../../config/messages';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ExpandSpanNoteComponent = observer((props) => {
  const [typeValue, setTypeValue] = useState('user');
  const [hourglassActive, setHourglassActive] = useState(false);
  const [usersActive, setUsersActive] = useState(false);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation()
  const [form] = Form.useForm();

  const {
    AUTH,
    SPANNOTESTORE: { addSnapNote, getList, setUsersSelected },
    TicketsStore: { addTicket },
    TagsStore: { addTag },
    LEADSSTORE: { addLeadData },
    ORGANISATION,
  } = useStore();

  // const SnapNoteTitle = () => {
  //   return (
  //     <>
  //       <img
  //         src={AUTH.GetThemedImage('Snap_Note')}
  //         alt='Snap Note'
  //         className='titleIcon'
  //       />{' '}
  //       Snap Note <span className='TitleValue'>{totalNotes}</span>
  //     </>
  //   );
  // };

  const saveReminder = (formdata) => {
    addSnapNote(formdata)
      .then(() => {
        AUTH.fetchLeftmenuCount();
        if (typeValue === 'user') {
          vsmNotify.success({
            message: vsmSnapNote.add,
          });
        } else {
          vsmNotify.success({
            message: vsmSnapNote.reminderadd,
          });
        }
        form.resetFields();
        setUsersSelected([]);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setDisabled(false));
  };
  // Add SnapNote
  const onFinish = (values) => {
    setDisabled(true);
    let formdata = {
      type: values.type,
      hourglass: values.hourglass,
      snap_note_texts: values.content,
    };

    if (typeValue === 'user') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'tags') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'tickets') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'leads') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'contacts') {
      formdata.users = values.userSelect;
    }

    if (typeValue === 'tickets' && !formdata.hourglass) {
      //Add Ticket
      let ticket_payload = {
        ticket_subject: values.content,
        is_org: true,
        ticket_status: 1, // Default Open AS per API
        ticket_description: values.content,
        priority: 4, //# Default Routine
        leads_entity_attributes_type: 8,
        owner: AUTH.user.id,
        is_deleted: 0,
      };
      addTicket(ticket_payload).then((res) => {
        formdata.id = res.id;
        formdata.hourglass = true;
        formdata.snap_note_texts = res.ticket_number + ' : ' + values.content;
        saveReminder(formdata);

        vsmNotify.success({
          message: vsmTicket.add,
        });
      });
    } else if (typeValue === 'tags' && !formdata.hourglass) {
      //Add Tag
      let tag_payload = {
        tag_subject: values.content,
        organisation_id: 0,
        parent_ticket_id: null,
        holder: AUTH.user.id,
        tag_status: 2, //Default Unassigned
        deadline_date: moment().format('YYYY-MM-DD'),
        tag_content: `<p>${values.content}</p>`,
      };
      addTag(tag_payload).then((res) => {
        formdata.id = res.id;
        formdata.hourglass = true;
        formdata.snap_note_texts = res.tag_number + ' : ' + values.content;
        saveReminder(formdata);
        vsmNotify.success({
          message: vsmTags.add,
        });
      });
    } else if (typeValue === 'leads' && !formdata.hourglass) {
      let timestamp = new Date().valueOf();
      let lead_payload = {
        lead_record_type: 0,
        classification_id: 0,
        full_name: 0,
        organisation_name: 0,
        lead_owner_id: AUTH.user.id,
        lead_holder_id: AUTH.user.id,
        lead_status_id: 4, //# Default Unused
        lead_currency_id: 1, //# Default Currency Euro
        phone: [{ contact_for: 'Primary', contact_value: '0000000000' }],
        email: [
          { contact_for: 'Primary', contact_value: `blank${timestamp}@convertibill.com` },
        ],
        product_name: values.content,
      };
      addLeadData(lead_payload)
        .then((res) => {
          formdata.id = res.id;
          formdata.hourglass = true;
          formdata.snap_note_texts = values.content;
          saveReminder(formdata);
          vsmNotify.success({
            message: vsmLeadsList.add,
          });
        })
        .finally(() => { });
    } else if (typeValue === 'contacts' && !formdata.hourglass) {
      let org_payload = {
        description: values.content,
        organisation_name: values.content,
        registered_country_id: 106,
        industry_id: 0,
        status_id: 1,
        nace_section_id: 0,
        currency_id: 1, // Default Euro
        add_address: 0,
      };
      ORGANISATION.AddData(org_payload).then((res) => {
        formdata.id = res.id;
        formdata.hourglass = true;
        formdata.snap_note_texts = values.content;
        saveReminder(formdata);

        vsmNotify.success({
          message: vsmOrganisations.add,
        });
      });
    } else {
      saveReminder(formdata);
    }
  };

  // handle Type Change
  const handleTypeChange = (e) => {
    setTypeValue(e.target.value);
  };

  const disableHourGlass = () => {
    setHourglassActive(true);
  };
  const activeHourGlass = () => {
    setHourglassActive(false);
  };

  const handleClick = () => {
    setChecked(!checked);
    if (!checked) {
      setUsersActive(true);
    } else {
      setUsersActive(false);
    }
  };

  useEffect(() => {
    const id = setInterval(() => getList(), 12000);
    return () => {
      clearInterval(id);
    };
  }, [getList]);

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  const EditorConfig = {
    selector: 'textarea#full-featured-non-premium',
    plugins:
      'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    menubar: false,
    quickbars_selection_toolbar: 'addKeyNote addEmailLink addSmsLink link unlink',
    contextmenu: false,
    height: 585,
  };

  const ExpandView = true;

  return (
    <>
      <Form
        form={form}
        id='SnapNoteForm'
        className='innerForm SnapNoteForm'
        onFinish={onFinish}
        name='SnapNote'
      >
        <Row gutter={10}>
          <Col span={18}>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name='content'
                  placeholder={`${t('SnapNoteModule.Your')} ${t('SnapNoteModule.Message')}`}
                  onChange={handleChange}
                  rules={vsmSnapNote.validation.content}
                >
                  <TinyComponent
                    EditorConfig={EditorConfig}
                    htmlName={`content`}
                    form={form}
                  />
                </Form.Item>
              </Col>
              <Col span={22} className='mt-10'>
                <Space align='start' size={5} className='footerBtn'>
                  <Form.Item
                    name='type'
                    onChange={() => {
                      form.resetFields(['userSelect']);
                      //handleChange();
                    }}
                    rules={vsmSnapNote.validation.type}
                  // initialValue='user'
                  // defaultValue='user'
                  >
                    <Radio.Group
                      name='type'
                      className='iconList'
                      onChange={handleTypeChange}
                    //defaultValue='user'
                    >
                      <Radio.Button
                        value='contacts'
                        name='contacts'
                        onClick={activeHourGlass}
                      >
                        <img
                          src={AUTH.GetThemedImage('Contacts_icn')}
                          alt={`${t('Reports.Contacts')}`}
                          className='titleIcon'
                          title={`${t('Reports.Contacts')}`}
                        />
                      </Radio.Button>
                      <Radio.Button value='leads' onClick={activeHourGlass}>
                        <img
                          src={AUTH.GetThemedImage('Deal_icn')}
                          alt={`${t('Common_Data.Lead')}, ${t('Common_Data.Deal')} & ${t('SnapNoteModule.Oportunity')}`}
                          className='titleIcon'
                          title={`${t('Common_Data.Lead')}, ${t('Common_Data.Deal')} & ${t('SnapNoteModule.Oportunity')}`}
                        />
                      </Radio.Button>
                      <Radio.Button value='tickets' onClick={activeHourGlass}>
                        <img
                          src={AUTH.GetThemedImage('Tickets')}
                          alt={`${t('Common_Data.Tickets')}`}
                          className='titleIcon'
                          title={`${t('Common_Data.Tickets')}`}
                        />
                      </Radio.Button>
                      <Radio.Button value='tags' onClick={activeHourGlass}>
                        <img
                          src={AUTH.GetThemedImage('Note')}
                          alt={`${t('Common_Data.Tags')} ${t('Common_Data.Note')}`}
                          className='titleIcon'
                          title={`${t('Common_Data.Tags')} ${t('Common_Data.Note')}`}
                        />
                      </Radio.Button>
                      <Radio.Button
                        value='user'
                        name='user'
                        disabled={usersActive}
                        onClick={disableHourGlass}
                      >
                        <img
                          src={AUTH.GetThemedImage('Profile')}
                          alt={`${t('ManageAllDepartments.Users')} & ${t('ManageAllDepartments.Users')} ${t('Common_Data.Group')}`}
                          className='titleIcon'
                          title={`${t('ManageAllDepartments.Users')} & ${t('ManageAllDepartments.Users')} ${t('Common_Data.Group')}`}
                        />
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name='hourglass'
                    initialValue={false}
                    valuePropName='checked'
                  >
                    <Checkbox
                      className='iconList hourglassCheckbox'
                      disabled={hourglassActive}
                      checked={checked}
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      <img
                        src={AUTH.GetThemedImage('Reminders_icn')}
                        alt={`${t('Common_Data.Snap')} ${t('Common_Data.Note')}`}
                        className='titleIcon'
                      />
                    </Checkbox>
                  </Form.Item>
                  <DropDownSelection typeValue={typeValue} onChange={handleChange} />
                </Space>
              </Col>
              <Col span={2} className='mt-10'>
                <button className='SendNote' htmlType='submit' disabled={disabled}>
                  <img src={AUTH.GetThemedImage('Send_Note')} alt='' />
                </button>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Notification ExpandView={ExpandView} />
          </Col>
        </Row>
      </Form>
    </>
  );
});

export default ExpandSpanNoteComponent;
