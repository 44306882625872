import { useCallback, useEffect } from 'react'


export const GridLoader = (Store) => {
    const onBtShowLoading = useCallback(() => {
        if (Store.agGrid) {
            Store.agGrid.api.showLoadingOverlay();
        }
    }, [Store.agGrid]);

    const hideOverlayFunction = useCallback(() => {
        if (Store.agGrid) {
            Store.agGrid.api.hideOverlay();
        }
    }, [Store.agGrid]);

    useEffect(() => {
        if (Store.gridLoading) {
            onBtShowLoading();
        } else {
            hideOverlayFunction();
        }
        return () => { };
    }, [Store.gridLoading, onBtShowLoading, hideOverlayFunction]);
}