import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Spin, Steps } from 'antd';
import useStore from '../../../store';
import axios from 'axios';
import { secondsToHmsWithAppend } from '../../../utils/GlobalFunction';

const { Step } = Steps;

const ViewComponent = observer(({ visible, close, sequenceTitle }) => {
  const {
    AUTH,
    SEQUENCERSTORE: {
      viewValues,
      viewLoader,
      //  downloadFile
    },
  } = useStore();

  const [current, setCurrent] = useState(0);
  const [stepDetails, setStepDetails] = useState(null);

  const onChange = (value) => {
    let result = viewValues.filter((e) => e.step === value + 1);
    setStepDetails(result && result.length > 0 ? result[0] : null);
    setCurrent(value);
  };

  useEffect(() => {
    if (visible && !stepDetails && viewValues) {
      let result = viewValues.filter((e) => e.step === 1);
      setStepDetails(result && result.length > 0 ? result[0] : null);
    }
  }, [visible, viewValues, setStepDetails, stepDetails]);

  const download = (url, payload) => {
    return axios.post(url, payload).then((data) => {
      return data;
    });
  };

  return (
    <Drawer
      visible={visible}
      onClose={close}
      placement='right'
      //width={'1100px'}
      width={'86vw'}
      title={`${sequenceTitle}`}
      destroyOnClose={true}
      footer={[]}
    >
      <Spin
        size='large'
        spinning={viewLoader}
        tip={'Loading'}
        style={{
          height: '100vh',
        }}
      >
        <Steps
          type='navigation'
          size='small'
          current={current}
          onChange={onChange}
          className='site-navigation-steps'
        >
          {viewValues &&
            viewValues.map((element) => {
              return (
                <Step
                  title={`${element.title}`}
                  // subTitle={`Step ${element.title}}`}
                  status={`${element.step}`}
                  description={secondsToHmsWithAppend(
                    element.details?.data?.step_name?.step_time_seconds
                  )}
                />
              );
            })}
        </Steps>
        <br />
        {stepDetails && (
          <>
            <table cellPadding={0} cellSpacing={0} className='sequencerTable'>
              <thead>
                <tr>
                  <th style={{ width: '15%' }}>
                    {stepDetails?.details?.data?.step_name?.title}
                  </th>
                  <th style={{ width: '40%' }}>
                    {stepDetails?.details?.data?.additional_information?.title}
                  </th>
                  <th style={{ width: '15%' }}>
                    {stepDetails?.details?.data?.submission_time?.title}
                  </th>
                  <th style={{ width: '15%' }}>
                    {stepDetails?.details?.data?.step_detail?.title
                      ? stepDetails?.details?.data?.step_detail?.title
                      : 'Step Details'}
                  </th>
                  <th style={{ width: '15%' }}>
                    {stepDetails?.details?.data?.email_status?.title}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{stepDetails?.details?.data?.step_name?.content}</td>
                  <td>
                    <>
                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              stepDetails?.details?.data?.additional_information?.content,
                          }}
                        ></div>
                      }

                      {
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              stepDetails?.details?.data?.additional_information
                                ?.approval_status,
                          }}
                        ></div>
                      }

                      {stepDetails?.details?.data?.additional_information?.files &&
                      stepDetails?.details?.data?.additional_information?.files.length >
                        0 ? (
                        <>
                          <label>
                            <b>File Upload</b>:
                          </label>
                          {stepDetails?.details?.data?.additional_information?.files.map(
                            (x, i) => {
                              return (
                                <span className='fileDownload'>
                                  <img
                                    className='menuicon'
                                    alt=''
                                    onClick={() =>
                                      download(x.content, { token: 'DMFILETOKEN2022' })
                                    }
                                    title={x.title}
                                    style={{ width: '1.8rem', height: 'auto' }}
                                    src={AUTH.GetThemedImage('Files_icn')}
                                  />
                                </span>
                              );
                            }
                          )}
                        </>
                      ) : null}
                    </>
                  </td>
                  <td>
                    {stepDetails?.details?.data?.submission_time?.content
                      ? stepDetails?.details?.data?.submission_time?.content
                      : '-'}
                  </td>
                  <td>
                    {stepDetails?.details?.data?.step_detail?.user_approved_notes
                      ? stepDetails?.details?.data?.step_detail?.user_approved_notes
                      : '-'}
                  </td>
                  <td>
                    {stepDetails?.details?.data?.email_status?.content
                      ? stepDetails?.details?.data?.email_status?.content
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </Spin>
    </Drawer>
  );
});

export default ViewComponent;
