import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { Spin, Popover } from 'antd';
import { vsmNotify, vsmProductivity } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const StatusRenderer = observer((props) => {
  const { t } = useTranslation()
  const {
    TicketsStore: { dropdown_ticketstatus },
    TagsStore: { statusArr_list },
    PRODUCTIVITYSTORE: { tickettagStatusUpdate },
    AUTH,
  } = useStore();

  let OwnerIsLoggin = true //props?.data?.owner?.id === AUTH?.user?.id
  let HolderIsLoggin = true //props?.data?.holder_id === AUTH?.user?.id

  const handleChangeStatusTicketTag = (value) => {
    if (AUTH.checkPermission(3, 'change-status')) {
      let payload = {
        type: props.data.type,
        id: props.data.id,
        status_id: value,
      };
      tickettagStatusUpdate(payload).then((data) => {
        vsmNotify.success({ message: vsmProductivity.successChangeStatus });
      });
    }
  };

  let defaultSelection = null;
  if (props?.data?.type.toLowerCase() === 'ticket') {
    let isStatusCheck = (e) => e.id === parseInt(props?.data?.status);
    let defaultSelectedValueIndex = dropdown_ticketstatus.findIndex(isStatusCheck);
    if (defaultSelectedValueIndex >= 0) {
      defaultSelection = dropdown_ticketstatus[defaultSelectedValueIndex];
    }
  }
  if (props?.data?.type.toLowerCase() === 'tag') {
    let isStatusCheck = (e) => e.value === parseInt(props?.data?.status);

    let defaultSelectedValueIndex = statusArr_list.findIndex(isStatusCheck);
    defaultSelection = statusArr_list[defaultSelectedValueIndex];
  }
  const changeStatusOptins = (
    <div className='ant-popover-inner-content'>
      <div className='productivityListing'>
        {props?.data?.type.toLowerCase() === 'ticket' ? (
          <ul>
            {
              dropdown_ticketstatus ? (
                dropdown_ticketstatus.map(function (item, index) {
                  return (
                    <li key={item.id + index} onClick={() => handleChangeStatusTicketTag(item.id)}>
                      {item.status_name}
                    </li>
                  );
                })
              ) : (
                <Spin />
              )}
          </ul>
        ) : (
          <ul>
            {statusArr_list ? (
              statusArr_list.map(function (item, index) {
                if (
                  defaultSelection?.text === 'Open' &&
                  ['Read', 'Closed', 'Approved'].includes(item.text)
                ) {
                  return (
                    <li
                      key={item.value}
                      onClick={() => handleChangeStatusTicketTag(item.value)}
                      disabled={true}
                    >
                      {item.text}
                    </li>
                  );
                } else if (
                  defaultSelection?.text === 'Read' &&
                  ['Closed', 'Approved'].includes(item.text)
                ) {
                  return (
                    <li
                      key={item.value}
                      onClick={() => handleChangeStatusTicketTag(item.value)}
                      disabled={true}
                    >
                      {item.text}
                    </li>
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <Spin />
            )}
          </ul>
        )}
      </div>
    </div >
  );

  const approvedNotesStatusChange = (
    <div className='ant-popover-inner-content'>
      <div className='productivityListing'>
        <ul>
          {statusArr_list ? (
            statusArr_list.map(function (item, index) {
              if (['Closed'].includes(item.text)) {
                return (
                  <li
                    key={item.value}
                    onClick={() => handleChangeStatusTicketTag(item.value)}
                    disabled={true}
                  >
                    {item.text}
                  </li>
                );
              } else {
                return null;
              }
            })
          ) : (
            <Spin />
          )}
        </ul>
      </div>
    </div>
  );

  let approvedTagNoteDesc = props?.data?.tag_content?.includes('<approved');
  let approvedTag = false;
  if (approvedTagNoteDesc) {
    approvedTag = true;
  }


  return (
    <>
      <div className='action-column StatusRenderer text-left'>

        {
          AUTH.checkPermission(3, 'change-status')
            ? (props?.data?.type.toLowerCase() === 'ticket' && (

              (OwnerIsLoggin || HolderIsLoggin)
                ? <Popover
                  content={changeStatusOptins}
                  placement='bottom'
                  trigger='click'
                  overlayClassName='profileMenu'
                >
                  <img
                    className='menuicon mr-10'
                    alt=''
                    title={`${t('Block_Edit_Assign.Status_Change')}`}
                    src={AUTH.GetThemedImage('StatusChange')}
                  />
                  {defaultSelection?.status_name}
                </Popover>
                : <>
                  <img
                    className='menuicon mr-10'
                    alt=''
                    title={`${t('Block_Edit_Assign.Status_Change')}`}
                    src={AUTH.GetThemedImage('StatusChange')}
                    style={{ opacity: 0 }}
                  />
                  {defaultSelection?.status_name}</>

            )) ||
            (props?.data?.type.toLowerCase() === 'tag' && (
              <>
                {OwnerIsLoggin || HolderIsLoggin ? (
                  ['Open', 'Read'].includes(defaultSelection?.text) ? (
                    <Popover
                      content={
                        approvedTag ? approvedNotesStatusChange : changeStatusOptins
                      }
                      placement='bottom'
                      trigger='click'
                      overlayClassName='profileMenu'
                    >
                      <img
                        className='menuicon mr-10'
                        alt=''
                        title={`${t('Block_Edit_Assign.Status_Change')}`}
                        src={AUTH.GetThemedImage('StatusChange')}
                      />
                      {defaultSelection?.text}
                    </Popover>
                  ) : (
                    <>
                      <img
                        className='menuicon mr-10'
                        alt=''
                        title={`${t('Block_Edit_Assign.Status_Change')}`}
                        src={AUTH.GetThemedImage('StatusChange')}
                      />
                      {defaultSelection?.text}
                    </>
                  )
                ) : (
                  <>
                    <img
                      className='menuicon mr-10'
                      alt=''
                      title={`${t('Block_Edit_Assign.Status_Change')}`}
                      src={AUTH.GetThemedImage('StatusChange')}
                    />
                    {defaultSelection?.text}
                  </>
                )}
              </>
            ))
            : props.value
        }
      </div>
    </>
  );
});
export default StatusRenderer;
