import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import FormComponent from './FormComponentNew';
import debounce from 'lodash/debounce';
import moment from 'moment';
import useStore from '../../../store';
import { useHistory } from 'react-router-dom';
import { vsmTicket, vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const AddTicketsComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    TicketsStore: { addTicket },
    TimeTrackerStore,
  } = useStore();

  const history = useHistory();
  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isMasterTicket, setMasterTicket] = useState(false);
  const [isOrgTicket, setOrgTicket] = useState();
  // const [subjectInput, setSubjectInput] = useState(''); //To set the data of subject Input

  /** To check the subject input contains word (master) */
  //let isIncludeMasterInSubjectInput = subjectInput && subjectInput.toLowerCase().includes("(master)");

  const close = () => {
    props.close();
    setDisabled(true);
    setSaving(false);
    form.resetFields();
    setMasterTicket(false);
    setOrgTicket(false);
    // setSubjectInput("")
  };
  const handleSubmit = (data) => {
    data.leads_entity_attributes_type = 8;
    data.owner = AUTH.user.id;

    data.hours_required = 0
    data.hours_required_hour = data.hours_required_hour ? data.hours_required_hour : 0
    data.hours_required_min = data.hours_required_min ? data.hours_required_min : 0
    if (data.hours_required_hour || data.hours_required_min) {

      let convertHourToMinInt = (data.hours_required_hour && parseInt(data.hours_required_hour * 60))
      let convertminitueToMinInt = (data.hours_required_min && parseInt(data.hours_required_min))

      let setTotalTime = (convertHourToMinInt && convertHourToMinInt) + (convertminitueToMinInt && convertminitueToMinInt)
      data.hours_required = parseInt(setTotalTime) * 60
    }
    data.deadline_date = data.deadline_date && moment(data.deadline_date).format(AUTH.form_submit_date_with_seconds);

    if (data.is_deadlinetime && data.deadline_time) {
      data.deadline_time = data.deadline_time
        ? moment(data.deadline_time).format('HH:mm:ss')
        : null;
    } else {
      delete data.deadline_time;
    }
    data.is_deleted = 0;

    let organisationID = data.organisation_id && data.organisation_id
    if (organisationID) {
      if (organisationID === 'Credebt Exchange' || organisationID === 1) {
        data.organisation_id = 1
      } else {
        data.organisation_id = organisationID
      }
    }
    else {
      data.organisation_id = ''
    }


    setSaving(true);
    addTicket(data)
      .then((res) => {
        TimeTrackerStore.setLeadTransactionId(res?.leads_transactions_id);
        close();
        AUTH.fetchLeftmenuCount();
        form.resetFields();
        vsmNotify.success({
          message: vsmTicket.add,
        });
        history.push(`/ticket/${res.ticket_number}`);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = debounce(() => {
    // setSubjectInput(form.getFieldValue("ticket_subject"));
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  /**This is called on onBlur event on subject input*/
  // const handleBlur = () => {
  //   if (subjectInput && isMasterTicket && !isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput} (Master)` })
  //     setSubjectInput(`${subjectInput} (Master)`)
  //   }
  //   if (subjectInput && !isMasterTicket && isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput.replace(/\(Master\)/gi, '')}` })
  //     setSubjectInput(`${subjectInput.replace(/\(Master\)/gi, '')}`);
  //   }
  // }

  /**This is called on onChange of Master Ticket Check box event on subject input*/
  // const handleCheck = () => {
  //   if (subjectInput && !isMasterTicket && !isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput} (Master)` })
  //   }
  //   if (subjectInput && isMasterTicket && isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput.replace(/\(Master\)/gi, '')}` });
  //   }
  // }


  return (
    <>
      <Drawer
        key='2'
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Add')} ${t('Common_Data.Ticket')}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right' key='1'>
            <Button
              key='1'
              form='addTicketFrom'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              shape='round'
              disabled={isDisabled}
            >
              {t('Common_Data.Save')}
            </Button>
            <Button shape='round' onClick={close} key='2'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='addTicketFrom'
          isMasterTicket={isMasterTicket}
          setMasterTicket={setMasterTicket}
          isOrgTicket={isOrgTicket}
          setOrgTicket={setOrgTicket}
          isDisabled={isDisabled}
          addTicket={true}
        // onBlurSubject={handleBlur}
        // onCheck={handleCheck}
        />
      </Drawer>
      <TimeTracker
        page={"tickets"}
        stop={!props.visible}
        fromSideBar={props?.fromSideBar}
      />
    </>
  );
});

export default AddTicketsComponent;
