import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import { Col, Row } from 'antd';
import DateRangeIterationElement from './DateRangeIterationElement';
import { vsmTransactionReport } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const PeriodSelectionElement = observer(({ formDataList, form, onChange }) => {
  const { t } = useTranslation()
  return (

    <Row gutter={24}>
      <Col>
        <InputComponent
          label={`${t('Reports.Period')} ${t('Reports.Selection')}`}
          type='radio_button'
          name='period_selection'
          onChange={onChange}
          options={{
            className: 'd-flex w-100',
            values: [
              { value: 'custom', text: `${t('Reports.Custom')}` },
              { value: 'auto', text: `${t('Reports.Auto')}` }
            ]
          }}
        />
      </Col>
      <Col>
        {(formDataList.includes('period_x_value') ||
          formDataList.includes('period_x_value_type')) && (
            <>
              <label className='horizontal-required'>{t('Common_Data.Date')} {t('Reports.Custom')}</label>
              <Row gutter={24}>
                {formDataList.includes('period_x_value') && (
                  <Col span={4}>
                    <InputComponent
                      required
                      type='number'
                      name='period_x_value'
                      min={2}
                      max={12}
                      className='w-100'
                      onChange={onChange}
                      rules={vsmTransactionReport.validation.period_x_value}
                    />
                  </Col>
                )}
                {formDataList.includes('period_x_value_type') && (
                  <Col span={20}>
                    <InputComponent
                      onChange={onChange}
                      type='radio_button'
                      name='period_x_value_type'
                      options={{
                        className: 'd-flex w-100',
                        values: [
                          { value: 'day', text: `${t('Reports.Day')}/s` },
                          { value: 'week', text: `${t('Common_Data.Week')}/s` },
                          { value: 'month', text: `${t('Common_Data.Month')}/s` },
                          { value: 'quarter', text: `${t('Common_Data.Quarter')}/s` },
                          { value: 'year', text: `${t('Reports.Year')}/s` }
                        ]
                      }}
                      tooltip={`${t('Reports.Period_Value_Tooltip')}.`}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        {formDataList.includes('period_date_range') && (
          <>
            <label className='horizontal-required'>{t('Common_Data.Date')} {t('Reports.Range')}</label>
            <DateRangeIterationElement form={form} onChange={onChange} />
          </>
        )}
      </Col>
    </Row>


  )
});

export default PeriodSelectionElement;
