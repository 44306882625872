import React from 'react';
import { observer } from 'mobx-react';
import { Image } from 'antd';
import useStore from '../../../../store';
import moment from 'moment';
import { vsmCallAudit, vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const ViewElement = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    ORGANISATION,
    ORGANISATION: { viewOrgValues },
    LEADSSTORE,
  } = useStore();

  const handleCallAudit = (data) => {
    let formdata = {
      id: viewOrgValues?.id,
      phone: data,
    };
    ORGANISATION.callAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.added,
      });
    });
  };

  const handleEmailCallAudit = (data) => {
    let formdata = {
      id: viewOrgValues?.id,
      email: data,
    };
    LEADSSTORE.callEmailAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.emailadded,
      });
    });
  };

  return (
    <>
      <div className='viewPeople'>
        <table cellPadding='0' cellSpacing='0' border='0'>
          <tbody>
            <tr>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
            </tr>
            <tr>
              <th>
                {t('OrganisationManagement.Organiation')} {t('Common_Data.Name')}:
              </th>
              <th>
                {t('OrganisationManagement.Parent')} {t('Common_Data.Organisation')}:
              </th>
              <th>
                {t('OrganisationManagement.Trade')} {t('Common_Data.Name')}:
              </th>
              <td rowSpan='4' className='logo'>
                {viewOrgValues?.logo_url ? (
                  <Image
                    width='100%'
                    height={150}
                    src={process.env.React_APP_API_URL + viewOrgValues?.logo_url}
                    alt='Logo'
                  />
                ) : (
                  <>{t('OrganisationManagement.Logo')}</>
                )}
              </td>
            </tr>
            <tr>
              <td>{viewOrgValues?.organisation_name}</td>
              <td>{viewOrgValues?.parent && viewOrgValues?.parent.organisation_name}</td>
              <td>{viewOrgValues?.trade_name && viewOrgValues?.trade_name}</td>
            </tr>
            <tr>
              <th>
                {t('OrganisationManagement.Registration')} {t('Common_Data.Country')}:
              </th>
              <th>{t('OrganisationManagement.Registration')}:</th>
              <th>
                {t('OrganisationManagement.VAT')} {t('Common_Data.Number')}:
              </th>
            </tr>
            <tr>
              <td>
                {viewOrgValues?.global_countries &&
                  viewOrgValues?.global_countries.country_name}
              </td>
              <td>
                {viewOrgValues?.registration_number && viewOrgValues?.registration_number}
              </td>
              <td>{viewOrgValues?.vat_number && viewOrgValues?.vat_number}</td>
            </tr>
            <tr>
              <th>
                {t('OrganisationManagement.FY')} {t('OrganisationManagement.Start')}{' '}
                {t('OrganisationManagement.Month')}:
              </th>
              <th>
                <span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>{' '}
                :
              </th>
              <th>{t('Common_Data.Status')}:</th>
              <th>
                {t('OrganisationManagement.Exchange_Ref')} {t('Common_Data.ID')}:
              </th>
            </tr>
            <tr>
              <td>{viewOrgValues?.fy_start_month}</td>
              <td>
                {viewOrgValues &&
                  viewOrgValues?.global_industries &&
                  viewOrgValues?.global_industries.name}
              </td>
              <td>{viewOrgValues?.status ? viewOrgValues?.status?.status_name : ''}</td>
              <td>{viewOrgValues?.crm_id}</td>
            </tr>
            <tr>
              <th>
                {t('OrganisationManagement.Tax')} {t('OrganisationManagement.Clearance')}{' '}
                {t('OrganisationManagement.Access')} {t('Common_Data.No')} :
              </th>
              <th>
                {' '}
                <span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/nacecategories-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('OrganisationManagement.NACE')}{' '}
                  {t('OrganisationManagement.Category')} &{' '}
                  {t('OrganisationManagement.Code')}
                </span>{' '}
                :{' '}
              </th>
              <th colSpan='2'>{t('Common_Data.Website')} :</th>
            </tr>
            <tr>
              <td>
                {viewOrgValues?.revenue_access_number &&
                  viewOrgValues?.revenue_access_number}
              </td>
              <td>
                {viewOrgValues?.global_nace_sections &&
                  viewOrgValues?.global_nace_sections?.nace_category?.category_name}{' '}
                <br />
                {viewOrgValues?.global_nace_sections &&
                  viewOrgValues?.global_nace_sections?.code +
                    ' - ' +
                    viewOrgValues?.global_nace_sections?.section}
              </td>
              <td colSpan='2'>{viewOrgValues?.website && viewOrgValues?.website}</td>
            </tr>
            <tr>
              <th colSpan='4'>{t('Common_Data.Description')}:</th>
            </tr>
            <tr>
              <td colSpan='4'>
                {viewOrgValues && viewOrgValues?.description ? (
                  viewOrgValues?.description
                ) : (
                  <div style={{ minHeight: '60px' }}></div>
                )}
              </td>
            </tr>

            {viewOrgValues?.mutiple_addresses &&
              viewOrgValues?.mutiple_addresses.length > 0 && (
                <>
                  {viewOrgValues?.mutiple_addresses.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <th colSpan='2'>
                            {item.address_type
                              ? item.address_type
                              : `${t('OrganisationManagement.Addresses')}`}
                            :
                          </th>
                          <th>{t('Common_Data.City')}:</th>
                          <td>{item.city}</td>
                        </tr>
                        <tr>
                          <td colSpan='2'>{item.address_1}</td>
                          <th>
                            {t('Common_Data.State')}/{t('Common_Data.Country')}:
                          </th>
                          <td>{item.state_county}</td>
                        </tr>
                        <tr>
                          <td colSpan='2'>{item.address_2}</td>
                          <th>{t('Common_Data.Country')}:</th>
                          <td>{item.countries && item.countries.country_name}</td>
                        </tr>
                        <tr>
                          <td colSpan='2'>{item.address_3}</td>
                          <th>{t('Common_Data.Postal_Code')}:</th>
                          <td>{item.postal_code}</td>
                        </tr>
                      </>
                    );
                  })}
                </>
              )}
            {viewOrgValues?.multiple_phone && viewOrgValues?.multiple_phone.length > 0 && (
              <tr>
                <th>{t('Common_Data.Phone')}:</th>
                <td colSpan='3'>
                  <ul className='contactListing'>
                    {viewOrgValues?.multiple_phone.map((item, index) => {
                      return (
                        <>
                          <li key={index}>
                            {`${t(`Common_Data.${item.contact_for}`)}`}:
                            <a
                              href={`tel:${item.contact_value}`}
                              onClick={(e) => {
                                handleCallAudit(item.contact_value);
                              }}
                            >
                              {item.contact_value}
                            </a>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            )}
            {viewOrgValues?.multiple_email && viewOrgValues?.multiple_email.length > 0 && (
              <tr>
                <th>{t('Common_Data.Email')}:</th>
                <td colSpan='3'>
                  <ul className='emailListing'>
                    {viewOrgValues?.multiple_email.map((item, index) => {
                      return (
                        <li key={index}>
                          {`${t(`Common_Data.${item.contact_for}`)}`}:
                          <a
                            href={`mailto:${item.contact_value}`}
                            onClick={(e) => {
                              handleEmailCallAudit(item.contact_value);
                            }}
                          >
                            {item.contact_value}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            )}

            <tr>
              <th>
                {t('Common_Data.Created')} {t('Common_Data.By')}
              </th>
              <td>{viewOrgValues?.created_by_name}</td>
              <th>
                {t('Common_Data.Created')} {t('Common_Data.At')}
              </th>
              <td>
                {viewOrgValues && viewOrgValues?.created_date
                  ? viewOrgValues?.created_time
                    ? `${AUTH.getLocalTime(
                        viewOrgValues?.created_date + ' ' + viewOrgValues?.created_time,AUTH.global_fulldate_format
                      )}`
                    : `${AUTH.getLocalTime(moment(viewOrgValues?.created_date))}`
                  : ''}
              </td>
            </tr>
            <tr>
              <th>{t('Common_Data.Last_Updated_By')}</th>
              <td>{viewOrgValues?.updated_by_name}</td>
              <th>{t('Common_Data.Last_Updated_At')}</th>
              <td>
                {viewOrgValues && viewOrgValues?.updated_date
                  ? viewOrgValues?.updated_time
                    ? `${AUTH.getLocalTime(
                        viewOrgValues?.updated_date + ' ' + viewOrgValues?.updated_time,AUTH.global_fulldate_format
                      )}`
                    : `${AUTH.getLocalTime(moment(viewOrgValues?.updated_date))}`
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* {AUTH.checkPermission(16, 'role-mapping-list') && (
        <RelatedOrganisationList
          id={props.id}
          currentTab={'organiation'}
          waste={props.waste}
          handleViewDetailCall={props.handleViewDetailCall}
        />
      )}
      {AUTH.checkPermission(18, 'role-mapping-list') && (
        <RelatedPeopleList
          id={props.id}
          currentTab={'organiation'}
          waste={props.waste}
          handleViewDetailCall={props.handleViewDetailCall}
        />
      )} */}
    </>
  );
});

export default ViewElement;
