import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Form, Tooltip, Select, Spin } from 'antd';
import useStore from '../../../store';
import debounce from 'lodash/debounce';
import { vsmSnapNote } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DropDownSelection = observer((props) => {
  const { typeValue, onChange } = props;
  const { Option, OptGroup } = Select;
  //const [usersSelected, setUsersSelected] = useState([]);
  const [userListKey, setUserListKey] = useState();

  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation()
  const {
    SPANNOTESTORE: {
      usersSelected,
      fetching,
      user_list_data,
      searchUsersAndGroup,
      setUsersSelected,
    },
  } = useStore();

  useEffect(() => {
    if (typeValue === null || typeValue === undefined) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setUsersSelected([]);
  }, [typeValue, setUsersSelected]);
  // search string for dropdown
  const handleSearch = useMemo(() => {
    const loadOptions = (val) => {
      if (typeValue) {
        //if (typeValue === 'user') {
        setUserListKey(val ? val : '');
        searchUsersAndGroup({ search_text: val ? val : '' });
        //}
      }
    };

    return debounce(loadOptions, 500);
  }, [typeValue, searchUsersAndGroup]);

  const handleChange = (value, name) => {
    setUsersSelected(name);
    onChange();
  };
  const selectedUsers = (
    <ul className='m-0 p-0' style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
      {usersSelected?.length ? (
        usersSelected.map((items, index) => <li key={index}>{items.name}</li>)
      ) : (
        <li key='1'>{t('SnapNoteModule.Not_Users_Selected')}</li>
      )}
    </ul>
  );
  return (
    <Tooltip
      placement='leftTop'
      title={usersSelected.length > 0 ? selectedUsers : null}
      trigger='hover'
    >
      <Form.Item name='userSelect' rules={vsmSnapNote.validation.userSelect}>
        <Select
          name='personName'
          // className='personName'
          type='select'
          mode={typeValue === 'user' ? 'multiple' : ''}
          showArrow
          allowClear={true}
          filterOption={false}
          onChange={handleChange}
          notFoundContent={
            fetching ? (
              <div className='spinInside'>
                <Spin size='small' />
              </div>
            ) : userListKey ? (
              `${t('Common_Data.No_Record_Found')}!`
            ) : null
          }
          onSearch={handleSearch}
          onFocus={() => {
            if (user_list_data?.users && user_list_data?.users.length > 0) {
            } else {
              handleSearch();
            }
          }}
          disabled={disabled}
          placeholder={typeValue === 'user' ? `${t('Common_Data.User')}/${t('Common_Data.User')} ${t('Common_Data.Group')}` : `${t('Common_Data.User')}`}
        >
          {user_list_data?.users && user_list_data?.users.length ? (
            <OptGroup label={`${t('ManageAllDepartments.Users')}`}>
              {user_list_data.users.map((item, index) => (
                <Option
                  value={'u' + item.id}
                  name={item.first_name + ' ' + item.last_name}
                  key={index}
                >
                  <Tooltip title={`${item.first_name} ${item.last_name}`}>
                    {item.first_name} {item.last_name}
                  </Tooltip>
                </Option>
              ))}
            </OptGroup>
          ) : null}

          {typeValue === 'user' &&
            user_list_data?.users_groups &&
            user_list_data?.users_groups.length ? (
            <OptGroup label={`${t('ManageAllDepartments.Users')} ${t('Common_Data.Groups')}`}>
              {user_list_data?.users_groups?.map((item, index) => (
                <Option value={'g' + item.id} name={item.group_name} key={index}>
                  {item.group_name}
                </Option>
              ))}
            </OptGroup>
          ) : null}
        </Select>
      </Form.Item>
    </Tooltip>
  );
});
export default DropDownSelection;
