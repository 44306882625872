import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import debounce from 'lodash/debounce';

import useStore from '../../../store';
import { vsmNotify, vsmContracts } from '../../../config/messages';

import FormComponent from './FormComponent';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { CONTRACTS } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [fileList, updateFileList] = useState([]);

  const close = () => {
    props.close();
    updateFileList([]);
    form.resetFields();
    setSaving(false);
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);

    data.contract_start_date =
      data.contract_start_date && data.contract_start_date.format('Y/MM/D');
    data.contract_end_date =
      data.contract_end_date && data.contract_end_date.format('Y/MM/D');
    data.company_signed_date =
      data.company_signed_date && data.company_signed_date.format('Y/MM/D');
    data.customer_signed_date =
      data.customer_signed_date && data.customer_signed_date.format('Y/MM/D');
    var formData = CONTRACTS.JsonToFormData(data);

    if (
      data.contract_file_path &&
      data.contract_file_path.fileList[0] &&
      data.contract_file_path.fileList.length > 0
    ) {
      formData.append(
        'contract_file_path',
        data.contract_file_path.fileList[0].originFileObj
      );
    } else {
      formData.append('contract_file_path', '');
    }
    CONTRACTS.AddData(formData)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmContracts.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('Contracts.Add_Contract')}`}
      destroyOnClose={true}
      footer={[
        <div className='text-right'>
          <Button
            key='1'
            form='addform'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='medium'
            disabled={isDisabled}
          >
            {t('Common_Data.Save')}
          </Button>
          <Button onClick={close} shape='round' size='medium' key='2'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <FormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        id='addform'
        updateFileList={updateFileList}
        fileList={fileList}
      />
    </Drawer>
  );
});

export default AddComponent;
