import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const RestPasswordComponent = observer((props) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const { USERS, AUTH: { user } } = useStore();
  const { t } = useTranslation()
  const handleSubmit = () => {
    setSaving(true);
    var payload = {
      email: USERS.resetValues.email,
    };
    USERS.resetPasswordCall(payload)
      .then(() => {
        USERS.getList({}, user?.user_role?.user_role_id === 1 ? true : false);
        props.close();
        vsmNotify.success({
          message: vsmUsers.resetEmailUser,
        });
        form.resetFields();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Reset')} ${t('Users.Password')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='resetpassword'
          loading={saving}
          htmlType='submit'
          type='primary'
        >
          {t('Common_Data.Reset')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='resetpassword' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col className='DeleteText'>
              {t('Users.Reset_Password')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  );
});

export default RestPasswordComponent;
