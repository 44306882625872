import React, { useState } from 'react';
import { observer } from 'mobx-react';
import DisplayScheduleList from '../component/DisplayScheduleList';
import ScheduleLibraryView from '../../RightSideComponent/Schedule/component/ScheduleLibraryView';
//import data from '../elements/data.json';
import useStore from '../../../../store';
import { Button, Dropdown, Spin } from 'antd';
import DeleteComponent from './DeleteComponent';
import { vsmNotify } from '../../../../config/messages';
// import ScheduleContentPost from '../../RightSideComponent/Schedule/component/ScheduleContentPost';
import ScheduleContentPostNew from '../../RightSideComponent/NewSchedule/component/ScheduleContentPost';
import ReactDOMServer from 'react-dom/server';
import ConfirmDraftSSSEdit from '../component/ConfirmDraftSSSEdit';
import { useTranslation } from 'react-i18next';

const Schedule = observer((props) => {
  const { t } = useTranslation()
  const [copySelectionVisible, setCopySelectionVisible] = useState(false);

  // const [curretSchedule, setCurretSchedule] = useState(null)
  const {
    DEALDESKSTORE: {
      // deal_data,
      setDealdeskActiveTab,
      setEditActiveScheduleData,
      editActiveScheduleData,
      activeScheduleData,
      setCopiedActiveScheduleData,
      setEditActiveStructureData,
      setStructureSchedulePostData,
      setEditActiveScreenData,
      removeDraftContentSSS,
      setEditActiveScrutiniseData,
    },
    SSSLEADLIBRARYSTORE: {
      // fetchListData,
      sss_list,
      addUpdateNoteList,
      setDealDeskRightSectionActiveTab,
      dealdeskSecLoader,
    },
    DEALDESKSTRUCTURELIBRARY: {
      savestructureform,
      SetStructure,
      SetScreen,
      SetScrunitise,
      setCopiedStructureData,
      setCopiedScreenData,
      setCopiedScrunitiseData,
    },
  } = useStore();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);
  const [visibleScheduleLibraryDrawer, setViewDrawer] = useState(false);

  //------------ Index Used for Active Schedule Edit Auto Save ----------  //
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();

  //------------ Used for Confirm Schedule Edit Auto Save ----------  //
  const [ConfirmScheduleVersion, setConfirmScheduleVersion] = useState();

  const openViewDrawer = (data) => {
    setScheduleData(data);
    setViewDrawer(true);
  };
  const closeScheduleLibraryDrawer = () => setViewDrawer(false);

  // useEffect(() => {
  //   fetchListData(deal_data?.lead_information?.id, false, { type: 2 });
  // }, [fetchListData, deal_data]);
  //close delete
  const closeDeleteDrawer = () => setDeleteConfirm(false);

  const copyMenuOverlay = (
    <div className='ant-popover-inner-content multipleTag'>
      <div className='leadStatusOptins lockUserList createNotetoTicket'>
        <ul>
          <li>
            <ul className='dealDeskCopySelection'>
              <li
                onClick={() => {
                  if (activeScheduleData) {
                    setEditActiveScrutiniseData(null);
                    setDealdeskActiveTab('7');
                    SetScrunitise(activeScheduleData.description);
                    setCopiedScrunitiseData(activeScheduleData.description);
                    setDealDeskRightSectionActiveTab('scrutinise');
                    if (savestructureform) {
                      savestructureform.setFieldsValue({
                        structure_title: activeScheduleData.title,
                      });
                    }
                  }
                }}
              >
                {t('DealDesk.To_Scrutinise')}
              </li>
              <li
                onClick={() => {
                  if (activeScheduleData) {
                    setEditActiveScreenData(null);
                    setDealdeskActiveTab('6');
                    SetScreen(activeScheduleData.description);
                    setCopiedScreenData(activeScheduleData.description);
                    setDealDeskRightSectionActiveTab('screen');
                    if (savestructureform) {
                      savestructureform.setFieldsValue({
                        structure_title: activeScheduleData.title,
                      });
                    }
                  }
                }}
              >
                {t('DealDesk.To_Screen')}
              </li>
              <li
                onClick={() => {
                  if (activeScheduleData) {
                    setEditActiveStructureData(null);
                    setDealdeskActiveTab('1');
                    setDealDeskRightSectionActiveTab('structure');
                    SetStructure(activeScheduleData.description);
                    setCopiedStructureData(activeScheduleData.description);

                    if (savestructureform) {
                      savestructureform.setFieldsValue({
                        structure_title: activeScheduleData.title,
                      });
                    }
                  }
                }}
              >
                {t('DealDesk.To_Structure')}
              </li>
              <li
                onClick={() => {
                  if (activeScheduleData) {
                    setDealdeskActiveTab('2');
                    setEditActiveScheduleData(null);
                    setCopiedActiveScheduleData(activeScheduleData);
                  }
                }}
              >
                {t('DealDesk.To_Schedule')}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const setEditScheduleData = (loadDraft = false) => {
    if (loadDraft) {
      let draftData = activeScheduleData;
      let currentSelectedData = sss_list[currentSelectedIndex];
      if (sss_list[currentSelectedIndex]) {
        draftData.description = JSON.parse(currentSelectedData["draft_description"]);
        draftData.isEditAutoSaveData = true;
      }
      setEditActiveScheduleData(draftData);
    }
    else {
      if (activeScheduleData.draft_description) {
        removeDraftContentSSS(activeScheduleData).then((data) => {
          addUpdateNoteList(data.data, true);
          setEditActiveScheduleData(data.data);
        })
      }
      else {
        setEditActiveScheduleData(activeScheduleData);
      }
    }
  }

  const onEditSchedule = () => {
    let selectedIndex = sss_list.findIndex(x => x.id === activeScheduleData.id && x.type === 2);
    setCurrentSelectedIndex(selectedIndex)
    if (activeScheduleData) {
      setDealdeskActiveTab('2');
      if (selectedIndex !== -1) {
        let currentSelectedObj = sss_list[selectedIndex];
        if (currentSelectedObj.hasOwnProperty('draft_description') && currentSelectedObj?.draft_description) {
          setConfirmScheduleVersion(true);
        }
        else {
          setEditScheduleData();
        }
      }
      setCopiedActiveScheduleData(null);
    }
  }

  return (
    <>
      <div className='structureRight'>
        <Spin
          size='large'
          spinning={dealdeskSecLoader}
          style={{
            textAlign: 'center',
            padding: '50px 30px',
          }}
        >
          <DisplayScheduleList
            openViewDrawer={openViewDrawer}
            listData={sss_list || []}
          />
        </Spin>
        <ScheduleLibraryView
          visibleScheduleLibraryDrawer={visibleScheduleLibraryDrawer}
          closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
          scheduleData={scheduleData}
        />
        <div className='tabFooter displayTabs'>
          <div className={`btnSectionBox`}>
            <Button
              shape='round'
              size='medium'
              disabled={!activeScheduleData}
              onClick={() => {
                onEditSchedule();
              }}
            >
              {t('Common_Data.Edit')}
            </Button>
            <Button
              shape='round'
              size='medium'
              disabled={!activeScheduleData}
              onClick={() => {
                if (activeScheduleData) {
                  if (
                    editActiveScheduleData &&
                    activeScheduleData.id === editActiveScheduleData.id
                  ) {
                    vsmNotify.error({
                      message: `${t('DealDesk.Edit_Schedule')}!`,
                    });
                  } else {
                    setDeleteConfirm(true);
                  }
                }
              }}
            >
              {t('Common_Data.Delete')}
            </Button>

            <Dropdown
              overlay={copyMenuOverlay}
              overlayClassName='lockContentMenu'
              placement='topCenter'
              trigger='click'
              visible={copySelectionVisible}
              onVisibleChange={(x) => {
                setCopySelectionVisible(x);
              }}
              disabled={!activeScheduleData}
            >
              <Button shape='round' size='medium'>
                {t('DealDesk.Copy')}
              </Button>
            </Dropdown>
          </div>
          <div>
            <Button
              shape='round'
              size='medium'
              disabled={!activeScheduleData}
              onClick={() => {
                if (activeScheduleData) {
                  setDealdeskActiveTab('5');

                  setStructureSchedulePostData(
                    ReactDOMServer.renderToString(
                      <>
                        <ScheduleContentPostNew
                          Schedule={activeScheduleData.description}
                        />
                      </>
                    )
                  );
                }
              }}
            >
              {t('DealDesk.Post')}
            </Button>
          </div>
        </div>
      </div>
      <DeleteComponent visible={deleteConfirm} close={closeDeleteDrawer} />

      <ConfirmDraftSSSEdit
        visible={ConfirmScheduleVersion}
        sssName="Schedule"
        setDraftNoteEditConfirmModal={setConfirmScheduleVersion}
        setEditNoteData={setEditScheduleData}
      />
    </>
  );
});

export default Schedule;
