import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { convertTextToID } from '../../utils/GlobalFunction';
import { reset, clear } from '../../utils/ResetClearButton';

export default class OrganisationStore {
  list_data = null;
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;

  editValues = null;
  deleteValues = null;
  organisationValues = null;
  rOrganisationValues = null;
  agGrid = null;
  viewOrgValues = null;

  dropdown_org_list = null;
  dropdown_ROrg_list = null;
  dropdown_countries_list = null;
  dropdown_orgRole_list = null;
  dropdown_ledger_org_list = null;
  dropdown_global_org = null;
  industries_list = null;
  naceCode_list = null;
  rating_map_list = null;
  dropdown_ROrg_name_report = null;
  gridLoading = false;
  isMergePeople = false;

  stateCountyList = null;
  cityList = null;
  fetching = false;
  fetchingReportName = false;
  exportLoadingIcn = false;
  waste = false;
  search_payload = {};

  viewPeopleLoading = false;
  editPeopleLoading = false;

  month_list = [
    { id: 0, month: 'January' },
    { id: 1, month: 'February' },
    { id: 2, month: 'March' },
    { id: 3, month: 'April' },
    { id: 4, month: 'May' },
    { id: 5, month: 'June' },
    { id: 6, month: 'July' },
    { id: 7, month: 'August' },
    { id: 8, month: 'September' },
    { id: 9, month: 'October' },
    { id: 10, month: 'November' },
    { id: 11, month: 'December' },
  ];

  adddress_type = [
    { value: 'Primary', name: 'Primary' },
    { value: 'Home', name: 'Home' },
    { value: 'Office', name: 'Office' },
    { value: 'Other', name: 'Other' },
  ];

  initialValues = {
    phone: [{ contact_for: 'Primary' }],
    mobile: [null],
    email: [{ contact_for: 'Primary' }],
    adddress: [],
  };

  filter_AssociatedLedger = [];
  filter_CreatedByLedger = [];
  filter_Status = [];
  filter_Country = [];

  selectedGridFilter = null; //Used For Saved Grid Filters To Apply -

  masterOrgToMerge = null;

  //-----------------Lead Connection List-----------------------------//
  fetchingConnection = false;
  dropdown_ROrg_connection_list = null;

  //-----------------isSavedFiltersLoadedPeople USED TO CHECK FILTER LOADED-----------------------------//
  isSavedFiltersLoadedOrg = false; //

  getAssociatedLedgerFilter = () => {
    return Axios.get(`organisations/filter/associated-by-ledger/get`)
      .then((data) => {
        this.filter_AssociatedLedger = data.data.data;
        return data.data;
      })
      .catch((data) => {
        Promise.reject(data);
      });
  };

  getCreatedByLedgerFilter = () => {
    return Axios.get(`organisations/filter/created-by-ledger/get`)
      .then((data) => {
        this.filter_CreatedByLedger = data.data.data;
        return data.data;
      })
      .catch((data) => {
        Promise.reject(data);
      });
  };

  getStatusFilter = () => {
    return Axios.get(`organisations/filter/status/get`)
      .then((data) => {
        this.filter_Status = data.data.data;
        return data.data;
      })
      .catch((data) => {
        Promise.reject(data);
      });
  };

  getCountryFilter = () => {
    return Axios.get(`organisations/get/countries`)
      .then((data) => {
        this.filter_Country = data.data.data;
        return data.data;
      })
      .catch((data) => {
        Promise.reject(data);
      });
  };

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.ORGANISATION) {
        params = { request: temp.ORGANISATION };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          ORGANISATION: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          ORGANISATION: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Setup grid and set column size to autosize
  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_org_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;

    let columnConfig = localStorage.getItem('org_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }

    var datasource = this.createDatasource(GridConfig.options);
    api.setServerSideDatasource(datasource);
    let param = this.getFilter(params);
    if (param && param.request) {
      this.agGrid.api.setFilterModel(param.request.filter);
      this.agGrid.api.setSortModel(param.request.sort);
    }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = {
          final_filter: params.request.filterModel,
          final_sort: params.request.sortModel,
        };
        if (filter_data.final_filter['associated_ledgers.name']) {
          filter_data.final_filter['associated_ledgers.name'].values = convertTextToID(
            filter_data.final_filter['associated_ledgers.name'],
            this.filter_AssociatedLedger,
            'name',
            'id'
          );
        }
        if (filter_data.final_filter['related_ledgers.name']) {
          filter_data.final_filter['related_ledgers.name'].values = convertTextToID(
            filter_data.final_filter['related_ledgers.name'],
            this.filter_CreatedByLedger,
            'name',
            'id'
          );
        }
        if (filter_data.final_filter['status.status_name']) {
          filter_data.final_filter['status.status_name'].values = convertTextToID(
            filter_data.final_filter['status.status_name'],
            this.filter_Status,
            'status_name',
            'id'
          );
        }
        if (filter_data.final_filter['global_countries.country_name']) {
          filter_data.final_filter['global_countries.country_name'].values =
            convertTextToID(
              filter_data.final_filter['global_countries.country_name'],
              this.filter_Country,
              'country_name',
              'id'
            );
        }
        if (filter_data.final_filter['global_industries.name']) {
          filter_data.final_filter['global_industries.name'].values = convertTextToID(
            filter_data.final_filter['global_industries.name'],
            this.industries_list,
            'name',
            'id'
          );
        }
        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          // filter_data: this.search_payload?.hasOwnProperty('search')
          //   ? {}
          //   : params.request.filterModel,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };

        let columnConfig = localStorage.getItem('org_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        // let merged = { ...payload, ...this.search_payload };
        let merged = { ...payload};
        this.setFilter(params.api);
        this.getList(merged).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('org_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  getRatingToMap = () => {
    return Axios.get(`ledger/role/mapping/ratingtomap`).then(({ data }) => {
      this.rating_map_list = data;
      return data;
    });
  };

  // call api to get all parent organisations list
  getMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      this.dropdown_global_org = data.data;
      return data;
    });
  };

  // call api to get all organisations list
  getAllOrg = (payload) => {
    this.dropdown_org_list = null;
    if (
      (payload.organisation_name && payload.organisation_name.length >= 1) ||
      payload.selected_id
    ) {
      this.fetching = true;
      return Axios.post(`organisations/get`, payload)
        .then(({ data }) => {
          this.dropdown_org_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  // call api to get all organisations list
  getAllROrg = (payload) => {
    this.dropdown_ROrg_list = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetching = true;
      return Axios.post(`organisations/get`, payload)
        .then(({ data }) => {
          this.dropdown_ROrg_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  getAllROrgNameListForReport = (payload) => {
    this.dropdown_ROrg_name_report = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetchingReportName = true;
      return Axios.post(`organisations/get`, payload)
        .then(({ data }) => {
          this.dropdown_ROrg_name_report = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingReportName = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  // call api to get all organisations list
  getAllROrgSOLDConnections = (payload) => {
    this.dropdown_ROrg_connection_list = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetchingConnection = true;
      return Axios.post(`organisations/get`, payload)
        .then(({ data }) => {
          this.dropdown_ROrg_connection_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingConnection = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  // call api to list organisations without current ledger
  getOrgCurrentLedgerMap = (payload) => {
    this.dropdown_ledger_org_list = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetching = true;
      return Axios.post(`organisations/current-ledger/get`, payload)
        .then(({ data }) => {
          this.dropdown_ledger_org_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  // set org values to null
  setOrgListNull = () => {
    this.dropdown_ledger_org_list = null;
    this.dropdown_org_list = null;
  };

  // call for city list to auto populate
  getCityList = () => {
    return Axios.get(`global/addresses/city/get`)
      .then(({ data }) => {
        var i;
        var arrayObj = data.data;
        for (i = 0; i < arrayObj.length; i++) {
          arrayObj[i].value = arrayObj[i]['city'];
          delete arrayObj[i].key1;
        }
        this.cityList = arrayObj;
        return data;
      })
      .catch(() => {
        this.cityList = null;
      });
  };

  // call for bank name list to auto populate
  getStateCountyList = () => {
    return Axios.get(`global/addresses/state/get`)
      .then(({ data }) => {
        var i;
        var arrayObj = data.data;
        for (i = 0; i < arrayObj.length; i++) {
          arrayObj[i].value = arrayObj[i]['state_county'];
          delete arrayObj[i].key1;
        }
        this.stateCountyList = arrayObj;
        return data;
      })
      .catch(() => {
        this.stateCountyList = null;
      });
  };

  // call api to get organisations roles list
  getOrgRoles = (payload) => {
    return Axios.post(`organisations/role/get`, payload).then(({ data }) => {
      this.dropdown_orgRole_list = data.data;
      return data;
    });
  };

  // call api to get organisations dependent related roles list
  getOrgRelatedRoles = (payload) => {
    return Axios.post(`organisations/role/get`, payload).then(({ data }) => {
      this.dropdown_RelatedRoles = data.data;
      return data;
    });
  };

  // call api to get all countries list
  getAllCountries = () => {
    if (!this.dropdown_countries_list) {
      return Axios.get(`organisations/get/countries`).then(({ data }) => {
        this.dropdown_countries_list = data.data;
        return data;
      });
    } else {
      return Promise.resolve();
    }
  };

  // get organisation record details
  getRecordDetail = (data, setVariable = true) => {
    return Axios.get(`organisations/read/${data.organisation_id}`)
      .then(({ data }) => {
        if (setVariable) {
          this.organisationValues = data.data;
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        this.organisationValues = null;
        return Promise.reject(data);
      });
  };

  getOrgCardValue = (id, type) => {
    return Axios.post(`global/card/read/${id}`, { entity_type: 1 })
      .then(({ data }) => {
        if (type === 'relatedOrg') {
          this.rOrganisationValues = data.data;
        } else if (type === 'org') {
          this.organisationValues = data.data;
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  // get organisation record details
  getViewOrgDetail = (data, lead_transaction_id) => {
    let payload = { waste: this.waste };
    if (lead_transaction_id) {
      payload.lead_transaction_id = lead_transaction_id;
    }
    return Axios.post(`organisations/organisations/view/details/${data}`, payload)
      .then(({ data }) => {
        this.organisationValues = data.data;
        return data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  // get related organisation record details
  getROrgRecordDetail = (data, setVariable = true) => {
    return Axios.get(`organisations/read/${data.organisation_id}`)
      .then(({ data }) => {
        if (setVariable) {
          this.rOrganisationValues = data.data;
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        this.rOrganisationValues = null;
        return Promise.reject(data);
      });
  };

  // get Industries list
  getIndustries = () => {
    return Axios.get(`organisations/global/industries`).then(({ data }) => {
      this.industries_list = data.data;
      return data;
    });
  };

  // get NACE code list
  getNaceCode = () => {
    return Axios.get(`organisations/global/nace-sections`).then(({ data }) => {
      this.naceCode_list = data.data;
      return data;
    });
  };

  // set organisation values
  setOrganisationValues = (data) => {
    this.organisationValues = data;
  };

  // set related organisation values
  setROrganisationValues = (data) => {
    this.rOrganisationValues = data;
  };

  // call api to get records
  getList = (payload = {}) => {
    return Axios.post(`organisations/list`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.srno = index + 1;
          item.mobile = item.phone ? item.mobile + ', ' + item.phone : item.mobile;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };
  // call api to get records
  getListSearch = (payload = {}) => {
    this.gridLoading = true;
    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.OrgSearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post(`organisations/list`, payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.srno = index + 1;
          item.mobile = item.phone ? item.mobile + ', ' + item.phone : item.mobile;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      this.agGrid.api.setSortModel(sort ? sort : temp?.OrgSearchStore?.sort);

      if(this.list_data?.length === 0){
        this.agGrid.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  // call api to import organisations file
  ImportOrgFile = (formdata) => {
    const options = {
      headers: { Accept: '' },
    };
    return Axios.post(`organisations/import`, formdata, options)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // call api to import organisations roles file
  ImportOrgRolesFile = (formdata) => {
    const options = {
      headers: { Accept: '' },
    };
    return Axios.post(`organisations/role-bank-account/import`, formdata, options)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // export logo as pdf
  exportLogoPdf = (payload) => {
    this.exportLogoLoading = true;
    return Axios.post(`organisations/organisations/logo-pdf/export`, payload)
      .then(({ data }) => {
        if (data.data && data.data.pdf_link) {
          window.open(data.data.pdf_link, '_blank');
        }
        this.exportLogoLoading = false;
        return data;
      })
      .catch(() => {
        this.exportLogoLoading = false;
        return Promise.reject([]);
      });
  };

  // export organisation data as a csv
  exportData = () => {
    this.exportLoadingIcn = true;
    return Axios.get(`organisations/organisations/export`)
      .then(({ data }) => {
        if (data.data && data.data.csv_link) {
          window.location.href = data.data.csv_link;
        }
        this.exportLoadingIcn = false;
        return data;
      })
      .catch(() => {
        this.exportLoadingIcn = false;
        return Promise.reject([]);
      });
  };

  // export organisation roles data as a csv
  exportOrganisationRolesData = () => {
    this.exportLoadingIcn = true;
    return Axios.get(`organisations/role-bank-account/export`)
      .then(({ data }) => {
        if (data.data && data.data.csv_link) {
          window.location.href = data.data.csv_link;
        }
        this.exportLoadingIcn = false;
        return data;
      })
      .catch(() => {
        this.exportLoadingIcn = false;
        return Promise.reject([]);
      });
  };

  // Call map api
  MapData = (formdata) => {
    return Axios.post(`ledger/role/mapping/create`, formdata)
      .then(({ data }) => {
        this.setupGrid(this.agGrid);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call add api
  AddData = (formdata) => {
    return Axios.post(`organisations/add`, formdata)
      .then(({ data }) => {
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  EditData = (formdata, id, SearchPageEdit) => {
    return Axios.post(`organisations/edit/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
          if (SearchPageEdit) {
            this.getListSearch(this.search_payload);
          }
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  CloneData = (formdata, id) => {
    return Axios.post(`organisations/add`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    formdata.waste = this.waste;
    return Axios.post(`organisations/delete/${formdata.id}`, formdata)
      .then(({ data }) => {
        // this.setupGrid(this.agGrid);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  RestoreData = (org_id) => {
    return Axios.get(`organisations/restore/${org_id}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== 'logo_url') {
        if (key === 'add_address') {
          formData.append(key, data[key] ? 1 : 0);
        } else if (key === 'transfer_in') {
          formData.append(key, data[key] ? 1 : 0);
        } else {
          data[key] && formData.append(key, data[key]);
        }
      }
    });
    return formData;
  };

  // reset all the server side filters : DEFAULT-R
  // handleReset = () => {
  //   localStorage.removeItem('org_grid');
  //   this.selectedGridFilter = null;
  //   this.agGrid.api.setFilterModel(null);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  // reset all the server side filters
  // Reset : Will reset all filters, sorting, saved columns settings.
  handleReset = () => {
    reset(this.agGrid, "org_grid", this.setSelectedGridFilter);
  }

  applyFilterDefault = () => {
    clear(this.agGrid)
  };

  // Set column width after resizing colums
  onGridChangedSearch = (params) => {
    localStorage.setItem('search_org_grid', JSON.stringify(params.columnApi.getColumnState()));
  };
  onGridChanged = (params) => {
    localStorage.setItem('org_grid', JSON.stringify(params.columnApi.getColumnState()));
  };

  getLeadTransactionID = (id = 0) => {
    return Axios.post(`create-lead-from-organisation/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          ORGANISATION: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  //Used On Waste Tab Change
  resetGridFilterForWaste = () => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));

      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          ORGANISATION: {
            filter: {},
          },
        })
      );

      this.agGrid.api.setFilterModel({});
    }
  };

  setMasterOrgToMerge = (data) => {
    this.masterOrgToMerge = data;
  };

  //------USED FOR ORGANISATION LIST  - MERGE MULTIPLE ORG INTO SINGLE ORG ------------//
  handleMergeOrganisation = (payload) => {
    return Axios.post(`organisations/merge-organisation`, payload)
      .then(({ data }) => {
        this.isMergePeople = true;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  // Call Audit
  callAudit = (payload) => {
    return Axios.post(`leads-transactions/call-audit/GlobalOrganisation`, payload).then(
      ({ data }) => {
        return data;
      }
    );
  };

  setIsSavedFiltersLoadedOrg = (data) => {
    this.isSavedFiltersLoadedOrg = data;
  };

  /** This is called on clear button :: DEFAULT-R */
  //   applyFilterDefault = () => {
  //     this.selectedGridFilter = null;
  //     if (this.agGrid) {
  //       // localStorage.removeItem('org_grid');
  //       this.selectedGridFilter = null;
  //       this.agGrid.api.setFilterModel(null);
  //       this.agGrid.api.setSortModel(null);
  //       // this.agGrid.columnApi.resetColumnState();
  //       this.agGrid.api.onFilterChanged(null);
  //     }
  //   };

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
}


decorate(OrganisationStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  editValues: observable,
  deleteValues: observable,
  rating_map_list: observable,
  dropdown_orgRole_list: observable,
  dropdown_ROrg_list: observable,
  dropdown_countries_list: observable,
  dropdown_org_list: observable,
  dropdown_ledger_org_list: observable,
  agGrid: observable,
  month_list: observable,
  organisationValues: observable,
  rOrganisationValues: observable,
  viewOrgValues: observable,
  stateCountyList: observable,
  adddress_type: observable,
  cityList: observable,
  fetching: observable,
  dropdown_global_org: observable,
  exportLoadingIcn: observable,
  exportLogoLoading: observable,
  dropdown_RelatedRoles: observable,
  industries_list: observable,
  initialValues: observable,
  naceCode_list: observable,
  filter_AssociatedLedger: observable,
  filter_CreatedByLedger: observable,
  filter_Status: observable,
  filter_Country: observable,
  search_payload: observable,
  waste: observable,
  viewPeopleLoading: observable,
  editPeopleLoading: observable,
  selectedGridFilter: observable,
  masterOrgToMerge: observable,
  fetchingConnection: observable,
  dropdown_ROrg_connection_list: observable,
  isSavedFiltersLoadedOrg: observable,
  dropdown_ROrg_name_report: observable,
  fetchingReportName: observable,
  gridLoading: observable,
  isMergePeople:observable,

  getNaceCode: action,
  getIndustries: action,
  getOrgRoles: action,
  getAllCountries: action,
  getList: action,
  getAllOrg: action,
  getAllROrg: action,
  getOrgCurrentLedgerMap: action,
  getRecordDetail: action,
  getROrgRecordDetail: action,
  getMappedOrg: action,
  EditData: action,
  CloneData: action,
  setEditValues: action,
  setDeleteValues: action,
  setOrganisationValues: action,
  setROrganisationValues: action,
  DeleteData: action,
  setupGrid: action,
  setPageSize: action,
  ImportOrgFile: action,
  exportData: action,
  getStateCountyList: action,
  getCityList: action,
  exportOrganisationRolesData: action,
  setOrgListNull: action,
  ImportOrgRolesFile: action,
  JsonToFormData: action,
  getOrgRelatedRoles: action,
  exportLogoPdf: action,
  getAssociatedLedgerFilter: action,
  getCountryFilter: action,
  getCreatedByLedgerFilter: action,
  getStatusFilter: action,
  getViewOrgDetail: action,
  getOrgCardValue: action,
  getRatingToMap: action,
  onGridChanged: action,
  handleReset: action,
  RestoreData: action,
  getLeadTransactionID: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  resetGridFilterForWaste: action,
  setMasterOrgToMerge: action,
  getAllROrgSOLDConnections: action,
  handleMergeOrganisation: action,
  callAudit: action,
  setIsSavedFiltersLoadedOrg: action,
  applyFilterDefault: action,
  getAllROrgNameListForReport: action,
  onGridChangedSearch: action,
  setupGridSearch: action,
  getListSearch: action,
});
