import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Select, Tooltip, Card, Typography, Row, Col } from 'antd'
import { InfoCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import { vsmNotify, vsmRoleMapping } from '../../../../config/messages'
import useStore from '../../../../store'
import { useTranslation } from 'react-i18next'

const AddBankComponent = observer((props) => {
	const { t } = useTranslation();
	const [form] = Form.useForm()
	const { OrganisationStore, OrgBankAccountStore, RoleMappingStore } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [selectedFields, setSelectedFields] = useState([null])

	var field_list = OrgBankAccountStore.dropdown_all_bank_list

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.organisation_id = OrganisationStore.organisationValues.id
		data.role_map_id = RoleMappingStore.editValues.id
		data.map_banks = data.map_banks.filter(x => (x) ? true : false)
		if (data.map_banks.length <= 0) {
			data.map_banks = null
		}
		RoleMappingStore.MapBank(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmRoleMapping.mapBank
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = () => {
		setSelectedFields(form.getFieldValue('map_banks'))
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setSelectedFields([null])
	}

	useEffect(() => {
		if (RoleMappingStore.editValues && props.visible) {
			if (RoleMappingStore.editValues.mapped_bank_accounts) {
				var banks = RoleMappingStore.editValues.mapped_bank_accounts
				if (banks.length <= 0) {
					banks = [null]
				}
				form.setFieldsValue({ map_banks: banks })
				setSelectedFields(banks)
			}
		}
	}, [RoleMappingStore.editValues, setSelectedFields, form, props.visible])

	return (
		<Modal
			centered
			title={`${t('Common_Data.Add')} ${t('OrganisationManagement.Bank_Account')} ${t('Common_Data.For')} ${t('OrganisationManagement.Role')} ${t('Common_Data.Mapping')} - ${OrganisationStore.organisationValues && OrganisationStore.organisationValues.organisation_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addbankform' loading={saving} htmlType="submit" type="primary">{t('OrganisationManagement.Map')} {t('Common_Data.Bank_Accounts')}</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>{t('Common_Data.Cancel')}</Button>
			]}
		>
			<Form form={form} id="addbankform" onFinish={handleSubmit}
				onChange={handleChange}
			>
				<Card hoverable={false}>
					{
						field_list && field_list.length <= 0 ? (
							<>
								<Typography.Text>{t('OrganisationManagement.Add_Bank_Accounts')}.</Typography.Text>
							</>
						) : (
							<>
								<Typography.Text>{t('OrganisationManagement.Select_Account_To_Associate')}</Typography.Text>
								<Tooltip title={`${t('OrganisationManagement.Individial_Role_Mapping')}`}>
									<InfoCircleOutlined className="ml-10" />
								</Tooltip>
								<div className="w-100 mt-20">
									<Form.List name='map_banks'>
										{
											(fields, { add, remove }) => {

												return fields.map((field, index) => {
													return (
														<div className="w-100" key={field.key}>
															<div>
																<Row gutter={16}>
																	<Col span={(field_list && (field_list.length > 1)) ? 19 : 24}>
																		<Form.Item name={[field.name]} >
																			<Select allowClear onChange={handleChange} showSearch className="w-100" placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Bank_Account')}`} optionFilterProp="children" key={[field.key, 'map_banks']}
																				filterOption={(input, option) =>
																					option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																				}
																			>
																				{
																					(field_list && field_list.map((item, key = { index }) => (
																						!selectedFields.includes(item.id) || (selectedFields[index] && item.id === selectedFields[index])
																					) ? (
																						<Select.Option key={key = { index }} value={item.id}>{item.banks ? item.banks.name + " - " + (item.account_number ? item.account_number : item.iban) : null}</Select.Option>
																					) : null
																					))
																				}
																			</Select>
																		</Form.Item>
																	</Col>
																	{
																		(field_list && field_list.length > 1) && (
																			<Col span={5} className="d-flex justify-space-between">
																				{
																					(fields.length > 1) && (<Button type="danger" onClick={() => {
																						remove(field.name)
																						handleChange()
																					}} icon={<MinusOutlined />}></Button>)
																				}

																				{(index === (fields.length - 1)) && (field_list.length > fields.length) && (
																					<Button type="primary" onClick={() => add()} icon={<PlusOutlined />}></Button>
																				)}
																			</Col>
																		)
																	}

																</Row>
															</div>
														</div>
													)
												})
											}
										}
									</Form.List >
								</div>
							</>
						)
					}
				</Card>
			</Form>
		</Modal>
	)
})

export default AddBankComponent
