import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Col, Spin } from 'antd';
import InputComponent from '../../../../component/InputComponent';

import OrganisationElements from './OrganisationElements';
import PersonElements from './PersonElements';
import useStore from '../../../../store';
import { vsmTransactionReport } from '../../../../config/messages';
import SoldStageSelectionElement from './SoldStageSelectionElement';
import TimesTrackElement from './TimesTrackElement';
import NaceCategoryElement from './NaceCategoryElement';
import ParentOrgRowElements from './ParentOrgRowElements';
import TurnoverElement from './TurnoverElement';
import { useTranslation } from 'react-i18next';

const SOLDReportCriteria = observer(({ form, handleChange, formDataList }) => {
  const [fetchClassification, setFetchClassification] = useState(true);
  const [fetchProductList, setFetchProductList] = useState(true);
  const [fetchCurrencyList, setFetchCurrencyList] = useState(true);
  const [fetchSoldSourceList, setFetchSoldSourceList] = useState(true);
  const { t } = useTranslation();
  //----------GLOBAL STATE DECLARATION---------------//
  const {
    REPORTSTORE: {
      classification_role_list,
      getClassificationList,
      getProductList,
      product_list
    },
    LEADSSTORE,
    LEADSSTORE :{
      getSoldSourceList
    },
  } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  return (
    <>
      <Col span={12}>
        {formDataList.includes('opportunity_name') && (
          <InputComponent
            name='opportunity_name'
            label={t('Common_Data.SOLD_Name')}
            placeholder={t('Common_Data.SOLD_Name')}
            type='text'
            tooltip={`${t('LeadListing.Three_Words')}.`}
            onChange={handleChange}
            maxLength={100}
          />
        )}
      </Col>
      <Col span={12}>
        {formDataList.includes('classification_id') && (
          <InputComponent
            name='classification_id'
            placeholder={`${t('PeopleManagement.All')}`}
            label={
              <span
                className='converbillLink'
                onClick={() => {
                  window.open(
                    'https://www.convertibill.com/credebtclassifications-popup.html',
                    '_blank',
                    'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                  );
                }}
              >
                {t('Credebt_Classification.Credebt')}®{t('Credebt_Classification.Classification')}
              </span>
            }
            type='select'
            mode='multiple'
            allowClear
            showArrow
            showSearch
            onChange={handleChange}
            options={{
              values: classification_role_list,
              value_key: 'id',
              text_key: 'name',
            }}
            onFocus={() =>
              fetchClassification &&
              getClassificationList().then(() => setFetchClassification(false))
            }
            notFoundContent={
              fetchClassification ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            tooltip={`${t('Common_Data.Classification_Group_Tooltip')}.`}
            rules={vsmTransactionReport.validation.classification_id}
          />
        )}
      </Col>

      <SoldStageSelectionElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />

      <Col span={6}>
        {formDataList.includes('product_name') && (
          <InputComponent
            name='product_name'
            label={`${t('Common_Data.Sold')} ${t('Reports.Product')}/${t('Reports.Service')}`}
            placeholder={`${t('PeopleManagement.All')}`}
            type='select'
            mode='multiple'
            onChange={handleChange}
            allowClear
            showArrow
            showSearch
            tooltip={`${t('Reports.Choose_To_Product_Created')}.`}
            rules={vsmTransactionReport.validation.product_name}
            onFocus={() =>
              fetchProductList && getProductList().then(() => setFetchProductList(false))
            }
            notFoundContent={
              fetchProductList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            options={{
              values: product_list,
              value_key: 'product_name',
              text_key: 'product_name',
            }}
          />
        )}
      </Col>
      <Col span={6}>
        {formDataList.includes('lead_currency_id') && (
          <InputComponent
            name='lead_currency_id'
            label={`${t('Common_Data.Currency')}`}
            allowClear
            showArrow
            showSearch
            mode='multiple'
            placeholder={`${t('Common_Data.Currency')}`}
            type='select'
            tooltip={`${t('Common_Data.Currency_Tooltip')}.`}
            onChange={handleChange}
            onFocus={() =>
              fetchCurrencyList &&
              LEADSSTORE.getCurrencyList().then(() => setFetchCurrencyList(false))
            }
            notFoundContent={
              fetchCurrencyList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            options={{
              values: LEADSSTORE.dropdown_currency_list,

              value_key: 'id',
              text_key: 'currency_code',
            }}
          />
        )}
      </Col>

      <Col span={6}>
        {formDataList.includes('lead_source') && (
          <InputComponent
            name='lead_source'
            label={`${t('Common_Data.Sold')} ${t('Common_Data.Source')}`}
            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Source')}`}
            mode='multiple'
            type='select'
            onChange={handleChange}
            allowClear
            showArrow
            showSearch
            tooltip={`${t('Reports.Sold_Source_Tooltip')}.`}
            onFocus={() =>
              fetchSoldSourceList && getSoldSourceList().then(() => setFetchSoldSourceList(false))
            }
            notFoundContent={
              fetchSoldSourceList ? <Spin size='small' /> : 'No Record Found.'
            }
            options={{
              values:LEADSSTORE.dropdown_sold_souce_list,                
              value_key: 'sold_source_name',
              text_key: 'sold_source_name',
            }}
            // options={{
            //   values: LEADSSTORE.leadSourceArray,
            // }}
          />
        )}
      </Col>
      <Col span={6}>
        {formDataList.includes('sold_source_details') && (
          <InputComponent
            name='sold_source_details'
            label={`${t('Common_Data.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`}
            placeholder={`${t('Common_Data.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`}
            type='textarea'
            rows={2}
            onChange={handleChange}
            tooltip={`${t('LeadListing.Lead_Source_Details_Tooltip')}.`}
          />
        )}
      </Col>
      <Col span={24}>
        {formDataList.includes('note') && (
          <InputComponent
            name='note'
            label={`${t('Common_Data.Note')}`}
            placeholder={`${t('Reports.Note_Placeholder')}`}
            type='textarea'
            tooltip={`${t('Common_Data.Note')}`}
            onChange={handleChange}
          />
        )}
      </Col>

      <OrganisationElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <PersonElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />

      <ParentOrgRowElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />

      <NaceCategoryElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />

      <TimesTrackElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <TurnoverElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
    </>
  );
});

export default SOLDReportCriteria;
