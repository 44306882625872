import React, { useEffect, useState } from 'react';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import {
  ActionRenderer,
  ActionHeaderComponent,
  TypeRenderer,
  HeaderTypeComponent,
} from './GridComponent';
import { vsmCommon } from '../../../config/messages';
import GridConfig from '../../../config/GridConfig';
import useStore from '../../../store';

import EditViewComponent from '../../Tags/component/EditViewComponent';
import { default as TicketEditComponent } from '../../Tickets/component/EditComponentNew';
import { default as TicketViewComponent } from '../../Tickets/component/ViewComponent';
import StatusRenderer from '../elements/StatusRenderer';
import MasterTicketRenderer from '../elements/MasterTicketRenderer';
import PriorityRender from '../elements/PriorityRender';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';


function subjectTooltip(params) {
  const title = (
    <div
      style={{ fontSize: '1.2rem' }}
      className='NoteText'
      dangerouslySetInnerHTML={{
        __html: params?.data?.subject,
      }}
    ></div>
  );
  return (
    <Popover
      content={title}
      placement='topLeft'
      trigger='hover'
      overlayClassName='profileMenu'
    >
      <div
        dangerouslySetInnerHTML={{
          __html: params?.data?.subject,
        }}
      ></div>
    </Popover>
  );
}
function descriptionTooltip(params) {
  const title = (
    <div
      style={{ fontSize: '1.2rem' }}
      className='NoteText'
      dangerouslySetInnerHTML={{
        __html: params?.data?.description,
      }}
    ></div>
  );
  return (
    <Popover
      content={title}
      placement='topLeft'
      trigger='hover'
      overlayClassName='profileMenu'
    >
      <div
        dangerouslySetInnerHTML={{
          __html: params?.data?.description,
        }}
      ></div>
    </Popover>
  );
}



const ListComponent = observer((props) => {
  const { t } = useTranslation()
  //---------------------Tag Edit States---------------------//
  const [viewTagDrawer, setViewTagDrawer] = useState(false);
  const [loadingEditView, setLoadingEditView] = useState(false);
  //---------------------Tag Edit States---------------------//

  //--------------------TICKET EDIT STATES -------------------//
  const [ticketEditDrawer, setTicketEditDrawer] = useState(false);
  const [ticketViewDrawer, setTicketViewDrawer] = useState(false);
  const [idTicket, setIdTicket] = useState();

  //---------------------Tag Edit States---------------------//

  //const { bulkDelete } = props;

  const {
    AUTH,
    TagsStore,
    TicketsStore,
    PRODUCTIVITYSTORE,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  const paddingLeft = '10px !important';

  useEffect(() => {
    TicketsStore.getTicketstatusList();
  }, [TicketsStore]);

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        //cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        //width: 60,

        lockPosition: true,
        cellClass: 'locked-col',
        minWidth: 37,
        width: 37,
        suppressNavigable: true,
      },
      {
        headerName: `${t('Common_Data.Priority')}`,
        headerTooltip: `${t('Common_Data.Priority')}`,
        field: 'priority',
        tooltipField: 'priority',
        cellClass: 'cellClass priorityRender',
        filter: 'agSetColumnFilter',
        sortable: false,
        lockPosition: true,
        cellRenderer: 'PriorityRender',
        // valueFormatter: (params) => {
        //   formatterSetColumnFilter(
        //     TicketsStore.dropdown_priority,
        //     params,
        //     'id',
        //     'priority'
        //   )
        // },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TicketsStore.getDropdownPriority().then((data) => {
              params.success([...TicketsStore.dropdown_priority.map((x) => x.id)]);
              // params?.success([...data?.data?.map((x) => x.id)]);
              // alert("hi")
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              TicketsStore.dropdown_priority,
              params,
              'id',
              'priority'
            ),
        },
        // onCellDoubleClicked: (event) => openViewDrawer(event?.data),
      },
      {
        headerName: `${t('Productivity.Tag_Ticket_Number')}`,
        field: 'tag_ticket_number',
        tooltipField: 'tag_ticket_number',
        cellRenderer: 'TypeRenderer',
        suppressNavigable: true,
        filter: 'agTextColumnFilter',
      },
      // {
      //   headerName: 'ID',
      //   field: 'tag_ticket_number',
      //   //cellClass: 'cellClass',
      //   tooltipField: 'tag_ticket_number',
      //   cellRenderer: (params) => {
      //     return params?.data?.tag_number
      //       ? params?.data?.tag_number
      //       : params?.data?.ticket_number;
      //   },
      //   lockPosition: true,
      //   cellClass: 'locked-col',
      //   suppressNavigable: true,
      //   filter: 'agTextColumnFilter',
      // },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',
        tooltipField: 'created_by_name',

        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PRODUCTIVITYSTORE.getUsersList().then((data) => {
              params.success([null, ...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'holder',
        cellClass: 'cellClass',
        tooltipField: 'holder',
        cellRenderer: (params) => {
          return params?.data?.ticket_holder
            ? params?.data?.ticket_holder?.first_name +
            ' ' +
            params?.data?.ticket_holder?.last_name
            : '';
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PRODUCTIVITYSTORE.getUsersList().then((data) => {
              params.success([null, ...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Productivity.Ticket')} ${t('Common_Data.Number')}`,
        field: 'master_ticket.master_ticket_number',
        cellClass: 'cellClass',
        tooltipField: 'master_ticket.master_ticket_number',
        filter: 'agTextColumnFilter',
        // cellRenderer: (params) => {
        //   return params?.data?.master_ticket ? (
        //     <>
        //     { params?.data?.master_ticket?.master_ticket_number && params?.data?.master_ticket?.master_ticket_number}
        //     <img className='menuicon' alt='' src={AUTH.GetThemedImage('Deal_icn')}/>
        //     </>
        //   ) : null
        //   // return params?.data?.master_ticket?.master_ticket_number &&
        //   //   <>
        //   //     {params?.data?.master_ticket?.master_ticket_number}

        //   //   </>;
        // },
        cellRenderer: 'MasterTicketRenderer',
        // onCellClicked: (params) => {
        //   params?.data?.master_ticket?.master_ticket_number &&
        //     openViewTicketDrawer(params?.data?.master_ticket);
        // },
      },
      // {
      //   headerName: 'Ticket Subject',
      //   field: 'master_ticket.ticket_subject',
      //   cellClass: 'cellClass',
      //   tooltipField: 'master_ticket.ticket_subject',
      //   filter: 'agTextColumnFilter',
      // },
      {
        headerName: `${t('Productivity.Ticket')} ${t('Productivity.Subject')}`,
        headerTooltip: `${t('Productivity.Ticket')} ${t('Productivity.Subject')}`,
        field: 'subject',
        cellClass: 'cellClass',
        // tooltipField: 'subject',
        filter: 'agTextColumnFilter',
        cellRenderer: 'subjectTooltip',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation.organisation_name',
        tooltipField: 'organisation.organisation_name',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'description',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
        cellRenderer: 'descriptionTooltip',
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        field: 'assigned_to.lead_ticket_status.status_name',
        cellClass: 'cellClass StatusCellPromotion text-left',
        filter: false,
        cellRenderer: 'StatusRenderer',
      },
      {
        headerName: 'Subject',
        field: 'subject',
        cellClass: 'cellClass',
        tooltipField: 'subject',
        filter: 'agTextColumnFilter',
      },
      
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.deadline_date
            ? AUTH.getLocalTime(params?.data?.deadline_date, 0, 1)
            : '';
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.Number_of_comments')}`,
        field: 'total_notes_comments',
        cellClass: 'cellClass',
        tooltipField: 'total_notes_comments',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params?.data?.created_at
            ? AUTH.getLocalTime(params?.data?.created_at,AUTH.global_fulldate_format)
            : '';
        },
      },

      /*{
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',

        maxWidth: 450,
      },*/

      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        field: 'updated_at',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.updated_at
            ? AUTH.getLocalTime(params?.data?.updated_at,AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
      },

      /*{
        headerName: '# ID',
        field: 'id',
        cellClass: 'cellClass text-left',

        tooltipField: 'id',
      },*/
      {
        headerName: ``,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        cellStyle: { paddingLeft },
        type: 'actionColumn',
        filter: false,
        //sortable: false,
        pinned: 'right',
        checkboxSelection: function (params) {
          if (
            params?.data?.owner?.id === AUTH?.user?.id &&
            (AUTH.checkPermission(3, 'delete-productivity') ||
              AUTH.checkPermission(3, 'reassign-productivity'))
          ) {
            if (params?.data?.type.toLowerCase() === 'tag') {
              return checkAllowEditTag(params?.data);
            } else {
              return true;
            }
          }
          else {
            return false;
          }

        },
        headerComponentFramework: ActionHeaderComponent,
        width: 160,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('productivity_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['description'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }
    return item;
  });

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Tab')}`,
          action: function () {
            if (params.node.data.type.toLowerCase() === 'ticket') {
              window.open(`/ticket/${params.node.data.ticket_number}`, '_blank');
            } else {
              window.open(
                `/dealdesk/${params.node.data.leads_transactions_id}`,
                '_blank'
              );
            }
          },
        },
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Window')}`,
          action: function () {
            if (params.node.data.type.toLowerCase() === 'ticket') {
              window.open(
                `/ticket/${params.node.data.ticket_number}`,
                "New Window'",
                'location=yes,scrollbars=yes,status=yes'
              );
            } else {
              window.open(
                `/dealdesk/${params.node.data.leads_transactions_id}`,
                "New Window'",
                'location=yes,scrollbars=yes,status=yes'
              );
            }
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  //------------------TAG EDIT FUNCTIONS--------------//
  const openTagEditDrawer = (data) => {
    setLoadingEditView(true);
    TagsStore.setViewValues(data).then((res) => {
      TagsStore.viewValues = res;
      setLoadingEditView(false);
      setViewTagDrawer(true);
    });
  };
  const closeEditTagDrawer = () => setViewTagDrawer(false);
  //------------------TAG EDIT FUNCTIONS--------------//

  //----------------TICKET EDIT - VIEW  FUNCTIONS ------------------//
  const openTicketEditDrawer = (data, isViewOpen = false) => {
    TicketsStore.setEditValues(data);
    setTicketEditDrawer(true);
  };
  const closeTicketEditDrawer = () => {
    setTicketEditDrawer(false);
  };

  const openViewTicketDrawer = (data, isEditOpen = false) => {
    if (AUTH.checkPermission(15, 'view-details')) {
      setIdTicket(data);

      setTicketViewDrawer(true);
    }
  };
  const closeViewTicketDrawer = () => {
    TicketsStore.viewValues = null;
    setIdTicket(null);
    setTicketViewDrawer(false);
  };

  // ----START: USED TO CHECK REASSIGN ALLOWED OR NOT-------------//
  const checkAllowEditTag = (tagObj) => {
    let allowEdit = false;
    if (tagObj?.tag_content?.includes('<approved')) {
      allowEdit = false;
    } else if (![5, 3].includes(tagObj.status)) {
      allowEdit = true;
    }
    return allowEdit;
  };
  // ----END: USED TO CHECK REASSIGN ALLOWED OR NOT-------------//

  //----------------TICKET EDIT FUNCTIONS ------------------//

  //confirmation alert before deleting existing user & edit
  const onCellKeyDown = (e) => {
    if (AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (result.key === 'Enter') {
            if (e?.data?.type.toLowerCase() === 'tag' && checkAllowEditTag(e.data)) {
              openTagEditDrawer(e.data);
            }
            if (e?.data?.type.toLowerCase() === 'ticket') {
              openTicketEditDrawer(e.data);
            }
            //openEditDrawer(e.data);
          }
          if (result.key === 'Delete') {
            //bulkDelete(e.data);
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper'
        style={{
          height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)',
        }}
      >
        <AgGridReact
          rowData={PRODUCTIVITYSTORE.list_data}
          modules={AllModules}
          columnDefs={withTooltipCols}
          // rowClassRules={
          //   {
          //     "row-other": function (params) { return params?.node?.data?.priority !== null },
          //     "row-critical": function (params) { return params?.node?.data?.priority === "Critical" },
          //     "row-urgent": function (params) { return params?.node?.data?.priority === "Urgent" },
          //     "row-important": function (params) { return params?.node?.data?.priority === "Important" },
          //     "row-timed": function (params) { return params?.node?.data?.priority === "Timed" },
          //   }
          // }
          defaultColDef={{
            ...GridConfig.defaultColDef,
            width: 120,
            minWidth: 70,
            flex:1,
            floatingFilter: true,
          }}
          columnTypes={GridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            StatusRenderer,
            MasterTicketRenderer,
            ActionHeaderComponent,
            TypeRenderer,
            subjectTooltip,
            descriptionTooltip,
            HeaderTypeComponent,
            checkAllowEditTag,
            PriorityRender,
            
          }}
          onGridReady={PRODUCTIVITYSTORE.setupGrid}
          gridOptions={{
            ...GridConfig.options,
            rowHeight: 30,
            pagination: true,
            getContextMenuItems: getContextMenuItems,
          }}
          onColumnResized={PRODUCTIVITYSTORE.onGridChanged}
          onColumnMoved={PRODUCTIVITYSTORE.onGridChanged}
          onColumnPinned={PRODUCTIVITYSTORE.onGridChanged}
          rowSelection='multiple'
          suppressRowClickSelection={true}
          onCellKeyDown={onCellKeyDown}
          onCellDoubleClicked={(e) => {
            if (e?.data?.type.toLowerCase() === 'tag' && checkAllowEditTag(e?.data)) {
              openTagEditDrawer(e.data);
            }
            if (e?.data?.type.toLowerCase() === 'ticket') {
              openViewTicketDrawer(e.data);
            }
          }}
          onFilterChanged={(e) => {
            if (
              e.hasOwnProperty('afterFloatingFilter') &&
              PRODUCTIVITYSTORE.selectedGridFilter
            ) {
              PRODUCTIVITYSTORE.setSelectedGridFilter(null);
            }
          }}
          onRowSelected={(e) => {
            if (e.node?.selected && e?.data?.type.toLowerCase() === 'ticket') {
              if (e?.data?.child_ticket_count) {
                PRODUCTIVITYSTORE.setSelectedMasterTicket(e.data.ticket_number);
              }
            }
          }}
        />
      </div>
      <EditViewComponent
        visible={viewTagDrawer}
        close={closeEditTagDrawer}
        loadingEditView={loadingEditView}
        editFromDealDeskNoteList={true}
      />

      <TicketEditComponent
        visible={ticketEditDrawer}
        close={closeTicketEditDrawer}
        viewOpen={ticketViewDrawer}
      />
      
      <TicketViewComponent
        visible={ticketViewDrawer}
        id={idTicket}
        editOpen={ticketEditDrawer}
        setId={setIdTicket}
        close={closeViewTicketDrawer}
        openEditDrawer={openTicketEditDrawer}
      />
      <TimeTracker stop={viewTagDrawer || ticketViewDrawer || ticketEditDrawer}/>
    </>
  );  
});
export default ListComponent;
