import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmTickertap } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { TICKERTAPBROADCAST, AUTH } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const close = () => {
    props.close();
    form.resetFields();
    TICKERTAPBROADCAST.editValues = null;
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.id = TICKERTAPBROADCAST.editValues.id;
    TICKERTAPBROADCAST.EditData(data)
      .then(() => {
        close();
        AUTH.getTickerHeaderInfo();
        vsmNotify.success({
          message: vsmTickertap.edit
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (TICKERTAPBROADCAST.editValues) {
      if (!TICKERTAPBROADCAST.usergroup_list) {
        TICKERTAPBROADCAST.getUsergroupList();
      }

      form.setFieldsValue({
        ticker_text: TICKERTAPBROADCAST.editValues.ticker_text,
        ticker_url: TICKERTAPBROADCAST.editValues.ticker_url,
        description: TICKERTAPBROADCAST.editValues.description,
        user_groups:
          TICKERTAPBROADCAST.editValues.user_groups.length &&
          TICKERTAPBROADCAST.editValues.user_groups.map((item) => item.id)
      });
    }
  }, [TICKERTAPBROADCAST, TICKERTAPBROADCAST.editValues, form]);

  return (
    TICKERTAPBROADCAST.editValues && (
      <Modal
        centered
        title={`${t('Common_Data.Edit')} ${t('TickerTapBroadcast.Information')} - #${TICKERTAPBROADCAST.editValues?.id}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            form='editform'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='medium'
            disabled={isDisabled}
          >
            {t('Common_Data.Update')}
          </Button>,
          <Button shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>
        ]}
        width={500}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
        />
      </Modal>
    )
  );
});

export default EditComponent;
