import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Form } from 'antd';
import useStore from '../../../../store';

import { generate_onReports } from '../../../../utils/GlobalFunction';
import DateSelectionElementNew from '../elements/DateSelectionElementNew';

const PreviewDateRangeForm = observer(
  ({
    defaultFormDataList,
    formDataList,
    setFormDataList,
    setTip,
    setSaving,
    setPreviewReport,
    setNoDataAlert,
  }) => {
    const {
      SavedReportStore,
      SavedReportStore: { setPreviewFilterForExport },
    } = useStore();

    const [DateRange, SetDateRange] = useState([]);
    const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [previewfilterBtnDisable, setPreviewfilterBtnDisable] = useState(true);

    const [form] = Form.useForm();

    const disabledDate = (current) => {
      if (!DateRange || DateRange.length === 0) {
        return false;
      }
      return moment(current).format('Y-M-D') === moment(DateRange[0]).format('Y-M-D');
    };

    const getFormDataList = useCallback(
      (type) => {
        let FormDataList = defaultFormDataList;
        if (generate_onReports.includes(type)) {
          FormDataList = [...FormDataList, 'generate_on', 'additional_fields'];
        } else {
          FormDataList = [...FormDataList, 'additional_fields', 'generate_on'];
        }

        switch (form.getFieldValue('generate_on')) {
          case 'yesterday':
            FormDataList = [...FormDataList];

            break;
          case 'last_week':
            FormDataList = [...FormDataList];

            break;
          case 'this_month':
            FormDataList = [...FormDataList];

            break;
          case 'this_quarter':
            FormDataList = [...FormDataList];

            break;
          case 'this_year':
            FormDataList = [...FormDataList];

            break;
          case 'date_range':
            FormDataList = [...FormDataList, 'date_range'];

            break;
          case 'single':
            FormDataList = [...FormDataList, 'date'];

            break;
          case 'auto':
            FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type'];
            break;
          case 'financial_year':
            FormDataList = [...FormDataList, 'financial_year'];

            if (!GENERATEONFINANCIAL) {
              SETGENERATEONFINANCIAL(true);
              SavedReportStore.getFinancialYearList();
            }
            break;
          case 'periods':
            FormDataList = [...FormDataList, 'period_selection'];
            switch (form.getFieldValue('period_selection')) {
              case 'custom':
                FormDataList = [...FormDataList, 'period_date_range'];
                break;
              case 'auto':
                FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type'];
                break;
              default:
            }
            break;
          default:
        }

        switch (type) {
          case 'deal_maker_bonus':
            FormDataList = [...FormDataList, 'lead_owner'];
            break;
          case 'sample_weekly':
            FormDataList = [...FormDataList];
            break;

          case 'performance':
            FormDataList = [
              ...FormDataList,
              'users_list',
              'user_group_list',
              'parent_sold_stage',
              'sold_stage',
              'record_type',
              'calls_checkbox',
              'show_result_column',
              'leads_structure_count',
              'leads_schedule_count',
              'leads_screen_count',
              'tag_assigned_to_count',
              'tag_created_count',
              'ticket_created_count',
              'ticket_assigned_to_count',
            ];
            break;
          case 'investment_hours':
            FormDataList = [
              ...FormDataList,
              'users_list',
              'user_group_list',
              'lead_id',
              'ticket_id',
              'group_on',
            ];
            break;
          default:
        }
        return FormDataList;
      },
      [defaultFormDataList, form, SavedReportStore, GENERATEONFINANCIAL]
    );

    const handleChange = async (event) => {
      setFilterApplied(true);
      await setFormDataList(
        getFormDataList(SavedReportStore.previewReportValues.report_type)
      );

      form
        .validateFields()
        .then((data) => {
          setPreviewfilterBtnDisable(false);
        })
        .catch((e) => {
          if (e.errorFields && e.errorFields.length > 0) {
            setPreviewfilterBtnDisable(true);
          }
        });
    };

    const handlePreviewReport = () => {
      let newObject = {
        ...SavedReportStore.previewReportValues,
        ...form.getFieldsValue(),
      };

      newObject.form_list = formDataList;
      let data = SavedReportStore.formatFormData(newObject);

      data.name = SavedReportStore.previewReportValues.name;
      data.code = SavedReportStore.previewReportValues.code;
      data.description = SavedReportStore.previewReportValues.description;
      data.action = 'preview-data';

      let callAPI = true;
      if (['single', 'date_range', 'auto'].includes(data.generate_on)) {
        if (data.generate_on === 'single') {
          if (!data.date) {
            callAPI = false;
          }
        }
        if (data.generate_on === 'date_range') {
          if (!data.date_range) {
            callAPI = false;
          }
        }
        if (data.generate_on === 'auto') {
          if (!data.date_x_value_type) {
            callAPI = false;
          }
        }
      }
      if (['financial_year'].includes(data.generate_on)) {
        if (data.generate_on === 'financial_year') {
          if (!data.financial_year) {
            callAPI = false;
          }
        }
      }

      if (callAPI) {
        setSaving(true);
        setTip('Loading Report');

        setPreviewFilterForExport(data);
        SavedReportStore.PreviewData(data)
          .then((data) => {
            setPreviewReport(data.data);
            if (data.data && Object.keys(data.data).length > 0) {
            } else {
              setNoDataAlert(true);
            }
          })
          .catch((e) => {
            if (e.errors) {
              form.setFields(e.errors);
            }
          })
          .finally(() => {
            setSaving(false);
            setTip(null);
          });
      }
    };

    useEffect(() => {
      if (!filterApplied) {
        let data = getFormDataList(SavedReportStore.previewReportValues.report_type);

        if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
          setFormDataList(data);
          var previewReportValues = SavedReportStore.formatFormData(
            SavedReportStore.previewReportValues,
            false
          );
          form.setFieldsValue(previewReportValues);
        }
      }
    }, [
      getFormDataList,
      setFormDataList,
      formDataList,
      form,
      filterApplied,
      setFilterApplied,
      SavedReportStore,
    ]);

    return (
      <>
        <Form
          form={form}
          layout='vertical'
          onFinish={handlePreviewReport}
          id='update_daterangeForm '
          className='innerForm'
          initialValues={SavedReportStore.initialValues}
        >
          <DateSelectionElementNew
            SetDateRange={SetDateRange}
            disabledDate={disabledDate}
            formDataList={formDataList}
            onChange={handleChange}
            form={form}
            handlePreviewReport={handlePreviewReport}
            isPreviewFilter={true}
            previewfilterBtnDisable={previewfilterBtnDisable}
          />
        </Form>
      </>
    );
  }
);

export default PreviewDateRangeForm;
