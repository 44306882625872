import { Col, Form, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../../component/InputComponent';
import { vsmDealDesk, vsmLeadsList } from '../../../../config/messages';
import useStore from '../../../../store';
import ScoreComponent from './ScoreComponent';

const FormComponent = observer((props) => {
  const { t } = useTranslation();
  const [fetchClassification, setFetchClassification] = useState(true);
  const [fetchProductList, setFetchProductList] = useState(true);
  const {
    handleSubmit,
    onChange,
    Score,
    SetScore,
    forceUpdate,
    form,
    id,
    // INITIAL_HEIGHT,
    // setis_checked,
    // is_checked,
    // setScheduleData,
    // ScheduleData,
    // autoSaveData = () => { },
    // saveObj,
    // openViewDrawer,
  } = props;

  const {
    LEADSSTORE,
    LEADSSTORE: { classification_role_list, getClassificationList, getProductList },
  } = useStore();

  useEffect(() => {
    if (fetchClassification && !classification_role_list) {
      getClassificationList().then(() => setFetchClassification(false));
    }
  }, [classification_role_list, fetchClassification, getClassificationList]);

  return (
    <div className='RightScheduleList'>
      <Form
        form={form}
        layout='vertical'
        id={id}
        onFinish={handleSubmit}
        className='innerForm'
      >
        <Row gutter={15}>
          <Col span={12}>
            <InputComponent
              required
              className='note'
              type='textarea'
              label={`${t('DealDesk.Score_Title')}`}
              name='title'
              placeholder={`${t('DealDesk.Enter_Score_Title')}...`}
              onChange={onChange}
              rules={vsmDealDesk.schedule.validation.title}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              required
              className='note'
              type='textarea'
              label={`${t('Common_Data.Description')}`}
              name='description'
              placeholder={`${t('Common_Data.Description')}`}
              onChange={onChange}
              rules={[{ required: true, message: `${t('DealDesk.Not_Blank')}` }]}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              name='classification_id'
              placeholder={`${t('Common_Data.Select_Credebt_Classification')}`}
              label={
                <span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>
              }
              required
              type='select'
              // mode='multiple'
              allowClear
              showArrow
              showSearch
              onChange={onChange}
              options={{
                values: classification_role_list,
                value_key: 'id',
                text_key: 'name',
                // rejected_keys: LEADSSTORE.editValues && [
                //   LEADSSTORE.editValues.id,
                // ],
              }}
              onFocus={() =>
                fetchClassification &&
                getClassificationList().then(() => setFetchClassification(false))
              }
              notFoundContent={
                fetchClassification ? (
                  <Spin size='small' />
                ) : (
                  `${t('Common_Data.No_Record_Found')}.`
                )
              }
              tooltip={`${t('LeadListing.Classification_Group_Tooltip')}.`}
              rules={vsmLeadsList.validation.classification_id}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              name='product'
              label={`${t('Common_Data.Sold')} ${t('LeadListing.Product')}/${t(
                'LeadListing.Service'
              )}`}
              placeholder={`${t('Common_Data.Sold')} ${t('LeadListing.Product')}/${t(
                'LeadListing.Service'
              )}`}
              type='select'
              onChange={onChange}
              allowClear
              showArrow
              showSearch
              tooltip={`${t('LeadListing.Choose_To_Product_Created')}.`}
              rules={vsmLeadsList.validation.product_name}
              onFocus={() =>
                fetchProductList &&
                getProductList().then(() => setFetchProductList(false))
              }
              notFoundContent={
                fetchProductList ? (
                  <Spin size='small' />
                ) : (
                  `${t('Common_Data.No_Record_Found')}.`
                )
              }
              options={{
                values: LEADSSTORE.product_list,
                value_key: 'product_name',
                text_key: 'product_name',
              }}
            />
          </Col>
        </Row>
        <div className='list mt-5'>
          <ScoreComponent
            Score={Score}
            form={form}
            handleFormChange={onChange}
            isFromLibrary={true}
            SetScore={SetScore}
            forceUpdate={forceUpdate}
          />
        </div>
      </Form>
    </div>
  );
});

export default FormComponent;
