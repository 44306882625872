import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, Modal, Row } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../../store';
import { vsmNotify, vsmPeopleRoles } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const { PEOPLEROLESTORE } = useStore();
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
    PEOPLEROLESTORE.setDeleteValues(null);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    PEOPLEROLESTORE.DeleteData(PEOPLEROLESTORE.deleteValues.id)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmPeopleRoles.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    PEOPLEROLESTORE.deleteValues && (
      <Modal
        centered
        title={`${t('Common_Data.Delete')} ${t('PeopleManagement.Role')} - #${PEOPLEROLESTORE.deleteValues.id} - ${PEOPLEROLESTORE.deleteValues.role_name}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='deleteformPeopleRoles'
            loading={saving}
            htmlType='submit'
            type='primary'
            danger
          >
            {t('Common_Data.Delete')}
          </Button>,
          <Button
            key='2'
            htmlType='button'
            onClick={() => {
              close();
            }}
          >
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
      >
        <Form form={form} id='deleteformPeopleRoles' onFinish={handleSubmit}>
          {
            <Row align='middle'>
              <Col span={4} className='DeleteIcon'>
                <WarningFilled />
              </Col>
              <Col span={20} className='DeleteText'>
                {t('PeopleManagement.Remove_This')} {t('Common_Data.People')} {t('PeopleManagement.Role')}?
              </Col>
            </Row>
          }
        </Form>
      </Modal>
    )
  );
});

export default DeleteComponent;
