import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class PeopleRoleMappingStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;

  editValues = null;
  deleteValues = null;
  agGrid = null;

  dropdown_tags_list = null;

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.PEOPLE_ROLE_MAPPING) {
        params = { request: temp.PEOPLE_ROLE_MAPPING };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PEOPLE_ROLE_MAPPING: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          PEOPLE_ROLE_MAPPING: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    this.setFilter(params.api);
    if (
      this.agGrid &&
      this.agGrid.api.rowModel.rowsToDisplay.length === 0
    ) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // call api to get record detail
  getRecordById = (id, payload) => {
    return Axios.post(`ledger/role/mapping/read/${id}`, payload)
      .then(({ data }) => {
        this.editValues = data.data;
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getList = (payload) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.api.getSortModel();
    }
    payload.mapping_type = 2;
    return Axios.post(`ledger/role/mapping/list`, payload).then(
      ({ data }) => {
        if (data.data.length) {
          data.data.forEach((item, index) => {
            item.association_count = item.has_transactions_count;
            item.updated_by = item.users
              ? item.users.first_name + ' ' + item.users.last_name
              : null;
          });
        }
        this.list_data = data.data;
        this.total = data.total;
        this.current_page = data.current_page;
        var allColumnIds = [];
        if (this.agGrid && this.agGrid.columnApi && data.total) {
          this.agGrid.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
          });
          //this.agGrid.columnApi.autoSizeColumns(allColumnIds);
        }
        if (this.agGrid) {
          this.agGrid.api.setFilterModel(filter);
          this.agGrid.api.setSortModel(sort);
        }
        return data;
      }
    );
  };

  // call api to get all tags list
  getAllTagsList = () => {
    return Axios.get(`ledger/role/mapping/tag/list`).then(({ data }) => {
      this.dropdown_tags_list = data;
      return data;
    });
  };

  // Call map api
  MapData = (formdata) => {
    return Axios.post(`ledger/role/mapping/create`, formdata)
      .then(({ data }) => {
        this.setupGrid(this.agGrid);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call api to map bank account
  MapBank = (formdata) => {
    formdata.mapping_type = 2;
    return Axios.post(`organisations/bankaccount/map`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.getList({ people_id: formdata.people_id });
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name: [name, 0], errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call add api
  AddData = (formdata) => {
    formdata.mapping_type = 2;
    return Axios.post(`ledger/role/mapping/create`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.getList({ people_id: formdata.people_id });
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  EditData = (formdata) => {
    formdata.mapping_type = 2;
    return Axios.post(`ledger/role/mapping/edit/${formdata.id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.getList({ people_id: formdata.people_id });
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call delete api
  DeleteData = (formdata, people_id) => {
    formdata.mapping_type = 2;
    return Axios.get(`ledger/role/mapping/delete/${formdata.id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.getList({ people_id: people_id });
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
}

decorate(PeopleRoleMappingStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  editValues: observable,
  deleteValues: observable,
  dropdown_tags_list: observable,
  setPageSize: action,
  setupGrid: action,
  onFilterChanged: action,
  getRecordDetail: action,
  getAllTagsList: action,
  MapData: action,
  setDeleteValues: action,
  getRecordById: action,
});
