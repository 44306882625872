import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import FormComponent from './FormComponentNew';
import debounce from 'lodash/debounce';
import useStore from '../../../store';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import { vsmNotify, vsmLeadsList } from '../../../config/messages';
import { default as AddPeopleComponent } from '../../PeopleManagement/People/component/AddComponentNew';
import { default as AddOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/AddComponentNew';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { default as EditPeopleComponent } from '../../PeopleManagement/People/component/EditComponentNew';
import { default as EditOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/EditComponentNew';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const CloneComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    PEOPLE,
    LEADSSTORE,
    LEADSSTORE: { cloneValues, getLeadStatusList },
    ORGANISATION,
    //ORGANISATION: { getMappedOrg },
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    PROMOTIONSTORE,
    TimeTrackerStore,
    DEALDESKSTORE,
    DEALDESKNOTESOUTBOUND,
    DEALDESKNOTESEQUENCE,
  } = useStore();
  const location = useLocation();
  let history = useHistory();

  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addOrgModal, setAddOrgModal] = useState(false);
  const [editPeopleDrawer, setEditPeopleDrawer] = useState(false);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null); //Used only for ORG edit.

  const [failedReason, setFailedReason] = useState(null); //Used only for ORG edit.
  const [connectionList, setConnectionList] = useState([]);
  const [getSoldStatus, setSoldStatus] = useState({});

  const [primaryConnectionList, setPrimaryConnectionList] = useState([]);

  /** Call on Clone drawer close  */
  const close = () => {
    props.close();
    setConnectionList([]);
    // LEADSSTORE.cloneValues = null;
    setDisabled(true);
    setSaving(false);
    setPrimaryConnectionList([]);
  };

  const handleSubmit = (data) => {
    if (failedReason) {
      data.failed_reason = failedReason;
    } else {
      if (data.lead_status_id === AUTH.SOLD_STATUS_FAILED) {
        if (
          AUTH.user &&
          AUTH.user?.failed_reasons &&
          AUTH.user.failed_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.failed_reasons[0]?.reason;
        }
      } else if (data.lead_status_id === AUTH.SOLD_STATUS_REJECTED) {
        if (
          AUTH.user &&
          AUTH.user?.rejected_reasons &&
          AUTH.user.rejected_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.rejected_reasons[0]?.reason;
        }
      }
    }

    //--------------------- Formatting Finance/Turn Over values ---------------------------------//

    let finance_value = data.finance_value;
    if (finance_value && (finance_value?.includes('k') || finance_value?.includes('K'))) {
      let splitArr = finance_value?.includes('K')
        ? finance_value.split('K')
        : finance_value.split('k');
      finance_value = Number(splitArr[0]) * 1000;
    } else if (
      finance_value &&
      (finance_value?.includes('m') || finance_value?.includes('M'))
    ) {
      let splitArr = finance_value?.includes('M')
        ? finance_value.split('M')
        : finance_value.split('m');
      finance_value = Number(splitArr[0]) * 1000000;
    } else if (
      finance_value &&
      (finance_value?.includes('b') || finance_value?.includes('B'))
    ) {
      let splitArr = finance_value?.includes('B')
        ? finance_value.split('B')
        : finance_value.split('b');
      finance_value = Number(splitArr[0]) * 1000000000;
    } else if (
      finance_value &&
      (finance_value?.includes('t') || finance_value?.includes('T'))
    ) {
      let splitArr = finance_value?.includes('T')
        ? finance_value.split('T')
        : finance_value.split('t');
      finance_value = Number(splitArr[0]) * 1000000000000;
    }

    let turn_over = data.turn_over;
    if (turn_over && (turn_over?.includes('k') || turn_over?.includes('K'))) {
      let splitArr = turn_over?.includes('K')
        ? turn_over.split('K')
        : turn_over.split('k');
      turn_over = Number(splitArr[0]) * 1000;
    } else if (turn_over && (turn_over?.includes('m') || turn_over?.includes('M'))) {
      let splitArr = turn_over?.includes('M')
        ? turn_over.split('M')
        : turn_over.split('m');
      turn_over = Number(splitArr[0]) * 1000000;
    } else if (turn_over && (turn_over?.includes('b') || turn_over?.includes('B'))) {
      let splitArr = turn_over?.includes('B')
        ? turn_over.split('B')
        : turn_over.split('b');
      turn_over = Number(splitArr[0]) * 1000000000;
    } else if (turn_over && (turn_over?.includes('t') || turn_over?.includes('T'))) {
      let splitArr = turn_over?.includes('T')
        ? turn_over.split('T')
        : turn_over.split('t');
      turn_over = Number(splitArr[0]) * 1000000000000;
    }

    data.finance_value = finance_value;
    data.turn_over = turn_over;

    //-------------------------------------------------------------------------------------------//
    data.deadline_date =
      data.deadline_date && moment(data.deadline_date).format('YYYY-MM-DD');

    let phoneArr = [];
    data.phone &&
      data.phone.length > 0 &&
      data.phone.forEach((x) => {
        delete x.id;
        phoneArr.push(x);
      });
    data.phone = phoneArr;

    let emailArr = [];
    data.email &&
      data.email.length > 0 &&
      data.email.forEach((x1) => {
        delete x1.id;
        emailArr.push(x1);
      });
    data.email = emailArr;

    setSaving(true);
    if (props.cloneNotes) {
      data.cloneNotes = props.cloneNotes;
    }
    if (props.cloneLeadId) {
      data.cloneLeadId = props.cloneLeadId;
    }
    data.connections = connectionList;


    data.future_reason = null;
    data.failed_reason = null;
    data.failed_summary = null;
    data.rejected_reason = null;
    data.summary = null;

    if (getSoldStatus) {
      data.future_reason = getSoldStatus.future_reason ? getSoldStatus.future_reason : null;
      data.failed_reason = getSoldStatus.failed_reason ? getSoldStatus.failed_reason : null;
      data.failed_summary = getSoldStatus.failed_summary ? getSoldStatus.failed_summary : null;
      data.rejected_reason = getSoldStatus.rejected_reason ? getSoldStatus.rejected_reason : null;
      data.summary = getSoldStatus.summary ? getSoldStatus.summary : null;
    }


    LEADSSTORE.addLeadData(data)
      .then((data) => {
        TimeTrackerStore.setLeadTransactionId(data.id)
        close();
        if (location.pathname === '/leads/deals') {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
        }
        if (location.pathname === '/leads/opportunities') {
          LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
        }
        if (location.pathname === '/leads/active') {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
        }
        if (location.pathname === '/promotion') {
          PROMOTIONSTORE.setupGrid(PROMOTIONSTORE.agGrid);
        }

        // Add note on Status changes to failed
        if (
          (DEALDESKSTORE.rejectionSummaryData?.summary) &&
          (DEALDESKSTORE?.rejectionSummaryData?.type === "failed")
        ) {
          let modifiedRejectionSummaryData = `<rejectionSummary>Failed Summary<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`

          let payload = {
            "notes_flag": 2,
            "notes_description": modifiedRejectionSummaryData,
          }
          DEALDESKNOTESOUTBOUND.saveOutboundNote(data?.id, payload).then((res) => {
            if (res && res?.data) {
              DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            }
          })
        }

        // Add note on Status changes to rejected
        if (
          (DEALDESKSTORE.rejectionSummaryData?.summary) &&
          (DEALDESKSTORE?.rejectionSummaryData?.type === "rejected")
        ) {
          let modifiedRejectionSummaryData = `<rejectionSummary>Rejection Summary<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`

          let payload = {
            "notes_flag": 2,
            "notes_description": modifiedRejectionSummaryData,
          }
          DEALDESKNOTESOUTBOUND.saveOutboundNote(data?.id, payload).then((res) => {
            if (res && res?.data) {
              DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            }
          })
        }



        vsmNotify.success({
          message: vsmLeadsList.add,
        });
        if (data?.id) {
          history.push(`/dealdesk/${data.id}`);
        }
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });
          form.setFields(e.errors);
        }
        setSaving(false);
      });
  }, 200);

  useEffect(() => {
    if (LEADSSTORE.cloneValues) {
      if (LEADSSTORE.cloneValues?.lead_record_type) {
        LEADSSTORE.cloneValues.lead_record_type = 'Originator';
        LEADSSTORE.getLeadStatusList(LEADSSTORE.cloneValues.lead_record_type);
      }
    }
  }, [LEADSSTORE]);

  /** To fetch the 'Sold Status Dropdown List' when 'Clone drawer' is opened */
  useEffect(() => {
    if (props.visible) {
      LEADSSTORE.getLeadStatusList();
    }
  }, [LEADSSTORE, props.visible]);

  useEffect(() => {
    if (cloneValues) {
      setPrimaryConnectionList([]);
      //----------------------START: SET CONNECTION FIELDS------------//
      if (cloneValues.connections && cloneValues.connections.length > 0) {
        let connection_list = [];
        let connection_list_ids = [];
        cloneValues.connections.forEach((el) => {
          let tempObj = {
            id: el.entity_id,
            type: el.entity_type,
            name: el?.entity?.name,
          };
          if (el?.entity?.phone && el.entity.phone.length > 0) {
            tempObj.phone = el.entity.phone[0].contact_value;
          } else {
            tempObj.phone = '';
          }
          if (el?.entity?.email && el.entity.email.length > 0) {
            tempObj.email = el.entity.email[0].contact_value;
          } else {
            tempObj.email = '';
          }
          connection_list.push(tempObj);
          connection_list_ids.push(el.entity_id);
        });
        setConnectionList(connection_list);
        form.setFieldsValue({ connections: connection_list_ids });
      }
      //----------------------END: SET CONNECTION FIELDS------------//

      if (cloneValues.sold_organisation) {
        let newOrg = {
          id: cloneValues.sold_organisation && cloneValues.sold_organisation.id,
          // connection_role_name: connectionRole,
          type: 1,
          name: cloneValues.sold_organisation
            ? cloneValues.sold_organisation?.organisation_name
            : '',
        };
        setPrimaryConnectionList((oldArray) => [...oldArray, newOrg]);
      }

      if (cloneValues.people) {
        let newPeople = {
          id: cloneValues.people && cloneValues.people.id,
          // connection_role_name: connectionRole,
          type: 2,
          name: cloneValues.people
            ? cloneValues.people?.first_name + '' + cloneValues.people?.last_name
            : '',
        };
        setPrimaryConnectionList((oldArray) => [...oldArray, newPeople]);
      }

      if (cloneValues.finance_value) {
        // form.setFieldsValue({ finance_value: cloneValues.financevalue });
        form.setFieldsValue({ finance_value: parseFloat(cloneValues.finance_value).toFixed(2) });
      }
      if (cloneValues.turn_over) {
        // form.setFieldsValue({ turn_over: cloneValues.turn_over });
        form.setFieldsValue({ turn_over: parseFloat(cloneValues.turn_over).toFixed(2) });
      }
      /*START: If Lead - Opportunity - Opportunityname value to set in from*/
      if (cloneValues && cloneValues?.opportunity_name) {
        form.setFieldsValue({
          opportunity_name: cloneValues?.opportunity_name,
        });
      }
      /*END: If Lead - Opportunity - Opportunityname value to set in from*/

      form.setFieldsValue({
        classification_id:
          cloneValues?.classification_id && parseInt(cloneValues.classification_id),
        lead_record_type: cloneValues?.lead_record_type,
        full_name: cloneValues?.people?.id,
        organisation_name: cloneValues?.organisation_name,
        phone: cloneValues.phone?.length > 0 ? cloneValues.phone : [{}],
        email: cloneValues.email?.length > 0 ? cloneValues.email : [{}],
        lead_source_details: cloneValues?.lead_source_details,
        lead_source: cloneValues?.lead_source,
        product_name: cloneValues?.product_name,
        //lead_status_id: LEADSSTORE.ignore_unusedlead_id,
        lead_currency_id: cloneValues?.lead_currency_id?.id,
        deadline_date: cloneValues?.deadline_date && moment(cloneValues.deadline_date),
        lead_owner_id: cloneValues?.lead_owner_name?.id,
        lead_holder_id: cloneValues?.lead_holder_name?.id,
        investment_hours: cloneValues?.investment_hours,
      });
    }
  }, [cloneValues, form, getLeadStatusList]);

  // Open form for add new People
  const openAddModal = () => {
    setAddModal(true);
  };

  const setPeopleDropDown = (id, classification_id = null) => {
    PEOPLE.getAllPeople({ selected_id: [id], mapping_type: 2 });
    form.setFieldsValue({ full_name: id });

    if (!form.getFieldValue('classification_id')) {
      if (!LEADSSTORE.classification_role_list) {
        LEADSSTORE.getClassificationList();
      }
      if (classification_id) {
        form.setFieldsValue({ classification_id: classification_id });
      }
    }
    handleChange();
  };

  const setOrgDropDown = (id, classification_id = null) => {
    //getMappedOrg();
    form.setFieldsValue({ organisation_name: id });

    if (!form.getFieldValue('classification_id')) {
      if (!LEADSSTORE.classification_role_list) {
        LEADSSTORE.getClassificationList();
      }
      if (classification_id) {
        form.setFieldsValue({ classification_id: classification_id });
      }
    }

    ORGANISATION.getAllROrg({
      selected_id: [id],
      mapping_type: 1,
    });
    handleChange();
  };

  // Close form for add new People
  const closeAddModal = () => setAddModal(false);

  // Open form for add new Organisation
  const openAddOrgModal = () => {
    setAddOrgModal(true);
  };
  // Close form for close new Organisation
  const closeAddOrgModal = () => setAddOrgModal(false);

  const setId = (id) => setTransactionId(id);
  const openEditOrgDrawer = () => {
    var payload = { organisation_id: form.getFieldValue('organisation_name') };
    ORGANISATION.setOrganisationValues(null);
    ORGANISATION.getRecordDetail(payload).then((data) => {
      setId(payload.organisation_id);
      ORGANISATION.setcloneValues(data);
      setEditOrgModal(true);
    });
  };

  // Open form for edit People
  const openEditPeopleDrawer = () => {
    var payload = { people_id: form.getFieldValue('full_name') };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setEditPeopleDrawer(true);
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  };
  // Close form for edit People
  const closeEditPeopleDrawer = () => {
    PEOPLE.getAllPeople({
      selected_id: [form.getFieldValue('full_name')],
      mapping_type: 2,
    });

    setEditPeopleDrawer(false);
    handleChange();
  };

  // Close form of edit Organisation
  const closeEditOrgDrawer = () => {
    //getMappedOrg();
    form.setFieldsValue({ organisation_name: form.getFieldValue('organisation_name') });

    ORGANISATION.getAllROrg({
      selected_id: [form.getFieldValue('organisation_name')],
      mapping_type: 1,
    });

    // ORGANISATION.organisationValues = null;
    // ORGANISATION.setcloneValues(null);
    setEditOrgModal(false);
    handleChange();
  };

  return <>
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      title={`${t('LeadListing.Clone')} ${t('Common_Data.Lead')} - #${cloneValues?.id}`}
      destroyOnClose={true}
      footer={[
        <>
          <div className='text-right'>
            <Button
              key='1'
              form='cloneleadform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              shape='round'
              disabled={isDisabled}
            >
              {t('LeadListing.Clone')}
            </Button>
            <Button shape='round' onClick={close} key='2'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>
        </>,
      ]}
    >
      <FormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        visible={props.visible}
        formid='cloneleadform'
        openAddModal={openAddModal}
        id={props.parent_id}
        openAddOrgModal={openAddOrgModal}
        openEditPeopleDrawer={openEditPeopleDrawer}
        openEditOrgDrawer={openEditOrgDrawer}
        setFailedReason={setFailedReason}
        setConnectionList={setConnectionList}
        connectionList={connectionList}
        setSoldStatus={setSoldStatus}
        setPrimaryConnectionList={setPrimaryConnectionList}
        primaryConnectionList={primaryConnectionList}
      />
      {/* {props?.isFromDealDesk && (
          <TrackTimeComponent pageName={'leadlisting'} leadId={cloneValues.id} />
        )} */}
      <TimeTracker
        page={'leadlisting'}
        id={cloneValues?.id}
        stop={!props.visible || addModal || addOrgModal || editPeopleDrawer || editOrgModal}
      />

      <AddPeopleComponent
        visible={addModal}
        isLead={true}
        close={closeAddModal}
        setPeopleDropDown={setPeopleDropDown}
      />
      <AddOrgComponent
        visible={addOrgModal}
        isLead={true}
        close={closeAddOrgModal}
        setOrgDropDown={setOrgDropDown}
      />
      <EditPeopleComponent
        visible={editPeopleDrawer}
        close={closeEditPeopleDrawer}
        setPeopleDropDown={setPeopleDropDown}
      />
      <EditOrgComponent
        transactionId={transactionId}
        setId={setId}
        visible={editOrgModal}
        close={closeEditOrgDrawer}
        setOrgDropDown={setOrgDropDown}
      />
    </Drawer>
  </>
});

export default CloneComponent;