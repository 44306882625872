import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import debounce from 'lodash/debounce';
import SettingsFormComponent from './SettingsFormComponent';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SettingsComponent = observer((props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation()
  const {
    USERS,
    AUTH: { user },
    USERS: { usersettingValues, getUserSetting, getDurationvalues, getFetchTimezone },
  } = useStore();

  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    // var payload = {
    //   ...data,
    //   user_id: USERS.usersettingValues.id,
    // };


    data.notification_settings = [];
    if (data?.dm_settings?.tickets_push_notifications) {
      data.notification_settings = [...data.notification_settings, 'TICKET_CREATE', 'TICKET_UPDATE']
    }
    if (data?.dm_settings?.tag_push_notifications) {
      data.notification_settings = [...data.notification_settings, 'TAG_ASSIGNED']
    }
    if (data?.dm_settings?.sold_push_notifications) {
      data.notification_settings = [
        ...data.notification_settings,
        'LEAD_CREATE',
        'LEAD_UPDATE',
        'LEAD_OWNER_UPDATE',
        'LEAD_HOLDER_UPDATE'
      ]
    }
    if (data?.dm_settings?.reminders_push_notifications) {
      data.notification_settings = [...data.notification_settings, 'REMINDER_CREATE', 'REMINDER_UPDATE']
    }


    var payload = {
      ...data,
      user_id: USERS.usersettingValues.id,
    }

    USERS.saveUserSetting(payload)
      .then(() => {
        USERS.getList({}, user?.user_role?.user_role_id === 1 ? true : false);
        close();
        vsmNotify.success({
          message: vsmUsers.userSetting,
        });
        // setSaving(false);
        // setTimeZone(data.dm_settings.timezone);
        // setGlobalDateFormate(data.dm_settings.date_format);
        // setGlobalTimeFormate(data.dm_settings.time_format);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // const onReset = () => {
  //   vsmNotify.success({
  //     message: vsmUsers.cancelSettingFormNotify,
  //   });
  //   fetchSettingData();
  // };

  const fetchSettingData = useCallback(() => {
    if (usersettingValues && usersettingValues.id) {
      var payload = {
        user_id: usersettingValues && usersettingValues.id,
      };
      getFetchTimezone();
      getUserSetting(payload).then((data) => {
        getDurationvalues();

        if (data.notification_settings) {
          if (data.notification_settings.includes('TICKET_CREATE')) {
            data.dm_settings.tickets_push_notifications = true;
          }
          if (data.notification_settings.includes('TAG_ASSIGNED')) {
            data.dm_settings.tag_push_notifications = true;
          }
          if (data.notification_settings.includes('LEAD_CREATE')) {
            data.dm_settings.sold_push_notifications = true;
          }
          if (data.notification_settings.includes('REMINDER_CREATE')) {
            data.dm_settings.reminders_push_notifications = true;
          }
        }
        
        if (data.dm_settings) {
          data.dm_settings.snap_note_send_option = moment(
            data.dm_settings.snap_note_send_option
          );
          form.setFieldsValue(data);
        }
      });
    }
  }, [getUserSetting, form, usersettingValues, getDurationvalues, getFetchTimezone]);

  useEffect(() => {
    fetchSettingData();
  }, [fetchSettingData]);

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('Common_Data.Change')} ${t('Common_Data.Settings')}`}
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          <Button
            key='1'
            form='userchangesetting'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='medium'
            disabled={isDisabled}
          >
            {t('Common_Data.Update')}
          </Button>
          <Button onClick={close} shape='round' size='medium' key='2'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <SettingsFormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        id='userchangesetting'
      />
    </Drawer>
  );
});

export default SettingsComponent;
