import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
// import { PageHeader, Button, Space, Form, Tooltip, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';

//import TopSelection from './component/TopSelection';
import DealSearchListing from './DealSearchListing';
import EditComponent from '../../LeadListing/component/EditComponentNew';
import DeleteComponent from '../../LeadListing/component/DeleteComponent';
// import CloneComponent from './../component/CloneComponentNew';
// import ViewComponent from './../component/ViewComponent';
// import LeadImportComponent from './../component/LeadImportComponent';
// import OpportunityImportComponent from './../component/OpportunityImportComponent';
import useStore from '../../../store';
import Unauthorized from '../../Unauthorized';
//import ApplyGridFilter from '../../../component/ApplyGridFilter';
// import MergeLead from '../../LeadListing/component/MergeLead';
// import { vsmNotify } from '../../../config/messages';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
// import ApplyGridFilterNew from '../../../component/ApplyGridFilterNew';
// import { filterFiltersByPageType } from '../../../utils/GlobalFunction';
// import ResetGridOptions from '../../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const DealSearch = observer((props) => {
    const { t } = useTranslation();
    // let history = useHistory();
    const location = useLocation();
    const { SearchPage, AllView, selectionChanged, showCheckbox,
         sectorSelector,
        //  search_term, resultCount, handleRoute
         } = props;

    const {
        LEADSSTORE,
        AUTH,
        // LEADDEALS,
        // SETTINGS,
        AUTH: { allPageFilters, allPageFiltersLoaded },
    } = useStore();
    // const [viewDrawer, setViewDrawer] = useState(false);
    const [editDrawer, setEditDrawer] = useState(false);
    const [deleteDrawer, setDeleteDrawer] = useState(false);
    // const [cloneDrawer, setCloneDrawer] = useState(false);
    // const [kanbanRoute, setKanbanRoute] = useState(null);
    // const [importLeadModal, setImportLeadModal] = useState(false);
    // const [importOpportunitiesModal, setImportOpportunitiesModal] = useState(false);
    //-------------------- Merge Sold States ---------------------------------//

    // const [selectedRowsCount, setSelectedRowsCount] = useState(false);
    // const [mergeSOLDVisible, setmergeSOLDVisible] = useState(false);
    // const [showCheckbox, setshowCheckbox] = useState(false);
    // const [selectedLeadsMerge, setSelectedLeadsMerge] = useState(null);

    //------------------------------------------------------------------------//

    const [editOpen, setEditOpen] = useState(false);

    // const [form] = Form.useForm();
    //Edit drawer
    const openEditDrawer = (data, isEditOpen = false) => {
        LEADSSTORE.setEditValues(data);
        setEditOpen(isEditOpen);
        setEditDrawer(true);
    };
    const closeEditDrawer = () => {
        setEditDrawer(false);
        setEditOpen(false);
    };

    //open delet drawer
    const openDeleteDrawer = (data) => {
        LEADSSTORE.setDeleteValues(data);
        setDeleteDrawer(true);
    };
    const closeDeleteDrawer = () => setDeleteDrawer(false);

    //Clone drawer
    // const openCloneDrawer = (data) => {
    //     LEADSSTORE.setCloneValues(data);
    //     setCloneDrawer(true);
    // };
    // const closeCloneDrawer = () => setCloneDrawer(false);

    //View drawer
    // const openViewDrawer = (data) => {
    //     LEADSSTORE.setViewValues(data);
    //     setViewDrawer(true);
    // };
    // const closeViewDrawer = () => setViewDrawer(false);
    // const openKanbanView = () => history.push(kanbanRoute);

    //Open Drawer to import data
    // const importLeadData = () => setImportLeadModal(true);
    // const closeImportLeadModal = () => {
    //     setImportLeadModal(false);
    // };
    // const importOpportunitiesData = () => setImportOpportunitiesModal(true);
    // const closeImportOpportunitiesModal = () => {
    //     setImportOpportunitiesModal(false);
    // };

    useEffect(() => {
        if (location.pathname !== LEADSSTORE.current_list_path) {
            LEADSSTORE.current_list_path = location.pathname;
        }
        // setKanbanRoute(location.pathname + '/kanban');
    }, [
        LEADSSTORE,
        LEADSSTORE.current_list_path,
        location,
        allPageFiltersLoaded,
        allPageFilters,
    ]);

    const onCellKeyDown = (e) => {
        if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
            if (
                AUTH.user.bonus_figures[0].shortcut_keys &&
                AUTH.user.bonus_figures[0].shortcut_keys.length > 0
            ) {
                const result = AUTH.user.bonus_figures[0].shortcut_keys
                    .filter((x) => x.key === e.event.key)
                    .shift();
                if (result) {
                    if (AUTH.checkPermission(13, 'edit')) {
                        if (result.key === 'Enter') {
                            openEditDrawer(e.data);
                        }
                    }

                    if (AUTH.checkPermission(13, 'delete')) {
                        if (result.key === 'Delete') {
                            openDeleteDrawer(e.data);
                        }
                    }
                }
            }
        }
    };

    //START:-----------SOLD Merge Functions---------------------------//

    // const toggleMergeLead = () => {
    //     //setshowCheckbox(!showCheckbox);
    //     if (showCheckbox) {
    //         openSOLDMergeModal();
    //     } else {
    //         setshowCheckbox(true);
    //     }
    // };
    // const openSOLDMergeModal = () => {
    //     let selectedRow = LEADDEALS.agGrid.api.getSelectedRows();
    //     if (selectedRow.length >= 2) {
    //         setmergeSOLDVisible(true);
    //         setSelectedLeadsMerge(selectedRow);
    //     } else {
    //         vsmNotify.error({
    //             message: `${t('LeadListing.Please_Select_Records')} 2 ${t('LeadListing.Records')}.`,
    //         });
    //     }
    // };
    // const closeSOLDMergeModal = () => {
    //     setmergeSOLDVisible(false);
    //     setSelectedLeadsMerge(null);
    //     setshowCheckbox(false);
    // };
    //END:-----------SOLD Merge Functions---------------------------//

    // const selectionChanged = () => {
    //     let selectedRow = LEADDEALS.agGrid.api.getSelectedRows();
    //     setSelectedRowsCount(selectedRow.length > 0 ? selectedRow.length : 0);
    // };

    // const onClearSelectionClick = () => {
    //     if (LEADDEALS.agGrid) {
    //         LEADDEALS.agGrid.api.deselectAll();
    //     }
    //     setshowCheckbox(false);
    // };


    return (
        <>
            {!AUTH.checkPermission(13, 'list') ? (
                <Unauthorized />
            ) : (
                    <>
                        <DealSearchListing
                            // openViewDrawer={openViewDrawer}
                            openEditDrawer={openEditDrawer}
                            openDeleteDrawer={openDeleteDrawer}
                            // openCloneDrawer={openCloneDrawer}
                            SearchPage={SearchPage}
                            showCheckbox={showCheckbox}
                            onCellKeyDown={onCellKeyDown}
                            selectionChanged={selectionChanged}
                            AllView={AllView}
                            sectorSelector= {sectorSelector}
                        />
                        {/* <TrackTimeComponent pageName={SearchPage ? 'search' : 'leadlisting'} /> */}
                        <TimeTracker
                            page={"leadlisting"}
                            stop={editDrawer}
                        />
                    </>
                )}
            {/* <ViewComponent
                visible={viewDrawer}
                openEditDrawer={openEditDrawer}
                close={closeViewDrawer}
            /> */}
            <EditComponent
                visible={editDrawer}
                editOpen={editOpen}
                close={closeEditDrawer}
                SearchPage={SearchPage}
                SearchPageEdit
            />
            {/* <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />z
            <LeadImportComponent visible={importLeadModal} close={closeImportLeadModal} />
            <OpportunityImportComponent
                visible={importOpportunitiesModal}
                close={closeImportOpportunitiesModal}
            /> */}
            {/* <MergeLead
                visible={mergeSOLDVisible}
                close={closeSOLDMergeModal}
                selectedLeadsMerge={selectedLeadsMerge}
            /> */}
            <DeleteComponent
                visible={deleteDrawer}
                close={closeDeleteDrawer}
                lineText={`${t('Common_Data.Remove_Lead')}?`}
                SearchPage={SearchPage}
            />
        </>
    );
});

export default DealSearch;
