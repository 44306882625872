import React from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../../store';
const StructureHeader = ({
  AddNode,
  editable,
  openViewDrawer,
  scrollToNode,
  autoSaveData,
  saveType,
}) => {
  // Variables -------------------
  const { AUTH, DEALDESKSTORE: { statusPayloadForShortfall } } = useStore();
  const { t } = useTranslation();


  const getTitleBasedOnType = () => {
    if (saveType === 'screen') {
      if (statusPayloadForShortfall) {
        return `${t('Common_Data.Shortfall')}`
      }
      return `${t('Common_Data.Screen')}`
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`
    } else if (saveType === 'shortfall') {
      return `${t('Common_Data.Shortfall')}`
    }
  };

  return (
    <div className='header mb-10'>
      {editable && (
        <img
          src={AUTH.GetThemedImage('add')}
          alt={`${t('DealDesk.Add')}`}
          className='icon circle cursorPointer'
          onClick={() => {
            AddNode();
            scrollToNode(0);
            autoSaveData();
          }}
        />
      )}
      {openViewDrawer && (
        <img
          src={AUTH.GetThemedImage('Deal_Library_icn')}
          alt={`${t('DealDesk.Add')}`}
          className='icon cursorPointer'
          title={
            `${t('DealDesk.View')} ${getTitleBasedOnType()} ${t('DealDesk.Library')}`
          }
          onClick={() => {
            openViewDrawer(openViewDrawer);
          }}
        />
      )}
    </div>
  );
};

export default StructureHeader;
