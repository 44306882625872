import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { vsmNotify, vsmProductivity } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const ReassignConfirmComponent = observer((props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const {
    PRODUCTIVITYSTORE: { bulkAssignUpdate },
  } = useStore();

  const closeModal = () => {
    props.close();
    form.resetFields();
  };

  const handleSubmit = () => {
    setLoading(true);
    bulkAssignUpdate(props.assignTempData.reassignPayload)
      .then((data) => {
        closeModal();
        vsmNotify.success({ message: vsmProductivity.sucecssBulkAssign });
      })
      .finally((data) => setLoading(false));
  };

  return props.assignTempData ? (
    <Modal
      centered
      title={`${t('Common_Data.Assign')} ${t('Common_Data.To')} ${props.assignTempData.username}`}
      visible={props.visible}
      onCancel={closeModal}
      onOk={closeModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key={1}
          form='bulkassignTagTicket'
          htmlType='submit'
          shape='round'
          loading={loading}
        >
          {t('Common_Data.Confirm')}
        </Button>,
        <Button key={2} htmlType='button' shape='round' onClick={closeModal}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='bulkassignTagTicket' onFinish={handleSubmit}>
        <Row align='middle'>
          <Col span={4} className='DeleteIcon'>
            <WarningFilled />
          </Col>
          <Col span={20} className='DeleteText'>
            {t('Productivity.You_Want_To_Assign')}{' '}
            <strong>{props.assignTempData.username}</strong>?
          </Col>
        </Row>
      </Form>
    </Modal>
  ) : null;
});

export default ReassignConfirmComponent;
