import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ActionRenderer } from './GridComponent';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../config/messages';
import useStore from '../../../../store';
import SwitchComponent from './SwitchComponent';
import { DateComparator } from '../../../../utils/GlobalFunction';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';


function fieldnameTooltip(params) {
  const title = (
    <div
      style={{ fontSize: '1.2rem' }}
      className='NoteText'
      dangerouslySetInnerHTML={{
        __html: params?.data?.field_name,
      }}
    ></div>
  );
  return (
    <Popover
      content={title}
      placement='topLeft'
      trigger='hover'
      overlayClassName='profileMenu'
    >
      <div
        dangerouslySetInnerHTML={{
          __html: params?.data?.field_name,
        }}
      ></div>
    </Popover>
  );
}

const ListComponent = observer((props) => {
  const {t} = useTranslation();
  const { openEditModal, openDeleteModal, onSwitchChange, onCellKeyDown } = props;
  const {
    TransactionFieldStore,
    TransactionFieldStore: { list_data, getList, setupGrid, onFilterChanged },
    AUTH,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth }
  } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('DealBuilder.Field_Name')}`,
        field: 'field_name',
        cellRenderer: 'fieldnameTooltip',
  
      },
      {
        headerName: `${t('DealBuilder.Field_Type')}`,
        field: 'field_type',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
        },
  
      },
      {
        headerName: `${t('DealBuilder.Algorithm_Name')}`,
        field: 'algorithm_name',
  
      },
      {
        headerName: `${t('DealBuilder.Default_Value')}`,
        field: 'default_value',
        valueGetter: (params) => {
          if (params.data && params.data.default_value) {
            const rpg_values = []
            if (params.data.rpa_group) {
              const default_values = params.data.default_value.replace(/^#+|#+$/gm, '').split('#')
              params.data.rpa_group.replace(/^#+|#+$/gm, '').split('#').forEach((x, index) => {
                rpg_values.push({ rpa_group: x, default_value: default_values[index] })
              })
            }
            return rpg_values.map((x) => x.rpa_group + ' - ' + x.default_value).join(', ')
          }
  
          return '-'
        },
        filter: false,
      },
      {
        headerName: `${t('DealBuilder.Description')}`,
        field: 'description',
        tooltipField: `${t('DealBuilder.Description_Header')}`,
  
      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
        },
  
      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          comparator: DateComparator,
        },
        width: 220,
  
      },
      {
        headerName: `${t('DealBuilder.Published')}`,
        cellRendererFramework: function (data) {
          const { onSwitchChange } = data.agGridReact.props;
          return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />;
        },
        field: 'published',
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Yes', 'No'],
          defaultToNothingSelected: true,
        },
  
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',
  
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        type: 'actionColumn',
        cellClass: 'cellClass actionColumn',
        sortable: false,
        filter: false,
        pinned: 'right',
  
      }
    ]
  }

  useEffect(() => {
    getList();
  }, [getList]);

  let columns = [];
  let columnConfig = localStorage.getItem('transaction_field_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }
  if (!AUTH.checkPermission(24, 'change_status')) {
    columns = columns.filter((x) => x.headerName !== 'Published');
  }

   // to show tooltip on column header and column values
   var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  })

  return (
    <div className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen
          ? fullscreen_heigthwidth
          : 'calc(100vh - 11rem)',
      }}>
      <AgGridReact
        rowData={list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openEditModal,
          openDeleteModal,
          fieldnameTooltip,
        }}
        onColumnResized={TransactionFieldStore.onGridChanged}
        onColumnMoved={TransactionFieldStore.onGridChanged}
        onColumnPinned={TransactionFieldStore.onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        onGridReady={setupGrid}
        onSwitchChange={onSwitchChange}
        gridOptions={{ ...LocalGridConfig.options, rowHeight: 30 }}
        onFilterChanged={onFilterChanged}
        onSortChanged={TransactionFieldStore.onGridChanged}
        onCellKeyDown={onCellKeyDown}
      />
    </div>
  );
});

export default ListComponent;
