import React from 'react';
import {
  FileManagerComponent,
  Inject,
  NavigationPane,
  DetailsView,
  Toolbar,
} from '@syncfusion/ej2-react-filemanager';
import axios from 'axios';
// import { useParams } from 'react-router';
import useStore from '../../store';
import { observer } from 'mobx-react';

export const getFileUrl = (filemanagerObj, data, deal_data) => {
  return axios
    .post(filemanagerObj.ajaxSettings.downloadUrl, data, {
      headers: {
        OrgID: deal_data?.lead_information?.sold_organisation?.id,
        LeadID: deal_data?.lead_information?.id,
      },
    })
    .then((data) => {
      return data;
    });
};

export const handleAttach = (
  FileManagerRef,
  selectedFiles,
  setSelectedFiles,
  deal_data
) => {
  if (setSelectedFiles) {
    const filemanagerObj = FileManagerRef.current;
    let all_files = filemanagerObj.getSelectedFiles();

    let selectedFiltesArr = [];
    let files = filemanagerObj?.selectedItems.filter((x, index) => {
      if (all_files && all_files[index]) {
        if (all_files[index].isFile && all_files[index].id) {
          selectedFiltesArr.push(
            `${all_files[index].id}|${all_files[index].name}|${all_files[index].type}`
          );
          return x;
        }
      }
      return false;
    });
    if (files) {
    }

    let myFirstPromise = new Promise((resolve, reject) => {
      setTimeout(function () {
        let newData = selectedFiles.concat(selectedFiltesArr);
        setSelectedFiles(newData);
        resolve(newData); // Yay! Everything went well!
      }, 100);
    });
    return myFirstPromise.then((data) => {
      return data;
    });
  }
};

export const removeAttachment = (selectedFiles, setSelectedFiles, index) => {
  setSelectedFiles(selectedFiles.filter((x, i) => index !== i));
  return new Promise((resolve, reject) => {
    resolve(selectedFiles.filter((x, i) => index !== i));
  });
};

const FileManager = observer(({ fileManagerRef, setDisableFileBtn }) => {
  const {
    DEALDESKSTORE: { deal_data, fetchFileFolderList },
  } = useStore();
  const FileManagerRef = fileManagerRef || React.createRef();

  const hostUrl = process.env.React_APP_API_URL + 'aws/s3/'; //
  let token = localStorage.getItem('token');

  // // File Manager Drag start event
  const onFileDragStart = (args) => {
  };
  // File Manager Drag start event
  const onFileDragStop = (args) => {
  };
  // File Manager Drag start event
  const onFileDragging = (args) => {
  };
  // File Manager Drag start event
  const onFileDropped = (args) => {
  };

  const beforeSend = (args) => {
    args.ajaxSettings.beforeSend = function (args) {
      args.httpRequest.setRequestHeader('Accept', 'application/json');
      args.httpRequest.setRequestHeader('Authorization', 'Bearer ' + token);
      args.httpRequest.setRequestHeader('fm', 'dm');
      if (deal_data?.lead_information?.sold_organisation?.id) {
        args.httpRequest.setRequestHeader(
          'OrgID',
          deal_data?.lead_information?.sold_organisation?.id
        );
      }
      if (deal_data?.lead_information?.id) {
        args.httpRequest.setRequestHeader('LeadID', deal_data?.lead_information?.id);
      }
    };
  };

  const ajaxSettings = {
    url: hostUrl + 'image/list/',
    //getImageUrl: hostUrl + 'api/FileManager/GetImage',
    uploadUrl: hostUrl + 'image/upload/',
    downloadUrl: hostUrl + 'image/download/',
  };

  const beforeDownload = (args) => {
    args.cancel = true;
    const filemanagerObj = FileManagerRef.current;
    var flag = filemanagerObj?.selectedItems?.length !== 0;
    let all_files = filemanagerObj.getSelectedFiles();
    let files = filemanagerObj?.selectedItems.filter((x, index) => {
      if (all_files && all_files[index]) {
        if (all_files[index].isFile) {
          return true;
        }
      }
      return false;
    });
    if (flag && files?.length > 0) {
      const data = {
        action: 'download',
        path: filemanagerObj.path,
        names: files,
        // data: filemanagerObj.getSelectedFiles(),
      };

      getFileUrl(filemanagerObj, data, deal_data).then((data) => {
        if (data.data && data.data.length > 0) {
          data.data.forEach((x) => {
            let anchor = document.createElement('a');
            anchor.href = x.url;
            anchor.download = x.name;
            anchor.target = '_blank';
            anchor.click();
          });
        }
      });
    }
  };


  const onFileUpdateRefreshFilelist = () => {
    fetchFileFolderList(deal_data?.lead_information?.id);
  };

  // const { id } = useParams()
  // let folder_path = '/';
  // if (
  //   deal_data?.lead_information?.sold_organisation?.id &&
  //   deal_data?.lead_information?.id
  // ) {
  //   folder_path =
  //     '/' +
  //     deal_data.lead_information?.organisation_name?.id +
  //     '/' +
  //     deal_data.lead_information?.id +
  //     '/';
  // }

  return (
    <div className='control-section'>
      <FileManagerComponent
        // path={id ? `/${id}/` : ''}
        ref={FileManagerRef}
        id='overview_file'
        beforeSend={beforeSend}
        beforeDownload={beforeDownload}
        ajaxSettings={ajaxSettings}
        view='LargeIcons'
        showThumbnail={false}
        fileDragStart={onFileDragStart}
        fileDragStop={onFileDragStop}
        fileDragging={onFileDragging}
        fileDropped={onFileDropped}
        fileSelect={(e) => {
          if (setDisableFileBtn) {
            if (
              FileManagerRef?.current &&
              FileManagerRef.current.getSelectedFiles() &&
              FileManagerRef.current.getSelectedFiles().length > 0
            ) {
              setDisableFileBtn(false);
            } else {
              setDisableFileBtn(true);
            }
          }
        }}
        success={onFileUpdateRefreshFilelist}
        toolbarSettings={{
          items: ['NewFolder', 'Upload', 'SortBy', 'Delete', 'Rename', 'Refresh'],
        }}
        contextMenuSettings={{
          file: ['Delete', 'Rename', 'Download'],
          folder: ['Delete', 'Rename'],
          layout: ['SortBy', 'View', 'Refresh', '|', 'NewFolder', 'Upload'],
        }}
      >
        <Inject services={[NavigationPane, DetailsView, Toolbar]} />
      </FileManagerComponent>
    </div>
  );
});

export default FileManager;
