import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react';

import InputComponent from './InputComponent';
import useStore from '../store';
import SaveGridFilter from './SaveGridFilter';
import { useTranslation } from 'react-i18next';

const ApplyGridFilterNew = observer((props) => {
  const [visibleSaveFilter, setvisibleSaveFilter] = useState(false);
  const [dataSaveFilter, setdataSaveFilter] = useState(null);

  const { t } = useTranslation();

  const { filterList } = props;
  const [form] = Form.useForm();
  const {
    AUTH: { allPageFiltersLoaded, getAllPageFilter },
    PEOPLE,
    PEOPLE: { setIsSavedFiltersLoadedPeople },
    ORGANISATION,
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    LEADSSTORE,
    TagsStore,
    TicketsStore,
    TicketsStore: { setIsSavedFiltersLoadedTicket },
    REMINDERSSTORE,
    REMINDERSSTORE: { setIsSavedFiltersLoadedRm },
    PROMOTIONSTORE,
    PROMOTIONSTORE: { setIsSavedFiltersLoadedPromotion },
    PRODUCTIVITYSTORE,
    PRODUCTIVITYSTORE: { setIsSavedFiltersLoadedProductivity },
    BLOCKEDITASSIGNSTORE,
    MANAGEMENTALLDEPARTMENTSSTORE,
    TagsStore: { setIsSavedFiltersLoadedTag },
  } = useStore();

  const { type, selectionValue } = props;

  const applyGridFilter = useCallback(
    (filterid) => {
      if (filterid) {
        let filterData = filterList.filter((x) => x.id === filterid);
        if (filterData && filterData.length > 0) {
          // let filterValue = filterData[0]?.value
          let filterValue = (filterData[0]?.value && ![99995, 99996, 99997, 99998, 99999, 2].includes(filterid))
            ? JSON.parse(filterData[0].value.replace(/[']/g, '')) : filterData[0]?.value;
          if (type === 'tickets') {
            // let filterstringstr = dynamicGridFilterId;
            // if (![99997, 99998, 99999, 2].includes(filterid)) {
            // If Not a static(appended) filter
            // filterstringstr = filterValue;
            // }
            TicketsStore.updateGridOnSavedFilter(filterValue);
            TicketsStore.setSelectedGridFilter(filterid);
          }
          if (type === 'people') {
            // let filterValue = filterValue;
            PEOPLE.updateGridOnSavedFilter(filterValue);
            PEOPLE.setSelectedGridFilter(filterid);
          }
          if (type === 'organisations') {
            let filterstringstr = filterValue;
            ORGANISATION.updateGridOnSavedFilter(filterstringstr);
            ORGANISATION.setSelectedGridFilter(filterid);
          }
          if (type === 'deals') {
            // let filterValue = filterValue;
            LEADDEALS.updateGridOnSavedFilter(filterValue);
            LEADDEALS.setSelectedGridFilter(filterid);
          }
          if (type === 'opportunity') {
            // let filterValue = filterValue;
            LEADOPPORTUNITY.updateGridOnSavedFilter(filterValue);
            LEADOPPORTUNITY.setSelectedGridFilter(filterid);
          }
          if (type === 'activeleads') {
            // let filterValue = filterValue;
            LEADACTIVE.updateGridOnSavedFilter(filterValue);
            LEADACTIVE.setSelectedGridFilter(filterid);
          }
          if (type === 'unusedleads') {
            // let filterValue = filterValue;
            LEADSSTORE.updateGridOnSavedFilter(filterValue);
            LEADSSTORE.setSelectedGridFilter(filterid);
          }
          if (type === 'tags') {
            // let filterValue = dynamicGridFilterId;
            // if (![99997, 2].includes(filterid)) {
            // If Not a static(appended) filter
            // filterValue = filterValue;
            // }
            TagsStore.updateGridOnSavedFilter(filterValue);
            TagsStore.setSelectedGridFilter(filterid);
          }

          if (type === 'reminder') {
            // let filterValue = dynamicGridFilterId;
            // if (![99996, 2].includes(filterid)) {
            // If Not a static(appended) filter
            // filterValue = JSON.parse(dynamicGridFilterId?.replace(/[']/g, ''));
            // }

            REMINDERSSTORE.updateGridOnSavedFilter(filterValue);
            REMINDERSSTORE.setSelectedGridFilter(filterid);
          }
          if (type === 'promotion') {
            // let filterValue = dynamicGridFilterId;
            // if (![99995, 2].includes(filterid)) {
            // If Not a static(appended) filter
            // filterValue = JSON.parse(filterValue);
            // }
            PROMOTIONSTORE.updateGridOnSavedFilter(filterValue);
            PROMOTIONSTORE.setSelectedGridFilter(filterid);
          }
          if (type === 'productivity') {
            // let filterValue = dynamicGridFilterId;
            // if (![2].includes(filterid)) {
            // filterValue = filterValue;
            // }

            PRODUCTIVITYSTORE.updateGridOnSavedFilter(filterValue);
            PRODUCTIVITYSTORE.setSelectedGridFilter(filterid);
          }
          if (type === 'blockeditassign') {
            // let filterValue = dynamicGridFilterId;
            // filterValue = filterValue;

            BLOCKEDITASSIGNSTORE.updateGridOnSavedFilter(filterValue);
            BLOCKEDITASSIGNSTORE.setSelectedGridFilter(filterid);
          }
          if (type === 'alldepartments') {
            // let filterValue = dynamicGridFilterId;
            // filterValue = filterValue;

            MANAGEMENTALLDEPARTMENTSSTORE.updateGridOnSavedFilter(filterValue);
            MANAGEMENTALLDEPARTMENTSSTORE.setSelectedGridFilter(filterid);
          }
        }
      }
    },
    [
      type,
      TicketsStore,
      PEOPLE,
      ORGANISATION,
      LEADDEALS,
      LEADOPPORTUNITY,
      LEADACTIVE,
      LEADSSTORE,
      filterList,
      TagsStore,
      REMINDERSSTORE,
      PROMOTIONSTORE,
      PRODUCTIVITYSTORE,
      BLOCKEDITASSIGNSTORE,
      MANAGEMENTALLDEPARTMENTSSTORE,
    ]
  );

  useEffect(() => {
    if (!allPageFiltersLoaded) {
      getAllPageFilter().then(() => {
        setIsSavedFiltersLoadedTag(true);
        setIsSavedFiltersLoadedTicket(true);
        setIsSavedFiltersLoadedRm(true);
        setIsSavedFiltersLoadedPeople(true);
        setIsSavedFiltersLoadedPromotion(true);
        setIsSavedFiltersLoadedProductivity(true);
      });
    }
  }, [
    type,
    allPageFiltersLoaded,
    getAllPageFilter,
    setIsSavedFiltersLoadedTag,
    setIsSavedFiltersLoadedTicket,
    setIsSavedFiltersLoadedRm,
    setIsSavedFiltersLoadedPeople,
    setIsSavedFiltersLoadedPromotion,
    setIsSavedFiltersLoadedProductivity,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      filtername: selectionValue,
    });
    applyGridFilter(selectionValue);
  }, [selectionValue, form, props.filterList, applyGridFilter]);

  const updateNewAddedFilter = (id) => {
    if (type === 'tickets') {
      TicketsStore.setSelectedGridFilter(id);
    }
    if (type === 'people') {
      PEOPLE.setSelectedGridFilter(id);
    }
    if (type === 'organisations') {
      ORGANISATION.setSelectedGridFilter(id);
    }
    if (type === 'deals') {
      LEADDEALS.setSelectedGridFilter(id);
    }
    if (type === 'activeleads') {
      LEADACTIVE.setSelectedGridFilter(id);
    }
    if (type === 'unusedleads') {
      LEADSSTORE.setSelectedGridFilter(id);
    }
    if (type === 'tags') {
      TagsStore.setSelectedGridFilter(id);
    }
    if (type === 'reminder') {
      REMINDERSSTORE.setSelectedGridFilter(id);
    }
    if (type === 'promotion') {
      PROMOTIONSTORE.setSelectedGridFilter(id);
    }
    if (type === 'productivity') {
      PRODUCTIVITYSTORE.setSelectedGridFilter(id);
    }
    if (type === 'alldepartments') {
      MANAGEMENTALLDEPARTMENTSSTORE.setSelectedGridFilter(id);
    }
    if (type === 'blockeditassign') {
      BLOCKEDITASSIGNSTORE.setSelectedGridFilter(id);
    }
  };

  const openSaveFilterPopup = (type) => {
    setvisibleSaveFilter(true);
    if (type === 'tickets') {
      setdataSaveFilter({ page: type, value: TicketsStore.agGrid.api.getFilterModel() });
    } else if (type === 'people') {
      setdataSaveFilter({ page: type, value: PEOPLE.agGrid.api.getFilterModel() });
    } else if (type === 'organisations') {
      setdataSaveFilter({ page: type, value: ORGANISATION.agGrid.api.getFilterModel() });
    } else if (type === 'deals') {
      setdataSaveFilter({ page: type, value: LEADDEALS.agGrid.api.getFilterModel() });
    } else if (type === 'opportunity') {
      setdataSaveFilter({
        page: type,
        value: LEADOPPORTUNITY.agGrid.api.getFilterModel(),
      });
    } else if (type === 'activeleads') {
      setdataSaveFilter({
        page: type,
        value: LEADACTIVE.agGrid.api.getFilterModel(),
      });
    } else if (type === 'unusedleads') {
      setdataSaveFilter({
        page: type,
        value: LEADSSTORE.agGrid.api.getFilterModel(),
      });
    } else if (type === 'tags') {
      setdataSaveFilter({
        page: type,
        value: TagsStore.agGrid.api.getFilterModel(),
      });
    } else if (type === 'reminder') {
      setdataSaveFilter({
        page: type,
        value: REMINDERSSTORE.agGrid.api.getFilterModel(),
      });
    } else if (type === 'promotion') {
      setdataSaveFilter({
        page: type,
        value: PROMOTIONSTORE.agGrid.api.getFilterModel(),
      });
    } else if (type === 'productivity') {
      setdataSaveFilter({
        page: type,
        value: PRODUCTIVITYSTORE.agGrid.api.getFilterModel(),
      });
    } else if (type === 'alldepartments') {
      setdataSaveFilter({
        page: type,
        value: MANAGEMENTALLDEPARTMENTSSTORE.agGrid.api.getFilterModel(),
      });
    } else if (type === 'blockeditassign') {
      setdataSaveFilter({
        page: type,
        value: BLOCKEDITASSIGNSTORE.agGrid.api.getFilterModel(),
      });
    } else {
      setdataSaveFilter({ page: type, value: {} });
    }
  };

  const closeSaveFilterPopup = () => {
    setvisibleSaveFilter(false);
    setdataSaveFilter(null);
  };

  const onChange = () => {
    if (form.getFieldValue('filtername') === 0) {
      openSaveFilterPopup(type);
      if (type === 'tickets') {
        TicketsStore.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'people') {
        PEOPLE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'organisations') {
        ORGANISATION.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'deals') {
        LEADDEALS.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'opportunity') {
        LEADOPPORTUNITY.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'activeleads') {
        LEADACTIVE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'unusedleads') {
        LEADSSTORE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'tags') {
        TagsStore.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'reminder') {
        REMINDERSSTORE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'promotion') {
        PROMOTIONSTORE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (type === 'productivity') {
        PRODUCTIVITYSTORE.setSelectedGridFilter(
          form.setFieldsValue({ filtername: null })
        );
      }
      if (type === 'alldepartments') {
        MANAGEMENTALLDEPARTMENTSSTORE.setSelectedGridFilter(
          form.setFieldsValue({ filtername: null })
        );
      }
      if (type === 'blockeditassign') {
        BLOCKEDITASSIGNSTORE.setSelectedGridFilter(
          form.setFieldsValue({ filtername: null })
        );
      }
    } else {
      applyGridFilter(form.getFieldValue('filtername'));
    }
  };

  return (
    <>
      <Form
        id='applyfilter'
        form={form}
        layout={'vertical'}
        className={
          ['promotion', 'productivity', 'blockeditassign', 'alldepartments'].includes(
            type
          )
            ? 'innerForm' //-----------GRID TOP FILTER FOR DYAMIC SIZE------------------//
            : 'innerForm fixDropdown' //-----------FIX WIDTH------------------//
        }
      >
        <InputComponent
          name='filtername'
          type='select'
          placeholder={`${t('Common_Data.Select_Filter')}`}
          showSearch={true}
          allowClear={true}
          onClear={() => {
            form.resetFields();
            if (type === 'tickets') {
              TicketsStore.handleReset();
            }
            if (type === 'people') {
              PEOPLE.handleReset();
            }
            if (type === 'organisations') {
              ORGANISATION.handleReset();
            }
            if (type === 'deals') {
              LEADDEALS.handleReset();
            }
            if (type === 'activeleads') {
              LEADACTIVE.handleReset();
            }
            if (type === 'unusedleads') {
              LEADSSTORE.handleReset();
            }
            if (type === 'tags') {
              TagsStore.handleReset();
            }
            if (type === 'reminder') {
              REMINDERSSTORE.handleReset();
            }
            if (type === 'promotion') {
              PROMOTIONSTORE.handleReset();
            }
            if (type === 'productivity') {
              PRODUCTIVITYSTORE.handleReset();
            }
            if (type === 'blockeditassign') {
              BLOCKEDITASSIGNSTORE.handleReset();
            }
          }}
          onChange={onChange}
          options={{
            values: filterList,
            value_key: 'id',
            text_key: 'filter',
          }}
          dropdownClassName={'globalGridFilterList'}
        />
      </Form>
      <SaveGridFilter
        visible={visibleSaveFilter}
        dataSaveFilter={dataSaveFilter}
        close={closeSaveFilterPopup}
        updateNewAddedFilter={updateNewAddedFilter}
        isCommonAPICall={true}
      />
    </>
  );
});

export default ApplyGridFilterNew;
