import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TopSelection from './component/TopSelection';
import ListComponent from './component/ListComponent';
import ChartComponent from './component/ChartComponent';
import { Divider } from 'antd';
import useStore from '../../store';
import Unauthorized from '../Unauthorized';
import { default as EditTicket } from '../Tickets/component/EditComponentNew';
import { default as ViewTicket } from '../Tickets/component/ViewComponent';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';

const ManagementAllDepartments = observer(() => {
  let expandVal = false;
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  // States added for resolving zindex issue
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [expand, setexpand] = useState(false);
  // ----------------------------------------//

  const [id, setId] = useState();
  const { SETTINGS, AUTH, TicketsStore, MANAGEMENTALLDEPARTMENTSSTORE } = useStore();
  const openEditDrawer = (data, isViewOpen = false) => {
    setViewOpen(isViewOpen);
    TicketsStore.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setViewOpen(false);
  };

  //View drawer
  const openViewDrawer = (data, isEditOpen = false) => {
    if (AUTH.checkPermission(15, 'view-details')) {
      setEditOpen(isEditOpen);
      setId(data);
      //TicketsStore.setViewValues(data);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => {
    TicketsStore.viewValues = null;
    setId(null);
    setViewDrawer(false);
    setEditOpen(false);
  };

  const expandGroup = () => {
    if (expandVal) {
      MANAGEMENTALLDEPARTMENTSSTORE.onCollapseAllGroups();
      setexpand(false);
    } else {
      MANAGEMENTALLDEPARTMENTSSTORE.onExpandAllGroups();
      setexpand(true);
    }

    expandVal = !expandVal;
  };

  return (
    <>
      {!AUTH.checkPermission(6, 'list') ? (
        <Unauthorized />
      ) : (
        <div className={SETTINGS.fullscreen_class}>
          {AUTH.checkPermission(6, 'charts') && <ChartComponent />}
          <Divider className='mt-10 mb-10' />
          <TopSelection />
          <ListComponent
            openViewDrawer={openViewDrawer}
            openEditDrawer={openEditDrawer}
            expandGroup={expandGroup}
            expand={expand}
          />
          <ViewTicket
            visible={viewDrawer}
            id={id}
            editOpen={editOpen}
            setId={setId}
            close={closeViewDrawer}
            openEditDrawer={openEditDrawer}
          />
          <EditTicket
            visible={editDrawer}
            viewOpen={viewOpen}
            close={closeEditDrawer}
            openViewDrawer={openViewDrawer}
          />
          {/* <TrackTimeComponent pageName={'all_departments'} /> */}
          <TimeTracker stop={ viewDrawer || editDrawer } />
        </div>
      )}
    </>
  );
});

export default ManagementAllDepartments;
