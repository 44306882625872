import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class MergeLeadsStore {
    per_page = LocalGridConfig.options.paginationPageSize;
    agGrid = null;

    setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
        this.per_page = page;
        this.agGrid.api.paginationSetPageSize(parseInt(page));
    };

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params;
    };

    // Set column width after resizing colums

}

decorate(MergeLeadsStore, {
    per_page: observable,
    agGrid: observable,

    setPageSize: action,
    setupGrid: action,

});
