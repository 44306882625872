import React, { useState, useEffect } from 'react';
import { Form, Button, Drawer, Spin } from 'antd';
import { observer } from 'mobx-react';
import { vsmPeopleRoleMapping, vsmNotify } from '../../../../config/messages';
import useStore from '../../../../store';
import FormComponent from './FormComponent';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [rPeopleData, setRPeopleData] = useState(null);
  const [currentTab, setCurrentTab] = useState('People');
  const [fetchRoles, setFetchRoles] = useState(true);
  const [fetchRelatedRoles, setFetchRelatedRoles] = useState(true);
  const [fetchTags, setFetchTags] = useState(true);
  const [loading, setLoading] = useState(true);
  const [rejectedkeys, setRejectedKeys] = useState();
  const { ORGANISATION, ORGMAPPINGSTORE, PEOPLE, PEOPLEMAPPINGSTORE } = useStore();

  // make a fuction to call to edit record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = PEOPLEMAPPINGSTORE.editValues.id;
    data.entity_type = 2;
    data.entity_id = props.parent_id;
    if (form.getFieldValue('related_entity_type') === 'Ledger') {
      data.related_entity_id = null;
      data.related_role_id = null;
    }
    PEOPLEMAPPINGSTORE.EditData(data)
      .then(() => {
        close();
        if (props.handleViewDetailCall) {
          props.handleViewDetailCall({ id: props.parent_id });
        }
        vsmNotify.success({
          message: vsmPeopleRoleMapping.edit
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // set data on refresh page
  useEffect(() => {
    if (props.visible) {
      setLoading(true);
      var payload = {
        people_id: props.parent_id && parseInt(props.parent_id),
        mapping_type: 2
      };
      PEOPLEMAPPINGSTORE.getRecordById(props.mappingId, payload)
        .then((data) => {
          PEOPLE.getPeopleCardValue(data.data.entity_id, 'people');
          setLoading(false);
        })
        .catch((data) => {
          vsmNotify.error({ message: `${t('PeopleManagement.Invalid')}` });
          setLoading(false);
        });
    }
  }, [props.visible, props.parent_id, props.mappingId, PEOPLEMAPPINGSTORE, PEOPLE, t]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = async () => {
    if (currentTab !== form.getFieldValue('related_entity_type')) {
      await form.resetFields([
        'related_people_id',
        'related_role_id',
        'related_entity_id'
      ]);
      ORGANISATION.rOrganisationValues = null;
      setRPeopleData(null);
      setFetchRelatedRoles(true);
      setCurrentTab(form.getFieldValue('related_entity_type'));
    }
    setRejectedKeys(form.getFieldValue('role_id'));
    validateData();
  };

  // call to validate fields
  const validateData = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  // reset form and close add form
  const close = () => {
    ORGMAPPINGSTORE.setEditValues(null);
    form.resetFields();
    setDisabled(true);
    setCurrentTab('People');
    PEOPLEMAPPINGSTORE.setEditValues(null);
    ORGANISATION.rOrganisationValues = null;
    ORGANISATION.dropdown_ROrg_list = null;
    PEOPLE.peopleCardValue = null;
    PEOPLE.rPeopleCardValue = null;
    PEOPLE.rPeopleValues = null;
    setFetchRoles(true);
    setFetchRelatedRoles(true);
    setFetchTags(true);
    setRejectedKeys();
    ORGMAPPINGSTORE.dropdown_tags_list = null;
    props.close();
  };

  return (
    <Drawer
      destroyOnClose={true}
      title={`${t('Common_Data.Edit')} ${t('Common_Data.People')} ${t('PeopleManagement.Mapped')} ${t('OrganisationManagement.Roles')}`}
      // title={`Edit Mapping of Organisation/People with ${
      //   PEOPLE.peopleValues && '- ' + PEOPLE.peopleValues.first_name
      // } ${
      //   PEOPLE.peopleValues && PEOPLE.peopleValues.last_name
      //     ? '- ' + PEOPLE.peopleValues.last_name
      //     : ''
      // }`}
      width={'1050px'}
      visible={props.visible}
      onClose={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <div className='text-right' key='1'>
          <Button
            key='1'
            disabled={disabled}
            form='mapaddeditform'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='mediumn'
            className='mr-10'
          >
            {t('Common_Data.Update')}
          </Button>
          <Button
            key='2'
            htmlType='button'
            shape='round'
            size='mediumn'
            onClick={() => {
              close();
            }}
          >
            {t('Common_Data.Cancel')}
          </Button>
        </div>
      ]}
    >
      <Spin size='large' spinning={loading} tip={`${t('Common_Data.Load')}`}>
        <FormComponent
          form={form}
          handleSubmit={handleSubmit}
          onChange={handleChange}
          currentTab={currentTab}
          fetchRoles={fetchRoles}
          fetchRelatedRoles={fetchRelatedRoles}
          fetchTags={fetchTags}
          setFetchRoles={setFetchRoles}
          setFetchRelatedRoles={setFetchRelatedRoles}
          setFetchTags={setFetchTags}
          rPeopleData={rPeopleData}
          setRPeopleData={setRPeopleData}
          visible={props.visible}
          id={props.parent_id}
          setCurrentTab={setCurrentTab}
          setRejectedKeys={setRejectedKeys}
          rejectedkeys={rejectedkeys}
          type='edit'
        />
      </Spin>
    </Drawer>
  );
});

export default EditComponent;
