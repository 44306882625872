import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
// import ListComponent from '../../Tickets/component/ListComponent';
import ViewComponent from '../../Tickets/component/ViewComponent';
import EditComponent from '../../Tickets/component/EditComponentNew';
import DeleteComponent from '../../Tickets/component/DeleteComponent';
import useStore from '../../../store';
import Unauthorized from '../../Unauthorized';
// import { vsmNotify } from '../../../config/messages';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
// import { useTranslation } from 'react-i18next';
import TicketSearchListing from './TicketSearchListing';
import TimeTracker from '../../../component/TimeTracker';

const TicketsSearch = observer((props) => {

  // const { t } = useTranslation()
  // const [addDrawer, setAddDrawer] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  // const [cloneDrawer, setCloneDrawer] = useState(false);
  const [id, setId] = useState();

  // States added for resolving zindex issue
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  // ----------------------------------------//

  // const [showCheckbox, setshowCheckbox] = useState(false);

  const { SearchPage, AllView, sectorSelector,
    //  search_term, resultCount, handleRoute,
      selectionChanged, showCheckbox } = props;
  const {
    TicketsStore,
    AUTH: { allPageFilters, allPageFiltersLoaded },
    AUTH,
    // DEALDESKSTORE: { stopInvestmentHourCounter, setStopInvestmentHourCounter },
  } = useStore();

  const openEditDrawer = (data, isViewOpen = false) => {
    setViewOpen(isViewOpen);
    // setStopInvestmentHourCounter(true);
    TicketsStore.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    // setStopInvestmentHourCounter(false);
    setEditDrawer(false);
    setViewOpen(false);
  };
  //View drawer
  const openViewDrawer = (data, isEditOpen = false) => {
    if (AUTH.checkPermission(15, 'view-details')) {
      setEditOpen(isEditOpen);
      setId(data);
      // setStopInvestmentHourCounter(true);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => {
    // TicketsStore.viewValues = null;
    // setStopInvestmentHourCounter(false);
    setId(null);
    setViewDrawer(false);
    setEditOpen(false);
  };


  //open delet drawer
  const openDeleteDrawer = (data) => {
    TicketsStore.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //confirmation alert before deleting existing user & edit
  const onCellKeyDown = (e) => {
    if (AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(15, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }
          if (AUTH.checkPermission(15, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteDrawer(e.data);
            }
          }
        }
      }
    }
  };
  useEffect(() => { }, [allPageFiltersLoaded, allPageFilters]);

  return (
    <>
      {!AUTH.checkPermission(15, 'list') ?
        <Unauthorized />
        :
        <>
          <TicketSearchListing
            openViewDrawer={openViewDrawer}
            openEditDrawer={openEditDrawer}
            openDeleteDrawer={openDeleteDrawer}
            // openCloneDrawer={openCloneDrawer}
            SearchPage={SearchPage}
            onCellKeyDown={onCellKeyDown}
            selectionChanged={selectionChanged}
            showCheckbox={showCheckbox}
            AllView={AllView}
            sectorSelector={sectorSelector}
          />
          <ViewComponent
            visible={viewDrawer}
            id={id}
            editOpen={editOpen}
            setId={setId}
            close={closeViewDrawer}
            openEditDrawer={openEditDrawer}
          />
          <EditComponent
            visible={editDrawer}
            viewOpen={viewOpen}
            close={closeEditDrawer}
            openViewDrawer={openViewDrawer}
            SearchPage={SearchPage}
          />
          <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} SearchPage={SearchPage} />
          {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={SearchPage ? 'search' : 'tickets'} />} */}
          <TimeTracker
            page={"tickets"}
            stop={viewDrawer || editDrawer}
          />
        </>
      }
    </>
  );
});

export default TicketsSearch;
