import React from 'react';
import { /* Button, DatePicker, */ Form, Input, InputNumber, Select } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import useStore from '../../../../store';
import {
  calculateHeight,
  // firstPositionItemKey,
  focusArray,
} from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const ScoreContent = ({
  handleFormChange,
  ChangeNode,
  AddNode,
  GeneratePercentage,
  Score = [],
  form,
  RemoveNode,
  SetScore,
  ExchangeNode,
  editable = true,
  onlyForViewWithNostyle,
  scrollToNode,
  saveType,
  autoSaveData,
  INITIAL_HEIGHT,
  resetPosition,
  isCheckBoxSelection,
  selectedLibraryItemNode,
  setSelectedLibraryItemNode,
  isFromLibrary
}) => {
  // Variables -------------------
  const { AUTH } = useStore();
  // Functions -------------------

  // const onSortStart = () => {
  //   // let timer = null;
  //   // timer = localStorage.getItem('scheduleAutoSaveData');
  //   // if (timer) {
  //   //     clearTimeout(Number(timer));
  //   // }
  // };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (Score && Score[0]) {
      let ItemKey = Score[0].item_key;
      ExchangeNode(ItemKey, { oldIndex, newIndex });
    }
    //SetScore(arrayMove(Score, oldIndex, newIndex));
  };

  const getCharacterLength = () => {
    let lengthLimit = 170;
    if (AUTH?.user?.bonus_figures) {
      if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
        lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
      }
    }
    return lengthLimit;
  };

  // Markup ----------------------

  // set menu icon component
  const DragHandle = SortableHandle(() => (
    <div className='dragger'>
      <MenuOutlined />
    </div>
  ));
  const { t } = useTranslation();
  const SortableItem = SortableElement(({ value: { ScoreItem, index } }) => {
    return (
      <div
        className={`fields_draggable_wrapper ${isFromLibrary ? 'extraZindex' : ''}`}
        id={`${saveType}_innerContent_${ScoreItem.item_key}`}
      >
        <table
          width='1000px'
          border={0}
          cellPadding={0}
          cellSpacing={0}
          className='box-RightScheduleList scoreLibrary'
        >
          <tr>
            {/* {isCheckBoxSelection ? (
                            <>
                                <td width={'2%'}>
                                    <Checkbox
                                        checked={
                                            selectedLibraryItemNode &&
                                                selectedLibraryItemNode.includes(ScoreItem.item_key)
                                                ? true
                                                : false
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                if (!selectedLibraryItemNode.includes(ScoreItem.item_key)) {
                                                    setSelectedLibraryItemNode([
                                                        ...selectedLibraryItemNode,
                                                        ScoreItem.item_key,
                                                    ]);
                                                }
                                            } else {
                                                let tempSlection = selectedLibraryItemNode.filter(
                                                    (x) => x !== ScoreItem.item_key
                                                );
                                                setSelectedLibraryItemNode(tempSlection);
                                            }
                                        }}
                                    />
                                </td>
                            </>
                        ) : null} */}
            {/* <td width={'20px'} className='draggerTD'>
              {editable && <DragHandle />}
            </td> */}
            {ScoreItem.parentNode ? (
              <>
                <td width={'20px'} className='draggerTD'>
                  {editable && <DragHandle />}
                </td>
                <td width={'130px'} className='dropDown' valign='middle'>
                  <Form.Item noStyle>
                    <Select
                      onChange={(value) => {
                        ChangeNode(ScoreItem.item_key, value, 'focus');
                      }}
                      disabled={!editable}
                      value={ScoreItem.focus}
                      className='days'
                      allowClear
                      showArrow
                    >
                      {focusArray.map((obj, idx) => {
                        return (
                          <Select.Option key={idx} value={obj}>
                            {obj}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </td>
              </>
            ) : (<>
              <td width={'20px'}> &nbsp;</td>
              <td width={'130px'} className='draggerTD text-right'>
                {editable && <DragHandle />}
              </td>
            </>)
            }

            <td
              width={ScoreItem?.children?.length > 0 ? '90px' : '90px'}
              className='data'
              valign='middle'
            >
              <Form.Item noStyle>
                <Input
                  className='days'
                  disabled={!editable}
                  value={ScoreItem.feature}
                  onChange={(e) => {
                    ChangeNode(ScoreItem.item_key, e.target.value, 'feature');
                  }}
                />
              </Form.Item>
            </td>
            <td width={'460x'} className='data' valign='middle'>
              <Form.Item noStyle>
                {onlyForViewWithNostyle ? (
                  // <div style={{ height: 'auto' }} className='text'>
                  //   {ScoreItem.detail}
                  // </div>
                  <TextArea
                    maxLength={getCharacterLength()}
                    disabled={true}
                    defaultValue={ScoreItem.detail}
                    className='text'
                    style={{ height: ScoreItem?.height }}
                  />
                ) : (
                  <TextArea
                    maxLength={getCharacterLength()}
                    disabled={!editable}
                    id={`${saveType}_innerContent_textarea_${ScoreItem.item_key}`}
                    defaultValue={ScoreItem.detail}
                    className='text'
                    style={{ height: ScoreItem?.height }}
                    // onChange={() => {
                    //   handleFormChange();
                    // }}
                    onChange={(e) => {
                      let HeightStyle = calculateHeight(
                        `${saveType}_innerContent_textarea_${ScoreItem.item_key}`,
                        INITIAL_HEIGHT,
                        null,
                        true
                      );
                      ChangeNode(ScoreItem.item_key, HeightStyle, 'height');
                      ChangeNode(ScoreItem.item_key, e.target.value, 'detail');
                    }}
                    onBlur={() => {
                      localStorage.setItem(
                        'last_focusedElementScore',
                        ScoreItem.item_key
                      );
                    }}
                  />
                )}
              </Form.Item>
            </td>
            <td width={'60px'} className='data' valign='middle'>
              {!ScoreItem?.children?.length > 0 ? (
                <Form.Item noStyle>
                  <InputNumber
                    className='days'
                    id={`${saveType}_innerContent_min_${ScoreItem.item_key}`}
                    min={0}
                    max={365}
                    formatter={(value) =>
                      typeof value === 'number' ? parseInt(value) : value
                    }
                    value={ScoreItem.min}
                    disabled={ScoreItem?.children?.length > 0 || !editable}
                    // onChange={() => {
                    //   handleFormChange();
                    // }}
                    onChange={(value) => {
                      setTimeout(() => {
                        ChangeNode(ScoreItem.item_key, value ? value : 0, 'min');
                      }, 0);
                    }}
                  />
                </Form.Item>
              ) : ""}
            </td>

            <td width={'60px'} className='data' valign='middle'>
              {!ScoreItem?.children?.length > 0 ? (
                <Form.Item noStyle>
                  <InputNumber
                    className='days'
                    id={`${saveType}_innerContent_max_${ScoreItem.item_key}`}
                    min={0}
                    max={365}
                    formatter={(value) =>
                      typeof value === 'number' ? parseInt(value) : value
                    }
                    value={ScoreItem.max}
                    disabled={ScoreItem?.children?.length > 0 || !editable}
                    onChange={(value) => {
                      setTimeout(() => {
                        ChangeNode(ScoreItem.item_key, value ? value : 0, 'max');
                      }, 0);
                    }}
                  />
                </Form.Item>
              ) : ""}
            </td>

            {!ScoreItem?.children?.length > 0 ? (
              <td width={'60px'} className='data' align='center' valign='middle'>
                <Form.Item noStyle>
                  {!ScoreItem.isPositive ? (
                    <img
                      src={AUTH.GetThemedImage('remove')}
                      alt={`${t('Common_Data.Remove')}`}
                      className={'icon circle cursorPointer'}
                      onClick={() => {
                        if (editable) {
                          ChangeNode(
                            ScoreItem.item_key,
                            !ScoreItem.isPositive,
                            'isPositive'
                          );
                        }
                      }}
                    />
                  ) : (
                    <img
                      src={AUTH.GetThemedImage('add')}
                      alt={`${t('Common_Data.Add')}`}
                      className='icon cursorPointer'
                      onClick={() => {
                        if (editable) {
                          ChangeNode(
                            ScoreItem.item_key,
                            !ScoreItem.isPositive,
                            'isPositive'
                          );
                        }
                      }}
                    />

                  )}
                </Form.Item>
              </td>
            ) : (
              <td width={'60px'} className='data'></td>
            )}
            {!ScoreItem?.children?.length > 0 ? (
              <td width={'60px'} className='data' valign='middle'>
                <Form.Item noStyle>
                  <Input
                    className='days'
                    onChange={(e) => {
                      ChangeNode(ScoreItem.item_key, e.target.value, 'weight');
                    }}
                    defaultValue={ScoreItem.weight}
                  />
                </Form.Item>
              </td>
            ) : (
              <td width={'60px'} className='data'></td>
            )}

            {editable && (
              <td width={'60px'} align='center' className='actionTD text-right pr-5'>
                {(ScoreItem.item_key + '').split('-').length < 2 && (
                  <img
                    src={AUTH.GetThemedImage('add')}
                    alt={`${t('Common_Data.Add')}`}
                    className='icon cursorPointer mr-5 ml-5'
                    onClick={() => {
                      AddNode(ScoreItem.item_key, false);
                      scrollToNode(`${saveType}_innerContent_${ScoreItem.item_key}`);
                    }}
                  />
                )}
                <img
                  src={AUTH.GetThemedImage('remove')}
                  alt={`${t('Common_Data.Remove')}`}
                  className={'mr-5 icon circle cursorPointer'}
                  onClick={() => {
                    RemoveNode(ScoreItem.item_key);
                  }}
                // onClick={() => {
                //   // RemoveNode(ScoreItem.item_key);
                //   // autoSaveData();
                // }}
                />
              </td>
            )}
          </tr>
          {ScoreItem?.children?.length > 0 ? (
            <tr>
              {/* <td className='draggerTD'></td> */}
              <td colSpan={9} className="childTable">
                <ScoreContent
                  handleFormChange={handleFormChange}
                  ChangeNode={ChangeNode}
                  GeneratePercentage={GeneratePercentage}
                  AddNode={AddNode}
                  Score={ScoreItem.children}
                  RemoveNode={RemoveNode}
                  form={form}
                  SetScore={SetScore}
                  ExchangeNode={ExchangeNode}
                  saveType={saveType}
                  editable={editable}
                  onlyForViewWithNostyle={onlyForViewWithNostyle}
                  scrollToNode={scrollToNode}
                  autoSaveData={autoSaveData}
                  INITIAL_HEIGHT={INITIAL_HEIGHT}
                  isCheckBoxSelection={isCheckBoxSelection}
                  selectedLibraryItemNode={selectedLibraryItemNode}
                  setSelectedLibraryItemNode={setSelectedLibraryItemNode}
                  isFromLibrary={isFromLibrary}
                />
              </td>
            </tr>
          ) : null}
        </table>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => (
    <div className='fields_draggable_container' id='field_draggable_container_schedule'>
      {items &&
        items.map((ScoreItem, index) => (
          <SortableItem key={index} index={index} value={{ ScoreItem, index }} />
        ))}
    </div>
  ));

  return (
    <>
      <SortableList
        // onSortStart={onSortStart}
        useDragHandle={true}
        items={Score}
        onSortEnd={onSortEnd}
      />
    </>
  );
};

export default ScoreContent;
