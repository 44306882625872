import React, { useState } from 'react';
import { Button, Popover, Space, Spin } from 'antd';
import useStore from '../../../../store';
import EditComponent from '../../component/EditComponent';
import DeleteComponent from '../../component/DeleteComponent';
import { useTranslation } from 'react-i18next';

const CardTemplate = (props) => {
  const {t} = useTranslation();

  //--------------------- Stores --------------------------------//

  const { LEADSTATUS, AUTH } = useStore();

  //-------------------------------------------------------------//

  //--------------------- Use States ----------------------------//

  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);

  //-------------------------------------------------------------//
  //----------------------Theme Color----------------------------//

  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }
  //-------------------------------------------------------------//

  //---------------------- Constants----------------------------//

  const { modified_record_type, status_name, record_type_actual } = props;

  const DropdownBox =
    require(`../../../../assets/images/${themecolor}/DropdownBox.svg`).default;
  const content = (
    <div className='ticketsPopoverIcons'>
      <Space size={0} wrap className='KanbanIcons'>
        {AUTH.checkPermission(30, 'edit') && (
          <Button
            type='text'
            title={`${t('Common_Data.Edit')}`}
            onClick={() => openEditDrawer(props)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
          </Button>
        )}
        {AUTH.checkPermission(30, 'delete') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            size='small'
            onClick={() => openDeleteDrawer(props)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        )}
      </Space>
    </div>
  );

  //-------------------------------------------------------------//
  //----------------- Open Close Modal Functions ----------------//

  const openEditDrawer = (data) => {
    LEADSTATUS.setEditValues(data);
    setEditDrawer(true);
  };

  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);

  //open delete drawer
  const openDeleteDrawer = (data) => {
    setDeleteDrawer(true);
    LEADSTATUS.setDeleteValues(data);
  };
  //close delet drawer
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //-------------------------------------------------------------//

  return (
    <>
      {props ? (
        <div className={'card-template'}>
          <div className='e-card-header'>
            <div className='e-card-header-caption'>
              <div className='e-card-header-title e-tooltip-text'>
                <span >{status_name}</span>
                <span className='icon'>
                  <Popover
                    content={content}
                    placement='bottomRight'
                    trigger='click'
                    overlayClassName='profileMenu'
                    arrowPointAtCenter
                  >
                    <Button type='text'>
                      <img className='menuicon' alt='' src={DropdownBox} />
                    </Button>
                  </Popover>
                </span>
              </div>
            </div>
          </div>
          <div className='e-card-content e-tooltip-text'>
            <div className='e-text' title={record_type_actual.replace(/,/gi, "\n")}>
              {modified_record_type}
            </div>
          </div>
        </div>
      ) : (
        <Spin />
      )}

      {/* Modals For Edit/Delete */}

      <EditComponent
        visible={editDrawer}
        close={closeEditDrawer}
      />
      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
      />

      {/*  */}
    </>
  );
};
export default CardTemplate;
