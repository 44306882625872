import Axios from 'axios';
//import { data } from 'jquery';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { convertTextToID } from '../../utils/GlobalFunction';
import { reset, clear } from '../../utils/ResetClearButton';

export default class ProductivityStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  extraFilter = null;
  dropdown_users_list = null;

  selectedMasterTicket = [];

  DEFAULT_GRID_FILTER_ID = 9999596;
  selectedGridFilter = localStorage.getItem('savedFilters')
    ? JSON.parse(localStorage.getItem('savedFilters'))?.PRODUCTIVITYSTORE
      ? JSON.parse(localStorage.getItem('savedFilters'))?.PRODUCTIVITYSTORE
      : null
    : 9999596;

  isSavedFiltersLoadedProductivity = false;  // Used for Twice api call

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.PRODUCTIVITYSTORE) {
        params = { request: temp.PRODUCTIVITYSTORE };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PRODUCTIVITYSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          PRODUCTIVITYSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('productivity_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
    let param = this.getFilter(params);
    let user_name_fullname = localStorage.getItem('user_name_fullname');
    let filter = {
      'holder': { values: [user_name_fullname], filterType: 'set' },
      ...param?.request?.filter,
    };

    params.api.setFilterModel(filter);
    params.api.setSortModel(param?.request?.sort);

    var datasource = this.createDatasource(GridConfig.options);
    if (this.isSavedFiltersLoadedProductivity) {
      api.setServerSideDatasource(datasource);
    } else {
      if (localStorage.getItem('isSavedFiltersFetching')) {
        setTimeout(() => {
          api.setServerSideDatasource(datasource);
        }, 2000);
      }
    }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('productivity_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }
        var filter_data = params.request.filterModel;
        if (filter_data['created_by_name']) {
          filter_data['created_by_name'].values = convertTextToID(
            filter_data['created_by_name'],
            this.dropdown_users_list,
            'name',
            'id'
          );
        }
        if (filter_data['holder']) {
          filter_data['holder'].values = convertTextToID(
            filter_data['holder'],
            this.dropdown_users_list,
            'name',
            'id'
          );
        }

        var payload = {
          filter_data: params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        let payloadMerged = { ...payload, ...this.extraFilter };
        this.setFilter(params.api);
        if (this.isSavedFiltersLoadedProductivity) {
          this.getList(payloadMerged).then((data) => {
            if (data.total === 0) {
              this.agGrid.api.showNoRowsOverlay();
            } else {
              this.agGrid.api.hideOverlay();
            }
            params.successCallback(data.data, data.total);
            var allColumnIds = [];
            let columnConfig = localStorage.getItem('promotion_grid');
            if (this.agGrid && this.agGrid.columnApi && columnConfig) {
              if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
                this.agGrid.columnApi.applyColumnState({
                  state: JSON.parse(columnConfig),
                });
              }
            } else {
              if (this.agGrid && this.agGrid.columnApi && data.total) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                  if (!['actions'].includes(column.colId)) {
                    allColumnIds.push(column.colId);
                  }
                });
                this.agGrid.columnApi.autoSizeColumns(allColumnIds);
              }
            }
          });
        }
      },
    };
  };

  getList = (payload = {}) => {
    return Axios.post(`deal-desk-productivity/tag-ticket/list`, payload).then(
      ({ data }) => {
        if (data.data.length) {
          data.data.forEach((item, index) => {
            if (item.type === 'tag') {
              item.tag_ticket_number = item.tag_number;
            } else {
              item.tag_ticket_number = item.ticket_number;
            }

            item.holder = null;
            item.holder_id = null;
            if (item?.assigned_to?.assigned_to_user) {
              item.holder =
                item?.assigned_to?.assigned_to_user?.first_name +
                ' ' +
                item?.assigned_to?.assigned_to_user?.last_name;
              item.holder_id = item?.assigned_to?.assigned_to_user?.id;
            }
          });
        }
        this.list_data = data.data;
        this.total = data.total;
        this.current_page = data.current_page;
        return data;
      }
    );
  };

  bulkAssignUpdate = (payload = {}) => {
    return Axios.post(`leadstickets/update-tickets-tags-users`, payload)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
          this.agGrid.api.deselectAll();
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  bulkDeleteUpdate = (payload = {}) => {
    return Axios.post(`deal-desk-productivity/tag-ticket/delete`, payload)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
          this.agGrid.api.deselectAll();
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  tickettagStatusUpdate = (payload = {}) => {
    return Axios.post(`deal-desk-productivity/tag-ticket/statuschange`, payload)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
          this.agGrid.api.deselectAll();
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  filter = null; // to set the holder data
  // reset all the server side filters :: DEFAULT - R
  // handleReset = () => {
  //   localStorage.removeItem('productivity_grid');
  //   this.extraFilter = null;
  //   this.selectedGridFilter = null;

  //   let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
  //   localStorage.setItem(
  //     'savedFilters',
  //     JSON.stringify({
  //       ...tempsavedFilters,
  //       PRODUCTIVITYSTORE: this.DEFAULT_GRID_FILTER_ID,
  //     })
  //   );

  //   let user_name_fullname = localStorage.getItem('user_name_fullname');
  //   this.filter = {
  //     'holder': { values: [user_name_fullname], filterType: 'set' },
  //   };

  //   this.agGrid.api.setFilterModel(this.filter);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };
  user_name_fullname = localStorage.getItem('user_name_fullname');
  filter = {
    'holder': { values: [this.user_name_fullname], filterType: 'set' },
  };

  handleReset = () => {
    this.extraFilter = null;
    reset(this.agGrid, "productivity_grid", this.setSelectedGridFilter, this.DEFAULT_GRID_FILTER_ID, this.filter);
  }
  applyFilterDefault = () => {
    this.extraFilter = null;
    clear(this.agGrid, this.filter )
  }


  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'productivity_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  getUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`)
      .then(({ data }) => {
        this.dropdown_users_list = data.data;

        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;

    let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
    localStorage.setItem(
      'savedFilters',
      JSON.stringify({
        ...tempsavedFilters,
        PRODUCTIVITYSTORE: data,
      })
    );
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PRODUCTIVITYSTORE: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  resetSelectedSavedGridFilter = () => {
    this.selectedGridFilter = this.DEFAULT_GRID_FILTER_ID;
  };

  setSelectedMasterTicket = (data) => {
    if (!this.selectedMasterTicket.includes(data)) {
      this.selectedMasterTicket = [...this.selectedMasterTicket, data];
    }
  };

  // applyFilterDefault = () => {
  //   if (this.agGrid) {
  //     this.selectedGridFilter = null;
  //     localStorage.removeItem('productivity_grid');
  //     this.agGrid.api.setFilterModel(null);
  //     this.agGrid.api.setSortModel(null);
  //     this.agGrid.columnApi.resetColumnState();
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };

  // applyFilterDefault = () => {
  //   // localStorage.removeItem('productivity_grid');

  //   this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
  //   this.extraFilter = null;
  //   // this.agGrid.columnApi.resetColumnState();
  //   if (this.agGrid) {
  //     // this.setupGrid(this.agGrid);
  //     this.agGrid.api.setFilterModel(this.filter);
  //     this.agGrid.api.setSortModel(null);
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };

  setIsSavedFiltersLoadedProductivity = (data) => {
    this.isSavedFiltersLoadedProductivity = data;
  };

}


decorate(ProductivityStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  extraFilter: observable,
  dropdown_users_list: observable,
  selectedGridFilter: observable,
  selectedMasterTicket: observable,
  DEFAULT_GRID_FILTER_ID: observable,
  isSavedFiltersLoadedProductivity: observable,

  setPageSize: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  bulkAssignUpdate: action,
  bulkDeleteUpdate: action,
  tickettagStatusUpdate: action,
  handleReset: action,
  onGridChanged: action,
  getUsersList: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  setSelectedMasterTicket: action,
  resetSelectedSavedGridFilter: action,
  setIsSavedFiltersLoadedProductivity: action,
});
