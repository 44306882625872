import Axios from 'axios';
import { decorate, observable, action } from 'mobx';

export default class TagsticketStore {
  tagsticket_data = null;
  tagcount = 0;
  ticketcount = 0;

  fetchTagsTicketData = (id) => {
    return Axios.get(`deal-desk/tag-ticket/list/${id}`)
      .then(({ data }) => {
        this.tagsticket_data = data && data.data;

        this.tagcount = this.tagsticket_data
          ? this.tagsticket_data.filter((e) => e.type === 'tags').length
          : 0;
        this.ticketcount = this.tagsticket_data
          ? this.tagsticket_data.filter((e) => e.type === 'ticket').length
          : 0;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  updateDealTicket = (dealid, id, payload) => {
    return Axios.post(
      `deal-desk/tag-ticket/ticket-update-remove-transactionId/${dealid}/${id}`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  updateTicketSequence = (id, payload) => {
    return Axios.post(`deal-desk/tag-ticket/update-tickets-sequence/${id}`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  updateTagsSequence = (payload) => {
    return Axios.post(`deal-desk/tag-ticket/update-tags-sequence`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
}
decorate(TagsticketStore, {
  tagsticket_data: observable,
  tagcount: observable,
  ticketcount: observable,
  fetchTagsTicketData: action,
  updateDealTicket: action,
  updateTicketSequence: action,
  updateTagsSequence: action,
});
