import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { PageHeader, Typography, Tooltip, Form, Space } from 'antd';

import useStore from '../../store';
import ListComponent from './component/ListComponent';
import DeleteComponent from './component/DeleteComponent';
import RecordPerPage from '../../config/RecordPerPage';
import EnterFullscreen from '../../component/EnterFullscreen';
import ResetGridOptions from '../../component/ResetGridOptions';
import TimeTracker from '../../component/TimeTracker';

const History = observer((props) => {
  const { t } = useTranslation()
  const { HISTORY, SETTINGS } = useStore();
  const [form] = Form.useForm();

  const { SearchPage, AllView, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;

  const [deleteDrawer, setDeleteDrawer] = useState(false);

  //open delet drawer
  const openDeleteDrawer = (data) => {
    HISTORY.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);
  const pageFilterType = 'history';

  return (
    <>
      {SearchPage ?
        <>
          <ListComponent
            openDeleteDrawer={openDeleteDrawer}
            SearchPage={SearchPage}
            AllView={AllView}
          />
          <DeleteComponent
            visible={deleteDrawer}
            close={closeDeleteDrawer}
            SearchPage={SearchPage}
          />
        </>
        :
        <PageHeader
          title={
            !SearchPage ? `${t('Common_Data.Tickets')}`
              : (<>
                <div className='d-flex justify-content-start align-items-center notes'>
                  <Title level={3}>
                    {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                  </Title>
                  <div className='searchView ml-10 '>
                    <span className='sep'>|</span>
                    {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                    {t('Common_Data.Records_found')}
                    <span className='sep'>|</span>
                    <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                      {t('Common_Data.View_all')} {sectorSelector}
                    </span>
                  </div>
                </div>
              </>)
          }
          className={SearchPage ? 'SearchResult-PageHeader ' + SETTINGS.fullscreen_class : ''}
          extra={[
            <Form form={form} className='innerForm headerButton'>
              <Space size={5}>
                <ResetGridOptions key={'4'} pageType={pageFilterType} />
                <EnterFullscreen key='5' />

                <RecordPerPage
                  key='6'
                  initialValue={HISTORY.per_page + ' per page'}
                  onChange={HISTORY.setPageSize}
                />
              </Space>
            </Form>
          ]}

        >
          <ListComponent
            openDeleteDrawer={openDeleteDrawer}
            SearchPage={SearchPage}
            AllView={AllView}
          />

          <DeleteComponent
            visible={deleteDrawer}
            close={closeDeleteDrawer}
            SearchPage={SearchPage}
          />
          <TimeTracker
            page={"history"}
          />
        </PageHeader>
      }
    </>
  );
});

export default History;
