import { Checkbox, Col, Form, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../../component/InputComponent';
import useStore from '../../../../store';

const KPISelectColumnsElement = observer(({ onChange, formDataList, form }) => {
  const {
    REPORTSTORE: { lead_status_list },
    AUTH,
  } = useStore();
  const { t } = useTranslation();

  const onSoldChange = (index) => {
    let formData = form.getFieldValue('selected_lead_status');
    for (let j = index + 1; j <= formData.length - 1; j++) {
      if (formData[j]) {
        formData[j]['lead_status_id'] = undefined;
      }
      // formData.splice(index + 1, formData.length - 1);
    }
    form.setFieldsValue({
      selected_lead_status: formData,
    });
  };

  return (
    <>
      <Row gutter={12}>
        <Col span={1}>&nbsp;</Col>
        <Col span={6}>
          <InputComponent
            required={true}
            label={t('Common_Data.SOLD_Stage')}
            type='labelOnly'
          />
        </Col>
        <Col span={2}>
          <InputComponent label='%' type='labelOnly' />
        </Col>
        <Col span={2}>
          <InputComponent label='Day' type='labelOnly' />
        </Col>
        <Col span={2}>
          <InputComponent label='Time' type='labelOnly' />
        </Col>
        <Col span={2}>
          <InputComponent label='Finance' type='labelOnly' />
        </Col>
      </Row>
      <Form.List name='selected_lead_status' initialValue={[null, null]}>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => {
            let leadStatusList = [
              ...lead_status_list,
              // , { id: 999, status_name: 'Results', disabled: false }
            ];
            if (leadStatusList.length > 0) {
              let newupDatedList = leadStatusList;
              let indexAvailable = newupDatedList.findIndex((x) => x.id === 15);
              if (indexAvailable !== -1) {
                leadStatusList.splice(indexAvailable + 1, 0, {
                  id: 999,
                  status_name: 'Results',
                  disabled: false,
                });
              }
            }
            leadStatusList = leadStatusList.map((arrayElem) => {
              delete arrayElem['disabled'];
              return arrayElem;
            });
            let currentMaxSelectedVal =
              form.getFieldValue('selected_lead_status')[index - 1];

            let currentIndex = currentMaxSelectedVal
              ? leadStatusList.findIndex(
                  (x) => x.id === currentMaxSelectedVal['lead_status_id']
                )
              : -1;
            for (let i = 0; i <= currentIndex; i++) {
              leadStatusList[i]['disabled'] = true;
            }

            //let formValue = form.getFieldValue('selected_lead_status')[index];

            return (
              <>
                <Row gutter={12}>
                  <Col span={1} className={'pt-10'}>
                    <InputComponent
                      label={`#${index + 1}`}
                      type='labelOnly'
                      // className={index === 0 ? 'mt-30' : 'mt-15'}
                    />
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      required
                      rules={[
                        { required: true, message: t('Reports.SOLD_Stage_Validation') },
                      ]}
                      // label={`${index === 0 ? t('Common_Data.SOLD_Stage') : ''}`}
                      name={[field.name, 'lead_status_id']}
                    >
                      <Select
                        placeholder={`${t('Reports.SOLD_Stage_Placeholder')}`}
                        showArrow
                        allowClear
                        tooltip={`${index === 0 ? t('Reports.SOLD_Stage_Tooltip') : ''}`}
                        onChange={(e) => {
                          onChange();
                          onSoldChange(index);
                        }}
                      >
                        {leadStatusList.map((obj, objIndex) => {
                          if (!obj.disabled) {
                            return (
                              <Select.Option
                                key={objIndex}
                                disabled={obj.disabled}
                                value={obj.id}
                                title={obj.status_name}
                              >
                                {obj.status_name}
                              </Select.Option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'pt-5 text-center'}>
                    <Form.Item
                      // className='m-0'
                      // label={`${index === 0 ? '%' : ' '}`}
                      name={[field.name, 'display_percentage']}
                      valuePropName='checked'
                    >
                      <Checkbox onChange={onChange} />
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'pt-5 text-center'}>
                    <Form.Item
                      // className='m-0'
                      // label={`${index === 0 ? 'Day' : ' '}`}
                      name={[field.name, 'display_days']}
                      valuePropName='checked'
                    >
                      <Checkbox onChange={onChange} />
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'pt-5 text-center'}>
                    <Form.Item
                      // className='m-0'
                      // label={`${index === 0 ? 'Time' : ' '}`}
                      name={[field.name, 'display_time']}
                      valuePropName='checked'
                    >
                      <Checkbox onChange={onChange} />
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'pt-5 text-center'}>
                    <Form.Item
                      // className='m-0'
                      // label={`${index === 0 ? 'Finance' : ' '}`}
                      name={[field.name, 'display_finance']}
                      valuePropName='checked'
                    >
                      <Checkbox onChange={onChange} />
                    </Form.Item>
                  </Col>
                  <Col span={2} className='text-center'>
                    <div className='d-flex pr-5'>
                      {index > 1 && (
                        <img
                          alt=''
                          className={`formIcon  mr-10`}
                          src={AUTH.GetThemedImage('remove')}
                          onClick={() => {
                            remove(field.name);
                            onChange();
                          }}
                        />
                      )}
                      {index > 0 && (
                        <img
                          alt=''
                          className={`formIcon mr-10`}
                          src={AUTH.GetThemedImage('add')}
                          onClick={async () => {
                            await add(null);
                            onChange();
                          }}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            );
          });
        }}
      </Form.List>
    </>
  );
});

export default KPISelectColumnsElement;
