import React from 'react';
import { Badge, Tooltip } from 'antd';
import useStore from '../../../../store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const HighlightsComponent = observer((props) => {
  const { t } = useTranslation()


  const {
    DEALDESKSTORE: { deal_data },
  } = useStore();

  const handleClick = () => {
    localStorage.setItem(
      'selected_dealdesk',
      JSON.stringify({
        current: deal_data?.lead_information?.is_ticket,
        org:
          deal_data?.lead_information?.sold_organisation?.organisation_name +
          ' (' +
          deal_data?.lead_information?.sold_organisation?.id +
          ')',
        people: deal_data?.lead_information?.people?.full_name,
      })
    );
    window.open(`/leads/active`, '_blank');
  };

  let showLeadStatusReason = null;
  if (deal_data?.lead_information?.lead_status_name.id === 12) {
    showLeadStatusReason = deal_data?.lead_information?.rejected_reason;
  }
  else if (deal_data?.lead_information?.lead_status_name.id === 13) {
    showLeadStatusReason = deal_data?.lead_information?.failed_reason;
  }
  else if (deal_data?.lead_information?.lead_status_name.id === 14) {
    showLeadStatusReason = deal_data?.lead_information?.future_reason;
  }
  else {
    showLeadStatusReason = null;
  }
  return (
    <>
      <table className='Highlights' cellPadding='0' cellSpacing='0'>
        <tbody>
          {deal_data.sales_target?.closed_deals && (
            <tr>
              <td className='text-left'>{t('DealDesk.Closed_Deals')}</td>
              <td className='text-right'>
                <Tooltip
                  placement='topLeft'
                  title={deal_data.sales_target && deal_data.sales_target.closed_deals}
                >
                  <Badge
                    count={deal_data.sales_target && deal_data.sales_target.closed_deals}
                  />
                </Tooltip>
              </td>
            </tr>
          )}
          {deal_data.sales_target?.remaining && (
            <tr>
              <td className='text-left'>{t('DealDesk.Remaining')}</td>
              <td className='text-right'>
                <Tooltip
                  placement='topLeft'
                  title={deal_data.sales_target && deal_data.sales_target.remaining}
                >
                  <Badge
                    count={deal_data.sales_target && deal_data.sales_target.remaining}
                  />
                </Tooltip>
              </td>
            </tr>
          )}
          {deal_data.sales_target?.days_left ? (
            <tr>
              <td className='text-left'>{t('DealDesk.Days_Left')}</td>
              <td className='text-right'>
                <Tooltip
                  placement='topLeft'
                  title={deal_data.sales_target && deal_data.sales_target.days_left}
                >
                  <Badge
                    overflowCount={365}
                    showZero
                    count={
                      deal_data.sales_target && deal_data.sales_target.days_left === 0
                        ? 0
                        : deal_data.sales_target && deal_data.sales_target.days_left
                    }
                  />
                </Tooltip>
              </td>
            </tr>
          ) : null}

          {
            showLeadStatusReason !== null
              ? (
                <tr>
                  <td colSpan={2} className="text-left">
                    {t('DealDesk.Reason')} :
                    <Tooltip placement='topLeft' title={showLeadStatusReason}>
                      <span style={{ cursor: "default" }}>{` ${showLeadStatusReason}`}</span>
                    </Tooltip>
                  </td>
                  {/* <td className='text-right'>
                
              </td> */}
                </tr>
              )
              : null
          }

          {[2, 3].includes(deal_data?.lead_information?.is_ticket) && (
            <button shape='round' className='cursor' onClick={handleClick}>
              {t('DealDesk.View_All_Leads')}
            </button>
          )}
        </tbody>
      </table >
    </>
  );
});

export default HighlightsComponent;
