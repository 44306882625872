import React from 'react';
import { observer } from 'mobx-react';
import { Form, Spin } from 'antd';
import useStore from '../../../../store';
import ViewElement from '../elements/ViewElement';
import { Drawer } from 'antd';
// import TrackTimeComponent from '../../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../../component/TimeTracker';
import { Link } from 'react-router-dom';

const ViewComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { PEOPLE, AUTH } = useStore();
  const { openEditDrawer, isChildDrawer = false } = props;
  const close = () => {
    props.close();
  };

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      zIndex={`${isChildDrawer ? 1005 : 1000}`}
      footer={
        <>
          {AUTH.checkPermission(18, 'edit') && openEditDrawer && (
            <img
              className='menuicon'
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '17px',
                right: '60px',
                width: '20px',
              }}
              alt=''
              title={`${t('Common_Data.Edit')} ${t('Common_Data.People')}`}
              src={AUTH.GetThemedImage('Edit')}
              onClick={() => openEditDrawer(PEOPLE.peopleValues, true)}
            />
          )}

          <Link to={`/people/${PEOPLE?.peopleValues?.leads_transactions_id}`} target='_blank'>
            <img
              className='menuicon'
              style={{ cursor: 'pointer', position: 'absolute', top: '17px', right: '20px', width: '20px', }}
              alt=''
              title={`${t('PeopleManagement.History')} ${t('Common_Data.People')}`}
              src={AUTH.GetThemedImage('Deal_icn')}
            />
          </Link>
        </>
      }
      title={`${t('Common_Data.Details')} ${t('Common_Data.For')} ${PEOPLE.peopleValues
        ? `${PEOPLE.peopleValues?.first_name ? PEOPLE.peopleValues?.first_name : ''} ${PEOPLE.peopleValues?.last_name ? PEOPLE.peopleValues.last_name : ''
        }`
        : ''
        }${PEOPLE.peopleValues && PEOPLE.peopleValues?.orgname
          ? ` ${t('Common_Data.Of')} ${PEOPLE.peopleValues?.orgname} `
          : ''
        }`}
      destroyOnClose={true}
    >
      <Spin size='large' spinning={PEOPLE.viewPeopleLoading} tip={`${t('Common_Data.Load')}`}>
        <ViewElement
          form={form}
          id={PEOPLE.peopleValues?.id}
          peopleValues={PEOPLE.peopleValues}
          handleViewDetailCall={props.handleViewDetailCall}
          waste={props.waste}
        />

        {/* {PEOPLE?.peopleValues && !isChildDrawer && (
          <TrackTimeComponent
            pageName={'people'}
            leadId={
              PEOPLE?.peopleValues?.leads_transactions_id
                ? PEOPLE?.peopleValues?.leads_transactions_id
                : 0
            }
          />
        )} */}

        <TimeTracker
          page={"people"}
          id={PEOPLE.peopleValues?.leads_transactions_id}
          stop={!props.visible || props.editDrawer}
        />
      </Spin>
    </Drawer>
  );
});

export default ViewComponent;
