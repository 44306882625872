import { DatePicker } from "antd";
import moment from "moment";
import React from 'react'


const DatePickerCostume = ({
  showTime,
  disabledDate,
  disabledHours,
  minuteStep,
  format,
  type,
  ...rest
}) => {


  /**If disabledDate is defined then that will be used otherwise
   * if it is blank then dates will not be disabled
   * if disabledDate is passed true then the below defined disabled dates will be used */
  /** Default disabled dates */
  const disableOldDates = (current) => {
    if (disabledDate === undefined) {
      return null
    } else {
      /**The dates(yesterday or later dates) */
      return current && current <= moment().subtract(1, 'days').endOf('day')
    }
  };

  /** Default disabled hours */
  // const disabledUnusedHours = (e) => {
  //   let hours = [20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  /**If showTime passed/true the "Date Picker with Time" will show;
   * If showTime is not passed then "Week Picker" will show;
   * If showTime is not passed and type='time' passed then only "Time Picker" will show.
  */
  return showTime ? (
    <DatePicker
      showTime
      use12Hours
      format={format || 'YYYY-MM-DD hh:mm a'}
      disabledDate={(disabledDate && typeof disabledDate !== 'boolean') ? disabledDate : disableOldDates}
      // disabledHours={disab/ledHours || disabledUnusedHours}
      minuteStep={minuteStep || 15}
      hideDisabledOptions
      defaultValue={moment('07:00:00', 'hh:mm a')}
      {...rest}
    />
  )
    : (type === 'time') ?
      (<DatePicker.TimePicker
        format={format || 'hh:mm a'}
        // disabledHours={disabledHours || disabledUnusedHours}
        minuteStep={minuteStep || 15}
        hideDisabledOptions
        {...rest}
      />)
      : (<DatePicker
        picker="week"
        disabledDate={(disabledDate && typeof disabledDate !== 'boolean') ? disabledDate : disableOldDates}
        format={"YYYY-MM-DD"}
        hideDisabledOptions
        {...rest}
      />)
}

export default DatePickerCostume