// import { faUndo } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResetGridOptions from '../../../component/ResetGridOptions';
import useStore from '../../../store';
import AddComponent from './component/AddComponent';
import DeleteComponent from './component/DeleteComponent';
import EditComponent from './component/EditComponent';
import ScoreLibraryList from './component/ListComponent';
import ScoreLibraryView from './component/ScoreLibraryView';

const ScoreLibrary = observer(() => {
  const {
    // SSSLEADLIBRARYSTORE,
    SCORELIBRARYSTORE: { score_library_data, setEditValues, fetchScoreLibrary },
  } = useStore();
  const [scheduleDataLIB, setScheduleDataLIB] = useState(null);
  const [addDrawer, setaddDrawer] = useState(false);
  const [libData, setlibData] = useState(null);
  const [id, setID] = useState(null);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [openScheduleLibraryDrawer, setOpenScheduleLibraryDrawer] = useState(false);
  // const [agGrid, setAgGrid] = useState({});

  const openAddDrawer = () => {
    setaddDrawer(true);
  };

  const closeAddDrawer = () => {
    setaddDrawer(false);
  };

  //open edit drawer
  const openEditDrawer = (data) => {
    setEditValues(data);
    setEditDrawer(true);
  };
  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);

  //open add drawer
  const openDeleteDrawer = (data) => {
    setDeleteDrawer(true);
    setlibData(data);
    setID(data.id);
  };

  //close add drawer
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const viewScheduleLibraryDrawer = (data) => {
    setScheduleDataLIB(data);
    setOpenScheduleLibraryDrawer(true);
  };

  const closeScheduleLibraryDrawer = () => {
    setScheduleDataLIB(null);
    setOpenScheduleLibraryDrawer(false);
  };

  const { t } = useTranslation();

  /**To update the agGrid ref data and used in reset button */
  // setAgGrid(SSSLEADLIBRARYSTORE.agGridRefScore?.current)
  // useEffect(() => {
  // }, [SSSLEADLIBRARYSTORE.agGridRefScore])

  /**To fetch library data on refresh button*/
  const refresh = () => {
    fetchScoreLibrary();
  }
  return (
    <>
      <PageHeader
        title=''
        className={'peopleOrg'}
        extra={[
          <Button shape='round' size='medium' key='1' onClick={openAddDrawer}>
            {t('Common_Data.Add')} {t('DealLibrary.Score')}
          </Button>,

          // <Button
          //   key='2'
          //   title={`${t('Common_Data.Reset')}`}
          //   shape='round'
          //   size='medium'
          //   onClick={() => {
          //     handleResetScore();
          //   }}
          // >
          //   <FontAwesomeIcon icon={faUndo} />
          // </Button>,
          <ResetGridOptions key={'2'} pageType={"score"} list_grid={"score_library_grid"} refresh={refresh} />
        ]}
      >
        <ScoreLibraryList
          libraryData={score_library_data}
          DealLibrary={true}
          viewScheduleLibraryDrawer={viewScheduleLibraryDrawer}
          openEditDrawer={openEditDrawer}
          openDeleteDrawer={openDeleteDrawer}
        />
      </PageHeader>

      <AddComponent visible={addDrawer} close={closeAddDrawer} />

      <EditComponent visible={editDrawer} close={closeEditDrawer} />

      <DeleteComponent
        visible={deleteDrawer}
        id={id}
        close={closeDeleteDrawer}
        libData={libData}
      />

      {openScheduleLibraryDrawer && (
        <ScoreLibraryView
          visibleScheduleLibraryDrawer={openScheduleLibraryDrawer}
          closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
          ScoreData={scheduleDataLIB}
          test={'3'}
        />
      )}
    </>
  );
});

export default ScoreLibrary;
