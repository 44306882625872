import React from 'react';
import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import UnreadNoteCompoenent from './UnreadNoteCompoenent';
import UrgentNoteCompoenent from './UrgentNoteCompoenent';
import CriticalNoteCompoenent from './CriticalNoteCompoenent';
import TagNoteCompoenent from './TagNoteCompoenent';
import useStore from '../../../store';
import {
  ClockCircleFilled,
  ExclamationCircleFilled,
  ForwardFilled,
  BellFilled,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const TabComponent = observer((props) => {
  const { TabPane } = Tabs;
  const {t}  = useTranslation()
  const {
    //HEADERREMINDERSSTORE: { list_data },
    SPANNOTESTORE: {
      unReadNote_count,
      urgentNote_count,
      criticalNote_count,
      tagNote_count,
    },
  } = useStore();

  const CriticalTitle = () => {
    return (
      <>
        <span style={{ color: '#ff2828' }} title={`${t('TopNotificationModule.OverDue_Still')}`}>
          <ExclamationCircleFilled />
          {'('}
          {criticalNote_count}
          {')'}
        </span>
      </>
    );
  };

  const UrgentTitle = () => {
    return (
      <>
        <span style={{ color: '#3776ea' }} title={`${t('TopNotificationModule.Open_Today')}`}>
          <ClockCircleFilled />
          {'('}
          {urgentNote_count}
          {')'}
        </span>
      </>
    );
  };

  const UnreadTitle = () => {
    return (
      <>
        <span style={{ color: '#414141' }} title={`${t('TopNotificationModule.Open_Tomorrow')}`}>
          <ForwardFilled />
          {'('}
          {unReadNote_count}
          {')'}
        </span>
      </>
    );
  };

  const TagTitle = () => {
    return (
      <>
        <span style={{ color: ' #009933' }} title={`${t('Common_Data.Tags')}`}>
          <BellFilled />
          {'('}
          {tagNote_count}
          {')'}
        </span>
      </>
    );
  };

  return (
    <>
      <Tabs centered>
        <TabPane tab={CriticalTitle()} key='3'>
          <CriticalNoteCompoenent
            onChange={props.onChange}
            openReminderViewDrawer={props.openReminderViewDrawer}
          />
        </TabPane>

        <TabPane tab={UrgentTitle()} key='2'>
          <UrgentNoteCompoenent
            onChange={props.onChange}
            openReminderViewDrawer={props.openReminderViewDrawer}
          />
        </TabPane>

        <TabPane tab={UnreadTitle()} key='1'>
          <UnreadNoteCompoenent
            onChange={props.onChange}
            openReminderViewDrawer={props.openReminderViewDrawer}
          />
        </TabPane>

        <TabPane tab={TagTitle()} key='4'>
          <TagNoteCompoenent
            onChange={props.onChange}
            openReminderViewDrawer={props.openReminderViewDrawer}
          />
        </TabPane>
      </Tabs>
    </>
  );
});
export default TabComponent;
