import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Row, Col, Checkbox } from 'antd';
import FormComponent from './FormComponentNew';
import debounce from 'lodash/debounce';
import { vsmTicket, vsmNotify } from '../../../config/messages';
import moment from 'moment';
import useStore from '../../../store';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const CloneComponent = observer((props) => {
  const { t } = useTranslation()
  const history = useHistory();
  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [cloneWithNote, setCloneWithNote] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [isOrgTicket, setOrgTicket] = useState(null);

  const {
    AUTH,
    // TicketsStore,
    TicketsStore: { addTicket, cloneValues },
    TimeTrackerStore,
  } = useStore();
  const [isMasterTicket, setMasterTicket] = useState();
  // const [subjectInput, setSubjectInput] = useState();

  /** To check the subject input contains word (Master) */
  // let isIncludeMasterInSubjectInput = subjectInput && subjectInput.toLowerCase().includes("(master)");

  const close = () => {
    props.close();
    setDisabled(true);
    setSaving(false);
    form.resetFields();
    // TicketsStore.cloneValues = null;
    setMasterTicket('')
    setOrgTicket(false);
  };
  const onChangeWithNote = (e) => {
    setCloneWithNote(e.target.checked)
  }

  const handleSubmit = (data) => {
    data.leads_entity_attributes_type = 8;
    data.owner = AUTH.user.id;

    data.is_deleted = 0;

    data.hours_required = 0
    data.hours_required_hour = data.hours_required_hour ? data.hours_required_hour : 0
    data.hours_required_min = data.hours_required_min ? data.hours_required_min : 0
    if (data.hours_required_hour || data.hours_required_min) {

      let convertHourToMinInt = (data.hours_required_hour && parseInt(data.hours_required_hour * 60))
      let convertminitueToMinInt = (data.hours_required_min && parseInt(data.hours_required_min))

      let setTotalTime = (convertHourToMinInt && convertHourToMinInt) + (convertminitueToMinInt && convertminitueToMinInt)
      data.hours_required = parseInt(setTotalTime) * 60
    }
    data.deadline_date = data.deadline_date && moment(data.deadline_date).format(AUTH.form_submit_date_with_seconds);



    // if (data.deadline_date) {
    //   data.deadline_date = data.deadline_date? moment(data.deadline_date).format('YYYY-MM-DD')
    //     : null;
    // } else {
    //   delete data.deadline_time;
    // }

    if (data.is_deadlinetime && data.deadline_time) {
      data.deadline_time = data.deadline_time
        ? moment(data.deadline_time).format('HH:mm:ss')
        : null;
    } else {
      delete data.deadline_time;
    }

    if (props.cloneNotesTicket) {
      data.cloneNotes = props.cloneNotesTicket;
      data.cloneLeadId = props.leadID;
    }
    if (cloneWithNote) {
      data.cloneNotes = 1;
      data.cloneLeadId = props.leadID;
    }


    let organisationID = data.organisation_id && data.organisation_id
    if (organisationID) {
      if (organisationID === 'Credebt Exchange' || organisationID === 1) {
        data.organisation_id = 1
      } else {
        data.organisation_id = organisationID
      }
    }
    else {
      data.organisation_id = ''
    }


    setSaving(true);
    addTicket(data)
      .then((res) => {
        TimeTrackerStore.setLeadTransactionId(res.leads_transactions_id);
        close();
        form.resetFields();
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmTicket.add,
        });
        history.push(`/ticket/${res.ticket_number}`);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };
  const handleChange = debounce(() => {
    // setSubjectInput(form.getFieldValue("ticket_subject"));
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {

    if (cloneValues) {
      // setSubjectInput(cloneValues.ticket_subject)
      setMasterTicket(cloneValues?.parent_ticket_id ? true : false)
      setOrgTicket(cloneValues?.global_organisation_id ? true : false)
      form.setFieldsValue({
        is_masterTicket: cloneValues?.parent_ticket_id ? true : false,
        is_org: cloneValues?.organisation?.id ? true : false,
        ticket_subject: cloneValues.ticket_subject,
        organisation_id: cloneValues.global_organisation_id,
        parent_ticket_id: cloneValues.parent_ticket_id
          ? cloneValues.parent_ticket_id
          : null,
        holder: cloneValues.ticket_holder && cloneValues.ticket_holder.id,
        ticket_status: cloneValues.ticket_status && cloneValues.ticket_status.id,
        ticket_description: cloneValues.ticket_description,
        ticket_type: cloneValues.ticket_type,
        priority: cloneValues.priority,
        hours_required: cloneValues.hours_required
          ? moment(cloneValues.hours_required, 'hh:mm')
          : null,
        deadline_date: cloneValues.deadline_date && moment(cloneValues.deadline_date),
        // deadline_date: cloneValues.deadline_date && cloneValues?.deadline_date,
        // deadline_date: cloneValues.deadline_date
        //   ? moment(cloneValues.deadline_date, 'YYYY-MM-DD')
        //   : null,
        // deadline_time: cloneValues.deadline_time
        //   ? moment(cloneValues.deadline_time, 'h:mm:ss a')
        //   : null,
      });
    }
  }, [cloneValues, form]);

  // /**This is called on onBlur event on subject input*/
  // const handleBlur = () => {
  //   if (subjectInput && isMasterTicket && !isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput} (Master)` });
  //     setSubjectInput(`${subjectInput} (Master)`);
  //   }
  //   if (subjectInput && !isMasterTicket && isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput.replace(/\(Master\)/gi, '')}` });
  //     setSubjectInput(`${subjectInput.replace(/\(Master\)/gi, '')}`);
  //   }
  // }

  // /**This is called on onChange of Master Ticket Check box event on subject input*/
  // const handleCheck = () => {
  //   if (subjectInput && !isMasterTicket && !isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput} (Master)` })
  //   }
  //   if (subjectInput && isMasterTicket && isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput.replace(/\(Master\)/gi, '')}` })
  //   }
  // }


  return (
    <>
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Clone')} ${t('Common_Data.Ticket')}`}
        destroyOnClose={true}
        footer={[
          <>
            <Row>
              <Col span={12}>
                <div className='text-left pt-10' key='1'>
                  <Row>
                    <Col span={12}>
                      <strong>{t('Common_Data.Created')} {t('Common_Data.Date')}:</strong>{' '}
                      {cloneValues?.created_date
                        ? AUTH.getLocalTimeDealDesk(
                          cloneValues?.created_date,
                          AUTH.global_dateformat
                        )
                        : `xxxx-xx-xx`}
                    </Col>
                    <Col span={12}>
                      <strong>{t('Common_Data.Modified')} {t('Common_Data.Date')}:</strong>{' '}
                      {cloneValues?.updated_date
                        ? AUTH.getLocalTimeDealDesk(
                          cloneValues?.updated_date,
                          AUTH.global_dateformat
                        )
                        : `xxxx-xx-xx`}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={12}>

                <div className='text-right' key='2'>
                  <Checkbox onChange={onChangeWithNote}>Clone with Notes</Checkbox>
                  <Button
                    key='1'
                    form='cloneForm'
                    className='mr-10'
                    loading={saving}
                    htmlType='submit'
                    type='primary'
                    shape='round'
                    disabled={resetSubject === true ? true : isDisabled}
                  >
                    {t('Common_Data.Clone')}
                  </Button>
                  <Button shape='round' onClick={close} key='2'>
                    {t('Common_Data.Cancel')}
                  </Button>
                </div>
              </Col>
            </Row>
          </>,
        ]}
      >

        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='cloneForm'
          isMasterTicket={isMasterTicket}
          setMasterTicket={setMasterTicket}
          isOrgTicket={isOrgTicket}
          setOrgTicket={setOrgTicket}
          cloneValues={cloneValues}
          setResetSubject={setResetSubject}
          resetSubject={resetSubject}
          isDisabled={isDisabled}
        // onBlurSubject={handleBlur}
        // onCheck={handleCheck}
        />
      </Drawer>
      <TimeTracker
        page={"tickets"}
        id={cloneValues?.leads_transactions_id}
        stop={!props.visible}
      />
    </>
  );
});

export default CloneComponent;
