import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Col, Divider, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const SummaryPeopleElement = observer(({ onChange, formDataList, form }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={12} align='middle' className='mb-5'>
        <Col span={24}>
          <Divider orientation='left' orientationMargin='0' plain>
            {t('Common_Data.People')}
          </Divider>
        </Col>
        <Col span={24}>
          <Row gutter={12} align='middle'>
            {formDataList.includes('people_created') && (
              <Col span={4}>
                <Form.Item className='m-0' name='people_created' valuePropName='checked'>
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Created')}</Checkbox>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
});

export default SummaryPeopleElement;
