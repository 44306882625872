import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
//import { Link } from 'react-router-dom';
import useStore from '../store';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import EditComponent from '../page/LeadListing/component/EditComponent';

const TickerTapeBroadcast = observer((props) => {
  const [editDrawer, setEditDrawer] = useState(false);

  const {
    AUTH,
    AUTH: { header_ticker_info },
    LEADSSTORE,
  } = useStore();

  const getisTickerScroll = () => {
    let isPlay = true;
    let isTickerScroll = localStorage.getItem("isTickerScroll");

    if (isTickerScroll !== undefined && isTickerScroll !== null) {
      if (isTickerScroll === "true") {
        isPlay = true
      }
      else {
        isPlay = false;
      }
    }

    return isPlay;
  }

  const [playMarkee, setPlayMarkee] = useState(getisTickerScroll());

  const updateMarkeePlay = () => {
    localStorage.setItem('isTickerScroll', !playMarkee);
    setPlayMarkee(!playMarkee);
  };

  const openEditDrawer = (id) => {
    let payload = {
      id: id,
    };
    LEADSSTORE.setEditValues(payload);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => setEditDrawer(false);

  return (
    AUTH.app_color_theme && (
      <div className='marqueeBox'>
        {AUTH.checkPermission(29, 'display-ticker') ? (
          <>
            <Marquee speed={40} play={playMarkee} pauseOnHover={true}>
              {header_ticker_info &&
                header_ticker_info.map(
                  (item, index) =>
                    item && (
                      <Tooltip title={item.description}>
                        {item.type === '27764' ? (
                          <>
                            <img
                              src={AUTH.GetThemedImage('Lead_Status')}
                              alt={item.ticker_text}
                              className='icon'
                              onClick={() => openEditDrawer(27764)}
                              key={index}
                            />
                          </>
                        ) : (
                          <a
                            target='_blank'
                            href={item.ticker_url}
                            rel='noopener noreferrer'
                            key={index}
                          >
                            {item.type === 'lead' ? (
                              <img
                                src={AUTH.GetThemedImage('Lead_Status')}
                                alt={item.ticker_text}
                                className='icon'
                              />
                            ) : item.type === 'deals' ? (
                              <img
                                src={AUTH.GetThemedImage('Deal_icn')}
                                alt={item.ticker_text}
                                className='icon'
                              />
                            ) : item.type === 'tags' ? (
                              <img
                                src={AUTH.GetThemedImage('Tags')}
                                alt={item.ticker_text}
                                className='icon'
                              />
                            ) : item.type === 'ticket' ? (
                              <img
                                src={AUTH.GetThemedImage('Tickets')}
                                alt={item.ticker_text}
                                className='icon'
                              />
                            ) : item.type === 'opportunity' ? (
                              <img
                                src={AUTH.GetThemedImage('Opportunities')}
                                alt={item.ticker_text}
                                className='icon'
                              />
                            ) : item.type === 'knowledge' ? (
                              <img
                                src={AUTH.GetThemedImage('Knowledge')}
                                alt={item.ticker_text}
                                className='icon'
                              />
                            ) : item.type === 'tickerInformation' ? (
                              <img
                                src={AUTH.GetThemedImage('Knowledge')}
                                alt={item.ticker_text}
                                className='icon'
                              />
                            ) : null}
                            {item.ticker_text}
                          </a>
                        )}
                      </Tooltip>
                    )
                )}
            </Marquee>
            {!playMarkee ? (
              <img
                src={AUTH.GetThemedImage('Play')}
                alt='Play'
                className='controlIcon'
                onClick={updateMarkeePlay}
              />
            ) : (
              <img
                src={AUTH.GetThemedImage('Pause')}
                alt='Pause'
                className='controlIcon dark'
                onClick={updateMarkeePlay}
              />
            )}
            <EditComponent visible={editDrawer} close={closeEditDrawer} />
          </>
        ) : null}
      </div>
    )
  );
});

export default TickerTapeBroadcast;
