import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import { numberWithCommas, secondsToHms } from '../../../../utils/GlobalFunction';

const ListComponent = observer((props) => {
  const {
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    SavedReportStore: {
      setupPerformanceGrid,
      onPerformanceGridChanged,
      // onFilterChanged,
      // onGridChanged,
      performance_total_investment_hours,
      reportColumnSettings,
    },
  } = useStore();

  let PinnedData = [];

  let columnDefs = [];

  let list_total = {};

  props.rowData &&
    props.rowData.length > 0 &&
    props.rowData.map((item, index) => {
      if (index === 0) {
        Object.keys(item).forEach((x) => {
          if (x === 'Users') {
            list_total[x] = 'Total';
          } else if (x === 'Time Spent') {
            if (performance_total_investment_hours) {
              list_total[x] = secondsToHms(performance_total_investment_hours);
            } else {
              list_total[x] = '0s';
            }
          } else {
            let total = 0;
            props.rowData.map((obj) => {
              let convertedCount = obj[x];
              if (typeof convertedCount === 'string') {
                convertedCount = convertedCount.replace(/,/g, '');
              }
              total += Number(convertedCount);
              return null;
            });

            list_total[x] = numberWithCommas(total);
          }
        });
      }
      Object.keys(item).forEach((x) => {
        if (!['Time Spent', 'Users'].includes(x)) {
          item[x] = item[x] ? item[x] : 0;
          item[x] = numberWithCommas(item[x]);
        } else {
          if (['Time Spent'].includes(x)) {
            item[x] = item[x] ? item[x] : '00h 00m 0s';
          }
        }
      });

      columnDefs.length <= 0 &&
        Object.keys(item).forEach((x) => {
          columnDefs.push({
            headerName: x,
            headerTooltip: x,
            cellStyle: {
              'text-align':
                x === 'Time Spent' ? 'right' : x === 'Users' ? 'left' : 'center',
            },
            field: x,
            comparator: (valueA, valueB) => {
              if (['Time Spent'].includes(x)) {
                return valueA.localeCompare(valueB);
              } else if (['Modified'].includes(x)) {
                //Comma removed to remove
                valueA = valueA && valueA.replace(/,/g, '');
                valueB = valueB && valueB.replace(/,/g, '');
                return valueA - valueB;
              } else {
                return valueA - valueB;
              }
            },
          });
        });
      return item;
    });

  PinnedData.push(list_total);

  let columns = [];
  let columnConfig = reportColumnSettings;
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          if (element?.width) {
            cols.width = element.width;
          }
          columns.push(cols);
        }
      });
  } else {
    columns = columnDefs;
  }

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper reportPreviewData'
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : `calc(100vh - ${props?.editFilter ? '44rem' : '12rem'})`,
        }}
      >
        <AgGridReact
          rowData={props.rowData && props.rowData.length > 0 ? props.rowData : []}
          modules={AllModules}
          columnDefs={columns}
          pinnedBottomRowData={PinnedData}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            filter: false,
            floatingFilter: false,
            minWidth: 80,
            flex: 1,
          }}
          columnTypes={{
            ...LocalGridConfig.columnTypes,
          }}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          onGridReady={setupPerformanceGrid}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
          }}
          onColumnResized={onPerformanceGridChanged}
          onColumnMoved={onPerformanceGridChanged}
          onColumnPinned={onPerformanceGridChanged}
          onSortChanged={onPerformanceGridChanged}
        // groupIncludeFooter={true}
        // groupIncludeTotalFooter={true}
        />
      </div>
    </>
  );
});

export default ListComponent;
