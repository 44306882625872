import { Layout, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RouterConfig } from '../config/RouterConfig';
import useStore from '../store';
// import useAuth from '../utils/useAuth';
import RightsideComponent from './RightsideComponent';
import SidebarComponent from './SidebarComponent';
import TickerTapeBroadcast from './TickerTapeBroadcast';

const LayoutComponent = observer((props) => {
  const {
    AUTH,
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: {
      setIsOutsideClicked,
      setCommentsOutsideClicked,
      setSummaryOutsideClicked,
    }
  } = useStore();
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem('collapsed') !== undefined
      ? localStorage.getItem('collapsed') === 'true'
      : false
  );
  const [internalLoader, setInternalLoader] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const [forceUpdateState, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    updateState({});
    window.location.reload();
    //setTimeout(() => updateState({}), 0);
  }, []);

  /** Synchronise All Tabs for login and logout */
  window.addEventListener('storage',(event)=>{
    if (event.key === 'token' && !event.newValue) {
      history.replace('/');
    }
    if(event.key === 'token' && event.newValue){
      window.location.reload();
    }
  })

  // check route & set the route setting accordingly
  useEffect(() => {
    if (!AUTH.loading) {
      const redirectBeforeDefault = RouterConfig.find((x) => x.default === 'beforeAuth');
      const redirectAfterDefault = RouterConfig.find((x) => x.default === 'AfterAuth');
      let path = RouterConfig.find((x) => x.path === location.pathname.trimRight('/'));
      if (path && path.auth && AUTH.token === null) {
        history.replace(redirectBeforeDefault.path);
      } else if (path && !path.auth && AUTH.token && !path.errorpage) {
        history.replace(redirectAfterDefault.path);
      }

      //-------------------START : DM-107 - Page Title-------------------------//
      document.title = 'Deal Maker System';
      const curTitle = RouterConfig.find((item) => item.path === location.pathname);
      if (curTitle && curTitle.title) {
        document.title = curTitle.title + ' | Deal Maker System';
      }
      //-------------------END : DM-107 - Page Title-------------------------//
    } else {
      setInternalLoader(true);
    }
    setTimeout(() => {
      setInternalLoader(false);
    }, 500);
  }, [AUTH.token, AUTH.loading, location, history, setInternalLoader]);

  //-------------------START : DM-107 - Page Title-------------------------//
  useEffect(() => {
    if (deal_data) {
      if (deal_data?.lead_information?.is_ticket === 3) {
        document.title = `${deal_data?.lead_information?.sold_organisation?.organisation_name} | Deal Maker System`;
      } else if (deal_data?.lead_information?.is_ticket === 2) {
        document.title = `${deal_data?.lead_information?.people?.full_name} | Deal Maker System`;
      } else if (deal_data?.lead_information?.is_ticket === 0) {
        document.title = `${deal_data?.lead_information?.ticket_data?.ticket_subject} | ${deal_data?.lead_information?.ticket_data?.ticket_number} | Deal Maker System`;
      } else {
        document.title = deal_data?.lead_information?.opportunity_name
          ? `${deal_data?.lead_information?.opportunity_name} | ${deal_data?.lead_information?.id} | Deal Maker System`
          : `${deal_data?.lead_information?.id} | Deal Maker System`;
      }
    }
  }, [deal_data]);
  //-------------------START : DM-107 - Page Title-------------------------//

  // // disable right click
  // document.addEventListener('contextmenu', (event) => {
  //   event.preventDefault();
  // });


  useEffect(() => {
    window.addEventListener('mousedown', (event) => {
      const domNode = document.getElementById('sequenceFilterPopover');
      if (
        (event.target !== domNode) && domNode !== null
        && !domNode.contains(event.target)
      ) {
        setIsOutsideClicked(false)
      }
    }, false);
  }, [setIsOutsideClicked])

  useEffect(() => {
    window.addEventListener('mousedown', (event) => {
      const domNode = document.getElementById('commentFilterPopover');
      if (
        (event.target !== domNode) && domNode !== null
        && !domNode.contains(event.target)
      ) {
        setCommentsOutsideClicked(false)
      }
    }, false);
  }, [setCommentsOutsideClicked])


  useEffect(() => {
    window.addEventListener('mousedown', (event) => {
      const domNode = document.getElementById('summaryFilterPopover');
      if (
        (event.target !== domNode) && domNode !== null
        && !domNode.contains(event.target)
      ) {
        setSummaryOutsideClicked(false)
      }
    }, false);
  }, [setSummaryOutsideClicked])



  if (AUTH.loading || internalLoader || !forceUpdateState) {
    return (
      <div className='fullscreen__spinner' key='1'>
        <Spin size='large' />
      </div>
    );
  } else {
    if (AUTH.token) {
      return (
        AUTH.app_color_theme && (
          <>
            <Layout className='site__layout__wrapper' key='1'>
              <SidebarComponent
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                forceUpdate={forceUpdate}
              />
              <section
                className={'site__middle__content ' + (collapsed ? 'collapse' : '')}
              >
                {/* <SubheaderComponent /> */}
                {AUTH.token ? (
                  <>
                    {/* {AUTH.checkPermission(29, 'display-ticker') ? ( */}
                    <TickerTapeBroadcast />
                    {/* ) : null} */}
                    <Layout.Content
                      //className="site__layout__content"
                      style={{
                        alignItems: 'flex-start',
                        height: !AUTH.checkPermission(29, 'display-ticker')
                          ? 'calc(100vh - 40px)' //'100vh'
                          : 'calc(100vh - 40px)',
                      }}
                    >
                      {props.children}
                    </Layout.Content>
                  </>
                ) : (
                  <>{props.children}</>
                )}
              </section>
              <RightsideComponent />
            </Layout>
          </>
        )
      );
    } else {
      return (
        <>
          <Layout className='site__layout__wrapper' key='1'>
            <Layout className='site__layout' key='2'>
              {AUTH.token ? (
                <Layout.Content
                //className="site__layout__content"
                >
                  {props.children}
                </Layout.Content>
              ) : (
                <>{props.children}</>
              )}
            </Layout>
          </Layout>
        </>
      );
    }
  }
});

export default LayoutComponent;
