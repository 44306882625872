import React, { useState } from 'react';
import { Form, Button, Input, Col, Row, Modal } from 'antd';
import useStore from '../../../../store';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { vsmAlgorithm, vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const UnpublishComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {
    AlgorithmsStore: {
      TogglepublishData,
      statusValues,
      setupGrid,
      agGrid,
      unpublish_list,
    },
  } = useStore();
  const [saving, setSaving] = useState();
  const [unpublishButton, ToggleUnpublishButton] = useState(true);

  // Make function call to unpublish existing record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = statusValues.id;
    data.status = false;
    delete data['unpublish'];
    TogglepublishData(data)
      .then(() => {
        vsmNotify.success({
          message: vsmAlgorithm.unpublish,
        });
        close();
        setupGrid(agGrid);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        ToggleUnpublishButton(true);
      });
  };

  // Make unpublish button enable / disable
  const handleFieldChange = () => {
    if (form.getFieldsValue().unpublish.toLowerCase() === 'unpublish') {
      ToggleUnpublishButton(false);
    } else {
      ToggleUnpublishButton(true);
    }
  };

  // reset form and close unpublish form
  const close = () => {
    form.resetFields();
    ToggleUnpublishButton(true);
    props.close();
  };

  return statusValues ? (
    <Modal
      centered
      title={`${t('DealBuilder.Unpublish_Deal')} - #${statusValues.id} - ${statusValues.algorithm_name}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='unpublishform'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
          disabled={unpublishButton}
          shape='round'
          size='medium'
        >
         {t('DealBuilder.Unpublish')}
        </Button>,
        <Button key='2' htmlType='button' onClick={close} shape='round' size='medium'>
         {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='unpublishform' onFinish={handleSubmit}>
        <Row>
          <Col span={4}>
            <WarningFilled style={{ fontSize: 45 }} />
          </Col>
          <Col span={20}>
            <h3>
           {t('DealBuilder.Unpublishing_Builder')}
            </h3>
            <span>
              <b>{t('DealBuilder.Associate_Deal_Maker_Model')}:</b>
              <ul>
                {unpublish_list && unpublish_list.model.length > 0 ? (
                  unpublish_list.model.map((item, index) => (
                    <li key={index}>{item.model_name}</li>
                  ))
                ) : (
                  <li>{''}</li>
                )}
              </ul>
            </span>
            <span>
              <b>{t('DealBuilder.Associate_Deal_Maker_Fields')}</b>
              <ul>
                {unpublish_list && unpublish_list.tte_field.length > 0 ? (
                  unpublish_list.tte_field.map((item, index) => (
                    <li key={index}>{item.field_name}</li>
                  ))
                ) : (
                  <li>{''}</li>
                )}
              </ul>
            </span>
            <span>
              <b>{t('DealBuilder.Associate_Builder')} :</b>
              <ul>
                {unpublish_list && unpublish_list.length > 0 ? (
                  unpublish_list.algorithm.map((item, index) => (
                    <li key={index}>{item.algorithm_name}</li>
                  ))
                ) : (
                  <li>{''}</li>
                )}
              </ul>
            </span>
            <Form.Item style={{ marginBottom: 0 }} name='unpublish'>
              <Input
                placeholder={`${t('DealBuilder.Unpublish_Confirm')}`}
                onChange={handleFieldChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  ) : null;
});

export default UnpublishComponent;
