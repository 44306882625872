import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import FormComponent from './FormComponent';
import debounce from 'lodash/debounce';
import useStore from '../../../store';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { vsmNotify, vsmLeadsList } from '../../../config/messages';
import { default as AddPeopleComponent } from '../../PeopleManagement/People/component/AddComponentNew';
import { default as AddOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/AddComponentNew';
import { default as EditPeopleComponent } from '../../PeopleManagement/People/component/EditComponentNew';
import { default as EditOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/EditComponentNew';

import TrackTimeComponent from '../../../component/TrackTimeComponent';

const EditComponent = observer((props) => {
  const {
    PEOPLE,
    LEADSSTORE,
    LEADSSTORE: { editValues },
    ORGANISATION,
    //ORGANISATION: { getMappedOrg },
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    PROMOTIONSTORE,
  } = useStore();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addOrgModal, setAddOrgModal] = useState(false);
  const [editPeopleDrawer, setEditPeopleDrawer] = useState(false);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null); //Used only for ORG edit.

  const close = () => {
    props.close();
    LEADSSTORE.editValues = null;
    form.resetFields();
    setDisabled(true);
    setSaving(false);
  };

  const handleSubmit = (data) => {
    data.deadline_date =
      data.deadline_date && moment(data.deadline_date).format('YYYY-MM-DD');
    setSaving(true);

    LEADSSTORE.editLeadData(editValues.id, data)
      .then(() => {
        close();
        if (location.pathname === '/leads/deals') {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
        }
        if (location.pathname === '/leads/opportunities') {
          LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
        }
        if (location.pathname === '/leads/active') {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
        }
        if (location.pathname === '/promotion') {
          PROMOTIONSTORE.setupGrid(PROMOTIONSTORE.agGrid);
        }
        vsmNotify.success({
          message: vsmLeadsList.edit,
        });
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });
          form.setFields(e.errors);
        }
        setSaving(false);
      });
  }, 200);

  useEffect(() => {
    if (editValues) {
      if (editValues.finance_value) {
        form.setFieldsValue({ finance_value: editValues.finance_value });
      }
      if (editValues.turn_over) {
        form.setFieldsValue({ turn_over: editValues.turn_over });
      }
      /*START: If Lead - Opportunity - Opportunityname value to set in from*/
      if (editValues && editValues.lead_status_id.parent_status_id === 2) {
        form.setFieldsValue({
          opportunity_name: editValues.opportunity_name && editValues.opportunity_name,
        });
      }
      /*END: If Lead - Opportunity - Opportunityname value to set in from*/

      form.setFieldsValue({
        classification_id:
          editValues.classification_id && parseInt(editValues.classification_id),
        lead_record_type: editValues.lead_record_type,
        full_name: editValues.people && editValues.people.id,
        organisation_name:
          editValues.organisation_name && editValues.organisation_name.id,

        phone: editValues.phone?.length > 0 ? editValues.phone : [{}],
        email: editValues.email?.length > 0 ? editValues.email : [{}],
        lead_source_details:
          editValues.lead_source_details && editValues.lead_source_details,
        lead_source: editValues.lead_source && editValues.lead_source,
        product_name: editValues.product_name && editValues.product_name,
        lead_status_id: editValues.lead_status_id && editValues.lead_status_id.id,

        lead_currency_id: editValues.lead_currency_id && editValues.lead_currency_id.id,
        deadline_date: editValues.deadline_date && moment(editValues.deadline_date),
        lead_owner_id: editValues.lead_owner_name && editValues.lead_owner_name.id,
        lead_holder_id: editValues.lead_holder_name && editValues.lead_holder_name.id,
        investment_hours: editValues.investment_hours && editValues.investment_hours,
      });
    }
  }, [LEADSSTORE, editValues, form]);

  // Open form for add new People
  const openAddModal = () => {
    setAddModal(true);
  };

  const setPeopleDropDown = (id) => {
    PEOPLE.getAllPeople({ selected_id: [id], mapping_type: 2 });
    form.setFieldsValue({ full_name: id });
    handleChange();
  };

  const setOrgDropDown = (id) => {
    //getMappedOrg();
    ORGANISATION.getAllROrg({
      selected_id: [id],
      mapping_type: 1,
    });
    form.setFieldsValue({ organisation_name: id });
    handleChange();
  };

  // Close form for add new People
  const closeAddModal = () => setAddModal(false);

  // Open form for add new Organisation
  const openAddOrgModal = () => {
    setAddOrgModal(true);
  };
  // Close form for close new Organisation
  const closeAddOrgModal = () => setAddOrgModal(false);

  // Open form for edit People
  const openEditPeopleDrawer = () => {
    var payload = { people_id: form.getFieldValue('full_name') };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setEditPeopleDrawer(true);
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  };
  // Close form for edit People
  const closeEditPeopleDrawer = () => {
    PEOPLE.getAllPeople({
      selected_id: [form.getFieldValue('full_name')],
      mapping_type: 2,
    });

    setEditPeopleDrawer(false);
    handleChange();
  };

  // Open form for edit existing Organisation and set values to form
  // set id for edit / view  Organisation drawer
  const setId = (id) => setTransactionId(id);
  const openEditOrgDrawer = () => {
    var payload = { organisation_id: form.getFieldValue('organisation_name') };
    ORGANISATION.setOrganisationValues(null);
    ORGANISATION.getRecordDetail(payload).then((data) => {
      setId(payload.organisation_id);
      ORGANISATION.setEditValues(data);
      setEditOrgModal(true);
    });
  };

  // Close form of edit Organisation
  const closeEditOrgDrawer = () => {
    ORGANISATION.getAllROrg({
      selected_id: [editValues?.id],
      mapping_type: 1,
    });

    LEADSSTORE.setEditValues({ id: editValues?.id });

    ORGANISATION.organisationValues = null;
    ORGANISATION.setEditValues(null);
    setEditOrgModal(false);
    handleChange();
  };

  return editValues ? (
    <>
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`Edit SOLD - #${editValues?.id}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right'>
            <Button
              key='1'
              form='editleadform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              shape='round'
              disabled={isDisabled}
            >
              Update
            </Button>
            <Button shape='round' onClick={close} key='2'>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          visible={props.visible}
          formid='editleadform'
          openAddModal={openAddModal}
          id={props.parent_id}
          openAddOrgModal={openAddOrgModal}
          openEditPeopleDrawer={openEditPeopleDrawer}
          openEditOrgDrawer={openEditOrgDrawer}
        />
        <TrackTimeComponent pageName={'leadlisting'} leadId={editValues.id} />
      </Drawer>
      <AddPeopleComponent
        visible={addModal}
        close={closeAddModal}
        setPeopleDropDown={setPeopleDropDown}
      />
      <AddOrgComponent
        visible={addOrgModal}
        close={closeAddOrgModal}
        setOrgDropDown={setOrgDropDown}
      />
      <EditPeopleComponent
        visible={editPeopleDrawer}
        close={closeEditPeopleDrawer}
        setPeopleDropDown={setPeopleDropDown}
      />
      <EditOrgComponent
        transactionId={transactionId}
        setId={setId}
        visible={editOrgModal}
        close={closeEditOrgDrawer}
        setOrgDropDown={setOrgDropDown}
      />
    </>
  ) : null;
});

export default EditComponent;
