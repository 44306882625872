import React, { useState, useEffect } from 'react';

import StructureLibraryView from '../../RightSideComponent/Structure/component/StructureLibraryView';

import useStore from '../../../../store';
import { observer } from 'mobx-react';
import Footer from './footer';
import DisplayMsgList from './DisplayDataList';
import { Spin } from 'antd';

const Structure = observer((props) => {
  const {
    // DEALDESKSTORE: { deal_data },
    // SSSLEADLIBRARYSTORE,
    SSSLEADLIBRARYSTORE: { sss_list, dealdeskSecLoader },
  } = useStore();

  const { listingType, apiTypeValue } = props;

  const [visibleScheduleLibraryDrawer, setViewDrawer] = useState(false);
  const [structureData, setStructureData] = useState([]);

  const openViewDrawer = (data) => {
    setStructureData(data);
    setViewDrawer(true);
  };
  const closeScheduleLibraryDrawer = () => {
    setStructureData([]);
    setViewDrawer(false);
  };

  // useEffect(() => {
  //   fetchListData(deal_data?.lead_information?.id, false, { type: 4 });
  // }, [fetchListData, deal_data]);


  // useEffect(() => {
  //   if (listingType === 'screen') {
  //     SSSLEADLIBRARYSTORE.currentTab = 3;
  //   } else if (listingType === 'structure') {
  //     SSSLEADLIBRARYSTORE.currentTab = 1;
  //   } else if (listingType === 'scrutinise') {
  //     SSSLEADLIBRARYSTORE.currentTab = 4;
  //   } else if (listingType === 'shortfall') {
  //     SSSLEADLIBRARYSTORE.currentTab = 5;
  //   }
  //   fetchListData(deal_data?.lead_information?.id, true, { type: SSSLEADLIBRARYSTORE.currentTab });
  // }, [fetchListData, deal_data, SSSLEADLIBRARYSTORE, SSSLEADLIBRARYSTORE.currentTab,listingType]);

  useEffect(() => { }, [sss_list, listingType]);

  return (
    <>
      <div className='structureRight'>
        <Spin
          size='large'
          spinning={dealdeskSecLoader}
          style={{
            textAlign: 'center',
            padding: '50px 30px',
          }}
        >
          <DisplayMsgList
            openViewDrawer={openViewDrawer}
            listData={sss_list || []}
            listingType={listingType}
            apiTypeValue={apiTypeValue}
          />
        </Spin>

        <Footer listingType={listingType} />
        <StructureLibraryView
          visibleScheduleLibraryDrawer={visibleScheduleLibraryDrawer}
          closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
          structure={structureData}
        />
      </div>
    </>
  );
});

export default Structure;
