import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { vsmCommon } from '../../../config/messages';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const MergeTicketListing = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    TicketsStore: { setMasterTicketToMerge, masterTicketToMerge },
  } = useStore();

  let gridOptions = {
    columnDefs: [
      {
        checkboxSelection: true,
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },

      {
        headerName: `${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`,
        field: 'ticket_number',
        tooltipField: 'ticket_number',
        //cellClass: 'cellClass',
        filter: 'agTextColumnFilter',

        lockPosition: true,
        cellClass: 'locked-col',
        width: 60,
        suppressNavigable: true,
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,
        field: 'owner',
        tooltipField: 'owner',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,
        field: 'ticketholder',
        tooltipField: 'ticketholder',
        filter: 'agSetColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`,
        field: 'master_ticket_number',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Subject')}`,
        headerTooltip: `${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Subject')}`,
        field: 'master_ticket.ticket_subject',
        tooltipField: 'master_ticket.ticket_subject',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        minWidth: 300,
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        field: 'organisation.organisation_name',
        tooltipField: 'organisation.organisation_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Subject')}`,
        headerTooltip: `${t('Common_Data.Subject')}`,
        field: 'ticket_subject',
        tooltipField: 'ticket_subject',
        cellClass: 'cellClass',
        minWidth: 300,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'ticket_description',
        tooltipField: 'ticket_description',
        cellClass: 'cellClass',
        minWidth: 300,
        filter: 'agTextColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Status')}`,
        headerTooltip: `${t('Common_Data.Status')}`,
        field: 'ticket_status.status_name',
        tooltipField: 'ticket_status.status_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Time_Spent')}`,
        headerTooltip: `${t('Common_Data.Time_Spent')}`,
        field: 'investment_hours',
        filter: false,
        sortable: false,
        tooltipField: 'investment_hours',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Tickets.Age')}(${t('Tickets.Day')}(s))`,
        headerTooltip: `${t('Tickets.Age')}`,
        field: 'age',
        filter: false,
        sortable: false,
        tooltipField: 'age',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Idle')}(${t('Tickets.Day')}(s))`,
        headerTooltip: `${t('Common_Data.Idle')}`,
        field: 'idle',
        filter: false,
        sortable: false,
        tooltipField: 'idle',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        // tooltipField: 'deadline_date',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        // cellRenderer: (params) => {
        //   return params?.data?.deadline_date === ' ' ||
        //     params?.data?.deadline_date === '' ||
        //     params?.data?.deadline_date === null
        //     ? ''
        //     : AUTH.getLocalTime(params?.data?.deadline_date, 0, 1);
        // },
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={params?.data?.deadline_date === ' ' ||
              params?.data?.deadline_date === '' ||
              params?.data?.deadline_date === null
              ? ''
              : AUTH.getLocalTime(params?.data?.deadline_date, 0, 1)}>
              {params?.data?.deadline_date === ' ' ||
                params?.data?.deadline_date === '' ||
                params?.data?.deadline_date === null
                ? ''
                : AUTH.getLocalTime(params?.data?.deadline_date, 0, 1)}
            </Tooltip>
          )
        },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        field: 'created_by_name',
        tooltipField: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        field: 'created_at',
        // tooltipField: 'created_at',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={AUTH.getLocalTime(params?.data?.created_at,AUTH.global_fulldate_format)}>
              {AUTH.getLocalTime(params?.data?.created_at,AUTH.global_fulldate_format)}
            </Tooltip>
          )
        },
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        // cellRenderer: (params) => {
        //   return AUTH.getLocalTime(params?.data?.created_at);
        // },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Modified')}  ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        field: 'updated_at',
        // tooltipField: 'updated_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={AUTH.getLocalTimeDealDesk(params?.data?.updated_at, AUTH.global_dateformat)}>
              {AUTH.getLocalTimeDealDesk(params?.data?.updated_at, AUTH.global_dateformat)}
            </Tooltip>
          )
        },
        // cellRenderer: (params) => {
        //   return AUTH.getLocalTimeDealDesk(params?.data?.updated_at, 'YYYY-MM-DD');
        // },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
    ],
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: '70vh',
        }}
      >
        <AgGridReact
          rowData={props.selectedMergeData}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            width: 120,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{}}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: false,
            animateRows: true,
          }}
          rowSelection={'single'}
          suppressRowClickSelection={true}
          onRowSelected={(params) => {
            if (masterTicketToMerge && params?.data?.id !== masterTicketToMerge) {
              setMasterTicketToMerge(params?.data?.id);
            } else if (masterTicketToMerge && params?.data?.id === masterTicketToMerge) {
              setMasterTicketToMerge(null);
            } else {
              setMasterTicketToMerge(params?.data?.id);
            }
          }}
        />
      </div>
    </>
  );
});
export default MergeTicketListing;
