import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import { ActionRenderer } from './ActionRenderer';
// import GridConfig from '../../../../config/GridConfig';
import LocalGridConfig from '../../../config/LocalGridConfig';
import PhoneNumberRender from '../../PeopleManagement/People/elements/PhoneNumberRender';
import EmailNumberRender from '../../PeopleManagement/People/elements/EmailNumberRender';
import DateTimeConfirmElements from '../../PeopleManagement/People/elements/DateTimeConfirmElements';
import HourglassConfirmElements from '../../PeopleManagement/People/elements/HourglassConfirmElements';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { GridLoader } from '../../../utils/GridLoader';
import moment from 'moment';
import { DateComparator } from '../../../utils/GlobalFunction';
// import moment from 'moment';

const PeopleSearchListing = observer((props) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();
  const [hourglassConfirm, setHourglassConfirm] = useState();
  const {
    openEditDrawer,
    openDeleteModal,
    openRestoreModal,
    openRoleListing,
    // openViewDrawer,
    openCloneDrawer,
    WasteManagement,
    SearchPage,
    AllView,
    // onCellKeyDown,
    showCheckbox = false,
    selectionChanged,
    // searchPayload
  } = props;

  const {
    ORGANISATION,
    PEOPLE,
    AUTH,
    SEARCHSTORE,
    SEARCHSTORE:{setResultCount, searchCount},
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  useEffect(() => {
    if (SearchPage) {
      PEOPLE.waste = false;
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.people_count;
    }
  }, [PEOPLE, SearchPage, SEARCHSTORE]);

  useEffect(() => {
    setResultCount(searchCount?.people_count);
    PEOPLE.getListSearch(PEOPLE.search_payload);
  }, [PEOPLE,setResultCount, searchCount?.people_count])

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        checkboxSelection: showCheckbox,
        valueGetter: function (params) {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        tooltipValueGetter: (params) => {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 67,
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Name')}`,
        field: 'people_name',
        tooltipField: 'people_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Title')}`,
        headerTooltip: `${t('Common_Data.Title')}`,
        field: 'title',
        tooltipField: 'title',
        filter: 'agTextColumnFilter',
        // valueGetter: (params) =>
        //   params.data && params.data.title ? params.data.title : '',
      },
      {
        headerName: `${t('Common_Data.Connected_Organisations')}`,
        headerTooltip: `${t('Common_Data.Connected_Organisations')}`,
        field: 'connected_organisations',
        tooltipField: 'connected_organisations',
        filter: 'agSetColumnFilter',
        floatingFilter: false,
      },
      // {
      //   headerName: `t/a`,
      //   headerTooltip: `Trade Name`,
      //   field: 'trade_name',
      //   tooltipField: 'trade_name',
      //   filter: 'agSetColumnFilter',
      // },
      // {
      //   headerName: `${t('Common_Data.People')} ${t('PeopleManagement.Role')}`,
      //   headerTooltip: `${t('Common_Data.People')} ${t('PeopleManagement.Role')}`,
      //   field: 'people_role',
      //   tooltipField: 'people_role',
      //   filter: 'agSetColumnFilter',
      //   valueGetter: (params) =>
      //     params.data &&
      //     (params.data.people_roles && params.data.people_roles.length > 0
      //       ? params.data.people_roles.map((x) => x.role_name)
      //       : null),
      // },
      {
        headerName: `${t('Common_Data.Phone')}`,
        headerTooltip: `${t('Common_Data.Phone')}`,
        field: 'phone',
        cellClass: 'cellClassDropDown',
        cellRenderer: 'phoneCellRenderer',
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: params => {
            return params.data.phone.length > 0 && params?.data?.phone[0].contact_value
          }
        }
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        headerTooltip: `${t('Common_Data.Email')}`,
        field: 'email',
        filter: 'agTextColumnFilter',
        cellClass: 'cellClassDropDown',
        cellRenderer: 'emailCellRenderer',
        filterParams: {
          valueGetter: params => {
            return params.data.email.length > 0 && params?.data?.email[0].contact_value
          }
        }
      },
      // {
      //   headerName: `${t('PeopleManagement.PPS')} ${t('Common_Data.Number')}`,
      //   headerTooltip: `${t('PeopleManagement.PPS')} ${t('Common_Data.Number')}`,
      //   field: 'pps_number',
      //   tooltipField: 'pps_number',
      //   filter: 'agTextColumnFilter',
      //   valueGetter: (params) =>
      //     params.data && params.data.pps_number ? params.data.pps_number : '',
      // },
      // {
      //   headerName: `${t('Common_Data.Address')}`,
      //   headerTooltip: `${t('Common_Data.Address')}`,
      //   field: 'address',
      //   tooltipField: 'address',
      //   filter: 'agTextColumnFilter',
      //   cellClass: (params) => ['cell_scroll'],
      //   cellRendererFramework: (data) => <div>{data.data && data.data.address}</div>,
      // },
      {
        headerName: `Country`,
        headerTooltip: `Country`,
        field: 'addresses_global_countries.country_name',
        tooltipField: 'global_countries.country_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            ORGANISATION.getCountryFilter().then((data) => {
              params.success([...data?.data?.map((x) => x.country_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,
        field: 'last_updated',
        cellRendererFramework: (params) => {
          return params?.data?.last_updated && (
            <Tooltip title={moment(params?.data?.last_updated).format(AUTH.global_fulldate_format)}>
              {moment(params?.data?.last_updated).format(AUTH.global_fulldate_format)}
            </Tooltip>
          )
        },
        // cellRenderer: (params) => {
        //   return AUTH.getLocalTime(params?.data?.last_updated);
        // },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'reset'],
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Credebt_Classification.Classification')}`,
        headerTooltip: `${t('Credebt_Classification.Classification')}`,
        field: 'classification',
        tooltipField: 'global_industries.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            ORGANISATION.getIndustries().then((data) => {
              params.success([...data?.data?.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Type')}`,
        headerTooltip: `${t('Common_Data.Type')}`,
        field: 'people_role',
        tooltipField: 'people_role',
        // filter: 'agSetColumnFilter',
        floatingFilter: false,
        valueGetter: (params) =>
          params.data &&
          (params.data.people_roles && params.data.people_roles.length > 0
            ? params.data.people_roles.map((x) => x.role_name)
            : null),
      },
      // {
      //   headerName: `Type`,
      //   headerTooltip: `Type`,
      //   field: 'type',
      //   tooltipField: 'type',
      //   filter: 'agSetColumnFilter',
      // },
      // {
      //   headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
      //   headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
      //   field: 'created_by',
      //   tooltipField: 'created_by',
      //   filter: 'agSetColumnFilter',
      //   filterParams: {
      //     defaultToNothingSelected: true,
      //     buttons: ['apply', 'reset'],
      //     values: (params) => {
      //       PEOPLE.getUsersList().then((data) => {
      //         params.success([...data.data.map((x) => x.name)]);
      //       });
      //     },
      //   },
      //   valueGetter: (params) =>
      //     params.data && params.data.created_by ? params.data.created_by : '',
      // },


      // {
      //   headerName: `#${t('Common_Data.ID')}`,
      //   headerTooltip: `#${t('Common_Data.ID')}`,
      //   field: 'id',
      //   tooltipField: 'id',
      //   filter: 'agNumberColumnFilter',
      //   width: 80,
      // },
      // {
      //   headerName: `${t('Common_Data.Exchange')} ${t('Common_Data.Ref')} ${t('Common_Data.ID')}`,
      //   headerTooltip: `${t('Common_Data.Exchange')} ${t('Common_Data.Ref')} ${t('Common_Data.ID')}`,
      //   field: 'crm_id',
      //   tooltipField: 'crm_id',
      //   filter: 'agTextColumnFilter',
      //   width: 80,
      // },
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: 141,
        // minWidth: 120,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(data);
      setOpenDateTimeModal(true);
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  const openConfirmHourglassModal = (data) => {
    setOpenHourglassModal(true);
    setCount(count + 1);
    setHourglassConfirm(data);
  };
  const closeHourglassModal = () => setOpenHourglassModal(false);

  // Columns size change, move, pin
  let columns = [];
  let columnConfig = localStorage.getItem('search_people_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/people/${params?.node?.data?.leads_transactions_id}`, '_blank');
          }
        },
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/people/${params?.node?.data?.leads_transactions_id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },

        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  function getClipBoardProcess(params) {
    if (params.column.colId === 'phone') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0].contact_value) {
          params.value = params.value[0].contact_value;
        }
      }
    }
    if (params.column.colId === 'email') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0].contact_value) {
          params.value = params.value[0].contact_value;
        }
      }
    }
    return params.value;
  }

  GridLoader(PEOPLE);

  return (
    <div
      className={`ag-theme-alpine grid_wrapper  ${SearchPage ? "paginationAtTop" : ""}`}
      style={{
        height: WasteManagement
          ? 'calc(100vh - 11rem)'
          : list_fullscreen
            ? fullscreen_heigthwidth
            : SearchPage && !AllView
              ? 'calc(100vh - 13rem)'
              : SearchPage && AllView
                ? '150px'
                : 'calc(100vh - 15rem)',
        minHeight: props.AllView ? '100px' : '250px',
      }}
    >
      <AgGridReact
        rowData={PEOPLE.list_data}
        modules={AllModules}
        columnDefs={columns}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openEditDrawer,
          openDeleteModal,
          openRestoreModal,
          // openViewDrawer,
          openRoleListing,
          openCloneDrawer,
          WasteManagement,
          phoneCellRenderer: PhoneNumberRender,
          emailCellRenderer: EmailNumberRender,
          openConfirmHourglassModal,
          openConfirmDateTimeModal,
          count,
          SearchPage,
        }}
        onGridReady={PEOPLE.setupGridSearch}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: true,
          // onRowDoubleClicked: (event) => openViewDrawer(event?.data),
          getContextMenuItems: getContextMenuItems,
          processCellForClipboard: getClipBoardProcess,
        }}
        onColumnResized={PEOPLE.onGridChangedSearch}
        onColumnMoved={PEOPLE.onGridChangedSearch}
        onColumnPinned={PEOPLE.onGridChangedSearch}
        onSortChanged={(params) => {
          if (localStorage.getItem('params')) {
            var temp = JSON.parse(localStorage.getItem('params'));
            localStorage.setItem('params', JSON.stringify({ ...temp, PeopleSearchStore: { sort: params.api.getSortModel() }, }));
          } else {
            localStorage.setItem('params',
              JSON.stringify({ PeopleSearchStore: { sort: params.api.getSortModel() }, })
            );
          }
        }}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        // onCellKeyDown={onCellKeyDown}
        // onFilterChanged={(e) => {
        //   if (e.hasOwnProperty('afterFloatingFilter') && PEOPLE.selectedGridFilter) {
        //     PEOPLE.setSelectedGridFilter(null);
        //   }
        // }}
        onSelectionChanged={selectionChanged}
      />

      <DateTimeConfirmElements
        visible={openDateTimeModal}
        close={closeDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <HourglassConfirmElements
        visible={openHourglassModal}
        close={closeHourglassModal}
        hourglassConfirm={hourglassConfirm}
      />
    </div>
  );
});

export default PeopleSearchListing;
