import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';
import { vsmNotify } from '../../config/messages';

export default class UsersStore {
  list_data = null;
  all_data = [];
  per_page = LocalGridConfig.options.paginationPageSize;
  agGrid = null;
  agGridRelOrg = null;
  editValues = [];
  current_page = 1;
  total = 0;
  dropdown_role_list = [];
  usersetting_duration_list = [];
  usersetting_timezone_list = [];
  dropdown_reminder_hour_list = [];
  dropdown_reminder_minute_list = [];

  proxyValues = null;
  rPeopleValues = null;
  editProfileValues = null;
  editValues = null;
  deleteValues = null;
  resetValues = null;
  usersettingValues = null;
  statusValues = null;
  restoreValues = null;
  permission_list = null;
  view_ledger_list = null;
  modifyPermissionValues = null;
  salesTargetValues = null;
  salesTargetUID = null;

  agGrid = null;
  ledger_list = null;
  archive = false;

  initialValues = {
    phone: [null],
    mobile: [null],
    email: [null],
    adddress: [],
  };

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // set form values to reset password
  setResetPassword = (data) => {
    this.resetValues = data;
  };

  // set form values to reset password
  setUserSetting = (data) => {
    this.usersettingValues = data;
  };

  // set form values to modify permissions
  setModufyPermissionValues = (data) => {
    this.modifyPermissionValues = data;
  };

  // set form values to status
  setStatusValues = (data) => {
    this.statusValues = data;
  };

  // set form values to restore
  setRestoreValues = (data) => {
    this.restoreValues = data;
  };

  setDataTableValues = (data) => {
    this.permission_list = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(page);
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('users_list_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.USERS) {
        params = { request: temp.USERS };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          USERS: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          USERS: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    this.setFilter(params.api);
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  changeArchiveValue = (value) => {
    this.archive = value;
    this.list_data = [];
    this.all_data.forEach((item, index) => {
      if (this.archive === true) {
        if (item.deleted_at !== null) {
          this.list_data.push(item);
        }
      } else {
        this.list_data.push(item);
      }
    });
  };

  // call api to get records
  getList = (payload = {}, isSuperAdmin = false) => {
    let filter;
    if (isSuperAdmin) {
      filter = {
        status: { values: ['Active'], filterType: 'set' },
      };
    }

    this.list_data = null;
    return Axios.post(`user/list`, payload).then(({ data }) => {
      if (data.data.length) {
        var tempData = [];
        data.data.forEach((item, index) => {
          item.srno = index + 1;
          item.status = item.status === 1 ? 'Active' : 'Inactive';

          if (this.archive === true && item.deleted_at !== null) {
            tempData.push(item);
          }
        });
      }
      this.list_data = tempData;
      this.all_data = data.data;
      if (this.archive === false) {
        this.list_data = data.data;
      }
      if (this.agGrid && this.agGrid.columnApi) {
        let param = this.getFilter(this.agGrid);
        if (param && param.request) {
          this.agGrid.api.setFilterModel(param.request.filter);
          this.agGrid.api.setSortModel(this.agGrid.columnApi.getColumnState());
        }
      }
      let columnConfig = localStorage.getItem('users_list_grid');
      if (this.agGrid && this.agGrid.columnApi && columnConfig) {
        this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
      }

      //---------------START:IF USER ADMIN LOGGED IN DEFAULT FILTER SELECTION ACTIVE ADDED---//
      if (this.agGrid && filter && isSuperAdmin) {
        this.agGrid.api.setFilterModel(filter);
      }
      //---------------END:IF USER ADMIN LOGGED IN DEFAULT FILTER SELECTION ACTIVE ADDED---//
      return data;
    });
  };

  //call api to get permissions list
  getPermissionsList = (formdata) => {
    return Axios.post('permission/list', formdata)
      .then(({ data }) => {
        this.permission_list = data.data;
        return data;
      })
      .catch(() => {
        this.permission_list = null;
      });
  };

  //call api to get role list
  getRoleList = () => {
    return Axios.get('user/role/list').then(({ data }) => {
      this.dropdown_role_list = data.data;
      return data;
    });
  };

  getViewLedgerList = (formdata) => {
    return Axios.get(`user/${formdata.id}`).then(({ data }) => {
      this.view_ledger_list = [data.data];
      return data;
    });
  };

  // Call add api
  AddData = (formdata) => {
    return Axios.post(`user/add`, formdata)
      .then(({ data }) => {})
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`user/edit/${formdata.id}`, formdata)
      .then(({ data }) => {})
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.post(`user/delete/${formdata.id}`, formdata)
      .then(({ data }) => {})
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // BulkAction for Approve, Disapprove and Delete
  BulkAction = (formdata) => {
    let array = this.agGrid && this.agGrid.api.getSelectedNodes();
    let selectedId = [];
    array && array.map((item) => selectedId.push(item.data.id));
    var payload = { id: selectedId, action: formdata };
    return Axios.post(`user/bulk/action`, payload)
      .then(({ data }) => {
        vsmNotify.success({
          message: data.message,
        });
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        vsmNotify.error({
          message: data.errors[0].errors[0],
        });
        // message.error(data.errors[0].errors[0]);
        return Promise.reject(data);
      });
  };

  //api call for modify user's permission
  ModifyPermissionData = (formdata) => {
    return Axios.post(`user/role/permission/create`, formdata)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ActivateDeactivateDate = (formdata) => {
    return Axios.post(`user/status/${formdata.id}`, formdata)
      .then(({ data }) => {})
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  RestoreData = (formdata) => {
    return Axios.post(`user/restore/${formdata.id}`, formdata)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getNameFromEmail = (formdata) => {
    return Axios.post(`user/email/check`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getLedgerList = () => {
    return Axios.post('user/ledger/list').then(({ data }) => {
      this.ledger_list = data.data;
      return data;
    });
  };

  // set form values to edit
  setEditProfileValues = (id) => {
    return Axios.get(`user/${id}`)
      .then(({ data }) => {
        this.editProfileValues = data.data;
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  resetPasswordCall = (formdata) => {
    return Axios.post(`user/reset/password`, formdata)
      .then(({ data }) => {})
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setProxyLogin = (data) => {
    this.proxyValues = data;
  };

  // call api for proxy login
  doProxyLogin = (formdata) => {
    return Axios.get(`proxy-login/${formdata.id}`)
      .then(({ data }) => {
        this.proxyValues = null;
        localStorage.setItem('previous_token', localStorage.getItem('token'));
        localStorage.setItem('token', data.access_token);
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getDurationvalues = () => {
    const arr = [];

    for (var i = 5; i <= 180; i += 5) {
      let obj = {};
      obj['value'] = i;
      obj['text'] = i + ' minutes';
      arr.push(obj);
    }
    this.usersetting_duration_list = arr;
    return this.usersetting_duration_list;
  };

  getReminderHourvalues = () => {
    const arr = [];

    for (var i = 1; i <= 23; i += 1) {
      let obj = {};
      obj['value'] = i;
      //obj['text'] = i;
      obj['text'] = i + ' Hours';
      arr.push(obj);
    }
    this.dropdown_reminder_hour_list = arr;
    return this.dropdown_reminder_hour_list;
  };

  getReminderMinutevalues = () => {
    const arr = [];

    for (var i = 15; i <= 480; i += 15) {
      let obj = {};
      obj['value'] = i;
      //obj['text'] = i;
      obj['text'] = i + ' Minutes';
      arr.push(obj);
    }
    this.dropdown_reminder_minute_list = arr;
    return this.dropdown_reminder_minute_list;
  };

  getFetchTimezone = () => {
    // return Axios.get(`globaltimezone/list`).then(({ data }) => {
    //   this.usersetting_timezone_list = data.data;
    //   return data;
    // });
    return Axios.get(`globalcountries/list`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.timezone_new = '(UTC ' + item.time_zone + ') ' + item.country_name;
        });
      }
      this.usersetting_timezone_list = data.data;
      return data;
    });
  };

  getUserSetting = (formdata) => {
    return Axios.post(`user/get-settings`, formdata)
      .then(({ data }) => {
        return data.data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  saveUserSetting = (formdata) => {
    return Axios.post(`user/update-settings`, formdata)
      .then(({ data }) => {})
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getSalesTarget = (data) => {
    this.salesTargetValues = data.id;
  };
  fetchSalesTargetData = (data) => {
    return Axios.get(`user/sales-target/${data}`)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  updateSalesTarget = (formdata) => {
    return Axios.post(`user/update-sales-target/${formdata.user_id}`, formdata)
      .then(({ data }) => {
        this.salesTargetValues = null;
        this.salesTargetUID = null;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  updateProfileData = (formdata, id) => {
    return Axios.post(`user/edit/${id}`, formdata)
      .then(({ data }) => {
        this.editProfileValues = null;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== 'logo_url') {
        data[key] && formData.append(key, data[key]);
      }
    });
    return formData;
  };
  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'users_list_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // reset function
  handleReset = () => {
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.setSortModel(null);
    this.agGrid.api.onFilterChanged(null);
  };
}

decorate(UsersStore, {
  list_data: observable,
  all_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  initialValues: observable,
  editProfileValues: observable,
  editValues: observable,
  deleteValues: observable,
  resetValues: observable,
  permission_list: observable,
  setResetPassword: observable,
  agGrid: observable,
  dropdown_role_list: observable,
  modifyPermissionValues: observable,
  statusValues: observable,
  restoreValues: observable,
  ledger_list: observable,
  archive: observable,
  view_ledger_list: observable,
  proxyValues: observable,
  usersetting_duration_list: observable,
  usersetting_timezone_list: observable,
  usersettingValues: observable,
  salesTargetValues: observable,
  salesTargetUID: observable,
  editProfileDetails: observable,
  dropdown_reminder_hour_list: observable,
  dropdown_reminder_minute_list: observable,

  getList: action,
  getPermissionsList: action,
  getRoleList: action,
  AddData: action,
  EditData: action,
  DeleteData: action,
  setModufyPermissionValues: action,
  setStatusValues: action,
  setEditValues: action,
  setDeleteValues: action,
  BulkAction: action,
  setupGrid: action,
  setPageSize: action,
  ActivateDeactivateDate: action,
  setRestoreValues: action,
  getNameFromEmail: action,
  getLedgerList: action,
  changeArchiveValue: action,
  getViewLedgerList: action,
  setDataTableValues: action,
  onFilterChanged: action,
  setEditProfileValues: action,
  setProxyLogin: action,
  doProxyLogin: action,
  getDurationvalues: action,
  getFetchTimezone: action,
  getUserSetting: action,
  setUserSetting: action,
  saveUserSetting: action,
  updateProfileData: action,
  fetchSalesTargetData: action,
  JsonToFormData: action,
  onGridChanged: action,
  handleReset: action,
  getReminderHourvalues: action,
  getReminderMinutevalues: action,
});
