import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import { convertError } from '../../../utils/GlobalFunction';

export default class DealStore {
  algorithm_list = null;
  currency_list = null;
  specific_condi_list = null;
  dealDetails = null;
  dropdown_bank_list = null;
  dropdown_acc_system_list = null;
  isDisable = true

  getAlgorithmList = () => {
    return Axios.get(`algorithm/name/list`)
      .then(({ data }) => {
        this.algorithm_list = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  getCurrencyList = () => {
    return Axios.get(`globalcurreny/list`)
      .then(({ data }) => {
        this.currency_list = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  getSpecificCondiList = () => {
    return Axios.get(`deal-desk/specific-conditions-list`)
      .then(({ data }) => {
        this.specific_condi_list = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  saveFirstStepDeal = (id, formdata) => {
    return Axios.post(`deal-desk/create-deal/${id}`, formdata)
      .then(({ data }) => {
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  updateDealModel = (id, formdata) => {
    return Axios.post(`deal-desk/create-deal/${id}`, formdata)
      .then(({ data }) => {
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  fetchDealPDFView = (id) => {
    return Axios.post(`deal-desk/create-deal-sum-pdf-view/${id}`)
      .then((data) => {
        return data && data.data && data.data.data;
      })
  }

  fetchDealData = (id) => {
    return Axios.get(`deal-desk/read-deal/${id}`)
      .then(({ data }) => {
        this.dealDetails = data && data.data;

        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  getBankList = () => {
    return Axios.get(`global/banks/get`)
      .then(({ data }) => {
        this.dropdown_bank_list = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  getAccountsystemList = () => {
    return Axios.get(`deal-desk/account-system-list`)
      .then(({ data }) => {
        this.dropdown_acc_system_list = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  sendEmail = (id, payload) => {
    return Axios.post(`deal-desk/send_deal`, payload)
      .then((data) => {
        this.isDisable = true
        return data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };

  fetchDealDetails = (id, payload) => {
    let url = `deal-desk/create-deal-read/${id}`
    return Axios.post(url, payload).then(({ data }) => {
      return data && data.data;
    })
  }

  saveDealDetails = (id, payload) => {
    return Axios.post(`deal-desk/create-deal-new/${id}`, payload)
      .then(({ data }) => {
        this.isDisable = false
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(convertError(data));
      });
  };
}
decorate(DealStore, {
  specific_condi_list: observable,
  currency_list: observable,
  dealDetails: observable,
  algorithm_list: observable,
  dropdown_bank_list: observable,
  dropdown_acc_system_list: observable,
  isDisable: observable,
  getCurrencyList: action,
  getSpecificCondiList: action,
  saveFirstStepDeal: action,
  fetchDealData: action,
  getBankList: action,
  getAccountsystemList: action,
  updateDealModel: action,
  sendEmail: action,
  getAlgorithmList: action,
  fetchDealDetails: action,
  fetchDealPDFView: action,
});
