import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Checkbox } from 'antd';
import FormComponent from './FormComponent';
// import moment from 'moment';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store';
import { calculateHeight, trimExtraCharacters } from '../../../../utils/GlobalFunction';
import { vsmDealDesk, vsmNotify } from '../../../../config/messages';
import ScoreLibraryView from './ScoreLibraryView';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [viewDrawer, setViewDrawer] = useState(false); //# USED TO OPEN COPY FROM LIBRARY DRAWER

  const [form] = Form.useForm();
  const INITIAL_HEIGHT = '18px';
  //-----------  For Deadline date Switch is on or not --------------//
  const [is_template, setIsTemplate] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  //-----------------------------------------------------------------//

  const {
    AUTH,
    SCORELIBRARYSTORE: {
      Score,
      SetScore,
      calculateHeightOnEdit,
      setEditValues,
      editValues,
      saveScoreData,
      fetchScoreLibrary,
    },
  } = useStore();
  const [isDisabled, setDisabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const close = () => {
    setEditValues(null);
    form.resetFields();
    setDisabled(false);
    SetScore([]);
    props.close();
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.is_library = true;
    data.score_json = JSON.stringify(Score);
    data.leads_transactions_id = null;
    data.is_template = is_template;
    data.id = editValues?.id;
    saveScoreData(editValues?.id, data)
      .then((data) => {
        fetchScoreLibrary();
        close();
        vsmNotify.success({
          message: vsmDealDesk.score.saveSuccess,
        });
      })
      .catch((e) => {
        if (e.errors) {
          let schedule_description_error = 0;
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (e === 'description') {
                  schedule_description_error = 1;
                }
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });

          if (schedule_description_error) {
            vsmNotify.error({
              message: `${t('DealDesk.Schedule_Description')}!`,
            });
          } else {
            form.setFields(e.errors);
          }
        }
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (props.visible && editValues) {
      // Character Length Limit from Setting

      let lengthLimit = 170;
      if (AUTH?.user?.bonus_figures) {
        if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
          lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
        }
      }

      //--------------------------------------- Calculate height based on content ------------------------------//

      let schedule_data = editValues.score_json || [];
      calculateHeightOnEdit(editValues.score_json).then(
        debounce(() => {
          if (schedule_data?.length > 0) {
            setDisabled(false);
          }
          schedule_data &&
            schedule_data.forEach((obj, index) => {
              obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

              obj.height = calculateHeight(
                `score_innerContent_textarea_${obj.item_key}`,
                INITIAL_HEIGHT,
                obj.detail,
                true
              );

              if (obj.children && obj.children.length > 0) {
                obj.children.forEach((obj1, index) => {
                  obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                  obj1.height = calculateHeight(
                    `score_innerContent_textarea_${obj1.item_key}`,
                    INITIAL_HEIGHT,
                    obj1.detail,
                    true
                  );
                  if (obj1.children && obj1.children.length > 0) {
                    obj1.children.forEach((obj2, index) => {
                      obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                      obj2.height = calculateHeight(
                        `score_innerContent_textarea_${obj2.item_key}`,
                        INITIAL_HEIGHT,
                        obj2.detail,
                        true
                      );
                    });
                  }
                });
              }
            });
          form.setFieldsValue({
            title: editValues?.title,
            description: editValues?.description,
            classification_id: editValues?.classification_id,
            product: editValues?.product,
          });
        }, 200)
      );
      SetScore(schedule_data);
      //--------------------------------------------------------------------------------------------------------//
      setIsTemplate(editValues?.is_template);
    }
  }, [editValues, SetScore, props.visible, calculateHeightOnEdit, AUTH?.user, form]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        if (Score?.length > 0) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  //-----------START: LIBRARY COPY---------------//
  const openViewDrawer = (data) => {
    setViewDrawer(true);
  };
  const closeViewDrawer = () => setViewDrawer(false);
  //-----------END: LIBRARY COPY---------------//

  return editValues ? (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('DealDesk.Edit_Score_Title')} - #${editValues?.id} - ${
        editValues?.title
      }`}
      destroyOnClose={true}
      footer={[
        <>
          <div className='DealLibrary-ScheduleFormFooter'>
            <div className='mr-10'>
              <Checkbox
                checked={is_template}
                onChange={(e) => setIsTemplate(e.target.checked)}
                disabled={editValues?.is_template}
              >
                {t('DealDesk.Save_Template')}
              </Checkbox>
              {/* <label className='mr-10'>Save as Template</label> */}
            </div>
            <div>
              <Button
                key='1'
                form='editfromScore'
                className='mr-10'
                loading={saving}
                htmlType='submit'
                type='primary'
                disabled={isDisabled || !is_template}
                shape='round'
                size='medium'
              >
                {t('Common_Data.Update')}
              </Button>
              <Button shape='round' size='medium' onClick={close} key='2'>
                {t('Common_Data.Cancel')}
              </Button>
            </div>
          </div>
        </>,
      ]}
    >
      <FormComponent
        form={form}
        onChange={handleChange}
        Score={Score}
        forceUpdate={forceUpdate}
        SetScore={SetScore}
        INITIAL_HEIGHT={INITIAL_HEIGHT}
        handleSubmit={handleSubmit}
        id={`editfromScore`}
        openViewDrawer={openViewDrawer}
      />

      {viewDrawer && <ScoreLibraryView visible={viewDrawer} close={closeViewDrawer} />}
    </Drawer>
  ) : null;
});

export default EditComponent;
