import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Popover, Spin } from 'antd';
import useStore from '../../../store';
import ReassignConfirmElements from './ReassignConfirmElements';
import DeleteComponent from './DeleteComponent';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { vsmNotify, vsmProductivity } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const ActionHeaderComponent = observer((props) => {
  const {t} = useTranslation();
  const [reAssignUser, setReAssignUser] = useState(null); // reassing list
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false); // delete modal
  const [reAssingTempData, setReAssingTempData] = useState(null); // reassing modal
  const [deleteDealLeads, setDeleteDealLeads] = useState(); // reassing modal
  const {
    AUTH,
    BLOCKEDITASSIGNSTORE,
    BLOCKEDITASSIGNSTORE: { agGrid },
  } = useStore();

  // Reassign User

  const openConfirmReassignModal = (userid, username) => {
    var selectedRow = BLOCKEDITASSIGNSTORE.agGrid.api.getSelectedRows();
    if (selectedRow.length > 0) {
      let selected = selectedRow.map((item) => item.id);
      let tempReAssign = {
        userid: userid,
        username: username,
        leadid: selected,
      };
      setReAssingTempData(tempReAssign);
      setOpenReassignModal(true);
    } else {
      vsmNotify.error({ message: vsmProductivity.errorNoRowSelection });
    }
  };
  const closeReassignModal = () => {
    setOpenReassignModal(false);
  };

  useEffect(() => {
    BLOCKEDITASSIGNSTORE.getReassignUserList().then((data) => {
      setReAssignUser(data.data);
    });
  }, [BLOCKEDITASSIGNSTORE]);

  const userList = (
    <div className='ticketsPopoverIcons username'>
      <ul>
        {reAssignUser ? (
          reAssignUser.map(function (item) {
            return (
              <li
                key={item.id}
                onClick={() => openConfirmReassignModal(item.id, item.name)}
              >
                {item.name}
              </li>
            );
          })
        ) : (
          <div className='spinCenter'>
            <Spin />
          </div>
        )}
      </ul>
    </div>
  );

  // Delete User
  const openDeleteDrawer = () => {
    var selectedRow = BLOCKEDITASSIGNSTORE.agGrid.api.getSelectedRows();
    if (selectedRow.length > 0) {
      let selected = selectedRow.map((item) => item.id);

      let deleteLeads = {
        leadid: selected,
      };

      setDeleteDealLeads(deleteLeads);
      setDeleteDrawer(true);
    } else {
      vsmNotify.error({ message: vsmProductivity.errorNoRowSelection });
    }
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const onchangeCheckbox = (e) => {
    if (e.target.checked) {
      agGrid.api.forEachNode(function (node) {
        node.setSelected(true);
      });
    } else {
      agGrid.api.deselectAll();
    }
  };

  return (
    <div className='headerAction'>
      {(AUTH.checkPermission(5, 'delete') || AUTH.checkPermission(5, 'reassign')) && (
        <Checkbox onChange={onchangeCheckbox} className='headerCheckbox' />
      )}
      {AUTH.checkPermission(5, 'reassign') && (
        <span>
          <Popover
            content={userList}
            placement='bottomRight'
            trigger='hover'
            overlayClassName='profileMenu'
          >
            <img
              className='headerIcon'
              src={AUTH.GetThemedImage('Reassign')}
              alt={`${t('Block_Edit_Assign.Re_Assign')}`}
              title={`${t('Block_Edit_Assign.Reassign')}`}
              // onMouseOver={() => {
              //   getReassignUserList().then((data) => {
              //     setReAssignUser(data.data);
              //   });
              // }}
            />
          </Popover>
        </span>
      )}
      {AUTH.checkPermission(5, 'delete') && (
        <span>
          <img
            className='headerIcon'
            src={AUTH.GetThemedImage('Waste')}
            alt={`${t('Common_Data.Delete')}`}
            title={`${t('Common_Data.Delete')}`}
            onClick={() => openDeleteDrawer()}
          />
        </span>
      )}
      <ReassignConfirmElements
        visible={openReassignModal}
        close={closeReassignModal}
        reAssingTempData={reAssingTempData}
      />
      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        deleteDealLeads={deleteDealLeads}
      />
    </div>
  );
});
export default ActionHeaderComponent;
