import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { Popover, Spin } from 'antd';

const GroupuserRenderer = observer((props) => {
  const [loading, setLoading] = useState(true);

  const {
    USERGROUP: { group_user_list, fetchGroupUsers },
  } = useStore();

  const content = (
    <div className='ticketsPopoverIcons username'>
      {loading ? (
        <Spin />
      ) : (
        <ul>
          {group_user_list &&
            group_user_list.length > 0 &&
            group_user_list.map((item, index) => {
              return <li key={index}>{item.username}</li>;
            })}
        </ul>
      )}
    </div>
  );

  return (
    <div>
      <Popover
        content={content}
        placement='top'
        arrowPointAtCenter
        overlayClassName='profileMenu'
      >
        <span
          onMouseEnter={() =>
            fetchGroupUsers(props.data.id).then((data) => {
              setLoading(false);
            })
          }
        >
          {props.data.total_users}
        </span>
      </Popover>
    </div>
  );
});
export default GroupuserRenderer;
