// import { useEffect, useState } from 'react';
// import { useIdleTimer } from 'react-idle-timer';
// import useStore from '../store';
// import { Button, Col, Row, Modal } from 'antd';
// import { WarningFilled } from '@ant-design/icons';
// import moment from 'moment';
// import { useTranslation } from 'react-i18next';

const TrackTimeComponent = (
  // {
  // saveTime = 300000,
  // idleTime = 450000,
  // leadId = 0,
  // isDealDesk = false,
  // pageName = null,
  // }
) => {
  // const {
  //   LEADSSTORE,
  //   AUTH: { user, setIsUserIdle, setCallNotesAPIAfterUserBack },
  //   DEALDESKSTORE: { deal_data },
  // } = useStore();

  // const { t } = useTranslation();
  // const [isIdleVisible, setIsIdleVisible] = useState(false);
  // const [isSaveIdleTime, setIsSaveIdleTime] = useState(true);

  // if (
  //   user &&
  //   user.bonus_figures &&
  //   user.bonus_figures.length > 0 &&
  //   user.bonus_figures[0]?.idle_popup_duration
  // ) {
  //   idleTime =
  //     (parseInt(moment(user.bonus_figures[0].idle_popup_duration).format('m')) * 60 +
  //       parseInt(moment(user.bonus_figures[0].idle_popup_duration).format('s'))) *
  //     1000;
  // }

  // const SaveTime = () => {
  //   if (getTotalActiveTime() > 5) {
  //     if (!isIdleVisible) {
  //       let seconds = parseInt(getTotalActiveTime() / 1000);
  //       let payload = {
  //         lead_transaction_id: leadId,
  //         page_name: pageName,
  //         seconds: seconds,
  //       };
  //       if (seconds > 0) {
  //         LEADSSTORE.TrackTimeData(payload);
  //       }
  //     }
  //     reset();
  //   }
  // };

  // const handleOnAction = () => {
  //   if (!isIdleVisible) {
  //     setIsUserIdle(false);
  //   }

  //   if (getTotalActiveTime() > saveTime) {
  //     SaveTime();
  //   }
  // };

  // const handleOnIdle = (event) => {
  //   if (getTotalIdleTime() >= idleTime) {
  //     if (!isIdleVisible) {
  //       setIsUserIdle(true);
  //       let seconds = parseInt(getTotalActiveTime() / 1000);
  //       var payload = {
  //         lead_transaction_id: leadId,
  //         page_name: pageName,
  //         seconds: seconds,
  //       };
  //       if (seconds > 0) {
  //         LEADSSTORE.TrackTimeData(payload);
  //       }
  //       reset();
  //       setIsIdleVisible(true);
  //       setIsSaveIdleTime(new Date());

  //       setTimeout(() => {
  //         setIsSaveIdleTime(null);
  //       }, 150000); //2.5 Minute: If withing this time - if user click on working then add difference in investment time
  //     }
  //   }
  //   //SaveTime();
  // };

  // useEffect(() => {
  //   return () => {
  //     SaveTime();
  //   };
  // });

  // const { reset, getTotalActiveTime, getTotalIdleTime } = useIdleTimer({
  //   timeout: idleTime,
  //   onIdle: handleOnIdle,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // });

  // const confirmWorkingOption = () => {
  //   if (isSaveIdleTime) {
  //     let popupShowedMs = new Date(isSaveIdleTime);
  //     let newPopupClicked = new Date();
  //     let clickDiff = parseInt(newPopupClicked - popupShowedMs);

  //     let newInvestedTime = idleTime + clickDiff;

  //     let secondsNew = parseInt(newInvestedTime / 1000);

  //     LEADSSTORE.TrackTimeData({
  //       lead_transaction_id: leadId,
  //       page_name: pageName,
  //       seconds: secondsNew,
  //     }).then((res) => {
  //       if (deal_data?.lead_information?.investment_hours && isDealDesk) {
  //         deal_data.lead_information.investment_hours = res?.investment_hours;
  //       }
  //     });

  //     reset();
  //     setIsIdleVisible(false);
  //     setIsSaveIdleTime(null);
  //   } else {
  //     reset();
  //     setIsIdleVisible(false);
  //     setIsSaveIdleTime(null);
  //   }
  //   setCallNotesAPIAfterUserBack(true);
  // };

  return (
    // <Modal
    //   centered
    //   title={`Deal Maker`}
    //   closable={false}
    //   visible={isIdleVisible}
    //   cancelButtonProps={{ style: { display: 'none' } }}
    //   okButtonProps={{ style: { display: 'none' } }}
    //   zIndex={99999}
    //   footer={[
    //     <Button
    //       shape='round'
    //       key='2'
    //       onClick={() => confirmWorkingOption()}
    //       type='primary'
    //     >
    //       {isSaveIdleTime ? `${t('Common_Data.Working_Text')}` : `${t('Common_Data.Working_Back_Text')}`}
    //     </Button>,
    //   ]}
    // >
    //   <Row align='middle'>
    //     <Col span={4} className='DeleteIcon'>
    //       <WarningFilled />
    //     </Col>
    //     <Col span={20} className='DeleteText'>
    //       {isSaveIdleTime ? `${t('Common_Data.Still_Working_Text')}` : `${t('Common_Data.Are_you_back_Text')}`}
    //     </Col>
    //   </Row>
    // </Modal>
    <></>
  );
};

export default TrackTimeComponent;
