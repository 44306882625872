import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Form, Modal, Row, Col, Button, Upload, message } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import debounce from 'lodash/debounce';
import InputComponent from '../../../component/InputComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const EditProfileComponent = observer((props) => {
  const { resetPassModal } = props;
  const [form] = Form.useForm();
  const {
    AUTH,
    USERS: { editProfileValues, getList, updateProfileData, JsonToFormData }
  } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formconfirm, setFormconfirm] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [imgchanged, setImgchanged] = useState(false);
  const { t } = useTranslation()
  // check for valid form values then accordingly make save button disable / enable & set value of user
  const onChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // reset form and close edit form
  const close = () => {
    form.resetFields();
    props.close();
    setImgchanged(false);
  };

  // make a fuction to call to edit record
  const handleSubmit = (data) => {
    setSaving(true);
    setFormconfirm(false);

    var formData = JsonToFormData(data);
    formData.append('role', editProfileValues?.ledgers_role[0]?.role_id);
    formData.append('ledger', editProfileValues.default_ledger_id);
    if (fileList.length > 0 && fileList[0] && imgchanged) {
      formData.append(
        'profile_img',
        fileList[0].hasOwnProperty('originFileObj')
          ? fileList[0].originFileObj
          : fileList[0]
      );
    }

    updateProfileData(formData, editProfileValues.id)
      .then(() => {
        close();
        if (props.currentTab) {
          getList();
        }
        vsmNotify.success({
          message: vsmUsers.userProfileUpdate
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (editProfileValues) {
      updateFileList(
        editProfileValues.profile_img
          ? [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: `${process.env.React_APP_API_URL}${editProfileValues.profile_img}`
            }
          ]
          : []
      );

      form.setFieldsValue({
        id: editProfileValues && editProfileValues.id,
        first_name: editProfileValues.first_name && editProfileValues.first_name,
        last_name: editProfileValues.last_name && editProfileValues.last_name,
        email: editProfileValues.email && editProfileValues.email,
        ledger:
          editProfileValues.default_ledger_id && editProfileValues.default_ledger_id,
        user_added_on: editProfileValues.created_at && editProfileValues.created_at,
        phone_number: editProfileValues.phone_number && editProfileValues.phone_number,
        mobile_number: editProfileValues.mobile_number && editProfileValues.mobile_number,
        sms_number: editProfileValues.sms_number && editProfileValues.sms_number
      });
    }
  }, [editProfileValues, form, props.visible]);

  const handleReadFile = async (file) => {
    let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    let isLt1M = file.size / 1024 / 1024 < 1;
    let pixel = false;
    if (!isLt1M || !isJpgOrPng) {
      return true;
    } else if (file) {
      pixel = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function () {
            if (this.width > 501 || this.height > 501) {
              resolve(true);
            } else {
              resolve(false);
            }
          };
        };
      });
    }
    let isValid = !isLt1M || !isJpgOrPng || pixel;
    return isValid;
  };

  const eventProps = {
    onChange: ({ fileList: newFileList }) => {
      if (newFileList && newFileList.length) {
        handleReadFile(newFileList[0].originFileObj).then((pixel) => {
          if (pixel) {
            updateFileList([]);
          } else {
            updateFileList(newFileList);
          }
        });
      }
    },
    fileList,
    beforeUpload: (file) => {
      if (file) {
        return handleReadFile(file).then((pixel) => {
          if (pixel) {
            message.error(
              `${t('Users.Image_Size')} 500${t('Common_Data.Px')} x 500${t('Common_Data.Px')} ${t('Users.File_Size')} 1 ${t('Common_Data.Mb')}.`
            );
            return true;
          } else {
            setImgchanged(true);
            updateFileList([file]);
            return false;
          }
        });
      }
    }
  };

  // Handle on remove image
  const onRemoveImage = () => {
    setImgchanged(false);
    updateFileList([]);
    onChange();
  };

  // call proxylogin function
  const cancelFormsubmit = () => {
    setFormconfirm(false);
  };

  return editProfileValues ? (
    <>
      <Modal
        centered
        title={
          props.myProfile ? `${t('Users.My')} ${t('Users.Profile')}` : `${t('Common_Data.Edit')} ${t('Common_Data.User')} ${t('Users.Profile')} - #` + editProfileValues?.id
        }
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        className='myProfile'
        footer={[
          <Button
            shape='round'
            saving={saving}
            size='medium'
            //htmlType='submit'
            disabled={isDisabled}
            onClick={() => setFormconfirm(true)}
          >
            {t('Common_Data.Update')}
          </Button>,
          <Button
            key='2'
            htmlType='button'
            onClick={() => {
              close();
            }}
            shape='round'
            size='medium'
          >
            {t('Common_Data.Cancel')}
          </Button>
        ]}
        width={1100}
      >
        <Form
          form={form}
          id='editform'
          onFinish={handleSubmit}
          labelCol={{ span: 24 }}
          className='innerForm profileForm'
        >
          <Row gutter={15}>
            <Col sm={{ span: 16 }}>
              <Row gutter={15}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 6 }}
                  className='border-bottom border-right min-heigth border-left  border-top'
                >
                  <InputComponent label={`${t('Common_Data.ID')}`} type='labelOnly' />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 18 }}
                  className='border-bottom min-heigth border-top'
                >
                  <Row style={{ width: '100%' }}>
                    <Col
                      xs={{ span: 12 }}
                      className='min-heigth border-bottom border-top'
                      style={{ justifyContent: 'flex-start' }}
                    >
                      {editProfileValues.id}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={{ span: 8 }}>
              <Row gutter={15}>
                <Col
                  xs={{ span: 24 }}
                  className='min-heigth border-bottom border-top border-right'
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Button
                    key='1'
                    shape='round'
                    size='small'
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                      resetPassModal(editProfileValues);
                    }}
                  >
                    <img
                      style={{ width: '1rem', marginRight: '0.5rem' }}
                      alt=''
                      className='menuicon'
                      src={AUTH.GetThemedImage('Lock_Note')}
                    />
                    {t('Common_Data.Reset')} {t('Users.Password')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={{ span: 16 }}>
              <Row gutter={15}>
                <Col
                  span={12}
                  className='border-bottom border-right topAlignment border-left'
                >
                  <InputComponent
                    label={`${t('Common_Data.First')} ${t('Common_Data.Name')}`}
                    required
                    type='labelOnly'
                    tooltip={`${t('Users.Name_Tooltip')}.`}
                  />
                </Col>
                <Col span={12} className='border-bottom border-right topAlignment'>
                  <InputComponent
                    label={`${t('Common_Data.Last')} ${t('Common_Data.Name')}'`}
                    required
                    type='labelOnly'
                    tooltip={`${t('Users.Last_Name_Tooltip')}.`}
                  />
                </Col>
                <Col
                  span={12}
                  className='border-right border-bottom border-left'
                  style={{ padding: '0' }}
                >
                  <InputComponent
                    name='first_name'
                    required
                    placeholder={`${t('Common_Data.First')} ${t('Common_Data.Name')}`}
                    type='input'
                    tooltip={`${t('Users.Name_Tooltip')}.`}
                    onChange={onChange}
                    autoComplete='none'
                    rules={vsmUsers.validation.first_name}
                    maxLength={100}
                  />
                </Col>
                <Col
                  span={12}
                  className='border-right border-bottom '
                  style={{ padding: '0' }}
                >
                  <InputComponent
                    name='last_name'
                    required
                    placeholder={`${t('Common_Data.Last')} ${t('Common_Data.Name')}'`}
                    type='input'
                    tooltip={`${t('Users.Last_Name_Tooltip')}.`}
                    onChange={onChange}
                    autoComplete='none'
                    rules={vsmUsers.validation.last_name}
                    maxLength={100}
                  />
                </Col>
                <Col
                  span={12}
                  className='border-bottom border-right topAlignment border-left'
                >
                  <InputComponent
                    label={`${t('Common_Data.Email')}`}
                    required
                    type='labelOnly'
                    tooltip={`${t('Common_Data.Email_Tooltip')}.`}
                  />
                </Col>

                <Col span={12} className='border-bottom border-right topAlignment'>
                  <InputComponent
                    label={`${t('Common_Data.SMS')} ${t('Common_Data.Number')}`}
                    required
                    type='labelOnly'
                    tooltip={`${t('Common_Data.Email_Tooltip')}.`}
                  />
                </Col>
                <Col
                  span={12}
                  className='border-right border-bottom border-left'
                  style={{ padding: '0' }}
                >
                  <InputComponent
                    name='email'
                    required
                    placeholder={`${t('Common_Data.Email')}`}
                    type='input'
                    tooltip={`${t('Common_Data.Email_Tooltip')}.`}
                    onChange={onChange}
                    autoComplete='none'
                    rules={vsmUsers.validation.email}
                    maxLength={100}
                  />
                </Col>
                <Col
                  span={12}
                  className='border-bottom border-right'
                  style={{ padding: '0' }}
                >
                  <InputComponent
                    name='sms_number'
                    placeholder={`${t('Common_Data.SMS')} ${t('Common_Data.Number')}`}
                    type='input'
                    tooltip={`${t('Users.SMS_Tooltip')}.`}
                    onChange={onChange}
                    autoComplete='none'
                    rules={vsmUsers.validation.msgno}
                  />
                </Col>

                <Col
                  span={12}
                  className='border-bottom border-right topAlignment border-left'
                >
                  <InputComponent
                    label={`${t('Common_Data.Phone')}`}
                    required
                    type='labelOnly'
                    tooltip={`${t('Common_Data.Phone_Number_Tooltip')}.`}
                  />
                </Col>

                <Col span={12} className='border-bottom border-right topAlignment'>
                  <InputComponent
                    label={`${t('Common_Data.Mobile')}`}
                    required
                    type='labelOnly'
                    tooltip={`${t('Users.Mobile_Number_Tooltip')}.`}
                  />
                </Col>
                <Col
                  span={12}
                  className=' border-right border-bottom border-left'
                  style={{ padding: '0' }}
                >
                  <InputComponent
                    name='phone_number'
                    required
                    placeholder={`${t('Common_Data.Phone')}`}
                    type='input'
                    tooltip={`${t('Common_Data.Phone_Number_Tooltip')}.`}
                    autoComplete='none'
                    onChange={onChange}
                    rules={vsmUsers.validation.phone}
                  />
                </Col>
                <Col
                  span={12}
                  className='border-right border-bottom'
                  style={{ padding: '0' }}
                >
                  <InputComponent
                    name='mobile_number'
                    placeholder={`${t('Common_Data.Mobile')}`}
                    type='input'
                    tooltip={`${t('Users.Mobile_Number_Tooltip')}.`}
                    onChange={onChange}
                    autoComplete='none'
                    rules={vsmUsers.validation.mobile}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={{ span: 8 }} className='border-right'>
              <Upload
                accept='.png, .jpeg'
                name='profile_img'
                fileList={fileList}
                onRemove={onRemoveImage}
                className='hideeyeicon'
                listType='picture-card'
                tooltip={`${t('Users.Upload_Photo')}`}
                multiple={false}
                showUploadList={true}
                rules={vsmUsers.validation.photo}
                {...eventProps}
              >
                {fileList.length >= 1 ? null : `${t('Common_Data.Upload')} ${t('Users.Profile')} ${t('Users.Image')}`}
              </Upload>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 4 }}
              className='border-bottom border-right min-heigth border-left'
            >
              <InputComponent label={`${t('Common_Data.User')} ${t('Common_Data.Group')}`} type='labelOnly' />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 8 }}
              className='border-bottom  min-heigth'
              style={{ backgroundColor: '#ffffff' }}
            >
              {editProfileValues?.user_group?.map((item, index) => {
                return (
                  <div class='ant-form-item-label' key={index}>
                    {item.group_name}
                    {editProfileValues?.user_group?.length - 1 !== index && ','}&nbsp;
                  </div>
                );
              })}
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 4 }}
              className='border-bottom border-right min-heigth border-left'
            >
              <InputComponent label={`${t('Common_Data.User')} ${t('Common_Data.Role')}`} type='labelOnly' />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 8 }}
              className='border-bottom border-top min-heigth border-right'
              style={{ backgroundColor: '#ffffff' }}
            >
              {editProfileValues?.ledgers_role?.map((item, index) => {
                return <div class='ant-form-item-label' key={index}>{item.role_name}</div>;
              })}
            </Col>
            {/* <Col
              xs={{ span: 24 }}
              md={{ span: 4 }}
              className='border-bottom border-right min-heigth border-left'
            >
              <div class='ant-form-item-label'>
                <label title='Ledger'>
                  <strong>Ledger</strong>
                </label>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 8 }}
              className='border-bottom  min-heigth'
              style={{ backgroundColor: '#ffffff' }}
            >
              <div class='ant-form-item-label'>Ledger-1</div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 4 }}
              className='border-bottom border-right min-heigth border-left'
            >
              <div class='ant-form-item-label'>
                <label title='User Added On'>
                  <strong>User Added On</strong>
                </label>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 8 }}
              className='border-bottom  min-heigth border-right'
              style={{ backgroundColor: '#ffffff' }}
            >
              <div class='ant-form-item-label'>2021-02-14 12:07:58</div>
            </Col> */}
          </Row>
        </Form>
      </Modal>

      <Modal
        centered
        title={`${t('Common_Data.Update')} ${t('Users.Profile')}`}
        visible={formconfirm}
        onCancel={cancelFormsubmit}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            //disabled={disabled}
            form='editform'
            //loading={saving}
            htmlType='submit'
            type='primary'
          >
            {t('Common_Data.Yes')}
          </Button>,
          <Button key='2' htmlType='button' onClick={cancelFormsubmit}>
            {t('Common_Data.Cancel')}
          </Button>
        ]}
      >
        <p>{t('Users.Update_Profile_Details')}?</p>
      </Modal>
      {props.visible && <TimeTracker
        page={"users"}
        fromSideBar={props?.fromSideBar}
      />}
    </>
  ) : null;
});

export default EditProfileComponent;
