import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class OrgStatusStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;

  editValues = null;
  deleteValues = null;
  agGrid = null;

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (
      this.agGrid &&
      this.agGrid.api.rowModel.rowsToDisplay.length === 0
    ) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // call api to get organisations roles list
  getOrgStatus = () => {
    return Axios.get(`organisations/status/get`).then(({ data }) => {
      this.orgStatusList = data.data;
      return data;
    });
  };

  // call api to get records
  getList = () => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }
    this.list_data = null;
    return Axios.get(`organisations/status/list`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.updated_by = item.users
            ? item.users.first_name + ' ' + item.users.last_name
            : null;
          item.srno = index + 1;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      var allColumnIds = [];
      if (this.agGrid && this.agGrid.columnApi && data.total) {
        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
          if (!['updated_at'].includes(column.colId)) {
            allColumnIds.push(column.colId);
          }
        });
        //this.agGrid.columnApi.autoSizeColumns(allColumnIds)
      }
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.columnApi.applyColumnState({ state: sort });
      }
      return data;
    });
  };

  // Call add api
  AddData = (formdata) => {
    return Axios.post(`organisations/status/add`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`organisations/status/edit/${formdata.id}`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.get(`organisations/status/delete/${formdata.id}`)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
}

decorate(OrgStatusStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  editValues: observable,
  orgStatusList: observable,
  deleteValues: observable,
  agGrid: observable,
  onFilterChanged: action,
  setPageSize: action,
  setupGrid: action,
  getList: action,
  EditData: action,
  setEditValues: action,
  DeleteData: action,
  setDeleteValues: action,
  getOrgStatus: action,
});
