import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, PageHeader, Button, Typography, Tooltip } from 'antd';
import useStore from '../../store';
import ListComponent from './component/ListComponent';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import ViewComponent from './component/ViewComponent';
import DeleteComponent from './component/DeleteComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../Unauthorized';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import { useTranslation } from 'react-i18next';

const Knowledge = observer((props) => {
  const { t } = useTranslation();
  const { KNOWLEDGE, AUTH, SETTINGS } = useStore();
  const [addDrawer, setAddDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const { SearchPage, AllView, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;

  //open add drawer
  const openAddDrawer = () => {
    KNOWLEDGE.knowledgeValues = null;
    setAddDrawer(true);
  };

  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  //open edit drawer
  const openEditDrawer = (data) => {
    var payload = { knowledgeid: data.id };
    KNOWLEDGE.getRecordDetail(payload);
    setEditDrawer(true);
  };

  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);

  const openViewDrawer = (data) => {
    var payload = { knowledgeid: data.id };
    KNOWLEDGE.getRecordDetail(payload);
    setViewDrawer(true);
  };
  const closeViewDrawer = () => {
    setViewDrawer(false);
    KNOWLEDGE.setKnowledgeValues(null);
  };

  //open edit drawer
  const openDeleteDrawer = (data) => {
    KNOWLEDGE.setDeleteValues(data);
    setDeleteModal(true);
  };

  //close edit drawer
  const closeDeleteDrawer = () => setDeleteModal(false);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (result.key === 'Enter' && AUTH.checkPermission(20, 'edit')) {
            openEditDrawer(e.data);
          }
        }
      }
    }
  };

  return (
    <>
      {!AUTH.checkPermission(20, 'list') ? (
        <Unauthorized />
      ) :
        // SearchPage ? (
        //   <>
        //     <ListComponent
        //       openAddDrawer={openAddDrawer}
        //       openEditDrawer={openEditDrawer}
        //       openViewDrawer={openViewDrawer}
        //       openDeleteDrawer={openDeleteDrawer}
        //       onCellKeyDown={onCellKeyDown}
        //       SearchPage={SearchPage}
        //     />
        //     <AddComponent visible={addDrawer} close={closeAddDrawer} />
        //     <EditComponent visible={editDrawer} close={closeEditDrawer} />
        //     <ViewComponent visible={viewDrawer} close={closeViewDrawer} />
        //     <DeleteComponent visible={deleteModal} close={closeDeleteDrawer} />
        //   </>
        // ) : 
        (
          <>
            {props.isSidebarDrawer ? (
              <Drawer
                key='3'
                visible={props.visible}
                onClose={() => props.close()}
                placement='right'
                width={'1100px'}
                title={`${t('DealDesk.Knowledge')} ${t('DealDesk.Library')}`}
                destroyOnClose={true}
                footer={[
                  <div className='text-right' key='1'>
                    <Button
                      onClick={() => props.close()}
                      shape='round'
                      size='medium'
                      key='2'
                    >
                      {t('Common_Data.Close')}
                    </Button>
                  </div>,
                ]}
              >
                <ListComponent
                  openAddDrawer={openAddDrawer}
                  openEditDrawer={openEditDrawer}
                  openViewDrawer={openViewDrawer}
                  openDeleteDrawer={openDeleteDrawer}
                  onCellKeyDown={onCellKeyDown}
                />
              </Drawer>
            ) : (
              <PageHeader
                title={
                  !SearchPage ? `${t('DealDesk.Knowledge_Library')}`
                    : (<>
                      <div className='d-flex justify-content-start align-items-center notes'>
                        <Title level={3}>
                          {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                        </Title>
                        <div className='searchView ml-10 '>
                          <span className='sep'>|</span>
                          {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                          {t('Common_Data.Records_found')}
                          <span className='sep'>|</span>
                          <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                            {t('Common_Data.View_all')} {sectorSelector}
                          </span>
                        </div>
                      </div>
                    </>)
                }
                className={SearchPage ? 'SearchResult-PageHeader ' + SETTINGS.fullscreen_class : 'page-title ' + SETTINGS.fullscreen_class}
                extra={[
                  AUTH.checkPermission(20, 'add') && (
                    !SearchPage ? <Button
                      className='gridbtn'
                      key='1'
                      shape='round'
                      size='medium'
                      onClick={openAddDrawer}
                    >
                      {t('Common_Data.Add_Knowledge')}
                    </Button>
                      : null
                  ),
                  <Button
                    key='2'
                    title={`${t('Common_Data.Reset')}`}
                    shape='round'
                    size='medium'
                    onClick={KNOWLEDGE.handleReset}
                  >
                    <FontAwesomeIcon icon={faUndo} />
                  </Button>,
                  <EnterFullscreen key='3' />,
                  // <RecordPerPage
                  //   key='6'
                  //   initialValue={TicketsStore.per_page + ' per page'}
                  //   onChange={TicketsStore.setPageSize}
                  // />
                ]}
              >
                <ListComponent
                  openAddDrawer={openAddDrawer}
                  openEditDrawer={openEditDrawer}
                  openViewDrawer={openViewDrawer}
                  openDeleteDrawer={openDeleteDrawer}
                  onCellKeyDown={onCellKeyDown}
                  SearchPage={SearchPage}
                  AllView={AllView}
                />
                {/* <TrackTimeComponent pageName={'knowledge'} /> */}
                <TimeTracker />
              </PageHeader>
            )}

            <AddComponent visible={addDrawer} close={closeAddDrawer} />
            <EditComponent visible={editDrawer} close={closeEditDrawer} />
            <ViewComponent visible={viewDrawer} close={closeViewDrawer} />
            <DeleteComponent visible={deleteModal} close={closeDeleteDrawer} />
            {props?.fromSideBar && <TimeTracker
              page={"knowledge"}
              fromSideBar={props?.fromSideBar}
            />}
          </>
        )}
    </>
  );
});

export default Knowledge;
