import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../../store';
//import FormComponent from './FormComponent';
import FormComponentNew from './FormComponentNew';
import { default as AddOrgComponent } from '../../../OrganisationManagement/AllOrganisations/component/AddComponentNew';
import { default as EditOrgComponent } from '../../../OrganisationManagement/AllOrganisations/component/EditComponentNew';

import { vsmNotify, vsmPeople } from '../../../../config/messages';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../../component/TimeTracker';

const AddComponentNew = observer((props) => {
  const { t } = useTranslation();
  const { isLead = false, isChildDrawer = false, isFromOrg = false } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const { PEOPLE, AUTH, ORGANISATION, TimeTrackerStore } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [addOrgModal, setAddOrgModal] = useState(false);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null); //Used only for ORG edit.

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    if (data.phone && data.phone.length > 0) {
      data.phone = data.phone.filter((x) => x && x.contact_for && x.contact_value);
      data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null;
    }
    //if (addAddress) {
    if (data.address && data.address.length > 0) {
      data.address = data.address.filter((x) => x && x && x.address_type);
      data.address = data.address.length > 0 ? JSON.stringify(data.address) : null;
    } else {
      delete data.address; // = ;//null;
    }
    // } else {
    //   data.address = null;
    // }

    if (data.email && data.email.length > 0) {
      data.email = data.email.filter((x) => x && x.contact_for && x.contact_value);
      data.email = data.email.length > 0 ? JSON.stringify(data.email) : null;
    }
    setSaving(true);
    var formData = PEOPLE.JsonToFormData(data);
    //formData.append('add_address', addAddress === true ? 1 : 0);
    formData.append('add_address', data.address && data.address.length > 0 ? 1 : 0);
    if (
      data.photo_url &&
      data.photo_url.fileList[0] &&
      data.photo_url.fileList.length > 0
    ) {
      formData.append('photo_url', data.photo_url.fileList[0].originFileObj);
    } else {
      formData.append('photo_url', '');
    }
    formData.append(
      'date_of_birth',
      data.date_of_birth ? moment(data.date_of_birth).format('Y-MM-DD') : ''
    );
    let formPayloadObject = data;

    PEOPLE.AddData(formData)
      .then((data) => {
        TimeTrackerStore.setLeadTransactionId(data.leads_transactions_id)
        let redirectToDesk = true;
        if (props?.setPeopleDropDown) {
          redirectToDesk = false;
          props?.setPeopleDropDown(data?.id, formPayloadObject?.industry_id);
        }
        close();
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmPeople.add,
        });

        if (!isLead && redirectToDesk) {
          history.push(`/people/${data.leads_transactions_id}`);
        }
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        let checkbox = form.getFieldValue('add_address');
        let address = form.getFieldValue('address');
        address = address && address.filter((x) => x && x);
        if (checkbox && (!address || (address && address.length === 0))) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // Open form for add new Organisation
  const openAddOrgModal = () => {
    setAddOrgModal(true);
  };
  // Close form for close new Organisation
  const closeAddOrgModal = () => setAddOrgModal(false);

  const setOrgDropDown = (id) => {
    //getMappedOrg();
    form.setFieldsValue({ organisation_name: id });
    ORGANISATION.getAllROrg({
      selected_id: [form.getFieldValue('organisation_name')],
      mapping_type: 1,
    });
    handleChange();
  };

  const setId = (id) => setTransactionId(id);
  const openEditOrgDrawer = () => {
    var payload = { organisation_id: form.getFieldValue('organisation_name') };
    ORGANISATION.setOrganisationValues(null);
    ORGANISATION.getRecordDetail(payload).then((data) => {
      setId(payload.organisation_id);
      ORGANISATION.setEditValues(data);
      setEditOrgModal(true);
    });
  };

  // Close form of edit Organisation
  const closeEditOrgDrawer = () => {
    //getMappedOrg();
    form.setFieldsValue({ organisation_name: form.getFieldValue('organisation_name') });

    ORGANISATION.getAllROrg({
      selected_id: [form.getFieldValue('organisation_name')],
      mapping_type: 1,
    });

    // ORGANISATION.organisationValues = null;
    // ORGANISATION.setEditValues(null);
    setEditOrgModal(false);
    handleChange();
  };
  return (
    <Drawer
      key='4'
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      title={`${t('Common_Data.Add')} ${t('Common_Data.People')}`}
      zIndex={isChildDrawer ? 1005 : 1000}
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          <Button
            key='1'
            form='addfrom'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
            shape='round'
            size='medium'
          >
            {t('Common_Data.Save')}
          </Button>
          <Button shape='round' size='medium' onClick={close} key='2'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      {/* <FormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        addAddress={addAddress}
        setAddAddress={setAddAddress}
        updateFileList={updateFileList}
        fileList={fileList}
        id='addfrom'
      /> */}
      <FormComponentNew
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        addAddress={addAddress}
        isFromOrg={isFromOrg}
        setAddAddress={setAddAddress}
        updateFileList={updateFileList}
        fileList={fileList}
        id='addfrom'
        openAddOrgModal={openAddOrgModal}
        openEditOrgDrawer={openEditOrgDrawer}
      />
      <AddOrgComponent
        visible={addOrgModal}
        close={closeAddOrgModal}
        isChildDrawer={true}
        isFromPeople={true}
        setOrgDropDown={setOrgDropDown}
      />
      <EditOrgComponent
        transactionId={transactionId}
        setId={setId}
        visible={editOrgModal}
        close={closeEditOrgDrawer}
        setOrgDropDown={setOrgDropDown}
      />
      {props.visible && <TimeTracker
        page={"people"}
        stop={!props.visible || addOrgModal || editOrgModal}
        fromSideBar={props?.fromSideBar}
      />}
    </Drawer>
  );
});

export default AddComponentNew;
