import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../../component/InputComponent';
import { vsmDealDesk, vsmLeadsList } from '../../../../../config/messages';
import { Col, Form, Row } from 'antd';
import StructureComponent from './StructureComponent';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';
import SaveModal from './SaveModal';

const FormComponent = observer(
  ({ handleSubmit, onChange, form, id, saveType, autoSaveData,
    openViewDrawer, isSaveModal, loading, isTemplate, setIsTemplate, setIsSaveModal }) => {
    const INITIAL_HEIGHT = '18px';
    const {
      DEALDESKSTRUCTURELIBRARY: {
        Structure,
        SetStructure,
        Screen,
        SetScreen,
        Scrunitise,
        SetScrunitise,
        setSavestructureform,
        Shortfall,
        SetShortfall,
      },
      LEADSSTORE,
      DEALDESKSTRUCTURELIBRARY,
    } = useStore();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const { t } = useTranslation()
    useEffect(() => {
      setSavestructureform(form);
    }, [form, setSavestructureform]);

    const checkSaveType = () => {
      if (saveType === 'screen') {
        return Screen;
      }
      if (saveType === 'structure') {
        return Structure;
      }
      if (saveType === 'scrutinise') {
        return Scrunitise;
      }
      if (saveType === 'shortfall') {
        return Shortfall;
      }
    }
    const getTitleBasedOnSaveType = () => {
      if (saveType === 'screen') {
        return `${t('DealDesk.Screen')}`;
      } else if (saveType === 'structure') {
        return `${t('Common_Data.Structure')}`;
      } else if (saveType === 'scrutinise') {
        return `${t('Common_Data.Scrutinise')}`;
      } else if (saveType === 'shortfall') {
        return `${t('Common_Data.Shortfall')}`;
      }
    }

    const setDataBasedOnSaveType = () => {
      if (saveType === 'screen') {
        return SetScreen;
      } else if (saveType === 'structure') {
        return SetStructure;
      } else if (saveType === 'scrutinise') {
        return SetScrunitise;
      } else if (saveType === 'shortfall') {
        return SetShortfall;
      }
    }

    const validationBasedOnSaveType = () => {
      if (saveType === 'screen') {
        return vsmDealDesk.structure.validation.screen_title;
      }
      else if (saveType === 'structure') {
        return vsmDealDesk.structure.validation.structure_title;
      }
      else if (saveType === 'scrutinise') {
        return vsmDealDesk.structure.validation.scrutinise_title;
      }
      else if (saveType === 'shortfall') {
        return vsmDealDesk.structure.validation.scrutinise_title;
      }
    }
    return (
      <div className='structureRight'>
        <Form
          form={form}
          layout='vertical'
          id={id}
          onFinish={handleSubmit}
          className='innerForm'
        >
          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                required
                className='note'
                type='textarea'
                autoSize={{ minRows: 1, maxRows: 1 }}
                label={`${getTitleBasedOnSaveType()} ${t('Common_Data.Title')}`}
                name='title'
                placeholder={`${t('Common_Data.Enter')} ${getTitleBasedOnSaveType()
                  } ${t('Common_Data.Title')} ${t('Common_Data.Here')}...`}
                onChange={onChange}
                maxLength={100}
                rules={
                  validationBasedOnSaveType()
                }
              />
            </Col>
            <Col span={12}>
              <InputComponent
                name='classification_id'
                placeholder={`${t('Common_Data.Select_Credebt_Classification')}`}
                onFocus={() =>
                  !LEADSSTORE.classification_role_list &&
                  LEADSSTORE.getClassificationList()
                }
                label={<span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>}
                required
                className='note'
                autoSize={{ minRows: 1, maxRows: 1 }}
                maxLength={50}
                type='select'
                allowClear
                showArrow
                showSearch
                onChange={onChange}
                options={{
                  values:
                    LEADSSTORE.classification_role_list ||
                    (DEALDESKSTRUCTURELIBRARY.editValues &&
                      DEALDESKSTRUCTURELIBRARY.editValues.classification_name && [
                        DEALDESKSTRUCTURELIBRARY.editValues.classification_name,
                      ]),
                  value_key: 'id',
                  text_key: 'name',
                }}
                notFoundContent={`${t('Common_Data.No_Record_Found')}.`}
                tooltip={`${t('LeadListing.Classification_Group_Tooltip')}.`}
                rules={vsmLeadsList.validation.classification_id}
              />
            </Col>
          </Row>

          <InputComponent
            required
            className='note'
            type='textarea'
            label={`${t('Common_Data.Description')}`}
            name='description_text'
            placeholder={`${t('Common_Data.Description')}`}
            onChange={onChange}
            maxLength={500}
            rules={[
              { required: true, message: `${t('DealDesk.Not_Blank')}` },
              {
                max: 500,
                message: `${t('DealDesk.Description_Contain_Maximum')} 500 ${t('DealDesk.Characters')}.`,
              },
            ]}
          />

          <StructureComponent
            MainStructure={checkSaveType()}
            Structure={checkSaveType()}
            SetStructure={setDataBasedOnSaveType()}
            forceUpdate={forceUpdate}
            editable={true}
            saveType={saveType}
            handleFormChange={onChange}
            isFromLibrary={true}
            INITIAL_HEIGHT={INITIAL_HEIGHT}
            autoSaveData={autoSaveData}
            openViewDrawer={openViewDrawer}
          />
          <SaveModal
            id={id}
            visible={isSaveModal}
            saveType={saveType}
            handleSubmit={handleSubmit}
            loading={loading}
            isTemplate={isTemplate}
            setIsTemplate={setIsTemplate}
            close={setIsSaveModal}
          />
        </Form>
      </div>
    );
  }
);

export default FormComponent;
