import React, { useState } from 'react';
import { Col, Spin } from 'antd';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const NaceCategoryElement = observer(({ handleChange, formDataList, form }) => {
  const [fetctNace, setFetchNace] = useState(true);
  const [categoryId, setCategoryId] = useState(null);
  const [fetchStatusPeople, setFetchStatusPeople] = useState(true);

  const { t } = useTranslation();

  const { ORGANISATION, PEOPLE } = useStore();

  const handleNaceChange = (e = null) => {
    setCategoryId(e);
    form.resetFields(['nace_code']);
    handleChange();
  };

  return (
    <>
      {formDataList.includes('nace_category') && (
        <Col span={8}>
          <InputComponent
            allowClear
            showSearch
            showArrow
            type='select'
            label={
              <span
                className='converbillLink'
                onClick={() => {
                  window.open(
                    'https://www.convertibill.com/nacecategories-popup.html',
                    '_blank',
                    'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                  );
                }}
              >
                {t('Reports.Org_NACE_Category')}
              </span>
            }
            name='nace_category'
            onChange={handleNaceChange}
            tooltip={t('Reports.Org_NACE_Tooltip')}
            onFocus={() =>
              fetctNace && ORGANISATION.getNaceCode().then(() => setFetchNace(false))
            }
            notFoundContent={fetctNace ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values: ORGANISATION?.naceCode_list,
              value_key: 'id',
              text_key: 'category_name',
            }}
          />
        </Col>
      )}
      {formDataList.includes('nace_code') && (
        <Col span={8}>
          <InputComponent
            allowClear
            showSearch
            showArrow
            type='select'
            label={`${t('Reports.NACE_Code')}`}
            name='nace_code'
            placeholder={`${t('Reports.Select_NACE_Code')}`}
            onChange={handleChange}
            disabled={!categoryId || !form.getFieldValue('nace_category')}
            tooltip={`${t('Reports.NACE_Tooltip')}.`}
            onFocus={() =>
              fetctNace && ORGANISATION.getNaceCode().then(() => setFetchNace(false))
            }
            notFoundContent={fetctNace ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values:
                //categoryId
                form.getFieldValue('nace_category') &&
                ORGANISATION?.naceCode_list?.find(
                  (item) => item.id === form.getFieldValue('nace_category')
                )?.nacesection,
              value_key: 'id',
              text_key: { key: ['code', ' - ', 'section'] },
            }}
          />
        </Col>
      )}
      {formDataList.includes('people_status') && (
        <Col span={8}>
          <InputComponent
            allowClear
            showSearch
            showArrow
            type='select'
            label={`${t('Reports.People_Status')}`}
            name='people_status'
            placeholder={`${t('Reports.People_Status')}`}
            onChange={handleChange}
            mode={'multiple'}
            tooltip={`${t('Reports.People_Status_Tooltip')}`}
            onFocus={() =>
              fetchStatusPeople &&
              PEOPLE.getPeopleStatus().then(() => setFetchStatusPeople(false))
            }
            notFoundContent={
              fetchStatusPeople ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            options={{
              values: PEOPLE.peopleStatusList,
              value_key: 'id',
              text_key: 'status_name',
            }}
          />
        </Col>
      )}
    </>
  );
});

export default NaceCategoryElement;
