import { observer } from 'mobx-react';
import React from 'react';
import TicketListComponent from '../../../page/Tickets/component/ListClientSideComponent';
import TagListComponent from '../../../page/Tags/component/ListClientSideComponent';
import useStore from '../../../store';
import { vsmNotify, vsmRightSectTagsTicket } from '../../../config/messages';

const ListTagTicketComponent = observer(({ ...rest }) => {
  const {
    DEALDESKSTORE: { deal_data },
    RIGHTSECTTION_TAGSTICKETS: { updateDealTicket, fetchTagsTicketData },
    TicketsStore,
  } = useStore();

  const toggleDealTicket = (id, type) => {
    if (deal_data?.lead_information?.id) {
      let payload = {
        action: type,
      };
      updateDealTicket(deal_data.lead_information.id, id, payload).then((data) => {
        vsmNotify.success({
          message:
            type === 'add' ? vsmRightSectTagsTicket.add : vsmRightSectTagsTicket.remove,
        });

        TicketsStore.getList({
          leads_transactions_id: deal_data?.lead_information?.id,
          organisation_id: deal_data?.lead_information?.sold_organisation?.id,
        });
        fetchTagsTicketData(deal_data?.lead_information?.id);
      });
    }
  };

  return (
    <>
      {rest.currentTab === 'tags' && (
        <TagListComponent
          {...rest}
          TagTicketModule={true}
          openViewDrawer={rest.openTagViewDrawer}
        />
      )}
      {rest.currentTab === 'tickets' && (
        <TicketListComponent
          {...rest}
          toggleDealTicket={toggleDealTicket}
          TagTicketModule={true}
          openViewDrawer={rest.openTicketViewDrawer}
        />
      )}
    </>
  );
});

export default ListTagTicketComponent;
