import React, { Component, useEffect, useState } from 'react';
import { Form, Input, Tooltip, Divider, Drawer } from 'antd';
import { observer } from 'mobx-react';
import { InfoCircleOutlined } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmNotify, vsmAlgorithm } from '../../../../config/messages';
import AlgorithmBuilder from '../AlgorithmBuilder';
import { DragDropContext } from 'react-beautiful-dnd';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const ViewComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { AlgorithmsStore } = useStore();
  const [viewValues, setViewValues] = useState(null);

  // set data on refresh page
  useEffect(() => {
    if (props.visible) {
      AlgorithmsStore.getAllTransactionFields();
      AlgorithmsStore.getRecordById({ id: props.id })
        .then((data) => {
          setViewValues(data.data);
        })
        .catch(() => {
          vsmNotify.error({
            message: vsmAlgorithm.InvalidRecord,
          });
          props.close();
        });
    }
  }, [props, AlgorithmsStore]);

  class ViewComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        formula: {},
        formula_status: 'error',
        saving: false,
        disabled: false,
        fields: [],
        field_list: this.props.field_list,
        readable_format: null,
      };
    }

    componentDidMount() {
      if (this.props.viewValues) {
        form.setFieldsValue({
          id: this.props.viewValues.id,
          algorithm_name: this.props.viewValues.algorithm_name,
          algorithm_desc: this.props.viewValues.algorithm_desc,
        });
        this.setFormula(JSON.parse(this.props.viewValues.algorithm_json || '{}'));
        this.setState({
          readable_format: this.props.viewValues.readable_format,
        });
      }

      window.onmousemove = logMouseMove;

      function logMouseMove(event) {
        let e = event || window.event;
        window.x = e.clientX;
        window.y = e.clientY;
      }
      var temp_interval = setInterval(function () {
        if ($('.sticky_fields').length > 0) {
          clearInterval(temp_interval);
          var stickyTop = $('.sticky_fields').offset().top;

          $(window).scroll(function () {
            var windowTop = $(window).scrollTop();

            if (stickyTop < windowTop) {
              $('.sticky_fields').css('top', windowTop - stickyTop + 'px');
            } else {
              $('.sticky_fields').css('top', '0');
            }
          });
        }
      }, 200);
    }

    // reset form and close view form
    close = () => {
      this.props.setViewValues(null);
      this.props.close();
    };

    setFormula = (formula) => {
      var readable_format = this.props.AlgoStore.createReadableFormula(formula);
      this.setState({
        formula,
        fields: readable_format.fields,
      });
    };

    render() {
      return this.props.viewValues ? (
        <>
          <Drawer
            title={`${t('DealBuilder.View_Deal_Calculation')} - #${this.props?.viewValues?.id}`}
            visible={this.props.visible}
            onClose={this.close}
            placement='right'
            width={'75%'}
            destroyOnClose={true}
          // footer={
          //   [
          // <div className='text-center'>
          //   <Button onClick={this.close}>Cancel</Button>
          // </div>
          //   ]
          // }
          >
            <Form
              form={this.props.form}
              //labelCol={{ span: 5 }}
              className='builder_wrapper'
              layout='vertical'
            >
              <DragDropContext>
                <Form.Item
                  name='algorithm_name'
                  rules={vsmAlgorithm.validation.algorithm_name}
                  label={`${t('DealBuilder.Algorithm_Name')}`}
                  required
                  tooltip={`${t('DealBuilder.Algorithm_Name_Tooltip')}`}
                >
                  <Input disabled placeholder={`${t('DealBuilder.Algorithm_Name')}`} maxLength={100} />
                </Form.Item>

                <Form.Item
                  name='algorithm_desc'
                  rules={vsmAlgorithm.validation.algorithm_desc}
                  label={`${t('DealBuilder.Algorithm_Description')}`}
                  required
                  tooltip={`${t('DealBuilder.Algorithm_Description_Tooltip')}`}
                >
                  <Input.TextArea
                    disabled
                    placeholder={`${t('DealBuilder.Algorithm_Description')}`}
                    maxLength={1000}
                  />
                </Form.Item>
                <h3>
                  <span>{t('DealBuilder.Algorithm_Builder')}</span>
                  <small>
                    <Tooltip title={`${t('DealBuilder.Algorithm_Builder_Tooltip')}`}>
                      <InfoCircleOutlined size={10} className='ml-10' />
                    </Tooltip>
                  </small>
                </h3>
                <div className='view_wrapper valid_always'>
                  <AlgorithmBuilder
                    main={this.state.formula}
                    formula={this.state.formula}
                    setFormula={this.setFormula}
                  />
                </div>
                <div className='mt-10'>
                  <h4>
                    <span>{t('DealBuilder.Algorithm_Parsed')}</span>
                    <Tooltip title={`${t('DealBuilder.Algorithm_Parsed_Tooltip')}`}>
                      <InfoCircleOutlined size={10} className='ml-10' />
                    </Tooltip>
                  </h4>
                  <div
                    className={
                      this.props.viewValues.last_parse_result === 'Success'
                        ? 'text-success parsed_wrapper'
                        : 'text-danger parsed_wrapper'
                    }
                  >
                    {this.props.viewValues.last_parse_result === 'Success'
                      ? `${t('DealBuilder.Parse_Success')}`
                      : `${t('DealBuilder.Parse_Error')}`}
                  </div>
                </div>
                <div className='mt-10'>
                  <h4>
                    <span>{t('DealBuilder.Readable_Format')}</span>
                    <Tooltip
                      placement='topRight'
                      title={`${t('DealBuilder.Readable_Format_Tooltip')}`}
                    >
                      <InfoCircleOutlined className='ml-10' />
                    </Tooltip>
                  </h4>
                  <div
                    className={
                      this.props.viewValues.last_parse_result === 'Success'
                        ? 'text-success'
                        : 'text-danger parsed_wrapper'
                    }
                  >
                    {this.state.readable_format
                      ? this.state.readable_format
                      : `${t('DealBuilder.ReadableFormatPlaceholder')}`}
                  </div>
                </div>
              </DragDropContext>

              <Divider />
            </Form>
            <div className='formulize-field-wrapper' style={{ display: 'none' }}>
              {this.state.field_list && this.state.field_list.length > 0 ? (
                this.state.field_list.map((item, index) => (
                  <div
                    key={index}
                    className={'formulize-custom draggable_' + item.id}
                    data-value={JSON.stringify({ item: item.id })}
                  >
                    {item.field_name}
                  </div>
                ))
              ) : (
                <div className='text-center'>{t('Common_Data.No_Record_Found')}.</div>
              )}
            </div>
          </Drawer>
        </>
      ) : null;
    }
  }

  return (
    <ViewComponent
      form={form}
      field_list={AlgorithmsStore.transaction_field_list}
      viewValues={viewValues}
      AlgoStore={AlgorithmsStore}
      {...props}
      setViewValues={setViewValues}
    />
  );
});

export default ViewComponent;
