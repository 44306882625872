import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { vsmCallAudit, vsmNotify } from '../../../config/messages';

const EmailCellRender = observer((props) => {
  const { LEADSSTORE } = useStore();

  const handleCallAudit = (data) => {
    let formdata = {
      id: props?.data?.data?.id,
      email: data,
    };
    LEADSSTORE.callEmailAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.emailadded,
      });
    });
  };

  return (
    <div className='action-column TelephoneRenderer'>
      <a
        href={`mailto:${props?.data?.value}`}
        onClick={() => handleCallAudit(props?.data?.value)}
      >
        {props?.data?.value}
      </a>
    </div>
  );
});
export default EmailCellRender;
