import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';

import { AllModules } from '@ag-grid-enterprise/all-modules';

import LocalGridConfig from '../../../../config/LocalGridConfig';

import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import { PageHeader } from 'antd';
import {
  DateComparator,
  secondsToHmsWithAppend,
  trackTimeSpentPageNameList,
} from '../../../../utils/GlobalFunction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const UserInvestmentOthers = observer((props) => {
  const {
    AUTH,
    SavedReportStore,
    SavedReportStore: { investmentHoursDetailDataByuser },
  } = useStore();

  const { t } = useTranslation();

  const gridOptions = {
    columnDefs: [
      {
        headerName: 'SR#',
        headerTooltip: 'SR#',
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',
        cellClass: 'cellClass',
        pinned: 'left',
        cellStyle: { 'text-align': 'center' },
        filter: false,
        sortable: false,
        width: 85,
        minWidth: 85,
      },

      {
        headerName: `# ${t('Common_Data.Page')}`,
        field: 'page_name',
        width: 500,
        minWidth: 500,
        cellRenderer: (params) => {
          return params.data?.page_name
            ? trackTimeSpentPageNameList[params.data?.page_name]
            : '';
        },
      },

      {
        headerName: 'Date',
        field: 'datetime',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.datetime
            ? moment(params.data.datetime).format(AUTH.global_dateformat)
            : '';
        },
        width: 200,
        minWidth: 200,
      },
      {
        headerName: 'Time Spent',
        field: 'time_spent',
        width: 80,
        minWidth: 80,
      },
    ],
  };

  let PinnedData = [];
  let soldObj = {
    sr: 'Total',
    id: null,
    page_name: null,
    sold_name: null,
    datetime: null,
    time_spent: investmentHoursDetailDataByuser
      ? secondsToHmsWithAppend(investmentHoursDetailDataByuser?.others_total)
      : 0,
  };
  PinnedData.push(soldObj);

  return (
    <PageHeader
      title={`Other Pages Breakdown (${investmentHoursDetailDataByuser?.others &&
        Array.isArray(investmentHoursDetailDataByuser?.others)
        ? investmentHoursDetailDataByuser?.others.length.toLocaleString('en-GB')
        : 0
        }  records)`}
    >
      <div
        className='ag-theme-alpine grid_wrapper reportPreviewData'
        style={{
          height: `calc(100vh - 15rem)`,
          minHeight: '200px',
        }}
      >
        <AgGridReact
          rowData={
            investmentHoursDetailDataByuser && investmentHoursDetailDataByuser?.others
              ? investmentHoursDetailDataByuser.others
              : []
          }
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            flex: 1,
          }}
          pinnedBottomRowData={PinnedData}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{}}
          onGridReady={SavedReportStore.setupTimespentTypeOthers}
          gridOptions={LocalGridConfig.options}
          suppressRowClickSelection={true}
          rowSelection={'multiple'}
          onSortChanged={SavedReportStore.drilldownRedrawGrid}
        />
      </div>
    </PageHeader>
  );
});

export default UserInvestmentOthers;
