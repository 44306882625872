import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Col, Divider, Form, Row } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';
import { historyCurrentValues, summaryType } from '../../../../utils/GlobalFunction';

const CommonSummaryElement = observer(
  ({ onChange, checkAll, indeterminate, handleCheckAllCheckbox, handleTicketPriorityCheckbox, formDataList, form }) => {
    const { t } = useTranslation();

    const [disabledStage, setDisabledStage] = useState(false);
    const [disabledCountry, setDisabledCountry] = useState(false);

    return (
      <>
        <Row gutter={12} className='mb-20' align='middle'>
          {summaryType.includes(form?.getFieldValue('report_type')) &&
            form?.getFieldValue('report_on') === 'sold' && (
              <Col span={24}>
                <Divider orientation='left' orientationMargin='0' plain>
                  {t('Reports.Row_Setting')}
                </Divider>
              </Col>
            )}
          <Col span={24}>
            <Row gutter={12}>
              {formDataList.includes('display_stages_as_row') && (
                <Col span={5}>
                  <Form.Item
                    className='m-0'
                    name='display_stages_as_row'
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={() => {
                        form.resetFields([
                          'sum_call_clicks',
                          'sum_email_clicks',
                          'sum_modified',
                          'sold_created',
                          'sold_assigned',
                          'sum_sss_created',
                          'sum_by_history_category',
                          'sum_results',
                          'sum_inbound_leads',
                          'sum_outbound_leads',
                          'history_categories',
                          'sold_sum_total',
                          'sum_active',
                          'sum_finance_value',
                          'sum_by_stages',
                        ]);
                        onChange();
                        setDisabledCountry(!disabledCountry);
                      }}
                      disabled={disabledStage}
                    >
                      {t('Reports.Display_Stages_As_Row')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}

              {formDataList.includes('count_historical_moments') &&
                form?.getFieldValue('display_stages_as_row') && (
                  <Col span={5}>
                    <InputComponent
                      type='radio_button'
                      name='count_historical_moments'
                      options={{
                        values: historyCurrentValues,
                      }}
                      onChange={onChange}
                    />
                    {/* <Form.Item className='m-0' name='count_historical_moments' valuePropName='checked'>
                                    <Checkbox onChange={onChange}>{t('Reports.Count_Historical_Moments')}</Checkbox>
                                </Form.Item> */}
                  </Col>
                )}
              {formDataList.includes('display_stages_as_row') && (
                <Col span={5}>
                  <Form.Item
                    className='m-0'
                    name='sold_by_country'
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={() => {
                        form.resetFields([
                          'sold_sum_total',
                          'sum_active',
                          'sum_finance_value',
                          'sum_by_stages',
                          'sum_call_clicks',
                          'sum_email_clicks',
                          'sum_modified',
                          'sold_created',
                          'sold_assigned',
                          'sum_sss_created',
                          'sum_by_history_category',
                          'sum_results',
                          'sum_inbound_leads',
                          'sum_outbound_leads',
                          'history_categories',
                          'display_stages_as_row',
                        ]);
                        onChange();
                        setDisabledStage(!disabledStage);
                      }}
                      disabled={disabledCountry}
                    >
                      {t('Reports.Display_Country_As_Row')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={4}>
            <legend style={{ margin: '0' }}>{t('Reports.Select_Columns')}</legend>
          </Col>
          <Col span={4}>
            <InputComponent
              label={
                <div className='labelWithCheckbox'>
                  {
                    <Form.Item className='ml-5'>
                      <Checkbox
                        onChange={(e) => {
                          handleCheckAllCheckbox(e);
                        }}
                        checked={checkAll}
                        indeterminate={indeterminate}
                      ></Checkbox>
                    </Form.Item>
                  }
                  &nbsp;&nbsp;&nbsp;
                  <span className='title'>{t('PeopleManagement.All')}</span>
                </div>
              }
              type='labelOnly'
            />
          </Col>
          {(form?.getFieldValue('report_on') === 'tickets' || form?.getFieldValue('report_on') === 'all') &&
            <Col span={4}>
              <Form.Item
                className='ml-5'
                name='ticket_priority'
                valuePropName='checked'
              >
                <Checkbox
                  onChange={(e) => {
                    handleTicketPriorityCheckbox(e);
                  }}
                >
                  {`${t("Common_Data.Ticket")}-${t("Common_Data.Priority")}`}
                </Checkbox>
              </Form.Item>
            </Col>
          }
        </Row>
        {!form?.getFieldValue('sold_by_country') && (
          <Row gutter={12} className='mb-5' align='middle'>
            <Col span={24}>
              <Divider orientation='left' orientationMargin='0' plain>
                {t('Reports.Common')}
              </Divider>
            </Col>
            <Col span={24}>
              <Row gutter={12} align='middle'>
                {!form?.getFieldValue('display_stages_as_row') && (
                  <Col span={4}>
                    <Form.Item
                      className='m-0'
                      name='sum_call_clicks'
                      valuePropName='checked'
                    >
                      <Checkbox onChange={onChange}>
                        {t('Reports.Sum_Call_Clicks')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
                {!form?.getFieldValue('display_stages_as_row') && (
                  <Col span={4}>
                    <Form.Item
                      className='m-0'
                      name='sum_email_clicks'
                      valuePropName='checked'
                    >
                      <Checkbox onChange={onChange}>
                        {t('Reports.Sum_Email_Clicks')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}


                {!form?.getFieldValue('display_stages_as_row') && (
                  <Col span={4}>
                    <Form.Item
                      className='m-0'
                      name='sum_modified'
                      valuePropName='checked'
                    >
                      <Checkbox onChange={onChange}>{t('Reports.Sum_Modified')}</Checkbox>
                    </Form.Item>
                  </Col>
                )}
                <Col span={5}>
                  <Form.Item
                    className='m-0'
                    name='sum_total_time_spent'
                    valuePropName='checked'
                  >
                    <Checkbox onChange={onChange}>
                      {t('Reports.Sum_Total_Time_Spent')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </>
    );
  }
);

export default CommonSummaryElement;
