const GridConfig = {
  options: {
    rowModelType: 'serverSide',
    animateRows: true,
    pagination: false,
    paginationPageSize: 100,
    cacheBlockSize: 500,
    rowHeight: 30,
    blockLoadDebounceMillis: 500,
    enableRangeSelection: true,
    suppressDragLeaveHidesColumns: true,
  },
  defaultColDef: {
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    filter: false,
    minWidth: 70,
    width: 120,
    flex: 1,
    resizable: true,
    filterParams: {
      suppressAndOrCondition: true,
      closeOnApply: true,
      debounceMs: 1500,
    },
    floatingFilter: true,
  },
  columnTypes: {
    actionColumn: {
      cellRenderer: 'ActionRenderer',
    },
  },
  domLayout: 'autoHeight',
};

export default GridConfig;
