import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import useStore from '../../../store';
import { vsmNotify, vsmLeadsList } from '../../../config/messages';
import InputComponent from '../../../component/InputComponent';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';


const StatusConfirmComponent = observer((props) => {
  const { t } = useTranslation();
  const { openShortfall = () => { }, isFromDealDesk, setSoldStatus, editSoldStatus, AddNewLead } = props;
  const {
    AUTH,
    LEADSSTORE,
    LEADSSTORE: { updateLeadStatus },
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    SEARCHSTORE,
    LEADSEARCH,
    DEALDESKSTORE: { deal_data, getDealDetail, setStatusPayloadForShortfall, setRejectionSummaryData },
  } = useStore();
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const location = useLocation();
  const [form] = Form.useForm();

  const close = () => {
    form.resetFields();
    props.close();
    
  };

  useEffect(() => {
    if (!AddNewLead) {
      if (editSoldStatus) {
        form.setFieldsValue({
          rejected_reason: editSoldStatus?.rejected_reason ? editSoldStatus?.rejected_reason : LEADSSTORE.editValues?.rejected_reason,
          summary: editSoldStatus?.summary ? editSoldStatus?.summary : LEADSSTORE.editValues?.summary,
          failed_reason: editSoldStatus?.failed_reason ? editSoldStatus?.failed_reason : LEADSSTORE.editValues?.failed_reason,
          failed_summary: editSoldStatus?.failed_summary ? editSoldStatus?.failed_summary : LEADSSTORE.editValues?.failed_summary,
          future_reason: editSoldStatus?.future_reason ? editSoldStatus?.future_reason : LEADSSTORE.editValues?.future_reason,
        })
      }
      if (deal_data?.lead_information && props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_REJECTED) {
        form.setFieldsValue({
          rejected_reason: deal_data.lead_information?.rejected_reason,
          summary: deal_data.lead_information?.summary
        })
        if (deal_data.lead_information?.rejected_reason) {
          setDisabled(false);
        }
      } else if (deal_data?.lead_information && props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FAILED) {
        form.setFieldsValue({
          failed_reason: deal_data.lead_information?.failed_reason,
          failed_summary: deal_data.lead_information?.failed_summary
        })
        if (deal_data.lead_information?.failed_reason) {
          setDisabled(false);
        }
      } else if (deal_data?.lead_information && props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FUTURE) {
        form.setFieldsValue({
          future_reason: deal_data.lead_information?.future_reason,
          // failed_summary: deal_data.lead_information?.failed_summary
        })
        if (deal_data.lead_information?.future_reason) {
          setDisabled(false);
        }
      }
    }


  }, [AddNewLead, form, deal_data, AUTH.SOLD_STATUS_REJECTED, AUTH.SOLD_STATUS_FUTURE, AUTH.SOLD_STATUS_FAILED, props?.statusUpdateData?.new_id, LEADSSTORE, editSoldStatus])

  // Make function call to delete existing record
  const handleSubmit = (data) => {

    if (props.isFromLeadForm) {
      props.setFailedReason(data.failed_reason);
      // let payload = {
      //   lead_status_id: props.statusUpdateData.new_id,
      // };
      if (data) {
        setSoldStatus(data);
        // For REJECTED //
        if (props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_REJECTED) {
          setRejectionSummaryData({ type: "rejected", reason: "Rejection Summary", summary: data.summary }) // For Note
          // payload = {
          //   ...payload,
          //   lead_status_id: props.statusUpdateData.new_id,
          //   rejected_reason: data.rejected_reason,
          //   summary: data.summary,
          //   statusType: "rejected",
          // }
        }
        // For FAILED //
        if (props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FAILED) {
          setRejectionSummaryData({ type: "failed", reason: "Failed Summary", summary: data.failed_summary }) // For Note
          // payload = {
          //   ...payload,
          //   lead_status_id: props.statusUpdateData.new_id,
          //   failed_reason: data.failed_reason,
          //   failed_summary: data.failed_summary,
          //   statusType: "failed",
          // }
        }
        // For FUTURE //
        if (props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FUTURE) {
          // payload = {
          //   ...payload,
          //   lead_status_id: props.statusUpdateData.new_id,
          //   future_reason: data.future_reason,
          //   statusType: "future",
          // }
        }
      }
      close();
    } else {
      let payload = {
        lead_status_id: props.statusUpdateData.new_id,
      };
      if (data) {
        // setRejectionSummaryData(data.summary)
        // For REJECTED //
        if (props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_REJECTED) {
          setRejectionSummaryData({ type: "rejected", reason: "Rejection Summary", summary: data.summary }) // For Note
          payload = {
            ...payload,
            lead_status_id: props.statusUpdateData.new_id,
            rejected_reason: data.rejected_reason,
            summary: data.summary,
            statusType: "rejected",
          }
        }
        // For FAILED //
        if (props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FAILED) {
          setRejectionSummaryData({ type: "failed", reason: "Failed Summary", summary: data.failed_summary }) // For Note
          payload = {
            ...payload,
            lead_status_id: props.statusUpdateData.new_id,
            failed_reason: data.failed_reason,
            failed_summary: data.failed_summary,
            statusType: "failed",
          }
        }
        // For FUTURE //
        if (props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FUTURE) {
          payload = {
            ...payload,
            lead_status_id: props.statusUpdateData.new_id,
            future_reason: data.future_reason,
            statusType: "future",
          }
        }
      }
      // if (data && data?.summary) {
      //   setRejectionSummaryData(data.summary)
      //   payload = {
      //     ...payload, failed_reason: data.failed_reason,
      //     failed_s ummary: data.failed_summary,
      //     rejected_reason: data.rejected_reason,
      //     summary: data.summary,
      //   }
      // }
      if ([AUTH.SOLD_STATUS_FAILED, AUTH.SOLD_STATUS_REJECTED].includes(payload.lead_status_id) && isFromDealDesk) {
        setStatusPayloadForShortfall(payload);
        openShortfall();
        props.close();
        form.resetFields();
      }
      else {
        setSaving(true);
        updateLeadStatus(props.statusUpdateData.id, payload)
          .then(() => {
            close();
            //------------START:TO REFRESH DEALDESK PAGE-----------//
            if (props.isFromDealDesk) {
              getDealDetail(props.statusUpdateData.id);
            }
            //------------END:TO REFRESH DEALDESK PAGE-----------//

            //------------START:TO CLOSE STATUS MODAL POPOVER USED FOR CONFIRMATION-----------//
            if (props.setStatusModalVisible) {
              props.setStatusModalVisible(false);
            }
            //------------END:TO CLOSE STATUS MODAL POPOVER USED FOR CONFIRMATION-----------//

            //------------START:TO REFRESH GRID-----------//
            if (location.pathname === '/leads/deals') {
              LEADDEALS.setupGrid(LEADDEALS.agGrid);
            }
            if (location.pathname === '/leads/opportunities') {
              LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
            }
            if (location.pathname === '/leads/active') {
              LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
            }
            //------------END:TO REFRESH GRID-----------//

            //------------START:TO REFRESH SEARCH RESULT COUNTS-----------//
            if (props.SearchPage) {
              SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'active');
              SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'deal');
              if (LEADDEALS.agGrid) {
                LEADDEALS.setupGrid(LEADDEALS.agGrid);
              }
              if (LEADSEARCH.agGrid) {
                LEADSEARCH.setupGrid(LEADSEARCH.agGrid);
              }
            }
            //------------END:TO REFRESH SEARCH RESULT COUNTS-----------//

            AUTH.fetchLeftmenuCount();

            vsmNotify.success({
              message: vsmLeadsList.successStatusUpdate,
            });
          })
          .catch((e) => {
            if (e.errors) {
              form.setFields(e.errors);
            }
          })
          .finally(() => {
            setSaving(false);
          });
      }
    }
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return props.statusUpdateData ? (
    <Modal
      centered
      zIndex={1008}
      title={
        props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FAILED
          ? `${t('Common_Data.Select')} ${t('Common_Data.Reason')} ${t('Common_Data.For')} "${t('LeadListing.Failed')}"`
          :
          props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_REJECTED
            ?
            `${t('Common_Data.Select')} ${t('Common_Data.Reason')} ${t('Common_Data.For')} "${t('LeadListing.Rejected')}"`
            :
            props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FUTURE
              ? `${t('Common_Data.Select')} ${t('Common_Data.Reason')} ${t('Common_Data.For')} "${t('LeadListing.Future')}"`
              : `${t('LeadListing.Change_Status')}`
      }
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <>
          <Button
            key='1'
            form='StatusConfirm'
            htmlType='submit'
            type='primary'
            shape='round'
            loading={saving}
            disabled={props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FAILED ||
              props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_REJECTED ||
              props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FUTURE
              ? disabled
              : false
            }
          >
            {props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FAILED ||
              props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_REJECTED ||
              props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FUTURE
              ? [AUTH.SOLD_STATUS_FAILED, AUTH.SOLD_STATUS_REJECTED].includes(props?.statusUpdateData?.new_id)
                ? isFromDealDesk
                  ? `${t('Common_Data.Add_Shortfall')}`
                  : `${t('LeadListing.Change_Status')}`
                : `${t('Common_Data.Yes')}`
              : `${t('Common_Data.Yes')}`
            }
          </Button>
          <Button key='2' htmlType='button' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>
        </>
      ]}
    >
      <Form
        className='innerForm'
        layout={'vertical'}
        form={form}
        id='StatusConfirm'
        onFinish={handleSubmit}
      >
        {props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FAILED ? (
          <Row>
            <Col span={24}>
              <InputComponent
                name='failed_reason'
                label={`${t('LeadListing.Reason')}`}
                required
                allowClear
                showArrow
                showSearch
                placeholder={`${t('LeadListing.Reason')}`}
                onChange={handleChange}
                type='select'
                tooltip={`${t('LeadListing.Reason')}.`}
                options={{
                  values:
                    AUTH.user && AUTH.user?.failed_reasons
                      ? AUTH.user?.failed_reasons
                      : [],
                  value_key: 'reason',
                  text_key: 'reason',
                }}
                rules={[{ required: true, message: `${t('Common_Data.Please')} ${t('LeadListing.Select')} ${t('Common_Data.Reason')}.` }]}
              />
            </Col>
            <Col span={24}>
              <InputComponent
                name='failed_summary'
                type='textarea'
                autoSize={{ minRows: 3, maxRows: 3 }}
                label={`${t('DealDesk.Summary')}`}
                placeholder={`${t('DealDesk.Summary')}`}
                onChange={handleChange}
              />
            </Col>
          </Row>
        ) : props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_REJECTED ? (
          <>
            <Row>
              <Col span={24}>
                <InputComponent
                  name='rejected_reason'
                  label={`${t('LeadListing.Reason')}`}
                  required
                  allowClear
                  showArrow
                  showSearch
                  placeholder={`${t('LeadListing.Reason')}`}
                  onChange={handleChange}
                  type='select'
                  tooltip={`${t('LeadListing.Reason')}.`}
                  options={{
                    values:
                      AUTH.user && AUTH.user?.rejected_reasons
                        ? AUTH.user?.rejected_reasons
                        : [],
                    value_key: 'reason',
                    text_key: 'reason',
                  }}
                  rules={[{ required: true, message: `${t('Common_Data.Please')} ${t('LeadListing.Select')} ${t('Common_Data.Reason')}.` }]}
                />
              </Col>
              <Col span={24}>
                <InputComponent
                  name='summary'
                  type='textarea'
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  label={`${t('DealDesk.Summary')}`}
                  placeholder={`${t('DealDesk.Summary')}`}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </>
        )
          : props?.statusUpdateData?.new_id === AUTH.SOLD_STATUS_FUTURE ? (
            <Row>
              <Col span={24}>
                <InputComponent
                  name='future_reason'
                  label={`${t('LeadListing.Reason')}`}
                  required
                  allowClear
                  showArrow
                  showSearch
                  placeholder={`${t('LeadListing.Reason')}`}
                  onChange={handleChange}
                  type='select'
                  tooltip={`${t('LeadListing.Reason')}.`}
                  options={{
                    values:
                      AUTH.user && AUTH.user?.future_reasons
                        ? AUTH.user?.future_reasons
                        : [],
                    value_key: 'reason',
                    text_key: 'reason',
                  }}
                  rules={[{ required: true, message: `${t('Common_Data.Please')} ${t('LeadListing.Select')} ${t('Common_Data.Reason')}.` }]}
                />
              </Col>
            </Row>
          ) : (
            <Row align='middle'>
              <Col span={4} className='DeleteIcon'>
                <WarningFilled />
              </Col>
              <Col span={20} className='DeleteText'>
                {t('LeadListing.Change_Status_From')}{' '}
                {props.statusUpdateData.oldstatus} to {props.statusUpdateData.newstatus} {t('Common_Data.For')}
                {t('Common_Data.This_Lead')} {props.statusUpdateData.id}?
              </Col>
            </Row>
          )}
      </Form>

      {[AUTH.SOLD_STATUS_FAILED, AUTH.SOLD_STATUS_REJECTED].includes(props?.statusUpdateData?.new_id) && isFromDealDesk
        ?
        <span className='redText noteMsg'>
          {t('LeadListing.Shortfall_Message_Note')}
        </span>
        :
        null
      }
    </Modal>
  ) : null;
});

export default StatusConfirmComponent;
