import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify, vsmTags } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const ReassignConfirmComponent = observer((props) => {
  const [saving, setSaving] = useState(false);
  const {t} = useTranslation()
  const [form] = Form.useForm();

  const {
    TagsStore: { assignTag },
    TagsStore,
  } = useStore();

  const close = () => {
    props.close();
    form.resetFields();
  };

  const handleSubmit = () => {
    setSaving(true);
    let payload = {
      assign_array: [
        {
          type: 'tag',
          user_id: props.reAssingTempData.userid,
          id: props.reAssingTempData.tagid,
        },
      ],
    };

    assignTag(payload)
      .then((data) => {
        close();
        if (props.SearchPage) {
          TagsStore.getListSearch(TagsStore.search_payload) 
        }
        vsmNotify.success({
          message: vsmTags.reassign,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    props.reAssingTempData && (
      <Modal
        centered
        title={`${t('Common_Data.Reassign')} ${t('Common_Data.To')} ${props.reAssingTempData.username}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='reassignForm'
            htmlType='submit'
            type='primary'
            shape='round'
            loading={saving}
          >
            {t('Common_Data.Yes')}
          </Button>,
          <Button key='2' htmlType='button' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
      >
        <Form form={form} id='reassignForm' onFinish={handleSubmit}>
          {
            <Row align='middle'>
              <Col span={4} className='DeleteIcon'>
                <WarningFilled />
              </Col>
              <Col span={20} className='DeleteText'>
                {t('Tags.Reassign_This')} {props.reAssingTempData.tagno}{' '}
                {t('Common_Data.To')} {props.reAssingTempData.username}?
              </Col>
            </Row>
          }
        </Form>
      </Modal>
    )
  );
});

export default ReassignConfirmComponent;
