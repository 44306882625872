import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import ListComponent from '../../OrganisationManagement/AllOrganisations/component/ListComponent';
import ViewComponent from '../../OrganisationManagement/AllOrganisations/component/ViewComponent';
import DeleteComponent from '../../OrganisationManagement/AllOrganisations/component/DeleteComponent';
import { globalStatus } from '../../../utils/GlobalFunction';
import RestoreComponent from './RestoreComponent';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const Organisations = observer((props) => {
  const {t} = useTranslation()
  const { WasteManagement } = props;
  const {
    AUTH,
    PEOPLE,
    ORGANISATION,
    WASTESTORE,
    TimeTrackerStore,
    // ORGANISATION: { setOrganisationValues, setDeleteValues },
    ORGANISATION: { setDeleteValues },
  } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restoreValues, setRestoreValues] = useState(false);

  // Open & Close Restore Model function
  const openRestoreModal = (data) => {
    setRestoreValues(data);
    setRestoreModal(true);
  };

  const closeRestoreModal = () => setRestoreModal(false);

  // Open & Close Delete Model function
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => setDeleteModal(false);

  // Open & Close View Drawer function

  const handleViewDetailCall = debounce((data) => {
    ORGANISATION.viewPeopleLoading = true;
    ORGANISATION.getViewOrgDetail(data?.id)
      .then((data) => {
        if (data?.cash_transactions && data?.cash_transactions.length) {
          data?.cash_transactions.forEach((item) => {
            item.status_name = globalStatus(
              'cash_transaction_status',
              'key',
              item.status
            );
          });
        }
        ORGANISATION.viewOrgValues = data;
        PEOPLE.list_related_ORG = data?.related_organisations;
        PEOPLE.list_related_Ppl = data?.related_peoples;
        PEOPLE.list_trade_transaction = data?.trade_transactions;
        PEOPLE.list_cash_transaction = data?.cash_transactions;
        PEOPLE.list_banks = data?.banks;
        ORGANISATION.viewPeopleLoading = false;
        setViewDrawer(true);
      })
      .catch((ORGANISATION.viewPeopleLoading = false));
  }, 500);

  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(16, 'view-details')) {
      ORGANISATION.viewPeopleLoading = true;

      handleViewDetailCall(data);
    }
  };

  const closeViewDrawer = () => {
    // setOrganisationValues([]);
    setViewDrawer(false);
  };

  useEffect(() => {
    if (viewDrawer) {
      TimeTrackerStore.setStopForWaste(true);
    } else {
      TimeTrackerStore.setStopForWaste(false);
    }
  }, [TimeTrackerStore, viewDrawer]);

  return (
    <>
      <ListComponent
        WasteManagement={WasteManagement}
        openViewDrawer={openViewDrawer}
        openDeleteModal={openDeleteModal}
        openRestoreModal={openRestoreModal}
      />
      <ViewComponent visible={viewDrawer} close={closeViewDrawer} waste={true} />
      <DeleteComponent
        visible={deleteModal}
        close={closeDeleteModal}
        getWasteCount={WASTESTORE.getWasteCount}
        lineText={`${t('Waste.Permanently_Remove_Record')}?`}
      />
      <RestoreComponent
        visible={restoreModal}
        close={closeRestoreModal}
        store={ORGANISATION}
        currentValues={restoreValues}
        lineText={`${t('Common_Data.Organisation')}`}
        getWasteCount={WASTESTORE.getWasteCount}
        id={'restoreorgform'}
        title={`${t('Common_Data.Restore')} ${t('Common_Data.Organisation')} - #${restoreValues && restoreValues.id} - ${
          restoreValues && restoreValues.organisation_name
        }`}
      />
    </>
  );
});

export default Organisations;
