import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmTags, vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const {
    AUTH,
    SEARCHSTORE,
    TagsStore,
    TagsStore: { deleteValues, removeTag },
  } = useStore();

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
    setDisabled(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setDisabled(true);
    setSaving(true);

    removeTag(deleteValues)
      .then(() => {
        form.resetFields();
        close();
        if (props.getWasteCount) {
          props.getWasteCount();
        }

        if (!props?.SearchPage) {
          if (TagsStore.agGrid) {
            TagsStore.setupGrid(TagsStore.agGrid);
          }
        } else {
          TagsStore.agGrid.api.applyTransaction({ remove: [deleteValues] });
          SEARCHSTORE.updateSearchCount({ tag_count: SEARCHSTORE.searchCount.tag_count - 1 });
        }

        AUTH.fetchLeftmenuCount(); //to update left menu count
        vsmNotify.success({
          message: vsmTags.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };
  return (
    deleteValues && (
      <Modal
        centered
        title={`${t('Common_Data.Delete')} ${t('Tags.Tag')} ${deleteValues.id ? ' - #' + deleteValues.id : ''}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='deletetagform'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            danger
            disabled={disabled}
          >
            {t('Common_Data.Delete')}
          </Button>,
          <Button key='2' htmlType='button' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
      >
        <Form form={form} id='deletetagform' onFinish={handleSubmit}>
          {
            <Row align='middle'>
              <Col span={4} className='DeleteIcon'>
                <WarningFilled />
              </Col>
              <Col span={20} className='DeleteText'>
                {t('Tags.Delete_Tag')}{' '}
                <strong>{deleteValues.tag_number ? deleteValues.tag_number : ''}</strong>?
              </Col>
            </Row>
          }
        </Form>
      </Modal>
    )
  );
});

export default DeleteComponent;
