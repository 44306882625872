import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Card, Form, Row, Col, Radio, Space } from 'antd';

import useStore from '../../../store';
import InputComponent from '../../../component/InputComponent';
import DropDownSelection from '../elements/DropDownSelection';
import Notification from '../elements/Notification';
import {
  vsmLeadsList,
  vsmNotify,
  vsmOrganisations,
  vsmSnapNote,
  vsmTags,
  vsmTicket,
} from '../../../config/messages';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const SpanNoteComponent = observer((props) => {
  const [typeValue, setTypeValue] = useState('user');
  const [hourglassActive, setHourglassActive] = useState(false);
  const [usersActive, setUsersActive] = useState(false);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [form] = Form.useForm();
  const { expandSnapNote } = props;

  const {
    AUTH,
    SPANNOTESTORE: { totalNotes, addSnapNote, getList, setUsersSelected, unreadBlink },
    TicketsStore: { addTicket },
    TagsStore: { addTag },
    LEADSSTORE: { addLeadData },
    ORGANISATION,
    DEALDESKSTORE,
  } = useStore();
  const { t } = useTranslation()
  const SnapNoteTitle = () => {
    return (
      <>
        <img
          src={AUTH.GetThemedImage('Snap_Note')}
          alt={`${t('Common_Data.Snap')} ${t('Common_Data.Note')}`}
          className='titleIcon'
        />{' '}
        {t('Common_Data.Snap')} {t('Common_Data.Note')}{' '}
        <span
          className={
            totalNotes > 0
              ? unreadBlink
                ? 'TitleValueBlink'
                : 'TitleValue'
              : 'TitleValue'
          }
        >
          {totalNotes}
        </span>
      </>
    );
  };

  const saveReminder = (formdata) => {
    addSnapNote(formdata)
      .then(() => {
        form.resetFields();
        setUsersSelected([]);
        AUTH.fetchLeftmenuCount();
        if (typeValue === 'user') {
          vsmNotify.success({
            message: vsmSnapNote.add,
          });
        } else {
          vsmNotify.success({
            message: vsmSnapNote.reminderadd,
          });
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setDisabled(false));
  };
  // Add SnapNote
  const onFinish = (values) => {
    setDisabled(true);
    let formdata = {
      type: values.type,
      hourglass: values.hourglass,
      snap_note_texts: values.content,
    };

    if (typeValue === 'user') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'tags') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'tickets') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'leads') {
      formdata.users = values.userSelect;
    } else if (typeValue === 'contacts') {
      formdata.users = values.userSelect;
    }

    if (typeValue === 'tickets' && !formdata.hourglass) {
      //Add Ticket
      let ticket_payload = {
        ticket_subject: values.content,
        is_org: true,
        ticket_status: 1, // Default Open AS per API
        ticket_description: values.content,
        priority: 4, //# Default Routine
        leads_entity_attributes_type: 8,
        owner: AUTH.user.id,
        is_deleted: 0,
      };
      addTicket(ticket_payload).then((res) => {
        formdata.id = res.id;
        formdata.hourglass = true;
        formdata.snap_note_texts = res.ticket_number + ' : ' + values.content;
        saveReminder(formdata);

        vsmNotify.success({
          message: vsmTicket.add,
        });
      });
    } else if (typeValue === 'tags' && !formdata.hourglass) {
      //Add Tag
      let tag_payload = {
        tag_subject: values.content,
        organisation_id: 0,
        parent_ticket_id: null,
        holder: AUTH.user.id,
        tag_status: 2, //Default Unassigned
        deadline_date: moment().format('YYYY-MM-DD'),
        tag_content: `<p>${values.content}</p>`,
      };
      addTag(tag_payload).then((res) => {
        formdata.id = res.id;
        formdata.hourglass = true;
        formdata.snap_note_texts = res.tag_number + ' : ' + values.content;
        saveReminder(formdata);
        vsmNotify.success({
          message: vsmTags.add,
        });
      });
    } else if (typeValue === 'leads' && !formdata.hourglass) {
      let timestamp = new Date().valueOf();
      let lead_payload = {
        lead_record_type: 0,
        classification_id: 0,
        full_name: 0,
        organisation_name: 0,
        lead_owner_id: AUTH.user.id,
        lead_holder_id: AUTH.user.id,
        lead_status_id: 4, //# Default Unused
        lead_currency_id: 1, //# Default Currency Euro
        phone: [{ contact_for: 'Primary', contact_value: '0000000000' }],
        email: [
          { contact_for: 'Primary', contact_value: `blank${timestamp}@convertibill.com` },
        ],
        product_name: values.content,
      };
      addLeadData(lead_payload)
        .then((res) => {
          formdata.id = res.id;
          formdata.hourglass = true;
          formdata.snap_note_texts = values.content;
          saveReminder(formdata);
          vsmNotify.success({
            message: vsmLeadsList.add,
          });
        })
        .finally(() => { });
    } else if (typeValue === 'contacts' && !formdata.hourglass) {
      let org_payload = {
        description: values.content,
        organisation_name: values.content,
        registered_country_id: 106,
        industry_id: 0,
        status_id: 1,
        nace_section_id: 0,
        currency_id: 1, // Default Euro
        add_address: 0,
      };
      ORGANISATION.AddData(org_payload).then((res) => {
        formdata.id = res.id;
        formdata.hourglass = true;
        formdata.snap_note_texts = values.content;
        saveReminder(formdata);

        vsmNotify.success({
          message: vsmOrganisations.add,
        });
      });
    } else {
      saveReminder(formdata);
    }
  };

  // handle Type Change
  const handleTypeChange = (e) => {
    setTypeValue(e.target.value);
  };

  const disableHourGlass = () => {
    setHourglassActive(true);
  };
  const activeHourGlass = () => {
    setHourglassActive(false);
  };

  const handleClick = () => {
    setChecked(!checked);
    if (!checked) {
      setUsersActive(true);
    } else {
      setUsersActive(false);
    }
  };

  const updateLeftMenuCounts = useCallback(
    (data) => {
      if (data && data?.sidebar_count?.original) {
        let leftmenucounts = data.sidebar_count.original;
        AUTH.api_menu_count_opportunities =
          leftmenucounts &&
          leftmenucounts.opportunities &&
          leftmenucounts.opportunities.count &&
          leftmenucounts.opportunities.count.toLocaleString('en-GB');
        AUTH.api_menu_count_deals = leftmenucounts.deals.count.toLocaleString('en-GB');
        AUTH.api_menu_countused = leftmenucounts.used_leads.count.toLocaleString('en-GB');
        AUTH.api_menu_count_unused =
          leftmenucounts.unused_leads.count.toLocaleString('en-GB');
        AUTH.api_menu_count_itinerary =
          leftmenucounts.itinerary.count.toLocaleString('en-GB');
        AUTH.api_menu_count_reminders =
          leftmenucounts.reminders.count.toLocaleString('en-GB');
        AUTH.api_menu_count_tags = leftmenucounts.tags.count.toLocaleString('en-GB');
        AUTH.api_menu_count_knowledge =
          leftmenucounts.knowledge.count.toLocaleString('en-GB');
        AUTH.api_menu_count_tickets =
          leftmenucounts.tickets.count.toLocaleString('en-GB');
        AUTH.api_menu_count_organisation =
          leftmenucounts.organisation.count.toLocaleString('en-GB');
        AUTH.api_menu_count_people = leftmenucounts.people.count.toLocaleString('en-GB');
        AUTH.api_menu_count_waste = leftmenucounts.waste.count.toLocaleString('en-GB');
      }
    },
    [AUTH]
  );

  /*Snapnote autofetch API call*/
  useEffect(() => {
    if (!AUTH.isUserIdle) {
      //if (AUTH.user && AUTH.user.id && AUTH.user.id === 1) {

      const id = setInterval(
        () =>
          getList().then((data) => {
            if (data.sidebar_count.original) {
              updateLeftMenuCounts(data);
            }
          }),
        60000
      );
      return () => {
        clearInterval(id);
      };
      //}
    }
  }, [getList, updateLeftMenuCounts, AUTH.isUserIdle]);

  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  return (
    <>
      <Card
        size='small'
        title={SnapNoteTitle()}
        className='SnapNoteModule'
        extra={<Notification />}
      >
        <Form
          form={form}
          id='SnapNoteForm'
          className='innerForm SnapNoteForm hideErrorMessage'
          labelCol={{ span: 24 }}
          onFinish={onFinish}
          name='SnapNote'
        >
          <Row gutter={0}>
            <Col span={24}>
              <Space align='start' size={4}>
                <Form.Item
                  name='type'
                  onChange={() => {
                    form.resetFields(['userSelect']);
                    //handleChange();
                  }}
                  rules={vsmSnapNote.validation.type}
                  initialValue='user'
                  defaultValue='user'
                >
                  <Radio.Group
                    name='type'
                    className='iconList'
                    onChange={handleTypeChange}
                  // initialValue='user'
                  // defaultValue='user'
                  >
                    {AUTH.checkPermission(48, 'snap-note-organisation') && (
                      <Radio.Button
                        value='contacts'
                        name='contacts'
                        onClick={activeHourGlass}
                      >
                        <img
                          src={AUTH.GetThemedImage('Contacts_icn')}
                          alt={`${t('Reports.Contacts')}`}
                          className='titleIcon'
                          title={`${t('Reports.Contacts')}`}
                        />
                      </Radio.Button>
                    )}
                    {AUTH.checkPermission(48, 'snap-note-deals') && (
                      <Radio.Button value='leads' onClick={activeHourGlass}>
                        <img
                          src={AUTH.GetThemedImage('Deal_icn')}
                          alt={`${t('Common_Data.Lead')}, ${t('Common_Data.Deal')} & ${t('Common_Data.Opportunities')}`}
                          className='titleIcon'
                          title={`${t('Common_Data.Lead')}, ${t('Common_Data.Deal')} & ${t('Common_Data.Opportunities')}`}
                        />
                      </Radio.Button>
                    )}
                    {AUTH.checkPermission(48, 'snap-note-tickets') && (
                      <Radio.Button value='tickets' onClick={activeHourGlass}>
                        <img
                          src={AUTH.GetThemedImage('Tickets')}
                          alt={`${t('Common_Data.Tickets')}`}
                          className='titleIcon'
                          title={`${t('Common_Data.Tickets')}`}
                        />
                      </Radio.Button>
                    )}
                    {AUTH.checkPermission(48, 'snap-note-notes') && (
                      <Radio.Button value='tags' onClick={activeHourGlass}>
                        <img
                          src={AUTH.GetThemedImage('Note')}
                          alt={`${t('Common_Data.Tags')} ${t('Common_Data.Note')}`}
                          className='titleIcon'
                          title={`${t('Common_Data.Tags')} ${t('Common_Data.Note')}`}
                        />
                      </Radio.Button>
                    )}
                    {AUTH.checkPermission(48, 'snap-note-people') && (
                      <Radio.Button
                        value='user'
                        name='user'
                        disabled={usersActive}
                        onClick={disableHourGlass}
                      >
                        <img
                          src={AUTH.GetThemedImage('Profile')}
                          alt={`${t('ManageAllDepartments.Users')} & ${t('ManageAllDepartments.Users')} ${t('Common_Data.Group')}`}
                          className='titleIcon'
                          title={`${t('ManageAllDepartments.Users')} & ${t('ManageAllDepartments.Users')} ${t('Common_Data.Group')}`}
                        />
                      </Radio.Button>
                    )}
                  </Radio.Group>
                </Form.Item>
                <Form.Item name='hourglass' initialValue={false} valuePropName='checked'>
                  <Checkbox
                    className='iconList hourglassCheckbox'
                    disabled={hourglassActive}
                    checked={checked}
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <img
                      src={AUTH.GetThemedImage('Reminders_icn')}
                      alt={`${t('Common_Data.Snap')} ${t('Common_Data.Note')}`}
                      className='titleIcon'
                    />
                  </Checkbox>
                </Form.Item>
              </Space>
            </Col>
            <Col span={24}>
              <InputComponent
                name='content'
                type='textarea'
                required
                className={
                  DEALDESKSTORE.deal_data
                    ? 'fixContent userLive fullHeight'
                    : 'fixContent userLive fullHeight'
                }
                //defaultValue='Hello, How are you?'
                placeholder={`${t('SnapNoteModule.Your')} ${t('SnapNoteModule.Message')}`}
                onChange={handleChange}
                rules={vsmSnapNote.validation.content}
              />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col xs={{ span: '15' }}>
              <DropDownSelection typeValue={typeValue} onChange={handleChange} />
            </Col>
            <Col xs={{ span: '4' }} className='p-0'>
              <img
                src={AUTH.GetThemedImage('Expand')}
                alt={`${t('Common_Data.Expand')}`}
                className='titleIcon'
                onClick={() => expandSnapNote(props)}
                style={{ width: '90%', height: 'auto' }}
              />
            </Col>
            <Col xs={{ span: '4' }}>
              <button className='SendNote' htmlType='submit' disabled={disabled}>
                <img src={AUTH.GetThemedImage('Send_Note')} alt='' />
              </button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
});

export default SpanNoteComponent;
