import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const ConditionalOption = (props) => {
  const {t} = useTranslation()
  const handleChange = (e) => {
    props.formula.component = e.target.value;
    switch (e.target.value) {
      case 'Formula':
        props.formula.operand1 = { key: 0, component: 'Custom' };
        props.formula.operand2 = { key: 0, component: 'Custom' };
        props.formula.operator = { key: 0, value: '=' };
        break;
      case 'AND':
      case 'OR':
        props.formula.child = [
          {
            block: 'Logical-Block',
            key: 0,
            component: 'Formula',
            operand1: { key: 0, component: 'Custom' },
            operand2: { key: 0, component: 'Custom' },
            operator: { key: 0, value: '=' }
          },
          {
            block: 'Logical-Block',
            key: 1,
            component: 'Formula',
            operand1: { key: 0, component: 'Custom' },
            operand2: { key: 0, component: 'Custom' },
            operator: { key: 0, value: '=' }
          }
        ];
        break;
      case 'Is Blank':
      case 'Not':
        props.formula.operand1 = { key: 0, component: 'Custom' };
        break;

      case 'Minimum':
      case 'Maximum':
        props.formula.child = [];
        props.formula.operand2 = { key: 0, component: 'Custom' };
        props.formula.operator = { key: 0, value: '=' };
        break;

      default:
        props.formula.child = [];
    }

    props.setFormula(props.main);
  };
  return (
    <Radio.Group
      defaultValue={props.formula.component}
      value={props.formula.component}
      buttonStyle='solid'
      onChange={handleChange}
      className='ConditionalOption'
    >
      <Radio.Button value='AND'>{t('DealBuilder.AND')}</Radio.Button>
      <Radio.Button value='OR'>{t('DealBuilder.OR')}</Radio.Button>
      <Radio.Button value='Not'>{t('DealBuilder.Not')}</Radio.Button>
      <Radio.Button value='Formula'>{t('DealBuilder.Formula')}</Radio.Button>
      <Radio.Button value='Is Blank'>{t('DealBuilder.Is_Blank')}</Radio.Button>
      <Radio.Button value='Minimum'>{t('DealBuilder.Minimum')}</Radio.Button>
      <Radio.Button value='Maximum'>{t('DealBuilder.Maximum')}</Radio.Button>
    </Radio.Group>
  );
};

export default ConditionalOption;
