import React from 'react';
//import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
//import { DateComparator } from '../../../../../utils/GlobalFunction';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';

export const ActionRenderer = (props) => {
  const {t} = useTranslation()
  const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents;
  const { AUTH } = useStore();

  return (
    <div className='action-column'>
      {props.data && props.data.id !== 1 ? (
        <>
          {AUTH.checkPermission(17, 'edit') && (
            <Button
              title={`${t('Common_Data.Edit')} ${t('OrganisationManagement.Org')} ${t('OrganisationManagement.Role')}`}
              type='text'
              onClick={() => {
                openEditModal(props.data);
              }}
            >
              <img alt='' className='menuicon' src={AUTH.GetThemedImage('Edit')} />
            </Button>
          )}
          {props.data && props.data.association_count > 0 ? (
            <Tooltip
              placement='topRight'
              color={'red'}
              title={
                `${t('OrganisationManagement.Delete_Role_Associated_Organisation')}`
              }
            >
              <Button type='text' disabled>
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            </Tooltip>
          ) : (
            AUTH.checkPermission(17, 'delete') && (
              <Button
                title={`${t('Common_Data.Delete')} ${t('OrganisationManagement.Org')} ${t('OrganisationManagement.Role')}`}
                type='text'
                onClick={() => {
                  openDeleteModal(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            )
          )}
        </>
      ) : null}
    </div>
  );
};

// export const gridOptions = {
//   columnDefs: [
//     {
//       headerName: '#',
//       valueGetter: function (params) { return params.node.rowIndex + 1; },
//       tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
//       cellClass: 'cellClass',
//       pinned: 'left',
//       filter: false,
//       sortable: false,
//       width: 60,
//     },
//     {
//       headerName: 'Role Name',
//       field: 'role_name',

//       width: 130,
//     },
//     {
//       headerName: 'No. of Organisations',
//       field: 'count_in_organisations_count',
//       filter: 'agNumberColumnFilter',

//     },
//     {
//       headerName: 'Related Roles',
//       headerTooltip: 'Related Roles',
//       field: 'associated_role_names',
//       tooltipValueGetter: (params) => params.data &&
//         params.data?.associated_role_names &&
//         params.data?.associated_role_names.length > 0
//         ? params.data?.associated_role_names.map((item, i) => {
//           return item.role_name;
//         })
//         : null,
//       valueGetter: (params) =>
//         params.data &&
//           params.data.associated_role_names &&
//           params.data.associated_role_names.length > 0
//           ? params.data.associated_role_names.map((item, i) => {
//             return item.role_name;
//           })
//           : null,
//       filter: 'agSetColumnFilter',
//       filterParams: {
//         defaultToNothingSelected: true,
//       },
//     },
//     {
//       headerName: 'Last Updated By',
//       field: 'updated_by',
//       filter: 'agSetColumnFilter',

//       filterParams: {
//         defaultToNothingSelected: true,
//       },
//     },
//     {
//       headerName: 'Last Updated On',
//       field: 'updated_at',
//       filter: 'agDateColumnFilter',
//       filterParams: {
//         buttons: ['reset'],
//         inRangeInclusive: true,
//         comparator: DateComparator,
//       },

//     },
//     {
//       headerName: '# ID',
//       field: 'id',
//       filter: 'agNumberColumnFilter',

//       width: 90,
//       // lockPosition: true,
//       // suppressNavigable: true
//     },
//     {
//       headerName: 'Actions',
//       field: 'actions',
//       cellClass: 'cellClass actionColumn',
//       type: 'actionColumn',
//       filter: false,
//       sortable: false,
//       pinned: 'right',

//       width: 100,
//       lockPosition: true,
//       suppressNavigable: true,
//     },
//   ],
// };
