import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { vsmCommon } from '../../../config/messages';
import LocalGridConfig from '../../../config/LocalGridConfig';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const MergeLeadListing = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    MERGELEADSTORE,
    LEADSSTORE: { setMasterLeadToMerge },
  } = useStore();

  let gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        checkboxSelection: true,
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'lead_owner_name.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'lead_holder_name.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        field: 'people.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name.organisation_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Product')}`,
        field: 'product_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },

      {
        headerName: `${t('LeadListing.SOLD_Value')}`,
        field: 'finance_value',
        cellClass: 'cellClass',

        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return (
            params.data?.lead_currency_id?.currency_code +
            ' ' +
            params.data?.finance_value
          );
        },
        // cellRenderer: 'btnCellRenderer'
      },
      {
        headerName: `${t('Common_Data.SOLD_Stage')}`,
        field: 'lead_status_id.status_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Time_Spent')}`,
        field: 'investment_hours',
        cellClass: 'cellClass',

        filter: 'agTextColumnFilter',
        // cellRenderer: 'btnCellRenderer'
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        // valueGetter: (params) =>
        //   params.data && params.data.deadline_date ? params.data.deadline_date : null,
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? moment(params.data.deadline_date).format(AUTH.global_dateformat)
            : ' ';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        cellClass: 'cellClass',
        cellStyle: { textAlign: 'center', padding: '0' },

        width: 80,
        filter: 'agNumberColumnFilter',
        sortable: true,
        // cellRenderer: 'btnCellRenderer',
        // lockPosition: true,
        // suppressNavigable: true
      },
    ],
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: '70vh',
        }}
      >
        <AgGridReact
          rowData={props.selectedLeadsMerge}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          onGridReady={MERGELEADSTORE.setupGrid}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            width: 120,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{}}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: false,
            animateRows: true,
          }}
          rowSelection={'single'}
          suppressRowClickSelection={true}
          onRowSelected={(params) => {
            let selectedRows = MERGELEADSTORE.agGrid.api.getSelectedRows();
            if (selectedRows.length > 0) {
              setMasterLeadToMerge(selectedRows[0].id);
            }
            else {
              setMasterLeadToMerge(null);
            }
          }}
        />
      </div>
    </>
  );
});
export default MergeLeadListing;
