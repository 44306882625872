import { Button, Divider, Drawer, PageHeader, Popover, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import {
  capitalFirstLetter,
  hideTotalRecordsCountForReport,
  numberWithCommas,
  replaceUnderScoreWithSpace,
} from '../../../../utils/GlobalFunction';
import PageNotFound from '../../../PageNotFound';
import Unauthorized from '../../../Unauthorized';
// import PreviewDataTableElement from '../elements/PreviewDataTableElement';
import FormComponent from './NewFormComponent';
import NoRecordAlert from './NoRecordAlert';
import PreviewDataGridComponent from './PreviewDataGridComponent';
import PreviewDateRangeForm from './PreviewDateRangeForm';

import {
  EditOutlined,
  FileExcelOutlined,
  FilterOutlined,
  TableOutlined,
} from '@ant-design/icons';

import { generate_onReports } from '../../../../utils/GlobalFunction';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
// import { toJS } from 'mobx';

const PreviewDataComponent = observer((props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = useForm();
  const [saving, setSaving] = useState(true);
  const [tip, setTip] = useState('Loading');
  const [isDisabled, setDisabled] = useState(false);
  const {
    SavedReportStore,
    AUTH,
    PEOPLE,
    ORGANISATION,
    LEADSSTORE,
    SavedReportStore: { preview_report_criteria },
  } = useStore();
  const [formDataList, setFormDataList] = useState([]);
  const [PreviewReport, setPreviewReport] = useState(null);
  const [NoDataAlert, setNoDataAlert] = useState(false);
  let defaultFormDataList = SavedReportStore?.previewReportValues?.id
    ? ['report_type', 'global_toggle']
    : ['report_type', 'name', 'code', 'description', 'global_toggle'];
  const [isInitiated, setInitiation] = useState(false);
  const [editFilter, setEditFilter] = useState(false);
  const [saveFilterList, setsaveFilterList] = useState(false);

  const handlePreviewReport = useCallback(() => {
    setPreviewReport(null);
    setSaving(true);
    setTip('Loading');
    var data;

    if (editFilter) {
      data = SavedReportStore.formatFormData(
        {
          ...SavedReportStore.previewReportValues,
          ...form.getFieldsValue(),
          form_list: formDataList,
        },
        true,
        true
      );
    } else {
      data = SavedReportStore.formatFormData(
        SavedReportStore.previewReportValues,
        true,
        true
      );
    }
    data.name = data.name ? data.name : SavedReportStore.previewReportValues.name;
    data.code = data.code ? data.code : SavedReportStore.previewReportValues.code;
    data.description = data.description
      ? data.description
      : SavedReportStore.previewReportValues.description;
    data.action = 'preview-data';

    SavedReportStore.PreviewData(data)
      .then((data) => {
        setPreviewReport(data.data);
        if (data.data && Object.keys(data.data).length > 0) {
        } else {
          setNoDataAlert(true);
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        setTip(null);
      });
  }, [setSaving, SavedReportStore, form, formDataList, editFilter]);

  const InitaitePreviewReport = useCallback(
    async (isPreview = true, isSaved = false, isCancel = false) => {
      if (SavedReportStore.previewReportValues?.id) {
        await setFormDataList(
          SavedReportStore.previewReportValues.form_list
            ? SavedReportStore.previewReportValues.form_list
            : []
        );
      } else {
        let updatedFormList = SavedReportStore.previewReportValues.form_list || [];
        updatedFormList = [...updatedFormList, 'name', 'code', 'description'];
        await setFormDataList(updatedFormList);
      }
      let previewReportValues = SavedReportStore.formatFormData(
        SavedReportStore.previewReportValues,
        false
      );

      // Cancel Mode Fetch Original Data
      if (isCancel) {
        previewReportValues = SavedReportStore.previewReportValues;
      }

      if (previewReportValues['organisation']) {
        ORGANISATION.getAllROrg({
          selected_id: previewReportValues['organisation'],
          mapping_type: 1,
        });
      }
      if (previewReportValues['people']) {
        PEOPLE.getAllPeople({
          selected_id: previewReportValues['people'],
          mapping_type: 2,
        });
      }
      if (previewReportValues['lead_currency_id'] && !LEADSSTORE.dropdown_currency_list) {
        await LEADSSTORE.getCurrencyList();
      }
      if (previewReportValues.report_type === 'investment_hours') {
        let formdata = {
          inactive: true,
        };
        LEADSSTORE.getReportUsersList(formdata);
      } else {
        LEADSSTORE.getReportUsersList();
      }

      await form.setFieldsValue(previewReportValues);
      if (isPreview || isCancel) {
        handlePreviewReport();
      }
    },
    [
      setFormDataList,
      form,
      handlePreviewReport,
      SavedReportStore,
      ORGANISATION,
      PEOPLE,
      LEADSSTORE,
    ]
  );

  useEffect(() => {
    if (!isInitiated && props.visible) {
      if (props.report_id && !props.previewFromEditDrawer && !props.previewFromClone) {
        setInitiation(true);
        SavedReportStore.ReadReport(props.report_id)
          .then((data) => {
            InitaitePreviewReport();
            setSaving(false);
            setTip(null);
          })
          .catch(({ response }) => {
            if (response && response.status === 404) {
              setSaving('404');
            }
            if (response && response.status === 403) {
              setSaving('403');
            }
          });
      } else if (SavedReportStore.previewReportValues) {
        setInitiation(true);
        InitaitePreviewReport();
        setSaving(false);
        setTip(null);
      }
    }
  }, [
    InitaitePreviewReport,
    SavedReportStore,
    isInitiated,
    props.report_id,
    props.previewFromEditDrawer,
    props.previewFromClone,
    props.visible,
    history,
  ]);

  const handleSubmit = (data) => {
    setSaving(true);
    setTip('Saving');

    if (editFilter) {
      data = SavedReportStore.formatFormData({
        ...SavedReportStore.previewReportValues,
        ...form.getFieldsValue(),
        form_list: formDataList,
      });
    } else {
      data = SavedReportStore.formatFormData(SavedReportStore.previewReportValues);
    }
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.name = SavedReportStore.previewReportValues.name;
    data.code = SavedReportStore.previewReportValues.code;
    data.description = SavedReportStore.previewReportValues.description;
    data.action = 'save_report';
    data.form_list = formDataList;
    if (typeof data.lead_type === 'number') {
      data.lead_type = [data.lead_type];
    }
    if (typeof data.record_type === 'number') {
      data.record_type = [data.record_type];
    }
    if (SavedReportStore.previewReportValues.id) {
      data.id = SavedReportStore.previewReportValues.id;

      //-------- START: On "save and preview" -> clear column settings--------//
      if (
        ['investment_hours', 'performance'].includes(
          SavedReportStore.previewReportValues.report_type
        )
      ) {
        data.column_settings = null;
      } else {
        if (this.preview_report_grid) {
          data.column_settings = JSON.stringify(
            SavedReportStore.preview_report_grid.columnApi.getColumnState()
          );
        }
      }
      //-------- END:On "save and preview" -> clear column settings--------//

      SavedReportStore.EditData(data)
        .then((data) => {
          vsmNotify.success({
            message: vsmTransactionReport.edit,
          });
          close(false);
          if (SavedReportStore.my_records === 'my') {
            SavedReportStore.getList({}, AUTH.user.id);
          } else {
            SavedReportStore.getList();
          }
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(e.errors);
          }
        })
        .finally(() => {
          setSaving(false);
          setTip(null);
          setEditFilter(false);
        });
    } else {
      SavedReportStore.AddData(data)
        .then((res) => {
          vsmNotify.success({
            message: vsmTransactionReport.save,
          });
          if (res.report) {
            SavedReportStore.created_by_id = res.report.created_by;
            SavedReportStore.previewReportValues = {
              ...JSON.parse(res.report.generated_on_options),
              id: res.report.id,
              name: res.report.name,
              code: res.report.code,
              description: res.report.description,
            };
          }
          close(false);
          if (SavedReportStore.my_records === 'my') {
            SavedReportStore.getList({}, AUTH.user.id);
          } else {
            SavedReportStore.getList();
          }
          setEditFilter(false);
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(e.errors);
          }
        })
        .finally(() => {
          setSaving(false);
          setTip(null);
        });
    }
  };

  const exportReport = (type) => {
    if (SavedReportStore.previewReportValues.report_type === 'investment_hours') {
      // gridOptions.api.exportDataAsExcel();
      SavedReportStore.exportInvestmentHourReport(
        SavedReportStore.previewReportValues.name
      );
    } else if (SavedReportStore.previewReportValues.report_type === 'performance') {
      SavedReportStore.exportPerformanceReport(SavedReportStore.previewReportValues.name);
    } else {
      if (SavedReportStore.previewFilterForExport) {
        setSaving(true);
        setTip('Exporting...');
        SavedReportStore.exportDataFromServer(SavedReportStore.previewFilterForExport)
          .catch((e) => {
            if (e.errors) {
              form.setFields(e.errors);
            }
          })
          .finally(() => {
            setSaving(false);
            setTip(null);
          });
      } else {
        setSaving(true);
        setTip('Exporting...');
        var data = null;
        if (editFilter) {
          data = SavedReportStore.formatFormData({
            ...SavedReportStore.previewReportValues,
            ...form.getFieldsValue(),
            form_list: formDataList,
          });
        } else {
          data = SavedReportStore.formatFormData(SavedReportStore.previewReportValues);
        }
        data.export = type;
        if (typeof data.lead_type === 'number') {
          data.lead_type = [data.lead_type];
        }
        if (typeof data.record_type === 'number') {
          data.record_type = [data.record_type];
        }

        /*START: Column Setting pass to export payload*/
        if (SavedReportStore.reportColumnSettings) {
          data.column_settings = SavedReportStore.reportColumnSettings;
        }
        /*END: Column Setting pass to export payload*/

        SavedReportStore.exportDataFromServer(data)
          .catch((e) => {
            if (e.errors) {
              form.setFields(e.errors);
            }
          })
          .finally(() => {
            setSaving(false);
            setTip(null);
          });
      }
    }
  };

  const close = (clear = true) => {
    if (SavedReportStore.previewFilterForExport) {
      SavedReportStore.setPreviewFilterForExport(null);
    }
    if (SavedReportStore.reportColumnSettings) {
      SavedReportStore.setReportColumnSettings(null);
    }
    if (clear === false) {
      SavedReportStore.ReadReport(
        props?.report_id ? props.report_id : SavedReportStore.previewReportValues?.id
      )
        .then((data) => {
          InitaitePreviewReport(true, true);
          setSaving(false);
          setTip(null);
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            setSaving('404');
          }
          if (response && response.status === 403) {
            setSaving('403');
          }
        });
    } else {
      props.close(
        (!props.addModal && !props.editModal && !props.cloneModal) ||
        clear === 'close_all'
      );
      if (editFilter) {
        SavedReportStore.setPreviewReportValues({
          ...SavedReportStore.previewReportValues,
          ...form.getFieldsValue(),
          form_list: formDataList,
        });
      }
      if (clear && !props.addModal && !props.editModal && !props.cloneModal) {
        props.setReportID(null);
        SavedReportStore.previewReportValues = null;
        SavedReportStore.preview_report_criteria = null;
      }
      setInitiation(false);
      setPreviewReport(null);
      setSaving(true);
      setTip('Loading');
    }
  };

  const saveGridColumnStates = () => {
    let payload = null;
    if (SavedReportStore.previewReportValues.report_type) {
      if (SavedReportStore.previewReportValues.report_type === 'investment_hours') {
        payload = {
          column_settings: JSON.stringify(
            SavedReportStore.AuditGrid.columnApi.getColumnState()
          ),
        };
      } else if (SavedReportStore.previewReportValues.report_type === 'performance') {
        payload = {
          column_settings: JSON.stringify(
            SavedReportStore.agGridPerformanceReport.columnApi.getColumnState()
          ),
        };
      } else {
        payload = {
          column_settings: JSON.stringify(
            SavedReportStore.preview_report_grid.columnApi.getColumnState()
          ),
        };
      }
      if (payload) {
        SavedReportStore.saveGridColumnState(
          SavedReportStore.previewReportValues.id,
          payload
        ).then((data) => {
          vsmNotify.success({
            message: `${t('Reports.Column_Setting_Saved')}!`,
          });
        });
      }
    }
  };

  const removeGridColumnStates = () => {
    let payload = null;
    let gridTypeToReset = null;

    if (SavedReportStore.previewReportValues.report_type === 'investment_hours') {
      gridTypeToReset = 2;
    } else if (SavedReportStore.previewReportValues.report_type === 'performance') {
      gridTypeToReset = 3;
    } else {
      gridTypeToReset = 1;
    }
    payload = {
      column_settings: null,
    };
    SavedReportStore.saveGridColumnState(
      SavedReportStore.previewReportValues.id,
      payload
    ).then((data) => {
      if (gridTypeToReset) {
        if (gridTypeToReset === 1) {
          SavedReportStore.handleResetGrid();
        }
        if (gridTypeToReset === 2) {
          SavedReportStore.handleResetInvestmentGrid();
        }
        if (gridTypeToReset === 3) {
          SavedReportStore.handleResetPerformanceGrid();
        }
      }
      vsmNotify.success({
        message: `${t('Reports.Column_Setting_Reset')}!`,
      });
    });
  };

  const cancelEditMode = () => {
    setEditFilter(false);
    SavedReportStore.setPreviewFilterForExport(null);
    let currentFormData = form.getFieldsValue();
    form.resetFields();
    form.setFieldsValue({
      report_type: currentFormData.report_type,
    });
    InitaitePreviewReport(false, false, true);
  };

  const recordCountText = () => {
    let count = 0;
    if (PreviewReport?.length) {
      count = PreviewReport.length;
      count = numberWithCommas(count);
    }
    return count;
  };

  const content = (
    <ul className='popover-menu' style={{ maxWidth: '450px' }}>
      {preview_report_criteria &&
        preview_report_criteria.map((item, index) => {
          return ![
            'column_settings',
            'investment_hours',
            'global_toggle',
            'tagholder',
            'tag_status_name',
            'owner',
            'organisation',
            'people',
            'classification_id',
            'json_load_investment_hour',
            'ticket_id',
            'user_group_list',
            'json_related_items_data',
          ].includes(item.name) ? (
            <li>
              <strong>
                {replaceUnderScoreWithSpace(capitalFirstLetter(item.name))} :
              </strong>{' '}
              {typeof item.value === 'object'
                ? Object.values(item.value).join()
                : Array.isArray(item.value)
                  ? item.value.join()
                  : replaceUnderScoreWithSpace(
                    typeof item.value === 'boolean'
                      ? item.value
                        ? 'Yes'
                        : 'No'
                      : item.value
                  )}
            </li>
          ) : null;
        })}
    </ul>
  );

  const displayTitle = () => {
    return (
      <>
        {SavedReportStore.previewReportValues
          ? hideTotalRecordsCountForReport.includes(
            SavedReportStore.previewReportValues.report_type
          )
            ? `${SavedReportStore.previewReportValues.name}`
            : `${SavedReportStore.previewReportValues.name
            }  (${recordCountText()} records)`
          : 'Preview Data'}{' '}
        {preview_report_criteria && (
          <Popover
            content={content}
            trigger={'click'}
            placement='rightBottom'
            overlayClassName='drawerPopover profileMenu'
          >
            <Button key='1' shape='round' size='medium'>
              <FilterOutlined />
            </Button>
          </Popover>
        )}
      </>
    );
  };

  return (
    <Drawer
      visible={props.visible}
      onClose={() => close()}
      placement='right'
      width={'90vw'}
      title={displayTitle()}
      destroyOnClose={true}
      extra={[
        SavedReportStore.previewReportValues && (
          <div className='ant-page-header p-0'>
            {SavedReportStore.previewReportValues.id && (
              <>
                <Button
                  onClick={() => saveGridColumnStates()}
                  key='1'
                  shape='round'
                  title={`${t('Common_Data.Save')} ${t('Reports.Column')} ${t('Reports.Settings')}`}
                  className='mr-10'
                  disabled={saving === true}
                >
                  <TableOutlined />
                </Button>
                <Button
                  onClick={() => removeGridColumnStates()}
                  key='2'
                  shape='round'
                  title={`${t('Common_Data.Reset')} ${t('Reports.Column')} ${t('Reports.Settings')}`}
                  className='mr-10'
                  disabled={saving === true}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
              </>
            )}

            {(props.previewFromClone
              ? true
              : AUTH.user.id === SavedReportStore.created_by_id ||
              AUTH.user.id === 69 ||
              AUTH.user?.user_role?.user_role_id === 1 ||
              !SavedReportStore.created_by_id) &&
              ((SavedReportStore.previewReportValues.id &&
                AUTH.checkPermission(21, 'edit')) ||
                (!SavedReportStore.previewReportValues.id &&
                  AUTH.checkPermission(21, 'add'))) && (
                <Button
                  disabled={saving === true}
                  key='3'
                  onClick={() => {
                    if (
                      SavedReportStore.previewReportValues.id &&
                      SavedReportStore.previewFilterForExport
                    ) {
                      SavedReportStore.setPreviewFilterForExport(null);
                      SavedReportStore.ReadReport(SavedReportStore.previewReportValues.id)
                        .then((data) => {
                          InitaitePreviewReport();
                          setSaving(false);
                          setTip(null);
                        })
                        .catch(({ response }) => {
                          if (response && response.status === 404) {
                            setSaving('404');
                          }
                          if (response && response.status === 403) {
                            setSaving('403');
                          }
                        });
                    }
                    setEditFilter(true);
                  }}
                  shape='round'
                  title={`${t('Common_Data.Edit')} ${t('Reports.Report')}`}
                  className='mr-10'
                >
                  <EditOutlined />
                </Button>
              )}

            {SavedReportStore.previewReportValues.id && AUTH.checkPermission(21, 'edit') && (
              <Button
                key='4'
                className='mr-10'
                shape='round'
                disabled={saving === true}
                title={`${t('Common_Data.Clone')} ${t('Reports.Report')}`}
                onClick={() => {
                  let rid = SavedReportStore.previewReportValues?.id;
                  close();
                  props.openCloneModal(rid);
                }}
              >
                <img className='menuicon ' alt='' src={AUTH.GetThemedImage('Clone_Record')} />
              </Button>
            )}

            {AUTH.checkPermission(21, 'preview-data-export') &&
              SavedReportStore.previewReportValues.id && (
                <Button
                  disabled={saving === true}
                  onClick={() => exportReport('csv')}
                  key='5'
                  shape='round'
                  title='Download Report'
                >
                  <FileExcelOutlined />
                </Button>
              )}
          </div>
        ),
      ]}
    >
      <Spin size='large' className='fullHeight' spinning={saving === true} tip={tip}>
        {saving === '404' ? (
          <PageNotFound />
        ) : saving === '403' ? (
          <Unauthorized />
        ) : (
          <div className='w-100' style={{ minHeight: '200px' }}>
            {SavedReportStore.previewReportValues ? (
              <>
                <PageHeader>
                  {!editFilter &&
                    generate_onReports.includes(
                      SavedReportStore.previewReportValues.report_type
                    ) ? (
                    <PreviewDateRangeForm
                      formDataList={formDataList}
                      handleSubmit={handleSubmit}
                      setFormDataList={setFormDataList}
                      defaultFormDataList={defaultFormDataList}
                      setTip={setTip}
                      setSaving={setSaving}
                      setNoDataAlert={setNoDataAlert}
                      setPreviewReport={setPreviewReport}
                    />
                  ) : null}

                  {editFilter ? (
                    <>
                      <FormComponent
                        initialValues={SavedReportStore.initialValues}
                        defaultFormDataList={defaultFormDataList}
                        form={form}
                        formDataList={formDataList}
                        setFormDataList={setFormDataList}
                        handleSubmit={handleSubmit}
                        setDisabled={setDisabled}
                        formButtons={
                          <div className='text-right'>
                            {AUTH.checkPermission(21, 'preview-data') && (
                              <Button
                                key='1'
                                className='mr-10'
                                htmlType='button'
                                shape='round'
                                disabled={isDisabled}
                                onClick={() => {
                                  handlePreviewReport();
                                }}
                              >
                                {t('Reports.Preview')} {t('Reports.Data')}
                              </Button>
                            )}
                            {AUTH.checkPermission(21, 'add') && (
                              <Button
                                key='2'
                                className='mr-10'
                                htmlType='submit'
                                type='primary'
                                shape='round'
                                disabled={isDisabled}
                              >
                                {t('Common_Data.Save')} & {t('Reports.Preview')}
                              </Button>
                            )}
                            {AUTH.checkPermission(21, 'add') && (
                              <Button
                                key='3'
                                className='mr-10'
                                shape='round'
                                onClick={() => {
                                  cancelEditMode();
                                }}
                                disabled={isDisabled}
                              >
                                {t('Common_Data.Cancel')}
                              </Button>
                            )}
                          </div>
                        }
                        setsaveFilterList={setsaveFilterList}
                        saveFilterList={saveFilterList}
                      />
                      <Divider className='pb-5 pt-5' />
                    </>
                  ) : null}
                  <div className='mt-0' style={{ minHeight: '100px' }}>
                    <PreviewDataGridComponent
                      rowData={PreviewReport}
                      form={form}
                      editFilter={editFilter}
                    />
                  </div>
                  {NoDataAlert && (
                    <NoRecordAlert
                      visible={NoDataAlert}
                      setNoDataAlert={setNoDataAlert}
                    />
                  )}
                </PageHeader>
              </>
            ) : null}
          </div>
        )}
      </Spin>
    </Drawer>
  );
});

export default PreviewDataComponent;
