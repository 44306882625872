import React, { useState } from 'react';
import { Popover, Checkbox, Button, Tooltip } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmProductivity } from '../../../config/messages';
import { observer } from 'mobx-react';
import ReassignConfirmComponent from './ReassignConfirmComponent';
import DeleteConfirmComponent from './DeleteConfirmComponent';
import DateTimeConfirmComponent from '../../Tickets/component/DateTimeConfirmComponent';
import HourglassConfirmComponent from '../../Tickets/component/HourglassConfirmComponent';
import TagDateTimeConfirmComponent from '../../Tags/component/DateTimeConfirmComponent';
import TagHourglassConfirmComponent from '../../Tags/component/HourglassConfirmComponent';
import { useHistory } from 'react-router-dom';
import { SwapOutlined } from '@ant-design/icons';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';

export const ActionRenderer = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const {
    AUTH,
    REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [openTagDateTimeModal, setOpenTagDateTimeModal] = useState(false);
  const [openTagHourglassModal, setOpenTagHourglassModal] = useState(false);
  const [reminderConfirm, setReminderConfirm] = useState();
  const [hourglassConfirm, setHourglassConfirm] = useState();
  const [date, setDate] = useState();
  const [extrafooter, setExtrafooter] = useState(null);

  //Confirm Modal
  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(data);
      if (data.type === 'ticket') {
        setOpenDateTimeModal(true);
      } else {
        setOpenTagDateTimeModal(true);
      }
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  const closeTagDateTimeModal = () => setOpenTagDateTimeModal(false);

  const openConfirmHourglassModal = (data) => {
    setHourglassConfirm(data);
    if (data.type === 'ticket') {
      setOpenHourglassModal(true);
    } else {
      setOpenTagHourglassModal(true);
    }
  };
  const closeHourglassModal = () => setOpenHourglassModal(false);

  const closeTagHourglassModal = () => setOpenTagHourglassModal(false);

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // }

  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  let approvedTagNoteDesc = props?.data?.tag_content?.includes('<approved');
  let approvedTag = false;
  if (approvedTagNoteDesc) {
    approvedTag = true;
  }

  return (
    <>
      <div className='action-column promotionAction productivityAction'>
        {
          (AUTH.checkPermission(3, 'reassign-productivity') ||
            AUTH.checkPermission(3, 'delete-productivity')) &&
            props?.data?.type.toLowerCase() === 'tag' &&
            ([5, 3].includes(props?.data?.status) || approvedTag) && (
            <Checkbox disabled={true}></Checkbox>
            )
          // ) : <Checkbox disabled={true}></Checkbox>
        }

        {props?.data?.type.toLowerCase() === 'tag' && (
          <Button type='text' title={`${t('Block_Edit_Assign.Deal_Desk')}`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('Deal_icn')}
              onClick={() => {
                history.push(`/dealdesk/${props.data.leads_transactions_id}`);
              }}
            />
          </Button>
        )}
        {props?.data?.type.toLowerCase() === 'ticket' &&
          AUTH.checkPermission(15, 'ticket-desk') && (
            <Button type='text' title={`${t('Productivity.Ticket')} ${t('Common_Data.Desk')}`}>
              <img
                className='menuicon'
                alt=''
                src={AUTH.GetThemedImage('Tickets')}
                onClick={() => {
                  history.push(`/ticket/${props.data.ticket_number}`);
                }}
              />
            </Button>
          )}

        {AUTH.checkPermission(3, 'reset-reminder-date-time') && (
          // (AUTH?.user?.id === props?.data?.owner?.id)            ? 
          <span
            className='Calendar'
            onMouseEnter={() => {
              if (props.data?.reset_count !== null) {
                getReminderDatepickerExtraFooter(
                  props.data,
                  AUTH.global_fulldate_format,
                  { type: props.data.type === 'ticket' ? 'tickets' : 'tags' }
                ).then((data) => {
                  setExtrafooter(data);
                });
              }
            }}
          >
            <Tooltip
              title={props.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`}
            >
              <DatePickerCostume
                name='Calendar'
                title={`${t('Common_Data.Calendar')}`}
                className='cursor'
                showTime
                disabledDate={true}
                // disabledHours={() => disabledHours()}
                // value={moment(AUTH.getLocalTimeDealDesk(new Date()))}
                // minuteStep={15}
                // hideDisabledOptions
                showNow={false}
                onOk={(e) => openConfirmDateTimeModal(e, props.data)}
                renderExtraFooter={() => extrafooter}
              />
            </Tooltip>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Calendar')} />
          </span>
          // : <>
          //   <Tooltip
          //     placement='topRight'
          //     color={'red'}
          //     title={`${t('Tickets.Reminder_Disable')}`}
          //   >
          //     <img className='menuicon' alt='' src={AUTH.GetThemedImage('Calendar')} />
          //   </Tooltip>
          // </>
        )}
        {AUTH.checkPermission(3, 'reset-reminder-time') && (
          // (AUTH?.user?.id === props?.data?.owner?.id)             ? <>
          <img
            className='menuicon'
            title={`${t('Common_Data.Hour_Glass')}`}
            alt={`${t('Common_Data.Hour_Glass')}`}
            src={AUTH.GetThemedImage('Reminders_icn')}
            onClick={() => openConfirmHourglassModal(props.data)}
          />
          // </>
          // : (<>
          //   <Tooltip
          //     placement='topRight'
          //     color={'red'}
          //     title={`${t('Tickets.Hour_Glass_Disable')}`}
          //   ><img
          //       className='menuicon'
          //       title={`${t('Common_Data.Hour_Glass')}`}
          //       alt={`${t('Common_Data.Hour_Glass')}`}
          //       src={AUTH.GetThemedImage('Reminders_icn')}
          //     />
          //   </Tooltip></>)
        )}
        {(AUTH.checkPermission(3, 'reset-reminder-date-time') ||
          AUTH.checkPermission(3, 'reset-reminder-time')) && (
            <span>{props.data?.reset_count ? props?.data?.reset_count : 0}</span>
          )}
      </div>
      <DateTimeConfirmComponent
        visible={openDateTimeModal}
        close={closeDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <HourglassConfirmComponent
        visible={openHourglassModal}
        close={closeHourglassModal}
        hourglassConfirm={hourglassConfirm}
      />
      <TagDateTimeConfirmComponent
        visible={openTagDateTimeModal}
        close={closeTagDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <TagHourglassConfirmComponent
        visible={openTagHourglassModal}
        close={closeTagHourglassModal}
        hourglassConfirm={hourglassConfirm}
      />
    </>
  );
};

export const TypeRenderer = (props) => {
  const { t } = useTranslation();
  const { AUTH } = useStore();

  return (
    <div className='action-column TypeRenderer'>
      {props?.data?.type.toLowerCase() === 'ticket' && (
        <img
          className='headerIcon'
          src={AUTH.GetThemedImage('Tickets')}
          alt={`${t('Common_Data.Tickets')}`}
          title={`${t('Common_Data.Tickets')}`}
        />
      )}

      {props?.data?.type.toLowerCase() === 'tag' && (
        <img
          className='headerIcon'
          src={AUTH.GetThemedImage('Tags')}
          alt={`${t('Common_Data.Tags')}`}
          title={`${t('Common_Data.Tags')}`}
        />
      )}
      {props?.data?.tag_ticket_number}
    </div>
  );
};

export const ActionHeaderComponent = observer((props) => {
  const { t } = useTranslation()
  const [reAssignUser, setReAssignUser] = useState(null);
  const [assignTempData, setAssignTempData] = useState(null);
  const [assignVisible, setAssignVisible] = useState(false);

  const [deleteTempData, setDeleteTempData] = useState(null);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const { checkAllowEditTag } = props.agGridReact.props.frameworkComponents;

  const {
    AUTH,
    PRODUCTIVITYSTORE: { agGrid, selectedMasterTicket },
    TicketsStore: { getUsersList },
  } = useStore();

  const userList = (
    <div className='ticketsPopoverIcons username'>
      <ul>
        {reAssignUser &&
          reAssignUser.map(function (item) {
            return (
              <li key={item.id} onClick={() => bulkAssign(item.id, item.name)}>
                {item.name}
              </li>
            );
          })}
      </ul>
    </div>
  );

  const onchangeCheckbox = (e) => {
    if (e.target.checked) {
      agGrid.api.forEachNode(function (node) {
        if (node?.data?.type.toLowerCase() === 'tag') {
          node.setSelected(checkAllowEditTag(node?.data));
        } else {
          node.setSelected(true);
        }
      });
    } else {
      agGrid.api.deselectAll();
    }
  };

  const bulkAssign = (userid, username) => {
    let selected = agGrid.api.getSelectedRows();
    if (selected.length > 0) {
      var tagticketArr = [];
      selected.forEach((item) => {
        var obj = {};
        obj.type = item.type;
        obj.user_id = userid;
        obj.id = item.id;
        tagticketArr.push(obj);
      });
      setAssignTempData({
        reassignPayload: {
          assign_array: tagticketArr,
        },
        username: username,
      });
      setAssignVisible(true);
    } else {
      vsmNotify.error({ message: vsmProductivity.errorNoRowSelection });
    }
  };
  const closeAssignModal = () => {
    setAssignTempData(null);
    setAssignVisible(false);
  };

  const bulkDelete = () => {
    if (selectedMasterTicket && selectedMasterTicket.length > 0) {
      vsmNotify.error({
        message: `${selectedMasterTicket.length} ${t('Productivity.Master_Ticket_Remove')}.`,
      });
    } else {
      let selected = agGrid.api.getSelectedRows();

      if (selected.length > 0) {
        var tagticketdeleteArr = [];
        selected.forEach((item) => {
          var obj = {};
          obj.type = item.type;
          obj.id = item.id;
          tagticketdeleteArr.push(obj);
        });

        setDeleteTempData({
          deletePayload: {
            remove_array: tagticketdeleteArr,
          },
        });
        setDeleteVisible(true);
      } else {
        vsmNotify.error({ message: vsmProductivity.errorNoRowSelection });
      }
    }
  };

  const closeDeleteModal = () => {
    setDeleteTempData(null);
    setDeleteVisible(false);
  };

  return (
    <div className='headerAction'>
      {(AUTH.checkPermission(3, 'reassign-productivity') ||
        AUTH.checkPermission(3, 'delete-productivity')) && (
          <Checkbox onChange={onchangeCheckbox} className='headerCheckbox'></Checkbox>
        )}

      {AUTH.checkPermission(3, 'reassign-productivity') && (
        <span>
          <Popover
            content={userList}
            placement='bottomRight'
            trigger='hover'
            overlayClassName='profileMenu'
          >
            <img
              className='headerIcon'
              src={AUTH.GetThemedImage('Reassign')}
              alt={`${t('Productivity.Re_Assign')}`}
              title={`${t('Common_Data.Reassign')}`}
              onMouseEnter={() => {
                if (!reAssignUser) {
                  getUsersList().then((data) => {
                    setReAssignUser(data.data);
                  });
                }
              }}
            />
          </Popover>
        </span>
      )}

      {AUTH.checkPermission(3, 'delete-productivity') && (
        <span>
          <img
            className='headerIcon'
            src={AUTH.GetThemedImage('Waste')}
            alt={`${t('Common_Data.Delete')}`}
            title={`${t('Common_Data.Delete')}`}
            onClick={() => bulkDelete()}
          />
        </span>
      )}

      <ReassignConfirmComponent
        visible={assignVisible}
        close={closeAssignModal}
        assignTempData={assignTempData}
      />
      <DeleteConfirmComponent
        visible={deleteVisible}
        close={closeDeleteModal}
        deleteTempData={deleteTempData}
      />
    </div>
  );
});

export const HeaderTypeComponent = observer((props) => {
  return (
    <div className='text-center'>
      <SwapOutlined rotate='90' className='mr-5' />
    </div>
  );
});
