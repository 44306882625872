import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Drawer, Col, Spin, Row, Card, Input } from 'antd';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { vsmRoleMapping, vsmOrganisations, vsmNotify } from '../../../../config/messages';
import useStore from '../../../../store';
import OrgDetailComponent from './OrgDetailComponent';
import InputComponent from '../../../../component/InputComponent';
import PplDetailComponent from '../../../PeopleManagement/RoleMapping/component/PplDetailComponent';
import RatingElement from '../../AllOrganisations/elements/RatingElement';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [currentTab, setCurrentTab] = useState('Organisation');
  const [searchROrgKey, setSearchROrgKey] = useState();
  const [fetchRoles, setFetchRoles] = useState(true);
  const [fetchRelatedRoles, setFetchRetaldRoles] = useState(true);
  const [fetchTags, setFetchTags] = useState(true);
  const [fetchCurrency, setFetchCurrency] = useState(true);
  //const [organisationValues, setOrganisationValues] = useState();
  const [rOrganisationValues, setROrganisationValues] = useState();
  const [loading, setLoading] = useState(true);
  const [updateState, setUpdateState] = useState(true);
  const [ratingHide, setRatingHide] = useState(true);
  const {
    ORGANISATION,
    ORGBANKACCOUNTSTORE,
    PEOPLE,
    ORGMAPPINGSTORE,
    AUTH: { user },
    ORGMAPPINGSTORE: { getAllTagsList, EditData, editValues, dropdown_tags_list }
  } = useStore();

  // make a fuction to call to edit record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = editValues.id;
    data.organisation_id = props.parent_id;
    data.entity_id = editValues.entity_id;
    data.entity_type = 1;
    data.is_rating = ratingHide ? 0 : 1;
    if (form.getFieldValue('related_entity_type') === 'Ledger') {
      data.related_entity_id = null;
      data.related_role_id = null;
    }
    if (data.rating_table && data.rating_table.length > 0) {
      data.rating_table = data.rating_table.filter(
        (x) => x && x && x.global_exchange_rating_id
      );
      data.rating_table =
        !ratingHide && data.rating_table.length > 0 ? data.rating_table : null;
    }
    EditData(data)
      .then(() => {
        close();
        if (props.handleViewDetailCall) {
          props.handleViewDetailCall({ id: editValues.entity_id });
        }
        vsmNotify.success({
          message: vsmRoleMapping.edit
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // set data on refresh page
  useEffect(() => {
    if (props.visible) {
      setLoading(true);
      var payload = {
        organisation_id: props.parent_id && parseInt(props.parent_id),
        mapping_type: 1
      };
      ORGANISATION.getRatingToMap();
      ORGMAPPINGSTORE.getRecordById(props.mappingId, payload)
        .then((data) => {
          setLoading(false);
        })
        .catch((data) => {
          vsmNotify.error({ message: vsmRoleMapping.InvalidRecord });
          setLoading(false);
        });
    }
  }, [props, ORGANISATION, ORGMAPPINGSTORE]);

  // set the form values to add
  useEffect(() => {
    if (editValues && props.visible) {
      //setOrganisationValues(editValues.organisation);
      if (editValues.related_organisation) {
        setROrganisationValues(editValues.related_organisation);
      }
      if (editValues.related_people) {
        PEOPLE.rPeopleCardValue = editValues.related_people;
      }
      ORGBANKACCOUNTSTORE.dropdown_currency_list = editValues.global_currency && [
        editValues.global_currency
      ];
      setRatingHide(editValues.is_rating === 1 ? false : true);
      form.setFieldsValue({
        entity_id: editValues.entity_id,
        role_id: editValues.role_id,
        related_role_id: editValues.related_role_id,
        related_entity_type: editValues.related_entity_type,
        related_entity_id: editValues.related_entity_id,
        related_people_id: editValues.related_people_id,
        exchange_ref_id: editValues.exchange_ref_id
          ? editValues.exchange_ref_id.toString()
          : null,
        tag_ids:
          editValues.tag_ids && editValues.tag_ids.length > 0
            ? editValues.tag_ids
            : undefined,
        supplier_insurance_no:
          editValues.supplier_insurance_no && editValues.supplier_insurance_no.toString(),
        agreed_early_payment_discount:
          editValues.agreed_early_payment_discount &&
          editValues.agreed_early_payment_discount.toString(),
        currency_id: editValues.currency_id,
        crm_id: editValues.crm_id ? editValues.crm_id.toString() : null,
        rating_table: editValues.rating_table,
        additional_description: editValues.additional_description
      });
      setCurrentTab(editValues.related_entity_type);
    }
  }, [editValues, ORGBANKACCOUNTSTORE, ORGANISATION, PEOPLE, form, props.visible]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = async () => {
    setUpdateState(!updateState);
    if (currentTab !== form.getFieldValue('related_entity_type')) {
      await form.resetFields([
        'related_people_id',
        'related_role_id',
        'related_entity_id'
      ]);
      ORGANISATION.rOrganisationValues = null;
      PEOPLE.rPeopleCardValue = null;
      setSearchROrgKey(null);
      setFetchRetaldRoles(true);
      setCurrentTab(form.getFieldValue('related_entity_type'));
    }
    if (form.getFieldValue('role_id')) {
      var rating =
        ORGANISATION.dropdown_orgRole_list &&
        ORGANISATION.dropdown_orgRole_list.find(
          (x) => x.role_name.toLowerCase() === 'rating agency'
        );
      if (rating && form.getFieldValue('role_id') === rating.id) {
        setRatingHide(false);
      } else {
        setRatingHide(true);
      }
    }
    validateData();
  };

  // call to validate fields
  const validateData = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // search string for dropdown
  const handleROrgSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchROrgKey(val);
      ORGANISATION.getAllROrg({ search_for: val, related_entity_type: 1 });
    };

    return debounce(loadOptions, 500);
  }, [ORGANISATION]);

  // get related organisation data
  const handleROrgChange = (data) => {
    if (data) {
      ORGANISATION.getOrgCardValue(data, 'relatedOrg').then((data) => {
        setROrganisationValues(data);
      });
    } else {
      setROrganisationValues(null);
    }
  };

  // search string for people dropdown
  const handleRPplSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchROrgKey(val);
      PEOPLE.getPeopleSearchList({
        search_for: val,
        related_entity_type: 2
      });
    };

    return debounce(loadOptions, 500);
  }, [PEOPLE]);

  // set related people data in card
  const handlePeopleChange = (data) => {
    if (data) {
      PEOPLE.getPeopleCardValue(data, 'people');
    } else {
      PEOPLE.rPeopleCardValue = null;
    }
  };

  const handleRoleChange = () => {
    if (
      form.getFieldValue('role_id') &&
      form.getFieldValue('related_entity_type') === 'Organisation'
    ) {
      ORGANISATION.getOrgRelatedRoles({
        role_id: form.getFieldValue('role_id')
      }).then(() => setFetchRetaldRoles(false));
      form.resetFields(['related_role_id']);
    }
  };

  // reset form and close edit form
  const close = () => {
    props.close();
    props.setId(null);
    form.resetFields();
    setDisabled(true);
    setCurrentTab('Organisation');
    ORGANISATION.rOrganisationValues = null;
    ORGANISATION.dropdown_ROrg_list = null;
    ORGMAPPINGSTORE.editValues = null;
    setFetchRoles(true);
    setFetchTags(true);
    setFetchCurrency(true);
    ORGMAPPINGSTORE.dropdown_tags_list = null;
    PEOPLE.rPeopleCardValue = null;
  };

  return editValues ? (
    <Drawer
      destroyOnClose={true}
      title={`${t('Common_Data.Edit')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Mapped')} ${t('OrganisationManagement.Roles')}`}
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      footer={[
        <div key='1' className='text-right'>
          <Button
            key='1'
            disabled={disabled}
            form='editform'
            loading={saving}
            htmlType='submit'
            type='primary'
          >
            {t('Common_Data.Update')}
          </Button>
          <Button key='2' className='ml-10' htmlType='button' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>
        </div>
      ]}
    >
      <Spin size='large' spinning={loading} tip={`${t('Common_Data.Load')}`}>
        <Form
          form={form}
          id='editform'
          onChange={validateData}
          onFinish={handleSubmit}
          className='innerForm'
          labelCol={{ span: 24 }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <div className='ant-row ant-form-item'>
                <div className='ant-col ant-col-24 ant-form-item-label'>
                  <label title={`${t('Common_Data.Organisation')}`}>{t('Common_Data.Organisation')}</label>
                </div>
                <Input
                  bordered={false}
                  value={ORGANISATION.organisationValues.organisation_name}
                  className='text-left'
                  name='organisation_id'
                />
              </div>
            </Col>
            <Col span={6}>
              <InputComponent
                disabled={editValues.has_transactions_count > 0}
                required
                type='select'
                allowClear
                label={`${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')}`}
                name='role_id'
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')}`}
                onChange={async () => {
                  await handleRoleChange();
                  handleChange();
                }}
                options={{
                  values: ORGANISATION.dropdown_orgRole_list || ([
                    { id: editValues.role_id, role_name: editValues.role_name }
                  ]),
                  value_key: 'id',
                  text_key: 'role_name',
                  rejected_keys: currentTab !== 'Ledger' && [
                    form.getFieldValue('related_role_id')
                  ]
                }}
                tooltip={`${t('OrganisationManagement.Role_Name_Tooltip')}.`}
                rules={vsmOrganisations.validation.organisation_role}
                onFocus={() =>
                  fetchRoles &&
                  ORGANISATION.getOrgRoles().then(() => setFetchRoles(false))
                }
                notFoundContent={fetchRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              />
            </Col>
            <Col span={10}>
              <InputComponent
                disabled={editValues.has_transactions_count > 0}
                onChange={handleChange}
                required
                type='radio_button'
                label={`${t('Common_Data.Relate')} ${t('Common_Data.To')}`}
                name='related_entity_type'
                initialValue={currentTab}
                options={{
                  values: [
                    { value: 'Organisation', text: `${t('Common_Data.Organisation')}` },
                    { value: 'People', text: `${t('Common_Data.People')}` },
                    { value: 'Ledger', text: user.default_ledger_name }
                  ]
                }}
                tooltip={`${t('OrganisationManagement.Option_To_Select')}: ${t('Common_Data.Organisation')}/${t('Common_Data.People')}/${t('OrganisationManagement.Ledger')} ${t('Common_Data.Name')}.`}
                rules={vsmOrganisations.validation.related_entity_type}
              />
            </Col>
          </Row>

          {currentTab === 'Ledger' ? null : currentTab === 'Organisation' ? (
            <Row gutter={24}>
              <Col span={12}>
                <InputComponent
                  disabled={editValues.has_transactions_count > 0}
                  required
                  type='select'
                  allowClear
                  label={`${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')}`}
                  name='related_entity_id'
                  placeholder={`${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')}`}
                  onChange={(data) => {
                    handleChange(data);
                    handleROrgChange(data);
                  }}
                  options={{
                    values: ORGANISATION.dropdown_ROrg_list || ([
                      { id: editValues.related_entity_id, entity_name: editValues.related_entity_name }
                    ]),
                    value_key: 'id',
                    text_key: 'entity_name',
                    rejected_keys: [form.getFieldValue('organisation_id')]
                  }}
                  tooltip={`${t('OrganisationManagement.Related_Organisation_Tooltip')}.`}
                  rules={vsmOrganisations.validation.related_organisation}
                  notFoundContent={
                    ORGANISATION.fetching ? (
                      <Spin size='small' />
                    ) : searchROrgKey ? (
                      `${t('Common_Data.No_Record_Found')}!`
                    ) : null
                  }
                  onSearch={handleROrgSearch}
                />
              </Col>
              <Col span={12}>
                <InputComponent
                  required
                  type='select'
                  allowClear
                  disabled={editValues.has_transactions_count > 0}
                  label={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                  name='related_role_id'
                  placeholder={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                  onChange={handleChange}
                  options={{
                    values: ORGANISATION.dropdown_RelatedRoles || ([
                      { id: editValues.related_role_id, role_name: editValues.related_role_name }
                    ]),
                    value_key: 'id',
                    text_key: 'role_name',
                    rejected_keys: [form.getFieldValue('role_id')]
                  }}
                  tooltip={`${t('OrganisationManagement.Related_Role_Tooltip')}.`}
                  rules={vsmOrganisations.validation.related_role}
                  onFocus={() =>
                    fetchRelatedRoles &&
                    ORGANISATION.getOrgRelatedRoles({
                      role_id: form.getFieldValue('role_id')
                    }).then(() => setFetchRetaldRoles(false))
                  }
                  notFoundContent={
                    fetchRelatedRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                  }
                />
              </Col>
              {rOrganisationValues && (
                <Col flex={1}>
                  <OrgDetailComponent data={rOrganisationValues} />
                </Col>
              )}
            </Row>
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <InputComponent
                  required
                  type='select'
                  allowClear
                  label={`${t('OrganisationManagement.Related')} ${t('Common_Data.People')}`}
                  name='related_entity_id'
                  placeholder={`${t('OrganisationManagement.Related')} ${t('Common_Data.People')}`}
                  onChange={(data) => {
                    handleChange(data);
                    handlePeopleChange(data);
                  }}
                  options={{
                    values: PEOPLE.dropdown_RPpl_list || ([
                      { id: editValues.related_entity_id, entity_name: editValues.related_entity_name }
                    ]),
                    value_key: 'id',
                    text_key: 'entity_name'
                  }}
                  tooltip={`${t('OrganisationManagement.Related_People_Tooltip')}.`}
                  rules={vsmOrganisations.validation.related_people}
                  filterOption={false}
                  notFoundContent={
                    PEOPLE.fetching ? (
                      <Spin size='small' />
                    ) : searchROrgKey ? (
                      `${t('Common_Data.No_Record_Found')}!`
                    ) : null
                  }
                  onSearch={handleRPplSearch}
                />
              </Col>
              <Col span={12}>
                <InputComponent
                  disabled={editValues.has_transactions_count > 0}
                  required
                  type='select'
                  allowClear
                  label={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                  name='related_role_id'
                  placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                  onChange={handleChange}
                  options={{
                    values: PEOPLE.dropdown_pplRole_list || ([
                      { id: editValues.related_role_id, role_name: editValues.related_role_name }
                    ]),
                    value_key: 'id',
                    text_key: 'role_name'
                  }}
                  tooltip={`${t('OrganisationManagement.People_Role_Relation_Tooltip')}.`}
                  rules={vsmOrganisations.validation.related_role}
                  onFocus={() =>
                    fetchRelatedRoles &&
                    PEOPLE.getPeopleRoles().then(() => setFetchRetaldRoles(false))
                  }
                  notFoundContent={
                    fetchRelatedRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                  }
                />
              </Col>
              {PEOPLE.rPeopleCardValue && (
                <Col flex={1}>
                  <PplDetailComponent data={PEOPLE.rPeopleCardValue} />
                </Col>
              )}
            </Row>
          )}

          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                mode='tags'
                type='select'
                allowClear
                label={`${t('Common_Data.Tags')}`}
                name='tag_ids'
                placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.More_Tags')}`}
                onChange={handleChange}
                options={{
                  values: dropdown_tags_list || (
                    editValues.tag_ids && editValues.tag_ids.map((x, i) => (typeof (x) === 'object') ? x : { id: x, tag_name: editValues.tags_names[i] })
                  ),
                  value_key: 'id',
                  text_key: 'tag_name'
                }}
                tooltip={`${t('OrganisationManagement.Tags_Tooltip')}.`}
                rules={vsmOrganisations.validation.tag_name}
                maxTagTextLength={10}
                tokenSeparators={[',']}
                onFocus={() =>
                  fetchTags && getAllTagsList().then(() => setFetchTags(false))
                }
                notFoundContent={fetchTags ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label={`${t('Common_Data.Exchange')} ${t('OrganisationManagement.Reference')} ${t('Common_Data.Id')}`}
                name='exchange_ref_id'
                placeholder={`${t('Common_Data.Exchange')} ${t('OrganisationManagement.Reference')} ${t('Common_Data.Id')}`}
                onChange={handleChange}
                tooltip={`${t('Common_Data.Exchange')} ${t('Common_Data.ID')} ${t('OrganisationManagement.Numerical_Value')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')} ${t('Common_Data.In')} ${t('Common_Data.Exchange')} 2.0 ${t('OrganisationManagement.Software')}.`}
                rules={vsmOrganisations.validation.exchange_ref_id}
                maxLength={100}
              />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                label={`${t('OrganisationManagement.Supplier_Insurance')} ${t('Common_Data.No')}.`}
                name='supplier_insurance_no'
                placeholder={`${t('OrganisationManagement.Supplier_Insurance')} ${t('Common_Data.Number')}`}
                onChange={handleChange}
                tooltip={`${t('OrganisationManagement.Insurance_Number_Tooltip')}.`}
                rules={vsmRoleMapping.validation.Insurance}
                maxLength={100}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label={`${t('OrganisationManagement.Agreed_Early_Payment_Discount')}`}
                name='agreed_early_payment_discount'
                placeholder={`${t('OrganisationManagement.Agreed_Early_Payment_Discount')}`}
                onChange={handleChange}
                tooltip={`${t('OrganisationManagement.Agreed_Early_Payment_Tooltip')}.`}
                rules={vsmRoleMapping.validation.Discount}
                maxLength={100}
              />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                allowClear
                type='select'
                label={`${t('Common_Data.Currency')}`}
                name='currency_id'
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')} ${t('Common_Data.Currency')}`}
                onChange={handleChange}
                tooltip={`${t('OrganisationManagement.Currency_Tooltip')}.`}
                onFocus={() =>
                  fetchCurrency &&
                  ORGBANKACCOUNTSTORE.getCurrencyList().then(() =>
                    setFetchCurrency(false)
                  )
                }
                notFoundContent={
                  fetchCurrency ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
                options={{
                  values: ORGBANKACCOUNTSTORE.dropdown_currency_list,
                  value_key: 'id',
                  text_key: 'currency_name'
                }}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label={`${t('OrganisationManagement.CRM')} ${t('Common_Data.ID')}`}
                name='crm_id'
                placeholder={`${t('Common_Data.Enter')} ${t('OrganisationManagement.CRM')} ${t('OrganisationManagement.Refernce')} ${t('Common_Data.ID')}`}
                onChange={handleChange}
                tooltip={`${t('OrganisationManagement.Unique_Id_CRM')}.`}
                rules={vsmRoleMapping.validation.crm}
                maxLength={100}
              />
            </Col>
          </Row>

          {ratingHide ? null : (
            <>
              <Card title={`${t('OrganisationManagement.Rating_Table')}`} className='mb-25'>
                <RatingElement form={form} onChange={validateData} />
              </Card>
              <InputComponent
                required
                allowClear
                type='textarea'
                label={`${t('Common_Data.Description')}`}
                name='additional_description'
                placeholder={`${t('Common_Data.Description')}`}
                onChange={validateData}
                rules={vsmOrganisations.validation.description}
                tooltip={`${t('OrganisationManagement.Description_Tooltip')}.`}
              />
            </>
          )}
        </Form>
      </Spin>
    </Drawer>
  ) : null;
});

export default EditComponent;
