import { Button, Form, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputComponent from '../component/InputComponent';
import { vsmAuth, vsmNotify } from '../config/messages';
import useStore from '../store';
import FinalLOGO from '../include/images/dealmaker-logo-250.png';
import Email_Icon from '../assets/images/gray/Mail.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Login = observer(() => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const {
    AUTH: { sendForgotPasswordLink }
  } = useStore();
  const {t} = useTranslation()
  // make function call to send link to email
  const sendLink = (data) => {
    setLoading(true);
    sendForgotPasswordLink(data)
      .then(() => {
        history.push('/');
        vsmNotify.success({
          message: vsmAuth.forgot_success
        });
      })
      .catch((errors) => {
        form.setFields(errors.errors);
        setLoading(false);
      });
  };

  return (
    <div className='login__page__wrapper'>
      {/* <div className="login__page__title__wrapper">
				<div className="login__page__title"></div>
				<div className="login__page__subtitle"></div>
			</div> */}
      {/* <div className="login__page__form__section"> */}
      <img src={FinalLOGO} className='LoginLogo' alt={`${t('Common_Data.Deal')} ${t('ForgotPassword.Maker')}`} title={`${t('Common_Data.Deal')} ${t('ForgotPassword.Maker')}`} />
      <div className='login__page__form'>
        <Typography.Title level={2} className='w-100 title'>
          {t('ForgotPassword.Forgot')} {t('ForgotPassword.Password')}?
        </Typography.Title>
        <Form form={form} onFinish={sendLink}>
          <InputComponent
            hasFeedback
            name='email'
            placeholder={`${t('Common_Data.Email')} ${t('Common_Data.Address')}`}
            prefix={<img src={Email_Icon} alt={`${t('Common_Data.Email')}`} />}
            rules={vsmAuth.validation.email}
          />
          <div className='d-flex justify-space-between align-items-center'>
            <Button
              loading={loading}
              htmlType='submit'
              block
              type='primary'
              className='login'
            >
              {t('Common_Data.Reset')} {t('ForgotPassword.Password')}
            </Button>
          </div>
          <div className='d-flex justify-space-between align-items-center'>
            <p className='mt-15 m-0 p-0 text-left copyRight'>{t('ForgotPassword.Copyright')} {moment().format('YYYY')} {t('Common_Data.Deal')} {t('ForgotPassword.Maker')} {t('ForgotPassword.System')}&reg;</p>
            <Link to='/' type='link' className='p-0 mt-15 link'>
              {t('Common_Data.Back')} {t('Common_Data.To')} {t('ForgotPassword.Login')}
            </Link>
          </div>
        </Form>
      </div>
      {/* </div> */}
    </div>
  );
});

export default Login;
