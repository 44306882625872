import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';

import { AllModules } from '@ag-grid-enterprise/all-modules';

import LocalGridConfig from '../../../../config/LocalGridConfig';

import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import { PageHeader } from 'antd';
import { DateComparator, secondsToHmsWithAppend } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const GroupInvestment = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    SavedReportStore,
    SavedReportStore: { investmentHoursDetailDataByuser },
  } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        headerName: 'SR#',
        headerTooltip: 'SR#',
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',
        cellClass: 'cellClass',
        pinned: 'left',
        cellStyle: { 'text-align': 'center' },
        filter: false,
        sortable: false,
        width: 85,
        minWidth: 85,
      },
      {
        headerName: `# ${t('Common_Data.ID')}`,
        field: 'id',
        width: 100,
        minWidth: 100,
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        field: 'sold_name',
        filter: 'agSetTextFilter',
        width: 620,
        minWidth: 620,
      },
      {
        headerName: `${t('Common_Data.Date')}`,
        field: 'datetime',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.datetime
            ? AUTH.getLocalTimeDealDesk(params.data.datetime, AUTH.global_dateformat)
            : '';
        },
        width: 100,
        minWidth: 100,
      },
      {
        headerName: `${t('Common_Data.Time_Spent')}`,
        field: 'time_spent',
        width: 100,
        minWidth: 100,
      },
    ],
  };

  let PinnedData = [];
  let soldObj = {
    sr: 'Total',
    id: null,
    sold_name: null,
    datetime: null,
    time_spent: investmentHoursDetailDataByuser
      ? secondsToHmsWithAppend(investmentHoursDetailDataByuser?.by_id?.sold_total)
      : 0,
  };
  PinnedData.push(soldObj);

  const onCellDoubleClickRoute = (cellName, cellData) => {
    if (['id'].includes(cellData?.column?.colId) && cellData?.value) {
      window.open(`/dealdesk/${cellData?.value}`, '_blank');
    }
  };

  return (
    <PageHeader
      title={`${t('Common_Data.Sold')} ${t('Reports.Breakdown')} (${investmentHoursDetailDataByuser?.by_id?.sold &&
        Array.isArray(investmentHoursDetailDataByuser?.by_id?.sold)
        ? investmentHoursDetailDataByuser?.by_id?.sold.length.toLocaleString('en-GB')
        : 0
        } records)`}
    >
      <div
        className='ag-theme-alpine grid_wrapper reportPreviewData'
        style={{
          height: `calc(100vh - 15rem)`,
          minHeight: '200px',
        }}
      >
        <AgGridReact
          rowData={
            investmentHoursDetailDataByuser
              ? investmentHoursDetailDataByuser?.by_id?.sold
              : []
          }
          modules={AllModules}
          pinnedBottomRowData={PinnedData}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            flex: 1,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{}}
          onGridReady={SavedReportStore.setupInvestmentGroup}
          onSortChanged={SavedReportStore.drilldownRedrawGrid}
          gridOptions={LocalGridConfig.options}
          suppressRowClickSelection={true}
          rowSelection={'multiple'}
          onCellDoubleClicked={(params) =>
            onCellDoubleClickRoute(params?.colDef?.headerName, params)
          }
        />
      </div>
    </PageHeader>
  );
});

export default GroupInvestment;
