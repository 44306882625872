import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { Checkbox, Spin } from 'antd';
import { ClockCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const UnreadNoteCompoenent = observer((props) => {
  const {
    AUTH,
    //HEADERREMINDERSSTORE: { list_data },
    SPANNOTESTORE: {
      is_unread_loadMore,
      unreadNoteList,
      getList,
      readCommonReminder,
      unreadLoading,
    },
    REMINDERSSTORE,
  } = useStore();
  const { t } = useTranslation()
  let sortedJsObjectstemp = [];
  let sortedJsObjects = [];

  if (unreadNoteList && unreadNoteList.length > 0) {
    unreadNoteList.forEach((element) => {
      if (!element?.OrderDateTime) {
        element.OrderDateTime = element.reminder_date_time;
        if (element.due_date_time) {
          element.OrderDateTime = element.due_date_time;
        }
      }
      sortedJsObjectstemp.push(element);
    });
    sortedJsObjects =
      sortedJsObjectstemp &&
      sortedJsObjectstemp.sort(function (a, b) {
        return new Date(a.OrderDateTime) - new Date(b.OrderDateTime);
      });
  }

  return (
    <>
      <Checkbox.Group style={{ width: '100%' }}>
        <ul className='popover-menu nocheckbox fixedHeight'>
          {sortedJsObjects?.length > 0 ? (
            sortedJsObjects.map((item, index) => (
              <li key={index}>
                <Checkbox
                  value={item.id}
                  onChange={(e) =>
                    readCommonReminder(
                      { reminder_id: [e.target.value], type: item.type },
                      2
                    ).then(() => {
                      if (REMINDERSSTORE.agGrid) {
                        REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
                      }
                    })
                  }
                ></Checkbox>
                <div className='ant-checkbox-wrapper'>
                  <span
                    onClick={() => {
                      props.openReminderViewDrawer(item, 2);
                    }}
                  >
                    <strong>
                      {item.subject && item.subject.length > 35
                        ? item.subject.substring(0, 35) + '...'
                        : item.subject}
                    </strong>
                    <div
                      className='resopnsiveImg'
                      dangerouslySetInnerHTML={{
                        __html:
                          item.description && item.description.length > 35
                            ? item.description.substring(0, 35) + '...'
                            : item.description,
                      }}
                    ></div>
                    <i>
                      {item.created_by_name && item.created_by_name.length > 14
                        ? item.created_by_name.substring(0, 14) + '...'
                        : item.created_by_name}{' '}
                      -{' '}
                      <span style={{ color: '#000000' }} title={`${t('Common_Data.Reminder')} ${t('Common_Data.Date')} ${t('Common_Data.Time')}`}>
                        <ClockCircleFilled />{' '}
                      </span>
                      {AUTH.getLocalTime(item.due_date_time)}
                    </i>

                    {/* <strong>{item?.created_by_name}</strong>
                    <i>{item.reminder_date_time}</i>
                    <div
                      className='resopnsiveImg'
                      dangerouslySetInnerHTML={{
                        __html:
                          item.description && item.description.length > 35
                            ? item.description.substring(0, 35) + '...'
                            : item.description,
                      }}
                    ></div> */}
                  </span>
                </div>
              </li>
            ))
          ) : (
            <li className='text-center'>{t('Common_Data.No')} {t('Common_Data.Reminders')}</li>
          )}
          {is_unread_loadMore && (
            <Spin spinning={unreadLoading}>
              <li className='loadMore'>
                <span onClick={() => getList(2)}>
                  {unreadLoading ? `${t('Common_Data.Load')}` : `${t('TopNotificationModule.Load_More')}`}
                </span>
              </li>
            </Spin>
          )}
        </ul>
      </Checkbox.Group>
    </>
  );
});
export default UnreadNoteCompoenent;
