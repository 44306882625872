import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Input, Spin } from 'antd';

import InputComponent from '../../../component/InputComponent';
import TinyComponent from '../../../component/TinyComponent';
import useStore from '../../../store';
import { vsmTags } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({ handleSubmit, form, onChange, visible, id, content }) => {
    const [fetchOrgList, setFetchOrgList] = useState(true);
    const [fetchMasterTicket, setFetchMasterTicket] = useState(true);
    const [fetchUsersList, setFetchUsersList] = useState(true);

    const {
      AUTH,
      TagsStore: {
        dropdown_user_list,
        dropdown_master_ticket,
        editValues,
        cloneValues,
        statusArr_list,
        getMasterTicketList,
        getUsersList,
      },
      ORGANISATION: { dropdown_global_org, getMappedOrg },
    } = useStore();
    const { t } = useTranslation()
    const EditorConfig = {
      selector: 'textarea#full-featured-non-premium',
      plugins:
        'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars',
      menubar: false,
      quickbars_selection_toolbar: false, //'addKeyNote addEmailLink addSmsLink link unlink',
      contextmenu: false,
      height: 350,
    };

    return (
      <Form
        form={form}
        labelCol={{ span: 24 }}
        id={id}
        onFinish={handleSubmit}
        className='innerForm'
      >
        <Row gutter={15}>
          {visible && visible === true ? (
            <>
              <Col span={12}>
                <InputComponent
                  name='tag_subject'
                  label={`${t('Common_Data.Subject')}`}
                  required
                  type='text'
                  tooltip={`${t('Tags.Person_Discussed_Described')}.`}
                  onChange={onChange}
                  rules={vsmTags.validation.tag_subject}
                />
              </Col>
              <Col span={12}>
                <div className='ant-row ant-form-item'>
                  <div className='ant-col ant-col-24 ant-form-item-label'>
                    <label title={`${t('Tags.Tag')} ${t('Common_Data.Number')}`}>{t('Tags.Tag')} {t('Common_Data.Number')}</label>
                  </div>
                  <Input
                    bordered={false}
                    name='tagsnumber'
                    value={editValues?.tag_number || cloneValues?.tag_number}
                    className='text-left'
                  />
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col span={24}>
                <InputComponent
                  name='tag_subject'
                  label={`${t('Common_Data.Subject')}`}
                  required
                  type='text'
                  tooltip={`${t('Tags.Person_Discussed_Described')}.`}
                  onChange={onChange}
                  rules={vsmTags.validation.tag_subject}
                />
              </Col>
            </>
          )}

          <Col span={12}>
            <InputComponent
              name='organisation_id'
              label={`${t('Common_Data.Organisation')}`}
              required
              type='select'
              showSearch={true}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')}`}
              tooltip={`${t('Tags.Organisation_Tooltip')}.`}
              onChange={onChange}
              notFoundContent={fetchOrgList ? <Spin size='small' /> : null}
              onFocus={() =>
                fetchOrgList && getMappedOrg().then(() => setFetchOrgList(false))
              }
              options={{
                values:
                  dropdown_global_org ||
                  (editValues?.organisation && [editValues.organisation]) ||
                  (cloneValues?.organisation && [cloneValues.organisation]),
                value_key: 'id',
                text_key: 'organisation_name',
              }}
              rules={vsmTags.validation.organisation_id}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              name='parent_ticket_id'
              label={`${t('Common_Data.Master')} ${t('Tags.Ticket')}`}
              type='select'
              showSearch={true}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Master')} ${t('Tags.Ticket')}`}
              tooltip={`${t('Tags.Master_Ticket_Tooltip')}.`}
              onChange={onChange}
              onFocus={() =>
                fetchMasterTicket &&
                getMasterTicketList().then(() => setFetchMasterTicket(false))
              }
              options={{
                values:
                  dropdown_master_ticket ||
                  (editValues?.master_ticket && [editValues.master_ticket]) ||
                  (cloneValues?.master_ticket && [cloneValues.master_ticket]),
                value_key: 'id',
                text_key: 'ticket_subject',
              }}
              notFoundContent={fetchMasterTicket ? <Spin size='small' /> : null}
            />
          </Col>
          <Col span={12}>
            <div className='ant-row ant-form-item'>
              <div className='ant-col ant-col-24 ant-form-item-label'>
                <label title={`${t('Common_Data.Owner')}`}>{t('Common_Data.Owner')}</label>
              </div>

              {editValues ? (
                <Input
                  bordered={false}
                  value={
                    editValues.tag_owner &&
                    editValues.tag_owner.first_name + ' ' + editValues.tag_owner.last_name
                  }
                  className='text-left'
                />
              ) : (
                <Input
                  bordered={false}
                  value={AUTH.user.first_name + ' ' + AUTH.user.last_name}
                  className='text-left'
                />
              )}
            </div>
          </Col>
          <Col span={12}>
            <InputComponent
              name='holder'
              label={`${t('Common_Data.Holder')}`}
              type='select'
              allowClear
              showSearch={true}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.User')}`}
              tooltip={`${t('Tags.Holder_Tooltip')}.`}
              onChange={onChange}
              onFocus={() =>
                fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
              }
              options={{
                values:
                  dropdown_user_list ||
                  (editValues?.holder && [
                    {
                      id: editValues.holder,
                      name:
                        editValues.tag_holder.first_name +
                        ' ' +
                        editValues.tag_holder.last_name,
                    },
                  ]) ||
                  (cloneValues?.holder && [
                    {
                      id: cloneValues.holder,
                      name:
                        cloneValues.tag_holder.first_name +
                        ' ' +
                        cloneValues.tag_holder.last_name,
                    },
                  ]),
                value_key: 'id',
                text_key: 'name',
              }}
              notFoundContent={fetchUsersList ? <Spin size='small' /> : null}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              name='tag_status'
              required
              label={`${t('Common_Data.Status')}`}
              type='select'
              showSearch={false}
              tooltip={`${t('Common_Data.Status_Active_Inactive')}.`}
              onChange={onChange}
              options={{
                values: statusArr_list,
              }}
              rules={vsmTags.validation.tag_status_id}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              name='deadline_date'
              label={`${t('LeadKanbanView.DeadLine_Date')}`}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')}`}
              required
              type='date'
              tooltip={`${t('Tags.Reminder_Due_Date_Tooltip')}.`}
              onChange={onChange}
              rules={vsmTags.validation.deadline_date}
            />
          </Col>
          <Col span={24}>
            <InputComponent
              label={`${t('Common_Data.Description')}`}
              required
              type='labelOnly'
              tooltip={`${t('Tags.Description_Tooltip')}.`}
            />
            <Form.Item className='editorInput'>
              <Form.Item
                name='tag_content'
                noStyle
                onChange={onChange}
                rules={vsmTags.validation.tag_content}
              >
                <TinyComponent
                  EditorConfig={EditorConfig}
                  handleFormChange={onChange}
                  //initialValue={`<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`}
                  editorvalue={content}
                  form={form}
                  htmlName='tag_content'
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default FormComponent;
