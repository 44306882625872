import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Card, Empty, List } from 'antd';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}
 
const TagsTicketsComponent = observer((props) => {
  const { AUTH } = useStore();
  const {
    addConnections,
    openTagViewDrawer,
    openTicketViewDrawer,
    tagcount,
    ticketcount,
  } = props;
  const { height } = useWindowDimensions();
  const {t} = useTranslation()
  const SanpNoteTitle = () => {
    return (
      <>
        {t('Common_Data.Tags')}
        <span className='TitleValue'>{tagcount}</span>
        <span style={{ width: '1rem' }}></span>
        {t('Common_Data.Tickets')}
        <span className='TitleValue'>{ticketcount}</span>
      </>
    );
  };

  let dataTagsTicket =
    props?.tagsticket_data && props.tagsticket_data.slice(0, height > 700 ? 5 : 3);

  return (
    <>
      <Card
        size='small'
        title={SanpNoteTitle()}
        className='nopadding'
        extra={
          <PlusCircleOutlined
            className='bar_icon'
            title={`${t('Common_Data.Manage')} ${t('Common_Data.Tags')} & ${t('Common_Data.Tickets')}`}
            onClick={() => addConnections(props)}
          />
        }
      >
        <List size='small' className='right-list'>
          {dataTagsTicket &&
            dataTagsTicket.map((x, index) => {
              return (
                <List.Item key={index}>
                  <Link
                    to='#nogo'
                    onClick={() =>
                      x.type === 'tags' ? openTagViewDrawer(x) : openTicketViewDrawer(x)
                    }
                  >
                    <img
                      src={AUTH.GetThemedImage(x.type === 'tags' ? 'Tags' : 'Tickets')}
                      alt=''
                    />
                    {x.subject}
                  </Link>
                </List.Item>
              );
            })}
        </List>
        {!dataTagsTicket?.length && (
          <Empty
            className={'rightSectionFixHeight'}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Card>
    </>
  );
});

export default TagsTicketsComponent;
