import React from 'react';
import { Button, Switch, Tooltip } from 'antd';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

export const ActionRenderer = (props) => {
  const { AUTH } = useStore();
  const { t } = useTranslation()
  const {
    openDeleteModal,
    openEditModal,
    openPreviewDataModal,
    onSwitchChange,
    openCloneModal,
    onFavReportChange,
  } = props.agGridReact.props.frameworkComponents;
  return (
    <div className='action-column'>
      {AUTH.checkPermission(21, 'edit') &&
        props.data &&
        (AUTH.user.id === props.data.created_by ||
          AUTH.user.id === 69 ||
          AUTH.user?.user_role?.user_role_id === 1) ? (
        <Tooltip
          title={`${props?.data?.global_toggle ? `${t('Reports.Global')} ${t('Reports.Report')}` : `${t('Reports.Private')} ${t('Reports.Report')}`}`}
        >
          <Switch
            style={{ margin: '5px' }}
            onChange={(val) => onSwitchChange(val, props?.data)}
            defaultChecked={props?.data?.global_toggle ? 1 : 0}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={`${props?.data?.global_toggle ? `${t('Reports.Global')} ${t('Reports.Report')}` : `${t('Reports.Private')} ${t('Reports.Report')}`}`}
        >
          <Switch
            style={{ margin: '5px' }}
            defaultChecked={props?.data?.global_toggle ? 1 : 0}
            disabled={true}
          />
        </Tooltip>
      )}

      {AUTH.checkPermission(21, 'save-to-dashboard') &&
        props.data &&
        (AUTH.user.id === props.data.created_by ||
          AUTH.user.id === 69 ||
          AUTH.user?.user_role?.user_role_id === 1) ? (
        props.data && props.data.is_favorite === 1 ? (
          <Tooltip title={`${t('Reports.Set_as_Favorite')}`}>
            <Button
              type='text'
              onClick={() =>
                onFavReportChange(props.data)
              }
            >
              <img
                className='menuicon '
                alt=''
                src={AUTH.GetThemedImage('Star_icon_Fill')}
              />
              {/* <StarFilled className='saveToMenuIcon' /> */}
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={`${t('Reports.Set_as_Favorite')}`}>
            <Button
              type='text'
              onClick={() =>
                onFavReportChange(props.data)
              }
            >
              <img
                className='menuicon '
                alt=''
                src={AUTH.GetThemedImage('Star_icon_Empty')}
              />
            </Button>
          </Tooltip>
        )
      ) : (
        <Button type='text' disabled={true}>
          <img
            className='menuicon '
            alt=''
            src={AUTH.GetThemedImage('Star_icon_Empty')}
            disabled={true}
          />
        </Button>
      )}

      {AUTH.checkPermission(21, 'preview-report') &&
        props.data &&
        (AUTH.user.id === props.data.created_by ||
          AUTH.user.id === 69 ||
          props.data.global_toggle ||
          AUTH.user?.user_role?.user_role_id === 1) ? (
        <Tooltip title={`${t('Reports.Preview')} ${t('Reports.Report')}`}>
          <Button type='text' onClick={() => openPreviewDataModal(props.data.id, false, true)}>
            {/* <FileTextOutlined /> */}
            <img
              className='menuicon '
              alt=''
              src={AUTH.GetThemedImage('Preview_Report')}
            />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title={`${t('Reports.Preview')} ${t('Reports.Report')}`}>
          <Button type='text' disabled={true}>
            <img
              className='menuicon '
              alt=''
              src={AUTH.GetThemedImage('Preview_Report')}
              disabled={true}
            />
          </Button>
        </Tooltip>
      )}

      {AUTH.checkPermission(21, 'edit') &&
        props.data &&
        (AUTH.user.id === props.data.created_by ||
          AUTH.user.id === 69 ||
          AUTH.user?.user_role?.user_role_id === 1) ? (
        <Tooltip title={`${t('Common_Data.Edit')}`}>
          <Button type='text' onClick={() => openEditModal(props.data.id)}>
            {/* <EditOutlined /> */}
            <img className='menuicon ' alt='' src={AUTH.GetThemedImage('Edit')} />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title={`${t('Common_Data.Edit')}`}>
          <Button type='text' disabled={true}>
            <img
              className='menuicon '
              alt=''
              src={AUTH.GetThemedImage('Edit')}
              disabled={true}
            />
          </Button>
        </Tooltip>
      )}
      {
        AUTH.checkPermission(21, 'edit') ? (
          <Tooltip title={`${t('Common_Data.Clone')} ${t('Reports.Report')}`}>
            <Button type='text' onClick={() => openCloneModal(props.data.id)}>
              {/* <EditOutlined /> */}
              <img className='menuicon ' alt='' src={AUTH.GetThemedImage('Clone_Record')} />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={`${t('Common_Data.Clone')} ${t('Reports.Report')}`}>
            <Button type='text' disabled={true}>
              <img
                className='menuicon '
                alt=''
                src={AUTH.GetThemedImage('Clone_Record')}
                disabled={true}
              />
            </Button>
          </Tooltip>
        )}

      {AUTH.checkPermission(21, 'delete') &&
        props.data &&
        (AUTH.user.id === props.data.created_by ||
          AUTH.user.id === 69 ||
          AUTH.user?.user_role?.user_role_id === 1) ? (
        props.data && props.data.is_associated ? (
          AUTH.checkPermission(21, 'delete') && (
            <Tooltip
              placement='topRight'
              color={'red'}
              title={
                `${t('Reports.Report_Tooltip')}.`
              }
            >
              <Button type='text' disabled>
                {/* <DeleteOutlined /> */}
                <img className='menuicon ' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            </Tooltip>
          )
        ) : (
          AUTH.checkPermission(21, 'delete') && (
            <Tooltip title={`${t('Common_Data.Delete')}`}>
              <Button
                type='text'
                onClick={() => {
                  openDeleteModal(props.data);
                }}
              >
                {/* <DeleteOutlined /> */}
                <img className='menuicon ' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            </Tooltip>
          )
        )
      ) : (
        <Tooltip title={`${t('Common_Data.Delete')}`}>
          <Button type='text' disabled={true}>
            <img
              className='menuicon '
              alt=''
              src={AUTH.GetThemedImage('Waste')}
              disabled={true}
            />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
