import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../component/InputComponent';

const RecordPerPage = (props) => {
  const { t } = useTranslation();


  let initialiseOptions = {
    values: [
      { value: 50, text: `50 ${t('Common_Data.Records')}` },
      { value: 100, text: `100 ${t('Common_Data.Records')}` },
      { value: 200, text: `200 ${t('Common_Data.Records')}` },
      { value: 500, text: `500 ${t('Common_Data.Records')}` },
      { value: 1000, text: `1000 ${t('Common_Data.Records')}` }
    ]
  };
  /* IF report type : tickets then we need to discuss 250 instant of 200 */
  if (props.isReportType && props.isReportType === "tickets") {
    initialiseOptions = {
      values: [
        { value: 50, text: `50 ${t('Common_Data.Records')}` },
        { value: 100, text: `100 ${t('Common_Data.Records')}` },
        { value: 250, text: `250 ${t('Common_Data.Records')}` },
        { value: 500, text: `500 ${t('Common_Data.Records')}` },
        { value: 1000, text: `1000 ${t('Common_Data.Records')}` }
      ]
    };
  }

  return (
    <>
      <InputComponent
        {...props}
        name='RecordPerPage'
        type='select'
        style={{ margin: '0', minWidth: '150px' }}
        initialValue={100}
        showSearch={false}
        options={initialiseOptions}
      />
    </>
  )
};

export default RecordPerPage;
