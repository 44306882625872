import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
//import OrgDetailComponent from '../../../OrganisationManagement/RoleMapping/component/OrgDetailComponent';
import InputComponent from '../../../../component/InputComponent';
import useStore from '../../../../store';
import { vsmOrganisations, vsmPeople } from '../../../../config/messages';
import { Form, Col, Row, Spin, Input } from 'antd';
import debounce from 'lodash/debounce';
import PplDetailComponent from './PplDetailComponent';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({
    onChange,
    form,
    handleSubmit,
    fetchRoles,
    fetchTags,
    setFetchRoles,
    setFetchTags,
    setCurrentTab,
    openAddModal,
    visible,
    id,
    currentTab,
    relatedToTab,
    rejectedkeys,
    setRejectedKeys,
    fetchRelatedRoles,
    setFetchRelatedRoles,
    type
  }) => {
    const { ORGANISATION, PEOPLEMAPPINGSTORE, PEOPLE, AUTH } = useStore();
    const [searchROrgKey, setSearchROrgKey] = useState();
    const [searchRPplKey, setSearchRPplKey] = useState();
    const [searchPplKey, setSearchPplKey] = useState();
    const { t } = useTranslation()
    const onFinish = (data) => {
      handleSubmit(data);
    };

    // search string for dropdown
    const handleROrgSearch = debounce((val) => {
      ORGANISATION.getAllROrg({ search_for: val, related_entity_type: 1 });
    }, 500);

    // search string for dropdown
    const handleRPplSearch = debounce((val) => {
      PEOPLE.getPeopleSearchList({
        search_for: val,
        related_entity_type: 2
      });
    }, 500);

    const handleSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchPplKey(val);
        PEOPLE.getAllPeople({ search_for: val, mapping_type: 2 });
      };

      return debounce(loadOptions, 500);
    }, [PEOPLE]);

    // get related organisation data
    const handleROrgChange = useCallback(
      (data) => {
        if (data) {
          ORGANISATION.getOrgCardValue(data, 'relatedOrg');
        } else {
          ORGANISATION.setROrganisationValues(null);
        }
      },
      [ORGANISATION]
    );

    // get related organisation data
    const handleRPplChange = useCallback(
      (data) => {
        if (data) {
          PEOPLE.getPeopleCardValue(data, 'relatedPeople');
        } else {
          PEOPLE.rPeopleCardValue = null;
        }
      },
      [PEOPLE]
    );

    // set the form values to add
    useEffect(() => {
      if (PEOPLE.peopleValues && visible) {
        PEOPLE.rPeopleValues = null;
        form.setFieldsValue({
          people_id: PEOPLE.peopleValues.id
        });
      }
    }, [ORGANISATION, form, visible, PEOPLE]);

    const handleCardChange = (id) => {
      if (id) {
        PEOPLE.getPeopleCardValue(id, 'people');
      } else {
        PEOPLE.peopleCardValue = null;
      }
    };

    const handleRoleChange = () => {
      if (
        form.getFieldValue('role_id') &&
        form.getFieldValue('related_entity_type') === 'People'
      ) {
        PEOPLE.getRelatedPeopleRoles({
          role_id: form.getFieldValue('role_id')
        }).then(() => setFetchRelatedRoles(false));
        form.resetFields(['related_role_id']);
      }
    };

    // set the form values to add
    useEffect(() => {
      if (PEOPLEMAPPINGSTORE.editValues && visible) {
        if (PEOPLEMAPPINGSTORE.editValues.related_entity_type === 'People') {
          handleRPplChange(PEOPLEMAPPINGSTORE.editValues.related_entity_id);
        } else {
          handleROrgChange(PEOPLEMAPPINGSTORE.editValues.related_entity_id);
        }
        setRejectedKeys(PEOPLEMAPPINGSTORE.editValues.role_id);
        form.setFieldsValue({
          people_id: PEOPLEMAPPINGSTORE.editValues.people.id,
          role_id: PEOPLEMAPPINGSTORE.editValues.role_id,
          related_role_id: PEOPLEMAPPINGSTORE.editValues.related_role_id,
          related_entity_type: PEOPLEMAPPINGSTORE.editValues.related_entity_type,
          related_entity_id: PEOPLEMAPPINGSTORE.editValues.related_entity_id,
          related_people_id: PEOPLEMAPPINGSTORE.editValues.related_people_id,
          exchange_ref_id: PEOPLEMAPPINGSTORE.editValues.exchange_ref_id
            ? PEOPLEMAPPINGSTORE.editValues.exchange_ref_id.toString()
            : null,
          tag_ids:
            PEOPLEMAPPINGSTORE.editValues.tag_ids &&
              PEOPLEMAPPINGSTORE.editValues.tag_ids.length > 0
              ? PEOPLEMAPPINGSTORE.editValues.tag_ids
              : undefined
        });
        setCurrentTab(PEOPLEMAPPINGSTORE.editValues.related_entity_type);
      }
    }, [
      PEOPLEMAPPINGSTORE.editValues,
      form,
      visible,
      setCurrentTab,
      handleROrgChange,
      handleRPplChange,
      setRejectedKeys
    ]);

    return (
      <Form
        form={form}
        id='mapaddeditform'
        labelCol={{ span: 24 }}
        onChange={onChange}
        onFinish={onFinish}
        className='innerForm'
      >
        <Row gutter={15}>
          {PEOPLE.peopleValues ? (
            <Col span={8}>
              <div className='ant-row ant-form-item'>
                <div className='ant-col ant-col-24 ant-form-item-label'>
                  <label title={`${t('Common_Data.Organisation')}`}>{t('Common_Data.People')}</label>
                </div>
                <Input
                  bordered={false}
                  value={
                    PEOPLE.peopleValues &&
                    PEOPLE.peopleValues.first_name +
                    ' ' +
                    (PEOPLE.peopleValues.last_name ? PEOPLE.peopleValues.last_name : '')
                  }
                  className='text-left'
                  name='organisation_id'
                />
              </div>
            </Col>
          ) : (
            <Col xs={{ span: 8 }}>
              <InputComponent
                required
                type='select'
                label={`${t('Common_Data.People')}`}
                name='people_id'
                placeholder={`${t('Common_Data.People')}`}
                disabled={id}
                style={{ width: '77%' }}
                showSearch
                allowClear
                notFoundContent={
                  PEOPLE.fetching ? (
                    <Spin size='small' />
                  ) : searchPplKey ? (
                    `${t('Common_Data.No_Record_Found')}.`
                  ) : null
                }
                onSearch={handleSearch}
                onChange={() => {
                  handleCardChange(form.getFieldValue('people_id'));
                  onChange();
                }}
                options={{
                  values:
                    PEOPLE.drowpdown_people_list ||
                    (PEOPLEMAPPINGSTORE.editValues &&
                      type === 'edit' &&
                      PEOPLEMAPPINGSTORE.editValues.people && [
                        {
                          id: PEOPLEMAPPINGSTORE.editValues.people.id,
                          entity_name:
                            PEOPLEMAPPINGSTORE.editValues.people.first_name +
                            ' ' +
                            (PEOPLEMAPPINGSTORE.editValues.people.last_name
                              ? PEOPLEMAPPINGSTORE.editValues.people.last_name
                              : '')
                        }
                      ]) ||
                    (PEOPLE.peopleValues && [
                      {
                        id: PEOPLE.peopleValues && PEOPLE.peopleValues.id,
                        entity_name:
                          PEOPLE.peopleValues &&
                          PEOPLE.peopleValues.first_name +
                          ' ' +
                          (PEOPLE.peopleValues.last_name
                            ? PEOPLE.peopleValues.last_name
                            : '')
                      }
                    ]),
                  value_key: 'id',
                  text_key: 'entity_name'
                }}
                tooltip={`${t('PeopleManagement.People_Tooltip')}`}
                extra={
                  AUTH.checkPermission(41, 'add') && (
                    <PlusCircleOutlined className='ml-10' onClick={openAddModal} />
                  )
                }
              />
            </Col>
          )}

          <Col xs={{ span: 6 }}>
            <InputComponent
              required
              type='select'
              allowClear
              label={`${t('Common_Data.People')} ${t('PeopleManagement.Role')}`}
              name='role_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.People')} ${t('PeopleManagement.Role')}`}
              onChange={() => {
                handleRoleChange();
                onChange();
              }}
              options={{
                values:
                  PEOPLE.dropdown_pplRole_list ||
                  (PEOPLEMAPPINGSTORE.editValues && [
                    {
                      id: PEOPLEMAPPINGSTORE.editValues.role_id,
                      role_name: PEOPLEMAPPINGSTORE.editValues.role_name
                    }
                  ]),
                value_key: 'id',
                text_key: 'role_name'
              }}
              tooltip={`${t('PeopleManagement.People_Role_Tooltip')}.`}
              rules={vsmPeople.validation.organisation_role}
              onFocus={() =>
                fetchRoles && PEOPLE.getPeopleRoles().then(() => setFetchRoles(false))
              }
              notFoundContent={fetchRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
        </Row>

        <Col xs={{ span: 8 }}>
          <InputComponent
            required
            type='radio_button'
            label={`${t('Common_Data.Relate')} ${t('Common_Data.To')}`}
            name='related_entity_type'
            initialValue={relatedToTab ? relatedToTab : 'People'}
            options={{
              values: [
                { value: 'Organisation', text: `${t('Common_Data.Organisation')}` },
                { value: 'People', text: `${t('Common_Data.People')}` }
                // { value: 'Ledger', text: AUTH.user.default_ledger_name }
              ]
            }}
            tooltip={`${t('PeopleManagement.Option_To_Select')}: ${t('Common_Data.Organisation')}/${t('Common_Data.People')}/${t('PeopleManagement.Ledger')} ${t('Common_Data.Name')}.`}
            rules={vsmOrganisations.validation.related_entity_type}
          />
        </Col>
        {currentTab === 'Ledger' ? null : currentTab === 'Organisation' ? (
          <Row gutter={15}>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                label={`${t('PeopleManagement.Related')} ${t('Common_Data.Organisation')}`}
                name='related_entity_id'
                placeholder={`${t('PeopleManagement.Related')} ${t('Common_Data.Organisation')}`}
                onChange={(data) => {
                  onChange(data);
                  handleROrgChange(data);
                }}
                options={{
                  values:
                    ORGANISATION.dropdown_ROrg_list ||
                    (PEOPLEMAPPINGSTORE.editValues && [
                      {
                        id: PEOPLEMAPPINGSTORE.editValues.related_entity_id,
                        entity_name: PEOPLEMAPPINGSTORE.editValues.related_entity_name
                      }
                    ]),
                  value_key: 'id',
                  text_key: 'entity_name'
                }}
                tooltip={`${t('PeopleManagement.Related_Organisation_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_organisation}
                filterOption={false}
                notFoundContent={
                  ORGANISATION.fetching ? (
                    <Spin size='small' />
                  ) : searchROrgKey ? (
                    `${t('Common_Data.No_Record_Found')}!`
                  ) : null
                }
                onSearch={debounce((val) => {
                  setSearchROrgKey(val);
                  handleROrgSearch(val);
                }, 500)}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                label={`${t('PeopleManagement.Related')} ${t('PeopleManagement.Role')}`}
                name='related_role_id'
                placeholder={`${t('PeopleManagement.Related')} ${t('PeopleManagement.Role')}`}
                onChange={onChange}
                options={{
                  values:
                    ORGANISATION.dropdown_orgRole_list ||
                    (PEOPLEMAPPINGSTORE.editValues && [
                      {
                        id: PEOPLEMAPPINGSTORE.editValues.related_role_id,
                        role_name: PEOPLEMAPPINGSTORE.editValues.related_role_name
                      }
                    ]),
                  value_key: 'id',
                  text_key: 'role_name'
                }}
                tooltip={`${t('PeopleManagement.Related_Role_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_role}
                onFocus={() =>
                  fetchRelatedRoles &&
                  ORGANISATION.getOrgRoles().then(() => setFetchRelatedRoles(false))
                }
                notFoundContent={
                  fetchRelatedRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              />
            </Col>
            {/* {ORGANISATION.rOrganisationValues && (
              <Col flex={1}>
                <OrgDetailComponent
                  data={ORGANISATION.rOrganisationValues}
                />
              </Col>
            )} */}
          </Row>
        ) : (
          <Row gutter={15}>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                label={`${t('PeopleManagement.Related')} ${t('Common_Data.People')}`}
                name='related_entity_id'
                placeholder={`${t('PeopleManagement.Related')} ${t('Common_Data.People')}`}
                onChange={async (data) => {
                  onChange(data);
                  await handleRPplChange(data);
                }}
                options={{
                  values:
                    PEOPLE.dropdown_RPpl_list ||
                    (PEOPLEMAPPINGSTORE.editValues && [
                      {
                        id: PEOPLEMAPPINGSTORE.editValues.related_entity_id,
                        entity_name: PEOPLEMAPPINGSTORE.editValues.related_entity_name
                      }
                    ]),
                  value_key: 'id',
                  text_key: 'entity_name',
                  rejected_keys: [form.getFieldValue('people_id')]
                }}
                tooltip={`${t('PeopleManagement.Related_People_Tooltip')}.`}
                rules={vsmPeople.validation.related_organisation}
                filterOption={false}
                notFoundContent={
                  PEOPLE.fetching ? (
                    <Spin size='small' />
                  ) : searchRPplKey ? (
                    `${t('Common_Data.No_Record_Found')}!`
                  ) : null
                }
                onSearch={debounce((val) => {
                  setSearchRPplKey(val);
                  handleRPplSearch(val);
                }, 500)}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                label={`${t('PeopleManagement.Related')} ${t('PeopleManagement.Role')}`}
                name='related_role_id'
                placeholder={`${t('Common_Data.Select')} ${t('PeopleManagement.Related')} ${t('PeopleManagement.Role')}`}
                onChange={onChange}
                options={{
                  values:
                    PEOPLE.dropdown_RpplRole_list ||
                    (PEOPLEMAPPINGSTORE.editValues && [
                      {
                        id: PEOPLEMAPPINGSTORE.editValues.related_role_id,
                        role_name: PEOPLEMAPPINGSTORE.editValues.related_role_name
                      }
                    ]),
                  value_key: 'id',
                  text_key: 'role_name',
                  rejected_keys: [rejectedkeys]
                }}
                disabled={!form.getFieldValue('role_id')}
                tooltip={`${t('PeopleManagement.People_Role_Relation_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_role}
                onFocus={() =>
                  fetchRelatedRoles &&
                  PEOPLE.getRelatedPeopleRoles({
                    role_id: form.getFieldValue('role_id')
                  }).then(() => setFetchRelatedRoles(false))
                }
                notFoundContent={
                  fetchRelatedRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              />
            </Col>
            {PEOPLE.rPeopleCardValue && (
              <Col flex={1}>
                <PplDetailComponent data={PEOPLE.rPeopleCardValue} />
              </Col>
            )}
          </Row>
        )}

        <Row gutter={15}>
          <Col span={12}>
            <InputComponent
              mode='tags'
              type='select'
              allowClear
              label={`${t('Common_Data.Tags')}`}
              name='tag_ids'
              placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.More_Tag')}`}
              onChange={onChange}
              options={{
                values:
                  PEOPLEMAPPINGSTORE.dropdown_tags_list ||
                  (PEOPLEMAPPINGSTORE.editValues &&
                    PEOPLEMAPPINGSTORE.editValues.tag_ids &&
                    PEOPLEMAPPINGSTORE.editValues.tag_ids.map((x, index) => {
                      return {
                        id: x,
                        tag_name: PEOPLEMAPPINGSTORE.editValues.tags_names[index]
                      };
                    })),
                value_key: 'id',
                text_key: 'tag_name'
              }}
              tooltip={`${t('PeopleManagement.Tags_Tooltip')}.`}
              rules={vsmOrganisations.validation.tag_name}
              maxTagTextLength={10}
              tokenSeparators={[',']}
              onFocus={() =>
                fetchTags &&
                PEOPLEMAPPINGSTORE.getAllTagsList().then(() => setFetchTags(false))
              }
              notFoundContent={fetchTags ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              label={`${t('Common_Data.Exchange')} ${t('PeopleManagement.Reference')} ${t('Common_Data.Id')}`}
              name='exchange_ref_id'
              placeholder={`${t('Common_Data.Exchange')} ${t('PeopleManagement.Reference')} ${t('Common_Data.Id')}`}
              onChange={onChange}
              tooltip={`${t('Common_Data.Exchange')} ${t('Common_Data.ID')} ${t('PeopleManagement.Numerical_Value')} ${t('Common_Data.Organisation')} ${t('PeopleManagement.Role')} ${t('Common_Data.In')} ${t('Common_Data.Exchange')} 2.0 ${t('PeopleManagement.Software')}.`}
              rules={vsmOrganisations.validation.exchange_ref_id}
              maxLength={100}
            />
          </Col>
        </Row>
      </Form>
    );
  }
);

export default FormComponent;
