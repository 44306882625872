import React, { useEffect } from 'react';
import { Tooltip, Collapse } from 'antd';
import { observer } from 'mobx-react';

import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';

const KeyDocuments = observer((props) => {
  const {t} = useTranslation();
  const { DealLibrary } = props;
  const {
    AUTH,
    DEALDESKSTORE: { leadFileFolderList, allKeydocFetch, allkeydocumentlist },
  } = useStore();

  if (DealLibrary) {
    /*To Update Component->on Toggle of KeyDoc Add/Remove */
    useEffect(() => {
      allKeydocFetch();
    }, [allKeydocFetch]);

    return (
      <>
        <Collapse accordion className='KeyNotes'>
          <ul className='inboundData'>
            {allkeydocumentlist &&
              allkeydocumentlist.map((data, index) => {
                return (
                  <li key={index}>
                    <div className='id'>{index + 1}</div>
                    <div className='details'>
                      <Tooltip title={data.entity_name} placement='topLeft'>
                        <div>{data.entity_name}</div>
                      </Tooltip>
                    </div>
                    <div className='action'>
                      <Tooltip title={`${t('DealDesk.Download')}`}>
                        <a
                          target={'_blank'}
                          href={data.full_path}
                          rel='noopener noreferrer'
                        >
                          <img src={AUTH.GetThemedImage('Download_icn')} alt={`${t('DealDesk.Download')}`} />
                        </a>
                      </Tooltip>
                    </div>
                  </li>
                );
              })}
          </ul>
        </Collapse>
      </>
    );
  } else {
    /*To Update Component->on Toggle of KeyDoc Add/Remove */
    useEffect(() => {}, [leadFileFolderList]);

    let keyDocList = [];
    if (leadFileFolderList) {
      leadFileFolderList.forEach((el) => {
        if (el.is_key_document) {
          keyDocList.push(el);
        }
      });
    }

    return (
      <>
        <Collapse accordion className='KeyNotes'>
          <ul className='inboundData'>
            {keyDocList &&
              keyDocList.map((data, index) => {
                return (
                  <li key={index}>
                    <div className='id'>{index + 1}</div>
                    <div className='details'>
                      <Tooltip title={data.entity_name} placement='topLeft'>
                        <div>{data.entity_name}</div>
                      </Tooltip>
                    </div>
                    <div className='action'>
                      <Tooltip title={`${t('DealDesk.Download')}`}>
                        <a 
                          target={'_blank'}
                          href={data.full_path}
                          rel='noopener noreferrer'
                        >
                          <img src={AUTH.GetThemedImage('Download_icn')} alt={`${t('DealDesk.Download')}`} />
                        </a>
                      </Tooltip>
                    </div>
                  </li>
                );
              })}
          </ul>
        </Collapse>
      </>
    );
  }
});

export default KeyDocuments;
