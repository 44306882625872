import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../../component/InputComponent';
import { Spin, Row, Col, Divider } from 'antd';
import { vsmPeople } from '../../../../../config/messages';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';

const AddressSubElement = observer(
  ({ onChange, field, SetSelectedTypes, rejected_types, form }) => {
    const [fetchCountry, setFetchCountry] = useState(true);
    const [fetchCity, setFetchCity] = useState(true);
    const [fetchState, setFetchState] = useState(true);
    const { ORGANISATION, PEOPLE } = useStore();

    const { t } = useTranslation();
    return (
      <>
        <Row gutter={15}>
          <Col
            span={12}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              label='Address Type'
              placeholder='Address Type'
              name={[field.name, 'address_type']}
              key={[field.key, 'address_type']}
              onChange={() => {
                onChange();
                SetSelectedTypes(form.getFieldValue('address'));
              }}
              tooltip='The type of the address under which the address can be created.[eg.: home, work]'
            />
          </Col>
          <Col span={24}></Col>
          <Col
            span={8}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              required
              label='Address-1'
              name={[field.name, 'address_1']}
              key={[field.key, 'address_1']}
              placeholder='Address Line 1'
              onChange={onChange}
              tooltip='The building number and the street name used to send physical forms of communication to the people.'
              rules={vsmPeople.validation.address_1}
              maxLength={1000}
            />
          </Col>
          <Col
            span={8}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              label='Address-2'
              name={[field.name, 'address_2']}
              key={[field.key, 'address_2']}
              placeholder='Address Line 2'
              onChange={onChange}
              tooltip='The locality name used to send physical forms of communication to the people.'
              rules={vsmPeople.validation.address_2and3}
              maxLength={1000}
            />
          </Col>
          <Col
            span={8}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              label='Address-3'
              name={[field.name, 'address_3']}
              key={[field.key, 'address_3']}
              placeholder='Address Line 3'
              onChange={onChange}
              tooltip='The name of the county and country used to send physical forms of communication to the people.'
              rules={vsmPeople.validation.address_2and3}
              maxLength={1000}
            />
          </Col>
          <Col
            span={6}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              type='autocomplete'
              label='City'
              name={[field.name, 'city']}
              key={[field.key, 'city']}
              placeholder='City'
              onChange={onChange}
              options={ORGANISATION.cityList}
              tooltip='It is a large human settlement place.'
              rules={vsmPeople.validation.city}
              allowClear
              showSearch
              showArrow
              onFocus={() =>
                fetchCity && ORGANISATION.getCityList().then(() => setFetchCity(false))
              }
              notFoundContent={fetchCity ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
          <Col
            span={6}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              label='Postal Code'
              name={[field.name, 'postal_code']}
              key={[field.key, 'postal_code']}
              placeholder='Postal Code'
              onChange={onChange}
              tooltip='Each administrative division maintains its own postal code for mail delivery purposes. Having the correct code is essential to your mails delivery.'
              rules={vsmPeople.validation.postal_code}
              maxLength={16}
            />
          </Col>
          <Col
            span={6}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              type='autocomplete'
              label='State/County'
              name={[field.name, 'state_county']}
              key={[field.key, 'state_county']}
              placeholder='State/County'
              onChange={onChange}
              options={ORGANISATION.stateCountyList}
              tooltip='State is defined as a territory with its own government and borders within a larger country.'
              rules={vsmPeople.validation.state}
              allowClear
              showSearch
              showArrow
              onFocus={() =>
                fetchState &&
                ORGANISATION.getStateCountyList().then(() => setFetchState(false))
              }
              notFoundContent={fetchState ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
          <Col
            span={6}
          //style={{ display: 'flex' }}
          >
            <InputComponent
              required
              type='select'
              label='Country'
              name={[field.name, 'country_id']}
              key={[field.key, 'country_id']}
              placeholder='Country'
              onChange={onChange}
              allowClear
              showSearch
              options={{
                values: ORGANISATION.dropdown_countries_list || [
                  {
                    id:
                      PEOPLE.peopleValues &&
                      PEOPLE.peopleValues.addresses_global_countries &&
                      PEOPLE.peopleValues.addresses_global_countries.id,
                    country_name:
                      PEOPLE.peopleValues &&
                      PEOPLE.peopleValues.addresses_global_countries &&
                      PEOPLE.peopleValues.addresses_global_countries.country_name
                  }
                ],
                value_key: 'id',
                text_key: 'country_name'
              }}
              rules={vsmPeople.validation.country}
              tooltip='A country is a political state, nation, or territory which is controlled.'
              onFocus={() =>
                fetchCountry &&
                ORGANISATION.getAllCountries().then(() => setFetchCountry(false))
              }
              notFoundContent={fetchCountry ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
        </Row>
        <Divider className='mt-10 mb-15' />
      </>
    );
  }
);

export default AddressSubElement;
