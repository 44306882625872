import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmTicket, vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    SEARCHSTORE,
    TicketsStore,
    TicketsStore: { deleteValues, deleteTicketData },
  } = useStore();

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const close = (reload = false) => {
    props.close(reload);
    form.resetFields();
    setSaving(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);

    deleteTicketData(deleteValues)
      .then(() => {
        form.resetFields();
        close(true);
        AUTH.fetchLeftmenuCount();
        if (props.getWasteCount) {
          props.getWasteCount();
        }
        if (!props?.SearchPage) {
          if (TicketsStore.agGrid) {
            TicketsStore.setupGrid(TicketsStore.agGrid);
          }
        } else {
          TicketsStore.agGrid.api.applyTransaction({ remove: [TicketsStore.deleteValues] });
          if (props.isMasterTicket) {
            SEARCHSTORE.updateSearchCount({ master_ticket_count: SEARCHSTORE.searchCount.master_ticket_count - 1 });
          } else {
            SEARCHSTORE.updateSearchCount({ ticket_count: SEARCHSTORE.searchCount.ticket_count - 1 });
          }
        }

        // if (props?.SearchPage) {
        //   // TicketsStore.agGrid.api.applyTransaction({ remove: [deleteValues] });// holder is applied after deleted
        //   TicketsStore.getListSearch(TicketsStore.search_payload)
        //   SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'ticket');
        // }
        vsmNotify.success({
          message: vsmTicket.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  return (
    deleteValues && (
      <Modal
        centered
        title={`${t('Common_Data.Delete')} ${t('Common_Data.Ticket')} - #${deleteValues?.ticket_number}`}
        visible={props.visible}
        onCancel={() => close()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='deleteticketform'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            danger
          >
            {t('Common_Data.Delete')}
          </Button>,
          <Button key='2' htmlType='button' shape='round' onClick={() => close()}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
      >
        <Form form={form} id='deleteticketform' onFinish={handleSubmit}>
          {
            <Row align='middle'>
              <Col span={4} className='DeleteIcon'>
                <WarningFilled />
              </Col>
              <Col span={20} className='DeleteText'>
                {t('Tickets.Delete_Ticket')}{' '}
                <strong>#{deleteValues.ticket_number}</strong>?
              </Col>
            </Row>
          }
        </Form>
      </Modal>
    )
  );
});

export default DeleteComponent;
