import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import useStore from '../../../../../store';
import { observer } from 'mobx-react';
import copy from 'copy-to-clipboard';
import { vsmNotify } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';

const TicketDetailsComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    openPeopleViewDrawer,
    openOrganisationViewDrawer,
    openTicketViewDrawer,
    // openTicketEditDrawer
  } = props;
  const {
    AUTH,
    DEALDESKSTORE: {
      deal_data,
      dealDeskTypeArr,
    },
  } = useStore();


  const copyIcon = (ticket_num) => {
    return (
      <img
        className='ml-5'
        style={{ width: '10px' }}
        src={AUTH.GetThemedImage('Copy_icn')}
        alt={`${t('DealDesk.Copy')}`}
        onClick={(event) => {
          copy(ticket_num);
          vsmNotify.success({
            message: `${t('DealDesk.Copied')}!`,
          });
        }}
      />
    )
  }

  const viewTicket_desc = (len = 150) => {
    let ticket_desc = '';
    if (deal_data?.lead_information?.ticket_data?.ticket_description) {
      ticket_desc = deal_data?.lead_information?.ticket_data?.ticket_description;
      if (ticket_desc.length > len) {
        ticket_desc = ticket_desc.substr(0, len);
        ticket_desc += "...";
      }
    }
    return ticket_desc;
  }

  // let OwnerIsLoggin = true // deal_data?.lead_information?.lead_owner_name?.id === AUTH?.user?.id
  // let HolderIsLoggin = true // deal_data?.lead_information?.lead_holder_name?.id === AUTH?.user?.id

  return (
    AUTH && (
      <>
        <div className='orgDetails'>
          <Row gutter={5}>
            <Col span={dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket) ? 10 : 7} className='orgBrife d-flex align-items-center'>
              <ul>
                <li>
                  <span className='title'>{t('DealDesk.Ticket_Number')}</span>
                  <Tooltip
                    placement='topLeft'
                    title={t('DealDesk.View_Ticket')
                      // (OwnerIsLoggin
                      //   ? `${t('DealDesk.Edit_Ticket')} - `
                      //   : HolderIsLoggin
                      //     ? `${t('DealDesk.View_Ticket')} - `
                      //     : ''
                      // )
                      + ' ' +
                      deal_data?.lead_information?.ticket_data?.ticket_number
                    }
                  >
                    <span
                      onClick={() => {
                        openTicketViewDrawer()
                        // if (OwnerIsLoggin) {
                        //   openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true)
                        // } else if (HolderIsLoggin) {
                        //   openTicketViewDrawer()
                        // }
                      }}
                    >
                      {deal_data?.lead_information?.ticket_data?.ticket_number}
                    </span>
                  </Tooltip>
                  <span>
                    {copyIcon(deal_data?.lead_information?.ticket_data?.ticket_number)}
                  </span>
                </li>
                <li>
                  <span className='title'>{t('DealDesk.Organisation_ID')}</span >
                  <Tooltip
                    placement='topLeft'
                    title={deal_data?.lead_information?.sold_organisation?.id}
                  >
                    <span
                      onClick={() => {
                        openOrganisationViewDrawer();
                      }}
                    >
                      {deal_data?.lead_information?.sold_organisation?.id}
                    </span>
                  </Tooltip>
                </li>

                <li>
                  <span className='title'>{t('DealDesk.Organisation')}</span >
                  <Tooltip
                    placement='topLeft'
                    title={
                      deal_data?.lead_information?.sold_organisation?.organisation_name
                    }
                  >
                    <strong
                      onClick={() => {
                        openOrganisationViewDrawer();
                      }}
                    >
                      {deal_data?.lead_information?.sold_organisation?.organisation_name}
                    </strong>
                  </Tooltip>
                </li>
                <li>
                  <span className='title'>{t('DealDesk.Person')}</span >
                  <Tooltip
                    placement='topLeft'
                    title={deal_data?.lead_information?.people_full_name}
                  >
                    <span
                      onClick={() => {
                        openPeopleViewDrawer();
                      }}
                    >
                      {deal_data?.lead_information?.people_full_name}
                    </span>
                  </Tooltip>
                </li>
                {/* )} */}
              </ul>
            </Col>
            <Col span={1} className='text-center'>
              <div className='sep'></div>
            </Col>
            <Col span={12} className='orgBrife d-flex'>
              {/* align-items-center */}
              <ul>
                <li>
                  <b>
                    <Tooltip
                      placement='topLeft'
                      title={deal_data?.lead_information?.ticket_data?.ticket_subject}
                    >
                      <span
                        onClick={() => {
                          openTicketViewDrawer()
                          // if (OwnerIsLoggin) {
                          //   openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true)
                          // } else if (HolderIsLoggin) {
                          //   openTicketViewDrawer()
                          // }
                        }}
                      >
                        {deal_data?.lead_information?.ticket_data?.ticket_subject}
                      </span>
                    </Tooltip>
                  </b>
                </li>
                <li className='ticket-description'>
                  <Tooltip
                    placement='topLeft'
                    title={deal_data?.lead_information?.ticket_data?.ticket_description}
                  >
                    <span
                      onClick={() => {
                        openTicketViewDrawer()
                        // if (OwnerIsLoggin) {
                        //   openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true)
                        // } else if (HolderIsLoggin) {
                        //   openTicketViewDrawer()
                        // }
                      }}
                    >
                      {viewTicket_desc()}
                    </span>
                  </Tooltip>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </>
    )
  );
});

export default TicketDetailsComponent;
