import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import { ActionRenderer, gridOptions } from './GridComponent';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const {t} = useTranslation();
  const {
    CREDEBTCLASSIFICATION,
    AUTH,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth }
  } = useStore();
  const { onCellKeyDown } = props;

  useEffect(() => {
    CREDEBTCLASSIFICATION.getList();
  }, [CREDEBTCLASSIFICATION]);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        {AUTH.checkPermission(28, 'edit') && (
          <Button
            type='text'
            title={`${t('Common_Data.Edit')}`}
            onClick={() => props.openEditDrawer(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
          </Button>)}
        {AUTH.checkPermission(28, 'delete') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            size='small'
            onClick={() => props.openDeleteDrawer(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
          </Button>)}
      </div>
    );
  };
  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('Credebt_Classification.Classification_Name')}`,
        field: 'name',

      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by_name',

      },

      {
        headerName: `${t('Common_Data.Last_Updated_At')}`,
        field: 'updated_at',
        cellRenderer: (params) => {
          return params.data.updated_at ? AUTH.getLocalTime(params.data.updated_at,AUTH.global_fulldate_format) : ' ';
        },

      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',

        maxWidth: 90
        // lockPosition: true,
        // suppressNavigable: true
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',

        width: 110,
        maxWidth: 110,
        filter: false,
        sortable: false,
        pinned: 'right',
        lockPosition: true,
        suppressNavigable: true
      }
    ]
  };

  let columns = [];
  let columnConfig = localStorage.getItem('cte_classification_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data.forEach((element) => {
      cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
      if (cols) {
        columns.push(cols);
      }
    });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  })

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)'
      }}
    >
      <AgGridReact
        rowData={CREDEBTCLASSIFICATION.list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          floatingFilter: false
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer
        }}
        onGridReady={CREDEBTCLASSIFICATION.setupGrid}
        onColumnResized={CREDEBTCLASSIFICATION.onGridChanged}
        onColumnMoved={CREDEBTCLASSIFICATION.onGridChanged}
        onColumnPinned={CREDEBTCLASSIFICATION.onGridChanged}
        onFilterChanged={CREDEBTCLASSIFICATION.onFilterChanged}
        onSortChanged={CREDEBTCLASSIFICATION.onGridChanged}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: false,
          rowHeight: 30
        }}
        onCellKeyDown={onCellKeyDown}
        rowSelection='multiple'
        suppressRowClickSelection={true}
      />
    </div>
  );
});

export default ListComponent;
