import React, { useState, useCallback, useEffect } from 'react';
import { Drawer, Form, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';

import useStore from '../../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages';
import PageNotFound from '../../../PageNotFound';
import Unauthorized from '../../../Unauthorized';
import { convertError } from '../../../../utils/GlobalFunction';

const EditComponent = observer((props) => {
  const [tip, setTip] = useState('Loading');
  const [saving, setSaving] = useState(true);
  const [submitSaving, setSubmitSaving] = useState(false);
  const [savePreviewSaving, setsavePreviewSaving] = useState(false);

  const [countsListReport, setCountsListReport] = useState(false);

  /*---------------START: INITIALISATION STATES---------------*/
  const [isInitiated, setInitiation] = useState(false);
  const [formDataList, setFormDataList] = useState([]); //Dynamic Form Fields
  const [saveFilterList, setsaveFilterList] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  /*---------------START: INITIALISATION STATES---------------*/

  /*---------------START: USED IN LANGUAGE TRANSLATION---------------*/
  const { t } = useTranslation();
  /*---------------START: USED IN LANGUAGE TRANSLATION---------------*/

  //----------GLOBAL STATE DECLARATION---------------//
  const {
    AUTH,
    REPORTSTORE,
    REPORTSTORE: {
      ReadReport,
      initialSummaryValues,
      PreviewData,
      selectedReportFieldsKeys,
      setSelectedReportFieldsKeys,
      setReportColumnSettings,
    },
    AUTH: { checkPermission },
    ORGANISATION,
    PEOPLE,
    ORGSTATUSSTORE,
    LEADSSTORE,
  } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  const [form] = Form.useForm();
  const { visible, setReportID, report_id, openPreviewDataModal } = props;
  const defaultFormDataList = ['report_type'];

  const close = () => {
    props.close();
    setReportID(null);
    REPORTSTORE.previewReportValues = null;
    setInitiation(false);
    setSaving(true);
    setsavePreviewSaving(false);
    setTip('Loading');
    setIndeterminate(false);
    setCheckAll(false);
    setSelectedReportFieldsKeys([]);
  };

  const savePreviewReport = (data = form.getFieldsValue()) => {
    data.form_list = formDataList;
    data = REPORTSTORE.formatFormData({
      ...REPORTSTORE.previewReportValues,
      ...data,
    });
    data.action = 'save_report';
    handleSubmit(data, true);
  };

  const handleSaveAsReport = (data = form.getFieldsValue(), isPreview = false) => {
    setSaving(true);
    if (isPreview) {
      setsavePreviewSaving(true);
    } else {
      setSubmitSaving(true);
    }

    setTip('Saving');
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.action = 'save_report';
    data.form_list = formDataList;
    //data.id = REPORTSTORE.previewReportValues.id;
    data.check_unique_code = true;
    data.grid_columns = selectedReportFieldsKeys;

    REPORTSTORE.AddData(data)
      .then((data) => {
        vsmNotify.success({
          message: vsmTransactionReport.edit,
        });

        props.close();

        if (REPORTSTORE.my_records === 'my') {
          REPORTSTORE.getList({}, AUTH.user.id);
        } else {
          REPORTSTORE.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => {
        setSaving(false);
        setSubmitSaving(false);
        setsavePreviewSaving(false);
        setTip(null);
      });
  };

  const handleSubmit = (data, isPreview = false) => {
    setSaving(true);
    if (isPreview) {
      setsavePreviewSaving(true);
    } else {
      setSubmitSaving(true);
    }

    setTip('Saving');
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.action = 'save_report';
    data.form_list = formDataList;
    data.id = REPORTSTORE.previewReportValues.id;
    data.check_unique_code = true;
    data.grid_columns = selectedReportFieldsKeys;
    data.column_settings = null;
    setReportColumnSettings(null);
    setTip('Saving');

    let users = data.users
    let owners = data.owners
    let holders = data.holders
    let user_groups = data.user_groups
    if (data.report_by === "all") {
      data.users = null;
      data.owners = null;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "users") {
      data.users = users;
      data.owners = null;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "owners") {
      data.users = null;
      data.owners = owners;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "holders") {
      data.users = null;
      data.owners = null;
      data.holders = holders;
      data.user_groups = null;
    } else if (data.report_by === "user_groups") {
      data.users = null;
      data.owners = null;
      data.holders = null;
      data.user_groups = user_groups;
    }

    REPORTSTORE.EditData(data)
      .then((data) => {
        vsmNotify.success({
          message: vsmTransactionReport.edit,
        });
        if (isPreview) {
          openPreviewReport();
        } else {
          props.close();
        }
        if (REPORTSTORE.my_records === 'my') {
          REPORTSTORE.getList({}, AUTH.user.id);
        } else {
          REPORTSTORE.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => {
        setSaving(false);
        setSubmitSaving(false);
        setsavePreviewSaving(false);
        setTip(null);
      });
  };
  const openPreviewReport = () => {
    let data;
    REPORTSTORE.setPreviewReportValues({
      ...REPORTSTORE.previewReportValues,
      ...form.getFieldsValue(),
      form_list: formDataList,
    });
    data = REPORTSTORE.formatFormData(REPORTSTORE.previewReportValues, true, true);
    data.check_unique_code = true;
    data.action = 'preview-data';
    setSaving(true);
    setTip('Loading');
    PreviewData(data)
      .then((data) => {
        openPreviewDataModal(report_id, true);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => {
        setSaving(false);
        setTip(null);
        setSubmitSaving(false);
        setsavePreviewSaving(false);
      });
  };

  /*---------------REPORT READ API AND INITIALISATION TO SET DYNAMIC FORM LIST AND ITS DROPDOWN APIS---------------*/
  const InitaitePreviewReport = useCallback(async () => {
    await setFormDataList(REPORTSTORE.previewReportValues.form_list);
    let previewReportValues = REPORTSTORE.formatFormData(
      REPORTSTORE.previewReportValues,
      false
    );

    if (previewReportValues['nace_category']) {
      ORGANISATION.getNaceCode();
    }
    if (previewReportValues['people_status']) {
      PEOPLE.getPeopleStatus();
    }

    if (previewReportValues['organisation']) {
      ORGANISATION.getAllROrg({
        selected_id: previewReportValues['organisation'],
        mapping_type: 1,
      });
    }
    if (previewReportValues['organisation_name']) {
      ORGANISATION.getAllROrgNameListForReport({
        selected_id: previewReportValues['organisation_name'],
        mapping_type: 1,
      });
    }
    if (previewReportValues['people']) {
      PEOPLE.getAllPeople({
        selected_id: previewReportValues['people'],
        mapping_type: 2,
      });
    }
    if (previewReportValues['people_name']) {
      PEOPLE.getAllPeopleNameDropdown({
        selected_id: previewReportValues['people_name'],
        mapping_type: 2,
      });
    }
    if (previewReportValues['lead_currency_id'] && !LEADSSTORE.dropdown_currency_list) {
      LEADSSTORE.getCurrencyList();
    }
    if (previewReportValues['organisation_type']) {
      ORGANISATION.getOrgRoles();
    }
    if (previewReportValues['people_type']) {
      PEOPLE.getPeopleRoles();
    }
    if (previewReportValues['classification_id']) {
      REPORTSTORE.getClassificationList();
    }
    if (
      previewReportValues &&
      (previewReportValues['users'] ||
        previewReportValues['owners'] ||
        previewReportValues['holders'])
    ) {
      let formdata = {
        inactive: true,
      };
      REPORTSTORE.getReportUsersList(formdata);
    }

    if (previewReportValues['parent_organisation']) {
      ORGANISATION.getMappedOrg();
    }
    if (previewReportValues['organisation_status']) {
      ORGSTATUSSTORE.getOrgStatus();
    }
    if (previewReportValues['filter_id']) {
      let page_type = previewReportValues['filter_type'];
      if (page_type && page_type === 'reminders') {
        page_type = 'reminder';
      } else if (page_type && page_type === 'organisation') {
        page_type = 'organisations';
      }
      AUTH.getSavedGridFilter({ page: page_type, is_from_report: true });
    }

    previewReportValues.selection_ui = 'radiobtn';
    await form.setFieldsValue(previewReportValues);
    await setSaving(false);
  }, [REPORTSTORE, form, ORGANISATION, PEOPLE, ORGSTATUSSTORE, LEADSSTORE, AUTH]);

  const checkInterMediateCheckbox = useCallback(() => {
    let commonFields = ['sum_call_clicks', 'sum_email_clicks', 'sum_total_time_spent', 'sum_modified'];
    // --------  Add Fields to check for All Checkbox -------- //
    if (['all', 'sold'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'sold_created',
        'sold_assigned',
        'sold_sum_total',
        'sum_sss_created',
        'sum_results',
        'sum_inbound_leads',
        'sum_outbound_leads',
        'sum_finance_value',
        'sum_by_history_category',
        'sum_by_stages',
      ];
    }

    if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'tag_created',
        'tag_sum_total',
        'tag_assigned',
        'sum_by_tag_status',
      ];
    }

    if (['all', 'tickets'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'ticket_sum_total',
        'ticket_created',
        'ticket_assigned',
        'sum_by_ticket_status',
      ];
    }

    if (['all', 'reminders'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'reminder_created',
        'reminder_sum_total',
        'reminder_assigned',
        'sum_by_reminder_status',
      ];
    }

    if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'tag_created',
        'tag_sum_total',
        'tag_assigned',
        'sum_by_tag_status',
      ];
    }

    if (['all', 'organisation'].includes(form.getFieldValue('report_on'))) {
      commonFields = [...commonFields, 'organisation_created'];
    }

    if (['all', 'people'].includes(form.getFieldValue('report_on'))) {
      commonFields = [...commonFields, 'people_created'];
    }
    // --------  Add Fields to check for All Checkbox -------- //
    let count = 0;
    let ispartialChecked = false;
    commonFields.map((obj) => {
      if (form.getFieldValue([obj])) {
        count++;
      }
      return null;
    });
    if (count === commonFields.length) {
      ispartialChecked = false;
      form.setFieldsValue({
        select_all_columns: true,
      });
      setCheckAll(true);
    } else if (count > 0) {
      ispartialChecked = true;
      form.setFieldsValue({
        select_all_columns: false,
      });
      setCheckAll(false);
    } else {
      ispartialChecked = false;
      form.setFieldsValue({
        select_all_columns: false,
      });
      setCheckAll(false);
    }
    setIndeterminate(ispartialChecked);
  }, [form]);

  useEffect(() => {
    if (!isInitiated && report_id && visible) {
      setInitiation(true);
      ReadReport(report_id)
        .then(async (data) => {
          await InitaitePreviewReport();
          await setTip(null);
          checkInterMediateCheckbox();
          if (data.report_type === 6) {
            setCountsListReport(true)
          }
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            setSaving('404');
          }
          if (response && response.status === 403) {
            setSaving('403');
          }
        });
    }
  }, [
    isInitiated,
    checkInterMediateCheckbox,
    report_id,
    visible,
    InitaitePreviewReport,
    ReadReport,
  ]);
  /*---------------REPORT READ API AND INITIALISATION TO SET DYNAMIC FORM LIST AND ITS DROPDOWN APIS---------------*/

  return (
    <Drawer
      visible={visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      title={
        `${t('Common_Data.Edit')} ${t('Reports.Report')} -  ` +
        (REPORTSTORE.previewReportValues ? REPORTSTORE.previewReportValues?.name : '')
      }
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          {checkPermission(21, 'preview-data') && (
            <Button
              key='1'
              className='mr-10'
              htmlType='button'
              shape='round'
              disabled={isDisabled}
              onClick={() => {
                openPreviewReport();
              }}
            >
              {t('Reports.Preview')} {t('Reports.Data')}
            </Button>
          )}

          {checkPermission(21, 'edit') ? (
            <>
              {AUTH?.user?.id === props?.editData?.created_by ? (
                <>
                  {checkPermission(21, 'edit') && (
                    <Button
                      key='2'
                      form='editformReport'
                      className='mr-10'
                      htmlType='submit'
                      shape='round'
                      type='primary'
                      disabled={isDisabled}
                      loading={submitSaving}
                    >
                      {t('Common_Data.Save')}
                    </Button>
                  )}
                  {checkPermission(21, 'add') && (
                    <Button
                      key='3'
                      className='mr-10'
                      shape='round'
                      disabled={isDisabled}
                      onClick={() => {
                        savePreviewReport();
                      }}
                      loading={savePreviewSaving}
                    >
                      {t('Common_Data.Save')} & {t('Reports.Preview')}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {checkPermission(21, 'edit') && (
                    <Button
                      key='1'
                      className='mr-10'
                      htmlType='button'
                      shape='round'
                      disabled={isDisabled}
                      onClick={() => {
                        handleSaveAsReport();
                      }}
                    >
                      {t('Common_Data.SaveAs')}
                    </Button>
                  )}
                </>
              )}
            </>
          ) : null}

          <Button shape='round' onClick={close} key='3'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <Spin size='large' spinning={saving} tip={tip}>
        {saving === '404' ? (
          <PageNotFound />
        ) : saving === '403' ? (
          <Unauthorized />
        ) : (
          <div className='w-100' style={{ minHeight: '200px' }}>
            {REPORTSTORE.previewReportValues ? (
              <FormComponent
                initialValues={initialSummaryValues}
                defaultFormDataList={defaultFormDataList}
                form={form}
                formDataList={formDataList}
                setFormDataList={setFormDataList}
                handleSubmit={handleSubmit}
                saveFilterList={saveFilterList}
                setsaveFilterList={setsaveFilterList}
                setDisabled={setDisabled}
                id={'editformReport'}
                checkAll={checkAll}
                isEdit={true}
                setCheckAll={setCheckAll}
                indeterminate={indeterminate}
                setIndeterminate={setIndeterminate}
                countsListReport={countsListReport}
                setCountsListReport={setCountsListReport}
              />
            ) : null}
          </div>
        )}
      </Spin>
    </Drawer>
  );
});
export default EditComponent;
