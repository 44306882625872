import React from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(({ handleSubmit, onChange, form }) => {
  const {t} = useTranslation()
  const onFinish = (data) => {
    handleSubmit(data);
  };
  return (
    <Form form={form} labelCol={{ span: 4 }} id='addeditform' onFinish={onFinish}>
      <Row gutter={15}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <InputComponent
            name='timezone'
            label={`${t('Users.Timezone')}:`}
            required
            placeholder={`${t('Common_Data.Select')} ${t('Users.Timezone')}`}
            type='select'
            tooltip={`${t('Common_Data.Select')} ${t('Users.Timezone')}`}
            options={{
              values: [
                {
                  value: 'UTC+01',
                  text: `(UTC+01:00) ${t('Users.Dublin')}, ${t('Users.Edinburgh')},...`
                },
                {
                  value: 'UTC+01',
                  text: `(UTC+01:00) ${t('Users.Dublin')}, ${t('Users.Edinburgh')},...`
                },
                {
                  value: 'UTC+01',
                  text: `(UTC+01:00) ${t('Users.Dublin')}, ${t('Users.Edinburgh')},...`
                }
              ]
            }}
            //rules={vsmBillingInfo.validation.selectPlan}
          />
        </Col>
      </Row>
    </Form>
  );
});

export default FormComponent;
