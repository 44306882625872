import React from 'react'
import { Button } from 'antd'
import AlgorithmBuilder from '..'
import { useTranslation } from 'react-i18next'

const LogicalBlock = (props) => {
	const {t} = useTranslation();
	const addBlock = () => {
		var key = 0
		if (props.formula.child) {
			props.formula.child.map((item) => {
				if (item.key >= key) {
					key = item.key + 1
				}
				return true
			})
		}
		props.formula.child.push({
			key,
			block: 'Logical-Block',
			component: 'Formula',
			operand1: { key: 0, component: 'Custom' },
			operand2: { key: 0, component: 'Custom' },
			operator: { key: 0, value: '=' }
		})
		props.setFormula(props.main)
	}
	const removeBlock = (key) => {
		if (props.formula.child) {
			props.formula.child = props.formula.child.filter((x) => {
				return (x.key !== key)
			})
		}
		props.setFormula(props.main)
	}
	return (
		<>
			{
				(props.formula.child.length > 0) ? props.formula.child.map((item, index) => {
					if (props.formula.child.length > 2) {
						return (
							<AlgorithmBuilder removeBlock={removeBlock} item_key={item.key} key={index} main={props.main} formula={item} setFormula={props.setFormula} />
						)
					}
					return (
						<AlgorithmBuilder key={index} main={props.main} formula={item} setFormula={props.setFormula} />
					)
				}) : null
			}
			<div className="text-right"><Button onClick={addBlock}>{t('DealBuilder.Add_Condition_Block')}</Button></div>
		</>
	)
}

export default LogicalBlock
