import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import ListComponent from '../../LeadListing/component/ListComponent';
import ViewComponent from '../../LeadListing/component/ViewComponent';
import DeleteComponent from '../../LeadListing/component/DeleteComponent';
import RestoreComponent from './RestoreComponent';
import { useTranslation } from 'react-i18next';

const Leads = observer((props) => {
  const { t } = useTranslation()
  const { WasteManagement } = props;
  const { LEADSSTORE, WASTESTORE, TimeTrackerStore } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restoreValues, setRestoreValues] = useState(false);

  // Open & Close Restore Model function
  const openRestoreModal = (data) => {
    setRestoreValues(data)
    setRestoreModal(true);
  };

  const closeRestoreModal = () => setRestoreModal(false);

  // Open & Close Delete Model function
  const openDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteDrawer(true);
  };

  const closeDeleteDrawer = () => setDeleteDrawer(false);

  // Open & Close View Drawer function
  const openViewDrawer = (data) => {
    LEADSSTORE.setViewValues(data);
    setViewDrawer(true);
  };

  const closeViewDrawer = () => setViewDrawer(false);

  useEffect(() => {
    if (viewDrawer) {
      TimeTrackerStore.setStopForWaste(true);
    } else {
      TimeTrackerStore.setStopForWaste(false);
    }
  }, [TimeTrackerStore, viewDrawer]);

  var formId;

  if (!LEADSSTORE.current_list_path) {
    formId = "restoreleadform"
  } else if (LEADSSTORE.current_list_path === "/leads/opportunities") {
    formId = "restoreoppform"
  } else if (LEADSSTORE.current_list_path === "/leads/deals") {
    formId = "restoredealform"
  } else {
    formId = "restoreform"
  }

  return (
    <>
      <ListComponent
        WasteManagement={WasteManagement}
        openViewDrawer={openViewDrawer}
        openDeleteDrawer={openDeleteDrawer}
        openRestoreModal={openRestoreModal}
      />
      <ViewComponent visible={viewDrawer} isWaste={true} close={closeViewDrawer} />
      <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} getWasteCount={WASTESTORE.getWasteCount} lineText={`${t('Waste.Permanently_Remove_Record')}?`} />
      <RestoreComponent visible={restoreModal} close={closeRestoreModal} store={LEADSSTORE} getWasteCount={WASTESTORE.getWasteCount} id={formId}
        currentValues={restoreValues} lineText={`${t('Common_Data.Lead')}`} title={`${t('Common_Data.Restore')} ${t('Common_Data.Lead')} - #${restoreValues && restoreValues.id} - ${restoreValues && restoreValues.lead_owner_name && restoreValues.lead_owner_name.full_name}`} />
    </>
  );
});

export default Leads;
