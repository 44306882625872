import Axios from 'axios';
import { decorate, observable, action } from 'mobx';

export default class SssLeadLibraryStore {
  agStructureLibraryGrid = null;
  agScreenLibraryGrid = null;
  agScrutiniseLibraryGrid = null;

  sss_list = null;

  strucureAutosaveData = null;
  screenAutosaveData = null;
  scrutiniseAutosaveData = null;
  scheduleAutoSaveData = null;

  apiCalled = false;

  notifyStructure = null; // NOTIFY STRUCTURE AUTOSAVE
  notifyScrutinise = null; // NOTIFY Scrutinise AUTOSAVE
  notifyScreen = null; // NOTIFY SCREEN AUTOSAVE
  notifySchedule = null; // NOTIFY SCHEDULE AUTOSAVE
  notifyScheduleLIB = null;

  structureLibrary = null;
  screenLibrary = null;
  scrutiniseLibrary = null;
  shortfallLibrary = null;
  schedule_library_data = null;

  structureAutoSaveDataLIB = null; // LIBRARY AUTOSAVE VARIALBE-STRUCTURE
  screenAutoSaveDataLIB = null; // LIBRARY AUTOSAVE DATA-SCREEN
  scrutiniseAutoSaveDataLIB = null; // LIBRARY AUTOSAVE DATA-Scrutinise
  scheduleAutoSaveDataLIB = null;

  structureAutosaveUpdatedLIB = null;
  screenAutosaveUpdatedLIB = null;
  scrutiniseAutosaveUpdatedLIB = null;
  autoSaveUpdatedData = null;

  structureAutosaveUpdated = null; //------DEALDESK -USED TO CLEAR VERY FIRST AUTOSAVE DATA---//
  scrunitiseAutosaveUpdated = null; //------DEALDESK -USED TO CLEAR VERY FIRST AUTOSAVE DATA---//
  screenAutosaveUpdated = null; //------DEALDESK -USED TO CLEAR VERY FIRST AUTOSAVE DATA---//
  scheduleAutosaveUpdated = null; //------DEALDESK -USED TO CLEAR VERY FIRST AUTOSAVE DATA---//

  dealDeskRightSectionActiveTab = null;

  dealdeskSecLoader = false;

  libraryGridLoader = true; //--------------------------USED TO SHOW LOADER IN LIST GRID OF LIBRARY -------//

  structureListGrid = null;
  screenListGrid = null;
  scheduleListGrid = null;
  scrunitiseListGrid = null;
  shortfallListGrid = null;
  scoreListGrid = null;
  agGridRef = null;

  //------------Called Ever 2 Min -- after note sync data called//
  setSyncData = (syncResData) => {
    if (syncResData && syncResData.length > 0) {
      let allDataList = [];
      syncResData.forEach((item) => {
        if (!item.autosave || (item.autosave && item.draft_description !== null)) {
          allDataList.push(item);
        }
      });
      this.sss_list = allDataList;
    }
  };
  fetchListData = (id, overWriteTemplate = false, libraryPayload = {}) => {
    if (id) {
      if (overWriteTemplate) {
      } else {
        // if (this.apiCalled && id) {
        //   return false;
        // }
        this.dealdeskSecLoader = true;
        // this.apiCalled = true;
      }
    } else {
      this.libraryGridLoader = true;
    }

    let APIURL = `deal-desk/leads-sss/list/${id}`;

    return Axios.post(APIURL, libraryPayload ? libraryPayload : {})
      .then(({ data }) => {
        let structureLibraryData = [];
        let screenLibraryData = [];
        let scrunitiseLibraryData = [];
        let scheduleLibraryData = [];
        let shortfallLibraryData = [];

        let currentLoggedUserID = parseInt(localStorage.getItem('user_name'));

        if (id) {
          if (data?.data && data.data.length > 0) {
            this.sss_list = data.data;
            //----------------------START:AUTOSAVE SET DATA-------------------//
            data?.data.forEach((item) => {
              if (currentLoggedUserID === item.created_by) {
                if (
                  !this.strucureAutosaveData &&
                  item.autosave &&
                  item.type === 1 &&
                  !item.draft_description
                ) {
                  this.strucureAutosaveData = item;
                  this.structureAutosaveUpdated = item;
                }
                if (
                  !this.screenAutosaveData &&
                  item.autosave &&
                  item.type === 3 &&
                  !item.draft_description
                ) {
                  this.screenAutosaveData = item;
                  this.screenAutosaveUpdated = item;
                }
                if (
                  !this.scheduleAutoSaveData &&
                  item.autosave &&
                  item.type === 2 &&
                  !item.draft_description
                ) {
                  this.scheduleAutoSaveData = item;
                  this.scheduleAutosaveUpdated = item;
                }
                if (
                  !this.scrutiniseAutosaveData &&
                  item.autosave &&
                  item.type === 4 &&
                  !item.draft_description
                ) {
                  this.scrutiniseAutosaveData = item;
                  this.scrunitiseAutosaveUpdated = item;
                }
              }
            });
            //----------------------END:AUTOSAVE SET DATA-------------------//
            // this.getCurrentAutoSaveSchedule(data.data);
          }
          this.dealdeskSecLoader = false;
        } else {
          if (libraryPayload && libraryPayload.type) {
            if (libraryPayload.type) {
              if (data?.data && data.data.length > 0) {
                if (libraryPayload.type === 3) {
                  screenLibraryData = data.data.filter(
                    (e) =>
                      e.type === 3 &&
                      e.autosave === false &&
                      (e.is_library === 1 || e.is_library === true)
                  );
                  this.screenLibrary = screenLibraryData;
                }
                if (libraryPayload.type === 4) {
                  scrunitiseLibraryData = data.data.filter(
                    (e) =>
                      e.type === 4 &&
                      e.autosave === false &&
                      (e.is_library === 1 || e.is_library === true)
                  );
                  this.scrutiniseLibrary = scrunitiseLibraryData;
                }
                if (libraryPayload.type === 5) {
                  shortfallLibraryData = data.data.filter(
                    (e) =>
                      e.type === 5 &&
                      e.autosave === false &&
                      (e.is_library === 1 || e.is_library === true)
                  );
                  this.shortfallLibrary = shortfallLibraryData;
                }

                if (libraryPayload.type === 1) {
                  structureLibraryData = data.data.filter(
                    (e) =>
                      e.type === 1 &&
                      e.autosave === false &&
                      (e.is_library === 1 || e.is_library === true)
                  );
                  this.structureLibrary = structureLibraryData;
                }
                if (libraryPayload.type === 2) {
                  scheduleLibraryData = data.data.filter(
                    (e) =>
                      e.type === 2 &&
                      e.autosave === false &&
                      (e.is_library === 1 || e.is_library === true)
                  );
                  this.schedule_library_data = scheduleLibraryData;
                }
              }
            }
          } else {
            if (data?.data && data.data.length > 0) {
              structureLibraryData = data.data.filter(
                (e) =>
                  e.type === 1 &&
                  e.autosave === false &&
                  (e.is_library === 1 || e.is_library === true)
              );
              screenLibraryData = data.data.filter(
                (e) =>
                  e.type === 3 &&
                  e.autosave === false &&
                  (e.is_library === 1 || e.is_library === true)
              );
              scrunitiseLibraryData = data.data.filter(
                (e) =>
                  e.type === 4 &&
                  e.autosave === false &&
                  (e.is_library === 1 || e.is_library === true)
              );
              shortfallLibraryData = data.data.filter(
                (e) =>
                  e.type === 5 &&
                  e.autosave === false &&
                  (e.is_library === 1 || e.is_library === true)
              );
              scheduleLibraryData = data.data.filter(
                (e) =>
                  e.type === 2 &&
                  e.autosave === false &&
                  (e.is_library === 1 || e.is_library === true)
              );
              //----------------------START:AUTOSAVE SET DATA-------------------//
              data?.data.forEach((item) => {
                if (item.is_template && item.autosave) {
                  if (currentLoggedUserID === item.created_by) {
                    if (!this.structureAutoSaveDataLIB && item.type === 1) {
                      this.structureAutoSaveDataLIB = item;
                      this.structureAutosaveUpdatedLIB = item;
                    }
                    if (!this.screenAutoSaveDataLIB && item.type === 3) {
                      this.screenAutoSaveDataLIB = item;
                      this.screenAutosaveUpdatedLIB = item;
                    }
                    if (!this.scrutiniseAutoSaveDataLIB && item.type === 4) {
                      this.scrutiniseAutoSaveDataLIB = item;
                      this.scrutiniseAutosaveUpdatedLIB = item;
                    }

                    if (!this.scheduleAutoSaveDataLIB && item.type === 2) {
                      this.scheduleAutoSaveDataLIB = item;
                    }
                  }
                }
              });
              //----------------------END:AUTOSAVE SET DATA-------------------//
            }

            this.structureLibrary = structureLibraryData;
            this.screenLibrary = screenLibraryData;
            this.schedule_library_data = scheduleLibraryData;
            this.scrutiniseLibrary = scrunitiseLibraryData;
            this.shortfallLibrary = shortfallLibraryData;
          }

          this.libraryGridLoader = false;
        }

        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  addSssData = (id, payload) => {
    let APIURL = `deal-desk/leads-sss/add/${id}`;

    return Axios.post(APIURL, payload)
      .then(({ data }) => {
        if (payload.autosave && payload.type === 1) {
          this.notifyStructure = true;
          if (payload?.isSaveFromLibrary) {
            this.structureAutosaveUpdatedLIB = data.data;
            this.structureAutoSaveDataLIB = data.data;
          } else {
            this.structureAutosaveUpdated = data.data;
          }
        }
        if (payload.autosave && payload.type === 2) {
          if (payload?.isSaveFromLibrary) {
            this.notifyScheduleLIB = true;
            this.scheduleAutoSaveDataLIB = data.data;
          } else {
            this.notifySchedule = true;
            this.scheduleAutosaveUpdated = data.data;
          }
        }
        if (payload.autosave && payload.type === 3) {
          this.notifyScreen = true;
          if (payload?.isSaveFromLibrary) {
            this.screenAutosaveUpdatedLIB = data.data;
            this.screenAutoSaveDataLIB = data.data;
          } else {
            this.screenAutosaveUpdated = data.data;
          }
        }

        if (payload.autosave && payload.type === 4) {
          this.notifyScrutinise = true;
          if (payload?.isSaveFromLibrary) {
            this.scrutiniseAutosaveUpdatedLIB = data.data;
            this.scrutiniseAutoSaveDataLIB = data.data;
          } else {
            this.scrunitiseAutosaveUpdated = data.data;
          }
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  deleteSssData = (id, payload = {}) => {
    let APIURL = `deal-desk/leads-sss/delete/${id}`;

    return Axios.post(APIURL, payload)
      .then(({ data }) => {
        if (payload?.is_deal_desk) {
          if (this.sss_list) {
            let index = this.sss_list.findIndex((x) => x.id === id);
            if (index !== -1) {
              this.sss_list.splice(index, 1);
            }
          }
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getCurrentAutoSaveSchedule = () => {
    this.scheduleAutoSaveData = null;
    let currentLoggedUserID = parseInt(localStorage.getItem('user_name'));

    let scheduleData = this.sss_list || [];

    if (scheduleData && scheduleData.length > 0) {
      scheduleData.forEach((item) => {
        if (
          item.autosave &&
          currentLoggedUserID === item.created_by &&
          item.type === 2 &&
          !item.draft_description
        ) {
          if (!this.scheduleAutoSaveData) {
            this.scheduleAutoSaveData = item;
            this.scheduleAutosaveUpdated = item;
          }
        }
      });
    }
  };

  setStrucureAutosaveData = (data) => {
    this.strucureAutosaveData = data;
  };

  setScrutiniseAutosaveData = (data) => {
    this.scrutiniseAutosaveData = data;
  };

  setScreenAutosaveData = (data) => {
    this.screenAutosaveData = data;
  };
  setStructureAutosaveUpdated = (data) => {
    this.structureAutosaveUpdated = data;
  };

  setScrunitiseAutosaveUpdated = (data) => {
    this.scrunitiseAutosaveUpdated = data;
  };

  setScreenAutosaveUpdated = (data) => {
    this.screenAutosaveUpdated = data;
  };

  setScheduleAutosaveUpdated = (data) => {
    this.scheduleAutosaveUpdated = data;
  };

  setScreenAutosaveData = (data) => {
    this.screenAutosaveData = data;
  };

  resetAutosaveData = () => {
    this.sss_list = null;
    this.apiCalled = false;
    this.screenAutosaveData = null;
    this.strucureAutosaveData = null;
    this.scrutiniseAutosaveData = null;
    this.scheduleAutoSaveData = null;

    this.screenAutosaveUpdated = null;
    this.structureAutosaveUpdated = null;
    this.scrunitiseAutosaveUpdated = null;
    this.scheduleAutosaveUpdated = null;
  };

  resetScheduleAutoSaveData = () => {
    this.scheduleAutoSaveData = null;
    this.scheduleAutosaveUpdated = null;
  };

  setNotifyStructure = (data) => {
    this.notifyStructure = data;
  };

  setNotifyScrutinise = (data) => {
    this.notifyScrutinise = data;
  };

  setNotifyScreen = (data) => {
    this.notifyScreen = data;
  };

  setNotifySchedule = (data) => {
    this.notifySchedule = data;
  };

  setNotifyScheduleLIB = (data) => {
    this.notifyScheduleLIB = data;
  };

  /**USED TO ADD- UPDATE NODE */
  addUpdateNoteList = (data, isEdit) => {
    let existingSSSList = this.sss_list;
    if (isEdit) {
      let foundIndex = existingSSSList.findIndex((x) => x.id === data.id);
      if (foundIndex >= 0) {
        existingSSSList[foundIndex] = data;
      }
    } else {
      if (existingSSSList && existingSSSList.length > 0) {
        existingSSSList.unshift(data);
      } else {
        existingSSSList = [];
        existingSSSList.push(data);
      }
    }
    this.sss_list = existingSSSList;
  };

  // Setup grid and set column size to autosize
  setupStructureLibraryGrid = (params) => {
    this.agStructureLibraryGrid = params;
  };

  // Setup grid and set column size to autosize
  setupScrutiniseLibraryGrid = (params) => {
    this.agScrutiniseLibraryGrid = params;
  };

  setupScreenLibraryGrid = (params) => {
    this.agScreenLibraryGrid = params;
  };

  setStructureAutoSaveDataLIB = (data) => {
    this.structureAutoSaveDataLIB = data;
  };
  setScreenAutoSaveDataLIB = (data) => {
    this.screenAutoSaveDataLIB = data;
  };

  setScrutiniseAutoSaveDataLIB = (data) => {
    this.scrutiniseAutoSaveDataLIB = data;
  };

  clearAutosaveData = (type, isLibrary = false) => {
    if (type === 1) {
      if (isLibrary) {
        this.structureAutoSaveDataLIB = null;
        this.structureAutosaveUpdatedLIB = null;
      } else {
        this.strucureAutosaveData = null;
      }
    }
    if (type === 4) {
      if (isLibrary) {
        this.scrutiniseAutoSaveDataLIB = null;
        this.scrutiniseAutosaveUpdatedLIB = null;
      } else {
        this.scrutiniseAutosaveData = null;
      }
    }
    if (type === 3) {
      if (isLibrary) {
        this.screenAutoSaveDataLIB = null;
        this.screenAutosaveUpdatedLIB = null;
      } else {
        this.screenAutosaveData = null;
      }
    }
    if (type === 2) {
      if (isLibrary) {
        this.scheduleAutoSaveDataLIB = null;
        this.autoSaveUpdatedData = null;
      } else {
        this.scheduleAutoSaveData = null;
      }
    }
  };

  setDealDeskRightSectionActiveTab = (data) => {
    this.dealDeskRightSectionActiveTab = data;
  };

  /**Setup grid on onGridReady */
  setupScrunitiseLibraryAgGrid = (params) => {
    this.scrunitiseListGrid = params;
    let columnConfig = localStorage.getItem('scrutinise_library_grid');
    if (this.scrunitiseListGrid && this.scrunitiseListGrid.columnApi && columnConfig) {
      this.scrunitiseListGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };
  setupScreenLibraryAgGrid = (data) => {
    this.screenListGrid = data;
    let columnConfig = localStorage.getItem('screen_library_grid');
    if (this.screenListGrid && this.screenListGrid.columnApi && columnConfig) {
      this.screenListGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };
  setupStrucureLibraryAgGrid = (data) => {
    this.structureListGrid = data;
    let columnConfig = localStorage.getItem('structure_library_grid');
    if (this.structureListGrid && this.structureListGrid.columnApi && columnConfig) {
      this.structureListGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };
  setupScheduleLibraryAgGrid = (data) => {
    this.scheduleListGrid = data;
    let columnConfig = localStorage.getItem('schedule_library_grid');
    if (this.scheduleListGrid && this.scheduleListGrid.columnApi && columnConfig) {
      this.scheduleListGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };
  setupScoreLibraryAgGrid = (data) => {
    this.scoreListGrid = data;
    let columnConfig = localStorage.getItem('score_library_grid');
    if (this.scoreListGrid && this.scoreListGrid.columnApi && columnConfig) {
      this.scoreListGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };
  setupShortfallLibraryAgGrid = (data) => {
    this.shortfallListGrid = data;
    let columnConfig = localStorage.getItem('shortfall_library_grid');
    if (this.shortfallListGrid && this.shortfallListGrid.columnApi && columnConfig) {
      this.shortfallListGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };  

  //----------------------LIBRARY GRID COLUMN CHANGES---------------------//
  onGridChangedStruct = (params, type) => {
    localStorage.setItem(
      `${type}_library_grid`,
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  onGridChangedScheulde = (params) => {
    localStorage.setItem(
      'schedule_library_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };


  /** To get the data of library by it's type ot key of the tab*/
  getLibraryByType = (e) => {
    return (e === "6") ? this.scrutiniseLibrary :
      ((e === "4") ? this.screenLibrary :
        ((e === "3") ? this.structureLibrary :
          ((e === "5") ? this.schedule_library_data :
            (((e === "7") ? this.shortfallLibrary : null)))))
  }

  /** To Fetch the library data by its type or key of library */
  fetchLibraryByType = (e, library = null) => {
    let type = null;
    if (library) {
      type = (e === "6" && !library) ? 4 :
        ((e === "4" && !library) ? 3 :
          ((e === "3" && !library) ? 1 :
            ((e === "5" && !library) ? 2 :
              (((e === "7" && !library) ? 5 : null)))));
    } else {
      type = (e === "6" || e === "scrutinise") ? 4 :
        ((e === "4" || e === "screen") ? 3 :
          ((e === "3" || e === "structure") ? 1 :
            ((e === "5" || e === "schedule") ? 2 :
              (((e === "7" || e === "shortfall") ? 5 : null)))));
    }

    type && this.fetchListData(0, false, { type: type })
  }
}

decorate(SssLeadLibraryStore, {
  sss_list: observable.shallow,
  strucureAutosaveData: observable,
  scrutiniseAutosaveData: observable,
  screenAutosaveData: observable,
  scheduleAutoSaveData: observable,
  apiCalled: observable,
  notifyStructure: observable,
  notifyScrutinise: observable,
  notifyScreen: observable,
  notifySchedule: observable,
  notifyScheduleLIB: observable,

  structureLibrary: observable,
  screenLibrary: observable,
  scrutiniseLibrary: observable,
  shortfallLibrary: observable,
  agStructureLibraryGrid: observable,
  agScreenLibraryGrid: observable,
  agScrutiniseLibraryGrid: observable,

  structureAutoSaveDataLIB: observable,
  screenAutoSaveDataLIB: observable,
  structureAutosaveUpdatedLIB: observable,
  scrutiniseAutosaveUpdatedLIB: observable,
  screenAutosaveUpdatedLIB: observable,
  scrutiniseAutoSaveDataLIB: observable,
  schedule_library_data: observable,
  scheduleAutoSaveDataLIB: observable,
  autoSaveUpdatedData: observable,

  structureAutosaveUpdated: observable,
  scrunitiseAutosaveUpdated: observable,
  screenAutosaveUpdated: observable,
  scheduleAutosaveUpdated: observable,

  dealDeskRightSectionActiveTab: observable,
  dealdeskSecLoader: observable,
  libraryGridLoader: observable,

  scheduleListGrid: observable,
  screenListGrid: observable,
  structureListGrid: observable,
  scrunitiseListGrid: observable,
  shortfallListGrid: observable,
  scoreListGrid: observable,
  agGridRef: observable,
  // agGridRefSchedule: observable,
  // agGridRefScore: observable,

  fetchListData: action,
  addSssData: action,
  setStrucureAutosaveData: action,
  setScreenAutosaveData: action,
  resetAutosaveData: action,
  addUpdateNoteList: action,
  setNotifyStructure: action,
  setNotifyScreen: action,
  setNotifySchedule: action,
  setupStructureLibraryGrid: action,
  setupScrutiniseLibraryGrid: action,
  setupScreenLibraryGrid: action,
  deleteSssData: action,
  getCurrentAutoSaveSchedule: action,
  setStructureAutoSaveDataLIB: action,
  setScreenAutoSaveDataLIB: action,
  setScrutiniseAutoSaveDataLIB: action,
  setNotifyScheduleLIB: action,
  clearAutosaveData: action,
  resetScheduleAutoSaveData: action,
  setSyncData: action,
  setStructureAutosaveUpdated: action,
  setScreenAutosaveUpdated: action,
  setScheduleAutosaveUpdated: action,
  setDealDeskRightSectionActiveTab: action,
  setupScheduleLibraryAgGrid: action,
  setupScreenLibraryAgGrid: action,
  setupScrunitiseLibraryAgGrid: action,
  setupShortfallLibraryAgGrid: action,
  setupScoreLibraryAgGrid: action,
  setupStrucureLibraryAgGrid: action,
  deleteLibraryRecord: action,
  deleteScreenLibraryRecord: action,
  deleteScrutiniseLibraryRecord: action,
  deleteShortfallLibraryRecord: action,
  deleteScoreLibraryRecord: action,
  deleteStructureLibraryRecord: action,
  setScrutiniseAutosaveData: action,
  setNotifyScrutinise: action,
  setScrunitiseAutosaveUpdated: action,
  //----------------------LIBRARY GRID COLUMN CHANGES---------------------//
  onGridChangedStruct: action,
  onGridChangedScheulde: action,
  handleResetScreen: action,
  handleResetScrutinise: action,
  handleResetStructure: action,
  handleResetSchedule: action,
  handleResetShortfall: action,
  handleResetScore: action,
  //----------------------LIBRARY GRID COLUMN CHANGES---------------------//
  handleResetLibraryData: action,
  getLibraryByType: action,
  fetchLibraryByType: action,
});
