import axios from 'axios';
import { decorate, observable, action } from 'mobx';

export default class SearchStore {
  resultCount = 0;
  searchTerm = null;
  viewAllSreachPage = false;
  searchCount = {
    all_count: null,
    ticket_count: null,
    master_ticket_count: null,
    tag_count: null,
    reminder_count: null,
    people_count: null,
    organisation_count: null,
    deal_count: null,
    opportunity_count: null,
    active_count: null,
    lead_count: null,
    notes_count: null,
    knowledge_count: null,
    Library_count: null,
    Key_note_count: null,
    count: 0,
  };
  searchLoader = {
    all_loader: true,
    ticket_loader: true,
    tag_loader: true,
    reminder_loader: true,
    people_loader: true,
    organisation_loader: true,
    deal_loader: true,
    opportunity_loader: false,
    active_loader: true,
    lead_loader: true,
    notes_loader: true,
    knowledge_loader: true,
    library_loader: true,
    key_notes_loader: true,
    master_loader: true,
  };

  plainOptions = [
    { label: 'All', value: 'all', count: 'all_count', loader: 'all_loader ' },
    { label: 'People', value: 'People', count: 'people_count', loader: 'people_loader' },
    { label: 'Organisations', value: 'Organisations', count: 'organisation_count', loader: 'organisation_loader', },

    { label: 'Notes', value: 'Notes', count: 'count', disabled: true },
    { label: 'Leads', value: 'Leads', count: 'lead_count', loader: 'lead_loader' },
    { label: 'Deals', value: 'Deals', count: 'deal_count', loader: 'deal_loader' },

    { label: 'Master', value: 'Master', count: 'master_ticket_count', loader: 'master_loader' },
    { label: 'Tickets', value: 'Tickets', count: 'ticket_count', loader: 'ticket_loader', },
    { label: 'Tags', value: 'Tags', count: 'tag_count', loader: 'tag_loader' },

    { label: 'Reminders', value: 'Reminders', count: 'reminder_count', loader: 'reminder_loader', },
    { label: 'Events', value: 'Events', count: 'count', disabled: true },
    { label: 'Calendar', value: 'Calendar', count: 'count', disabled: true },

    { label: 'History', value: 'History', count: 'notes_count', loader: 'notes_loader' },
    { label: 'Knowledge', value: 'Knowledge', count: 'knowledge_count', loader: 'knowledge_loader', },
    { label: 'Library', value: 'Library', count: 'Library_count', loader: 'library_loader', },
    { label: 'Key Notes', value: 'Key Notes', count: 'Key_note_count', loader: 'key_notes_loader', },


    { label: 'Folders', value: 'Folders', count: 'count', disabled: true },
    { label: 'Files', value: 'Folders-Files', count: 'count', disabled: true },
    { label: 'Links', value: 'Folders-Links', count: 'count', disabled: true },
    // { label: 'Opportunities', value: 'Opportunities', count: 'opportunity_count', loader: 'opportunity_loader', disabled: true, },
  ];

  setViewAllSreachPage = (data) => {
    this.viewAllSreachPage = data;
  }
  setResultCount = (data) => {
    this.resultCount = data;
  };

  setIntialCount = () => {
    this.searchCount = {
      all_count: null,
      ticket_count: null,
      master_ticket_count: null,
      tag_count: null,
      reminder_count: null,
      people_count: null,
      organisation_count: null,
      deal_count: null,
      opportunity_count: null,
      active_count: null,
      lead_count: null,
      notes_count: null,
      knowledge_count: null,
      Library_count: null,
      Key_note_count: null,
    };

    this.searchLoader = {
      all_loader: true,
      ticket_loader: true,
      master_loader: true,
      tag_loader: true,
      reminder_loader: true,
      people_loader: true,
      organisation_loader: true,
      deal_loader: true,
      opportunity_loader: false,
      active_loader: true,
      lead_loader: true,
      notes_loader: true,
      knowledge_loader: true,
      library_loader: true,
      key_notes_loader: true,
    };
  };
  updateSearchCount = (data) => {
    this.searchCount = { ...this.searchCount, ...data };
  };

  updateSearchCount = (data) => {
    this.searchCount = { ...this.searchCount, ...data };
  };


  setSearchCount = (data, type) => {
    this.searchTerm = data.search_for;
    let apiUrl = null;
    if (type === 'all') {
      apiUrl = 'search/all/count';
    }
    if (type === 'tag') {
      apiUrl = 'search/tag/count';
    }
    if (type === 'reminder') {
      apiUrl = 'search/reminder/count';
    }
    if (type === 'ticket') {
      apiUrl = 'search/ticket/count';
    }
    if (type === 'master') {
      apiUrl = "search/master-ticket/count"
    }
    if (type === 'people') {
      apiUrl = 'search/people/count';
    }
    if (type === 'opporunity') {
      apiUrl = 'search/opporunity/count';
    }
    if (type === 'active') {
      apiUrl = 'search/active/count';
    }
    if (type === 'deal') {
      apiUrl = 'search/deal/count';
    }
    if (type === 'organisation') {
      apiUrl = 'search/organisation/count';
    }
    if (type === 'history') {
      apiUrl = 'search/notes/count';
    }
    if (type === 'knowledge') {
      apiUrl = 'search/knowledge/count/';
    }
    if (type === 'library') {
      apiUrl = 'search/library/count/';
    }
    if (type === 'key-notes') {
      apiUrl = 'search/keynote/count/'
    }
    return axios.post(apiUrl, data).then(({ data }) => {
      this.searchCount = { ...this.searchCount, ...data };

      if (type === 'tag' && data.tag_count >= 0) {
        this.searchLoader.tag_loader = false;
      }
      if (type === 'reminder' && data.reminder_count >= 0) {
        this.searchLoader.reminder_loader = false;
      }
      if (type === 'ticket' && data.ticket_count >= 0) {
        this.searchLoader.ticket_loader = false;
      }
      if (type === 'master' && data.master_ticket_count >= 0) {
        this.searchLoader.master_loader = false;
      }
      if (type === 'people' && data.people_count >= 0) {
        this.searchLoader.people_loader = false;
      }
      if (type === 'opporunity' && data.opportunity_count >= 0) {
        this.searchLoader.opportunity_loader = false;
      }
      if (type === 'deal' && data.deal_count >= 0) {
        this.searchLoader.deal_loader = false;
      }
      if (type === 'organisation' && data.organisation_count >= 0) {
        this.searchLoader.organisation_loader = false;
      }
      if (type === 'history' && data.notes_count >= 0) {
        this.searchLoader.notes_loader = false;
      }
      if (type === 'active' && data.lead_count >= 0) {
        this.searchLoader.lead_loader = false;
      }
      if (type === 'knowledge' && data.knowledge_count >= 0) {
        this.searchLoader.knowledge_loader = false;
      }
      if (type === 'library' && data.Library_count >= 0) {
        this.searchLoader.library_loader = false;
      }
      if (type === 'key-notes' && data.Key_note_count >= 0) {
        this.searchLoader.key_notes_loader = false;
      }
      return data && data;
    });
  };


}
decorate(SearchStore, {
  resultCount: observable,
  plainOptions: observable,
  searchCount: observable,
  searchTerm: observable,
  searchLoader: observable,
  viewAllSreachPage: observable,

  setResultCount: action,
  setSearchCount: action,
  setIntialCount: action,
  setViewAllSreachPage: action,
  updateSearchCount: action,
});
