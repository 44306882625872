import React from 'react';
import { observer } from 'mobx-react';
import Listing from './component/Listing';

const Structure = observer((props) => {
  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }

  return (
    <>
      <div className='structureRight'>
        <Listing saveType={props.type} />
      </div>
    </>
  );
});

export default Structure;
