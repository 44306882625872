import React from 'react';
import { Checkbox, Form, Input, InputNumber } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useStore from '../../../../../store';
import {
  calculateHeight,
  firstPositionItemKey,
} from '../../../../../utils/GlobalFunction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../../../component/DatePickerCostume';

const { TextArea } = Input;

const ScheduleContent = ({
  ChangeNode,
  AddNode,
  GenerateDate,
  scheduleList,
  RemoveNode,
  setscheduleList,
  is_checked,
  SelectedDate,
  ExchangeNode,
  editable = true,
  onlyForViewWithNostyle,
  scrollToNode,
  saveType,
  autoSaveData,
  INITIAL_HEIGHT,
  resetPosition,
  isCheckBoxSelection,
  selectedLibraryItemNode,
  setSelectedLibraryItemNode,
  isFromLibrary,
}) => {
  // Variables -------------------
  const { AUTH } = useStore();
  // Functions -------------------

  const onSortStart = () => {
    let timer = null;
    timer = localStorage.getItem('scheduleAutoSaveData');
    if (timer) {
      clearTimeout(Number(timer));
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (scheduleList && scheduleList[0]) {
      let ItemKey = scheduleList[0].item_key;
      ExchangeNode(ItemKey, { oldIndex, newIndex });
    }
    // setscheduleList(arrayMove(scheduleList, oldIndex, newIndex));
  };

  const getCharacterLength = () => {
    let lengthLimit = 170;
    if (AUTH?.user?.bonus_figures) {
      if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
        lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
      }
    }
    return lengthLimit;
  };

  // Markup ----------------------

  // set menu icon component
  const DragHandle = SortableHandle(() => (
    <div className='dragger'>
      <MenuOutlined />
    </div>
  ));
 const {t} = useTranslation();

  const SortableItem = SortableElement(({ value: { StructureItem, index } }) => {
    return (
      <div
        className={`fields_draggable_wrapper ${isFromLibrary ? 'extraZindex' : ''}`}
        id={`${saveType}_innerContent_${StructureItem.item_key}`}
      >
        <table
          width='100%'
          border={0}
          cellPadding={0}
          cellSpacing={0}
          className='box-RightScheduleList'
        >
          <tr>
            {isCheckBoxSelection ? (
              <>
                <td width={'2%'}>
                  <Checkbox
                    checked={
                      selectedLibraryItemNode &&
                      selectedLibraryItemNode.includes(StructureItem.item_key)
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (!selectedLibraryItemNode.includes(StructureItem.item_key)) {
                          setSelectedLibraryItemNode([
                            ...selectedLibraryItemNode,
                            StructureItem.item_key,
                          ]);
                        }
                      } else {
                        let tempSlection = selectedLibraryItemNode.filter(
                          (x) => x !== StructureItem.item_key
                        );
                        setSelectedLibraryItemNode(tempSlection);
                      }
                    }}
                  />
                </td>
              </>
            ) : null}
            <td width={'4%'} className='draggerTD'>
              {editable && <DragHandle />}
            </td>
            <td width={'10%'} className='numTD' valign='middle'>
              <Form.Item
                noStyle
                onBlur={(e) => {
                  setTimeout(() => {
                    ChangeNode(StructureItem.item_key, e.target.value, 'position');
                    resetPosition(e.target.value);
                  }, 0);
                  autoSaveData();
                }}
              >
                <InputNumber
                  className='days'
                  id={`${saveType}_innerContent_position_${StructureItem.item_key}`}
                  onChange={(value) => {
                    ChangeNode(StructureItem.item_key, value, 'position');
                    autoSaveData();
                  }}
                  onBlur={(e) => {
                    let maxCal = 0;
                    if (scheduleList && scheduleList.length) {
                      maxCal =
                        parseInt(e.target.value) + parseInt(scheduleList.length) - 1;
                    }

                    if (maxCal <= 365 && parseInt(e.target.value) > 0) {
                      resetPosition(e.target.value);
                    }
                  }}
                  disabled={
                    !editable ||
                    (firstPositionItemKey.includes(Number(StructureItem.item_key))
                      ? false
                      : true)
                  }
                  value={StructureItem.position}
                  min={1}
                  max={365}
                />
              </Form.Item>
            </td>
            <td width={'6%'} className='dayTD' valign='middle'>
              <Form.Item noStyle>
                <InputNumber
                  className='days'
                  id={`${saveType}_innerContent_day_${StructureItem.item_key}`}
                  min={0}
                  max={365}
                  formatter={(value) =>
                    typeof value === 'number' ? parseInt(value) : value
                  }
                  value={StructureItem.day}
                  disabled={StructureItem?.children?.length > 0}
                  onChange={(value) => {
                    setTimeout(() => {
                      ChangeNode(StructureItem.item_key, value ? value : 0, 'day');
                    }, 0);
                  }}
                  onBlur={(e) => {
                    if (!StructureItem?.children?.length > 0) {
                      GenerateDate(SelectedDate, is_checked);
                    }
                    autoSaveData();
                  }}
                />
              </Form.Item>
            </td>
            <td style={{ width: 'calc(80% - 145px)' }} className='data' valign='middle'>
              <Form.Item noStyle>
                {onlyForViewWithNostyle ? (
                  // <div style={{ height: 'auto' }} className='text'>
                  //   {StructureItem.detail}
                  // </div>
                  <TextArea
                    maxLength={getCharacterLength()}
                    disabled={true}
                    defaultValue={StructureItem.detail}
                    className='text'
                    style={{ height: StructureItem?.height }}
                  />
                ) : (
                  <TextArea
                    maxLength={getCharacterLength()}
                    disabled={!editable}
                    id={`${saveType}_innerContent_textarea_${StructureItem.item_key}`}
                    defaultValue={StructureItem.detail}
                    className='text'
                    style={{ height: StructureItem?.height }}
                    onChange={(e) => {
                      autoSaveData();

                      let HeightStyle = calculateHeight(
                        `${saveType}_innerContent_textarea_${StructureItem.item_key}`,
                        INITIAL_HEIGHT,
                        null,
                        true
                      );
                      ChangeNode(StructureItem.item_key, HeightStyle, 'height');
                      ChangeNode(StructureItem.item_key, e.target.value, 'detail');
                    }}
                    onBlur={() => {
                      localStorage.setItem(
                        'last_focusedElementSchedule',
                        StructureItem.item_key
                      );
                    }}
                  />
                )}
              </Form.Item>
            </td>
            {!StructureItem?.children?.length > 0 ? (
              <td width={'90px'} className='date'>
                {onlyForViewWithNostyle ? (
                  moment(StructureItem.start_date).format('YYYY-MM-DD')
                ) : (
                  <Form.Item>
                    <DatePickerCostume
                      value={StructureItem.start_date}
                      disabled
                      bordered={false}
                      format='YYYY-MM-DD'
                    />
                  </Form.Item>
                )}
              </td>
            ) : (
              <td width={'90px'} className='date'></td>
            )}

            {editable && (
              <td width={'55px'} className='actionTD text-right'>
                {(StructureItem.item_key + '').split('-').length < 3 && (
                  <img
                    src={AUTH.GetThemedImage('add')}
                    alt={`${t('Common_Data.Add')}`}
                    className='icon cursorPointer mr-5 ml-5'
                    onClick={() => {
                      AddNode(StructureItem.item_key);
                      scrollToNode(`${saveType}_innerContent_${StructureItem.item_key}`);
                      autoSaveData();
                    }}
                  />
                )}
                <img
                  src={AUTH.GetThemedImage('remove')}
                  alt={`${t('Common_Data.Remove')}`}
                  className={'mr-5 icon circle cursorPointer'}
                  onClick={() => {
                    RemoveNode(StructureItem.item_key);
                    autoSaveData();
                  }}
                />
              </td>
            )}
          </tr>
          {StructureItem?.children?.length > 0 ? (
            <tr>
              <td className='draggerTD'></td>
              <td colSpan={5}>
                <ScheduleContent
                  ChangeNode={ChangeNode}
                  GenerateDate={GenerateDate}
                  AddNode={AddNode}
                  scheduleList={StructureItem.children}
                  RemoveNode={RemoveNode}
                  SelectedDate={SelectedDate}
                  setscheduleList={setscheduleList}
                  is_checked={is_checked}
                  ExchangeNode={ExchangeNode}
                  editable={editable}
                  onlyForViewWithNostyle={onlyForViewWithNostyle}
                  scrollToNode={scrollToNode}
                  saveType={saveType}
                  autoSaveData={autoSaveData}
                  INITIAL_HEIGHT={INITIAL_HEIGHT}
                  isCheckBoxSelection={isCheckBoxSelection}
                  selectedLibraryItemNode={selectedLibraryItemNode}
                  setSelectedLibraryItemNode={setSelectedLibraryItemNode}
                  isFromLibrary={isFromLibrary}
                />
              </td>
            </tr>
          ) : null}
          <tr></tr>
        </table>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => (
    <div className='fields_draggable_container' id='field_draggable_container_schedule'>
      {items &&
        items.map((StructureItem, index) => (
          <SortableItem key={index} index={index} value={{ StructureItem, index }} />
        ))}
    </div>
  ));

  return (
    <>
      <SortableList
        onSortStart={onSortStart}
        useDragHandle={true}
        items={scheduleList}
        onSortEnd={onSortEnd}
      />
    </>
  );
};

export default ScheduleContent;
