import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import { Button, Col, Row } from 'antd';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const DateSelectionElementNew = observer(
  ({
    formDataList,
    onChange,
    disabledDate,
    SetDateRange,
    form,
    handlePreviewReport,
    isPreviewFilter = false,
    previewfilterBtnDisable = true,
  }) => {
    const { SavedReportStore } = useStore();
    const { t } = useTranslation()
    return (
      <Row gutter={24}>
        <Col>
          <InputComponent
            label={`${t('Reports.Run')} ${t('Reports.Report')} ${t('Reports.For')}`}
            onChange={() => {
              onChange();
              form.resetFields([
                'date_range',
                'date',
                'date_x_value',
                'date_x_value_type',
                'financial_year',
              ]);
            }}
            type='radio_button'
            name='generate_on'
            options={{
              values: [
                { value: 'yesterday', text: `${t('Reports.Yesterday')}` },
                { value: 'last_week', text: `${t('Common_Data.Last')} ${t('Common_Data.Week')}` },
                { value: 'this_month', text: `${t('Common_Data.This')} ${t('Reports.Month')}` },
                { value: 'this_quarter', text: `${t('Common_Data.This')} ${t('Reports.Quarter')}` },
                { value: 'this_year', text: `${t('Common_Data.This')} ${t('Reports.Year')}` },
                { value: 'financial_year', text: `${t('Reports.Financial')} ${t('Reports.Year')}` },
                { value: 'single', text: `${t('Reports.Single')}` },
                { value: 'date_range', text: `${t('Reports.Range')}` },
                { value: 'auto', text: `${t('Reports.Auto')}` },
              ],
            }}
            tooltip={`${t('Reports.Run_Report_Tooltip')}.`}
          />
        </Col>
        <Col>
          {formDataList.includes('date_range') && (
            <InputComponent
              label={`${t('Common_Data.Date')} ${t('Reports.Range')}`}
              type='date_range'
              name='date_range'
              onChange={onChange}
              rules={vsmTransactionReport.validation.date_range}
              tooltip={`${t('Reports.Date_Range_Tooltip')}.`}
              disabledDate={disabledDate}
              onCalendarChange={(val) => SetDateRange(val)}
            />
          )}
          {formDataList.includes('date') && (
            <InputComponent
              label={`${t('Common_Data.Date')}`}
              type='date'
              name='date'
              onChange={onChange}
              rules={vsmTransactionReport.validation.date}
              tooltip={`${t('Reports.Fetch_Reports')}.`}
            />
          )}
          {(formDataList.includes('date_x_value') ||
            formDataList.includes('date_x_value_type')) && (
              <Row gutter={24}>
                {formDataList.includes('date_x_value') && (
                  <Col span={4}>
                    <InputComponent
                      label={`${t('Common_Data.Last')}`}
                      required
                      type='number'
                      name='date_x_value'
                      min={1}
                      max={12}
                      className='w-100'
                      onChange={onChange}
                      rules={vsmTransactionReport.validation.date_x_value}
                      tooltip={`${t('Reports.Date_Selection')}.`}
                    />
                  </Col>
                )}
                {formDataList.includes('date_x_value_type') && (
                  <Col span={20}>
                    <InputComponent
                      label='&nbsp;'
                      onChange={onChange}
                      type='radio_button'
                      name='date_x_value_type'
                      options={{
                        className: 'd-flex w-100',
                        values: [
                          { value: 'day', text: `${t('Common_Data.Days')}` },
                          { value: 'week', text: `${t('Common_Data.Weeks')}` },
                          { value: 'month', text: `${t('Common_Data.Months')}` },
                          { value: 'quarter', text: `${t('Common_Data.Quarters')}` },
                          { value: 'year', text: `${t('Common_Data.Years')}` },
                        ],
                      }}
                    />
                  </Col>
                )}
              </Row>
            )}
        </Col>
        {formDataList.includes('financial_year') && (
          <Col span={6}>
            <div className='w400'>
              <InputComponent
                label={`${t('Reports.Financial')} ${t('Reports.Year')}`}
                required
                type='select'
                name='financial_year'
                placeholder={`${t('Reports.Financial')} ${t('Reports.Year')}`}
                onChange={onChange}
                options={{
                  values: SavedReportStore.financial_year_list,
                }}
                tooltip={`${t('Reports.Financial_Year_Tooltip')}.`}
                rules={vsmTransactionReport.validation.financial_year}
              />
            </div>
          </Col>
        )}
        {handlePreviewReport && isPreviewFilter && (
          <Col span={6}>
            <Button
              size='medium'
              shape='round'
              onClick={() => {
                handlePreviewReport();
              }}
              type='primary'
              disabled={previewfilterBtnDisable}
              style={{ marginTop: 18 }}
            >
              {t('Common_Data.Update')} {t('Common_Data.Result')}
            </Button>
          </Col>
        )}
      </Row>
    );
  }
);

export default DateSelectionElementNew;
