import React, { useState } from 'react';
import { Form, Button, Modal, Input, Spin, Row, Col } from 'antd';
//import { InfoCircleOutlined } from '@ant-design/icons';
import { vsmRoles, vsmNotify } from '../../../../../config/messages';
import useStore from '../../../../../store';
import InputComponent from '../../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const AddComponent = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    ORGANISATION,
    ORGROLESTORE: { AddData }
  } = useStore();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [fetchRole, setFetchRole] = useState(true);

  // Handle submit and call function to save new record
  const handleSubmit = (data) => {
    setSaving(true);
    AddData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmRoles.add
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable & set value of role
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  // reset form and close add form
  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
    setFetchRole(true);
  };

  return (
    <Modal
      centered
      title={`${t('OrganisationManagement.New')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          disabled={disabled}
          form='addform'
          loading={saving}
          htmlType='submit'
          type='primary'
        >
          {t('Common_Data.Save')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>
      ]}
    >
      <Form
        form={form}
        id='addform'
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        className='innerForm'
        onChange={handleChange}
      >
        <Row gutter={15}>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label={`${t('OrganisationManagement.Role')} ${t('Common_Data.Name')}`}
              tooltip={`${t('OrganisationManagement.Role_Name_Tooltip')}.`}
              required
            >
              <Form.Item name='role_name' noStyle rules={vsmRoles.validation.role_name}>
                <Input placeholder={`${t('OrganisationManagement.Role')} ${t('Common_Data.Name')}`} maxLength={50} />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }}>
            <InputComponent
              required
              type='select'
              mode='tags'
              label={`${t('OrganisationManagement.Related_Roles')}`}
              name='related_role_ids'
              placeholder={`${t('OrganisationManagement.Choose')} ${t('OrganisationManagement.Related_Roles')}`}
              tooltip={`${t('OrganisationManagement.Related_Role_Tooltip')}.`}
              onChange={handleChange}
              rules={vsmRoles.validation.related_role}
              showArrow
              allowClear
              onFocus={() =>
                fetchRole && ORGANISATION.getOrgRoles().then(() => setFetchRole(false))
              }
              notFoundContent={fetchRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values: ORGANISATION.dropdown_orgRole_list,
                value_key: 'id',
                text_key: 'role_name'
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddComponent;
