import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Drawer, Col, Row, Spin, Card, Input } from 'antd';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { vsmRoleMapping, vsmOrganisations, vsmNotify } from '../../../../config/messages';
import useStore from '../../../../store';
//import OrgDetailComponent from './OrgDetailComponent';
import InputComponent from '../../../../component/InputComponent';
import PplDetailComponent from '../../../PeopleManagement/RoleMapping/component/PplDetailComponent';
import RatingElement from '../../AllOrganisations/elements/RatingElement';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [ratingHide, setRatingHide] = useState(true);
  const [currentTab, setCurrentTab] = useState('Organisation');
  const [searchROrgKey, setSearchROrgKey] = useState();
  const [fetchRoles, setFetchRoles] = useState(true);
  const [fetchRelatedRoles, setFetchRetaldRoles] = useState(true);
  const [fetchTags, setFetchTags] = useState(true);
  const [fetchCurrency, setFetchCurrency] = useState(true);
  const [updateState, setUpdateState] = useState(true);
  const [role, setRole] = useState(null);
  const {
    ORGANISATION,
    ORGBANKACCOUNTSTORE,
    ORGMAPPINGSTORE,
    PEOPLE,
    //AUTH: { user },
    ORGMAPPINGSTORE: { getAllTagsList, AddData, dropdown_tags_list },
  } = useStore();

  // Handle submit and call function to save new record
  const handleSubmit = (data) => {
    setSaving(true);
    data.organisation_id = ORGANISATION.organisationValues.id;
    data.entity_id = props.parent_id;
    data.entity_type = 1;
    data.is_rating = ratingHide ? 0 : 1;
    if (data.rating_table && data.rating_table.length > 0) {
      data.rating_table = data.rating_table.filter(
        (x) => x && x && x.global_exchange_rating_id
      );
      data.rating_table =
        !ratingHide && data.rating_table.length > 0 ? data.rating_table : null;
    }
    AddData(data)
      .then(() => {
        close();
        /*START:Used To Update Dealdesk connections */
        if (props.ConnectionModule) {
          props.fetchMappingData();
        }
        /*END:Used To Update Dealdesk connections */

        if (props.handleViewDetailCall) {
          props.handleViewDetailCall({ id: ORGANISATION.organisationValues.id });
        }
        vsmNotify.success({
          message: vsmRoleMapping.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (props.visible && props.parent_id && !props.waste) {
      ORGANISATION.getOrgCardValue(props.parent_id, 'org');
    }
  }, [props.visible, props.parent_id, props.waste, ORGANISATION]);

  // set the CurrentTab values to add
  useEffect(() => {
    if (props.visible && props.relatedToTab) {
      setCurrentTab(props.relatedToTab);
      form.setFieldsValue({ related_entity_type: props.relatedToTab });
    } else {
      setCurrentTab('Organisation');
      form.setFieldsValue({ related_entity_type: 'Organisation' });
    }
  }, [props, form]);

  // set the form values to add
  useEffect(() => {
    if (ORGANISATION.organisationValues && props.visible) {
      setRatingHide(true);
      ORGANISATION.getRatingToMap();
      ORGANISATION.rOrganisationValues = null;
      form.setFieldsValue({
        organisation_id: ORGANISATION.organisationValues.id,
      });
    }
  }, [ORGANISATION, form, props]);

  // check for valid form values then accordingly make save button disable/enable & set value of role
  const handleChange = async () => {
    setUpdateState(!updateState);
    setRole(form.getFieldValue('role_id'));
    if (currentTab !== form.getFieldValue('related_entity_type')) {
      await form.resetFields([
        'related_people_id',
        'related_role_id',
        'related_entity_id',
      ]);
      ORGANISATION.rOrganisationValues = null;
      PEOPLE.rPeopleCardValue = null;
      setSearchROrgKey(null);
      setFetchRetaldRoles(true);
      setCurrentTab(form.getFieldValue('related_entity_type'));
    }
    if (form.getFieldValue('role_id')) {
      var rating =
        ORGANISATION.dropdown_orgRole_list &&
        ORGANISATION.dropdown_orgRole_list.find(
          (x) => x.role_name.toLowerCase() === 'rating agency'
        );
      if (rating && form.getFieldValue('role_id') === rating.id) {
        setRatingHide(false);
      } else {
        setRatingHide(true);
      }
    }
    validateData();
  };

  // call to validate fields
  const validateData = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // search string for organisation dropdown
  const handleROrgSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchROrgKey(val);
      ORGANISATION.getAllROrg({ search_for: val, related_entity_type: 1 });
    };

    return debounce(loadOptions, 500);
  }, [ORGANISATION]);

  // get related organisation data
  const handleROrgChange = (data) => {
    if (data) {
      ORGANISATION.getOrgCardValue(data, 'relatedOrg');
    } else {
      ORGANISATION.setROrganisationValues(null);
    }
  };

  // search string for people dropdown
  const handleRPplSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchROrgKey(val);
      PEOPLE.getPeopleSearchList({
        search_for: val,
        related_entity_type: 2,
      });
    };

    return debounce(loadOptions, 500);
  }, [PEOPLE]);

  // set related people data in card
  const handlePeopleChange = (data) => {
    if (data) {
      PEOPLE.getPeopleCardValue(data, 'people');
    } else {
      PEOPLE.rPeopleCardValue = null;
    }
  };

  const handleRoleChange = () => {
    if (
      form.getFieldValue('role_id') &&
      form.getFieldValue('related_entity_type') === 'Organisation'
    ) {
      ORGANISATION.getOrgRelatedRoles({
        role_id: form.getFieldValue('role_id'),
      }).then(() => setFetchRetaldRoles(false));
      form.resetFields(['related_role_id']);
    }
  };

  // reset form and close add form
  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
    setCurrentTab('Organisation');
    setFetchRoles(true);
    setFetchTags(true);
    setFetchCurrency(true);
    setFetchRetaldRoles(true);
    setRole(null);
    ORGMAPPINGSTORE.dropdown_tags_list = null;
    PEOPLE.rPeopleCardValue = null;
  };

  return (
    <Drawer
      destroyOnClose={true}
      title={`${t('OrganisationManagement.Map')} ${t('OrganisationManagement.New')} ${t('OrganisationManagement.Role')}`}
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      footer={[
        <div key='1' className='text-right'>
          <Button
            key='1'
            disabled={disabled}
            form='addmapform'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
          >
            {t('Common_Data.Save')}
          </Button>
          <Button
            key='2'
            className='ml-10'
            htmlType='button'
            onClick={close}
            shape='round'
          >
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        id='addmapform'
        onChange={validateData}
        onFinish={handleSubmit}
        className='innerForm'
        labelCol={{ span: 24 }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <div className='ant-row ant-form-item'>
              <div className='ant-col ant-col-24 ant-form-item-label'>
                <label title={`${t('Common_Data.Organisation')}`}>{t('Common_Data.Organisation')}</label>
              </div>
              <Input
                bordered={false}
                value={
                  ORGANISATION.organisationValues &&
                  ORGANISATION.organisationValues.organisation_name
                }
                className='text-left'
                name='organisation_id'
              />
            </div>
          </Col>
          <Col span={24}>
            <InputComponent
              required
              type='select'
              allowClear
              label={`${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')}`}
              name='role_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')}`}
              onChange={async () => {
                await handleRoleChange();
                handleChange();
              }}
              options={{
                values: ORGANISATION.dropdown_orgRole_list,
                value_key: 'id',
                text_key: 'role_name',
                rejected_keys: currentTab !== 'Ledger' && [
                  form.getFieldValue('related_role_id'),
                ],
              }}
              tooltip={`${t('OrganisationManagement.Role_Name_Tooltip')}.`}
              rules={vsmOrganisations.validation.organisation_role}
              onFocus={() =>
                fetchRoles && ORGANISATION.getOrgRoles().then(() => setFetchRoles(false))
              }
              notFoundContent={fetchRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
          {/* {ORGANISATION.organisationValues && (
            <Col flex={1}>
              <OrgDetailComponent data={ORGANISATION.organisationValues} />
            </Col>
          )} */}
        </Row>

        <InputComponent
          required
          type='radio_button'
          label={`${t('Common_Data.Relate')} ${t('Common_Data.To')}`}
          name='related_entity_type'
          initialValue={currentTab}
          onChange={handleChange}
          options={{
            values: [
              { value: 'Organisation', text: `${t('Common_Data.Organisation')}` },
              { value: 'People', text: `${t('Common_Data.People')}` },
              // { value: 'Ledger', text: user.default_ledger_name }
            ],
          }}
          tooltip={`${t('OrganisationManagement.Option_To_Select')}: ${t('Common_Data.Organisation')}/${t('Common_Data.People')}/${t('OrganisationManagement.Ledger')} ${t('Common_Data.Name')}.`}
          rules={vsmOrganisations.validation.related_entity_type}
        />
        {currentTab === 'Ledger' ? null : currentTab === 'Organisation' ? (
          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                label={`${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')}`}
                name='related_entity_id'
                placeholder={`${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')}`}
                onChange={(data) => {
                  handleChange(data);
                  handleROrgChange(data);
                }}
                options={{
                  values: ORGANISATION.dropdown_ROrg_list,
                  value_key: 'id',
                  text_key: 'entity_name',
                  rejected_keys: [form.getFieldValue('organisation_id')],
                }}
                tooltip={`${t('OrganisationManagement.Related_Organisation_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_organisation}
                notFoundContent={
                  ORGANISATION.fetching ? (
                    <Spin size='small' />
                  ) : searchROrgKey ? (
                    `${t('Common_Data.No_Record_Found')}!`
                  ) : null
                }
                onSearch={handleROrgSearch}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                disabled={role ? false : true}
                label={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                name='related_role_id'
                placeholder={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                onChange={handleChange}
                options={{
                  values: ORGANISATION.dropdown_RelatedRoles,
                  value_key: 'id',
                  text_key: 'role_name',
                  rejected_keys: [form.getFieldValue('role_id')],
                }}
                tooltip={`${t('OrganisationManagement.Related_Role_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_role}
                onFocus={() =>
                  fetchRelatedRoles &&
                  ORGANISATION.getOrgRelatedRoles({
                    role_id: form.getFieldValue('role_id'),
                  }).then(() => setFetchRetaldRoles(false))
                }
                notFoundContent={
                  fetchRelatedRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              />
            </Col>
            {/* {ORGANISATION.rOrganisationValues && (
              <Col flex={1}>
                <OrgDetailComponent data={ORGANISATION.rOrganisationValues} />
              </Col>
            )} */}
          </Row>
        ) : (
          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                label={`${t('OrganisationManagement.Related')} ${t('Common_Data.People')}`}
                name='related_entity_id'
                placeholder={`${t('OrganisationManagement.Related')} ${t('Common_Data.People')}`}
                onChange={(data) => {
                  handleChange(data);
                  handlePeopleChange(data);
                }}
                options={{
                  values: PEOPLE.dropdown_RPpl_list,
                  value_key: 'id',
                  text_key: 'entity_name',
                }}
                tooltip={`${t('OrganisationManagement.Related_People_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_people}
                filterOption={false}
                notFoundContent={
                  PEOPLE.fetching ? (
                    <Spin size='small' />
                  ) : searchROrgKey ? (
                    `${t('Common_Data.No_Record_Found')}!`
                  ) : null
                }
                onSearch={handleRPplSearch}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                required
                type='select'
                allowClear
                label={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                name='related_role_id'
                placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                onChange={handleChange}
                options={{
                  values: PEOPLE.dropdown_pplRole_list,
                  value_key: 'id',
                  text_key: 'role_name',
                }}
                tooltip={`${t('OrganisationManagement.People_Role_Relation_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_role}
                onFocus={() =>
                  fetchRelatedRoles &&
                  PEOPLE.getPeopleRoles().then(() => setFetchRetaldRoles(false))
                }
                notFoundContent={
                  fetchRelatedRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              />
            </Col>
            {PEOPLE.rPeopleCardValue && (
              <Col offset={6} flex={1}>
                <PplDetailComponent data={PEOPLE.rPeopleCardValue} />
              </Col>
            )}
          </Row>
        )}

        <Row gutter={24}>
          <Col span={12}>
            <InputComponent
              mode='tags'
              type='select'
              allowClear
              label={`${t('Common_Data.Tags')}`}
              name='tag_ids'
              placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.More_Tags')}`}
              onChange={handleChange}
              options={{
                values: dropdown_tags_list,
                value_key: 'id',
                text_key: 'tag_name',
              }}
              tooltip={`${t('OrganisationManagement.Tags_Tooltip')}.`}
              rules={vsmOrganisations.validation.tag_name}
              maxTagTextLength={10}
              tokenSeparators={[',']}
              onFocus={() =>
                fetchTags && getAllTagsList().then(() => setFetchTags(false))
              }
              notFoundContent={fetchTags ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              label={`${t('Common_Data.Exchange')} ${t('OrganisationManagement.Reference')} ${t('Common_Data.Id')}`}
              name='exchange_ref_id'
              placeholder={`${t('Common_Data.Exchange')} ${t('OrganisationManagement.Reference')} ${t('Common_Data.Id')}`}
              onChange={handleChange}
              tooltip={`${t('Common_Data.Exchange')} ${t('Common_Data.ID')} ${t('OrganisationManagement.Numerical_Value')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')} ${t('Common_Data.In')} ${t('Common_Data.Exchange')} 2.0 ${t('OrganisationManagement.Software')}.`}
              rules={vsmOrganisations.validation.exchange_ref_id}
              maxLength={100}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <InputComponent
              label={`${t('OrganisationManagement.Supplier_Insurance')} ${t('Common_Data.No')}.`}
              name='supplier_insurance_no'
              placeholder={`${t('OrganisationManagement.Supplier_Insurance')} ${t('Common_Data.Number')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.Insurance_Number_Tooltip')}.`}
              rules={vsmRoleMapping.validation.Insurance}
              maxLength={100}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              label={`${t('OrganisationManagement.Agreed_Early_Payment_Discount')}`}
              name='agreed_early_payment_discount'
              placeholder={`${t('OrganisationManagement.Agreed_Early_Payment_Discount')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.Agreed_Early_Payment_Tooltip')}.`}
              rules={vsmRoleMapping.validation.Discount}
              maxLength={100}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <InputComponent
              allowClear
              type='select'
              label={`${t('Common_Data.Currency')}`}
              name='currency_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')} ${t('Common_Data.Currency')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.Currency_Tooltip')}.`}
              onFocus={() =>
                fetchCurrency &&
                ORGBANKACCOUNTSTORE.getCurrencyList().then(() => setFetchCurrency(false))
              }
              notFoundContent={fetchCurrency ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values: ORGBANKACCOUNTSTORE.dropdown_currency_list,
                value_key: 'id',
                text_key: 'currency_name',
              }}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              label={`${t('OrganisationManagement.CRM')} ${t('Common_Data.ID')}`}
              name='crm_id'
              placeholder={`${t('Common_Data.Enter')} ${t('OrganisationManagement.CRM')} ${t('OrganisationManagement.Refernce')} ${t('Common_Data.ID')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.Unique_Id_CRM')}.`}
              rules={vsmRoleMapping.validation.crm}
              maxLength={100}
            />
          </Col>
        </Row>

        {ratingHide ? null : (
          <>
            <Card title={`${t('OrganisationManagement.Rating_Table')}`} className='mb-25'>
              <RatingElement form={form} onChange={validateData} />
            </Card>
            <InputComponent
              required
              allowClear
              type='textarea'
              label={`${t('Common_Data.Description')}`}
              name='additional_description'
              placeholder={`${t('Common_Data.Description')}`}
              onChange={validateData}
              rules={vsmOrganisations.validation.description}
              tooltip={`${t('OrganisationManagement.Description_Tooltip')}.`}
            />
          </>
        )}
      </Form>
    </Drawer>
  );
});

export default AddComponent;
