import React from 'react';
import { observer } from 'mobx-react';
import DisplayMsgList from '../component/DisplayMsgList';
import Footer from '../component/footer';
import useStore from '../../../../store';
import { Spin } from 'antd';

const Summary = observer((props) => {
  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }

  const {
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: { dataValue, noteLoader },
  } = useStore();

  const summaryList = true;

  const { openExpandModalSum, openModalSum, close } = props;

  let dealid = deal_data.lead_information && deal_data.lead_information.id;

  return (
    <>
      <div className='displayBoxWithFooter'>
        <Spin
          size='large'
          spinning={noteLoader}
          style={{
            textAlign: 'center',
            padding: '50px 30px',
          }}
        >
          <DisplayMsgList
            msgBoxArray={props.filteredResultSum}
            type='summary'
            dealid={dealid}
          />
        </Spin>
      </div>
      <Footer
        msgBoxArray={{ get: props.filteredResultSum, set: dataValue }}
        openExpandModalSum={openExpandModalSum}
        openModalSum={openModalSum}
        close={close}
        summaryList={summaryList}
      />
    </>
  );
});

export default Summary;
