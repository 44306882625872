
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import OrganisationSearchListing from './OrganisationSearchListing';
import EditComponent from '../../OrganisationManagement/AllOrganisations/component/EditComponentNew';
import DeleteComponent from '../../OrganisationManagement/AllOrganisations/component/DeleteComponent';
import TimeTracker from '../../../component/TimeTracker';

const OrganisationSearch = observer((props) => {
  const { t } = useTranslation();
  const {
    // ORGANISATION,
    ORGANISATION: {
      setOrganisationValues,
      setEditValues,
      setDeleteValues,
    },
    // DEALDESKSTORE: { stopInvestmentHourCounter, setStopInvestmentHourCounter },
    // PEOPLE: { isSavedFiltersLoadedPeople },
  } = useStore();

  const { SearchPage, searchPayload } = props
  const [editOpen, setEditOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);


  // set id for edit / view drawer
  const setId = (id) => setTransactionId(id);


  // Open form for edit existing Organisation and set values to form
  const openEditModal = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    setOrganisationValues(null);
    setId(data?.id);
    setEditValues(data);
    // setStopInvestmentHourCounter(true);
    setEditModal(true);
  };

  // Close form of edit Organisation
  const closeEditModal = () => {
    // ORGANISATION.organisationValues = null;
    setEditValues(null);
    setEditModal(false);
    // setStopInvestmentHourCounter(false);
    setEditOpen(false);
  };

  // Open confirmation alert before deleting existing Organisation
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => {
    setDeleteModal(false);
  }


  return (
    <>
      <OrganisationSearchListing SearchPage={SearchPage} searchPayload={searchPayload} openEditModal={openEditModal} openDeleteModal={openDeleteModal} selectionChanged={props.selectionChanged} showCheckbox={props.showCheckbox} />
      <EditComponent visible={editModal}
        editOpen={editOpen}
        close={closeEditModal} SearchPageEdit={true} transactionId={transactionId} />
      <DeleteComponent visible={deleteModal} close={closeDeleteModal} lineText={`${t('OrganisationManagement.Remove_Organisation')} ? `} SearchPage={SearchPage} />

      {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={'Organisations'} />} */}
      <TimeTracker
        page={"organisation"}
        stop={editModal}
      />
    </>
  )
})
export default OrganisationSearch;