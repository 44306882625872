import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import KeyNotes from '../DealDesk/RightSideComponent/Key/KeyNotes';
import KeyDocuments from '../DealDesk/RightSideComponent/Key/KeyDocuments';
// import ScheduleLibrary from '../DealDesk/RightSideComponent/Schedule/component/ViewListing';
import ScheduleLibraryNew from '../DealDesk/RightSideComponent/NewSchedule/component/ViewListing';
import StructureLibrary from '../DealDesk/RightSideComponent/Structure/component/StructureLibrary';
import useStore from '../../store';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import { useTranslation } from 'react-i18next';
import ScoreLibrary from './ScoreLibrary';

const DealLibrary = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    DEALDESKSTRUCTURELIBRARY: { setTabChanged },
    DEALDESKKEYNOTES: { key_notes_data, fetchKeyNotes },
    SCORELIBRARYSTORE: { fetchScoreLibrary, score_library_data },
    SSSLEADLIBRARYSTORE: {
      fetchListData,
      getLibraryByType,
      fetchLibraryByType,
      // structureLibrary,
      // scrutiniseLibrary,
      // screenLibrary,
      // schedule_library_data,
      // shortfallLibrary,
      // handleResetLibraryData
    },
    SEARCHSTORE
  } = useStore();
  const { TabPane } = Tabs;
  const DealLibrary = true;

  const onTabChanged = (e) => {
    if (['8'].includes(e) && !score_library_data) {
      fetchScoreLibrary();
      // setTabChanged(e);
    }
    // if (['3', '4', '5', '6', '7'].includes(e) && !structureLibrary) {
    //   fetchListData(0);
    //   setTabChanged(e);
    // }
    /**To Fetch the library data on tab change if there is no data available */
    let library = getLibraryByType(e);
    fetchLibraryByType(e, library)

    setTabChanged(e);
    if (e === "1") {
      fetchKeyNotes();
    }
  };

  useEffect(() => {
    // fetchListData(0);
    if (SEARCHSTORE.viewAllSreachPage === "Key Notes") {
      fetchKeyNotes();
    } else {
      fetchListData(0, false, { type: 4 })
    }
    return {};
  }, [SEARCHSTORE.viewAllSreachPage, fetchKeyNotes, fetchListData]);

  // useEffect(() => {
  //   return () => {
  //     handleResetLibraryData();
  //   };
  // }, [handleResetLibraryData]);

  /**Fetch classificationList */
  // if(!LEADSSTORE.classification_role_list) {
  //   LEADSSTORE.getClassificationList();
  // }

  return (
    <>
      <Tabs
        defaultActiveKey={SEARCHSTORE.viewAllSreachPage === "Key Notes" ? "1" : "6"}
        onChange={(e) => onTabChanged(e)}
        className='tabs DealLibrary'
        style={{ height: 'calc(100vh - 6rem)' }}
      >
        {AUTH.checkPermission(53, 'structure-library') && (
          <TabPane tab={`${t('Common_Data.Scrutinise')}`} key='6'>
            <StructureLibrary DealLibrary={DealLibrary} saveType={'scrutinise'} />
          </TabPane>
        )}
        {AUTH.checkPermission(53, 'structure-library') && (
          <TabPane tab={`${t('DealLibrary.Screen')}`} key='4'>
            <StructureLibrary DealLibrary={DealLibrary} saveType={'screen'} />
          </TabPane>
        )}
        {AUTH.checkPermission(53, 'structure-library') && (
          <TabPane tab={`${t('Common_Data.Structure')}`} key='3'>
            <StructureLibrary DealLibrary={DealLibrary} saveType={'structure'} />
          </TabPane>
        )}
        {AUTH.checkPermission(53, 'schedule-library') && (
          <TabPane tab={`${t('DealLibrary.Schedule')}`} key='5'>
            <ScheduleLibraryNew DealLibrary={DealLibrary} />
          </TabPane>
        )}
        {AUTH.checkPermission(53, 'schedule-library') && (
          <TabPane tab={`${t('DealLibrary.Score')}`} key='8'>
            <ScoreLibrary DealLibrary={DealLibrary} />
          </TabPane>
        )}
        {AUTH.checkPermission(53, 'schedule-library') && (
          <TabPane tab={`${t('Common_Data.Shortfall')}`} key='7'>
            <StructureLibrary DealLibrary={DealLibrary} saveType={'shortfall'} />
          </TabPane>
        )}
        {AUTH.checkPermission(53, 'key-library-notes') && (
          <TabPane tab={`${t('DealLibrary.Key_Notes')}`} key='1'>
            <KeyNotes key_notes_data={key_notes_data} />
          </TabPane>
        )}
        {AUTH.checkPermission(53, 'key-library-documents') && (
          <TabPane tab={`${t('DealLibrary.Key_Documents')}`} key='2'>
            <KeyDocuments DealLibrary={DealLibrary} />
          </TabPane>
        )}
      </Tabs>
      {/* <TrackTimeComponent pageName={'library'} /> */}
      <TimeTracker />
    </>
  );
});
export default DealLibrary;
