import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Col, Row } from 'antd';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const ConfirmFavReportElement = observer((props) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const { t } = useTranslation();

  const {
    REPORTSTORE: { globalStatusTempData },
    REPORTSTORE,
    AUTH,
  } = useStore();

  const handleSubmit = (data) => {
    setSaving(true);
    REPORTSTORE.saveToMenu(
      globalStatusTempData.id,
      globalStatusTempData.is_favorite ? '0' : '1'
    )
      .then((data) => {
        AUTH.setUserOptions(localStorage.getItem('token'));
        props.close();
      })
      .finally(() => setSaving(false));
  };

  return globalStatusTempData ? (
    <Modal
      centered
      title={`${t('Reports.Favorite_Report')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='confirmFavReport'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='confirmFavReport' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={24}>
              <p>
                {!globalStatusTempData.is_favorite
                  ? t('Reports.Set_Fav_Text')
                  : t('Reports.Remove_Fav_Text')
                }
              </p>
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default ConfirmFavReportElement;
