import moment from 'moment';
import React from 'react';
import useStore from '../../../../../store';
const ScheduleContentPost = ({ Schedule }) => {
  const { AUTH } = useStore();
  const PrintSchedule = (schedule, isParent = null) => {
    const dateFormat = (x) => {
      return moment(new Date(x.start_date), AUTH.global_dateformat, true).isValid()
        ? moment(new Date(x.start_date)).format(AUTH.global_dateformat)
        : moment(new Date(x.start_date), 'YYYY-MM-DD').format(AUTH.global_dateformat);
    };
    return (
      schedule &&
      schedule.map((x, i) => {
        return (
          x && (
            <>
              <div key={i}>
                {isParent ? `${isParent}.${x.position}` : `${x.position}.`}
                &nbsp;&nbsp;
                {/* {x.detail} */}
                <span
                  className='data'
                  dangerouslySetInnerHTML={{
                    __html: x.detail
                      ? x.detail.replace(
                          /(?:\r\n|\r|\n)/g,
                          '<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                        )
                      : null,
                  }}
                />
                &nbsp;&nbsp;
                {!x?.children?.length > 0 ? dateFormat(x) : ''}
              </div>
              {x.children && x.children.length > 0 && (
                <div>
                  {x.children &&
                    PrintSchedule(
                      x.children,
                      isParent ? isParent + '.' + x.position : x.position
                    )}
                </div>
              )}
            </>
          )
        );
      })
    );
  };

  return (
    <>
      <div className='structure'>{PrintSchedule(Schedule)}</div>
    </>
  );
};

export default ScheduleContentPost;

// import moment from 'moment';
// import React from 'react';
// import useStore from '../../../../../store';
// const ScheduleContentPost = ({ Schedule }) => {
//     const { AUTH } = useStore();
//     const PrintSchedule = (schedule, isParent = null) => {
// const dateFormat = (x) => {
//     return moment.isMoment(x.start_date) ? moment(x.start_date, "YYYY-MM-DD").format(AUTH.global_dateformat) : moment(x.start_date, "YYYY-MM-DD").format(AUTH.global_dateformat)
// }
//         return (
//             schedule &&
//             schedule.map((x, i) => {
//                 return (
//                     x && (
//                         <>
//                             <div key={i}>
//                                 {x.task}
//                                 {` `}
//                                 {x.detail}
//                                 &nbsp;&nbsp;&nbsp;
//                                 {x.day !== 0 ? dateFormat(x) : ''}
//                             </div>
//                         </>
//                     )
//                 );
//             })
//         );
//     };

//     return (
//         <>
//             <div className='structure'>{PrintSchedule(Schedule)}</div>
//         </>
//     );
// };

// export default ScheduleContentPost;
