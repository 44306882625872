import Axios from 'axios';
import { action, decorate, observable } from 'mobx';
import { Notify } from '../utils/Notify';
import moment from 'moment-timezone';
import { fetchStaticFilters, getSavedFilterPageTypes } from '../utils/GlobalFunction';
import i18n from '../i18n';
import TranslateMessage from '../component/TranslateMessages';
export default class AuthStore {
  user = null;
  superUser = null;
  token = null;
  menu = null;
  loading = true;
  open_login = false;
  permissions = null;
  timezone = null;

  api_menu_countused = 0;
  api_menu_count_unused = 0;
  api_menu_count_opportunities = 0;
  api_menu_count_deals = 0;
  api_menu_count_itinerary = 0;
  api_menu_count_reminders = 0;
  api_menu_count_tags = 0;
  api_menu_count_tickets = 0;
  api_menu_count_organisation = 0;
  api_menu_count_people = 0;
  api_menu_count_knowledge = 0;
  api_menu_count_waste = 0;

  menuused_title = <TranslateMessage msgKey={'DashBoard.Leads'} />;
  menuUsedText = 'Leads';
  menuused_link = '/leads/active';
  menuused_count = 0;

  menu_people_org_title = <TranslateMessage msgKey={'Common_Data.People'} />;
  menuPeopleText = 'People';
  menu_people_org_link = '/contacts/people';
  menu_people_org_count = '0';
  app_color_theme = 'gray';
  app_font_size = 'medium';
  global_country_list = [];

  header_ticker_info = null;
  global_dateformat = null;
  global_timeformat = null;
  global_fulldate_format = null;
  global_fulldate_format_upper = null;
  form_submit_date_with_minutes = 'YYYY-MM-DD HH:mm:00';
  form_submit_date_with_seconds = 'YYYY-MM-DD HH:mm:ss';


  notificationData = null; //USED FOR REMINDER RESET ON PUSH NOTIFICATION.
  reminderNotifyVisible = false; //USED FOR REMINDER RESET ON PUSH NOTIFICATION.

  SOLD_STATUS_FAILED = 13;
  SOLD_STATUS_REJECTED = 12;
  SOLD_STATUS_FUTURE = 14;
  lead_failed_reason_list = null;

  gridfilter_list = []; //Grid Saved Filter Array.
  allPageFilters = [];
  savedFilterLoaded = false;

  isUserIdle = false; //Used To Stop API calls during idle time
  callNotesAPIAfterUserBack = false; //Used To Call Notes API after click on I'm back/I'm Working

  constructor() {
    this.initiatAppOptions();
  }

  // set interceptors to axios
  setAxiosInterceptors = () => {
    Axios.interceptors.response.use(
      (data) => {
        return data;
      },
      (data) => {
        if (data.response) {
          if (data.response.status === 401) {
            let token = localStorage.getItem('token');
            if (!token) {
              this.clearLocalStorage();
            }
            this.open_login = true;
          } else if (data.response.status === 500) {
            Notify.error({
              message: 'Something went wrong. For more detail [500]',
            });
          } else if (data.response.status === 422) {
            if (data.response.data.errors.general) {
              data.response.data.errors.general.map((err) =>
                Notify.error({
                  message: err,
                })
              );
            }
          }
        } else {
          Notify.error({
            message: 'Network Problem',
          });
        }
        return Promise.reject(data);
      }
    );
  };

  // set axios default setting for api call
  initiatAppOptions = () => {
    this.loading = true;
    Axios.defaults.baseURL = process.env.React_APP_API_URL;
    //Axios.defaults.baseURL = 'http://credebt.silicontechnolabs.com:8001/'
    let token = localStorage.getItem('token');
    if (token && token !== 'undefined') {
      // Axios.defaults.headers = {
      //   Accept: 'application/json',
      //   Authorization: 'Bearer ' + token,
      //   fm: 'dm',
      // };
      Axios.interceptors.request.use((config) => {
        let token = localStorage.getItem('token');
        if (token && token !== 'undefined') {
          config.headers.Authorization = 'Bearer ' + token;
          config.headers.Accept = 'application/json';
          config.headers.fm = 'dm';
          return config;
        } else {
          this.setUser(null, null);
          this.loading = false;
          config.headers.fm = 'dm';
          return config;
        }
      });
      this.setUserOptions(token);
    } else {
      this.setUser(null, null);
      this.loading = false;
      Axios.defaults.headers = {
        fm: 'dm',
      };
    }
  };

  fetchDashboardReport = () => {
    return Axios.get(`v2/dashboard/favorite/report`)
      .then(({ data }) => {
        this.report_data = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  // make a api call to get current user & accessible menu
  setUserOptions = (token) => {
    Axios.post('me')
      .then(({ data }) => {
        //----------- Set Language based on user settings -------//

        if (data.user) {
          if (data.user?.dm_settings.length > 0) {
            if (data.user?.dm_settings[0].language) {
              i18n.changeLanguage(data.user?.dm_settings[0].language);
            }
          }
        }
        //---------------------------------------------------//
        this.setUser(data.user, token);
        this.superUser = data.super_user_id;
        var menu = data.menu;
        this.permissions = data.dm_permissions;
        this.menu = menu;
        this.timezone = data.user?.dm_settings?.[0]?.timezone;
        this.global_dateformat = data.user?.dm_settings?.[0]?.date_format
          ? data.user?.dm_settings?.[0]?.date_format
          : 'YYYY-MM-DD';
        this.global_timeformat = data.user?.dm_settings?.[0]?.time_format
          ? data.user?.dm_settings?.[0]?.time_format
          : 'hh:mm a';
        this.global_fulldate_format =
          this.global_dateformat + ' ' + this.global_timeformat;

        this.global_fulldate_format_upper =
          this.global_dateformat + ' ' + (this.global_timeformat === 'hh:mm a' ? 'hh:mm A' : this.global_timeformat);

        this.menu_people_org_title = this.checkPermission(18, 'list')
          ? <TranslateMessage msgKey={'Common_Data.People'} />
          : <TranslateMessage msgKey={'Common_Data.Organisation'} />;
        this.menuPeopleText = this.checkPermission(18, 'list') ? 'People' : 'Organisation';
        this.menu_people_org_link = this.checkPermission(18, 'list')
          ? '/contacts/people'
          : '/contacts/organisations';
        this.menuused_title = this.checkPermission(11, 'list') ? <TranslateMessage msgKey={'DashBoard.Leads'} /> : <TranslateMessage msgKey={'Common_Data.Unused'} />;
        this.menuUsedText = this.checkPermission(11, 'list') ? 'Leads' : 'Unused';
        this.menuused_link = this.checkPermission(11, 'list')
          ? '/leads/active'
          : '/leads/unused';
        this.fetchLeftmenuCount();
        this.fetchGlobalCountryList();
        this.fetchDashboardReport();
        this.setThemeFontColor(data.user.theme_color, data.user.font_size);
        this.getTickerHeaderInfo();
        //this.getAllPageFilter();
        setTimeout(() => (this.loading = false), 100);
      })
      .catch((e) => {
        this.clearLocalStorage();
        this.initiatAppOptions();
      });
  };

  // set authenticated user to mobx store
  setUser = (user, token) => {
    this.user = user;
    this.token = token;
    if (user) {
      localStorage.setItem('user_name_fullname', user.first_name + ' ' + user.last_name);
      localStorage.setItem('user_name', user.id);
    }
  };

  // call api for login
  doLogin = (data) => {
    return Axios.post('login', data)
      .then(({ data }) => {
        if (!data.reset_token) {
          localStorage.setItem('token', data.access_token);
          localStorage.setItem('savedFilters', null);

          this.initiatAppOptions();
          this.open_login = false;
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data && data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === 'common') {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        if (data && data.message) {
          notify = data.message;
        }
        return Promise.reject({ errors, notify });
      });
  };

  // logout function
  doLogout = () => {
    if (localStorage.getItem('previous_token')) {
      localStorage.setItem('token', localStorage.getItem('previous_token'));
      localStorage.removeItem('previous_token');
      this.initiatAppOptions();
    } else {
      this.setUser(null, null);
      this.superUser = null;

      this.loading = false;
      this.clearLocalStorage();
    }
  };

  clearLocalStorage = () => {
    localStorage.removeItem('app_font_size');
    localStorage.removeItem('token');
    localStorage.removeItem('app_color_theme');
    localStorage.removeItem('user_name_fullname');
    localStorage.removeItem('user_name');
    localStorage.removeItem('selected_leads');
    localStorage.removeItem('selected_dealdesk');
    // localStorage.removeItem('params')
    // localStorage.removeItem('opportunities_params')
    // localStorage.removeItem('unused_params')
    // localStorage.removeItem('deals_params')
    // localStorage.removeItem('active_params')
    // localStorage.removeItem('promotion_params')
  };

  // call api for forgot password link
  sendForgotPasswordLink = (data) => {
    return Axios.post('forgotpassword', data)
      .then(({ data }) => {
        return true;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
        }
        return Promise.reject({ errors });
      });
  };

  // call api for reset password
  doForgotPassword = (data) => {
    return Axios.post('/user/updatenewpassword', data)
      .then(({ data }) => {
        return true;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
        }
        return Promise.reject({ errors });
      });
  };

  doResetPassword = (data) => {
    return Axios.post('/user/reset/password', data)
      .then(({ data }) => {
        return true;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
        }
        return Promise.reject({ errors });
      });
  };

  // Check User Permission
  checkPermission = (module_id, permission_type) => {
    //return true;
    if (this.user && this.user.id === 1) {
      // Bypass all permissions for first super admin
      return true;
    } else {
      if (
        this.permissions &&
        this.permissions[module_id] &&
        this.permissions[module_id].includes(permission_type)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  // switch link used-unused
  updateSwitchmenu = (selection) => {
    if (selection) {
      this.menuused_title = <TranslateMessage msgKey={'DashBoard.Leads'} />;
      this.menuUsedText = 'Leads';
      this.menuused_link = '/leads/active';
      this.menuused_count = this.api_menu_countused;
    } else {
      this.menuused_title = <TranslateMessage msgKey={'Common_Data.Unused'} />;
      this.menuUsedText = 'Unused';
      this.menuused_link = '/leads/unused';
      this.menuused_count = this.api_menu_count_unused;
    }
  };
  updateSwitchOrgmenu = (selection) => {
    if (selection) {
      this.menu_people_org_title = <TranslateMessage msgKey={'Common_Data.People'} />;
      this.menuPeopleText = 'People';
      this.menu_people_org_link = '/contacts/people';
      this.menu_people_org_count = this.api_menu_count_people;
    } else {
      this.menu_people_org_title = <TranslateMessage msgKey={'Common_Data.Organisations'} />;
      this.menuPeopleText = 'Organisations';
      this.menu_people_org_link = '/contacts/organisations';
      this.menu_people_org_count = this.api_menu_count_organisation;
    }
  };

  // call api for login
  setThemeFontColor = (themecolor, themefontsize) => {
    if (themecolor) {
      localStorage.setItem('app_color_theme', themecolor);
      this.app_color_theme = themecolor;
    } else {
      localStorage.setItem('app_color_theme', 'gray');
      this.app_color_theme = 'gray';
    }
    if (themefontsize) {
      localStorage.setItem('app_font_size', themefontsize);
      this.app_font_size = themefontsize;
    } else {
      localStorage.setItem('app_font_size', 'medium');
      this.app_font_size = 'medium';
    }
  };

  // call api for login
  fetchGlobalCountryList = () => {
    return Axios.get('globalcountries/list')
      .then(({ data }) => {
        this.global_country_list = data.data;
        return true;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;

        return Promise.reject({ errors, notify });
      });
  };

  // call api for login
  updateUIsettings = (themecolor, themefont) => {
    let payload = {
      theme_color: themecolor,
      font_size: themefont,
    };
    let userid = this.user.id;

    return Axios.post(`user/ui-settings/${userid}`, payload)
      .then(({ data }) => {
        this.setThemeFontColor(themecolor, themefont);
        //window.location.reload();
        return true;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        return Promise.reject({ errors, notify });
      });
  };

  // call api for login
  fetchLeftmenuCount = () => {
    return Axios.get(`sidebar-module-records`)
      .then(({ data }) => {
        this.api_menu_countused =
          data.used_leads && data.used_leads.count.toLocaleString('en-GB');
        this.api_menu_count_unused =
          data.unused_leads && data.unused_leads.count.toLocaleString('en-GB');
        this.api_menu_count_opportunities =
          data.opportunities && data.opportunities.count.toLocaleString('en-GB');
        this.api_menu_count_deals =
          data.deals && data.deals.count.toLocaleString('en-GB');

        this.api_menu_count_itinerary =
          data.itinerary && data.itinerary.count.toLocaleString('en-GB');
        this.api_menu_count_reminders =
          data.reminders && data.reminders.count.toLocaleString('en-GB');
        this.api_menu_count_tags = data.tags && data.tags.count.toLocaleString('en-GB');
        this.api_menu_count_tickets =
          data.tickets && data.tickets.count.toLocaleString('en-GB');
        this.api_menu_count_organisation =
          data.organisation && data.organisation.count.toLocaleString('en-GB');
        this.api_menu_count_people =
          data.people && data.people.count.toLocaleString('en-GB');

        this.api_menu_count_knowledge =
          data.knowledge && data.knowledge.count.toLocaleString('en-GB');
        this.api_menu_count_waste =
          data.waste && data.waste.count.toLocaleString('en-GB');

        // Update AS per default showed Count
        this.menu_people_org_count = this.checkPermission(18, 'list')
          ? this.api_menu_count_people
          : this.api_menu_count_organisation;
        this.menuused_count = this.checkPermission(11, 'list')
          ? this.api_menu_countused
          : this.api_menu_count_unused;
        // Update AS per default showed Count
        return true;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        return Promise.reject({ errors, notify });
      });
  };

  getTickerHeaderInfo = () => {
    return Axios.get(`ticker/header`)
      .then(({ data }) => {
        this.header_ticker_info = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
        }
        return Promise.reject({ errors });
      });
  };

  GetThemedImage = (IMAGE = null, MIME = 'svg') => {
    if (IMAGE) {
      return require(`../assets/images/${this.app_color_theme ? this.app_color_theme : 'gray'
        }/${IMAGE}.${MIME}`).default;
    } else {
      return require(`../assets/images/${this.app_color_theme ? this.app_color_theme : 'gray'
        }/Snap_Note.svg`).default;
    }
  };

  updateCountryTimezone = (utcTimezone) => {
    let payload = {
      timezone: utcTimezone,
    };

    return Axios.post(`update-time-zone`, payload)
      .then(({ data }) => {
        this.setTimeZone(utcTimezone);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        return Promise.reject({ errors, notify });
      });
  };

  // Convert Date Time (UTC) to Local Date Time
  //Type =0 - full date , Type=1 - Only Date, Type=2 Only Time
  getLocalTime = (
    date = moment.utc(new Date()),
    formatType,//= 'YYYY-MM-DD hh:mm',
    type = 0
  ) => {
    let format = formatType ? formatType : 'YYYY-MM-DD hh:mm'

    if (date === null) {
      return null;
    }
    if (this.timezone) {
      //START - Timezone===CountryID Filter to get UTC from global_country_list
      let selectedCountry =
        this.global_country_list &&
        this.global_country_list.filter((x) => x.id === this.timezone).shift();
      if (selectedCountry?.time_zone) {
        moment.tz.setDefault(selectedCountry?.time_zone);
        if (this.global_dateformat || formatType) {
          if (type === 1) {
            return moment
              .utc(date)
              .utcOffset(selectedCountry.time_zone)
              .format(this.global_dateformat);
          } else if (type === 2) {
            return moment
              .utc(date)
              .utcOffset(selectedCountry.time_zone)
              .format(this.global_timeformat);
          } else {

            return moment
              .utc(date)
              .utcOffset(selectedCountry.time_zone)
              .format(format ? format : this.global_dateformat + ' ' + this.global_timeformat);
          }
        } else {

          return moment.utc(date).utcOffset(selectedCountry.time_zone).format(format);
        }
      }
    }
    return moment(date).format(format);
  };

  getLocalTimeDealDesk = (
    date = moment.utc(new Date()),
    format = 'YYYY-MM-DD HH:mm'
  ) => {
    if (date === null) {
      return null;
    }
    let temp_date = moment.utc(date);
    if (this.timezone) {
      //START - Timezone===CountryID Filter to get UTC from global_country_list
      let selectedCountry =
        this.global_country_list &&
        this.global_country_list.find((x) => x.id === this.timezone);
      if (selectedCountry) {
        temp_date = temp_date.utcOffset(selectedCountry.time_zone);
      }
    }
    return temp_date.format(format);
  };

  // Set Timezone offset
  setTimeZone = (timezone) => {
    this.timezone = timezone;
  };
  // Set Global DateFormat
  setGlobalDateFormate = (format) => {
    this.global_dateformat = format;
  };
  // Set Global TimeFormat
  setGlobalTimeFormate = (format) => {
    this.global_timeformat = format;
  };

  deleteWasteData = () => {
    return Axios.get(`waste/permanent-delete`)
      .then(({ data }) => {
        Notify.success({ message: 'Records Removed successfully.' });
        this.fetchLeftmenuCount();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // common api call for reset
  ResetData = (data) => {
    return Axios.post('reminder/reset', data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  //Reminder Reset Push Notification On
  setnotificationData = (data) => {
    if (data) {
      this.notificationData = data;
      this.reminderNotifyVisible = true;
    } else {
      this.notificationData = data;
    }
  };
  //Reminder Reset Push Notification On
  setnotificationVisible = (data) => {
    this.reminderNotifyVisible = data;
  };

  // Used To Save - Grid Filter
  saveGridFilter = (payload, isCommonAPICall = false) => {
    return Axios.post(`page-filter/add`, payload)
      .then(({ data }) => {
        if (payload.page) {
          if (isCommonAPICall) {
            this.setAllPageFiltersLoaded(false);
            this.getAllPageFilter(true);
          } else {
            this.getSavedGridFilter({
              page: payload.page,
            });
          }
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
        }
        return Promise.reject({ errors });
      });
  };

  // Get Used - Grid Filter
  getSavedGridFilter = (payload) => {
    return Axios.post(`page-filter/list`, payload)
      .then(({ data }) => {
        if (payload.page === 'tickets') {
          // let fetch_child_ticket = JSON.parse(localStorage.getItem('fetch_child_ticket'));
          // let get_child_tickets = JSON.parse(localStorage.getItem('get_child_tickets'));


          let defaultMyOpenTickets = {
            'ticket_status.status_name': {
              values: [
                null,
                '5', //'Escalated',
                '2', //'In Progress',
                '9', //'IT Active',
                '8', //'IT Assigned',
                '12', //'IT Feedback',
                '14', //'IT In QA',
                '11', //'IT Paused',
                '10', //'IT Planning',
                '13', //'IT QA Ready',
                '4', //'On Hold',
                '1', //'Open',
                '7', //'Project System',
                '3', //,'Waiting for Response',
              ],
              filterType: 'set',
            },
            ticketholder: {
              values: [localStorage.getItem('user_name')],
              filterType: 'set',
            },
          };
          // if (defaultMyOpenTickets && fetch_child_ticket) {
          //   defaultMyOpenTickets = {
          //     ...defaultMyOpenTickets,
          //     master_ticket_number: {
          //       filterType: 'text',
          //       type: 'contains',
          //       filter: fetch_child_ticket?.master_ticket_number,
          //     },
          //   };
          // }
          // let defaultTicketsFilter;
          // if (get_child_tickets) {
          //   defaultTicketsFilter = {
          //     'ticket_status.status_name': {
          //       values: [
          //         null,
          //         '5', //'Escalated',
          //         '2', //'In Progress',
          //         '9', //'IT Active',
          //         '8', //'IT Assigned',
          //         '12', //'IT Feedback',
          //         '14', //'IT In QA',
          //         '11', //'IT Paused',
          //         '10', //'IT Planning',
          //         '13', //'IT QA Ready',
          //         '4', //'On Hold',
          //         '1', //'Open',
          //         '7', //'Project System',
          //         '3', //,'Waiting for Response',
          //         '6', // 'Closed'
          //       ],
          //       filterType: 'set',
          //     },
          //     ticketholder: {
          //       values: [localStorage.getItem('user_name')],
          //       filterType: 'set',
          //     },
          //   };
          // }

          this.gridfilter_list = [
            {
              id: 0,
              filter: 'Save New Filter',
            },
            // {
            //   id: 99993,
            //   filter: 'Master Ticket',
            //   value: null,
            // },
            // {
            //   id: 99994,
            //   filter: 'Linked Tickets',
            //   value: defaultTicketsFilter,
            // },
            {
              id: 99998,
              filter: 'My Open Tickets',
              value: defaultMyOpenTickets,
            },
            {
              id: 99999,
              filter: 'Open Tickets',
              value: {
                'ticket_status.status_name': {
                  values: [
                    null,
                    '5', //'Escalated',
                    '2', //'In Progress',
                    '9', //'IT Active',
                    '8', //'IT Assigned',
                    '12', //'IT Feedback',
                    '14', //'IT In QA',
                    '11', //'IT Paused',
                    '10', //'IT Planning',
                    '13', //'IT QA Ready',
                    '4', //'On Hold',
                    '1', //'Open',
                    '7', //'Project System',
                    '3', //,'Waiting for Response',
                  ],
                  filterType: 'set',
                },
              },
            },
            {
              id: 99997, ///Recently Updated
              filter: 'Recently Viewed Tickets',
              value: {},
            },
            ...data.data,
          ];
        } else if (payload.page === 'tags') {
          this.gridfilter_list = [
            {
              id: 0,
              filter: 'Save New Filter',
            },
            {
              id: 99997,
              filter: 'My Open Tags',
              value: {
                tag_status_name: {
                  values: [
                    null,
                    '1', //,'Open'
                    '2', //, 'Read',
                    '4', //'Returned'
                  ],
                  filterType: 'set',
                },
                tagholder: {
                  values: [localStorage.getItem('user_name')],
                  filterType: 'set',
                },
              },
            },
            ...data.data,
          ];
        } else if (payload.page === 'reminder') {
          this.gridfilter_list = [
            {
              id: 0,
              filter: 'Save New Filter',
            },
            {
              id: 99996,
              filter: 'My Open Reminder',
              value: {
                status: {
                  values: [
                    //'Approved',
                    //'Document Uploaded',
                    'Open',
                    //'Pending',
                    //'Rejected',
                    'Read',
                  ],
                  filterType: 'set',
                },
              },
            },
            ...data.data,
          ];
        } else if (payload.page === 'promotion') {
          this.gridfilter_list = [
            {
              id: 0,
              filter: 'Save New Filter',
            },
            {
              id: 99995,
              filter: 'My Leads',
              value: {
                'lead_owner_name.full_name': {
                  values: [localStorage.getItem('user_name_fullname')],
                  filterType: 'set',
                },
              },
            },
            ...data.data,
          ];
        } else {
          this.gridfilter_list = [{ id: 0, filter: 'Save New Filter' }, ...data.data];
        }

        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
        }
        return Promise.reject({ errors });
      });
  };

  // call api for login
  getLeadFailedReason = () => {
    return Axios.get('get-failed-reasons')
      .then(({ data }) => {
        this.lead_failed_reason_list = data.data.failed_reasons;
        return true;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;

        return Promise.reject({ errors, notify });
      });
  };
  setIsUserIdle = (data) => {
    this.isUserIdle = data;
  };

  setCallNotesAPIAfterUserBack = (data) => {
    this.callNotesAPIAfterUserBack = data;
  };

  setAllPageFilters = (data) => {
    let pagesListSavedFilterApplied = getSavedFilterPageTypes();
    if (pagesListSavedFilterApplied && pagesListSavedFilterApplied.length > 0) {
      pagesListSavedFilterApplied.map((x) => {
        let pageIndex = data.findIndex((savedFilter) => savedFilter.page === x);
        if (pageIndex === -1) {
          data.push({
            page: x,
            filters: fetchStaticFilters(x),
          });
        }
        return null;
      });
    }

    this.allPageFilters = data;
    localStorage.removeItem('isSavedFiltersFetching');
    this.setAllPageFiltersLoaded(true);
  };

  getAllPageFilter = (maintainLocalVariable = true) => {
    if (maintainLocalVariable) {
      localStorage.setItem('isSavedFiltersFetching', true);
    }
    return Axios.post(`page-filter/v2/list`)
      .then(({ data }) => {
        let allFilters = [];

        if (data.data && data.data.length > 0) {
          allFilters = data.data.reduce((acc, init) => {
            if (init.page) {
              let pageIndex = acc.findIndex((x) => x.page === init.page);
              if (acc && acc.length > 0 && pageIndex >= 0) {
                acc[pageIndex]?.filters?.push(init);
              } else {
                let staticFilters = fetchStaticFilters(init.page);
                acc.push({
                  page: init.page,
                  filters: [...staticFilters, init],
                });
              }
            }
            return acc;
          }, []);
        }

        this.setAllPageFilters(allFilters);

        return data;
      })
      .catch(({ response: { data } }) => {
        localStorage.removeItem('isSavedFiltersFetching');
        var errors = [];
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
        }
        return Promise.reject({ errors });
      });
  };
  setAllPageFiltersLoaded = (data) => {
    this.allPageFiltersLoaded = data;
  };
}

decorate(AuthStore, {
  user: observable,
  token: observable,
  menu: observable,
  loading: observable,
  open_login: observable,
  permissions: observable,
  app_color_theme: observable,
  app_font_size: observable,
  global_country_list: observable,
  api_menu_countused: observable,
  api_menu_count_unused: observable,
  api_menu_count_opportunities: observable,
  api_menu_count_deals: observable,
  api_menu_count_itinerary: observable,
  api_menu_count_reminders: observable,
  api_menu_count_tags: observable,
  api_menu_count_tickets: observable,
  api_menu_count_organisation: observable,
  api_menu_count_people: observable,
  api_menu_count_knowledge: observable,
  api_menu_count_waste: observable,
  header_ticker_info: observable,
  global_dateformat: observable,
  global_timeformat: observable,
  global_fulldate_format: observable,
  global_fulldate_format_upper: observable,
  form_submit_date_with_minutes: observable,
  form_submit_date_with_seconds: observable,
  notificationData: observable,
  reminderNotifyVisible: observable,
  gridfilter_list: observable,
  SOLD_STATUS_FAILED: observable,
  SOLD_STATUS_REJECTED: observable,
  SOLD_STATUS_FUTURE: observable,
  lead_failed_reason_list: observable,
  isUserIdle: observable,
  callNotesAPIAfterUserBack: observable,
  report_data: observable,
  allPageFiltersLoaded: observable,

  setUser: action,
  doLogin: action,
  doLogout: action,
  doForgotPassword: action,
  sendForgotPasswordLink: action,
  doResetPassword: action,
  checkPermission: action,

  menuused_title: observable,
  menuUsedText: observable,
  menuused_link: observable,
  menuused_count: observable,
  menu_people_org_title: observable,
  menuPeopleText: observable,
  menu_people_org_link: observable,
  menu_people_org_count: observable,
  updateSwitchOrgmenu: action,
  updateSwitchmenu: action,
  setThemeFontColor: action,
  fetchGlobalCountryList: action,
  updateUIsettings: action,
  fetchLeftmenuCount: action,
  getTickerHeaderInfo: action,
  GetThemedImage: action,
  updateCountryTimezone: action,

  timezone: observable,
  getLocalTime: action,
  setTimeZone: action,
  setGlobalDateFormate: action,
  setGlobalTimeFormate: action,
  getLocalTimeDealDesk: action,
  ResetData: action,
  clearLocalStorage: action,
  setnotificationData: action,
  saveGridFilter: action,
  getSavedGridFilter: action,
  getLeadFailedReason: action,
  setIsUserIdle: action,
  setCallNotesAPIAfterUserBack: action,
  fetchDashboardReport: action,
  getAllPageFilter: action,
  setAllPageFilters: action,
  setAllPageFiltersLoaded: action,
});
