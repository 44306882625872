import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const Delete = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { SEARCHSTORE, KeyNotesSearchStore, KeyNotesSearchStore: { deleteKeyNote, deleteValue, } } = useStore();
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    // KeyNotesSearchStore.setDeleteValue(null)
    form.resetFields();
    setSaving(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    deleteKeyNote(props.searchPayload)
      .then(() => {
        close();
        if (props?.SearchPage) {
          KeyNotesSearchStore.agGrid?.api.applyTransaction({ remove: [deleteValue] });
          SEARCHSTORE.updateSearchCount({ Key_note_count: SEARCHSTORE.searchCount.Key_note_count - 1 });
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return <Modal
    centered
    title={`${t('Common_Data.Delete')} ${t('DealDesk.Key_Note')} - #${deleteValue?.id}`}
    visible={props.visible}
    onCancel={close}
    onOk={close}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
    footer={[
      <Button
        key='1'
        form={'deleteKeyNoteForm'}
        loading={saving}
        htmlType='submit'
        type='primary'
        shape='round'
        danger
      >
        {t('Common_Data.Delete')}
      </Button>,
      <Button key='2' htmlType='button' shape='round' onClick={close}>
        {t('Common_Data.Cancel')}
      </Button>,
    ]}
  >
    <Form form={form} id={`deleteKeyNoteForm`} onFinish={handleSubmit}>
      {
        <Row align='middle'>
          <Col span={4} className='DeleteIcon'>
            <WarningFilled />
          </Col>
          <Col span={20} className='DeleteText'>
            {`${t('Knowledge.You_Want_Delete')} ${t('DealDesk.Key_Note')} - #${deleteValue?.id}?`}
          </Col>
        </Row>
      }
    </Form>
  </Modal>
});

export default Delete;
