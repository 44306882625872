import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import useStore from '../store';
import InputComponent from './InputComponent';
import SaveGridFilter from './SaveGridFilter';
import { useTranslation } from 'react-i18next';

const ApplyGridFilter = observer((props) => {
  const [visibleSaveFilter, setvisibleSaveFilter] = useState(false);
  const [dataSaveFilter, setdataSaveFilter] = useState(null);
  const { t } = useTranslation();
  const {
    AUTH: { gridfilter_list, getSavedGridFilter },
    PEOPLE,
    ORGANISATION,
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    LEADSSTORE,
    TagsStore,
    TagsStore: { setIsSavedFiltersLoadedTicket },
    TicketsStore,
    REMINDERSSTORE,
    TicketsStore: { setSelectedGridFilter },
    PROMOTIONSTORE,
    PRODUCTIVITYSTORE,
    BLOCKEDITASSIGNSTORE,
    MANAGEMENTALLDEPARTMENTSSTORE,
  } = useStore();
  const [form] = Form.useForm();

  const applyGridFilter = useCallback(
    (filterid) => {
      if (filterid) {
        let filterData = gridfilter_list.filter((x) => x.id === filterid);
        if (filterData && filterData.length > 0) {
          if (props.type === 'tickets') {
            let filterstringstr = filterData[0]?.value;
            if (![99997, 99998, 99999, 2].includes(filterid)) {
              // If Not a static(appended) filter
              filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            }
            TicketsStore.updateGridOnSavedFilter(filterstringstr);
            TicketsStore.setSelectedGridFilter(filterid);
          }
          if (props.type === 'people') {
            let filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            PEOPLE.updateGridOnSavedFilter(filterstringstr);
            PEOPLE.setSelectedGridFilter(filterid);
          }
          if (props.type === 'organisations') {
            let filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            ORGANISATION.updateGridOnSavedFilter(filterstringstr);
            ORGANISATION.setSelectedGridFilter(filterid);
          }
          if (props.type === 'deals') {
            let filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            LEADDEALS.updateGridOnSavedFilter(filterstringstr);
            LEADDEALS.setSelectedGridFilter(filterid);
          }
          if (props.type === 'opportunity') {
            let filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            LEADOPPORTUNITY.updateGridOnSavedFilter(filterstringstr);
            LEADOPPORTUNITY.setSelectedGridFilter(filterid);
          }
          if (props.type === 'activeleads') {
            let filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            LEADACTIVE.updateGridOnSavedFilter(filterstringstr);
            LEADACTIVE.setSelectedGridFilter(filterid);
          }
          if (props.type === 'unusedleads') {
            let filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            LEADSSTORE.updateGridOnSavedFilter(filterstringstr);
            LEADSSTORE.setSelectedGridFilter(filterid);
          }
          if (props.type === 'tags') {
            let filterstringstr = filterData[0]?.value;
            if (![99997].includes(filterid)) {
              // If Not a static(appended) filter
              filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            }

            TagsStore.updateGridOnSavedFilter(filterstringstr);
            TagsStore.setSelectedGridFilter(filterid);
          }

          if (props.type === 'reminder') {
            let filterstringstr = filterData[0]?.value;
            if (![99996].includes(filterid)) {
              // If Not a static(appended) filter
              filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));
            }

            REMINDERSSTORE.updateGridOnSavedFilter(filterstringstr);
            REMINDERSSTORE.setSelectedGridFilter(filterid);
          }
          if (props.type === 'promotion') {
            let filterstringstr = filterData[0]?.value;
            if (![99995].includes(filterid)) {
              // If Not a static(appended) filter
              filterstringstr = JSON.parse(filterstringstr);
            }
            PROMOTIONSTORE.updateGridOnSavedFilter(filterstringstr);
            PROMOTIONSTORE.setSelectedGridFilter(filterid);
          }
          if (props.type === 'productivity') {
            let filterstringstr = filterData[0]?.value;
            filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));

            PRODUCTIVITYSTORE.updateGridOnSavedFilter(filterstringstr);
            PRODUCTIVITYSTORE.setSelectedGridFilter(filterid);
          }
          if (props.type === 'blockeditassign') {
            let filterstringstr = filterData[0]?.value;
            filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));

            BLOCKEDITASSIGNSTORE.updateGridOnSavedFilter(filterstringstr);
            BLOCKEDITASSIGNSTORE.setSelectedGridFilter(filterid);
          }
          if (props.type === 'alldepartments') {
            let filterstringstr = filterData[0]?.value;
            filterstringstr = JSON.parse(filterData[0]?.value.replace(/[']/g, ''));

            MANAGEMENTALLDEPARTMENTSSTORE.updateGridOnSavedFilter(filterstringstr);
            MANAGEMENTALLDEPARTMENTSSTORE.setSelectedGridFilter(filterid);
          }
        }
      }
    },
    [
      props.type,
      TicketsStore,
      PEOPLE,
      ORGANISATION,
      LEADDEALS,
      LEADOPPORTUNITY,
      LEADACTIVE,
      LEADSSTORE,
      gridfilter_list,
      TagsStore,
      REMINDERSSTORE,
      PROMOTIONSTORE,
      PRODUCTIVITYSTORE,
      BLOCKEDITASSIGNSTORE,
      MANAGEMENTALLDEPARTMENTSSTORE,
    ]
  );
  const onChange = () => {
    if (form.getFieldValue('filtername') === 0) {
      openSaveFilterPopup(props.type);
      if (props.type === 'tickets') {
        setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'people') {
        PEOPLE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'organisations') {
        ORGANISATION.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'deals') {
        LEADDEALS.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'opportunity') {
        LEADOPPORTUNITY.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'activeleads') {
        LEADACTIVE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'unusedleads') {
        LEADSSTORE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'tags') {
        TagsStore.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'reminder') {
        REMINDERSSTORE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'promotion') {
        PROMOTIONSTORE.setSelectedGridFilter(form.setFieldsValue({ filtername: null }));
      }
      if (props.type === 'productivity') {
        PRODUCTIVITYSTORE.setSelectedGridFilter(
          form.setFieldsValue({ filtername: null })
        );
      }
      if (props.type === 'alldepartments') {
        MANAGEMENTALLDEPARTMENTSSTORE.setSelectedGridFilter(
          form.setFieldsValue({ filtername: null })
        );
      }
      if (props.type === 'blockeditassign') {
        BLOCKEDITASSIGNSTORE.setSelectedGridFilter(
          form.setFieldsValue({ filtername: null })
        );
      }
    } else {
      applyGridFilter(form.getFieldValue('filtername'));
    }
  };

  useEffect(() => {
    getSavedGridFilter({ page: props.type }).then(() => {
      if (props.type === 'tags') {
        setIsSavedFiltersLoadedTicket(true);
      }
    });

    return function cleanup() {
      if (props.type === 'tags') {
        setIsSavedFiltersLoadedTicket(false);
      }
    };
  }, [props.type, getSavedGridFilter, setIsSavedFiltersLoadedTicket]);

  useEffect(() => {
    form.setFieldsValue({
      filtername: props.selectionValue,
    });
    applyGridFilter(props.selectionValue);
  }, [props.selectionValue, form, applyGridFilter]);

  const openSaveFilterPopup = (type) => {
    setvisibleSaveFilter(true);
    if (type === 'tickets') {
      setdataSaveFilter({ page: type, value: TicketsStore.agGrid.api.getFilterModel() });
    } else if (type === 'people') {
      setdataSaveFilter({ page: type, value: PEOPLE.agGrid.api.getFilterModel() });
    } else if (type === 'organisations') {
      setdataSaveFilter({ page: type, value: ORGANISATION.agGrid.api.getFilterModel() });
    } else if (type === 'deals') {
      setdataSaveFilter({ page: type, value: LEADDEALS.agGrid.api.getFilterModel() });
    } else if (type === 'opportunity') {
      setdataSaveFilter({
        page: type,
        value: LEADOPPORTUNITY.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'activeleads') {
      setdataSaveFilter({
        page: type,
        value: LEADACTIVE.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'unusedleads') {
      setdataSaveFilter({
        page: type,
        value: LEADSSTORE.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'tags') {
      setdataSaveFilter({
        page: type,
        value: TagsStore.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'reminder') {
      setdataSaveFilter({
        page: type,
        value: REMINDERSSTORE.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'promotion') {
      setdataSaveFilter({
        page: type,
        value: PROMOTIONSTORE.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'productivity') {
      setdataSaveFilter({
        page: type,
        value: PRODUCTIVITYSTORE.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'alldepartments') {
      setdataSaveFilter({
        page: type,
        value: MANAGEMENTALLDEPARTMENTSSTORE.agGrid.api.getFilterModel(),
      });
    } else if (props.type === 'blockeditassign') {
      setdataSaveFilter({
        page: type,
        value: BLOCKEDITASSIGNSTORE.agGrid.api.getFilterModel(),
      });
    } else {
      setdataSaveFilter({ page: type, value: {} });
    }
  };
  const closeSaveFilterPopup = () => {
    setvisibleSaveFilter(false);
    setdataSaveFilter(null);
  };

  // To Update New Saved Filter ID
  const updateNewAddedFilter = (id) => {
    if (props.type === 'tickets') {
      TicketsStore.setSelectedGridFilter(id);
    }
    if (props.type === 'people') {
      PEOPLE.setSelectedGridFilter(id);
    }
    if (props.type === 'organisations') {
      ORGANISATION.setSelectedGridFilter(id);
    }
    if (props.type === 'deals') {
      LEADDEALS.setSelectedGridFilter(id);
    }
    if (props.type === 'activeleads') {
      LEADACTIVE.setSelectedGridFilter(id);
    }
    if (props.type === 'unusedleads') {
      LEADSSTORE.setSelectedGridFilter(id);
    }
    if (props.type === 'tags') {
      TagsStore.setSelectedGridFilter(id);
    }
    if (props.type === 'reminder') {
      REMINDERSSTORE.setSelectedGridFilter(id);
    }
    if (props.type === 'promotion') {
      PROMOTIONSTORE.setSelectedGridFilter(id);
    }
    if (props.type === 'productivity') {
      PRODUCTIVITYSTORE.setSelectedGridFilter(id);
    }
    if (props.type === 'alldepartments') {
      MANAGEMENTALLDEPARTMENTSSTORE.setSelectedGridFilter(id);
    }
    if (props.type === 'blockeditassign') {
      BLOCKEDITASSIGNSTORE.setSelectedGridFilter(id);
    }
  };

  return (
    <>
      <Form
        id='applyfilter'
        form={form}
        layout='vertical'
        className={
          ['promotion', 'productivity', 'blockeditassign', 'alldepartments'].includes(
            props.type
          )
            ? 'innerForm' //-----------GRID TOP FILTER FOR DYAMIC SIZE------------------//
            : 'innerForm fixDropdown' //-----------FIX WIDTH------------------//
        }
      >
        <InputComponent
          name='filtername'
          type='select'
          // style={{ margin: '0', minWidth: '250px' }}
          placeholder={`${t('Common_Data.Select_Filter')}`}
          showSearch={true}
          allowClear={true}
          onClear={() => {
            form.resetFields();
            if (props.type === 'tickets') {
              TicketsStore.handleReset();
            }
            if (props.type === 'people') {
              PEOPLE.handleReset();
            }
            if (props.type === 'organisations') {
              ORGANISATION.handleReset();
            }
            if (props.type === 'deals') {
              LEADDEALS.handleReset();
            }
            if (props.type === 'activeleads') {
              LEADACTIVE.handleReset();
            }
            if (props.type === 'unusedleads') {
              LEADSSTORE.handleReset();
            }
            if (props.type === 'tags') {
              TagsStore.handleReset();
            }
            if (props.type === 'reminder') {
              REMINDERSSTORE.handleReset();
            }
            if (props.type === 'promotion') {
              PROMOTIONSTORE.handleReset();
            }
            if (props.type === 'productivity') {
              PRODUCTIVITYSTORE.handleReset();
            }
            if (props.type === 'blockeditassign') {
              BLOCKEDITASSIGNSTORE.handleReset();
            }
          }}
          onChange={onChange}
          options={{
            values:
              (gridfilter_list.length <= 0 &&
                props.type === 'tickets' && [
                  {
                    id: 99998,
                    filter: 'My Open Tickets',
                  },
                ]) ||
              (gridfilter_list.length <= 0 &&
                props.type === 'tags' && [
                  {
                    id: 99997,
                    filter: 'My Open Tags',
                  },
                ]) ||
              (gridfilter_list.length <= 0 &&
                props.type === 'reminder' && [
                  {
                    id: 99996,
                    filter: 'My Open Reminder',
                  },
                ]) ||
              gridfilter_list,

            value_key: 'id',
            text_key: 'filter',
          }}
          dropdownClassName={'globalGridFilterList'}
        />
      </Form>
      <SaveGridFilter
        visible={visibleSaveFilter}
        dataSaveFilter={dataSaveFilter}
        close={closeSaveFilterPopup}
        updateNewAddedFilter={updateNewAddedFilter}
      />
    </>
  );
});

export default ApplyGridFilter;
