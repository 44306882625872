import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row, Form } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const OrgPeopleBankAccNoElement = observer(({ onChange }) => {
  const {t} = useTranslation()
  return (
    <Form.List name='org_people_bank_acc_no'>
      {(fields, { add, remove }) => {
        return (
          fields &&
          fields.map((field, index) => {
            return (
              <div className='w-100' key={field.key}>
                <Row gutter={10}>
                  <Col flex={1}>
                    <InputComponent
                      required
                      name={[field.name]}
                      key={[field.key]}
                      onChange={onChange}
                      placeholder={`${t('Reports.Org')}/${t('Common_Data.People')} ${t('Common_Data.Bank_Account')} ${t('Common_Data.No')}`}
                      tooltip={`${t('Reports.Bank_Account_Tooltip')}.`}
                    />
                  </Col>
                  <Col>
                    <div className='d-flex'>
                      {fields.length > 1 && (
                        <Button
                          type='danger'
                          onClick={() => remove(field.name)}
                          icon={<MinusOutlined />}
                        ></Button>
                      )}
                      {index === fields.length - 1 && (
                        <Button
                          className={fields.length > 1 ? 'ml-10' : null}
                          type='primary'
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })
        );
      }}
    </Form.List>
  );
});

export default OrgPeopleBankAccNoElement;
