import useStore from '../../../store';
import {
  // defaultFieldsListReport,
  dynamicFieldsListReport, dynamicFieldsRelationshipReport, getReportOnFromSavedFilterPageType, relationShipsReportType,
  //dynamicFieldsListReport,
} from '../../../utils/GlobalFunction';
import GetDynamicColumns from './GetDynamicColumns';

const GetGridColumnOptions = (reportType, mainReportType) => {
  const {
    REPORTSTORE: { previewReportValues },
  } = useStore();
  if (previewReportValues?.report_on === 'saved_filter') {
    reportType = 'sold';
  }
  if (
    previewReportValues?.filter_type &&
    getReportOnFromSavedFilterPageType[previewReportValues?.filter_type]
  ) {
    reportType =
      getReportOnFromSavedFilterPageType[previewReportValues.filter_type];
  }
  let gridOptionsDynamic = [];

  let columns = [];
  // if (previewReportValues?.grid_columns) {
  //   columns = previewReportValues?.grid_columns;
  // } else {
  if (relationShipsReportType.includes(mainReportType)) {
    columns = dynamicFieldsRelationshipReport[reportType];
  }
  else {
    columns = dynamicFieldsListReport[reportType];
  }
  // }

  columns &&
    columns.length > 0 &&
    columns.forEach((element) => {
      if (
        reportType &&
        [
          'reminders',
          'sold',
          'tags',
          'tickets',
          'people',
          'organisation',
          'saved_filter',
        ].includes(reportType)
      ) {
        gridOptionsDynamic.push(GetDynamicColumns(element, reportType, mainReportType));
      }
    });

  return gridOptionsDynamic;
};

export default GetGridColumnOptions;
