import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
//import TopSelection from './component/TopSelection';
import TagsSearchListing from './TagsSearchListing';
// import EditComponent from '../../Tags/component/EditComponent';
import DeleteComponent from '../../Tags/component/DeleteComponent';
// import CloneComponent from './component/CloneComponent';
// import ViewComponent from './component/ViewComponent';
// import { default as ViewComponentTicket } from '../../Ticket/component/ViewComponent';

import useStore from '../../../store';
// import { PageHeader, Space, Form, Tooltip, Typography } from 'antd';
// import RecordPerPage from '../../config/RecordPerPage';
// import EnterFullscreen from '../../component/EnterFullscreen';

import Unauthorized from '../../Unauthorized';
import EditViewComponent from '../../Tags/component/EditViewComponent';

// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import TimeTracker from '../../../component/TimeTracker';
// import { filterFiltersByPageType } from '../../utils/GlobalFunction';
// import ApplyGridFilterNew from '../../component/ApplyGridFilterNew';
// import ResetGridOptions from '../../component/ResetGridOptions';
// import { useTranslation } from 'react-i18next';

const TagsSearch = observer((props) => {
    // const { t } = useTranslation()
    // const [viewDrawer, setViewDrawer] = useState(false);
    const [viewEditDrawer, setViewEditDrawer] = useState(false);
    // const [editDrawer, setEditDrawer] = useState(false);
    const [deleteDrawer, setDeleteDrawer] = useState(false);
    // const [cloneDrawer, setCloneDrawer] = useState(false);
    const [viewRowIndex, setViewRowIndex] = useState(null);
    const [prevLoader, setPrevLoader] = useState(false);
    const [nextLoader, setNextLoader] = useState(false);
    // const [viewTicketDrawer, setViewTicketDrawer] = useState(false);
    // const [id, setId] = useState(); // Used For Ticket's View
    const [loadingEditView, setLoadingEditView] = useState(false);

    const { SearchPage, AllView,
        //  sectorSelector, search_term, resultCount, handleRoute 
    } = props;
    // const { Title } = Typography;

    const {
        AUTH: { allPageFilters, allPageFiltersLoaded },
        TagsStore,
        AUTH,
        // SETTINGS,
        // TicketsStore,
        // DEALDESKSTORE: { stopInvestmentHourCounter, setStopInvestmentHourCounter },
    } = useStore();
    // const [form] = Form.useForm();
    //Edit drawer
    // const openEditDrawer = (data) => {
    //     TagsStore.setEditValues(data);
    //     setEditDrawer(true);
    // };
    // const closeEditDrawer = () => setEditDrawer(false);

    //open delet drawer
    const openDeleteDrawer = (data) => {
        TagsStore.setDeleteValues(data);
        setDeleteDrawer(true);
    };
    const closeDeleteDrawer = () => setDeleteDrawer(false);

    //Clone drawer
    // const openCloneDrawer = (data) => {
    //     TagsStore.setCloneValues(data);
    //     setCloneDrawer(true);
    // };
    // const closeCloneDrawer = () => setCloneDrawer(false);

    //View drawer
    // const openViewDrawer = (data) => {
    //     if (AUTH.checkPermission(14, 'view-details')) {
    //         TagsStore.setViewValues(data);
    //         setViewDrawer(true);
    //     }
    // };
    // const closeViewDrawer = () => setViewDrawer(false);

    //View drawer
    const openEditViewDrawer = (data, index) => {
        if (AUTH.checkPermission(14, 'view-details')) {
            setLoadingEditView(true);
            //TagsStore.setViewValues(data);

            TagsStore.viewValues = data;
            TagsStore.setViewValues(data).then((res) => {
                if (TagsStore.viewValues.tag_status_id === 1) {
                    //If Open Then Convert To Read
                    if (TagsStore.viewValues.tag_holder.id === AUTH.user.id) {
                        TagsStore.viewValues.tag_status_id = 2;
                    }
                }
                TagsStore.viewValues.leads_notes_description = res?.leads_notes_description;
                setLoadingEditView(false);
            });

            setViewRowIndex(index);
            if (TagsStore.list_data.length > 0) {
                if (TagsStore.list_data.length === index + 1) {
                    setNextLoader(true);
                }
                if (TagsStore.list_data.length > index + 1) {
                    setNextLoader(false);
                }
            }

            // setStopInvestmentHourCounter(true);
            setViewEditDrawer(true);
        }
    };
    const closeEditViewDrawer = () => {
        // setStopInvestmentHourCounter(false);
        // TagsStore.viewValues = null;
        setViewEditDrawer(false);
    };

    //View drawer
    // const openTicketViewDrawer = (data) => {
    //     if (AUTH.checkPermission(15, 'view-details')) {
    //         TicketsStore.apicalled = false;
    //         setId(data);
    //         //TicketsStore.setViewValues(data);
    //         setViewTicketDrawer(true);
    //     }
    // };
    // const closeTicketViewDrawer = () => {
    //     TicketsStore.viewValues = null;
    //     setId(null);
    //     setViewTicketDrawer(false);
    // };

    //confirmation alert before deleting existing user & edit
    const onCellKeyDown = (e) => {
        if (AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
            if (
                AUTH.user.bonus_figures[0].shortcut_keys &&
                AUTH.user.bonus_figures[0].shortcut_keys.length > 0
            ) {
                const result = AUTH.user.bonus_figures[0].shortcut_keys
                    .filter((x) => x.key === e.event.key)
                    .shift();
                if (result) {
                    if (AUTH.checkPermission(14, 'edit')) {
                        if (result.key === 'Enter') {
                            openEditViewDrawer(e.data);
                        }
                    }

                    if (AUTH.checkPermission(14, 'delete')) {
                        if (result.key === 'Delete') {
                            openDeleteDrawer(e.data);
                        }
                    }
                }
            }
        }
    };

    const previousNextReminder = (type, rowIndex) => {
        if (type === 1 && rowIndex) {
            //Previous
            setLoadingEditView(true);
            setPrevLoader(true);
            setViewRowIndex(rowIndex - 1);
            if (TagsStore.list_data[rowIndex - 1]) {
                TagsStore.viewValues = TagsStore.list_data[rowIndex - 1];
                setNextLoader(false);
                setPrevLoader(false);
            }

            TagsStore.setViewValues(TagsStore.list_data[rowIndex - 1]).then((res) => {
                if (TagsStore.viewValues.tag_status_id === 1) {
                    //If Open Then Convert To Read
                    if (TagsStore.viewValues.tag_holder.id === AUTH.user.id) {
                        TagsStore.viewValues.tag_status_id = 2;
                    }
                }
                TagsStore.viewValues.leads_notes_description = res?.leads_notes_description;
                setLoadingEditView(false);
            });
        }
        if (type === 2) {
            //Next
            if (rowIndex || rowIndex === 0) {
                if (TagsStore.list_data && rowIndex + 1 < TagsStore.list_data.length) {
                    setLoadingEditView(true);
                    setNextLoader(true);
                    setViewRowIndex(rowIndex + 1);

                    if (TagsStore.list_data[rowIndex + 1]) {
                        TagsStore.viewValues = TagsStore.list_data[rowIndex + 1];

                        if (rowIndex + 2 === TagsStore.list_data.length) {
                            setPrevLoader(false);
                            setNextLoader(true);
                        } else if (rowIndex + 2 <= TagsStore.list_data.length) {
                            setPrevLoader(false);
                            setNextLoader(false);
                        }
                    }
                    TagsStore.setViewValues(TagsStore.list_data[rowIndex + 1]).then((res) => {
                        if (TagsStore.viewValues.tag_status_id === 1) {
                            //If Open Then Convert To Read
                            if (TagsStore.viewValues.tag_holder.id === AUTH.user.id) {
                                TagsStore.viewValues.tag_status_id = 2;
                            }
                        }
                        TagsStore.viewValues.leads_notes_description = res?.leads_notes_description;
                        setLoadingEditView(false);
                    });
                }
            }
        }
    };

    useEffect(() => { }, [allPageFiltersLoaded, allPageFilters]);

    // const pageFilterType = 'tags';

    return (
        <>
            {!AUTH.checkPermission(14, 'list') ? (
                <Unauthorized />
            ) :
                (<>
                    <TagsSearchListing
                        // openViewDrawer={openViewDrawer}
                        // openEditDrawer={openEditDrawer}
                        openDeleteDrawer={openDeleteDrawer}
                        // openCloneDrawer={openCloneDrawer}
                        openEditViewDrawer={openEditViewDrawer}
                        SearchPage={SearchPage}
                        onCellKeyDown={onCellKeyDown}
                        // openTicketViewDrawer={openTicketViewDrawer}
                        AllView={AllView}
                    />
                    {/* <ViewComponent visible={viewDrawer} close={closeViewDrawer} /> */}
                    {/* <EditComponent visible={editDrawer} close={closeEditDrawer} /> */}
                    <EditViewComponent
                        visible={viewEditDrawer}
                        close={closeEditViewDrawer}
                        viewRowIndex={viewRowIndex}
                        prevLoader={prevLoader}
                        nextLoader={nextLoader}
                        TotalRecords={TagsStore?.list_data?.length}
                        previousNextReminder={previousNextReminder}
                        setPrevLoader={setPrevLoader}
                        setNextLoader={setNextLoader}
                        loadingEditView={loadingEditView}
                        SearchPage={SearchPage}
                    />
                    <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} SearchPage={SearchPage} />
                    {/* <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} /> */}

                    {/* <ViewComponentTicket
                        visible={viewTicketDrawer}
                        id={id}
                        setId={setId}
                        close={closeTicketViewDrawer}
                    /> */}

                    {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={SearchPage ? 'search' : 'tags'} />} */}
                    <TimeTracker
                        page={"tags"}
                        stop={viewEditDrawer}
                    />
                </>
                )}
        </>
    );
});

export default TagsSearch;
