import React from 'react';
import { Switch } from 'antd';
import useStore from '../../../store';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

export const ActionRenderer = (props) => {
  const {
    AUTH,
    DEALDESKSTORE: { deal_data, toogleKeyDocument, fetchFileFolderList, downloadFileS3 },
  } = useStore();
  const { t } = useTranslation()
  const downloadFile = (filedata) => {
    let payload = {
      org_id: deal_data?.lead_information?.sold_organisation?.id,
      leadid: deal_data.lead_information?.id,
      file: filedata.entity_name,
      fileID: filedata.id,
    };

    downloadFileS3(payload)
      .then((data) => {
        let anchor = document.createElement('a');
        anchor.href = data;
        anchor.download = filedata.entity_name;
        anchor.target = '_blank';
        anchor.click();
      })
      .catch((e) => { })
      .finally(() => { });
  };

  return (
    <div className='action-column'>
      <Switch
        defaultChecked={props.data.is_key_document}
        onChange={(val) => {
          toogleKeyDocument(props.data.id, { is_key_document: val }).then((data) => {
            fetchFileFolderList(deal_data?.lead_information?.id);
            vsmNotify.success({
              message: `${t('Common_Data.Key')} ${t('Common_Data.Document')} ${t('FoldersFileModule.Updated')} ${t('Common_Data.Successfully')}!`,
            });
          });
        }}
      />

      <span onClick={() => downloadFile(props.data)}>
        <img src={AUTH.GetThemedImage('Download_icn')} alt='' />
      </span>
      {/* <a href={props.data.full_path} target='_blank' rel='noopener noreferrer'>
        <img src={AUTH.GetThemedImage('Download_icn')} alt='' />
      </a> */}
    </div>
  );
};

export const gridOptions = {

  columnDefs: [
    {
      headerName: 'Key Document',
      field: 'actions',
      cellClass: 'cellClass',
      type: 'actionColumn',
      width: 100,
      minWidth: 150,
      filter: false,
      sortable: false,
      //pinned: 'right'
    },
  ],
};
