import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class TableExampleStore {
  per_page = LocalGridConfig.options.paginationPageSize;
  agGrid = null;

  list_data = null;
  total = 0;
  allColumnIds = [];

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    this.agGrid.columnApi &&
      this.agGrid.columnApi.getAllColumns().forEach((column) => {
        this.allColumnIds.push(column.colId);
      });
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // call api to get records
  getList = () => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.api.getSortModel();
    }
    setTimeout(() => {
      this.list_data = [
        { id: 1, name: "Test 1", desc: "Desc 1", count: 10 },
        { id: 2, name: "Test 2", desc: "Desc 2", count: 20 },
        { id: 3, name: "Test 3", desc: "Desc 3", count: 30 },
        { id: 4, name: "Test 4", desc: "Desc 4", count: 40 },
        { id: 5, name: "Test 5", desc: "Desc 5", count: 20 },
        { id: 6, name: "Test 6", desc: "Desc 6", count: 40 },
        { id: 7, name: "Test 7", desc: "Desc 7", count: 10 },
        { id: 8, name: "Test 8", desc: "Desc 8", count: 60 },
        { id: 9, name: "Test 9", desc: "Desc 9", count: 20 },
        { id: 10, name: "Test 10", desc: "Desc 10", count: 110 },
        { id: 11, name: "Test 11", desc: "Desc 11", count: 20 },
        { id: 11, name: "Test 12", desc: "Desc 12", count: 20 },
        { id: 11, name: "Test 13", desc: "Desc 13", count: 20 },
        { id: 11, name: "Test 14", desc: "Desc 14", count: 20 },
        { id: 11, name: "Test 15", desc: "Desc 15", count: 20 },
        { id: 11, name: "Test 16", desc: "Desc 16", count: 20 },
        { id: 11, name: "Test 17", desc: "Desc 17", count: 20 },
        { id: 11, name: "Test 18", desc: "Desc 18", count: 20 },
        { id: 11, name: "Test 19", desc: "Desc 19", count: 20 },
        { id: 11, name: "Test 20", desc: "Desc 20", count: 20 },
        { id: 11, name: "Test 21", desc: "Desc 21", count: 20 },
      ];
      if (this.agGrid) {
        this.agGrid.columnApi.autoSizeColumns(this.allColumnIds, false);
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.api.setSortModel(sort);
      }
    }, 500);
  };
}

decorate(TableExampleStore, {
  per_page: observable,
  agGrid: observable,
  list_data: observable,
  total: observable,
  allColumnIds: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
});
