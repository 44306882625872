import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReminderSearchListing from './ReminderSearchLisitng';
// import AddComponent from './component/AddComponent';
import EditComponent from '../../Reminders/component/EditComponent';
import DeleteComponent from '../../Reminders/component/DeleteComponent';
// import ViewComponent from './component/ViewComponent';
// import { PageHeader, Button, Space, Form, Tooltip, Typography } from 'antd';
import useStore from '../../../store';
// import RecordPerPage from '../../config/RecordPerPage';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';
// import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../../Unauthorized';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import TimeTracker from '../../../component/TimeTracker';
// import ApplyGridFilterNew from '../../component/ApplyGridFilterNew';
// import { filterFiltersByPageType } from '../../utils/GlobalFunction';
// import ResetGridOptions from '../../component/ResetGridOptions';
// import { useTranslation } from 'react-i18next';

const RemindersSearch = observer((props) => {
//   const { t } = useTranslation()
//   const [addReminder, setAddReminder] = useState(false);
  const [editReminder, setEditReminder] = useState(false);
  const [deleteReminder, setDeleteReminder] = useState(false);
//   const [viewDrawer, setViewDrawer] = useState(false);

  const [isRefreshGrid, setIsRefreshGrid] = useState(false);

  const {
    REMINDERSSTORE,
    // SETTINGS,
    AUTH,
    AUTH: { allPageFilters, allPageFiltersLoaded },
    // DEALDESKSTORE: { stopInvestmentHourCounter, setStopInvestmentHourCounter },
  } = useStore();

  const { SearchPage, AllView,
    //  AllSOLDReminder, sectorSelector, search_term, resultCount, handleRoute 
    } = props;
//   const { Title } = Typography;

  //open add drawer
//   const openAddReminder = (data) => {
//     // DEALDESKSTORE.deal_data = null;
//     setAddReminder(true);
//   };
//   const closeAddReminder = () => setAddReminder(false);

  //open edit drawer
  const openEditReminder = (data) => {
    //DEALDESKSTORE.deal_data = null;
    if (!data.is_read) {
      setIsRefreshGrid(true);
    }
    REMINDERSSTORE.setEditValues(data);
    // setStopInvestmentHourCounter(true);
    setEditReminder(true);
  };
  const closeEditReminder = () => {
    // setStopInvestmentHourCounter(false);
    setEditReminder(false);
    setIsRefreshGrid(false);
  };

  //open delete drawer
  const openDeleteReminder = (data) => {
    REMINDERSSTORE.setDeleteValues(data);
    setDeleteReminder(true);
  };
  const closeDeleteReminder = () => setDeleteReminder(false);

  //open view drawer
//   const openViewDrawer = (data) => {
//     if (AUTH.checkPermission(46, 'view')) {
//       if (!data.is_read) {
//         setIsRefreshGrid(true);
//       }
//       REMINDERSSTORE.setViewValues(data);
//       setStopInvestmentHourCounter(true);
//       setViewDrawer(true);
//     }
//   };
//   const closeViewDrawer = () => {
//     REMINDERSSTORE.viewValues = null;
//     setViewDrawer(false);
//     setStopInvestmentHourCounter(false);
//     setIsRefreshGrid(false);
//   };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(46, 'edit')) {
            if (result.key === 'Enter') {
              openEditReminder(e.data);
            }
          }
          if (AUTH.checkPermission(46, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteReminder(e.data);
            }
          }
        }
      }
    }
  };

  useEffect(() => { }, [allPageFilters, allPageFiltersLoaded]);

//   const pageFilterType = 'reminder'; //FOR PROPS: TOP FILTER DROPDOWN LIST AND RESET OPTIONS

  return (
    <>
      {!AUTH.checkPermission(46, 'list') ? (
        <Unauthorized />
      ) :
        //  SearchPage ? (
        //   <>
        //     <ListComponent
        //       openAddReminder={openAddReminder}
        //       openEditReminder={openEditReminder}
        //       openDeleteReminder={openDeleteReminder}
        //       openViewDrawer={openViewDrawer}
        //       SearchPage={SearchPage}
        //       AllView={AllView}
        //     />
        //     <AddComponent visible={addReminder} close={closeAddReminder} />
        //     <EditComponent visible={editReminder} close={closeEditReminder} />
        //     <DeleteComponent
        //       visible={deleteReminder}
        //       close={closeDeleteReminder}
        //       SearchPage={SearchPage}
        //     />
        //     <ViewComponent visible={viewDrawer} close={closeViewDrawer} />
        //   </>
        // ) : 
        // AllSOLDReminder ? (
        //   <>
        //     <ListComponent
        //       openAddReminder={openAddReminder}
        //       openEditReminder={openEditReminder}
        //       openDeleteReminder={openDeleteReminder}
        //       openViewDrawer={openViewDrawer}
        //       SearchPage={SearchPage}
        //       onCellKeyDown={onCellKeyDown}
        //       AllSOLDReminder={AllSOLDReminder}
        //     />
        //     <AddComponent visible={addReminder} close={closeAddReminder} />
        //     <EditComponent
        //       visible={editReminder}
        //       close={closeEditReminder}
        //       isRefreshGrid={isRefreshGrid}
        //     />
        //     <DeleteComponent
        //       visible={deleteReminder}
        //       close={closeDeleteReminder}
        //       AllSOLDReminder={AllSOLDReminder}
        //     />
        //     <ViewComponent
        //       visible={viewDrawer}
        //       close={closeViewDrawer}
        //       isRefreshGrid={isRefreshGrid}
        //     />
        //     {!stopInvestmentHourCounter && <TrackTimeComponent pageName={'reminders'} />}
        //   </>
        // ) : (
          <>
            <ReminderSearchListing
            //   openAddReminder={openAddReminder}
              openEditReminder={openEditReminder}
              openDeleteReminder={openDeleteReminder}
            //   openViewDrawer={openViewDrawer}
              SearchPage={SearchPage}
              AllView={AllView}
              onCellKeyDown={onCellKeyDown}
            />
            {/* <AddComponent visible={addReminder} close={closeAddReminder} /> */}
            <EditComponent
              visible={editReminder}
              close={closeEditReminder}
              isRefreshGrid={isRefreshGrid}
              SearchPage={SearchPage}
            />
            <DeleteComponent visible={deleteReminder} close={closeDeleteReminder} SearchPage={SearchPage} />
            {/* <ViewComponent
              visible={viewDrawer}
              close={closeViewDrawer}
              isRefreshGrid={isRefreshGrid}
            /> */}
          {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={'reminders'} />} */}
          <TimeTracker
            page={"reminders"}
            stop={editReminder}
          />
          </>
        // )
        }
    </>
  );
});

export default RemindersSearch;
