import React, { useState } from 'react';
import { observer } from 'mobx-react';

import useStore from '../../store';

import Unauthorized from '../Unauthorized';
import TopSelection from './component/TopSelection';
import ListComponent from './component/ListComponent';
import ViewComponent from '../LeadListing/component/ViewComponent';
import EditComponent from '../LeadListing/component/EditComponentNew';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';

const BlockEditAssign = observer(() => {
  const [viewSOLD, setViewSOLD] = useState(false);
  const [editSOLD, setEditSOLD] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const {
    AUTH: { checkPermission },
    SETTINGS: { fullscreen_class },
    LEADSSTORE: { setViewValues, setEditValues },
  } = useStore();

  //--------------------START : SOLD VIEW AND EDIT FUNCTION ------------//
  const openSOLDViewDrawer = (data) => {
    setViewValues(data);
    setViewSOLD(true);
  };

  const closeSOLDViewDrawer = () => {
    setViewSOLD(false);
  };

  const openSOLDEditDrawer = (data, isEditOpen) => {
    setEditOpen(isEditOpen);
    setEditValues(data);
    setEditSOLD(true);
  };

  const closeSOLDEditDrawer = () => {
    setEditSOLD(false);
    setEditOpen(false);
  };
  //--------------------END : SOLD VIEW AND EDIT FUNCTION ------------//

  return (
    <>
      {!checkPermission(5, 'list') ? (
        <Unauthorized />
      ) : (
        <div className={fullscreen_class}>
          <TopSelection />
          <ListComponent
            openViewDrawer={openSOLDViewDrawer}
            openEditDrawer={openSOLDEditDrawer}
          />
          <ViewComponent
            visible={viewSOLD}
            openEditDrawer={openSOLDEditDrawer}
            close={closeSOLDViewDrawer}
          />
          <EditComponent
            visible={editSOLD}
            editOpen={editOpen}
            close={closeSOLDEditDrawer}
          />
          {/* <TrackTimeComponent pageName={'blockeditassign'} /> */}
          <TimeTracker stop={ viewSOLD || editSOLD || editOpen}/>
        </div>
      )}
    </>
  );
});

export default BlockEditAssign;
