import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class UserGroupStore {
  list_data = [];
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  groupValues = null;
  agGridRelOrg = null;
  deleteValues = null;
  viewValues = null;
  editValues = null;
  peopleValues = null;
  rPeopleValues = null;
  permissionValues = null;

  dropdown_RPpl_list = null;
  fetching = false;
  group_user_list = null;

  initialValues = {
    group_name: null,
  };

  dropdown_user_list = null;

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setEditValues = (data) => {
    this.editValues = data;
  };

  //set delete values
  setPermissionValues = (data) => {
    this.permissionValues = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.USERGROUP) {
        params = { request: temp.USERGROUP };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          USERGROUP: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          USERGROUP: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('usergroup_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  getList = (payload = {}) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }
    return Axios.post(`usergroup/list`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.full_name = item.first_name + ' ' + item.last_name;
        });
      }

      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.columnApi.applyColumnState({ state: sort });
      }
      let columnConfig = localStorage.getItem('usergroup_grid');
      if (this.agGrid && this.agGrid.columnApi && columnConfig) {
        this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
      }
      return data;
    });
  };

  getRecordDetail = (formdata, setVariable = true) => {
    return Axios.get(`usergroup/read/${formdata.groupid}`)
      .then(({ data }) => {
        if (setVariable) {
          this.groupValues = data.data;
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        this.groupValues = null;
        return Promise.reject(data);
      });
  };

  setGroupValues = (data) => {
    this.groupValues = data;
  };

  AddData = (data) => {
    return Axios.post(`usergroup/add`, data)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditData = (data) => {
    return Axios.post(`usergroup/edit/${data.id}`, data)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteData = (group_id) => {
    return Axios.get(`usergroup/delete/${group_id}`)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['logo_url', 'date_of_birth'].includes(key)) {
        data[key] && formData.append(key, data[key]);
      }
    });
    return formData;
  };

  getPermissions = (data) => {
    return Axios.post(`usergroup/permission`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  modifyPermissions = (data) => {
    return Axios.post(`usergroup/permissionupdate`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'usergroup_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // reset function
  handleReset = () => {
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.setSortModel(null);
    this.agGrid.api.onFilterChanged(null);
  };

  fetchGroupUsers = (id = 0) => {
    return Axios.get(`usergroup/username/list/${id}`)
      .then(({ data }) => {
        this.group_user_list = data && data.data;

        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        this.group_user_list = null;
        return Promise.reject(data);
      });
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_user_list = data.data;

      return data;
    });
  };
}

decorate(UserGroupStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  agGridRelOrg: observable,
  deleteValues: observable,
  groupValues: observable,
  rGroupValues: observable,
  initialValues: observable,
  viewValues: observable,
  permissionValues: observable,
  editValues: observable,
  group_user_list: observable,
  dropdown_user_list: observable,
  setPageSize: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  getRecordDetail: action,
  setGroupValues: action,
  AddData: action,
  EditData: action,
  DeleteData: action,
  JsonToFormData: action,
  setPermissionValues: action,
  getPermissions: action,
  modifyPermissions: action,
  setEditValues: action,
  onFilterChanged: action,
  onGridChanged: action,
  handleReset: action,
  fetchGroupUsers: action,
  getUsersList: action,
});
