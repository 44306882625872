import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Space, Form } from 'antd';
import ListComponent from './component/ListComponent';
import useStore from '../../../store';
import DeleteComponent from './component/DeleteComponent';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import ViewComponent from './component/ViewComponent';
import UnpublishComponent from './component/UnpublishComponent';
import PublishComponent from './component/PublishComponent';
import RecordPerPage from '../../../config/RecordPerPage';
import { vsmAlgorithm, vsmNotify } from '../../../config/messages';
import Unauthorized from '../../Unauthorized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../../component/EnterFullscreen';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Algorithms = observer(() => {
  const {t} = useTranslation()
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [id, setID] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unpublishModal, setUnpublishModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const history = useHistory();
  const {
    AlgorithmsStore: {
      setDeleteValues,
      setStatusValues,
      per_page,
      setPageSize,
      getUnpublishRelation,
      getPublishRelation,
      TogglepublishData,
      agGrid,
      handleReset,
    },
    AUTH,
    SETTINGS,
  } = useStore();

  // open add modal function
  const gotoAddScreen = () => {
    setAddModal(true);
  };

  // close add modal function
  const closAddScreen = () => setAddModal(false);

  // open edit modal function
  const gotoEditScreen = (id) => {
    setID(id);
    setEditModal(true);
  };

  // close edit modal function
  const closeEditScreen = () => setEditModal(false);

  // open view modal function
  const gotoViewScreen = (id) => {
    setID(id);
    setViewModal(true);
  };

  //close view modal function
  const closeViewScreen = () => setViewModal(false);

  // Open confirmation alert before deleting existing Journal Type
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);

  // Close unpublish modal
  const closeUnpublishModal = () => {
    setUnpublishModal(false);
  };

  // Close publish modal
  const closePublishModal = () => {
    setPublishModal(false);
  };

  const onSwitchChange = (checked, data) => {
    setStatusValues(data);
    if (!checked) {
      agGrid.api.refreshCells({ force: true });
      getUnpublishRelation(data);
      setUnpublishModal(true);
    } else {
      getPublishRelation(data, handlePublish, setPublishModal);
    }
  };

  // Handle Publish and call function to publish record
  const handlePublish = (data) => {
    let formdata = {
      id: data.id,
      status: true,
    };
    TogglepublishData(formdata)
      .then(() => {
        vsmNotify.success({
          message: vsmAlgorithm.publish,
        });
      })
      .catch((e) => {
        agGrid.api.refreshCells({ force: true });
      });
  };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (e.data && !e.data.status) {
            if (result.key === 'Enter' && AUTH.checkPermission(23, 'edit')) {
              gotoEditScreen(e.data.id);
            }
            if (result.key === 'Delete' && AUTH.checkPermission(23, 'delete')) {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };
  const goToDealMakerFields = () => {
    let path = `/deal-builders/deal-maker-fields`;
    history.push(path);
  };

  return (
    <>
      {!AUTH.checkPermission(23, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('DealBuilder.Deals_Builder')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <Form className='innerForm headerButton'>
              <Space size={5}>
                <Button key='2' shape='round' size='medium' onClick={goToDealMakerFields}>
                  {t('DealBuilder.Deal_Fields')}
                </Button>
                {AUTH.checkPermission(23, 'add') && (
                  <Button key='2' shape='round' size='medium' onClick={gotoAddScreen}>
                    {t('DealBuilder.New_Deal')}
                  </Button>
                )}

                <Button
                  key='1'
                  title={`${t('Common_Data.Reset')}`}
                  shape='round'
                  size='medium'
                  onClick={handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <EnterFullscreen key='4' />
                <RecordPerPage
                  key='3'
                  initialValue={per_page + ' per page'}
                  onChange={setPageSize}
                />
              </Space>
            </Form>,
          ]}
        >
          <ListComponent
            gotoEditScreen={gotoEditScreen}
            gotoViewScreen={gotoViewScreen}
            openDeleteModal={openDeleteModal}
            onSwitchChange={onSwitchChange}
            onCellKeyDown={onCellKeyDown}
          />
          {addModal && <AddComponent visible={addModal} close={closAddScreen} />}
          {editModal && (
            <EditComponent id={id} visible={editModal} close={closeEditScreen} />
          )}
          {viewModal && (
            <ViewComponent id={id} visible={viewModal} close={closeViewScreen} />
          )}
          <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
          <UnpublishComponent visible={unpublishModal} close={closeUnpublishModal} />
          <PublishComponent visible={publishModal} close={closePublishModal} />
        </PageHeader>
      )}
    </>
  );
});

export default Algorithms;
