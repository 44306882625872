import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import debounce from 'lodash/debounce';
import { vsmNotify, vsmReminders } from '../../../config/messages';
import FormElements from '../elements/FormElements';
import useStore from '../../../store';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const AddComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [priority, setPriority] = useState(false);

  const {
    AUTH,
    SPANNOTESTORE,
    REMINDERSSTORE: { addReminderData, getUsersList, dropdown_user_list },
    DEALDESKSTORE: { deal_data, setDealData },
  } = useStore();

  const close = () => {
    form.resetFields();
    SPANNOTESTORE.contacts_list_data = null;
    setSaving(false);
    setDisabled(true);
    props.close();
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.reminder_date_time = data.reminder_date_time
      ? moment(data.reminder_date_time).format(AUTH.form_submit_date_with_seconds)
      : null;
    if (data.reminder_date) {
      let updatedDate = new Date(data.reminder_date);
      updatedDate.setSeconds(0);
      data.reminder_date = updatedDate;
    }
    data.reminder_date = data.reminder_date
      ? moment(data.reminder_date).format(AUTH.form_submit_date_with_seconds)
      : null;

    if (data.receiver_by && data.receiver_by === AUTH?.user?.id) {
      data.status = 'Read';
    } else {
      data.status = 'Open'; //Open default client CR
    }
    data.event_record_type = 'To Do'; //Default
    if (props.isDeal) {
      data.is_from_dealdesk = 1;
    } else {
      data.is_from_dealdesk = 0;
    }

    addReminderData(data)
      .then((res) => {
        close();
        if (props.isDeal && res?.data?.data) {
          let tempDealdata = deal_data;
          tempDealdata.reminder = res.data?.data;
          tempDealdata.total_reminder_count = res?.data?.total_reminder_count;
          setDealData(tempDealdata);
        }

        form.resetFields();
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmReminders.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const onDefaultUserSelection = useCallback(() => {
    if (dropdown_user_list && dropdown_user_list.length > 0) {
      let index = dropdown_user_list.findIndex(x => x.id === AUTH?.user?.id);
      if (index !== -1) {
        form.setFieldsValue({
          receiver_by: AUTH?.user?.id,
        });
      }
    } else {
      getUsersList().then(() => {
        if (dropdown_user_list) {
          let index = dropdown_user_list.findIndex(x => x.id === AUTH?.user?.id);
          if (index !== -1) {
            form.setFieldsValue({
              receiver_by: AUTH?.user?.id,
            });
          }
        }
      });
    }
  }, [dropdown_user_list, form, getUsersList, AUTH?.user?.id]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    setPriority(form.getFieldValue('priority'));

    if (form.getFieldValue('reminder_type') === 'private') {
      onDefaultUserSelection();
    }

    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    onDefaultUserSelection();
  }, [onDefaultUserSelection, props.visible]);

  return (
    <>
    <Drawer
      key='7'
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('Common_Data.Add')} ${t('Common_Data.Reminder')}`}
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          <Button
            key='1'
            form='addform'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='medium'
            disabled={isDisabled}
          >
            {t('Common_Data.Save')}
          </Button>
          <Button onClick={close} shape='round' size='medium' key='2'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <FormElements
        form={form}
        isAdd={true}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        id='addform'
        priority={priority}
        isDeal={props.isDeal}
      />
    </Drawer>
      {props.addInDesk && <TimeTracker
        page={"reminders"}
        stop={!props.visible}
      />}
      {props?.fromSideBar &&
        <TimeTracker
        page={"reminders"}
          stop={!props.visible}
          fromSideBar={props?.fromSideBar}
        />
      }
    </>
  );
});

export default AddComponent;
