import React, { useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PageHeader, Space, Form } from 'antd';
import { useLocation } from 'react-router-dom';

//import TopSelection from './component/TopSelection';
import ListComponent from './ListComponent';
import EditComponent from '../component/EditComponentNew';
import DeleteComponent from '../component/DeleteComponent';
import CloneComponent from '../component/CloneComponentNew';
import ViewComponent from '../component/ViewComponent';
import LeadImportComponent from '../component/LeadImportComponent';
import OpportunityImportComponent from '../component/OpportunityImportComponent';
import RecordPerPage from '../../../config/RecordPerPage';
import useStore from '../../../store';
import EnterFullscreen from '../../../component/EnterFullscreen';
import Unauthorized from '../../Unauthorized';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import ResetGridOptions from '../../../component/ResetGridOptions';
import TimeTracker from '../../../component/TimeTracker';

const AllLeads = observer((props) => {
  //let history = useHistory();
  const location = useLocation();
  const { SearchPage } = props;
  const { t } = useTranslation();
  const { LEADSSTORE, AUTH, ALLLEADS, SETTINGS } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  //const [kanbanRoute, setKanbanRoute] = useState(null);
  const [importLeadModal, setImportLeadModal] = useState(false);
  const [importOpportunitiesModal, setImportOpportunitiesModal] = useState(false);

  const [editOpen, setEditOpen] = useState(false);

  const [form] = Form.useForm();
  //Edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    LEADSSTORE.setEditValues(data);
    setEditOpen(isEditOpen);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setEditOpen(false);
  };

  //open delet drawer
  const openDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //Clone drawer
  const openCloneDrawer = (data) => {
    LEADSSTORE.setCloneValues(data);
    setCloneDrawer(true);
  };
  const closeCloneDrawer = () => setCloneDrawer(false);

  //View drawer
  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(11, 'view-details')) {
      LEADSSTORE.setViewValues(data);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => setViewDrawer(false);
  //const openKanbanView = () => history.push(kanbanRoute);

  //Open Drawer to import data
  //const importLeadData = () => setImportLeadModal(true);
  const closeImportLeadModal = () => {
    setImportLeadModal(false);
  };
  //const importOpportunitiesData = () => setImportOpportunitiesModal(true);
  const closeImportOpportunitiesModal = () => {
    setImportOpportunitiesModal(false);
  };

  const pageFilterType = 'allleads';

  useEffect(() => {
    if (location.pathname !== LEADSSTORE.current_list_path) {
      LEADSSTORE.current_list_path = location.pathname;
    }
    //setKanbanRoute(location.pathname + '/kanban');
  }, [LEADSSTORE, LEADSSTORE.current_list_path, location]);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(11, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }
          if (AUTH.checkPermission(11, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteDrawer(e.data);
            }
          }
        }
      }
    }
  };

  return (
    <>
      {!AUTH.checkPermission(11, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('LeadListing.Leads')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <Form form={form} className='innerForm headerButton'>
              <Space size={5}>
                <ResetGridOptions key={'5'} pageType={pageFilterType} />
                <EnterFullscreen key='6' />
                <RecordPerPage
                  key='7'
                  initialValue={ALLLEADS.per_page + ' per page'}
                  onChange={ALLLEADS.setPageSize}
                />
              </Space>
            </Form>,
          ]}
        >
          <ListComponent
            openViewDrawer={openViewDrawer}
            openEditDrawer={openEditDrawer}
            openDeleteDrawer={openDeleteDrawer}
            openCloneDrawer={openCloneDrawer}
            SearchPage={SearchPage}
            onCellKeyDown={onCellKeyDown}
          />
            <TimeTracker
              page={"leadlisting"}
              stop={viewDrawer || editDrawer || cloneDrawer}
            />
        </PageHeader>
      )}
      <ViewComponent
        visible={viewDrawer}
        openEditDrawer={openEditDrawer}
        close={closeViewDrawer}
      />
      <EditComponent visible={editDrawer} editOpen={editOpen} close={closeEditDrawer} />
      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        lineText={`${t('Common_Data.Remove_Lead')}?`}
      />
      <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
      <LeadImportComponent visible={importLeadModal} close={closeImportLeadModal} />
      <OpportunityImportComponent
        visible={importOpportunitiesModal}
        close={closeImportOpportunitiesModal}
      />
    </>
  );
});

export default AllLeads;
