import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../config/LocalGridConfig';
// import NumberFormat from 'react-number-format';
import useStore from '../../../../store';
import { getGroupOnText, secondsToHms } from '../../../../utils/GlobalFunction';
import InvestmentSubReport from './InvestmentSubReport';
import { vsmCommon } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const PreviewAuditReportTableElement = observer((props) => {
  const { t } = useTranslation()
  const {
    SavedReportStore,
    SavedReportStore: {
      payload_drilldown_generated_on,
      setinvestment_hour_grid_settings,
      previewReportValues,
      reportColumnSettings,
    },
  } = useStore();
  const [visible, setVisible] = useState(false);
  const [userWiseDrillDetails, setUserWiseDrillDetails] = useState(null);

  let columnDefs = [
    {
      headerName: `${getGroupOnText[
        props?.form?.getFieldValue('group_on')
          ? props?.form?.getFieldValue('group_on')
          : previewReportValues?.group_on
      ]
        ? getGroupOnText[
        props?.form?.getFieldValue('group_on')
          ? props?.form?.getFieldValue('group_on')
          : previewReportValues?.group_on
        ]
        : 'Week'
        }`,
      field: 'group_on',
      filter: false,
      cellStyle: { 'text-align': 'left' },
      sortable: false,
      pinned: 'left',
    },
  ];

  let list_data = [];
  let list_object = {};
  let PinnedData = [];

  let userNameIDArr = [];
  let users_name_array = [];

  if (props.rowData) {
    let totalKeysArr = [];
    Object.keys(props.rowData).forEach((key) => {
      if (props.rowData[key]) {
        let item = props.rowData[key];
        let newKey = key.split('#');
        //let dynamic_user_id = null;
        if (newKey.length > 0) {
          userNameIDArr[newKey[0]] = Number(newKey[1]);
          users_name_array.push(newKey[0]);
          newKey = newKey[0];
        }

        item &&
          Object.keys(item).forEach((group) => {
            if (group !== 'columns') {
              let existsIndex = list_data.findIndex((x) => x.group_on === group);
              let currentItem = item[group];
              if (currentItem) {
                if (currentItem.length > 0) {
                  currentItem = currentItem[0];
                }
                if (existsIndex === -1) {
                  list_object = {
                    group_on: group,
                  };
                  list_data.push(list_object);
                }

                let newData = list_data;
                let newIndex = newData.findIndex((x) => x.group_on === group);
                if (newIndex !== -1) {
                  let newkey = currentItem['user'];
                  //dynamic_user_id = currentItem['uid'];
                  newData[newIndex][newkey] = secondsToHms(
                    currentItem['investment_hours_sec']
                  );
                  newData[newIndex][`${newkey}_total`] =
                    currentItem['investment_hours_sec'];
                  let currentObj = newData[newIndex];
                  let rowTotal = 0;
                  Object.keys(currentObj).forEach((currentKey) => {
                    if (currentKey.includes('_total')) {
                      rowTotal += currentObj[currentKey];
                    }
                  });
                  newData[newIndex]['total'] = secondsToHms(rowTotal);
                  list_data = newData;
                }
              }
            }
          });
        totalKeysArr.push(newKey);
        columnDefs.push({
          headerName: newKey,
          headerTooltip: newKey,
          field: newKey,
          cellStyle: { 'text-align': 'right' },
          cellClass: 'text-right',
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label label-pinned" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
              '     <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
          // headerComponentFramework: (params) => {
          //   return (
          //     <div
          //       className='d-flex align-items-center ag-header-cell-text h-100 cs_pointer'
          //       onClick={() => {
          //         if (!['total', 'group_on'].includes(params?.column?.colId)) {
          //           if (payload_drilldown_generated_on) {
          //             let payloadData = payload_drilldown_generated_on;
          //             payloadData.user_id = dynamic_user_id;
          //             delete payloadData.group_on_value;

          //             setUserWiseDrillDetails({
          //               column: params?.column?.colId,
          //               group_on: payload_drilldown_generated_on?.group_on,
          //             });
          //             SavedReportStore.getUserInvestmentHourDetail(payloadData);
          //             setVisible(true);
          //           }
          //         }
          //       }}
          //     >
          //       {newKey}
          //     </div>
          //   );
          // },
          // sortable: false,
          minWidth: 150,
        });
      }
    });
    if (list_data.length === 0) {
      columnDefs.push({
        headerName: '',
        field: '',
        filter: false,
        sortable: false,
      });
    }
    columnDefs.push({
      headerName: `${t('Common_Data.Total')}`,
      field: 'total',
      filter: false,
      cellStyle: { 'text-align': 'right' },
      minWidth: 150,
      sortable: false,
      pinned: 'right',
    });
    let list_total = {
      group_on: 'Grand Total',
    };
    let finalTotal = 0;
    totalKeysArr.forEach((key) => {
      if (!['group_on'].includes(key)) {
        list_total[key] = list_object[key] || {};
        let filterData = list_data.filter((x) => x.hasOwnProperty(key));
        let total = 0;
        filterData.map((x) => {
          total += x[`${key}_total`];
          return null;
        });
        let convertHours = secondsToHms(total);
        list_total[key] = convertHours;
        finalTotal += total;
      }
    });
    list_total['total'] = secondsToHms(finalTotal);

    list_data.map((obj) => {
      const hasAllKeys = users_name_array.every((item) => obj.hasOwnProperty(item));
      if (!hasAllKeys) {
        users_name_array.map((key_obj) => {
          if (!obj.hasOwnProperty(key_obj)) {
            obj[key_obj] = '0s';
          }
          return null;
        });
      }
      return null;
    });

    list_data.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      a = a.group_on;
      b = b.group_on;
      if (['month', 'week'].includes(SavedReportStore.previewReportValues.group_on)) {
        let _a = a.split('-');
        let _b = b.split('-');
        _a[1] = parseInt(_a[1]);
        _a[0] = parseInt(_a[0]);
        _b[1] = parseInt(_b[1]);
        _b[0] = parseInt(_b[0]);
        if (_a[1] < _b[1] || (_a[1] === _b[1] && _a[0] < _b[0])) {
          return -1;
        }
        if (_a[1] > _b[1] || (_a[1] === _b[1] && _a[0] > _b[0])) {
          return 1;
        }
      } else if (['day'].includes(SavedReportStore.previewReportValues.group_on)) {
        let dateA = new Date(a),
          dateB = new Date(b);
        return dateA - dateB;
      } else {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
      }
      return 0;
    });

    PinnedData.push(list_total);
  }

  SavedReportStore.auditExportData = props.rowData;
  SavedReportStore.auditExportFormatedData = list_data;

  let columns = [];
  let columnConfig = reportColumnSettings;
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = columnDefs.find((x) => x.field === element.colId);

        if (cols) {
          if (element?.width) {
            cols.width = element?.width;
          }
          columns.push(cols);
        }
      });
  } else {
    columns = columnDefs;
  }

  const openSubReport = (params) => {
    if (params) {
      setinvestment_hour_grid_settings(params?.columnApi?.getColumnState());
    }

    if (!['total', 'group_on'].includes(params?.column?.colId)) {
      if (payload_drilldown_generated_on && params?.value && params?.value !== '0s') {
        let payloadData = payload_drilldown_generated_on;
        payloadData.user_id = userNameIDArr[params?.column?.colId];
        payloadData.group_on_value = params?.data?.group_on;
        if (params?.node?.rowPinned) {
          delete payloadData.group_on_value;
        }

        setUserWiseDrillDetails({
          column: params?.column?.colId,
          group_on: params?.data?.group_on,
        });
        SavedReportStore.getUserInvestmentHourDetail(payloadData);
        setVisible(true);
      }
    }
  };

  const closeSubReport = () => {
    setUserWiseDrillDetails(null);
    setVisible(false);
  };

  return (
    <div
      className='ag-theme-alpine grid_wrapper reportPreviewData'
      style={{
        height: `calc(100vh - ${props?.editFilter ? '44rem' : '24rem'})`,
        minHeight: '200px',
      }}
    >
      <AgGridReact
        rowHeight={32}
        rowData={list_data}
        modules={AllModules}
        columnDefs={columns}
        pinnedBottomRowData={PinnedData}
        onGridReady={SavedReportStore.setupAuditGrid}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          filter: false,
          floatingFilter: false,
          autoHeight: false,
          flex: 1,
        }}
        onColumnResized={SavedReportStore.onInvestmentHourGridChanged}
        onColumnMoved={SavedReportStore.onInvestmentHourGridChanged}
        onColumnPinned={SavedReportStore.onInvestmentHourGridChanged}
        onSortChanged={SavedReportStore.onInvestmentHourGridChanged}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        enableRangeSelection={true}
        onCellDoubleClicked={(params) => {
          openSubReport(params);
        }}
        overlayNoRowsTemplate={vsmCommon.noRecord}
      ></AgGridReact>

      {visible && (
        <InvestmentSubReport
          visible={visible}
          userWiseDrillDetails={userWiseDrillDetails}
          close={() => {
            closeSubReport();
          }}
        />
      )}
    </div>
  );
});

export default PreviewAuditReportTableElement;
