import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmPeople, vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { PEOPLE, AUTH, SEARCHSTORE } = useStore();
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    PEOPLE.DeleteData(PEOPLE.deleteValues.id,)
      .then(() => {
        close();

        if (props.getWasteCount) {
          props.getWasteCount();
        }

        // if (props?.SearchPage) {
        //   SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'people');
        // }

        if (!props?.SearchPage) {
          if (PEOPLE.agGrid) {
            PEOPLE.setupGrid(PEOPLE.agGrid);
          }
        } else {
          PEOPLE.agGrid.api.applyTransaction({ remove: [PEOPLE.deleteValues] });
          SEARCHSTORE.updateSearchCount({ people_count: SEARCHSTORE.searchCount.people_count - 1 });
        }
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmPeople.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return PEOPLE.deleteValues ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${t('PeopleManagement.Person')} - #${PEOPLE.deleteValues?.id} - ${PEOPLE.deleteValues?.people_name}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deletepplform'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deletepplform' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {props.lineText}
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});
export default DeleteComponent;
