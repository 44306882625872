import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import { Form, Col, Row, Space } from 'antd';
//import { vsmLeadsList } from '../../../config/messages';
import useStore from '../../../store';
import { vsmLeadsList } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const PhoneElement = observer(({ onChange, form }) => {
  const { t } = useTranslation()
  const [SelectTypes, SetSelectedTypes] = useState([{ contact_for: `${t('LeadListing.Primary')}` }]);
  const { AUTH } = useStore();
  return (
    <div className='w-100'>
      <Form.List name='phone' initialValue={[{ contact_for: `${t('LeadListing.Primary')}` }]}>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => {
            let rejected_types = [];
            var accepted_index;
            SelectTypes &&
              SelectTypes.map((x, index) => {
                if (x && x.contact_for === 'Primary') {
                  accepted_index = index;
                  rejected_types.push('Primary');
                }
                return x;
              });
            return (
              <div className='w-100' key={field.key}>
                <Row gutter={15}>
                  <Col span={10}>
                    <InputComponent
                      name={[field.name, 'contact_for']}
                      key={[field.key, 'contact_for']}
                      onChange={() => {
                        onChange();
                        SetSelectedTypes(form.getFieldValue('phone'));
                      }}
                      type='select'
                      placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Type')}`}
                      showSearch={false}
                      style={{ width: '100%' }}
                      options={{
                        values: [
                          { value: 'Primary', text: `${t('LeadListing.Primary')}` },
                          { value: 'Home', text: `${t('Common_Data.Home')}` },
                          { value: 'Office', text: `${t('Common_Data.Office')}` },
                          { value: 'Other', text: `${t('LeadListing.Other')}` },
                          { value: 'Mobile', text: `${t('Common_Data.Mobile')}` },
                          { value: 'SMS', text: `${t('Common_Data.SMS')}` },
                        ],
                        rejected_keys: rejected_types,
                        accepted_keys: index === accepted_index && 'Primary',
                      }}
                    />
                  </Col>

                  <Col span={10}>
                    <InputComponent
                      name={[field.name, 'contact_value']}
                      key={[field.key, 'contact_value']}
                      onChange={onChange}
                      placeholder={`${t('Common_Data.Phone_Number_Placeholder')}`}
                      type='text'
                      style={{ width: '100%' }}
                      rules={vsmLeadsList.validation.phone_without_required}
                    />
                  </Col>

                  <Col span={4}>
                    <Space size={5}>
                      {fields.length > 1 && (
                        <img
                          alt=''
                          className={`formIcon`}
                          src={AUTH.GetThemedImage('remove')}
                          onClick={() => {
                            remove(field.name);
                            onChange();
                          }}
                        />
                      )}
                      {index === fields.length - 1 && (
                        <img
                          alt=''
                          className={`formIcon`}
                          src={AUTH.GetThemedImage('add')}
                          onClick={async () => {
                            await add(null);
                            onChange();
                          }}
                        />
                      )}
                    </Space>
                  </Col>
                </Row>
              </div>
            );
          });
        }}
      </Form.List>
    </div>
  );
});

export default PhoneElement;
