import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmKnowledge, vsmNotify } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { KNOWLEDGE } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [content, setContent] = useState({ data: null });

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.id = KNOWLEDGE.knowledgeValues.id;
    KNOWLEDGE.EditData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmKnowledge.edit
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (KNOWLEDGE.knowledgeValues) {
      form.setFieldsValue({
        subject: KNOWLEDGE.knowledgeValues.subject,
        description: KNOWLEDGE.knowledgeValues.description,
        knowledge_group: KNOWLEDGE.knowledgeValues.knowledge_group,
        classification: parseInt(KNOWLEDGE.knowledgeValues.classification),
        knowledge_classification: KNOWLEDGE.knowledgeValues.knowledge_classification,
      });
      setContent(KNOWLEDGE.knowledgeValues.description);
    }
  }, [KNOWLEDGE, KNOWLEDGE.knowledgeValues, form]);

  return (
    KNOWLEDGE.knowledgeValues && (
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Edit_Knowledge')} - #${KNOWLEDGE.knowledgeValues?.id}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right'>
            <Button
              key='1'
              form='editform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              disabled={isDisabled}
              shape='round'
            >
              {t('Common_Data.Update')}
            </Button>
            <Button onClick={close} shape='round' key='2'>
              {' '}
              {t('Common_Data.Cancel')}
            </Button>
          </div>
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
          content={content}
          setContent={setContent}
        />
      </Drawer>
    )
  );
});

export default EditComponent;
