import React from 'react';
import { Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ConfirmMergeComponent = observer((props) => {
  const { t } = useTranslation();
  const { handleSubmit, saving,setSaving } = props;

  const close = () => {
    props.close();  
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Confirm')} ${t('Common_Data.Merge')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          onClick={() => { handleSubmit(); setSaving(true) }}
          type='primary'
          shape='round'
          disabled={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Row align='middle'>
        <Col span={4} className='DeleteIcon'>
          <WarningFilled />
        </Col>
        <Col span={20} className='DeleteText'>
          {t('OrganisationManagement.Not_Undo_Action')}.
        </Col>
      </Row>
    </Modal>
  );
});

export default ConfirmMergeComponent;
