import React from 'react';
import { Checkbox, Col, Form } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const UsergroupSelectionElement = observer(({ handleChange }) => {
  const {
    REPORTSTORE,
    //REPORTSTORE: { getReportUserGroupList },
  } = useStore();

  const { t } = useTranslation();

  return (
    <>
      <Col span={5}>
        <InputComponent label=' ' type='labelOnly' />
        <Form.Item label=' ' name='include_inactive_user_groups' valuePropName='checked'>
          <Checkbox onChange={(e) => handleChange()}>{t('Reports.Include_non_active_users')}?</Checkbox>
        </Form.Item>
      </Col>
      <Col span={7}>
        <InputComponent
          label={`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
          type='select'
          mode='multiple'
          name='user_groups'
          placeholder={`${t('Common_Data.Choose')} ${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
          allowClear
          showArrow
          onChange={() => {
            handleChange();
          }}
          options={{
            values: REPORTSTORE.dropdown_userGroupList,
            value_key: 'id',
            text_key: 'group_name',
          }}
          tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
        />
      </Col>
    </>
  );
});
export default UsergroupSelectionElement;
