import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row, Form, Divider } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const TransactionDateElement = observer(({ onChange }) => {
  const { t } = useTranslation()
  return (
    <div className='w-100'>
      <Form.List name='transaction_dates'>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <div className='w-100' key={field.key}>
                {index > 0 && <Divider style={{ marginTop: -10 }}>OR</Divider>}
                <Row gutter={10} className='no-wrap'>
                  <Col className='min-w150 max-w150'>
                    <InputComponent
                      onChange={onChange}
                      type='select'
                      name={[field.name, 'transaction_date_type']}
                      key={[field.key, 'transaction_date_type']}
                      placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')} ${t('Reports.Field')}`}
                      options={{
                        values: [
                          { value: 'issued_on', text: `${t('Reports.Issued')} ${t('Common_Data.On')}` },
                          { value: 'posted_on', text: `${t('Reports.Posted')} ${t('Common_Data.On')}` },
                          { value: 'traded_on', text: `${t('Reports.Traded')} ${t('Common_Data.On')}` },
                          { value: 'settled_on', text: `${t('Reports.Settled')} ${t('Common_Data.On')}` },
                          { value: 'closed_on', text: `${t('Reports.Closed')} ${t('Common_Data.On')}` },
                          { value: 'forwarded_on', text: `${t('Reports.Forwarded')} ${t('Common_Data.On')}` }
                        ]
                      }}
                    />
                  </Col>
                  <Col flex={1}>
                    <InputComponent
                      onChange={onChange}
                      type='date_range'
                      className='w-100'
                      name={[field.name, 'transaction_date_value']}
                      key={[field.key, 'transaction_date_value']}
                      tooltip={`${t('Reports.Transcation_Tooltip')}`}
                    />
                  </Col>
                  <Col className='d-flex'>
                    {fields.length > 1 && (
                      <Button
                        type='danger'
                        onClick={() => remove(field.name)}
                        icon={<MinusOutlined />}
                      ></Button>
                    )}
                    {index === fields.length - 1 && (
                      <Button
                        className='ml-5'
                        type='primary'
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      ></Button>
                    )}
                  </Col>
                </Row>
              </div>
            );
          })
        }
      </Form.List>
    </div>
  );
});

export default TransactionDateElement;
