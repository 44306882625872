import Axios from 'axios';
import { decorate, observable, action } from 'mobx';

export default class RemindersStore {
  list_data = [];
  read_data = [];

  getList = () => {
    return Axios.post('get-my-reminder').then(({ data }) => {
      this.list_data = data;
      return data;
    });
  };

  readReminder = (payload = {}) => {
    return Axios.post('read-reminder', payload).then(({ data }) => {
      this.getList();
      return data;
    });
  };
}

decorate(RemindersStore, {
  list_data: observable,
  read_data: observable,
  getList: action,
  readReminder: action,
});
