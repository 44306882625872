import React from 'react';
import { observer } from 'mobx-react';

const HistoryRender = observer((props) => {
  let data = props.value;
  let content = data && data.map((item) => (
    item.children
      ? item.item_key + ":" + (item.detail ? item.detail : " ") + ", " + item.children.map((child1) => (
        child1.children
          ? child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", " + child1.children.map((child2) => (
            child2.item_key + ":" + (child2.detail ? child2.detail : " ")
          ))
          : child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", "
      )
      )
      : item.item_key + ":" + (item.detail ? item.detail : " ") + ", "
  ))

  return (<>
    <div className='action-column' title={content && content}>
      {content && content}
    </div>
  </>
  );
});
export default HistoryRender;
