import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { Button, Drawer, Form, PageHeader, Popover, Spin, Divider } from 'antd';
import {
  capitalFirstLetter,
  convertError,
  formatReportForComparisonData,
  replaceUnderScoreWithSpace,
  summaryType,
  kpiReportType,
  countsListType,
} from '../../../../utils/GlobalFunction';
import {
  EditOutlined,
  FileExcelOutlined,
  FilterOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages';
import NoRecordAlert from '../../SavedReports/components/NoRecordAlert';
import PageNotFound from '../../../PageNotFound';
import Unauthorized from '../../../Unauthorized';
import PreviewDataGridComponent from './PreviewDataGridComponent';
import FormComponent from './FormComponent';
import { useTranslation } from 'react-i18next';
import PreviewDateSubFilterForm from '../elements/PreviewDateSubFilterForm';
import RecordPerPage from '../../../../config/RecordPerPage';
import TimeTracker from '../../../../component/TimeTracker';

const PreviewDataComponent = observer((props) => {
  const { t } = useTranslation();

  const [tip, setTip] = useState('Loading');
  const [saving, setSaving] = useState(true);
  const [NoDataAlert, setNoDataAlert] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const [countsListReport, setCountsListReport] = useState(false);

  /*---------------START: INITIALISATION STATES---------------*/
  const [isInitiated, setInitiation] = useState(false);
  const [formDataList, setFormDataList] = useState([]);
  const [editFilter, setEditFilter] = useState(false);
  const [PreviewReport, setPreviewReport] = useState(null);
  const [saveFilterList, setsaveFilterList] = useState(false);

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  /*---------------START: INITIALISATION STATES---------------*/

  //----------GLOBAL STATE DECLARATION---------------//
  const {
    AUTH,
    REPORTSTORE,
    REPORTSTORE: {
      per_page,
      setPageSize,
      previewReportValues,
      preview_report_criteria,
      setReportColumnSettings,
      handleResetGrid,
      saveGridColumnState,
      setPreviewFilterForExport,
      targetKeys,
      setSelectedReportFieldsKeys,
      setTargetKeys,
      mockData,
      setIsPreviewFromEditMode,
    },
    ORGANISATION,
    PEOPLE,
    LEADSSTORE,
    ORGSTATUSSTORE,
  } = useStore();

  //----------GLOBAL STATE DECLARATION---------------//
  const [form] = Form.useForm();
  const history = useHistory();
  let defaultFormDataList = REPORTSTORE?.previewReportValues?.id
    ? ['report_type', 'global_toggle']
    : [
      'report_type',
      // , 'name', 'code', 'description'
      'global_toggle',
    ];
  const { visible, previewFromClone, openCloneModal, report_id, previewFromEditDrawer } = props;
  //-------------PREVIEW INITIALIZATION-----------------//
  const handlePreviewReport = useCallback(
    (updatedTargetKeys = null) => {
      setPreviewReport(null);
      setSaving(true);
      setTip('Loading');
      var data;

      if (editFilter) {
        setIsPreviewFromEditMode(true);
        if (Object.keys(form.getFieldsValue()).length === 0) {
          data = REPORTSTORE.formatFormData(
            {
              ...REPORTSTORE.previewReportValues,
              ...form.getFieldsValue(),
              form_list: formDataList,
            },
            true,
            true
          );
        } else {

          let newObject = {
            ...REPORTSTORE.previewReportValues,
            ...form.getFieldsValue(),
            form_list: formDataList,
          };

          data = REPORTSTORE.formatFormData(
            newObject,
            true,
            true
          );
        }
      } else {
        setIsPreviewFromEditMode(false);
        data = REPORTSTORE.formatFormData(REPORTSTORE.previewReportValues, true, true);
      }
      data.name = data.name ? data.name : REPORTSTORE.previewReportValues.name;
      data.code = data.code ? data.code : REPORTSTORE.previewReportValues.code;
      data.description = data.description
        ? data.description
        : REPORTSTORE.previewReportValues.description;
      data.action = 'preview-data';
      let payloadFields = [];
      if (updatedTargetKeys) {
        mockData &&
          mockData.length > 0 &&
          mockData.forEach((x, i) => {
            if (updatedTargetKeys.includes(x.key)) {
              payloadFields.push(x.description);
            }
          });
      } else {
        mockData &&
          mockData.length > 0 &&
          mockData.forEach((x, i) => {
            if (targetKeys.includes(x.key)) {
              payloadFields.push(x.description);
            }
          });
      }

      if (payloadFields && payloadFields?.length > 0) {
        data.grid_columns = payloadFields;
      } else {
        if (REPORTSTORE?.previewReportValues?.grid_columns) {
          data.grid_columns = REPORTSTORE?.previewReportValues?.grid_columns;
        }
      }

      //REPORTSTORE.setPreview_report_grid(null);

      REPORTSTORE.PreviewData(data)
        .then((data) => {
          let ttData = data.data;
          if (data.data.hasOwnProperty('data')) {
            ttData = data.data.data
          }
          setPreviewReport(ttData);
          if (ttData && Object.keys(ttData).length > 0) {
          } else {
            setNoDataAlert(true);
          }
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(convertError(e.errors));
          }
        })
        .finally(() => {
          setSaving(false);
          setTip(null);
          let report_comparison_data = form.getFieldValue('report_for_comparison_mode');
          if (report_comparison_data && editFilter) {
            form.setFieldsValue({
              report_for_comparison_mode:
                formatReportForComparisonData(report_comparison_data),
            });
          }
        });
    },
    [
      setSaving,
      REPORTSTORE,
      setIsPreviewFromEditMode,
      form,
      formDataList,
      editFilter,
      mockData,
      targetKeys,
    ]
  );

  const handlePreviewReportNew = useCallback((isCancled = false) => {
    let newObject = {
      ...REPORTSTORE.previewReportValues,
      ...form.getFieldsValue(),
      form_list: formDataList,
    };

    //newObject.form_list = formDataList;
    let data = REPORTSTORE.formatFormData(newObject);
    data.name = REPORTSTORE.previewReportValues.name;
    data.code = REPORTSTORE.previewReportValues.code;
    data.description = REPORTSTORE.previewReportValues.description;
    data.action = 'preview-data';

    let callAPI = true;
    if (isCancled) {
      callAPI = false;
    }

    if (callAPI) {
      setSaving(true);
      setTip('Loading Report');

      setPreviewFilterForExport(data);
      REPORTSTORE.PreviewData(data)
        .then((data) => {
          let ttData = data.data;
          if (data.data.hasOwnProperty('data')) {
            ttData = data.data.data
          }
          setPreviewReport(ttData);
          if (ttData && Object.keys(ttData).length > 0) {
          } else {
            setNoDataAlert(true);
          }
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(e.errors);
          }
        })
        .finally(() => {
          setSaving(false);
          setTip(null);
        });
    }
  }, [form, REPORTSTORE, formDataList, setPreviewFilterForExport]);

  const checkInterMediateCheckbox = useCallback(() => {
    let commonFields = [
      'sum_call_clicks',
      'sum_email_clicks',
      'sum_total_time_spent',
      'sum_modified',
    ];
    // --------  Add Fields to check for All Checkbox -------- //
    if (['all', 'sold'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'sold_created',
        'sold_assigned',
        'sold_sum_total',
        'sum_sss_created',
        'sum_results',
        'sum_inbound_leads',
        'sum_outbound_leads',
        'sum_finance_value',
        'sum_by_history_category',
        'sum_by_stages',
      ];
    }

    if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'tag_sum_total',
        'tag_created',
        'tag_assigned',
        'sum_by_tag_status',
      ];
    }

    if (['all', 'tickets'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'ticket_sum_total',
        'ticket_created',
        'ticket_assigned',
        'sum_by_ticket_status',
      ];
    }

    if (['all', 'reminders'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'reminder_sum_total',
        'reminder_created',
        'reminder_assigned',
        'sum_by_reminder_status',
      ];
    }

    if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'tag_sum_total',
        'tag_created',
        'tag_assigned',
        'sum_by_tag_status',
      ];
    }

    if (['all', 'organisation'].includes(form.getFieldValue('report_on'))) {
      commonFields = [...commonFields, 'organisation_created'];
    }

    if (['all', 'people'].includes(form.getFieldValue('report_on'))) {
      commonFields = [...commonFields, 'people_created'];
    }
    // --------  Add Fields to check for All Checkbox -------- //
    let count = 0;
    let ispartialChecked = false;
    commonFields.map((obj) => {
      if (form.getFieldValue([obj])) {
        count++;
      }
      return null;
    });
    if (count === commonFields.length) {
      ispartialChecked = false;
      form.setFieldsValue({
        select_all_columns: true,
      });
      setCheckAll(true);
    } else if (count > 0) {
      ispartialChecked = true;
      form.setFieldsValue({
        select_all_columns: false,
      });
      setCheckAll(false);
    } else {
      ispartialChecked = false;
      form.setFieldsValue({
        select_all_columns: false,
      });
      setCheckAll(false);
    }
    setIndeterminate(ispartialChecked);
  }, [form]);

  const InitaitePreviewReport = useCallback(
    async (isPreview = true, isSaved = false, isCancel = false) => {
      if (REPORTSTORE.previewReportValues?.id) {
        await setFormDataList(
          REPORTSTORE.previewReportValues.form_list
            ? REPORTSTORE.previewReportValues.form_list
            : []
        );
      } else {
        let updatedFormList = REPORTSTORE.previewReportValues.form_list || [];
        updatedFormList = [...updatedFormList, 'name', 'code', 'description'];
        await setFormDataList(updatedFormList);
      }

      let previewReportValues = REPORTSTORE.formatFormData(
        REPORTSTORE.previewReportValues,
        false
      );

      // Cancel Mode Fetch Original Data
      if (isCancel) {
        previewReportValues = REPORTSTORE.previewReportValues;

        if (previewReportValues.targetKeys) {
          setTargetKeys(previewReportValues.targetKeys);
          let payloadFields = [];
          mockData &&
            mockData.length > 0 &&
            mockData.forEach((x, i) => {
              if (previewReportValues.targetKeys.includes(x.key)) {
                payloadFields.push(x.description);
              }
            });
          setSelectedReportFieldsKeys(payloadFields);
        }
      }

      if (previewReportValues['nace_category']) {
        ORGANISATION.getNaceCode();
      }
      if (previewReportValues['people_status']) {
        PEOPLE.getPeopleStatus();
      }

      if (previewReportValues['organisation']) {
        ORGANISATION.getAllROrg({
          selected_id: previewReportValues['organisation'],
          mapping_type: 1,
        });
      }
      if (previewReportValues['organisation_name']) {
        ORGANISATION.getAllROrgNameListForReport({
          selected_id: previewReportValues['organisation_name'],
          mapping_type: 1,
        });
      }
      if (previewReportValues['people_name']) {
        PEOPLE.getAllPeople({
          selected_id: previewReportValues['people_name'],
          mapping_type: 2,
        });
      }
      if (previewReportValues['people']) {
        PEOPLE.getAllPeople({
          selected_id: previewReportValues['people'],
          mapping_type: 2,
        });
      }
      if (previewReportValues['people_name']) {
        PEOPLE.getAllPeopleNameDropdown({
          selected_id: previewReportValues['people_name'],
          mapping_type: 2,
        });
      }
      if (previewReportValues['lead_currency_id'] && !LEADSSTORE.dropdown_currency_list) {
        LEADSSTORE.getCurrencyList();
      }
      if (previewReportValues['organisation_type']) {
        ORGANISATION.getOrgRoles();
      }
      if (previewReportValues['people_type']) {
        PEOPLE.getPeopleRoles();
      }
      if (previewReportValues['classification_id']) {
        REPORTSTORE.getClassificationList();
      }
      if (
        previewReportValues &&
        (previewReportValues['users'] ||
          previewReportValues['owners'] ||
          previewReportValues['holders'])
      ) {
        let formdata = {
          inactive: true,
        };
        REPORTSTORE.getReportUsersList(formdata);
      }

      if (previewReportValues['parent_organisation']) {
        ORGANISATION.getMappedOrg();
      }
      if (previewReportValues['organisation_status']) {
        ORGSTATUSSTORE.getOrgStatus();
      }
      if (previewReportValues['filter_id']) {
        let page_type = previewReportValues['filter_type'];
        if (page_type && page_type === 'reminders') {
          page_type = 'reminder';
        } else if (page_type && page_type === 'organisation') {
          page_type = 'organisations';
        }
        AUTH.getSavedGridFilter({ page: page_type, is_from_report: true });
      }

      previewReportValues.selection_ui = 'radiobtn';
      await form.setFieldsValue(previewReportValues);
      await checkInterMediateCheckbox();
      if (isPreview) {
        handlePreviewReport(previewReportValues?.targetKeys);
      }
      if (isCancel) {
        handlePreviewReportNew(true);
      }
    },
    [
      setFormDataList,
      form,
      mockData,
      setSelectedReportFieldsKeys,
      handlePreviewReport,
      handlePreviewReportNew,
      REPORTSTORE,
      setTargetKeys,
      ORGANISATION,
      checkInterMediateCheckbox,
      PEOPLE,
      LEADSSTORE,
      ORGSTATUSSTORE,
      AUTH,
    ]
  );

  useEffect(() => {
    if (!isInitiated && visible) {
      if (report_id && !previewFromEditDrawer && !previewFromClone) {
        setInitiation(true);
        REPORTSTORE.ReadReport(report_id)
          .then((data) => {
            InitaitePreviewReport();
            setSaving(false);
            setTip(null);
            if (data.report_type === 6) {
              setCountsListReport(true)
            }
          })
          .catch(({ response }) => {
            if (response && response.status === 404) {
              setSaving('404');
            }
            if (response && response.status === 403) {
              setSaving('403');
            }
          });
      } else if (REPORTSTORE.previewReportValues) {
        setInitiation(true);
        InitaitePreviewReport();
        setSaving(false);
        setTip(null);
      }
    }
  }, [
    InitaitePreviewReport,
    REPORTSTORE,
    isInitiated,
    report_id,
    previewFromEditDrawer,
    previewFromClone,
    visible,
    history,
  ]);

  const handleSubmit = (data) => {
    setSaving(true);
    setTip('Saving');

    if (editFilter) {
      data = REPORTSTORE.formatFormData({
        ...REPORTSTORE.previewReportValues,
        ...form.getFieldsValue(),
        form_list: formDataList,
      });

    } else {
      data = REPORTSTORE.formatFormData(REPORTSTORE.previewReportValues);
    }
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.name = REPORTSTORE.previewReportValues.name;
    data.code = REPORTSTORE.previewReportValues.code;
    data.description = REPORTSTORE.previewReportValues.description;
    data.action = 'save_report';
    data.form_list = formDataList;
    if (typeof data.lead_type === 'number') {
      data.lead_type = [data.lead_type];
    }
    if (typeof data.record_type === 'number') {
      data.record_type = [data.record_type];
    }
    let payloadFields = [];
    mockData &&
      mockData.length > 0 &&
      mockData.forEach((x, i) => {
        if (targetKeys.includes(x.key)) {
          payloadFields.push(x.description);
        }
      });
    data.grid_columns = payloadFields;

    if (REPORTSTORE.previewReportValues.id) {
      data.id = REPORTSTORE.previewReportValues.id;

      //-------- START: On "save and preview" -> clear column settings--------//
      // if (
      //   ['time_spent', 'summary'].includes(
      //     REPORTSTORE.previewReportValues.report_type
      //   )
      // ) {
      data.column_settings = JSON.stringify(REPORTSTORE.preview_report_grid.columnApi.getColumnState());
      setReportColumnSettings(JSON.stringify(REPORTSTORE.preview_report_grid.columnApi.getColumnState()));
      // } else {
      //   if (REPORTSTORE.preview_report_grid) {
      //     data.column_settings = JSON.stringify(
      //       REPORTSTORE.preview_report_grid.columnApi.getColumnState()
      //     );
      //   }
      // }
      //-------- END:On "save and preview" -> clear column settings--------//

      REPORTSTORE.EditData(data)
        .then((data) => {
          vsmNotify.success({
            message: vsmTransactionReport.edit,
          });
          close(false);
          if (REPORTSTORE.my_records === 'my') {
            REPORTSTORE.getList({}, AUTH.user.id);
          } else {
            REPORTSTORE.getList();
          }
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(convertError(e.errors));
          }
        })
        .finally(() => {
          setSaving(false);
          setTip(null);
          setEditFilter(false);
        });
    } else {
      REPORTSTORE.AddData(data)
        .then((res) => {
          vsmNotify.success({
            message: vsmTransactionReport.save,
          });
          if (res.report) {
            REPORTSTORE.created_by_id = res.report.created_by;
            REPORTSTORE.previewReportValues = {
              ...JSON.parse(res.report.generated_on_options),
              id: res.report.id,
              name: res.report.name,
              code: res.report.code,
              description: res.report.description,
            };
          }
          close(false);
          if (REPORTSTORE.my_records === 'my') {
            REPORTSTORE.getList({}, AUTH.user.id);
          } else {
            REPORTSTORE.getList();
          }
          setEditFilter(false);
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(convertError(e.errors));
          }
        })
        .finally(() => {
          setSaving(false);
          setTip(null);
        });
    }
  };

  const handleSaveAsReport = (data = form.getFieldsValue()) => {
    setSaving(true);
    setTip('Saving');

    if (editFilter) {
      data = REPORTSTORE.formatFormData({
        ...REPORTSTORE.previewReportValues,
        ...form.getFieldsValue(),
        form_list: formDataList,
      });
    } else {
      data = REPORTSTORE.formatFormData(REPORTSTORE.previewReportValues);
    }
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.name = REPORTSTORE.previewReportValues.name;
    data.code = REPORTSTORE.previewReportValues.code;
    data.description = REPORTSTORE.previewReportValues.description;
    data.action = 'save_report';
    data.form_list = formDataList;
    if (typeof data.lead_type === 'number') {
      data.lead_type = [data.lead_type];
    }
    if (typeof data.record_type === 'number') {
      data.record_type = [data.record_type];
    }

    let payloadFields = [];
    mockData &&
      mockData.length > 0 &&
      mockData.forEach((x, i) => {
        if (targetKeys.includes(x.key)) {
          payloadFields.push(x.description);
        }
      });
    data.grid_columns = payloadFields;
    data.check_unique_code = true;
    delete data.id;

    REPORTSTORE.AddData(data)
      .then((res) => {
        vsmNotify.success({
          message: vsmTransactionReport.save,
        });
        close();
        if (REPORTSTORE.my_records === 'my') {
          REPORTSTORE.getList({}, AUTH.user.id);
        } else {
          REPORTSTORE.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => {
        setSaving(false);
        setTip(null);
      });
  };
  //-------------PREVIEW INITIALIZATION-----------------//



  //---------------------COLUMN SETTING SAVE REMOVE FUNCTION --------------------//
  const saveGridColumnStates = () => {
    let payload = null;
    if (REPORTSTORE.previewReportValues.report_type === 'time_spent') {
      payload = {
        column_settings: JSON.stringify(REPORTSTORE.AuditGrid.columnApi.getColumnState()),        
      };
    } else {
      payload = {
        column_settings: JSON.stringify(REPORTSTORE.preview_report_grid.columnApi.getColumnState()),
      };
    }
    if (payload) {
      saveGridColumnState(REPORTSTORE.previewReportValues.id, payload).then((data) => {
        vsmNotify.success({
          message: `${t('Reports.Column_Setting_Saved')}!`,
        });
      });
    }
  };
  const removeGridColumnStates = () => {
    // let payload = {
    //   column_settings: null,
    // };
    // saveGridColumnState(REPORTSTORE.previewReportValues.id, payload).then((data) => {
    //   if (REPORTSTORE.previewReportValues.report_type === 'time_spent') {
    //     REPORTSTORE.handleResetInvestmentGrid();
    //   } else {
    //     handleResetGrid();
    //   }
    //   vsmNotify.success({
    //     message: `${t('Reports.Column_Setting_Reset')}!`,
    //   });
    // });
    handleResetGrid();
  };
  //---------------------COLUMN SETTING SAVE REMOVE FUNCTION --------------------//

  //------------------------------EXPORT REPORT --------------------------------//
  /* const exportReport = () => {
    REPORTSTORE.exportPreviewReportData(REPORTSTORE.previewReportValues.name);
  }; */

  const exportReport = (type = 'csv') => {
    if (
      summaryType.includes(form.getFieldValue('report_type')) ||
      countsListType.includes(form.getFieldValue('report_type')) ||
      kpiReportType.includes(form.getFieldValue('report_type'))
    ) {
      REPORTSTORE.exportPreviewReportByAgGrid(REPORTSTORE.previewReportValues.name);
    } else {
      if (REPORTSTORE.previewFilterForExport) {
        setSaving(true);
        setTip('Exporting...');

        /*START: Column Setting pass to export payload*/
        if (REPORTSTORE.reportColumnSettings) {
          REPORTSTORE.previewFilterForExport.column_settings =
            REPORTSTORE.reportColumnSettings;
        }
        /*END: Column Setting pass to export payload*/
        REPORTSTORE.exportDataFromServer(REPORTSTORE.previewFilterForExport)
          .catch((e) => {
            if (e.errors) {
              form.setFields(convertError(e.errors));
            }
          })
          .finally(() => {
            setSaving(false);
            setTip(null);
          });
      } else {
        setSaving(true);
        setTip('Exporting...');
        var data = null;
        if (editFilter) {
          data = REPORTSTORE.formatFormData({
            ...REPORTSTORE.previewReportValues,
            ...form.getFieldsValue(),
            form_list: formDataList,
          });
        } else {
          data = REPORTSTORE.formatFormData(REPORTSTORE.previewReportValues);
        }

        /*START: Column Setting pass to export payload*/
        if (REPORTSTORE.reportColumnSettings) {
          data.column_settings = REPORTSTORE.reportColumnSettings;
        }
        /*END: Column Setting pass to export payload*/
        data.export = type;
        if (typeof data.lead_type === 'number') {
          data.lead_type = [data.lead_type];
        }
        if (typeof data.record_type === 'number') {
          data.record_type = [data.record_type];
        }
        REPORTSTORE.exportDataFromServer(data)
          .catch((e) => {
            if (e.errors) {
              form.setFields(convertError(e.errors));
            }
          })
          .finally(() => {
            setSaving(false);
            setTip(null);
          });
      }
    }
  };
  //------------------------------EXPORT REPORT --------------------------------//

  //-------------PREVIEW REPORT CRITERIA FUNCTIONS-----------------//
  const content = (
    <ul className='popover-menu' style={{ maxWidth: '450px' }}>
      {preview_report_criteria &&
        preview_report_criteria.map((item, index) => {
          return ![
            'column_settings',
            'investment_hours',
            'global_toggle',
            'tagholder',
            'tag_status_name',
            'owner',
            'organisation',
            'people',
            'classification_id',
            'json_load_investment_hour',
            'ticket_id',
            'user_group_list',
            'json_related_items_data',
          ].includes(item.name) ? (
            <li>
              <strong>
                {replaceUnderScoreWithSpace(capitalFirstLetter(item.name))} :
              </strong>{' '}
              {typeof item.value === 'object'
                ? Object.values(item.value).join()
                : Array.isArray(item.value)
                  ? item.value.join()
                  : replaceUnderScoreWithSpace(
                    typeof item.value === 'boolean'
                      ? item.value
                        ? 'Yes'
                        : 'No'
                      : item.value
                  )}
            </li>
          ) : null;
        })}
    </ul>
  );

  const displayTitle = () => {
    return (
      <>
        <div className='d-flex justify-space-between align-items-center'>
          <div className='leftSide'>
            {!editFilter &&
              !form.getFieldValue('enable_comparison_mode') &&
              form.getFieldValue('report_type') !== 'relationship' &&
              form.getFieldValue('report_on') !== 'saved_filter' ? (
              <PreviewDateSubFilterForm
                form={form}
                formDataList={formDataList}
                handleSubmit={handleSubmit}
                setFormDataList={setFormDataList}
                defaultFormDataList={defaultFormDataList}
                setTip={setTip}
                setSaving={setSaving}
                setNoDataAlert={setNoDataAlert}
                setPreviewReport={setPreviewReport}
              />
            ) : `${previewReportValues.name}`}

            {preview_report_criteria && (
              <Popover
                content={content}
                trigger={'click'}
                placement='rightBottom'
                overlayClassName='drawerPopover profileMenu'
              >
                <Button key='1' shape='round' size='medium'>Rat
                  <FilterOutlined />
                </Button>
              </Popover>
            )}
          </div>

          <div className='rightSide'>
            {previewReportValues && (
              <>
                <div className='ant-page-header p-0 extraRightOptions footerT Header'>
                  {previewReportValues.id && (
                    <>
                      <Button
                        onClick={() => saveGridColumnStates()}
                        key='1'
                        shape='round'
                        title={`${t('Common_Data.Save')} ${t('Reports.Column')} ${t(
                          'Reports.Settings'
                        )}`}
                        className=''
                        disabled={saving === true}
                      >
                        <TableOutlined />
                      </Button>
                      {/* {<Button
                onClick={() => removeGridColumnStates()}
                key='2'
                shape='round'
                title={`${t('Common_Data.Reset')} ${t('Reports.Column')} ${t(
                  'Reports.Settings'
                )}`}
                className='mr-10'
                disabled={saving === true}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>} */}
                      {/* <span className='mr-10'>
                <ResetGridOptions key={'21'} pageType={pageFilterType} />
              </span> */}

                      <Popover
                        content={
                          <ul className='popover-menu' key='1' style={{ cursor: 'pointer', width: '105px', minWidth: 'unset' }}>
                            <li key='1' onClick={() => {
                              manageReset();
                              handlePreviewReportNew();
                            }}>
                              {t('Common_Data.Reset')}
                            </li>
                            {/* <li key='2' onClick={() => { manageRefresh() }}>
                        {t('Common_Data.Refresh')}
                      </li> */}
                            <li key='3' onClick={() => { removeGridColumnStates() }}>
                              {t('Common_Data.Clear')}
                            </li>
                            <li key='4' htmlType='submit' id='update_daterangeForm ' disabled={true} onClick={() => {
                              handlePreviewReportNew()
                            }}>
                              {t('Common_Data.Refresh')}
                            </li>
                          </ul>
                        }
                        trigger="click"
                        visible={resetOptionVisible}
                        overlayClassName='main-menu-popover'
                        onVisibleChange={() => {
                          setResetOptionVisible(!resetOptionVisible);
                        }}
                      >
                        <Button key="2" shape='round' size='medium' title={t('Common_Data.Reset')} className=''>
                          <FontAwesomeIcon icon={faUndo} />
                        </Button>
                      </Popover>
                    </>
                  )}

                  {
                    (REPORTSTORE.previewReportValues.id && AUTH.checkPermission(21, 'edit')) ||
                      (!REPORTSTORE.previewReportValues.id && AUTH.checkPermission(21, 'add')) ? (
                      <>
                        <Button
                          disabled={saving === true}
                          key='3'
                          onClick={() => {
                            editFilterMode();
                          }}
                          shape='round'
                          title={`${t('Common_Data.Edit')} ${t('Reports.Report')}`}
                          className=''
                        >
                          <EditOutlined />
                        </Button>
                      </>
                    ) : null
                  }

                  {
                    previewReportValues.id && AUTH.checkPermission(21, 'edit') && (
                      <Button
                        key='4'
                        className=''
                        shape='round'
                        disabled={saving === true}
                        title={`${t('Common_Data.Clone')} ${t('Reports.Report')}`}
                        onClick={() => {
                          let rid = previewReportValues?.id;
                          close();
                          openCloneModal(rid);
                        }}
                      >
                        <img
                          className='menuicon '
                          alt=''
                          src={AUTH.GetThemedImage('Clone_Record')}
                        />
                      </Button>
                    )
                  }

                  {
                    AUTH.checkPermission(21, 'preview-data-export') && previewReportValues.id && (
                      <Button
                        disabled={saving === true}
                        onClick={() => exportReport('csv')}
                        key='5'
                        shape='round'
                        title={`${t('DealDesk.Download')} ${t('Reports.Report')}`}
                        className=''
                      >
                        <FileExcelOutlined />
                      </Button>
                    )
                  }

                  {(
                    form.getFieldValue('report_type') &&
                    (form.getFieldValue('report_type') === 'records' || form.getFieldValue('report_type') === 'count_list')
                  ) && (
                      <Form form={form} id='ReportPagination' className='innerForm headerButton' labelCol={{ span: 24 }} >
                        <RecordPerPage key='4' initialValue={per_page + ' per page'} isReportType={form.getFieldValue('report_on')} onChange={(value) => setPageSize(value)} />
                      </Form>)}

                </div >

              </>
            )}
          </div>
        </div>
      </>
    );
  };

  //-------------PREVIEW REPORT CRITERIA FUNCTIONS-----------------//

  //-------------DRAWER RELATED FUNCTION-----------------//
  const close = (clear = true) => {
    if (REPORTSTORE.previewFilterForExport) {
      setPreviewFilterForExport(null);
    }
    if (REPORTSTORE.reportColumnSettings) {
      setReportColumnSettings(null);
    }
    if (clear === false) {
      REPORTSTORE.ReadReport(
        props?.report_id ? props.report_id : REPORTSTORE.previewReportValues?.id
      )
        .then((data) => {
          InitaitePreviewReport(true, true);
          setSaving(false);
          setTip(null);
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            setSaving('404');
          }
          if (response && response.status === 403) {
            setSaving('403');
          }
        });
    } else {
      props.close(
        (!props.addModal && !props.editModal && !props.cloneModal) ||
        clear === 'close_all'
      );
      if (editFilter) {
        REPORTSTORE.setPreviewReportValues({
          ...REPORTSTORE.previewReportValues,
          ...form.getFieldsValue(),
          form_list: formDataList,
        });
      }
      if (clear && !props.addModal && !props.editModal && !props.cloneModal) {
        props.setReportID(null);
        REPORTSTORE.previewReportValues = null;
        REPORTSTORE.preview_report_criteria = null;
      }
      setInitiation(false);
      setPreviewReport(null);
      setSaving(true);
      setTip('Loading');

      setIndeterminate(false);
      setCheckAll(false);
      setSelectedReportFieldsKeys([]);
    }
  };

  const editFilterMode = () => {
    if (REPORTSTORE.previewReportValues.id && REPORTSTORE.previewFilterForExport) {
      REPORTSTORE.setPreviewFilterForExport(null);
      REPORTSTORE.ReadReport(REPORTSTORE.previewReportValues.id)
        .then((data) => {
          InitaitePreviewReport();
          setSaving(false);
          setTip(null);
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            setSaving('404');
          }
          if (response && response.status === 403) {
            setSaving('403');
          }
        });
    } else {
      let payloadFields = [];
      mockData &&
        mockData.length > 0 &&
        mockData.forEach((x, i) => {
          if (targetKeys.includes(x.key)) {
            payloadFields.push(x.description);
          }
        });
      if (payloadFields) {
        localStorage.setItem(
          'tempSelectedReportFieldsKeys',
          JSON.stringify(payloadFields)
        );
      }
    }
    setEditFilter(true);
    let report_comparison_data = form.getFieldValue('report_for_comparison_mode');
    if (report_comparison_data) {
      form.setFieldsValue({
        report_for_comparison_mode: formatReportForComparisonData(report_comparison_data),
      });
    }
  };

  //-------------DRAWER RELATED FUNCTION-----------------//

  const cancelEditMode = () => {
    setEditFilter(false);
    REPORTSTORE.setPreviewFilterForExport(null);
    let currentFormData = form.getFieldsValue();
    form.resetFields();
    form.setFieldsValue({
      report_type: currentFormData.report_type,
    });
    InitaitePreviewReport(false, false, true);
    //setIsPreviewFromEditMode(false);

  };

  const manageReset = () => {
    REPORTSTORE.handleInnserReset();
  }

  // const manageRefresh = () => {
  //   REPORTSTORE.handleInnserReset();
  // }

  const [resetOptionVisible, setResetOptionVisible] = useState(false);
  // const [topPinnedData, setTopPinnedData] = useState(null);



  // if (form.getFieldValue('report_type') === "summary") {
  //   const rowListRecord = PreviewReport ? PreviewReport : null;

  //   if (rowListRecord && topPinnedData === null) {
  //     let cloneRowData = [...rowListRecord];
  //     const rowLastIndexRecord = cloneRowData.splice(-1);

  //     setTopPinnedData(rowLastIndexRecord);
  //     setPreviewReport(cloneRowData);
  //   }
  // }


  return (
    <Drawer
      visible={visible}
      onClose={() => close()}
      placement='right'
      getContainer={false}
      width={'90vw'}
      title={displayTitle()}
      destroyOnClose={true}
      className="headerWith_MultiOptions"
    // extra={}
    >
      <Spin size='large' className='fullHeight' spinning={saving === true} tip={tip}>
        {saving === '404' ? (
          <PageNotFound />
        ) : saving === '403' ? (
          <Unauthorized />
        ) : (
          <>
            <div className='w-100' style={{ minHeight: '200px' }}>
              {REPORTSTORE.previewReportValues ? (
                <>
                  <PageHeader>
                    {/** Here  OLF FILTER CODE WILL GOES */}

                    {editFilter ? (
                      <>
                        <FormComponent
                          initialValues={REPORTSTORE.initialSummaryValues}
                          defaultFormDataList={defaultFormDataList}
                          form={form}
                          formDataList={formDataList}
                          setFormDataList={setFormDataList}
                          isPreview={true}
                          handleSubmit={handleSubmit}
                          setDisabled={setDisabled}
                          checkAll={checkAll}
                          setCheckAll={setCheckAll}
                          indeterminate={indeterminate}
                          setIndeterminate={setIndeterminate}
                          formButtons={
                            <div className='text-right'>
                              {AUTH.checkPermission(21, 'preview-data') && (
                                <Button
                                  key='1'
                                  className='mr-10'
                                  htmlType='button'
                                  shape='round'
                                  disabled={
                                    isDisabled ||
                                    !(
                                      form.getFieldValue('code') &&
                                      (form.getFieldValue('report_type') === 'kpi'
                                        ? true
                                        : form.getFieldValue('report_on'))
                                    )
                                  }
                                  onClick={() => {
                                    handlePreviewReport();
                                  }}
                                >
                                  {t('Reports.Preview')} {t('Reports.Data')}
                                </Button>
                              )}

                              {AUTH.checkPermission(21, 'edit') ? (
                                <>
                                  {AUTH?.user?.id !== REPORTSTORE.created_by_id ? (
                                    <>
                                      {REPORTSTORE.previewReportValues?.id ? (
                                        <>
                                          <Button
                                            key='2'
                                            className='mr-10'
                                            htmlType='button'
                                            shape='round'
                                            disabled={
                                              isDisabled ||
                                              !(
                                                form.getFieldValue('code') &&
                                                (form.getFieldValue('report_type') ===
                                                  'kpi'
                                                  ? true
                                                  : form.getFieldValue('report_on'))
                                              )
                                            }
                                            onClick={() => {
                                              handleSaveAsReport();
                                            }}
                                          >
                                            {t('Common_Data.SaveAs')}
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          {AUTH.checkPermission(21, 'add') && (
                                            <Button
                                              key='2'
                                              className='mr-10'
                                              htmlType='submit'
                                              type='primary'
                                              shape='round'
                                              disabled={
                                                isDisabled ||
                                                !(
                                                  form.getFieldValue('code') &&
                                                  (form.getFieldValue('report_type') ===
                                                    'kpi'
                                                    ? true
                                                    : form.getFieldValue('report_on'))
                                                )
                                              }
                                            >
                                              {t('Common_Data.Save')} &{' '}
                                              {t('Reports.Preview')}
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <Button
                                      key='2'
                                      className='mr-10'
                                      htmlType='submit'
                                      type='primary'
                                      shape='round'
                                      disabled={
                                        isDisabled ||
                                        !(
                                          form.getFieldValue('code') &&
                                          (form.getFieldValue('report_type') === 'kpi'
                                            ? true
                                            : form.getFieldValue('report_on'))
                                        )
                                      }
                                    >
                                      {t('Common_Data.Save')} & {t('Reports.Preview')}
                                    </Button>
                                  )}
                                </>
                              ) : null}

                              {AUTH.checkPermission(21, 'add') && (
                                <Button
                                  key='3'
                                  className='mr-10'
                                  shape='round'
                                  onClick={() => {
                                    cancelEditMode();
                                  }}
                                  disabled={isDisabled}
                                >
                                  {t('Common_Data.Cancel')}
                                </Button>
                              )}
                            </div>
                          }
                          setsaveFilterList={setsaveFilterList}
                          saveFilterList={saveFilterList}
                          countsListReport={countsListReport}
                          setCountsListReport={setCountsListReport}
                        />
                        <Divider className='pb-5 pt-5' />
                      </>
                    ) : null}
                    <div className='mt-0' style={{ minHeight: '100px' }}>                      
                      <PreviewDataGridComponent
                        rowData={PreviewReport}
                        // topPinnedDataRow={topPinnedData}
                        form={form}
                        editFilter={editFilter}
                      />
                    </div>
                    {NoDataAlert && (
                      <NoRecordAlert
                        visible={NoDataAlert}
                        setNoDataAlert={setNoDataAlert}
                      />
                    )}
                  </PageHeader>
                </>
              ) : null}
            </div>
          </>
        )}
      </Spin>
      {props?.fromSideBar && <TimeTracker
        page={"reports"}
        fromSideBar={props?.fromSideBar}
      />}
    </Drawer >
  );
});

export default PreviewDataComponent;
