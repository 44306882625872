import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../../component/InputComponent';
import { Spin, Col, Divider } from 'antd';
import { vsmPeople } from '../../../../../config/messages';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';

const AddressSubElementNew = observer(
  ({ onChange, field, SetSelectedTypes, rejected_types, form }) => {
    const [fetchCountry, setFetchCountry] = useState(true);
    const [fetchCity, setFetchCity] = useState(true);
    const [fetchState, setFetchState] = useState(true);
    const { ORGANISATION, PEOPLE } = useStore();
    const { t } = useTranslation();
    return (
      <>
        <Col span={12}>
          <InputComponent
            type='autocomplete'
            label={`${t('Common_Data.City')}`}
            name={[field.name, 'city']}
            key={[field.key, 'city']}
            placeholder={`${t('Common_Data.City')}`}
            onChange={onChange}
            options={ORGANISATION.cityList}
            tooltip={`${t('Common_Data.Settlement_Place')}.`}
            rules={vsmPeople.validation.city}
            allowClear
            showSearch
            showArrow
            onFocus={() =>
              fetchCity && ORGANISATION.getCityList().then(() => setFetchCity(false))
            }
            notFoundContent={fetchCity ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
          />
        </Col>
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.Address')} 1`}
            placeholder={`${t('Common_Data.Address')} 1`}
            name={[field.name, 'address_1']}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.ZIP')}/${t('Common_Data.Postal_Code')}`}
            placeholder={`${t('Common_Data.ZIP')}/${t('Common_Data.Postal_Code')}`}
            name={[field.name, 'postal_code']}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.Address')} 2`}
            placeholder={`${t('Common_Data.Address')} 2`}
            name={[field.name, 'address_2']}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <InputComponent
            type='autocomplete'
            label={`${t('Common_Data.State')}/${t('PeopleManagement.County')}`}
            name={[field.name, 'state_county']}
            key={[field.key, 'state_county']}
            placeholder={`${t('Common_Data.State')}/${t('PeopleManagement.County')}`}
            onChange={onChange}
            options={ORGANISATION.stateCountyList}
            tooltip={`${t('Common_Data.State_Country_Tooltip')}.`}
            rules={vsmPeople.validation.state}
            allowClear
            showSearch
            showArrow
            onFocus={() =>
              fetchState &&
              ORGANISATION.getStateCountyList().then(() => setFetchState(false))
            }
            notFoundContent={fetchState ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
          />
        </Col>
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.Address')} 3`}
            placeholder={`${t('Common_Data.Address')} 3`}
            name={[field.name, 'address_3']}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <InputComponent
            required
            type='select'
            label={`${t('Common_Data.Country')}`}
            name={[field.name, 'country_id']}
            key={[field.key, 'country_id']}
            placeholder={`${t('Common_Data.Country')}`}
            onChange={onChange}
            allowClear
            showSearch
            options={{
              values: ORGANISATION.dropdown_countries_list || [
                {
                  id:
                    PEOPLE.peopleValues &&
                    PEOPLE.peopleValues.addresses_global_countries &&
                    PEOPLE.peopleValues.addresses_global_countries.id,
                  country_name:
                    PEOPLE.peopleValues &&
                    PEOPLE.peopleValues.addresses_global_countries &&
                    PEOPLE.peopleValues.addresses_global_countries.country_name,
                },
              ],
              value_key: 'id',
              text_key: 'country_name',
            }}
            rules={vsmPeople.validation.country}
            tooltip={`${t('Common_Data.State_Nation_Territory_Tooltip')}.`}
            onFocus={() =>
              fetchCountry &&
              ORGANISATION.getAllCountries().then(() => setFetchCountry(false))
            }
            notFoundContent={fetchCountry ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
          />
        </Col>
        <Divider className='mt-10 mb-30' />
      </>
    );
  }
);

export default AddressSubElementNew;
