import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const [form] = Form.useForm();
  const { USERS, AUTH: { user } } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation()
  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    vsmNotify.success({
      message: vsmUsers.add
    });
    window.date_test = data.date_of_birth;
    setSaving(true);
    var formData = USERS.JsonToFormData(data);
    // if (data.logo_url.fileList[0] && data.logo_url.fileList.length > 0) { formData.append("photo_url", data.logo_url.fileList[0].originFileObj) }
    formData.append('date_of_birth', data.date_of_birth.format('D/MM/Y'));
    USERS.AddData(formData)
      .then(() => {
        USERS.getList({}, user?.user_role?.user_role_id === 1 ? true : false);
        close();
        vsmNotify.success({
          message: vsmUsers.add
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('Common_Data.Add')} ${t('Common_Data.User')}`}
      destroyOnClose={true}
      footer={[
        <div className='text-center' key='1'>
          <Button
            key='1'
            form='addeditform'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
          >
            {t('Common_Data.Save')}
          </Button>
          <Button onClick={close} key='2'> {t('Common_Data.Cancel')}</Button>
        </div>
      ]}
    >
      <FormComponent form={form} onChange={handleChange} handleSubmit={handleSubmit} />
    </Drawer>
  );
});

export default AddComponent;
