import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row, Form, Divider } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmTransactionReport } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const FieldValuesElement = observer(({ onChange, form }) => {
  const {
    TransactionFieldStore: { dropdown_list }
  } = useStore();
  const {t} = useTranslation()
  return (
    <div className='w-100'>
      <Form.List name='field_values'>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => {
            var transaction_field_rule = {};
            var operator_rule = {};
            var value_rule = {};
            var field_values = form.getFieldValue('field_values');
            if (
              field_values[field.name] &&
              (field_values[field.name].transaction_field ||
                field_values[field.name].operator ||
                field_values[field.name].value)
            ) {
              transaction_field_rule = {
                rules: vsmTransactionReport.validation.transaction_field
              };
              operator_rule = { rules: vsmTransactionReport.validation.operator };
              value_rule = { rules: vsmTransactionReport.validation.value };
            }
            return (
              <div className='w-100' key={field.key}>
                {index > 0 && <Divider style={{ marginTop: -10 }}>OR</Divider>}
                <div>
                  <Row gutter={10} style={{ flexFlow: 'row' }}>
                    <Col span={8}>
                      <InputComponent
                        required
                        type='select'
                        name={[field.name, 'transaction_field']}
                        key={[field.key, 'transaction_field']}
                        placeholder={`${t('Common_Data.Select')} ${t('Reports.Transaction')} ${t('Reports.Field')}`}
                        onChange={onChange}
                        options={{
                          values: dropdown_list,
                          value_key: 'id',
                          text_key: 'field_name'
                        }}
                        {...transaction_field_rule}
                      />
                    </Col>
                    <Col span={5}>
                      <InputComponent
                        required
                        type='select'
                        name={[field.name, 'operator']}
                        key={[field.key, 'operator']}
                        placeholder={`${t('Reports.Operator')}`}
                        onChange={onChange}
                        options={{
                          values: [
                            { value: '=', text: '=' },
                            { value: '!=', text: '!=' },
                            { value: '<', text: '<' },
                            { value: '>', text: '>' },
                            { value: '>=', text: '>=' },
                            { value: '<=', text: '<=' }
                          ]
                        }}
                        {...operator_rule}
                      />
                    </Col>
                    <Col flex={1}>
                      <InputComponent
                        onChange={onChange}
                        required
                        name={[field.name, 'value']}
                        key={[field.key, 'value']}
                        placeholder='Value'
                        {...value_rule}
                        tooltip={`${t('Reports.Value_Tooltip')}.`}
                      />
                    </Col>
                    <Col className='d-flex'>
                      {fields.length > 1 && (
                        <Button
                          type='danger'
                          onClick={() => remove(field.name)}
                          icon={<MinusOutlined />}
                        ></Button>
                      )}
                      {index === fields.length - 1 && (
                        <Button
                          type='primary'
                          className='ml-5'
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        ></Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            );
          });
        }}
      </Form.List>
    </div>
  );
});

export default FieldValuesElement;
