import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Tooltip, Button } from 'antd';
import useStore from '../../../store';
import DateTimeConfirmComponent from './DateTimeConfirmComponent';
import HourglassConfirmComponent from './HourglassConfirmComponent';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';


const ActionRenderer = observer((props) => {
  //const [count, setCount] = useState(0);
  const { t } = useTranslation();
  const [extrafooter, setExtrafooter] = useState(null);
  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [openHourglassModalLead, setOpenHourglassModalLead] = useState(false);
  const [leadHourglassConfirm, setLeadHourglassConfirm] = useState();
  const [leadHourglassTime, setleadHourglassTime] = useState(null);
  let history = useHistory();

  const {
    AUTH,
    REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();

  /*const handleCount = () => {
    setCount(count + 1);
  };*/

  //Confirm Modal
  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(data);
      setOpenDateTimeModal(true);
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // };

  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22 ];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  /**Start: LEAD Hour Glass Update  */
  const openLeadConfirmHourglassModal = (data) => {
    setOpenHourglassModalLead(true);
    setLeadHourglassConfirm(data);
    let leadHourglassTime = 0;
    if (data.lead_status_id) {
      if (data.lead_status_id.parent_status_id === 2) {
        //Opportunity
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_opportunity_hourglass;
      } else if (data.lead_status_id.parent_status_id === 3) {
        //Deals
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_deals_hourglass;
      } else if (data.lead_status_id === 4) {
        //Un-Used Leads
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_unused_leads_hourglass;
      } else {
        //Active Leads
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_used_leads_hourglass;
      }
    }
    setleadHourglassTime(leadHourglassTime);
  };
  const closeLeadConfirmHourglassModal = () => {
    setleadHourglassTime(null);
    setOpenHourglassModalLead(false);
  };
  /**Start: LEAD Hour Glass Update  */

  return (
    <div className='action-column promotionAction'>
      {/* {AUTH.checkPermission(11, 'change-view') && ( */}
      <Button type='text' title={`${t('Block_Edit_Assign.Deal_Desk')}`}>
        <img
          className='menuicon'
          alt=''
          src={AUTH.GetThemedImage('Deal_icn')}
          onClick={() => {
            history.push(`/dealdesk/${props.data.id}`);
          }}
        />
      </Button>
      {/* )} */}
      {AUTH.checkPermission(5, 'reset-reminder-date-time') && (
        <span
          className='Calendar'
          onMouseEnter={() => {
            if (props.data?.reset_count !== null) {
              getReminderDatepickerExtraFooter(props.data, AUTH.global_fulldate_format, {
                type: 'leads',
              }).then((data) => {
                setExtrafooter(data);
              });
            }
          }}
        >
          <Tooltip
            title={props.data?.reset_count !== null ? `${t('Block_Edit_Assign.Reset_Reminder')}` : `${t('Block_Edit_Assign.Set_Reminder')}`}
          >
            <DatePickerCostume
              name='Calendar'
              showTime
              disabledDate={true}
              // format={'HH:mm'}
              // disabledHours={() => disabledHours()}
              //value={moment(AUTH.getLocalTimeDealDesk(new Date()))}
              // minuteStep={15}
              // hideDisabledOptions
              showNow={false}
              onOk={(e) => openConfirmDateTimeModal(e, props.data)}
              renderExtraFooter={() => extrafooter}
            />
          </Tooltip>

          <img className='menuicon' alt='' src={AUTH.GetThemedImage('Calendar')} />
        </span>
      )}
      {AUTH.checkPermission(3, 'reset-reminder-time') && (
        <img
          className='menuicon'
          alt=''
          src={AUTH.GetThemedImage('Reminders_icn')}
          //onClick={handleCount}
          onClick={() => openLeadConfirmHourglassModal(props.data)}
        />
      )}

      {(AUTH.checkPermission(5, 'reset-reminder-date-time') ||
        AUTH.checkPermission(5, 'reset-reminder-time')) && (
          <span className='count'>
            {props.data?.reset_count ? props.data?.reset_count : 0}
          </span>
        )}
      <DateTimeConfirmComponent
        visible={openDateTimeModal}
        close={closeDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <HourglassConfirmComponent
        visible={openHourglassModalLead}
        close={closeLeadConfirmHourglassModal}
        hourglassConfirm={leadHourglassConfirm}
        resetTime={leadHourglassTime}
      />
    </div>
  );
});

export default ActionRenderer;
