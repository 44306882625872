import React from 'react';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ScheduleLibraryView = observer((props) => {
  const { t } = useTranslation();
  const { scheduleData } = props;

  const closeScheduleLibraryDrawer = () => props.closeScheduleLibraryDrawer();

  return (
    <>
      <Drawer
        title={`${t('DealDesk.View_Schedule')} - #${scheduleData?.id} - ${scheduleData?.title}`}
        visible={props.visibleScheduleLibraryDrawer}
        onClose={closeScheduleLibraryDrawer}
        placement='right'
        width={'1050px'}
        destroyOnClose={true}
      >
        <ul className='ScheduleLibraryListing'>
          {scheduleData?.description &&
            scheduleData.description.map((item, index) => (
              <li key={index}>
                <div className='days'>{item.day}</div>
                <div className='task'>{item.task}</div>
                <div className='details'>
                  <div style={{ height: item?.height ? item.height : '25px' }} className='text'>{item.detail}</div>
                  <div className='date'>
                    {moment(item.start_date).format('YYYY-MM-DD')}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </Drawer>
    </>
  );
});
export default ScheduleLibraryView;
