import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button } from 'antd';

import useStore from '../../../store';

import LocalGridConfig from '../../../config/LocalGridConfig';
import { vsmCommon } from '../../../config/messages';
import { DateComparator } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const {t} = useTranslation();
  const {
    CONTRACTS,
    AUTH,
    CONTRACTS: { onFilterChanged, onGridChanged },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth }
  } = useStore();
  const { onCellKeyDown } = props;

  useEffect(() => {
    CONTRACTS.getList();
  }, [CONTRACTS]);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        {AUTH.checkPermission(22, 'edit') && (
          <Button
            type='text'
            title={`${t('Common_Data.Edit')}`}
            onClick={() => props.openEditDrawer(params.data)}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Edit')} />
          </Button>)}
        {AUTH.checkPermission(22, 'delete') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => props.openDeleteDrawer(params.data)}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
          </Button>)}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('Contracts.Contract_Owner')}`,
        field: 'contract_owner',

      },
      {
        headerName: `${t('Contracts.Contract_Number')}`,
        field: 'contract_number',

      },
      {
        headerName: `${t('Contracts.Contract_Start_Date')}`,
        field: 'contract_start_date',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator
        },
        cellRenderer: (params) => {
          return params.data.contract_start_date
            ? AUTH.getLocalTime(params.data.contract_start_date, 0, 1)
            : '';
        },

      },
      {
        headerName: `${t('Contracts.Contract_End_Date')}`,
        field: 'contract_end_date',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator
        },
        cellRenderer: (params) => {
          return params.data.contract_end_date
            ? AUTH.getLocalTime(params.data.contract_end_date, 0, 1)
            : '';
        },

      },
      {
        headerName: `${t('Contracts.Organisation_Name')}`,
        field: 'organisation_name',

      },
      {
        headerName: `${t('Contracts.Contract_Term')}`,
        field: 'contract_months',

      },
      {
        headerName: `${t('Contracts.Contract_Status')}`,
        field: 'contract_status_name',

      },
      {
        headerName: `${t('Contracts.Contract_Currency')}`,
        field: 'currency.currency_name',

      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator
        },
        cellRenderer: (params) => {
          return params.data.updated_at
            ? AUTH.getLocalTime(params.data.updated_at, 0, 1)
            : '';
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',

        // lockPosition: true,
        // suppressNavigable: true
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',

        filter: false,
        sortable: false,
        pinned: 'right',
        maxWidth: 120,
        lockPosition: true,
        suppressNavigable: true
      }
    ]
  };

  let columns = [];
  let columnConfig = localStorage.getItem('contracts_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  })

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)'
      }}
    >
      <AgGridReact
        rowData={CONTRACTS.list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer
        }}
        onGridReady={CONTRACTS.setupGrid}
        gridOptions={{ ...LocalGridConfig.options, rowHeight: 30 }}
        onCellKeyDown={onCellKeyDown}
        onColumnResized={onGridChanged}
        onColumnMoved={onGridChanged}
        onColumnPinned={onGridChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
      />
    </div>
  );
});

export default ListComponent;
