import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Spin } from 'antd';
import useStore from '../../../../store';
import FormComponentNew from './FormComponentNew';
import { vsmNotify, vsmPeople } from '../../../../config/messages';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { peoplePhotoURL, address_type_list } from '../../../../utils/GlobalFunction';

import { default as AddOrgComponent } from '../../../OrganisationManagement/AllOrganisations/component/AddComponentNew';
import { default as EditOrgComponent } from '../../../OrganisationManagement/AllOrganisations/component/EditComponentNew';
// import TrackTimeComponent from '../../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../../component/TimeTracker';
import { Link } from 'react-router-dom';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { PEOPLE, ORGANISATION,AUTH } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [addAddress, setAddAddress] = useState(false);
  const [imgchanged, setImgchanged] = useState(false);

  const [addOrgModal, setAddOrgModal] = useState(false);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null); //Used only for ORG edit.
  const { editOpen = false, isChildDrawer = false, isFromOrg = false, SearchPageEdit } = props; // Added for View/Edit Drawer Flag

  const close = () => {
    setAddAddress(false);
    updateFileList([]);
    // if (!editOpen) {
    //   PEOPLE.peopleValues = null;
    // }
    form.resetFields();
    setSaving(false);
    setDisabled(true);
    setImgchanged(false);
    props.close();
  };

  const handleSubmit = (data) => {
    if (data.phone && data.phone.length > 0) {
      data.phone = data.phone.filter((x) => x && x.contact_for && x.contact_value);
      data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null;
    }

    //if (addAddress) {
    if (data.address && data.address.length > 0) {
      data.address = data.address.filter((x) => x && x && x.address_type);
      data.address = data.address.length > 0 ? JSON.stringify(data.address) : null;
    } else {
      delete data.address; // = null;
    }
    // } else {
    //   data.address = null;
    // }

    if (data.email && data.email.length > 0) {
      data.email = data.email.filter((x) => x && x.contact_for && x.contact_value);
      data.email = data.email.length > 0 ? JSON.stringify(data.email) : null;
    }

    setSaving(true);
    var formData = PEOPLE.JsonToFormData(data);

    //formData.append('add_address', addAddress === true ? 1 : 0);
    formData.append('add_address', data.address && data.address.length > 0 ? 1 : 0);

    if (fileList.length > 0 && fileList[0] && imgchanged) {
      formData.append(
        'photo_url',
        fileList[0].hasOwnProperty('originFileObj')
          ? fileList[0].originFileObj
          : fileList[0]
      );
    } else if (fileList.length === 0) {
      formData.append('photo_status', 'deleted');
    }

    formData.append(
      'date_of_birth',
      data.date_of_birth ? moment(data.date_of_birth).format('YYYY-MM-DD') : ''
    );
    PEOPLE.EditData(formData, PEOPLE.peopleValues.id, SearchPageEdit)
      .then((res) => {
        if (props.setPeopleDropDown) {
          props.setPeopleDropDown(PEOPLE.peopleValues.id, data.industry_id);
        }

        if (res?.data) {
          PEOPLE.peopleValues = res.data;
        }
        close();
        vsmNotify.success({
          message: vsmPeople.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        let checkbox = form.getFieldValue('add_address');
        let address = form.getFieldValue('address');
        address = address && address.filter((x) => x && x);
        if (checkbox && (!address || (address && address.length === 0))) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (PEOPLE.peopleValues) {
      updateFileList(
        PEOPLE.peopleValues.photo_url
          ? [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: peoplePhotoURL + PEOPLE.peopleValues.photo_url,
            },
          ]
          : []
      );
      PEOPLE.peopleStatusList = [PEOPLE.peopleValues?.people_status_name];
      if (PEOPLE.peopleValues.address && PEOPLE.peopleValues.address.length > 0) {
        setAddAddress(true);
      } else {
        setAddAddress(false);
      }
      let address_list =
        PEOPLE.peopleValues.address && PEOPLE.peopleValues.address.length > 0
          ? PEOPLE.peopleValues.address
          : [{}];
      if (address_list.length > 0) {
        address_list.map((obj) => {
          if (!address_type_list.includes(obj.address_type)) {
            obj.address_type = 'Other';
          }
          return null;
        });
      }
      form.setFieldsValue({
        salutation: PEOPLE.peopleValues.salutation ? PEOPLE.peopleValues.salutation : '',
        first_name: PEOPLE.peopleValues.first_name,
        last_name: PEOPLE.peopleValues.last_name,
        title: PEOPLE.peopleValues.title,
        status_id: PEOPLE.peopleValues.status_id,
        date_of_birth: PEOPLE.peopleValues.date_of_birth
          ? moment(PEOPLE.peopleValues.date_of_birth, 'YYYY-MM-DD')
          : null,
        phone:
          PEOPLE.peopleValues.phone.length > 0
            ? PEOPLE.peopleValues.phone
            : PEOPLE.initialValues.phone,
        email:
          PEOPLE.peopleValues.email.length > 0
            ? PEOPLE.peopleValues.email
            : PEOPLE.initialValues.email,
        pss_number: PEOPLE.peopleValues.pss_number,
        address:
          PEOPLE.peopleValues.address && PEOPLE.peopleValues.address.length > 0
            ? PEOPLE.peopleValues.address
            : [{}],
        former_last_name: PEOPLE.peopleValues.former_last_name,
        notes: PEOPLE.peopleValues.notes,
        ppsn_document_type: PEOPLE.peopleValues.ppsn_document_type,
        pps_number: PEOPLE.peopleValues.pps_number,
        pronounced: PEOPLE.peopleValues.pronounced,
        industry_id:
          PEOPLE.peopleValues.industry_id && PEOPLE.peopleValues.industry_id.id,
        add_address:
          PEOPLE.peopleValues.address && PEOPLE.peopleValues.address.length > 0
            ? true
            : false,
        types:
          PEOPLE.peopleValues.types && PEOPLE.peopleValues.types.length > 0
            ? PEOPLE.peopleValues.types
            : [],
        organisation_name: PEOPLE.peopleValues.organisation_name,
      });
      if (PEOPLE.peopleValues?.org_name) {
        ORGANISATION.dropdown_ROrg_list = [PEOPLE.peopleValues.org_name];
      }

    }
  }, [PEOPLE.peopleValues, PEOPLE, form, PEOPLE.initialValues, ORGANISATION]);

  // Open form for add new Organisation
  const openAddOrgModal = () => {
    setAddOrgModal(true);
  };
  // Close form for close new Organisation
  const closeAddOrgModal = () => setAddOrgModal(false);

  const setOrgDropDown = (id) => {
    //getMappedOrg();
    form.setFieldsValue({ organisation_name: id });
    ORGANISATION.getAllROrg({
      selected_id: [form.getFieldValue('organisation_name')],
      mapping_type: 1,
    });
    handleChange();
  };

  const setId = (id) => setTransactionId(id);
  const openEditOrgDrawer = () => {
    var payload = { organisation_id: form.getFieldValue('organisation_name') };
    ORGANISATION.setOrganisationValues(null);
    ORGANISATION.getRecordDetail(payload).then((data) => {
      setId(payload.organisation_id);
      ORGANISATION.setEditValues(data);
      setEditOrgModal(true);
    });
  };

  // Close form of edit Organisation
  const closeEditOrgDrawer = () => {
    //getMappedOrg();
    form.setFieldsValue({ organisation_name: form.getFieldValue('organisation_name') });

    ORGANISATION.getAllROrg({
      selected_id: [form.getFieldValue('organisation_name')],
      mapping_type: 1,
    });

    // ORGANISATION.organisationValues = null;
    // ORGANISATION.setEditValues(null);
    setEditOrgModal(false);
    handleChange();
  };

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={`${editOpen ? '1050px' : '1100px'}`}
      zIndex={editOpen || isChildDrawer ? 1001 : 1000}
      title={`${t('Common_Data.Edit')} ${PEOPLE.peopleValues
        ? `${PEOPLE.peopleValues?.first_name ? PEOPLE.peopleValues?.first_name : ''} ${PEOPLE.peopleValues?.last_name ? PEOPLE.peopleValues.last_name : ''
        }`
        : ''
        }`}
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          <Link to={`/people/${PEOPLE?.peopleValues?.leads_transactions_id}`} target='_blank'>
            <img
              className='menuicon'
              style={{ cursor: 'pointer', position: 'absolute', top: '17px', right: '20px', width: '20px', }}
              alt=''
              title={`${t('PeopleManagement.History')} ${t('Common_Data.People')}`}
              src={AUTH.GetThemedImage('Deal_icn')}
            />
          </Link>

          <Button
            key='3'
            form='editform'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
            shape='round'
            size='medium'
          >
            {t('Common_Data.Update')}
          </Button>
          <Button shape='round' size='medium' onClick={close} key='2'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <Spin size='large' spinning={PEOPLE.editPeopleLoading} tip={`${t('Common_Data.Load')}`}>
        <FormComponentNew
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
          updateFileList={updateFileList}
          isFromOrg={isFromOrg}
          fileList={fileList}
          setAddAddress={setAddAddress}
          addAddress={addAddress}
          setImgchanged={setImgchanged}
          openAddOrgModal={openAddOrgModal}
          openEditOrgDrawer={openEditOrgDrawer}
        />
      </Spin>
      <AddOrgComponent
        visible={addOrgModal}
        close={closeAddOrgModal}
        isChildDrawer={true}
        isFromPeople={true}
        setOrgDropDown={setOrgDropDown}
      />

      <EditOrgComponent
        transactionId={transactionId}
        setId={setId}
        isFromPeople={true}
        visible={editOrgModal}
        isChildDrawer={true}
        close={closeEditOrgDrawer}
        setOrgDropDown={setOrgDropDown}
      />
      {/* {PEOPLE?.peopleValues && props.visible && (
        <TrackTimeComponent
          pageName={'people'}
          leadId={
            PEOPLE?.peopleValues?.leads_transactions_id
              ? PEOPLE?.peopleValues?.leads_transactions_id
              : 0
          }
        />
      )} */}

      <TimeTracker
        page={"people"}
        id={PEOPLE.peopleValues?.leads_transactions_id}
        stop={!props.visible || addOrgModal || editOrgModal}
      />
    </Drawer>
  );
});

export default EditComponent;
