import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ActionRenderer } from './GridComponent';

import useStore from '../../../../store';
import { DateComparator, reportSubTypeList, reportTypeObject, reportTypeValueObject } from '../../../../utils/GlobalFunction';
import { vsmCommon } from '../../../../config/messages';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { useTranslation } from 'react-i18next';

const ListComponent = observer(
  ({
    openDeleteModal,
    onSwitchChange,
    onFavReportChange,
    openEditModal,
    openPreviewDataModal,
    openCloneModal,
  }) => {
    const {
      AUTH,
      SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
      REPORTSTORE: {
        list_data,
        getList,
        setupGrid,
        onFilterChanged,
        onGridChanged,
        agGrid,
        reportGridLoader,
      },
    } = useStore();

    const { t } = useTranslation();

    const onBtShowLoading = useCallback(() => {
      if (agGrid) {
        agGrid.api.showLoadingOverlay();
      }
    }, [agGrid]);

    const hideOverlayFunction = useCallback(() => {
      if (agGrid) {
        agGrid.api.hideOverlay();
      }
    }, [agGrid]);

    useEffect(() => {
      if (reportGridLoader) {
        onBtShowLoading();
      } else {
        hideOverlayFunction();
      }
      return () => { };
    }, [reportGridLoader, onBtShowLoading, hideOverlayFunction]);

    useEffect(() => {
      getList();
    }, [getList]);

    const gridOptions = {
      columnDefs: [
        {
          headerName: '#',
          valueGetter: function (params) {
            return params.node.rowIndex + 1;
          },
          tooltipValueGetter: (params) => {
            return params.node.rowIndex + 1;
          },
          cellClass: 'cellClass',
          pinned: 'left',
          filter: false,
          sortable: false,
          minWidth: 37,
          width: 37,
        },
        {
          headerName: `${t('Reports.Report')} ${t('Common_Data.Name')}`,
          field: 'name',
        },
        {
          headerName: `${t('Reports.Code')}`,
          field: 'code',
          valueGetter: (params) =>
            params.data && params.data.code ? params.data.code : '',
        },
        {
          headerName: `${t('Common_Data.Type')}`,
          field: 'type',
          filter: 'agSetColumnFilter',
          valueGetter: (params) =>
            params.data && params.data.type ? reportTypeValueObject[params.data.type] : '',
        },
        {
          headerName: `${t('Reports.Report_On')}`,
          headerTooltip: `${t('Reports.Report_On')}`,
          field: 'report_on',
          filter: 'agSetColumnFilter',

          filterParams: {
            defaultToNothingSelected: true,
          },
          tooltipValueGetter: (params) => {
            let report_type = reportTypeObject[params.data.type]

            let reportDetailIndex =
              reportSubTypeList[report_type] &&
              reportSubTypeList[report_type].findIndex((x) => x.value === params?.data?.report_on);

            return reportDetailIndex >= 0
              ? reportSubTypeList[report_type]?.[reportDetailIndex]?.text
              : '';
          },
          valueGetter: (params) => {
            let report_type = reportTypeObject[params.data.type]
            let reportDetailIndex =
              reportSubTypeList[report_type] &&
              reportSubTypeList[report_type].findIndex((x) => x.value === params?.data?.report_on);

            return reportDetailIndex >= 0
              ? reportSubTypeList[report_type]?.[reportDetailIndex]?.text
              : '';
          },
        },
        {
          headerName: `${t('Common_Data.Last')} ${t('Reports.Generated')} ${t('Common_Data.By')}`,
          field: 'last_generated_by',

          valueGetter: (params) =>
            params.data && params.data.user
              ? `${params.data.user.first_name} ${params.data.user.last_name}`
              : '',
          filter: 'agSetColumnFilter',
          filterParams: {
            defaultToNothingSelected: true,
          },
        },
        {
          headerName: `${t('Common_Data.Last')} ${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
          field: 'last_generated_at',
          filter: 'agDateColumnFilter',
          cellRenderer: (params) => {
            return AUTH.getLocalTime(params?.data?.last_generated_at,AUTH.global_fulldate_format);
          },
          filterParams: {
            buttons: ['reset'],
            inRangeInclusive: true,
            comparator: DateComparator,
          },
        },
        {
          headerName: `#${t('Common_Data.ID')}`,
          field: 'id',
          filter: 'agNumberColumnFilter',

          width: 150,
        },
        {
          headerName: `${t('Common_Data.Actions')}`,
          field: 'actions',
          type: 'actionColumn',
          cellClass: 'cellClass actionColumn',
          sortable: false,
          filter: false,
          pinned: 'right',
          width: 220,
        },
      ],
    };

    let columns = [];
    let columnConfig = localStorage.getItem('report_grid');
    if (columnConfig) {
      let data = JSON.parse(columnConfig);
      let cols = gridOptions.columnDefs.find((x) => !x.field);
      if (cols) {
        columns.push(cols);
      }
      data &&
        data.forEach((element) => {
          cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
          if (cols) {
            columns.push(cols);
          }
        });
    } else {
      columns = gridOptions.columnDefs;
    }

    // to show tooltip on column header and column values
    var withTooltipCols = columns.map((item) => {
      if (!['type'].includes(item.field)) {
        item.headerTooltip = item.headerName;
        item.tooltipField = item.field;
      }
      return item;
    });

    return (
      <div
        className='ag-theme-alpine grid_wrapper'
        style={{
          height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)',
        }}
      >
        <AgGridReact
          gridOptions={{ ...LocalGridConfig.options, rowHeight: 30, pagination: true }}
          rowData={list_data}
          modules={AllModules}
          columnDefs={withTooltipCols}
          defaultColDef={LocalGridConfig.defaultColDef}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openDeleteModal,
            onSwitchChange,
            onFavReportChange,
            openEditModal,
            openPreviewDataModal,
            openCloneModal,
          }}
          onGridReady={setupGrid}
          onColumnResized={onGridChanged}
          onColumnMoved={onGridChanged}
          onColumnPinned={onGridChanged}
          onFilterChanged={onFilterChanged}
          onSortChanged={onGridChanged}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
        />
      </div>
    );
  }
);

export default ListComponent;
