import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { clear, reset } from '../../utils/ResetClearButton';
import { convertTextToID } from '../../utils/GlobalFunction';

export default class HistoryStore {
  list_data = null;
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;

  agGrid = null;
  deleteValues = null;
  search_payload = {};

  selectedGridFilter = null; //Used For Saved Grid Filters To Apply -

  statuslistHistoryFilter = [
    { value: 'Ticket', text: 'Ticket' },
    { value: 'SOLD', text: 'SOLD' },
    { value: 'People', text: 'People' },
    { value: 'Organisation', text: 'Organisation' },
  ];

  isWastePage = false;
  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.HISTORY) {
        params = { request: temp.HISTORY };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          HISTORY: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          HISTORY: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'history_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('history_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig), });
    }
    // var datasource = this.createDatasource(GridConfig.options);
    let param = this.getFilter(params);
    params.api.setFilterModel(param?.request?.filter);
    params.api.setSortModel(param?.request?.sort);
    var datasource = this.createDatasource(GridConfig.options);
    api.setServerSideDatasource(datasource);
    // if (param && param.request) {
    //   this.agGrid.api.setFilterModel(param.request.filter);
    //   this.agGrid.api.setSortModel(param.request.sort);
    // }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('history_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        var filter_data = {
          final_filter: params.request.filterModel,
          final_sort: params.request.sortModel,
        };

        // var filter_data = params.request.filterModel;
        
        if (filter_data['type']) {
          filter_data['type'].values = convertTextToID(
            filter_data['type'],
            this.statuslistHistoryFilter,
            'text',
            'value'
          );
        }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          // filter_data: (this.search_payload?.hasOwnProperty('search')
          //   ? {}
          //   : params.request.filterModel),
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        let merged = {...payload,...this.search_payload,...{ waste: this.isWastePage },};
        this.setFilter(params.api);
        this.getList(merged).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('history_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
              this.agGrid.columnApi.applyColumnState({
                state: JSON.parse(columnConfig),
              });
            }
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  getList = (payload = {}) => {
    return Axios.post(`deal-desk/get-notes-from-search`, payload).then(({ data }) => {
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  filterGetMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      let newData = [];
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.organisation_name = item.organisation_name + ' (' + item.id + ')';
          newData[item.id] = item.organisation_name;
        });
      }
      this.filter_org_list = newData;

      return newData;
    });
  };

  getLeadsUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`).then(({ data }) => {
      this.dropdown_leads_users_list = data.data;
      return data;
    });
  };

  DeletedData = (formdata) => {
    return Axios.post(
      `deal-desk/delete-note/${formdata.lead_transaction_id}/${formdata.id}`,
      { waste: false }
    )
      .then(({ data }) => {
        //this.getWasteCount();
        // if (this.agGrid) {
        //   this.getList();
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };


  // // reset all the server side filters
  // handleReset = () => {
  //   localStorage.removeItem('history_grid');
  //   this.selectedGridFilter = null;
  //   this.agGrid.api.setFilterModel(null);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  handleReset = () => {
    reset(this.agGrid, "history_grid", this.setSelectedGridFilter)
  }

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  // saveParams = JSON.parse(localStorage.getItem('params'))
  // onLoadFliter = this.saveParams?.HistoryStore?.filter
  applyFilterDefault = () => {
    clear(this.agGrid, null)
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;
  };

  // /** This is called on clear button */
  // applyFilterDefault = () => {
  //   this.selectedGridFilter = null;
  //   if (this.agGrid) {
  //     // localStorage.removeItem('org_grid');
  //     this.selectedGridFilter = null;
  //     this.agGrid.api.setFilterModel(null);
  //     this.agGrid.api.setSortModel(null);
  //     // this.agGrid.columnApi.resetColumnState();
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };
}

decorate(HistoryStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  deleteValues: observable,
  isWastePage: observable,
  statuslistHistoryFilter: observable,

  getList: action,
  setupGrid: action,
  onGridChanged: action,
  DeletedData: action,
  setDeleteValues: action,
  setPageSize: action,
  handleReset: action,
  applyFilterDefault: action,
  setSelectedGridFilter: action,
  getLeadsUsersList: action,
  filterGetMappedOrg:action,
});
