import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';
const AutoSaveNotifyLIB = observer(() => {
    const {t} = useTranslation()
    const {
        SSSLEADLIBRARYSTORE: { setNotifyScheduleLIB, notifyScheduleLIB },
    } = useStore();

    useEffect(() => {
        if (notifyScheduleLIB) {
            setTimeout(() => {
                setNotifyScheduleLIB(false);
            }, 4000);
        }
    }, [notifyScheduleLIB, setNotifyScheduleLIB]);

    return (
        <>
            {notifyScheduleLIB ? <span className={'autoSaveNoteNotify'}>{t('DealDesk.Draft_Saved')}</span> : null}
        </>
    );
});

export default AutoSaveNotifyLIB;
