import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'antd/lib/form/Form';
import { Button, Spin, Drawer } from 'antd';
import FormComponent from './NewFormComponent';
import useStore from '../../../../store';
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages';
import { saveFilterArr } from '../../../../utils/GlobalFunction';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CloneComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = useForm();
  const [submitSaving, setSubmitSaving] = useState(false);
  const [savePreviewSaving, setsavePreviewSaving] = useState(false);

  const [tip, setTip] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const { SavedReportStore, AUTH, PEOPLE, ORGANISATION, LEADSSTORE } = useStore();
  const [formDataList, setFormDataList] = useState([]);
  const [saveFilterList, setsaveFilterList] = useState([]);
  const [isInitiated, setInitiation] = useState(false);
  const [isReportLoading, setisReportLoading] = useState(false);

  const history = useHistory();

  const defaultFormDataList = [
    'report_type',
    'name',
    'code',
    'description',
    'global_toggle',
  ];

  const handleSubmit = (data, isPreview = false) => {
    data.form_list = formDataList;
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.check_unique_code = true;
    if (isPreview) {
      setsavePreviewSaving(true);
    } else {
      setSubmitSaving(true);
    }
    setTip('Saving');
    SavedReportStore.AddData(data)
      .then((res) => {
        vsmNotify.success({
          message: vsmTransactionReport.save,
        });

        if (res.report) {
          SavedReportStore.created_by_id = res.report.created_by;
          SavedReportStore.previewReportValues = {
            ...JSON.parse(res.report.generated_on_options),
            id: res.report.id,
            name: res.report.name,
            code: res.report.code,
            description: res.report.description,
          };
          if (SavedReportStore.previewReportValues?.global_toggle !== undefined) {
            SavedReportStore.previewReportValues.global_toggle =
              !SavedReportStore.previewReportValues.global_toggle;
          } else {
            SavedReportStore.previewReportValues.global_toggle = false;
          }
        }
        if (isPreview) {
          props.openPreviewDataModal(res.report.id, true);
        } else {
          props.close();
        }
        if (SavedReportStore.my_records === 'my') {
          SavedReportStore.getList({}, AUTH.user.id);
        } else {
          SavedReportStore.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSubmitSaving(false);
        setsavePreviewSaving(false);
        setTip(null);
      });
  };

  const openPreviewReport = () => {
    let data;
    SavedReportStore.setPreviewReportValues({
      ...SavedReportStore.previewReportValues,
      ...form.getFieldsValue(),
      form_list: formDataList,
    });
    data = SavedReportStore.formatFormData(
      SavedReportStore.previewReportValues,
      true,
      true
    );
    data.check_unique_code = true;
    data.action = 'preview-data';
    setTip('Loading');
    SavedReportStore.PreviewData(data)
      .then((data) => {
        props.openPreviewDataModal(undefined, false, false, true);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setTip(null);
      });
  };

  const savePreviewReport = (data = form.getFieldsValue()) => {
    data.form_list = formDataList;
    data = SavedReportStore.formatFormData({
      ...SavedReportStore.previewReportValues,
      ...data,
    });
    data.action = 'save_report';
    handleSubmit(data, true);
  };

  const InitaitePreviewReport = useCallback(async () => {
    await setFormDataList(SavedReportStore.previewReportValues.form_list);
    var previewReportValues = SavedReportStore.formatFormData(
      SavedReportStore.previewReportValues,
      false
    );

    if (previewReportValues['organisation']) {
      await ORGANISATION.getAllROrg({
        selected_id: previewReportValues['organisation'],
        mapping_type: 1,
      });
    }
    if (previewReportValues['people']) {
      await PEOPLE.getAllPeople({
        selected_id: previewReportValues['people'],
        mapping_type: 2,
      });
    }
    if (previewReportValues['lead_currency_id'] && !LEADSSTORE.dropdown_currency_list) {
      await LEADSSTORE.getCurrencyList();
    }

    await form.setFieldsValue(previewReportValues);
    if (
      previewReportValues.report_type !== 'leads' &&
      saveFilterArr.includes(previewReportValues.report_type)
    ) {
      if (previewReportValues.report_type === 'reminders') {
        AUTH.getSavedGridFilter({ page: 'reminder' }).then((data) => {
          setsaveFilterList(data);
        });
      } else {
        AUTH.getSavedGridFilter({
          page: previewReportValues.report_type,
        }).then((data) => {
          setsaveFilterList(data);
        });
      }
    }
    if (previewReportValues.report_type === 'investment_hours') {
      let formdata = {
        inactive: true,
      };
      LEADSSTORE.getReportUsersList(formdata);
    }
  }, [SavedReportStore, LEADSSTORE, ORGANISATION, PEOPLE, AUTH, form]);

  useEffect(() => {
    if (!isInitiated && props.report_id && props.visible) {
      setInitiation(true);
      setisReportLoading(true);
      SavedReportStore.ReadReport(props.report_id, true)
        .then((data) => {
          InitaitePreviewReport();
          setTip(null);
        })
        .catch(({ response }) => { })
        .finally(() => {
          setisReportLoading(false);
        });
    }
  }, [
    InitaitePreviewReport,
    SavedReportStore,
    isInitiated,
    props.report_id,
    props.visible,
    history,
  ]);

  useEffect(() => {
    SavedReportStore.previewReportValues = null;
  }, [SavedReportStore]);

  const close = () => {
    props.close();
    props.setReportID(null);
    SavedReportStore.previewReportValues = null;
    setInitiation(false);
    setisReportLoading(false);
    setsavePreviewSaving(false);
    setTip('Loading');
  };

  return (
    <>
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Clone')} ${t('Reports.Report')}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right' key='1'>
            {
              <Button
                key='1'
                className='mr-10'
                htmlType='button'
                shape='round'
                disabled={isDisabled}
                onClick={() => {
                  openPreviewReport();
                }}
              >
                {t('Reports.Preview')} {t('Reports.Data')}
              </Button>
            }
            {AUTH.checkPermission(21, 'add') && (
              <Button
                key='2'
                form='cloneformReport'
                className='mr-10'
                htmlType='submit'
                shape='round'
                type='primary'
                disabled={isDisabled}
                loading={submitSaving}
              >
                {t('Common_Data.Save')}
              </Button>
            )}
            {AUTH.checkPermission(21, 'add') && (
              <Button
                key='3'
                className='mr-10'
                shape='round'
                disabled={isDisabled}
                onClick={() => {
                  savePreviewReport();
                }}
                loading={savePreviewSaving}
              >
                {t('Common_Data.Save')} & {t('Reports.Preview')}
              </Button>
            )}
            <Button shape='round' onClick={close} key='4'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        <Spin
          size='large'
          spinning={isReportLoading}
          className='fullHeight w1000'
          tip={tip}
        >
          {isReportLoading ? null : (
            <FormComponent
              defaultFormDataList={defaultFormDataList}
              form={form}
              formDataList={formDataList}
              setFormDataList={setFormDataList}
              handleSubmit={handleSubmit}
              initialValues={{
                ...SavedReportStore.initialValues,
                sort_by: 'modified_at',
              }}
              setDisabled={setDisabled}
              saveFilterList={saveFilterList}
              setsaveFilterList={setsaveFilterList}
              id='cloneformReport'
              isClone={true}
            />
          )}
        </Spin>
      </Drawer>
    </>
  );
});

export default CloneComponent;
