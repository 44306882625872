import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmKnowledge } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const AddComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { AUTH, KNOWLEDGE } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [content, setContent] = useState({ value: null });

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    var formData = KNOWLEDGE.JsonToFormData(data);

    KNOWLEDGE.AddData(formData)
      .then(() => {
        close();
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmKnowledge.add
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <>
      <Drawer
        key='3'
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Add')} ${t('DealDesk.Knowledge')}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right' key='1'>
            <Button
              key='1'
              form='addform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              shape='round'
              size='medium'
              disabled={isDisabled}
            >
              {t('Common_Data.Save')}
            </Button>
            <Button onClick={close} shape='round' size='medium' key='2'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='addform'
          content={content}
          setContent={setContent}
        />
      </Drawer>
      {props.visible && <TimeTracker
        page={"knowledge"}
        stop={!props?.fromSideBar}
        fromSideBar={props.fromSideBar}
      />}
    </>
  );
});

export default AddComponent;
