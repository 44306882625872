import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import useStore from '../../../store';
import ListComponent from './component/ListComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import AddComponent from './component/AddComponent';
//import RecordPerPage from '../../../config/RecordPerPage';
//import AddBankComponent from './component/AddBankComponent';
import { default as OrgRolemappingEditComponent } from '../../OrganisationManagement/RoleMapping/component/EditComponent';
//import { default as AddBankAccountComponent } from '../BankAccounts/component/AddComponent';
import ContentLoader from '../../../component/ContentLoader';
import Unauthorized from '../../Unauthorized';
import PageNotFound from '../../PageNotFound';
import { useTranslation } from 'react-i18next';

const RoleMapping = observer(() => {
  const {t} = useTranslation()
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState('pending');
  const [mappingId, setMappingId] = useState(null);

  const { PEOPLE, PEOPLEMAPPINGSTORE, ORGANISATION, AUTH } = useStore();

  const { id } = useParams();
  let history = useHistory();

  // set id on edit drawer
  const setId = (id) => setMappingId(id);

  // Open form for add new Organisation
  const openAddModal = () => {
    setAddModal(true);
  };

  // Close form for close new Organisation
  const closeAddModal = () => setAddModal(false);

  // Open form for edit existing Organisation and set values to form
  const openEditModal = (data) => {
    setId(data.id);
    if (data.entity_type === 'People') {
      setEditModal(true);
    } else {
      var payload = { organisation_id: data.entity_id };
      ORGANISATION.getRecordDetail(payload).then((data) => {
        setEditOrgModal(200);
      });
    }
  };

  // Close form of edit people
  const closeEditModal = () => {
    PEOPLEMAPPINGSTORE.setEditValues(null);
    setEditModal(false);
  };

  // Close form of edit Organisation
  const closeEditOrgModal = () => setEditOrgModal(false);

  // Open confirmation alert before deleting existing Organisation
  const openDeleteModal = (data) => {
    PEOPLEMAPPINGSTORE.setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);
  function pageTitle() {
    return (
      <>
        {t('PeopleManagement.Role')} {t('Common_Data.Mapping')}
        <Button
          key='1'
          shape='round'
          size='small'
          type='primary'
          onClick={history.goBack}
          className='goBackBtn'
        >
          {t('Common_Data.GO')} {t('Common_Data.Back')}
        </Button>
      </>
    );
  }

  useEffect(() => {
    PEOPLE.drowpdown_people_list = null;
    PEOPLE.peopleValues = null;
    var payload = { people_id: id };
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setLoading(200);
      })
      .catch((data) => {
        if (data.status === 404) {
          setLoading(404);
        }
        if (data.status === 403) {
          setLoading(403);
        }
      });
    PEOPLEMAPPINGSTORE.getList(payload);
  }, [PEOPLEMAPPINGSTORE, PEOPLE, id]);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(18, 'role-mapping-edit')) {
            if (result.key === 'Enter') {
              openEditModal(e.data);
            }
          }
          if (AUTH.checkPermission(18, 'role-mapping-delete')) {
            if (result.key === 'Delete') {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };

  if (loading === 'pending') {
    return <ContentLoader />;
  }
  if (loading === 200) {
    return (
      <>
        <PageHeader
          title={pageTitle()}
          extra={[
            AUTH.checkPermission(18, 'role-mapping-add') && (
              <Button key='2' shape='round' size='mediumn' onClick={openAddModal}>
                {t('PeopleManagement.Map')} {t('PeopleManagement.New')} {t('PeopleManagement.Role')}
              </Button>
            ),
            // <RecordPerPage
            //   key='5'
            //   defaultValue={PEOPLEMAPPINGSTORE.per_page + ' per page'}
            //   onChange={PEOPLEMAPPINGSTORE.setPageSize}
            // />,
          ]}
        >
          <ListComponent
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
            onCellKeyDown={onCellKeyDown}
          />
          <AddComponent visible={addModal} close={closeAddModal} parent_id={id} />
          <OrgRolemappingEditComponent
            visible={editOrgModal}
            close={closeEditOrgModal}
            parent_id={id}
            mappingId={mappingId}
            setId={setId}
          />
          <EditComponent
            visible={editModal}
            close={closeEditModal}
            parent_id={id}
            mappingId={mappingId}
            setId={setId}
          />
          <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
          {/* {mapBankModal && (
            <AddBankComponent visible={mapBankModal} close={closeBankModal} />
          )} */}
          {/* <AddBankAccountComponent
            visible={addBankAccountModal}
            close={closeAddBankModal}
            parent_id={id}
          /> */}
        </PageHeader>
      </>
    );
  } else {
    if (loading === 404) {
      return <PageNotFound />;
    }
    if (loading === 403) {
      return <Unauthorized />;
    }
  }
});

export default RoleMapping;
