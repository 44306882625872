import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Input, Spin, Checkbox } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { vsmTicket } from '../../../config/messages';
import useStore from '../../../store';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import debounce from 'lodash/debounce';
// import moment from 'moment';

const FormComponent = observer(
  ({
    handleSubmit,
    form,
    onChange,
    // props,
    isDisabled,
    visible,
    id,
    isMasterTicket,
    setMasterTicket,
    isOrgTicket, setOrgTicket,
    onChangeDeadline,
    smallDeadlineDate,
    setResetSubject,
    resetSubject,
    addTicket,
  }) => {
    const {
      AUTH,
      TicketsStore,
      TicketsStore: {
        editValues,
        cloneValues,
        dropdown_user_list,
        dropdown_master_ticket,
        dropdown_ticketstatus,
        dropdown_ticket_type,
        getMasterTicketList,
        getUsersList,
        getTicketstatusList,
      },
      ORGANISATION: { dropdown_global_org, getMappedOrg },
    } = useStore();

    const { t } = useTranslation()
    const [fetchOrgList, setFetchOrgList] = useState(true);
    const [fetchMasterTicket, setFetchMasterTicket] = useState(true);
    const [fetchUsersList, setFetchUsersList] = useState(true);
    const [fetchTicketstatus, setFetchTicketstatus] = useState(true);
    // const [isdeadlineRoutine, setIsdeadlineRoutine] = useState(false);
    const [dropdownPriority, setDropdownPriority] = useState(true);
    // const [PriorityDays, setPriorityDays] = useState(999);
    // const [smallDeadlineDate, setSmallDeadlineDate] = useState(false);    
    const [updateHolder, setUpdateHolder] = useState(true);
    const [checkHolderRule, setHolderRule] = useState(false);


    const checkHolder = () => {
      if (form.getFieldValue('holder') === undefined) {
        setHolderRule(true)
      }
      else {
        setHolderRule(false)
      }
    }
    const updatePriorityDays = (e) => {

      if (e === 7) {
        setUpdateHolder(true)
        // setHolderRule(true)
      } else {
        setUpdateHolder(false)
        // setHolderRule(false)
      }



      // form.getFieldValue('deadline_date')
      // if (e === 1) {
      //   setPriorityDays(24) // disable Critical
      //   if (form.getFieldValue('deadline_date') > moment().add(24, 'hours').endOf('day')) {
      //     form.resetFields(['deadline_date'])
      //   }
      // } else if (e === 2) {
      //   setPriorityDays(48) // disable Urgent
      //   if (form.getFieldValue('deadline_date') > moment().add(48, 'hours').endOf('day')) {
      //     form.resetFields(['deadline_date'])
      //   }
      // } else if (e === 3) {
      //   setPriorityDays(72) // disable Important
      //   if (form.getFieldValue('deadline_date') >= moment().add(72, 'hours').endOf('day')) {
      //     form.resetFields(['deadline_date'])
      //   }
      // } else {
      //   setPriorityDays(999) // disable rest all
      // }

    }

    useEffect(() => {
      if (form.getFieldValue('priority')) {
        updatePriorityDays(form.getFieldValue('priority'))
      }
      if (form.getFieldValue('holder')) {
        checkHolder(form.getFieldValue('holder'))
      }
    })

    useEffect(() => {
      if (editValues) {
        editValues.deadline_date && form.setFieldsValue({
          deadline_date: moment(editValues.deadline_date),
        })
      } else if (cloneValues) {
        cloneValues.deadline_date && form.setFieldsValue({
          deadline_date: moment(cloneValues.deadline_date),
        })
      }
    })

    useEffect(() => {
      if (cloneValues?.ticket_subject) {
        setResetSubject(true)
      }
    }, [cloneValues, setResetSubject])



    const onTicketSubjectChange = () => {
      if (form?.getFieldValue('ticket_subject') === cloneValues?.ticket_subject) {
        setResetSubject(true)
      }
      else {
        setResetSubject(false)
      }
    }

    // disable deadline date base on priority
    // const priorityDate = (current) => {
    //   if (current) {
    //     var a = current <= moment().subtract(1, 'days').endOf('day') // disable old days
    //     var b = PriorityDays === 999 ? null : current >= moment().add(PriorityDays, 'hours').endOf('day') // disable future days
    //     return a || b
    //   }
    // }


    return (
      <Form
        form={form}
        layout='vertical'
        id={id}
        onFinish={handleSubmit}
        className='innerForm'
        onChange={onChange}
      >
        <Row gutter={15}>
          <Col span={12}>
            <InputComponent
              name='parent_ticket_id'
              label={
                <Form.Item
                  lable=''
                  name='is_masterTicket'
                  valuePropName='checked'
                  // initialValue={isMasterTicket}
                  className='p-0 m-0 titleCheckbox'
                >
                  <Checkbox
                    onChange={(e) => {
                      setMasterTicket(e.target.checked);
                      // onCheck();
                      onChange();
                      if (!e.target.checked) {
                        form.setFieldsValue({ parent_ticket_id: null })
                      }
                    }}
                    style={{ fontSize: '1.2rem', padding: '0', margin: '0' }}
                  // checked={isMasterTicket}
                  >
                    {t('DealDesk.Master_Ticket')}
                  </Checkbox>
                </Form.Item>
              }
              // required={isMasterTicket ? true : false}
              //required
              type='select'
              disabled={isMasterTicket ? false : true}
              showSearch={true}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Master')} ${t('Common_Data.Ticket')}`}
              tooltip={`${t('Tickets.Master_Ticket_Tooltip')}.`}
              onChange={onChange}
              onFocus={() =>
                fetchMasterTicket &&
                getMasterTicketList().then(() => setFetchMasterTicket(false))
              }
              allowClear
              options={{
                values:
                  dropdown_master_ticket ||
                  (editValues?.master_ticket && [editValues.master_ticket]) ||
                  (cloneValues?.master_ticket && [cloneValues.master_ticket]),
                value_key: 'id',
                text_key: 'ticket_subject',
              }}
              notFoundContent={fetchMasterTicket ? <Spin size='small' /> : null}
              rules={
                !isMasterTicket
                  ? [{ required: false }]
                  : vsmTicket.validation.parent_ticket_id
              }
            />
          </Col>
          {visible && visible === true ? (
            <>
              <Col span={6}>
                <InputComponent label={`${t('Common_Data.Master')} ${t('Common_Data.Ticket')} ${t('Common_Data.Number')}`} type='labelOnly' />
                <Input
                  bordered={false}
                  value={
                    (editValues && editValues?.master_ticket?.master_ticket_number
                      ? editValues?.master_ticket?.master_ticket_number
                      : '-') ||
                    (cloneValues && cloneValues?.master_ticket?.master_ticket_number
                      ? cloneValues?.master_ticket?.master_ticket_number
                      : '-')
                  }
                  className='text-left'
                />
              </Col>
            </>
          ) : null}
          <Col span={6}>
            <InputComponent
              label={`${t('Common_Data.Owner')}`}
              //tooltip='A Lead Owner is someone who owns the record when it is a Lead in the system.'
              type='labelOnly'
            />
            {editValues ? (
              <Input
                bordered={false}
                value={
                  editValues.ticket_owner?.first_name +
                  ' ' +
                  editValues.ticket_owner?.last_name
                }
                className='text-left'
              />
            ) : (
              <Input
                bordered={false}
                value={AUTH.user.first_name + ' ' + AUTH.user.last_name}
                className='text-left'
              />
            )}
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={24}>
            {/* {resetSubject ? "yes" : "no"} */}
            {cloneValues?.ticket_subject === form?.getFieldValue('ticket_subject')
              ? <>
                <InputComponent
                  name='ticket_subject'
                  label={`${t('Common_Data.Subject')}`}
                  required
                  type='text'
                  tooltip={`${t('Tickets.Subject_Tooltip')}`}
                  onChange={() => { cloneValues && onTicketSubjectChange(); onChange() }}
                  placeholder={`${t('Common_Data.Subject')}`}
                  rules={vsmTicket.validation.ticket_subject}
                  className={resetSubject && "resetSubject"}
                  note={resetSubject && 'Please edit this subject'}
                  validateStatus={resetSubject && "error"}
                />
                {/* {form.getFieldError('ticket_subject')} */}
              </>
              : <>
                <InputComponent
                  name='ticket_subject'
                  label={`${t('Common_Data.Subject')}`}
                  required
                  type='text'
                  tooltip={`${t('Tickets.Subject_Tooltip')}`}
                  onChange={() => { cloneValues && onTicketSubjectChange(); onChange() }}
                  placeholder={`${t('Common_Data.Subject')}`}
                  rules={vsmTicket.validation.ticket_subject}
                />
              </>
            }
          </Col>

          <Col xs={{ span: 24 }}>
            <InputComponent
              name='ticket_description'
              label={`${t('Common_Data.Description')}`}
              required
              onChange={onChange}
              type='textarea'
              placeholder={`${t('Common_Data.Description')}`}
              tooltip={`${t('Tickets.Comprehensive_Description_Tooltip')}.`}
              rules={vsmTicket.validation.ticket_description}
            />
          </Col>

          <Col span={6}>
            <InputComponent
              name='ticket_type'
              //required
              label={`${t('Common_Data.Ticket')} ${t('Common_Data.Type')}`}
              type='select'
              showSearch={true}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Type')}`}
              tooltip={`${t('Tickets.Ticket_Type_Tooltip')}.`}
              onChange={onChange}
              options={{
                values: dropdown_ticket_type,
              }}
            //rules={vsmTicket.validation.ticket_type}
            />
          </Col>

          <Col span={6}>
            <InputComponent
              name='ticket_status'
              required
              label={`${t('Common_Data.Status')}`}
              type='select'
              showSearch={true}
              initialValue={1} //status Open Default
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Status')}`}
              tooltip={`${t('Common_Data.Status_Active_Inactive')}.`}
              onChange={onChange}
              onFocus={() =>
                fetchTicketstatus &&
                getTicketstatusList().then(() => setFetchTicketstatus(false))
              }
              options={{
                values: dropdown_ticketstatus ||
                  (editValues?.ticket_status && [
                    {
                      id: editValues?.ticket_status?.id,
                      status_name: editValues?.ticket_status?.status_name,
                    },
                  ]) ||
                  (cloneValues?.ticket_status && [
                    {
                      id: cloneValues?.ticket_status?.id,
                      status_name: cloneValues?.ticket_status?.status_name,
                    },
                  ]) || [{ id: 1, status_name: 'Open' }],
                value_key: 'id',
                text_key: 'status_name',
              }}
              notFoundContent={fetchTicketstatus ? <Spin size='small' /> : null}
              rules={vsmTicket.validation.status}
            />
          </Col>

          <Col span={12}>
            <InputComponent
              label={`${t('Tickets.Internal')}/${t('Common_Data.Person')}/${t('Common_Data.Organisation')}`}
              type='labelOnly'
              tooltip={`${t('Tickets.Internal_Person_Tooltip')}.`}
            />
            <div className='suffixIcon'>
              <InputComponent
                name='internal_personal_org'
                placeholder={`${t('Common_Data.Select')} ${t('Tickets.Internal')}/${t('Common_Data.Person')}/${t('Common_Data.Organisation')}`}
                required
                type='select'
                allowClear
                options={{
                  values: [],
                  value_key: 'id',
                  text_key: 'name',
                }}
                disabled={true}
              />
              <PlusCircleOutlined className='extraFromIcon' />
            </div>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={7}>
            <InputComponent
              name='priority'
              required={true}
              label={`${t('Common_Data.Priority')}`}
              type='select'
              showSearch={true}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Priority')}`}
              // initialValue={1}
              tooltip={`${t('Tickets.Priority_Tooltip')}`}
              onFocus={() => {
                dropdownPriority && TicketsStore.getDropdownPriority().then(() => setDropdownPriority(true))
              }}
              onChange={(e) => {
                // if (e === 1 || e === 2 || e === 3 || e === 4) {
                //   setIsdeadlineRoutine(true);
                // } else if (e === 5 || e === 6 || e === 7) {
                //   setIsdeadlineRoutine(false);
                // }
                updatePriorityDays(e)
                checkHolder()
                onChange();
              }}
              options={{
                values:
                  TicketsStore.dropdown_priority ||
                  (editValues?.ticket_priority && [
                    {
                      id: editValues.ticket_priority.id,
                      priority: editValues?.ticket_priority?.priority
                    },
                  ]) ||
                  (cloneValues?.ticket_priority && [
                    {
                      id: cloneValues.ticket_priority.id,
                      priority: cloneValues?.ticket_priority?.priority
                    },
                  ]),
                value_key: 'id',
                text_key: 'priority',
              }}
              notFoundContent={dropdownPriority ? <Spin size='small' /> : null}
              rules={vsmTicket.validation.priority}
            />
          </Col>

          <Col span={5}>
            <InputComponent label={`Time Required`} type='labelOnly' />
            <Input.Group>
              <Form.Item name={'hours_required_hour'} noStyle>
                <Input
                  name='hours_required_hour'
                  type='number'
                  suffix="hh"
                  onChange={(e) => { onChange() }}
                  className="w-50 noAppearance"
                  defaultValue={'0'}
                  min="0"
                  max='9999'
                  placeholder='0'
                />
              </Form.Item>
              <Form.Item name={'hours_required_min'} noStyle>
                <Input
                  name='hours_required_min'
                  type='number'
                  suffix="mm"
                  onChange={(e) => { onChange() }}
                  className="w-50 noAppearance"
                  defaultValue={'0'}
                  min="0"
                  max='59'
                  placeholder='0'
                />
              </Form.Item>
            </Input.Group>
          </Col>

          <Col span={5}>
            <InputComponent
              name='deadline_date'
              label={`${t('LeadKanbanView.DeadLine_Date')}`}
              //required
              type='date'
              // format={'YYYY-MM-DD HH:mm'}
              showTime={true}
              // initialValue={moment()}
              // disabledDate={priorityDate}
              placeholder={`${t('LeadKanbanView.DeadLine_Date')}`}
              tooltip={`${t('Tickets.Deadline_Tooltip')}.`}
              onChange={() => { (editValues || cloneValues) && onChangeDeadline(); onChange(); }}
              // rules={
              //   isdeadlineRoutine //|| isdeadlinetime
              //     ? vsmTicket.validation.deadline_date
              //     : [{ required: false }]
              // }
              onClear={onChangeDeadline}
            />
            {smallDeadlineDate && <div class="ant-form-item-explain ant-form-item-explain-error">
              <div role="alert" style={{ fontSize: "1.2rem", color: '#ff4d4f', marginTop: '-6px' }}>Deadline is invalid.</div>
            </div>}
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12}>
            <InputComponent
              className='p-0 m-0 titleCheckbox'
              name='organisation_id'
              label={
                <>
                  <Form.Item
                    lable=''
                    name='is_org'
                    valuePropName='checked'
                    // initialValue={editValues?.organisation?.id ? true : isOrg}
                    className='p-0 m-0 titleCheckbox'
                  >
                    <Checkbox
                      // checked={isOrg}
                      onChange={(e) => {
                        setOrgTicket(e.target.checked === true ? true : false);
                        onChange();
                        if (!e.target.checked) {
                          form.setFieldsValue({ organisation_id: null })
                        }
                        else if (addTicket) {
                          form.setFieldsValue({ organisation_id: "Credebt Exchange" })
                        }
                        else if (editValues) {
                          form.setFieldsValue({ organisation_id: editValues?.organisation?.id ? editValues?.organisation?.id : "Credebt Exchange" })
                        }
                        else if (cloneValues) {
                          form.setFieldsValue({ organisation_id: cloneValues?.organisation?.id ? cloneValues?.organisation?.id : "Credebt Exchange" })
                        }
                      }}
                      style={{ fontSize: '1.2rem', padding: '0', margin: '0' }}
                    >
                      {t('Common_Data.Organisation')}
                    </Checkbox>
                  </Form.Item>
                </>
              }
              allowClear
              onClear={() => {
                setOrgTicket(false)
                form.setFieldsValue({ is_org: false })
              }}
              type='select'
              showSearch={true}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')}`}
              // tooltip='Organisation from which the ticket belongs to.'
              onChange={onChange}
              onFocus={() =>
                fetchOrgList && getMappedOrg().then(() => setFetchOrgList(false))
              }
              // defaultValue={isOrg ? 'Credebt Exchange' : ""}
              // disabled={isOrgTicket ? false : true}
              disabled={
                !isOrgTicket ? true : false
              }
              options={{
                values:
                  dropdown_global_org ||
                  (editValues?.organisation && [
                    {
                      id: editValues?.organisation?.id,
                      organisation_name: editValues?.organisation?.organisation_name,
                    },
                  ]) ||
                  (cloneValues?.organisation && [
                    {
                      id: cloneValues?.organisation?.id,
                      organisation_name: cloneValues?.organisation?.organisation_name,
                    },
                  ]),
                value_key: 'id',
                text_key: 'organisation_name',
              }}
              notFoundContent={fetchOrgList ? <Spin size='small' /> : null}
              rules={!isOrgTicket ? [{ required: false }] : vsmTicket.validation.organisation_id}
            />
          </Col>


          <Col span={5}>
            {updateHolder
              ? <InputComponent
                name='holder'
                label={`${t('Common_Data.Holder')}`}
                type='select'
                showSearch={true}
                allowClear={true}
                required={false}
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.User')}`}
                //tooltip='Holder may be the Deal Owner, or it can be another user.'
                onChange={() => { checkHolder(); onChange(); }}
                onFocus={() =>
                  fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
                }
                validateStatus={'warning'}
                help={''}
                options={{
                  values:
                    dropdown_user_list ||
                    (editValues?.ticket_holder && [
                      {
                        id: editValues.ticket_holder.id,
                        name:
                          editValues?.ticket_holder?.first_name +
                          ' ' +
                          editValues?.ticket_holder?.last_name,
                      },
                    ]) ||
                    (cloneValues?.ticket_holder && [
                      {
                        id: cloneValues.ticket_holder.id,
                        name:
                          cloneValues?.ticket_holder?.first_name +
                          ' ' +
                          cloneValues?.ticket_holder?.last_name,
                      },
                    ]),
                  value_key: 'id',
                  text_key: 'name',
                }}
                notFoundContent={fetchUsersList ? <Spin size='small' /> : null}
              />
              : <InputComponent
                name='holder'
                label={`${t('Common_Data.Holder')}`}
                type='select'
                showSearch={true}
                allowClear={false}
                required={true}
                rules={vsmTicket.validation.holder}
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.User')}`}
                //tooltip='Holder may be the Deal Owner, or it can be another user.'
                onChange={() => { checkHolder(); onChange(); }}
                onFocus={() =>
                  fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
                }
                validateStatus={checkHolderRule === true ? 'error' : ''}
                help={checkHolderRule === true ? t('Messages.vsmTicket_validation_holder_required') : ""}
                options={{
                  values:
                    dropdown_user_list ||
                    (editValues?.ticket_holder && [
                      {
                        id: editValues.ticket_holder.id,
                        name:
                          editValues?.ticket_holder?.first_name +
                          ' ' +
                          editValues?.ticket_holder?.last_name,
                      },
                    ]) ||
                    (cloneValues?.ticket_holder && [
                      {
                        id: cloneValues.ticket_holder.id,
                        name:
                          cloneValues?.ticket_holder?.first_name +
                          ' ' +
                          cloneValues?.ticket_holder?.last_name,
                      },
                    ]),
                  value_key: 'id',
                  text_key: 'name',
                }}
                notFoundContent={fetchUsersList ? <Spin size='small' /> : null}
              />
            }
          </Col>
        </Row>
      </Form>
    );
  }
);

export default FormComponent;
