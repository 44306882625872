import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Typography, Spin, Checkbox, Tooltip } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { vsmUsers } from '../../../config/messages';

import useStore from '../../../store';
import { useTranslation } from 'react-i18next';
import { lang_trans_arr } from '../../../utils/GlobalFunction';
import i18n from '../../../i18n';
import { QuestionCircleOutlined } from '@ant-design/icons';
const SettingsFormComponent = observer(({ handleSubmit, onChange, form, id }) => {
  const {
    USERS,
    USERS: {
      usersetting_duration_list,
      getDurationvalues,
      getReminderHourvalues,
      getReminderMinutevalues,
      dropdown_reminder_hour_list,
      dropdown_reminder_minute_list,
    },
  } = useStore();


  // Language Translation Change Event 

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
  }

  const { Title } = Typography;
  const [fetchTimeZoneList, setfetchTimeZoneList] = useState(true);
  const { t } = useTranslation()
  var usersetting_timezone_list = USERS.usersetting_timezone_list;

  const onFinish = (data) => {
    handleSubmit(data);
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      className='innerForm'
      id={id}
      onFinish={onFinish}
    >
      <Form.List name='dm_settings'>
        {(fields) => (
          <>
            <Title level={3} className='page-title'>
              {t('Common_Data.Date')} & {t('Common_Data.Time')}
            </Title>
            <Row gutter={15}>
              <Col span={8}>
                <InputComponent
                  name='timezone'
                  label={`${t('Users.Timezone')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Timezone')}`}
                  type='select'
                  tooltip={`${t('Users.Timezone_Tooltip')}.`}
                  onFocus={() =>
                    fetchTimeZoneList &&
                    USERS.getFetchTimezone().then(() => setfetchTimeZoneList(false))
                  }
                  options={{
                    values: usersetting_timezone_list,
                    value_key: 'id',
                    text_key: 'timezone_new',
                  }}
                  notFoundContent={
                    fetchTimeZoneList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                  }
                  onChange={onChange}
                  rules={vsmUsers.validation.timezone}
                />
              </Col>
              <Col sm={{ span: 4 }}>
                <InputComponent
                  name={['language']}
                  label={t('Common_Data.Language')}
                  required
                  type='select'
                  tooltip={t('Common_Data.Language')}
                  options={{
                    values: lang_trans_arr,
                    value_key: 'id',
                    text_key: 'language',
                  }}
                  onChange={(e) => {
                    onChange();
                    handleLanguageChange(e);
                  }}
                  rules={vsmUsers.validation.language}
                />
              </Col>
              <Col sm={{ span: 6 }}>
                <InputComponent
                  name='date_format'
                  label={`${t('Common_Data.Date')} ${t('Common_Data.Format')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Time')} ${t('Common_Data.Format')}`}
                  type='select'
                  tooltip={`${t('Users.Time_Format_Tooltip')}.`}
                  options={{
                    values: [
                      {
                        value: 'YYYY-MM-DD',
                        text: 'YYYY-MM-DD',
                      },
                      {
                        value: 'MM-DD-YYYY',
                        text: 'MM-DD-YYYY',
                      },
                      {
                        value: 'DD-MM-YYYY',
                        text: 'DD-MM-YYYY',
                      },
                    ],
                  }}
                  onChange={onChange}
                  rules={vsmUsers.validation.date_format}
                />
              </Col>
              <Col sm={{ span: 6 }}>
                <InputComponent
                  name='time_format'
                  label={`${t('Common_Data.Time')} ${t('Common_Data.Format')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Time')} ${t('Common_Data.Format')}`}
                  type='select'
                  tooltip={`${t('Users.Time_Format')}.`}
                  options={{
                    values: [
                      {
                        value: 'HH:mm',
                        text: `24-${t('Settings.Hours')} (HH:mm)`,
                      },
                      {
                        value: 'hh:mm a',
                        text: `12-${t('Settings.Hours')} (hh:mm a)`,
                      },
                    ],
                  }}
                  onChange={onChange}
                  rules={vsmUsers.validation.time_format}
                />
              </Col>
            </Row>

            <Title level={3} className='page-title'>
              {t('Users.Snap')} {t('Common_Data.Note')} {t('Common_Data.Hourglass')}
            </Title>
            <Row gutter={15}>
              <Col span={8}>
                <InputComponent
                  name='organization_hourglass'
                  label={`${t('Common_Data.Organisation')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Duration_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.organization_hourglass}
                />
              </Col>
              <Col span={8}>
                <InputComponent
                  name='people_hourglass'
                  label={`${t('Common_Data.People')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.People_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.people_hourglass}
                />
              </Col>
              <Col span={8}>
                <InputComponent
                  name='deal_hourglass'
                  label={`${t('Common_Data.Deal')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Deal_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_deals_hourglass}
                />
              </Col>
              <Col span={8}>
                <InputComponent
                  name='ticket_hourglass'
                  label={`${t('Common_Data.Ticket')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Ticket_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.ticket_hourglass}
                />
              </Col>
              <Col span={8}>
                <InputComponent
                  name='note_hourglass'
                  label={`${t('Common_Data.Note')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Note_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.note_hourglass}
                />
              </Col>
            </Row>

            <Title level={3} className='page-title'>
              {t('Common_Data.Screen')} {t('Common_Data.Hour_Glass')}
            </Title>
            <Row gutter={15}>
              <Col span={8}>
                <InputComponent
                  name='screen_organization_hourglass'
                  label={`${t('Common_Data.Organisation')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Organisation_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_organization_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='screen_people_hourglass'
                  label={`${t('Common_Data.People')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Screen_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_people_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='screen_unused_leads_hourglass'
                  label={`${t('Common_Data.Unused')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Unused_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_unused_leads_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='screen_used_leads_hourglass'
                  label={`${t('Users.Used')} ${t('LeadListing.Leads')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Used_Leads')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screenhour_usedleads}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='screen_opportunity_hourglass'
                  label={`${t('Common_Data.Opportunity')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Opportunities_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_opportunity_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='screen_deals_hourglass'
                  label={`${t('LeadListing.Deals')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Deals_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_deals_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='screen_tags_hourglass'
                  label={`${t('Common_Data.Tags')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Tags_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_tags_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='screen_ticket_hourglass'
                  label={`${t('Common_Data.Tickets')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Users.Duration')}`}
                  initialValue='60 minutes'
                  type='select'
                  tooltip={`${t('Users.Tickets_Tooltip')}.`}
                  onChange={onChange}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  rules={vsmUsers.validation.screen_ticket_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name={['screen_reminder_hourglass']}
                  label={`${t('Common_Data.Reminder')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                  type='select'
                  tooltip={`${t('Settings.Reminder_Tooltip')}.`}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  onChange={onChange}
                  rules={vsmUsers.validation.screen_reminder_hourglass}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name={['screen_note_hourglass']}
                  label={`${t('LeadListing.Notes')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                  type='select'
                  tooltip={`${t('Settings.Notes_Tooltip')}.`}
                  onFocus={getDurationvalues}
                  options={{
                    values: usersetting_duration_list,
                  }}
                  onChange={onChange}
                  rules={vsmUsers.validation.screen_note_hourglass}
                />
              </Col>
            </Row>


            <div class='ant-form-item-label'>
              <label class='ant-form-item-required' title='First Name'>
                <Title level={3} className='page-title'>
                  {t('Common_Data.Settings')} {t('Common_Data.For')} {t('Common_Data.Reminders')} {t('Settings.Calling')}
                </Title>
              </label>
            </div>

            <Row gutter={12}>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name={['reminder_due_days']}
                  label={`${t('Common_Data.Reminder')} ${t('Common_Data.Days')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Common_Data.Days')}`}
                  type='select'
                  tooltip={`${t('Settings.Organisation_Tooltip')}.`}
                  options={{
                    values: [
                      {
                        text: `1 Day`,
                        value: 1,
                      },
                      {
                        text: `2 Days`,
                        value: 2,
                      },
                      {
                        text: `3 Days`,
                        value: 3,
                      },
                      {
                        text: `4 Days`,
                        value: 4,
                      },
                      {
                        text: `5 Days`,
                        value: 5,
                      },
                      {
                        text: `6 Days`,
                        value: 6,
                      },
                      {
                        text: `7 Days`,
                        value: 7,
                      },
                      {
                        text: `8 Days`,
                        value: 8,
                      },
                      {
                        text: `9 Days`,
                        value: 9,
                      },
                      {
                        text: `10 Days`,
                        value: 10,
                      },
                    ],
                  }}
                  onChange={onChange}
                  rules={vsmUsers.validation.reminder_due_days}
                />
              </Col>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name={['reminder_due_hours']}
                  label={`${t('Common_Data.Reminder')} ${t('Common_Data.Hours')}`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Common_Data.Hours')}`}
                  type='select'
                  tooltip={`${t('Settings.Organisation_Tooltip')}.`}
                  onFocus={getReminderHourvalues}
                  options={{
                    values: dropdown_reminder_hour_list,
                  }}
                  onChange={onChange}
                  rules={vsmUsers.validation.reminder_due_hours}
                />
              </Col>

              <Col sm={{ span: 8 }}>
                <InputComponent
                  name={['reminder_due_mins']}
                  label={`${t('Common_Data.Reminder')} ${t('Common_Data.Minute')}s`}
                  required
                  placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Common_Data.Minute')}s`}
                  type='select'
                  tooltip={`${t('Settings.Organisation_Tooltip')}.`}
                  onFocus={getReminderMinutevalues}
                  options={{
                    values: dropdown_reminder_minute_list,
                  }}
                  onChange={onChange}
                  rules={vsmUsers.validation.reminder_due_mins}
                />
              </Col>
            </Row>

            <div class='ant-form-item-label'>
              <label class='ant-form-item-required' title='First Name'>
                <Title level={3} className='page-title'>
                  {t('Users.Snap')} {t('Common_Data.Note')} {t('Common_Data.Send')} {t('Users.Options')}
                </Title>
              </label>
            </div>

            <Row gutter={12}>
              <Col sm={{ span: 8 }}>
                <InputComponent
                  name='snap_note_send_option'
                  placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Time')}`}
                  type='time'
                  // use12Hours
                  // format='h:mm a'
                  tooltip={`${t('Users.Send_Option_Tooltip')}.`}
                  onChange={onChange}
                  rules={vsmUsers.validation.snap_note_send_option}
                />
              </Col>
            </Row>
            {/* Notifcation Settings  */}

            <div className='ant-form-item-label'>
              <label
                className='ant-form-item-required'
                title={t('Settings.Notification_Settings')}
              >
                <Title level={3} className='page-title'>
                  {t('Settings.Notification_Settings')}
                  <Tooltip title={t('Settings.Notification_Settings')}>
                    <QuestionCircleOutlined className='ml-5' />
                  </Tooltip>
                </Title>
              </label>
            </div>
            <Row gutter={15} className='mb-5'>
              <Col xs={{ span: 3 }}>
                <strong>{t('Settings.Settings_For')}</strong>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <strong>{t('Settings.Alerts')}</strong>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <strong>{t('Common_Data.Emails')}</strong>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs={{ span: 3 }}>
                <div className='ant-form-item-label mb-0'>
                  <label className='mb-0' title={t('Settings.Tag_Assignments')}>
                    {t('Settings.Tag_Assignments')}
                  </label>
                </div>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['tag_push_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['tag_email_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs={{ span: 3 }}>
                <div className='ant-form-item-label mb-0'>
                  <label className='mb-0' title={t('Settings.Tickets_Assignments')}>
                    {t('Settings.Tickets_Assignments')}
                  </label>
                </div>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['tickets_push_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['tickets_email_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs={{ span: 3 }}>
                <div className='ant-form-item-label mb-0'>
                  <label className='mb-0' title={t('Settings.SOLD_Assignments')}>
                    {t('Settings.SOLD_Assignments')}
                  </label>
                </div>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['sold_push_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['sold_email_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col xs={{ span: 3 }}>
                <div className='ant-form-item-label mb-0'>
                  <label className='mb-0' title={t('Common_Data.Reminders')}>
                    {t('Common_Data.Reminders')}
                  </label>
                </div>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['reminders_push_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 2 }} className='text-center'>
                <Form.Item
                  noStyle
                  name={['reminders_email_notifications']}
                  valuePropName='checked'
                >
                  <Checkbox
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </Form>
  );
});

export default SettingsFormComponent;
