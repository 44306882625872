import React, { useState } from 'react';
import { Popover, Tooltip, Spin, Button } from 'antd';
import useStore from '../../../store';
import ReassignConfirmComponent from './ReassignConfirmComponent';
import DateTimeConfirmComponent from '../../Tickets/component/DateTimeConfirmComponent';
import HourglassConfirmComponent from '../../Tickets/component/HourglassConfirmComponent';
import TagDateTimeConfirmComponent from '../../Tags/component/DateTimeConfirmComponent';
import TagHourglassConfirmComponent from '../../Tags/component/HourglassConfirmComponent';
import { observer } from 'mobx-react';
import { vsmNotify, vsmProductivity } from '../../../config/messages';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';

export const ActionRenderer = (props) => {
  let history = useHistory();
  const [reAssignUser, setReAssignUser] = useState(null);
  const [reAssingTempData, setReAssingTempData] = useState(null);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [openTagDateTimeModal, setOpenTagDateTimeModal] = useState(false);
  const [openTagHourglassModal, setOpenTagHourglassModal] = useState(false);
  const [reminderConfirm, setReminderConfirm] = useState();
  const [hourglassConfirm, setHourglassConfirm] = useState();
  const [date, setDate] = useState();
  const [extrafooter, setExtrafooter] = useState(null);

  const {
    AUTH,
    TicketsStore: { getUsersList },
    REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();

  const { t } = useTranslation();
  //Confirm Modal
  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(data);
      if (data.type === 'ticket') {
        setOpenDateTimeModal(true);
      } else {
        setOpenTagDateTimeModal(true);
      }
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  const closeTagDateTimeModal = () => setOpenTagDateTimeModal(false);

  const openConfirmHourglassModal = (data) => {
    setHourglassConfirm(data);
    if (data.type === 'ticket') {
      setOpenHourglassModal(true);
    } else {
      setOpenTagHourglassModal(true);
    }
  };
  const closeHourglassModal = () => setOpenHourglassModal(false);

  const closeTagHourglassModal = () => setOpenTagHourglassModal(false);

  const openConfirmReassignModal = (id, username, tickettagData) => {
    let tempReAssign = {
      userid: id,
      username: username,
      tickettagData: tickettagData,
    };
    setReAssingTempData(tempReAssign);
    setOpenReassignModal(true);
  };
  const closeReassignModal = () => setOpenReassignModal(false);

  const userList = (
    <div className='ticketsPopoverIcons username'>
      <ul>
        {reAssignUser &&
          reAssignUser.map(function (item) {
            return (
              <li
                key={item.id}
                onClick={() => openConfirmReassignModal(item.id, item.name, props.data)}
              >
                {item.name}
              </li>
            );
          })}
      </ul>
    </div>
  );

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // };
  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  return (
    <>
      <div className='action-column promotionAction'>
        {AUTH.checkPermission(6, 'reassign') && (
          // (props?.data?.owner?.id === AUTH?.user?.id) ? 
          <Popover
            content={userList}
            placement='bottomRight'
            trigger='hover'
            overlayClassName='profileMenu'
          >
            <img
              className='headerIcon'
              src={AUTH.GetThemedImage('Reassign')}
              alt={`${t('ManageAllDepartments.Re_Assign')}`}
              title={`${t('Common_Data.Reassign')}`}
              onMouseEnter={() => {
                if (!reAssignUser) {
                  getUsersList().then((data) => {
                    setReAssignUser(data.data);
                  });
                }
              }}
            />
          </Popover>
          // : <Tooltip placement='topRight' color={'red'} title={`${t('Tickets.Reassign_Disable')}.`}>
          //   <img className='headerIcon' src={AUTH.GetThemedImage('Reassign')} alt={`${t('ManageAllDepartments.Re_Assign')}`} title={`${t('Common_Data.Reassign')}`} />
          // </Tooltip>
        )}
        {AUTH.checkPermission(15, 'ticket-desk') && (
          <Button type='text' title={`${t('ManageAllDepartments.Ticket')} ${t('Common_Data.Desk')}`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('Tickets')}
              onClick={() => {
                if (props.data?.ticket_number) {
                  history.push(`/ticket/${props.data.ticket_number}`);
                } else {
                  history.push(`/ticket/${props.data.leads_transactions_id}`);
                }
              }}
            />
          </Button>
        )}

        {AUTH.checkPermission(6, 'reset-reminder-date-time') && (
          // (props?.data?.owner?.id === AUTH?.user?.id) ?
          <span
            className='Calendar'
            onMouseEnter={() => {
              if (props.data?.reset_count !== null) {
                getReminderDatepickerExtraFooter(
                  props.data,
                  AUTH.global_fulldate_format,
                  { type: props.data.type === 'ticket' ? 'tickets' : 'tags' }
                ).then((data) => {
                  setExtrafooter(data);
                });
              }
            }}
          >
            <Tooltip
              title={props.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`}
            >
              <DatePickerCostume
                name='Calendar'
                title={`${t('Common_Data.Calendar')}`}
                className='cursor'
                showTime
                disabledDate={true}
                // disabledHours={() => disabledHours()}
                //value={moment(AUTH.getLocalTimeDealDesk(new Date()))}
                // minuteStep={15}
                // hideDisabledOptions
                showNow={false}
                onOk={(e) => openConfirmDateTimeModal(e, props.data)}
                renderExtraFooter={() => extrafooter}
              />
            </Tooltip>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Calendar')} />
          </span>
          // :
          // <Tooltip placement='topRight' color={'red'} title={`${t('Tickets.Reminder_Disable')}.`}>
          //   <img className='menuicon' alt='' src={AUTH.GetThemedImage('Calendar')} />
          // </Tooltip>
        )}
        {(AUTH.checkPermission(6, 'reset-reminder-time') &&
          // (props?.data?.owner?.id === AUTH?.user?.id)) ? 
          <> <img
            className='menuicon'
            title={`${t('ManageAllDepartments.Hour_Glass')}`}
            alt={`${t('ManageAllDepartments.Hour_Glass')}`}
            src={AUTH.GetThemedImage('Reminders_icn')}
            onClick={() => openConfirmHourglassModal(props.data)}
          />
            <span>{props.data?.reset_count ? props.data?.reset_count : 0}</span>
          </>
          // : <Tooltip placement='topRight' color={'red'} title={`${t('Tickets.Hour_Glass_Disable')}.`}>
          //   <img className='menuicon'
          //     title={`${t('ManageAllDepartments.Hour_Glass')}`}
          //     alt={`${t('ManageAllDepartments.Hour_Glass')}`}
          //     src={AUTH.GetThemedImage('Reminders_icn')}
          //   />
          // </Tooltip>
        )}

        <ReassignConfirmComponent
          visible={openReassignModal}
          close={closeReassignModal}
          reAssingTempData={reAssingTempData}
        />
      </div>
      <DateTimeConfirmComponent
        visible={openDateTimeModal}
        close={closeDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <HourglassConfirmComponent
        visible={openHourglassModal}
        close={closeHourglassModal}
        hourglassConfirm={hourglassConfirm}
      />
      <TagDateTimeConfirmComponent
        visible={openTagDateTimeModal}
        close={closeTagDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <TagHourglassConfirmComponent
        visible={openTagHourglassModal}
        close={closeTagHourglassModal}
        hourglassConfirm={hourglassConfirm}
      />
    </>
  );
};

export const TypeRenderer = (props) => {
  const { t } = useTranslation();
  const { AUTH } = useStore();
  return (
    <div className='action-column TypeRenderer'>
      <span className='ml-5'>
        {props?.data?.type.toLowerCase() === 'ticket' && (
          <img
            className='headerIcon'
            src={AUTH.GetThemedImage('Tickets')}
            alt={`${t('Common_Data.Tickets')}`}
            title={`${t('Common_Data.Tickets')}`}
          />
        )}

        {props?.data?.type.toLowerCase() === 'tag' && (
          <img
            className='headerIcon'
            src={AUTH.GetThemedImage('Tags')}
            alt={`${t('ManageAllDepartments.Tags')}`}
            title={`${t('ManageAllDepartments.Tags')}`}
          />
        )}
      </span>
    </div>
  );
};

export const StatusRenderer = observer((props) => {
  const { t } = useTranslation();

  const {
    TicketsStore: { dropdown_ticketstatus },
    TagsStore: { statusArr_list },
    MANAGEMENTALLDEPARTMENTSSTORE: { tickettagStatusUpdate },
    AUTH,
  } = useStore();

  // let OwnerIsLoggin = true // props?.data?.owner?.id === AUTH?.user?.id
  // let HolderIsLoggin = true // props?.data?.holder_id === AUTH?.user?.id

  const handleChangeStatusTicketTag = (value) => {
    if (AUTH.checkPermission(3, 'change-status')) {
      let payload = {
        type: props.data.type,
        id: props.data.id,
        status_id: value,
      };
      tickettagStatusUpdate(payload).then((data) => {
        vsmNotify.success({ message: vsmProductivity.successChangeStatus });
      });
    }
  };

  const changeStatusOptins = (
    <div className='ant-popover-inner-content'>
      <div className='productivityListing'>
        {props?.data?.type.toLowerCase() === 'ticket' ? (
          <ul style={{ width: '130px' }}>
            {dropdown_ticketstatus ? (
              dropdown_ticketstatus.map(function (item, index) {
                return (
                  <li key={item.id + index} onClick={() => handleChangeStatusTicketTag(item.id)}>
                    {item.status_name}
                  </li>
                );
              })
            ) : (
              <Spin />
            )}
          </ul>
        ) : (
          <ul>
            {statusArr_list ? (
              statusArr_list.map(function (item, index) {
                return (
                  <li
                    key={item.value}
                    onClick={() => handleChangeStatusTicketTag(item.value)}
                  >
                    {item.text}
                  </li>
                );
              })
            ) : (
              <Spin />
            )}
          </ul>
        )}
      </div>
    </div>
  );

  let defaultSelection = null;
  if (props?.data?.type.toLowerCase() === 'ticket') {
    let isStatusCheck = (e) => e.id === parseInt(props?.data?.status);
    let defaultSelectedValueIndex = dropdown_ticketstatus.findIndex(isStatusCheck);
    if (defaultSelectedValueIndex >= 0) {
      defaultSelection = dropdown_ticketstatus[defaultSelectedValueIndex];
    }
  }
  if (props?.data?.type.toLowerCase() === 'tag') {
    let isStatusCheck = (e) => e.value === parseInt(props?.data?.status);

    let defaultSelectedValueIndex = statusArr_list.findIndex(isStatusCheck);
    defaultSelection = statusArr_list[defaultSelectedValueIndex];
  }

  return (
    <>
      <div className='action-column StatusRenderer text-left'>
        {AUTH.checkPermission(3, 'change-status')
          ? (props?.data?.type.toLowerCase() === 'ticket' && (
            <>
              <Popover
                content={changeStatusOptins}
                placement='bottom'
                trigger='click'
                overlayClassName='profileMenu'
              >
                <img
                  className='menuicon mr-10'
                  alt=''
                  title={`${t('Block_Edit_Assign.Status_Change')}`}
                  src={AUTH.GetThemedImage('StatusChange')}
                />
                {defaultSelection?.status_name}
              </Popover>
            </>
          )) ||
          (props?.data?.type.toLowerCase() === 'tag' && (
            <>
              <Popover
                content={changeStatusOptins}
                placement='bottom'
                trigger='click'
                overlayClassName='profileMenu'
              >
                <img
                  className='menuicon mr-10'
                  alt=''
                  title={`${t('Block_Edit_Assign.Status_Change')}`}
                  src={AUTH.GetThemedImage('StatusChange')}
                />
                {defaultSelection?.text}
              </Popover>
            </>
          ))
          : props.value}
      </div>
    </>
  );
});
