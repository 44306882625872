import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import useStore from '../../store';

import ListComponent from './component/ListComponent';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../Unauthorized';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../component/TimeTracker';

const Contracts = observer(() => {
  const { t } = useTranslation();
  let history = useHistory();
  const { CONTRACTS, AUTH, SETTINGS } = useStore();
  const [addDrawer, setAddDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);

  //open add drawer
  const openAddDrawer = () => {
    setAddDrawer(true);
  };

  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  //open edit drawer
  const openEditDrawer = (data) => {
    CONTRACTS.setEditValues(data);
    setEditDrawer(true);
  };

  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);

  //open delet drawer
  const openDeleteDrawer = (data) => {
    setDeleteDrawer(true);
    CONTRACTS.setDeleteValues(data);
  };

  //close delet drawer
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const openViewDrawer = (data) => {
    history.push(`/people/all-people/view-people/${data.id}`);
  };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (result.key === 'Enter' && AUTH.checkPermission(22, 'edit')) {
            openEditDrawer(e.data);
          }
          if (result.key === 'Delete' && AUTH.checkPermission(22, 'delete')) {
            openDeleteDrawer(e.data);
          }
        }
      }
    }
  };

  return (
    <>
      {!AUTH.checkPermission(22, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('Contracts.Contracts_Title')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            AUTH.checkPermission(22, 'add') && (
              <Button
                className='gridbtn'
                key='2'
                shape='round'
                size='medium'
                onClick={openAddDrawer}
              >
                {t('Contracts.Add_Contract')}
              </Button>
            ),
            <Button
              key='1'
              title={`${t('Common_Data.Reset')}`}
              shape='round'
              size='medium'
              onClick={CONTRACTS.handleReset}
            >
              <FontAwesomeIcon icon={faUndo} />
            </Button>,
            <EnterFullscreen key='3' />,
          ]}
        >
          <ListComponent
            openDeleteDrawer={openDeleteDrawer}
            openEditDrawer={openEditDrawer}
            openViewDrawer={openViewDrawer}
            onCellKeyDown={onCellKeyDown}
          />
          <AddComponent visible={addDrawer} close={closeAddDrawer} />
          <EditComponent visible={editDrawer} close={closeEditDrawer} />
          <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
          {/* <TrackTimeComponent pageName={'contracts'} /> */}
        </PageHeader>
      )}
      <TimeTracker />
    </>
  );
});

export default Contracts;
