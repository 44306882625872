import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../store';
import { useTranslation } from 'react-i18next';

const WasteDeleteComponent = observer((props) => {
  const [form] = Form.useForm();
  const { AUTH } = useStore();
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation();

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    AUTH.deleteWasteData()
      .then(() => {
        close();
      })
      .catch((e) => {
        if (e.errors) { form.setFields(e.errors); }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      key='6'
      centered
      title={`${t('Common_Data.Delete_Waste')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button key='1' form='deleteformWaste' loading={saving} htmlType='submit' type='primary' danger>
          {`${t('Common_Data.Delete')}`}
        </Button>,
        <Button key='2' onClick={close}>
          {`${t('Common_Data.Cancel')}`}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformWaste' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {`${t('Common_Data.Waste_Text_Delete')}`}
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  )
});
export default WasteDeleteComponent;
