import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { vsmCommon } from '../../../config/messages';
import GridConfig from '../../../config/GridConfig';
import {
  Spin,
  Button,
  //DatePicker, Tooltip
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

//import DateTimeConfirmComponent from '../elements/DateTimeConfirmComponent';
import HourglassConfirmComponent from '../elements/HourglassConfirmComponent';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation()
  let history = useHistory();

  const {
    AUTH,
    REMINDERSSTORE,
    SEARCHSTORE,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    //REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();

  useEffect(() => {
    if (props.SearchPage) {
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.reminder_count;
      REMINDERSSTORE.setIsSavedFiltersLoadedRm(true);
    } else {
      REMINDERSSTORE.search_payload = {};
      REMINDERSSTORE.setIsSavedFiltersLoadedRm(true);
    }
  }, [props.SearchPage, REMINDERSSTORE, SEARCHSTORE, REMINDERSSTORE.total]);

  const ActionRenderer = (params) => {
    // const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
    // const [date, setDate] = useState();
    // const [reminderConfirm, setReminderConfirm] = useState();
    // const [extrafooter, setExtrafooter] = useState(null);

    const [hourglassConfirm, setHourglassConfirm] = useState();
    const [openHourglassModal, setOpenHourglassModal] = useState(false);

    // const openConfirmDateTimeModal = (dateformat, data) => {
    //   if (dateformat) {
    //     setDate(dateformat);
    //     setReminderConfirm(data);
    //     setOpenDateTimeModal(true);
    //   }
    // };
    // const closeDateTimeModal = () => {
    //   setDate(null);
    //   setReminderConfirm(null);
    //   setOpenDateTimeModal(false);
    // };

    // const disabledDate = (current) => {
    //   // Can not select days before today and today
    //   return current && current <= moment().subtract(1, 'days').endOf('day');
    // };

    // const disabledHours = (e) => {
    //   let hours = [19, 20, 21, 22, 23];
    //   for (let i = 0; i < 7; i++) {
    //     hours.push(i);
    //   }
    //   return hours;
    // };

    const openConfirmHourglassModal = (data) => {
      setHourglassConfirm(data);
      setOpenHourglassModal(true);
    };
    const closeHourglassModal = () => {
      setHourglassConfirm(null);
      setOpenHourglassModal(false);
    };

    return (
      <>
        <div className='action-column'>
          {/* {params.data?.leads_transactions_id > 0 && ( */}

          {props?.AllSOLDReminder ? null : (
            <Button
              type='text'
              title={`${t('Block_Edit_Assign.Deal_Desk')}`}
              disabled={
                params?.data?.reminder_type === 'private'
                  ? true
                  : params.data?.leads_transactions_id
                    ? false
                    : true
              }
            >
              <img
                className='menuicon'
                alt=''
                src={AUTH.GetThemedImage('Deal_icn')}
                onClick={() => {
                  if (params.data.leads_transactions_id) {
                    let routePath = `/dealdesk/${params.data.leads_transactions_id}`;
                    if (
                      params.data.reminder_type === 'notes' &&
                      params.data?.reminder_for
                    ) {
                      routePath = `/dealdesk/${params.data.leads_transactions_id}/#n${params.data?.reminder_for}`;
                    }
                    if (
                      params.data.reminder_type === 'tags' &&
                      params.data?.leads_notes_id
                    ) {
                      routePath = `/dealdesk/${params.data.leads_transactions_id}/#n${params.data?.leads_notes_id}`;
                    }

                    history.push(routePath);
                  }
                }}
              />
            </Button>
          )}

          {/* )} */}
          {AUTH.checkPermission(46, 'view') && (
            <Button
              type='text'
              title={`${t('Common_Data.View')}`}
              onClick={() => props.openViewDrawer(params.data)}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
            </Button>
          )}

          {AUTH.checkPermission(46, 'edit') &&
            (props?.AllSOLDReminder ? (
              params.data?.receiver_by && params.data.receiver_by?.id === AUTH.user.id ? (
                <Button
                  type='text'
                  title={`${t('Common_Data.Edit')}`}
                  disabled={params.data.status === 'Closed' ? true : false}
                  onClick={() => props.openEditReminder(params.data)}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
                </Button>
              ) : (
                <Button type='text' title={`${t('Common_Data.Edit')}`} disabled={true}>
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
                </Button>
              )
            ) : (
              <Button
                type='text'
                title={`${t('Common_Data.Edit')}`}
                disabled={params.data.status === 'Closed' ? true : false}
                onClick={() => props.openEditReminder(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
              </Button>
            ))}
          {AUTH.checkPermission(46, 'delete') &&
            (props?.AllSOLDReminder ? (
              params.data?.receiver_by && params.data.receiver_by?.id === AUTH.user.id ? (
                <Button
                  type='text'
                  title={`${t('Common_Data.Delete')}`}
                  onClick={() => props.openDeleteReminder(params.data)}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              ) : (
                <Button type='text' title={`${t('Common_Data.Delete')}`} disabled={true}>
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              )
            ) : (
              <Button
                type='text'
                title={`${t('Common_Data.Delete')}`}
                onClick={() => props.openDeleteReminder(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            ))}

          {/* {AUTH.checkPermission(46, 'reset-reminder-date-time') && (
            <span
              style={{ position: 'relative', overflow: 'hidden' }}
              onMouseEnter={() => {
                if (params.data?.reset_count !== null) {
                  getReminderDatepickerExtraFooter(
                    params.data,
                    AUTH.global_fulldate_format,
                    {
                      type: params?.data?.reminder_type,
                    }
                  ).then((data) => {
                    setExtrafooter(data);
                  });
                }
              }}
            >
              <Tooltip
                title={
                  params.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`
                }
              >
                <DatePicker
                  name='Calendar'
                  onOk={(e) => openConfirmDateTimeModal(e, params.data)}
                  showTime={{ format: 'HH:mm' }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 5,
                    opacity: 0,
                  }}
                  disabledDate={disabledDate}
                  disabledHours={() => disabledHours()}
                  minuteStep={15}
                  renderExtraFooter={() => extrafooter}
                  hideDisabledOptions
                  showNow={false}
                />
              </Tooltip>
              <Button type='text' title='Calendar'>
                <img
                  className='menuicon'
                  alt='Calendar'
                  src={AUTH.GetThemedImage('Calendar')}
                  title='Calendar'
                  // onClick={handleCount}
                />
              </Button>
            </span>
          )} */}

          {AUTH.checkPermission(14, 'reset-reminder-time') &&
            (props?.AllSOLDReminder ? (
              params.data?.receiver_by && params.data.receiver_by?.id === AUTH.user.id ? (
                <div>
                  <img
                    style={
                      params.data.status !== 'Closed'
                        ? { margin: 5 }
                        : { margin: 5, cursor: 'not-allowed', opacity: '0.8' }
                    }
                    className='menuicon'
                    title={`${t('Common_Data.Hour_Glass')}`}
                    alt={`${t('Common_Data.Hour_Glass')}`}
                    src={AUTH.GetThemedImage('Reminders_icn')}
                    onClick={() => {
                      if (params.data.status !== 'Closed') {
                        openConfirmHourglassModal(params.data);
                      }
                    }}
                  />

                  <span>{params.data?.reset_count ? params.data?.reset_count : 0}</span>
                </div>
              ) : (
                <div>
                  <img
                    style={{ margin: 5, cursor: 'not-allowed', opacity: '0.8' }}
                    className='menuicon'
                    title={`${t('Common_Data.Hour_Glass')}`}
                    alt={`${t('Common_Data.Hour_Glass')}`}
                    src={AUTH.GetThemedImage('Reminders_icn')}
                  />

                  <span>{params.data?.reset_count ? params.data?.reset_count : 0}</span>
                </div>
              )
            ) : (
              <div>
                <img
                  style={
                    params.data.status !== 'Closed'
                      ? { margin: 5 }
                      : { margin: 5, cursor: 'not-allowed', opacity: '0.8' }
                  }
                  className='menuicon'
                  title={`${t('Common_Data.Hour_Glass')}`}
                  alt={`${t('Common_Data.Hour_Glass')}`}
                  src={AUTH.GetThemedImage('Reminders_icn')}
                  onClick={() => {
                    if (params.data.status !== 'Closed') {
                      openConfirmHourglassModal(params.data);
                    }
                  }}
                />

                <span>{params.data?.reset_count ? params.data?.reset_count : 0}</span>
              </div>
            ))}
        </div>
        {/* <DateTimeConfirmComponent
          visible={openDateTimeModal}
          close={closeDateTimeModal}
          date={date}
          reminderConfirm={reminderConfirm}
        /> */}
        <HourglassConfirmComponent
          visible={openHourglassModal}
          close={closeHourglassModal}
          hourglassConfirm={hourglassConfirm}
        />
      </>
    );
  };

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params?.column?.colId === 'actions') {
      let leads_transactions_id = params.node?.data?.leads_transactions_id;
      let reminder_for = params.node?.data?.reminder_for;
      let leads_notes_id = params.node?.data?.leads_notes_id;
      let routePath = null;

      if (leads_transactions_id) {
        routePath = `/dealdesk/${leads_transactions_id}`;
        if (params?.node.data.reminder_type === 'notes' && reminder_for) {
          routePath = `/dealdesk/${leads_transactions_id}/#n${reminder_for}`;
        }
        if (params?.node?.data.reminder_type === 'tags' && leads_notes_id) {
          routePath = `/dealdesk/${leads_transactions_id}/#n${leads_notes_id}`;
        }
      } else {
        routePath = '/reminders';
      }

      result = [
        {
          // custom item
          name: 'Open link in new tab',
          action: function () {
            window.open(routePath, '_blank');
          },
        },
        {
          // custom item
          name: 'Open link in new window',
          action: function () {
            window.open(
              routePath,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  const gridOptions = {
    columnDefs: [
      // {
      //   headerName: 'Record Type',
      //   field: 'event_record_type',
      //   cellClass: 'cellClass',
      // },
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`,
        headerTooltip: `${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`,
        field: 'reminder_type',
        tooltipValueGetter: (params) =>
          params?.data?.reminder_type &&
          REMINDERSSTORE.remindersTypeValue[params?.data?.reminder_type],
        valueGetter: (params) =>
          params?.data?.reminder_type &&
          REMINDERSSTORE.remindersTypeValue[params?.data?.reminder_type],
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([
              ...REMINDERSSTORE.remindersTypeValueForFilter.map((x) => x.name),
            ]);
          },
        },
      },
      {
        headerName: `${t('Common_Data.Priority')}`,
        field: 'priority_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([...REMINDERSSTORE.priorityForFilter.map((x) => x.text)]);
          },
        },
      },
      {
        headerName: `${t('Common_Data.Reminder')} ${t('Common_Data.Subject')}`,
        field: 'subject',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Status')}`,
        headerTooltip: `${t('Common_Data.Status')}`,
        field: 'status',
        tooltipField: 'status',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([...REMINDERSSTORE.statuslistForFilter.map((x) => x.text)]);
          },
        },
      },

      {
        headerName: `${t('Reminders.Due')} ${t('Common_Data.Date')}`,
        field: 'due_date_time',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.due_date_time
            ? moment(params?.data?.due_date_time).format(AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Reminder')} ${t('Reminders.For')}`,
        field: 'reminder_for_name',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,
        field: 'holder',
        tooltipField: 'holder',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.receiver_by
            ? params?.data?.receiver_by?.first_name +
            ' ' +
            params?.data?.receiver_by?.last_name
            : '';
        },
        filter: 'agSetColumnFilter',

        valueFormatter: (params) =>
          formatterSetColumnFilter(
            REMINDERSSTORE.dropdown_user_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            REMINDERSSTORE.getUsersList().then((data) => {
              params.success([...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              REMINDERSSTORE.dropdown_user_list,
              params,
              'id',
              'name'
            ),
        },
      },

      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            REMINDERSSTORE.dropdown_user_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            REMINDERSSTORE.getUsersList().then((data) => {
              params.success([...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              REMINDERSSTORE.dropdown_user_list,
              params,
              'id',
              'name'
            ),
        },
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.At')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.created_at
            ? moment(params?.data?.created_at).format(AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        cellClass: 'cellClass',
        filter: 'agNumberColumnFilter',
        width: 90,
        sortable: true,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        width: 200,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('reminder_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['reminder_type'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }
    return item;
  });

  return (
    <>
      <div
        className={`ag-theme-alpine grid_wrapper ${props.SearchPage ? "paginationAtTop" : ""}`}
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : props.SearchPage && !props.AllView
              ? 'calc(100vh - 13rem)'
              : props.SearchPage && props.AllView
                ? '150px'
                : 'calc(100vh - 13rem)',
          minHeight: props.AllView ? '100px' : '250px',
        }}
      >
        {REMINDERSSTORE.list_data ? (
          <AgGridReact
            rowData={REMINDERSSTORE.list_data}
            modules={AllModules}
            columnDefs={withTooltipCols}
            defaultColDef={{
              ...GridConfig.defaultColDef,
              width: 120,
              minWidth: 70,
              flex: 1,
              // floatingFilter: !SearchPage,
              sortable: true,

            }}
            columnTypes={GridConfig.columnTypes}
            overlayNoRowsTemplate={vsmCommon.noRecord}
            frameworkComponents={{ ActionRenderer }}
            onGridReady={REMINDERSSTORE.setupGrid}
            onColumnResized={REMINDERSSTORE.onGridChanged}
            onColumnMoved={REMINDERSSTORE.onGridChanged}
            onColumnPinned={REMINDERSSTORE.onGridChanged}
            gridOptions={{
              ...GridConfig.options,
              rowHeight: 30,
              pagination: true,
              paginationPageSize: 100,
              getContextMenuItems: getContextMenuItems,
              onRowDoubleClicked: (event) => props.openViewDrawer(event?.data),
            }}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            onCellKeyDown={props.onCellKeyDown}
            onFilterChanged={(e) => {
              if (
                e.hasOwnProperty('afterFloatingFilter') &&
                REMINDERSSTORE.selectedGridFilter
              ) {
                REMINDERSSTORE.setSelectedGridFilter(null);
              }
            }}
          />
        ) : (
          <div className='spinCenter' style={{ paddingTop: '20rem' }}>
            <Spin tip={`${t('Common_Data.Load')}...`} />
          </div>
        )}
      </div>
    </>
  );
});
export default ListComponent;
