import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Popover, Spin, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import useStore from '../../../store';
import { vsmCommon, vsmNotify, vsmQuickNoteUpdate } from '../../../config/messages';
import GridConfig from '../../../config/GridConfig';
import PhoneNumberRender from '../elements/PhoneNumberRender';
import EmailCellRender from '../elements/EmailCellRender';
import ReassignConfirmElements from '../elements/ReassignConfirmElements';
import moment from 'moment';
import NoteRenderer from '../../Promotion/elements/NoteRenderer';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const {
    AUTH,
    LEADSSTORE,
    LEADSSTORE: { onGridChanged },
    SEARCHSTORE,
    LEADSSTORE: { getReassignUserList },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    PROMOTIONSTORE,
  } = useStore();

  const [quickNoteList, setQuickNoteList] = useState();

  const { openRestoreModal, WasteManagement, SearchPage, onCellKeyDown } = props;

  useEffect(() => {
    if (WasteManagement) {
      LEADSSTORE.waste = true;
      LEADSSTORE.search_payload = {};
    } else if (SearchPage) {
      LEADSSTORE.waste = false;
      SEARCHSTORE.resultCount = LEADSSTORE.list_data.length;
    } else {
      LEADSSTORE.waste = false;
      LEADSSTORE.search_payload = {};
    }
  }, [LEADSSTORE, SEARCHSTORE, SearchPage, WasteManagement, LEADSSTORE.list_data]);

  useEffect(() => {
    if (!quickNoteList) {
      PROMOTIONSTORE.getQuickNoteList().then((data) => {
        setQuickNoteList(data.data.quick_notes);
      });
    }
  }, [PROMOTIONSTORE, quickNoteList]);

  const handleSubmitQN = (id, data) => {
    let formdata = {
      notes_flag: 1,
      notes_description: data,
    };
    PROMOTIONSTORE.updateQuickNote(id, formdata).then(() => {
      vsmNotify.success({
        message: vsmQuickNoteUpdate.success,
      });
    });
  };

  const ActionRenderer = (params) => {
    // const [count, setCount] = useState(0);
    const [openReassignModal, setOpenReassignModal] = useState(false);
    const [reAssignUser, setReAssignUser] = useState(null);
    const [reAssingTempData, setReAssingTempData] = useState(null);

    const openConfirmReassignModal = (userid, username, leadid) => {
      let tempReAssign = {
        userid: userid,
        username: username,
        leadid: leadid,
      };
      setReAssingTempData(tempReAssign);
      setOpenReassignModal(true);
    };
    const closeReassignModal = () => {
      setOpenReassignModal(false);
    };

    const userList = (
      <div className='ticketsPopoverIcons username'>
        <ul>
          {reAssignUser &&
            reAssignUser.map(function (item) {
              return (
                <li
                  key={item.id}
                  onClick={() =>
                    openConfirmReassignModal(item.id, item.name, params.data.id)
                  }
                >
                  {item.name}
                </li>
              );
            })}
        </ul>
      </div>
    );

    const { quickNoteList, handleSubmitQN } =
      params.agGridReact.props.frameworkComponents;

    const contentP = (
      <div className='QuicknoteList'>
        <ul>
          {quickNoteList ? (
            quickNoteList.map(function (item, index) {
              return (
                <li
                  key={index}
                  title={item.quick_note_text}
                  onClick={() => {
                    handleSubmitQN(params?.data?.id, item.quick_note_text);
                  }}
                >
                  {item.quick_note_text_value}
                </li>
              );
            })
          ) : (
            <Spin />
          )}
        </ul>
      </div>
    );

    return (
      <div className='action-column'>
        {WasteManagement === true ? (
          <>
            {AUTH.checkPermission(35, 'view-details') && (
              <Button
                type='text'
                title={`${t('Common_Data.View')}`}
                onClick={() => props.openViewDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
              </Button>
            )}
            {AUTH.checkPermission(35, 'leads-delete') && (
              <Button
                type='text'
                title={`${t('Common_Data.Delete')}`}
                onClick={() => props.openDeleteDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            )}
            {AUTH.checkPermission(35, 'restore') && (
              <Button
                type='text'
                title={`${t('Common_Data.Restore')}`}
                onClick={() => {
                  openRestoreModal(params.data);
                }}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Restore')} />
              </Button>
            )}
          </>
        ) : (
          <>
            {AUTH.checkPermission(10, 'change-view') && (
              <Button type='text' title={`${t('Block_Edit_Assign.Deal_Desk')}`}>
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Deal_icn')}
                  onClick={() => {
                    history.push(`/dealdesk/${params.data.id}`);
                  }}
                />
              </Button>
            )}

            {AUTH.checkPermission(10, 'edit') && (
              <Button
                type='text'
                title={`${t('Common_Data.Edit')}`}
                onClick={() => props.openEditDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
              </Button>
            )}

            {AUTH.checkPermission(10, 'delete') && (
              <Button
                type='text'
                title={`${t('Common_Data.Delete')}`}
                onClick={() => props.openDeleteDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            )}

            {AUTH.checkPermission(10, 'view-details') && (
              <Button
                type='text'
                title={`${t('Common_Data.View')}`}
                onClick={() => props.openViewDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
              </Button>
            )}
            {AUTH.checkPermission(2, 'quick-message') && (
              <Popover
                content={contentP}
                placement='bottom'
                arrowPointAtCenter
                trigger='hover'
                overlayClassName='profileMenu'
              >
                <Button type='text' title={`${t('LeadListing.Quick_Note')}`}>
                  <img
                    className='menuicon'
                    alt=''
                    src={AUTH.GetThemedImage('Quicknote')}
                  />
                </Button>
              </Popover>
            )}
            <NoteRenderer data={params.data} />

            {AUTH.checkPermission(10, 'reassign') && (
              <Popover
                content={userList}
                placement='bottomRight'
                trigger='hover'
                overlayClassName='profileMenu'
              >
                <Button type='text' title={`${t('LeadListing.Reassign')}`}>
                  <img
                    className='menuicon'
                    alt=''
                    src={AUTH.GetThemedImage('Reassign')}
                    title={`${t('LeadListing.Reassign')}`}
                    onMouseEnter={() => {
                      if (!reAssignUser) {
                        getReassignUserList().then((data) => {
                          setReAssignUser(data.data);
                        });
                      }
                    }}
                  />
                </Button>
              </Popover>
            )}

            {AUTH.checkPermission(10, 'reassign') && (
              <ReassignConfirmElements
                visible={openReassignModal}
                close={closeReassignModal}
                reAssingTempData={reAssingTempData}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,
        field: 'lead_owner_name.full_name',
        tooltipField: 'lead_owner_name.full_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            LEADSSTORE.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.getLeadsUsersList().then((data) => {
              params.success([null, ...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              LEADSSTORE.dropdown_leads_users_list,
              params,
              'id',
              'name'
            ),
        },
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,
        field: 'lead_holder_name.full_name',
        tooltipField: 'lead_holder_name.full_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            LEADSSTORE.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.getLeadsUsersList().then((data) => {
              params.success([null, ...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              LEADSSTORE.dropdown_leads_users_list,
              params,
              'id',
              'name'
            ),
        },
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Name')}`,
        field: 'people.full_name',
        tooltipField: 'people.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            LEADSSTORE.filter_people_list &&
            LEADSSTORE.filter_people_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.filterGetPeopleList().then((data) => {
              params.success([null, ...data.map((x, indexID) => indexID)]);
            });
          },
          valueFormatter: (params) => {
            return (
              params.value &&
              LEADSSTORE.filter_people_list &&
              LEADSSTORE.filter_people_list[params.value]
            );
          },
        },
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name.organisation_name',
        tooltipField: 'organisation_name.organisation_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            LEADSSTORE.filter_org_list &&
            LEADSSTORE.filter_org_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.filterGetMappedOrg().then((data) => {
              params.success([null, ...data.map((x, indexID) => indexID)]);
            });
          },
          valueFormatter: (params) => {
            return (
              params.value &&
              LEADSSTORE.filter_org_list &&
              LEADSSTORE.filter_org_list[params.value]
            );
          },
        },
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'opportunity_name',
        cellClass: 'cellClass',
        tooltipField: 'SOLD Name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Product')}`,
        headerTooltip: `${t('LeadListing.Product')}`,
        field: 'product_name',
        tooltipField: 'product_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.getProductList().then((data) => {
              params.success([null, ...data.data.map((x) => x.product_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Phone')}`,
        headerTooltip: `${t('Common_Data.Phone')}`,
        field: 'phone',
        cellClass: 'cellClassDropDown',

        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: 'phoneCellRenderer',
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        headerTooltip: `${t('Common_Data.Email')}`,
        field: 'email',
        cellClass: 'cellClassDropDown',

        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: 'emailCellRender',
      },
      {
        headerName: `${t('LeadListing.SOLD_Value')}`,
        headerTooltip: `${t('LeadListing.SOLD_Value')}`,
        field: 'finance_value',
        cellClass: 'cellClass',
        tooltipField: 'finance_value',

        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return (
            params.data?.lead_currency_id?.currency_code +
            ' ' +
            params.data?.finance_value
          );
        },
        // cellRenderer: 'btnCellRenderer'
      },
      {
        headerName: `${t('Common_Data.SOLD_Stage')}`,
        headerTooltip: `${t('Common_Data.SOLD_Stage')}`,
        field: 'lead_status_id.status_name',
        tooltipField: 'lead_status_id.status_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            LEADSSTORE.allChildStagesList,
            params,
            'id',
            'status_name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.getAllChildStatusList(1).then((data) => {
              params.success([...data.map((x) => x && x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              LEADSSTORE.allChildStagesList,
              params,
              'id',
              'status_name'
            ),
        },
      },

      {
        headerName: `${t('LeadListing.Time_Spent')}`,
        headerTooltip: `${t('LeadListing.Time_Spent')}`,
        field: 'investment_hours',
        filter: false,
        sortable: false,
        tooltipField: 'investment_hours',
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellRendererFramework: (params) => {
          return (
            <Tooltip
              title={
                params.data.deadline_date
                  ? AUTH.getLocalTime(moment(params.data.deadline_date),AUTH.global_dateformat)
                  : ' '
              }
            >
              {params.data.deadline_date
                ? AUTH.getLocalTime(moment(params.data.deadline_date),AUTH.global_dateformat)
                : ' '}
            </Tooltip>
          );
        },
        // valueGetter: (params) =>
        //   params.data && params.data.deadline_date ? params.data.deadline_date : null,
        // cellRenderer: (params) => {
        //   return params.data.deadline_date
        //     ? AUTH.getLocalTime(moment(params.data.deadline_date))
        //     : ' ';
        // },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `#${t('Common_Data.ID')}`,
        field: 'id',
        tooltipField: 'id',
        cellClass: 'cellClass',
        cellStyle: { textAlign: 'center', padding: '0' },

        width: 80,
        filter: 'agNumberColumnFilter',
        sortable: true,
        // cellRenderer: 'btnCellRenderer',
        // lockPosition: true,
        // suppressNavigable: true
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: WasteManagement === true ? 120 : 280,

        filter: false,
        sortable: false,
        pinned: 'right',
        // lockPosition: true,
        // suppressNavigable: true,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('leads_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  const onCellKeyUp = (e) => {
    if (quickNoteList && quickNoteList.length > 0) {
      if (e.event.ctrlKey || e.event.altKey || e.event.shiftKey) {
        return false;
      }
      let keyPressed = e.event.key;
      let result = quickNoteList
        .filter((x) => x.quick_note_text_value.charAt(0) === keyPressed.toUpperCase())
        .shift();
      if (result) {
        handleSubmitQN(e.data.id, result.quick_note_text);
      }
    }
  };

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('LeadListing.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/dealdesk/${params.node.data.id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('LeadListing.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/dealdesk/${params.node.data.id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  function getClipBoardProcess(params) {
    if (params.column.colId === 'phone') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.value[0].contact_value;
        }
      }
    }
    if (params.column.colId === 'email') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.node.data.email;
        }
      }
    }

    return params.value;
  }

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 13rem)',
        }}
      >
        <AgGridReact
          rowData={LEADSSTORE.list_data}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...GridConfig.defaultColDef,
            floatingFilter: !SearchPage,
            editable: false,
          }}
          columnTypes={GridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openRestoreModal,
            phoneCellRenderer: PhoneNumberRender,
            emailCellRender: EmailCellRender,
            quickNoteList,
            handleSubmitQN,
          }}
          onGridReady={LEADSSTORE.setupGrid}
          gridOptions={{
            ...GridConfig.options,
            rowHeight: 30,
            pagination: !SearchPage,
            onRowDoubleClicked: (event) => props.openViewDrawer(event?.data),
            getContextMenuItems: getContextMenuItems,
            processCellForClipboard: getClipBoardProcess,
          }}
          onColumnResized={onGridChanged}
          onColumnMoved={onGridChanged}
          onColumnPinned={onGridChanged}
          rowSelection='multiple'
          suppressRowClickSelection={true}
          onCellKeyDown={(e) => {
            onCellKeyDown(e);
            onCellKeyUp(e);
          }}
          onFilterChanged={(e) => {
            if (
              e.hasOwnProperty('afterFloatingFilter') &&
              LEADSSTORE.selectedGridFilter
            ) {
              LEADSSTORE.setSelectedGridFilter(null);
            }
          }}
        />
      </div>
    </>
  );
});
export default ListComponent;
