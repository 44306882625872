import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { Button, Popover } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../store';
import { useTranslation } from 'react-i18next';
import { clear, reset } from '../utils/ResetClearButton';

const ResetGridOptions = observer((props) => {
  const {
    AUTH,
    TicketsStore,
    TagsStore,
    PEOPLE,
    ORGANISATION,
    REMINDERSSTORE,
    LEADACTIVE,
    LEADSSTORE,
    LEADDEALS,
    PROMOTIONSTORE,
    PRODUCTIVITYSTORE,
    BLOCKEDITASSIGNSTORE,
    ALLLEADS,
    MANAGEMENTALLDEPARTMENTSSTORE,
    REPORTSTORE,
    HISTORY,
    KNOWLEDGE,
    LIBRARYSEARCHSTORE,
    SSSLEADLIBRARYSTORE,
    KeyNotesSearchStore
  } = useStore();
  const [resetOptionVisible, setResetOptionVisible] = useState(false);
  const { t } = useTranslation();

  const { pageType, key, form = null, Store, list_grid, refresh } = props;

  const applyOption = (gridUpdateType) => {
    //1 - Reset Grid, 2 = Refresh Data, 3= Reset To Default
    if (form) {
      form.resetFields();
    }
    /** For Advance Search Reset Refresh and Clear buttons  */
    if (Store) {
      if (gridUpdateType === 1) {
        reset(Store.agGrid)
      }
      if (gridUpdateType === 3) {
        clear(Store.agGrid)
      }
      if (gridUpdateType === 2) {
        if (Store === HISTORY) {
          HISTORY.setupGrid(HISTORY.agGrid);
        } else if (Store === KNOWLEDGE || Store === LIBRARYSEARCHSTORE || Store === KeyNotesSearchStore) {
          Store.getList(Store.search_payload);
        } else {
          Store.getListSearch(Store.search_payload);
        }
      }
    }
    /** For Normal Listings Reset Refresh and Clear buttons */
    else if (pageType === 'tickets') {
      if (TicketsStore.agGrid) {
        if (gridUpdateType === 1) {
          TicketsStore.handleReset(); // Reset : Will reset all filters, sorting, saved columns settings.
        } else if (gridUpdateType === 2) {
          TicketsStore.setupGrid(TicketsStore.agGrid); // Refresh : Will just refresh data from server keeping everything as it is, Filters, sorting and column settings.
        } else if (gridUpdateType === 3) {
          TicketsStore.applyFilterDefault(); // Clear : Will clear all applied Filter and sorting, And will reset to default.
        }
      }
    } else if (pageType === 'tags') {
      if (TagsStore.agGrid) {
        if (gridUpdateType === 1) {
          TagsStore.handleReset();
        } else if (gridUpdateType === 2) {
          TagsStore.setupGrid(TagsStore.agGrid);
        } else if (gridUpdateType === 3) {
          TagsStore.applyFilterDefault();
        }
      }
    } else if (pageType === 'people') {
      if (PEOPLE.agGrid) {
        if (gridUpdateType === 1) {
          PEOPLE.handleReset();
        } else if (gridUpdateType === 2) {
          PEOPLE.setupGrid(PEOPLE.agGrid);
        } else if (gridUpdateType === 3) {
          PEOPLE.applyFilterDefault();
        }
      }
    } else if (pageType === 'organisations') {
      if (ORGANISATION.agGrid) {
        if (gridUpdateType === 1) {
          ORGANISATION.handleReset();
        } else if (gridUpdateType === 2) {
          ORGANISATION.setupGrid(ORGANISATION.agGrid);
        } else if (gridUpdateType === 3) {
          ORGANISATION.applyFilterDefault();
        }
      }
    } else if (pageType === 'reminder') {
      if (REMINDERSSTORE.agGrid) {
        if (gridUpdateType === 1) {
          REMINDERSSTORE.handleReset();
        } else if (gridUpdateType === 2) {
          REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
        } else if (gridUpdateType === 3) {
          REMINDERSSTORE.applyFilterDefault();
        }
      }
    } else if (pageType === 'activeleads') {
      if (LEADACTIVE.agGrid) {
        if (gridUpdateType === 1) {
          LEADACTIVE.handleReset();
        } else if (gridUpdateType === 2) {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
        } else if (gridUpdateType === 3) {
          LEADACTIVE.applyFilterDefault();
        }
      }
    } else if (pageType === 'unusedleads') {
      if (LEADSSTORE.agGrid) {
        if (gridUpdateType === 1) {
          LEADSSTORE.handleReset();
        } else if (gridUpdateType === 2) {
          LEADSSTORE.setupGrid(LEADSSTORE.agGrid);
        } else if (gridUpdateType === 3) {
          LEADSSTORE.applyFilterDefault();
        }
      }
    } else if (pageType === 'deals') {
      if (LEADDEALS.agGrid) {
        if (gridUpdateType === 1) {
          LEADDEALS.handleReset();
        } else if (gridUpdateType === 2) {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
        } else if (gridUpdateType === 3) {
          LEADDEALS.applyFilterDefault();
        }
      }
    } else if (pageType === 'promotion') {
      if (PROMOTIONSTORE.agGrid) {
        if (gridUpdateType === 1) {
          PROMOTIONSTORE.handleReset();
        } else if (gridUpdateType === 2) {
          PROMOTIONSTORE.setupGrid(PROMOTIONSTORE.agGrid);
        } else if (gridUpdateType === 3) {
          PROMOTIONSTORE.applyFilterDefault();
        }
      }
    } else if (pageType === 'productivity') {
      if (PRODUCTIVITYSTORE.agGrid) {
        if (gridUpdateType === 1) {
          PRODUCTIVITYSTORE.handleReset();
        } else if (gridUpdateType === 2) {
          PRODUCTIVITYSTORE.setupGrid(PRODUCTIVITYSTORE.agGrid);
        } else if (gridUpdateType === 3) {
          PRODUCTIVITYSTORE.applyFilterDefault();
        }
      }
    } else if (pageType === 'blockeditassign') {
      if (BLOCKEDITASSIGNSTORE.agGrid) {
        if (gridUpdateType === 1) {
          BLOCKEDITASSIGNSTORE.handleReset();
        } else if (gridUpdateType === 2) {
          BLOCKEDITASSIGNSTORE.setupGrid(BLOCKEDITASSIGNSTORE.agGrid);
        } else if (gridUpdateType === 3) {
          BLOCKEDITASSIGNSTORE.applyFilterDefault();
        }
      }
    } else if (pageType === 'allleads') {
      if (ALLLEADS.agGrid) {
        if (gridUpdateType === 1) {
          ALLLEADS.handleReset();
        } else if (gridUpdateType === 2) {
          ALLLEADS.setupGrid(ALLLEADS.agGrid);
        } else if (gridUpdateType === 3) {
          ALLLEADS.applyFilterDefault();
        }
      }
    } else if (pageType === 'reports') {
      if (REPORTSTORE.agGrid) {
        if (gridUpdateType === 1) {
          REPORTSTORE.handleReset();
        } else if (gridUpdateType === 2) {
          if (REPORTSTORE.my_records === 'my') {
            REPORTSTORE.getList({}, AUTH.user.id);
          } else {
            REPORTSTORE.getList();
          }
        }
        //  else if (gridUpdateType === 3) {
        //   ALLLEADS.applyFilterDefault();
        // }
      }
    } else if (pageType === 'alldepartments') {
      if (MANAGEMENTALLDEPARTMENTSSTORE.agGrid) {
        if (gridUpdateType === 1) {
          MANAGEMENTALLDEPARTMENTSSTORE.handleReset();
        } else if (gridUpdateType === 2) {
          if (MANAGEMENTALLDEPARTMENTSSTORE.agGrid) {
            MANAGEMENTALLDEPARTMENTSSTORE.getList();
          }
        } else if (gridUpdateType === 3) {
          MANAGEMENTALLDEPARTMENTSSTORE.applyFilterDefault();
        }
      }
    } else if (pageType === 'history') {
      if (HISTORY.agGrid) {
        if (gridUpdateType === 1) {
          HISTORY.handleReset();
        } else if (gridUpdateType === 2) {
          HISTORY.setupGrid(HISTORY.agGrid);
        } else if (gridUpdateType === 3) {
          HISTORY.applyFilterDefault();
        }
      }
    } else if (pageType === "scrutinise") {
      if (SSSLEADLIBRARYSTORE.scrunitiseListGrid) {
        if (gridUpdateType === 1) {
          reset(SSSLEADLIBRARYSTORE.scrunitiseListGrid, list_grid)
        } else if (gridUpdateType === 2) {
          refresh()
        } else if (gridUpdateType === 3) {
          clear(SSSLEADLIBRARYSTORE.scrunitiseListGrid);
        }
      }
    } else if (pageType === "screen") {
      if (SSSLEADLIBRARYSTORE.screenListGrid) {
        if (gridUpdateType === 1) {
          reset(SSSLEADLIBRARYSTORE.screenListGrid, list_grid)
        } else if (gridUpdateType === 2) {
          refresh()
        } else if (gridUpdateType === 3) {
          clear(SSSLEADLIBRARYSTORE.screenListGrid);
        }
      }
    } else if (pageType === "structure") {
      if (SSSLEADLIBRARYSTORE.structureListGrid) {
        if (gridUpdateType === 1) {
          reset(SSSLEADLIBRARYSTORE.structureListGrid, list_grid)
        } else if (gridUpdateType === 2) {
          refresh()
        } else if (gridUpdateType === 3) {
          clear(SSSLEADLIBRARYSTORE.structureListGrid);
        }
      }
    } else if (pageType === "schedule") {
      if (SSSLEADLIBRARYSTORE.scheduleListGrid) {
        if (gridUpdateType === 1) {
          reset(SSSLEADLIBRARYSTORE.scheduleListGrid, list_grid)
        } else if (gridUpdateType === 2) {
          refresh()
        } else if (gridUpdateType === 3) {
          clear(SSSLEADLIBRARYSTORE.scheduleListGrid);
        }
      }
    } else if (pageType === "score") {
      if (SSSLEADLIBRARYSTORE.scoreListGrid) {
        if (gridUpdateType === 1) {
          reset(SSSLEADLIBRARYSTORE.scoreListGrid, list_grid)
        } else if (gridUpdateType === 2) {
          refresh()
        } else if (gridUpdateType === 3) {
          clear(SSSLEADLIBRARYSTORE.scoreListGrid);
        }
      }
    } else if (pageType === "shortfall") {
      if (SSSLEADLIBRARYSTORE.shortfallListGrid) {
        if (gridUpdateType === 1) {
          reset(SSSLEADLIBRARYSTORE.shortfallListGrid, list_grid)
        } else if (gridUpdateType === 2) {
          refresh()
        } else if (gridUpdateType === 3) {
          clear(SSSLEADLIBRARYSTORE.shortfallListGrid);
        }
      }
    }
    setResetOptionVisible(false);
  };
  return (
    <>
      <Popover
        content={
          <ul
            className='popover-menu'
            key='1'
            style={{ cursor: 'pointer', width: '90px', minWidth: 'unset' }}
          >
            <li key='r1' onClick={() => applyOption(1)}>
              {t('Common_Data.Reset')}
            </li>
            <li key='r2' onClick={() => applyOption(2)}>
              {t('Common_Data.Refresh')}
            </li>
            <li key='r3' onClick={() => applyOption(3)}>
              {t('Common_Data.Clear')}
            </li>
          </ul>
        }
        trigger='click'
        placement='bottom'
        overlayClassName='main-menu-popover'
        visible={resetOptionVisible}
        onVisibleChange={() => {
          setResetOptionVisible(!resetOptionVisible);
        }}
      >
        <Button key={key} shape='round' size='medium' title={t('Common_Data.Reset')}>
          <FontAwesomeIcon icon={faUndo} />
        </Button>
      </Popover>
    </>
  );
});

export default ResetGridOptions;
