import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import useStore from '../../../../store';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../../../../utils/GlobalFunction';
import TicketPriorityDrawer from './TicketPriorityDrawer.js';
// import InputComponent from '../../../../component/InputComponent';

const PreviewDataTableElement = observer((props) => {
  const gridRef = useRef();

  // const {
  //   REPORTSTORE: {      },
  // } = useStore();
  const { report_on, /*report_type*/ } = props;
  const { t } = useTranslation();
  const {
    REPORTSTORE,
    REPORTSTORE: {  /*setupGrid,*/ setupReportGrid, previewReportTotal },
  } = useStore();

  const [visibleTicketPriorityDrawer, setVisibleTicketPriorityDrawer] = useState(false);
  const [ticketPriorityDrawerTitle, setTicketPriorityDrawerTitle] = useState('Ticket-Priority')

  if (props.rowData && props.gridOptions) {
    let columns = [];
    columns = props?.gridOptions;    
    // columns = columns.sort(function (a, b) {
    //   var nameA = a.field.toLowerCase(), nameB = b.field.toLowerCase();
    //   if (nameA < nameB) //sort string ascending
    //     return -1;
    //   if (nameA > nameB)
    //     return 1;
    //   return 0; //default return value (no sorting)
    // });


    const onCellDoubleClickRoute = (cellName, cellData) => {
      if (['ID', 'SOLD ID'].includes(cellName) && ['sold', 'tags', 'tickets'].includes(report_on) && cellData?.value) {
        window.open(`/dealdesk/${cellData?.value}`, '_blank');
      }
      if (['ticket_number'].includes(cellData?.column?.colId) && cellData?.value) {
        window.open(`/ticket/${cellData?.value}`, '_blank');
      }
      //openTicketPriorityDrawer
      if (props.form.getFieldValue("ticket_priority")) {
        setVisibleTicketPriorityDrawer(true);
        setTicketPriorityDrawerTitle(cellData?.data?.user);
        REPORTSTORE.getTicketPriorityDataByUser({
          ...REPORTSTORE.previewReportValues,
          ...props.form.getFieldsValue(),
          "is_drill_down_report": true,
          "users": [cellData?.data?.id],
        })
      }
    };


    //--------------START ----Bottom Pinned Row Total column blank Fix--------//
    // if (pinnedBottomRowData && pinnedBottomRowData.length > 0) {
    //   let formatObj = pinnedBottomRowData[0];
    //   if (!formatObj.hasOwnProperty('sr')) {
    //     formatObj.sr = 'Total';
    //     formatObj.user = 'Total';
    //     pinnedBottomRowData = [formatObj];
    //   }
    // }

    // merge history total row to pinnedBottom
    // Array.prototype.push.apply(pinnedBottomRowData, pinnedTopRowData);

    /** START :: On column show hide filter get reset which is hide NOT ALL  */
    const onCellVisibleHandler = (e) => {
      var changedClmn = e.columns[0].colId;
      var changedClmnAction = e.columns[0].visible;
      if (changedClmnAction === false) {
        const filterInstance = e.api.getFilterInstance(changedClmn);
        /*Set the model to null*/
        filterInstance.setModel(null);
        /*Tell grid to run filter operation again*/
        e.api.onFilterChanged();

      }
    }
    /** END ::  On column show hide filter get reset which is hide NOT ALL  */
    // const onBtPageFifty = useCallback(() => {
    //   gridRef.current.api.paginationGoToPage(40);
    // }, []);

    // const handleChangeddd = (e) => {
    //   gridRef.current.api.paginationGoToPage(e);
    // };
    let filterOptions = { ...LocalGridConfig.defaultColDef, sortable: true };
    if (props.form.getFieldValue('report_type') === "summary") {
      // filterOptions = { ...LocalGridConfig.defaultColDef, floatingFilter: false, suppressMenu: false, menuTabs: ['generalMenuTab'], cellClass: 'locked-visible', filter: false, lockPinned: true, lockPosition: true, };
      filterOptions = {
        ...LocalGridConfig.defaultColDef,
        floatingFilter: false, suppressMenu: false, cellClass: 'locked-visible', filter: false, lockPinned: true, sortable: true
      };
    } 




    let colIds = [];
    let removeColIds = [];
    let hiddenCols = [];
    let currentCols = [];
    let currentTotals = [];

    let historyTotal = { "user": "History Total" }
    let modifiedCurrentTotalRow = { "user": "Total" }
    let modifiedPinnedBottomRowData = [];
    let nonStagesCurrentValues = {};

    /**Storing Hidden Columns from Saved Columns Setting*/
    if (REPORTSTORE.reportColumnSettings) {
      let columnsSettings = JSON.parse(REPORTSTORE.reportColumnSettings);
      columnsSettings.forEach((data) => {
        if (data.hide === true) {
          removeColIds.push(data.colId);
          hiddenCols.push(data.colId)
        }
      })
    }

    /** Storing all ColIds */
    (gridRef.current?.columnApi.getColumnState() || columns)?.filter((x) => (x.colId || x.field) !== "user")?.forEach((x) => {
      colIds.push(x.colId || x.field);
    });
    colIds = colIds?.filter(n => !removeColIds?.includes(n));//Remove hidden Cols

    /**Modify and Set Current Total Row if any row is hidden */
    if (removeColIds.length > 0) {
      colIds && colIds?.filter(n => !removeColIds?.includes(n))?.forEach((colId) => {
        props.pinnedBottomRowData[0] && Object.entries(props.pinnedBottomRowData[0]).forEach(entry => {
          const [key, value] = entry;
          if (colId === key) {
            modifiedCurrentTotalRow[colId] = value;
          }
        });
      });
      modifiedPinnedBottomRowData.push(modifiedCurrentTotalRow);
    } else {
      modifiedPinnedBottomRowData.push(props.pinnedBottomRowData[0])
    }

    /** Function to modify data of history total data on Move */
    const onMove = (e) => {
      if (props.form.getFieldValue('report_type') === "summary" && props.form.getFieldValue('sum_by_stages')) {
        let colIds = [];

        /** To store hidden Cols */
        if (!e?.columns[0].visible) {
          hiddenCols.push(e?.columns[0]?.colId);
        } else {
          const i = [...new Set(hiddenCols)].indexOf(e?.columns[0]?.colId);
          if (i > -1) {
            hiddenCols?.splice(0, 1);
          }
          const index = [...new Set(hiddenCols)].indexOf(e?.columns[0]?.colId);
          if (index > -1) {
            removeColIds?.splice(0, 1);
          }
        }

        /** To Store ColIds */
        gridRef.current?.columnApi?.getAllGridColumns()?.filter((x) => x.colId !== "user")?.forEach((x) => {
          colIds.push(x.colId);
        })

        /** To set Current Total RowData */
        let currentTotalValues = []
        if (e?.columns[0]?.visible) {
          colIds?.forEach((colId) => {
            modifiedPinnedBottomRowData[0] && Object.entries(modifiedPinnedBottomRowData[0]).forEach(entry => {
              const [key, value] = entry;
              if (colId === key) {
                gridRef.current?.api?.getPinnedBottomRow("0")?.setDataValue(colId, value);
              }
            });
          })
        }
        if (e.columns[0]?.visible) {
          if (hiddenCols?.includes(e?.columns[0]?.colId)) {
            hiddenCols = hiddenCols?.filter(n => !n?.includes(e?.columns[0]?.colId))
          }
        }
        colIds = colIds?.filter(n => !hiddenCols?.includes(n)); //To remove Hidden Columns

        /** To Store Current Total Values */
        let currentTotal = gridRef.current?.api?.getPinnedBottomRow("0")?.data;
        colIds?.forEach((x) => {
          currentTotal && Object.entries(currentTotal).forEach(entry => {
            const [key, value] = entry;
            if (x === key) {
              if (key?.includes("- Lead") || key?.includes("- Deal") || key?.includes("- Trade") || key?.includes("- Treasury")) {
                currentTotalValues.push(parseInt(value?.toLocaleString()?.replace(/,/g, '')));
              } else {
                currentTotalValues.push(0);
                nonStagesCurrentValues[key] = value;
              }
            }
          });
        })
        currentTotals = currentTotalValues;
        currentCols = colIds;

        /** To set History Total Values */
        currentCols?.forEach((colId) => {
          if (colId?.includes("- Lead") || colId?.includes("- Deal") || colId?.includes("- Trade") || colId?.includes("- Treasury")) {
            gridRef.current?.api?.getPinnedBottomRow("1")?.setDataValue(colId, currentTotals?.reduce((a, b) => a + b, 0).toLocaleString());
          } else {
            nonStagesCurrentValues && Object.entries(nonStagesCurrentValues).forEach(entry => {
              const [key, value] = entry;
              if (key === colId) {
                gridRef.current?.api?.getPinnedBottomRow("1")?.setDataValue(colId, value);
              }
            })
          }
          currentTotals.shift();
        })
      }
    };

    /** Function to add/modify data of history total on Grid Ready */
    if (props.form.getFieldValue('report_type') === "summary" && props.form.getFieldValue('sum_by_stages')) {
      /** Storing Current Total Values */
      let currentTotalValues = [];
      colIds && colIds.forEach((x) => {
        modifiedPinnedBottomRowData[0] && Object.entries(modifiedPinnedBottomRowData[0]).forEach(entry => {
          const [key, value] = entry;
          if (x === key) {
            if (key?.includes("- Lead") || key?.includes("- Deal") || key?.includes("- Trade") || key?.includes("- Treasury")) {
              currentTotalValues.push(parseInt(value?.toLocaleString()?.replace(/,/g, '')));
            } else {
              currentTotalValues.push(0);
              nonStagesCurrentValues[key] = value;
            }

            // currentTotalValues.push(parseInt(value.toLocaleString().replace(/,/g, '')));
          }
        });
      })

      /**Set History Total Row */
      colIds && colIds.forEach((colId) => {
        if (colId?.includes("- Lead") || colId?.includes("- Deal") || colId?.includes("- Trade") || colId?.includes("- Treasury")) {
          historyTotal[colId] = currentTotalValues?.reduce((a, b) => a + b, 0)?.toLocaleString();
        } else {
          nonStagesCurrentValues && Object.entries(nonStagesCurrentValues).forEach(entry => {
            const [key, value] = entry;
            if (key === colId) {
              historyTotal[colId] = value;
            }
          })
        }
        currentTotalValues.shift();
      })
      modifiedPinnedBottomRowData.push(historyTotal);
    }

    /** On Filter Changed */
    // const onFilterChanged = () => {
    //   let colIds = [];
    //   let getColumns = gridRef.current?.columnApi?.getAllGridColumns();
    //   let rowNode = gridRef.current?.api?.getPinnedBottomRow("0");

    //   getColumns.filter((x) => x.colId !== "user").forEach((x) => {
    //     colIds.push(x.colId)
    //   })
    //   let rowData = []
    //   gridRef.current?.api?.forEachNodeAfterFilterAndSort((rowNode) => {
    //     rowData.push(rowNode.data);
    //   });
    //   let currentTotalValues = [];
    //   // let currentTotals = 
    //   colIds.forEach((colId) => {
    //     let sum = 0;
    //     rowData.forEach((row) => {
    //       let values = []
    //       Object.entries(row).forEach(entry => {
    //         const [key, value] = entry;
    //         if (colId === key) {
    //           sum += parseInt(value);
    //           values.push(value)
    //           currentTotalValues.push(sum)
    //         }

    //       });
    //     })
    //     currentTotalValues.forEach((currentValue) => {
    //       rowNode.setDataValue(colId, currentValue.toLocaleString());
    //       // currentTotals.push(currentValue)
    //     })
    //     // currentCols = colIds
    //     // currentCols.forEach((colId) => {
    //     //   if (colId !== "user") {
    //     //     gridRef.current?.api?.getPinnedBottomRow("1").setDataValue(colId, currentTotalValues.reduce((a, b) => a + b, 0).toLocaleString());
    //     //     currentTotalValues.shift();
    //     //   }
    //     // })
    //     onMove();
    //     onPinned();
    //   })

    // }




    /**On Pinned */
    // let pinnedColsLeft = []
    // let pinnedColsRight = []
    // const onPinned = (e) => {
    //   if (e?.pinned === "right") {
    //     pinnedColsRight.push(e?.column.colId)
    //     pinnedColsLeft = pinnedColsLeft.filter(n => !pinnedColsRight.includes(n));
    //   } else {
    //     pinnedColsLeft.unshift(e?.column.colId)
    //     pinnedColsRight = pinnedColsRight.filter(n => !pinnedColsLeft.includes(n));
    //   }

    //   let allColumns = [];
    //   gridRef.current?.columnApi?.getColumnState().forEach((x) => {
    //     allColumns.push(x.colId)
    //   });

    //   pinnedColsLeft = allColumns.filter(n => pinnedColsLeft.includes(n));
    //   pinnedColsRight = allColumns.filter(n => pinnedColsRight.includes(n));
    //   currentCols = allColumns;
    //   if (pinnedColsLeft) {
    //     currentCols = pinnedColsLeft.concat(allColumns.filter(n => !pinnedColsLeft.includes(n)))
    //   }
    //   if (pinnedColsRight) {
    //     currentCols = currentCols.filter(n => !pinnedColsRight.includes(n)).concat(pinnedColsRight)
    //   }

    //   if (props.form.getFieldValue('report_type') === "summary") {
    //     let rowNode = gridRef.current?.api?.getPinnedBottomRow("1");
    //     currentCols.filter((x) => x !== "user").forEach((x) => {
    //       let currentTotalValues = []
    //       currentCols.forEach((x) => {
    //         let currentTotal = gridRef.current?.api?.getPinnedBottomRow("0").data
    //         Object.entries(currentTotal).forEach(entry => {
    //           const [key, value] = entry;
    //           if (x === key && key !== 'user') {
    //             currentTotalValues.push(parseInt(value.toLocaleString().replace(/,/g, '')));
    //           }
    //         });
    //       })
    //       currentTotals = currentTotalValues
    //     })
    //     currentCols.forEach((colId) => {
    //       if (colId !== "user") {
    //         rowNode.setDataValue(colId, currentTotals.reduce((a, b) => a + b, 0).toLocaleString());
    //         currentTotals.shift();
    //       }
    //     })
    //   }
    // }

    return (
      <>
        {/* <InputComponent
          name='sdasdasd'
          type='select'
          style={{ margin: '0', minWidth: '150px' }}
          initialValue={100}
          showSearch={false}
          options={{
            values: [
              { value: 1, text: `1` },
              { value: 20, text: `20` },
              { value: 30, text: `30` },
              { value: 50, text: `50` },
              { value: 70, text: `70` },
              { value: 1000, text: `1000` }
            ]
          }}
          onChange={handleChangeddd}
        /> */}
        <div className='' style={{ height: 'calc(100vh - 12rem)' }}>
          <div className={`ag-theme-alpine grid_wrapper customPaginationUI ${(typeof previewReportTotal === "undefined" || previewReportTotal === 0) ? "removeLeft" : ""}`}>
            {(typeof previewReportTotal === "undefined" || previewReportTotal === 0) ? (<div className='reportsTotalCountTop' >&nbsp;</div>)
              : (<div className='reportsTotalCountTop'>{`${t('Reports.Total_Count')}`}: <b>{numberWithCommas(previewReportTotal)}</b> </div>)
            }
            <AgGridReact
              ref={gridRef}
              gridOptions={{
                ...LocalGridConfig.options,
                pagination: typeof previewReportTotal === "undefined" || previewReportTotal === 0 ? false : true,
              }}
              rowData={props.rowData}
              modules={AllModules}
              columnDefs={columns}
              pinnedBottomRowData={modifiedPinnedBottomRowData}
              defaultColDef={{...filterOptions, sortable: true}}
              columnTypes={LocalGridConfig.columnTypes}
              overlayNoRowsTemplate={vsmCommon.noRecord}
              onGridReady={setupReportGrid}
              onColumnResized={props.onColumnResized}
              onColumnPinned={(params) => {
                props.onColumnPinned(params);
                // onPinned(params);
              }}
              onSortChanged={props.onSortChanged}
              onColumnMoved={onMove}
              rowSelection={'multiple'}
              sortingOrder={["desc", "asc"]}
              onFilterChanged={(e) => {
                // onFilterChanged()
                if (e.api.getDisplayedRowCount()) {
                  e.api.hideOverlay();
                } else {
                  e.api.showNoRowsOverlay();
                }
              }}
              onCellDoubleClicked={(params) => {
                onCellDoubleClickRoute(params?.colDef?.headerName, params)
              }}
              onColumnVisible={(e) => {
                onCellVisibleHandler(e);
                // onFilterChanged(e)
                onMove(e)
              }}
            />
            {visibleTicketPriorityDrawer && <TicketPriorityDrawer
              visible={visibleTicketPriorityDrawer}
              close={() => setVisibleTicketPriorityDrawer(false)}
              form={props.form}
              title={ticketPriorityDrawerTitle}
            />
            }
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
});
export default PreviewDataTableElement;
