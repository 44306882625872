import React, { useState } from 'react';
import { Col, Spin } from 'antd';
import { observer } from 'mobx-react';

import InputComponent from '../../../../component/InputComponent';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';
import {
  capitalize,
  getReportOnFromSavedFilterPageType,
} from '../../../../utils/GlobalFunction';

const SavedFilterElements = observer(({ handleChange, formDataList, form }) => {
  const [fetchFilter, setFetchFilter] = useState(true);
  const [fetchFilterAllPage, setSetFetchFilterAllPage] = useState(true);
  const { t } = useTranslation();
  const {
    AUTH: { allPageFilters, gridfilter_list, getAllPageFilter, getSavedGridFilter },
    REPORTSTORE: { setReport_on_report },
  } = useStore();

  let pageTypeArr = [];
  if (allPageFilters && allPageFilters.length > 0) {
    allPageFilters.forEach((x, i) => {
      pageTypeArr = [...pageTypeArr, { value: x?.page, text: capitalize(x?.page) }];
    });
  }

  const onPageType = (filterType) => {
    let reportOnType = 'sold';
    if (getReportOnFromSavedFilterPageType[filterType]) {
      reportOnType = getReportOnFromSavedFilterPageType[filterType];
    }
    setReport_on_report(reportOnType);
  };

  return (
    <>
      {formDataList.includes('filter_type') && (
        <Col span={12}>
          <InputComponent
            label={t('Reports.Filter_Type')}
            name='filter_type'
            placeholder={`${t('PeopleManagement.All')}`}
            type='select'
            //mode='multiple'
            showSearch
            allowClear
            showArrow
            onFocus={() =>
              fetchFilterAllPage &&
              getAllPageFilter({ is_from_report: true }).then(() =>
                setSetFetchFilterAllPage(false)
              )
            }
            notFoundContent={
              fetchFilterAllPage ? (
                <Spin size='small' />
              ) : (
                `${t('Common_Data.No_Record_Found')}.`
              )
            }
            options={{
              values: pageTypeArr,
              value_key: 'value',
              text_key: 'text',
            }}
            tooltip={t('Reports.Filter_Type')}
            onChange={(e) => {
              onPageType(e);
              form.setFieldsValue({
                filter_id: null
              })
              handleChange();
              getSavedGridFilter({ page: form.getFieldValue('filter_type') });
            }}
          />
        </Col>
      )}
      {formDataList.includes(`filter_id`) && (
        <Col span={12}>
          <InputComponent
            allowClear
            showSearch
            showArrow
            type='select'
            label={t('Common_Data.Filter')}
            name='filter_id'
            placeholder={t('Reports.Select_Saved_Filter')}
            onChange={handleChange}
            tooltip={t('Reports.Select_Saved_Filter')}
            onFocus={() =>
              fetchFilter &&
              getSavedGridFilter({
                page: form.getFieldValue('filter_type'),
                is_from_report: true,
              }).then(() => setFetchFilter(false))
            }
            notFoundContent={
              fetchFilter ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            options={{
              values: gridfilter_list,
              value_key: 'id',
              text_key: 'filter',
              rejected_keys: [0, 2, 99998, 99997, 99999, 99996, 99995]
            }}
          />
        </Col>
      )}
    </>
  );
});

export default SavedFilterElements;
