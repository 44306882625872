import React from 'react';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const ViewComponent = observer((props) => {
  const { KNOWLEDGE } = useStore();
  const { t } = useTranslation()
  const close = () => {
    props.close();
  };

  return (
    KNOWLEDGE.knowledgeValues && (
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('DealDesk.View_Knowledge')} - #${KNOWLEDGE.knowledgeValues?.id}`}
        destroyOnClose={true}
      >
        <div className='knowledgeView' key='1'>
          <table border='0' width='100%' className='knowledgeView'>
            <tbody>
              <tr>
                <th width='30%'>{t('DealDesk.Knowledge_Title')}:</th>
                <td width='70%'>{KNOWLEDGE.knowledgeValues.subject}</td>
              </tr>
              <tr>
                <th>
                  {t('Knowledge.Group_Name')}:</th>
                <td>{KNOWLEDGE.knowledgeValues.knowledge_group}</td>
              </tr>
              <tr>
                <th><span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>:</th>
                <td>{KNOWLEDGE.knowledgeValues.classification_name}</td>
              </tr>
              <tr>
                <th>
                  {t('DealDesk.Knowledge_Classification')}:</th>
                <td>{KNOWLEDGE.knowledgeValues.knowledge_classification}</td>
              </tr>
              <tr>
                <th colSpan='2'>{t('Common_Data.Description')}:</th>
              </tr>
              <tr>
                <td
                  colSpan='2'
                  dangerouslySetInnerHTML={{
                    __html: KNOWLEDGE.knowledgeValues.description,
                  }}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>
    )
  );
});

export default ViewComponent;
