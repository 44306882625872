import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Col, Divider, Form, Row } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import TradeIdElement from '../elements/TradeIdElement';
import FieldValuesElement from '../elements/FieldValuesElement';
import TransactionDateElement from '../elements/TransactionDateElement';
import DateSelectionElement from '../elements/DateSelectionElement';
import PeriodSelectionElement from '../elements/PeriodSelectionElement';
import CombinedReportsElement from '../elements/CombinedReportsElement';
import BankRefElement from '../elements/BankRefElement';
import CustomerRefElement from '../elements/CustomerRefElement';
import OrgPeopleBankIBANElement from '../elements/OrgPeopleBankIBANElement';
import OrgPeopleBankAccNoElement from '../elements/OrgPeopleBankAccNoElement';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({
    defaultFormDataList,
    form,
    formDataList,
    setFormDataList,
    initialValues,
    setDisabled,
    handleSubmit,
    formButtons
  }) => {
    const {
      TransactionFieldMappingStore,
      TransactionTypeStore,
      SavedReportStore,
      TransactionModelStore,
      TransactionFieldStore,
      LedgerBankAccountStore,
      CTEImportStore,
      LedgerDetailStore,
      ClassificationStore,
      RoleMappingStore
    } = useStore();
    const { t } = useTranslation()
    const [DateRange, SetDateRange] = useState([]);
    const [DPREPORTS, SETDPREPORTS] = useState(false);
    const [BASEDONMODEL, SETBASEDONMODEL] = useState(false);
    const [BASEDONMAPPING, SETBASEDONMAPPING] = useState(false);
    const [BASEDONTRANSTYPE, SETBASEDONTRANSTYPE] = useState(false);
    const [BASEDONJOURNAL, SETBASEDONJOURNAL] = useState(false);
    const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false);
    const [CASHBANKACCOUNT, SETCASHBANKSACCOUNT] = useState(false);
    const [CASHBANKS, SETCASHBANKS] = useState(false);
    const [CASHCURRENCY, SETCASHCURRENCY] = useState(false);
    const [CASHTRANSACTIONTYPE, SETCASHTRANSACTIONTYPE] = useState(false);
    const [CASHCLASSIFICATION, SETCASHCLASSIFICATION] = useState(false);
    const [CASHTAGS, SETCASHTAGS] = useState(false);
    const [FIELDVALUES, SETFIELDVALUES] = useState(false);

    const typeChange = useMemo(() => [
      'generate_on',
      'journal_type',
      'group_on',
      'currency',
      'main_entity',
      'related_entity'
    ], [])
    const cashChange = useMemo(() => [
      'main_entity',
      'related_entity',
      'cte_banks',
      'cte_bank_accounts',
      'cte_classification',
      'cte_transaction_type',
      'cte_currency',
      'cte_tags',
      'cte_exchange_id',
      'cte_bank_ref',
      'cte_customer_ref',
      'org_people_bank_iban',
      'org_people_bank_acc_no',
      'generate_on',
      'additional_columns'
    ], [])

    const disabledDate = (current) => {
      if (!DateRange || DateRange.length === 0) {
        return false;
      }
      return moment(current).format('Y-M-D') === moment(DateRange[0]).format('Y-M-D');
    };




    const getFormDataList = useCallback(() => {

      var FormDataList = defaultFormDataList;

      switch (form.getFieldValue('report_type')) {
        case 'leads':
          FormDataList = [...FormDataList, 'type'];
          switch (form.getFieldValue('type')) {
            case 1: // global
              FormDataList = [...FormDataList, ...typeChange];
              break;
            case 2: // single
              FormDataList = [...FormDataList, ...typeChange, 'single_based_on'];
              switch (form.getFieldValue('single_based_on')) {
                case 'single_model':
                  FormDataList = [...FormDataList, 'single_model'];
                  if (!BASEDONMODEL) {
                    SETBASEDONMODEL(true);
                    SavedReportStore.getAllModels();
                  }
                  break;
                case 'single_mapping':
                  FormDataList = [...FormDataList, 'single_mapping'];
                  if (!BASEDONMAPPING) {
                    SETBASEDONMAPPING(true);
                    TransactionFieldMappingStore.getAllRecords();
                  }
                  break;
                case 'single_transaction_type':
                  FormDataList = [...FormDataList, 'single_transaction_type'];
                  if (!BASEDONTRANSTYPE) {
                    SETBASEDONTRANSTYPE(true);
                    TransactionTypeStore.getAllRecords();
                  }
                  break;
                case 'single_journal':
                  FormDataList = [...FormDataList, 'single_journal'];
                  if (!BASEDONJOURNAL) {
                    SETBASEDONJOURNAL(true);
                    TransactionModelStore.getAllJournalsList();
                  }
                  break;
                default:
                  FormDataList = [...FormDataList, 'single_transaction'];
              }
              break;
            case 3: // multiple
              FormDataList = [...FormDataList, ...typeChange, 'multiple_based_on'];
              switch (form.getFieldValue('multiple_based_on')) {
                case 'multiple_model':
                  FormDataList = [...FormDataList, 'multiple_model'];
                  if (!BASEDONMODEL) {
                    SETBASEDONMODEL(true);
                    SavedReportStore.getAllModels();
                  }
                  break;
                case 'multiple_mapping':
                  FormDataList = [...FormDataList, 'multiple_mapping'];
                  if (!BASEDONMAPPING) {
                    SETBASEDONMAPPING(true);
                    TransactionFieldMappingStore.getAllRecords();
                  }
                  break;
                case 'multiple_transaction_type':
                  FormDataList = [...FormDataList, 'multiple_transaction_type'];
                  if (!BASEDONTRANSTYPE) {
                    SETBASEDONTRANSTYPE(true);
                    TransactionTypeStore.getAllRecords();
                  }
                  break;
                case 'multiple_journal':
                  FormDataList = [...FormDataList, 'multiple_journal'];
                  if (!BASEDONJOURNAL) {
                    SETBASEDONJOURNAL(true);
                    TransactionModelStore.getAllJournalsList();
                  }
                  break;
                default:
                  FormDataList = [...FormDataList, 'multiple_transaction'];
              }
              break;
            case 4: // complex
              FormDataList = [
                ...FormDataList,
                ...typeChange,
                'transaction_type',
                'journals',
                'transaction_mappings',
                'transaction_ids',
                'field_values',
                'transaction_dates'
              ];
              if (!BASEDONJOURNAL) {
                SETBASEDONJOURNAL(true);
                TransactionModelStore.getAllJournalsList();
              }
              if (!BASEDONTRANSTYPE) {
                SETBASEDONTRANSTYPE(true);
                TransactionTypeStore.getAllRecords();
              }
              if (!BASEDONMAPPING) {
                SETBASEDONMAPPING(true);
                TransactionFieldMappingStore.getAllRecords();
              }
              if (!BASEDONMODEL) {
                SETBASEDONMODEL(true);
                SavedReportStore.getAllModels();
              }
              if (!FIELDVALUES) {
                SETFIELDVALUES(true);
                TransactionFieldStore.getAllRecord();
              }
              break;

            case 5: // combined_reports
              FormDataList = [...FormDataList, 'combined_reports'];
              if (!DPREPORTS) {
                SETDPREPORTS(true);
                SavedReportStore.getAllRecords();
              }
              break;
            default:
          }
          switch (form.getFieldValue('type')) {
            case 1: // global
            case 2: // single
            case 3: // multiple
            case 4: // complex
              switch (form.getFieldValue('main_entity')) {
                case 'people':
                  FormDataList = [...FormDataList, 'main_people_role', 'main_people'];
                  break;
                default:
                  FormDataList = [
                    ...FormDataList,
                    'main_organisation_role',
                    'main_organisation'
                  ];
              }

              switch (form.getFieldValue('related_entity')) {
                case 'people':
                  FormDataList = [
                    ...FormDataList,
                    'related_people_role',
                    'related_people'
                  ];
                  break;
                default:
                  FormDataList = [
                    ...FormDataList,
                    'related_organisation_role',
                    'related_organisation'
                  ];
              }

              switch (form.getFieldValue('generate_on')) {
                case 'date_range':
                  FormDataList = [...FormDataList, 'date_selection'];
                  switch (form.getFieldValue('date_selection')) {
                    case 'range':
                      FormDataList = [...FormDataList, 'date_range'];
                      break;
                    case 'single':
                      FormDataList = [...FormDataList, 'date'];
                      break;
                    case 'auto':
                      FormDataList = [
                        ...FormDataList,
                        'date_x_value',
                        'date_x_value_type'
                      ];
                      break;
                    default:
                  }
                  break;
                case 'financial_year':
                  FormDataList = [...FormDataList, 'financial_year'];
                  if (!GENERATEONFINANCIAL) {
                    SETGENERATEONFINANCIAL(true);
                    SavedReportStore.getFinancialYearList();
                  }
                  break;
                case 'periods':
                  FormDataList = [...FormDataList, 'period_selection'];
                  switch (form.getFieldValue('period_selection')) {
                    case 'custom':
                      FormDataList = [...FormDataList, 'period_date_range'];
                      break;
                    case 'auto':
                      FormDataList = [
                        ...FormDataList,
                        'period_x_value',
                        'period_x_value_type'
                      ];
                      break;
                    default:
                  }
                  break;
                default:
              }
              break;
            default:
          }
          break;
        case 'audit':
        case 'cash':
          FormDataList = [...FormDataList, ...cashChange];
          if (!BASEDONTRANSTYPE) {
            SETBASEDONTRANSTYPE(true);
            TransactionTypeStore.getAllRecords();
          }
          if (!CASHBANKS) {
            SETCASHBANKS(true);
            LedgerBankAccountStore.getBanksNameList();
          }
          if (!CASHBANKACCOUNT) {
            SETCASHBANKSACCOUNT(true);
            CTEImportStore.getBankList();
          }
          if (!CASHCURRENCY) {
            SETCASHCURRENCY(true);
            LedgerDetailStore.getAllCurrencyList();
          }
          if (!CASHTRANSACTIONTYPE) {
            SETCASHTRANSACTIONTYPE(true);
            SavedReportStore.getCashTransactionType();
          }
          if (!CASHCLASSIFICATION) {
            SETCASHCLASSIFICATION(true);
            ClassificationStore.getClassfications();
          }
          if (!CASHTAGS) {
            SETCASHTAGS(true);
            RoleMappingStore.getAllTagsList();
          }
          if (form.getFieldValue('report_type') === 'audit') {
            FormDataList = [...FormDataList, 'audit_group_on'];
          }
          switch (form.getFieldValue('main_entity')) {
            case 'people':
              FormDataList = [...FormDataList, 'main_people_role', 'main_people'];
              break;
            default:
              FormDataList = [
                ...FormDataList,
                'main_organisation_role',
                'main_organisation'
              ];
          }

          switch (form.getFieldValue('related_entity')) {
            case 'people':
              FormDataList = [...FormDataList, 'related_people_role', 'related_people'];
              break;
            default:
              FormDataList = [
                ...FormDataList,
                'related_organisation_role',
                'related_organisation'
              ];
          }
          switch (form.getFieldValue('generate_on')) {
            case 'date_range':
              FormDataList = [...FormDataList, 'date_selection'];
              switch (form.getFieldValue('date_selection')) {
                case 'range':
                  FormDataList = [...FormDataList, 'date_range'];
                  break;
                case 'single':
                  FormDataList = [...FormDataList, 'date'];
                  break;
                case 'auto':
                  FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type'];
                  break;
                default:
              }
              break;
            case 'financial_year':
              FormDataList = [...FormDataList, 'financial_year'];
              if (!GENERATEONFINANCIAL) {
                SETGENERATEONFINANCIAL(true);
                SavedReportStore.getFinancialYearList();
              }
              break;
            default:
          }
          break;
        default:
      }
      return FormDataList;
    }, [
      defaultFormDataList,
      form,
      typeChange,
      DPREPORTS,
      BASEDONMODEL,
      BASEDONJOURNAL,
      BASEDONMAPPING,
      BASEDONTRANSTYPE,
      GENERATEONFINANCIAL,
      FIELDVALUES,
      CASHBANKS,
      CASHBANKACCOUNT,
      CASHCURRENCY,
      CASHTRANSACTIONTYPE,
      CASHCLASSIFICATION,
      CASHTAGS,
      TransactionFieldMappingStore,
      SavedReportStore,
      TransactionModelStore,
      TransactionTypeStore,
      TransactionFieldStore,
      cashChange,
      LedgerBankAccountStore,
      CTEImportStore,
      LedgerDetailStore,
      ClassificationStore,
      RoleMappingStore
    ]);

    const getOrgRelatedData = useCallback(
      (data = {}) => {
        let main_entity = form.getFieldValue('main_entity');
        let related_entity = form.getFieldValue('related_entity');
        let payload = { main_entity, related_entity };
        if (main_entity === 'people') {
          let main_people_role = form.getFieldValue('main_people_role');
          main_people_role =
            main_people_role && main_people_role.length > 0 ? main_people_role : null;
          let main_people = form.getFieldValue('main_people');
          main_people = main_people && main_people.length > 0 ? main_people : null;
          payload = { ...payload, main_people_role, main_people };
        } else {
          let main_organisation_role = form.getFieldValue('main_organisation_role');
          main_organisation_role =
            main_organisation_role && main_organisation_role.length > 0
              ? main_organisation_role
              : null;
          let main_organisation = form.getFieldValue('main_organisation');
          main_organisation =
            main_organisation && main_organisation.length > 0 ? main_organisation : null;
          payload = { ...payload, main_organisation_role, main_organisation };
        }
        if (related_entity === 'people') {
          let related_people_role = form.getFieldValue('related_people_role');
          related_people_role =
            related_people_role && related_people_role.length > 0
              ? related_people_role
              : null;
          payload = { ...payload, related_people_role };
        } else {
          let related_organisation_role = form.getFieldValue('related_organisation_role');
          related_organisation_role =
            related_organisation_role && related_organisation_role.length > 0
              ? related_organisation_role
              : null;
          payload = { ...payload, related_organisation_role };
        }
        payload = { ...payload, ...data };
        SavedReportStore.getDependentList(payload);
      },
      [form, SavedReportStore]
    );

    useEffect(() => {
      var data = getFormDataList();
      if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
        setFormDataList(data);
      }
    }, [getFormDataList, setFormDataList, formDataList]);

    useEffect(() => {
      if (
        SavedReportStore.previewReportValues &&
        SavedReportStore.previewReportValues.main_entity
      ) {
        SavedReportStore.getMainRoles(SavedReportStore.previewReportValues.main_entity);
        let main_entity = SavedReportStore.previewReportValues.main_entity;
        let related_entity = SavedReportStore.previewReportValues.related_entity;
        let main_organisation = SavedReportStore.previewReportValues.main_organisation;
        let main_organisation_role =
          SavedReportStore.previewReportValues.main_organisation_role;
        let related_organisation_role =
          SavedReportStore.previewReportValues.related_organisation_role;
        let main_people = SavedReportStore.previewReportValues.main_people;
        let main_people_role = SavedReportStore.previewReportValues.main_people_role;
        let related_people_role =
          SavedReportStore.previewReportValues.related_people_role;
        getOrgRelatedData({
          main_entity,
          related_entity,
          main_organisation,
          main_organisation_role,
          related_organisation_role,
          main_people,
          main_people_role,
          related_people_role
        });
      } else {
        if (
          !SavedReportStore.previewReportValues ||
          (SavedReportStore.previewReportValues &&
            SavedReportStore.previewReportValues.type !== 5)
        ) {
          // combined_reports
          SavedReportStore.getMainRoles();
          let main_entity = 'organisation';
          let related_entity = 'organisation';
          let main_organisation = [0];
          let main_organisation_role = [0];
          let related_organisation_role = [0];
          let main_people = [0];
          let main_people_role = [0];
          let related_people_role = [0];
          getOrgRelatedData({
            main_entity,
            related_entity,
            main_organisation,
            main_organisation_role,
            related_organisation_role,
            main_people,
            main_people_role,
            related_people_role
          });
        }
      }
    }, [SavedReportStore, getOrgRelatedData]);

    const handleChange = async () => {
      await setFormDataList(getFormDataList());
      form
        .validateFields()
        .then((data) => {
          setDisabled(false);
        })
        .catch((e) => {
          if (e.errorFields.length > 0) {
            setDisabled(true);
          }
        });
    };

    const getPeopleRole = () => {
      if (
        !SavedReportStore.main_people_roles &&
        form.getFieldValue('main_entity') === 'people'
      ) {
        SavedReportStore.getMainRoles(form.getFieldValue('main_entity'));
      }
    };

    const onFinish = (data) => {
      data.form_list = formDataList;
      data = SavedReportStore.formatFormData({
        ...SavedReportStore.previewReportValues,
        ...data
      });

      data.action = 'save_report';
      handleSubmit(data);
    };

    return (
      <Form
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        id='addeditform '
        className='innerForm'
      >
        {formDataList.includes('report_type') && (
          <Row>
            <Col span={24}>
              <InputComponent
                disabled={
                  SavedReportStore.previewReportValues &&
                  SavedReportStore.previewReportValues.id
                }
                required
                type='radio_button'
                name='report_type'
                onChange={async (data) => {
                  if (form.getFieldValue('generate_on') === 'periods') {
                    await form.setFields([
                      { name: 'generate_on', value: 'all_unarchive_data' }
                    ]);
                  }
                  handleChange(data);
                }}
                options={{
                  values: [
                    { value: 'leads', text: `${t('LeadListing.Leads')}` },
                    { value: 'tags', text: `${t('Common_Data.Tags')}` },
                    { value: 'tickets', text: `${t('Common_Data.Tickets')}` },
                    { value: 'users', text: `${t('ManageAllDepartments.Users')}` },
                    { value: 'reminders', text: `${t('Common_Data.Reminders')}` },
                    { value: 'promotion-bonus', text: `${t('Reports.Promotion')} ${t('Reports.Bonus')}` },
                    { value: 'deal-maker-bonus', text: `${t('Common_Data.Deal')} ${t('Common_Data.Maker')} ${t('Reports.Bonus')}` },
                    { value: 'sample-weekly', text: `${t('Reports.Sample')} ${t('Reports.Weekly')}` }
                  ]
                }}
                rules={vsmTransactionReport.validation.type}
              />
            </Col>
          </Row>
        )}

        <Row gutter={24}>
          <Col span={12}>
            {formDataList.includes('name') && (
              <InputComponent
                required
                label={`${t('Common_Data.Name')}`}
                name='name'
                placeholder={`${t('Reports.Report')} ${t('Common_Data.Name')}`}
                onChange={handleChange}
                tooltip={`${t('Reports.Report_Name_Tooltip')}.`}
                rules={vsmTransactionReport.validation.name}
                maxLength={100}
                autoComplete='off'
              />
            )}
            {formDataList.includes('code') && (
              <InputComponent
                required
                label={`${t('Reports.Code')}`}
                name='code'
                placeholder={`${t('Reports.Report')} ${t('Reports.Code')}`}
                onChange={handleChange}
                tooltip={`${t('Reports.Code_Tooltip')}.`}
                rules={vsmTransactionReport.validation.code}
                maxLength={10}
              />
            )}
          </Col>
          <Col span={12}>
            {formDataList.includes('description') && (
              <InputComponent
                className='default_size_textarea'
                required
                type='textarea'
                label={`${t('Common_Data.Description')}`}
                name='description'
                placeholder={`${t('Reports.Report')} ${t('Common_Data.Description')}`}
                onChange={handleChange}
                tooltip={`${t('Reports.Description_Tooltip')}.`}
                rules={vsmTransactionReport.validation.description}
                maxLength={1000}
              />
            )}
          </Col>
        </Row>

        {formDataList.includes('type') && (
          <InputComponent
            disabled={
              SavedReportStore.previewReportValues &&
              SavedReportStore.previewReportValues.id
            }
            required
            type='radio_button'
            label={`${t('Common_Data.Type')}`}
            name='type'
            onChange={handleChange}
            options={{
              values: [
                { value: 1, text: `${t('Reports.Global')}` },
                { value: 2, text: `${t('Reports.Single')}` },
                { value: 3, text: `${t('Reports.Multiple')}` },
                { value: 4, text: `${t('Reports.Complex')}` },
                { value: 5, text: `${t('Reports.Combined')} ${t('Reports.Report')}${t('Common_Data.S')}` }
              ]
            }}
            tooltip={`${t('Reports.Type_Tooltip')}.`}
            rules={vsmTransactionReport.validation.type}
          />
        )}

        <Row gutter={24}>
          {formDataList.includes('journal_type') && (
            <Col>
              <InputComponent
                label={`${t('Reports.Journal')} ${t('Common_Data.Type')}`}
                required
                type='radio_button'
                name='journal_type'
                onChange={handleChange}
                options={{
                  values: [
                    { value: 'all', text: `${t('Reports.All')}` },
                    { value: 'P&L', text: 'P&L' },
                    { value: 'B/S', text: 'B/S' }
                  ]
                }}
                tooltip={`${t('Reports.Journal_Tooltip')}.`}
                rules={vsmTransactionReport.validation.journal_type}
              />
            </Col>
          )}

          {formDataList.includes('group_on') && (
            <Col>
              <InputComponent
                label={`${t('Common_Data.Group')} ${t('Common_Data.On')}`}
                required
                type='radio_button'
                name='group_on'
                onChange={handleChange}
                options={{
                  className: 'd-flex w-100',
                  values: [
                    { value: 'journals', text: `${t('Reports.Journal')}${t('Common_Data.S')}` },
                    { value: 'models', text: `${t('Common_Data.Model')}${t('Common_Data.S')}` }
                  ]
                }}
                tooltip={`${t('Reports.Group_On_Tooltip')}.`}
                rules={vsmTransactionReport.validation.group_on}
              />
            </Col>
          )}

          {formDataList.includes('currency') && (
            <Col>
              <InputComponent
                label={`${t('Common_Data.Currency')}`}
                required
                type='radio_button'
                name='currency'
                onChange={handleChange}
                options={{
                  values: [
                    { value: 'ledger', text: `${t('Reports.Ledger')}` },
                    { value: 'transaction', text: `${t('Reports.Transaction')}` }
                  ]
                }}
                tooltip={`${t('Reports.Currency_Tooltip')}.`}
                rules={vsmTransactionReport.validation.currency}
              />
            </Col>
          )}
        </Row>

        <Row gutter={24} className='no-wrap'>
          <Col flex='210px' style={{ minWidth: '210px' }}>
            {formDataList.includes('main_entity') && (
              <div className='mt-24'>
                <InputComponent
                  type='radio_button'
                  name='main_entity'
                  options={{
                    values: [
                      { value: 'organisation', text: `${t('Common_Data.Organisation')}` },
                      { value: 'people', text: `${t('Common_Data.People')}` }
                    ]
                  }}
                  onChange={() => {
                    getPeopleRole();
                    getOrgRelatedData();
                    form.resetFields([
                      'main_organisation_role',
                      'main_organisation',
                      'main_people_role',
                      'main_people',
                      'related_organisation_role',
                      'related_organisation',
                      'related_people_role',
                      'related_people'
                    ]);
                  }}
                />
              </div>
            )}
          </Col>
          <Col flex={1}>
            <Row gutter={24}>
              {formDataList.includes('main_organisation_role') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Org')}. ${t('Common_Data.Role')}/s`}
                    type='select'
                    mode='multiple'
                    name='main_organisation_role'
                    placeholder={`${t('Reports.All')}`}
                    onChange={() => {
                      handleChange();
                      getOrgRelatedData();
                      form.resetFields([
                        'main_organisation',
                        'related_organisation_role',
                        'related_organisation',
                        'related_people_role',
                        'related_people'
                      ]);
                    }}
                    options={{
                      values: SavedReportStore.main_organisation_roles,
                      value_key: 'id',
                      text_key: 'role_name'
                    }}
                    tooltip={`${t('Common_Data.People_Role_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('main_organisation') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Organisation')}/s`}
                    type='select'
                    mode='multiple'
                    name='main_organisation'
                    placeholder={`${t('Reports.All')}`}
                    onChange={() => {
                      handleChange();
                      getOrgRelatedData();
                      form.resetFields([
                        'related_organisation_role',
                        'related_organisation',
                        'related_people_role',
                        'related_people'
                      ]);
                    }}
                    tooltip={`${t('Reports.Organisation_Tooltip')}.`}
                    options={{
                      values: SavedReportStore.main_organisation,
                      value_key: 'organisation_id',
                      text_key: 'organisation_name'
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('main_people_role') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.People')} ${t('Common_Data.Role')}/s`}
                    type='select'
                    mode='multiple'
                    name='main_people_role'
                    placeholder={`${t('Reports.All')}`}
                    onChange={() => {
                      handleChange();
                      getOrgRelatedData();
                      form.resetFields([
                        'main_people',
                        'related_people_role',
                        'related_people'
                      ]);
                    }}
                    options={{
                      values: SavedReportStore.main_people_roles,
                      value_key: 'id',
                      text_key: 'role_name'
                    }}
                    tooltip={`${t('Reports.People_Role_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('main_people') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.People')}/s`}
                    type='select'
                    mode='multiple'
                    name='main_people'
                    placeholder={`${t('Reports.All')}`}
                    onChange={() => {
                      handleChange();
                      getOrgRelatedData();
                      form.resetFields(['related_people_role', 'related_people']);
                    }}
                    tooltip={`${t('Reports.People_Tooltip')}.`}
                    options={{
                      values: SavedReportStore.main_people,
                      value_key: 'people_id',
                      text_key: 'people_name'
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={24} className='no-wrap'>
          <Col flex='210px' style={{ minWidth: '210px' }}>
            {formDataList.includes('related_entity') && (
              <div className='mt-24'>
                <InputComponent
                  type='radio_button'
                  name='related_entity'
                  options={{
                    values: [
                      { value: 'organisation', text: `${t('Common_Data.Organisation')}` },
                      { value: 'people', text: `${t('Common_Data.People')}` }
                    ]
                  }}
                  onChange={() => {
                    getOrgRelatedData();
                    form.resetFields(['related_people_role', 'related_people']);
                  }}
                />
              </div>
            )}
          </Col>
          <Col flex={1}>
            <Row gutter={24}>
              {formDataList.includes('related_organisation_role') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Related')} ${t('Reports.Org')}. ${t('Common_Data.Role')}/s`}
                    type='select'
                    mode='multiple'
                    name='related_organisation_role'
                    placeholder={`${t('Reports.All')}`}
                    onChange={() => {
                      handleChange();
                      getOrgRelatedData();
                      form.resetFields(['related_organisation']);
                    }}
                    tooltip={`${t('Reports.Related_Role_Tooltip')}.`}
                    options={{
                      values: SavedReportStore.related_organisation_role,
                      value_key: 'id',
                      text_key: 'role_name'
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('related_organisation') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Related')} ${t('Common_Data.Organisation')}/s`}
                    type='select'
                    mode='multiple'
                    name='related_organisation'
                    placeholder={`${t('Reports.All')}`}
                    onChange={handleChange}
                    tooltip={`${t('Reports.Related_Organisation_Tooltip')}.`}
                    options={{
                      values: SavedReportStore.related_organisation,
                      value_key: 'organisation_id',
                      text_key: 'organisation_name'
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('related_people_role') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Related')} ${t('Common_Data.People')}/s`}
                    type='select'
                    mode='multiple'
                    name='related_people_role'
                    placeholder={`${t('Reports.All')}`}
                    onChange={() => {
                      handleChange();
                      getOrgRelatedData();
                      form.resetFields(['related_people']);
                    }}
                    tooltip={`${t('Reports.People_Role_Relation_Tooltip')}.`}
                    options={{
                      values: SavedReportStore.related_people_role,
                      value_key: 'id',
                      text_key: 'role_name'
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('related_people') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Related')} ${t('Common_Data.People')}/s`}
                    type='select'
                    mode='multiple'
                    name='related_people'
                    placeholder={`${t('Reports.All')}`}
                    onChange={handleChange}
                    tooltip={`${t('Reports.Related_People_Tooltip')}.`}
                    options={{
                      values: SavedReportStore.related_people,
                      value_key: 'people_id',
                      text_key: 'people_name'
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {(formDataList.includes('single_based_on') ||
          formDataList.includes('multiple_based_on')) && (
            <Form.Item className='mb-0' required label={`${t('Reports.Based')} ${t('Common_Data.On')}`}>
              <Row className='w-100 no-wrap'>
                <Col flex='477px'>
                  {formDataList.includes('single_based_on') && (
                    <InputComponent
                      type='radio_button'
                      name='single_based_on'
                      onChange={handleChange}
                      options={{
                        values: [
                          { value: 'single_transaction', text: `${t('Reports.Transaction')}` },
                          { value: 'single_model', text: `${t('Common_Data.Model')}` },
                          { value: 'single_mapping', text: `${t('Common_Data.Mapping')}` },
                          { value: 'single_transaction_type', text: `${t('Reports.Transaction')} ${t('Common_Data.Type')}` },
                          { value: 'single_journal', text: `${t('Reports.Journal')}` }
                        ]
                      }}
                    />
                  )}
                  {formDataList.includes('multiple_based_on') && (
                    <InputComponent
                      type='radio_button'
                      name='multiple_based_on'
                      onChange={handleChange}
                      options={{
                        values: [
                          { value: 'multiple_transaction', text: `${t('Reports.Transaction')}` },
                          { value: 'multiple_model', text: `${t('Common_Data.Model')}` },
                          { value: 'multiple_mapping', text: `${t('Common_Data.Mapping')}` },
                          { value: 'multiple_transaction_type', text: `${t('Reports.Transaction')} ${t('Common_Data.Type')}` },
                          { value: 'multiple_journal', text: `${t('Reports.Journal')}` }
                        ]
                      }}
                    />
                  )}
                </Col>
                <Col flex={1}>
                  {formDataList.includes('single_transaction') && (
                    <InputComponent
                      name='single_transaction'
                      placeholder={`${t('Reports.Transaction')} ${t('Common_Data.ID')}`}
                      hint={`${t('Reports.Transaction_ID_Tooltip')}.`}
                      onChange={handleChange}
                      rules={vsmTransactionReport.validation.single_transaction}
                    />
                  )}
                  {formDataList.includes('single_model') && (
                    <InputComponent
                      type='select'
                      name='single_model'
                      placeholder={`${t('Common_Data.Model')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: SavedReportStore.dropdown_models,
                        value_key: 'id',
                        text_key: 'model_name'
                      }}
                      hint={`${t('Reports.Choose_Transaction')}.`}
                      rules={vsmTransactionReport.validation.single_model}
                    />
                  )}
                  {formDataList.includes('single_mapping') && (
                    <InputComponent
                      type='select'
                      name='single_mapping'
                      placeholder={`${t('Common_Data.Mapping')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: TransactionFieldMappingStore.dropdown_list,
                        value_key: 'id',
                        text_key: 'mapping_name'
                      }}
                      hint={`${t('Common_Data.Choose')} ${t('Reports.Transaction')} ${t('Common_Data.Mapping')}.`}
                      rules={vsmTransactionReport.validation.single_mapping}
                    />
                  )}
                  {formDataList.includes('single_transaction_type') && (
                    <InputComponent
                      type='select'
                      name='single_transaction_type'
                      placeholder={`${t('Reports.Transaction')} ${t('Common_Data.Type')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: TransactionTypeStore.dropdown_list,
                        value_key: 'id',
                        text_key: 'type_name'
                      }}
                      hint={`${t('Reports.Transaction_Tooltip')}.`}
                      rules={vsmTransactionReport.validation.single_transaction_type}
                    />
                  )}
                  {formDataList.includes('single_journal') && (
                    <InputComponent
                      type='select'
                      name='single_journal'
                      placeholder={`${t('Reports.Journal')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: TransactionModelStore.journal_list,
                        value_key: 'id',
                        text_key: { key: ['journal_code', ' - ', 'journal_label'] }
                      }}
                      hint={`${t('Reports.Transaction_Tooltip')}.`}
                      rules={vsmTransactionReport.validation.single_journal}
                    />
                  )}
                  {formDataList.includes('multiple_transaction') && (
                    <InputComponent
                      mode='tags'
                      type='select'
                      name='multiple_transaction'
                      placeholder={`${t('Reports.Transaction')} ${t('Common_Data.ID')}`}
                      onChange={handleChange}
                      options={{
                        values: []
                      }}
                      notFoundContent={null}
                      hint={`${t('Reports.Transaction_ID_Tooltip')}.`}
                      rules={vsmTransactionReport.validation.multiple_transaction}
                    />
                  )}
                  {formDataList.includes('multiple_model') && (
                    <InputComponent
                      mode='multiple'
                      type='select'
                      name='multiple_model'
                      placeholder={`${t('Common_Data.Model')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: SavedReportStore.dropdown_models,
                        value_key: 'id',
                        text_key: 'model_name'
                      }}
                      hint={`${t('Reports.Choose_Transaction')}.`}
                      rules={vsmTransactionReport.validation.multiple_model}
                    />
                  )}
                  {formDataList.includes('multiple_mapping') && (
                    <InputComponent
                      mode='multiple'
                      type='select'
                      name='multiple_mapping'
                      placeholder={`${t('Common_Data.Mapping')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: TransactionFieldMappingStore.dropdown_list,
                        value_key: 'id',
                        text_key: 'mapping_name'
                      }}
                      hint={`${t('Common_Data.Choose')} ${t('Reports.Transaction')} ${t('Common_Data.Mapping')}.`}
                      rules={vsmTransactionReport.validation.multiple_mapping}
                    />
                  )}
                  {formDataList.includes('multiple_transaction_type') && (
                    <InputComponent
                      mode='multiple'
                      type='select'
                      name='multiple_transaction_type'
                      placeholder={`${t('Reports.Transaction')} ${t('Common_Data.Type')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: TransactionTypeStore.dropdown_list,
                        value_key: 'id',
                        text_key: 'type_name'
                      }}
                      hint={`${t('Reports.Transaction_Tooltip')}.`}
                      rules={vsmTransactionReport.validation.multiple_transaction_type}
                    />
                  )}
                  {formDataList.includes('multiple_journal') && (
                    <InputComponent
                      mode='multiple'
                      type='select'
                      name='multiple_journal'
                      placeholder={`${t('Reports.Journal')}`}
                      className='w-100'
                      onChange={handleChange}
                      options={{
                        values: TransactionModelStore.journal_list,
                        value_key: 'id',
                        text_key: { key: ['journal_code', ' - ', 'journal_label'] }
                      }}
                      hint={`${t('Reports.Transaction_Tooltip')}.`}
                      rules={vsmTransactionReport.validation.multiple_journal}
                    />
                  )}
                </Col>
              </Row>
            </Form.Item>
          )}
        <Row gutter={24}>
          {formDataList.includes('cte_banks') && (
            <Col span={12}>
              <InputComponent
                label={`${t('Reports.Bank')}/s`}
                type='select'
                mode='multiple'
                name='cte_banks'
                onChange={() => {
                  handleChange();
                  form.resetFields(['cte_bank_accounts']);
                }}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.Bank')}/s`}
                options={{
                  values: LedgerBankAccountStore.bankNameList,
                  value_key: 'id',
                  text_key: 'name'
                }}
                tooltip={`${t('Reports.Bank_Tooltip')}.`}
              />
            </Col>
          )}
          {formDataList.includes('cte_bank_accounts') && (
            <Col span={12}>
              <InputComponent
                label={`${t('Reports.Bank')} ${t('Reports.Account')}/s`}
                type='select'
                mode='multiple'
                name='cte_bank_accounts'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.Bank')} ${t('Reports.Account')}/s`}
                options={{
                  values: CTEImportStore.bank_list,
                  value_key: 'id',
                  text_key: 'bankAccount_names',
                  rejected_keys:
                    form.getFieldValue('cte_banks') &&
                    form.getFieldValue('cte_banks').length > 0 &&
                    CTEImportStore.bank_list &&
                    CTEImportStore.bank_list.map((x) => x.id),
                  accepted_key:
                    CTEImportStore.bank_list &&
                    CTEImportStore.bank_list.map((x) =>
                      form.getFieldValue('cte_banks') &&
                        form.getFieldValue('cte_banks').includes(x.bank_id)
                        ? x.id
                        : null
                    )
                }}
                tooltip={`${t('Reports.Bank_Account_Tooltip_Purpose')}.`}
              />
            </Col>
          )}
          {formDataList.includes('cte_classification') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <InputComponent
                label={`${t('Reports.Cash')} ${t('Reports.Transaction')} ${t('Common_Data.Classification')}/s`}
                type='select'
                mode='multiple'
                name='cte_classification'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Classification')}/s`}
                options={{
                  values: ClassificationStore.classificationList,
                  value_key: 'id',
                  text_key: 'transaction_class_name'
                }}
                tooltip={`${t('Reports.Classification_Tooltip')}.`}
              />
            </Col>
          )}
          {formDataList.includes('transaction_type') && (
            <Col span={12}>
              <InputComponent
                label={`${t('Reports.Transaction')} ${t('Common_Data.Type')}/s`}
                type='select'
                mode='multiple'
                name='transaction_type'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.One_Many')} ${t('Reports.Transaction')} ${t('Common_Data.Type')}/s`}
                options={{
                  values: TransactionTypeStore.dropdown_list,
                  value_key: 'id',
                  text_key: 'type_name'
                }}
                tooltip={`${t('Reports.Transaction_Tooltip')}.`}
              />
            </Col>
          )}
          {formDataList.includes('cte_transaction_type') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <InputComponent
                label={`${t('Reports.Transaction')} ${t('Common_Data.Type')}/s`}
                type='select'
                mode='multiple'
                name='cte_transaction_type'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.One_Many')} ${t('Reports.Transaction')} ${t('Common_Data.Type')}/s`}
                options={{
                  values: SavedReportStore.cteTypesList,
                  value_key: 'id',
                  text_key: 'text'
                }}
                tooltip={`${t('Reports.Transaction_Tooltip')}.`}
              />
            </Col>
          )}

          {formDataList.includes('cte_currency') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <InputComponent
                label={`${t('Common_Data.Currency')}/s`}
                type='select'
                mode='multiple'
                name='cte_currency'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Currency')}/s`}
                options={{
                  values: LedgerDetailStore.currency_list,
                  value_key: 'id',
                  text_key: 'currency_name'
                }}
                tooltip={`${t('Common_Data.Currency_Tooltip')}.`}
              />
            </Col>
          )}

          {formDataList.includes('cte_tags') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <InputComponent
                label={`${t('Reports.Tag')}/s`}
                type='select'
                mode='multiple'
                name='cte_tags'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.One_More')} ${t('Reports.Tag')}/s`}
                options={{
                  values: RoleMappingStore.dropdown_tags_list,
                  value_key: 'id',
                  text_key: 'tag_name'
                }}
                tooltip={`${t('Reports.Tags_Tooltip')}.`}
              />
            </Col>
          )}

          {formDataList.includes('cte_exchange_id') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <InputComponent
                label={`${t('Common_Data.Exchange')} ${t('Common_Data.Id')}`}
                name='cte_exchange_id'
                placeholder={`${t('Common_Data.Exchange')} ${t('Common_Data.Id')}`}
                tooltip={`${t('Common_Data.Exchange')} ${t('Common_Data.ID')} ${t('Reports.Numerical_Value')} ${t('Common_Data.Organisation')} ${t('Common_Data.Role')} ${t('Common_Data.In')} ${t('Common_Data.Exchange')} 2.0 ${t('Reports.Software')}.`}
                onChange={handleChange}
                rules={vsmTransactionReport.validation.cte_exchange_id}
              />
            </Col>
          )}
          {formDataList.includes('additional_columns') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <InputComponent
                label={`${t('Common_Data.Select')} ${t('Reports.Additional')} ${t('Reports.Column')}${t('Common_Data.S')} ${t('Common_Data.For')} ${t('Common_Data.Export')}`}
                type='select'
                mode='multiple'
                name='additional_columns'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.Additional')} ${t('Reports.Column')}${t('Common_Data.S')}`}
                options={{
                  values: [
                    { value: 'Bank Account No.', text: `${t('Reports.Bank')} ${t('Reports.Account')} ${t('Common_Data.No')}.` },
                    { value: 'Valued On', text: `${t('Reports.Valued')} ${t('Common_Data.On')}` },
                    { value: 'Customer Reference', text: `${t('Reports.Customer')} ${t('Common_Data.Reference')}` },
                    { value: 'Bank Reference', text: `${t('Reports.Bank')} ${t('Common_Data.Reference')}` },
                    { value: 'Ledger Role Mapping ID', text: `${t('Reports.Ledger')} ${t('Common_Data.Role')} ${t('Common_Data.Mapping')} ${t('Common_Data.ID')}` },
                    {
                      value: 'Organisation / People Role',
                      text: `${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Role')}`
                    },
                    {
                      value: 'Related Organisation / People Role',
                      text: `${t('Reports.Related')} ${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Role')}`
                    },
                    { value: 'Currency', text: `${t('Common_Data.Currency')}` },
                    { value: 'Financial Year', text: `${t('Reports.Financial')} ${t('Reports.Year')}` },
                    { value: 'Reconciled Status', text: `${t('Reports.Reconciled')} ${t('Common_Data.Status')}` },
                    {
                      value: 'Reconciled TTE Transaction ID ',
                      text: `${t('Reports.Reconciled')} ${t('Reports.TTE')} ${t('Reports.Transaction')} ${t('Common_Data.ID')}`
                    }
                  ]
                }}
                tooltip={`${t('Reports.Additional_Tooltip')}.`}
              />
            </Col>
          )}
          {formDataList.includes('cte_bank_ref') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <Form.Item className='mb-0' label={`${t('Reports.Bank')} ${t('Common_Data.Reference')}`}>
                <div className='w-100'>
                  <BankRefElement onChange={handleChange} />
                </div>
              </Form.Item>
            </Col>
          )}

          {formDataList.includes('cte_customer_ref') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <Form.Item className='mb-0' label={`${t('Reports.Customer')} ${t('Common_Data.Reference')}`}>
                <div className='w-100'>
                  <CustomerRefElement onChange={handleChange} />
                </div>
              </Form.Item>
            </Col>
          )}

          {formDataList.includes('org_people_bank_iban') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <Form.Item className='mb-0' label={`${t('Reports.Org')}/${t('Common_Data.People')} ${t('Reports.Bank')} ${t('Reports.IBAN')}`}>
                <div className='w-100'>
                  <OrgPeopleBankIBANElement onChange={handleChange} />
                </div>
              </Form.Item>
            </Col>
          )}

          {formDataList.includes('org_people_bank_acc_no') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <Form.Item className='mb-0' label={`${t('Reports.Org')}/${t('Common_Data.People')} ${t('Reports.Bank')} ${t('Reports.Account')} ${t('Common_Data.No')}`}>
                <div className='w-100'>
                  <OrgPeopleBankAccNoElement onChange={handleChange} />
                </div>
              </Form.Item>
            </Col>
          )}

          {formDataList.includes('journals') && (
            <Col span={12}>
              <InputComponent
                label={`${t('Reports.Journal')}/s`}
                type='select'
                mode='multiple'
                name='journals'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.One_Many')} ${t('Reports.Journal')}/s`}
                options={{
                  values: TransactionModelStore.journal_list,
                  value_key: 'id',
                  text_key: { key: ['journal_code', ' - ', 'journal_label'] }
                }}
                tooltip={`${t('Reports.Transaction_Tooltip')}.`}
              />
            </Col>
          )}

          {formDataList.includes('transaction_mappings') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <InputComponent
                label={`${t('Common_Data.Type')} ${t('Common_Data.Mapping')}/s`}
                type='select'
                mode='multiple'
                name='transaction_mappings'
                onChange={handleChange}
                placeholder={`${t('Common_Data.Select')} ${t('Reports.One_Many')} ${t('Reports.Transaction')} ${t('Common_Data.Type')} ${t('Common_Data.Mapping')}/s`}
                options={{
                  values: TransactionFieldMappingStore.dropdown_list,
                  value_key: 'id',
                  text_key: 'mapping_name'
                }}
                tooltip={`${t('Common_Data.Choose')} ${t('Reports.Transaction')} ${t('Common_Data.Mapping')}.`}
              />
            </Col>
          )}

          {formDataList.includes('transaction_ids') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <Form.Item className='mb-0' label={`${t('Reports.Transaction')} ${t('Common_Data.ID')}`}>
                <div className='w-100'>
                  <TradeIdElement onChange={handleChange} />
                </div>
              </Form.Item>
            </Col>
          )}

          {formDataList.includes('field_values') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <Form.Item className='mb-0' label={`${t('Reports.Field')} ${t('Common_Data.Value')}${t('Common_Data.S')}`}>
                <div className='w-100'>
                  <FieldValuesElement onChange={handleChange} form={form} />
                </div>
              </Form.Item>
            </Col>
          )}

          {formDataList.includes('transaction_dates') && (
            <Col span={12}>
              <Divider style={{ marginTop: -10 }}>{t('Reports.AND')}</Divider>
              <Form.Item className='mb-0' label={`${t('Reports.Transaction')} ${t('Common_Data.Date')}/s`}>
                <div className='w-100'>
                  <TransactionDateElement onChange={handleChange} />
                </div>
              </Form.Item>
            </Col>
          )}
        </Row>

        {formDataList.includes('generate_on') && (
          <InputComponent
            required
            type='radio_button'
            label={`${t('Common_Data.Generate')} ${t('Common_Data.On')}`}
            name='generate_on'
            onChange={handleChange}
            options={{
              values: [
                { value: 'all_unarchive_data', text: `${t('Reports.All')} ${t('Reports.Unarchived')} ${t('Common_Data.Data')}` },
                { value: 'date_range', text: `${t('Common_Data.Date')} ${t('Reports.Range')}/s` },
                { value: 'financial_year', text: `${t('Reports.Financial')} ${t('Reports.Year')}` },
                { value: 'periods', text: `${t('Reports.Period')}` }
              ],
              rejected_keys:
                form.getFieldValue('report_type') === 'leads' ? null : ['periods']
            }}
            tooltip={`${t('Reports.Group_Tooltip')}.`}
            rules={vsmTransactionReport.validation.generate_on}
          />
        )}

        {formDataList.includes('date_selection') && (
          <DateSelectionElement
            SetDateRange={SetDateRange}
            disabledDate={disabledDate}
            formDataList={formDataList}
            onChange={handleChange}
          />
        )}

        {formDataList.includes('financial_year') && (
          <div className='w400'>
            <InputComponent
              label={`${t('Reports.Financial')} ${t('Reports.Year')}`}
              required
              type='select'
              name='financial_year'
              placeholder={`${t('Reports.Financial')} ${t('Reports.Year')}`}
              onChange={handleChange}
              options={{
                values: SavedReportStore.financial_year_list
              }}
              tooltip={`${t('Reports.Financial_Year_Tooltip')}.`}
              rules={vsmTransactionReport.validation.financial_year}
            />
          </div>
        )}

        {formDataList.includes('period_selection') && (
          <PeriodSelectionElement formDataList={formDataList} onChange={handleChange} />
        )}

        {formDataList.includes('combined_reports') && (
          <div className='w600'>
            <h1>{t('Common_Data.Select')} {t('Reports.Multiple')} {t('Reports.Report')} {t('Reports.Below')}, {t('Common_Data.Up')} {t('Common_Data.To')} 12 {t('Common_Data.Reports')}</h1>
            <CombinedReportsElement onChange={handleChange} form={form} />
          </div>
        )}

        {formDataList.includes('audit_group_on') && (
          <InputComponent
            required
            label={`${t('Reports.Audit')} ${t('Common_Data.Group')} ${t('Common_Data.On')}`}
            onChange={handleChange}
            type='radio_button'
            name='audit_group_on'
            options={{
              values: [
                { value: 'day', text: `${t('Reports.Daily')}` },
                { value: 'week', text: `${t('Reports.Weekly')}` },
                { value: 'month', text: `${t('Reports.Monthly')}` },
                { value: 'year', text: `${t('Reports.Yearly')}` }
              ]
            }}
            tooltip={`${t('Reports.Audit')} ${t('Common_Data.Group')} ${t('Common_Data.On')}`}
          />
        )}

        {formButtons}
      </Form>
    );
  }
);

export default FormComponent;
