import React from 'react'
import { Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const DeleteComponent = observer((props) => {
	const {t} = useTranslation();

	return (
		<Modal
			centered
			title={`${t('DealBuilder.Delete_Condition_Block')}`}
			visible={true}
			onCancel={() => props.close()}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" onClick={() => { props.removeBlock(props.item_key); props.close() }} type="primary" danger>{t('Common_Data.Delete')}</Button>,
				<Button key="2" htmlType="button" onClick={() => { props.close() }}>{t('Common_Data.Cancel')}</Button>
			]}
		>
			{
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>{t('DealBuilder.Remove_Condition_Block')}</h3>
					</Col>
				</Row>
			}
		</Modal>
	)
})

export default DeleteComponent
