import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row, Form } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { vsmTransactionReport } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const BankRefElement = observer(({ onChange }) => {
  const {t} = useTranslation()
  return (
    <Form.List name='cte_bank_ref'>
      {(fields, { add, remove }) => {
        return (
          fields &&
          fields.map((field, index) => {
            return (
              <div className='w-100' key={field.key}>
                <Row gutter={10}>
                  <Col flex={1}>
                    <InputComponent
                      required
                      name={[field.name]}
                      key={[field.key]}
                      onChange={onChange}
                      placeholder={`${t('Reports.Bank')} ${t('Common_Data.Reference')}`}
                      tooltip={`${t('Reports.Bank')} ${t('Reports.Bank_Placeholder')}123 ${t('Reports.Unique_Name')} (${t('Reports.Organisation_Relation')})`}
                      rules={vsmTransactionReport.validation.cte_bank_ref}
                    />
                  </Col>
                  <Col>
                    <div className='d-flex'>
                      {fields.length > 1 && (
                        <Button
                          type='danger'
                          onClick={() => remove(field.name)}
                          icon={<MinusOutlined />}
                        ></Button>
                      )}
                      {index === fields.length - 1 && (
                        <Button
                          className={fields.length > 1 ? 'ml-10' : null}
                          type='primary'
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })
        );
      }}
    </Form.List>
  );
});

export default BankRefElement;
