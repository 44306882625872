import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Form, Tooltip, Select, Spin } from 'antd';
import useStore from '../../../store';
import debounce from 'lodash/debounce';
import { vsmReminders } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DropDownSelection = observer((props) => {
  const { t } = useTranslation()
  const { typeValue } = props;
  const { Option, OptGroup } = Select;
  const [usersSelected, setUsersSelected] = useState([]);
  const [tagsListKey, setTagsListKey] = useState();
  const [ticketListKey, setTicketListKey] = useState();
  const [leadListKey, setLeadListKey] = useState();
  const [personContactsListKey, setPersonContactsListKey] = useState();
  const [organisationContactsListKey, setOrganisationContactsListKey] = useState();
  const [disabled, setDisabled] = useState(true);

  const {
    REMINDERSSTORE,
    DEALDESKSTORE: { deal_data },
    SPANNOTESTORE: {
      fetching,
      ticket_list_data,
      searchTicket,
      tags_list_data,
      searchTags,
      lead_list_data,
      //searchLead,
      searchLeadSOLDName,
      contacts_list_data,
      searchContacts,
      searchPersonContacts,
    },
  } = useStore();

  useEffect(() => {
    if (props.isDeal || !props.isAdd) {
      setDisabled(true);
    } else {
      if (typeValue === null || typeValue === undefined || typeValue === 'private') {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [typeValue, props.isDeal, props.isAdd]);

  // search string for dropdown
  const handleSearch = useMemo(() => {
    const loadOptions = (val) => {
      if (typeValue === 'tags') {
        if (val && val.length >= 3) {
          setTagsListKey(val);
          searchTags({ search_text: val });
        }
      } else if (typeValue === 'tickets') {
        if (val && val.length >= 3) {
          setTicketListKey(val);
          searchTicket({ search_text: val });
        }
      } else if (typeValue === 'leads') {
        if (val && val.length >= 3) {
          setLeadListKey(val);
          searchLeadSOLDName({ search_text: val });
        }
      } else if (typeValue === 'contacts') {
        if (val && val.length >= 3) {
          setPersonContactsListKey(val);
          searchPersonContacts({ search_text: val });
        }
      } else if (typeValue === 'organisations') {
        if (val && val.length >= 3) {
          setOrganisationContactsListKey(val);
          searchContacts({ search_text: val });
        }
      }
    };

    return debounce(loadOptions, 500);
  }, [
    typeValue,
    searchTags,
    searchTicket,
    searchLeadSOLDName,
    searchContacts,
    searchPersonContacts,
  ]);

  const handleChange = (value, name) => {
    setUsersSelected(name);
    props.onChange();
  };

  const selectedUsers = (
    <ul className='m-0 p-0' style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
      {usersSelected?.length ? (
        usersSelected.map((items, index) => <li key={index}>{items.name}</li>)
      ) : (
        <li>{t('Reminders.Not_Users_Selected')}</li>
      )}
    </ul>
  );

  return (
    <Tooltip
      placement='leftTop'
      title={usersSelected?.length > 0 ? selectedUsers : null}
      trigger='hover'
    >
      {(typeValue === null || typeValue === undefined || typeValue === 'private') && (
        <Form.Item label={`${t('Common_Data.Reminder')} ${t('Reminders.For')}`} tooltip={`${t('Common_Data.Reminder')} ${t('Reminders.Created')} ${t('Common_Data.For')}`} name='userSelect'>
          <Select disabled={disabled} placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Reminders.For')}`}></Select>
        </Form.Item>
      )}
      {typeValue === 'tags' && (
        <Form.Item
          label={`${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
          tooltip={`${t('Common_Data.Reminder')} ${t('Reminders.Created')} ${t('Common_Data.For')}`}
          name='tag_id'
          required
          rules={vsmReminders.validation.reminder_for}
        >
          <Select
            name='personName'
            className='personName'
            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
            type='select'
            showSearch
            showArrow
            allowClear={true}
            filterOption={false}
            onChange={handleChange}
            notFoundContent={
              fetching ? <Spin size='small' /> : tagsListKey ? `${t('Common_Data.No_Record_Found')}!` : null
            }
            onSearch={handleSearch}
            onClear={handleSearch}
            disabled={disabled}
          >
            {tags_list_data?.length ? (
              <OptGroup label={`${t('LeadListing.Notes')}`}>
                {tags_list_data?.map((item, index) => (
                  <Option value={item.id} name={item.tag_subject} key={index}>
                    {`${item.tag_number} | ${item.tag_subject}`}
                  </Option>
                ))}
              </OptGroup>
            ) : REMINDERSSTORE.editValues?.reminder_type === typeValue ? (
              <OptGroup label={`${t('LeadListing.Notes')}`}>
                <Option
                  value={REMINDERSSTORE.editValues.reminder_for}
                  name={REMINDERSSTORE.editValues.reminder_for}
                  key='1'
                >
                  {REMINDERSSTORE.editValues.subject}
                </Option>
              </OptGroup>
            ) : null}
          </Select>
        </Form.Item>
      )}
      {typeValue === 'tickets' && (
        <Form.Item
          label={`${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
          tooltip={`${t('Common_Data.Reminder')} ${t('Reminders.Created')} ${t('Common_Data.For')}`}
          name='ticket_id'
          required
          rules={vsmReminders.validation.reminder_for}
        >
          <Select
            name='personName'
            className='personName'
            type='select'
            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
            showSearch
            showArrow
            allowClear={true}
            filterOption={false}
            onChange={handleChange}
            notFoundContent={
              fetching ? <Spin size='small' /> : ticketListKey ? `${t('Common_Data.No_Record_Found')}!` : null
            }
            onSearch={handleSearch}
            onClear={handleSearch}
            disabled={disabled}
          >
            {ticket_list_data?.length ? (
              <OptGroup label={`${t('Common_Data.Tickets')}`}>
                {ticket_list_data?.map((item, index) => (
                  <Option value={item.id} name={item.ticket_subject} key={index}>
                    {`${item.ticket_number} | ${item.ticket_subject}`}
                  </Option>
                ))}
              </OptGroup>
            ) : REMINDERSSTORE.editValues?.reminder_type === typeValue ? (
              <OptGroup label={`${t('Common_Data.Tickets')}`}>
                <Option
                  value={REMINDERSSTORE.editValues.reminder_for}
                  name={REMINDERSSTORE.editValues.reminder_for}
                  key='1'
                >
                  {REMINDERSSTORE.editValues.subject}
                </Option>
              </OptGroup>
            ) : deal_data?.lead_information?.ticket_data ? (
              <OptGroup label={`${t('Common_Data.Tickets')}`}>
                <Option
                  value={deal_data?.lead_information?.ticket_data?.id}
                  name={deal_data?.lead_information?.ticket_data?.id}
                  key='1'
                >
                  {`${deal_data?.lead_information?.ticket_data?.ticket_number} | ${deal_data?.lead_information?.ticket_data?.ticket_subject}`}
                </Option>
              </OptGroup>
            ) : null}
          </Select>
        </Form.Item>
      )}
      {typeValue === 'leads' && (
        <Form.Item
          label={`${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
          tooltip={`${t('Common_Data.Reminder')} ${t('Reminders.Created')} ${t('Common_Data.For')}`}
          name='leads_id'
          required
          rules={vsmReminders.validation.reminder_for}
        >
          <Select
            name='personName'
            className='personName'
            type='select'
            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
            showSearch
            showArrow
            allowClear={true}
            filterOption={false}
            onChange={handleChange}
            notFoundContent={
              fetching ? <Spin size='small' /> : leadListKey ? `${t('Common_Data.No_Record_Found')}!` : null
            }
            onSearch={handleSearch}
            onClear={handleSearch}
            disabled={disabled}
          >
            {lead_list_data?.length ? (
              <OptGroup label={`${t('Common_Data.Sold')}`}>
                {lead_list_data?.map((item, index) => (
                  <Option value={item.id} name={item.opportunity_name} key={index}>
                    {`${item.id} | ${item.opportunity_name}`}
                  </Option>
                ))}
              </OptGroup>
            ) : REMINDERSSTORE.editValues?.reminder_type === typeValue ? (
              <OptGroup label={`${t('Common_Data.Sold')}`}>
                <Option
                  value={REMINDERSSTORE.editValues.reminder_for}
                  name={REMINDERSSTORE.editValues.reminder_for}
                  key='1'
                >
                  {REMINDERSSTORE.editValues.reminder_for_name}
                </Option>
              </OptGroup>
            ) : deal_data?.lead_information?.is_ticket === 1 ? (
              <OptGroup label={`${t('Common_Data.Sold')}`}>
                <Option
                  value={deal_data?.lead_information?.id}
                  name={
                    deal_data?.lead_information?.opportunity_name
                      ? deal_data?.lead_information?.opportunity_name
                      : deal_data?.lead_information?.sold_organisation?.organisation_name
                  }
                  key='1'
                >
                  {deal_data?.lead_information?.opportunity_name
                    ? deal_data?.lead_information?.opportunity_name
                    : deal_data?.lead_information?.sold_organisation?.organisation_name}
                </Option>
              </OptGroup>
            ) : deal_data?.lead_information?.organisation_name ? (
              <OptGroup label={`${t('Common_Data.Sold')}`}>
                <Option
                  value={deal_data?.lead_information?.sold_organisation?.id}
                  name={deal_data?.lead_information?.sold_organisation?.id}
                  key='1'
                >
                  {deal_data?.lead_information?.sold_organisation?.organisation_name}
                </Option>
              </OptGroup>
            ) : null}
          </Select>
        </Form.Item>
      )}
      {typeValue === 'contacts' && (
        <Form.Item
          label={`${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
          tooltip={`${t('Common_Data.Reminder')} ${t('Reminders.Created')} ${t('Common_Data.For')}`}
          name='contacts_id'
          required
          rules={vsmReminders.validation.reminder_for}
        >
          <Select
            name='personName'
            className='personName'
            type='select'
            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
            showSearch
            showArrow
            allowClear={true}
            filterOption={false}
            onChange={handleChange}
            notFoundContent={
              fetching ? (
                <Spin size='small' />
              ) : personContactsListKey ? (
                `${t('Common_Data.No_Record_Found')}!`
              ) : null
            }
            onSearch={handleSearch}
            onClear={handleSearch}
            disabled={disabled}
          >
            {contacts_list_data?.people && contacts_list_data?.people.length ? (
              <OptGroup label={`${t('Common_Data.People')}`}>
                {contacts_list_data?.people?.map((item, index) => (
                  <Option
                    value={'p' + item.id}
                    name={item.first_name + ' ' + item.last_name}
                    key={index}
                  >
                    {`${item.first_name} ${item.last_name} | ${item.people_contact_info}`}
                  </Option>
                ))}
              </OptGroup>
            ) : REMINDERSSTORE.editValues?.reminder_type === typeValue ? (
              <OptGroup label={`${t('Common_Data.People')}`}>
                <Option
                  value={REMINDERSSTORE.editValues.reminder_for}
                  name={REMINDERSSTORE.editValues.reminder_for}
                  key='1'
                >
                  {`${REMINDERSSTORE.editValues.reminder_for_name}`}
                </Option>
              </OptGroup>
            ) : deal_data?.lead_information?.people ? (
              <OptGroup label={`${t('Common_Data.People')}`}>
                <Option
                  value={`p${deal_data?.lead_information?.people?.id}`}
                  name={deal_data?.lead_information?.people?.id}
                  key='1'
                >
                  {`${deal_data?.lead_information?.people?.full_name} ${deal_data?.lead_information?.email[0]
                    ? '|' + deal_data?.lead_information?.email[0].contact_value
                    : null
                    } ${deal_data?.lead_information?.email[0]
                      ? '|' + deal_data?.lead_information?.phone[0].contact_value
                      : null
                    }`}
                </Option>
              </OptGroup>
            ) : null}
          </Select>
        </Form.Item>
      )}
      {typeValue === 'organisations' && (
        <Form.Item
          label={`${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
          tooltip={`${t('Common_Data.Reminder')} ${t('Reminders.Created')} ${t('Common_Data.For')}`}
          name='organisations_id'
          required
          rules={vsmReminders.validation.reminder_for}
        >
          <Select
            name='personName'
            className='personName'
            type='select'
            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Reminders.For')}`}
            showSearch
            showArrow
            allowClear={true}
            filterOption={false}
            onChange={handleChange}
            notFoundContent={
              fetching ? (
                <Spin size='small' />
              ) : organisationContactsListKey ? (
                `${t('Common_Data.No_Record_Found')}!`
              ) : null
            }
            onSearch={handleSearch}
            onClear={handleSearch}
            disabled={disabled}
          >
            {contacts_list_data?.organisation &&
              contacts_list_data?.organisation.length ? (
              <OptGroup label={`${t('Common_Data.Organisation')}`}>
                {contacts_list_data?.organisation.map((item, index) => (
                  <Option value={'o' + item.id} name={item.organisation_name} key={index}>
                    {`${item.organisation_name} | ${item.org_contact_info}`}
                  </Option>
                ))}
              </OptGroup>
            ) : REMINDERSSTORE.editValues?.reminder_type === typeValue ? (
              <OptGroup label={`${t('Common_Data.Organisation')}`}>
                <Option
                  value={'o' + REMINDERSSTORE.editValues.reminder_for}
                  name={REMINDERSSTORE.editValues.reminder_for}
                  key='1'
                >
                  {`${REMINDERSSTORE.editValues.reminder_for_name} `}
                </Option>
              </OptGroup>
            ) : deal_data?.lead_information?.organisation_name ? (
              <OptGroup label={`${t('Common_Data.Organisation')}`}>
                <Option
                  value={`o${deal_data?.lead_information?.sold_organisation?.id}`}
                  name={deal_data?.lead_information?.sold_organisation?.id}
                  key='1'
                >
                  {`${deal_data?.lead_information?.sold_organisation?.organisation_name
                    } ${deal_data?.lead_information?.email[0]
                      ? '|' + deal_data?.lead_information?.email[0].contact_value
                      : ''
                    }  ${deal_data?.lead_information?.email[0]
                      ? '|' + deal_data?.lead_information?.phone[0].contact_value
                      : ''
                    }`}
                </Option>
              </OptGroup>
            ) : null}
          </Select>
        </Form.Item>
      )}
    </Tooltip>
  );
});
export default DropDownSelection;
