import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import {
  vsmPeopleRoleMapping,
  vsmNotify,
} from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { PEOPLE, PEOPLEMAPPINGSTORE } = useStore();
  const [saving, setSaving] = useState(false);

  // Make function call to delete existing record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = PEOPLEMAPPINGSTORE.deleteValues.id;
    PEOPLEMAPPINGSTORE.DeleteData(data, PEOPLE.peopleValues.id)
      .then(() => {
        close();
        if (props.handleViewDetailCall) {
          props.handleViewDetailCall({ id: PEOPLE.peopleValues.id });
        }
        vsmNotify.success({
          message: vsmPeopleRoleMapping.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  return PEOPLEMAPPINGSTORE.deleteValues ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${t('PeopleManagement.Role')} ${t('Common_Data.Mapping')} ${t('Common_Data.With')} ${PEOPLE.peopleValues && PEOPLE.peopleValues.first_name
        } ${PEOPLE.peopleValues &&
        (PEOPLE.peopleValues.last_name
          ? PEOPLE.peopleValues.last_name
          : '')
        }`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteformPeopleRoleMapping'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformPeopleRoleMapping' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('PeopleManagement.Remove_Role_Mapping')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
