import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify, vsmKnowledge } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const { AUTH, KNOWLEDGE,SEARCHSTORE } = useStore();
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    KNOWLEDGE.deleteValues = null;
    form.resetFields();
    setSaving(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    KNOWLEDGE.deleteKnowledgeData(KNOWLEDGE.deleteValues)
      .then(() => {
        close();

        if (!props?.SearchPage) {
          KNOWLEDGE.getList();
        }else {
          KNOWLEDGE.agGrid.api.applyTransaction({ remove: [KNOWLEDGE.deleteValues] });
          SEARCHSTORE.updateSearchCount({ knowledge_count: SEARCHSTORE.searchCount.knowledge_count - 1 });
        }
        
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmKnowledge.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return KNOWLEDGE.deleteValues ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${t('DealDesk.Knowledge')} - ${KNOWLEDGE?.deleteValues?.subject.length > 20 ? KNOWLEDGE?.deleteValues?.subject.substring(0, 20)+'...' : KNOWLEDGE?.deleteValues?.subject}`}
      visible={props.visible}
      onCancel={close}
      onOk={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form={'deleteKnowledgeForm'}
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          danger
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id={`deleteKnowledgeForm`} onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {`${t('Knowledge.You_Want_Delete')}?`}
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
