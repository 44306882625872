import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../../store';
import FormComponent from './FormComponent';
// import FormComponentNew from './FormComponentNew';
import { vsmNotify, vsmPeople } from '../../../../config/messages';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { PEOPLE, AUTH } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [fileList, updateFileList] = useState([]);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    if (data.phone && data.phone.length > 0) {
      data.phone = data.phone.filter((x) => x && x.contact_for && x.contact_value);
      data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null;
    }
    //if (addAddress) {
    if (data.address && data.address.length > 0) {
      data.address = data.address.filter((x) => x && x);
      data.address = data.address.length > 0 ? JSON.stringify(data.address) : null;
    } else {
      delete data.address; // = ;//null;
    }
    // } else {
    //   data.address = null;
    // }

    if (data.email && data.email.length > 0) {
      data.email = data.email.filter((x) => x && x.contact_for && x.contact_value);
      data.email = data.email.length > 0 ? JSON.stringify(data.email) : null;
    }
    setSaving(true);
    var formData = PEOPLE.JsonToFormData(data);
    //formData.append('add_address', addAddress === true ? 1 : 0);
    formData.append('add_address', data.address && data.address.length > 0 ? 1 : 0);
    if (
      data.photo_url &&
      data.photo_url.fileList[0] &&
      data.photo_url.fileList.length > 0
    ) {
      formData.append('photo_url', data.photo_url.fileList[0].originFileObj);
    } else {
      formData.append('photo_url', '');
    }
    formData.append(
      'date_of_birth',
      data.date_of_birth ? moment(data.date_of_birth).format('Y-MM-DD') : ''
    );
    PEOPLE.AddData(formData)
      .then((data) => {
        if (props.setPeopleDropDown) {
          props.setPeopleDropDown(data.id);
        }
        close();
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmPeople.add
        });
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        let checkbox = form.getFieldValue('add_address');
        let address = form.getFieldValue('address');
        address = address && address.filter((x) => x && x);
        if (checkbox && (!address || (address && address.length === 0))) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <Drawer
      key='4'
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      title={`${t('Common_Data.Add')} ${t('Common_Data.People')}`}
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          <Button
            key='1'
            form='addfrom'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
            shape='round'
            size='medium'
          >
            {t('Common_Data.Save')}
          </Button>
          <Button shape='round' size='medium' onClick={close} key='2'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>
      ]}
    >
      <FormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        addAddress={addAddress}
        setAddAddress={setAddAddress}
        updateFileList={updateFileList}
        fileList={fileList}
        id='addfrom'
      />
      {/* <FormComponentNew
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        addAddress={addAddress}
        setAddAddress={setAddAddress}
        updateFileList={updateFileList}
        fileList={fileList}
        id='addfrom'
      /> */}
    </Drawer>
  );
});

export default AddComponent;
