import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { Image, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const MergeOrganisationListing = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    ORGANISATION: { setMasterOrgToMerge },
  } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        checkboxSelection: true,
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`,
        field: 'organisation_name',
        tooltipField: 'organisation_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        headerTooltip: `${t('Common_Data.Credebt_Classification')}`,
        field: 'global_industries.name',
        tooltipField: 'global_industries.name',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('OrganisationManagement.Trade')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('OrganisationManagement.Trade')} ${t('Common_Data.Name')}`,
        field: 'trade_name',
        tooltipField: 'trade_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('OrganisationManagement.Role')} (${t('OrganisationManagement.Associated')} ${t('Common_Data.Organisation')})`,
        headerTooltip: `${t('OrganisationManagement.Role')} (${t('OrganisationManagement.Associated')} ${t('Common_Data.Organisation')})`,
        field: 'organisation_role_mapping',
        tooltipValueGetter: (params) =>
          params.data &&
            params.data?.organisation_role_mapping &&
            params.data?.organisation_role_mapping.length > 0
            ? params.data?.organisation_role_mapping.map((item, i) => {
              return item.role_name + '(' + item.organisation_name + ')';
            })
            : '',
        valueGetter: (params) =>
          params.data &&
            params.data?.organisation_role_mapping &&
            params.data?.organisation_role_mapping.length > 0
            ? params.data?.organisation_role_mapping.map((item, i) => {
              return item.role_name + '(' + item.organisation_name + ')';
            })
            : '',
        filter: false,
        sortable: false,
        wrapText: false,
      },
      {
        headerName: `${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`,
        field: 'registration_number',
        tooltipField: 'registration_number',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Country')}`,
        headerTooltip: `${t('Common_Data.Country')}`,
        field: 'global_countries.country_name',
        tooltipField: 'global_countries.country_name',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        headerTooltip: `${t('Common_Data.Status')}`,
        field: 'status.status_name',
        tooltipField: 'status.status_name',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('OrganisationManagement.Logo')}`,
        headerTooltip: `${t('OrganisationManagement.Logo')}`,
        field: 'logo_url',
        cellRendererFramework: function (data) {
          return data?.data && data?.data?.logo_url ? (
            <div className='text-center'>
              <Image
                style={{ height: '46px', width: 'auto' }}
                src={process.env.React_APP_API_URL + data?.data?.logo_url}
                alt=''
              />
            </div>
          ) : (
            ''
          );
        },

        filter: false,
        sortable: false,
      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        headerTooltip: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        // tooltipField: 'updated_at',
        filter: 'agDateColumnFilter',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={AUTH.getLocalTime(params?.data?.updated_at,AUTH.global_fulldate_format)}>
              {AUTH.getLocalTime(params?.data?.updated_at,AUTH.global_fulldate_format)}
            </Tooltip>
          )
        },
        // cellRenderer: (params) => {
        //   return AUTH.getLocalTime(params?.data?.updated_at);
        // },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `#${t('Common_Data.ID')}`,
        field: 'id',
        tooltipField: 'id',
        filter: 'agNumberColumnFilter',
        width: 120,
      },
      {
        headerName: `${t('OrganisationManagement.Exchange_Ref')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('OrganisationManagement.Exchange_Ref')} ${t('Common_Data.ID')}`,
        field: 'crm_id',
        tooltipField: 'crm_id',
        filter: 'agTextColumnFilter',
        width: 80,
      },
    ],
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: '70vh',
        }}
      >
        <AgGridReact
          rowData={props.selectedMergeData}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            width: 120,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{}}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: false,
            animateRows: true,
          }}
          rowSelection={'single'}
          suppressRowClickSelection={true}
          onRowSelected={(params) => {
            let selectedItems = params.api.getSelectedNodes().length;
            if (selectedItems) {
              setMasterOrgToMerge(params?.data?.id);
            } else {
              setMasterOrgToMerge(null);
            }
          }}
        />
      </div>
    </>
  );
});
export default MergeOrganisationListing;
