import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DateTimeConfirmComponent = observer((props) => {
  const {t} = useTranslation()
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const { AUTH, TagsStore, PRODUCTIVITYSTORE, MANAGEMENTALLDEPARTMENTSSTORE } =
    useStore();
  const location = useLocation();

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    var data = {
      reminder_type: 'tags',
      reminder_for: props?.reminderConfirm?.id,
      reminder_date_time: moment(props?.date).format(AUTH.form_submit_date_with_minutes),
      hourglass: false,
    };
    setSaving(true);
    AUTH.ResetData(data)
      .then(() => {
        close();
        if (props?.resetNotificationData) {
          props.resetNotificationData();
        }
        if (location.pathname === '/productivity') {
          PRODUCTIVITYSTORE.setupGrid(PRODUCTIVITYSTORE.agGrid);
        } else if (location.pathname === '/management/all-departments') {
          MANAGEMENTALLDEPARTMENTSSTORE.getList();
        } else {
          TagsStore.setupGrid(TagsStore.agGrid);
        }
        vsmNotify.success({
          message: `${t('Common_Data.Reminder_Updated')}!`,
        });
      })
      .catch((e) => {})
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Reset')} ${t('Tags.Tag')} ${t('Common_Data.Reminder')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='dateTimeForm'
          htmlType='submit'
          type='primary'
          shape='round'
          loading={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='dateTimeForm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('Tags.Reset_Reminder_Tag')}{' '}
              <strong>#{props?.reminderConfirm?.tag_number}</strong> {t('Common_Data.To')}{' '}
              <strong>
                {moment(props?.date).format(AUTH.global_dateformat + ' hh:mm A')}
              </strong>
              ?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  );
});

export default DateTimeConfirmComponent;
