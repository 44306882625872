import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Form,
  Row,
  Col,
  Upload,
  Typography,
  Divider,
  Spin,
  message,
} from 'antd';
import useStore from '../../../store';
import { PlusOutlined } from '@ant-design/icons';
import InputComponent from '../../../component/InputComponent';
import { vsmContracts } from '../../../config/messages';
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';

const { Title } = Typography;

const FormComponent = observer(
  ({ handleSubmit, onChange, form, id, setImgchanged, fileList, updateFileList }) => {
    const {
      CONTRACTS,
      ORGANISATION,
      PEOPLE,
      CONTRACTS: { editValues },
    } = useStore();
    const { t } = useTranslation();
    const [fetchCurrencylist, setFetchCurrencylist] = useState([]);

    const [imgDisabled, setImgDisabled] = useState(false);

    const [startValue, setStartValue] = useState(null);
    const [endValue, setEndValue] = useState(null);
    const [endOpen, setEndOpen] = useState(false);
    const [searchPplKey, setSearchPplKey] = useState()
    const [searchOrgKey, setSearchOrgKey] = useState()
    const [searchOrgsKey, setSearchOrgsKey] = useState()

    const onFinish = (data) => {
      handleSubmit(data);
    };

    const eventProps = {
      onChange: ({ fileList: newFileList }) => {
        updateFileList(newFileList);
      },
      fileList,
      beforeUpload: (file) => {
        let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        let isLt1M = file.size / 1024 / 1024 < 1;

        if (!isJpgOrPng) {
          message.error(`${t('Contracts.Upload_Valid_Image')}.`);
          setImgDisabled(true);
          return true;
        } else if (!isLt1M) {
          message.error(`${t('Contracts.File_Size')} 500${t('Contracts.Px')} x 500${t('Contracts.Px')} ${t('Contracts.And')} 1 ${t('Contracts.Mb')}`);
          setImgDisabled(true);
          return true;
        } else {
          setImgchanged(true);
          updateFileList([file]);
          setImgDisabled(false);
          return false;
        }
      },
    };

    // Handle on remove image
    const onRemoveImage = () => {
      form.setFields([{ name: 'contract_file_path', errors: [] }]);
      updateFileList([]);
      setImgDisabled(false);
      onChange();
    };

    /**START: Date Range Related Functions */
    const disabledStartDate = (startValue) => {
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    };

    const disabledEndDate = (endValue) => {
      if (!endValue || !startValue) {
        return false;
      }
      return endValue.valueOf() <= startValue.valueOf();
    };

    const onDateChange = (field, value) => {
      if (field === 'startValue') {
        setStartValue(value);
      } else if (field === 'endValue') {
        setEndValue(value);
      }
    };

    const onStartChange = (value) => {
      onDateChange('startValue', value);
      onChange();
    };

    const onEndChange = (value) => {
      onDateChange('endValue', value);
      onChange();
    };

    const handleStartOpenChange = (open) => {
      if (!open) {
        setEndOpen(true);
      }
    };

    const handleEndOpenChange = (open) => {
      setEndOpen(open);
    };
    /**END : Date Range Related Functions */

    const uploadButton = (
      <div>
        <PlusOutlined />
      </div>
    );

    const handleSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchPplKey(val);
        PEOPLE.getPeopleSearchList({ search_for: val, mapping_type: 2 })
      };

      return debounce(loadOptions, 500);
    }, [PEOPLE])

    // search string for dropdown
    const handleOrgSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchOrgKey(val);
        ORGANISATION.getAllROrg({ search_for: val, mapping_type: 1, selected_id: [form.getFieldValue('global_organisation_id')] });
      };

      return debounce(loadOptions, 500);
    }, [ORGANISATION, form]);

    // search string for dropdown
    const handleOrgsSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchOrgsKey(val);
        ORGANISATION.getAllROrg({ search_for: val, mapping_type: 1, selected_id: [form.getFieldValue('company_signed_by')] });
      };

      return debounce(loadOptions, 500);
    }, [ORGANISATION, form]);

    return (
      <Form
        form={form}
        layout='vertical'
        className='innerForm'
        id={id}
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col span={6}>
            <InputComponent
              name='contract_owner'
              label={`${t('Contracts.Contract_Owner')}`}
              placeholder={`${t('Contracts.Contract_Owner')}`}
              type='input'
              tooltip={`${t('Contracts.Name_Of_Owner')}`}
              required
              onChange={onChange}
              rules={vsmContracts.validation.contract_owner}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              name='contract_number'
              label={`${t('Contracts.Contract_Number')}`}
              placeholder={`${t('Contracts.Contract_Number')}`}
              type='input'
              tooltip={`${t('Contracts.Number_Contract')}`}
              required
              onChange={onChange}
              rules={vsmContracts.validation.contract_number}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              name='global_organisation_id'
              label={`${t('Contracts.Organisation_Name')}`}
              placeholder={`${t('Contracts.Organisation_Name_Select')}`}
              type='select'
              tooltip={`${t('Contracts.Organisation_Name_Associate')}`}
              required
              showSearch allowClear
              onChange={onChange}
              onSearch={handleOrgsSearch}
              options={{
                values:
                  ORGANISATION.dropdown_ROrg_list ||
                  (editValues &&
                    editValues.global_organisation && [
                      {
                        id: editValues.global_organisation.id,
                        entity_name:
                          editValues.global_organisation.organisation_name,
                      },
                    ]),
                value_key: 'id',
                text_key: 'entity_name',
              }}
              notFoundContent={ORGANISATION.fetching ? <Spin size="small" /> : searchOrgsKey ? `${t('Contracts.No_Record_Found')}.` : null}
              rules={vsmContracts.validation.global_organisation_id}
            />
          </Col>

          <Col span={6}>
            <Form.Item
              label={`${t('Contracts.Contract_Start_Date')}`}
              tooltip={`${t('Contracts.Start_Date')}`}
              required={true}
            >
              <Form.Item
                name='contract_start_date'
                noStyle
                rules={vsmContracts.validation.contract_start_date}
                onChange={onChange}
              >
                <DatePickerCostume
                  name='contract_start_date'
                  label={`${t('Contracts.Contract_Start_Date')}`}
                  disabledDate={disabledStartDate}
                  format='YYYY-MM-DD'
                  value={startValue}
                  placeholder={`${t('Contracts.Start')}`}
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={`${t('Contracts.Contract_End_Date')}`}
              tooltip={`${t('Contracts.End_Date')}`}
              required={true}
            >
              <Form.Item
                name='contract_end_date'
                noStyle
                onChange={onChange}
                rules={vsmContracts.validation.contract_end_date}
              >
                <DatePickerCostume
                  name='contract_end_date'
                  label={`${t('Contracts.Contract_End_Date')}`}
                  disabledDate={disabledEndDate}
                  format='YYYY-MM-DD'
                  value={endValue}
                  placeholder={`${t('Contracts.End')}`}
                  open={endOpen}
                  onChange={onEndChange}
                  onOpenChange={handleEndOpenChange}
                />
              </Form.Item>
            </Form.Item>
          </Col>

          <Col span={6}>
            <InputComponent
              name='contract_months'
              label={`${t('Contracts.Contract_Term_months')}`}
              type='input'
              tooltip={`${t('Contracts.Term_Of_Contract')}`}
              required
              onChange={onChange}
              rules={vsmContracts.validation.contract_months}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              name='contract_status'
              label={`${t('Contracts.Status')}`}
              placeholder={`${t('Contracts.Select_Status')}`}
              type='select'
              tooltip={`${t('Contracts.Status_Of_Contract')}`}
              required
              onChange={onChange}
              options={{
                values: [
                  {
                    value: 2,
                    text: `${t('Contracts.Activated')}`,
                  },
                  {
                    value: 3,
                    text: `${t('Contracts.Draft')}`,
                  },
                  {
                    value: 1,
                    text: `${t('Contracts.In_Approval_Process')}`,
                  },
                ],
              }}
              rules={vsmContracts.validation.contract_status}
            />
          </Col>
          <Col span={6}>
            <Row gutter={0}>
              <Col span={24}>
                <InputComponent
                  name='currency_id'
                  label={`${t('Contracts.Contract_Currency')}`}
                  placeholder={`${t('Contracts.Contract_Currency')}`}
                  type='select'
                  tooltip={`${t('Contracts.Contract_Tooltip')}`}
                  required
                  showSearch
                  onChange={onChange}
                  options={{
                    values:
                      CONTRACTS.dropdown_global_currency_list ||
                      (editValues &&
                        editValues.currency && [
                          {
                            id: editValues.currency.id,
                            currency_name: editValues.currency.currency_name,
                          },
                        ]),
                    value_key: 'id',
                    text_key: 'currency_name',
                  }}
                  onFocus={() =>
                    fetchCurrencylist &&
                    CONTRACTS.getGlobalCurrencyList().then(() =>
                      setFetchCurrencylist(false)
                    )
                  }
                  notFoundContent={
                    fetchCurrencylist ? <Spin size='small' /> : `${t('Contracts.No_Record_Found')}.`
                  }
                  rules={vsmContracts.validation.currency_id}
                />
              </Col>
              <Col span={24}>
                <InputComponent
                  name='owner_expiration_notice'
                  label={`${t('Contracts.Owner_Expiration_Notice')}:`}
                  placeholder={`${t('Contracts.Select_Owner_Expiration_Notice')}`}
                  type='select'
                  onChange={onChange}
                  tooltip={`${t('Contracts.Select_Owner_Expiration_Notice')}`}
                  options={{
                    values: [
                      {
                        value: 15,
                        text: `15 Days`,
                      },
                      {
                        value: 30,
                        text: `30 Days`,
                      },
                      {
                        value: 45,
                        text: `45 Days`,
                      },
                      {
                        value: 60,
                        text: `60 Days`,
                      },
                      {
                        value: 90,
                        text: `90 Days`,
                      },
                      {
                        value: 120,
                        text: `120 Days`,
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={18}>
            <InputComponent
              name='price_book_description'
              label={`${t('Contracts.Price_Book_Description')}:`}
              type='textarea'
              tooltip={`${t('Contracts.Price_Book_Description')}`}
              onChange={onChange}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>
        </Row>

        <Divider className='mt-10 mb-20' />
        <Row gutter={15}>
          <Col span={18}>
            <Title level={3} className='page-title'>
              {t('Contracts.Description_Information')}
            </Title>
            <Row gutter={15}>
              <Col span={24}>
                <InputComponent
                  name='special_terms'
                  label={`${t('Contracts.Special_Terms')}:`}
                  type='input'
                  tooltip={`${t('Contracts.The_Special_Terms')}`}
                  onChange={onChange}
                  rules={vsmContracts.validation.special_terms}
                />
              </Col>

              <Col span={24}>
                <InputComponent
                  name='description'
                  label={`${t('Contracts.Description')}:`}
                  type='textarea'
                  tooltip={`${t('Contracts.The_Description_To_Any_contract')}`}
                  onChange={onChange}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  rules={vsmContracts.validation.description}
                />
              </Col>

              <Col span={24}>
                <Form.Item
                  label={`${t('Contracts.Contract_File')}`}
                  tooltip={`${t('Contracts.Contract_File')}`}
                  name='contract_file_path'
                  onChange={onChange}
                >
                  <Upload
                    accept='.png, .jpeg'
                    fileList={fileList}
                    onRemove={onRemoveImage}
                    className='uploadContract'
                    multiple={false}
                    showUploadList={true}
                    disabled={imgDisabled}
                    {...eventProps}
                  >
                    {fileList && fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Title level={3} className='page-title'>
              {t('Contracts.Signature_Information')}
            </Title>
            <Row gutter={15}>
              <Col span={24}>
                <InputComponent
                  name='customer_signed_title'
                  label={`${t('Contracts.Customer_Signed_Title')}:`}
                  placeholder={`${t('Contracts.Customer_Signed_Title')}`}
                  type='input'
                  onChange={onChange}
                  tooltip={`${t('Contracts.Contract_Signed_By_The_Customer')}`}
                />
              </Col>
              <Col span={24}>
                <InputComponent
                  name='customer_signed_by'
                  label={`${t('Contracts.Customer_Signed_By')}:`}
                  placeholder={`${t('Contracts.Select_Customer_Signed_By')}`}
                  type='select'
                  tooltip={`${t('Contracts.Person_Who_Signed_The_Contract')}`}
                  required
                  showSearch allowClear
                  onChange={onChange}
                  onSearch={handleSearch}
                  options={{
                    values:
                      PEOPLE.dropdown_RPpl_list ||
                      (editValues &&
                        editValues.customer_signed && [
                          {
                            id: editValues.customer_signed.id,
                            entity_name:
                              editValues?.customer_signed?.first_name +
                              ' ' +
                              editValues?.customer_signed?.last_name,
                          },
                        ]),
                    value_key: 'id',
                    text_key: 'entity_name',
                    rejected_keys: editValues && [editValues?.id],
                  }}
                  notFoundContent={PEOPLE.fetching ? <Spin size="small" /> : searchPplKey ? `${t('Contracts.No_Record_Found')}.` : null}
                  rules={vsmContracts.validation.customer_signed_by}
                />
              </Col>
              <Col span={24}>
                <InputComponent
                  name='customer_signed_date'
                  label={`${t('Contracts.Customer_Signed_Date')}:`}
                  placeholder={`${t('Contracts.Format')}`}
                  type='date'
                  tooltip={`${t('Contracts.Date_When_Customer_Signed_Contract')}`}
                  onChange={onChange}
                />
              </Col>
              <Col span={24}>
                <InputComponent
                  name='company_signed_by'
                  label={`${t('Contracts.Company_Signed_By')}:`}
                  placeholder={`${t('Contracts.Select_Company_Signed_By')}`}
                  type='select'
                  tooltip={`${t('Contracts.Person_From_Company_Who_Signed_Contract')}`}
                  required
                  onChange={onChange}
                  onSearch={handleOrgSearch}
                  showSearch allowClear
                  options={{
                    values:
                      ORGANISATION.dropdown_ROrg_list ||
                      (editValues?.company_signed && [
                        {
                          id: editValues?.company_signed.id,
                          entity_name:
                            editValues?.company_signed?.organisation_name,
                        },
                      ]),
                    value_key: 'id',
                    text_key: 'entity_name',
                  }}
                  notFoundContent={ORGANISATION.fetching ? <Spin size="small" /> : searchOrgKey ? `${t('Contracts.No_Record_Found')}.` : null}
                  rules={vsmContracts.validation.company_signed_by}
                />
              </Col>
              <Col span={24}>
                <InputComponent
                  name='company_signed_date'
                  label={`${t('Contracts.Company_Signed_Date')}:`}
                  placeholder={`${t('Contracts.Format')}`}
                  type='date'
                  tooltip={`${t('Contracts.Date_When_Company_Signed_Contract')}`}
                  onChange={onChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default FormComponent;
