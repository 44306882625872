import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class StructureLibraryStore {
  structure_library_data = null;
  CopiedStructureData = null;
  CopiedScreenData = null;
  CopiedScrunitiseData = null;
  CopiedShortfallData = null;
  Screen = [];
  Shortfall = [];
  Structure = [];
  Scrunitise = [];
  savestructureform = null;
  editValues = null;
  tabChanged = '3';
  apiCalled = false;

  notifyAutosave = false;

  setEditValues = (data) => {
    this.editValues = data;
  };

  setTabChanged = (data) => {
    this.tabChanged = data;
    this.apiCalled = false;
  };

  saveStructureData = (id, formdata, structure_id) => {
    let APIURL = `deal-desk/structure-library`;
    if (id) {
      APIURL = `deal-desk/create-leads-structure/${id}`;
    }
    if (structure_id) {
      //Used - Strucutre Update
      APIURL = `deal-desk/structure-library/${structure_id}`;
    }
    return Axios.post(APIURL, formdata)
      .then(({ data }) => {
        if (formdata.is_saved) {
          this.notifyAutosave = true;
        }

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  fetchStructureLibrary = (payload = {}) => {
    this.apiCalled = true;
    return Axios.post(`deal-desk/structure-library-list`, payload)
      .then(({ data }) => {
        this.structure_library_data = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  SetStructure = (data) => {
    this.Structure = data;
  };

  SetScrunitise = (data) => {
    this.Scrunitise = data;
  };

  SetScreen = (data) => {
    this.Screen = data;
  };
  SetShortfall = (data) => {
    this.Shortfall = data;
  };
  setCopiedStructureData = (data) => {
    this.CopiedStructureData = data;
  };
  setCopiedScreenData = (data) => {
    this.CopiedScreenData = data;
  };

  setCopiedScrunitiseData = (data) => {
    this.CopiedScrunitiseData = data;
  };
  setCopiedShortfallData = (data) => {
    this.CopiedShortfallData = data;
  };

  setSavestructureform = (data) => {
    this.savestructureform = data;
  };
  calculateHeightOnEdit = () => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };
  setNotifyAutosave = (data) => {
    this.notifyAutosave = data;
  };
}
decorate(StructureLibraryStore, {
  structure_library_data: observable,
  Structure: observable,
  Scrunitise: observable,
  Screen: observable,
  Shortfall: observable,
  editValues: observable,
  CopiedStructureData: observable,
  CopiedScreenData: observable,
  CopiedScrunitiseData: observable,
  CopiedShortfallData: observable,
  tabChanged: observable,
  apiCalled: observable,
  notifyAutosave: observable,
  saveStructureData: action,
  fetchStructureLibrary: action,
  SetStructure: action,
  setCopiedStructureData: action,
  setSavestructureform: action,
  setEditValues: action,
  SetScreen: action,
  SetShortfall: action,
  setTabChanged: action,
  calculateHeightOnEdit: action,
  setNotifyAutosave: action,
  setCopiedScreenData: action,
  setCopiedScrunitiseData: action,
  setCopiedShortfallData: action,
  SetScrunitise: action,
});
