import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Row, Col } from 'antd';
import FormComponent from './FormComponentNew';
import debounce from 'lodash/debounce';
import useStore from '../../../store';
import { vsmTicket, vsmNotify } from '../../../config/messages';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { ageDifferenceInDays } from '../../../utils/GlobalFunction';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const EditComponent = observer((props) => {
  const { t } = useTranslation()
  const { SearchPage, viewOpen = false, isFromTicketDesk = false } = props;
  const {
    AUTH,
    TicketsStore,
    TicketsStore: { editTicket, editValues },
    DEALDESKSTORE,
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: { fetchSequenceNotes },
    PRODUCTIVITYSTORE,
    // SEARCHSTORE,
    DEALDESKNOTESEQUENCE
  } = useStore();

  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const location = useLocation();

  const [isMasterTicket, setMasterTicket] = useState();
  const [isOrgTicket, setOrgTicket] = useState(null);
  const [smallDeadlineDate, setSmallDeadlineDate] = useState(false);

  const onChangeDeadline = debounce((event) => {
    if (event <= moment().subtract(1, 'seconds')) {
      setSmallDeadlineDate(true)
    }
    else {
      setSmallDeadlineDate(false)
    }
  }, 200);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
    setSaving(false);
    // TicketsStore.editValues = null;
    setMasterTicket(false);
    setOrgTicket(false);
    // setEditValues(null)
  };

  const handleSubmit = (data) => {
    data.hours_required = 0
    data.hours_required_hour = data.hours_required_hour ? data.hours_required_hour : 0
    data.hours_required_min = data.hours_required_min ? data.hours_required_min : 0
    if (data.hours_required_hour || data.hours_required_min) {

      let convertHourToMinInt = (data.hours_required_hour && parseInt(data.hours_required_hour * 60))
      let convertminitueToMinInt = (data.hours_required_min && parseInt(data.hours_required_min))

      let setTotalTime = (convertHourToMinInt && convertHourToMinInt) + (convertminitueToMinInt && convertminitueToMinInt)
      data.hours_required = parseInt(setTotalTime) * 60
    }
    data.deadline_date = data.deadline_date && moment(data.deadline_date).format(AUTH.form_submit_date_with_seconds);

    if (!data.ticket_type) {
      delete data.ticket_type;
    }

    let organisationID = data.organisation_id && data.organisation_id
    if (organisationID) {
      if (organisationID === 'Credebt Exchange' || organisationID === 1) {
        data.organisation_id = 1
      } else {
        data.organisation_id = organisationID
      }
    }
    else {
      data.organisation_id = ''
    }

    setSaving(true);
    editTicket(editValues.id, data)
      .then((response) => {
        if (location.pathname === '/productivity') {
          PRODUCTIVITYSTORE.setupGrid(PRODUCTIVITYSTORE.agGrid);
        }
        if (isFromTicketDesk) {
          if (editValues?.ticket_holder?.id !== data?.holder) {
            fetchSequenceNotes(deal_data?.lead_information?.id, null, true);
          }
        }
        if (viewOpen) {
          data.isDataModified = true;
          response.data.age = response.data.created_at
            ? ageDifferenceInDays(new Date(response.data.created_at))
            : 1;
          response.data.idle = response.data.updated_at
            ? ageDifferenceInDays(new Date(response.data.updated_at))
            : 1;
          TicketsStore.viewValues = response.data;

          TicketsStore.setisDataModified(true);
        }
        if (SearchPage) {
          TicketsStore.getListSearch(TicketsStore.search_payload);
          // SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'tickets');
        }
        close();
        form.resetFields();
        if (location.pathname === '/ticket') {
          DEALDESKSTORE.getDealDetail(editValues?.ticket_number);
          DEALDESKNOTESEQUENCE.fetchSequenceNotes(editValues?.leads_transactions_id ? editValues?.leads_transactions_id : editValues?.leads_transaction?.id);
        }
        vsmNotify.success({
          message: vsmTicket.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };
  const handleChange = debounce(() => {
    // setSubjectInput(form.getFieldValue("ticket_subject"));
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // let getHours = editValues?.hours_required / 60 / 60;
  // // let setTime = editValues?.hours_required_converted && editValues?.hours_required_converted.split(":")
  // let setTime = getHours && String(getHours).split('.')
  // let setHour = setTime && setTime[0];
  // let setMin = setTime && setTime[1];
  // var minutes = Math.floor((setMin));

  var decimalTime = editValues?.hours_required;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));
  useEffect(() => {
    if (editValues) {
      // setSubjectInput(editValues.ticket_subject)
      setMasterTicket(editValues?.parent_ticket_id ? true : false)
      setOrgTicket(editValues?.global_organisation_id ? true : false)
      form.setFieldsValue({
        hours_required_hour: hours && hours,
        hours_required_min: minutes && minutes,
        is_masterTicket: editValues?.parent_ticket_id ? true : false,
        ticket_subject: editValues.ticket_subject,
        is_org: editValues?.organisation?.id ? true : false,
        organisation_id: editValues.global_organisation_id
          ? editValues.global_organisation_id
          : null,
        parent_ticket_id: editValues.parent_ticket_id
          ? editValues.parent_ticket_id
          : null,
        holder: editValues.ticket_holder && editValues.ticket_holder.id,
        ticket_status: editValues.ticket_status && editValues.ticket_status.id,
        ticket_description: editValues.ticket_description,
        ticket_type: editValues.ticket_type,
        priority: editValues.priority,
        deadline_date: editValues.deadline_date && moment(editValues.deadline_date),
        // deadline_date: editValues.deadline_date
        //   // ? AUTH.getLocalTime(editValues.deadline_date, 'YYYY-MM-DD hh:mm a')
        //   ? moment(editValues.deadline_date, 'YYYY-MM-DD HH:mm')
        //   : null,
        // deadline_time: editValues.deadline_time
        //   ? moment(editValues.deadline_time, 'h:mm:ss a')
        //   : null,
        is_deadlinetime: editValues.deadline_time ? true : false,
      });
    }
  }, [editValues, form, minutes, hours]);

  // useEffect(() => {
  //   if (isOrg) {
  //     form.setFieldsValue({
  //       organisation_id: "Credebt Exchange"
  //     })
  //   } else {
  //     form.setFieldsValue({
  //       organisation_id: ""
  //     })
  //   }
  // }, [isOrg, form]);

  /**This is called on onBlur event on subject input*/
  // const handleBlur = () => {
  //   if (subjectInput && isMasterTicket && !isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput} (Master)` });
  //     setSubjectInput(`${subjectInput} (Master)`);
  //   }
  //   if (subjectInput && !isMasterTicket && isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput.replace(/\(Master\)/gi, '')}` });
  //     setSubjectInput(`${subjectInput.replace(/\(Master\)/gi, '')}`);
  //   }
  // }

  /**This is called on onChange of Master Ticket Check box event on subject input*/
  // const handleCheck = () => {
  //   if (subjectInput && !isMasterTicket && !isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput} (Master)` })
  //   }
  //   if (subjectInput && isMasterTicket && isIncludeMasterInSubjectInput) {
  //     form.setFieldsValue({ ticket_subject: `${subjectInput.replace(/\(Master\)/gi, '')}` })
  //   }
  // }

  return (
    <>
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={`${viewOpen ? '1050px' : '1100px'}`}
        title={`${t('Common_Data.Edit')} ${t('Common_Data.Ticket')} - #${editValues?.ticket_number ? editValues?.ticket_number : ''
          }`}
        zIndex={viewOpen ? 1002 : 1001}
        destroyOnClose={true}
        footer={[
          <>
            <Row justify="space-between" align="middle">
              <Col span={11}>
                <div className='text-left' key='1'>
                  <Row>
                    <Col span={16}>
                      <strong>{t('Common_Data.Created')} {t('Common_Data.Date')}:</strong>{' '}
                      {editValues?.created_date
                        ? AUTH.getLocalTimeDealDesk(
                          editValues?.created_date,
                          AUTH.global_dateformat
                        )
                        : `xxxx-xx-xx`}<br />
                      <strong>{t('Common_Data.Modified')} {t('Common_Data.Date')}:</strong>{' '}
                      {editValues?.updated_date
                        ? AUTH.getLocalTimeDealDesk(
                          editValues?.updated_date,
                          AUTH.global_dateformat
                        )
                        : `xxxx-xx-xx`}

                    </Col>
                    <Col span={8}>
                      <strong>{t('Tickets.Age')}{`(${t('Tickets.Day')}(s))`}:</strong> {editValues?.age}<br />
                      <strong>{t('Common_Data.Idle')}{`(${t('Tickets.Day')}(s))`}:</strong> {editValues?.idle}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={13}>
                <div className='text-right' key='5'>
                  <Button
                    key='3'
                    form='editTicketform'
                    className='mr-10'
                    loading={saving}
                    htmlType='submit'
                    type='primary'
                    shape='round'
                    disabled={smallDeadlineDate ? true : isDisabled}
                  >
                    {t('Common_Data.Update')}
                  </Button>
                  <Button shape='round' onClick={close} key='4'>
                    {t('Common_Data.Cancel')}
                  </Button>
                </div>
              </Col>
            </Row>
            {/* {props.openViewDrawer && !viewOpen && (
              <img
                className='menuicon'
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '20px',

                  right: '60px',
                  width: '20px',
                }}
                alt=''
                title={`${t('Common_Data.View')} ${t('Common_Data.Ticket')}`}
                src={AUTH.GetThemedImage('View')}
                onClick={() => props.openViewDrawer(editValues, true)}
              />
            )} */}
            {editValues?.ticket_number &&
              <Link
                to={`/ticket/${editValues?.ticket_number}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  className='menuicon'
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '15px',
                    right: '20px',
                    width: '20px',
                  }}
                  alt=''
                  title={`${t('Common_Data.View')} ${t('Tickets.Chat')} ${t('Tickets.History')}`}
                  src={AUTH.GetThemedImage('Tickets')}
                />
              </Link>
            }
          </>
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          visible={props.visible}
          id='editTicketform'
          isMasterTicket={isMasterTicket}
          setMasterTicket={setMasterTicket}
          isOrgTicket={isOrgTicket}
          setOrgTicket={setOrgTicket}
          onChangeDeadline={onChangeDeadline}
          smallDeadlineDate={smallDeadlineDate}
          isDisabled={isDisabled}
        // onBlurSubject={handleBlur}
        // onCheck={handleCheck}
        />
        {/* {editValues && !viewOpen && (
          <TrackTimeComponent
            pageName={'tickets'}
            leadId={
              editValues?.leads_transactions_id
                ? editValues?.leads_transactions_id
                : 0
            }
          />
        )} */}
      </Drawer>
      <TimeTracker
        page={"tickets"}
        id={editValues?.leads_transactions_id}
        stop={!props.visible}
      />

    </>
  );
});

export default EditComponent;
