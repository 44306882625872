import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Row, Col } from 'antd';
import FormComponent from './FormComponentNew';
import debounce from 'lodash/debounce';
import useStore from '../../../store';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { vsmNotify, vsmLeadsList } from '../../../config/messages';
import { default as AddPeopleComponent } from '../../PeopleManagement/People/component/AddComponentNew';
import { default as AddOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/AddComponentNew';
import { default as EditPeopleComponent } from '../../PeopleManagement/People/component/EditComponentNew';
import { default as EditOrgComponent } from '../../OrganisationManagement/AllOrganisations/component/EditComponentNew';

// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const EditComponent = observer((props) => {
  const {
    AUTH,
    PEOPLE,
    LEADSSTORE,
    // LEADSEARCH,
    LEADSSTORE: { editValues },
    ORGANISATION,
    //ORGANISATION: { getMappedOrg },
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    PROMOTIONSTORE,
    SEARCHSTORE,
    BLOCKEDITASSIGNSTORE,
    DEALDESKSTORE,
    DEALDESKNOTESOUTBOUND,
    DEALDESKNOTESEQUENCE,
  } = useStore();
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addOrgModal, setAddOrgModal] = useState(false);
  const [editPeopleDrawer, setEditPeopleDrawer] = useState(false);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null); //Used only for ORG edit.

  const [failedReason, setFailedReason] = useState(null); //Used only for ORG edit.
  const [connectionList, setConnectionList] = useState([]);
  const [primaryConnectionList, setPrimaryConnectionList] = useState([]);
  const [getSoldStatus, setSoldStatus] = useState({});
  const [editSoldStatus, setEditSoldStatus] = useState({});

  const { editOpen = false, SearchPageEdit } = props;

  const close = () => {
    props.close();
    setConnectionList([]);
    setPrimaryConnectionList([]);
    ORGANISATION.dropdown_ROrg_list = null;
    // LEADSSTORE.editValues = null;
    form.resetFields();
    setDisabled(true);
    setSaving(false);
  };


  const handleSubmit = (data) => {
    //--------------------- Formatting Finance/Turn Over values ---------------------------------//

    let finance_value = data.finance_value;
    if (finance_value && (finance_value?.includes('k') || finance_value?.includes('K'))) {
      let splitArr = finance_value?.includes('K')
        ? finance_value.split('K')
        : finance_value.split('k');
      finance_value = Number(splitArr[0]) * 1000;
    } else if (
      finance_value &&
      (finance_value?.includes('m') || finance_value?.includes('M'))
    ) {
      let splitArr = finance_value?.includes('M')
        ? finance_value.split('M')
        : finance_value.split('m');
      finance_value = Number(splitArr[0]) * 1000000;
    } else if (
      finance_value &&
      (finance_value?.includes('b') || finance_value?.includes('B'))
    ) {
      let splitArr = finance_value?.includes('B')
        ? finance_value.split('B')
        : finance_value.split('b');
      finance_value = Number(splitArr[0]) * 1000000000;
    } else if (
      finance_value &&
      (finance_value?.includes('t') || finance_value?.includes('T'))
    ) {
      let splitArr = finance_value?.includes('T')
        ? finance_value.split('T')
        : finance_value.split('t');
      finance_value = Number(splitArr[0]) * 1000000000000;
    }

    let turn_over = data.turn_over;
    if (turn_over && (turn_over?.includes('k') || turn_over?.includes('K'))) {
      let splitArr = turn_over?.includes('K')
        ? turn_over.split('K')
        : turn_over.split('k');
      turn_over = Number(splitArr[0]) * 1000;
    } else if (turn_over && (turn_over?.includes('m') || turn_over?.includes('M'))) {
      let splitArr = turn_over?.includes('M')
        ? turn_over.split('M')
        : turn_over.split('m');
      turn_over = Number(splitArr[0]) * 1000000;
    } else if (turn_over && (turn_over?.includes('b') || turn_over?.includes('B'))) {
      let splitArr = turn_over?.includes('B')
        ? turn_over.split('B')
        : turn_over.split('b');
      turn_over = Number(splitArr[0]) * 1000000000;
    } else if (turn_over && (turn_over?.includes('t') || turn_over?.includes('T'))) {
      let splitArr = turn_over?.includes('T')
        ? turn_over.split('T')
        : turn_over.split('t');
      turn_over = Number(splitArr[0]) * 1000000000000;
    }

    data.finance_value = finance_value;
    data.turn_over = turn_over;

    //-------------------------------------------------------------------------------------------//

    data.deadline_date =
      data.deadline_date && moment(data.deadline_date).format('YYYY-MM-DD');
    setSaving(true);

    if (failedReason) {
      data.failed_reason = failedReason;
    } else {
      if (data.lead_status_id === AUTH.SOLD_STATUS_FAILED) {
        if (
          AUTH.user &&
          AUTH.user?.failed_reasons &&
          AUTH.user.failed_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.failed_reasons[0]?.reason;
        }
      } else if (data.lead_status_id === AUTH.SOLD_STATUS_REJECTED) {
        if (
          AUTH.user &&
          AUTH.user?.rejected_reasons &&
          AUTH.user.rejected_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.rejected_reasons[0]?.reason;
        }
      } else if (data.lead_status_id === AUTH.SOLD_STATUS_FUTURE) {
        if (
          AUTH.user &&
          AUTH.user?.future_reasons &&
          AUTH.user.future_reasons.length > 0
        ) {
          data.failed_reason = AUTH.user.future_reasons[0]?.reason;
        }
      }
    }
    let newConnections = connectionList;
    if (newConnections.length > 0) {
      newConnections.map((obj) => {
        let newConnectionRolename = '';
        if (obj?.connection_role_name && obj?.connection_role_name?.length) {
          obj.connection_role_name.map((role_obj, index) => {
            if (index === 0) {
              newConnectionRolename = role_obj;
            } else {
              newConnectionRolename += `#${role_obj}`;
            }
            return null;
          });
        }
        obj.connection_role_name = newConnectionRolename;
        return null;
      });
      newConnections = newConnections.filter(
        (x) => x.role_id !== 0 || x.role_id !== null
      );
    }
    let primaryArr = [];
    if (primaryConnectionList.length > 0) {
      let orgData;
      let peopleData;
      primaryConnectionList.map((obj) => {
        let newConnectionRolename = '';

        if (obj?.connection_role_name && obj?.connection_role_name?.length) {
          obj.connection_role_name.map((role_obj, index) => {
            if (index === 0) {
              newConnectionRolename = role_obj;
            } else {
              newConnectionRolename += `#${role_obj}`;
            }
            return null;
          });
        }
        if (obj.type === 1) {
          orgData = newConnectionRolename;
          if (orgData) {
            primaryArr.push({ [`${data.organisation_name}_org`]: orgData });
          }
        }
        if (obj.type === 2) {
          peopleData = newConnectionRolename;
          if (peopleData) {
            primaryArr.push({ [`${data.full_name}_people`]: peopleData });
          }
        }
        return obj;
      });
    }
    data.primary_connection = primaryArr;
    data.connections = newConnections;

    data.future_reason = editValues.future_reason ? editValues.future_reason : null;
    data.failed_reason = editValues.failed_reason ? editValues.failed_reason : null;
    data.failed_summary = editValues.failed_summary ? editValues.failed_summary : null;
    data.rejected_reason = editValues.rejected_reason ? editValues.rejected_reason : null;
    data.summary = editValues.summary ? editValues.summary : null;

    if (getSoldStatus) {
      data.future_reason = getSoldStatus.future_reason ? getSoldStatus.future_reason : editValues.future_reason;
      data.failed_reason = getSoldStatus.failed_reason ? getSoldStatus.failed_reason : editValues.failed_reason;
      data.failed_summary = getSoldStatus.failed_summary ? getSoldStatus.failed_summary : editValues.failed_summary;
      data.rejected_reason = getSoldStatus.rejected_reason ? getSoldStatus.rejected_reason : editValues.rejected_reason;
      data.summary = getSoldStatus.summary ? getSoldStatus.summary : editValues.summary;
    }

    LEADSSTORE.editLeadData(editValues.id, data, SearchPageEdit)
      .then((res) => {
        if (editOpen && res.data) {
          LEADSSTORE.viewValues = res.data;
        }
        close();
        if (location.pathname === '/leads/deals') {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
        }
        if (location.pathname === '/leads/opportunities') {
          LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
        }
        if (location.pathname === '/leads/active') {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
        }
        if (location.pathname === '/promotion') {
          PROMOTIONSTORE.setupGrid(PROMOTIONSTORE.agGrid);
        }
        if (location.pathname === '/leads/deals/kanban') {
          LEADSSTORE.getKanbanStatusList('Deal');
        }
        if (location.pathname === '/leads/opportunities/kanban') {
          LEADSSTORE.getKanbanStatusList('Opportunity');
        }
        if (location.pathname === '/leads/active/kanban') {
          LEADSSTORE.getKanbanStatusList('Lead');
        }

        if (location.pathname === '/management/block-edit-assign') {
          BLOCKEDITASSIGNSTORE.setupGrid(BLOCKEDITASSIGNSTORE.agGrid);
        }

        /** Search page */
        // if(SearchPageEdit) {

        // }
        // if (
        //   location.pathname.search('search/Leads') ||
        //   location.pathname.search('search/Deals')
        // ) {
        //After Lead Edit From Search
        if (location.pathname === '/search/Deals') {
          LEADDEALS.getListSearch(LEADDEALS.search_payload);
          SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'deal');
        }
        if (location.pathname === '/search/Leads') {
          LEADSSTORE.getListSearch(LEADSSTORE.search_payload);
          SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'active');
        }
        // }

        // Add note on Status changes to failed
        if (
          (DEALDESKSTORE.rejectionSummaryData?.summary !== editValues.failed_summary) &&
          (DEALDESKSTORE?.rejectionSummaryData?.type === "failed")
        ) {
          let modifiedRejectionSummaryData = `<rejectionSummary>Failed Summary<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`

          let payload = {
            "notes_flag": 2,
            "notes_description": modifiedRejectionSummaryData,
          }
          DEALDESKNOTESOUTBOUND.saveOutboundNote(editValues.id, payload).then((res) => {
            if (res && res?.data) {
              DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            }
          })
        }

        // Add note on Status changes to rejected
        if (
          (DEALDESKSTORE.rejectionSummaryData?.summary !== editValues.summary) &&
          (DEALDESKSTORE?.rejectionSummaryData?.type === "rejected")
        ) {
          let modifiedRejectionSummaryData = `<rejectionSummary>Rejection Summary<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`

          let payload = {
            "notes_flag": 2,
            "notes_description": modifiedRejectionSummaryData,
          }
          DEALDESKNOTESOUTBOUND.saveOutboundNote(editValues.id, payload).then((res) => {
            if (res && res?.data) {
              DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            }
          })
        }

        vsmNotify.success({
          message: vsmLeadsList.edit,
        });
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });
          form.setFields(e.errors);
        }
        setSaving(false);
      });
  }, 200);

  useEffect(() => {
    if (LEADSSTORE.editValues) {
      if (LEADSSTORE.editValues.lead_record_type) {
        LEADSSTORE.getLeadStatusList(LEADSSTORE.editValues.lead_record_type).then(
          ({ data }) => {
            LEADSSTORE.dropdown_leadstatus_list = data;
          }
        );
      }
      setEditSoldStatus({
        future_reason: LEADSSTORE.editValues.future_reason,
        failed_reason: LEADSSTORE.editValues.failed_reason,
        failed_summary: LEADSSTORE.editValues.failed_summary,
        rejected_reason: LEADSSTORE.editValues.rejected_reason,
        summary: LEADSSTORE.editValues.summary,
      })
    }
  }, [LEADSSTORE]);

  useEffect(() => {
    if (editValues) {
      //----------------------START: SET CONNECTION FIELDS------------//
      setPrimaryConnectionList([]);
      if (editValues.connections && editValues.connections.length > 0) {
        let connection_list = [];
        let connection_list_ids = [];
        editValues.connections.forEach((el) => {
          if (el.role_id) {
            let connection_role_arr = [];
            if (el.connection_role_name?.includes('#')) {
              connection_role_arr = el.connection_role_name.split('#');
            } else if (el.connection_role_name?.length) {
              connection_role_arr = [el.connection_role_name];
            }
            let tempObj = {
              id: el.entity_id,
              type: el.entity_type,
              name: el?.entity?.name,
              connection_role_name: connection_role_arr,
            };
            if (el?.entity?.phone && el.entity.phone.length > 0) {
              tempObj.phone = el.entity.phone[0].contact_value;
            } else {
              tempObj.phone = '';
            }
            if (el?.entity?.email && el.entity.email.length > 0) {
              tempObj.email = el.entity.email[0].contact_value;
            } else {
              tempObj.email = '';
            }
            connection_list.push(tempObj);
            connection_list_ids.push(el.entity_id);
          }
        });
        LEADSSTORE.setoldConnectionList(connection_list);
        setConnectionList(connection_list);

        form.setFieldsValue({ connections: connection_list_ids });
      }
      //----------------------END: SET CONNECTION FIELDS------------//

      if (editValues.sold_organisation) {
        let connectionRole = editValues.connections?.filter(
          (x) => x.entity?.id === editValues.sold_organisation.id
        );
        if (connectionRole?.length > 0) {
          connectionRole = connectionRole[0]['connection_role_name'];
          connectionRole = connectionRole ? connectionRole.split('#') : [];
        } else {
          connectionRole = [];
        }
        let newOrg = {
          id: editValues.sold_organisation && editValues.sold_organisation.id,
          connection_role_name: connectionRole,
          type: 1,
          name: editValues.sold_organisation
            ? editValues.sold_organisation?.organisation_name
            : '',
        };
        setPrimaryConnectionList((oldArray) => [...oldArray, newOrg]);
      }

      if (editValues.people) {
        let connectionRole = editValues.connections?.filter(
          (x) => x.entity?.id === editValues.people.id
        );
        if (connectionRole?.length > 0) {
          connectionRole = connectionRole[0]['connection_role_name'];
          connectionRole = connectionRole ? connectionRole.split('#') : [];
        } else {
          connectionRole = [];
        }
        let newPeople = {
          id: editValues.people && editValues.people.id,
          connection_role_name: connectionRole,
          type: 2,
          name: editValues.people
            ? editValues.people?.first_name + '' + editValues.people?.last_name
            : '',
        };
        setPrimaryConnectionList((oldArray) => [...oldArray, newPeople]);
      }

      if (editValues.finance_value) {
        form.setFieldsValue({ finance_value: parseFloat(editValues.finance_value).toFixed(2) });
      }
      if (editValues.turn_over) {
        // form.setFieldsValue({ turn_over: editValues.turn_over});
        form.setFieldsValue({ turn_over: parseFloat(editValues.turn_over).toFixed(2) });
      }
      /*START: If Lead - Opportunity - Opportunityname value to set in from*/
      form.setFieldsValue({
        opportunity_name: editValues?.opportunity_name,
      });

      /*END: If Lead - Opportunity - Opportunityname value to set in from*/

      form.setFieldsValue({
        classification_id:
          editValues.classification_id && parseInt(editValues.classification_id),
        lead_record_type: editValues.lead_record_type,
        full_name: editValues.people && editValues.people.id,
        organisation_name:
          editValues.sold_organisation && editValues.sold_organisation.id,

        phone: [{}],
        email: [{}],

        lead_source_details:
          editValues.lead_source_details && editValues.lead_source_details,
        lead_source: editValues.lead_source && editValues.lead_source,
        product_name: editValues.product_name && editValues.product_name,
        lead_status_id: editValues.lead_status_id && editValues.lead_status_id.id,

        lead_currency_id: editValues.lead_currency_id && editValues.lead_currency_id.id,
        deadline_date: editValues.deadline_date && moment(editValues.deadline_date),
        lead_owner_id: editValues.lead_owner_name && editValues.lead_owner_name.id,
        lead_holder_id: editValues.lead_holder_name && editValues.lead_holder_name.id,
      });
    }
  }, [LEADSSTORE, form, editValues]);

  // Open form for add new People
  const openAddModal = () => {
    setAddModal(true);
  };

  const setPeopleDropDown = (id, classification_id = null) => {
    PEOPLE.getAllPeople({ selected_id: [id], mapping_type: 2 });
    form.setFieldsValue({ full_name: id });

    if (!form.getFieldValue('classification_id')) {
      if (!LEADSSTORE.classification_role_list) {
        LEADSSTORE.getClassificationList();
      }
      if (classification_id) {
        form.setFieldsValue({ classification_id: classification_id });
      }
    }

    handleChange();
  };

  const setOrgDropDown = (id, classification_id = null) => {
    //getMappedOrg();
    ORGANISATION.getAllROrg({
      selected_id: [id],
      mapping_type: 1,
    });
    form.setFieldsValue({ organisation_name: id });

    if (!form.getFieldValue('classification_id')) {
      if (!LEADSSTORE.classification_role_list) {
        LEADSSTORE.getClassificationList();
      }
      if (classification_id) {
        form.setFieldsValue({ classification_id: classification_id });
      }
    }

    handleChange();
  };

  // Close form for add new People
  const closeAddModal = () => setAddModal(false);

  // Open form for add new Organisation
  const openAddOrgModal = () => {
    setAddOrgModal(true);
  };
  // Close form for close new Organisation
  const closeAddOrgModal = () => setAddOrgModal(false);

  // Open form for edit People
  const openEditPeopleDrawer = () => {
    var payload = { people_id: form.getFieldValue('full_name') };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setEditPeopleDrawer(true);
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  };
  // Close form for edit People
  const closeEditPeopleDrawer = () => {
    PEOPLE.getAllPeople({
      selected_id: [form.getFieldValue('full_name')],
      mapping_type: 2,
    });

    setEditPeopleDrawer(false);
    handleChange();
  };

  // Open form for edit existing Organisation and set values to form
  // set id for edit / view  Organisation drawer
  const setId = (id) => setTransactionId(id);
  const openEditOrgDrawer = () => {
    var payload = { organisation_id: form.getFieldValue('organisation_name') };
    ORGANISATION.setOrganisationValues(null);
    ORGANISATION.getRecordDetail(payload).then((data) => {
      setId(payload.organisation_id);
      ORGANISATION.setEditValues(data);
      setEditOrgModal(true);
    });
  };

  // Close form of edit Organisation
  const closeEditOrgDrawer = () => {
    ORGANISATION.getAllROrg({
      selected_id: [form.getFieldValue('organisation_name')],
      mapping_type: 1,
    });

    //LEADSSTORE.setEditValues({ id: editValues?.id });

    // ORGANISATION.organisationValues = null;
    // ORGANISATION.setEditValues(null);
    setEditOrgModal(false);
    handleChange();
  };

  return <Drawer
    visible={props.visible}
    onClose={close}
    placement='right'
    width={`${editOpen ? '1050px' : '1100px'}`}
    zIndex={editOpen ? 1001 : 1000}
    title={`${t('Common_Data.Edit')} ${t('Common_Data.Sold')}- #${editValues?.id}`}
    destroyOnClose={true}
    footer={[
      <>
        <Row>
          <Col span={16}>
            <div className='text-left pt-10' key='1'>
              <Row>
                <Col span={8}>
                  <strong>{t('Block_Edit_Assign.Created_Date')}:</strong>{' '}
                  {editValues?.created_date
                    ? AUTH.getLocalTimeDealDesk(
                      editValues?.created_date,
                      AUTH.global_dateformat
                    )
                    : `xxxx-xx-xx`}
                </Col>
                <Col span={8}>
                  <strong>{t('Common_Data.Modified_Date')}:</strong>{' '}
                  {editValues?.updated_date
                    ? AUTH.getLocalTimeDealDesk(
                      editValues?.updated_date,
                      AUTH.global_dateformat
                    )
                    : `xxxx-xx-xx`}
                </Col>

                <Col span={8}>
                  <strong>{t('LeadListing.Time_Spent')}:</strong>{' '}
                  {editValues?.investment_hours ? editValues?.investment_hours : `-`}
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={8}>
            <div className='text-right' key='2'>
              <Button
                key='1'
                form='editleadform'
                className='mr-10'
                loading={saving}
                htmlType='submit'
                type='primary'
                shape='round'
                disabled={isDisabled}
              >
                {t('Common_Data.Update')}
              </Button>
              <Button shape='round' onClick={close} key='2'>
                {t('Common_Data.Cancel')}
              </Button>
            </div>
          </Col>
        </Row>
      </>,
    ]}
  >
    <FormComponent
      form={form}
      onChange={handleChange}
      handleSubmit={handleSubmit}
      visible={props.visible}
      formid='editleadform'
      openAddModal={openAddModal}
      id={props.parent_id}
      openAddOrgModal={openAddOrgModal}
      openEditPeopleDrawer={openEditPeopleDrawer}
      openEditOrgDrawer={openEditOrgDrawer}
      setFailedReason={setFailedReason}
      setConnectionList={setConnectionList}
      setPrimaryConnectionList={setPrimaryConnectionList}
      connectionList={connectionList}
      primaryConnectionList={primaryConnectionList}
      setSoldStatus={setSoldStatus}
      editSoldStatus={editSoldStatus}

    />
    {/* {props?.isFromDealDesk || editOpen ? null : (
          <TrackTimeComponent pageName={'leadlisting'} leadId={editValues.id} />
        )} */}

    <TimeTracker
      page={'leadlisting'}
      id={editValues?.id}
      stop={!props.visible || addModal || addOrgModal || editPeopleDrawer || editOrgModal}
    />
    <AddPeopleComponent
      visible={addModal}
      isChildDrawer={true}
      close={closeAddModal}
      isLead={true}
      setPeopleDropDown={setPeopleDropDown}
    />
    <AddOrgComponent
      visible={addOrgModal}
      isChildDrawer={true}
      close={closeAddOrgModal}
      isLead={true}
      setOrgDropDown={setOrgDropDown}
    />
    <EditPeopleComponent
      visible={editPeopleDrawer}
      isChildDrawer={true}
      close={closeEditPeopleDrawer}
      setPeopleDropDown={setPeopleDropDown}
    />
    <EditOrgComponent
      transactionId={transactionId}
      isChildDrawer={true}
      setId={setId}
      visible={editOrgModal}
      close={closeEditOrgDrawer}
      setOrgDropDown={setOrgDropDown}
    />
  </Drawer>
});

export default EditComponent;