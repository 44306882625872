import React, { useState } from 'react';
import { observer } from 'mobx-react';

import useStore from '../../../store';
import KnowledgeSearchListing from './KnowledgeSearchListing';
import EditComponent from '../../Knowledge/component/EditComponent';
import DeleteComponent from '../../Knowledge/component/DeleteComponent';
import TimeTracker from '../../../component/TimeTracker';

const KnowledgeSearch = observer((props) => {
  const { SearchPage, searchPayload, selectionChanged } = props
  const { KNOWLEDGE } = useStore();

  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  //open edit drawer
  const openEditDrawer = (data) => {
    var payload = { knowledgeid: data.id };
    KNOWLEDGE.getRecordDetail(payload);
    setEditDrawer(true);
  };

  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);


  //open edit drawer
  const openDeleteDrawer = (data) => {
    KNOWLEDGE.setDeleteValues(data);
    setDeleteModal(true);
  };

  //close edit drawer
  const closeDeleteDrawer = () => setDeleteModal(false);

  return (
    <>
      <KnowledgeSearchListing SearchPage={SearchPage} searchPayload={searchPayload} openEditDrawer={openEditDrawer} openDeleteDrawer={openDeleteDrawer} selectionChanged={selectionChanged} />
      <EditComponent visible={editDrawer} close={closeEditDrawer} />
      <DeleteComponent visible={deleteModal} close={closeDeleteDrawer} SearchPage={SearchPage} />
      <TimeTracker
        page={"knowledge"}
      />
    </>
  );
})
export default KnowledgeSearch;