import React from 'react';
import { Col, Row, Form } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { vsmSettings } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const ProductElement = observer(({ onChange }) => {
  const { AUTH } = useStore();
  const {t} = useTranslation()
  return (
    <div className="w-100">
      <Form.List name='products' initialValue={[null]}>
        {
          (fields, { add, remove }) => {
            return fields.map((field, index) => {
              return (
                <div className="w-100" key={field.key}>
                  <Row gutter={15}>
                    <Col span={16}>
                      <InputComponent
                        name={[field.name, 'product_name']} key={[field.key, 'product_name']}
                        placeholder={`${t('Common_Data.Enter')} ${t('Common_Data.Product')}`} onChange={onChange}
                        maxLength={100}
                        autoComplete="off"
                        rules={vsmSettings.validation.product}
                      />
                    </Col>
                    <Col span={8}>
                      <div>
                        {(fields.length > 1) && (
                          <img
                            alt=''
                            className={`formIcon mt-5 mr-10`}
                            src={AUTH.GetThemedImage('remove')}
                            onClick={async () => {
                              await remove(field.name);
                              await onChange();
                            }}
                          />
                        )}
                        {(index === (fields.length - 1) && (fields.length < 12)) && (
                          <img
                            alt=''
                            className={`formIcon mt-5 mr-10`}
                            src={AUTH.GetThemedImage('add')}
                            onClick={async () => {
                              await add();
                              await onChange();
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })
          }
        }
      </Form.List>
    </div>
  )
})

export default ProductElement