import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify, vsmLeadsList } from '../../../config/messages';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    LEADSSTORE,
    LEADDEALS,
    LEADOPPORTUNITY,
    LEADACTIVE,
    PROMOTIONSTORE,
    SEARCHSTORE,
    // LEADSEARCH,
  } = useStore();
  const [saving, setSaving] = useState(false);
  const location = useLocation();

  const close = (reload = false) => {
    props.close(reload);
    if (props.getWasteCount) {
      props.getWasteCount();
    }
    // LEADSSTORE.deleteValues = null;
    form.resetFields();
    setSaving(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);

    LEADSSTORE.deleteLeadData(LEADSSTORE.deleteValues, props.SearchPage)
      .then(() => {
        close(true);
        if (location.pathname === '/leads/deals') {
          LEADDEALS.setupGrid(LEADDEALS.agGrid);
        }
        if (location.pathname === '/leads/opportunities') {
          LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
        }
        if (location.pathname === '/leads/active') {
          LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
        }
        if (location.pathname === '/promotion') {
          PROMOTIONSTORE.setupGrid(PROMOTIONSTORE.agGrid);
        }
        // if (props?.SearchPage) {
        //   if (location.pathname.search('/Deals')) {
        //     SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'deal');
        //     LEADDEALS.setupGrid(LEADDEALS.agGrid);
        //   }
        //   if (location.pathname.search('/Leads')) {
        //     SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'active');
        //     LEADSEARCH.setupGrid(LEADSEARCH.agGrid);
        //   }
        // }
        if (location.pathname === '/search/Leads') {
          if (props.SearchPage) {
            LEADSSTORE.agGrid.api.applyTransaction({ remove: [LEADSSTORE.deleteValues] });
            SEARCHSTORE.updateSearchCount({ lead_count: SEARCHSTORE.searchCount.lead_count - 1 });
          }
          // SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'active');
        }
        if (location.pathname === '/search/Deals') {
          if (props.SearchPage) {
            LEADDEALS.agGrid.api.applyTransaction({ remove: [LEADSSTORE.deleteValues] });
            SEARCHSTORE.updateSearchCount({ deal_count: SEARCHSTORE.searchCount.deal_count - 1 });
          }
          // SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'deal');
        }
        LEADSSTORE.deleteValues = null;

        vsmNotify.success({
          message: vsmLeadsList.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  var formId;

  if (!LEADSSTORE.current_list_path) {
    formId = 'deleteleadform';
  } else if (LEADSSTORE.current_list_path === '/leads/opportunities') {
    formId = 'deleteoppform';
  } else if (LEADSSTORE.current_list_path === '/leads/deals') {
    formId = 'deletedealform';
  } else {
    formId = 'deleteform';
  }

  return LEADSSTORE.deleteValues ? (
    <Modal
      centered
      title={`${t('Block_Edit_Assign.Delete_Lead')} - #${LEADSSTORE.deleteValues?.id}`}
      visible={props.visible}
      onCancel={() => close()}
      onOk={() => close()}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form={formId}
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          danger
        //disabled={disabled}
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={() => close()}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id={formId} onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {props.lineText}
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
