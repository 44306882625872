import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Form } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';

const DateRangeIterationElement = observer(({ onChange }) => {
  const { AUTH } = useStore();
  return (
    <Form.List name='period_date_range'>
      {(fields, { add, remove }) => {
        return fields.map((field, index) => {
          return (
            <div className='w-100' key={field.key}>
              <Row gutter={24}>
                <Col>
                  <InputComponent
                    type='date_range'
                    name={[field.name]}
                    key={[field.key, 'date_range']}
                    className='w-100'
                    onChange={onChange}
                    rules={vsmTransactionReport.validation.period_date_range}
                  />
                </Col>
                <Col>
                  <div className='d-flex justify-space-between'>
                    {fields.length > 2 && (
                    <img src={AUTH.GetThemedImage("remove")} className="mr-10" alt="" onClick={() => {
                        remove(field.name);
                        onChange();
                    }}/>
                    )}
                    {index === fields.length - 1 && fields.length < 12 && (
                    <img src={AUTH.GetThemedImage("add")} alt=""   onClick={() => {
                        add();
                        onChange();
                    }}/>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          );
        });
      }}
    </Form.List>
  );
});

export default DateRangeIterationElement;
