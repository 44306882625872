import React, { useEffect, useState } from 'react';
import InputComponent from '../../../../component/InputComponent';
import { Form, Spin } from 'antd';

import useStore from '../../../../store';
import { observer } from 'mobx-react';
//import { vsmNotify, vsmDealDesk } from '../../../../config/messages';
import { UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DropdownComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    DEALDESKSTORE,
    DEALDESKSTORE: {
      dropdown_users_list,
      deal_data,
      getUsersList,
      updateLeadOwner,
      updateLeadHolder,
    },
  } = useStore();
  const [form] = Form.useForm();
  const [fetchUsersList, setFetchUsersList] = useState(true);

  const callUpdateLeadOwner = () => {
    let payload = {
      lead_owner_id: form.getFieldValue('Owner'),
    };
    updateLeadOwner(deal_data.lead_information.id, payload).then((data) => {
      // vsmNotify.success({
      //   message: vsmDealDesk.successLeadOwnerUpdate,
      // });

      // To update last updated date in  headers with header api
      DEALDESKSTORE?.getDealDetail(deal_data?.lead_information?.id);
    });
  };

  const callUpdateLeadHolder = () => {
    let payload = {
      lead_holder_id: form.getFieldValue('Holder'),
    };
    updateLeadHolder(deal_data.lead_information.id, payload).then((data) => {
      // vsmNotify.success({
      //   message: vsmDealDesk.successLeadHolderUpdate,
      // });

      // To update last updated date in  headers with header api
      DEALDESKSTORE?.getDealDetail(deal_data?.lead_information?.id);
    });
  };
  // ---  UseEffect Added for Owner and Holder Set When Form Updated ---------- //
  useEffect(() => {
    if (form.getFieldValue('Owner') !== deal_data?.lead_information?.lead_owner_name?.id) {
      form.setFieldsValue({
        Owner: deal_data?.lead_information?.lead_owner_name?.id
      })
    }
    if (form.getFieldValue('Holder') !== deal_data?.lead_information?.lead_holder_name?.id) {
      form.setFieldsValue({
        Holder: deal_data?.lead_information?.lead_holder_name?.id
      })
    }
  }, [form, deal_data?.lead_information])
  // ---  UseEffect Added for Owner and Holder Set When Form Updated ---------- //

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        id='updateownerholderform'
        className='innerForm DropdownComponent prefixIconDropdown'
      >
        <InputComponent
          disabled={deal_data?.lead_information?.is_ticket === 0 ? true : false} //Disable for Ticket Desk
          suffixIcon={<UserOutlined title={t('Common_Data.Owner')} />}
          name='Owner'
          placeholder={`${t('Common_Data.Owner')}`}
          type='select'
          initialValue={deal_data?.lead_information?.lead_owner_name?.id}
          showSearch={false}
          onFocus={() =>
            fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
          }
          notFoundContent={
            fetchUsersList ? (
              <div className='spinInside'>
                <Spin size='small' />
              </div>
            ) : (
              `${t('Common_Data.No_Record_Found')}.`
            )
          }
          options={{
            values:
              dropdown_users_list ||
              (deal_data &&
                deal_data.lead_information &&
                deal_data.lead_information.lead_owner_name && [
                  {
                    id: deal_data.lead_information.lead_owner_name.id,
                    name: deal_data.lead_information.lead_owner_name.full_name,
                  },
                ]),

            value_key: 'id',
            text_key: 'name',
          }}
          onChange={() => callUpdateLeadOwner()}
        />
        <InputComponent
          suffixIcon={<UserSwitchOutlined title={`${t('DealDesk.Holder')}`} />}
          name='Holder'
          placeholder={`${t('DealDesk.Holder')}`}
          type='select'
          initialValue={deal_data?.lead_information?.lead_holder_name?.id}
          showSearch={false}
          onFocus={() =>
            fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
          }
          notFoundContent={
            fetchUsersList ? (
              <div className='spinInside'>
                <Spin size='small' />
              </div>
            ) : (
              `${t('Common_Data.No_Record_Found')}.`
            )
          }
          options={{
            values:
              dropdown_users_list ||
              (deal_data &&
                deal_data.lead_information &&
                deal_data.lead_information.lead_holder_name && [
                  {
                    id: deal_data.lead_information.lead_holder_name.id,
                    name: deal_data.lead_information.lead_holder_name.full_name,
                  },
                ]),

            value_key: 'id',
            text_key: 'name',
          }}
          onChange={() => callUpdateLeadHolder()}
        />
      </Form>
    </>
  );
});

export default DropdownComponent;
