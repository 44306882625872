import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmUsergroup } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { USERGROUP } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    var formData = USERGROUP.JsonToFormData(data);
    // if (data.logo_url.fileList[0] && data.logo_url.fileList.length > 0) { formData.append("photo_url", data.logo_url.fileList[0].originFileObj) }
    USERGROUP.AddData(formData)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmUsergroup.add
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <>
      <Modal
        //centered
        title={`${t('Common_Data.Add')} ${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='addform'
            shape='round'
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
            loading={saving}
          >
            {t('Common_Data.Save')}
          </Button>,
          <Button key='2' form='addform' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>
        ]}
        width={500}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='addform'
        />
      </Modal>
    </>
  );
});

export default AddComponent;
