import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import useStore from '../../../store';
import { vsmCallAudit, vsmNotify } from '../../../config/messages';

const PhoneRenderer = observer((props) => {
  const { AUTH, LEADSSTORE } = useStore();
  //const contactList = props.value;

  const [contactList] = useState(props.data.phone);
  const [peoplePhone] = useState(props.data.people_phone);

  const handleCallAudit = (data) => {
    let formdata = {
      id: props.data.id,
      phone: data,
    };
    LEADSSTORE.callAudit(formdata).then(() => {
      //alert(phone);
      vsmNotify.success({
        message: vsmCallAudit.added,
      });
    });
  };

  const numbers = (
    <div className='contactList'>
      <ul>
        {peoplePhone &&
          peoplePhone.length > 0 &&
          peoplePhone.map((detail, index) => {
            return (
              <li key={index} value={`${detail.contact_for}: ${detail.contact_value}`}>
                <a
                  href={
                    AUTH.checkPermission(2, 'quick-call')
                      ? `tel:${detail.contact_value}`
                      : `#`
                  }
                  onClick={(e) => {
                    handleCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === 'Primary' &&
                        AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Home' && AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Office' && AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Other' && AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Mobile' &&
                        AUTH.GetThemedImage('Mobile')) ||
                      (detail.contact_for === 'SMS' && AUTH.GetThemedImage('SMS'))
                    }
                    alt=''
                    className='menuicon'
                  />{' '}
                  {detail.contact_value}
                </a>
              </li>
            );
          })}
        {contactList &&
          contactList.length > 0 &&
          contactList.map((detail, index) => {
            return (
              <li key={index} value={`${detail.contact_for}: ${detail.contact_value}`}>
                <a
                  href={
                    AUTH.checkPermission(2, 'quick-call')
                      ? `tel:${detail.contact_value}`
                      : `#`
                  }
                  onClick={(e) => {
                    handleCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === 'Primary' &&
                        AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Home' && AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Office' && AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Other' && AUTH.GetThemedImage('Phone')) ||
                      (detail.contact_for === 'Mobile' &&
                        AUTH.GetThemedImage('Mobile')) ||
                      (detail.contact_for === 'SMS' && AUTH.GetThemedImage('SMS'))
                    }
                    alt=''
                    className='menuicon'
                  />{' '}
                  {detail.contact_value}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <div className='action-column TelephoneRenderer'>
      <Popover
        content={numbers}
        placement='topLeft'
        trigger='hover'
        overlayClassName='profileMenu'
      >
        {peoplePhone && peoplePhone.length > 0
          ? peoplePhone.map((detail, index) => {
              if (index === 0) {
                return (
                  <a
                    href={
                      AUTH.checkPermission(2, 'quick-call')
                        ? `tel:${detail.contact_value}`
                        : `#`
                    }
                    onClick={(e) => {
                      handleCallAudit(detail.contact_value);
                    }}
                  >
                    <img
                      src={
                        (detail.contact_for === 'Primary' &&
                          AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Home' && AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Office' &&
                          AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Other' &&
                          AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Mobile' &&
                          AUTH.GetThemedImage('Mobile')) ||
                        (detail.contact_for === 'SMS' && AUTH.GetThemedImage('SMS'))
                      }
                      alt=''
                      className='menuicon'
                    />{' '}
                    {detail.contact_value}
                  </a>
                );
              } else {
                return null;
              }
            })
          : contactList &&
            contactList.length > 0 &&
            contactList.map((detail, index) => {
              if (index === 0) {
                return (
                  <a
                    href={
                      AUTH.checkPermission(2, 'quick-call')
                        ? `tel:${detail.contact_value}`
                        : `#`
                    }
                    onClick={(e) => {
                      handleCallAudit(detail.contact_value);
                    }}
                  >
                    <img
                      src={
                        (detail.contact_for === 'Primary' &&
                          AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Home' && AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Office' &&
                          AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Other' &&
                          AUTH.GetThemedImage('Phone')) ||
                        (detail.contact_for === 'Mobile' &&
                          AUTH.GetThemedImage('Mobile')) ||
                        (detail.contact_for === 'SMS' && AUTH.GetThemedImage('SMS'))
                      }
                      alt=''
                      className='menuicon'
                    />{' '}
                    {detail.contact_value}
                  </a>
                );
              } else {
                return null;
              }
            })}
      </Popover>
    </div>
  );
});
export default PhoneRenderer;
