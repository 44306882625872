import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import { Form, Col, Row } from 'antd';
import { vsmSettings } from '../../../config/messages';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const QuickNotes = observer(({ onChange, form }) => {
  const { AUTH } = useStore();
  const { t } = useTranslation()
  return (
    <div className='w-100'>
      <Row>
        <Form.List name='quick_notes' initialValue={[null]} className='d-flex '>
          {(fields, { add, remove }) => {
            return fields.map((field, index) => {
              return (
                <>
                  <Col span={6} key={field.key} className='d-flex quick_notes'>
                    <div className='p-5 w150'>
                      <InputComponent
                        name={[field.name, 'quick_note_text']}
                        key={[field.key, 'quick_note_text']}
                        onChange={onChange}
                        placeholder={`${t('Common_Data.Quick')} ${t('LeadListing.Notes')}`}
                        style={{ width: '100%' }}
                        rules={vsmSettings.validation.quick_note_text_with_required}
                      />
                    </div>
                    <div className='p-5 w150'>
                      <InputComponent
                        name={[field.name, 'quick_note_text_value']}
                        key={[field.key, 'quick_note_text_value']}
                        onChange={onChange}
                        placeholder={`${t('Settings.Acronym')}`}
                        style={{ width: '100%' }}
                        rules={vsmSettings.validation.quick_note_text_value_with_required}
                      />
                    </div>
                    <div className='d-flex p-5'>
                      {fields.length > 1 && (
                        <img
                          alt=''
                          className={`formIcon mt-5 mr-10`}
                          src={AUTH.GetThemedImage('remove')}
                          onClick={() => {
                            remove(field.name);
                            //onChange();
                          }}
                        />
                      )}
                      {index === fields.length - 1 && (
                        <img
                          alt=''
                          className={`formIcon mt-5 mr-10`}
                          src={AUTH.GetThemedImage('add')}
                          onClick={async () => {
                            await add(null);
                            //onChange();
                          }}
                        />
                      )}
                    </div>
                  </Col>
                </>
              );
            });
          }}
        </Form.List>
      </Row>
    </div>
  );
});

export default QuickNotes;
