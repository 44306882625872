import React from 'react';
import { Row, Col } from 'antd';
import HighlightsComponent from './component/HighlightsComponent';
import DropdownComponent from './component/DropdownComponent';
import OrganisationDetailsComponent from './component/OrganisationDetailsComponent';
import ButtonsComponent from './component/ButtonsComponent';
import { useWindowDimensions } from '../../../config/WindowHeightWidth';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import TicketDeskHeaderComponent from './component/TicketDeskComponents';

const HeaderComponent = observer((props) => {
  const { width } = useWindowDimensions();
  const {
    DEALDESKSTORE: {
      deal_data,
    },
  } = useStore();

  return deal_data && (
    <>

      {deal_data.lead_information?.is_ticket === 0 ?
        <TicketDeskHeaderComponent />
        :
        <Row gutter={5} className='HeaderComponent'>
          <Col span={width && width > 1900 ? 3 : 5} className='d-flex align-items-center'>
            <HighlightsComponent />
          </Col>

          <Col span={width && width > 1900 ? 17 : 15}>
            <OrganisationDetailsComponent />
          </Col>
          <Col span={4}>
            <DropdownComponent />
          </Col>
          <Col span={24}>
            <ButtonsComponent />
          </Col>
        </Row>
      }
    </>
  );
});

export default HeaderComponent;
