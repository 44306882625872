import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import MergePeopleListing from './MergePeopleListing';
import { vsmNotify } from '../../../../config/messages';
import ConfirmMergeComponent from './ConfirmMergeComponent';
import { useTranslation } from 'react-i18next';

const MergePeople = observer((props) => {
  const {t} = useTranslation()
  const [saving, setSaving] = useState(false);
  const [confirmMerge, setconfirmMerge] = useState(false);

  const { selectedMergeData, visible } = props;

  const {
    PEOPLE,
    PEOPLE: { masterPeopleToMerge, setMasterPeopleToMerge, handleMergePeople },
  } = useStore();

  useEffect(() => {}, [masterPeopleToMerge]);

  //---------------------MERGE CONFIRM FUNCTIONS -------------------------//
  const openConfirmMergeModal = () => {
    setconfirmMerge(true);
  };

  const closeConfirmMerge = () => {
    setconfirmMerge(false);
    setSaving(false);
  };
  //---------------------MERGE CONFIRM FUNCTIONS -------------------------//

  const close = () => {
    setMasterPeopleToMerge(null);
    setSaving(false);
    setconfirmMerge(false);

    props.close();
  };

  const handleSubmit = () => {
    let selectedArray = [];

    selectedMergeData &&
      selectedMergeData.forEach((element) => {
        selectedArray.push(element.id);
      });
    let payload = {
      selectedPeoples: selectedArray,
      mergePeople: masterPeopleToMerge,
    };

    handleMergePeople(payload)
      .then((data) => {
        if (PEOPLE.agGrid) {
          PEOPLE.agGrid.api.deselectAll();
          PEOPLE.setupGrid(PEOPLE.agGrid);
        }
        setconfirmMerge(false);
        close();

        vsmNotify.success({
          message: `${t('Common_Data.People')} ${t('PeopleManagement.Merged')} ${t('PeopleManagement.Successfully')}!`,
        });
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
        }
      })
      .finally(() => setSaving(false));
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Merge')} ${t('Common_Data.People')}`}
      visible={visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      width={1200}
      destroyOnClose
      footer={[
        <Button
          key='1'
          shape='round'
          size='medium'
          disabled={masterPeopleToMerge ? false : true}
          // loading={saving}
          onClick={() => openConfirmMergeModal()}
        >
          {t('Common_Data.Merge')} {t('Common_Data.People')}
        </Button>,
      ]}
    >
      <h5>
        {t('PeopleManagement.One_Primary_People')}.
      </h5>
      <MergePeopleListing selectedMergeData={selectedMergeData} />
      <ConfirmMergeComponent
        visible={confirmMerge}
        close={closeConfirmMerge}
        handleSubmit={handleSubmit}
        saving={saving}
        setSaving={setSaving}
      />
    </Modal>
  );
});
export default MergePeople;
