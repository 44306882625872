import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmUsergroup } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const {
    USERGROUP: { EditData, editValues },
  } = useStore();

  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.id = editValues.id;
    if (data.manager_id === undefined) {
      data.manager_id = 0;
    }

    EditData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmUsergroup.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (editValues && props.visible) {
      form.setFieldsValue({
        group_name: editValues.group_name,
        manager_id: editValues.manager_id ? editValues.manager_id : null,
      });
    }
  }, [editValues, props.visible, form]);

  return (
    editValues && (
      <Modal
        title={`${t('Common_Data.Edit')} ${t('Common_Data.User')} ${t('Common_Data.Group')} - ${editValues?.group_name}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='editform'
            shape='round'
            htmlType='submit'
            type='primary'
            disabled={isDisabled}
            loading={saving}
          >
            {t('Common_Data.Update')}
          </Button>,
          <Button key='2' form='editform' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={500}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
        />
      </Modal>
    )
  );
});

export default EditComponent;
