import { Alert, Button, Col, Form, Popover, Row, Typography } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InputComponent from "../component/InputComponent";
import { vsmAuth } from "../config/messages";
import useStore from "../store";
//import { Notify } from "../utils/Notify";
import FinalLOGO from "../include/images/DealMaker_Logo_final_250.svg";
import Email_Icon from "../assets/images/gray/Mail.svg";
import Pass_Icon from "../assets/images/gray/Lock_Note.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Login = observer(() => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [commonError, setCommonError] = useState(null);
  const [tooltip, setTooltip] = useState(false);
  const { t } = useTranslation()
  const {
    AUTH,
    DEALDESKNOTESOUTBOUND: { fetchClassificationList }
  } = useStore();
  const history = useHistory();
  // password criteria tool tip
  const passwordTooltip = (
    <div>
      <div>{t('Login.At_Least')} 1 {t('Login.Numeric')} {t('Login.Character')}</div>
      <div>{t('Login.At_Least')} 1 {t('Login.Special')} {t('Login.Character')}</div>
      <div>{t('Login.At_Least')} 1 {t('Login.Uppercase_Letter')}</div>
      <div>{t('Login.At_Least')} 8 {t('Login.Character')}</div>
    </div>
  );

  // handle password tool tip visiblility
  const handleChangePassword = (e) => {
    form
      .validateFields(["password"])
      .then(() => {
        setTooltip(false);
      })
      .catch(() => {
        setTooltip(true);
      });
  };

  // make function call to login
  const handleSubmit = (data) => {
    setLoading(true);
    AUTH.doLogin(data)
      .then((data) => {
        if (data.reset_token) {
          history.replace("reset/" + data.reset_token);
        } else {
          // Notify.success({ message: vsmAuth.success });
          fetchClassificationList();
        }
      })
      .catch(({errors, notify}) => {
        if (errors?.notify) { setCommonError(errors.errors[0].errors[0]);}
        if (notify) {
          setCommonError(notify);
        }
        if (errors.notify) {
          setCommonError(errors.notify);
        }
        errors.errors && form.setFields(errors.errors);
        setLoading(false);
      });
  };

  return (
    <div className="login__page__wrapper">
      <img src={FinalLOGO} className="LoginLogo" alt={`${t('Common_Data.Deal')} ${t('Login.Maker')}`} title={`${t('Common_Data.Deal')} ${t('Login.Maker')}`} />
      <p className="text_wrapper">{t('Login.Organisation_Deals')}</p>
      <div className="login__page__form">
        <Form form={form} onFinish={handleSubmit}>
          {commonError && (
            <Alert
              style={{ marginBottom: 15 }}
              message={commonError}
              type="error"
              showIcon
            />
          )}
          <Row gutter={24}>
            <Col xs={24}>
              <Typography.Title level={2} className="w-100 title">
                {t('Common_Data.Login')}
              </Typography.Title>
              <InputComponent
                hasFeedback
                name="email"
                placeholder={`${t('Common_Data.Email')} ${t('Common_Data.Address')}`}
                prefix={<img src={Email_Icon} alt={`${t('Common_Data.Email')}`} width="25px" />}
                rules={vsmAuth.validation.email}
              />
              <Popover placement="topRight" content={passwordTooltip} visible={tooltip}>
                <InputComponent
                  hasFeedback
                  type="password"
                  name="password"
                  placeholder={`${t('Common_Data.Password')}`}
                  prefix={<img src={Pass_Icon} alt={`${t('Common_Data.Email')}`} width="25px" height="25px" />}
                  onBlur={() => setTooltip(false)}
                  onChange={handleChangePassword}
                  onFocus={handleChangePassword}
                  rules={vsmAuth.validation.password}
                />
              </Popover>
            </Col>
          </Row>

          <div className="d-flex justify-space-between align-items-center">
            <Button
              loading={loading}
              htmlType="submit"
              size="small"
              block
              type="primary"
              className="login"
            >
              {t('Login.Login_Title')}
            </Button>
          </div>
          <div className="d-flex justify-space-between align-items-center">
            <p className="mt-15 m-0 p-0 text-left copyRight">
              {t('ForgotPassword.Copyright')} {moment().format("YYYY")} {t('Common_Data.Deal')} {t('Login.Maker')} {t('ForgotPassword.System')}&reg;
            </p>
            <Link to="/forget-password" type="link" className="p-0 mt-15 link">
              {t('ForgotPassword.Forgot')} {t('ForgotPassword.Password')}?
            </Link>
          </div>
        </Form>
      </div>
      {/* </div> */}
    </div>
  );
});

export default Login;
