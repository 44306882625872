import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Col, Divider, Form, Row } from 'antd';

import moment from 'moment';

import useStore from '../../../../store';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';

// import FieldTransferComponent from '../formElements/FieldTransferComponent';
import UsergroupSelectionElement from '../formElements/UsergroupSelectionElement';
import UserSelectionElement from '../formElements/UserSelectionElement';
import { default as DateSelectionElement } from '../formElements/DateSelectionElement';

import SOLDReportCriteria from '../formElements/SOLDReportCriteria';
import TagsReportCriteria from '../formElements/TagsReportCriteria';
import TicketReportCriteria from '../formElements/TicketReportCriteria';
import ReminderReportElements from '../formElements/ReminderReportElements';
import SavedFilterElements from '../formElements/SavedFilterElements';
import PeopleformElements from '../formElements/PeopleformElements';
import OrgformElements from '../formElements/OrgformElements';
import {
  reportForTypeNew,
  reportSubTypeList,
  reportByUserDropdown,
  reportTypeListNewLayout,
  summaryType,
  countsListType,
  listReportType,
  getDateFilterOnReportList,
  timeSpentReportType,
  dynamicFieldsListReport,
  reportDynamicFieldsListColumnConfig,
  capitalize,
  group_onArr,
  getReportOnFromSavedFilterPageType,
  relationShipsReportType,
  kpiReportType,
} from '../../../../utils/GlobalFunction';
import ReportForComparisonModeElement from '../formElements/ReportForComparisonModeElement';
import CommonSummaryElement from '../formElements/CommonSummaryElement';
import SummarySOLDElement from '../formElements/SummarySOLDElement';
import SummaryTagsElement from '../formElements/SummaryTagsElement';
import SummaryTicketsElement from '../formElements/SummaryTicketsElement';
import SummaryReminderElement from '../formElements/SummaryReminderElement';
import SummaryPeopleElement from '../formElements/SummaryPeopleElement';
import SummaryOrganisationElement from '../formElements/SummaryOrganisationElement';
import TimeSpentReportElement from '../formElements/TimeSpentReportElement';
// import RecordsSortByElement from '../formElements/RecordsSortByElement';
import { useTranslation } from 'react-i18next';
import FormRelationshipElements from '../formElements/FormRelationshipElements';
import KPIReportCriteriaElement from '../formElements/KPIReportCriteriaElement';
import KPISelectColumnsElement from '../formElements/KPISelectColumnsElement';

const FormComponent = observer(
  ({
    initialValues,
    form,
    formDataList,
    handleSubmit,
    id,
    isClone = false,
    setFormDataList,
    setDisabled,
    defaultFormDataList,
    formButtons,
    indeterminate,
    setIndeterminate,
    checkAll,
    setCheckAll,
    isEdit = false,
    isPreview = false,
    countsListReport,
    setCountsListReport
  }) => {
    const [DateRange, SetDateRange] = useState([]);

    //----------------------------- States Declaration --------------------------------------------//
    const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false);
    const [USERLIST, SETUSERLIST] = useState(false);
    const [USERGROUPLIST, SETUSERGROUPLIST] = useState(false);
    // const [fetchTicketStatus, setfetchTicketStatus] = useState(true);
    const [initializeFormData, setinitializeFormData] = useState(true);


    //----------------------------- States Declaration --------------------------------------------//

    //----------GLOBAL STATE DECLARATION---------------//
    const { t } = useTranslation();
    const {
      REPORTSTORE,
      REPORTSTORE: {
        previewReportValues,
        formatFormData,
        columnsForSortList,
        getFinancialYearList,
        setSelectedReportFieldsKeys,
        // setColumnForSortList,
        setTargetKeys,
        // targetKeys,
        // mockData,
        setMockData,
        // selectedKeys,
        // setSelectedKeys,
        setReport_on_report,
        report_on_report,
        // setMockDataToggleDeleteColumn,
      },
      // TicketsStore: { getTicketstatusList, getDropdownPriority},
    } = useStore();
    //----------GLOBAL STATE DECLARATION---------------//

    const disabledDate = (current) => {
      if (!DateRange || DateRange.length === 0) {
        return false;
      }
      return moment(current).format('Y-M-D') === moment(DateRange[0]).format('Y-M-D');
    };
    //--------- Handle Submit Event Starts -------- //
    const onFinish = (data) => {
      data.form_list = formDataList;
      data = formatFormData({ ...previewReportValues, ...data });
      data.action = 'save_report';
      handleSubmit(data);
    };

    //--------- Handle Submit Event Ends -------- //

    const onDepenentFieldsChange = async (type) => {
      form.resetFields(['name', 'code', 'description']);
      switch (type) {
        case 'report_type':
          form.resetFields(['report_on', 'report_by', 'report_for']);
          break;
        case 'report_on':
          if (form.getFieldValue('report_on') !== 'saved_filter') {
            form.resetFields(['report_by', 'report_for']);
          }
          break;
        case 'report_by':
          form.resetFields(['date_filter_on', 'report_for']);
          break;
        default:
      }

      form
        .validateFields()
        .then((data) => {
          setDisabled(false);
        })
        .catch((e) => {
          if (e.errorFields.length > 0) {
            setDisabled(true);
          }
        });
    };

    const getFormDataList = useCallback(
      (type) => {
        let FormDataList = defaultFormDataList;

        if (form.getFieldValue('report_type')) {
          if (!kpiReportType.includes(form.getFieldValue('report_type'))) {
            FormDataList = [...FormDataList, 'report_on'];
          }
          if (kpiReportType.includes(form.getFieldValue('report_type'))) {
            FormDataList = [...FormDataList, 'report_by', 'seperate_sum_users'];
            if (form.getFieldValue('report_by')) {
              FormDataList = [...FormDataList, 'report_for'];
              if (form.getFieldValue('report_for')) {
                FormDataList = [
                  ...FormDataList,
                  'name',
                  'code',
                  'description',
                  'global_toggle',
                  'waste',
                  'users',
                  'owners',
                  'holders',
                  'user_groups',
                  'include_inactive_user_groups',
                  'include_inactive_users',
                  'record_type',
                  'selected_lead_status',
                ];
              }
            }
          }
          // if (form.getFieldValue('report_type') === 'summary' && (!form.getFieldValue('report_on') || form.getFieldValue('report_on') === 'saved_filter')) {
          //   form.setFieldsValue({
          //     report_on: 'all',
          //     report_by: 'all',
          //   })
          //   setReport_on_report('all');
          // }
          // if (form.getFieldValue('report_type') === 'records' && (!form.getFieldValue('report_on') || form.getFieldValue('report_on') === 'all')) {
          //   form.setFieldsValue({
          //     report_on: 'sold',
          //     date_filter_on: 'created'
          //   })
          //   setReport_on_report('sold');
          // }
          // if (form.getFieldValue('report_type') === 'records' && !form.getFieldValue('date_filter_on')) {
          //   form.setFieldsValue({
          //     date_filter_on: 'created'
          //   })
          // }
        }

        if (
          form.getFieldValue('report_on') &&
          form.getFieldValue('report_type') &&
          form.getFieldValue('report_type') === 'relationship'
        ) {
          FormDataList = [
            ...FormDataList,
            'report',
            'name',
            'code',
            'global_toggle',
            'waste',
            'description',
            'lead_id',
            'ticket_number',
            'organisation_name',
            'people_name',
            'related_to_rows',
          ];
          return FormDataList;
        } else {
          if (form.getFieldValue('report_on')) {
            if (form.getFieldValue('report_on') !== 'saved_filter') {
              FormDataList = [...FormDataList, 'report_by'];
            }
          }
        }

        if (
          form.getFieldValue('report_on') &&
          form.getFieldValue('report_on') === 'saved_filter'
        ) {
          FormDataList = [
            ...FormDataList,
            'name',
            'code',
            'global_toggle',
            'description',
            'filter_type',
            'filter_id',
            'sort_by_columns',
          ];
          if (
            !form.getFieldValue('sort_by_columns') &&
            FormDataList.includes('sort_by_columns')
          ) {
            form.setFieldsValue({
              sort_by_columns: [
                { sort_by: null, sort: 'desc' },
                { sort_by: null, sort: 'desc' },
                { sort_by: null, sort: 'desc' },
              ],
            });
          }
        } else {
          if (form.getFieldValue('report_by')) {
            FormDataList = [...FormDataList, 'report_for'];
            // if (!form.getFieldValue('report_for')) {
            //   form.setFieldsValue({
            //     report_for: 'all',
            //   });
            // }

            if (!USERLIST) {
              if (
                ['users', 'owners', 'holders'].includes(form.getFieldValue('report_by'))
              ) {
                SETUSERLIST(true);
                REPORTSTORE.getReportUsersList({ inactive: true });
              }
            }
            if (!USERGROUPLIST && form.getFieldValue('report_by') === 'user_group') {
              SETUSERGROUPLIST(true);
              REPORTSTORE.getReportUserGroupList({
                inactive: true,
                is_from_report: true,
              });
            }
          }

          if (
            form.getFieldValue('report_for') &&
            form.getFieldValue('report_type') === 'records'
          ) {
            FormDataList = [...FormDataList, 'date_filter_on'];
          }

          if (
            form.getFieldValue('report_for') ||
            (form.getFieldValue('enable_comparison_mode') &&
              form.getFieldValue('report_for_comparison_mode'))
          ) {
            if (
              form.getFieldValue('report_type') === 'summary' ||
              form.getFieldValue('report_type') === 'count_list'
            ) {
              FormDataList = [
                ...FormDataList,
                'name',
                'code',
                'global_toggle',
                'description',
                'report_for_comparison_mode',
                'enable_comparison_mode',
                'seperate_sum_users',
                'select_all_columns',
                'sum_call_clicks',
                'sum_email_clicks',
                'sum_total_time_spent',
                'sum_modified',
                'users',
                'owners',
                'holders',
                'user_groups',
                'include_inactive_user_groups',
                'include_inactive_users',
                // 'date_range',
                // 'single_date',
                // 'period_x_value_type',
                // 'period_x_value',
                // 'financial_year',
                // 'period_selection',
                // 'period_date_range',
              ];

              if (
                form.getFieldValue('report_on') &&
                form.getFieldValue('report_on') !== 'reminders'
              ) {
                FormDataList = [...FormDataList, 'waste'];
              }

              switch (form.getFieldValue('report_on')) {
                case 'all':
                  FormDataList = [
                    ...FormDataList,
                    'sold_created',
                    'sold_assigned',
                    'sold_sum_total',
                    'sum_sss_created',
                    'sum_results',
                    'sum_inbound_leads',
                    'sum_outbound_leads',
                    'sum_finance_value',
                    'sum_by_history_category',
                    'sum_by_stages',
                    'tag_sum_total',
                    'tag_created',
                    'tag_assigned',
                    'sum_by_tag_status',
                    'ticket_sum_total',
                    'ticket_created',
                    'ticket_assigned',
                    'sum_by_ticket_status',
                    'reminder_created',
                    'reminder_sum_total',
                    'reminder_assigned',
                    'sum_by_reminder_status',
                    'organisation_created',
                    'people_created',
                    'ticket_priority',
                  ];

                  if (form.getFieldValue('sum_by_history_category')) {
                    FormDataList = [...FormDataList, 'history_categories'];
                  }

                  if (form.getFieldValue('sum_by_stages')) {
                    FormDataList = [
                      ...FormDataList,
                      'include_bonus_figures',
                      'include_budget',
                      'record_type',
                      'parent_sold_stage',
                      'sold_stage',
                      'count_for_holders_only',
                    ];
                  }

                  if (form.getFieldValue('sum_by_tag_status')) {
                    FormDataList = [...FormDataList, 'tag_status'];
                  }
                  if (form.getFieldValue('sum_by_ticket_status')) {
                    FormDataList = [...FormDataList, 'ticket_status'];
                  }

                  if (form.getFieldValue('sum_by_reminder_status')) {
                    FormDataList = [...FormDataList, 'reminder_status'];
                  }
                  break;
                case 'sold':
                  FormDataList = [
                    ...FormDataList,
                    'sold_created',
                    'sold_assigned',
                    'sold_sum_total',
                    'display_stages_as_row',
                    'display_country_as_row',
                    'sold_by_country',
                    'count_historical_moments',
                    'sum_sss_created',
                    'sum_results',
                    'sum_inbound_leads',
                    'sum_outbound_leads',
                    'sum_modified',
                    'sum_finance_value',
                    'sum_by_history_category',
                    'sum_by_stages',
                    'sum_active',
                  ];
                  if (form.getFieldValue('sum_by_history_category')) {
                    FormDataList = [...FormDataList, 'history_categories'];
                  }
                  if (form.getFieldValue('sum_by_stages')) {
                    FormDataList = [
                      ...FormDataList,
                      'include_bonus_figures',
                      'include_budget',
                      'record_type',
                      'parent_sold_stage',
                      'sold_stage',
                      'count_for_holders_only',
                    ];
                  }

                  break;
                case 'tags':
                  FormDataList = [
                    ...FormDataList,
                    'tag_sum_total',
                    'tag_created',
                    'tag_assigned',
                    'sum_by_tag_status',
                  ];
                  if (form.getFieldValue('sum_by_tag_status')) {
                    FormDataList = [...FormDataList, 'tag_status'];
                  }

                  break;
                case 'tickets':
                  FormDataList = [
                    ...FormDataList,
                    'ticket_sum_total',
                    'ticket_created',
                    'ticket_assigned',
                    'sum_by_ticket_status',
                    'ticket_priority'
                  ];

                  if (form.getFieldValue('sum_by_ticket_status')) {
                    FormDataList = [...FormDataList, 'ticket_status'];
                  }

                  break;
                case 'reminders':
                  FormDataList = [
                    ...FormDataList,
                    'reminder_created',
                    'reminder_sum_total',
                    'reminder_assigned',
                    'sum_by_reminder_status',
                  ];

                  if (form.getFieldValue('sum_by_reminder_status')) {
                    FormDataList = [...FormDataList, 'reminder_status'];
                  }
                  break;
                case 'organisation':
                  FormDataList = [...FormDataList, 'organisation_created'];

                  break;
                case 'people':
                  FormDataList = [...FormDataList, 'people_created'];
                  break;
                default:
              }
            } else if (
              form.getFieldValue('date_filter_on') &&
              form.getFieldValue('report_type') === 'records'
            ) {
              if (form.getFieldValue('report_on') === 'reminders') {
                FormDataList = [
                  ...FormDataList,
                  'name',
                  'code',
                  'global_toggle',
                  'description',
                  'sort_by_columns',
                ];
              } else {
                FormDataList = [
                  ...FormDataList,
                  'name',
                  'code',
                  'global_toggle',
                  'waste',
                  'description',
                  'sort_by_columns',
                ];
              }

              switch (form.getFieldValue('report_on')) {
                case 'sold':
                  FormDataList = [
                    ...FormDataList,
                    'opportunity_name',
                    'classification_id',
                    'record_type',
                    'parent_sold_stage',
                    'sold_stage',
                    'product_name',
                    'lead_currency_id',
                    'lead_source',
                    'sold_source_details',
                    'note',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'turnover_min',
                    'turnover_max',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'sort_by_columns',
                  ];

                  break;
                case 'tags':
                  FormDataList = [
                    ...FormDataList,
                    'organisation_type',
                    'organisation',
                    'master_ticket_number',
                    'tag_status',
                    'ticket_number',
                    'sold_id',
                    'tag_subject',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'people_type',
                    'people',
                    'sort_by_columns',
                  ];

                  break;
                case 'tickets':
                  FormDataList = [
                    ...FormDataList,
                    'master_ticket_number',
                    'leads_tickets_status',
                    'ticket_type',
                    'ticket_priority',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'ticket_subject',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'sort_by_columns',
                  ];

                  // if (fetchTicketStatus) {
                  //   setfetchTicketStatus(false);
                  //   getTicketstatusList();
                  //   getDropdownPriority()
                  // }

                  break;
                case 'reminders':
                  FormDataList = [
                    ...FormDataList,
                    'reminder_type',
                    'reminder_status',
                    'reminder_priority',
                    'reminder_subject',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'reminder_ticket_id',
                    'reminder_leads_id',
                    'sort_by_columns',
                  ];
                  break;
                case 'organisation':
                  FormDataList = [
                    ...FormDataList,
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'classification_id',
                    'organisation_name',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'sort_by_columns',
                  ];

                  break;
                case 'people':
                  FormDataList = [
                    ...FormDataList,
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'classification_id',
                    'people_name',
                    'users',
                    'owners',
                    'holders',
                    'sort_by_columns',
                  ];

                  break;
                case 'saved_filter':
                  FormDataList = [
                    ...FormDataList,
                    'filter_type',
                    'filter_id',
                    'sort_by_columns',
                  ];
                  break;
                default:
              }
              if (
                !form.getFieldValue('sort_by_columns') &&
                FormDataList.includes('sort_by_columns')
              ) {
                form.setFieldsValue({
                  sort_by_columns: [
                    { sort_by: null, sort: 'desc' },
                    { sort_by: null, sort: 'desc' },
                    { sort_by: null, sort: 'desc' },
                  ],
                });
              }
            } else if (form.getFieldValue('report_type') === 'time_spent') {
              FormDataList = [
                ...FormDataList,
                'name',
                'code',
                'global_toggle',
                'waste',
                'description',
                'report_for_comparison_mode',
                'enable_comparison_mode',
                'users',
                'owners',
                'holders',
                'user_groups',
                'include_inactive_user_groups',
                'include_inactive_users',
                'group_on',
              ];

              switch (form.getFieldValue('report_on')) {
                case 'all':
                  FormDataList = [
                    ...FormDataList,
                    'record_type',
                    'lead_id',
                    'master_ticket_number',
                    'ticket_id',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                  ];
                  break;
                case 'sold':
                  FormDataList = [...FormDataList, 'record_type', 'lead_id'];
                  break;
                case 'tickets':
                  FormDataList = [...FormDataList, 'master_ticket_number', 'ticket_id'];
                  break;
                case 'organisation':
                  FormDataList = [...FormDataList, 'organisation_type', 'organisation'];
                  break;
                case 'people':
                  FormDataList = [...FormDataList, 'people_type', 'people'];
                  break;
                case 'others':
                  break;
                default:
              }
            }
          }
        }

        switch (form.getFieldValue('report_for')) {
          case 'yesterday':
            FormDataList = [...FormDataList];

            break;
          case 'last_week':
            FormDataList = [...FormDataList];

            break;
          case 'this_month':
            FormDataList = [...FormDataList];

            break;
          case 'this_quarter':
            FormDataList = [...FormDataList];

            break;
          case 'this_year':
            FormDataList = [...FormDataList];

            break;
          case 'date_range':
            FormDataList = [...FormDataList, 'date_range'];

            break;
          case 'single':
            FormDataList = [...FormDataList, 'single_date'];
            if (!form.getFieldValue('date')) {
              form.setFieldsValue({ date: moment(new Date(), 'YYYY-MM-DD') });
            }

            break;
          case 'this':
            FormDataList = [...FormDataList, 'period_x_value_type'];

            break;
          case 'last':
            FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type'];

            break;
          case 'financial_year':
            FormDataList = [...FormDataList, 'financial_year'];
            if (!GENERATEONFINANCIAL) {
              SETGENERATEONFINANCIAL(true);
              getFinancialYearList();
            }
            break;
          case 'periods':
            FormDataList = [...FormDataList, 'period_selection'];
            switch (form.getFieldValue('period_selection')) {
              case 'custom':
                FormDataList = [...FormDataList, 'period_date_range'];
                break;
              case 'auto':
                FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type'];
                break;
              default:
            }
            break;
          default:
        }

        return FormDataList;
      },
      [
        form,
        defaultFormDataList,
        getFinancialYearList,
        GENERATEONFINANCIAL,
        USERGROUPLIST,
        USERLIST,
        REPORTSTORE,
        // fetchTicketStatus,
        // setReport_on_report,
        // getTicketstatusList,
      ]
    );

    // UseEffect Added for Form Initialize
    useEffect(() => {
      if (initializeFormData && !isEdit) {
        async function setFormData() {
          await setFormDataList(getFormDataList());
        }
        setFormData();
        setinitializeFormData(false);
      }
    }, [getFormDataList, initializeFormData, isEdit, setFormDataList]);

    const handleChange = async (event, type) => {
      await setFormDataList(getFormDataList(type));
      if (form?.getFieldValue('report_type') === 'summary' || form?.getFieldValue('report_type') === 'count_list') {
        checkInterMediateCheckbox();
      }
      if (type === 'report_on') {
      }
      form
        .validateFields()
        .then((data) => {
          setDisabled(false);
        })
        .catch((e) => {
          if (e.errorFields.length > 0) {
            setDisabled(true);
          }
        });
    };

    // const handleEnableComparison = (e) => {
    //   if (e.target.checked) {
    //     form.setFieldsValue({
    //       report_for_comparison_mode: [
    //         { single_date: undefined, enable_radio_button: true, report_for: 'all' },
    //         { single_date: undefined, enable_radio_button: true, report_for: 'all' },
    //         { single_date: undefined, enable_radio_button: false },
    //         { single_date: undefined, enable_radio_button: false },
    //       ],
    //     });
    //   }
    // };

    const resetFieldsReportFor = (index) => {
      let formValue = form.getFieldValue('report_for_comparison_mode');
      delete formValue[index]['date_range'];
      delete formValue[index]['date'];
      delete formValue[index]['date_x_value'];
      delete formValue[index]['date_x_value_type'];
      delete formValue[index]['financial_year'];
      delete formValue[index]['period_x_value_type'];
      delete formValue[index]['period_x_value'];
      delete formValue[index]['single_date'];
      form.setFieldsValue({
        report_for_comparison_mode: formValue,
      });
    };

    const enableComparisonTime = (e, index) => {
      if (e.target.checked === false) {
        let formValue = form.getFieldValue('report_for_comparison_mode');
        formValue[index]['report_for'] = null;
        delete formValue[index]['date_range'];
        delete formValue[index]['date'];
        delete formValue[index]['date_x_value'];
        delete formValue[index]['date_x_value_type'];
        delete formValue[index]['financial_year'];
        delete formValue[index]['period_x_value_type'];
        delete formValue[index]['period_x_value'];
        delete formValue[index]['single_date'];
        form.setFieldsValue({
          report_for_comparison_mode: formValue,
        });
      } else {
        let formValue = form.getFieldValue('report_for_comparison_mode');
        formValue[index]['report_for'] = 'all';
        form.setFieldsValue({
          report_for_comparison_mode: formValue,
        });
      }
    };

    const handleReportForChange = (index) => {
      let formValue = form.getFieldValue('report_for_comparison_mode');
      switch (formValue[index]['report_for']) {
        case 'yesterday':
          break;
        case 'last_week':
          break;
        case 'this_month':
          break;
        case 'this_quarter':
          break;
        case 'this_year':
          break;
        case 'date_range':
          formValue[index]['date_range'] = null;

          break;
        case 'single':
          formValue[index]['single_date'] = null;

          break;
        case 'this':
          formValue[index]['period_x_value_type'] = null;

          break;
        case 'last':
          formValue[index]['period_x_value_type'] = null;
          formValue[index]['period_x_value'] = 1;

          break;
        case 'financial_year':
          formValue[index]['financial_year'] = null;
          break;
        case 'periods':
          formValue[index]['period_selection'] = null;
          switch (formValue[index]['period_selection']) {
            case 'custom':
              formValue[index]['period_date_range'] = null;
              break;
            case 'auto':
              formValue[index]['period_x_value_type'] = null;
              formValue[index]['period_x_value'] = null;
              break;
            default:
          }
          break;
        default:
      }

      form.setFieldsValue({
        report_for_comparison_mode: formValue,
      });
    };

    const checkInterMediateCheckbox = () => {
      let commonFields = [
        'sum_call_clicks',
        'sum_email_clicks',
        'sum_total_time_spent',
        'sum_modified',
      ];
      // --------  Add Fields to check for All Checkbox -------- //
      if (['all', 'sold'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'sold_created',
          'sold_assigned',
          'sold_sum_total',
          'sum_sss_created',
          'sum_results',
          'sum_inbound_leads',
          'sum_outbound_leads',
          'sum_finance_value',
          'sum_by_history_category',
          'sum_by_stages',
          'sum_active',
        ];
      }

      if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'tag_created',
          'tag_sum_total',
          'tag_assigned',
          'sum_by_tag_status',
        ];
      }

      if (['all', 'tickets'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'ticket_sum_total',
          'ticket_created',
          'ticket_assigned',
          'sum_by_ticket_status',
          'ticket_priority',
        ];
      }

      if (['all', 'reminders'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'reminder_created',
          'reminder_sum_total',
          'reminder_assigned',
          'sum_by_reminder_status',
        ];
      }

      if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'tag_created',
          'tag_sum_total',
          'tag_assigned',
          'sum_by_tag_status',
        ];
      }

      if (['all', 'organisation'].includes(form.getFieldValue('report_on'))) {
        commonFields = [...commonFields, 'organisation_created'];
      }

      if (['all', 'people'].includes(form.getFieldValue('report_on'))) {
        commonFields = [...commonFields, 'people_created'];
      }
      // --------  Add Fields to check for All Checkbox -------- //
      let count = 0;
      let ispartialChecked = false;
      commonFields.map((obj) => {
        if (form.getFieldValue([obj])) {
          count++;
        }
        return null;
      });
      if (count === commonFields.length) {
        ispartialChecked = false;
        form.setFieldsValue({
          select_all_columns: true,
        });
        setCheckAll(true);
      } else if (count > 0) {
        ispartialChecked = true;
        form.setFieldsValue({
          select_all_columns: false,
        });
        setCheckAll(false);
      } else {
        ispartialChecked = false;
        form.setFieldsValue({
          select_all_columns: false,
        });
        setCheckAll(false);
      }
      setIndeterminate(ispartialChecked);
      if (ispartialChecked) {
        form.setFieldsValue({
          ticket_priority: false,
        })
      }
    };

    const handleCheckAllCheckbox = async (e) => {
      let val = e.target.checked;
      let commonFields = [
        'sum_call_clicks',
        'sum_email_clicks',
        'sum_total_time_spent',
        'sum_modified',
      ];

      if (['all', 'sold'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'sold_created',
          'sold_assigned',
          'sold_sum_total',
          'sum_sss_created',
          'sum_results',
          'sum_inbound_leads',
          'sum_outbound_leads',
          'sum_finance_value',
          'sum_by_history_category',
          'sum_by_stages',
          'include_bonus_figures',
          'include_budget',
          'sum_active',
        ];
      }

      if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'tag_created',
          'tag_sum_total',
          'tag_assigned',
          'sum_by_tag_status',
        ];
      }

      if (['all', 'tickets'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'ticket_created',
          'ticket_sum_total',
          'ticket_assigned',
          'sum_by_ticket_status',
          'ticket_priority',
        ];
      }

      if (['all', 'reminders'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'reminder_created',
          'reminder_sum_total',
          'reminder_assigned',
          'sum_by_reminder_status',
        ];
      }

      if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
        commonFields = [
          ...commonFields,
          'tag_created',
          'tag_sum_total',
          'tag_assigned',
          'sum_by_tag_status',
        ];
      }

      if (['all', 'organisation'].includes(form.getFieldValue('report_on'))) {
        commonFields = [...commonFields, 'organisation_created'];
      }

      if (['all', 'people'].includes(form.getFieldValue('report_on'))) {
        commonFields = [...commonFields, 'people_created'];
      }

      if (val) {
        commonFields.map((obj) => {
          form.setFieldsValue({
            [obj]: true,
          });
          return null;
        });
      } else {
        commonFields.map((obj) => {
          form.setFieldsValue({
            [obj]: false,
          });
          return null;
        });
      }
      if (val) {
        form.setFieldsValue({
          ticket_priority: false,
        })
      }
      setIndeterminate(false);
      setCheckAll(val);
      await setFormDataList(getFormDataList());
    };

    const handleTicketPriorityCheckbox = (e) => {
      let commonFields = [
        'sum_call_clicks',
        'sum_email_clicks',
        'sum_total_time_spent',
        'sum_modified',

        'sold_created',
        'sold_assigned',
        'sold_sum_total',
        'sum_sss_created',
        'sum_results',
        'sum_inbound_leads',
        'sum_outbound_leads',
        'sum_finance_value',
        'sum_by_history_category',
        'sum_by_stages',
        'include_bonus_figures',
        'include_budget',
        'sum_active',

        'ticket_created',
        'ticket_sum_total',
        'ticket_assigned',
        'sum_by_ticket_status',

        'reminder_created',
        'reminder_sum_total',
        'reminder_assigned',
        'sum_by_reminder_status',

        'tag_created',
        'tag_sum_total',
        'tag_assigned',
        'sum_by_tag_status',

        'organisation_created',

        'people_created',
      ];
      // setTicketPriority(e.target.checked);
      form.setFieldsValue({
        ticket_priority: e.target.checked,
      })
      if (e.target.checked) {
        commonFields.map((obj) => {
          form.setFieldsValue({
            [obj]: false,
          });
          return null;
        });
        setCheckAll(false);
        setIndeterminate(false)
      }
    }

    const resetUserSelection = (e) => {
      let report_by = form.getFieldValue('report_by');
      form.setFieldsValue({
        [`${report_by ? report_by : 'users'}`]: undefined,
      });
    };

    // const changeSortSelection = () => {
    //   let sort_By_list = columnsForSortList.map((e) => ({
    //     ...e,
    //     disabled: false,
    //   }));
    //   let current_sort_list = form.getFieldValue('sort_by_columns');
    //   if (current_sort_list?.length) {
    //     current_sort_list.map((obj) => {
    //       if (obj.sort_by) {
    //         let col_index = sort_By_list.findIndex((x) => x.col_id === obj.sort_by);
    //         if (col_index !== -1) {
    //           sort_By_list[col_index]['disabled'] = true;
    //         }
    //       }
    //       return null;
    //     });
    //   }
    //   setColumnForSortList(sort_By_list);
    // };

    // const ascDescChange = (index, val) => {
    //   let current_obj = form.getFieldValue('sort_by_columns');
    //   if (current_obj[index]) {
    //     current_obj[index]['sort'] = val;
    //   }
    //   form.setFieldsValue({
    //     sort_by_columns: current_obj,
    //   });
    // };

    const handleStagesSelection = (e) => {
      if (!e.target.checked) {
        form.setFieldsValue({
          parent_sold_stage: undefined,
          record_type: undefined,
          sold_stage: undefined,
        });
        REPORTSTORE.getStatusList();
      }
    };
    //------------------END FORM ONCHANGE-EVENTS ----------------------//

    // const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    //   setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    // };

    // const handleTransferChange = async (newTargetKeys) => {
    //   await setTargetKeys(newTargetKeys);
    //   let payloadFields = [];
    //   mockData &&
    //     mockData.length > 0 &&
    //     mockData.forEach((x, i) => {
    //       if (newTargetKeys.includes(x.key)) {
    //         payloadFields.push(x.description);
    //       }
    //     });
    //   handleChange();
    //   setSelectedReportFieldsKeys(payloadFields);
    // };

    /**-----START - USE EFFECTS TO RERENDER */

    useEffect(() => {
      return () => {
        REPORTSTORE.report_on_report = null;
      };
    }, [REPORTSTORE]);

    useEffect(() => {
      if (formDataList.includes('report_type')) {
        setCountsListReport(true)
      } else {
        setCountsListReport(false)
      }
      if (report_on_report || form.getFieldValue('report_on')) {
        let allFieldKeysArr = [];
        let initialSelectedKeys = [];
        let defaultSelectedKeysArr = [];
        let reportType = report_on_report || form.getFieldValue('report_on');

        /**-----LOAD SAVED FILTER---- */
        if (reportType === 'saved_filter') {
          reportType = 'sold';
          if (
            form.getFieldValue('filter_type') &&
            getReportOnFromSavedFilterPageType[form.getFieldValue('filter_type')]
          ) {
            reportType =
              getReportOnFromSavedFilterPageType[form.getFieldValue('filter_type')];
          }
        }
        /**-----LOAD SAVED FILTER---- */
        // let colsWithoutId = null;
        // if (reportType === "tickets" && form.getFieldValue("ticket_priority")) {
        //   colsWithoutId = dynamicFieldsListReport?.['tickets'].filter((n) => n !== "id")
        // }

        let dynamicArray = dynamicFieldsListReport?.[reportType]
          ? dynamicFieldsListReport?.[reportType]
          : [];
        let defaultArray = dynamicFieldsListReport?.[reportType]
          ? dynamicFieldsListReport?.[reportType]
          : [];

        if (form.getFieldValue('waste')) {
          if (!defaultArray.includes('deleted_at')) {
            defaultArray = [...defaultArray, 'deleted_at'];
          }
          if (!dynamicArray.includes('deleted_at')) {
            dynamicArray = [...dynamicArray, 'deleted_at'];
          }
        }

        let tempSelectedReportFieldsKeys = [];
        if (localStorage.getItem('tempSelectedReportFieldsKeys')) {
          tempSelectedReportFieldsKeys = JSON.parse(
            localStorage.getItem('tempSelectedReportFieldsKeys')
          );
        }
        /*if (previewReportValues?.grid_columns) {
          tempSelectedReportFieldsKeys = previewReportValues?.grid_columns;
        }*/

        dynamicArray &&
          dynamicArray.length > 0 &&
          dynamicArray.map((item, i) => {
            let elementDetails = reportDynamicFieldsListColumnConfig?.[item];
            allFieldKeysArr.push({
              key: i.toString(),
              title: `${elementDetails && elementDetails?.title
                ? elementDetails?.title
                : capitalize(item)
                }`,
              description: item,
              disabled: defaultArray.includes(item) ? true : false,
            });
            if (defaultArray.includes(item)) {
              defaultSelectedKeysArr.push(i.toString());
              initialSelectedKeys.push(item);
            } else if (tempSelectedReportFieldsKeys.includes(item)) {
              defaultSelectedKeysArr.push(i.toString());
              initialSelectedKeys.push(item);
            }
            return null;
          });
        if (localStorage.getItem('tempSelectedReportFieldsKeys')) {
          localStorage.removeItem('tempSelectedReportFieldsKeys');
        }
        setMockData(allFieldKeysArr);
        setTargetKeys(defaultSelectedKeysArr);
        setSelectedReportFieldsKeys(initialSelectedKeys);
      }
    }, [
      report_on_report,
      form,
      setMockData,
      setTargetKeys,
      setSelectedReportFieldsKeys,
      //previewReportValues?.grid_columns,
      formDataList,
      setCountsListReport,
    ]);

    /**-----END - USE EFFECTS TO RERENDER */

    //------------------ UseEffect Added for Sort By Columns Transfer  ----------//
    useEffect(() => {
      if (columnsForSortList) {
        let sort_By_list = columnsForSortList;
        let current_sort_list = form.getFieldValue('sort_by_columns');
        if (current_sort_list?.length) {
          current_sort_list.map((obj) => {
            if (obj.sort_by) {
              let col_index = sort_By_list.findIndex((x) => x.col_id === obj.sort_by);
              if (col_index !== -1) {
                sort_By_list[col_index]['disabled'] = true;
              } else {
                obj.sort_by = undefined;
              }
            }
            return null;
          });
        }
        form.setFieldsValue({
          sort_by_columns: current_sort_list,
        });
      }
    }, [columnsForSortList, form]);
    //------------------ UseEffect Added for Sort By Columns Transfer  ----------//

    return form ? (
      <Form
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        id={id}
        className='innerForm addEditReport'
      >
        {/* <Row>
          <Col span={24}>
            <InputComponent
              label={'Report UI Type : '}
              required
              type='radio_button'
              name='selection_ui'
              options={{
                values: [
                  { value: 'radiobtn', text: 'Radio Button' },
                  { value: 'dropdown', text: 'Dropdown List' },
                ],
              }}
              onChange={handleChange}
              tooltip='Report UI Type.'
            />
          </Col>
        </Row> */}
        {/* {form.getFieldValue('selection_ui') ? (
          form.getFieldValue('selection_ui') === 'radiobtn' ? ( */}

        {true ? (
          true ? (
            <>
              {formDataList.includes('report_type') && (
                <Row gutter={12}>
                  <Col span={10}>
                    <InputComponent
                      name='report_type'
                      label={t('Reports.Report_Type_Title')}
                      allowClear
                      showArrow
                      showSearch
                      placeholder={t('Reports.Report_Type_Title')}
                      type='radio_button'
                      tooltip={t('Reports.Report_Type')}
                      onChange={() => {
                        // setFormDataList(defaultFormDataList);
                        onDepenentFieldsChange('report_type');
                        if (form.getFieldValue('report_type') === 'kpi') {
                          setCountsListReport(false)
                          form.setFieldsValue({
                            seperate_sum_users: true,
                            report_on: null
                          });
                        } else if (form.getFieldValue('report_type') === 'summary') {
                          setCountsListReport(false)
                          form.setFieldsValue({
                            seperate_sum_users: true,
                            report_on: null
                          });
                        }
                        else if (form.getFieldValue('report_type') === 'count_list') {
                          setCountsListReport(true)
                          form.setFieldsValue({
                            seperate_sum_users: true,
                            report_on: 'sold',
                            report_by: 'users',
                          })
                        } else {
                          setCountsListReport(false)
                        }
                        setSelectedReportFieldsKeys([]);
                        handleChange();
                        form.setFieldsValue({
                          enable_comparison_mode: undefined,
                          record_type: undefined,
                          parent_sold_stage: undefined,
                          sold_stage: undefined,
                        });
                      }}
                      options={{
                        values: reportTypeListNewLayout,
                      }}
                      disabled={
                        isEdit || isClone || (isPreview && previewReportValues?.id)
                          ? true
                          : false
                      }
                    />
                  </Col>
                  {formDataList.includes('report_on') && (
                    <Col span={14}>
                      <InputComponent
                        name='report_on'
                        label={t('Reports.Report_On_Title')}
                        allowClear
                        showArrow
                        showSearch
                        placeholder={t('Reports.Report_On_Title')}
                        type='radio_button'
                        tooltip={t('Reports.Report_On')}
                        onChange={(e) => {
                          // onDepenentFieldsChange('report_on');
                          setSelectedReportFieldsKeys([]);
                          setReport_on_report(e.target.value);
                          handleChange(e, 'report_on');
                          form.resetFields(['display_stages_as_row'])
                        }}
                        options={{
                          values: reportSubTypeList[form?.getFieldValue('report_type')],
                        }}
                        disabled={
                          isEdit || isClone || (isPreview && previewReportValues?.id)
                            ? true
                            : false
                        }
                      />
                    </Col>
                  )}
                </Row>
              )}
              {formDataList.includes('report_by') && (
                <>
                  <Row gutter={12}>
                    <Col
                      span={
                        listReportType.includes(form?.getFieldValue('report_type'))
                          ? 11
                          : 9
                      }
                    >
                      <InputComponent
                        name='report_by'
                        label={
                          <div className='labelWithCheckbox'>
                            <span className='title'>{t('Reports.Report_By_Title')}</span>
                            <div style={{visibility:"hidden"}}>{summaryType.includes(form?.getFieldValue('report_type')) ||
                              countsListType.includes(form?.getFieldValue('report_type')) ||
                              kpiReportType.includes(form?.getFieldValue('report_type')) ? (
                              <Form.Item
                                name='seperate_sum_users'
                                valuePropName='checked'
                                className='ml-5'
                              >
                                <Checkbox onChange={(e) => handleChange()}>
                                  {t('Reports.Display_Seperate_Sum')}
                                </Checkbox>
                              </Form.Item>
                            ) : null}
                            </div>
                          </div>
                        }
                        allowClear
                        showArrow
                        showSearch
                        placeholder={t('Reports.Report_By_Title')}
                        type='radio_button'
                        onChange={() => {
                          // onDepenentFieldsChange('report_by');
                          handleChange();
                          form.resetFields(['users', 'owners', 'holders', 'user_groups']);
                        }}
                        options={{
                          values:
                            reportByUserDropdown[form?.getFieldValue('report_type')],
                        }}
                      />
                    </Col>
                    {form.getFieldValue('report_by') &&
                      ['users', 'owners', 'holders'].includes(
                        form.getFieldValue('report_by')
                      ) && (
                        <UserSelectionElement
                          handleChange={handleChange}
                          resetUserSelection={resetUserSelection}
                          form={form}
                          report_by={form.getFieldValue('report_by')}
                        />
                      )}
                    {form.getFieldValue('report_by') &&
                      ['user_group'].includes(form.getFieldValue('report_by')) && (
                        <UsergroupSelectionElement handleChange={handleChange} />
                      )}
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              {formDataList.includes('report_type') && (
                <Row>
                  <Col span={12}>
                    <InputComponent
                      name='report_type'
                      label={t('Reports.Report_Type_Title')}
                      allowClear
                      required
                      showArrow
                      showSearch
                      placeholder={t('Reports.Report_Type_Title')}
                      type='select'
                      tooltip={t('Reports.Report_Type')}
                      onChange={() => {
                        form.resetFields(['name', 'code', 'report_on', 'report_for']);
                        setFormDataList(defaultFormDataList);
                        setSelectedReportFieldsKeys([]);
                        handleChange();
                      }}
                      options={{
                        values: reportTypeListNewLayout,
                      }}
                    />
                  </Col>
                </Row>
              )}

              {formDataList.includes('report_on') && (
                <Row gutter={12}>
                  <Col span={12}>
                    <InputComponent
                      required
                      name='report_on'
                      label={t('Reports.Report_On_Title')}
                      allowClear
                      showArrow
                      showSearch
                      placeholder={t('Reports.Report_On_Title')}
                      type='select'
                      tooltip={t('Reports.Report_On')}
                      onChange={(e) => {
                        handleChange(e, 'report_on');
                      }}
                      options={{
                        values: reportSubTypeList[form?.getFieldValue('report_type')],
                      }}
                    />
                  </Col>
                </Row>
              )}

              {formDataList.includes('report_by') && (
                <Row>
                  <Col span={11}>
                    <InputComponent
                      name='report_by'
                      label={t('Reports.Report_By_Title')}
                      allowClear
                      showArrow
                      required
                      showSearch
                      placeholder={t('Reports.Report_By_Title')}
                      type='select'
                      tooltip={t('Reports.Report_By')}
                      onChange={() => {
                        form.resetFields(['users', 'owners', 'holders', 'user_groups']);
                        handleChange();
                      }}
                      options={{
                        values: reportByUserDropdown[form?.getFieldValue('report_type')],
                      }}
                    />
                  </Col>
                  {form.getFieldValue('report_by') &&
                    ['users', 'owners', 'holders'].includes(
                      form.getFieldValue('report_by')
                    ) && (
                      <>
                        <UserSelectionElement
                          handleChange={handleChange}
                          resetUserSelection={resetUserSelection}
                          form={form}
                          report_by={form.getFieldValue('report_by')}
                        />
                        Yes
                      </>
                    )}
                  {form.getFieldValue('report_by') &&
                    ['user_group'].includes(form.getFieldValue('report_by')) && (
                      <>
                        <UsergroupSelectionElement handleChange={handleChange} />
                        No
                      </>
                    )}
                </Row>
              )}
            </>
          )
        ) : null}

        {formDataList.includes('report_for') && (
          <Row gutter={12}>
            <Col span={12}>
              <InputComponent
                label={
                  <div className='labelWithCheckbox'>
                    <span className='title'>{t('Reports.Report_For')} :</span>

                    {/* No futher needed : Enable Comparison Mode */}
                    {/* {summaryType.includes(form?.getFieldValue('report_type')) ||
                      countsListType.includes(form?.getFieldValue('report_type')) ||
                      timeSpentReportType.includes(form?.getFieldValue('report_type')) ? (
                      <Form.Item
                        name='enable_comparison_mode'
                        valuePropName='checked'
                        className='ml-5'
                      >
                        <Checkbox
                          onChange={(e) => {
                            handleEnableComparison(e);
                            handleChange();
                          }}
                        >
                          {t('Reports.Enable_Comparison_Mode')}
                        </Checkbox>
                      </Form.Item>
                    ) : null} */}

                  </div>
                }
                type={
                  form.getFieldValue('enable_comparison_mode')
                    ? 'labelOnly'
                    : 'radio_button'
                }
                name='report_for'
                options={{
                  values: reportForTypeNew,
                }}
                onChange={() => {
                  handleChange();
                  form.resetFields([
                    'date_range',
                    'date',
                    'date_x_value',
                    'date_x_value_type',
                    'financial_year',
                  ]);
                }}
                tooltip={t('Reports.Report_For')}
              />
            </Col>
            {form.getFieldValue('enable_comparison_mode') === true &&
              (summaryType.includes(form?.getFieldValue('report_type')) ||
                countsListType.includes(form?.getFieldValue('report_type')) ||
                timeSpentReportType.includes(form?.getFieldValue('report_type'))) ? (
              <Col span={24}>
                <ReportForComparisonModeElement
                  formDataList={formDataList}
                  onChange={handleChange}
                  handleReportForChange={handleReportForChange}
                  resetFieldsReportFor={resetFieldsReportFor}
                  enableComparisonTime={enableComparisonTime}
                  form={form}
                />
              </Col>
            ) : (
              <DateSelectionElement
                SetDateRange={SetDateRange}
                disabledDate={disabledDate}
                formDataList={formDataList}
                onChange={handleChange}
                form={form}
              />
            )}
          </Row>
        )}
        {formDataList.includes('date_filter_on') && (
          <Row>
            <Col span={24}>
              <InputComponent
                label={`${t('Reports.Date_Filter_On')} : `}
                type='radio_button'
                name='date_filter_on'
                options={{
                  values: getDateFilterOnReportList[form?.getFieldValue('report_on')]
                    ? getDateFilterOnReportList[form?.getFieldValue('report_on')]
                    : [],
                }}
                onChange={handleChange}
                tooltip={`${t('Reports.Date_Filter_On')}`}
              />
            </Col>
          </Row>
        )}
        {formDataList.includes('name', 'code') && (
          <Row gutter={12}>
            <Col span={24}>
              <legend>{t('Reports.Report_Information')} :</legend>
            </Col>
            <Col span={24}>
              <Row gutter={12}>
                <Col span={10}>
                  <InputComponent
                    required
                    label={`${t('Common_Data.Name')}`}
                    name='name'
                    placeholder={`${t('Reports.Report')} ${t('Common_Data.Name')}`}
                    onChange={handleChange}
                    tooltip={`${t('Reports.Report_Name_Tooltip')}.`}
                    rules={vsmTransactionReport.validation.name}
                    maxLength={100}
                    autoComplete='off'
                  />
                </Col>
                <Col span={4}>
                  <InputComponent
                    required
                    label={`${t('Reports.Code')}`}
                    name='code'
                    placeholder={`${t('Reports.Report')} ${t('Reports.Code')}`}
                    onChange={handleChange}
                    tooltip={`${t('Reports.Code_Tooltip')}.`}
                    rules={vsmTransactionReport.validation.code}
                    maxLength={10}
                  />
                </Col>
                <Col span={4} style={{ paddingTop: '10px' }}>
                  {/* <InputComponent label=' ' type='labelOnly' /> */}
                  <Form.Item label=' ' name='global_toggle' valuePropName='checked'>
                    <Checkbox>
                      {t('Reports.Private')} {t('Reports.Report')} ?
                    </Checkbox>
                  </Form.Item>
                </Col>
                {formDataList.includes('waste') && (
                  <>
                    <Col span={5} style={{ paddingTop: '10px' }}>
                      {/* <InputComponent label=' ' type='labelOnly' /> */}
                      <Form.Item label=' ' name='waste' valuePropName='checked'>
                        <Checkbox
                        // onChange={(e) =>
                        //   setMockDataToggleDeleteColumn(e.target.checked)
                        // }
                        >
                          {t('Reports.Include')} {t('Reports.Waste')}{' '}
                          {t('Common_Data.Records')} ?
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <InputComponent
                className='default_size_textarea'
                required
                type='textarea'
                rows={2}
                label={`${t('Common_Data.Description')}`}
                name='description'
                placeholder={`${t('Reports.Report')} ${t('Common_Data.Description')}`}
                onChange={handleChange}
                tooltip={`${t('Reports.Description_Tooltip')}.`}
                rules={vsmTransactionReport.validation.description}
                maxLength={1000}
              />
            </Col>
          </Row>
        )}
        {formDataList.includes('group_on') && (
          <Col span={12}>
            <InputComponent
              type='radio_button'
              label={`${t('Common_Data.Group')} ${t('Common_Data.On')}`}
              name='group_on'
              tooltip={`${t('Reports.Group_Tooltip')}.`}
              onChange={handleChange}
              options={{
                values: group_onArr,
              }}
            />
          </Col>
        )}

        {/* Summary Report Elements */}
        {form.getFieldValue('code') &&
          form.getFieldValue('name') &&
          form.getFieldValue('report_on') &&
          (summaryType.includes(form?.getFieldValue('report_type')) || countsListType.includes(form?.getFieldValue('report_type'))) && (
            <>
              {(!countsListType.includes(form?.getFieldValue('report_type')) && formDataList.includes('select_all_columns')) && (
                <CommonSummaryElement
                  form={form}
                  handleCheckAllCheckbox={handleCheckAllCheckbox}
                  handleTicketPriorityCheckbox={handleTicketPriorityCheckbox}
                  checkAll={checkAll}
                  setCheckAll={setCheckAll}
                  formDataList={formDataList}
                  indeterminate={indeterminate}
                  setIndeterminate={setIndeterminate}
                  onChange={handleChange}
                />
              )}
              {(form?.getFieldValue('report_on') === 'all' ||
                form.getFieldValue('report_on') === 'sold') && (
                  <SummarySOLDElement
                    form={form}
                    handleStagesSelection={handleStagesSelection}
                    formDataList={formDataList}
                    onChange={handleChange}
                    countsListReport={countsListReport}
                  />
                )}
              {(form?.getFieldValue('report_on') === 'all' ||
                form.getFieldValue('report_on') === 'tags') && (
                  <SummaryTagsElement
                    form={form}
                    formDataList={formDataList}
                    onChange={handleChange}
                  />
                )}
              {(form?.getFieldValue('report_on') === 'all' ||
                form.getFieldValue('report_on') === 'tickets') && (
                  <SummaryTicketsElement
                    form={form}
                    formDataList={formDataList}
                    onChange={handleChange}
                  />
                )}
              {(form?.getFieldValue('report_on') === 'all' ||
                form.getFieldValue('report_on') === 'reminders') && (
                  <SummaryReminderElement
                    form={form}
                    formDataList={formDataList}
                    onChange={handleChange}
                  />
                )}
              {(form?.getFieldValue('report_on') === 'all' ||
                form.getFieldValue('report_on') === 'people') && (
                  <SummaryPeopleElement
                    form={form}
                    formDataList={formDataList}
                    onChange={handleChange}
                  />
                )}
              {(form?.getFieldValue('report_on') === 'all' ||
                form.getFieldValue('report_on') === 'organisation') && (
                  <SummaryOrganisationElement
                    form={form}
                    formDataList={formDataList}
                    onChange={handleChange}
                  />
                )}
            </>
          )}

        {/* List Report Elements */}
        {form.getFieldValue('code') &&
          form.getFieldValue('name') &&
          form.getFieldValue('report_on') &&
          listReportType.includes(form?.getFieldValue('report_type')) && (
            <>
              <Row gutter={12}>
                {(form.getFieldValue('report_on') === 'saved_filter'
                  ? true
                  : form.getFieldValue('date_filter_on')) && (
                    <Col span={24}>
                      <legend>{t('Reports.Report_Criteria')} :</legend>
                    </Col>
                  )}
                {form.getFieldValue('report_on') &&
                  form.getFieldValue('report_on') === 'sold' && (
                    <SOLDReportCriteria
                      form={form}
                      formDataList={formDataList}
                      handleChange={handleChange}
                    />
                  )}
                {form.getFieldValue('report_on') &&
                  form.getFieldValue('report_on') === 'tags' && (
                    <TagsReportCriteria
                      form={form}
                      formDataList={formDataList}
                      handleChange={handleChange}
                    />
                  )}
                {form.getFieldValue('report_on') &&
                  form.getFieldValue('report_on') === 'tickets' && (
                    <TicketReportCriteria
                      form={form}
                      formDataList={formDataList}
                      handleChange={handleChange}
                    />
                  )}

                {form.getFieldValue('report_on') &&
                  form.getFieldValue('report_on') === 'reminders' && (
                    <ReminderReportElements
                      form={form}
                      formDataList={formDataList}
                      handleChange={handleChange}
                    />
                  )}
                {form.getFieldValue('report_on') &&
                  form.getFieldValue('report_on') === 'saved_filter' && (
                    <SavedFilterElements
                      form={form}
                      formDataList={formDataList}
                      handleChange={handleChange}
                    />
                  )}
                {form.getFieldValue('report_on') &&
                  form.getFieldValue('report_on') === 'people' && (
                    <PeopleformElements
                      form={form}
                      formDataList={formDataList}
                      handleChange={handleChange}
                    />
                  )}
                {form.getFieldValue('report_on') &&
                  form.getFieldValue('report_on') === 'organisation' && (
                    <OrgformElements
                      form={form}
                      formDataList={formDataList}
                      handleChange={handleChange}
                    />
                  )}
              </Row>
              {/* <FieldTransferComponent
                form={form}
                mockData={mockData}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}

                handleSelectChange={handleSelectChange}
                handleTransferChange={handleTransferChange}
              />
              <Row gutter={12}>
                <RecordsSortByElement
                  form={form}
                  onChange={handleChange}
                  formDataList={formDataList}
                  changeSortSelection={changeSortSelection}
                  ascDescChange={ascDescChange}
                />
              </Row> */}
            </>
          )}

        {/* List Report Elements */}
        {form.getFieldValue('code') &&
          form.getFieldValue('name') &&
          kpiReportType.includes(form?.getFieldValue('report_type')) && (
            <>
              <Row gutter={12}>
                <Col span={24}>
                  <legend>{t('Reports.Report_Criteria')} :</legend>
                </Col>
                <KPIReportCriteriaElement
                  formDataList={formDataList}
                  form={form}
                  handleChange={handleChange}
                />
                {form.getFieldValue('record_type') && (
                  <Col span={24}>
                    <KPISelectColumnsElement
                      form={form}
                      onChange={handleChange}
                      formDataList={formDataList}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}

        {/* Time Spent Report Elements */}
        {form.getFieldValue('code') &&
          form.getFieldValue('name') &&
          form.getFieldValue('report_on') &&
          form.getFieldValue('report_on') !== 'others' &&
          timeSpentReportType.includes(form?.getFieldValue('report_type')) && (
            <>
              <Row gutter={12}>
                <Col span={24}>
                  <legend>{t('Reports.Report_Criteria')} :</legend>
                </Col>
                <TimeSpentReportElement
                  form={form}
                  formDataList={formDataList}
                  handleChange={handleChange}
                />
              </Row>
            </>
          )}

        {/* Time Spent Report Elements */}
        {form.getFieldValue('code') &&
          form.getFieldValue('name') &&
          form.getFieldValue('report_on') &&
          relationShipsReportType.includes(form?.getFieldValue('report_type')) && (
            <>
              <Row gutter={12}>
                <Col span={24}>
                  <legend>{t('Reports.Report_Criteria')} :</legend>
                </Col>
                <FormRelationshipElements
                  form={form}
                  handleChange={handleChange}
                  formDataList={formDataList}
                />
              </Row>
            </>
          )}

        {formButtons && (
          <>
            <Divider />
            {formButtons}
          </>
        )}
      </Form>
    ) : null;
  }
);

export default FormComponent;
