import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';
import moment from 'moment';
import { vsmNotify } from '../../config/messages';
import GridConfig from '../../config/GridConfig';
import { convertTextToID } from '../../utils/GlobalFunction';

export default class SavedReportStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  preview_report_grid = null;
  agGridList = [];
  dropdown_models = [];
  deleteValues = null;
  previewReportValues = null;
  payload_drilldown_generated_on = null;

  dropdown_list = null;
  main_organisation_roles = null;
  main_people_roles = null;
  main_organisation = null;
  related_organisation = null;
  related_organisation_role = null;
  main_people = null;
  related_people = null;
  related_people_role = null;
  my_records = 'all';
  financial_year_list = null;
  cteTypesList = null;
  auditExportData = null;
  auditExportFormatedData = null;
  created_by_id = null;
  lead_type_list = [];
  lead_status_list = [];
  additional_fields = [];
  dropdown_reminder_type_list = [
    { value: 'contacts', text: 'Person' },
    { value: 'organisations', text: 'Organisations' },
    { value: 'leads', text: 'Leads-Deals-Opportunities' },
    { value: 'tickets', text: 'Tickets' },
    { value: 'tags', text: 'Notes' },
  ];
  dropdown_userGroupList = null;
  recordTypeList = [];
  performance_total_investment_hours = null;
  preview_report_criteria = null;
  investment_hour_grid_settings = null;

  initialValues = {
    report_type: 'leads',
    type: 1,
    journal_type: 'all',
    group_on: 'week',
    currency: 'ledger',
    single_based_on: 'single_transaction',
    multiple_based_on: 'multiple_transaction',
    main_entity: 'organisation',
    related_entity: 'organisation',
    transaction_ids: [null],
    field_values: [null],
    transaction_dates: [null],
    generate_on: 'single',
    date_selection: 'range',
    date_x_value: 1,
    date_x_value_type: 'day',
    audit_group_on: 'day',
    period_selection: 'auto',
    period_x_value: 2,
    period_x_value_type: 'day',
    period_date_range: [null, null],
    combined_reports: [null],
    cte_bank_ref: [null],
    cte_customer_ref: [null],
    org_people_bank_iban: [null],
    org_people_bank_acc_no: [null],
    calls_checkbox: true,
    include_related_item_types: ['SOLD', 'Tickets', 'Organisations', 'People'],
    related_item_type: 'organisations',
  };

  //--------------------START: USED IN REMINDER REPORTS - REMINDERFOR DROPDOWN-------//
  ticket_list_data = null;
  contacts_list_data = null;
  org_contacts_list_data = null;
  lead_search_dropdown_data = null;
  investmentHoursDetailDataByuser = null; //USED FOR INVESTMENT HOUR REPORT DRILL DOWN
  drilldownLoader = null; //USED FOR INVESTMENT HOUR REPORT DRILL DOWN

  fetchingTicketSearch = false;
  fetchingLead = false;
  fetchingPeople = false;
  fetchingOrg = false;
  //--------------------END: USED IN REMINDER REPORTS - REMINDERFOR DROPDOWN-------//

  reportGridLoader = true;

  globalStatusTempData = null;

  //----------------USED FOR EXPORTING PREVIEW FILTERED DATE RANGE DATA----------------//
  previewFilterForExport = null;
  //----------------USED FOR EXPORTING PREVIEW FILTERED DATE RANGE DATA----------------//

  reportColumnSettings = null;

  searchLeadKey = null;

  searchTicketKey = null;

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setPreviewReportValues = (data) => {
    this.previewReportValues = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // Setup grid and set column size to autosize
  setupReportGrid = (params) => {
    this.preview_report_grid = params;

    let columnConfig = this.reportColumnSettings;
    if (this.preview_report_grid && this.preview_report_grid.columnApi && columnConfig) {
      this.preview_report_grid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };

  // Setup grid and set column size to autosize
  setupPerformanceGrid = (params) => {
    this.agGridPerformanceReport = params;
    let columnConfig = this.reportColumnSettings;
    if (
      this.agGridPerformanceReport &&
      this.agGridPerformanceReport.columnApi &&
      columnConfig
    ) {
      this.agGridPerformanceReport.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };

  setSearchLeadKey = (data) => {
    this.searchLeadKey = data;
  }


  setSearchTicketKey = (data) => {
    this.searchTicketKey = data;
  }

  setinvestment_hour_grid_settings = (data) => {
    this.investment_hour_grid_settings = data;
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'report_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Set column width after resizing colums
  onChildnGridChanged = (params) => {
    params.api.redrawRows();
  };

  // Setup grid and set column size to autosize
  setupAuditGrid = (params, index) => {
    this.AuditGrid = params;
    let columnConfig = this.reportColumnSettings;
    if (this.AuditGrid && this.AuditGrid.columnApi && columnConfig) {
      this.AuditGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
  };

  // Setup grid and set column size to autosize
  setupGridList = (params, index) => {
    var gridApi = this.agGridList;
    gridApi[index] = params;
    this.agGridList = gridApi;
    var allColumnIds = [];
    if (params.columnApi) {
      params.columnApi.getAllColumns().forEach(function (column) {
        if (!column.colId.includes(['transaction_details'])) {
          allColumnIds.push(column.colId);
        }
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
    }
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    // this.setFilter(params.api)
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // export data as a csv
  exportData = async () => {
    this.agGrid.api.exportDataAsCsv();
  };

  // export data as a csv
  exportAudit = async () => {
    var account_numbers = [];
    var group_on = [];
    if (this.auditExportFormatedData && this.auditExportData) {
      Object.keys(this.auditExportData).forEach((AccountNumber) => {
        if (
          !['group_range', 'group_on', 'account_number'].includes(AccountNumber) &&
          !account_numbers.includes(AccountNumber)
        ) {
          account_numbers.push(AccountNumber);
        }
      });
      this.auditExportFormatedData.forEach((accountData) => {
        if (!group_on.includes(accountData.group_on)) {
          group_on.push(accountData.group_on);
        }
      });
      var csv_array = [];
      var csv_headerGroup = [''];
      account_numbers &&
        account_numbers.forEach((x, index) => {
          csv_headerGroup.push(x);
          csv_headerGroup.push(x);
          csv_headerGroup.push(x);
        });
      csv_array.push(csv_headerGroup);
      group_on &&
        group_on.forEach((x, index) => {
          var csv_row = [];
          if (index === 0) {
            csv_row = ['Group On'];
            account_numbers &&
              account_numbers.forEach((x) => {
                csv_row.push('Reciept');
                csv_row.push('Payment');
                csv_row.push('External');
              });
            csv_array.push(csv_row);
          }
          csv_row = [x];
          account_numbers &&
            account_numbers.forEach((account_no) => {
              var g_x = x;
              if ((x.match(/-/g) || []).length === 1) {
                g_x = x.replace('-', '_');
              }

              if (g_x && parseInt(g_x[0]) === 0) {
                g_x = g_x.slice(1);
              }
              if (
                this.auditExportData[account_no] &&
                this.auditExportData[account_no][g_x]
              ) {
                csv_row.push(`"${this.auditExportData[account_no][g_x].receipts.main +
                  '\n' +
                  this.auditExportData[account_no][g_x].receipts.errors
                  }
						"`);
                csv_row.push(`"${this.auditExportData[account_no][g_x].payments.main +
                  '\n' +
                  this.auditExportData[account_no][g_x].payments.errors
                  }
						"`);
                csv_row.push(`"${this.auditExportData[account_no][g_x].externals
                  ? this.auditExportData[account_no][g_x].externals.main +
                  '\n' +
                  this.auditExportData[account_no][g_x].externals.errors
                  : '0\n0'
                  }
						"`);
              } else {
                csv_row.push(`"0\n0"`);
                csv_row.push(`"0\n0"`);
                csv_row.push(`"0\n0"`);
              }
            });
          csv_array.push(csv_row);
        });
    }
    let csvContent =
      'data:text/csv;charset=utf-8,' + csv_array.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);

    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `auditlog-export-${moment().format('YYYY-MM-DD hh:mm:s')}.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click();
    document.body.removeChild(link);
  };

  // export multiple reports as a csv
  exportReport = () => {
    if (this.agGridList && this.agGridList.length) {
      this.agGridList.forEach((item) => {
        item.api.exportDataAsCsv({ columnGroups: true });
      });
    }
  };
  // export report from server as a csv
  exportReportFromServer = (payload) => {
    return Axios.post(`report/preview/export`, payload)
      .then(({ data }) => {
        if (data.data && data.data.url) {
          window.open(data.data.url, '_blank');
        }
        return data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };
  // export data from server as a csv
  exportDataFromServer = (payload) => {
    return Axios.post(`report/preview/data/export`, payload)
      .then(({ data }) => {
        if (data.data && data.data.url) {
          window.open(data.data.url, '_blank');
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  exportInvestmentHourReport = (name) => {
    if (this.AuditGrid) {
      let params = {
        fontSize: 10,
        rowHeight: 16,
        fileName: `${name}.csv`,
      };

      this.AuditGrid.api.exportDataAsCsv(params);
    }
  };

  exportPerformanceReport = (name) => {
    if (this.agGridPerformanceReport) {
      let params = {
        fontSize: 10,
        rowHeight: 16,
        fileName: `${name}.csv`,
      };

      this.agGridPerformanceReport.api.exportDataAsCsv(params);
    }
  };

  //call dropdown api
  getAllRecords = () => {
    return Axios.get('report/combined/dropdown/list').then(({ data }) => {
      this.dropdown_list = data.data;
      return data.data;
    });
  };

  // call list api
  getList = (payload = {}, my_reports = this.my_records) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }

    this.list_data = null;
    this.reportGridLoader = true;
    return Axios.post(`report/list`, payload).then(({ data }) => {
      this.reportGridLoader = false;
      if (data.data) {
        var temp = [];
        data.data.forEach((item) => {
          item.last_generated_by = item.user
            ? item.user.first_name + ' ' + item.user.last_name
            : 'N/A';
          if (my_reports !== 'all') {
            if (my_reports === item.created_by) {
              temp.push(item);
            }
          } else {
            temp.push(item);
          }
        });
        this.list_data = temp;
        this.total = temp.length;
      }
      if (this.agGrid && this.agGrid.columnApi) {
        let columnConfig = localStorage.getItem('report_grid');
        this.agGrid.columnApi.applyColumnState({
          state: columnConfig ? JSON.parse(columnConfig) : sort,
        });
        this.agGrid.api.setFilterModel(filter);
      }
      return data;
    });
  };

  // Call add api
  AddData = (formdata) => {
    return Axios.post(`/report/create`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`/report/edit/${formdata.id}`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call preview report api
  PreviewReport = (formdata) => {
    let url = '/report/preview';
    if (formdata.report_type === 'audit') {
      url = '/report/audit';
      if (formdata.action === 'preview_child_report') {
        url = '/report/audit/child';
      }
    }
    return Axios.post(url, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call preview report api
  PreviewData = (formdata) => {
    if (this.preview_report_grid) {
      var filter = this.preview_report_grid.api.getFilterModel();
      var sort = this.preview_report_grid.columnApi.getColumnState();
    }
    return Axios.post(`/report/preview/data`, formdata)
      .then(({ data }) => {
        this.performance_total_investment_hours = data?.total_investment_hours;
        this.preview_report_criteria = data?.preview_report_criteria;
        if (this.preview_report_grid && this.preview_report_grid.columnApi) {
          this.preview_report_grid.api.setFilterModel(filter);
          this.preview_report_grid.columnApi.applyColumnState({ state: sort });
        }
        let columnConfig = this.reportColumnSettings;
        if (
          this.preview_report_grid &&
          this.preview_report_grid.columnApi &&
          columnConfig
        ) {
          this.preview_report_grid.columnApi.applyColumnState({
            state: JSON.parse(columnConfig),
          });
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call Read report api
  ReadReport = (id, isClone = false) => {
    return Axios.get(`/report/read/${id}`)
      .then(({ data }) => {
        if (data.data) {
          if (data?.data?.global_toggle !== undefined) {
            data.data.global_toggle = !data.data.global_toggle;
          } else {
            data.data.global_toggle = false;
          }
          this.created_by_id = data.data.created_by;
          if (isClone) {
            let clonePreivewReportValues = {
              ...JSON.parse(data.data.generated_on_options),
              id: null,
            };
            delete clonePreivewReportValues.id;
            this.previewReportValues = clonePreivewReportValues;
          } else {
            this.previewReportValues = {
              ...JSON.parse(data.data.generated_on_options),
              id: data.data.id,
              name: data.data.name,
              code: data.data.code,
              description: data.data.description,
            };
          }
          if (this.previewReportValues?.global_toggle !== undefined) {
            this.previewReportValues.global_toggle =
              !this.previewReportValues.global_toggle;
          } else {
            this.previewReportValues.global_toggle = false;
          }

          //--------------------GRID COLUMN LOAD---------------------//
          if (data.data.column_settings && !isClone) {
            this.setReportColumnSettings(data.data.column_settings);
          }
          //--------------------GRID COLUMN LOAD---------------------//
        }
        return data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  // Call MODEL DROPDOWN api
  getAllModels = () => {
    return Axios.get(`/report/model/list`)
      .then(({ data }) => {
        this.dropdown_models = data.data;
        return data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.get(`report/delete/${formdata.id}`)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  formatFormData = (data, forRequest = true, isPreview = false) => {
    var temp;

    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          ![
            'form_list',
            'id',
            'action',
            'name',
            'code',
            'description',
            'json_load_reminder_for',
            'json_load_investment_hour',
            'global_toggle',
            'column_settings',
            'json_related_items_data',
          ].includes(key)
        ) {
          if (!data.form_list.includes(key)) {
            delete data[key];
          }
        }
      });

      if (forRequest) {
        // if (!isPreview) {
        this.previewReportValues = data;
        // }
        if (data.field_values) {
          var temp_field_values = [];
          data.field_values.map((item, index) => {
            if (item && JSON.stringify(item) !== '{}') {
              temp_field_values.push(item);
            }
            return null;
          });
          data.field_values = temp_field_values;
        }
        if (data.transaction_ids) {
          var temp_transaction_ids = [];
          data.transaction_ids.map((item, index) => {
            if (item && JSON.stringify(item) !== '{}') {
              temp_transaction_ids.push(item);
            }
            return null;
          });
          data.transaction_ids = temp_transaction_ids;
        }
        if (data.form_list.includes('organisation')) {
          data.organisation =
            data.organisation && data.organisation.length > 0 ? data.organisation : [0];
        }

        if (data.form_list.includes('people')) {
          data.people = data.people && data.people.length > 0 ? data.people : [0];
        }

        if (data.form_list.includes('cte_bank_ref') && data.cte_bank_ref) {
          data.cte_bank_ref = data.cte_bank_ref.filter((x) => x !== null && x !== '');
        }
        if (data.form_list.includes('cte_customer_ref') && data.cte_customer_ref) {
          data.cte_customer_ref = data.cte_customer_ref.filter(
            (x) => x !== null && x !== ''
          );
        }
        if (
          data.form_list.includes('org_people_bank_iban') &&
          data.org_people_bank_iban
        ) {
          data.org_people_bank_iban = data.org_people_bank_iban.filter(
            (x) => x !== null && x !== ''
          );
        }
        if (
          data.form_list.includes('org_people_bank_acc_no') &&
          data.org_people_bank_acc_no
        ) {
          data.org_people_bank_acc_no = data.org_people_bank_acc_no.filter(
            (x) => x !== null && x !== ''
          );
        }

        if (data.form_list.includes('transaction_ids') && data.transaction_ids) {
          data.transaction_ids = data.transaction_ids.filter(
            (x) => x !== null && x !== ''
          );
        }
        if (data.form_list.includes('field_values') && data.field_values) {
          data.field_values = data.field_values.filter((x) => x !== null && x !== '');
        }
        if (data.form_list.includes('transaction_dates') && data.transaction_dates) {
          data.transaction_dates = data.transaction_dates.filter(
            (x) => x !== null && x !== ''
          );
        }

        if (data.date) {
          data.date = moment(data.date).format('YYYY-MM-DD');
        }
        if (data.date_range && data.date_range.length === 2) {
          data.date_range = {
            from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
            to_date: moment(data.date_range[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.due_date && data.due_date.length === 2) {
          data.due_date = {
            from_date: moment(data.due_date[0]).format('YYYY-MM-DD'),
            to_date: moment(data.due_date[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.deadline_date_tags && data.deadline_date_tags.length === 2) {
          data.deadline_date_tags = {
            from_date: moment(data.deadline_date_tags[0]).format('YYYY-MM-DD'),
            to_date: moment(data.deadline_date_tags[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.deadline_date_tickets && data.deadline_date_tickets.length === 2) {
          data.deadline_date_tickets = {
            from_date: moment(data.deadline_date_tickets[0]).format('YYYY-MM-DD'),
            to_date: moment(data.deadline_date_tickets[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.deadline_date_leads && data.deadline_date_leads.length === 2) {
          data.deadline_date_leads = {
            from_date: moment(data.deadline_date_leads[0]).format('YYYY-MM-DD'),
            to_date: moment(data.deadline_date_leads[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.created_at && data.created_at.length === 2) {
          data.created_at = {
            from_date: moment(data.created_at[0]).format('YYYY-MM-DD'),
            to_date: moment(data.created_at[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.due_date_time && data.due_date_time.length === 2) {
          data.due_date_time = {
            from_date: moment(data.due_date_time[0]).format('YYYY-MM-DD'),
            to_date: moment(data.due_date_time[1]).format('YYYY-MM-DD'),
          };
        }

        if (data.completed_on && data.completed_on.length === 2) {
          data.completed_on = {
            from_date: moment(data.completed_on[0]).format('YYYY-MM-DD'),
            to_date: moment(data.completed_on[1]).format('YYYY-MM-DD'),
          };
        }

        if (data.modified_at && data.modified_at.length === 2) {
          data.modified_at = {
            from_date: moment(data.modified_at[0]).format('YYYY-MM-DD'),
            to_date: moment(data.modified_at[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.last_note_on && data.last_note_on.length === 2) {
          data.last_note_on = {
            from_date: moment(data.last_note_on[0]).format('YYYY-MM-DD'),
            to_date: moment(data.last_note_on[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.last_conversation_on && data.last_conversation_on.length === 2) {
          data.last_conversation_on = {
            from_date: moment(data.last_conversation_on[0]).format('YYYY-MM-DD'),
            to_date: moment(data.last_conversation_on[1]).format('YYYY-MM-DD'),
          };
        }
        if (data.turn_over && data?.turn_over?.length > 0) {
          let newTurnOver = [data.turn_over[0] * 1000000, data.turn_over[1] * 1000000];
          data.turn_over = newTurnOver;
        }
        if (data.investment_hours && data?.investment_hours?.length > 0) {
          let newInvestmentHour = [
            data.investment_hours[0] * 60 * 60,
            data.investment_hours[1] * 60 * 60,
          ];
          data.investment_hours = newInvestmentHour;
        }

        if (typeof data.parent_sold_stage === 'number') {
          data.parent_sold_stage = [data.parent_sold_stage];
        }
        if (typeof data.record_type === 'number') {
          data.record_type = [data.record_type];
        }

        if (data.period_date_range) {
          temp = [];
          data.period_date_range.map((item, index) => {
            if (item && item.length === 2) {
              temp.push({
                from_date: moment(item[0]).format('YYYY-MM-DD'),
                to_date: moment(item[1]).format('YYYY-MM-DD'),
              });
            }
            return null;
          });
          if (temp.length > 0) {
            data.period_date_range = temp;
          }
        }
        if (data.transaction_dates) {
          temp = [];
          data.transaction_dates.map((item, index) => {
            if (
              item &&
              item.transaction_date_value &&
              item.transaction_date_value.length === 2
            ) {
              temp.push({
                ...item,
                transaction_date_value: {
                  from_date: moment(item.transaction_date_value[0]).format('YYYY-MM-DD'),
                  to_date: moment(item.transaction_date_value[1]).format('YYYY-MM-DD'),
                },
              });
            }
            return null;
          });
          data.transaction_dates = temp;
        }
        if (data.report_type && data.report_type === 'reminders') {
          let jsonLoadReminderFor = {};
          if (data?.reminder_type?.length > 0) {
            if (data.reminder_type.includes('tickets') && data.reminder_ticket_id) {
              temp = [];
              this.ticket_list_data &&
                this.ticket_list_data.length > 0 &&
                this.ticket_list_data.map((item, index) => {
                  if (data.reminder_ticket_id.includes(item.id)) {
                    temp.push({
                      ...item,
                    });
                  }
                  return null;
                });
              jsonLoadReminderFor.reminder_ticket_id = temp;
            } else {
              if (data.reminder_ticket_id) {
                delete data.reminder_ticket_id;
              }
            }
            if (data.reminder_type.includes('leads') && data.reminder_leads_id) {
              temp = [];
              this.lead_search_dropdown_data &&
                this.lead_search_dropdown_data.length > 0 &&
                this.lead_search_dropdown_data.map((item, index) => {
                  if (data.reminder_leads_id.includes(item.id)) {
                    temp.push({
                      ...item,
                    });
                  }
                  return null;
                });
              jsonLoadReminderFor.reminder_leads_id = temp;
            } else {
              if (data.reminder_leads_id) {
                delete data.reminder_leads_id;
              }
            }
            if (data.reminder_type.includes('contacts') && data.reminder_contacts_id) {
              temp = [];
              this.contacts_list_data &&
                this.contacts_list_data?.people &&
                this.contacts_list_data?.people.length &&
                this.contacts_list_data?.people.map((item, index) => {
                  if (data.reminder_contacts_id.includes('p' + item.id)) {
                    temp.push({
                      ...item,
                    });
                  }
                  return null;
                });
              jsonLoadReminderFor.reminder_contacts_id = temp;
            } else {
              if (data.reminder_contacts_id) {
                delete data.reminder_contacts_id;
              }
            }
            if (
              data.reminder_type.includes('organisations') &&
              data.reminder_organisations_id
            ) {
              temp = [];
              this.org_contacts_list_data &&
                this.org_contacts_list_data?.organisation &&
                this.org_contacts_list_data?.organisation.length &&
                this.org_contacts_list_data?.organisation.map((item, index) => {
                  if (data.reminder_organisations_id.includes('o' + item.id)) {
                    temp.push({
                      ...item,
                    });
                  }
                  return null;
                });
              jsonLoadReminderFor.reminder_organisations_id = temp;
            } else {
              if (data.reminder_organisations_id) {
                delete data.reminder_organisations_id;
              }
            }
          } else {
            data.reminder_ticket_id = [];
            data.reminder_leads_id = [];
            data.reminder_contacts_id = [];
            data.reminder_organisations_id = [];
          }
          data.jsonLoadReminderFor = jsonLoadReminderFor;
        }
        if (data.report_type && data.report_type === 'investment_hours') {
          let jsonLoadInvestmentHour = {};
          if (data.lead_id) {
            temp = [];
            this.lead_list_data &&
              this.lead_list_data.length > 0 &&
              this.lead_list_data.map((item, index) => {
                if (data.lead_id.includes(item.id)) {
                  temp.push({
                    ...item,
                  });
                }
                return null;
              });
            jsonLoadInvestmentHour.lead_id = temp;
          }
          if (data.ticket_id) {
            temp = [];
            this.ticket_list_data &&
              this.ticket_list_data.length > 0 &&
              this.ticket_list_data.map((item, index) => {
                if (data.ticket_id.includes(item.id)) {
                  temp.push({
                    ...item,
                  });
                }
                return null;
              });
            jsonLoadInvestmentHour.ticket_id = temp;
          }
          data.jsonLoadInvestmentHour = jsonLoadInvestmentHour;
          if (data?.generate_on) {
            this.payload_drilldown_generated_on = data;
          }
        }

        //-----------------START- JSON OBJECT TO LOAD ON EDIT TIME------------//
        if (data.report_type && data.report_type === 'related_items') {
          let json_related_items_data = {};
          if (data.related_item_leads_id && data.related_item_type === 'leads') {
            temp = [];
            temp =
              this.lead_search_dropdown_data &&
              this.lead_search_dropdown_data.length > 0 &&
              this.lead_search_dropdown_data.filter(
                (x) => x.id === data.related_item_leads_id
              );
            json_related_items_data.related_item_leads_id = temp;
          } else {
            if (data.related_item_leads_id) {
              delete data.related_item_leads_id;
            }
          }
          if (data.related_item_ticket_id && data.related_item_type === 'tickets') {
            temp = [];
            temp =
              this.ticket_list_data &&
              this.ticket_list_data.length > 0 &&
              this.ticket_list_data.filter((x) => x.id === data.related_item_ticket_id);
            json_related_items_data.related_item_ticket_id = temp;
          } else {
            if (data.related_item_ticket_id) {
              delete data.related_item_ticket_id;
            }
          }
          if (data.related_item_contacts_id && data.related_item_type === 'contacts') {
            temp = [];
            temp =
              this.contacts_list_data?.people &&
              this.contacts_list_data?.people.length > 0 &&
              this.contacts_list_data?.people.filter(
                (x) => 'p' + x.id === data.related_item_contacts_id
              );
            json_related_items_data.related_item_contacts_id = temp;
          } else {
            if (data.related_item_contacts_id) {
              delete data.related_item_contacts_id;
            }
          }

          if (
            data.related_item_organisations_id &&
            data.related_item_type === 'organisations'
          ) {
            temp = [];
            temp =
              this.org_contacts_list_data?.organisation &&
              this.org_contacts_list_data?.organisation.length > 0 &&
              this.org_contacts_list_data?.organisation.filter(
                (x) => 'o' + x.id === data.related_item_organisations_id
              );
            json_related_items_data.related_item_organisations_id = temp;
          } else {
            if (data.related_item_organisations_id) {
              delete data.related_item_organisations_id;
            }
          }
          data.json_related_items_data = json_related_items_data;
        }
        //-----------------END- JSON OBJECT TO LOAD ON EDIT TIME------------//

        //------------------- IF PAGE FILTER IS APPLIED REMOVED OTHER KEYS OTHER THAN REQUIRED KEYS ----------------------------//
        if (data.form_list.includes('filter_id') && data.filter_id) {
          let newData = {
            report_type: data.report_type,
            id: data.id,
            name: data.name,
            code: data.code,
            description: data.description,
            filter_id: data.filter_id,
            action: data.action,
            global_toggle: data.global_toggle,
            waste: data.waste,
            people: data.people,
            organisation: data.organisation,
            form_list: data.form_list,
          };
          data = newData;
        }
        //----------------------------------------------------------------------------------------------------------------------//
      } else {
        if (
          data.form_list.includes('organisation') &&
          JSON.stringify(data.organisation) === JSON.stringify([0])
        ) {
          data.organisation = undefined;
        }

        if (
          data.form_list.includes('people') &&
          JSON.stringify(data.people) === JSON.stringify([0])
        ) {
          data.people = undefined;
        }

        if (data.form_list.includes('cte_bank_ref') && data.cte_bank_ref) {
          data.cte_bank_ref = data.cte_bank_ref.length > 0 ? data.cte_bank_ref : [null];
        }
        if (data.form_list.includes('cte_customer_ref') && data.cte_customer_ref) {
          data.cte_customer_ref =
            data.cte_customer_ref.length > 0 ? data.cte_customer_ref : [null];
        }
        if (
          data.form_list.includes('org_people_bank_iban') &&
          data.org_people_bank_iban
        ) {
          data.org_people_bank_iban =
            data.org_people_bank_iban.length > 0 ? data.org_people_bank_iban : [null];
        }
        if (
          data.form_list.includes('org_people_bank_acc_no') &&
          data.org_people_bank_acc_no
        ) {
          data.org_people_bank_acc_no =
            data.org_people_bank_acc_no.length > 0 ? data.org_people_bank_acc_no : [null];
        }

        if (data.form_list.includes('transaction_ids') && data.transaction_ids) {
          data.transaction_ids =
            data.transaction_ids.length > 0 ? data.transaction_ids : [null];
        }
        if (data.form_list.includes('field_values') && data.field_values) {
          data.field_values = data.field_values.length > 0 ? data.field_values : [null];
        }
        if (data.form_list.includes('transaction_dates') && data.transaction_dates) {
          data.transaction_dates =
            data.transaction_dates.length > 0 ? data.transaction_dates : [null];
        }

        if (data.turn_over && data?.turn_over?.length > 0) {
          let newTurnOver = [data.turn_over[0] / 1000000, data.turn_over[1] / 1000000];
          data.turn_over = newTurnOver;
        }
        if (data.investment_hours && data?.investment_hours?.length > 0) {
          let newInvestmentHour = [
            Math.round(data.investment_hours[0] / (60 * 60)).toFixed(0),
            Math.round(data.investment_hours[1] / (60 * 60)).toFixed(0),
          ];
          data.investment_hours = newInvestmentHour;
        }

        if (data.date) {
          data.date = moment(data.date);
        }
        if (data.date_range) {
          if (Array.isArray(data.date_range)) {
            if (data.date_range && data.date_range.length === 2) {
              data.date_range = [moment(data.date_range[0]), moment(data.date_range[1])];
            }
          } else {
            data.date_range = [
              moment(data.date_range.from_date),
              moment(data.date_range.to_date),
            ];
          }
        }

        if (data.due_date && data.due_date.from_date) {
          data.due_date = [
            moment(data.due_date.from_date),
            moment(data.due_date.to_date),
          ];
        }

        if (data.deadline_date_leads && data.deadline_date_leads.from_date) {
          data.deadline_date_leads = [
            moment(data.deadline_date_leads.from_date),
            moment(data.deadline_date_leads.to_date),
          ];
        }

        if (data.created_at && data.created_at.from_date) {
          data.created_at = [
            moment(data.created_at.from_date),
            moment(data.created_at.to_date),
          ];
        }
        if (data.due_date_time && data.due_date_time.from_date) {
          data.due_date_time = [
            moment(data.due_date_time.from_date),
            moment(data.due_date_time.to_date),
          ];
        }

        if (data.completed_on && data.completed_on.from_date) {
          data.completed_on = [
            moment(data.completed_on.from_date),
            moment(data.completed_on.to_date),
          ];
        }

        if (data.modified_at && data.modified_at.from_date) {
          data.modified_at = [
            moment(data.modified_at.from_date),
            moment(data.modified_at.to_date),
          ];
        }

        if (data.last_conversation_on && data.last_conversation_on.from_date) {
          data.last_conversation_on = [
            moment(data.last_conversation_on.from_date),
            moment(data.last_conversation_on.to_date),
          ];
        }

        if (data.last_note_on && data.last_note_on.from_date) {
          data.last_note_on = [
            moment(data.last_note_on.from_date),
            moment(data.last_note_on.to_date),
          ];
        }

        if (data.deadline_date_tags && data.deadline_date_tags.from_date) {
          data.deadline_date_tags = [
            moment(data.deadline_date_tags.from_date),
            moment(data.deadline_date_tags.to_date),
          ];
        }

        if (data.deadline_date_tickets && data.deadline_date_tickets.from_date) {
          data.deadline_date_tickets = [
            moment(data.deadline_date_tickets.from_date),
            moment(data.deadline_date_tickets.to_date),
          ];
        }
        if (
          data.period_date_range &&
          data.period_date_range[0] &&
          data.period_date_range[0].from_date
        ) {
          temp = [];
          data.period_date_range.forEach((item, index) => {
            temp.push([moment(item.from_date), moment(item.to_date)]);
          });
          data.period_date_range = temp;
        }
        if (
          data.transaction_dates &&
          data.transaction_dates[0] &&
          data.transaction_dates[0].transaction_date_value.from_date
        ) {
          temp = [];
          data.transaction_dates.forEach((item, index) => {
            if (item && item.transaction_date_value) {
              temp.push({
                ...item,
                transaction_date_value: [
                  moment(item.transaction_date_value.from_date),
                  moment(item.transaction_date_value.to_date),
                ],
              });
            }
            return null;
          });
          if (temp.length <= 0) {
            temp = [null];
          }
          data.transaction_dates = temp;
        }
        //---------------START:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//
        if (data.json_load_reminder_for) {
          if (data.json_load_reminder_for?.reminder_organisations_id) {
            this.org_contacts_list_data = {
              organisation: data.json_load_reminder_for?.reminder_organisations_id,
            };
          }
          if (data.json_load_reminder_for?.reminder_contacts_id) {
            this.contacts_list_data = {
              people: data.json_load_reminder_for?.reminder_contacts_id,
            };
          }
          if (data.json_load_reminder_for?.reminder_leads_id) {
            this.lead_search_dropdown_data =
              data.json_load_reminder_for?.reminder_leads_id;
          }
          if (data.json_load_reminder_for?.reminder_ticket_id) {
            this.ticket_list_data = data.json_load_reminder_for?.reminder_ticket_id;
          }
        }
        //---------------END:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//

        //---------------START:INVESTMENT HOUR SOLD ID LOAD DROPDOWN DATA-------------//
        if (data.json_load_investment_hour) {
          if (data.json_load_investment_hour?.lead_id) {
            this.lead_list_data = data.json_load_investment_hour?.lead_id;
          }
          if (data.json_load_investment_hour?.ticket_id) {
            this.ticket_list_data = data.json_load_investment_hour?.ticket_id;
          }
        }
        //---------------END:INVESTMENT HOUR SOLD ID LOAD DROPDOWN DATA-------------//

        //---------------START:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//
        if (data.json_load_reminder_for) {
          if (data.json_load_reminder_for?.reminder_organisations_id) {
            this.org_contacts_list_data = {
              organisation: data.json_load_reminder_for?.reminder_organisations_id,
            };
          }
          if (data.json_load_reminder_for?.reminder_contacts_id) {
            this.contacts_list_data = {
              people: data.json_load_reminder_for?.reminder_contacts_id,
            };
          }
          if (data.json_load_reminder_for?.reminder_leads_id) {
            this.lead_search_dropdown_data =
              data.json_load_reminder_for?.reminder_leads_id;
          }
          if (data.json_load_reminder_for?.reminder_ticket_id) {
            this.ticket_list_data = data.json_load_reminder_for?.reminder_ticket_id;
          }
        }
        //---------------END:REMINDER REPORT - REMINDER FOR FIELD DROPDOWN DATA LOAD FROM JSON-------------//

        //---------------START:RELATED ITEMS REPORT - IDS object load -------------//
        if (data.json_related_items_data) {
          if (data.json_related_items_data?.related_item_organisations_id) {
            this.org_contacts_list_data = {
              organisation: data.json_related_items_data?.related_item_organisations_id,
            };
          }
          if (data.json_related_items_data?.related_item_contacts_id) {
            this.contacts_list_data = {
              people: data.json_related_items_data?.related_item_contacts_id,
            };
          }
          if (data.json_related_items_data?.related_item_leads_id) {
            this.lead_search_dropdown_data =
              data.json_related_items_data?.related_item_leads_id;
          }
          if (data.json_related_items_data?.related_item_ticket_id) {
            this.ticket_list_data = data.json_related_items_data?.related_item_ticket_id;
          }
        }
        //---------------END:RELATED ITEMS REPORT - IDS object load -------------//
      }
    }

    return data;
  };

  saveToMenu = (formdata, is_favorite) => {
    return Axios.post(`report/favorite/status/${formdata}`, { is_favorite: is_favorite })
      .then(({ data }) => {
        vsmNotify.success({
          message: data.message,
        });
        this.getList();
        return data;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  getMainRoles = (type = 'organisation') => {
    var payload = { main_entity: type };
    return Axios.post(`report/main/role`, payload).then(({ data }) => {
      if (type === 'people') {
        this.main_people_roles = data.data;
      } else {
        this.main_organisation_roles = data.data;
      }
      return data;
    });
  };

  getDependentList = (payload) => {
    if (payload && !payload.related_entity) {
      payload.related_entity = 'organisation';
      payload.related_organisation_role = null;
    }
    return Axios.post(`report/dropdown/list`, payload).then(({ data }) => {
      this.main_organisation = data.data.main_organisation;
      this.related_organisation = data.data.related_organisation;
      this.related_organisation_role = data.data.related_organisation_role;
      this.main_people = data.data.main_people;
      this.related_people = data.data.related_people;
      this.related_people_role = data.data.related_people_role;
      return data;
    });
  };

  getFinancialYearList = () => {
    return Axios.get(`report/financial/year`).then(({ data }) => {
      this.financial_year_list = data.data;

      return data;
    });
  };
  PreviewDetailPayload = {};
  agPreviewDetailGrid = null;
  previewDetailGrid = [];
  previewReportID = null;
  PreviewDetailPinnedRow = null;
  ZeroRecord = true;
  filter_org = [];
  filter_related_org = [];
  setupPreviewDetailGrid = (params) => {
    this.agPreviewDetailGrid = params;
    var datasource = this.createDatasource(GridConfig.options);
    params.api.setServerSideDatasource(datasource);
  };

  getOrgFilter = (type) => {
    var extra_payload = {};
    if (SavedReportStore.previewReportID) {
      extra_payload.report_id = parseInt(SavedReportStore.previewReportID);
    }
    let formdata = {
      ...this.PreviewDetailPayload,
      zero_record: this.ZeroRecord,
      dropdown_type: type,
    };

    return Axios.post(`report/child/data`, { ...formdata, ...extra_payload })
      .then(({ data }) => {
        if (type === 'entity') {
          this.filter_org = data.data;
        } else {
          this.filter_related_org = data.data;
        }
        return data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };

  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = {
          final_filter: params.request.filterModel,
          final_sort: params.request.sortModel,
        };
        if (filter_data.final_filter['entity_name']) {
          filter_data.final_filter['entity_name'].values = convertTextToID(
            filter_data.final_filter['entity_name'],
            this.filter_org,
            'entity_name',
            'entity_id'
          );
        }
        if (filter_data.final_filter['related_entity_name']) {
          filter_data.final_filter['related_entity_name'].values = convertTextToID(
            filter_data.final_filter['related_entity_name'],
            this.filter_related_org,
            'related_entity_name',
            'related_entity_id'
          );
        }

        var payload = {
          ...this.PreviewDetailPayload,
          zero_record: this.ZeroRecord,
          filter_data: filter_data.final_filter,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        this.PreviewDetails(payload).then((data) => {
          this.PreviewDetailPinnedRow = data.extra_data;
          params.successCallback(data.data, data.total);
        });
      },
    };
  };

  // Call preview report details
  PreviewDetails = (formdata) => {
    var extra_payload = {};
    if (SavedReportStore.previewReportID) {
      extra_payload.report_id = parseInt(SavedReportStore.previewReportID);
    }
    if (!formdata.filter_data) {
      extra_payload.filter_data = {};
    }
    if (!formdata.sort_data) {
      extra_payload.sort_data = {};
    }
    return Axios.post(`report/child/data`, { ...formdata, ...extra_payload })
      .then(({ data }) => {
        if (data.data.length) {
          data.data.forEach((item, index) => {
            item.entity_name = item.entity_name ? item.entity_name : null;
            item.related_entity_name = item.related_entity_name
              ? item.related_entity_name
              : null;
            item.face_value = item.face_value ? item.face_value : 0.0;
            item.journal_log_date = item.journal_log_date ? item.journal_log_date : null;
            item.debit = item.debit ? item.debit : 0.0;
            item.credit = item.credit ? item.credit : 0.0;
            item.posting = item.posting ? item.posting : 0.0;
            if (item.periods) {
              item.periods.forEach((p) => {
                p.debit = p.debit ? p.debit : 0.0;
                p.credit = p.credit ? p.credit : 0.0;
                p.posting = p.posting ? p.posting : 0.0;
              });
            }
          });
        }
        return data;
      })
      .catch(({ response }) => {
        let data = {};
        if (response) {
          data = response.data;
          var errors = [];
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
          data.errors = errors;
        }
        return Promise.reject(data);
      });
  };

  getCashTransactionType = () => {
    return Axios.get('report/cash/transactions/types').then(({ data }) => {
      this.cteTypesList = data.data;

      return data.data;
    });
  };

  //Get Lead Type List
  getLeadTypeList = (form = null) => {
    Axios.get('leads-status/status/list').then(({ data }) => {
      let lead_type_list_temp = data.data;

      lead_type_list_temp &&
        lead_type_list_temp.length > 0 &&
        lead_type_list_temp.sort(function (a, b) {
          const nameA = a.status_name;
          const nameB = b.status_name;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      this.lead_type_list = lead_type_list_temp;

      if (form?.report_type && form?.report_type === 'performance') {
        this.getStatusList([], []).then(() => { }); // Due to independent dropdown
      } else {
        this.getStatusList(form?.parent_sold_stage, form?.record_type).then(() => { });
      }
    });
  };

  getRecordType = (type = {}) => {
    return Axios.get('leads-record-type/list', type).then(({ data }) => {
      let recordTypeList_temp = data.data;
      recordTypeList_temp &&
        recordTypeList_temp.length > 0 &&
        recordTypeList_temp.sort(function (a, b) {
          const nameA = a.record_type_name;
          const nameB = b.record_type_name;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      this.recordTypeList = recordTypeList_temp;
      return data;
    });
  };

  getClassificationList = () => {
    return Axios.post(`industry/list`).then(({ data }) => {
      this.classification_role_list = data.data;
      return data;
    });
  };

  getProductList = () => {
    return Axios.post(`products-names`).then(({ data }) => {
      this.product_list = data.data;

      return data;
    });
  };

  // Get Status List by Lead Type
  getStatusList = (lead_list = [], record_type_list = []) => {
    let status_list = [];
    let tempList = this.lead_type_list;
    if (typeof lead_list === 'number') {
      lead_list = [lead_list];
    }
    if (typeof record_type_list === 'number') {
      record_type_list = [record_type_list];
    }
    if (lead_list.length > 0) {
      lead_list?.map((obj) => {
        let statusIndex =
          tempList && tempList.length > 0 && tempList.findIndex((x) => x.id === obj);
        if (statusIndex !== -1) {
          let child_list = tempList[statusIndex]['child_leads'];
          status_list = [...status_list, ...child_list];
        }
        return null;
      });
    } else {
      tempList.map((obj) => {
        if (obj.child_leads) {
          status_list = [...status_list, ...obj.child_leads];
        }
        return null;
      });
    }
    let final_status_list = [];

    if (record_type_list.length > 0) {
      record_type_list.map((obj) => {
        let newRecordList =
          status_list &&
          status_list.length > 0 &&
          status_list.filter((x) => x.record_type_id.includes(obj));
        final_status_list = [...final_status_list, ...newRecordList];
        return null;
      });
    } else {
      final_status_list = status_list;
    }
    if (final_status_list && final_status_list.length > 0) {
      final_status_list = final_status_list.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      final_status_list.sort(function (a, b) {
        const nameA = a.status_name;
        const nameB = b.status_name;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }

    this.lead_status_list = final_status_list;
    return Promise.resolve();
  };
  // snapnote/lead-people-org/read
  getEditLeads = (payload) => {
    this.lead_list_data = null;
    return Axios.post(`snapnote/lead-people-org/read`, payload)
      .then(({ data }) => {
        if (data.data) {
          data.data.map((obj) => {
            obj.search_name = obj.id + '-' + obj.org_name + '-' + obj.person;
            return null;
          });
        }
        this.lead_list_data = data.data;
        return data;
      })
      .catch(({ response: { data } }) => {
        return data;
      })
      .finally(() => { });
  };

  searchLead = (payload) => {
    if (payload?.search_text?.length >= 1) {
      this.lead_list_data = null;
      this.fetching = true;
      return Axios.post(`snapnote/lead-people-org-search`, payload)
        .then(({ data }) => {
          // if (data.data) {
          //   data.data.map((obj) => {
          //     obj.concated_name = obj.id + '-' + obj.org_name + '-' + obj.person
          //     return null;
          //   })
          // }
          this.lead_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  getAdditionalFields = (report_type) => {
    this.additional_fields = [
      { id: 1, name: 'Remaining Deals' },
      { id: 2, name: 'Days Left' },
    ];
  };

  searchTicket = (payload) => {
    this.ticket_list_data = null;

    if (payload?.search_text?.length >= 3) {
      this.fetchingTicketSearch = true;
      return Axios.post(`snapnote/ticket-search`, payload)
        .then(({ data }) => {
          this.ticket_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingTicketSearch = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchLeadSOLDName = (payload) => {
    this.lead_search_dropdown_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetchingLead = true;
      return Axios.post(`snapnote/lead-search`, payload)
        .then(({ data }) => {
          this.lead_search_dropdown_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingLead = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchPersonContacts = (payload) => {
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetchingPeople = true;
      return Axios.post(`snapnote/contacts-people-search`, payload)
        .then(({ data }) => {
          this.contacts_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingPeople = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchOrgContacts = (payload) => {
    this.org_contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetchingOrg = true;
      return Axios.post(`snapnote/contacts-organisations-search`, payload)
        .then(({ data }) => {
          this.org_contacts_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingOrg = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  editFilter = (id, payload, userid) => {
    return Axios.post(`report/edit/is-global/${id}`, payload)
      .then(({ data }) => {
        if (this.my_records === 'all') {
          this.getList({}, 'all');
        } else {
          this.getList({}, userid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setGlobalStatusTempData = (data) => {
    this.globalStatusTempData = data;
  };

  //set Deal Status Update API
  fetchLockListGroupUsers = () => {
    return Axios.get(`deal-desk/user/user-group/dropdown`)
      .then(({ data }) => {
        this.dropdown_userGroupList = data.userGroupList;
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  saveGridColumnState = (id, payload = null) => {
    if (payload) {
      return Axios.post(`report/column-setting/save/${id}`, payload)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response: { data } }) => {
          var errors = [];
          Object.keys(data.errors).forEach((name) => {
            errors.push({ name, errors: data.errors[name] });
          });
          data.errors = errors;
          return Promise.reject(data);
        });
    }
  };

  handleResetGrid = () => {
    if (this.preview_report_grid) {
      if (this.reportColumnSettings) {
        let NewcolumnDef = this.preview_report_grid.columnApi.getAllColumns();
        let allColumnIds = [];
        NewcolumnDef.forEach(function (column) {
          allColumnIds.push(column.colId.replace('_1', ''));
        });
        this.preview_report_grid.api.setColumnDefs(allColumnIds);
      }
      this.setReportColumnSettings(null);
      this.preview_report_grid.columnApi.resetColumnState();
    }
  };

  handleResetPerformanceGrid = () => {
    if (this.agGridPerformanceReport) {
      if (this.reportColumnSettings) {
        let NewcolumnDef = this.agGridPerformanceReport.columnApi.getAllColumns();
        let allColumnIds = [];
        NewcolumnDef.forEach(function (column) {
          allColumnIds.push(column.colId.replace('_1', ''));
        });
        this.agGridPerformanceReport.api.setColumnDefs(allColumnIds);
      }
      this.setReportColumnSettings(null);
      this.agGridPerformanceReport.columnApi.resetColumnState();
    }
  };
  handleResetInvestmentGrid = () => {
    if (this.AuditGrid) {
      if (this.reportColumnSettings) {
        let NewcolumnDef = this.AuditGrid.columnApi.getAllColumns();
        let allColumnIds = [];
        NewcolumnDef.forEach(function (column) {
          allColumnIds.push(column.colId.replace('_1', ''));
        });
        this.AuditGrid.api.setColumnDefs(allColumnIds);
      }
      this.setReportColumnSettings(null);
      this.AuditGrid.columnApi.resetColumnState();
    }
  };
  setPreviewFilterForExport = (data) => {
    this.previewFilterForExport = data;
  };

  onPerformanceGridChanged = (params) => { };

  onInvestmentHourGridChanged = (params) => {
    //this.investment_hour_grid_settings
  };

  //--------------------START:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//
  setupInvestmentGroup = (params) => {
    this.agGridGroupInvestment = params;
  };
  setupInvestmentDrillDownDateWise = (params) => {
    this.agGridGroupInvestment = params;
    let defaultsort = [
      {
        colId: 'datetime',
        sort: 'desc',
      },
    ];
    this.agGridGroupInvestment.api.setSortModel(defaultsort);
  };
  setupTimespentTypeOthers = (params) => {
    this.agGRidTimespentTypeOthers = params;
    let defaultsort = [
      {
        colId: 'datetime',
        sort: 'desc',
      },
    ];
    this.agGRidTimespentTypeOthers.api.setSortModel(defaultsort);
  };
  getUserInvestmentHourDetail = (payload) => {
    this.drilldownLoader = true;
    return Axios.post(`report/investment-hours/data`, payload)
      .then(({ data }) => {
        this.drilldownLoader = false;
        this.investmentHoursDetailDataByuser = data?.data;
        return data && data?.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  //--------------------END:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//

  drilldownRedrawGrid = (params) => {
    params.api.redrawRows();
  };
  setReportColumnSettings = (data) => {
    this.reportColumnSettings = data;
  };
}

decorate(SavedReportStore, {
  list_data: observable,
  all_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  preview_report_grid: observable,
  agGridPerformanceReport: observable,
  agGridList: observable,
  fetching: observable,
  lead_list_data: observable,
  deleteValues: observable,
  initialValues: observable,
  dropdown_models: observable,
  dropdown_list: observable,
  main_organisation_roles: observable,
  main_people_roles: observable,
  main_organisation: observable,
  related_organisation: observable,
  related_organisation_role: observable,
  main_people: observable,
  related_people: observable,
  related_people_role: observable,
  my_records: observable,
  financial_year_list: observable,
  cteTypesList: observable,
  auditExportData: observable,
  auditExportFormatedData: observable,
  created_by_id: observable,
  ZeroRecord: observable,
  filter_org: observable,
  filter_related_org: observable,
  lead_status_list: observable,
  lead_type_list: observable,
  additional_fields: observable,
  performance_total_investment_hours: observable,
  preview_report_criteria: observable,
  recordTypeList: observable,
  classification_role_list: observable,
  product_list: observable,
  dropdown_reminder_type_list: observable,
  globalStatusTempData: observable,
  dropdown_userGroupList: observable,
  previewFilterForExport: observable,
  reportColumnSettings: observable,

  //--------------------START: USED IN REMINDER REPORTS - REMINDERFOR DROPDOWN-------//
  ticket_list_data: observable,
  contacts_list_data: observable,
  fetchingTicketSearch: observable,
  lead_search_dropdown_data: observable,
  fetchingLead: observable,
  fetchingPeople: observable,
  fetchingOrg: observable,
  org_contacts_list_data: observable,
  //--------------------END: USED IN REMINDER REPORTS - REMINDERFOR DROPDOWN-------//

  //--------------------START:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//
  investmentHoursDetailDataByuser: observable,
  drilldownLoader: observable,
  //--------------------END:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//

  previewReportValues: observable.shallow,
  payload_drilldown_generated_on: observable,
  reportGridLoader: observable,
  investment_hour_grid_settings: observable,
  searchLeadKey: observable,
  searchTicketKey: observable,

  getList: action,
  AddData: action,
  DeleteData: action,
  setupGrid: action,
  setupReportGrid: action,
  setupAuditGrid: action,
  setupPerformanceGrid: action,
  setPageSize: action,
  setPreviewReportValues: action,
  setPreviousReportValues: action,
  PreviewReport: action,
  PreviewData: action,
  exportData: action,
  exportReport: action,
  ReadReport: action,
  saveToMenu: action,
  getAllRecords: action,
  getMainRoles: action,
  PreviewDetails: action,
  getFinancialYearList: action,
  getDependentList: action,
  getRecordType: action,
  getClassificationList: action,
  getProductList: action,
  getCashTransactionType: action,
  exportAudit: action,
  onGridChanged: action,
  autoSizeColumns: action,
  getLeadTypeList: action,
  getStatusList: action,
  getAdditionalFields: action,
  searchLead: action,
  getEditLeads: action,
  //--------------------START:USED IN REMINDER REPORTS - REMINDERFOR DROPDOWN-------//
  searchTicket: action,
  searchLeadSOLDName: action,
  searchPersonContacts: action,
  searchOrgContacts: action,
  //--------------------END:USED IN REMINDER REPORTS - REMINDERFOR DROPDOWN-------//
  setGlobalStatusTempData: action,
  editFilter: action,
  exportInvestmentHourReport: action,
  fetchLockListGroupUsers: action,
  saveGridColumnState: action,
  setPreviewFilterForExport: action,
  onPerformanceGridChanged: action,
  onInvestmentHourGridChanged: action,
  handleResetGrid: action,
  handleResetPerformanceGrid: action,
  handleResetInvestmentGrid: action,
  setSearchLeadKey: action,
  setSearchTicketKey: action,

  //--------------------START:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//
  setupInvestmentGroup: action,
  //--------------------END:USED IN INVESTMENT HOUR REPORT DRILL DOWN-------------//
  setupInvestmentDrillDownDateWise: action,
  setinvestment_hour_grid_settings: action,
  setupTimespentTypeOthers: action,
  setReportColumnSettings: action,
});
