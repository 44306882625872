import axios from 'axios';
import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import moment from 'moment';
import { vsmNotify, vsmDealDesk } from '../../config/messages';

export default class DealDeskStore {
  deal_data = null;
  dropdown_users_list = null;
  leadstatus_list = null;
  colorlist = null;
  historyCategoryList = null;
  filternoteUserGroup = null;

  dealdeskActiveTab = '5';
  dealdeskActiveNoteTab = '4';

  dealDeskTypeArr = [0, 2, 3]; //0 : Ticket,  1 : Lead ,2 : People,  3 : Org

  msgBoxIndex = null;
  //msgBoxArray = null;
  msgBoxAPI = null;
  msgBoxAPIDealID = null;
  msgBoxAPINoteID = null;

  //activeNoteEditData ->>on Edit Note->>Used To display Data in >>Notes-Dated Tab
  activeNoteEditDated = null;
  activeNoteEditComment = null;
  activeNoteEditOutbound = null;

  currentTimeStamp = null; //Used For Force Rerender - Dated Notes Form
  leadFileFolderList = null;

  allkeydocumentlist = null; // Deal Library Key document list all

  // Used For Structure Edit/delete
  activeStructureData = null;
  editActiveStructureData = null;
  editActiveScreenData = null;

  // Used for Scrutinise 
  editActiveScrutiniseData = null;

  // Used For Schedule Edit/delete
  activeScheduleData = null;
  editActiveScheduleData = null;

  copiedActiveScheduleData = null;

  stopInvestmentHourCounter = false; // Used To Count Investment Hours For CONNECTIONS and TagTicketList.
  structureSchedulePostData = null; // Used To Post Structure Data TO Note Editor

  leftSectionActiveTab = '3'; //--------DEFAULT TAB SELECTION FOR LEFT SECTION--------//

  isDataChangedSchedule = false;

  statusPayloadForShortfall = null;
  tagSubject = null;//to send tag subject upon tag selection

  disableFooterIcons = false;

  rejectionSummaryData = null;

  setRejectionSummaryData = (data) => {
    this.rejectionSummaryData = data;
  }

  setIsDataChangedSchedule = (data) => {
    this.isDataChangedSchedule = data;
  };

  setLeftSectionActiveTab = (data) => {
    this.leftSectionActiveTab = data;
  };

  setDealdeskActiveTab = (activeTab) => {
    this.dealdeskActiveTab = activeTab;
  };
  setDealdeskActiveNoteTab = (activeTab) => {
    this.dealdeskActiveNoteTab = activeTab;
  };

  setStatusPayloadForShortfall = (data) => {
    this.statusPayloadForShortfall = data;
  }

  setMsgBoxIndex = (index, type, dealid, noteid) => {
    this.msgBoxIndex = index;
    this.msgBoxAPI = type;
    this.msgBoxAPIDealID = dealid;
    this.msgBoxAPINoteID = noteid;
    return this.msgBoxIndex;
  };
  changeColor = (msgBoxArray, color) => {
    if (this.msgBoxIndex || this.msgBoxIndex === 0) {
      if (this.msgBoxAPI === 'sequence') {
      } else {
      }
      this.updateDealNoteColor(this.msgBoxAPIDealID, {
        current_bg_color: color,
        lead_note_id: this.msgBoxAPINoteID,
      });
      msgBoxArray[this.msgBoxIndex].current_bg_color = color;
    }

    return msgBoxArray;
  };

  /** Modify Tag Subject Function*/
  modifyTagSubject = (data) => {
    let tagSubjectTemp = []
    data.forEach((x) => {
      x && tagSubjectTemp.push(x);
    })
    this.tagSubject = tagSubjectTemp.join(" | ");
    return this.tagSubject;
  }

  addTag = (msgBoxArray, userid, username, grouplist = 0) => {
    /**Modify Tag Subject according to dealDesk type */
    /**Ticket */
    if (this.deal_data?.lead_information?.is_ticket === 0) {
      let ticketData = this.deal_data?.lead_information?.ticket_data;
      // let tTagSubject = []
      // ticketData.ticket_subject && tTagSubject.push(ticketData.ticket_subject);
      // ticketData.ticket_number && tTagSubject.push(ticketData.ticket_number);
      // this.tagSubject = `${this.deal_data?.lead_information?.ticket_data?.ticket_subject} | ${this.deal_data?.lead_information?.ticket_data?.ticket_number}`
      this.modifyTagSubject([ticketData.ticket_subject, ticketData.ticket_number])
    }
    /**Leads */
    if (this.deal_data?.lead_information?.is_ticket === 1) {
      let leadData = this.deal_data?.lead_information;
      let soldValue = this.deal_data?.deal_sum?.deal_sum.replace(/SOLD Value : /g, "")
      this.modifyTagSubject([leadData.opportunity_name, leadData.sold_organisation?.organisation_name, leadData.product_name, soldValue, leadData.id])
      // this.tagSubject = `${this.deal_data?.lead_information?.opportunity_name} | ${this.deal_data?.lead_information?.sold_organisation?.organisation_name} | ${this.deal_data?.lead_information?.product_name} |  ${this.deal_data?.deal_sum?.deal_sum.replace("SOLD Value :", "")} | ${this.deal_data?.lead_information?.id}`
    }
    /**People */
    if (this.deal_data?.lead_information?.is_ticket === 2) {
      let peopleData = this.deal_data?.lead_information?.people
      this.modifyTagSubject([peopleData.full_name, peopleData.id])
      // this.tagSubject = `${this.deal_data?.lead_information?.people?.full_name} | ${this.deal_data?.lead_information?.people?.id}`
    }
    /**Org */
    if (this.deal_data?.lead_information?.is_ticket === 3) {
      let orgData = this.deal_data?.lead_information?.sold_organisation
      this.modifyTagSubject([orgData.organisation_name, orgData.id])
      // this.tagSubject = `${this.deal_data?.lead_information?.sold_organisation?.organisation_name}${this.deal_data?.lead_information?.sold_organisation?.organisation_name && " | "}${this.deal_data?.lead_information?.organisation_name}`
    }
    if (this.msgBoxIndex || this.msgBoxIndex === 0) {
      this.updateNoteTagUser(this.msgBoxAPIDealID, {
        group_id: grouplist,
        user_id: userid,
        leads_notes_id: this.msgBoxAPINoteID,
        leads_transactions_id: msgBoxArray[this.msgBoxIndex].leads_transactions_id,
        tag_subject: this.tagSubject,
      });
    }

    return msgBoxArray;
  };

  setActiveNoteEditDated = (msgBoxArray) => {
    if (this.msgBoxIndex || this.msgBoxIndex === 0) {
      this.activeNoteEditDated = msgBoxArray[this.msgBoxIndex];
      this.currentTimeStamp = moment();
    }
  };
  resetActiveNoteEditDated = () => {
    this.activeNoteEditDated = null;
    this.currentTimeStamp = moment();
  };

  resetActiveScheduleNoteEditDated = () => {
    this.activeScheduleData = null;
    this.editActiveScheduleData = null;
    this.copiedActiveScheduleData = null;
  };

  resetActiveStructureNoteEditDated = () => {
    this.activeStructureData = null;
    this.editActiveStructureData = null;
    this.editActiveScrutiniseData = null;
    this.editActiveScreenData = null;
  };

  //Update Note Tag User ID
  updateNoteTagUser = (id, formdata) => {
    return Axios.post(`deal-desk/update-note-tag/${id}`, formdata)
      .then(({ data }) => {
        // vsmNotify.success({
        //   message: vsmDealDesk.Notes.successTagUpdate,
        // });
        return data;
      })
      .catch(({ response }) => {
        vsmNotify.error({
          message: vsmDealDesk.Notes.errorTagUpdate,
        });
        return Promise.reject(response);
      });
  };

  //set Deal Status Update API
  updateDealNoteColor = (id, formdata) => {
    return Axios.post(`deal-desk/update-note-color/${id}`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getDealDetail = (id) => {
    // alert(id && id);
    return Axios.get(`deal-desk/header/${id}`)
      .then(({ data }) => {
        this.deal_data = data?.data && data?.data;
        return data;
      })
      .catch((data) => {
        this.deal_data = null;
        return Promise.reject(data);
      });
  };

  getLeadStatusList = (type = {}) => {
    return Axios.post(`leads-status/status/list`, { lead_record_type: type }).then(
      ({ data }) => {
        this.leadstatus_list = data.data;
        return data;
      }
    );
  };

  //set Deal Status Update API
  updateLeadStatus = (id, formdata) => {
    return Axios.post(`leads-status/update-status/${id}`, formdata)
      .then(({ data }) => {
        this.getDealDetail(id);
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_users_list = data.data;

      return data;
    });
  };
  updateLeadOwner = (id, payload) => {
    return Axios.post(`deal-desk/update-owner/${id}`, payload)
      .then(({ data }) => {
        this.getDealDetail(id);
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  updateLeadHolder = (id, payload) => {
    return Axios.post(`deal-desk/update-holder/${id}`, payload)
      .then(({ data }) => {
        this.getDealDetail(id);
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  updateLeadDeadLine = (id, payload) => {
    return Axios.post(`deal-desk/update-deadline-date/${id}`, payload)
      .then(({ data }) => {
        this.getDealDetail(id);
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  changeNextPrevDeal = (append_lead_url, payload) => {
    return Axios.post(`deal-desk/next-prev-lead${append_lead_url}`, payload)
      .then(({ data }) => {
        return data && data.new_lead_id;
      })
      .catch((data) => {
        this.deal_data = null;
        return Promise.reject(data);
      });
  };
  fetchGlobalCurrencyList = (payload) => {
    return Axios.get(`globalcurreny/list`, payload)
      .then(({ data }) => {
        return data && data.data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };

  // Used to Remove Notes LeftComponents
  removeDealNote = () => {
    if (this.msgBoxIndex || this.msgBoxIndex === 0) {
      return Axios.post(
        `deal-desk/delete-note/${this.msgBoxAPIDealID}/${this.msgBoxAPINoteID}`
      )
        .then(({ data }) => {
          return data;
        })
        .catch((data) => {
          return Promise.reject(data);
        });
    }
    return true;
  };

  //reset Tab Index on switch between Notes Tabs
  resetNoteTabIndex = () => {
    this.msgBoxIndex = null;
    this.msgBoxAPI = null;
    this.msgBoxAPIDealID = null;
    this.msgBoxAPINoteID = null;
  };

  fetchColorList = () => {
    return Axios.post(`notes-category`)
      .then(({ data }) => {
        this.colorlist = [
          ...data.data,
          {
            color_name: 'Reset Note',
            color_code: { hex: '' },
          },
        ];
        if (data.data) {
          data.data.map((obj) => {
            obj.hex_code = obj.color_code.hex;
            return null;
          })
        }
        this.historyCategoryList = data.data;
        return data && data.data;
      })
      .catch((data) => {
        this.colorlist = null;
        return Promise.reject(data);
      });
  };
  resetDealData = () => {
    this.deal_data = null;

    /*Start: Used For Dealdesk-edit note data clear and left note selection(index) clear */
    this.activeNoteEditDated = null;
    this.msgBoxIndex = null;
    this.dealdeskActiveTab = '5'; // Default Right section NOTES Tab Selection
    this.dealdeskActiveNoteTab = '4'; // Default Notes section OUTBOUND Tab Selection
    /*Edit: Used For Dealdesk-edit note data clear and left note selection(index) clear */

    /*START: TO RESET STRUCTURE EDIT DATA */
    this.activeStructureData = null;
    this.editActiveStructureData = null;
    this.editActiveScrutiniseData = null;
    this.editActiveScreenData = null;
    /*END: TO RESET STRUCTURE EDIT DATA */

    /*START: TO RESET SCHEDULE EDIT DATA */
    this.activeScheduleData = null;
    this.editActiveScheduleData = null;
    /*END: TO RESET SCHEDULE EDIT DATA */

    this.copiedActiveScheduleData = null;
    //this.leftSectionActiveTab = '3';
  };

  getUserGroupFilter = (user) => {
    let ApiURL = null; //`deal-desk/user-group-list`;
    if (this.deal_data && this.deal_data?.lead_information?.id) {
      ApiURL = `deal-desk/user-group-list/${this.deal_data?.lead_information?.id}`;
    } else {
      return null;
    }
    //this.deal_data
    return Axios.get(ApiURL)
      .then(({ data }) => {
        let usergrouplist = [];
        if (data && data.userGroupList) {
          usergrouplist = [
            // {
            //   id: 'u' + user.id,
            //   group_name: user?.first_name + ' ' + user?.last_name,
            // },
            ...data.userGroupList,
            {
              id: 0,
              group_name: 'All',
            },
          ];
        }

        this.filternoteUserGroup = usergrouplist;
        return usergrouplist;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  fetchFileFolderList = (lead_id) => {
    return Axios.get(`deal-desk/files-folders/${lead_id}`)
      .then(({ data }) => {
        if (data && data.data && data.data.length > 0) {
          this.leadFileFolderList = data && data.data;
        } else {
          this.leadFileFolderList = null;
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  toogleKeyDocument = (docid, payload) => {
    return Axios.post(`deal-desk/files-folders-key-document/${docid}`, payload)
      .then(({ data }) => {
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getFormFields = (payload) => {
    return axios.post('transactionfield/search', payload).then((data) => {
      return data;
    });
  };

  setActiveStructureData = (data) => {
    this.activeStructureData = data;
  };
  setEditActiveStructureData = (data) => {
    this.editActiveStructureData = data;
  };

  setEditActiveScrutiniseData = (data) => {
    this.editActiveScrutiniseData = data;
  };

  setEditActiveScreenData = (data) => {
    this.editActiveScreenData = data;
  };

  setActiveScheduleData = (data) => {
    this.activeScheduleData = data;
  };
  setEditActiveScheduleData = (data) => {
    this.editActiveScheduleData = data;
  };
  setCopiedActiveScheduleData = (data) => {
    this.copiedActiveScheduleData = data;
  };

  //used in dealdesk right section list file download
  downloadFileS3 = (data) => {
    //return Axios.get(`aws/generate/file/url/${data.org_id}/${data.leadid}/${data.file}`);
    return Axios.get(`aws/generate/download/url/${data.fileID}`)
      .then(({ data }) => {
        return data && data.download_url && data.download_url;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //Used For Deal Library - Key Documents Listing
  allKeydocFetch = () => {
    return Axios.get(`deal-desk/files-folders-key-document-list`)
      .then(({ data }) => {
        if (data && data.data && data.data.length > 0) {
          this.allkeydocumentlist = data && data.data;
        } else {
          this.allkeydocumentlist = null;
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  convertNoteToTicketDesk = (payload) => {
    return Axios.post(`deal-desk/create-ticket-from-note`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setStopInvestmentHourCounter = (data) => {
    this.stopInvestmentHourCounter = data;
  };

  setStructureSchedulePostData = (data) => {
    if (this.structureSchedulePostData) {
      this.structureSchedulePostData = null;
    } else {
      this.structureSchedulePostData = data;
    }
  };

  calculateHeightOnEdit = (editActiveScheduleData, form) => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };
  setDealData = (data) => {
    this.deal_data = data;
  };

  removeDraftContentSSS = (data) => {
    return Axios.get(`deal-desk/leads-sss/clear-draft-desc/${data.id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  }

  removeDraftNoteContent = (data) => {
    return Axios.get(`deal-desk/clear-note-draft-desc/${data.id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  }

  setDisableFooterIcons = (data) => {
    this.disableFooterIcons = data
    return this.disableFooterIcons;
  }


}

decorate(DealDeskStore, {
  dropdown_users_list: observable,
  msgBoxIndex: observable,
  msgBoxAPI: observable,
  msgBoxAPIDealID: observable,
  msgBoxAPINoteID: observable,
  leadstatus_list: observable,
  //msgBoxArray: observable,
  deal_data: observable,
  colorlist: observable,
  historyCategoryList: observable,
  dealdeskActiveTab: observable,
  dealdeskActiveNoteTab: observable,
  activeNoteEditDated: observable.shallow,
  activeNoteEditComment: observable,
  activeNoteEditOutbound: observable,
  currentTimeStamp: observable,
  filternoteUserGroup: observable,
  leadFileFolderList: observable,
  activeStructureData: observable.shallow,
  editActiveStructureData: observable,
  editActiveScrutiniseData: observable,
  editActiveScreenData: observable,

  activeScheduleData: observable,
  editActiveScheduleData: observable.shallow,
  copiedActiveScheduleData: observable.shallow,
  allkeydocumentlist: observable,
  stopInvestmentHourCounter: observable,
  structureSchedulePostData: observable,
  leftSectionActiveTab: observable,
  isDataChangedSchedule: observable,
  statusPayloadForShortfall: observable,
  disableFooterIcons: observable,
  rejectionSummaryData: observable,

  getDealDetail: action,
  setMsgBoxIndex: action,
  changeColor: action,
  addTag: action,
  updateLeadStatus: action,
  getUsersList: action,
  updateLeadOwner: action,
  updateLeadHolder: action,
  updateLeadDeadLine: action,
  changeNextPrevDeal: action,
  fetchGlobalCurrencyList: action,
  updateNoteTagUser: action,
  removeDealNote: action,
  resetNoteTabIndex: action,
  fetchColorList: action,
  setNoteEdit: action,
  setDealdeskActiveTab: action,
  setDealdeskActiveNoteTab: action,
  setActiveNoteEditDated: action,
  resetDealData: action,
  resetActiveNoteEditDated: action,
  resetActiveScheduleNoteEditDated: action,
  resetActiveStructureNoteEditDated: action,
  getUserGroupFilter: action,
  fetchFileFolderList: action,
  toogleKeyDocument: action,
  getFormFields: action,
  setActiveStructureData: action,
  setEditActiveStructureData: action,
  setEditActiveScreenData: action,
  setActiveScheduleData: action,
  setEditActiveScheduleData: action,
  setCopiedActiveScheduleData: action,
  downloadFileS3: action,
  allKeydocFetch: action,
  convertNoteToTicketDesk: action,
  setStopInvestmentHourCounter: action,
  setStructureSchedulePostData: action,
  calculateHeightOnEdit: action,
  setLeftSectionActiveTab: action,
  setIsDataChangedSchedule: action,
  setDealData: action,
  removeDraftContentSSS: action,
  removeDraftNoteContent: action,

  setEditActiveScrutiniseData: action,
  setStatusPayloadForShortfall: action,
  setDisableFooterIcons: action,
  setRejectionSummaryData: action,
});
