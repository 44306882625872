import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal } from 'antd';
import SpanNoteComponent from './component/SpanNoteComponent';
import ExpandSpanNoteComponent from './component/ExpandSpanNoteComponent';
import useStore from '../../store';
import { useTranslation } from 'react-i18next';
const SnapNoteModule = observer((props) => {
  const { AUTH } = useStore();
  //const [totalList, setTotalList] = useState();
  const [openModal, setOpenModal] = useState(false);
  const expandSnapNote = (data) => {
    setOpenModal(true);
  };
  const {t}  = useTranslation()
  // const snapNoteListTotal = (data) => {
  //   setTotalList(data.total);
  // };
  const SnapNoteTitle = (
    <div className='headerTitle'>
      <img src={AUTH.GetThemedImage('Snap_Note')} alt={`${t('SnapNoteModule.Icon')}`} /> {t('Common_Data.Snap')} {t('Common_Data.Note')}
      {/* <span className='TitleValue'>{totalList}</span> */}
    </div>
  );
  return (
    AUTH.app_color_theme && (
      <>
        <SpanNoteComponent expandSnapNote={expandSnapNote} />
        <Modal
          centered
          title={SnapNoteTitle}
          visible={openModal}
          footer={false}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          width={'71vw'}
          className='SnapNoteModal'
          closeIcon={<img src={AUTH.GetThemedImage('CloseExpand')} alt={`${t('SnapNoteModule.Close')}`}/>}
        >
          <ExpandSpanNoteComponent />
          {/* snapNoteListTotal={snapNoteListTotal} */}
        </Modal>
      </>
    )
  );
});

export default SnapNoteModule;
