import React from 'react';
import { observer } from 'mobx-react';

import useStore from '../../../../store';
import NoteEditor from './global/NoteEditor';

const TabComponent = observer(() => {
  const {
    DEALDESKSTORE: {
      dealdeskActiveNoteTab,
      activeNoteEditDated,
    },
    DEALDESKNOTESEQUENCE: { autoSavedTabID },
  } = useStore();

  return (
    <>
      <NoteEditor
        isNoteFlagExist={
          activeNoteEditDated
            ? dealdeskActiveNoteTab
            : autoSavedTabID
              ? autoSavedTabID
              : 0
        }
      />
    </>
  );
});
export default TabComponent;
