import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Popover, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import useStore from '../../../store';
import { vsmCommon, vsmNotify, vsmQuickNoteUpdate } from '../../../config/messages';
import LocalGridConfig from '../../../config/LocalGridConfig';
import PhoneNumberRender from '../../LeadListing/elements/PhoneNumberRender';
import EmailCellRender from '../../LeadListing/elements/EmailCellRender';
// import DateTimeConfirmElements from '../elements/DateTimeConfirmElements';
// import HourglassConfirmElements from '../elements/HourglassConfirmElements';
import ReassignConfirmElements from '../../LeadListing/elements/ReassignConfirmElements';
// import StatusConfirmElements from '../elements/StatusConfirmElements';
// import AuditLogElements from '../elements/AuditLogElements';
import NoteRenderer from '../../Promotion/elements/NoteRenderer';
import moment from 'moment';
import { CurrencyFormat, DateComparator } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
import { GridLoader } from '../../../utils/GridLoader';
// import DatePickerCostume from '../../../component/DatePickerCostume';

const DealSearchListing = observer((props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const {
    AUTH,
    LEADDEALS,
    LEADSSTORE,
    SEARCHSTORE,
    SEARCHSTORE:{setResultCount, searchCount},
    LEADSSTORE: {
      // getLeadStatusList,
      getReassignUserList },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    PROMOTIONSTORE,
    // REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();
  const [quickNoteList, setQuickNoteList] = useState();

  const {
    openRestoreModal,
    WasteManagement,
    SearchPage,
    AllView,
    // onCellKeyDown,
    showCheckbox = false,
  } = props;

  useEffect(() => {
    if (WasteManagement) {
      LEADSSTORE.waste = true;
      LEADDEALS.search_payload = {};
    } else if (SearchPage) {
      LEADSSTORE.waste = false;
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.deal_count;
    } else {
      LEADSSTORE.waste = false;
      LEADDEALS.search_payload = {};
    }
  }, [LEADSSTORE, LEADDEALS, SEARCHSTORE, SearchPage, WasteManagement, LEADDEALS.total]);

  useEffect(() => {
    setResultCount(searchCount?.deal_count);
    LEADDEALS.getListSearch(LEADDEALS.search_payload)
  }, [LEADDEALS, setResultCount,searchCount?.deal_count ])

  
  useEffect(() => {
    if (!quickNoteList) {
      PROMOTIONSTORE.getQuickNoteList().then((data) => {
        setQuickNoteList(data.data.quick_notes);
      });
    }
  }, [PROMOTIONSTORE, quickNoteList]);

  const handleSubmitQN = (id, data) => {
    let formdata = {
      notes_flag: 1,
      notes_description: data,
    };
    PROMOTIONSTORE.updateQuickNote(id, formdata).then(() => {
      vsmNotify.success({
        message: vsmQuickNoteUpdate.successQuickNoteUpdate,
      });
    });
  };

  const ActionRenderer = (params) => {
    // const [count, setCount] = useState(0);
    // const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
    // const [openHourglassModal, setOpenHourglassModal] = useState(false);
    const [openReassignModal, setOpenReassignModal] = useState(false);
    // const [openleadStatusOptins, setOpenleadStatusOptins] = useState(false);
    // const [leadStatus, setLeadStatus] = useState(null);
    // const [statusUpdateData, setStatusUpdateData] = useState(null);
    const [reAssignUser, setReAssignUser] = useState(null);
    const [reAssingTempData, setReAssingTempData] = useState(null);
    // const [openAuditLogModal, setopenAuditLogModal] = useState(false);
    // const [auditLeadID, setAuditLeadID] = useState(false);
    // const [date, setDate] = useState();
    // const [reminderConfirm, setReminderConfirm] = useState();
    // const [hourglassConfirm, setHourglassConfirm] = useState();
    // const [extrafooter, setExtrafooter] = useState(null);
    // const [loadingLeadStatus, setLoadingLeadStatus] = useState(true);
    // const [statusModalVisible, setStatusModalVisible] = useState(false);

    // const AuditLogModal = (leadid) => {
    //   setAuditLeadID(leadid);
    //   setopenAuditLogModal(true);
    // };
    // const closeAuditLogModal = () => setopenAuditLogModal(false);

    // const openConfirmDateTimeModal = (dateformat, data) => {
    //   if (dateformat) {
    //     setDate(dateformat);
    //     setReminderConfirm(data);
    //     setOpenDateTimeModal(true);
    //   }
    // };
    // const closeDateTimeModal = () => {
    //   setDate(null);
    //   setReminderConfirm(null);
    //   setOpenDateTimeModal(false);
    // };

    // const openConfirmHourglassModal = (data) => {
    //   setOpenHourglassModal(true);
    //   // setCount(count + 1);
    //   setHourglassConfirm(data);
    // };
    // const closeHourglassModal = () => setOpenHourglassModal(false);

    const openConfirmReassignModal = (userid, username, leadid) => {
      let tempReAssign = {
        userid: userid,
        username: username,
        leadid: leadid,
      };
      setReAssingTempData(tempReAssign);
      setOpenReassignModal(true);
    };
    const closeReassignModal = () => {
      // setStatusUpdateData(null);
      setOpenReassignModal(false);
    };

    // const openleadStatusModal = (new_id, oldstatus, newstatus, id) => {
    //   let tempDataStatusUpdate = {
    //     new_id: new_id,
    //     oldstatus: oldstatus,
    //     newstatus: newstatus,
    //     id: id,
    //   };
    //   setStatusUpdateData(tempDataStatusUpdate);

    //   setOpenleadStatusOptins(true);
    // };
    // const closeleadStatusOptins = () => {
    //   setStatusUpdateData(null);
    //   setOpenleadStatusOptins(false);
    // };

    // const disabledDate = (current) => {
    //   // Can not select days before today and today
    //   return current && current <= moment().subtract(1, 'days').endOf('day');
    // };

    // const disabledHours = (e) => {
    //   let hours = [19, 20, 21, 22, 23];
    //   for (let i = 0; i < 7; i++) {
    //     hours.push(i);
    //   }
    //   return hours;
    // };

    // let liPercentage =
    //   leadStatus && leadStatus.length > 0 ? ((100 / leadStatus.length) - 1) + '%' : '24%';

    // let ulWidth =
    //   leadStatus && (leadStatus.length === 1 || leadStatus.length === 2)
    //     ? 200 * leadStatus.length + 'px'
    //     : '655px';

    // const LeadStatusOptins = (
    //   <div className='leadStatusOptins'>
    //     {loadingLeadStatus ? (
    //       <ul style={{ width: '655px', height: '100px' }}>
    //         <Spin size='large' spinning={loadingLeadStatus} tip={`${t('Common_Data.Load')}`} />
    //       </ul>
    //     ) : (
    //       <ul
    //         style={{
    //           width: ulWidth,
    //         }}
    //       >
    //         {leadStatus &&
    //           leadStatus.map(function (item) {
    //             return (
    //               <li key={item.id} style={{ width: liPercentage }}>
    //                 <span className='title'>{item.status_name}</span>
    //                 <ul>
    //                   {item.child_leads.map(
    //                     (itemsubleads) =>
    //                       itemsubleads.id !== LEADSSTORE.ignore_unusedlead_id && (
    //                         <li
    //                           key={itemsubleads.id}
    //                           className={
    //                             itemsubleads.id === params.data?.lead_status_id?.id
    //                               ? 'active'
    //                               : ''
    //                           }
    //                           onClick={() => {
    //                             if (params.data?.lead_status_id?.id !== itemsubleads.id) {
    //                               openleadStatusModal(
    //                                 itemsubleads.id,
    //                                 params.data.lead_status_id.status_name,
    //                                 itemsubleads.status_name,
    //                                 params.data.id
    //                               )
    //                             }
    //                           }
    //                           }
    //                         >
    //                           {itemsubleads.status_name}
    //                         </li>
    //                       )
    //                   )}
    //                 </ul>
    //               </li>
    //             );
    //           })}
    //       </ul>
    //     )}
    //   </div>
    // );

    const userList = (
      <div className='ticketsPopoverIcons username'>
        <ul>
          {reAssignUser &&
            reAssignUser.map(function (item) {
              return (
                <li
                  key={item.id}
                  onClick={() =>
                    openConfirmReassignModal(item.id, item.name, params.data.id)
                  }
                >
                  {item.name}
                </li>
              );
            })}
        </ul>
      </div>
    );

    // const content = (
    //   <div className='ticketsPopoverIcons'>
    //     <Space size={15}>
    //       {AUTH.checkPermission(13, 'change-status') && (
    //         <Popover
    //           content={LeadStatusOptins}
    //           placement='bottom'
    //           trigger='hover'
    //           overlayClassName='profileMenu leadStatusFixHeight'
    //           visible={statusModalVisible}
    //           onVisibleChange={() => {
    //             if (!openleadStatusOptins) {
    //               setStatusModalVisible(!statusModalVisible);
    //             }
    //           }}
    //         >
    //           <img
    //             className='menuicon'
    //             alt=''
    //             title={`${t('LeadListing.Status_Change')}`}
    //             src={AUTH.GetThemedImage('StatusChange')}
    //             onMouseEnter={() => {
    //               if (!leadStatus) {
    //                 getLeadStatusList(params.data?.lead_record_type)
    //                   .then((data) => {
    //                     setLeadStatus(data.data);
    //                   })
    //                   .finally(() => setLoadingLeadStatus(false));
    //               }
    //             }}
    //           />
    //         </Popover>
    //       )}

    //       {AUTH.checkPermission(13, 'reset-reminder-date-time') && (
    //         //params?.data?.reset_count !== null &&
    //         <span
    //           className='Calendar cursor'
    //           onMouseEnter={() => {
    //             if (params?.data?.reset_count !== null) {
    //               getReminderDatepickerExtraFooter(
    //                 params.data,
    //                 AUTH.global_fulldate_format,
    //                 {
    //                   type: 'leads',
    //                 }
    //               ).then((data) => {
    //                 setExtrafooter(data);
    //               });
    //             }
    //           }}
    //         >
    //           <Tooltip
    //             title={
    //               params?.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`
    //             }
    //           >
    //             <DatePickerCostume
    //               name='Calendar'
    //               title={`${t('LeadListing.Calendar')}`}
    //               className='cursor'
    //               showTime
    //               disabledDate={true}
    //               // disabledHours={() => disabledHours()}
    //               // minuteStep={15}
    //               // hideDisabledOptions
    //               showNow={false}
    //               onOk={(e) => openConfirmDateTimeModal(e, params.data)}
    //               renderExtraFooter={() => extrafooter}
    //             />
    //           </Tooltip>

    //           <img
    //             className='menuicon'
    //             title={`${t('LeadListing.Calendar')}`}
    //             alt=''
    //             src={AUTH.GetThemedImage('Calendar')}
    //           />
    //         </span>
    //       )}
    //       {AUTH.checkPermission(13, 'reset-reminder-time') && (
    //         //params?.data?.reset_count !== null &&
    //         <>
    //           <img
    //             className='menuicon'
    //             title={`${t('LeadListing.Hour_Glass')}`}
    //             alt=''
    //             src={AUTH.GetThemedImage('Reminders_icn')}
    //             onClick={() => openConfirmHourglassModal(params.data)}
    //           />
    //           <span>{params?.data?.reset_count ? params?.data?.reset_count : 0}</span>
    //         </>
    //       )}
    //       {AUTH.checkPermission(31, 'list') && (
    //         <>
    //           <img
    //             className='menuicon'
    //             alt=''
    //             title={`${t('Common_Data.Audit_Logs')}`}
    //             src={AUTH.GetThemedImage('Audit_Logs')}
    //             onClick={() => {
    //               AuditLogModal(params.data.id);
    //             }}
    //           />
    //         </>
    //       )}
    //     </Space>
    //   </div>
    // );

    // const { quickNoteList, handleSubmitQN } =
    //   params.agGridReact.props.frameworkComponents;

    // const contentP = (
    //   <div className='QuicknoteList'>
    //     <ul>
    //       {quickNoteList ? (
    //         quickNoteList.map(function (item, index) {
    //           return (
    //             <li
    //               key={index}
    //               title={item.quick_note_text}
    //               onClick={() => {
    //                 handleSubmitQN(params?.data?.id, item.quick_note_text);
    //               }}
    //             >
    //               {item.quick_note_text_value}
    //             </li>
    //           );
    //         })
    //       ) : (
    //         <Spin />
    //       )}
    //     </ul>
    //   </div>
    // );

    return (
      <div className='action-column'>
        {
          <>
            {/* History */}
            {AUTH.checkPermission(13, 'change-view') && (
              <Button type='text' title={`${t('Block_Edit_Assign.Deal_Desk')}`}>
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Deal_icn')}
                  onClick={() => {
                    history.push(`/dealdesk/${params.data.id}`);
                  }}
                />
              </Button>
            )}
            {/* Edit */}
            {AUTH.checkPermission(13, 'edit') && (
              <Button
                type='text'
                title={`${t('Common_Data.Edit')}`}
                onClick={() => props.openEditDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
              </Button>
            )}

            {/* {AUTH.checkPermission(13, 'view-details') && (
              <Button
                type='text'
                title={`${t('Common_Data.View')}`}
                onClick={() => props.openViewDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
              </Button>
            )} */}
            {/* {AUTH.checkPermission(13, 'clone') && (
              <Button
                type='text'
                title={`${t('LeadListing.Clone')}`}
                onClick={() => props.openCloneDrawer(params.data)}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Clone_Record')}
                />
              </Button>
            )} */}
            {/* {AUTH.checkPermission(2, 'quick-message') && (
              <Popover
                content={contentP}
                placement='bottom'
                arrowPointAtCenter
                trigger='hover'
                overlayClassName='profileMenu'
              >
                <Button type='text' title={`${t('LeadListing.Quick_Note')}`}>
                  <img
                    className='menuicon'
                    alt=''
                    src={AUTH.GetThemedImage('Quicknote')}
                  />
                </Button>
              </Popover>
            )} */}

            {/* Note */}
            <NoteRenderer data={params.data} />
            {/* ReAssign */}
            {AUTH.checkPermission(13, 'reassign') && (
              <Popover
                content={userList}
                placement='bottomRight'
                trigger='hover'
                overlayClassName='profileMenu'
              >
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Reassign')}
                  title={`${t('LeadListing.Reassign')}`}
                  onMouseEnter={() => {
                    if (!reAssignUser) {
                      getReassignUserList().then((data) => {
                        setReAssignUser(data.data);
                      });
                    }
                  }}
                />
              </Popover>
            )}
            {AUTH.checkPermission(13, 'reassign') && (
              <ReassignConfirmElements
                visible={openReassignModal}
                close={closeReassignModal}
                reAssingTempData={reAssingTempData}
              />
            )}
            {/* Delete */}
            {AUTH.checkPermission(13, 'delete') && (
              <Button
                type='text'
                title={`${t('Common_Data.Delete')}`}
                onClick={() => props.openDeleteDrawer(params.data)}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            )}
            {/* {(AUTH.checkPermission(
              13,
              'change-status' ||
              'reassign' ||
              'reset-reminder-date-time' ||
              'reset-reminder-time'
            ) ||
              AUTH.checkPermission(31, 'list')) && (
                <Popover
                  content={content}
                  placement='bottomRight'
                  trigger='hover'
                  overlayClassName='profileMenu'
                  arrowPointAtCenter
                >
                  <Button type='text'>
                    <img
                      className='menuicon'
                      alt=''
                      src={AUTH.GetThemedImage('DropdownBox')}
                    />
                  </Button>
                </Popover>
              )}

            {AUTH.checkPermission(31, 'list') && (
              <AuditLogElements
                visible={openAuditLogModal}
                close={closeAuditLogModal}
                auditLeadID={auditLeadID}
              />
            )}
            {AUTH.checkPermission(13, 'reset-reminder-date-time') && (
              <DateTimeConfirmElements
                visible={openDateTimeModal}
                close={closeDateTimeModal}
                date={date}
                reminderConfirm={reminderConfirm}
              />
            )}
            {AUTH.checkPermission(13, 'reset-reminder-time') && (
              <HourglassConfirmElements
                visible={openHourglassModal}
                close={closeHourglassModal}
                hourglassConfirm={hourglassConfirm}
                resetTime={AUTH.user?.dm_settings?.[0].screen_deals_hourglass}
              />
            )}

            {AUTH.checkPermission(13, 'change-status') && (
              <StatusConfirmElements
                visible={openleadStatusOptins}
                close={closeleadStatusOptins}
                statusUpdateData={statusUpdateData}
                setStatusModalVisible={setStatusModalVisible}
                SearchPage={SearchPage}
              />
            )} */}
          </>
        }
      </div>
    );
  };

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        checkboxSelection: showCheckbox,
        valueGetter: function (params) {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        tooltipValueGetter: (params) => {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,
        field: 'updated_at',
        cellRendererFramework: (params) => {
          return params?.data?.updated_at && (
            <Tooltip title={moment(params?.data?.updated_at).format(AUTH.global_fulldate_format)}>
              {moment(params?.data?.updated_at).format(AUTH.global_fulldate_format)}
            </Tooltip>
          )
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'reset'],
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'lead_holder_name.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            LEADDEALS.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        field: 'people.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            LEADDEALS.filter_people_list &&
            LEADDEALS.filter_people_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name.organisation_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            LEADDEALS.filter_org_list &&
            LEADDEALS.filter_org_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'opportunity_name',
        cellClass: 'cellClass',
        tooltipField: 'SOLD Name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Product')}`,
        field: 'product_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADDEALS.getProductList().then((data) => {
              params.success([null, ...data.data.map((x) => x.product_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Value')}`,
        field: 'finance_value',
        cellClass: 'cellClass',
        cellStyle: {
          'text-align': 'right'
        },
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return (
            params.data?.lead_currency_id?.currency_code +
            ' ' +
            params.data?.finance_value
          );
        },
        // cellRenderer: 'btnCellRenderer'
      },
      {
        headerName: `${t('Common_Data.SOLD_Stage')}`,
        field: 'lead_status_id.status_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            LEADDEALS.child_lead_status_list,
            params,
            'id',
            'status_name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
      },
      // {
      //   headerName: `${t('LeadListing.Reason')}`,
      //   headerTooltip: `${t('LeadListing.Reason')}`,
      //   field: 'failed_reason',
      //   tooltipField: 'failed_reason',
      //   cellClass: 'cellClass',
      //   filter: 'agTextColumnFilter',
      // },
      // {
      //   headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
      //   field: 'deadline_date',
      //   // valueGetter: (params) =>
      //   //   params.data && params.data.deadline_date ? params.data.deadline_date : null,
      //   cellRenderer: (params) => {
      //     return params.data.deadline_date
      //       ? moment(params.data.deadline_date).format(AUTH.global_dateformat)
      //       : ' ';
      //   },
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     buttons: ['reset'],
      //     suppressAndOrCondition: true,
      //   },
      // },
      // {
      //   headerName: `${t('Common_Data.Phone')}`,
      //   headerTooltip: `${t('Common_Data.Phone')}`,
      //   field: 'phone',
      //   cellClass: 'cellClassDropDown',

      //   filter: 'agTextColumnFilter',
      //   editable: false,
      //   cellRenderer: 'phoneCellRenderer',
      // },
      // {
      //   headerName: `${t('Common_Data.Email_ID')}`,
      //   headerTooltip: `${t('Common_Data.Email_ID')}`,
      //   field: 'email',
      //   cellClass: 'cellClassDropDown',

      //   filter: 'agTextColumnFilter',
      //   editable: false,
      //   cellRenderer: 'emailCellRender',
      // },
      // {
      //   headerName: `${t('Common_Data.Owner')}`,
      //   field: 'lead_owner_name.full_name',

      //   cellClass: 'cellClass',
      //   filter: 'agSetColumnFilter',
      //   valueFormatter: (params) =>
      //     formatterSetColumnFilter(
      //       LEADDEALS.dropdown_leads_users_list,
      //       params,
      //       'id',
      //       'name'
      //     ),
      //   filterParams: {
      //     defaultToNothingSelected: true,
      //     buttons: ['apply', 'reset'],
      //     values: (params) => {
      //       LEADDEALS.getLeadsUsersList().then((data) => {
      //         params.success([null, ...data.data.map((x) => x.id)]);
      //       });
      //     },
      //     valueFormatter: (params) =>
      //       formatterSetColumnFilter(
      //         LEADDEALS.dropdown_leads_users_list,
      //         params,
      //         'id',
      //         'name'
      //       ),
      //   },
      // },

      // {
      //   headerName: `${t('LeadListing.Time_Spent')}`,
      //   field: 'investment_hours',
      //   cellClass: 'cellClass text-right',

      //   filter: 'agTextColumnFilter',
      //   // cellRenderer: 'btnCellRenderer'
      // },
      {
        headerName: `${t('LeadListing.Age')}`,
        headerTooltip: `${t('LeadListing.Age')}`,
        field: 'age',
        cellRendererFramework: (params) => {
          return (
            <> {CurrencyFormat({ value: params.data.age })}</>
          );
        },
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'age',
        cellClass: 'cellClass text-center',
      },
      {
        headerName: `${t('LeadListing.Idle')}`,
        headerTooltip: `${t('LeadListing.Idle')}`,
        cellRendererFramework: (params) => {
          return (
            <> {CurrencyFormat({ value: params.data.idle })}</>
          );
        },
        field: 'idle',
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'idle',
        cellClass: 'cellClass text-center',
      },
      // {
      //   headerName: `#${t('Common_Data.ID')}`,
      //   field: 'id',
      //   cellClass: 'cellClass',
      //   cellStyle: { textAlign: 'center', padding: '0' },

      //   width: 80,
      //   filter: 'agNumberColumnFilter',
      //   sortable: true,
      //   // cellRenderer: 'btnCellRenderer',
      //   // lockPosition: true,
      //   // suppressNavigable: true
      // },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        // width: WasteManagement === true ? 120 : 280,
        width: 156,
        filter: false,
        sortable: false,
        pinned: 'right',
        // lockPosition: true,
        // suppressNavigable: true,
        // width: "160",
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('search_deal_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['phone', 'email'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }
    return item;
  });

  // const onCellKeyUp = (e) => {
  //   if (quickNoteList && quickNoteList.length > 0) {
  //     if (e.event.ctrlKey || e.event.altKey || e.event.shiftKey) {
  //       return false;
  //     }
  //     let keyPressed = e.event.key;
  //     let result = quickNoteList
  //       .filter((x) => x.quick_note_text_value.charAt(0) === keyPressed.toUpperCase())
  //       .shift();
  //     if (result) {
  //       handleSubmitQN(e.data.id, result.quick_note_text);
  //     }
  //   }
  // };

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('LeadListing.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/dealdesk/${params.node.data.id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('LeadListing.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/dealdesk/${params.node.data.id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }
  function getClipBoardProcess(params) {
    if (params.column.colId === 'phone') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.value[0].contact_value;
        }
      }
    }
    if (params.column.colId === 'email') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.node.data.email;
        }
      }
    }

    return params.value;
  }

  GridLoader(LEADDEALS);

  return (
    <>
      <div
        className={`ag-theme-alpine grid_wrapper TicketsGrid ${SearchPage ? "paginationAtTop" : ""}`}
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : SearchPage && !AllView
              ? 'calc(100vh - 13rem)'
              : SearchPage && AllView
                ? '150px'
                : 'calc(100vh - 13rem)',
          minHeight: props.AllView ? '100px' : '250px',
        }}
      >
        <AgGridReact
          rowData={LEADDEALS.list_data}
          modules={AllModules}
          columnDefs={withTooltipCols}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            // floatingFilter: !SearchPage,
            width: 120,
            minWidth: 70,
            flex: 1,
            editable: false,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openRestoreModal,
            phoneCellRenderer: PhoneNumberRender,
            emailCellRender: EmailCellRender,
            quickNoteList,
            handleSubmitQN,
          }}
          onRowSelected={() => {
            let selectionCounts = LEADDEALS.agGrid.api.getSelectedNodes();
            if (selectionCounts?.length > 10) {
              LEADDEALS.agGrid.api.deselectNode(selectionCounts[0]); // Deselect first selected node
            }
          }}
          onGridReady={LEADDEALS.setupGridSearch}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: true,
            // onRowDoubleClicked: (event) => props.openViewDrawer(event?.data),
            getContextMenuItems: getContextMenuItems,
            processCellForClipboard: getClipBoardProcess,
          }}
          onColumnResized={LEADDEALS.onGridChangedSearch}
          onColumnMoved={LEADDEALS.onGridChangedSearch}
          onColumnPinned={LEADDEALS.onGridChangedSearch}
          rowSelection='multiple'
          suppressRowClickSelection={true}
          // onCellKeyDown={(e) => {
          //   onCellKeyDown(e);
          //   onCellKeyUp(e);
          // }}
          // onFilterChanged={(e) => {
          //   if (e.hasOwnProperty('afterFloatingFilter') && LEADDEALS.selectedGridFilter) {
          //     LEADDEALS.setSelectedGridFilter(null);
          //   }
          // }}
          onSelectionChanged={() => {
            props.selectionChanged();
          }}
        />
      </div>
    </>
  );
});
export default DealSearchListing;
