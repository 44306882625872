import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Col, Row } from 'antd';
import useStore from '../../../store';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const GlobalStatusComponent = observer((props) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const {t} = useTranslation()
  const {
    SAVEDFILTERSTORE: { globalStatusTempData, editFilter },
  } = useStore();

  const handleSubmit = (data) => {
    setSaving(true);
    data.global_toggle = props.toggleswichvalue;

    editFilter(globalStatusTempData.id, data)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: `${t('Save_Filter.Filter_Updated')}!`,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  return globalStatusTempData ? (
    <Modal
      centered
      title={`${t('Common_Data.Filter')} ${t('Common_Data.Update')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='toggleSavedFilterForm'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='toggleSavedFilterForm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={24}>
              <p>
                {!globalStatusTempData.global_toggle
                  ? `${t('Save_Filter.Set_Global_Filter')}?`
                  : `${t('Save_Filter.Remove_Global_Filter')}?`}
              </p>
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default GlobalStatusComponent;
