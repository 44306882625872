import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Col, Row } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const StatusComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const { USERS, AUTH: { user } } = useStore();

  const handleSubmit = () => {
    setSaving(true);
    var payload = {
      id: USERS.statusValues.id,
      Status: USERS.statusValues.status === 'Active' ? 0 : 1,
    };
    USERS.ActivateDeactivateDate(payload)
      .then(() => {
        USERS.getList({}, user?.user_role?.user_role_id === 1 ? true : false);
        props.close();
        vsmNotify.success({
          message:
            USERS.statusValues.status === 'Active'
              ? vsmUsers.deactiveUser
              : vsmUsers.activeUser,
        });
        form.resetFields();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return USERS.statusValues ? (
    <Modal
      centered
      title={USERS.statusValues.status === 'Active' ? `${t('Users.Inactive')} ${t('Common_Data.User')}` : `${t('Users.Active')} ${t('Common_Data.User')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deactivateform'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deactivateform' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={24}>
              <p>
                {t('Users.You_Want_To')}
                {USERS.statusValues.status === 'Active' ? ' inactive ' : ' active '}
                {t('Users.This')} {t('Common_Data.User')}?
              </p>
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default StatusComponent;
