import moment from 'moment';
import React from 'react';
import useStore from '../../../../../store';
const ScheduleTableContent = ({ Schedule }) => {
  const { AUTH } = useStore();
  const PrintSchedule = (structure, ItemKey = '') => {
    return (
      structure &&
      structure.map((x, i) => {
        return (
          <>
            <tr key={ItemKey + x.position}>
              <td valign='top' width='50px' className='task'>
                {ItemKey + x.position}
                {ItemKey === '' ? '.' : ''}
              </td>
              <td valign='top' width='30px' className='day'>
                {x.day}
              </td>
              <td valign='top' className='text'>
                {/* {x.detail} */}
                <span
                  className='data'
                  dangerouslySetInnerHTML={{
                    __html: x.detail
                      ? x.detail.replace(/(?:\r\n|\r|\n)/g, '<br />')
                      : null,
                  }}
                />
              </td>
              <td valign='top' width='100px' className='date'>
                {!x?.children?.length > 0
                  ? moment(new Date(x.start_date), AUTH.global_dateformat, true).isValid()
                    ? moment(new Date(x.start_date)).format(AUTH.global_dateformat)
                    : moment(new Date(x.start_date), 'YYYY-MM-DD').format(
                        AUTH.global_dateformat
                      )
                  : null}
              </td>
            </tr>
            {x.children && PrintSchedule(x.children, ItemKey + x.position + '.')}
          </>
        );
      })
    );
  };

  return <>{PrintSchedule(Schedule)}</>;
};

export default ScheduleTableContent;
