import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Spin } from 'antd';
import FormElements from '../elements/FormElements';
import { vsmNotify, vsmReminders } from '../../../config/messages';
import debounce from 'lodash/debounce';
import useStore from '../../../store';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const EditComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    REMINDERSSTORE,
    SPANNOTESTORE,
    REMINDERSSTORE: { editValues, getUsersList, dropdown_user_list },
    DEALDESKSTORE: { deal_data, setDealData },
  } = useStore();
  const [form] = Form.useForm();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [priority, setPriority] = useState(false);

  const { isFromDealDesk = false } = props;

  const close = () => {
    props.close();
    // REMINDERSSTORE.editValues = null;
    SPANNOTESTORE.contacts_list_data = null;
    setDisabled(true);
    setSaving(false);
    form.resetFields();
  };

  const closeRefresh = () => {
    if (props?.isRefreshGrid) {
      if (REMINDERSSTORE.agGrid) {
        REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
      }
    }
    props.close();
    close();
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.reminder_date_time =
      data.reminder_date_time &&
      moment(data.reminder_date_time).format(AUTH.form_submit_date_with_seconds);
    data.reminder_date =
      data.reminder_date && moment(data.reminder_date).format(AUTH.form_submit_date_with_seconds);

    data.status = editValues.status;
    if (isFromDealDesk) {
      data.is_from_dealdesk = 1;
    }

    if (data.reminder_type === 'notes') {
      data.notes_id = editValues?.reminder_for;
    }
    REMINDERSSTORE.editReminderData(editValues.id, data, props.SearchPage)
      .then((res) => {
        close();
        AUTH.fetchLeftmenuCount();
        if (isFromDealDesk && res?.data) {
          let tempDealdata = deal_data;
          tempDealdata.reminder = res?.data;
          tempDealdata.total_reminder_count = res?.total_reminder_count;
          setDealData(tempDealdata);
        }

        vsmNotify.success({
          message: vsmReminders.edit,
        });
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    setPriority(form.getFieldValue('priority'));

    if (form.getFieldValue('reminder_type') === 'private') {
      if (dropdown_user_list && dropdown_user_list.length > 0) {
        form.setFieldsValue({
          receiver_by: AUTH?.user?.id,
        });
      } else {
        getUsersList().then(() => {
          form.setFieldsValue({
            receiver_by: AUTH?.user?.id,
          });
        });
      }
    }

    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });
          form.setFields(e.errors);
        }
        setSaving(false);
      });
  }, 200);

  useEffect(() => {
    if (editValues) {
      if (editValues.reminder_type === 'tickets') {
        form.setFieldsValue({ ticket_id: editValues.reminder_for });
      }
      if (editValues.reminder_type === 'tags') {
        form.setFieldsValue({ tag_id: editValues.reminder_for });
      }
      if (editValues.reminder_type === 'leads') {
        form.setFieldsValue({ leads_id: editValues.reminder_for });
      }
      if (editValues.reminder_type === 'contacts') {
        form.setFieldsValue({ contacts_id: editValues.reminder_for });
      }
      if (editValues.reminder_type === 'organisations') {
        form.setFieldsValue({ organisations_id: 'o' + editValues.reminder_for });
      }
      setPriority(editValues.priority);
      form.setFieldsValue({
        event_record_type: editValues.event_record_type,
        reminder_type: editValues.reminder_type,
        //status: editValues.status,
        priority: editValues.priority,
        reminder_date: editValues.due_date_time && moment(editValues.due_date_time),
        receiver_by: editValues.receiver_id,
        subject: editValues.subject,
        description: editValues.description,
        full_description: editValues.full_description,
        reminder_date_time:
          editValues.reminder_date_time && moment(editValues.reminder_date_time),
      });
    }
  }, [REMINDERSSTORE, editValues, form, AUTH]);

  const reminderMarkasCompleted = () => {
    SPANNOTESTORE.readCommonReminder(
      { reminder_id: [editValues.id], type: editValues.reminder_type },
      props?.topnotificationViewIDType ? props.topnotificationViewIDType : 4
    ).then((res) => {
      if (isFromDealDesk) {
        let tempDealdata = deal_data;
        tempDealdata.reminder = null;
        tempDealdata.total_reminder_count = res?.total_reminder_count;
        setDealData(tempDealdata);
      }

      props.close();
      if (REMINDERSSTORE.agGrid) {
        REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
      }
      vsmNotify.success({
        message: `${t('Common_Data.Reminder_Updated')}!`,
      });
    });
  };

  return (
    <Drawer
      visible={props.visible}
      onClose={closeRefresh}
      placement='right'
      width={'1050px'}
      title={`${t('Common_Data.Edit')} ${t('Common_Data.Reminder')} - ${editValues ? editValues.id : ''}`}
      destroyOnClose={true}
      footer={[
        <>
          <div className='d-flex' style={{ width: '100%' }}>
            {editValues?.status &&
              !['Closed', 'Completed'].includes(editValues?.status) ? (
              <div className='text-left' style={{ flexGrow: '1' }} key='2'>
                <Button
                  type='primary'
                  key='1remindermarkascompleted'
                  shape='round'
                  size='medium'
                  onClick={() => reminderMarkasCompleted()}
                >
                  {t('Reminders.Mark_Completed')}
                </Button>
              </div>
            ) : null}
            <div className='text-right' key='1'>
              <Button
                key='1'
                form='editform'
                className='mr-10'
                loading={saving}
                htmlType='submit'
                shape='round'
                size='medium'
                type='primary'
                disabled={isDisabled}
              >
                {t('Common_Data.Update')}
              </Button>
              <Button onClick={closeRefresh} shape='round' size='medium' key='2'>
                {t('Common_Data.Cancel')}
              </Button>
            </div>
          </div>

          {editValues?.reminder_type === 'private' ? (
            <img
              className='menuicon'
              style={{
                opacity: '0.5',
                cursor: 'not-allowed',
                position: 'absolute',
                top: '15px',
                right: '20px',
                width: '20px',
              }}
              alt=''
              src={AUTH.GetThemedImage('Deal_icn')}
            />
          ) : (
            <Link
              to={
                editValues?.leads_transactions_id
                  ? editValues?.reminder_type === 'notes'
                    ? `/dealdesk/${editValues?.leads_transactions_id}#n${editValues?.reminder_for}`
                    : editValues?.reminder_type === 'tags' && editValues?.leads_notes_id
                      ? `/dealdesk/${editValues?.leads_transactions_id}#n${editValues?.leads_notes_id}`
                      : `/dealdesk/${editValues?.leads_transactions_id}`
                  : `#nogo`
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='menuicon'
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '15px',
                  right: '20px',
                  width: '20px',
                }}
                alt=''
                src={AUTH.GetThemedImage('Deal_icn')}
              />
            </Link>
          )}
        </>,
      ]}
    >
      {editValues ? (
        <FormElements
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
          priority={priority}
          isDeal={isFromDealDesk}
        />
      ) : (
        <div className='spinCenter'>
          <Spin />
        </div>
      )}
      <TrackTimeComponent
        pageName={'reminders'}
        leadId={editValues?.leads_transactions_id ? editValues?.leads_transactions_id : 0}
      />
      <TimeTracker
        page={"reminders"}
        id={editValues?.leads_transactions_id}
        stop={!props.visible}
      />
    </Drawer>
  );
});

export default EditComponent;
