import { Button, PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import useStore from "../../store";
import ListComponent from "./component/ListComponent";

const TableExample = observer(() => {
	const { TABLEEXAMPLE } = useStore()
    const {t} = useTranslation()
	useEffect(() => {
		TABLEEXAMPLE.getList()
	}, [TABLEEXAMPLE])

	return (
		<>
			<PageHeader
				title=""
				extra={[
					<Button key="1"  >{t('TableExample.New')}</Button>,
				]}
			>
				<ListComponent />
			</PageHeader>
		</>
	)
})

export default TableExample
