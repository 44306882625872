import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Modal, Radio } from 'antd';
import ConnectionsComponent from './component/ConnectionsComponent';
//import SortableConnections from './component/SortableConnections';
import useStore from '../../store';
import DisplayRelatedConnection from './component/DisplayRelatedConnection';
import TrackTimeComponent from '../../component/TrackTimeComponent';
import { default as SOLDConnectionComponent } from '../../page/LeadListing/component/ConnectionComponent';
import { useTranslation } from 'react-i18next';

const ConnectionsModule = observer((props) => {
  const {
    AUTH,
    DEALDESKSTORE: { deal_data, setStopInvestmentHourCounter },
    CONNECTIONS: {
      connection_data,
      displayConnectionList,
      otherConnections_data,
      fetchConnections,
      updateSequence,
      //setConnectionData,
    },
    TimeTrackerStore,
  } = useStore();
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false);
  //const [sortModal, setSortModal] = useState(false);
  const [connectionVisible, setConnectionVisible] = useState(false);
  const [connectionList, setConnectionList] = useState([]);
  const [primaryConnectionList, setPrimaryConnectionList] = useState([]);
  const [otherConnectionList, setOtherConnectionList] = useState([]);

  const [ConnectionModule, setConnectionModule] = useState(false);
  const [peopleOrgData, setPeopleOrgData] = useState(null);
  const [currentTab, setCurrentTab] = useState('organiation');

  const addConnections = (data) => {
    setPeopleOrgData(data);
    setOpenModal(true);
    setConnectionModule(true);
    setStopInvestmentHourCounter(true);
  };

  const sortConnections = (data) => {
    let allFormatArr = [];
    let primaryConnectionArr = [];
    let otherConnectionArr = [];

    connection_data &&
      connection_data.forEach((element) => {
        let connection_role_arr = [];
        if (element.connection_role_name?.includes('#')) {
          connection_role_arr = element.connection_role_name.split('#');
        } else if (element.connection_role_name) {
          connection_role_arr = [element.connection_role_name];
        }

        if (element.role_id) {
          allFormatArr.push({
            type: element?.entity_type,
            id: element?.entity_id,
            name: element?.entity?.name,
            connection_role_name: connection_role_arr,
            email:
              element.entity?.email && Array.isArray(element.entity.email)
                ? element?.entity?.email?.[0]?.contact_value
                : '-',
            phone:
              element.entity?.phone && Array.isArray(element.entity.phone)
                ? element?.entity?.phone?.[0]?.contact_value
                : '-',
            role_name: element?.role_name,
          });
        } else {
          primaryConnectionArr.push({
            type: element?.entity_type,
            id: element?.entity_id,
            name: element?.entity?.name,
            connection_role_name: connection_role_arr,
            email:
              element.entity?.email && Array.isArray(element.entity.email)
                ? element?.entity?.email?.[0]?.contact_value
                : '-',
            phone:
              element.entity?.phone && Array.isArray(element.entity.phone)
                ? element?.entity?.phone?.[0]?.contact_value
                : '-',
            role_name: element?.role_name,
          });
        }
      });

    otherConnections_data &&
      otherConnections_data.forEach((element) => {
        let otherConnectionType = null;
        let otherConnectionId = null;
        if (element.entity?.id === element.entity_id) {
          otherConnectionType = element.entity_type;
          otherConnectionId = element.entity_id;
        }
        if (element.entity?.id === element.related_entity_id) {
          otherConnectionType = element.related_entity_type;
          otherConnectionId = element.related_entity_id;
        }
        otherConnectionArr.push({
          type: otherConnectionType,
          id: otherConnectionId,
          name: element?.entity?.name,
          email:
            element.entity?.email && Array.isArray(element.entity.email)
              ? element?.entity?.email?.[0]?.contact_value
              : '-',
          phone:
            element.entity?.phone && Array.isArray(element.entity.phone)
              ? element?.entity?.phone?.[0]?.contact_value
              : '-',
          role_name: element?.role_name,
        });
      });
    // if (allFormatArr) {
    //   allFormatArr = allFormatArr.filter(x => (x.role_id !== 0 || x.role_id !== null));
    // }
    setPrimaryConnectionList(primaryConnectionArr);
    setConnectionList(allFormatArr);
    setOtherConnectionList(otherConnectionArr);

    setStopInvestmentHourCounter(true);
    setConnectionVisible(true);
  };

  const closeSortConnection = (data) => {
    // setSortModal(false);
    setConnectionVisible(false);
    setStopInvestmentHourCounter(false);
  };

  const closeConnections = () => {
    setPeopleOrgData(null);
    setOpenModal(false);
    setStopInvestmentHourCounter(false);
  };

  const optionsRadiolist = [
    { label: peopleOrgData && peopleOrgData.orgname, value: 'organiation' },
    { label: peopleOrgData && peopleOrgData.peoplename, value: 'people' },
  ];

  useEffect(() => {
    if (deal_data.lead_information && deal_data.lead_information.id) {
      fetchConnections(deal_data.lead_information && deal_data.lead_information.id);
    }
  }, [fetchConnections, deal_data]);

  useEffect(() => { }, [connectionList, connection_data, otherConnectionList, otherConnections_data]);

  useEffect(() => {
    if (connectionVisible) {
      TimeTrackerStore.setStopForDesk(true)
    } else {
      TimeTrackerStore.setStopForDesk(false)
    }
  }, [connectionVisible, TimeTrackerStore]);

  return (
    AUTH.app_color_theme && (
      <>
        <ConnectionsComponent
          addConnections={addConnections}
          sortConnections={sortConnections}
          connection_dataCount={ connection_data?.length + otherConnections_data?.length || 0}
          connection_data={displayConnectionList}
        />

        {/* may be there is no more use of this modal */}
        <Modal
          title={`${t('ConnectionsModule.Manage')} ${t('LeadListing.Connections')}`}
          centered
          visible={openModal}
          footer={false}
          onCancel={() => closeConnections()}
          width={1000}
          className='SnapNoteModal'
        >
          <Radio.Group
            options={optionsRadiolist}
            onChange={(e) => setCurrentTab(e.target.value)}
            value={currentTab}
            optionType='button'
            buttonStyle='solid'
            className='peopleOrgTabs'
          />
          <DisplayRelatedConnection
            id={
              currentTab === 'organiation'
                ? peopleOrgData && peopleOrgData.orgid
                : peopleOrgData && peopleOrgData.peopleid
            }
            currentTab={currentTab}
            ConnectionModule={ConnectionModule}
          />
        </Modal>

        {openModal && deal_data ? (
          <TrackTimeComponent
            pageName={'dealdesk'}
            leadId={deal_data?.lead_information?.id}
          />
        ) : null}

        <SOLDConnectionComponent
          visible={connectionVisible}
          close={closeSortConnection}
          isFromDealDesk={true}
          setConnectionList={setConnectionList}
          connectionList={connectionList}
          updateSequence={updateSequence}
          primaryConnectionList={primaryConnectionList}
          setPrimaryConnectionList={setPrimaryConnectionList}
          otherConnectionList={otherConnectionList}
          setOtherConnectionList={setOtherConnectionList}
        />
      </>
    )
  );
});

export default ConnectionsModule;
