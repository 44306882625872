import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import useStore from '../../../../store';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import EditForm from './EditForm';

const Edit = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { KeyNotesSearchStore } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.id = KeyNotesSearchStore.editValue.id;
    KeyNotesSearchStore.EditKeyNote(data)
      .then(() => {
        close();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (KeyNotesSearchStore.editValue && props.visible) {
      form.setFieldsValue({
        key_note: KeyNotesSearchStore.editValue?.key_note,
      });
      if (KeyNotesSearchStore.editValue?.key_note) {
        setDisabled(false)
      }
    }
  }, [KeyNotesSearchStore, KeyNotesSearchStore?.editValue?.key_note, form, props.visible]);

  return (
    KeyNotesSearchStore.editValue && (
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Edit')} ${t('DealDesk.Key_Note')} - #${KeyNotesSearchStore.editValue?.id}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right'>
            <Button
              key='1'
              form='editform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              disabled={isDisabled}
              shape='round'
            >
              {t('Common_Data.Update')}
            </Button>.
            <Button onClick={close} shape='round' key='2'>
              {' '}
              {t('Common_Data.Cancel')}
            </Button>
          </div>
        ]}
      >
        <EditForm
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='editform'
        />
      </Drawer>
    )
  );
});

export default Edit;
