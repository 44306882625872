import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button } from 'antd';
import GridConfig from '../../../config/GridConfig';
import { useHistory } from 'react-router';
import { vsmCommon } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
import { DateComparator } from '../../../utils/GlobalFunction';
import moment from 'moment';
import NoteRenderer from './NoteRenderer';

const ListComponent = observer((props) => {
  const { t } = useTranslation();
  const { AUTH, HISTORY, SEARCHSTORE, SEARCHSTORE: { setResultCount, searchCount } } = useStore();
  const { SearchPage, openDeleteDrawer, WasteManagement, } = props;
  let history = useHistory();

  useEffect(() => {
    if (WasteManagement) {
      HISTORY.isWastePage = true;
      HISTORY.search_payload = {};
    } else if (SearchPage) {
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.notes_count;
      setResultCount(searchCount?.notes_count);
      HISTORY.isWastePage = false;
    } else {
      HISTORY.search_payload = {};
      HISTORY.isWastePage = false;
    }
  }, [
    HISTORY,
    SEARCHSTORE,
    SEARCHSTORE.searchCount,
    SearchPage,
    HISTORY.total,
    WasteManagement,
    setResultCount,
    searchCount?.notes_count
  ]);

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  const ActionRenderer = (params) => {
    return WasteManagement ? (
      <div className='action-column'>
        {AUTH.checkPermission(38, 'delete') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => openDeleteDrawer(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        )}
        {AUTH.checkPermission(38, 'restore') && (
          <Button
            type='text'
            title={`${t('Common_Data.Restore')}`}
            onClick={() => props.openRestoreModal(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Restore')} />
          </Button>
        )}
      </div>
    ) : (
      <div className='action-column'>
        <Button type='text' title={`${t('History.Deal_Desk')}`}>
          <img
            className='menuicon'
            alt=''
            src={AUTH.GetThemedImage('Deal_icn')}
            onClick={() => {
              if (params.data?.id) {
                history.push(
                  `/dealdesk/${params.data.leads_transactions_id}#n${params.data?.id}`
                );
              } else {
                history.push(`/dealdesk/${params.data.leads_transactions_id}`);
              }
            }}
          />
        </Button>

        {/* Note */}
        <NoteRenderer data={params.data} />

        {AUTH.checkPermission(38, 'delete') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => openDeleteDrawer(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        )}


      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,
        field: 'history_date_modified',
        cellRenderer: (params) => {
          let newDate = params?.data?.history_date_modified && params?.data?.history_date_modified
          return newDate ? moment(newDate).format(AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,
        field: 'created_by_name',
        tooltipField: 'created_by_name',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            HISTORY.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            HISTORY.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              HISTORY.dropdown_leads_users_list,
              params,
              'id',
              'name'
            ),
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `#${t('Common_Data.ID')}`,
        field: 'related_id',
        tooltipField: 'related_id',
        // filter: 'agNumberColumnFilter',
        filter: false,
        sortable: false,
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
        // pinned: 'left',
        width: 120,
      },     
      {
        headerName: `${t('Common_Data.Type')}`,
        headerTooltip: `${t('Common_Data.Type')}`,
        field: 'type',
        tooltipField: 'type',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([...HISTORY.statuslistHistoryFilter.map((x) => x.text)]);
          },
        },
      },

      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        cellClass: 'cellClass',
        field: 'organisation_name',
        tooltipField: 'organisation_name',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            HISTORY.filter_org_list &&
            HISTORY.filter_org_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            HISTORY.filterGetMappedOrg().then((data) => {
              params.success([null, ...data.map((x, indexID) => indexID)]);
            });
          },
          valueFormatter: (params) => {
            return (
              params.value &&
              HISTORY.filter_org_list &&
              HISTORY.filter_org_list[params.value]
            );
          },
        },
      },

      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'notes_description',
        tooltipValueGetter: (params) => {
          return (
            params.data?.notes_description &&
            params.data.notes_description.replace(/<(.|\n)*?>/g, '')
          );
        },
        cellRenderer: (params) => {
          return (
            params.data?.notes_description &&
            params.data.notes_description.replace(/<(.|\n)*?>/g, '')
          );
        },
        filter: 'agTextColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: 130,
        minWidth: 130,
        filter: false,
        sortable: false,
        pinned: 'right',
        lockPosition: true,
        suppressNavigable: true,
      },
    ],
  }

  let columns = [];
  let columnConfig = localStorage.getItem('history_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data.forEach((element) => {
      cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
      if (cols) {
        columns.push(cols);
      }
    });
  } else {
    columns = gridOptions.columnDefs;
  }

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('History.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/dealdesk/${params.node.data.leads_transactions_id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('History.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/dealdesk/${params.node.data.leads_transactions_id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  return (
    <div
      className={`ag-theme-alpine grid_wrapper TicketsGrid ${SearchPage ? "paginationAtTop" : ""}`}
      style={{
        height:
          props.SearchPage && !props.AllView
            ? 'calc(100vh - 13rem)'
            : props.SearchPage && props.AllView
              ? '150px'
              : 'calc(100vh - 13rem)',
        minHeight: props.AllView ? '100px' : '250px',
      }}
    >
      <AgGridReact
        rowData={HISTORY.list_data}
        modules={AllModules}
        columnDefs={columns}
        defaultColDef={{
          ...GridConfig.defaultColDef,
          width: 120,
          minWidth: 70,
          flex: 1,
          // floatingFilter: !SearchPage,
          editable: false,
        }}
        columnTypes={GridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openDeleteDrawer,
        }}
        onGridReady={HISTORY.setupGrid}
        gridOptions={{
          ...GridConfig.options,
          rowHeight: 30,
          pagination: !props.AllView,
          getContextMenuItems: getContextMenuItems,
        }}
        onColumnResized={HISTORY.onGridChanged}
        onColumnMoved={HISTORY.onGridChanged}
        onColumnPinned={HISTORY.onGridChanged}
        onFilterChanged={HISTORY.onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
      />
    </div>
  );
});

export default ListComponent;
