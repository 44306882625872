import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Space, Form, Button } from 'antd';
import useStore from '../../store';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import ListComponent from './component/ListComponent';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import SalesComponent from './component/SalesComponent';
import SettingsComponent from './component/SettingsComponent';
import EditProfileComponent from './component/EditProfileComponent';
import RestPasswordComponent from './component/ResetPasswordComponent';
import StatusComponent from './component/StatusComponent';
import ProxyloginComponent from './component/ProxyloginComponent';
import RecordPerPage from '../../config/RecordPerPage';
import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../Unauthorized';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../component/TimeTracker';

const Users = observer(() => {
  let history = useHistory();
  const { USERS, SETTINGS, AUTH } = useStore();
  const [addDrawer, setAddDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [salesDrawer, setSalesDrawer] = useState(false);
  const [settingDrawer, setSettingDrawer] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [openRestPassModal, setOpenRestPassModal] = useState(false);
  const [proxyconfirm, setProxyconfirm] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const { t } = useTranslation()
  // Open form for edit profile User and set values to form
  const openProfileEditModal = (data) => {
    USERS.setEditProfileValues(data.id);
    setEditProfileModal(true);
  };

  //Open reset pass modal
  const resetPassModal = (data) => {
    USERS.setResetPassword(data);
    setOpenRestPassModal(true);
  };

  const closeResetPassModal = () => {
    USERS.setResetPassword(null);
    setOpenRestPassModal(false);
  };

  // Open form for edit User
  const closeProfileEditModal = () => {
    USERS.editProfileValues = null;
    setEditProfileModal(false);
  };

  //open sales drawer
  const openSettingDrawer = (data) => {
    USERS.setUserSetting(data);
    setSettingDrawer(true);
  };

  //close add drawer
  const closeSettingDrawer = () => {
    USERS.usersettingValues = null;
    setSettingDrawer(false);
  };

  //open sales drawer
  const openSalesDrawer = (data) => {
    USERS.getSalesTarget(data);
    setSalesDrawer(true);
  };

  //close sales drawer
  const closeSalesDrawer = () => setSalesDrawer(false);

  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  //open edit drawer
  const openEditDrawer = (data) => {
    USERS.setEditValues(data);
    setEditDrawer(true);
  };

  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);

  //open delet drawer
  const openProxyDrawer = (data) => {
    USERS.setProxyLogin(data);
    setProxyconfirm(true);
  };

  //close delet drawer
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const openViewDrawer = (data) => {
    history.push(`/people/all-people/view-people/${data.id}`);
  };

  // call proxylogin function
  const cancelProxylogin = () => {
    USERS.setProxyLogin([]);
    setProxyconfirm(false);
  };

  // Handle on switch data
  const onSwitchChange = (checked, data) => {
    USERS.agGrid.api.refreshCells({ force: true });
    USERS.setStatusValues(data);
    setUserStatus(true);
  };

  // To Close Status Model Popup
  const closeStatsModal = () => setUserStatus(false);

  return (
    <>
      {!AUTH.checkPermission(27, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('ManageAllDepartments.Users')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <Form className='innerForm headerButton'>
              <Space align='center' size={5}>
                <Button
                  key='1'
                  title={`${t('Common_Data.Reset')}`}
                  shape='round'
                  size='medium'
                  onClick={USERS.handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <EnterFullscreen key='3' />
                <RecordPerPage
                  key='2'
                  initialValue={USERS.per_page + ' per page'}
                  onChange={USERS.setPageSize}
                />
              </Space>
            </Form>,
          ]}
        >
          <ListComponent
            openProfileEditModal={openProfileEditModal}
            openSettingDrawer={openSettingDrawer}
            openSalesDrawer={openSalesDrawer}
            openProxyDrawer={openProxyDrawer}
            openEditDrawer={openEditDrawer}
            openViewDrawer={openViewDrawer}
            resetPassModal={resetPassModal}
            onSwitchChange={onSwitchChange}
          />
          <AddComponent visible={addDrawer} close={closeAddDrawer} />
          <EditComponent visible={editDrawer} close={closeEditDrawer} />
          <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
          <RestPasswordComponent
            visible={openRestPassModal}
            close={closeResetPassModal}
          />
          <SalesComponent visible={salesDrawer} close={closeSalesDrawer} />
          <SettingsComponent visible={settingDrawer} close={closeSettingDrawer} />
          <EditProfileComponent
            visible={editProfileModal}
            close={closeProfileEditModal}
            resetPassModal={resetPassModal}
            currentTab={'user'}
          />
          {userStatus && <StatusComponent visible={userStatus} close={closeStatsModal} />}
          {openRestPassModal && (
            <RestPasswordComponent
              visible={openRestPassModal}
              close={closeResetPassModal}
            />
          )}
          <ProxyloginComponent visible={proxyconfirm} close={cancelProxylogin} />
          {/* <TrackTimeComponent pageName={'users'} /> */}
          <TimeTracker />
        </PageHeader>
      )}
    </>
  );
});

export default Users;
