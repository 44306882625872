import React from 'react';
import { observer } from 'mobx-react';
import { Drawer, Row, Col } from 'antd';
import useStore from '../../../store';
import { capitalFirstLetter } from '../../../utils/GlobalFunction';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ViewComponent = observer((props) => {
  const {t} = useTranslation()
  const {
    AUTH,
    SAVEDFILTERSTORE: { viewValues },
  } = useStore();

  let newArrrr = [];
  let jsonObjData = viewValues?.value ? JSON.parse(viewValues?.value) : {};

  const result = jsonObjData
    ? Object.keys(jsonObjData).map((key) =>
      newArrrr.push({
        name: key,
        filterData: jsonObjData[key],
      })
    )
    : null;
  if (result) {
  }

  return viewValues ? (
    <>
      <Drawer
        visible={props.visible}
        onClose={() => props.close()}
        placement='right'
        width={`1100px`}
        title={`${t('Common_Data.View')} ${t('Common_Data.Page')} ${t('Common_Data.Filter')}`}
        destroyOnClose={true}
        footer={[]}
      >
        <div className='viewPeople'>
          <table cellPadding='0' cellSpacing='0' border='0'>
            <tr>
              <td width='20%' style={{ border: '0' }}></td>
              <td width='30%' style={{ border: '0' }}></td>
              <td width='20%' style={{ border: '0' }}></td>
              <td width='30%' style={{ border: '0' }}></td>
            </tr>
            <tr>
              <th>{t('Common_Data.Filter')} {t('Common_Data.Name')}:</th>
              <td colSpan='3'>{viewValues?.filter} </td>
            </tr>
            <tr>
              <th>{t('Common_Data.Page')} {t('Common_Data.Name')}:</th>
              <td colSpan='3'>{capitalFirstLetter(viewValues?.page)} </td>
            </tr>

            {/* <tr>
              <th>Filter Data:</th>
              <td colSpan='3'>{viewValues?.value} </td>
            </tr> */}
            <tr>
              <th>{t('Common_Data.Created')} {t('Common_Data.By')}:</th>
              <td colSpan='3'>{viewValues?.created_by_name} </td>
            </tr>
            <tr>
              <th>{t('Common_Data.Created')} {t('Common_Data.At')} :</th>
              <td colSpan='3'>{AUTH.getLocalTime(viewValues?.created_at,AUTH.global_fulldate_format)}</td>
            </tr>
          </table>

          <Row gutter={0}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <div className='LogDetails pageFillterView'>
                <div className='title'>{t('Common_Data.Filter')} {t('Common_Data.Details')}</div>
                <div className='content'>
                  <ol>
                    {newArrrr &&
                      newArrrr.length > 0 &&
                      newArrrr.map((item, index) => {

                        return (
                          <>
                            <li>
                              <div className='itemName'>{item.name}</div>
                              <div className='itemDetail'>
                                {item?.filterData?.filterType
                                  ? item?.filterData?.filterType === 'text' ||
                                    item?.filterData?.filterType === 'number'
                                    ? item?.filterData?.filter?.toString()
                                    :
                                    item?.filterData?.filterType === 'date'
                                      ?
                                      item?.filterData?.dateTo ?
                                        `${moment(item?.filterData?.dateFrom).format(AUTH.global_dateformat)} - ${moment(item?.filterData?.dateTo).format(AUTH.global_dateformat)}`
                                        :
                                        `${moment(item?.filterData?.dateFrom).format(AUTH.global_dateformat)}`
                                      :
                                      item?.filterData?.values?.toString()
                                  : item?.filterData?.filter_type
                                    ? item?.filterData?.filter_type === 'date'
                                      ?
                                      item?.filterData?.date_to ?
                                        `${moment(item?.filterData?.date_from).format(AUTH.global_dateformat)} - ${moment(item?.filterData?.date_to).format(AUTH.global_dateformat)}`
                                        :
                                        `${moment(item?.filterData?.date_from).format(AUTH.global_dateformat)}`
                                      : item?.filterData?.filter_type === 'text' ||
                                        item?.filterData?.filter_type === 'number'
                                        ? item?.filterData?.filter
                                        : item?.filterData?.values?.toString()
                                    : ''}
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  ) : null;
});

export default ViewComponent;
