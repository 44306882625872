import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import moment from 'moment';
import { vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DateTimeConfirmComponent = observer((props) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const {
    AUTH,
    PEOPLE,
    DEALDESKSTORE: { deal_data, setDealData },
  } = useStore();

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    var data = {
      reminder_type: 'contacts',
      reminder_for: props?.reminderConfirm?.id,
      reminder_date_time: moment(props.date).format(AUTH.form_submit_date_with_minutes),
      hourglass: false,
    };
    if (props.isFromDealDesk) {
      data.is_from_dealdesk = true; //If Quick reminder created from dealdesk
    }
    setSaving(true);
    AUTH.ResetData(data)
      .then((res) => {
        close();
        if (props?.resetNotificationData) {
          props.resetNotificationData();
        }
        if (props.isFromDealDesk) {
          let tempDealdata = deal_data;
          tempDealdata.reminder = res?.reminder_data;
          tempDealdata.total_reminder_count = res?.total_reminder_count;
          setDealData(tempDealdata);
        }
        if (PEOPLE.agGrid) {
          PEOPLE.setupGrid(PEOPLE.agGrid);
        }
        vsmNotify.success({
          message: `${t('Common_Data.Reminder_Updated')}!`,
        });
      })
      .catch((e) => { })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Reset')} ${t('Common_Data.People')} ${t('Common_Data.Reminder')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='dateTimeForm'
          htmlType='submit'
          type='primary'
          shape='round'
          loading={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='dateTimeForm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('PeopleManagement.Reminder_People')}{' '}
              <strong>
                {props?.reminderConfirm?.people_name
                  ? props?.reminderConfirm?.people_name
                  : ''}
              </strong>{' '}
              {t('Common_Data.To')}{' '}
              <strong>
                {props?.date
                  ? moment(props?.date).format(AUTH.global_dateformat + ' hh:mm A')
                  : ''}
              </strong>
              ?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  );
});

export default DateTimeConfirmComponent;
