import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';

import EditViewComponent from '../../page/Tags/component/EditViewComponent';
import TicketViewComponent from '../../page/Tickets/component/ViewComponent';
import useStore from '../../store';

import TagsTicketsComponent from './component/TagsTicketsComponent';
import ModalTagTicketComponent from './component/ModalTagTicketComponent';
// import TrackTimeComponent from '../../component/TrackTimeComponent';

const TagsTicketsModule = observer((props) => {
  const [viewTagDrawer, setViewTagDrawer] = useState(false);
  const [viewTicketDrawer, setViewTicketDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingEditView, setLoadingEditView] = useState(false);

  const [id, setId] = useState();

  const {
    AUTH,
    TagsStore,
    DEALDESKSTORE: { deal_data, setStopInvestmentHourCounter },
    RIGHTSECTTION_TAGSTICKETS: {
      tagcount,
      ticketcount,
      tagsticket_data,
      fetchTagsTicketData,
    },
    TimeTrackerStore
  } = useStore();

  const addConnections = (data) => {
    //On Plus Icon Click->FetchData
    TagsStore.getList({
      leads_transactions_id: deal_data?.lead_information?.id,
    });
    setOpenModal(true);
    setStopInvestmentHourCounter(true);
  };

  //View drawer
  const openTagViewDrawer = (data) => {
    TimeTrackerStore.setStop(true);
    setLoadingEditView(true);
    TagsStore.setViewValues(data).then((res) => {
      TagsStore.viewValues = res;
      setLoadingEditView(false);
      setViewTagDrawer(true);
    });
  };
  const closeViewTagDrawer = () => {
    TimeTrackerStore.setStop(false);
    setViewTagDrawer(false)
  };

  const openTicketViewDrawer = (data) => {
    if (AUTH.checkPermission(15, 'view-details')) {
      TimeTrackerStore.setStop(true);
      setId(data);
      // TicketsStore.setViewValues(data);
      setViewTicketDrawer(true);
    }
  };
  const closeViewTicketDrawer = () => {
    TimeTrackerStore.setStop(false);
    setViewTicketDrawer(false)
  };

  const closeTagTicketModal = () => {
    setStopInvestmentHourCounter(false);

    setOpenModal(false);
  };

  useEffect(() => {
    if (deal_data?.lead_information?.id) {
      fetchTagsTicketData(deal_data?.lead_information?.id);
    }
  }, [fetchTagsTicketData, deal_data]);

  useEffect(() => {}, [tagsticket_data]);

  return (
    <>
      <TagsTicketsComponent
        addConnections={addConnections}
        openTagViewDrawer={openTagViewDrawer}
        openTicketViewDrawer={openTicketViewDrawer}
        tagsticket_data={tagsticket_data}
        tagcount={tagcount}
        ticketcount={ticketcount}
      />



      <ModalTagTicketComponent
        visible={openModal}
        close={closeTagTicketModal}
        openTagViewDrawer={openTagViewDrawer}
        openTicketViewDrawer={openTicketViewDrawer}
      />


      <EditViewComponent
        visible={viewTagDrawer}
        close={closeViewTagDrawer}
        loadingEditView={loadingEditView}
        fromSideBar
      />
      <TicketViewComponent
        id={id}
        setId={setId}
        visible={viewTicketDrawer}
        close={closeViewTicketDrawer}
        fromSideBar
      />
      {/* {openModal && deal_data ? (
        <TrackTimeComponent
          pageName={'dealdesk'}
          leadId={deal_data?.lead_information?.id}
        />
      ) : null} */}
    </>
  );
});

export default TagsTicketsModule;
