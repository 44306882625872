import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import useStore from '../../../store';
import { vsmCallAudit, vsmNotify } from '../../../config/messages';

const EmailRenderer = observer((props) => {
  const { AUTH, LEADSSTORE } = useStore();
  //const emailList = props.value;

  const [emailList] = useState(props.data.email);
  const [peopleEmail] = useState(props.data.people_email);

  const handleCallAudit = (data) => {
    let formdata = {
      id: props.data.id,
      email: data,
    };
    LEADSSTORE.callEmailAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.emailadded,
      });
    });
  };

  const emails = (
    <div className='contactList'>
      <ul>
        {peopleEmail &&
          peopleEmail.length > 0 &&
          peopleEmail.map((detail, index) => {
            return (
              <li key={index} value={`${detail.contact_for}: ${detail.contact_value}`}>
                <a
                  href={`mailto:${detail.contact_value}`}
                  onClick={(e) => {
                    handleCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === 'Primary' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Home' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Office' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Other' && AUTH.GetThemedImage('Mail'))
                    }
                    alt=''
                    className='menuicon'
                  />{' '}
                  {detail.contact_value}
                </a>
              </li>
            );
          })}
        {emailList &&
          emailList.length > 0 &&
          emailList.map((detail, index) => {
            return (
              <li key={index} value={`${detail.contact_for}: ${detail.contact_value}`}>
                <a
                  href={`mailto:${detail.contact_value}`}
                  onClick={(e) => {
                    handleCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === 'Primary' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Home' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Office' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Other' && AUTH.GetThemedImage('Mail'))
                    }
                    alt=''
                    className='menuicon'
                  />{' '}
                  {detail.contact_value}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <div className='action-column TelephoneRenderer'>
      <Popover
        content={emails}
        placement='topLeft'
        trigger='hover'
        overlayClassName='profileMenu'
      >
        {peopleEmail && peopleEmail.length > 0
          ? peopleEmail.map((detail, index) => {
              if (index === 0) {
                return (
                  <a
                    href={`mailto:${detail.contact_value}`}
                    onClick={(e) => {
                      handleCallAudit(detail.contact_value);
                    }}
                    key={index}
                  >
                    <img
                      src={
                        (detail.contact_for === 'Primary' &&
                          AUTH.GetThemedImage('Mail')) ||
                        (detail.contact_for === 'Home' && AUTH.GetThemedImage('Mail')) ||
                        (detail.contact_for === 'Office' &&
                          AUTH.GetThemedImage('Mail')) ||
                        (detail.contact_for === 'Other' && AUTH.GetThemedImage('Mail'))
                      }
                      alt=''
                      className='menuicon'
                    />{' '}
                    {detail.contact_value}
                  </a>
                );
              } else {
                return null;
              }
            })
          : emailList &&
            emailList.length > 0 &&
            emailList.map((detail, index) => {
              if (index === 0) {
                return (
                  <a
                    href={`mailto:${detail.contact_value}`}
                    onClick={(e) => {
                      handleCallAudit(detail.contact_value);
                    }}
                    key={index}
                  >
                    <img
                      src={
                        (detail.contact_for === 'Primary' &&
                          AUTH.GetThemedImage('Mail')) ||
                        (detail.contact_for === 'Home' && AUTH.GetThemedImage('Mail')) ||
                        (detail.contact_for === 'Office' &&
                          AUTH.GetThemedImage('Mail')) ||
                        (detail.contact_for === 'Other' && AUTH.GetThemedImage('Mail'))
                      }
                      alt=''
                      className='menuicon'
                    />{' '}
                    {detail.contact_value}
                  </a>
                );
              } else {
                return null;
              }
            })}
      </Popover>
    </div>
  );
});
export default EmailRenderer;
