import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const EditForm = observer(
    ({ handleSubmit, onChange, form, id }) => {


        const { t } = useTranslation();
        const onFinish = (data) => {
            handleSubmit(data);
        };

        return (
            <Form
                form={form}
                id={id}
                labelCol={{ span: 24 }}
                className='innerForm'
                onFinish={onFinish}
            >
                <InputComponent
                    required
                    className='note'
                    type='textarea'
                    name={'key_note'}
                    label={`${t('DealDesk.Key_Note')}`}
                    placeholder={`${t('DealDesk.Key_Note')}`}
                    onChange={onChange}
                    rows={5}
                    rules={[
                        { required: true, message: `${t('DealDesk.Not_Blank')}` },
                    ]}
                />
            </Form>
        );
    }
);

export default EditForm;
