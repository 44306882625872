import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { ageDifferenceInDays } from '../../utils/GlobalFunction';
//import { convertTextToID } from '../../utils/GlobalFunction';
import { reset, clear } from '../../utils/ResetClearButton';

export default class LeadsStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  extraFilter = null;

  deleteValues = null;
  editValues = null;

  oldConnectionList = null;

  cloneValues = null;
  viewValues = null;
  waste = false;
  search_payload = {};
  child_lead_status_list = null;
  dropdown_leads_users_list = null;

  ignore_unusedlead_id = 4;

  filter_people_list = null;
  filter_org_list = null;

  current_list_path = null;
  dropdown_leadstatus_list = null;
  dropdown_leadstatus_list_combined = null;
  dropdown_leadstatus_list_filtered = null;
  classification_role_list = null;
  dropdown_people_list = null;
  dropdown_org_list = null;
  dropdown_currency_list = null;
  dropdown_users_list = null;
  dropdown_sold_souce_list = null;
  report_dropdown_users_list = null;
  dropdown_unusedleadstatus_list = null;

  kanban_status_list = [];
  kanban_list_data = [];
  loading_kanban = true;

  exportLoadingIcn = false;

  masterLeadToMerge = null;

  gridLoading = false;
  isMergePeople = false;

  initialValues = {
    phone: [{ id: null, contact_for: 'Primary' }],
    email: [{ id: null, contact_for: 'Primary' }],
  };

  call_audit_list = null;
  allChildStagesList = null;

  selectedGridFilter = null;

  leadSourceArray = [
    { value: 'None', text: 'None' },
    { value: 'Accountant', text: 'Accountant' },
    { value: 'Advertisement', text: 'Advertisement' },
    { value: 'AdWords', text: 'AdWords' },
    { value: 'Agent', text: 'Agent' },
    { value: 'Cold Call', text: 'Cold Call' },
    {
      value: 'Credebt® Employee',
      text: 'Credebt® Employee',
    },
    {
      value: 'Credebt® Website',
      text: 'Credebt® Website',
    },
    {
      value: 'Email via Credebt Shipping',
      text: 'Email via Credebt Shipping',
    },
    {
      value: 'Email via Trade Credebt',
      text: 'Email via Trade Credebt',
    },
    {
      value: 'Existing Originator',
      text: 'Existing Originator',
    },
    {
      value: 'Financial Broker',
      text: 'Financial Broker',
    },
    {
      value: 'Friend/acquaintance',
      text: 'Friend/acquaintance',
    },
    {
      value: 'Inbound Call',
      text: 'Inbound Call',
    },
    { value: 'Internet Search', text: 'Internet Search' },
    { value: 'List', text: 'List' },
    { value: 'Miscellaneous', text: 'Miscellaneous' },
    { value: 'Other', text: 'Other' },
    { value: 'Other Website', text: 'Other Website' },
    {
      value: 'Questionnaire - Credebt Shipping',
      text: 'Questionnaire - Credebt Shipping'
    },
    {
      value: 'Questionnaire - Trade Credebt',
      text: 'Questionnaire - Trade Credebt'
    },
    {
      value: 'Questionnaire - Trade Credebt [L/C]',
      text: 'Questionnaire - Trade Credebt [L/C]'
    },
    {
      value: 'Recruitment/HR company',
      text: 'Recruitment/HR company',
    },
    { value: 'Referral', text: 'Referral' },
    { value: 'Research', text: 'Research' },
    { value: 'Trade Show', text: 'Trade Show' },
  ];

  setoldConnectionList = (data = []) => {
    this.oldConnectionList = data;
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setViewValues = (data) => {    
    return Axios.post(`leadstransactions/read/${data.id}`, { waste: this.waste })
      .then(({ data }) => {
        this.viewValues = data && data.data;
        return true;
      })
      .catch(({ response: { data } }) => {
        // this.viewValues = null;
        return Promise.reject(data);
      });
  };

  //set edit values
  setEditValues = (data) => {
    return Axios.post(`leadstransactions/read/${data.id}`, { waste: this.waste })
      .then(({ data }) => {
        this.editValues = data && data.data;
        return true;
      })
      .catch(({ response: { data } }) => {
        //  this.editValues = null;
        return Promise.reject(data);
      });
  };

  //set edit values
  setCloneValues = (data) => {
    return Axios.post(`leadstransactions/read/${data.id}`, { waste: this.waste })
      .then(({ data }) => {
        this.cloneValues = data && data.data;
        return true;
      })
      .catch(({ response: { data } }) => {
        //  this.editValues = null;
        return Promise.reject(data);
      });
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('leads_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
    var datasource = this.createDatasource(GridConfig.options);
    api.setServerSideDatasource(datasource);
    let param = this.getFilter(params);
    if (param && param.request) {
      this.agGrid.api.setFilterModel(param.request.filter);
      this.agGrid.api.setSortModel(param.request.sort);
    }
  };
  // Setup grid and set column size to autosize
  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_leads_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  getFilter = (params) => {
    let get_var_name = '';
    if (this.current_list_path === '/leads/opportunities') {
      get_var_name = `opportunities_params`;
    } else if (this.current_list_path === '/leads/unused') {
      get_var_name = `unused_params`;
    } else if (this.current_list_path === '/leads/deals') {
      get_var_name = `deals_params`;
    } else if (this.current_list_path === '/leads') {
      get_var_name = `active_params`;
    } else if (this.current_list_path === '/promotion') {
      get_var_name = `promotion_params`;
    } else if (this.current_list_path === '/management/block-edit-assign') {
      get_var_name = `management_params`;
    }

    if (localStorage.getItem(get_var_name)) {
      var temp = JSON.parse(localStorage.getItem(get_var_name));
      if (temp.LEADSSTORE) {
        params = { request: temp.LEADSSTORE };
      }
    }
    return params;
  };

  setFilter = (param) => {
    let var_name = '';
    if (this.current_list_path === '/leads/opportunities') {
      var_name = `opportunities_params`;
    } else if (this.current_list_path === '/leads/unused') {
      var_name = `unused_params`;
    } else if (this.current_list_path === '/leads/deals') {
      var_name = `deals_params`;
    } else if (this.current_list_path === '/leads') {
      var_name = `active_params`;
    } else if (this.current_list_path === '/promotion') {
      var_name = `promotion_params`;
    } else if (this.current_list_path === '/management/block-edit-assign') {
      var_name = `management_params`;
    }

    if (localStorage.getItem(var_name)) {
      var temp = JSON.parse(localStorage.getItem(var_name));
      localStorage.setItem(
        var_name,
        JSON.stringify({
          ...temp,
          LEADSSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        var_name,
        JSON.stringify({
          LEADSSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  getList = (payload = {}) => {
    let api_url_path = `/leadstransactions/list/active`;

    if (this.current_list_path === '/leads/opportunities') {
      api_url_path = `/leadstransactions/list/opportunities`;
    } else if (this.current_list_path === '/leads/unused') {
      api_url_path = `/leadstransactions/list/unused`;
    } else if (this.current_list_path === '/leads/deals') {
      api_url_path = `/leadstransactions/list/deals`;
    } else if (this.current_list_path === '/promotion') {
      api_url_path = `/leadstransactions/list`;
    } else if (this.current_list_path === '/management/block-edit-assign') {
      api_url_path = `/leadstransactions/list/management`;
    }

    return Axios.post(api_url_path, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
          // item.email_name = 'a'; // item.email && item.email[0] && item.email[0].contact_value;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  getListSearch = (payload = {}) => {
    this.gridLoading = true;
    let api_url_path = `/leadstransactions/list/active`;

    if (this.current_list_path === '/leads/opportunities') {
      api_url_path = `/leadstransactions/list/opportunities`;
    } else if (this.current_list_path === '/leads/unused') {
      api_url_path = `/leadstransactions/list/unused`;
    } else if (this.current_list_path === '/leads/deals') {
      api_url_path = `/leadstransactions/list/deals`;
    } else if (this.current_list_path === '/promotion') {
      api_url_path = `/leadstransactions/list`;
    } else if (this.current_list_path === '/management/block-edit-assign') {
      api_url_path = `/leadstransactions/list/management`;
    }

    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.LeadsSearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post(api_url_path, payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
          // item.email_name = 'a'; // item.email && item.email[0] && item.email[0].contact_value;

          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at
            ? ageDifferenceInDays(new Date(item.updated_at))
            : 1;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      this.agGrid.api.setSortModel(sort ? sort : temp?.LeadsSearchStore?.sort);

      if (this.list_data?.length === 0) {
        this.agGrid.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem('leads_grid', JSON.stringify(params.columnApi.getColumnState()));
  };
  onGridChangedSearch = (params) => {
    localStorage.setItem(
      'search_leads_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('leads_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        //var filter_data = params.request.filterModel;
        // if (filter_data['lead_owner_name.full_name']) {
        //   filter_data['lead_owner_name.full_name'].values = convertTextToID(
        //     filter_data['lead_owner_name.full_name'],
        //     this.dropdown_leads_users_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data['lead_holder_name.full_name']) {
        //   filter_data['lead_holder_name.full_name'].values = convertTextToID(
        //     filter_data['lead_holder_name.full_name'],
        //     this.dropdown_leads_users_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data['people.full_name']) {
        //   filter_data['people.full_name'].values = convertTextToID(
        //     filter_data['people.full_name'],
        //     this.dropdown_people_list,
        //     'people',
        //     'id'
        //   );
        // }
        // if (filter_data['organisation_name.organisation_name']) {
        //   filter_data['organisation_name.organisation_name'].values = convertTextToID(
        //     filter_data['organisation_name.organisation_name'],
        //     this.dropdown_global_org,
        //     'organisation_name',
        //     'id'
        //   );
        // }
        // if (filter_data['lead_status_id.status_name']) {
        //   filter_data['lead_status_id.status_name'].values = convertTextToID(
        //     filter_data['lead_status_id.status_name'],
        //     this.allChildStagesList,
        //     'status_name',
        //     'id'
        //   );
        // }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        let merged = { ...payload, ...this.search_payload, ...this.extraFilter };
        this.setFilter(params.api);
        this.getList(merged).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('leads_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
              this.agGrid.columnApi.applyColumnState({
                state: JSON.parse(columnConfig),
              });
            }
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  getProductList = () => {
    return Axios.post(`products-names`).then(({ data }) => {
      this.product_list = data.data;

      return data;
    });
  };

  getClassificationList = () => {
    return Axios.post(`industry/list`).then(({ data }) => {
      this.classification_role_list = data.data;
      return data;
    });
  };

  getPeopleList = () => {
    return Axios.get(`global/people/dropdown/list`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => { });
      }
      this.dropdown_people_list = data.data;

      return data;
    });
  };

  filterGetPeopleList = () => {
    return Axios.get(`global/people/dropdown/list`).then(({ data }) => {
      let newData = [];
      if (data.data.length) {
        data.data.forEach((item, index) => {
          newData[item.id] = item.people;
        });
      }
      this.filter_people_list = newData;

      return newData;
    });
  };

  getOrganisationList = () => {
    return Axios.get(`organisations/dropdown`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => { });
      }
      this.dropdown_org_list = data.data;

      return data;
    });
  };
  getCurrencyList = () => {
    return Axios.get(`globalcurreny/list`).then(({ data }) => {
      this.dropdown_currency_list = data.data;

      return data;
    });
  };
  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_users_list = data.data;
      return data;
    });
  };

  // Fetch souce list
  getSoldSourceList = () => {
    return Axios.get(`leadstransactions/sold-source-list`).then(({ data }) => {
      this.dropdown_sold_souce_list = data.data;
      return data;
    });
  };

  //For Performance Report
  getReportUsersList = (payload) => {
    return Axios.post(`user/dropdown/list`, payload).then(({ data }) => {
      this.report_dropdown_users_list = data.data;
      return data;
    });
  };

  addLeadData = (data) => {
    return Axios.post(`leadstransactions/add`, data)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      //if (!['photo_url'].includes(key)) {
      formData.append(
        key,
        data[key] !== undefined && data[key] !== null ? data[key] : ''
      );
      //}
    });
    return formData;
  };

  getLeadStatusList = (type = {}) => {
    return Axios.post(`leads-status/status/list`, { lead_record_type: type }).then(
      ({ data }) => {
        this.dropdown_leadstatus_list = data.data;
        // this.dropdown_leadstatus_list_filtered = data.data;
        // let combinedArr = []
        // this.dropdown_leadstatus_list.map((parent_status) => {
        //   let childArr = parent_status.child_leads.map(v => ({ ...v, parent_id: parent_status.id, parent_name: parent_status.status_name }))
        //   combinedArr = [...childArr, ...combinedArr];
        //   return null;
        // })
        // this.dropdown_leadstatus_list_combined = combinedArr;
        return data;
      }
    );
  };
  deleteLeadData = (formdata, SearchPage) => {
    return Axios.post(`leadstransactions/delete/${formdata.id}`, { waste: this.waste })
      .then(({ data }) => {
        if (!SearchPage) {
          if (this.agGrid) {
            this.setupGrid(this.agGrid);
          }
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  RestoreData = (lead_id) => {
    return Axios.get(`leads-transactions/restore/${lead_id}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  editLeadData = (id, formdata, SearchPageEdit) => {
    return Axios.post(`leadstransactions/edit/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid && !SearchPageEdit) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //set Deal Status Update API
  updateLeadStatus = (id, formdata) => {
    return Axios.post(`leads-status/update-status/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        this.getKanbanList();
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getReassignUserList = () => {
    return Axios.get(`leads-status/user-list`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  //set Deal Status Update API
  assignDeal = (id, formdata) => {
    return Axios.post(`deal-desk/update-holder/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        this.getKanbanList();
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getKanbanStatusList = (type) => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      let temparr = data.data.find((x) => x.status_name === type);

      this.kanban_status_list = temparr ? temparr.child_leads : [];
      this.getKanbanList();
      return data;
    });
  };

  getKanbanList = (payload = {}) => {
    let api_url_path = `/leads-transactions/kanban/list/1`;

    if (this.current_list_path === '/leads/opportunities/kanban') {
      api_url_path = `/leads-transactions/kanban/list/2`;
    } else if (this.current_list_path === '/leads/unused/kanban') {
      api_url_path = `/leads-transactions/kanban/list`;
    } else if (this.current_list_path === '/leads/deals/kanban') {
      api_url_path = `/leads-transactions/kanban/list/3`;
    }

    return Axios.post(api_url_path, payload).then(({ data }) => {
      if (data.data && data.data.data && data.data.data.length) {
        data.data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
          item['Title'] = item.id;
          item['Status'] =
            item.lead_status_id &&
            item.lead_status_id.status_name.charAt(0).toUpperCase() +
            item.lead_status_id.status_name.slice(1);
          item.leadstatusname = item.lead_status_id && item.lead_status_id.status_name;
          item.LeadStatusID = item.lead_status_id && item.lead_status_id.id;
        });
      }
      this.kanban_list_data = data && data.data && data.data.data;

      return data.data;
    });
  };

  ImportLeadsFile = (formdata) => {
    return Axios.post(`lead-transaction/import`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ImportLeadsNoteFile = (formdata) => {
    return Axios.post(`lead-notes/import`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ImportOpportunityFile = (formdata) => {
    return Axios.post(`opportunity/import`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  // call api to get all parent organisations list
  getMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.organisation_name = item.organisation_name + ' (' + item.id + ')';
        });
      }
      this.dropdown_global_org = data.data;
      return data;
    });
  };
  // call api to get all parent organisations list
  filterGetMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      let newData = [];
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.organisation_name = item.organisation_name + ' (' + item.id + ')';
          newData[item.id] = item.organisation_name;
        });
      }
      this.filter_org_list = newData;
      return newData;
    });
  };

  TrackTimeData = (data) => {
    return Axios.post(`leads-transactions/investmenthours`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        return Promise.reject(response);
      });
  };
  getChildStatusList = (parentstatus_type) => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      let child_status = [];
      if (data.data && data.data.length > 0) {
        // 2 = opportunity
        // 3 = Deal
        // 1 = Unused
        // 1 = Active
        let parentstatusArr = data.data.filter((e) => e.id === parentstatus_type);
        if (parentstatusArr && parentstatusArr.length > 0) {
          child_status = parentstatusArr.shift().child_leads;
        }
      }
      data.data = child_status;
      this.child_lead_status_list = child_status;

      return data;
    });
  };

  // reset all the server side filters
  // handleReset = () => {
  //   localStorage.removeItem('leads_grid');

  //   this.extraFilter = null;
  //   this.selectedGridFilter = null;

  //   this.agGrid.api.setFilterModel(null);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  // reset all the server side filters
  // Reset : Will reset all filters, sorting, saved columns settings.
  handleReset = () => {
    this.extraFilter = null;
    reset(this.agGrid, "leads_grid", this.setSelectedGridFilter);
  }

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  applyFilterDefault = () => {
    this.extraFilter = null;
    clear(this.agGrid)
  };

  // export leads data as a csv
  exportLeadData = (type = '') => {
    this.exportLoadingIcn = true;
    return Axios.get(`leadstransactions/export/${type}`)
      .then(({ data }) => {
        if (data.data && data.data.csv_link) {
          window.location.href = data.data.csv_link;
        }
        this.exportLoadingIcn = false;
        return data;
      })
      .catch(() => {
        this.exportLoadingIcn = false;
        return Promise.reject([]);
      });
  };

  getLeadsUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`).then(({ data }) => {
      this.dropdown_leads_users_list = data.data;

      return data;
    });
  };
  setLoadkanban = (data) => {
    this.loadkanban = data;
  };

  // Call Audit
  callAudit = (payload) => {
    return Axios.post(`leads-transactions/call-audit/LeadsTransactions`, payload).then(
      ({ data }) => {
        return data;
      }
    );
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          LEADSSTORE: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  // USED FOR PROMOTION AND UNUSED LEADS FILTER DROPDOWN
  getAllChildStatusList = () => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      let childArr = [];
      if (data.data && data.data.length > 0) {
        data.data.forEach((x) => {
          if (x.child_leads) {
            x.child_leads.forEach((y) => {
              if (y) {
                childArr.push(y);
              }
            });
          }
        });
      }
      this.allChildStagesList = childArr;

      return childArr;
    });
  };

  //Used On Waste Tab Change
  resetGridFilterForWaste = () => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));

      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          LEADSSTORE: {
            filter: {},
          },
        })
      );

      this.agGrid.api.setFilterModel({});
    }
  };

  setMasterLeadToMerge = (data) => {
    this.masterLeadToMerge = data;
  };

  handleMergeLead = (data) => {
    return Axios.post(`leadstransactions/merge-lead`, data)
      .then(({ data }) => {
        this.isMergePeople = true;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  onSOLDSearch = (value) => {
    let filteredData = this.dropdown_leadstatus_list_combined;
    filteredData = filteredData.filter((x) => x.status_name.includes(value));
    let updatedData = this.dropdown_leadstatus_list;
    updatedData.map((obj) => {
      obj.child_leads = filteredData.filter((x) => x.parent_id === obj.id);
      return null;
    });
    this.dropdown_leadstatus_list_filtered = updatedData;
    return true;
  };

  /** This is called on clear button :: DEFAULT-R */
  // applyFilterDefault = () => {
  //   this.selectedGridFilter = null;
  //   if (this.agGrid) {
  //     // localStorage.removeItem('leads_grid');
  //     this.agGrid.api.setFilterModel(null);
  //     this.agGrid.api.setSortModel(null);
  //     // this.agGrid.columnApi.resetColumnState();
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };

  // Call Email Audit
  callEmailAudit = (payload) => {
    return Axios.post(`leads-transactions/email-audit/LeadsTransactions`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        this.exportLoadingIcn = false;
        return Promise.reject([]);
      });
  };


  // API to update all kind of reason and summary 
  updateReasonAndDummary = (id, data) => {
    return Axios.post(`deal-desk/update-summary/${id}`, data)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
          alert("Done")
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
}

decorate(LeadsStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  current_list_path: observable,
  agGrid: observable,
  deleteValues: observable,
  editValues: observable,
  cloneValues: observable,
  viewValues: observable,
  classification_role_list: observable,
  dropdown_people_list: observable,
  dropdown_org_list: observable,
  dropdown_currency_list: observable,
  dropdown_users_list: observable,
  report_dropdown_users_list: observable,
  dropdown_leadstatus_list: observable,
  dropdown_leadstatus_list_combined: observable,
  dropdown_leadstatus_list_filtered: observable,
  dropdown_unusedleadstatus_list: observable,
  kanban_status_list: observable,
  kanban_list_data: observable,
  ignore_unusedlead_id: observable,
  initialValues: observable,
  waste: observable,
  dropdown_global_org: observable,
  search_payload: observable,
  child_lead_status_list: observable,
  extraFilter: observable,
  exportLoadingIcn: observable,
  product_list: observable,
  dropdown_leads_users_list: observable,
  loadkanban: observable,
  call_audit_list: observable,
  selectedGridFilter: observable,
  allChildStagesList: observable,
  masterLeadToMerge: observable,
  oldConnectionList: observable,
  leadSourceArray: observable,

  filter_people_list: observable,
  filter_org_list: observable,
  gridLoading: observable,
  isMergePeople: observable,
  dropdown_sold_souce_list: observable,

  setPageSize: action,
  onGridChanged: action,
  getList: action,
  setupGrid: action,
  getListSearch: action,
  setupGridSearch: action,
  onGridChangedSearch: action,

  setDeleteValues: action,
  setEditValues: action,
  setCloneValues: action,
  getClassificationList: action,
  getPeopleList: action,
  getOrganisationList: action,
  getCurrencyList: action,
  getUsersList: action,
  addLeadData: action,
  JsonToFormData: action,
  getLeadStatusList: action,
  deleteLeadData: action,
  editLeadData: action,
  setViewValues: action,
  updateLeadStatus: action,
  getReassignUserList: action,
  assignDeal: action,
  getKanbanStatusList: action,
  getKanbanList: action,
  RestoreData: action,
  ImportLeadsFile: action,
  ImportLeadsNoteFile: action,
  getMappedOrg: action,
  TrackTimeData: action,
  getChildStatusList: action,
  handleReset: action,
  exportLeadData: action,
  setLoadkanban: action,
  getProductList: action,
  callAudit: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  getAllChildStatusList: action,
  resetGridFilterForWaste: action,
  setMasterLeadToMerge: action,
  handleMergeLead: action,
  setoldConnectionList: action,
  filterGetPeopleList: action,
  filterGetMappedOrg: action,
  onSOLDSearch: action,
  applyFilterDefault: action,
  callEmailAudit: action,
  getSoldSourceList: action,
  updateReasonAndDummary: action,
});
