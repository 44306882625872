import React from 'react';
import { Button, Tooltip } from 'antd';
//import SwitchComponent from './SwitchComponent';
//import { DateComparator } from '../../../../utils/GlobalFunction';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';


export const ActionRenderer = (props) => {
  const {t} = useTranslation();
  const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents;
  const { AUTH } = useStore();
  return (
    <div className='action-column'>
      <>
        {AUTH.checkPermission(24, 'edit') && (
          <Button
            title={`${t('DealBuilder.Edit_Transaction')}`}
            disabled={props.data.field_type === 'DB Column'}
            type='text'
            onClick={() => {
              openEditModal(props.data);
            }}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
          </Button>
        )}
        {props.data && props.data.status === true
          ? AUTH.checkPermission(24, 'delete') && (
            <Tooltip
              placement='topRight'
              color={'red'}
              title={`${t('DealBuilder.Edit_Transaction_Tooltip')}`
             }
            >
              <Button disabled type='text'>
                <img
                  className='menuicon disabled'
                  alt=''
                  src={AUTH.GetThemedImage('Waste')}
                />
              </Button>
            </Tooltip>
          )
          : props.data && props.data.association_count > 0
            ? AUTH.checkPermission(24, 'delete') && (
              <Tooltip
                placement='topRight'
                color={'red'}
                title={`${t('DealBuilder.Association_Count_Tooltip')}`}
                
              >
                <Button disabled type='text'>
                  <img
                    className='menuicon disabled'
                    alt=''
                    src={AUTH.GetThemedImage('Waste')}
                  />
                </Button>
              </Tooltip>
            )
            : AUTH.checkPermission(24, 'delete') && (
              <Button
                title={`${t('DealBuilder.Delete_Transaction')}`}
                disabled={props.data.field_type === 'DB Column'}
                type='text'
                onClick={() => {
                  openDeleteModal(props.data);
                }}
              >
                <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            )}
      </>
    </div>
  );
};


/*export const gridOptions = ()=>{
  //const {t} = useTranslation()

   return  [
    {
      headerName: '#',
      valueGetter: function (params) { return params.node.rowIndex + 1; },
      tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
      cellClass: 'cellClass',
      pinned: 'left',
      filter: false,
      sortable: false,
      width: 60,
    },
    {
      headerName: `${('DealBuilder.Field_Name')}`,
      field: 'field_name',
      cellRenderer: 'fieldnameTooltip',

    },
    {
      headerName: `${('DealBuilder.Field_Type')}`,
      field: 'field_type',
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,
      },

    },
    {
      headerName: `${('DealBuilder.Algorithm_Name')}`,
      field: 'algorithm_name',

    },
    {
      headerName: `${('DealBuilder.Default_Value')}`,
      field: 'default_value',
      valueGetter: (params) => {
        if (params.data && params.data.default_value) {
          const rpg_values = []
          if (params.data.rpa_group) {
            const default_values = params.data.default_value.replace(/^#+|#+$/gm, '').split('#')
            params.data.rpa_group.replace(/^#+|#+$/gm, '').split('#').forEach((x, index) => {
              rpg_values.push({ rpa_group: x, default_value: default_values[index] })
            })
          }
          return rpg_values.map((x) => x.rpa_group + ' - ' + x.default_value).join(', ')
        }

        return '-'
      },
      filter: false,
    },
    {
      headerName: `${('DealBuilder.Description')}`,
      field: 'description',
      tooltipField: 'description',

    },
    {
      headerName: `${('Common_Data.Last_Updated_By')}`,
      field: 'updated_by',
      filter: 'agSetColumnFilter',
      filterParams: {
        defaultToNothingSelected: true,
      },

    },
    {
      headerName: `${('Common_Data.Last_Updated_On')}`,
      field: 'updated_at',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        comparator: DateComparator,
      },
      width: 220,

    },
    {
      headerName: `${('DealBuilder.Published')}`,
      cellRendererFramework: function (data) {
        const { onSwitchChange } = data.agGridReact.props;
        return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />;
      },
      field: 'published',
      sortable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Yes', 'No'],
        defaultToNothingSelected: true,
      },

    },
    {
      headerName: `#${('Common_Data.ID')}`,
      field: 'id',
      filter: 'agNumberColumnFilter',

    },
    {
      headerName: `${('Common_Data.Actions')}`,
      field: 'actions',
      type: 'actionColumn',
      cellClass: 'cellClass actionColumn',
      sortable: false,
      filter: false,
      pinned: 'right',

    },
  ]
};*/
