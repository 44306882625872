import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Card, Empty, Modal, Spin } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import ListComponent from './ListComponent';
import EditComponent from './EditComponent';
import useStore from '../../../store';
import FileManager from '../../../page/FileManager';
import { useTranslation } from 'react-i18next';

const ConnectionsComponent = observer((props) => {
  const {
    AUTH,
    DEALDESKSTORE: { deal_data, leadFileFolderList, fetchFileFolderList },
  } = useStore();
  const { t } = useTranslation()
  const [openFileModal, setOpenFileModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const SanpNoteTitle = () => {
    return (
      <>
        {t('FoldersFileModule.Folders')}
        <span className='TitleValue'>0</span>
        <span style={{ width: '1rem' }}></span>
        {t('FoldersFileModule.Files')}
        <span className='TitleValue'>
          {leadFileFolderList ? leadFileFolderList.length : 0}
        </span>
      </>
    );
  };

  const addFiles = () => {
    setOpenFileModal(true);
  };

  const editFiles = (data) => {
    setEditModal(true);
  };
  useEffect(() => {
    if (deal_data?.lead_information?.id) {
      fetchFileFolderList(deal_data?.lead_information?.id).then((data) => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [deal_data, fetchFileFolderList]);

  return (
    <>
      {AUTH.app_color_theme && (
        <Spin size='medium' spinning={loading} tip={`${t('Common_Data.Load')}`}>
          <Card
            size='small'
            title={SanpNoteTitle()}
            className='nopadding'
            extra={<PlusCircleOutlined className='bar_icon' onClick={editFiles} />}
          >
            <ListComponent leadFileFolderList={leadFileFolderList} />

            <div className='text-center'>
              {!leadFileFolderList && (
                <Empty
                  className={'rightSectionFixHeight'}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={`${t('Common_Data.No')} ${t('FoldersFileModule.Files')}`}
                />
              )}

              <button
                className='RigthBarBtn'
                shape='round'
                onClick={addFiles}
                disabled={!deal_data.lead_information?.organisation_name?.id}
              >
                <img
                  src={AUTH.GetThemedImage('Folders_icn')}
                  alt={`${t('FoldersFileModule.Manage')}`}
                  className='mr-5 icon'
                />{' '}
                {t('FoldersFileModule.Manage')}
              </button>
            </div>
          </Card>
        </Spin>
      )}
      <Modal
        title={`${t('FoldersFileModule.Manage')} ${t('FoldersFileModule.Files')}`}
        centered
        visible={openFileModal}
        footer={false}
        onOk={() => setOpenFileModal(false)}
        onCancel={() => setOpenFileModal(false)}
        width={1000}
        className='SnapNoteModal'
        destroyOnClose={true}
      >
        <FileManager />
      </Modal>

      <Modal
        title={`${t('Common_Data.Key')} ${t('Common_Data.Documents')}`}
        centered
        visible={editModal}
        footer={false}
        onOk={() => setEditModal(false)}
        onCancel={() => setEditModal(false)}
        width={1000}
        className='SnapNoteModal'
      >
        <EditComponent />
      </Modal>
    </>
  );
});

export default ConnectionsComponent;
