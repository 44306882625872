import { useRef, useEffect } from "react";
// import useStore from "../store";

const useInterval = (callback, delay) => {
    // const { TimeTrackerStore } = useStore();
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };
        // console.log("TimeTrackerStore.isBackPopup", TimeTrackerStore.isBackPopupClicked)
        // console.log("timer", delay ? true : false)
        if (delay) {

            let intervalID = window.setInterval(tick, delay);
            // if (stop) {
            //     window.clearInterval(intervalID);
            // }
            return () => {
                window.clearInterval(intervalID)
                // console.log("called clearinterval", intervalID)
            };
        }
    }, [delay]);
};

export default useInterval;