import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import { Button } from 'antd';
import moment from 'moment';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { DateComparator } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation();
  const { openViewDrawer, dateFilter, selectFilter, AuditLogModal } = props;
  const {
    AUTH,
    AUDITLOG,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    AUDITLOG: { setupGrid, getList, list_data, agGrid, auditGridLoader }
  } = useStore();

  const onBtShowLoading = useCallback(() => {
    if (agGrid) {
      agGrid.api.showLoadingOverlay();
    }
  }, [agGrid]);

  const hideOverlayFunction = useCallback(() => {
    if (agGrid) {
      agGrid.api.hideOverlay();
    }
  }, [agGrid]);

  useEffect(() => {
    if (auditGridLoader) {
      onBtShowLoading();
    } else {
      hideOverlayFunction();
    }
    return () => { };
  }, [auditGridLoader, onBtShowLoading, hideOverlayFunction]);

  useEffect(() => {
    AUDITLOG.list_data = null;
    let payload = {
      user_id: selectFilter,
      date: dateFilter && moment(dateFilter).format('YYYY-MM-DD')
    };
    if (props.auditLeadID) {
      payload.auditable_id = props.auditLeadID;
      payload.filter_data = {
        auditable_id: {
          filterType: 'number',
          type: 'equals',
          filter: payload.auditable_id
        }
      };
      payload.sort_data = [];
      payload.per_page = 500;
      payload.page = 1;
    }
    getList(payload);
  }, [getList, selectFilter, dateFilter, AUDITLOG, props.auditLeadID]);

  const ActionRendererAuditLog = (props) => {
    return (
      <div className='action-column'>
        {AUTH.checkPermission(31, 'view-details') && (
          <Button
            type='text'
            title={`${t('Audit_Logs.Profile')}`}
            onClick={() => {
              openViewDrawer(props.data.id);
            }}
          >
            <img alt={`${t('Audit_Logs.View')}`} className='menuicon' src={AUTH.GetThemedImage('View')} />
          </Button>)}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('Audit_Logs.User_Type')}`,
        field: 'user_type',

      },
      {
        headerName: `${t('Audit_Logs.User_Name')}`,
        field: 'userfullname',

      },
      {
        headerName: `${t('Audit_Logs.Event')}`,
        field: 'event',

      },
      {
        headerName: `${t('Audit_Logs.Audit_Type')}`,
        field: 'auditable_type',

      },
      {
        headerName: `${t('Audit_Logs.Audit_ID')}`,
        field: 'auditable_id',

      },
      {
        headerName: `${t('Audit_Logs.URL')}`,
        field: 'url',

      },
      {
        headerName: `${t('Audit_Logs.IPaddress')}`,
        field: 'ip_address',

      },
      {
        headerName: `${t('Audit_Logs.User_Agent')}`,
        field: 'user_agent',

      },
      {
        headerName: `${t('Audit_Logs.Tags')}`,
        field: 'tags',

      },

      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator
        },
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params.data.updated_at,AUTH.global_fulldate_format);
        },

      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',

        maxWidth: 100
        // lockPosition: true,
        // suppressNavigable: true
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: 110,
        filter: false,
        sortable: false,
        pinned: 'right',
        lockPosition: true,
        suppressNavigable: true
      }
    ]
  };

  let columns = [];
  let columnConfig = localStorage.getItem('audit_log_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data.forEach((element) => {
      cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
      if (cols) {
        columns.push(cols);
      }
    });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  })

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper p-reletive'
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : AuditLogModal
              ? '500px'
              : 'calc(100vh - 15rem)'
        }}
      >
        <AgGridReact
          rowData={list_data}
          //rowData={rowData}
          modules={AllModules}
          columnDefs={withTooltipCols}
          defaultColDef={LocalGridConfig.defaultColDef}
          //columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRendererAuditLog,
            openViewDrawer
          }}
          onGridReady={setupGrid}
          onColumnResized={AUDITLOG.onGridChanged}
          onColumnMoved={AUDITLOG.onGridChanged}
          onColumnPinned={AUDITLOG.onGridChanged}
          onFilterChanged={AUDITLOG.onFilterChanged}
          onSortChanged={AUDITLOG.onGridChanged}
          gridOptions={{
            ...LocalGridConfig.options,
            onRowDoubleClicked: (event) => { AUTH.checkPermission(31, 'view-details') && openViewDrawer(event?.data?.id) },
            pagination: true,
            rowHeight: 30,
            columnTypes: {
              actionColumn: {
                cellRenderer: 'ActionRendererAuditLog'
              }
            }
          }}
          rowSelection='multiple'
          suppressRowClickSelection={true}
        />

        {list_data?.length === 10000 ? (

          <div className='redText noteAtTop text-right'>
            {t('Common_Data.Note')}: {t('Audit_Logs.Listing_Maximum')} 10,000 {t('Audit_Logs.Records')}.
          </div>
        ) : null}

      </div>



    </>
  );
});

export default ListComponent;
