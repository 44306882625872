import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Spin, Select } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmLeadsList } from '../../../config/messages';
import PhoneElement from '../elements/PhoneElement';
import EmailElement from '../elements/EmailElement';
import debounce from 'lodash/debounce';

const { Option, OptGroup } = Select;

const FormComponent = observer(
  ({
    handleSubmit,
    form,
    onChange,
    props,
    visible,
    id,
    formid,
    openAddModal,
    openAddOrgModal,
    openEditPeopleDrawer,
    openEditOrgDrawer,
  }) => {
    const {
      AUTH,
      PEOPLE,
      LEADSSTORE,
      ORGANISATION,
      LEADSSTORE: {
        ignore_unusedlead_id,
        classification_role_list,
        dropdown_currency_list,
        dropdown_users_list,
        dropdown_leadstatus_list,
        getCurrencyList,
        getUsersList,
        getClassificationList,
        getLeadStatusList,
        getProductList,
      },
    } = useStore();

    const [fetchClassification, setFetchClassification] = useState(true);
    const [fetchCurrencyList, setFetchCurrencyList] = useState(true);
    const [fetchUsersList, setFetchUsersList] = useState(true);
    const [fetchLeadstatusList, setFetchLeadstatusList] = useState(true);
    const [fetchProductList, setFetchProductList] = useState(true);
    const [searchPplKey, setSearchPplKey] = useState();
    const [searchOrgKey, setSearchOrgKey] = useState();
    const [showOrgEditBtn, setShowOrgEditBtn] = useState(false);
    const [showPeopleEditBtn, setShowPeopleEditBtn] = useState(false);

    const onFinish = (data) => {
      handleSubmit(data);
    };

    const handleSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchPplKey(val);
        PEOPLE.getAllPeople({ search_for: val, mapping_type: 2 });
      };

      return debounce(loadOptions, 500);
    }, [PEOPLE]);

    // search string for dropdown
    const handleOrgSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchOrgKey(val);
        ORGANISATION.getAllROrg({ search_for: val, mapping_type: 1 });
      };

      return debounce(loadOptions, 500);
    }, [ORGANISATION]);

    return (
      <>
        <Form
          form={form}
          //labelCol={{ span: 24 }}
          layout='vertical'
          id={formid}
          onFinish={onFinish}
          className='innerForm'
        >
          <Row gutter={15}>
            {(LEADSSTORE.editValues &&
              LEADSSTORE.editValues.lead_status_id.parent_status_id === 2) ||
              (LEADSSTORE.cloneValues &&
                LEADSSTORE.cloneValues.lead_status_id.parent_status_id === 2) ? (
              <>
                <Col span={6}>
                  <InputComponent
                    name='lead_record_type'
                    label='SOLD Record Type'
                    placeholder='Select Record Type'
                    required
                    type='select'
                    tooltip='SOLD Record types help you differentiate between sales solds and registered deals, assign specific page layouts for different processes, and simplify reporting for each type of record.'
                    onChange={onChange}
                    allowClear
                    showArrow
                    showSearch
                    options={{
                      values: [
                        { value: 'Agent', text: 'Agent' },
                        { value: 'Investor', text: 'Investor' },
                        { value: 'Intermediary', text: 'Intermediary' },
                        { value: 'Licensee', text: 'Licensee' },
                        { value: 'Leasee', text: 'Leasee' },
                        { value: 'Originator', text: 'Originator' },
                        { value: 'Pension Trustee', text: 'Pension Trustee' },
                      ],
                    }}
                    rules={vsmLeadsList.validation.lead_record_type}
                  />
                </Col>
                <Col span={6}>
                  <InputComponent
                    name='opportunity_name'
                    label='Opportunity Name'
                    placeholder='Opportunity Name'
                    type='text'
                    tooltip='Use a part of the Organisation name to create a memorable Opportunity name.'
                    onChange={onChange}
                    maxLength={100}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col span={12}>
                  <InputComponent
                    name='lead_record_type'
                    label='SOLD Record Type'
                    placeholder='Select Record Type'
                    required
                    type='select'
                    tooltip='SOLD Record types help you differentiate between sales solds and registered deals, assign specific page layouts for different processes, and simplify reporting for each type of record.'
                    onChange={onChange}
                    allowClear
                    showArrow
                    showSearch
                    options={{
                      values: [
                        { value: 'Agent', text: 'Agent' },
                        { value: 'Investor', text: 'Investor' },
                        { value: 'Intermediary', text: 'Intermediary' },
                        { value: 'Licensee', text: 'Licensee' },
                        { value: 'Leasee', text: 'Leasee' },
                        { value: 'Originator', text: 'Originator' },
                        { value: 'Pension Trustee', text: 'Pension Trustee' },
                      ],
                    }}
                    rules={vsmLeadsList.validation.lead_record_type}
                  />
                </Col>
              </>
            )}

            <Col span={12}>
              <InputComponent
                name='classification_id'
                placeholder='Select Classification'
                label={<span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  Credebt® Classification
                </span>}
                required
                type='select'
                // mode='multiple'
                allowClear
                showArrow
                showSearch
                onChange={onChange}
                options={{
                  values:
                    classification_role_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.classifications && [
                        LEADSSTORE.editValues.classifications,
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.classifications && [
                        LEADSSTORE.cloneValues.classifications,
                      ]),
                  value_key: 'id',
                  text_key: 'name',
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
                onFocus={() =>
                  fetchClassification &&
                  getClassificationList().then(() => setFetchClassification(false))
                }
                notFoundContent={
                  fetchClassification ? <Spin size='small' /> : 'No Record Found.'
                }
                tooltip='Classification is a group of productive enterprises or organisations that produce or supply goods, services, or sources of income. '
                rules={vsmLeadsList.validation.classification_id}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label='Full Name'
                type='labelOnly'
                required
                tooltip={`A personal Name that is given to user/ person at birth or baptism and used before a family name. It includes the Last Name of the family to which a person/ user is born.`}
              />
              <div className='suffixIcon'>
                <InputComponent
                  name='full_name'
                  placeholder='Select Name'
                  required
                  type='select'
                  showSearch={true}
                  onSearch={handleSearch}
                  allowClear
                  onChange={() => {
                    if (form.getFieldValue('full_name')) {
                      setShowPeopleEditBtn(true);
                    } else {
                      setShowPeopleEditBtn(false);
                    }
                    onChange();
                  }}
                  notFoundContent={
                    PEOPLE.fetching ? (
                      <Spin size='small' />
                    ) : searchPplKey ? (
                      'No Record Found.'
                    ) : null
                  }
                  options={{
                    values:
                      PEOPLE.drowpdown_people_list ||
                      (LEADSSTORE.editValues &&
                        LEADSSTORE.editValues.people && [
                          {
                            id: LEADSSTORE.editValues.people.id,
                            entity_name:
                              (LEADSSTORE.editValues.people.first_name
                                ? LEADSSTORE.editValues.people.first_name
                                : '') +
                              ' ' +
                              (LEADSSTORE.editValues.people.last_name
                                ? LEADSSTORE.editValues.people.last_name
                                : ''),
                          },
                        ]) ||
                      (LEADSSTORE.cloneValues &&
                        LEADSSTORE.cloneValues.people && [
                          {
                            id: LEADSSTORE.cloneValues.people.id,
                            entity_name:
                              (LEADSSTORE.cloneValues.people.first_name
                                ? LEADSSTORE.cloneValues.people.first_name
                                : '') +
                              ' ' +
                              (LEADSSTORE.cloneValues.people.last_name
                                ? LEADSSTORE.cloneValues.people.last_name
                                : ''),
                          },
                        ]),
                    value_key: 'id',
                    text_key: 'entity_name',
                    // rejected_keys: LEADSSTORE.editValues && [
                    //   LEADSSTORE.editValues.id,
                    // ],
                  }}
                  rules={vsmLeadsList.validation.full_name}
                />
                {AUTH.checkPermission(18, 'add') && !id ? (
                  <PlusCircleOutlined className='extraFromIcon' onClick={openAddModal} />
                ) : null}
                {AUTH.checkPermission(18, 'edit') && !id ? (
                  form.getFieldValue('full_name') ||
                    LEADSSTORE.editValues?.people ||
                    showPeopleEditBtn ? (
                    <EditOutlined
                      className='extraFromIcon'
                      onClick={openEditPeopleDrawer}
                    />
                  ) : null
                ) : null}
              </div>
            </Col>
            <Col span={12}>
              <InputComponent
                label='Organisation Name'
                type='labelOnly'
                required
                tooltip={`Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name).`}
              />
              <div className='suffixIcon'>
                <InputComponent
                  name='organisation_name'
                  placeholder='Select Organisation'
                  required
                  allowClear
                  type='select'
                  onChange={() => {
                    if (form.getFieldValue('organisation_name')) {
                      setShowOrgEditBtn(true);
                    } else {
                      setShowOrgEditBtn(false);
                    }
                    onChange();
                  }}
                  onSearch={handleOrgSearch}
                  notFoundContent={
                    ORGANISATION.fetching ? (
                      <Spin size='small' />
                    ) : searchOrgKey ? (
                      'No Record Found.'
                    ) : null
                  }
                  options={{
                    values:
                      ORGANISATION.dropdown_ROrg_list ||
                      (LEADSSTORE.editValues &&
                        LEADSSTORE.editValues.organisation_name && [
                          {
                            id: LEADSSTORE.editValues.organisation_name.id,
                            entity_name:
                              LEADSSTORE.editValues.organisation_name.organisation_name,
                          },
                        ]) ||
                      (LEADSSTORE.cloneValues &&
                        LEADSSTORE.cloneValues.organisation_name && [
                          {
                            id: LEADSSTORE.cloneValues.organisation_name.id,
                            entity_name:
                              LEADSSTORE.cloneValues.organisation_name.organisation_name,
                          },
                        ]),

                    value_key: 'id',
                    text_key: 'entity_name',
                  }}
                  rules={vsmLeadsList.validation.organisation_name}
                />

                {AUTH.checkPermission(16, 'add') ? (
                  <PlusCircleOutlined
                    className='extraFromIcon'
                    onClick={openAddOrgModal}
                  />
                ) : null}

                {AUTH.checkPermission(16, 'edit') ? (
                  form.getFieldValue('organisation_name') ||
                    LEADSSTORE.editValues?.organisation_name ||
                    showOrgEditBtn ? (
                    <EditOutlined
                      className='extraFromIcon'
                      onClick={() => openEditOrgDrawer()}
                    />
                  ) : null
                ) : null}
              </div>
            </Col>

            <Col span={12}>
              <InputComponent
                name='product_name'
                label='Product'
                placeholder='Product'
                type='select'
                onChange={onChange}
                allowClear
                showArrow
                showSearch
                tooltip='The name that a business, company or enterprise chooses to give a product created.'
                rules={vsmLeadsList.validation.product_name}
                onFocus={() =>
                  fetchProductList &&
                  getProductList().then(() => setFetchProductList(false))
                }
                notFoundContent={
                  fetchProductList ? <Spin size='small' /> : 'No Record Found.'
                }
                options={{
                  values:
                    LEADSSTORE.product_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.product_name && [
                        { product_name: LEADSSTORE.editValues.product_name },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.product_name && [
                        { product_name: LEADSSTORE.cloneValues.product_name },
                      ]),
                  value_key: 'product_name',
                  text_key: 'product_name',
                }}
              />
            </Col>

            <Col span={12}>
              <InputComponent
                name='lead_source'
                label='SOLD Source'
                placeholder='Select Source'
                type='select'
                onChange={onChange}
                allowClear
                showArrow
                showSearch
                tooltip='A sold source is what turns a prospect into a potential customer.'
                options={{
                  values: [
                    { value: 'None', text: 'None' },
                    { value: 'Accountant', text: 'Accountant' },
                    { value: 'Advertisement', text: 'Advertisement' },
                    { value: 'AdWords', text: 'AdWords' },
                    { value: 'Agent', text: 'Agent' },
                    { value: 'Cold Call', text: 'Cold Call' },
                    {
                      value: 'Credebt® Employee',
                      text: 'Credebt® Employee',
                    },
                    {
                      value: 'Credebt® Website',
                      text: 'Credebt® Website',
                    },
                    {
                      value: 'Existing Originator',
                      text: 'Existing Originator',
                    },
                    {
                      value: 'Financial Broker',
                      text: 'Financial Broker',
                    },
                    {
                      value: 'Friend/acquaintance',
                      text: 'Friend/acquaintance',
                    },
                    { value: 'Internet Search', text: 'Internet Search' },
                    { value: 'List', text: 'List' },
                    { value: 'Miscellaneous', text: 'Miscellaneous' },
                    { value: 'Other', text: 'Other' },
                    { value: 'Other Website', text: 'Other Website' },
                    {
                      value: 'Recruitment/HR company',
                      text: 'Recruitment/HR company',
                    },
                    { value: 'Referral', text: 'Referral' },
                    { value: 'Research', text: 'Research' },
                    { value: 'Trade Show', text: 'Trade Show' },
                  ],
                }}
              />
            </Col>
            <Col span={12}>
              {LEADSSTORE.editValues ? (
                <>
                  <Form.Item
                    label='SOLD Stage'
                    required
                    tooltip='The SOLD stage field is often a source of contention between Marketing and Sales within an organization. The field is typically used to communicate the status of a record to both the reporting users and the sales rep who is working the sold'
                  >
                    <Form.Item
                      name='lead_status_id'
                      noStyle
                      rules={vsmLeadsList.validation.lead_status_id}
                    >
                      <Select
                        className='w100'
                        defaultValue='1'
                        onChange={onChange}
                        allowClear
                        showArrow
                        showSearch
                        onFocus={() =>
                          fetchLeadstatusList &&
                          getLeadStatusList().then(() => setFetchLeadstatusList(false))
                        }
                        notFoundContent={
                          fetchLeadstatusList ? <Spin size='small' /> : 'No Record Found.'
                        }
                      >
                        {dropdown_leadstatus_list && dropdown_leadstatus_list.length
                          ? dropdown_leadstatus_list.map((parent_status) => (
                            <OptGroup
                              key={parent_status.id}
                              label={parent_status.status_name}
                            >
                              {parent_status.child_leads.map((child_status) => (
                                <Option value={child_status.id} key={child_status.id}>
                                  {child_status.status_name}
                                </Option>
                              ))}
                            </OptGroup>
                          ))
                          : LEADSSTORE.editValues &&
                          LEADSSTORE.editValues.lead_status_id && (
                            <Option value={LEADSSTORE.editValues.lead_status_id.id}>
                              {LEADSSTORE.editValues.lead_status_id.status_name}
                            </Option>
                          )}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label='SOLD Stage'
                    required
                    tooltip='The deal stage field is often a source of contention between Marketing and Sales within an organization. The field is typically used to communicate the status of a record to both the reporting users and the sales rep who is working the sold'
                  >
                    <Form.Item
                      name='lead_status_id'
                      noStyle
                      rules={vsmLeadsList.validation.lead_status_id}
                      initialValue={6}
                    >
                      <Select
                        className='w100'
                        onChange={onChange}
                        allowClear
                        showArrow
                        showSearch
                        onFocus={() =>
                          fetchLeadstatusList &&
                          getLeadStatusList().then(() => setFetchLeadstatusList(false))
                        }
                        notFoundContent={
                          fetchLeadstatusList ? <Spin size='small' /> : 'No Record Found.'
                        }
                      >
                        {dropdown_leadstatus_list && dropdown_leadstatus_list.length
                          ? dropdown_leadstatus_list.map((parent_status) => (
                            <OptGroup
                              key={parent_status.id}
                              label={parent_status.status_name}
                            >
                              {parent_status.child_leads
                                .filter(
                                  (item) =>
                                    item.id !== ignore_unusedlead_id ||
                                    item.status_name !== 'Unused'
                                )
                                .map((child_status) => (
                                  <Option value={child_status.id} key={child_status.id}>
                                    {child_status.status_name}
                                  </Option>
                                ))}
                            </OptGroup>
                          ))
                          : LEADSSTORE.editValues &&
                          LEADSSTORE.editValues.lead_status_id && (
                            <Option value={LEADSSTORE.editValues.lead_status_id.id}>
                              {LEADSSTORE.editValues.lead_status_id.status_name}
                            </Option>
                          )}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </>
              )}
            </Col>
            <Col span={12}>
              <InputComponent
                name='deadline_date'
                label='Deadline Date'
                type='date'
                placeholder='Select Date'
                tooltip='A date or time before which something must be done.'
                onChange={onChange}
              />
            </Col>

            <Col span={12}>
              <InputComponent
                name='lead_owner_id'
                label='SOLD Owner'
                type='select'
                allowClear
                showArrow
                showSearch
                placeholder='Select Owner'
                tooltip='A SOLD Owner is someone who owns the record when it is a SOLD in the system.'
                onChange={onChange}
                onFocus={() =>
                  fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
                }
                notFoundContent={
                  fetchUsersList ? <Spin size='small' /> : 'No Record Found.'
                }
                options={{
                  values:
                    dropdown_users_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.lead_owner_name && [
                        {
                          id: LEADSSTORE.editValues.lead_owner_name.id,
                          name:
                            (LEADSSTORE.editValues.lead_owner_name.first_name
                              ? LEADSSTORE.editValues.lead_owner_name.first_name
                              : '') +
                            ' ' +
                            (LEADSSTORE.editValues.lead_owner_name.last_name
                              ? LEADSSTORE.editValues.lead_owner_name.last_name
                              : ''),
                        },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.lead_owner_name && [
                        {
                          id: LEADSSTORE.cloneValues.lead_owner_name.id,
                          name:
                            (LEADSSTORE.cloneValues.lead_owner_name.first_name
                              ? LEADSSTORE.cloneValues.lead_owner_name.first_name
                              : '') +
                            ' ' +
                            (LEADSSTORE.cloneValues.lead_owner_name.last_name
                              ? LEADSSTORE.cloneValues.lead_owner_name.last_name
                              : ''),
                        },
                      ]),

                  value_key: 'id',
                  text_key: 'name',
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                name='lead_holder_id'
                label='SOLD Holder'
                type='select'
                allowClear
                showArrow
                showSearch
                placeholder='Select Holder'
                tooltip='The Deal Holder may be the Deal Owner, or it can be another user.'
                onChange={onChange}
                onFocus={() =>
                  fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
                }
                notFoundContent={
                  fetchUsersList ? <Spin size='small' /> : 'No Record Found.'
                }
                options={{
                  values:
                    dropdown_users_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.lead_holder_name && [
                        {
                          id: LEADSSTORE.editValues.lead_holder_name.id,
                          name:
                            (LEADSSTORE.editValues.lead_holder_name.first_name
                              ? LEADSSTORE.editValues.lead_holder_name.first_name
                              : '') +
                            ' ' +
                            (LEADSSTORE.editValues.lead_holder_name.last_name
                              ? LEADSSTORE.editValues.lead_holder_name.last_name
                              : ''),
                        },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.lead_holder_name && [
                        {
                          id: LEADSSTORE.cloneValues.lead_holder_name.id,
                          name:
                            (LEADSSTORE.cloneValues.lead_holder_name.first_name
                              ? LEADSSTORE.cloneValues.lead_holder_name.first_name
                              : '') +
                            ' ' +
                            (LEADSSTORE.cloneValues.lead_holder_name.last_name
                              ? LEADSSTORE.cloneValues.lead_holder_name.last_name
                              : ''),
                        },
                      ]),

                  value_key: 'id',
                  text_key: 'name',
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                name='finance_value'
                label='Finance Value'
                placeholder='Finance Value'
                // type='number'
                min={1}
                tooltip='The maximum amount an individual or company may borrow to buy securities on margin on a certain amount of collateral.'
                onChange={onChange}
                rules={vsmLeadsList.validation.finace_value}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                name='turn_over'
                label='Turn over'
                placeholder='Turn over'
                // type='number'
                min={1}
                tooltip='Turnover is the net sales generated by a business, while profit is the residual earnings of a business after all expenses have been charged against net sales.'
                onChange={onChange}
                rules={vsmLeadsList.validation.turn_over}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                name='lead_currency_id'
                label='SOLD Currency'
                required
                allowClear
                showArrow
                showSearch
                placeholder='Select Currency'
                type='select'
                tooltip='Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger.'
                onChange={onChange}
                onFocus={() =>
                  fetchCurrencyList &&
                  getCurrencyList().then(() => setFetchCurrencyList(false))
                }
                notFoundContent={
                  fetchCurrencyList ? <Spin size='small' /> : 'No Record Found.'
                }
                options={{
                  values:
                    dropdown_currency_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.lead_currency_id && [
                        {
                          id: LEADSSTORE.editValues.lead_currency_id.id,
                          currency_name:
                            LEADSSTORE.editValues.lead_currency_id.currency_name,
                        },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.lead_currency_id && [
                        {
                          id: LEADSSTORE.cloneValues.lead_currency_id.id,
                          currency_name:
                            LEADSSTORE.cloneValues.lead_currency_id.currency_name,
                        },
                      ]),

                  value_key: 'id',
                  text_key: 'currency_name',
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
                rules={vsmLeadsList.validation.lead_currency_id}
              />
            </Col>
            {LEADSSTORE.editValues ? (
              <Col span={12}>
                <InputComponent
                  disabled
                  label='Time Spent'
                  name='investment_hours'
                  placeholder='Time Spent'
                  tooltip='Hours invested by all users for this SOLD'
                />
              </Col>
            ) : null}

            <Col span={24}>
              <InputComponent
                name='lead_source_details'
                label='SOLD Source Details'
                placeholder='SOLD Source Details'
                type='textarea'
                onChange={onChange}
                tooltip='SOLD Source Details field is a picklist field which records where solds came from.'
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label={`Phone (Note: To add extension use ext keyword above i.e : +353 x xxx-xxxx, ext. xxxx)`}
                tooltip='Assign Shortcut Keys is used to allocate specific keys to specific actions.'
                type='labelOnly'
              //required
              />
              <PhoneElement onChange={onChange} form={form} />
            </Col>
            <Col span={12}>
              <InputComponent
                label='Email ID'
                type='labelOnly'
                tooltip='Email Type is the type, that would be associated with the Email(Eg.: Primary, home, work).'
              //required
              />
              <EmailElement onChange={onChange} form={form} />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
);

export default FormComponent;
