import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import useStore from '../../../store';
import { Form, Spin, Select } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import EnterFullscreen from '../../../component/EnterFullscreen';
import { useTranslation } from 'react-i18next';

const TopSelection = observer(() => {
  const { t } = useTranslation();
  const [fetchClassification, setFetchClassification] = useState(true);
  const [fetchOrgList, setFetchOrgList] = useState(true);
  const [fetchLeadstatusList, setFetchLeadstatusList] = useState(true);

  const { Option, OptGroup } = Select;

  const {
    AUTH,
    LEADSSTORE,
    LEADSSTORE: {
      classification_role_list,
      getClassificationList,
      getLeadStatusList,
      dropdown_leadstatus_list,
    },
    ORGANISATION: { dropdown_global_org, getMappedOrg },
  } = useStore();

  const [form] = Form.useForm();

  const handleSubmit = (data) => {
    data.deadline_date =
      data.deadline_date && moment(data.deadline_date).format(AUTH.global_dateformat);
    LEADSSTORE.getKanbanList(data);
  };

  const onChange = debounce(() => {
    form.submit();
  }, 500);

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        className='innerForm'
        //onChange={onChange}
        onFinish={handleSubmit}
        id='TopSelection'
      >
        <div className='topSelection'>
          <InputComponent
            name='lead_record_type'
            placeholder={`${t('LeadKanbanView.Record_Type')}`}
            type='select'
            onChange={onChange}
            allowClear
            options={{
              values: [
                { value: 'Originator', text: `${t('LeadKanbanView.Originator')}` },
                { value: 'Agent', text: `${t('LeadKanbanView.Agent')}` },
                { value: 'Intermediary', text: `${t('LeadKanbanView.Intermediary')}` },
                { value: 'Investor', text: `${t('LeadKanbanView.Investor')}` },
                { value: 'Leasee', text: `${t('LeadKanbanView.Leasee')}` },
                { value: 'Licensee', text: `${t('LeadKanbanView.Licensee')}` },
                { value: 'Pension Trustee', text: `${t('LeadKanbanView.Pension_Trustee')}` },
              ],
            }}
          />
          <InputComponent
            name='classification_id'
            placeholder={`${t('Common_Data.Classification')}`}
            type='select'
            showArrow
            allowClear
            onChange={onChange}
            options={{
              values:
                classification_role_list ||
                (LEADSSTORE.editValues &&
                  LEADSSTORE.editValues.classifications && [
                    LEADSSTORE.editValues.classifications,
                  ]) ||
                (LEADSSTORE.cloneValues &&
                  LEADSSTORE.cloneValues.classifications && [
                    LEADSSTORE.cloneValues.classifications,
                  ]),
              value_key: 'id',
              text_key: 'name',
            }}
            onFocus={() =>
              fetchClassification &&
              getClassificationList().then(() => setFetchClassification(false))
            }
            notFoundContent={
              fetchClassification ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
          />
          <InputComponent
            name='organisation_name'
            placeholder={`${t('Common_Data.Organisation')}`}
            type='select'
            allowClear
            onChange={onChange}
            onFocus={() =>
              fetchOrgList && getMappedOrg().then(() => setFetchOrgList(false))
            }
            notFoundContent={fetchOrgList ? <Spin size='small' /> : null}
            options={{
              values:
                dropdown_global_org ||
                (LEADSSTORE.editValues &&
                  LEADSSTORE.editValues.organisation_name && [
                    {
                      id: LEADSSTORE.editValues.organisation_name.id,
                      organisation_name:
                        LEADSSTORE.editValues.organisation_name.organisation_name,
                    },
                  ]) ||
                (LEADSSTORE.cloneValues &&
                  LEADSSTORE.cloneValues.organisation_name && [
                    {
                      id: LEADSSTORE.cloneValues.organisation_name.id,
                      organisation_name:
                        LEADSSTORE.cloneValues.organisation_name.organisation_name,
                    },
                  ]),

              value_key: 'id',
              text_key: 'organisation_name',
            }}
          />
          <Form.Item
          // label='SOLD Stage'
          // required
          // tooltip='The deal stage field is often a source of contention between Marketing and Sales within an organization. The field is typically used to communicate the status of a record to both the reporting users and the sales rep who is working the lead'
          >
            <Form.Item
              name='lead_status_id'
              noStyle
              onChange={onChange}
            // rules={vsmLeadsList.validation.lead_status_id}
            >
              <Select
                // defaultValue='1'
                // onChange={onChange}
                placeholder={`${t('Common_Data.SOLD_Stage')}`}
                allowClear
                onChange={onChange}
                onFocus={() =>
                  fetchLeadstatusList &&
                  getLeadStatusList().then(() => setFetchLeadstatusList(false))
                }
                notFoundContent={
                  fetchLeadstatusList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              >
                {dropdown_leadstatus_list && dropdown_leadstatus_list.length > 0
                  ? dropdown_leadstatus_list.map((e, key) => {
                    return (
                      <OptGroup key={key} label={e.status_name}>
                        {e.child_leads.map((project, index) => {
                          return (
                            <Option key={index} value={project.id}>
                              {project.status_name}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    );
                  })
                  : LEADSSTORE.editValues &&
                  LEADSSTORE.editValues.lead_status_id && (
                    <Option value={LEADSSTORE.editValues.lead_status_id.id}>
                      {LEADSSTORE.editValues.lead_status_id.status_name}
                    </Option>
                  )}
              </Select>
            </Form.Item>
          </Form.Item>
          <InputComponent
            onChange={onChange}
            name='deadline_date'
            type='date'
            placeholder={`${t('LeadKanbanView.DeadLine_Date')}`}
          />
          <div className='smallBtn'>
            <EnterFullscreen key='2' />
          </div>
        </div>
      </Form>
    </>
  );
});
export default TopSelection;
