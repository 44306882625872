import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Col, Row, Input } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmTransactionFields, vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const UnpublishComponent = observer((props) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const {
    TransactionFieldStore: { TogglepublishData, unpublishValues, relation_list },
  } = useStore();
  const [saving, setSaving] = useState();
  const [unpublishButton, ToggleUnpublishButton] = useState(true);

  // Made function call to unpublish existing record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = unpublishValues.id;
    data.status = false;
    delete data['unpublish'];
    TogglepublishData(data)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: vsmTransactionFields.unpublish,
        });
        form.resetFields();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        ToggleUnpublishButton(true);
      });
  };

  // Handle on field change
  const handleFieldChange = () => {
    if (form.getFieldsValue().unpublish.toLowerCase() === 'unpublish') {
      ToggleUnpublishButton(false);
    } else {
      ToggleUnpublishButton(true);
    }
  };

  // Set values to unpublish
  useEffect(() => {
    ToggleUnpublishButton(true);
  }, [unpublishValues, form]);

  return (
    <Modal
      title={`${t('DealBuilder.Additional_Confirmation')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='unpublishform'
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          size='medium'
          danger
          disabled={unpublishButton}
        >
          {t('DealBuilder.Unpublish')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          shape='round'
          size='medium'
          onClick={() => {
            form.resetFields();
            ToggleUnpublishButton(true);
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='unpublishform' onFinish={handleSubmit} labelCol={{ span: 6 }}>
        <>
          <Row>
            <Col span={4}>
              <WarningFilled style={{ fontSize: 45 }} />
            </Col>
            <Col span={20}>
              <h3>
                {t('DealBuilder.Unpublish_Warning')} :
              </h3>
              <ul>
                <span>
                  <li>
                    <span>
                     {t('DealBuilder.Builder')} :
                      {relation_list && relation_list.algorithms.length > 0 ? (
                        relation_list.algorithms
                          .map((item) => <span key={item.id}> {item.name}</span>)
                          .reduce((prev, curr) => [prev, ', ', curr])
                      ) : (
                        <span> {''} </span>
                      )}
                    </span>
                  </li>
                </span>

                <span>
                  <li>
                    <span>
                      {t('DealBuilder.Associate_Fields_Builders')} :
                      {relation_list && relation_list.associated_field.length > 0 ? (
                        relation_list.associated_field
                          .map((item) => <span key={item.id}> {item.name}</span>)
                          .reduce((prev, curr) => [prev, ', ', curr])
                      ) : (
                        <span> {''} </span>
                      )}
                    </span>
                  </li>
                </span>

                <span>
                  <li>
                    <span>
                      {t('DealBuilder.Model')} :
                      {relation_list && relation_list.model.length > 0 ? (
                        relation_list.model
                          .map((item) => <span key={item.id}> {item.name}</span>)
                          .reduce((prev, curr) => [prev, ', ', curr])
                      ) : (
                        <span> {''} </span>
                      )}
                    </span>
                  </li>
                </span>

                <span>
                  <li>
                    <span>
                      {t('DealBuilder.Mapping')} :
                      {relation_list && relation_list.mappings.length > 0 ? (
                        relation_list.mappings
                          .map((item) => <span key={item.id}> {item.name}</span>)
                          .reduce((prev, curr) => [prev, ', ', curr])
                      ) : (
                        <span> {''} </span>
                      )}
                    </span>
                  </li>
                </span>
              </ul>
              <h3>{t('DealBuilder.Unpublish_Anyway')},</h3>
              <ul>
                <li>{t('DealBuilder.Remove_From_Mapping')}</li>
                <li>
                  {t('DealBuilder.Models_Of_Unpublished')}
                </li>
                <li>{t('DealBuilder.Algorithm_And_Associated_Fields')}</li>
              </ul>
              <h3>
                {t('DealBuilder.Republish_Them')}.
              </h3>

              <Form.Item style={{ marginBottom: 0 }} name='unpublish'>
                <Input
                  placeholder={`${t('DealBuilder.Unpublish_Confirm')}`}
                  onChange={handleFieldChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
});

export default UnpublishComponent;
