import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Popover, Tooltip } from 'antd';
import { vsmCommon, vsmNotify, vsmQuickNoteUpdate } from '../../../config/messages';
import useStore from '../../../store';
import { useHistory } from 'react-router-dom';
import LocalGridConfig from '../../../config/LocalGridConfig';
import PhoneNumberRender from '../../LeadListing/elements/PhoneNumberRender';
import EmailCellRender from '../../LeadListing/elements/EmailCellRender';
import ReassignConfirmElements from '../../LeadListing/elements/ReassignConfirmElements';
import NoteRenderer from '../../Promotion/elements/NoteRenderer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CurrencyFormat, DateComparator } from '../../../utils/GlobalFunction';
import { GridLoader } from '../../../utils/GridLoader';

const LeadSearchListing = observer((props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const {
    AUTH,
    SEARCHSTORE,
    LEADSSTORE,
    LEADSSTORE: { getReassignUserList, onGridChangedSearch },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    PROMOTIONSTORE,
    // REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();

  const [quickNoteList, setQuickNoteList] = useState();

  const { WasteManagement, SearchPage, showCheckbox } = props;

  /** NOTE: imports and column defs are removed which are not required and all the functionality are commented
   * if any functionality are required further; uncommnet it and add column defs and import whichever required
   */

  useEffect(() => {
    if (WasteManagement) {
      LEADSSTORE.waste = true;
      LEADSSTORE.search_payload = {};
    } else if (SearchPage) {
      LEADSSTORE.waste = false;
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.lead_count;
    } else {
      LEADSSTORE.waste = false;
      LEADSSTORE.search_payload = {};
    }
  }, [
    LEADSSTORE,
    // LEADSEARCH,
    SEARCHSTORE,
    SearchPage,
    WasteManagement,
    LEADSSTORE.total,
  ]);

  useEffect(() => {
    SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.active;
    LEADSSTORE.getListSearch(LEADSSTORE.search_payload)    
  }, [LEADSSTORE, SEARCHSTORE])

  useEffect(() => {
    if (!quickNoteList) {
      PROMOTIONSTORE.getQuickNoteList().then((data) => {
        setQuickNoteList(data.data.quick_notes);
      });
    }
  }, [PROMOTIONSTORE, quickNoteList]);

  const handleSubmitQN = (id, data) => {
    let formdata = {
      notes_flag: 1,
      notes_description: data,
    };
    PROMOTIONSTORE.updateQuickNote(id, formdata).then(() => {
      vsmNotify.success({
        message: vsmQuickNoteUpdate.successQuickNoteUpdate,
      });
    });
  };

  const ActionRenderer = (params) => {
    // const [count, setCount] = useState(0);
    // const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
    // const [openHourglassModal, setOpenHourglassModal] = useState(false);
    const [openReassignModal, setOpenReassignModal] = useState(false);
    // const [openleadStatusOptins, setOpenleadStatusOptins] = useState(false);
    // const [leadStatus, setLeadStatus] = useState(null);
    // const [statusUpdateData, setStatusUpdateData] = useState(null);
    const [reAssignUser, setReAssignUser] = useState(null);
    const [reAssingTempData, setReAssingTempData] = useState(null);
    // const [openAuditLogModal, setopenAuditLogModal] = useState(false);
    // const [auditLeadID, setAuditLeadID] = useState(false);
    // const [date, setDate] = useState();
    // const [reminderConfirm, setReminderConfirm] = useState();
    // const [hourglassConfirm, setHourglassConfirm] = useState();
    // const [extrafooter, setExtrafooter] = useState(null);
    // const [loadingLeadStatus, setLoadingLeadStatus] = useState(true);
    // const [statusModalVisible, setStatusModalVisible] = useState(false);

    // const AuditLogModal = (leadid) => {
    //   setAuditLeadID(leadid);
    //   setopenAuditLogModal(true);
    // };
    // const closeAuditLogModal = () => setopenAuditLogModal(false);

    // const openConfirmDateTimeModal = (dateformat, data) => {
    //   if (dateformat) {
    //     setDate(dateformat);
    //     setReminderConfirm(data);
    //     setOpenDateTimeModal(true);
    //   }
    // };
    // const closeDateTimeModal = () => {
    //   setDate(null);
    //   setReminderConfirm(null);
    //   setOpenDateTimeModal(false);
    // };

    // const openConfirmHourglassModal = (data) => {
    //   setOpenHourglassModal(true);
    //   // setCount(count + 1);
    //   setHourglassConfirm(data);
    // };
    // const closeHourglassModal = () => setOpenHourglassModal(false);

    /** ReAssign Modal Function */
    const openConfirmReassignModal = (userid, username, leadid) => {
      let tempReAssign = {
        userid: userid,
        username: username,
        leadid: leadid,
      };
      setReAssingTempData(tempReAssign);
      setOpenReassignModal(true);
    };
    const closeReassignModal = () => {
      // setStatusUpdateData(null);
      setOpenReassignModal(false);
    };

    // const openleadStatusModal = (new_id, oldstatus, newstatus, id) => {
    //   let tempDataStatusUpdate = {
    //     new_id: new_id,
    //     oldstatus: oldstatus,
    //     newstatus: newstatus,
    //     id: id,
    //   };
    //   setStatusUpdateData(tempDataStatusUpdate);

    //   setOpenleadStatusOptins(true);
    // };
    // const closeleadStatusOptins = () => {
    //   setStatusUpdateData(null);
    //   setOpenleadStatusOptins(false);
    // };

    // const disabledHours = (e) => {
    //   let hours = [19, 20, 21, 22, 23];
    //   for (let i = 0; i < 7; i++) {
    //     hours.push(i);
    //   }
    //   return hours;
    // };

    // let liPercentage =
    //   leadStatus && leadStatus.length > 0 ? ((100 / leadStatus.length) - 1) + '%' : '24%';

    // let ulWidth =
    //   leadStatus && (leadStatus.length === 1 || leadStatus.length === 2)
    //     ? 200 * leadStatus.length + 'px'
    //     : '655px';

    // const LeadStatusOptins = (
    //   <div className='leadStatusOptins'>
    //     {loadingLeadStatus ? (
    //       <ul style={{ width: '655px', height: '100px' }}>
    //         <Spin size='large' spinning={loadingLeadStatus} tip={`${t('Common_Data.Load')}`} />
    //       </ul>
    //     ) : (
    //       <ul
    //         style={{
    //           width: ulWidth,
    //         }}
    //       >
    //         {leadStatus &&
    //           leadStatus.map(function (item) {
    //             return (
    //               <li key={item.id} style={{ width: liPercentage }}>
    //                 <span className='title'>{item.status_name}</span>
    //                 <ul>
    //                   {item.child_leads.map(
    //                     (itemsubleads) =>
    //                       itemsubleads.id !== LEADSSTORE.ignore_unusedlead_id && (
    //                         <li
    //                           key={itemsubleads.id}
    //                           className={
    //                             itemsubleads.id === params.data?.lead_status_id?.id
    //                               ? 'active'
    //                               : ''
    //                           }
    //                           onClick={() => {
    //                             if (itemsubleads.id !== params.data?.lead_status_id?.id) {
    //                               openleadStatusModal(
    //                                 itemsubleads.id,
    //                                 params.data.lead_status_id.status_name,
    //                                 itemsubleads.status_name,
    //                                 params.data.id
    //                               )
    //                             }
    //                           }
    //                           }
    //                         >
    //                           {itemsubleads.status_name}
    //                         </li>
    //                       )
    //                   )}
    //                 </ul>
    //               </li>
    //             );
    //           })}
    //       </ul>
    //     )}
    //   </div>
    // );

    const userList = (
      <div className='ticketsPopoverIcons username'>
        <ul>
          {reAssignUser &&
            reAssignUser.map(function (item) {
              return (
                <li
                  key={item.id}
                  onClick={() =>
                    openConfirmReassignModal(item.id, item.name, params.data.id)
                  }
                >
                  {item.name}
                </li>
              );
            })}
        </ul>
      </div>
    );

    // const content = (
    //   <div className='ticketsPopoverIcons'>
    //     <Space size={15}>
    //       {AUTH.checkPermission(11, 'change-status') && (
    //         <Popover
    //           content={LeadStatusOptins}
    //           placement='bottom'
    //           trigger='click'
    //           overlayClassName='profileMenu leadStatusFixHeight'
    //           visible={statusModalVisible}
    //           onVisibleChange={() => {
    //             if (!openleadStatusOptins) {
    //               setStatusModalVisible(!statusModalVisible);
    //             }
    //           }}
    //         >
    //           <img
    //             className='menuicon'
    //             alt=''
    //             title={`${t('LeadListing.Status_Change')}`}
    //             src={AUTH.GetThemedImage('StatusChange')}
    //             onMouseEnter={() => {
    //               if (!leadStatus) {
    //                 getLeadStatusList(params.data?.lead_record_type)
    //                   .then((data) => {
    //                     setLeadStatus(data.data);
    //                   })
    //                   .finally(() => setLoadingLeadStatus(false));
    //               }
    //             }}
    //           />
    //         </Popover>
    //       )}
    //       {AUTH.checkPermission(11, 'reassign') && (
    //         <Popover
    //           content={userList}
    //           placement='bottomRight'
    //           trigger='hover'
    //           overlayClassName='profileMenu'
    //         >
    //           <img
    //             className='menuicon'
    //             alt=''
    //             src={AUTH.GetThemedImage('Reassign')}
    //             title={`${t('LeadListing.Reassign')}`}
    //             onMouseEnter={() => {
    //               if (!reAssignUser) {
    //                 getReassignUserList().then((data) => {
    //                   setReAssignUser(data.data);
    //                 });
    //               }
    //             }}
    //           />
    //         </Popover>
    //       )}
    //       {AUTH.checkPermission(11, 'reset-reminder-date-time') && (
    //         // params?.data?.reset_count !== null &&
    //         <span
    //           className='Calendar cursor'
    //           onMouseEnter={() => {
    //             if (params.data?.reset_count !== null) {
    //               getReminderDatepickerExtraFooter(
    //                 params.data,
    //                 AUTH.global_fulldate_format,
    //                 {
    //                   type: 'leads',
    //                 }
    //               ).then((data) => {
    //                 setExtrafooter(data);
    //               });
    //             }
    //           }}
    //         >
    //           <Tooltip
    //             title={
    //               params.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`
    //             }
    //           >
    //             <DatePickerCostume
    //               name='Calendar'
    //               onOk={(e) => openConfirmDateTimeModal(e, props.data)}
    //               showTime
    //               title={`${t('LeadListing.Calendar')}`}
    //               className='cursor'
    //               // disabledHours={() => disabledHours()}
    //               // minuteStep={15}
    //               // hideDisabledOptions
    //               renderExtraFooter={() => extrafooter}
    //               showNow={false}
    //             />
    //           </Tooltip>

    //           <img
    //             className='menuicon'
    //             title={`${t('LeadListing.Calendar')}`}
    //             alt=''
    //             src={AUTH.GetThemedImage('Calendar')}
    //           />
    //         </span>
    //       )}
    //       {AUTH.checkPermission(11, 'reset-reminder-time') && (
    //         //params?.data?.reset_count !== null &&
    //         <>
    //           <img
    //             className='menuicon'
    //             title={`${t('LeadListing.Hour_Glass')}`}
    //             alt=''
    //             src={AUTH.GetThemedImage('Reminders_icn')}
    //             onClick={() => openConfirmHourglassModal(params.data)}
    //           />
    //           <span>{params?.data?.reset_count ? params?.data?.reset_count : 0}</span>
    //         </>
    //       )}
    //       {AUTH.checkPermission(31, 'list') && (
    //         <>
    //           <img
    //             className='menuicon'
    //             alt=''
    //             title={`${t('Common_Data.Audit_Logs')}`}
    //             src={AUTH.GetThemedImage('Audit_Logs')}
    //             onClick={() => {
    //               AuditLogModal(params.data.id);
    //             }}
    //           />
    //         </>
    //       )}
    //     </Space>
    //   </div>
    // );

    // const { quickNoteList, handleSubmitQN } =
    //   params.agGridReact.props.frameworkComponents;

    // const contentP = (
    //   <div className='QuicknoteList'>
    //     <ul>
    //       {quickNoteList ? (
    //         quickNoteList.map(function (item, index) {
    //           return (
    //             <li
    //               key={index}
    //               title={item.quick_note_text}
    //               onClick={() => {
    //                 handleSubmitQN(params?.data?.id, item.quick_note_text);
    //               }}
    //             >
    //               {item.quick_note_text_value}
    //             </li>
    //           );
    //         })
    //       ) : (
    //         <Spin />
    //       )}
    //     </ul>
    //   </div>
    // );

    return (
      <div className='action-column'>
        <>
          {/* History */}
          {AUTH.checkPermission(11, 'change-view') && (
            <Button type='text' title={`${t('Block_Edit_Assign.Deal_Desk')}`}>
              <img
                className='menuicon'
                alt=''
                src={AUTH.GetThemedImage('Deal_icn')}
                onClick={() => {
                  history.push(`/dealdesk/${params.data.id}`);
                }}
              />
            </Button>
          )}
          {/* Edit */}
          {AUTH.checkPermission(11, 'edit') && (
            <Button
              type='text'
              title={`${t('Common_Data.Edit')}`}
              onClick={() => props.openEditDrawer(params.data)}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
            </Button>
          )}
          {/* Note */}
          <NoteRenderer data={params.data} />
          {/* ReAssign */}
          {AUTH.checkPermission(11, 'reassign') && (
            <Popover
              content={userList}
              placement='bottomRight'
              trigger='hover'
              overlayClassName='profileMenu'
            >
              <img
                className='menuicon'
                alt=''
                src={AUTH.GetThemedImage('Reassign')}
                title={`${t('LeadListing.Reassign')}`}
                onMouseEnter={() => {
                  if (!reAssignUser) {
                    getReassignUserList().then((data) => {
                      setReAssignUser(data.data);
                    });
                  }
                }}
              />
            </Popover>
          )}
          {/* Confirm modal for ReAssign */}
          {AUTH.checkPermission(11, 'reassign') && (
            <ReassignConfirmElements
              visible={openReassignModal}
              close={closeReassignModal}
              reAssingTempData={reAssingTempData}
            />
          )}
          {/* Delete */}
          {AUTH.checkPermission(11, 'delete') && (
            <Button
              type='text'
              title={`${t('Common_Data.Delete')}`}
              onClick={() => props.openDeleteDrawer(params.data)}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
            </Button>
          )}
        </>
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        checkboxSelection: showCheckbox,
        valueGetter: function (params) {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        tooltipValueGetter: (params) => {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,
        field: 'updated_at',
        tooltipField: 'updated_at',
        cellRendererFramework: (params) => {
          return params?.data?.updated_at && (
            <Tooltip title={moment(params?.data?.updated_at).format(AUTH.global_fulldate_format)}>
              {moment(params?.data?.updated_at).format(AUTH.global_fulldate_format)}
            </Tooltip>
          )
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'reset'],
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'lead_holder_name.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          // values: (params) => {
          //   LEADSSTORE.getLeadsUsersList().then((data) => {
          //     params.success([null, ...data.data.map((x) => x.name)]);
          //   });
          // },
        },
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        field: 'people.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          // values: (params) => {
          //   LEADSSTORE.getPeopleList().then((data) => {
          //     params.success([null, ...data.data.map((x) => x.people)]);
          //   });
          // },
        },
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name.organisation_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          // values: (params) => {
          //   LEADSSTORE.getMappedOrg().then((data) => {
          //     params.success([null, ...data.data.map((x) => x.organisation_name)]);
          //   });
          // },
        },
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'opportunity_name',
        cellClass: 'cellClass',
        tooltipField: 'SOLD Name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Product')}`,
        field: 'product_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          // values: (params) => {
          //   LEADSSTORE.getProductList().then((data) => {
          //     params.success([null, ...data.data.map((x) => x.product_name)]);
          //   });
          // },
        },
      },
      {
        headerName: `${t('Common_Data.Value')}`,
        field: 'finance_value',
        cellClass: 'cellClass',

        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return (
            params.data?.lead_currency_id?.currency_code +
            ' ' +
            params.data?.finance_value
          );
        },
        // cellRenderer: 'btnCellRenderer'
      },
      {
        headerName: `${t('Common_Data.SOLD_Stage')}`,
        field: 'lead_status_id.status_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          // values: (params) => {
          //   LEADSSTORE.getChildStatusList(1).then((data) => {
          //     params.success([null, ...data.data.map((x) => x.status_name)]);
          //   });
          // },
        },
      },
      {
        headerName: `${t('LeadListing.Age')}`,
        headerTooltip: `${t('LeadListing.Age')}`,
        cellRendererFramework: (params) => {
          return <> {CurrencyFormat({ value: params.data.age })}</>;
        },
        field: 'age',
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'age',
        cellClass: 'cellClass text-center',
      },
      {
        headerName: `${t('LeadListing.Idle')}`,
        headerTooltip: `${t('LeadListing.Idle')}`,
        cellRendererFramework: (params) => {
          return <> {CurrencyFormat({ value: params.data.idle })}</>;
        },
        field: 'idle',
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'idle',
        cellClass: 'cellClass text-center',
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        // width: WasteManagement === true ? 120 : 280,
        width: 156,
        filter: false,
        sortable: false,
        pinned: 'right',
        // lockPosition: true,
        // suppressNavigable: true,
      },
      {
        headerName: `${t('LeadListing.Age')}(${t('Common_Data.Days')})`,
        headerTooltip: `${t('LeadListing.Age')}`,
        field: 'age',
        cellRendererFramework: (params) => {
          return (
            <> {CurrencyFormat({ value: params.data.age })}</>
          );
        },
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'age',
        cellClass: 'cellClass text-center',
      },
      {
        headerName: `${t('LeadListing.Idle')}(${t('Common_Data.Days')})`,
        headerTooltip: `${t('LeadListing.Idle')}`,
        cellRendererFramework: (params) => {
          return (
            <> {CurrencyFormat({ value: params.data.idle })}</>
          );
        },
        field: 'idle',
        filter: 'agNumberColumnFilter',
        sortable: true,
        tooltipField: 'idle',
        cellClass: 'cellClass text-center',
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('search_leads_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  var withTooltipCols = columns.map((item) => {
    if (!['phone', 'email'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }
    return item;
  });

  // const onCellKeyUp = (e) => {
  //   if (quickNoteList && quickNoteList.length > 0) {
  //     if (e.event.ctrlKey || e.event.altKey || e.event.shiftKey) {
  //       return false;
  //     }
  //     let keyPressed = e.event.key;
  //     let result = quickNoteList
  //       .filter((x) => x.quick_note_text_value.charAt(0) === keyPressed.toUpperCase())
  //       .shift();
  //     if (result) {
  //       handleSubmitQN(e.data.id, result.quick_note_text);
  //     }
  //   }
  // };

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('LeadListing.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/dealdesk/${params.node.data.id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('LeadListing.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/dealdesk/${params.node.data.id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  function getClipBoardProcess(params) {
    if (params.column.colId === 'phone') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.value[0].contact_value;
        }
      }
    }
    if (params.column.colId === 'email') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.node.data.email;
        }
      }
    }

    return params.value;
  }

  GridLoader(LEADSSTORE)

  return (
    <>
      <div
        className={`ag-theme-alpine grid_wrapper TicketsGrid ${SearchPage ? "paginationAtTop" : ""}`}
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : props.SearchPage && !props.AllView
              ? 'calc(100vh - 13rem)'
              : props.SearchPage && props.AllView
                ? '150px'
                : 'calc(100vh - 13rem)',
          minHeight: props.AllView ? '100px' : '250px',
        }}
      >
        <AgGridReact
          rowData={LEADSSTORE.list_data}
          modules={AllModules}
          columnDefs={withTooltipCols}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            width: 120,
            minWidth: 70,
            flex: 1,
            editable: false,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            phoneCellRenderer: PhoneNumberRender,
            emailCellRender: EmailCellRender,
            quickNoteList,
            handleSubmitQN,
          }}
          onGridReady={LEADSSTORE.setupGridSearch}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: true,
            // onRowDoubleClicked: (event) => props.openViewDrawer(event?.data),
            getContextMenuItems: getContextMenuItems,
            processCellForClipboard: getClipBoardProcess,
          }}
          onColumnResized={onGridChangedSearch}
          onColumnMoved={onGridChangedSearch}
          onColumnPinned={onGridChangedSearch}
          onSortChanged={(params) => {
            if (localStorage.getItem('params')) {
              var temp = JSON.parse(localStorage.getItem('params'));
              localStorage.setItem('params', JSON.stringify({ ...temp, LeadsSearchStore: { sort: params.api.getSortModel() }, }));
            } else {
              localStorage.setItem('params',
                JSON.stringify({ LeadsSearchStore: { sort: params.api.getSortModel() }, })
              );
            }
          }}

          rowSelection='multiple'
          suppressRowClickSelection={true}
          // onCellKeyDown={(e) => {
          //   onCellKeyDown(e);
          //   onCellKeyUp(e);
          // }}
          onSelectionChanged={() => {
            props.selectionChanged();
          }}
        />
      </div>
    </>
  );
});
export default LeadSearchListing;
