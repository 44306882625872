import React, { useState, useEffect, useMemo } from 'react';
import { Col, Spin } from 'antd';

import { observer } from 'mobx-react';
import useStore from '../../../../store';
import InputComponent from '../../../../component/InputComponent';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import OrganisationElements from './OrganisationElements';
import PersonElements from './PersonElements';

const TimeSpentReportElement = observer(({ form, handleChange, formDataList }) => {
  const [fetchLeadRecordType, setFetchLeadRecordType] = useState(false);
  const [fetchTicket, setFetchTicket] = useState(true);
  const { t } = useTranslation();

  //----------GLOBAL STATE DECLARATION---------------//
  const {
    REPORTSTORE,
    REPORTSTORE: {
      searchLead,
      searchTicket,
      recordTypeList,
      getLeadTypeList,
      previewReportValues,
      getRecordType,
      searchLeadKey,
      setSearchLeadKey,
      searchTicketKey,
      setSearchTicketKey,
    },
    TicketsStore: { dropdown_master_ticket, getMasterTicketList },
  } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  useEffect(() => {
    if (!fetchLeadRecordType) {
      getLeadTypeList(previewReportValues);
      getRecordType();
      setFetchLeadRecordType(true);
    }
  }, [
    fetchLeadRecordType,
    previewReportValues,
    getLeadTypeList,
    getRecordType,
    setFetchLeadRecordType,
  ]);

  const handleSearchLead = useMemo(() => {
    const loadOptions = (val) => {
      if (val && val?.length >= 3) {
        setSearchLeadKey(val);
        searchLead({ search_text: val });
      }
    };

    return debounce(loadOptions, 500);
  }, [searchLead, setSearchLeadKey]);

  const handleSearchTicket = useMemo(() => {
    const loadOptions = (val) => {
      if (val && val?.length >= 3) {
        setSearchTicketKey(val);
        searchTicket({ search_text: val });
      }
    };

    return debounce(loadOptions, 500);
  }, [searchTicket, setSearchTicketKey]);

  return (
    <>
      {formDataList.includes('record_type') && (
        <Col span={12}>
          <InputComponent
            type='select'
            mode='multiple'
            label={`${t('Common_Data.Sold')} ${t('Common_Data.Type')}`}
            name='record_type'
            placeholder={`${t('PeopleManagement.All')}`}
            tooltip={`${t('Common_Data.Sold_Record_Tooltip')}.`}
            onChange={(e) => {
              handleChange();
            }}
            showArrow
            allowClear
            options={{
              values: recordTypeList,
              value_key: 'id',
              text_key: 'record_type_name',
            }}
          />
        </Col>
      )}
      {formDataList.includes('lead_id') && (
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
            type='select'
            showSearch={true}
            allowClear
            showArrow
            onSearch={handleSearchLead}
            mode='multiple'
            placeholder={`${t('Reports.All')}`}
            notFoundContent={
              REPORTSTORE.fetching ? (
                <Spin size='small' />
              ) : searchLeadKey ? (
                `${t('Common_Data.No_Record_Found')}.`
              ) : null
            }
            name='lead_id'
            onChange={(e) => {
              handleChange(e);
            }}
            options={{
              values: REPORTSTORE.lead_list_data,
              value_key: 'id',
              text_key: 'search_name',
            }}
            tooltip={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
          />
        </Col>
      )}
      {formDataList.includes('master_ticket_number') && (
        <Col span={12}>
          <InputComponent
            name='master_ticket_number'
            label={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            type='select'
            mode='multiple'
            showSearch={true}
            placeholder={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            tooltip={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            onChange={handleChange}
            allowClear
            showArrow
            onFocus={() =>
              fetchTicket && getMasterTicketList().then(() => setFetchTicket(false))
            }
            notFoundContent={
              fetchTicket ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
            options={{
              values: dropdown_master_ticket,
              value_key: 'id',
              text_key: 'ticket_subject',
            }}
          />
        </Col>
      )}
      {formDataList.includes('ticket_id') && (
        <Col span={12}>
          <InputComponent
            label={`${t('Reports.Ticket')} ${t('Common_Data.Number')}`}
            type='select'
            showSearch={true}
            allowClear
            showArrow
            onSearch={handleSearchTicket}
            mode='multiple'
            placeholder={`${t('Reports.All')}`}
            notFoundContent={
              REPORTSTORE.fetchingTicketSearch ? (
                <Spin size='small' />
              ) : searchTicketKey ? (
                `${t('Common_Data.No_Record_Found')}.`
              ) : null
            }
            name='ticket_id'
            onChange={(e) => {
              handleChange(e);
            }}
            options={{
              values: REPORTSTORE.ticket_list_data,
              value_key: 'id',
              text_key: 'subjectwithnum',
            }}
            tooltip={`${t('Reports.Ticket')} ${t('Common_Data.Number')}`}
          />
        </Col>
      )}
      <OrganisationElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <PersonElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
    </>
  );
});

export default TimeSpentReportElement;
