import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class NotesSequenceStore {
  noteLoader = true; //Loader on notes fetch
  sequencenotes_list = null;
  comment_notes_list = null;
  summary_notes_list = null;

  autoSavedTabID = null; //Used To Show Active autosaved note
  autoSavedNoteData = null; //Used To Update Autosave note - data fetch and set

  noteFilterColor = null; //Color Filter Applied?

  sequence_filter_option_list = null; // Used to Generate Filter Options based on Notes Data

  sequence_notes_display_data = null; // Used to Display Data in Right Side Section DealDesk

  isOutsideClicked = false;

  commentsOutsideClicked = false;

  summaryOutsideClicked = false;

  currentCommentFilterApplied = [];  // Used for Selected filter for Comment(Specific) Tab

  currentSummaryFilterApplied = []; // Used for Selected filter for Summary Tab

  currentSequenceFilterApplied = []; // Used for Selected filter for Sequence Tab

  //set Deal Status Update API
  fetchSequenceNotes = (
    id,
    color = null,
    setAutosaveNote = false,
    isFromSync = false
  ) => {
    if (color === 0) {
      this.noteFilterColor = null;
    }
    if (!this.noteFilterColor || (color !== 0 && this.noteFilterColor !== color)) {
      this.noteFilterColor = color; // Added To Solve- AutoFetched Note with Color Filter if applied color filter
    }

    return Axios.post(`deal-desk/get-leads-notes/${id}`)
      .then(({ data }) => {
        let currentLoggedUserID = parseInt(localStorage.getItem('user_name'));
        let is_autosave_exits = 0;
        let noteListArr = [];

        if (data.data.length) {
          data.data.forEach((item, index) => {
            item.tag_user_id = [];
            item.tag_group_id = [];
            if (item.is_tagged) {
              if (item.tagged_user) {
                item.tagged_user.forEach((taguser, i) => {
                  if (taguser.group_id) {
                    if (!item.tag_group_id.includes(taguser.group_id)) {
                      item.tag_group_id.push(taguser.group_id);
                    }
                  } else {
                    item.tag_user_id.push(taguser.tag_holder);
                  }
                });
              }
            }

            if (item.attachment) {
              let editAttchements = item.attachment.split('#');
              editAttchements = editAttchements.filter((word) => word !== '');
              item.attachment = editAttchements;
            } else {
              item.attachment = [];
            }

            if (isFromSync && !setAutosaveNote) {
              // Is Sync API call.
              if (!item.is_autosaved) {
                noteListArr.push(item);
              }
            } else {
              if (setAutosaveNote) {
                if (item.is_autosaved) {
                  if (item.is_autosaved && currentLoggedUserID === item.created_by) {
                    is_autosave_exits = 1;
                    if (!this.autoSavedNoteData) {
                      if (item.notes_flag === 2) {
                        // Comment
                        this.autoSavedTabID = '3';
                      } else if (item.notes_flag === 3) {
                        // Dated
                        this.autoSavedTabID = '2';
                      } else if (item.notes_flag === 4) {
                        // Inbound
                        this.autoSavedTabID = '1';
                      } else if (item.notes_flag === 1) {
                        // Outbound
                        this.autoSavedTabID = '4';
                      }

                      this.autoSavedNoteData = item;
                    }
                  }
                } else {
                  noteListArr.push(item);
                }
              } else {
                noteListArr.push(item);
              }
            }
          });
        }

        if (!is_autosave_exits && setAutosaveNote) {
          /**START: If sync api called before autosave than dont clear selected tab*/
          if (!this.autoSavedTabID) {
            this.autoSavedTabID = null;
          }
          /**END: If sync api called before autosave than dont clear selected tab*/
          this.autoSavedNoteData = null;
        }
        let filterOptionsList = {
          Users: [],
          'User Groups': [],
        };

        noteListArr.map((obj) => {
          // Key Added for User/User Groups Filter
          obj.filter_user_list = [];
          obj.filter_user_group_list = [];

          //---------------- Adding Lock Users/Tagged Users/Updated By/Created By  ---------------------- //
          if (obj.lock_user_names && obj.lock_user_names?.length > 0) {
            obj.filter_user_list = [...obj.filter_user_list, ...obj.lock_user_names];
          }
          if (obj.tagged_user && obj?.tagged_user?.length > 0) {
            let tag_users = obj.tagged_user.map((x) => {
              return x.user ? x.user.full_name : '';
            });
            obj.filter_user_list = [...obj.filter_user_list, ...tag_users];
          }
          if (obj.updated_by_name) {
            obj.filter_user_list.push(obj.updated_by_name);
          }
          if (obj.created_by_name) {
            obj.filter_user_list.push(obj.created_by_name);
          }

          obj.filter_user_list = obj.filter_user_list.filter((v, i, a) => {
            return i === a.indexOf(v);
          });

          // -----------------------------------------------------------------------------------//

          if (obj.filter_user_list.length > 0) {
            filterOptionsList['Users'] = [
              ...obj.filter_user_list,
              ...filterOptionsList['Users'],
            ];
          }

          //---------------- Adding Tagged User Group  ---------------------- //
          if (obj.tagged_group && obj?.tagged_group?.length > 0) {
            let tag_group_name = obj.tagged_group.map((x) => {
              return x.group ? x.group.group_name : '';
            });
            obj.filter_user_group_list = [
              ...obj.filter_user_group_list,
              ...tag_group_name,
            ];
          }
          obj.filter_user_group_list = obj.filter_user_group_list.filter((v, i, a) => {
            return i === a.indexOf(v);
          });

          if (obj.filter_user_group_list.length > 0) {
            filterOptionsList['User Groups'] = [
              ...obj.filter_user_group_list,
              ...filterOptionsList['User Groups'],
            ];
          }
          // -----------------------------------------------------------------------------------//

          return null;
        });
        filterOptionsList['Users'] = filterOptionsList['Users'].filter((v, i, a) => {
          return i === a.indexOf(v);
        });
        filterOptionsList['User Groups'] = filterOptionsList['User Groups'].filter(
          (v, i, a) => {
            return i === a.indexOf(v);
          }
        );

        this.sequence_filter_option_list = filterOptionsList;

        this.sequencenotes_list = noteListArr;
        this.sequence_notes_display_data = noteListArr;
        this.summary_notes_list = noteListArr;
        this.comment_notes_list =
          noteListArr && noteListArr.filter((word) => word.notes_flag === 2);

        //----- To Filter Note Color and Save All Data into Sequence Display Data ------//
        if (this.noteFilterColor) {
          this.sequence_notes_display_data =
            this.sequencenotes_list &&
            this.sequencenotes_list.filter(
              (item) => item.current_bg_color === this.noteFilterColor
            );
          return this.sequence_notes_display_data;
        }

        this.commentFilterNotes('', 'apply_current_filter');
        this.summaryFilterNotes('', 'apply_current_filter');
        this.sequenceFilterNotes('', 'apply_current_filter');
        //------------------------------------------------------------------------------//

        this.noteLoader = false; //Loader on notes fetch

        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  dataValue = (value) => {
    this.sequencenotes_list = value;
    this.sequence_notes_display_data = value;
  };

  setSeqNoteDisplayData = (data) => {
    this.sequence_notes_display_data = data;
  };
  //used on Filter Comment Notes
  fetchCommentNotes = (id, filterdata = null) => {
    let payload = {
      note_type: 1, //1= Comment & 2=Summary
      id: filterdata ? filterdata.id : 0,
    };

    return Axios.post(`deal-desk/get-leads-notes-filter/${id}`, payload)
      .then(({ data }) => {
        this.comment_notes_list = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //used on Filter Summary Notes
  fetchSummaryNotes = (id, filterdata = null) => {
    let payload = {
      note_type: 2, //1= Comment & 2=Summary
      id: filterdata ? filterdata.id : 0,
    };

    return Axios.post(`deal-desk/get-leads-notes-filter/${id}`, payload)
      .then(({ data }) => {
        this.summary_notes_list = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  resetNoteData = () => {
    this.sequencenotes_list = null;
    this.sequence_notes_display_data = null;
    this.comment_notes_list = null;
    this.summary_notes_list = null;
    this.noteLoader = true;
    this.noteFilterColor = null;
    this.resetAutosaveEditNoteData();
  };

  // Used To Diplay AUTOSAVEDNOTEDATA
  setAutoSavedNoteData = (data) => {
    this.autoSavedNoteData = data;
  };

  // Used To Diplay AUTOSAVEDNOTEDATA
  setAutoSavedTabID = (data) => {
    this.autoSavedTabID = data;
  };

  resetAutosaveEditNoteData = () => {
    this.autoSavedTabID = null;
    this.autoSavedNoteData = null;
  };
  setNoteLoader = (data) => {
    this.noteLoader = data;
  };

  /**USED TO UPDATE NOTES LIST - MANUALLY AFTER NOTE SAVE - OR EDIT FROM DEALDESK EDITOR */
  addUpdateNoteList = (note, isEdit) => {
    let noteListArr = this.sequencenotes_list ? this.sequencenotes_list : null;
    if (isEdit) {
      let foundIndex = noteListArr.findIndex((x) => x.id === note.id);

      if (foundIndex >= 0) {
        noteListArr[foundIndex] = note;
      }
    } else {
      noteListArr && noteListArr.unshift(note && note);
    }

    let filterOptionsList = {
      Users: [],
      'User Groups': [],
    };
    noteListArr &&
      noteListArr.length > 0 &&
      noteListArr.forEach((item, index) => {
        // Key Added for User/User Groups Filter
        item.filter_user_list = [];
        item.filter_user_group_list = [];

        //---------------- Adding Lock Users/Tagged Users/Updated By/Created By  ---------------------- //
        if (item.lock_user_names && item.lock_user_names?.length > 0) {
          item.filter_user_list = [...item.filter_user_list, ...item.lock_user_names];
        }
        if (item.tagged_user && item?.tagged_user?.length > 0) {
          let tag_users = item.tagged_user.map((x) => {
            return x.user ? x.user.full_name : '';
          });
          item.filter_user_list = [...item.filter_user_list, ...tag_users];
        }
        if (item.updated_by_name) {
          item.filter_user_list.push(item.updated_by_name);
        }
        if (item.created_by_name) {
          item.filter_user_list.push(item.created_by_name);
        }

        item.filter_user_list = item.filter_user_list.filter((v, i, a) => {
          return i === a.indexOf(v);
        });

        // -----------------------------------------------------------------------------------//

        if (item.filter_user_list.length > 0) {
          filterOptionsList['Users'] = [
            ...item.filter_user_list,
            ...filterOptionsList['Users'],
          ];
        }

        //---------------- Adding Tagged User Group  ---------------------- //
        if (item.tagged_group && item?.tagged_group?.length > 0) {
          let tag_group_name = item.tagged_group.map((x) => {
            return x.group ? x.group.group_name : '';
          });
          item.filter_user_group_list = [
            ...item.filter_user_group_list,
            ...tag_group_name,
          ];
        }
        item.filter_user_group_list = item.filter_user_group_list.filter((v, i, a) => {
          return i === a.indexOf(v);
        });

        if (item.filter_user_group_list.length > 0) {
          filterOptionsList['User Groups'] = [
            ...item.filter_user_group_list,
            ...filterOptionsList['User Groups'],
          ];
        }
        // -----------------------------------------------------------------------------------//

        item.tag_user_id = [];
        item.tag_group_id = [];
        if (item.is_tagged) {
          if (item.tagged_user) {
            item.tagged_user.forEach((taguser, i) => {
              if (taguser.group_id) {
                if (!item.tag_group_id.includes(taguser.group_id)) {
                  item.tag_group_id.push(taguser.group_id);
                }
              } else {
                item.tag_user_id.push(taguser.tag_holder);
              }
            });
          }
        }
        if (item.attachment && !Array.isArray(item.attachment)) {
          if (item.attachment) {
            let editAttchements = item.attachment.split('#');
            editAttchements = editAttchements.filter((word) => word !== '');
            item.attachment = editAttchements;
          } else {
            item.attachment = [];
          }
        }
      });

    noteListArr = noteListArr.sort(function (a, b) {
      return new Date(b.notes_date).getTime() - new Date(a.notes_date).getTime();
    });

    filterOptionsList['Users'] = filterOptionsList['Users'].filter((v, i, a) => {
      return i === a.indexOf(v);
    });
    filterOptionsList['User Groups'] = filterOptionsList['User Groups'].filter(
      (v, i, a) => {
        return i === a.indexOf(v);
      }
    );

    this.sequence_filter_option_list = filterOptionsList;
    this.sequencenotes_list = noteListArr;
    this.sequence_notes_display_data = noteListArr;

    this.summary_notes_list = noteListArr;
    this.comment_notes_list =
      noteListArr && noteListArr.filter((word) => word.notes_flag === 2);

    this.commentFilterNotes('', 'apply_current_filter');
    this.summaryFilterNotes('', 'apply_current_filter');
    this.sequenceFilterNotes('', 'apply_current_filter');
  };

  // Used To Filter Notes Data
  sequenceFilterNotes = (filterValue, type) => {
    let sequenceList = this.sequencenotes_list;

    let currentFilterApplied = this.currentSequenceFilterApplied;

    if (type !== 'all') {

      let finalNotesFilterData = [];

      // ------------ Condition added for apply current filter do not push filter data -------------//
      if (type !== 'apply_current_filter') {
        let filterOption = {
          value: filterValue,
          field: type
        }
        let currentIndex = currentFilterApplied.findIndex(x => x.field === type && x.value === filterValue);
        if (currentIndex !== -1) {
          currentFilterApplied.splice(currentIndex, 1)
        }
        else {
          currentFilterApplied = [...currentFilterApplied, filterOption];
        }
      }
      if (currentFilterApplied.length > 0) {
        currentFilterApplied.map((obj) => {
          let notesList;
          if (obj.field === 'current_bg_color') {
            if (obj.value === '') {
              notesList = sequenceList.filter((x) => x[obj.field] === null || x[obj.field] === "");
            }
            else {
              notesList = sequenceList.filter((x) => x[obj.field] && x[obj.field] === obj.value);
            }
          }
          else {
            notesList = sequenceList.filter(
              (x) => x[obj.field] && x[obj.field].includes(obj.value)
            );
          }
          finalNotesFilterData = [...finalNotesFilterData, ...notesList];
          return null;
        })
        finalNotesFilterData = finalNotesFilterData.filter((x) => (x.deleted_at === null));
        finalNotesFilterData = finalNotesFilterData.filter(function (obj) {
          return !obj.notes_description?.includes('<approved');
        });
        sequenceList = finalNotesFilterData;
      }
    }
    else {
      currentFilterApplied = [];

    }
    this.currentSequenceFilterApplied = currentFilterApplied;
    this.sequence_notes_display_data = sequenceList;

  };

  setIsOutsideClicked = (data) => {
    this.isOutsideClicked = data;
  };

  setCommentsOutsideClicked = (data) => {
    this.commentsOutsideClicked = data;
  };

  setSummaryOutsideClicked = (data) => {
    this.summaryOutsideClicked = data;
  };

  // Used To Filter Comments Data
  commentFilterNotes = (filterValue, type) => {
    let currentFilterApplied = this.currentCommentFilterApplied;

    let commentNotesList = this.sequencenotes_list.filter(
      (word) => word.notes_flag === 2
    );
    if (type !== 'all') {
      let finalNotesFilterData = [];
      // ------------ Condition added for apply current filter do not push filter data -------------//
      if (type !== 'apply_current_filter') {
        let filterOption = {
          value: filterValue,
          field: type
        }
        let currentIndex = currentFilterApplied.findIndex(x => x.field === type && x.value === filterValue);
        if (currentIndex !== -1) {
          currentFilterApplied.splice(currentIndex, 1)
        }
        else {
          currentFilterApplied = [...currentFilterApplied, filterOption];
        }
      }

      if (currentFilterApplied.length > 0) {
        currentFilterApplied.map((obj) => {
          let notesList = commentNotesList.filter((x) => x[obj.field].includes(obj.value));
          finalNotesFilterData = [...finalNotesFilterData, ...notesList];
          return null;
        })
        commentNotesList = finalNotesFilterData;
      }
    }
    else {
      currentFilterApplied = [];
    }
    this.currentCommentFilterApplied = currentFilterApplied;
    this.comment_notes_list = commentNotesList;
  };

  // Used To Filter Summary Data
  summaryFilterNotes = (filterValue, type) => {
    let summary_notes_list = this.sequencenotes_list;
    let currentFilterApplied = this.currentSummaryFilterApplied;
    if (type !== 'all') {
      let finalNotesFilterData = [];

      // ------------ Condition added for apply current filter do not push filter data -------------//
      if (type !== 'apply_current_filter') {
        let filterOption = {
          value: filterValue,
          field: type
        }
        let currentIndex = currentFilterApplied.findIndex(x => x.field === type && x.value === filterValue);
        if (currentIndex !== -1) {
          currentFilterApplied.splice(currentIndex, 1)
        }
        else {
          currentFilterApplied = [filterOption];
        }
      }

      if (currentFilterApplied.length > 0) {
        currentFilterApplied.map((obj) => {
          if (obj.field === 'notes_description') {
            let notesList = summary_notes_list.filter((x) => x[obj.field].includes(obj.value));
            finalNotesFilterData = [...finalNotesFilterData, ...notesList];
          }
          else {
            let notesList = summary_notes_list.filter((x) => x[obj.field] === obj.value);
            finalNotesFilterData = [...finalNotesFilterData, ...notesList];
          }
          return null;
        })
        summary_notes_list = finalNotesFilterData;
      }
    }
    else {
      currentFilterApplied = [];
    }
    this.currentSummaryFilterApplied = currentFilterApplied;
    this.summary_notes_list = summary_notes_list;
  };
}
decorate(NotesSequenceStore, {
  sequencenotes_list: observable,
  comment_notes_list: observable,
  summary_notes_list: observable,
  autoSavedTabID: observable,
  autoSavedNoteData: observable,
  noteLoader: observable,
  noteFilterColor: observable,
  sequence_filter_option_list: observable,
  sequence_notes_display_data: observable,
  isOutsideClicked: observable,
  commentsOutsideClicked: observable,
  summaryOutsideClicked: observable,
  fetchSequenceNotes: action,
  dataValue: action,
  fetchCommentNotes: action,
  fetchSummaryNotes: action,
  resetNoteData: action,
  setAutoSavedNoteData: action,
  setAutoSavedTabID: action,
  resetAutosaveEditNoteData: action,
  setNoteLoader: action,
  addUpdateNoteList: action,
  sequenceFilterNotes: action,
  setIsOutsideClicked: action,
  commentFilterNotes: action,
  setSummaryOutsideClicked: action,
  summaryFilterNotes: action,
  setSeqNoteDisplayData: action,
});
